import React from 'react';

import ButtonToggle from '../mui/ButtonToggle';
import TeamSubGroupSortSelect from './TeamSubGroupSortSelect';

import withIntl from '../withIntl';

const TeamSubGroupHeaderRow = props => {
  const {
    activities,
    sortProps,
    sort,
    statsShown,
    onStatsShownToggle,
    onSort,
    intl: { messages }
  } = props;
  return (
    <div className="container-flex-row fw-yes jc-space-between mui-padded-half border-top border-color-primary__50">
      <div className="container-flex-row ai-center fw-yes text-muted mui-padded-half mui-padded-horizontal">
        <span>{messages.sortByLabel}:</span>
        <div className="mui-padded-left" style={{ minWidth: '90px' }}>
          <TeamSubGroupSortSelect
            activities={activities}
            sortProps={sortProps}
            property={sort?.property || 'default'}
            order={sort?.order || 'DESC'}
            onChange={onSort}
          />
        </div>
      </div>
      {onStatsShownToggle && (
        <div className="mui-padded-half">
          <div className="container-flex-row fw-yes bg-primary-light border-radius" style={{ padding: '2px' }}>
            <div style={{ padding: '2px' }}>
              <ButtonToggle onToggle={onStatsShownToggle} active={statsShown}>
                <div className="text-center mui-padded-half flex1 text-sz-reg-sm text-uppercase">
                  <i className="material-icons text-sz-lg">insights</i>
                </div>
              </ButtonToggle>
            </div>
            <div style={{ padding: '2px' }}>
              <ButtonToggle onToggle={onStatsShownToggle} active={!statsShown}>
                <div className="text-center mui-padded-half flex1 text-sz-reg-sm text-uppercase">
                  <i className="material-icons text-sz-lg">assignment</i>
                </div>
              </ButtonToggle>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    sortByLabel: 'Řazení'
  },
  sk: {
    sortByLabel: 'Usporiadanie'
  },
  en: {
    sortByLabel: 'Sort by'
  }
};

export default withIntl(TeamSubGroupHeaderRow, intlMessages);
