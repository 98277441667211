import React from 'react';
import { withRouter } from 'react-router';

const RouteMatch = props => {
  const { location, children, expression } = props;
  const expr = new RegExp(expression);
  const match = expr.exec(location.pathname);
  if (match) {
    return typeof children === 'function' ? children({ match }) : children;
  }
  //noinspection JSConstructorReturnsPrimitive
  return null;
};

export default withRouter(RouteMatch);
