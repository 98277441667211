import React from 'react';
import PropTypes from 'prop-types';

import Chip from '../mui/Chip';
import FilterBadge from './FilterBadge';

const FilterChip = props => {
  const { value, label, color, none, active, handleToggle, count, countFiltered } = props;
  return (
    <div style={{ display: 'inline-block', margin: 3, verticalAlign: 'middle' }}>
      <Chip iconLeft={active && 'check'} active={active} variant="compact" onClick={() => handleToggle(value)}>
        {color && (
          <div className={`circle-indicator ci-size-8${active ? ' border' : ''}`} style={{ backgroundColor: color }} />
        )}
        {none ? <em>{label}</em> : label}
        {count != undefined && <FilterBadge all={count} count={countFiltered} />}
      </Chip>
    </div>
  );
};

FilterChip.propTypes = {
  value: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  active: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired
};

export default FilterChip;
