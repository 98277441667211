import React from 'react';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';

import Checkbox from 'material-ui/Checkbox';

import Button from '../mui/Button';
import Dialog from '../mui/Dialog';
import ParticipantsSelectPanel from '../applicant/ParticipantsSelectPanel';
import ActivityLabel from '../activity/ActivityLabel';
import ParticipantName from '../basic/ParticipantName';

import { getActivityMapKey } from './TeamUtils';
import { formatMessage, selectQuantityMessage } from '../../utils/utils';

import withIntl from '../withIntl';

class MembersActivitiesSelectDialog extends React.Component {
  state = {
    selected: {},
    selectedMembers: null,
    memberSelectShown: false
  };

  componentDidMount() {
    this.updateSelectedActivities(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.preselectedActivities !== this.props.preselectedActivities ||
      prevProps.activities !== this.props.activities
    ) {
      this.updateSelectedActivities(this.props);
    }
    if (prevProps.members !== this.props.members) {
      this.setState({ selectedMembers: null, memberSelectShown: false });
    }
  }

  updateSelectedActivities = props => {
    const { preselectedActivities } = props;
    this.setState({
      selected: _reduce(
        preselectedActivities || [],
        (res, a) => {
          res[getActivityMapKey(a)] = a;
          return res;
        },
        {}
      )
    });
  };

  handleToggleActivity = a => {
    const aKey = getActivityMapKey(a);
    const {
      selected: { [aKey]: present, ...selected }
    } = this.state;
    if (!present) {
      selected[aKey] = a;
    }
    this.setState({ selected });
  };

  handleConfirm = () => {
    const { onConfirm, activities, members } = this.props;
    const { selectedMembers } = this.state;
    const actualSelectedMembers = selectedMembers ? selectedMembers : members;
    let selectedActivities;
    if (activities && activities.length > 1) {
      const { selected } = this.state;
      selectedActivities = _reduce(
        activities,
        (res, a) => {
          if (selected[getActivityMapKey(a)]) {
            res.push(a);
          }
          return res;
        },
        []
      );
    } else {
      selectedActivities = activities;
    }
    onConfirm(actualSelectedMembers, selectedActivities);
  };

  renderMembers = () => {
    const {
      members,
      intl: { messages }
    } = this.props;
    const res = [];
    for (let i = 0; i < members.length; i += 1) {
      if (i >= 3) {
        const moreCount = members.length - 3;
        res.push(
          <span key="moreMembers">
            {' '}
            {formatMessage(messages.andMore, [
              <a key="showSelect" role="button" onClick={this.handleShowMemberSelect}>
                {formatMessage(selectQuantityMessage(messages.andMoreMessages, moreCount), [moreCount])}
              </a>
            ])}
          </span>
        );
        break;
      }
      if (i > 0) {
        res.push(<span key={`comma-${i}`}>, </span>);
      }
      res.push(
        <strong key={`member-${i}`}>
          <ParticipantName {...members[i]} />
        </strong>
      );
    }
    return res;
  };

  handleShowMemberSelect = () => {
    this.setState({ memberSelectShown: true });
  };
  handleMemberSelectionChange = selectedMembers => {
    this.setState({ selectedMembers });
  };

  render() {
    const {
      open,
      members,
      activities,
      cancelLabel,
      confirmLabel,
      confirmIcon,
      intl: { messages }
    } = this.props;
    const { renderMessage } = this.props;
    const { onCancel } = this.props;
    const { selected, selectedMembers, memberSelectShown } = this.state;
    return (
      <Dialog
        open={open}
        contentStyle={{ maxWidth: '520px' }}
        actions={[
          <Button label={cancelLabel} onClick={onCancel} />,
          <Button
            primary
            label={confirmLabel}
            icon={confirmIcon}
            onClick={this.handleConfirm}
            disabled={
              (activities && activities.length > 0 && _isEmpty(selected)) ||
              (selectedMembers && selectedMembers.length === 0)
            }
          />
        ]}
      >
        {members && members.length > 0 && (
          <div>
            {activities && activities.length > 1 ? (
              <div>
                <div>{renderMessage(this.renderMembers(), false)}</div>
                <div>
                  {_map(activities, a => {
                    const aKey = getActivityMapKey(a);
                    return (
                      <div key={aKey} style={{ marginTop: '8px' }}>
                        <Checkbox
                          label={<ActivityLabel activity={a} />}
                          labelStyle={{ margin: 0, fontWeight: 400 }}
                          onCheck={() => this.handleToggleActivity(a)}
                          checked={!!selected[aKey]}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div>
                <span>
                  {renderMessage(
                    this.renderMembers(),
                    true,
                    activities && activities.length > 0 ? (
                      <strong>
                        <ActivityLabel activity={activities[0]} />
                      </strong>
                    ) : null
                  )}
                </span>
              </div>
            )}
            {members.length > 1 &&
              (memberSelectShown ? (
                <div
                  className="bg-primary-light border border-color-primary__50 border-radius mui-padded-vertical"
                  style={{ maxHeight: '200px', overflow: 'auto', marginTop: '16px' }}
                >
                  <ParticipantsSelectPanel
                    participants={members}
                    getParticipant={m => m}
                    onSelectionChange={this.handleMemberSelectionChange}
                  />
                </div>
              ) : (
                <div className="text-muted small" style={{ marginTop: '8px' }}>
                  <a role="button" className="link-button" onClick={this.handleShowMemberSelect}>
                    {messages.selectMembersMessage}
                  </a>
                </div>
              ))}
          </div>
        )}
      </Dialog>
    );
  }
}

const intlMessages = {
  cs: {
    selectMembersMessage: 'Upravit výběr',
    andMore: '({0})',
    andMoreMessages: {
      '>0': '+{0}...'
    }
  },
  sk: {
    selectMembersMessage: 'Upraviť výber',
    andMore: '({0})',
    andMoreMessages: {
      '>0': '+{0}...'
    }
  },
  en: {
    selectMembersMessage: 'Edit selection',
    andMore: 'and {0}',
    andMoreMessages: {
      '>0': '{0} more...'
    }
  }
};

export default withIntl(MembersActivitiesSelectDialog, intlMessages);
