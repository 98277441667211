import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { formatDuration } from '../../../utils/utils';

const renderDuration = metrics => {
  if (!metrics) {
    return null;
  }
  const { durationTasks, durationPreparation } = metrics;
  const duration =
    ((durationTasks && durationTasks.value) || 0) + ((durationPreparation && durationPreparation.value) || 0);
  return <small>{formatDuration(duration)}</small>;
};

const FinishedCellContent = ({ finishedTimestamp, metrics, locale }) => {
  return (
    <div className="applicant-finished-timestamp">
      <span>
        {moment(finishedTimestamp)
          .locale(locale)
          .format('L')}
      </span>
      <span className="text-sz-reg-sm text-muted">
        {moment(finishedTimestamp)
          .locale(locale)
          .format('LT')}
      </span>
      {metrics && <div>{renderDuration(metrics)}</div>}
    </div>
  );
};

FinishedCellContent.propTypes = {
  finishedTimestamp: PropTypes.number.isRequired,
  metrics: PropTypes.object,
  locale: PropTypes.string.isRequired
};

export default FinishedCellContent;
