import React from 'react';
import _map from 'lodash/map';

import Toggle from 'material-ui/Toggle';

import MetricIcon from '../icons/Metric';

import withIntl from '../withIntl';

const CoreMetricsControl = props => {
  const { metrics, core, onToggle } = props;
  return (
    <div className="container-flex-row fw-yes">
      {_map(metrics, m => {
        const active = !!core[m.key];
        return (
          <div style={{ padding: '2px' }} key={m.key}>
            <div
              className={`container-flex-row ai-center mui-padded-half`}
              style={{ borderRadius: '2px', background: active ? 'rgba(0, 0, 0, 0.08)' : 'rgba(0, 0, 0, 0.04)' }}
            >
              <MetricIcon metric={m} size={24} color={active ? 'rgba(0, 0, 0, 0.36)' : 'rgba(0, 0, 0, 0.12)'} />
              <div
                className={`flex1 ${active ? 'text-normal' : 'text-muted'}`}
                style={{ padding: '0 8px', fontSize: '13px' }}
              >
                {m.label}
              </div>
              <div>
                <Toggle toggled={active} onToggle={() => onToggle(m)} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withIntl(CoreMetricsControl);
