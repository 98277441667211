import React from 'react';
import PropTypes from 'prop-types';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import filter from 'lodash/filter';

import Checkbox from 'material-ui/Checkbox';

import SimpleModal from './SimpleModal';
import SelectOverlay from '../select/SelectOverlay';
import ProjectTypeBadge from '../project/ProjectTypeBadge';
import Chip from '../mui/Chip';

import { formatMessage, selectQuantityMessage } from '../../utils/utils';
import { EParticipantBatchOperation } from '../../constants/enum';

const renderParticipantName = ({ participant: { firstName, lastName, email } }) => {
  if (lastName || firstName) {
    return `${lastName} ${firstName} (${email})`;
  }
  if (email) {
    return `N/A (${email})`;
  }
  return 'N/A';
};

const canSelectProject = type => type === EParticipantBatchOperation.COPY || type === EParticipantBatchOperation.MOVE;

class ParticipantBatchOperation extends React.Component {
  constructor(props) {
    super(props);
    const participants = props.participants || [];
    this.state = {
      checked: reduce(
        participants,
        (res, p) => {
          res[this.getParticipantId(p)] = true;
          return res;
        },
        {}
      ),
      checkedCount: participants.length,
      projectSelectOpen: canSelectProject(props.type),
      targetProject: null
    };
  }

  getParticipantId = p => `${p.projectId}/${p.participant.id}`;

  handleParticipantToggle = p => {
    const { checked, checkedCount } = this.state;
    const pId = this.getParticipantId(p);
    const { [pId]: value, ...rest } = checked;
    rest[pId] = !value;
    this.setState({ checked: rest, checkedCount: checkedCount + (value ? -1 : 1) });
  };
  handleSelectProject = p => this.setState({ targetProject: p, projectSelectOpen: false });
  handleCloseProjectSelection = () => this.setState({ projectSelectOpen: false });
  handleOpenProjectSelection = () => this.setState({ projectSelectOpen: true });

  render() {
    const { id, intl, type, sourceProject, participants, projects, gridOptions } = this.props;
    const {
      switchModal,
      getCompanies,
      getProjectsAll,
      getResults,
      showSnackbar,
      executeProjectParticipantsBatchAction
    } = this.props;

    const messages = intl.messages.components.modals.participantBatchOperation;
    const typeMessages = messages[type.key];

    const { checked, checkedCount, projectSelectOpen, targetProject } = this.state;
    const targetProjectRequired = canSelectProject(type);

    if (projectSelectOpen) {
      return (
        <SelectOverlay
          items={projects}
          messages={{ searchPlaceholder: typeMessages.searchPlaceholder }}
          filterFunction={(p, search) => p.name.toLowerCase().indexOf(search) > -1}
          itemRenderer={p => (
            <span>
              {p.id === sourceProject.id ? (
                <em>{typeMessages.thisProjectLabel}</em>
              ) : (
                <div>
                  <div>{`${p.company.name} - ${p.name}`}</div>
                  <div className="small" style={{ marginTop: '4px' }}>
                    {p.activity.name}
                    <div style={{ display: 'inline-block', verticalAlign: 'middle', margin: '0 8px' }}>
                      <Chip iconLeft={'language'} variant="mini">
                        {p.activity.language.toUpperCase()}
                      </Chip>
                    </div>
                    <ProjectTypeBadge project={p} />
                  </div>
                </div>
              )}
            </span>
          )}
          onClose={this.handleCloseProjectSelection}
          onSelect={this.handleSelectProject}
        />
      );
    }

    return (
      <SimpleModal
        open
        id={id}
        callback={confirmed => {
          if (confirmed) {
            const participantIds = map(
              filter(participants, p => checked[this.getParticipantId(p)]),
              p => ({
                userId: p.participant.id,
                projectId: p.projectId
              })
            );
            executeProjectParticipantsBatchAction(
              type,
              sourceProject.id,
              participantIds,
              targetProject && targetProject.id
            ).then(() => {
              getCompanies(true).then(companies => getProjectsAll(companies.items));
              if (type !== EParticipantBatchOperation.COPY) {
                getResults(sourceProject.company.slug, sourceProject.slug, intl.locale, gridOptions, true);
              }
              const messageTemplate = selectQuantityMessage(typeMessages.snackbarMessage, participantIds.length);
              const message = messageTemplate
                .replace('{0}', participantIds.length)
                .replace('{1}', targetProject ? targetProject.name : 'N/A');
              showSnackbar({ id: `participantBatchOperation-${type}`, message });
            });
          }
        }}
        switchModal={switchModal}
        confirmLabel={typeMessages.confirmButton}
        confirmDisabled={checkedCount <= 0 || (targetProjectRequired && !targetProject)}
        cancelLabel={typeMessages.cancelButton}
        autoScrollBodyContent
        message={
          <div>
            <p>
              {formatMessage(typeMessages.message, [
                <b key="0">{sourceProject.name}</b>,
                <b key="1">{targetProjectRequired && (targetProject ? targetProject.name : <em>N/A</em>)}</b>
              ])}
              {targetProjectRequired && (
                <span style={{ fontSize: '13px' }}>
                  {' ('}
                  <a role="button" onClick={this.handleOpenProjectSelection}>
                    {typeMessages.changeProjectLabel}
                  </a>
                  {')'}
                </span>
              )}
            </p>
            <div style={{ margin: '16px -24px 8px' }}>
              <div
                style={{
                  background: 'rgba(0,0,0,0.05)',
                  marginTop: '16px',
                  padding: '8px 0'
                }}
              >
                {map(participants, p => {
                  const pId = this.getParticipantId(p);
                  return (
                    <div key={pId}>
                      <label
                        className="container-flex"
                        htmlFor={`cb-${pId}`}
                        style={{
                          fontSize: '14px',
                          fontWeight: '400',
                          margin: 0,
                          cursor: 'pointer',
                          padding: '8px 24px'
                        }}
                      >
                        <div>
                          <Checkbox
                            id={`cb-${pId}`}
                            onCheck={() => this.handleParticipantToggle(p)}
                            checked={checked[pId]}
                          />
                        </div>
                        <div className="flex1">
                          <div>{renderParticipantName(p)}</div>
                          {p.project && <div className="small text-muted">{p.project.name}</div>}
                        </div>
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="small" style={{ marginTop: '8px', padding: '0 24px' }}>
                {typeMessages.participantListHelper}
              </div>
              {targetProject && !targetProject.view && typeMessages.operationWarning && (
                <div style={{ margin: '16px 16px 0' }}>
                  <div className="mui-padded border-radius bg-warning text-warning small">
                    <div dangerouslySetInnerHTML={{ __html: typeMessages.operationWarning }} />
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      />
    );
  }
}

ParticipantBatchOperation.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.any.isRequired,
  sourceProject: PropTypes.object.isRequired,
  participants: PropTypes.array.isRequired,
  targetProject: PropTypes.object,
  intl: PropTypes.object.isRequired,
  switchModal: PropTypes.func.isRequired
};

export default ParticipantBatchOperation;
