import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router';

import HomeSectionHeader from './HomeSectionHeader';
import ProjectTypeBadge from '../project/ProjectTypeBadge';
import ProjectCardSummary from '../cards/ProjectCardSummary';
import Button from '../mui/Button';
import EmptySectionMessage from './EmptySectionMessage';
import IconButton from '../mui/IconButton';

import withIntl from '../withIntl';

import { reloadProjects } from '../../actions/entities/projectActions';
import ProjectGroupLabel from '../projects/ProjectGroupLabel';
import { selectAccountPermissions } from '../../selectors/auth';
import Overlay from '../basic/Overlay';

const _mapOnly = (items, limit, transform) => {
  const max = Math.min(limit, items.length);
  const res = [];
  for (let i = 0; i < max; i += 1) {
    res.push(transform(items[i], i, items, limit));
  }
  return res;
};

const LIMIT = 5;
const CREATE_PROJECT_LINK = '/projects/new';

class ProjectsHomeContainer extends React.Component {
  state = {
    moreShown: false
  };

  componentDidMount() {
    const { infinite, reloadProjects } = this.props;
    if (!infinite) {
      reloadProjects();
    }
  }

  handleShowMore = () => this.setState({ moreShown: true });
  handleHideMore = () => this.setState({ moreShown: false });

  render() {
    const {
      infinite,
      projects,
      auth,
      intl: { messages }
    } = this.props;
    const { moreShown } = this.state;
    return (
      <div>
        <HomeSectionHeader
          icon={'assignment_ind'}
          title={messages.title}
          menu={
            projects.length > 0 &&
            auth.canEditProjects && (
              <IconButton
                style={{ width: '24px', height: '24px' }}
                containerElement={<Link to={CREATE_PROJECT_LINK} />}
                tooltip={messages.addLabel}
              >
                <i className="material-icons text-muted" style={{ fontSize: '20px', lineHeight: '24px' }}>
                  add
                </i>
              </IconButton>
            )
          }
        />
        <div>
          {projects.length > 0 ? (
            <div className="mui-padded">
              {_mapOnly(projects, infinite ? projects.length : LIMIT, p => (
                <Link key={p.id} to={`/projects/overview/${p.company.slug}/${p.slug}`} className="project-home-link">
                  <div className="container-flex-row ai-center jc-space-between mui-padded-half mui-margin bg-white border-radius text-normal">
                    <div className="mui-padded-half">
                      <div className="container-flex-row fw-yes ai-center" style={{ margin: '0 -8px' }}>
                        <div className="mui-margin-left mui-margin-right-half">{p.name}</div>
                        <div className="mui-margin-left-half mui-margin-right">
                          <ProjectTypeBadge project={p} compact />
                        </div>
                        {p.group && (
                          <div className="mui-margin-horizontal">
                            <ProjectGroupLabel project={p} group={p.group} groupMembership={p.groupMembership} />
                          </div>
                        )}
                      </div>
                      <div className="text-sz-reg-sm text-muted">{p.activity.name}</div>
                    </div>
                    <div>
                      <ProjectCardSummary project={p} chartSize={38} infoInTooltip />
                    </div>
                  </div>
                </Link>
              ))}
              <div className="container-flex-row ai-center jc-space-between mui-margin-horizontal">
                <div className="mui-padded">
                  {!infinite && projects.length > LIMIT && (
                    <a role="button" className="link-button" onClick={this.handleShowMore}>
                      <span>{messages.showMoreLabel}</span>
                    </a>
                  )}
                </div>
                <div className="text-right">
                  <Button
                    primary
                    labelStyle={{ fontSize: '12px' }}
                    containerElement={<Link to={'/projects'} />}
                    icon={<i className="material-icons text-sz-lg">arrow_forward</i>}
                    label={messages.goToProjectsLabel}
                    labelPosition="before"
                  />
                </div>
              </div>
            </div>
          ) : (
            <EmptySectionMessage
              header={messages.emptyMessage}
              message={messages.infoMessage}
              actionLabel={auth.canEditProjects && messages.addLabel}
              actionLink={auth.canEditProjects && CREATE_PROJECT_LINK}
            />
          )}
        </div>
        {!infinite && moreShown && (
          <Overlay onRequestClose={this.handleHideMore} noBlur maxWidth={'480px'}>
            <ProjectsHomeContainer {...this.props} infinite />
          </Overlay>
        )}
      </div>
    );
  }
}

const baseSelectCompany = (state, props) => props.company;
const baseSelectProjects = state => state.entities.projects;

const localSelectCompanyProjects = createSelector([baseSelectCompany, baseSelectProjects], (company, projects) => {
  if (!company || !projects) {
    return {};
  }
  const res = [];
  for (let i = 0; i < projects.length; i += 1) {
    const p = projects[i];
    if (p.company.id === company.id) {
      res.push(p);
    }
  }
  res.sort((a, b) => {
    const alp = a.activity.latestProfileAddedAt;
    const blp = b.activity.latestProfileAddedAt;
    return alp > blp ? -1 : alp < blp ? 1 : 0;
  });
  return res;
});

const intlMessages = {
  cs: {
    showMoreLabel: 'Více...',
    title: 'Assessmenty',
    emptyMessage: 'Nemáte zatím žádné assessmentové projekty',
    infoMessage:
      'Slouží k přístupu účastníků ke konkrétní <b>jedné</b> aktivitě (simulační hra, chatbot...). Můžete je vytvářet přímo, nebo jako součást assessmentů pro týmy, skupiny a otevřené pozice - ty všechny můžou obsahovat profily s více aktivitami a tím pádem být navázany na více assessmentových projektů.',
    goToProjectsLabel: 'Podrobný přehled',
    addLabel: 'Vytvořit'
  },
  sk: {
    showMoreLabel: 'Viac...',
    title: 'Assessmenty',
    emptyMessage: 'Nemáte zatiaľ žiadne assessmentové projekty',
    infoMessage:
      'Slúžia na prístup účastníkov do konkrétnej <b>jednej</b> aktivity (simulačná hra, chatbot...). Môžete ich vytvárať priamo, alebo ako súčasť assessmentov pre tímy, skupiny a otvorené pozície - tie všetky môžu obsahovať profily s viacerými aktivitami a tým pádom byť naviazané na viac assessmentových projektov.',
    goToProjectsLabel: 'Podrobný prehľad',
    addLabel: 'Vytvoriť'
  },
  en: {
    showMoreLabel: 'More...',
    title: 'Assessments',
    emptyMessage: 'No assessments projects yet',
    infoMessage:
      'They are used for participants to access a specific <b>single</b> activity (simulation game, chatbot...). You can create them directly or as part of assessments for teams, groups and open positions - all of which can contain profiles with multiple activities and thus be linked to multiple assessment projects.',
    goToProjectsLabel: 'Detailed overview',
    addLabel: 'Create'
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    projects: localSelectCompanyProjects(state, ownProps),
    auth: selectAccountPermissions(state, ownProps)
  };
};

const actions = { reloadProjects };

export default withIntl(connect(mapStateToProps, actions)(ProjectsHomeContainer), intlMessages);
