import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ProjectForm from '../forms/ProjectForm';
import BaseContainer from '../basic/BaseContainer';

const ProjectEditContainer = props => {
  const { companyId, projectId } = props;

  return (
    <div>
      <BaseContainer>
        <div className="mui-card" style={{ maxWidth: '768px', margin: '0 auto' }}>
          <ProjectForm companyId={companyId} projectId={projectId} />
        </div>
      </BaseContainer>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { projectId, companyId } = ownProps.params || {};
  return {
    projectId,
    companyId
  };
};

export default withRouter(connect(mapStateToProps)(ProjectEditContainer));
