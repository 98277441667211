import React from 'react';
import withIntl from '../withIntl';

const WeightLevelLabel = props => {
  const {
    level,
    weight,
    extended,
    className,
    intl: { messages }
  } = props;
  if (!level) {
    return null;
  }
  const levelMsg = messages.levels[level.value.key];
  return (
    <span className={className}>
      {(extended && levelMsg.weightLabel) || levelMsg.label}
      {extended && !level.exact && (
        <span>
          {' '}
          <small>({weight.value.toFixed(1)})</small>
        </span>
      )}
    </span>
  );
};

const intlMessages = {
  cs: {
    levels: {
      OFF: {
        label: 'Vypnuto'
      },
      LOW: {
        label: 'Nízká',
        weightLabel: 'Nízká váha'
      },
      MEDIUM: {
        label: 'Střední',
        weightLabel: 'Střední váha'
      },
      HIGH: {
        label: 'Vysoká',
        weightLabel: 'Vysoká váha'
      },
      MAX: {
        label: 'Max',
        weightLabel: 'Maximální váha'
      }
    }
  },
  sk: {
    levels: {
      OFF: {
        label: 'Vypnuté'
      },
      LOW: {
        label: 'Nízka',
        weightLabel: 'Nízka váha'
      },
      MEDIUM: {
        label: 'Strední',
        weightLabel: 'Stredná váha'
      },
      HIGH: {
        label: 'Vysoká',
        weightLabel: 'Vysoká váha'
      },
      MAX: {
        label: 'Max',
        weightLabel: 'Maximálna váha'
      }
    }
  },
  en: {
    levels: {
      OFF: {
        label: 'Off'
      },
      LOW: {
        label: 'Low',
        weightLabel: 'Low weight'
      },
      MEDIUM: {
        label: 'Medium',
        weightLabel: 'Medium weight'
      },
      HIGH: {
        label: 'High',
        weightLabel: 'High weight'
      },
      MAX: {
        label: 'Max',
        weightLabel: 'Max weight'
      }
    }
  }
};

export default withIntl(WeightLevelLabel, intlMessages);
