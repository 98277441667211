import React from 'react';
import _map from 'lodash/map';

import Checkbox from 'material-ui/Checkbox';
import RadioButton from 'material-ui/RadioButton';

import ButtonToggle from '../mui/ButtonToggle';
import MetricIcon from '../icons/Metric';
import InfoTooltip from '../basic/InfoTooltip';

const ActivityToggle = props => {
  const { active, activity, type } = props;
  const { onToggle } = props;
  const { label, name, description, summary, sampleMetrics } = activity;
  return (
    <ButtonToggle active={active} onToggle={() => onToggle(activity)} className="activity-toggle flex-grow">
      <div className="mui-padded-x2 activity-toggle__container container-flex-row">
        {type === 'checkbox' && (
          <div>
            <Checkbox checked={active} iconStyle={active ? { fill: 'white' } : {}} />
          </div>
        )}
        {type === 'radio' && (
          <div>
            <RadioButton checked={active} iconStyle={active ? { fill: 'white' } : {}} />
          </div>
        )}
        <div className="flex1">
          <div className="activity-toggle__label tooltip-element">
            <span>{label}</span>
          </div>
          <div className="activity-toggle__description">
            <span>{description}</span>
          </div>
          <div className="activity-name__chip">
            <span>{name}</span>
          </div>
        </div>
        <div className="mui-padded-vertical-half mui-padded-left-half">
          <InfoTooltip
            content={
              <div>
                <div className="activity-toggle__summary">
                  <span>{summary}</span>
                </div>
                <div className="activity-toggle__sample-metrics-labels text-normal">
                  {_map(sampleMetrics, m => (
                    <div key={m.key} className="mui-margin-half">
                      <MetricIcon metric={m} color={'rgba(0, 0, 0, 0.32)'} size={20} />
                      <span className="mui-margin-horizontal" style={{ textTransform: 'uppercase' }}>
                        {m.label}
                      </span>
                    </div>
                  ))}
                  <div className="mui-margin-half">&hellip;</div>
                </div>
              </div>
            }
          >
            <i className="material-icons" style={{ fontSize: '16px', opacity: 0.5 }}>
              help
            </i>
          </InfoTooltip>
        </div>
      </div>
    </ButtonToggle>
  );
};

export default ActivityToggle;
