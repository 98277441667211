import React from 'react';
import map from 'lodash/map';

import IconButton from './IconButton';

const ToggleIconButtonGroup = props => {
  const { onClick, buttons, style } = props;
  const activeValue = props.value;
  return (
    <div style={{ display: 'inline-block', background: 'white', borderRadius: '2px', ...style }}>
      {map(buttons, ({ value, icon, tooltip }, idx) => (
        <div
          key={idx}
          style={{
            display: 'inline-block',
            background: activeValue === value ? 'rgba(0,0,0,0.12)' : 'transparent',
            borderRadius: '2px',
            margin: `6px 6px 6px ${idx === 0 ? '6px' : '0'}`
          }}
        >
          <IconButton
            onClick={() => onClick(value)}
            style={{ borderRadius: '2px' }}
            tooltip={tooltip}
            tooltipPosition="bottom"
          >
            <i className="material-icons">{icon}</i>
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default ToggleIconButtonGroup;
