import React from 'react';
import _map from 'lodash/map';

import MetricIcon from '../icons/Metric';
import SignIcon from '../icons/Sign';

const CharacteristicsItem = ({ data, showInterpretation }) => {
  const { color, label, rank, formattedValue, interpretation } = data;

  return (
    <div className="applicant-characteristics-item mui-padded">
      <div className="container-flex-row ai-center mui-padded-half">
        <div className="mui-padded-horizontal mui-padded-vertical-half">
          <div style={{ position: 'relative' }}>
            <MetricIcon metric={data} size={24} color={color} />
            <SignIcon
              rank={rank}
              size={13}
              style={{
                position: 'absolute',
                top: -6,
                right: -6,
                background: 'white',
                borderRadius: '50%',
                color
              }}
            />
          </div>
        </div>
        <div className="mui-padded-horizontal mui-padded-vertical-half">
          <strong style={{ lineHeight: '1.2em' }}>{label}</strong>
        </div>
        <div className="mui-padded-half">
          <span style={{ color, lineHeight: '1.2em' }}>{formattedValue}</span>
        </div>
      </div>
      {showInterpretation &&
        interpretation && (
          <div className="mui-padded">
            {interpretation.text && <p>{interpretation.text}</p>}
            <ul style={{ color: color }}>
              {_map(interpretation.bullets || [], (item, i) => (
                <li key={i}>
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
};

export default CharacteristicsItem;
