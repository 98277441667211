import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import map from 'lodash/map';

import Avatar from 'material-ui/Avatar';
import IconButton from 'material-ui/IconButton';
import Toggle from 'material-ui/Toggle';

import Draggable from './Draggable';
import DialogueTextEditor from './DialogueTextEditor';
import { checkForUpdate } from '../../../../utils/utils';
import { ESimulationVariableOperation } from '../../../../constants/enum';

const overlayZ = 100;

const renderVariableOperation = (vo, key, node) => {
  const op = ESimulationVariableOperation[vo.operation];
  const value = op === ESimulationVariableOperation.ASSIGN_RESULT ? node.text : vo.value;
  return (
    <div key={key} className="designer-node-variable-operation">
      <code>{vo.variable}</code>
      <i className="material-icons">keyboard_backspace</i>
      {(op === ESimulationVariableOperation.ADD || op === ESimulationVariableOperation.MULTIPLY) && (
        <code>{vo.variable}</code>
      )}
      {op === ESimulationVariableOperation.ADD && <span className="designer-node-math-operation">+</span>}
      {op === ESimulationVariableOperation.MULTIPLY && <span className="designer-node-math-operation">x</span>}
      {op === ESimulationVariableOperation.ASSIGN_RESULT ? (
        node.free ? (<em>Free text</em>) : (<span dangerouslySetInnerHTML={{ __html: node.text }} />)
      ) : (
        op === ESimulationVariableOperation.ASSIGN_VARIABLE ? <code>{value}</code> : <span>{value}</span>
      )}
    </div>
  );
};

const TYPES = [
  {
    key: 'DIALOGUE_REPLY',
    icon: 'reply',
    canBeAdded: node => node.type === 'DIALOGUE_IMPULSE',
    isEnabled: node => node.computed.solitaryChildren === 0
  },
  {
    key: 'DIALOGUE_IMPULSE',
    icon: 'add',
    canBeAdded: () => true,
    isEnabled: node => node.computed.out === 0
  }
];

class CanvasNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return checkForUpdate(this.props, nextProps) || checkForUpdate(this.state, nextState);
  }

  onFocus = () => {
    this.setState({ expanded: true });
  };

  onBlur = () => {
    this.setState({ expanded: false });
  };

  render() {
    const { node, linkingNode, messages } = this.props;
    const { expanded } = this.state;
    const {
      onLinkFinish,
      onNodeAdd,
      onLinkStart,
      onTextChange,
      onNodeRemove,
      onNodeSettings,
      onPropertyChange
    } = this.props;
    const n = node;
    const position = n.g.position;
    const containerProps = {
      style: { ...position }
    };
    const valid = n.computed.in > 0 || n.computed.initial;
    const btnSize = 32;
    const btnPadding = 0;
    const isImpulse = n.type === 'DIALOGUE_IMPULSE';
    const btnStyle = { width: btnSize, height: btnSize, padding: btnPadding };
    const size = expanded ? { width: 320, height: 320 } : n.g.size;

    let linkingNodeOverlay = false;
    if (linkingNode && isImpulse) {
      linkingNodeOverlay = (
        <div className="designer-node-linking-overlay">
          <i className="material-icons" style={{
            width: 48,
            height: 48,
            marginLeft: (size.width - 48) / 2,
            marginTop: (size.height - 48) / 2,
            color: 'rgba(33,33,33,0.54)'
          }}>link</i>
        </div>
      );
      containerProps.style.zIndex = overlayZ + 1;
      containerProps.onClick = e => {
        onLinkFinish(n);
        e.preventDefault();
      };
    }
    return (
      <div
        className={`designer-node designer-node-${n.type} designer-node-${expanded ? 'expanded' : 'collapsed'}${valid
          ? ''
          : ' designer-node-invalid'}`}
        {...containerProps}
      >
        <div className="designer-node-id">
          <span>{n.id}</span>
          {n.type === 'DIALOGUE_REPLY' && (
            <div>
              <Toggle toggled={n.free} label={'Free'} onToggle={() => onPropertyChange(n.id, 'free', !n.free)} />
            </div>
          )}
          {n.type === 'DIALOGUE_IMPULSE' && (
            <div>
              <Toggle toggled={n.empty} label={messages.emptyLabel} onToggle={() => onPropertyChange(n.id, 'empty', !n.empty)} />
            </div>
          )}
          {!n.computed.initial && (
            <div className="designer-node-remove-button">
              <IconButton onClick={() => onNodeRemove(n)} style={btnStyle}>
                <i className="material-icons">close</i>
              </IconButton>
            </div>
          )}
        </div>
        <div className="designer-node-tools">
          {map(filter(TYPES, ({ canBeAdded }) => canBeAdded(n)), ({ key, icon, isEnabled }) => (
            <IconButton key={key} onClick={() => onNodeAdd(n, key)} style={btnStyle} disabled={!isEnabled(n)}>
              <i className="material-icons">{icon}</i>
            </IconButton>
          ))}
          <IconButton onClick={() => onLinkStart(n)} style={btnStyle} disabled={n.computed.out > 1}>
            <i className="material-icons">link</i>
          </IconButton>
          <IconButton onClick={() => onNodeSettings(n)} style={btnStyle}>
            <i className="material-icons">build</i>
          </IconButton>
        </div>
        {node.data &&
          node.data.variableOperations &&
          node.data.variableOperations.length > 0 && (
            <div className="designer-node-variable-operations">
              <div className="relative-container">
                {map(node.data.variableOperations, (vo, idx) => renderVariableOperation(vo, idx, node))}
                {node.data.variableOperations.length > 1 && (
                  <div className="designer-node-variable-operation more">
                    <code>...</code>
                  </div>
                  )}
              </div>
            </div>
          )}
        <Draggable id={n.id} position={position} size={size}>
          <div className="designer-node-content" style={size}>
            {isImpulse && (
              <div className="designer-node-header">
                <div className="designer-node-title-container">
                  <Avatar
                    style={{ backgroundColor: 'none', position: 'absolute', top: 0, left: 0 }}
                    src="/img/editor/bibi.png"
                  />
                  <div className="designer-node-title">{messages.defaultPersonName}</div>
                  <div className="designer-node-subtitle">{messages.defaultPersonInfo}</div>
                </div>
              </div>
            )}
            {n.type === 'DIALOGUE_REPLY' && (
              <div className="designer-node-header">
                <i className="material-icons">reply</i>
              </div>
            )}
            {!n.free && !n.empty && (
              <div className="designer-node-body">
                <div className="designer-node-body-arrow" />
                <div className="designer-node-body-arrow-over" />
                <DialogueTextEditor
                  value={n.text}
                  onChange={val => onTextChange(n.id, val)}
                  expanded={expanded}
                  handleFocus={this.onFocus}
                  handleBlur={this.onBlur}
                />
              </div>
            )}
          </div>
        </Draggable>
        {linkingNodeOverlay}
      </div>
    );
  }
}

CanvasNode.propTypes = {
  node: PropTypes.object.isRequired,
  linkingNode: PropTypes.object,
  messages: PropTypes.object,
  onLinkFinish: PropTypes.func.isRequired,
  onLinkStart: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onNodeRemove: PropTypes.func.isRequired,
  onNodeAdd: PropTypes.func.isRequired
};

export default CanvasNode;
