import React from 'react';
import { filter, map } from 'lodash';

import RaisedButton from 'material-ui/RaisedButton';

import { EPricePackage, getEnumOrdered } from '../../constants/enum';
import { selectQuantityMessage, formatMessage } from '../../utils/utils';

const formatHtml = (value, strikethrough, bold) => {
  let res = <span>{value}</span>;
  if (bold) {
    res = <strong>{res}</strong>;
  }
  if (strikethrough) {
    res = <s>{res}</s>;
  }
  return res;
};

const PackageFeature = ({ label, value, strikethrough, bold }) => (
  <div className="price-list-package-feature">
    <div className="price-list-package-feature-label">
      <span>{label}</span>
    </div>
    <div className="price-list-package-feature-value">{formatHtml(value, strikethrough, bold)}</div>
  </div>
);

const getResponsiveClasses = itemCount => {
  if (itemCount <= 1) {
    return 'col-xs-12';
  }
  if (itemCount === 2) {
    return 'col-xs-6';
  }
  if (itemCount === 3) {
    return 'col-xs-6 col-sm-4';
  }
  if (itemCount === 4) {
    return 'col-xs-6 col-md-3';
  }
};

const formatMonths = (m, messages) => {
  const years = Math.floor(m / 12);
  const months = m - years * 12;
  const yearsMsg = years ? selectQuantityMessage(messages.yearFormat, years) : '';
  const monthsMsg = months ? selectQuantityMessage(messages.monthFormat, months) : '';
  return (
    <span>
      {yearsMsg && formatMessage(yearsMsg, [years])}
      {yearsMsg && monthsMsg && ' '}
      {monthsMsg && formatMessage(monthsMsg, [months])}
    </span>
  );
};

const PriceList = ({ messages, priceList, selectPackage }) => {
  if (!priceList || !priceList.packages) {
    return null;
  }
  const availablePackages = filter(getEnumOrdered(EPricePackage), ({ id }) => priceList.packages[id]);
  return (
    <div className="price-list row">
      {map(availablePackages, ({ id }) => {
        const pkg = priceList.packages[id];
        const discount = parseFloat(pkg.creditPriceDiscount) || null;
        return (
          <div key={id} className={`price-list-package ${getResponsiveClasses(availablePackages.length)}`}>
            <div className="price-list-package-container">
              <div className="price-list-package-name">
                <span>{id}</span>
              </div>
              <PackageFeature label={messages.creditAmount} value={pkg.creditAmount} bold />
              <PackageFeature label={messages.creditPrice} value={`${parseFloat(pkg.creditPrice).toFixed(2)} €`} />
              {discount && <PackageFeature label={messages.creditPriceDiscount} value={`-${discount.toFixed(2)} €`} />}
              <PackageFeature label={messages.numberOfTODProfiles} value={(pkg.creditAmount / 5).toFixed(0)} />
              <PackageFeature label={messages.expiry} value={formatMonths(pkg.expiryInMonths, messages)} />
              <PackageFeature
                label={messages.totalPrice}
                value={`${(parseFloat(pkg.creditPrice) * pkg.creditAmount).toFixed(2)} €`}
                strikethrough={!!discount}
                bold={!discount}
              />
              {discount && (
                <PackageFeature
                  label={messages.discountedPrice}
                  value={`${(parseFloat(pkg.creditPrice - discount) * pkg.creditAmount).toFixed(2)} €`}
                  bold
                />
              )}
              <div className="price-list-package-top-up-button">
                <RaisedButton
                  label={messages.topUpButton}
                  primary
                  fullWidth
                  buttonStyle={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                  onClick={() => selectPackage(id, pkg)}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PriceList;
