import React, { useState, useRef } from 'react';

import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import Menu from 'material-ui/Menu';

import Button from '../mui/Button';
import IconButton from '../mui/IconButton';
import MenuItemText from '../mui/MenuItemText';
import Popover from '../mui/Popover';

import withIntl from '../withIntl';

const TeamSubGroupSelectionMenu = props => {
  const {
    intl: { messages },
    readOnly,
    onHighlight,
    onRemove,
    onGroupCreate,
    onCopy,
    onMove,
    onTag
  } = props;
  const [menuShown, showMenu] = useState(false);
  const btn = useRef(null);
  return (
    <div className="container-flex-row ai-center fw-yes">
      <Button
        primary
        label={messages.highlightLabel}
        onClick={onHighlight}
        icon={<i className="material-icons text-sz-lg">lightbulb_outline</i>}
        mini
      />
      {!readOnly && (
        <div>
          <div ref={btn}>
            <IconButton primary onClick={() => showMenu(true)}>
              <i className="material-icons text-sz-lg">more_vert</i>
            </IconButton>
          </div>
          <Popover open={menuShown} anchorEl={btn?.current} onRequestClose={() => showMenu(false)}>
            <Menu desktop onItemClick={() => showMenu(false)}>
              <MenuItem
                primaryText={<MenuItemText label={messages.newGroupLabel} description={messages.newGroupDescription} />}
                leftIcon={
                  <i className="material-icons" style={{ marginTop: '12px' }}>
                    add
                  </i>
                }
                onClick={onGroupCreate}
              />
              <MenuItem
                primaryText={messages.copyLabel}
                leftIcon={<i className="material-icons">file_copy</i>}
                onClick={onCopy}
              />
              <MenuItem
                primaryText={messages.moveLabel}
                leftIcon={<i className="material-icons">reply</i>}
                onClick={onMove}
              />
              <Divider />
              <MenuItem
                primaryText={messages.tagLabel}
                leftIcon={<span className="mdi mdi-tag-multiple-outline mdi-24px" />}
                onClick={onTag}
              />
              <Divider />
              <MenuItem
                primaryText={messages.removeLabel}
                leftIcon={<i className="material-icons">delete</i>}
                onClick={onRemove}
              />
            </Menu>
          </Popover>
        </div>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    highlightLabel: 'Zvýraznit',
    removeLabel: 'Odstranit',
    copyLabel: 'Kopírovat do skupiny / týmu',
    moveLabel: 'Přesunout do skupiny / týmu',
    newGroupLabel: 'Nová skupina',
    newGroupDescription: 'Vytvořit skupinu z vybraných členů',
    tagLabel: 'Přidat tagy'
  },
  sk: {
    highlightLabel: 'Zvýrazniť',
    removeLabel: 'Odstrániť',
    copyLabel: 'Kopírovať do skupiny / tímu',
    moveLabel: 'Presunúť do skupiny / tímu',
    newGroupLabel: 'Nová skupina',
    newGroupDescription: 'Vytvoriť skupinu z vybraných členov',
    tagLabel: 'Pridať tagy'
  },
  en: {
    highlightLabel: 'Highlight',
    removeLabel: 'Remove',
    copyLabel: 'Copy to group / team',
    moveLabel: 'Move to group / team',
    newGroupLabel: 'New group',
    newGroupDescription: 'Create group from selection',
    tagLabel: 'Add tags'
  }
};

export default withIntl(TeamSubGroupSelectionMenu, intlMessages);
