import React from 'react';
import PropTypes from 'prop-types';
import {
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from 'recharts';
import _map from 'lodash/map';
import _values from 'lodash/values';

import IconPanel from '../IconPanel';
import MultiColorCell from './MultiColorCell';
import MetricTick from './MetricTick';
import TooltipContent from './TooltipContent';

const BUBBLE_SIZE = 42;

const METRIC_AXIS_SIZE = 20;
const SCORE_AXIS_SIZE = 30;
const Z_AXIS_DOMAIN = [0, 1];

const MetricsBubbleChart = props => {
  const {
    data,
    metricsData,
    meanData,
    medianData,
    otherData,
    domain,
    ticks,
    scoreFormatter,
    tickFormatter,
    horizontal,
    mean,
    median
  } = props;
  //
  const bubbleSize = props.bubbleSize || BUBBLE_SIZE;
  const showIcons = props.showIcons != null ? props.showIcons : true;
  const showLabels = props.showLabels != null ? props.showLabels : true;
  const metricAxisPadding = bubbleSize / 2;
  const metricAxisSize = showIcons ? METRIC_AXIS_SIZE : 0;
  const zAxisRange = [0, Math.PI * Math.pow((bubbleSize - 2) / 2, 2)];
  //
  let xAxisProps, yAxisProps, getMetricRefLineProps, getScoreRefLineProps, height, minWidth, maxWidth;
  if (horizontal) {
    height = metricsData.length * bubbleSize + SCORE_AXIS_SIZE;
    minWidth = bubbleSize * 7.72 + metricAxisSize + 2 * metricAxisPadding;
    maxWidth = bubbleSize * 7.72 * 2 + metricAxisSize + 2 * metricAxisPadding;
    xAxisProps = {
      type: 'number',
      dataKey: 'score',
      domain,
      ticks,
      tickFormatter,
      height: SCORE_AXIS_SIZE,
      padding: { left: metricAxisPadding, right: metricAxisPadding }
    };
    yAxisProps = {
      type: 'category',
      dataKey: 'metricIndex',
      allowDuplicatedCategory: false,
      interval: 0,
      width: metricAxisSize,
      tick: showLabels && <MetricTick metrics={metricsData} horizontal />
    };
    getMetricRefLineProps = (m, idx) => ({ y: idx });
    getScoreRefLineProps = t => ({ x: t });
  } else {
    height = bubbleSize * 7.72;
    minWidth = SCORE_AXIS_SIZE + metricsData.length * bubbleSize;
    maxWidth = SCORE_AXIS_SIZE + metricsData.length * bubbleSize * 2;
    xAxisProps = {
      type: 'category',
      dataKey: 'metricIndex',
      allowDuplicatedCategory: false,
      interval: 0,
      height: metricAxisSize,
      tick: showLabels && <MetricTick metrics={metricsData} />
    };
    yAxisProps = {
      type: 'number',
      dataKey: 'score',
      domain,
      ticks,
      tickFormatter,
      width: SCORE_AXIS_SIZE,
      padding: { top: metricAxisPadding, bottom: metricAxisPadding }
    };
    getMetricRefLineProps = (m, idx) => ({ x: idx });
    getScoreRefLineProps = t => ({ y: t });
  }

  return (
    <div className="metrics-bubble-chart">
      <div className="metrics-bubble-chart__container">
        <div style={{ minWidth, maxWidth }} className={horizontal ? 'container-flex-row' : 'container-flex-column'}>
          {horizontal && showIcons && (
            <div style={{ padding: `0 0 ${SCORE_AXIS_SIZE}px 0`, height }}>
              <IconPanel data={metricsData} vertical />
            </div>
          )}
          <div style={{ width: horizontal && showIcons ? 'calc(100% - 24px)' : '100%' }}>
            <ResponsiveContainer height={height}>
              <ScatterChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                {_map(metricsData, (m, idx) => (
                  <ReferenceLine
                    key={`metricRefLine-${idx}`}
                    {...getMetricRefLineProps(m, idx)}
                    strokeDasharray="5 5"
                    stroke="rgba(0,0,0,0.12)"
                  />
                ))}
                {_map(ticks, t => (
                  <ReferenceLine
                    key={`scoreRefLine-${t}`}
                    {...getScoreRefLineProps(t)}
                    strokeDasharray="5 5"
                    stroke="rgba(0,0,0,0.06)"
                  />
                ))}
                <XAxis tickLine={false} axisLine={false} {...xAxisProps} />
                <YAxis tickLine={false} axisLine={false} {...yAxisProps} />
                <ZAxis type="number" dataKey="value" domain={Z_AXIS_DOMAIN} range={zAxisRange} />
                <Scatter
                  data={data}
                  fill="#8884d8"
                  isAnimationActive={false}
                  shape={<MultiColorCell maxSize={bubbleSize} />}
                >
                  {_map(data, b => (
                    <Cell key={`${b.metricKey}-${b.score}`} fill={b.color} stroke="none" />
                  ))}
                </Scatter>
                {mean && (
                  <Scatter
                    className="mean-scatter"
                    line
                    lineJointType="natural"
                    isAnimationActive={false}
                    data={meanData}
                  />
                )}
                {median && (
                  <Scatter
                    className="median-scatter"
                    line
                    lineJointType="natural"
                    isAnimationActive={false}
                    data={medianData}
                  />
                )}
                {otherData &&
                  _map(_values(otherData), ({ id, data, color }) => (
                    <Scatter
                      key={id}
                      line={{ stroke: color, strokeWidth: 1 }}
                      fill="none"
                      lineJointType="natural"
                      isAnimationActive={false}
                      data={data}
                    />
                  ))}
                <Tooltip
                  wrapperStyle={{ zIndex: 100 }}
                  cursor={{ stroke: 'rgba(0,0,0,0.24)', strokeWidth: 1 }}
                  content={<TooltipContent metrics={metricsData} scoreFormatter={scoreFormatter} />}
                  isAnimationActive={false}
                />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
          {!horizontal && showIcons && (
            <div style={{ padding: `0 0 8px ${SCORE_AXIS_SIZE}px`, marginTop: '-3px' }}>
              <IconPanel data={metricsData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MetricsBubbleChart.propTypes = {
  data: PropTypes.array.isRequired,
  metricsData: PropTypes.array.isRequired,
  meanData: PropTypes.array.isRequired,
  medianData: PropTypes.array.isRequired,
  otherData: PropTypes.object,
  domain: PropTypes.array.isRequired,
  ticks: PropTypes.array.isRequired,
  scoreFormatter: PropTypes.func,
  tickFormatter: PropTypes.func,
  horizontal: PropTypes.bool,
  mean: PropTypes.bool,
  median: PropTypes.bool,
  bubbleSize: PropTypes.number
};

export default MetricsBubbleChart;
