import React from 'react';

const MatchSectionDivider = () => {
  return (
    <div style={{ padding: '0 16px' }}>
      <div className="bg-primary-light" style={{ width: '100%', height: '1px' }} />
    </div>
  );
};

export default MatchSectionDivider;
