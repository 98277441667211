import React from 'react';

import FloatingActionButton from 'material-ui/FloatingActionButton';
import Tooltip from '../tooltip/Tooltip';

const FAB = props => {
  const { tooltip, children, position, containerStyle, ...rest } = props;
  const cStyle = { zIndex: 10, ...containerStyle };
  if (position !== 'relative') {
    cStyle.position = 'fixed';
    cStyle.right = '8px';
    cStyle.bottom = '8px';
  }
  return (
    <div style={cStyle} className="tooltip-element">
      <FloatingActionButton {...rest}>{children}</FloatingActionButton>
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </div>
  );
};

export default FAB;
