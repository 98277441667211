export function put(key, value) {
  window.localStorage.setItem(key, value);
}

export function putObject(key, obj) {
  put(key, obj ? JSON.stringify(obj) : null);
}

export function get(key) {
  return window.localStorage.getItem(key);
}

export function getObject(key) {
  const val = get(key);
  if (val) {
    try {
      return JSON.parse(val);
    } catch (err) {
      // ignore
    }
  }
  return null;
}

export function remove(key) {
  return window.localStorage.removeItem(key);
}

export function clear() {
  window.localStorage.clear();
}
