import { authRequest } from './../Request.js';
import { API_URL } from '../../config';

export function login(username, password) {
    return new Promise((resolve, reject) => {
        const grant_type = 'password';

        authRequest(true)
            .post(`${API_URL}/oauth/token`)
            .type('form')
            .send({ username, password, grant_type })
            .end((err, res) => err ? reject(err.response) : resolve(res.body));
    });
}

export function getUser(user_id) {
    return new Promise((resolve, reject) => {
        authRequest(false)
            .get(`${API_URL}/api/v1/users/${user_id}`)
            .end((err, res) => err ? reject(err.response) : resolve(res.body));
    });
}

export function setLanguage(userId, language) {
    return new Promise((resolve, reject) => {
        authRequest(false)
            .post(`${API_URL}/api/v1/users/${userId}/language`)
            .send({ language })
            .end((err, res) => err ? reject(err.response) : resolve(res.body));
    })
}

export function sendEmail(userEmail) {
    return new Promise((resolve, reject) => {
        authRequest(false)
            .post(`${API_URL}/api/v1/users/password/reset`)
            .query({ email: userEmail })
            .end((err, res) => err ? reject(err.response.body) : resolve(res.body));
    });
}

export function resetPassword(password, email, token) {
    return new Promise((resolve, reject) => {
        authRequest(false)
            .post(`${API_URL}/api/v1/users/password`)
            .send({ password, email, token })
            .end((err, res) => err ? reject(err.response.body) : resolve(res.body));
    });
}
