import React from 'react';

import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';

import Button from './Button';

const anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
const targetOrigin = { horizontal: 'right', vertical: 'top' };

class SplitButton extends React.Component {
  state = {
    open: false
  };

  setContainer = el => (this.container = el);
  handleMenuToggle = () => this.setState(({ open }) => ({ open: !open }));
  handleMenuClose = () => this.setState({ open: false });

  render() {
    const { containerElement, style, buttonStyle, labelStyle, children, onClick, ...buttonProps } = this.props;
    return (
      <div className="inline-container">
        <div className="container-flex-row ai-center" ref={this.setContainer}>
          <Button
            {...buttonProps}
            onClick={onClick || this.handleMenuToggle}
            containerElement={containerElement}
            style={{ borderRadius: '2px 0 0 2px', ...style }}
            buttonStyle={buttonProps.raised ? { borderRadius: '2px 0 0 2px', ...buttonStyle } : undefined}
            labelStyle={{ ...labelStyle }}
          />
          <div
            style={{ height: '36px', width: '1px', background: buttonProps.raised ? 'white' : 'rgba(0,0,0,0.08)' }}
          />
          <Button
            {...buttonProps}
            icon={false}
            style={{ borderRadius: '0 2px 2px 0', minWidth: 36, ...style }}
            buttonStyle={buttonProps.raised ? { borderRadius: '0 2px 2px 0', minWidth: 36, ...buttonStyle } : undefined}
            labelStyle={{ ...labelStyle, padding: 0 }}
            label={
              <i className="material-icons" style={{ lineHeight: '36px' }}>
                arrow_drop_down
              </i>
            }
            onClick={this.handleMenuToggle}
          />
        </div>
        <Popover
          open={this.state.open}
          onRequestClose={this.handleMenuClose}
          anchorEl={this.container}
          anchorOrigin={anchorOrigin}
          targetOrigin={targetOrigin}
        >
          <Menu desktop onItemClick={this.handleMenuClose}>
            {children}
          </Menu>
        </Popover>
      </div>
    );
  }
}

export default SplitButton;
