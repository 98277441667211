import {
  PROJECT_OVERVIEW_PART_TOGGLE,
  PROJECT_OVERVIEW_TAB_SET,
  PROJECT_OVERVIEW_CUSTOM_PROP_SET
} from '../../constants/constants';

export const toggleProjectOverviewPart = (projectId, part) => ({
  type: PROJECT_OVERVIEW_PART_TOGGLE,
  payload: { projectId, part }
});
export const setProjectOverviewTab = (projectId, tab) => ({
  type: PROJECT_OVERVIEW_TAB_SET,
  payload: { projectId, tab }
});
export const setProjectOverviewCustomProp = (projectId, key, value) => ({
  type: PROJECT_OVERVIEW_CUSTOM_PROP_SET,
  payload: { projectId, key, value }
});
