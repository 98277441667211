import { authRequest } from '../Request';
import { API_URL } from '../../config';

const DEFAULT_PAGINATION = { size: 1000 };

export function getInsights(groupId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/groups/${groupId}/insights`)
      .query({ ...DEFAULT_PAGINATION })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getInsight(groupId, id) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/groups/${groupId}/insights/${id}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function createInsight(insight) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/groups/${insight.group.id}/insights`)
      .send(insight)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
export function updateInsight(id, insight) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .put(`${API_URL}/api/v1/groups/${insight.group.id}/insights/${id}`)
      .send(insight)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
export function deleteInsight(groupId, id) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .delete(`${API_URL}/api/v1/groups/${groupId}/insights/${id}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
