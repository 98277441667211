import React from 'react';
import { connect } from 'react-redux';

import Card from '../mui/Card';

import { EProjectFilterLayout } from '../../constants/enum';
import { selectWidthXS } from '../../selectors/ui';

const preventDefault = e => e.preventDefault();

const MainActionContainer = ({ children }) => {
  return (
    <div
      className="container-flex-row jc-flex-end ai-center fw-yes mui-padded border-top border-color-primary__50"
      style={{ margin: '8px -8px -8px' }}
      onClick={preventDefault}
    >
      {children}
    </div>
  );
};

const ProjectCardView = props => {
  const { header, status, summary, mainAction, sideActions, layout, widthXS } = props;
  if (layout === EProjectFilterLayout.LIST && !widthXS) {
    return (
      <Card className="project-card">
        <div className="container-flex-row">
          <div className="row flex1" style={{ maxWidth: 'calc(100% - 21px)' }}>
            <div className="col-sm-8 col-md-5">{header}</div>
            <div className="col-sm-4 col-md-3">{summary}</div>
            <div className="col-sm-12 col-md-4 project-card-status-container">{status}</div>
          </div>
          {sideActions && (
            <div
              className="border-left border-color-primary__50 container-flex-column mui-padded"
              onClick={preventDefault}
              style={{ flexFlow: 'column-reverse', margin: '-8px -8px -8px 8px', justifyContent: 'flex-end' }}
            >
              {sideActions}
            </div>
          )}
        </div>
        {mainAction && <MainActionContainer>{mainAction}</MainActionContainer>}
      </Card>
    );
  }
  return (
    <Card className="project-card">
      {header}
      {summary}
      {status}
      {(mainAction || sideActions) && (
        <MainActionContainer>
          {mainAction}
          {sideActions && <div className="mui-margin-left">{sideActions}</div>}
        </MainActionContainer>
      )}
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => {
  return { widthXS: selectWidthXS(state, ownProps) };
};

export default connect(mapStateToProps)(ProjectCardView);
