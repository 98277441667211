import React from 'react';
import PropTypes from 'prop-types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import Sticky from 'react-stickynode';

import theme from '../../_muiTheme';
import Modals from '../Modals';
import Snackbars from '../Snackbars';
import Navbar from '../../../components/navigation/Navbar';
import { decodeToken, saveToken, canAccessProjects, canAccessDesigner, canAccessGroups } from '../../../utils/utils';
import { BRAND } from '../../../constants/constants';

import allActions from '../../../actions';
import { selectAccountPermissions } from '../../../selectors/auth';
import IEUnsupportedPage from '../../../components/navigation/IEUnsupportedPage';

class SimulationDesigner extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    children: PropTypes.any,
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    //
    getUser: PropTypes.func.isRequired
  };

  componentWillMount() {
    const { getUser, router, location, auth } = this.props;
    if (!auth.email) {
      let decodedToken = decodeToken();

      if (location.query.access_token) {
        saveToken(location.query.access_token);
        decodedToken = jwtDecode(location.query.access_token);
      }
      if (isEmpty(decodedToken)) {
        router.push({ pathname: '/login' });
      } else if (canAccessDesigner(decodedToken)) {
        getUser(decodedToken);
      } else if (canAccessProjects(decodedToken) || canAccessGroups(decodedToken)) {
        router.push({ pathname: '/home' });
      } else {
        router.push({ pathname: '/logout' });
      }
    }
  }

  render() {
    const { auth } = this.props;
    return (
      <MuiThemeProvider muiTheme={theme}>
        <div className={`designer app-container brand-${BRAND.key.toLowerCase()}`}>
          <Sticky>
            <IEUnsupportedPage />
            <Navbar sidebarDisabled />
          </Sticky>
          {auth.designerAccess && <div>{this.props.children}</div>}
          <Modals />
          <Snackbars />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({ auth: selectAccountPermissions(state) });

export default withRouter(connect(mapStateToProps, allActions)(SimulationDesigner));
