import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import * as actions from '../../actions/options/snackbarsActions';

import withIntl from '../withIntl';

const TeamInsightLinkCopyToClipboard = props => {
  const { insight, children, onCopy, showSnackbar, intl } = props;
  const host = window.location.origin;
  const path = `/groups/${insight.group.id}?tab=insights&insightId=${insight.id}`;
  const url = `${host}${path}`;

  const linkMessages = intl.messages.components.snackbar.link;
  const snackbarMessage = window.navigator.userAgent.indexOf('Mac') >= 0 ? linkMessages.mac : linkMessages.common;

  const handleCopy = useCallback(() => {
    showSnackbar({ id: url, message: snackbarMessage });
    if (onCopy) {
      onCopy();
    }
  }, [showSnackbar, snackbarMessage, url]);

  return (
    <CopyToClipboard text={url} onCopy={handleCopy}>
      {children}
    </CopyToClipboard>
  );
};

export default withIntl(connect(null, actions)(TeamInsightLinkCopyToClipboard));
