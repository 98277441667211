import React from 'react';
import { connect } from 'react-redux';

import * as dataSourcesActions from '../../actions/options/dataSourcesActions';

class DataSourcesLoader extends React.Component {
  componentDidMount() {
    this.load(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.intl.locale !== this.props.intl.locale) {
      this.load(this.props);
    }
  }

  load = props => {
    props.getAllDataSources(props.intl.locale);
  };

  render() {
    return <div />;
  }
}

const actions = {
  getAllDataSources: dataSourcesActions.getAllDataSources
};

export default connect(({ intl }) => ({ intl }), actions)(DataSourcesLoader);
