import { handleActions } from 'redux-actions';

import * as c from '../../constants/constants';

export const defaultState = {
  chartActive: null,
  sorted: true,
  startIndex: 0,
  endIndex: null
};

export default handleActions(
  {
    [c.OVERVIEW_CHART_SET_ACTIVE]: (state, { payload }) => {
      return {
        ...state,
        chartActive: payload
      };
    },
    [c.OVERVIEW_CHART_SET_SORTED]: (state, { payload }) => {
      return {
        ...state,
        sorted: payload
      };
    },
    [c.OVERVIEW_CHART_SET_WINDOW]: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    }
  },
  defaultState
);
