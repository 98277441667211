import React from 'react';

const formatNumber = number => (typeof number === 'number' ? number.toFixed(0) : number);

const NumberIndicator = props => {
  const { number, numberLabel, present, fallback, rankLabel, colorVariant, children } = props;
  return (
    <div>
      {present && rankLabel && (
        <div className="mui-margin-bottom-half">
          <strong className="text-uppercase">{rankLabel}</strong>
        </div>
      )}
      <div className="container-flex-row">
        {present && typeof number === 'number' && (
          <div
            className={`bg-color-${colorVariant}__100`}
            style={{
              width: '12px',
              marginRight: '8px',
              marginTop: '3px',
              borderRadius: '2px',
              height: '36px',
              paddingTop: `${36 * (1 - number / 100)}px`,
              overflow: 'hidden'
            }}
          >
            <div className={`bg-color-${colorVariant}`} style={{ width: '100%', height: '100%' }} />
          </div>
        )}
        <div
          className={present ? `text-color-${colorVariant}` : 'text-muted'}
          style={{ fontSize: 36, lineHeight: '42px', fontWeight: 500 }}
        >
          {present ? (numberLabel ? numberLabel : formatNumber(number)) : fallback}
        </div>
        <div style={{ marginLeft: '12px' }}>{children}</div>
      </div>
    </div>
  );
};

export default NumberIndicator;
