import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _slice from 'lodash/slice';
import _reduce from 'lodash/reduce';
import _isArray from 'lodash/isArray';
import Sticky from 'react-stickynode';

import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';

import TeamSubGroup from './TeamSubGroup';
import TeamSelect from './TeamSelect';
import TeamConfig from './TeamConfig';
import TeamName from './TeamName';
import TeamOverview from './TeamOverview';
import TeamMemberSelect from './TeamMemberSelect';
import ActivityInviteDialog from './ActivityInviteDialog';
import ActivityRemindDialog from './ActivityRemindDialog';
import TeamDeleteDialog from './TeamDeleteDialog';
import TeamFilter from './TeamFilter';
import MemberRemoveDialog from './MemberRemoveDialog';
import GroupTypeSelect from './GroupTypeSelect';
import DynamicMessage from './DynamicMessage';
import NewGroupDialog from './NewGroupDialog';
import AddToGroupsDialog from './AddToGroupsDialog';
import MembersHighlightSelect from './MembersHighlightSelect';
import TeamInsights from './TeamInsights';
import TargetGroupConnectPanel from './TargetGroupConnectPanel';
import GroupSourcesPanel from './GroupSourcesPanel';
import Overlay from '../basic/Overlay';
import Collapse from '../basic/Collapse';
import HorizontalScrollPane from '../basic/HorizontalScrollPane';
import Tab from '../menu/MenuItem';
import IconButton from '../mui/IconButton';
import ApplicantDetail from '../../containers/private/position/ApplicantDetail';
import GroupMembersTagsAssignmentOverlay from '../tags/GroupMembersTagsAssignmentOverlay';
import Spinner from '../spinner/Spinner';
import Button from '../mui/Button';
import TeamShareDialog from './TeamShareDialog';
import BetaBadge from '../badge/BetaBadge';
import GenerateMembersDialog from './GenerateMembersDialog';

import * as groupActions from '../../actions/entities/groupActions';
import { notifyProfiles } from '../../actions/entities/applicantActions';
import { getGroupUserCompanyTags } from '../../actions/entities/tagActions';
import { openGroupMemberFilter, closeGroupMemberFilter } from '../../actions/options/groupMemberFiltersActions';

import * as Api from '../../api/entities/groupApi';

import { getActivityMapKey, saveLastGroupId, saveTeamState, getGroupState, filterWellBeing } from './TeamUtils';
import { formatMessage } from '../../utils/utils';

import { ETab } from './Constants';
import { EGroupType, EGroupMembership } from '../../constants/enum';
import { HIGHLIGHT_COLORS, HEADER_SELECTOR } from '../../constants/constants';

import { selectFilteredGroupMembers, selectGroupMembersFilter, selectTargetGroup } from './teamMemberSelectors';

const initialState = {
  switching: false,
  profile: null,
  expanded: {},
  highlighted: {},
  // first color reserved for manager
  availableColors: _slice(HIGHLIGHT_COLORS, 1),
  removing: null,
  inviting: null,
  reminding: null,
  creatingGroup: null,
  moving: null,
  tagging: null,
  tab: null
};

const Tabs = ({ currentTab, onChange, messages, loading, wellBeingEnabled }) => {
  const getTabProps = tab => {
    const active = currentTab === tab.key;
    return {
      onClick: () => onChange(tab.key),
      active,
      className: active ? 'border-color-primary__75' : '',
      icon:
        active && loading ? (
          <Spinner relative show size={18} stroke={2} />
        ) : (
          <i className="material-icons">{tab.icon}</i>
        )
    };
  };

  return (
    <HorizontalScrollPane>
      <div className="container-flex-row">
        <Tab label={messages.overview} {...getTabProps(ETab.OVERVIEW)} />
        <Tab label={messages.list} {...getTabProps(ETab.LIST)} />
        <Tab label={messages.insights} {...getTabProps(ETab.INSIGHTS)} />
        {/*<Tab label={messages.analytics} {...getTabProps(ETab.ANALYTICS)} />*/}
        {wellBeingEnabled && (
          <Tab label={messages.wellBeing} badge={<BetaBadge />} {...getTabProps(ETab.WELL_BEING)} />
        )}
      </div>
    </HorizontalScrollPane>
  );
};

const isWellBeingEnabled = cfg => {
  for (let prop in cfg) {
    if (cfg.hasOwnProperty(prop)) {
      const item = cfg[prop];
      if (filterWellBeing(item.activities || []).length > 0) {
        return true;
      }
    }
  }
  return false;
};

class TeamContainer extends React.Component {
  state = initialState;

  componentDidMount() {
    this.loadTeam(true);
    this.loadState();
    this.setTabFromLocationToState();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.groupId !== this.props.groupId ||
      prevProps.intl.locale !== this.props.intl.locale ||
      prevProps.company?.id !== this.props.company?.id
    ) {
      this.loadTeam(prevProps.groupId !== this.props.groupId);
    }
    if (this.props.team.current?.id !== prevProps.team.current?.id) {
      this.loadState();
    } else if (this.props.team.current?.id === this.props.groupId) {
      this.saveState();
    }
    if (this.props.location !== prevProps.location) {
      this.setTabFromLocationToState();
    }
  }

  loadState = () => {
    const {
      team,
      location: { query }
    } = this.props;
    if (team && team.current) {
      let stateToSet = initialState;
      let currentTab = query.tab?.toUpperCase();
      const savedState = getGroupState(team.current);
      if (savedState) {
        const { tab, ...restOfSavedState } = savedState;
        stateToSet = restOfSavedState;
        currentTab = currentTab || tab;
      }
      this.setState(stateToSet);
      if (currentTab) {
        this.handleTabChange(currentTab);
      }
    }
  };

  saveState = () => {
    const { team } = this.props;
    if (team && team.current) {
      const { highlighted, availableColors, tab } = this.state;
      saveTeamState(team.current, { highlighted, availableColors, tab });
    }
  };

  setTabFromLocationToState = () => {
    const {
      location: { query }
    } = this.props;
    this.setState({ tab: query.tab ? query.tab.toUpperCase() : null });
  };

  saveTeamCommon = team => {
    const { groupId, router } = this.props;
    const { updateGroup, createGroup } = this.props;
    if (!groupId) {
      return createGroup(team).then(action => {
        router.push(`/groups/${action.payload.payload.id}`);
        return action;
      });
    } else {
      return updateGroup(groupId, team);
    }
  };

  getTeamName = () => {
    const { team } = this.props;
    return team.current ? team.current.name : '';
  };

  getTeamType = () => {
    const {
      team: { current }
    } = this.props;
    const { selectedType } = this.state;
    return current && current.type ? EGroupType[current.type] || selectedType : selectedType;
  };

  handleSelectType = selectedType => this.setState({ selectedType });

  saveTeam = values => {
    const {
      company,
      team: { current }
    } = this.props;
    const data = {
      company,
      ...current,
      type: (this.getTeamType() || EGroupType.TEAM).key,
      ...values
    };
    return this.saveTeamCommon(data);
  };

  loadTeam = teamChanged => {
    const {
      company,
      router,
      groupId,
      intl: { locale }
    } = this.props;
    const {
      getGroup,
      getGroupManagers,
      getGroupMembers,
      getGroupMemberSuggestedCandidates,
      getGroupUserCompanyTags,
      getGroupTarget,
      getGroupSources,
      unsetCurrentGroup,
      unsetGroupTarget
    } = this.props;
    if (groupId) {
      this.setState({ loading: true, changingTeam: teamChanged });
      getGroup(groupId)
        .then(({ payload: { payload } }) => {
          if (payload.company && payload.company.id !== company.id) {
            router.push('/groups');
          } else {
            saveLastGroupId(company.id, groupId);
            const requests = [
              getGroupManagers(groupId, locale, { page: 0 }),
              getGroupMembers(groupId, locale, { page: 0 }),
              getGroupMemberSuggestedCandidates(groupId, locale, { page: 0, size: 5 }),
              getGroupUserCompanyTags(payload),
              getGroupSources(groupId)
            ];
            if (payload.targetId) {
              requests.push(getGroupTarget(payload.targetId, locale));
            } else {
              requests.push(unsetGroupTarget());
            }
            return Promise.all(requests);
          }
        })
        .catch(() => {
          saveLastGroupId(company.id, null);
          unsetCurrentGroup();
          unsetGroupTarget();
        })
        .finally(() => this.setState({ loading: false, changingTeam: false }));
    } else {
      unsetCurrentGroup();
      unsetGroupTarget();
    }
    this.setState({ selectedType: null });
  };

  deleteTeam = () => {
    const { company, groupId, router, deleteGroup } = this.props;
    deleteGroup(groupId).then(() => {
      saveLastGroupId(company.id, null);
      router.push('/groups');
    });
  };

  reload = () => this.loadTeam();

  handleAddMembersCommon = (members, membership, autoInvite, relevantActivities, autoDetectMembership) => {
    const activities = _map(relevantActivities, ({ key, variant }) => ({ key, variant }));
    this.saveTeam()
      .then(
        ({
          payload: {
            payload: { id }
          }
        }) => {
          const membershipMap = _reduce(
            members,
            (res, m) => {
              const { id, email } = m;
              const ms = (autoDetectMembership && m.membership) || membership;
              if (!res[ms]) {
                res[ms] = [];
              }
              const item = id ? { id, activities } : { email, activities };
              res[ms].push(item);
              return res;
            },
            {}
          );
          const promises = [];
          for (let ms in membershipMap) {
            if (membershipMap.hasOwnProperty(ms)) {
              promises.push(Api.createGroupMembers(id, { items: membershipMap[ms], membership: ms, autoInvite }));
            }
          }
          return Promise.all(promises);
        }
      )
      .then(this.reload);
  };

  handleAddManagers = (managers, autoInvite) => {
    this.handleAddMembersCommon(managers, EGroupMembership.MANAGER.key, autoInvite);
  };

  handleAddMembers = (members, autoInvite) => {
    this.handleAddMembersCommon(members, EGroupMembership.MEMBER.key, autoInvite);
  };

  handleInviteMembersCommon = (members, membership, activitiesToInvite, allActivities, autoDetectMembership) => {
    this.setState({
      inviting: {
        members,
        membership,
        preselectedActivities: activitiesToInvite,
        activities: allActivities || activitiesToInvite,
        autoDetectMembership
      }
    });
  };

  handleInviteMembersClose = () => this.setState({ inviting: null });
  handleInviteMembersConfirm = (members, activities) => {
    const { membership, autoDetectMembership } = this.state.inviting || {};
    this.handleAddMembersCommon(members, membership, true, activities, autoDetectMembership);
    this.handleInviteMembersClose();
  };

  handleInviteManagers = (managers, activitiesToInvite, allActivities, autoDetectMembership) => {
    this.handleInviteMembersCommon(
      managers,
      EGroupMembership.MANAGER.key,
      activitiesToInvite,
      allActivities,
      autoDetectMembership
    );
  };

  handleInviteMembers = (members, activitiesToInvite, allActivities, autoDetectMembership) => {
    this.handleInviteMembersCommon(
      members,
      EGroupMembership.MEMBER.key,
      activitiesToInvite,
      allActivities,
      autoDetectMembership
    );
  };

  removeMembers = members => {
    const {
      team: { current },
      deleteGroupMember
    } = this.props;
    if (members && current && current.id) {
      Promise.all(_map(members, m => deleteGroupMember(current.id, m.id))).then(this.reload);
    }
  };

  handleRemoveMembers = (members, autoConfirm) => {
    if (!autoConfirm) {
      this.setState({ removing: { members } });
    } else {
      this.removeMembers(members);
    }
  };

  handleRemoveMembersConfirm = members => {
    this.removeMembers(members);
    this.handleRemoveMembersClose();
  };
  handleRemoveMembersClose = () => this.setState({ removing: null });

  handleSaveName = name => {
    if (this.getTeamName() !== name) {
      this.saveTeam({ name });
    }
  };

  handleNew = () => {
    const { router } = this.props;
    router.push('/groups/new');
  };

  handleStartConfiguring = () => this.setState({ configuring: true });
  handleStopConfiguring = () => this.setState({ configuring: false });

  handleStartSwitch = () => this.setState({ switching: true });
  handleStopSwitch = () => this.setState({ switching: false });
  handleSwitch = t => {
    const { router } = this.props;
    this.handleStopSwitch();
    router.push(`/groups/${t.id}`);
  };

  handleChangeActivityConfigs = configs => {
    const {
      team: { current }
    } = this.props;
    if (!current) {
      return Promise.reject();
    }
    const data = current.data || {};
    const activityConfigurations = data.activityConfigurations || {};
    const updatedData = {
      ...data,
      activityConfigurations: {
        ...activityConfigurations,
        ...configs
      }
    };
    return this.saveTeamCommon({ ...current, data: updatedData }).then(() => {
      this.reload();
      this.handleStopConfiguring();
    });
  };
  handleChangeActivityConfig = (membership, config) => this.handleChangeActivityConfigs({ [membership.key]: config });
  handleChangeManagerActivityConfig = config => this.handleChangeActivityConfig(EGroupMembership.MANAGER, config);
  handleChangeMemberActivityConfig = config => this.handleChangeActivityConfig(EGroupMembership.MEMBER, config);

  handleShowProfile = (m, a) => {
    this.setState({
      profile: { id: m.id, project: a.project }
    });
  };
  handleHideProfile = () => {
    this.setState({ profile: null });
  };

  toggleMember = (m, prop) => {
    const {
      [prop]: { [m.id]: on, ...rest }
    } = this.state;
    if (on) {
      this.setState({ [prop]: rest });
    } else {
      rest[m.id] = true;
      this.setState({ [prop]: rest });
    }
  };

  getAllMembers = () => {
    const {
      members: { members, managers }
    } = this.props;
    const mmbItems = (members && members.items) || [];
    const mngItems = (managers && managers.items) || [];
    return [...mmbItems, ...mngItems];
  };

  handleToggleExpanded = m => this.toggleMember(m, 'expanded');
  handleToggleHighlighted = m => {
    // consolidate highlights
    const allMembers = this.getAllMembers();
    const { highlighted, availableColors } = this.state;
    const newHighlighted = {};
    let newColors = availableColors;
    for (let i = 0; i < allMembers.length; i += 1) {
      const member = allMembers[i];
      const highlight = highlighted[member.id];
      if (highlight) {
        newHighlighted[member.id] = highlight;
      }
    }
    for (let mId in highlighted) {
      if (highlighted.hasOwnProperty(mId)) {
        if (!newHighlighted[mId]) {
          newColors = [highlighted[mId], ...newColors];
        }
      }
    }
    //
    const items = _isArray(m) ? m : [m];
    // find out if they are all highlighted
    let allHighlighted = true;
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      if (!newHighlighted[item.id]) {
        allHighlighted = false;
        break;
      }
    }
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      const color = newHighlighted[item.id];
      if (color) {
        // only "invert" if all are highlighted
        if (allHighlighted) {
          delete newHighlighted[item.id];
          newColors = [color, ...newColors];
        }
      } else if (newColors.length > 0) {
        // do not highlight rest
        newHighlighted[item.id] = newColors[0];
        newColors = _slice(newColors, 1);
      }
    }
    this.setState({ highlighted: newHighlighted, availableColors: newColors });
  };

  handleRemindMembers = (members, preselectedActivities, activities, projectsMap) => {
    this.setState({ reminding: { members, preselectedActivities, activities, projectsMap } });
  };
  handleRemindMembersClose = () => this.setState({ reminding: null });
  handleRemindMembersConfirm = (members, activities) => {
    const { notifyProfiles } = this.props;
    const { projectsMap } = this.state.reminding || {};
    const values = [];
    for (let i = 0; i < members.length; i += 1) {
      const m = members[i];
      for (let j = 0; j < activities.length; j += 1) {
        const a = activities[j];
        const aKey = getActivityMapKey(a);
        const project = (projectsMap[m.id] || {})[aKey];
        if (project) {
          values.push({ userId: m.id, projectId: project.id, type: 'unfinished' });
        }
      }
    }
    if (values.length > 0) {
      notifyProfiles(values).then(this.reload);
    }
    this.handleRemindMembersClose();
  };

  handleDeleteTeamStart = () => this.setState({ deletingTeam: true });
  handleDeleteTeamClose = () => this.setState({ deletingTeam: false });
  handleDeleteTeamConfirm = () => {
    this.deleteTeam();
    this.setState({ deletingTeam: false });
  };

  handleTabChange = tab => {
    const { router, location } = this.props;
    router.push({ ...location, query: { ...location.query, tab: tab && tab.toLowerCase() } });
  };
  getCurrentTab = () => {
    const { tab } = this.state;
    return tab || ETab.LIST.key;
  };

  handleAddStart = membership => this.setState({ adding: membership });
  handleAddStop = () => this.setState({ adding: null });
  handleAddManagerStart = () => this.handleAddStart(EGroupMembership.MANAGER);
  handleAddMembersStart = () => this.handleAddStart(EGroupMembership.MEMBER);
  handleAddConfirm = (newMembers, autoInvite) => {
    const { members } = this.props;
    const { adding } = this.state;
    this.handleAddStop();
    if (adding === EGroupMembership.MANAGER) {
      const manager = _get(members, 'managers.items[0]');
      if (manager) {
        this.handleRemoveMembers([manager], true);
      }
      this.handleAddManagers(newMembers, autoInvite);
    } else {
      this.handleAddMembers(newMembers, autoInvite);
    }
  };

  handleToggleFilter = () => {
    const { filter, groupId, openGroupMemberFilter, closeGroupMemberFilter } = this.props;
    const { open } = filter;
    if (open) {
      closeGroupMemberFilter(groupId);
    } else {
      openGroupMemberFilter(groupId);
    }
  };

  handleCreateGroupStart = members => {
    this.setState({ creatingGroup: { members } });
  };
  handleCreateGroupConfirm = groupId => {
    const { router } = this.props;
    this.handleCreateGroupClose();
    router.push(`/groups/${groupId}`);
  };
  handleCreateGroupClose = () => this.setState({ creatingGroup: null });

  handleCopyStart = participants => {
    this.setState({ moving: { participants, mode: 'COPY' } });
  };
  handleMoveStart = participants => {
    this.setState({ moving: { participants, mode: 'MOVE' } });
  };
  handleMoveConfirm = () => {
    this.reload();
    this.handleMoveClose();
  };
  handleMoveClose = () => this.setState({ moving: null });

  handleTagStart = members => {
    this.setState({ tagging: { members } });
  };
  handleTagClose = () => this.setState({ tagging: null });
  handleTagConfirm = () => {
    this.handleTagClose();
  };

  handleConnect = t => {
    this.saveTeam({ targetId: t ? t.id : null }).then(() => {
      if (!t) {
        const { unsetGroupTarget } = this.props;
        unsetGroupTarget();
      }
      const { groupId, getGroupSources } = this.props;
      getGroupSources(groupId);
    });
    if (t) {
      const {
        getGroupTarget,
        intl: { locale }
      } = this.props;
      getGroupTarget(t.id, locale);
    }
  };

  handleSortSet = (type, sort) => this.props.setSubGroupSort(type, sort);
  handleMembersSortSet = sort => this.handleSortSet(EGroupMembership.MEMBER, sort);
  handleManagersSortSet = sort => this.handleSortSet(EGroupMembership.MANAGER, sort);

  handleTypeChangeStart = () => this.setState({ changingType: true });
  handleTypeChangeClose = () => this.setState({ changingType: false });
  handleTypeChangeConfirm = type => {
    this.handleTypeChangeClose();
    this.saveTeam({ type: type.key });
  };

  handleHiringLinkStart = () => this.setState({ linkingHiring: true });
  handleHiringLinkClose = () => this.setState({ linkingHiring: false });
  handleHiringLinkConfirm = t => {
    const { groupId, getGroupSources } = this.props;
    const newT = { ...t, targetId: groupId };
    this.handleHiringLinkClose();
    Api.updateGroup(t.id, newT).then(() => getGroupSources(groupId));
  };

  handleMembersGenerateStart = () => this.setState({ membersGenerating: true });
  handleMembersGenerateClose = () => this.setState({ membersGenerating: false });

  handleShareStart = () => this.setState({ sharing: true });
  handleShareClose = () => this.setState({ sharing: false });

  render() {
    const { auth, members, team, targetGroup, groupId, teamsCount, company, filter, location, intl } = this.props;
    const {
      switching,
      configuring,
      profile,
      expanded,
      highlighted,
      adding,
      removing,
      inviting,
      reminding,
      deletingTeam,
      creatingGroup,
      moving,
      tagging,
      loading,
      changingTeam,
      changingType,
      linkingHiring,
      membersGenerating,
      sharing
    } = this.state;
    const teamName = this.getTeamName();
    const showSwitch = teamsCount > (groupId ? 1 : 0);
    const configs = _get(team || {}, `current.data.activityConfigurations`) || {};
    const type = this.getTeamType();
    const readOnly = team?.current?.readOnly;
    if (!type && (!team || team.loading)) {
      return null;
    }
    if (loading && changingTeam) {
      return (
        <div className="team">
          <div className="mui-padded text-center">
            <Spinner show relative size={32} stroke={4} />
          </div>
        </div>
      );
    }
    const messages = intl.messages.components.teams.team;
    if (!type) {
      return (
        <div className="team">
          <div className="text-center" style={{ fontSize: '24px', margin: '32px 0 16px' }}>
            <strong>{messages.typeSelect.header}</strong>
            <div style={{ fontSize: '20px', fontWeight: 300, maxWidth: '30em', margin: '0 auto' }}>
              {formatMessage(messages.typeSelect.message, [
                <strong key="dynamicMessage">
                  <DynamicMessage messages={messages.typeSelect.dynamicMessageTexts} />
                </strong>
              ])}
            </div>
          </div>
          <div className="mui-padded-vertical-x2" style={{ maxWidth: '640px', margin: '0 auto' }}>
            <div className="mui-card">
              <GroupTypeSelect type={EGroupType[location.query.type]} onSelect={this.handleSelectType} />
            </div>
          </div>
        </div>
      );
    }
    const currentTab = this.getCurrentTab();
    const subGroupCommonProps = {
      expanded,
      highlighted,
      team: team && team.current,
      groupType: type,
      currentTab,
      suggestedItems: _get(members, 'suggestedCandidates.items'),
      onRemove: this.handleRemoveMembers,
      onRemind: this.handleRemindMembers,
      onShowProfile: this.handleShowProfile,
      onToggleExpanded: this.handleToggleExpanded,
      onToggleHighlighted: this.handleToggleHighlighted,
      onGroupCreate: this.handleCreateGroupStart,
      onMove: this.handleMoveStart,
      onCopy: this.handleCopyStart,
      onTag: this.handleTagStart
    };
    const managerConfig = configs[EGroupMembership.MANAGER.key];
    const membersConfig = configs[EGroupMembership.MEMBER.key];

    const showSubGroups = (managerConfig || type !== EGroupType.TEAM) && membersConfig;
    const showSubGroupsConfig = !showSubGroups && team && team.current;

    const sidebarOpen = filter.open || currentTab === ETab.ANALYTICS.key;
    const sidebarContent = sidebarOpen ? (
      <div>
        {filter.open && (
          <div className="team__filter-container-content mui-padded mui-padded-horizontal-x2">
            <TeamFilter />
          </div>
        )}
        <div className={`mui-padded-x2${filter.open ? ' border-top border-color-primary__50' : ''}`}>
          <MembersHighlightSelect
            highlighted={highlighted}
            members={members.members?.items || []}
            onToggleHighlighted={this.handleToggleHighlighted}
          />
        </div>
      </div>
    ) : null;
    const sidebarOpenInContainer = sidebarOpen && currentTab !== ETab.INSIGHTS.key;
    const wellBeingEnabled = isWellBeingEnabled(configs);

    return (
      <div className="team">
        <div className="container-flex-row">
          <div className="flex1 container-flex-row">
            <TeamName key={teamName} type={type} value={teamName} onBlur={this.handleSaveName} />
            {showSwitch && (
              <div className="mui-margin-top hidden-print">
                <Button
                  className="border-color-primary__100"
                  icon={<i className="material-icons text-muted">arrow_drop_down</i>}
                  style={{ minWidth: '36px', margin: 0, borderLeft: '1px solid', borderRadius: '0 2px 2px 0' }}
                  onClick={this.handleStartSwitch}
                  mini
                />
              </div>
            )}
          </div>
          {groupId && (
            <div className="hidden-print" style={{ margin: '7px 0 0 8px' }}>
              <IconButton onClick={this.reload} tooltip={messages.reloadTooltip}>
                <i className="material-icons text-muted">refresh</i>
              </IconButton>
              {!readOnly && (
                <IconButton onClick={this.handleNew} tooltip={messages.newLabel}>
                  <i className="material-icons text-muted">add</i>
                </IconButton>
              )}
              {!readOnly && (
                <IconMenu
                  iconButtonElement={
                    <IconButton>
                      <i className="material-icons text-muted">more_vert</i>
                    </IconButton>
                  }
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                  desktop
                >
                  {showSubGroups && (
                    <MenuItem
                      style={{ fontWeight: '400' }}
                      primaryText={messages.settingsLabel}
                      leftIcon={<i className="material-icons">settings</i>}
                      onClick={this.handleStartConfiguring}
                    />
                  )}
                  {auth.isAdmin && (
                    <MenuItem
                      style={{ fontWeight: '400' }}
                      primaryText={messages.shareLabel}
                      leftIcon={<i className="material-icons">share</i>}
                      onClick={this.handleShareStart}
                    />
                  )}
                  <MenuItem
                    style={{ fontWeight: '400' }}
                    primaryText={messages.changeTypeLabel}
                    leftIcon={<i className="material-icons">change_history</i>}
                    onClick={this.handleTypeChangeStart}
                  />
                  {type !== EGroupType.HIRING && (
                    <MenuItem
                      primaryText={messages.linkHiringLabel}
                      leftIcon={<i className="material-icons">link</i>}
                      onClick={this.handleHiringLinkStart}
                    />
                  )}
                  {auth.isAdmin && <Divider />}
                  {auth.isAdmin && (
                    <MenuItem
                      style={{ fontWeight: '400' }}
                      primaryText={messages.generateMembersLabel}
                      leftIcon={<i className="material-icons">celebration</i>}
                      onClick={this.handleMembersGenerateStart}
                    />
                  )}
                  <Divider />
                  <MenuItem
                    style={{ fontWeight: '400' }}
                    primaryText={messages.deleteLabel}
                    leftIcon={<i className="material-icons">delete</i>}
                    onClick={this.handleDeleteTeamStart}
                  />
                </IconMenu>
              )}
            </div>
          )}
        </div>
        <Collapse isOpened={team.sources?.length > 0 && !readOnly}>
          <div>
            <div className="mui-padded-horizontal-half mui-margin-bottom border-radius inline-container hidden-print">
              <GroupSourcesPanel sources={team.sources || []} />
            </div>
          </div>
        </Collapse>
        {showSubGroups && (
          <div className="team__subgroups mui-card">
            {type === EGroupType.HIRING && !readOnly && (
              <div className="hidden-print">
                <TargetGroupConnectPanel team={team.current} target={targetGroup} onConnect={this.handleConnect} />
              </div>
            )}
            <div className="team__tabs">
              <div className="container-flex-row ai-end">
                <div className="flex1" style={{ maxWidth: 'calc(100% - 36px)' }}>
                  <Tabs
                    messages={messages.tabs}
                    currentTab={currentTab}
                    onChange={this.handleTabChange}
                    loading={loading}
                    wellBeingEnabled={wellBeingEnabled}
                  />
                </div>
                <div style={{ padding: '8px 0 12px' }}>
                  <IconButton onClick={this.handleToggleFilter} tooltip={messages.searchTooltip}>
                    <i className="material-icons text-primary-sub">{filter.open ? 'clear' : 'search'}</i>
                  </IconButton>
                </div>
              </div>
            </div>
            <div className="container-flex-row fw-yes row" style={{ margin: 0 }}>
              {sidebarOpenInContainer && (
                <div
                  id={`teamFilter_${groupId}`}
                  className={'team__sidebar-container col-xs-12 col-md-3 col-md-push-9 border-color-primary__50'}
                >
                  <Sticky top={HEADER_SELECTOR} innerZ={100} bottomBoundary={`#teamFilter_${groupId}`}>
                    {sidebarContent}
                  </Sticky>
                </div>
              )}
              <div
                className={`team__content ${sidebarOpenInContainer ? 'col-xs-12 col-md-9 col-md-pull-3' : 'col-xs-12'}`}
                style={{ padding: 0 }}
              >
                {currentTab === ETab.OVERVIEW.key ? (
                  <div className="team__content-panel border-color-primary__75" style={{ minHeight: '520px' }}>
                    <TeamOverview
                      team={team?.current}
                      type={type}
                      members={members}
                      onInvite={this.handleInviteMembers}
                      onNotify={this.handleRemindMembers}
                      onAddStart={this.handleAddStart}
                    />
                  </div>
                ) : currentTab === ETab.INSIGHTS.key ? (
                  <div>
                    <TeamInsights
                      manager={_get(members.managers, 'items[0]')}
                      managers={members.managers}
                      members={members.members}
                      highlighted={highlighted}
                      activities
                      team={team?.current}
                      targetGroup={targetGroup}
                      type={type}
                      sidebar={sidebarContent}
                      onAddStart={this.handleAddStart}
                      onProfileShow={this.handleShowProfile}
                    />
                  </div>
                ) : (
                  <div className="team__content-panel border-color-primary__75 mui-padded-vertical">
                    {type === EGroupType.TEAM && (
                      <TeamSubGroup
                        single
                        type={EGroupMembership.MANAGER}
                        onAdd={this.handleAddManagers}
                        onAddStart={this.handleAddManagerStart}
                        onInvite={this.handleInviteManagers}
                        onChangeActivityConfig={this.handleChangeManagerActivityConfig}
                        onSortSet={this.handleManagersSortSet}
                        sort={team?.sort[EGroupMembership.MANAGER.key]}
                        {...subGroupCommonProps}
                        {...members.managers}
                      />
                    )}
                    <TeamSubGroup
                      type={EGroupMembership.MEMBER}
                      manager={_get(members.managers, 'items[0]')}
                      onAdd={this.handleAddMembers}
                      onAddStart={this.handleAddMembersStart}
                      onInvite={this.handleInviteMembers}
                      onChangeActivityConfig={this.handleChangeMemberActivityConfig}
                      onSortSet={this.handleMembersSortSet}
                      sort={team?.sort[EGroupMembership.MEMBER.key]}
                      {...subGroupCommonProps}
                      {...members.members}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {showSubGroupsConfig && (
          <TeamConfig
            team={team && team.current}
            type={type}
            activityConfigurations={configs}
            onChange={this.handleChangeActivityConfigs}
          />
        )}
        {switching && (
          <TeamSelect
            currentTeamId={groupId}
            companyId={company.id}
            onSelect={this.handleSwitch}
            onClose={this.handleStopSwitch}
          />
        )}
        {linkingHiring && (
          <TeamSelect
            type={EGroupType.HIRING.key}
            currentTeamId={groupId}
            companyId={company.id}
            onSelect={this.handleHiringLinkConfirm}
            onClose={this.handleHiringLinkClose}
          />
        )}
        {sharing && (
          <Overlay onRequestClose={this.handleTypeChangeClose} maxWidth={440} noBlur>
            <div className="mui-card">
              <TeamShareDialog team={team && team.current} onClose={this.handleShareClose} />
            </div>
          </Overlay>
        )}
        {profile && (
          <Overlay onRequestClose={this.handleHideProfile} full noBlur>
            <div>
              <ApplicantDetail
                disableStickyHeader
                params={{
                  projectSlug: profile.project.slug,
                  companySlug: profile.project.company.slug,
                  applicantId: profile.id
                }}
                singleActivity
              />
            </div>
          </Overlay>
        )}
        {configuring && (
          <Overlay onRequestClose={this.handleStopConfiguring} noBlur>
            <TeamConfig
              team={team && team.current}
              type={type}
              activityConfigurations={configs}
              onChange={this.handleChangeActivityConfigs}
            />
          </Overlay>
        )}
        {changingType && (
          <Overlay onRequestClose={this.handleTypeChangeClose} maxWidth={720} noBlur>
            <div className="mui-card">
              <GroupTypeSelect type={type} change onSelect={this.handleTypeChangeConfirm} />
            </div>
          </Overlay>
        )}
        {membersGenerating && (
          <Overlay onRequestClose={this.handleTypeChangeClose} maxWidth={320} noBlur>
            <div className="mui-card">
              <GenerateMembersDialog
                team={team && team.current}
                type={type}
                onReload={this.reload}
                onClose={this.handleMembersGenerateClose}
              />
            </div>
          </Overlay>
        )}
        <MemberRemoveDialog
          {...removing}
          open={!!(removing && removing.members)}
          onCancel={this.handleRemoveMembersClose}
          onConfirm={this.handleRemoveMembersConfirm}
        />
        <ActivityInviteDialog
          {...inviting}
          open={!!(inviting && inviting.members)}
          onCancel={this.handleInviteMembersClose}
          onConfirm={this.handleInviteMembersConfirm}
        />
        <ActivityRemindDialog
          {...reminding}
          open={!!(reminding && reminding.members)}
          onCancel={this.handleRemindMembersClose}
          onConfirm={this.handleRemindMembersConfirm}
        />
        <TeamDeleteDialog
          team={team && team.current}
          open={!!deletingTeam}
          onCancel={this.handleDeleteTeamClose}
          onConfirm={this.handleDeleteTeamConfirm}
        />
        <NewGroupDialog
          {...creatingGroup}
          company={company}
          team={team && team.current}
          open={!!creatingGroup?.members}
          onCancel={this.handleCreateGroupClose}
          onConfirm={this.handleCreateGroupConfirm}
        />
        {moving && moving.participants && (
          <AddToGroupsDialog
            {...moving}
            getParticipant={m => m}
            currentGroup={team && team.current}
            onClose={this.handleMoveClose}
            onConfirm={this.handleMoveConfirm}
          />
        )}
        {adding && (
          <TeamMemberSelect
            groupId={groupId}
            onSelect={this.handleAddConfirm}
            onClose={this.handleAddStop}
            membership={adding}
            single={adding === EGroupMembership.MANAGER}
          />
        )}
        {tagging && (
          <GroupMembersTagsAssignmentOverlay
            {...tagging}
            group={team && team.current}
            onClose={this.handleTagClose}
            onConfirm={this.handleTagConfirm}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth,
    intl,
    entities: { group }
  } = state;
  return {
    auth,
    intl,
    team: group,
    members: selectFilteredGroupMembers(state, ownProps),
    filter: selectGroupMembersFilter(state, ownProps),
    targetGroup: selectTargetGroup(state, ownProps)
  };
};

const actions = {
  ...groupActions,
  notifyProfiles,
  getGroupUserCompanyTags,
  openGroupMemberFilter,
  closeGroupMemberFilter
};

export default withRouter(connect(mapStateToProps, actions)(TeamContainer));
