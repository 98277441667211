import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import Sticky from 'react-stickynode';

import Modals from './private/Modals';
import Guide from './private/Guide';
import Snackbars from './private/Snackbars';
import Navbar from './../components/navigation/Navbar';
import Sidebar from '../components/sidebar/Sidebar';
import Spinner from './../components/spinner/Spinner';
import SimpleModal from '../components/modals/SimpleModal';
import Notifications from '../components/notifications/Notifications';
import UserMenuButton from '../components/user/UserMenuButton';
import BackButton from '../components/navigation/BackButton';
import GoToTopButton from '../components/navigation/GoToTopButton';

import { MODAL_SIMPLE, NOTIFICATIONS_TYPE_CTA, BRAND } from '../constants/constants';
import {
  decodeToken,
  loadToken,
  getSidebarSettings,
  saveToken,
  canAccessProjects,
  canAccessGroups
} from '../utils/utils';
import { redirectAuthenticated } from '../utils/route';
import allActions from './../actions';
import { isPublic } from '../routes';
import languages from '../constants/languages';
import { selectAccountPermissions } from '../selectors/auth';
import IEUnsupportedPage from '../components/navigation/IEUnsupportedPage';

const NoNavbar = props => {
  const { sidebarSettings } = props;
  return (
    <div className="no-navbar">
      <IEUnsupportedPage />
      <div className="mui-padded-half" style={{ position: 'fixed', top: 0, left: sidebarSettings.width, zIndex: 1000 }}>
        <div className="mui-padded-half">
          <BackButton />
        </div>
      </div>
      <div
        className="mui-padded-half"
        style={{ position: 'fixed', bottom: 0, left: sidebarSettings.width, zIndex: 1000 }}
      >
        <div className="mui-padded-half">
          <GoToTopButton />
        </div>
      </div>
      <div style={{ position: 'fixed', top: '8px', right: '8px', zIndex: 1000 }}>
        <UserMenuButton />
      </div>
    </div>
  );
};

class App extends Component {
  componentDidMount() {
    const { location, addNotification } = this.props;

    const accessToken = location.query.access_token;
    const decodedToken = this.decodeAndSaveToken(accessToken) || decodeToken();
    this.load(decodedToken);

    // TODO remove
    const ELF_2017 = 'elf2017';
    if ((location.query.cta || '').toLowerCase() === ELF_2017) {
      const timeout = (location.query.cta_to && parseInt(location.query.cta_to, 10)) || 5000;
      setTimeout(() => addNotification({ id: ELF_2017, type: NOTIFICATIONS_TYPE_CTA, ctaType: ELF_2017 }), timeout);
    }
  }

  componentDidUpdate(prevProps) {
    const curToken = this.props.location?.query?.access_token;
    const prevToken = prevProps.location?.query?.access_token;
    if (curToken && curToken !== prevToken) {
      const decodedToken = this.decodeAndSaveToken(curToken);
      if (decodedToken) {
        this.load(decodedToken);
      }
    }
  }

  decodeAndSaveToken = token => {
    if (!token) {
      return null;
    }
    try {
      const decodedToken = jwtDecode(token);
      saveToken(token);
      return decodedToken;
    } catch (e) {
      console.warn('Invalid token', token);
    }
    return null;
  };

  loadUserData = (token, indexRedirect) => {
    const { getUser, getCompanies, getProjectsAll, router } = this.props;
    const promises = Promise.all([getUser(token), getCompanies()]);
    return promises.then(([userRes, companyRes]) => {
      if (userRes.error) {
        this.logout();
        return Promise.reject(userRes.error);
      }
      const promises = [];
      const companies = companyRes.items;
      promises.push(getProjectsAll(companies));
      if (indexRedirect) {
        promises.push(Promise.resolve(redirectAuthenticated(router, { ...token, ...userRes.payload }, companies)));
      }
      return Promise.all(promises);
    });
  };

  load = decodedToken => {
    const { router, location, updateLocale } = this.props;
    const lang = location.query.lang;

    const promiseArr = [];
    if ((location.pathname === '/login' || location.pathname === '/') && !isEmpty(decodedToken)) {
      if (canAccessProjects(decodedToken) || canAccessGroups(decodedToken)) {
        promiseArr.push(() => this.loadUserData(decodedToken, true));
      } else {
        promiseArr.push(() => router.push({ pathname: '/designer' }));
      }
    } else if (!isPublic(location)) {
      if (isEmpty(decodedToken)) {
        promiseArr.push(() => router.push({ pathname: '/login' }));
      } else {
        if (canAccessProjects(decodedToken) || canAccessGroups(decodedToken)) {
          promiseArr.push(() => this.loadUserData(decodedToken));
        } else {
          promiseArr.push(() => router.push({ pathname: '/designer' }));
        }
      }
    }
    if (lang && languages[lang]) {
      promiseArr.push(() => updateLocale(lang));
    }
    reduce(promiseArr, (cur, promise) => cur.then(promise), Promise.resolve());
  };

  logout = () => {
    const { router } = this.props;
    router.push({ pathname: '/logout' });
  };

  render() {
    const { auth, modal, loader, ui, intl, location, notifications, children } = this.props;
    const { switchModal, addNotification, hideNotification, dismissNotification, showHiddenNotifications } = this.props;

    const tokenExists = !isEmpty(loadToken());
    const isLoading = !isEmpty(filter(values(loader), property => property));
    const sidebarSettings = getSidebarSettings(ui);
    const publicPath = isPublic(location);

    return (
      <div
        className={`app-container brand-${BRAND.key.toLowerCase()}`}
        style={{
          paddingLeft: !publicPath && tokenExists && sidebarSettings.docked ? `${sidebarSettings.width}px` : '0'
        }}
      >
        <Spinner show={isLoading} />
        {!publicPath && tokenExists && <Sidebar />}
        {!publicPath &&
          tokenExists &&
          (sidebarSettings.docked ? (
            <NoNavbar sidebarSettings={sidebarSettings} />
          ) : (
            <Sticky>
              <IEUnsupportedPage />
              <Navbar />
            </Sticky>
          ))}
        {children}
        <SimpleModal {...modal[MODAL_SIMPLE]} switchModal={switchModal} />
        <Notifications
          notifications={notifications}
          intl={intl}
          addNotification={addNotification}
          hideNotification={hideNotification}
          dismissNotification={dismissNotification}
          showHiddenNotifications={showHiddenNotifications}
        />
        <Modals />
        {auth.guideEnabled && <Guide />}
        <Snackbars />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.object,
  auth: PropTypes.object,
  ui: PropTypes.object,
  intl: PropTypes.object,
  location: PropTypes.object,
  router: PropTypes.object,
  loader: PropTypes.object,
  modal: PropTypes.object,
  notifications: PropTypes.object.isRequired,
  //
  getUser: PropTypes.func,
  getCompanies: PropTypes.func,
  getProjectsAll: PropTypes.func.isRequired,
  updateLocale: PropTypes.func,
  switchModal: PropTypes.func,
  addNotification: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
  dismissNotification: PropTypes.func.isRequired,
  showHiddenNotifications: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const {
    options: { loader, stepper, modal, notifications },
    ui,
    intl
  } = state;
  return {
    ui,
    intl,
    auth: selectAccountPermissions(state, ownProps),
    loader,
    stepper,
    modal,
    notifications
  };
};

export default connect(mapStateToProps, allActions)(withRouter(App));
