import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';
import map from 'lodash/map';

import withIntl from '../withIntl';
import InfoTooltip from '../basic/InfoTooltip';

const createData = (statusCounts, messages) => {
  return [
    {
      name: messages.ended,
      value: statusCounts.finished,
      className: 'finished'
    },
    {
      name: messages.started,
      value: statusCounts.started,
      className: 'started'
    },
    {
      name: messages.new,
      value: statusCounts.total - statusCounts.finished - statusCounts.started,
      className: 'new'
    }
  ];
};

const StatusCountsChart = props => {
  const {
    statusCounts,
    icon,
    color,
    colorTransparent,
    size = 60,
    style,
    infoInTooltip,
    intl: { messages }
  } = props;
  const data = createData(statusCounts || { finished: 0, started: 0, total: 0 }, messages);
  const chartData =
    statusCounts && statusCounts.total > 0
      ? data
      : [
          {
            name: messages.participantCount,
            value: 1,
            color: statusCounts ? 'rgba(0,0,0,0.24)' : colorTransparent
          }
        ];
  const rawChartFontSize = Math.min(28, Math.max(14, Math.round((size / 100) * 28)));
  const chartFontSizeQ = statusCounts && statusCounts.total >= 1000 ? 0.7 : 1;
  const chartFontSize = rawChartFontSize * chartFontSizeQ;
  const chartLineHeight = Math.round(chartFontSize * 1.3);
  const chart = (
    <div className="status-counts__chart" style={{ minWidth: size }}>
      <PieChart width={size} height={size} margin={{ top: 0, left: 0, right: 0, bottom: 0 }} style={{ margin: 'auto' }}>
        <Pie
          data={chartData}
          dataKey={'value'}
          innerRadius={'80%'}
          outerRadius={'100%'}
          startAngle={90}
          endAngle={-270}
          isAnimationActive={false}
        >
          {map(chartData, e => (
            <Cell key={e.name} className={e.className} fill={e.color} stroke="none" />
          ))}
        </Pie>
      </PieChart>
      <div className="status-counts__chart-total">
        {statusCounts ? (
          <span
            style={{
              fontSize: chartFontSize,
              lineHeight: `${chartLineHeight}px`,
              marginTop: -(chartLineHeight / 2)
            }}
          >
            {statusCounts.total}
          </span>
        ) : (
          <i className="material-icons" style={{ fontSize: size * 0.48, color }}>
            {icon}
          </i>
        )}
      </div>
    </div>
  );
  const info = (
    <div>
      {map(data, (e, idx) => (
        <div className="status-counts__count-item" key={idx}>
          <span className={e.className}>{`${e.value} `}</span>
          {e.name}
        </div>
      ))}
    </div>
  );
  return (
    <div className="status-counts" style={style}>
      <div className="status-counts__content" style={{ minHeight: size, minWidth: size, maxWidth: size + 180 }}>
        {infoInTooltip ? (
          <InfoTooltip content={info} delay={500}>{chart}</InfoTooltip>
        ) : (
          <>
            {chart}
            {info}
          </>
        )}
      </div>
    </div>
  );
};

StatusCountsChart.propTypes = {
  intl: PropTypes.object,
  size: PropTypes.number,
  style: PropTypes.object
};

const intlMessages = {
  sk: {
    participantCount: 'Počet účastníkov',
    ended: 'dokončilo',
    started: 'zahájilo',
    new: 'nezačaté'
  },
  cs: {
    participantCount: 'Počet účastníků',
    ended: 'dokončilo',
    started: 'zahájilo',
    new: 'nezapočato'
  },
  en: {
    participantCount: 'Number of participants',
    ended: 'completed',
    started: 'started',
    new: 'registered'
  }
};

export default withIntl(StatusCountsChart, intlMessages);
