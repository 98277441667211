import React from 'react';
import _map from 'lodash/map';

import InitialsAvatar from '../applicant/InitialsAvatar';
import ParticipantName from '../basic/ParticipantName';
import MemberActivityInsights from './MemberActivityInsights';
import StatsNoticeMessage from './StatsNoticeMessage';

import { EGroupType } from '../../constants/enum';

const MemberActivityInsightsPanel = props => {
  const {
    member,
    activities,
    activityMetricProperties,
    activityMetricStats,
    activityCollapsed,
    activityChartsShown,
    activityParticipantsListShown,
    type,
    targetGroup,
    targetMembers
  } = props;
  const { onActivityCollapsedToggle, onProfileShow } = props;
  return (
    <div className="mui-padded-vertical">
      {member ? (
        <div>
          <div className="container-flex-row ai-center fw-yes jc-space-between mui-padded-vertical mui-padded-horizontal-half text-sz-lg">
            <div className="mui-padded-vertical">
              <InitialsAvatar
                size={40}
                firstName={member.firstName}
                lastName={member.lastName}
                identifier={member.id}
              />
              <b style={{ margin: '0 12px' }}>
                <ParticipantName
                  firstName={member.firstName}
                  lastName={member.lastName}
                  email={member.email}
                  anonymous={member.anonymous}
                />
              </b>
            </div>
            {type === EGroupType.HIRING && <StatsNoticeMessage group={targetGroup?.group} />}
          </div>
          <div>
            {_map(activities, a => (
              <MemberActivityInsights
                key={`${a.mapKey}-${member.id}`}
                activity={a}
                member={member}
                metricProps={activityMetricProperties[a.mapKey] || {}}
                metricStats={activityMetricStats[a.mapKey] || {}}
                expanded={!activityCollapsed[a.mapKey]}
                chartShown={activityChartsShown && activityChartsShown[a.mapKey]}
                participantsListShown={activityParticipantsListShown && activityParticipantsListShown[a.mapKey]}
                onExpandedToggle={onActivityCollapsedToggle}
                onProfileShow={onProfileShow}
                targetMembers={targetMembers}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="mui-padded-vertical">
          <em className="text-muted">N/A</em>
        </div>
      )}
    </div>
  );
};

export default MemberActivityInsightsPanel;
