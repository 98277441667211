import React from 'react';
import { Link, withRouter } from 'react-router';

import withIntl from '../../withIntl';

import { formatMessage } from '../../../utils/utils';

const TabLinkButton = props => {
  const {
    tab,
    location,
    intl: { messages }
  } = props;
  const label = formatMessage(messages.baseLabel, [messages[tab.key].label]);
  const to = { ...location, query: { ...location.query, tab: tab.key.toLowerCase() } };
  return (
    <Link className="link-button" to={to}>
      <span>{label}</span>
    </Link>
  );
};

const intlMessages = {
  cs: {
    baseLabel: 'Přejít na {0}',
    ANALYTICS: {
      label: 'analytiku'
    }
  },
  sk: {
    baseLabel: 'Prejsť na {0}',
    ANALYTICS: {
      label: 'analytiku'
    }
  },
  en: {
    baseLabel: 'Go to {0}',
    ANALYTICS: {
      label: 'analytics'
    }
  }
};

export default withIntl(withRouter(TabLinkButton), intlMessages);
