import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';

import Spinner from './../../../components/spinner/Spinner';

import ApplicantPrintPage from './../../../components/pages/private/position/ApplicantPrint';

import { decodeToken, loadToken, saveToken } from '../../../utils/utils';
import { BRAND } from '../../../constants/constants';
import { EProfileFeature } from '../../../constants/enum';
import { getCurrentParticipant } from '../../../selectors/applicantDetail';
import allActions from './../../../actions';

const extractProjectName = ({ id, project, additionalActivities }, activityIndex) => {
  if (activityIndex == null || activityIndex === 0) {
    return project.name;
  }
  const { results, projects } = additionalActivities || {};
  const participantResults = (results || {})[id] || [];
  const res = participantResults[activityIndex - 1];
  const actualProject = res ? (projects && projects[res.projectId]) || project : project;
  return actualProject.name;
};

class ApplicantPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const { getUser, getResult, params: { companySlug, projectSlug }, location, locale } = this.props;
    const { query, pathname } = location;

    let decodedToken = decodeToken();

    if (query.access_token) {
      saveToken(query.access_token);
      decodedToken = jwtDecode(query.access_token);
    }

    if (isEmpty(decodedToken)) {
      if (pathname !== '/user/password-reset' && pathname !== '/reset-password') {
        this.context.router.push({ pathname: '/login' });
      }
    } else {
      getUser(decodedToken).then(res => {
        if (res.error) {
          this.logout();
          return new Promise((resolve, reject) => reject(res.error));
        }

        return getResult(companySlug, projectSlug, query.id, locale, true).then(res => {
          const { payload, payload: { participant } } = res;
          const activityIndex = query.aidx || 0;
          const projectName = extractProjectName(payload, activityIndex);
          let participantName;
          if (participant.anonymous) {
            participantName = participant.id;
          } else {
            participantName =
              participant.lastName || participant.firstName
                ? `${participant.lastName} ${participant.firstName}`
                : participant.email;
          }
          this.changeTitle(`${projectName} - ${participantName}`);
          setTimeout(() => {
            window.print();
            // setTimeout(() => window.close(), 0);
          }, 350);
        });
      });
    }
  }

  changeTitle = title => {
    const elems = document.getElementsByTagName('title');
    const el = elems && elems[0];
    if (el) {
      el.innerText = title;
    }
  };

  logout() {
    const { router } = this.props;
    router.push({ pathname: '/logout' });
  }

  render() {
    const { auth, currentParticipant, loader, location } = this.props;
    const activityIndex = location.query.aidx;

    if (!currentParticipant) {
      return null;
    }
    const { features } = currentParticipant;

    if (!features[EProfileFeature.PROFILE_PRINT]) {
      return null;
    }

    const tokenExists = !isEmpty(loadToken());
    const isLoading = !isEmpty(values(loader).filter(property => property));

    const pageProps = {
      auth,
      activityIndex,
      ...currentParticipant
    };

    return (
      <div className={`app-container brand-${BRAND.key.toLowerCase()}`}>
        <Spinner show={isLoading} />
        {tokenExists && <ApplicantPrintPage {...pageProps} />}
      </div>
    );
  }
}

ApplicantPrint.propTypes = {
  auth: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
  currentParticipant: PropTypes.object,
  router: PropTypes.object,
  loader: PropTypes.object,
  getUser: PropTypes.func,
  getResult: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  const { auth, intl: { locale }, options: { loader } } = state;
  return {
    auth,
    locale,
    loader,
    currentParticipant: getCurrentParticipant(state, ownProps)
  };
};

export default connect(mapStateToProps, allActions)(withRouter(ApplicantPrint));
