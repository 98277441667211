import React from 'react';

import MultiText from '../../superfield/SuperMultiText';

import { formatMessage, splitToEmails } from '../../../utils/utils';

const SendFromSystemContent = props => {
  const { messages, emails } = props;
  const { toggleSendFromSystem, onEmailsChange } = props;
  return (
    <div>
      <p style={{ margin: 0 }}>
        {formatMessage(messages.sendFromSystem.enterEmailsText, (idx, val) => (
          <a key={idx} role="button" onClick={toggleSendFromSystem}>
            {val}
          </a>
        ))}
      </p>
      <MultiText
        label={messages.sendFromSystem.emailsLabel}
        values={emails}
        onChange={onEmailsChange}
        helperText={messages.sendFromSystem.emailsHintText}
        splitter={splitToEmails}
        errorMessage={messages.sendFromSystem.emailsInvalidText}
      />
    </div>
  );
};

export default SendFromSystemContent;
