import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import { DraggableCore as Draggable } from 'react-draggable';

import { grey700 } from 'material-ui/styles/colors';

import { BRAND } from '../../constants/constants';

import BrushHandle from './BrushHandle';

const Tooltip = props => {
  const { text, style } = props;
  return (
    <div
      style={{
        display: 'inline-block',
        padding: '0 8px',
        fontSize: '10px',
        color: '#fff',
        backgroundColor: grey700,
        opacity: 0.9,
        borderRadius: '2px',
        verticalAlign: 'middle',
        lineHeight: '22px',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '420px',
        ...style
      }}
    >
      {text}
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.any.isRequired,
  style: PropTypes.any
};

const resolveLabel = (label, data) => {
  if (typeof label === 'function') {
    return label(data);
  }
  return label;
};

const boundToStepInverted = (val, step) => {
  const factor = 1 / step;
  return Math.round(val * factor) / factor;
};

const boundToStep = (val, step) => (step < 1 ? boundToStepInverted(val, step) : Math.round(val / step) * step);
const bound = (val, { min, max, step }) => Math.min(Math.max(min, boundToStep(val, step)), max);

class Brush extends Component {
  static propTypes = {
    startIndex: PropTypes.number,
    endIndex: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    labelStart: PropTypes.any,
    labelEnd: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      startIndex: props.startIndex,
      endIndex: props.endIndex,
      dim: {
        width: 100,
        left: 0
      },
      dragStart: null
    };
  }

  componentDidUpdate(prevProps) {
    const { startIndex, endIndex } = this.props;
    if (prevProps.startIndex !== startIndex || prevProps.endIndex !== endIndex) {
      if (startIndex != undefined && endIndex != undefined) {
        this.setState({ startIndex, endIndex });
      }
    }
  }

  getBounds = () => {
    const { min, max, step } = this.props;
    return {
      min: min != undefined ? min : 0,
      max: max != undefined ? max : 100,
      step: step != undefined ? step : 1
    };
  };

  getValues = () => {
    const bounds = this.getBounds();
    const { startIndex, endIndex } = this.state;
    return {
      startIndex: bound(startIndex, bounds),
      endIndex: bound(endIndex, bounds)
    };
  };

  setDragStart = ({ x, y }) => {
    this.setState({
      dragStart: {
        x,
        y
      }
    });
  };

  setDragStop = () => {
    this.setState({ dragStart: null });
  };

  handleChange = (diff, start, end) => {
    if (!start && !end) {
      return;
    }
    const { onChange } = this.props;
    const bounds = this.getBounds();
    const values = this.getValues();
    const startIndex = bound(values.startIndex + (start ? diff : 0), bounds);
    const endIndex = bound(values.endIndex + (end ? diff : 0), bounds);
    if (
      (!start || startIndex !== values.startIndex) &&
      (!end || endIndex !== values.endIndex) &&
      startIndex <= endIndex
    ) {
      const newState = { startIndex, endIndex };
      if (onChange) {
        onChange(newState);
      }
      this.setState(newState);
    }
  };

  handleStartChange = diff => {
    this.handleChange(diff, true, false);
  };

  handleEndChange = diff => {
    this.handleChange(diff, false, true);
  };

  handleFullChange = diff => {
    this.handleChange(diff, true, true);
  };

  handleDimMeasure = dim => {
    this.setState({
      dim
    });
  };

  calculateStepWidth = () => {
    const { dim: { width } } = this.state;
    const { min, max } = this.getBounds();
    return width / (max - min);
  };

  enabledOnlyAction = action => {
    return (...args) => {
      if (!this.props.disabled) {
        action(...args);
      }
    };
  };

  handleDrag = this.enabledOnlyAction((data, method) => {
    const { dragStart } = this.state;
    const stepWidth = this.calculateStepWidth();
    const delta = data.x - dragStart.x;
    const diffReal = delta / stepWidth;
    const diff = boundToStep(diffReal, this.getBounds().step);
    if (diff) {
      method(diff);
    }
  });

  handleDragStop = this.enabledOnlyAction((data, method) => {
    this.handleDrag(data, method);
    this.setDragStop();
  });
  handleDragStart = this.enabledOnlyAction((e, data) => this.setDragStart(data));
  handleFullChangeDrag = this.enabledOnlyAction((e, data) => this.handleDrag(data, this.handleFullChange));
  handleFullChangeDragStop = this.enabledOnlyAction((e, data) => this.handleDragStop(data, this.handleFullChange));
  handleStartChangeDrag = this.enabledOnlyAction((e, data) => this.handleDrag(data, this.handleStartChange));
  handleStartChangeDragStop = this.enabledOnlyAction((e, data) => this.handleDragStop(data, this.handleStartChange));
  handleEndChangeDrag = this.enabledOnlyAction((e, data) => this.handleDrag(data, this.handleEndChange));
  handleEndChangeDragStop = this.enabledOnlyAction((e, data) => this.handleDragStop(data, this.handleEndChange));

  render() {
    const { dragStart } = this.state;
    const { labelStart, labelEnd, labelStatic, disabled } = this.props;
    const { min } = this.getBounds();
    const { startIndex, endIndex } = this.getValues();
    const stepWidth = this.calculateStepWidth();
    const handleSize = 24;
    const brushLeft = stepWidth * (startIndex - min);
    const brushWidth = stepWidth * (endIndex - startIndex);
    return (
      <Measure onMeasure={this.handleDimMeasure}>
        <div
          style={{
            width: '100%',
            height: handleSize,
            position: 'relative',
            display: 'inline-block',
            ...(disabled
              ? {
                  filter: 'grayscale(1)',
                  opacity: 0.6
                }
              : {})
          }}
        >
          <div style={{ width: '100%', height: '2px', backgroundColor: '#bdbdbd', marginTop: handleSize / 2 - 1 }} />
          <div style={{ position: 'absolute', top: 0, left: brushLeft, height: handleSize, width: brushWidth }}>
            <Draggable
              onStart={this.handleDragStart}
              onDrag={this.handleFullChangeDrag}
              onStop={this.handleFullChangeDragStop}
            >
              <div
                style={{
                  width: '100%',
                  padding: `${(handleSize - 14) / 2}px 0`,
                  height: handleSize,
                  cursor: disabled ? 'not-allowed' : 'pointer'
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: BRAND.theme.palette.primary1Color
                  }}
                />
              </div>
            </Draggable>
            <div style={{ position: 'absolute', top: 0, left: -handleSize / 2, bottom: 0 }}>
              <BrushHandle
                disabled={disabled}
                size={handleSize}
                handleDragStart={this.handleDragStart}
                handleDrag={this.handleStartChangeDrag}
                handleDragStop={this.handleStartChangeDragStop}
              />
            </div>
            <div style={{ position: 'absolute', top: 0, right: -handleSize / 2, bottom: 0 }}>
              <BrushHandle
                disabled={disabled}
                size={handleSize}
                handleDragStart={this.handleDragStart}
                handleDrag={this.handleEndChangeDrag}
                handleDragStop={this.handleEndChangeDragStop}
              />
            </div>
            {(dragStart || labelStatic) &&
              (labelStart || labelEnd) && (
                <div style={{ position: 'absolute', top: handleSize, left: 0, right: 0, zIndex: 10 }}>
                  {labelStart && (
                    <div className="pull-left" style={{ marginTop: '4px' }}>
                      {labelStatic ? (
                        resolveLabel(labelStart, { value: startIndex, start: true })
                      ) : (
                        <Tooltip text={resolveLabel(labelStart, { value: startIndex, start: true })} />
                      )}
                    </div>
                  )}
                  {labelEnd && (
                    <div className="pull-right" style={{ marginTop: '4px' }}>
                      {labelStatic ? (
                        resolveLabel(labelEnd, { value: endIndex, start: false })
                      ) : (
                        <Tooltip text={resolveLabel(labelEnd, { value: endIndex, start: false })} />
                      )}
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      </Measure>
    );
  }
}

export default Brush;
