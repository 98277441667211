import React from 'react';
import { Area, AreaChart, Tooltip, ResponsiveContainer, XAxis } from 'recharts';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import moment from 'moment';

import DateRangeSelect from '../mui/DateRangeSelect';
import withIntl from '../withIntl';

const tooltipMessageMapping = {
  rc: 'registeredLabel',
  fc: 'finishedLabel',
  sc: 'startedLabel'
};

const CustomTooltipContent = props => {
  const { payload, messages, label } = props;
  if (!payload || !payload.length) {
    return null;
  }
  return (
    <div className="chart__tooltip">
      <h5>{label}</h5>
      <div>
        {_map(payload, p => (
          <div key={p.dataKey}>
            <span>{messages[tooltipMessageMapping[p.dataKey]]}</span>: <span>{p.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const timestampKey = ts => (ts ? moment(ts).format('YYYY-MM-DD') : null);

const computeData = ({ participants, getParticipantActivities }, range) => {
  const updateCount = (res, tsKey, countName) => {
    if (tsKey) {
      res.counts[countName][tsKey] = (res.counts[countName][tsKey] || 0) + 1;
    }
    return res;
  };
  const activities = _reduce(
    participants,
    (res, p) => {
      const a = getParticipantActivities(p);
      for (let i = 0; i < a.length; i += 1) {
        res.push(a[i]);
      }
      return res;
    },
    []
  );
  const meta = _reduce(
    activities,
    (res, a) => {
      const { createdAt, startedAt, finishedAt } = a;
      const ct = timestampKey(createdAt);
      const st = timestampKey(startedAt);
      const ft = timestampKey(finishedAt);
      res = updateCount(res, ct, 'registered');
      res = updateCount(res, st, 'started');
      res = updateCount(res, ft, 'finished');
      if (!res.min || ct < res.min) {
        res.min = ct;
      }
      const maxT = ft || st || ct;
      if (!res.max || maxT > res.max) {
        res.max = maxT;
      }
      return res;
    },
    {
      counts: {
        invited: {},
        registered: {},
        started: {},
        finished: {}
      },
      min: null,
      max: null
    }
  );
  const data = [];
  let curr = timestampKey(range.from);
  let max = timestampKey(range.to);
  let prevItem = null;
  while (curr && curr <= max) {
    const rc = meta.counts.registered[curr] || 0;
    const sc = meta.counts.started[curr] || 0;
    const fc = meta.counts.finished[curr] || 0;
    const label = moment(curr).format('L');
    const item = { rc, sc, fc, label };
    data.push(item);
    prevItem = item;

    curr = timestampKey(moment(curr).add(1, 'day'));
  }
  return { data, meta };
};

class ActivityStatusChart extends React.Component {
  state = { data: [] };

  componentDidMount() {
    const { dateRange } = this.props;
    this.setDateRange(dateRange || { from: moment().add(-27, 'day'), to: moment() });
  }

  componentDidUpdate(prevProps) {
    const { participants, intl, dateRange } = this.props;
    let compute = false;
    if (prevProps.participants !== participants || prevProps.intl !== intl) {
      compute = true;
    }
    if (dateRange && prevProps.dateRange !== dateRange) {
      // will be computed either way
      compute = false;
      this.setDateRange(dateRange);
    }
    if (compute) {
      this.computeDataFromProps();
    }
  }

  setDateRange = range => {
    const { from, to } = range || {};
    const newFrom = (from && moment(from)) || moment().add(-27, 'day');
    const newTo = (to && moment(to)) || moment();
    if (
      !this.state.from ||
      !newFrom.isSame(this.state.from, 'day') ||
      !this.state.to ||
      !newTo.isSame(this.state.to, 'day')
    ) {
      this.setState({ from: newFrom, to: newTo }, this.computeDataFromProps);
    }
  };

  computeDataFromProps = () => {
    const { participants, getParticipantActivities, intl } = this.props;
    const { from, to } = this.state;
    moment.locale(intl.locale);
    this.setState(computeData({ participants, getParticipantActivities }, { from, to }));
  };
  handleChangeDateRange = ({ from, to }) => {
    const { onDateRangeChange } = this.props;
    this.setState({ from, to }, this.computeDataFromProps);
    if (onDateRangeChange) {
      onDateRangeChange({ from, to });
    }
  };

  render() {
    const {
      intl: { messages },
      title,
      border
    } = this.props;
    const { from, to, data } = this.state;
    return (
      <div className={'mui-padded'}>
        <div className={`mui-card${border ? ' border border-color-primary__50' : ''}`} style={{ boxShadow: 'none' }}>
          <div className="container-flex-row ai-center jc-space-between">
            <div className="mui-padded-x2">
              <div className="mui-label" style={{ margin: 0 }}>
                {title || messages.title}
              </div>
            </div>
            <div className="text-right mui-padded">
              <DateRangeSelect from={from} to={to} onChange={this.handleChangeDateRange} />
            </div>
          </div>
          <ResponsiveContainer height={240}>
            <AreaChart
              data={data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
              }}
            >
              <Tooltip cursor={false} content={<CustomTooltipContent messages={messages} />} />
              <Area
                type="monotone"
                dataKey="fc"
                stackId="1"
                stroke="rgba(50, 28, 86, 0.84)"
                fill="rgba(50, 28, 86, 0.84)"
                fillOpacity={1}
              />
              <Area
                type="monotone"
                dataKey="sc"
                stackId="1"
                stroke="rgba(50, 28, 86, 0.38)"
                fill="rgba(50, 28, 86, 0.38)"
                fillOpacity={1}
              />
              <Area
                type="monotone"
                dataKey="rc"
                stackId="1"
                stroke="rgba(50, 28, 86, 0.08)"
                fill="rgba(50, 28, 86, 0.08)"
                fillOpacity={1}
              />
              <XAxis dataKey={'label'} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

const intlMessages = {
  cs: {
    title: 'Aktivita',
    finishedLabel: 'Dokončilo',
    startedLabel: 'Zahájilo',
    registeredLabel: 'Registrováno'
  },
  sk: {
    title: 'Aktivita',
    finishedLabel: 'Dokončilo',
    startedLabel: 'Zahájilo',
    registeredLabel: 'Registrovaní'
  },
  en: {
    title: 'Activity',
    finishedLabel: 'Finished',
    startedLabel: 'Started',
    registeredLabel: 'Registered'
  }
};

export default withIntl(ActivityStatusChart, intlMessages);
