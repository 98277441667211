import React from 'react';

import NewBadge from '../../badge/NewBadge';
import Personal from '../Personal';

const ParticipantNameCellContent = props => {
  const { isNew, showEmail, participant, messages } = props;
  const { firstName, lastName, email, personal, anonymous } = participant;
  const emailElement = email || <em>N/A</em>;
  const participantName =
    firstName || lastName
      ? `${lastName}${lastName && firstName ? ' ' : ''}${firstName}`
      : showEmail ? '' : emailElement;
  return (
    <div style={{ fontWeight: isNew ? 500 : 400 }}>
      {anonymous ? (
        <div className="applicant-name-value">
          <em className="text-muted">{messages.anonymous}</em>
        </div>
      ) : (
        <div className="applicant-name-value">
          {participantName}
          {showEmail && <div>{emailElement}</div>}
        </div>
      )}
      {isNew && (
        <div className="applicant-new-badge">
          <NewBadge />
        </div>
      )}
      {personal && (
        <div style={{ padding: '5px 0 0 5px', fontWeight: 400, fontSize: '12px' }}>
          <Personal {...personal} />
        </div>
      )}
    </div>
  );
};

export default ParticipantNameCellContent;
