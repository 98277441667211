import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import clr from 'color';

const Badge = ({ color = 'rgb(33,33,33)', inverted, style, className, text, title }) => {
  const colorObject = clr(color);
  const backgroundColor = colorObject.alpha(inverted ? 0.87 : 0.13).string();
  const textColor = inverted ? (colorObject.isDark() ? 'white' : 'rgba(33,33,33,0.87)') : colorObject.alpha(0.87).string();
  return (
    <div
      title={title}
      className={cn({ 'mui-badge': true, [className]: className })}
      style={{
        backgroundColor,
        color: textColor,
        ...style
      }}
    >
      <span>{text}</span>
    </div>
  );
};

Badge.propTypes = {
  text: PropTypes.any.isRequired,
  color: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string
};

export default Badge;
