import React from 'react';
import { connect } from 'react-redux';

import MembersActivitiesSelectDialog from './MembersActivitiesSelectDialog';

import { formatMessage } from '../../utils/utils';

const ActivityInviteDialog = props => {
  const { intl, ...rest } = props;
  const messages = intl.messages.components.teams.dialogs.activityInvite;
  return (
    <MembersActivitiesSelectDialog
      {...rest}
      renderMessage={(members, single, activityLabel) => {
        if (single) {
          return <span>{formatMessage(messages.messageSingle, [members, activityLabel])}</span>;
        } else {
          return <span>{formatMessage(messages.message, [members])}</span>;
        }
      }}
      cancelLabel={messages.cancelLabel}
      confirmLabel={messages.inviteLabel}
      confirmIcon={<i className="material-icons">email</i>}
    />
  );
};

export default connect(({ intl }) => ({ intl }))(ActivityInviteDialog);
