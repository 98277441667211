import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import values from 'lodash/values';
import reduce from 'lodash/reduce';
import keyBy from 'lodash/keyBy';

import { getProjects } from './base';
import { getCurrentProject, selectParticipants } from './index';
import { get, applyFilters, formatDuration, resolveGridOptions } from '../utils/utils';
import { getProjectsForCrossComparison } from '../utils/analytics';
import {
  EActivityStatus,
  EApplicationStatus,
  EEducation,
  EWorkExperienceYears,
  EWorkExperiencePosition,
  ESeekerStatus,
  ECurrency,
  EBool
} from '../constants/enum';
import { getFilterValues, createSingleItemExtract, createFromValuesMatcher, matchesString } from './filterUtils';

const getApplicantsGrid = state => state.options.grids.applicantsGrid;
const getParticipantFilters = state => state.options.participantFilters;
const baseSelectProjectParticipantsGroupsMembers = state => state.entities.projectParticipants.groups.members;

const matchesFullTextFilter = ({ participant: { firstName, lastName, email }, simulation: { identifier } }, flt) =>
  !flt ||
  matchesString(firstName, flt) ||
  matchesString(lastName, flt) ||
  matchesString(email, flt) ||
  matchesString(identifier, flt) ||
  (firstName &&
    lastName &&
    (matchesString(firstName + ' ' + lastName, flt) || matchesString(lastName + ' ' + firstName, flt)));

export const getFilteredParticipants = createSelector(
  [selectParticipants, getParticipantFilters, getCurrentProject],
  (participants, participantFilters, project) => {
    let filteredParticipants = participants.items;
    const participantFilter = (project && participantFilters[project.id]) || {};
    const { open, fullText, fromValues } = participantFilter;
    const fullTextFilter = fullText && fullText.toLowerCase();
    if (open) {
      const fromValuesEmpty = isEmpty(fromValues);
      if (fullTextFilter || !fromValuesEmpty) {
        filteredParticipants = filter(
          participants.items,
          a =>
            matchesFullTextFilter(a, fullTextFilter) &&
            (fromValuesEmpty || createFromValuesMatcher(FILTER_VALUES_EXTRACTORS)(a, fromValues))
        );
      }
    }
    return filteredParticipants;
  }
);

export const getGridOptions = createSelector(
  [getApplicantsGrid, selectParticipants, getFilteredParticipants, getCurrentProject],
  (grid, { pagination }, items, project) => {
    const options = resolveGridOptions(grid, project && project.slug);
    options.totalFilteredElements = items ? items.length : pagination ? pagination.totalElements : 0;
    options.totalPages = Math.ceil((options.totalFilteredElements || 0) / (options.pageSize || 10));
    if (options.pageNumber == undefined || options.pageNumber >= options.totalPages) {
      options.pageNumber = 0;
    }
    return options;
  }
);

export const getFilteredAndEvaluatedParticipants = createSelector([getFilteredParticipants], filteredParticipants =>
  filter(filteredParticipants, p => p.result && p.result.metrics)
);

export const getFilteredAndPaginatedParticipants = createSelector(
  [selectParticipants, getFilteredParticipants, baseSelectProjectParticipantsGroupsMembers, getGridOptions],
  (participants, filteredParticipants, groupMemberships, options) => {
    const { additionalActivities } = participants;
    const filteredAndPaginated = applyFilters(filteredParticipants, options);
    const { results } = additionalActivities || {};
    if (isEmpty(results) && isEmpty(groupMemberships)) {
      return filteredAndPaginated;
    }
    return map(filteredAndPaginated, p => {
      const { id } = p;
      let updatedParticipant = p;
      const groups = (groupMemberships || {})[id];
      if (groups) {
        updatedParticipant = { ...updatedParticipant, groups };
      }
      const activities = (results || {})[id];
      const finishedActivities = filter(activities || [], a => a.result && a.result.metrics);
      if (finishedActivities.length > 0) {
        updatedParticipant = { ...updatedParticipant, finishedAdditionalActivities: finishedActivities };
      }
      return updatedParticipant;
    });
  }
);

export const selectProjectsForCrossComparison = createSelector(
  [getCurrentProject, getProjects],
  getProjectsForCrossComparison
);

/*
  RANGE:
    - min
    - max
    - start
    - end
    - filteredStart
    - filteredEnd
    - step
 */

const CURRENT_YEAR = new Date().getFullYear();

const fGet = (obj, path) => get(obj, path, []) || [];

const removeHtml = val =>
  val
    ? val
        .replace(/\\n/g, '')
        .replace(/<br\/?>/gi, '\n')
        .replace(/<\/?\w+[^>]*>/g, '')
        .replace(/&\w+;/g, '')
    : '';

const FILTER_VALUES_EXTRACTORS = {
  tags: {
    key: 'tags',
    extract: p => {
      const tags = fGet(p, 'participant.tags');
      if (tags.length === 0) {
        return [{ value: 'emptyTag', none: true }];
      }
      return map(tags, t => ({ value: t.tagId, label: t.name, color: t.color }));
    },
    supportsMatchAll: true
  },
  activityStatus: {
    key: 'activityStatus',
    type: 'ENUM',
    enum: EActivityStatus,
    enumName: 'EActivityStatus',
    extract: createSingleItemExtract('status')
  },
  applicationStatus: {
    key: 'applicationStatus',
    type: 'ENUM',
    enum: EApplicationStatus,
    enumName: 'EApplicationStatus',
    extract: createSingleItemExtract('applicationStatus')
  },
  metricsScore: {
    key: 'metricsScore',
    type: 'RANGE',
    extract: createSingleItemExtract('result.metrics.scoreTotal.value', v =>
      v != undefined ? Math.round(v * 100) / 10 : null
    ),
    min: 0,
    max: 10,
    step: 0.1
  },
  metricsDuration: {
    key: 'metricsDuration',
    type: 'RANGE',
    extract: p => {
      const tasksDuration = get(p, 'result.metrics.durationTasks.value') || 0;
      const prepDuration = get(p, 'result.metrics.durationPreparation.value') || 0;
      const value = tasksDuration + prepDuration;
      if (value > 0) {
        return [{ value }];
      }
      return [];
    },
    min: 600,
    max: 9000,
    step: 60,
    formatter: v => formatDuration(v, true)
  },
  registeredAt: {
    key: 'registeredAt',
    type: 'DATE',
    extract: createSingleItemExtract('entryStatus.timestamp')
  },
  finishedAt: {
    key: 'finishedAt',
    type: 'DATE',
    extract: createSingleItemExtract('simulation.finishedAt')
  },
  seekerStatus: {
    key: 'seekerStatus',
    type: 'ENUM',
    enum: ESeekerStatus,
    enumName: 'ESeekerStatus',
    extract: createSingleItemExtract('participant.personal.seekerStatus')
  },
  bioAge: {
    key: 'bioAge',
    type: 'RANGE',
    extract: p => {
      const yob = get(p, 'participant.personal.yearOfBirth');
      if (yob != undefined) {
        return [{ value: CURRENT_YEAR - yob }];
      }
      return [];
    },
    min: 12,
    max: 70
  },
  skillsLanguages: {
    key: 'skillsLanguages',
    extract: p => map(fGet(p, 'participant.personal.skills.languages'), ({ id, label }) => ({ value: id, label })),
    supportsMatchAll: true
  },
  educationFields: {
    key: 'educationFields',
    extract: p => fGet(p, 'participant.personal.education.fields'),
    supportsMatchAll: true
  },
  educationLevel: {
    key: 'educationLevel',
    type: 'ENUM',
    enum: EEducation,
    enumName: 'EEducation',
    extract: createSingleItemExtract('participant.personal.education.education')
  },
  educationCurrentlyStudying: {
    key: 'educationCurrentlyStudying',
    type: 'ENUM',
    enum: EBool,
    enumName: 'EBool',
    extract: createSingleItemExtract('participant.personal.education.currentlyStudying', v =>
      v === true ? EBool.YES.key : v === false ? EBool.NO.key : null
    )
  },
  employmentWorkExperience: {
    key: 'employmentWorkExperience',
    type: 'ENUM',
    enum: EWorkExperienceYears,
    enumName: 'EWorkExperienceYears',
    extract: createSingleItemExtract('participant.personal.employment.workExperience')
  },
  employmentLastWorkPosition: {
    key: 'employmentLastWorkPosition',
    type: 'ENUM',
    enum: EWorkExperiencePosition,
    enumName: 'EWorkExperiencePosition',
    extract: createSingleItemExtract('participant.personal.employment.lastWorkPosition')
  },
  preferencesRoles: {
    key: 'preferencesRoles',
    extract: p => fGet(p, 'participant.personal.preferences.roles'),
    supportsMatchAll: true
  },
  preferencesSpecializations: {
    key: 'preferencesSpecializations',
    extract: p => fGet(p, 'participant.personal.preferences.specializations'),
    supportsMatchAll: true
  },
  preferencesIndustries: {
    key: 'preferencesIndustries',
    extract: p => fGet(p, 'participant.personal.preferences.industries'),
    supportsMatchAll: true
  },
  preferencesOpportunityTypes: {
    key: 'preferencesOpportunityTypes',
    extract: p => fGet(p, 'participant.personal.preferences.opportunityTypes'),
    supportsMatchAll: true
  },
  preferencesLocations: {
    key: 'preferencesLocations',
    extract: p => fGet(p, 'participant.personal.preferences.locations'),
    supportsMatchAll: true
  },
  preferencesPersonalDevelopmentAreas: {
    key: 'preferencesPersonalDevelopmentAreas',
    extract: p => fGet(p, 'participant.personal.preferences.personalDevelopmentAreas'),
    supportsMatchAll: true
  },
  preferencesBenefits: {
    key: 'preferencesBenefits',
    extract: p => fGet(p, 'participant.personal.preferences.benefits'),
    supportsMatchAll: true
  },
  preferencesLoveBrands: {
    key: 'preferencesLoveBrands',
    extract: p =>
      flatMap(fGet(p, 'participant.personal.preferences.loveBrands'), b =>
        map(b.value ? b.value.split(/\s*,\s*/) : [], v => ({ value: v.trim() }))
      ),
    supportsMatchAll: true
  },
  custom: {
    key: 'custom',
    custom: true,
    extract: p => {
      return reduce(
        filter(fGet(p, 'userProjectData.customParticipantData.items'), i => i.reported),
        (res, { name, label, value }) => {
          const prev = res[name] || { items: [], key: `custom_${name}`, label };
          return {
            ...res,
            [name]: {
              ...prev,
              items: [...prev.items, { value: removeHtml(value) }]
            }
          };
        },
        {}
      );
    }
  },
  // preferencesSalaryCurrency: {
  //   key: 'preferencesSalaryCurrency',
  //   type: 'ENUM',
  //   enum: ECurrency,
  //   enumName: 'ECurrency',
  //   extract: createSingleItemExtract('participant.personal.preferences.salaryRange.currency')
  // }
  preferencesSalaryRange: {
    key: 'preferencesSalaryRange',
    type: 'RANGE',
    extract: p => {
      const res = [];
      const curVal = get(p, 'participant.personal.preferences.salaryRange.currency', 'CZK');
      const cur = ECurrency[curVal];
      const min = get(p, 'participant.personal.preferences.salaryRange.min');
      if (min != undefined) {
        res.push({ value: min * (cur.baseRate || 1) });
      }
      const max = get(p, 'participant.personal.preferences.salaryRange.max');
      if (max != undefined) {
        res.push({ value: max * (cur.baseRate || 1) });
      }
      return res;
    },
    min: 0,
    max: 200000,
    step: 1000
  },
  skillsOther: {
    key: 'skillsOther',
    extract: p => map(fGet(p, 'participant.personal.skills.other'), s => ({ value: s.value.toLowerCase().trim() })),
    supportsMatchAll: true
  }
};

/*
  OUTPUTS:
    - range: { min: X, max: Y },
    - other: { items: [{ value: '', label: '', count: '' }], type: 'ENUM'? }
 */

const EXTRACTORS_LIST = values(FILTER_VALUES_EXTRACTORS);

export const selectParticipantsFilterValues = createSelector(
  [selectParticipants, getParticipantFilters, getCurrentProject, getFilteredParticipants],
  (participants, filters, project, filteredParticipants) => {
    const filter = (filters && project && filters[project.id]) || {};
    const { fromValues } = filter;
    return getFilterValues(EXTRACTORS_LIST, participants.items || [], filteredParticipants, fromValues);
  }
);

export const selectParticipantSelection = createSelector(
  [getFilteredParticipants, getGridOptions],
  (participants, gridOptions) => {
    const { selectedApplicants } = gridOptions;
    const participantMap = keyBy(participants, p => `${p.projectId}/${p.participant.id}`);
    const counts = { total: 0, finished: 0 };
    const list = [];
    for (let participantId in selectedApplicants) {
      if (selectedApplicants.hasOwnProperty(participantId)) {
        const participant = participantMap[participantId];
        if (participant) {
          counts.total += 1;
          if (participant.result && participant.result.metrics) {
            counts.finished += 1;
          }
          list.push(participant);
        }
      }
    }
    return {
      map: selectedApplicants,
      list,
      counts
    };
  }
);
