import React from 'react';
import { connect } from 'react-redux';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';

import MetricIcon from '../icons/Metric';
import Tooltip from '../tooltip/Tooltip';

import withIntl from '../withIntl';
import { ERank } from '../../constants/enum';

const SectionType = {
  SIGNIFICANT: {
    key: 'SIGNIFICANT'
  },
  MODERATE: {
    key: 'MODERATE'
  }
};

const renderSingleRecommendation = (t, significant) => {
  return (
    <div
      className="dual-metric-recommendation__item mui-padded-vertical-half"
      style={{ fontSize: significant ? '14px' : '13px' }}
    >
      <p dangerouslySetInnerHTML={{ __html: t }} />
    </div>
  );
};

const LEFT_PADDING = 24;

const RecommendationProperty = {
  MOTIVATION: {
    key: 'MOTIVATION',
    prop: 'motivation'
  },
  MANAGEMENT: {
    key: 'MANAGEMENT',
    prop: 'management'
  },
  REMUNERATION: {
    key: 'REMUNERATION',
    prop: 'remuneration'
  }
};

const RECOMMENDATION_PROPERTIES = [
  RecommendationProperty.MOTIVATION,
  RecommendationProperty.MANAGEMENT,
  RecommendationProperty.REMUNERATION
];

const DualMetricRecommendation = props => {
  const { metric, significant, type } = props;
  const r = metric.interpretation?.recommendations || {};
  const { motivation, management, remuneration } = r;
  const opposite = metric.value < 0.5;
  return (
    <div className="dual-metric-recommendation container-flex-row ai-center">
      <div className="tooltip-element" style={{ padding: significant ? 0 : '2px' }}>
        <MetricIcon metric={metric} opposite={opposite} size={significant ? 24 : 20} color={metric.color} />
        <Tooltip position="bottom">{opposite ? metric.oppositeLabel : metric.label}</Tooltip>
      </div>
      <div>
        {type ? (
          <div className="row">
            <div className="col-xs-12">{renderSingleRecommendation(r[type.prop], significant)}</div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-4">{renderSingleRecommendation(motivation, significant)}</div>
            <div className="col-md-4">{renderSingleRecommendation(management, significant)}</div>
            <div className="col-md-4">{renderSingleRecommendation(remuneration, significant)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const getData = metrics => {
  return _reduce(
    metrics,
    (res, m) => {
      const rank = m.rank;
      const r = m.interpretation?.recommendations || {};
      if (r.motivation || r.management || r.remuneration) {
        const target = rank === ERank.LOW.key || rank === ERank.HIGH.key ? res.significant : res.moderate;
        target.push(m);
      }
      return res;
    },
    { significant: [], moderate: [] }
  );
};

const renderSectionSymbol = () => (
  <i className="material-icons text-muted" style={{ fontSize: '16px', width: '12px' }}>
    add
  </i>
);

const renderRecommendationHeader = (type, messages) => {
  const msg = messages.recommendations[type.prop];
  return (
    <div className="dual-metrics-recommendation__header">
      <div className="mui-label">{msg.title}</div>
      <strong>{msg.subtitle}</strong>
    </div>
  );
};

const renderSection = (type, data, small, messages) => {
  const msg = messages.sections[type.key];
  let significant = type === SectionType.SIGNIFICANT;
  return (
    <div>
      <div
        className="dual-metrics-recommendation__section-header-container"
        style={{ paddingLeft: LEFT_PADDING, position: 'relative' }}
      >
        <div style={{ position: 'absolute', top: '16px', left: 0, width: LEFT_PADDING, textAlign: 'center' }}>
          {renderSectionSymbol()}
          {significant && renderSectionSymbol()}
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="dual-metrics-recommendation__section-header">
              <span className="text-muted">{msg.title}</span>
            </div>
          </div>
        </div>
      </div>
      {data.length === 0 ? (
        <div style={{ paddingLeft: LEFT_PADDING }}>
          <div className="row">
            <div className="col-xs-12">
              <div className="mui-padded-x2 text-muted bg-muted" style={{ borderRadius: '2px', margin: '4px 0 8px' }}>
                <span style={{ fontSize: '12px' }}>{msg.emptyMessage}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {small ? (
            <div>
              {_map(RECOMMENDATION_PROPERTIES, p => (
                <div key={p.key}>
                  <div style={{ paddingLeft: LEFT_PADDING }}>
                    <div className="row">
                      <div className="col-xs-12">{renderRecommendationHeader(p, messages)}</div>
                    </div>
                  </div>
                  {_map(data, m => (
                    <div key={m.key} className="mui-padded-vertical-half">
                      <DualMetricRecommendation metric={m} significant={significant} type={p} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            _map(data, m => (
              <div key={m.key} className="mui-padded-vertical-half">
                <DualMetricRecommendation metric={m} significant={significant} />
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

const DualMetricsRecommendation = props => {
  const {
    metrics,
    intl: { messages },
    ui
  } = props;
  const data = getData(metrics);
  if (data.significant.length + data.moderate.length <= 0) {
    return null;
  }
  const small = ui.width < 992;
  return (
    <div className="dual-metrics-recommendation">
      {!small && (
        <div className="dual-metrics-recommendation__header-container" style={{ paddingLeft: LEFT_PADDING }}>
          <div className="row">
            <div className="col-md-4">{renderRecommendationHeader(RecommendationProperty.MOTIVATION, messages)}</div>
            <div className="col-md-4">{renderRecommendationHeader(RecommendationProperty.MANAGEMENT, messages)}</div>
            <div className="col-md-4">{renderRecommendationHeader(RecommendationProperty.REMUNERATION, messages)}</div>
          </div>
        </div>
      )}
      {renderSection(SectionType.SIGNIFICANT, data.significant, small, messages)}
      {renderSection(SectionType.MODERATE, data.moderate, small, messages)}
    </div>
  );
};

const intlMessages = {
  cs: {
    sections: {
      SIGNIFICANT: {
        title: 'Výrazně preferované faktory pracovní kultury a motivace',
        emptyMessage: 'Účastník není výrazně profilovaný (vyhraněný)'
      },
      MODERATE: {
        title: 'Spíše preferované faktory pracovní kultury a motivace',
        emptyMessage: 'Účastník je pouze výrazně profilovaný (vyhraněný)'
      }
    },
    recommendations: {
      motivation: {
        title: 'Motivovat',
        subtitle: 'Co jej bude motivovat'
      },
      management: {
        title: 'Managovat',
        subtitle: 'Jak efektivně vést'
      },
      remuneration: {
        title: 'Odměňovat',
        subtitle: 'Jak efektivně odměňovat'
      }
    }
  },
  en: {
    sections: {
      SIGNIFICANT: {
        title: 'Strongly preferred factors of work culture and motivation',
        emptyMessage: 'The participant is rather ambivalent, doesn\'t show strong preferences in any of the dimensions.'
      },
      MODERATE: {
        title: 'Rather preferred factors of work culture and motivation',
        emptyMessage: 'The participant shows strong preferences in all dimensions.'
      }
    },
    recommendations: {
      motivation: {
        title: 'Motivate',
        subtitle: 'What will motivate them'
      },
      management: {
        title: 'Manage',
        subtitle: 'How to lead them'
      },
      remuneration: {
        title: 'Reward',
        subtitle: 'How to reward them'
      }
    }
  },
  sk: {
    sections: {
      SIGNIFICANT: {
        title: 'Výrazne preferované faktory pracovnej kultúry a motivácie',
        emptyMessage: 'Účastník nie je výrazne profilovaný (vyhranený)'
      },
      MODERATE: {
        title: 'Ďalšie preferované faktory pracovnej kultúry a motivácie',
        emptyMessage: 'Účastník je výlučne výrazne profilovaný (vyhranený)'
      }
    },
    recommendations: {
      motivation: {
        title: 'Motivovať',
        subtitle: 'Čo ho bude motivovať'
      },
      management: {
        title: 'Manažovať',
        subtitle: 'Ako efektívne viesť'
      },
      remuneration: {
        title: 'Odmeňovať',
        subtitle: 'Ako efektívne odmeňovať'
      }
    }
  }
};

const mapStateToProps = ({ ui }) => ({ ui });

export default connect(mapStateToProps)(withIntl(DualMetricsRecommendation, intlMessages));
