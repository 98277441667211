import { handleActions } from 'redux-actions';
import { GROUPS_GET } from '../../constants/constants';

const defaultState = {
  items: [],
  pagination: {},
  loading: false
};

export default handleActions(
  {
    [GROUPS_GET.PENDING]: state => ({ ...state, loading: true }),
    [GROUPS_GET.ERROR]: state => ({ ...state, loading: false }),
    [GROUPS_GET.SUCCESS]: (state, { payload }) => ({
      ...state,
      items: payload.items,
      pagination: payload.page,
      loading: false
    })
  },
  defaultState
);
