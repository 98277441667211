import React from 'react';
import PropTypes from 'prop-types';

import Chip from '../mui/Chip';
import SuperSelect from '../superfield/SuperSelect';

import withIntl from '../withIntl';

class MembersHighlightSelect extends React.Component {
  state = {
    dataSource: [],
    values: []
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.members !== this.props.members || prevProps.highlighted !== this.props.highlighted) {
      this.updateData();
    }
  }

  updateData = () => {
    const { members, highlighted } = this.props;
    const dataSource = [];
    const values = [];
    for (let i = 0; i < members.length; i += 1) {
      const m = members[i];
      if (m.meta.activities.finished.length > 0) {
        const { id, firstName, lastName, email } = m;
        dataSource.push({
          value: id,
          label: lastName && firstName ? `${lastName} ${firstName}` : email,
          description: lastName && firstName ? email : null
        });
      }
      if (highlighted[m.id]) {
        values.push(m.id);
      }
    }
    this.setState({ dataSource, values });
  };

  render() {
    const {
      highlighted,
      onToggleHighlighted,
      intl: { messages }
    } = this.props;
    const { dataSource, values } = this.state;
    return (
      <SuperSelect
        label={messages.highlightLabel}
        helperText={messages.highlightHelperText}
        multi
        filterable
        values={values}
        dataSource={dataSource}
        style={{ margin: 0 }}
        onChange={values => {
          const h = { ...highlighted };
          for (let i = 0; i < values.length; i += 1) {
            const id = values[i];
            if (!h[id]) {
              onToggleHighlighted({ id });
            } else {
              delete h[id];
            }
          }
          for (let id in h) {
            if (h.hasOwnProperty(id)) {
              onToggleHighlighted({ id });
            }
          }
        }}
        renderChip={({ item, onRequestDelete }) => (
          <Chip onRequestDelete={() => onRequestDelete(item)} variant={'compact'}>
            <div className="circle-indicator ci-size-8" style={{ backgroundColor: highlighted[item.value] }} />
            {item.label}
          </Chip>
        )}
      />
    );
  }
}

MembersHighlightSelect.propTypes = {
  members: PropTypes.array.isRequired,
  highlighted: PropTypes.object.isRequired,
  onToggleHighlighted: PropTypes.func.isRequired
};

const intlMessages = {
  cs: {
    highlightLabel: 'Zvýraznění členové',
    highlightHelperText: 'Vyberte členy tímu, které chcete v analytice zvýraznit'
  },
  sk: {
    highlightLabel: 'Zvýraznení členovia',
    highlightHelperText: 'Vyberte členov tímu, ktorých chcete v analytike zvýrazniť'
  },
  en: {
    highlightLabel: 'Highlighted members',
    highlightHelperText: 'Select the team members you want to highlight in the analytics'
  }
};

export default withIntl(MembersHighlightSelect, intlMessages);
