import React from 'react';
import { withRouter } from 'react-router';
import _map from 'lodash/map';

import ParticipantsSelectPanel from '../applicant/ParticipantsSelectPanel';
import Overlay from '../basic/Overlay';
import Button from '../mui/Button';

import withIntl from '../withIntl';

import { formatMessage } from '../../utils/utils';

const COMPARISON_LIMIT = 7;

class ComparisonParticipantsSelect extends React.Component {
  state = {
    selection: []
  };

  componentDidMount() {
    this.setState({ selection: this.props.preselected });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.preselected !== this.props.preselected) {
      this.setState({ selection: this.props.values });
    }
  }

  handleClose = () => {
    const { switchModal, id } = this.props;
    switchModal({ id, open: false });
  };
  handleConfirm = () => {
    const { router, location, project } = this.props;
    const { selection } = this.state;

    const ids = _map(selection, ({ projectId, participant: { id } }) => {
      if (projectId !== project.id) {
        return `${id}/${projectId}`;
      }
      return id;
    });
    router.push({
      ...location,
      pathname: `/projects/overview/${project.company.slug}/${project.slug}/participants/compare`,
      query: { ...location.query, ids }
    });

    this.handleClose();
  };
  handleSelectionChange = selection => this.setState({ selection });

  render() {
    const { open, participants, preselected, intl: { messages } } = this.props;
    if (!open) {
      return null;
    }
    return (
      <Overlay full onRequestClose={this.handleClose}>
        <div
          style={{ maxWidth: 552, margin: '0 auto', height: '100%', padding: '32px 16px' }}
          className="container-flex-column jc-center"
        >
          <div
            className="mui-card"
            style={{ maxHeight: '100%', overflow: 'auto', animation: 'vb-appear 0.2s ease-in-out' }}
          >
            <div className="mui-padded-x2 text-muted" style={{ fontSize: '14px' }}>
              <div className="mui-padded-vertical">{messages.message}</div>
              <div
                className="mui-padded-vertical bg-primary-light"
                style={{ margin: '8px -16px', maxHeight: 336, overflow: 'auto' }}
              >
                <ParticipantsSelectPanel
                  participants={participants}
                  preselected={preselected}
                  withFilter={participants.length > 8}
                  onSelectionChange={this.handleSelectionChange}
                  limit={COMPARISON_LIMIT}
                />
              </div>
              <div className="text-muted small">{formatMessage(messages.helperText, [<b key={'limit'}>7</b>])}</div>
            </div>
            <div className="mui-padded-half text-right">
              <Button label={messages.cancelLabel} onClick={this.handleClose} style={{ margin: 4 }} />
              <Button primary label={messages.compareLabel} style={{ margin: 4 }} onClick={this.handleConfirm} />
            </div>
          </div>
        </div>
      </Overlay>
    );
  }
}

const intlMessages = {
  cs: {
    cancelLabel: 'Zrušit',
    compareLabel: 'Porovnat',
    message: 'Porovnejte účastníky mezi sebou',
    helperText: 'Vyberte maximálně {0} účastníků'
  },
  sk: {
    cancelLabel: 'Zrušiť',
    compareLabel: 'Porovnať',
    message: 'Porovnajte účastníkov medzi sebou',
    helperText: 'Vyberte maximálne {0} účastníkov'
  },
  en: {
    cancelLabel: 'Cancel',
    compareLabel: 'Compare',
    message: 'Compare participants side-by-side',
    helperText: 'Select up to {0} participants'
  }
};

export default withIntl(withRouter(ComparisonParticipantsSelect), intlMessages);
