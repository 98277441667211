import React from 'react';
import { connect } from 'react-redux';

import LockedPlaceholder from '../../../components/placeholder/LockedPlaceholder';

import { EUpgradeRequestSource } from '../../../constants/enum';
import withIntl from '../../../components/withIntl';

import { invokeUpgradeAccountModal } from '../../../actions/entities/accountActions';

const WeightsLockedPlaceholder = props => {
  const {
    intl: { messages },
    onUpgrade
  } = props;
  return (
    <LockedPlaceholder
      message={messages.message}
      image={
        <div style={{ paddingBottom: '16px' }}>
          <img src="/img/misc/teasers/weights_v3.svg" width="100%" alt="Weights teaser" />
        </div>
      }
      upgradeAccount={onUpgrade}
      source={EUpgradeRequestSource.WEIGHTS_CONTROLS}
    />
  );
};

const intlMessages = {
  cs: {
    message:
      'Nastavte důležitost jednotlivým kompetencím a přizpůsobte tak výsledky pro konkrétní projekt. Tato funkce je dostupná v {0[plné verzi]}'
  },
  sk: {
    message:
      'Nastavte dôležitosť jednotlivým kompetenciám a prispôsobte tak výsledky pre konkrétny projekt. Táto funkcia je dostupná v {0[plnej verzii]}'
  },
  en: {
    message:
      'Set the importance to individual competencies and tailor the results to the specific project requirements. {0[Upgrade]} to use this feature'
  }
};

export default connect(null, { onUpgrade: invokeUpgradeAccountModal })(
  withIntl(WeightsLockedPlaceholder, intlMessages)
);
