import { debounce } from 'lodash';

import { GUIDE_SHOW, GUIDE_HIDE, GUIDE_NEXT_STEP, GUIDE_PREV_STEP, GUIDE_FINISH } from '../../constants/constants';
import * as Api from '../../api/options/guideApi';

const TYPES = [GUIDE_SHOW, GUIDE_HIDE, GUIDE_NEXT_STEP, GUIDE_PREV_STEP, GUIDE_FINISH];

export default function analyticsMiddleware({ getState }) {
  const debouncedSetGuideState = debounce(Api.setGuideState, 1000);
  return next => action => {
    if (TYPES.indexOf(action.type) !== -1) {
      const res = next(action);
      const { options: { guide }, auth: { id } } = getState();
      debouncedSetGuideState(id, guide);
      return res;
    }
    return next(action);
  };
};
