import React from 'react';
// import { Collapse } from 'react-collapse';
import fuzzysort from 'fuzzysort';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import forEachRight from 'lodash/forEachRight';
import filter from 'lodash/filter';
import VisSensor from 'react-visibility-sensor';

import cn from 'classnames';

import Checkbox from 'material-ui/Checkbox';
import IconButton from 'material-ui/IconButton';
import FlatButton from 'material-ui/FlatButton';

import SomeCheckIcon from 'material-ui/svg-icons/toggle/indeterminate-check-box';
import FullCheckIcon from 'material-ui/svg-icons/toggle/check-box';
import UpArrowIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import DownArrowIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';

import SuperText from './SuperText';

import { KC, determineKeyCode } from './Utils';

const Collapse = props => {
  const { isOpened, children } = props;

  return (
    <div style={{ display: isOpened ? 'block' : 'none' }}>
      {children}
    </div>
  );
};

class TreeNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  toggleExpanded = e => {
    const { item, toggleExpanded } = this.props;
    toggleExpanded(item, e);
  };

  toggleSelected = e => {
    const { item, parent, toggleSelected } = this.props;
    toggleSelected(item, parent, e);
  };

  render() {
    const { item, itemState, level, renderer, multi, highlight, highlighted } = this.props;
    const config = {
      toggleExpanded: this.toggleExpanded,
      toggleSelected: this.toggleSelected,
      itemState,
      highlighted,
      highlight,
      multi,
      level
    };
    return <div className="supsel-node">{renderer(item, config)}</div>;
  }
}

const highlightText = (text, highlight) => {
  if (!highlight || highlight.length === 0) {
    return text;
  }
  const res = [];
  let start = -1;
  let end = -1;
  for (let i = 0; i < highlight.length; i += 1) {
    const idx = highlight[i];
    if (start < 0) {
      if (idx > 0) {
        res.push(text.substring(0, idx));
      }
      start = idx;
    } else if (end !== idx) {
      res.push(<b key={start}>{text.substring(start, end)}</b>);
      res.push(text.substring(end, idx));
      start = idx;
    }
    end = idx + 1;
  }
  res.push(<b key={start}>{text.substring(start, end)}</b>);
  if (end < text.length) {
    res.push(text.substring(end));
  }
  return res;
};

const removeAccents = it => it && it.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const MuiTreeNode = props => {
  return (
    <TreeNode
      {...props}
      renderer={(item, { level, itemState, highlight, highlighted, multi, toggleSelected, toggleExpanded }) => {
        const paddingLeft = `${16 + level * 24}px`;
        const disabled = item.disabled;
        const itemStyle = { paddingLeft };
        if (disabled) {
          itemStyle.opacity = 0.5;
          itemStyle.cursor = 'not-allowed';
        }
        if (item.header) {
          return (
            <div className="mui-tree-menu-item" style={itemStyle}>
              <div className="mui-tree-menu-item-content header">{item.label}</div>
            </div>
          );
        }
        const selected = itemState.selection !== 'NONE';
        return (
          <div
            className={cn({ 'mui-tree-menu-item': true, highlighted, selected })}
            style={itemStyle}
            onClick={!disabled && toggleSelected}
            role="menuitem"
            tabIndex="0"
          >
            {multi && (
              <div>
                <Checkbox
                  checked={selected}
                  iconStyle={{ left: 0 }}
                  checkedIcon={itemState.selection === 'FULL' ? <FullCheckIcon /> : <SomeCheckIcon />}
                  onCheck={!disabled && toggleSelected}
                />
              </div>
            )}
            <div className="mui-tree-menu-item-content">
              <span>{highlight ? highlightText(item.label, highlight.label) : item.label}</span>
              {item.description && (
                <div className="mui-tree-menu-item-description">
                  {highlight ? highlightText(item.description, highlight.description) : item.description}
                </div>
              )}
            </div>
            {item.children &&
            item.children.length > 0 && (
              <div>
                <IconButton
                  style={{ margin: '-8px' }}
                  onClick={e => {
                    toggleExpanded(e);
                    e.stopPropagation();
                  }}
                >
                  {itemState.expanded ? <UpArrowIcon /> : <DownArrowIcon />}
                </IconButton>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

const DEFAULT_LIMIT = 10;
const DEFAULT_FILTER_LIMIT = 10;

class SuperSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: {},
      expansion: {},
      values: [],
      filterValue: '',
      focused: false,
      menuOpen: false,
      limit: props.limit || DEFAULT_LIMIT,
      highlightedIndex: 0,
      ...SuperSelect.processDataSource(props.dataSource)
    };
  }

  static processDataSource = ds => {
    const itemMap = {};
    const flattenedData = [];
    SuperSelect.traverseTree(ds || [], ({ item, parent }) => {
      const id = SuperSelect.getItemId(item);
      flattenedData.push({
        ...item,
        labelNormalized: removeAccents(item.label),
        descriptionNormalized: removeAccents(item.description),
        parent
      });
      if (!itemMap[id]) {
        itemMap[id] = {
          item,
          parent
        };
      }
    });
    return {
      itemMap,
      flattenedData
    };
  };
  static getMaxSelectionCount = item => Math.max(1, (item.children || []).length);
  static getItemId = item => (item ? item.value : null);
  static normalize = (count, maxCount) => (count === 0 ? 0 : count === maxCount ? 1 : 0.5);
  static traverseTree = (tree, itemCallback) => {
    const q = map(tree, item => ({ item, parent: null, level: 0 }));
    const visited = {};
    while (q.length) {
      const n = q.shift();
      const { item, level } = n;
      const id = SuperSelect.getItemId(item);
      if (!visited[id]) {
        if (itemCallback(n) !== false) {
          const children = item.children || [];
          forEach(children, c => q.push({ item: c, parent: item, level: item.header ? level : level + 1 }));
          visited[id] = true;
        }
      }
    }
  };
  static getItemSelectionState = (item, { selection }) => {
    const { count } = selection[SuperSelect.getItemId(item)] || { count: 0 };
    const maxCount = SuperSelect.getMaxSelectionCount(item);
    if (count === maxCount) {
      return 'FULL';
    }
    if (count > 0) {
      return 'SOME';
    }
    return 'NONE';
  };
  static buildSelectionFromItem = (item, parent, { selection, itemMap, multi }) => {
    const itemId = SuperSelect.getItemId(item);
    const oldSelection = selection[itemId];
    const maxCount = SuperSelect.getMaxSelectionCount(item);
    const newState = oldSelection && oldSelection.count === maxCount ? null : { count: maxCount };
    const newSelection = { [itemId]: newState };
    // PROPAGATE: children
    const q = [...(item.children || [])];
    while (q.length) {
      const c = q.shift();
      const cId = SuperSelect.getItemId(c);
      // just in case
      if (!newSelection[cId]) {
        newSelection[cId] = newState ? { count: SuperSelect.getMaxSelectionCount(c) } : null;
        forEach(c.children || [], cc => q.push(cc));
      }
    }
    // PROPAGATE: ancestors
    let curParent = parent;
    let change = newState ? 1 : -1;
    let topMostSelectedItem = change === 1 ? item : null;
    while (curParent && change !== 0) {
      const curParentId = SuperSelect.getItemId(curParent);
      if (!newSelection[curParentId]) {
        const oldCount = ((multi && selection[curParentId]) || { count: 0 }).count;
        const maxCount = SuperSelect.getMaxSelectionCount(curParent);
        const newCount = Math.min(maxCount, Math.max(0, oldCount + change));
        newSelection[curParentId] = { count: newCount };
        //
        change = SuperSelect.normalize(newCount, maxCount) - SuperSelect.normalize(oldCount, maxCount);
        if (change === 1) {
          topMostSelectedItem = curParent;
        }
        curParent = itemMap[curParentId].parent;
      } else {
        // stop, just in case
        curParent = null;
      }
    }
    return {
      selection: newSelection,
      topMostSelectedItem,
      selected: !!newState
    };
  };
  static buildSelectionFromValues = (values, { itemMap, multi }) => {
    let selection = {};
    for (let i = 0; i < values.length; i += 1) {
      const id = values[i];
      const { item, parent } = itemMap[id] || {};
      if (item) {
        const itemSelection = SuperSelect.buildSelectionFromItem(item, parent, { selection, multi, itemMap }).selection;
        selection = {
          ...selection,
          ...itemSelection
        };
      }
    }
    return selection;
  };
  static getValues = ({ dataSource, selection }) => {
    const values = [];
    SuperSelect.traverseTree(dataSource, n => {
      const selectionState = SuperSelect.getItemSelectionState(n.item, { selection });
      if (selectionState === 'FULL' && !n.item.header) {
        values.push(n.item.value);
        // do not traverse children
        return false;
      }
    });
    return values;
  };

  componentDidMount() {
    this.updateFromProps({}, this.props);
  }

  componentDidUpdate(prevProps) {
    this.updateFromProps(prevProps, this.props);
  }

  updateFromProps = (props, nextProps) => {
    const { onChange, dataSource, multi, filterable } = nextProps;
    let newState = {};
    let buildSelection = false;
    let { itemMap, values } = this.state;
    if (dataSource !== props.dataSource) {
      newState = SuperSelect.processDataSource(dataSource);
      itemMap = newState.itemMap;
      buildSelection = true;
    }
    if (onChange && props.values !== nextProps.values) {
      values = nextProps.values;
      const { item } = (values.length > 0 && itemMap[values[0]]) || {};
      newState = {
        ...newState,
        values,
        filterValue: item && !multi && filterable ? item.label : ''
      };
      buildSelection = true;
    }
    if (buildSelection) {
      newState.selection = SuperSelect.buildSelectionFromValues(values, { itemMap, multi });
      this.setState(newState);
    }
  };

  toggleSelected = (item, parent, e) => {
    const { selection, itemMap } = this.state;
    const { multi, filterable, onChange } = this.props;
    const itemSelection = SuperSelect.buildSelectionFromItem(item, parent, { selection, multi, itemMap });
    const newSelection = { ...(multi ? selection : {}), ...itemSelection.selection };
    const values = SuperSelect.getValues({ dataSource: this.props.dataSource, selection: newSelection });
    const topMostItem = itemSelection.topMostSelectedItem;
    const menuOpen = (multi && this.state.menuOpen) || !itemSelection.selected;
    const newState = {
      menuOpen,
      filterValue: topMostItem && !multi && filterable ? topMostItem.label : ''
    };
    if (onChange) {
      onChange(values);
    } else {
      newState.selection = { ...(multi ? selection : {}), ...itemSelection.selection };
      newState.values = values;
    }
    this.setState(newState);
    if (e && determineKeyCode(e) === KC.ENTER) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  isExpanded = item => {
    if (item.header) {
      return true;
    }
    const expanded = this.state.expansion[SuperSelect.getItemId(item)];
    // by default everything is expanded
    return expanded || typeof expanded === 'undefined';
  };

  toggleExpanded = item => {
    const id = SuperSelect.getItemId(item);
    this.setState({ expansion: { ...this.state.expansion, [id]: !this.isExpanded(item) } });
  };

  getSelectionState = item => SuperSelect.getItemSelectionState(item, { selection: this.state.selection });

  getItemState = item => {
    return {
      selection: this.getSelectionState(item),
      expanded: this.isExpanded(item)
    };
  };

  handleFilterInputChange = (e, selection) => {
    const { value } = e.target;
    const { multi, filterable } = this.props;
    if (!multi && filterable && value !== this.state.filterValue && selection && selection.length > 0) {
      const item = selection[selection.length - 1];
      this.toggleSelected(item, item.parent, e);
    }
    this.setState(() => ({
      filterValue: value,
      menuOpen: value !== this.state.filterValue || this.state.menuOpen,
      highlightedIndex: 0
    }));
  };

  handleKeyDown = (e, selection, menuItems, limit, filtered) => {
    const { filterValue, menuOpen } = this.state;
    const { multi, filterable } = this.props;
    const keyCode = determineKeyCode(e);
    const itemsLimit = Math.min(limit, menuItems.length);
    const { highlightedIndex } = this.state;
    if (keyCode === KC.ESC) {
      this.setState({ menuOpen: false, filterValue: !multi && filterable ? filterValue : '' });
    } else if (keyCode === KC.BACKSPACE && selection && selection.length > 0) {
      const item = selection[selection.length - 1];
      if ((multi && !filterValue) || filterValue === item.label) {
        this.toggleSelected(item, item.parent, e);
      }
    } else if (keyCode === KC.ENTER) {
      if (menuOpen) {
        const obj = menuItems[highlightedIndex];
        const item = filtered ? obj.obj : obj.item;
        if (!item.header) {
          this.toggleSelected(item, item.parent, e);
        }
      }
    } else if (keyCode === KC.UP) {
      if (menuOpen) {
        this.setState({ highlightedIndex: (itemsLimit + highlightedIndex - 1) % itemsLimit });
      } else {
        this.setState({ menuOpen: true });
      }
      e.preventDefault();
    } else if (keyCode === KC.DOWN) {
      if (menuOpen) {
        this.setState({ highlightedIndex: (highlightedIndex + 1) % itemsLimit });
      } else {
        this.setState({ menuOpen: true });
      }
      e.preventDefault();
    } else if (!filtered && (keyCode === KC.LEFT || keyCode === KC.RIGHT)) {
      const item = menuItems[highlightedIndex].item;
      this.toggleExpanded(item);
      e.preventDefault();
    }
  };

  handleRequestMenuClose = () => this.setState({ menuOpen: false });

  handleRequestMenuOpen = () => this.setState({ menuOpen: true });

  handleFocus = e => {
    this.setState({ focused: true, menuOpen: true });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  handleBlur = () => {
    this.setState({ focused: false, menuOpen: false });
    if (this.props.onBlur) {
      this.props.onBlur(this.state.values);
    }
  };

  handleIncreaseLimit = () => {
    this.setState({ limit: this.state.limit + (this.props.limit || DEFAULT_LIMIT) });
  };

  getShownItems = () => {
    const { dataSource } = this.props;
    const { limit } = this.state;
    const res = [];
    const q = [];
    forEachRight(dataSource, item => q.push({ item: { ...item, parent: null }, level: 0 }));
    let counter = 0;
    while (q.length) {
      const { item, parent, level } = q.pop();
      const itemState = this.getItemState(item);
      res.push({ item, itemState, parent, level });
      // do not count headers into limit
      if (!item.header) {
        counter += 1;
        if (counter >= limit) {
          break;
        }
      }
      const children = item.children || [];
      if (itemState.expanded) {
        forEachRight(children, c => q.push({ item: { ...c, parent: item }, level: item.header ? level : level + 1 }));
      }
    }
    return {
      items: res,
      limited: q.length > 0
    };
  };
  handleMenuContainerSet = el => {
    this.menuContainer = el;
  };

  render() {
    const TreeNodeElement = MuiTreeNode;
    const { multi, label, helperText, error, disabled, filterable, renderChip, style } = this.props;
    const { filterValue, flattenedData, itemMap, values, limit, highlightedIndex } = this.state;
    const items = map(values, v => {
      const { item, parent } = itemMap[v] || {};
      return { ...item, parent };
    });
    const filtering = !!filterValue && (multi || !items[0] || filterValue !== items[0].label);
    let filtered = [];
    if (filtering) {
      filtered = fuzzysort.go(removeAccents(filterValue), filter(flattenedData, d => !d.header), {
        keys: ['labelNormalized', 'descriptionNormalized'],
        limit: DEFAULT_FILTER_LIMIT,
        allowTypo: false
      });
    }
    const shownItems = this.getShownItems();
    const handleKeyDown = filtering
      ? e => this.handleKeyDown(e, items, filtered, DEFAULT_FILTER_LIMIT, true)
      : e => this.handleKeyDown(e, items, shownItems.items, limit);
    return (
      <SuperText
        label={label}
        helperText={helperText}
        value={this.state.filterValue}
        focused={this.state.focused}
        menuOpen={this.state.menuOpen}
        onChange={e => this.handleFilterInputChange(e, items, filtered)}
        onKeyDown={handleKeyDown}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onRequestMenuClose={this.handleRequestMenuClose}
        onRequestMenuOpen={this.handleRequestMenuOpen}
        onSetMenuContainer={this.handleMenuContainerSet}
        select
        filter={filterable}
        error={error}
        multi={multi}
        disabled={disabled}
        renderChip={renderChip}
        style={style}
        menu={
          <div className="super-menu-container">
            <Collapse isOpened={filtering}>
              {map(filtered, (res, idx) => (
                <TreeNodeElement
                  key={res.obj.value}
                  item={res.obj}
                  itemState={this.getItemState(res.obj)}
                  parent={res.obj.parent}
                  level={0}
                  multi={multi}
                  highlighted={idx === highlightedIndex}
                  highlight={{
                    label: res[0] && res[0].indexes,
                    description: res[1] && res[1].indexes
                  }}
                  toggleSelected={this.toggleSelected}
                  toggleExpanded={this.toggleExpanded}
                />
              ))}
            </Collapse>
            <Collapse isOpened={!filtering}>
              {map(shownItems.items, ({ item, itemState, level }, idx) => (
                <TreeNodeElement
                  key={item.value}
                  item={item}
                  itemState={itemState}
                  parent={item.parent}
                  level={level}
                  highlighted={idx === highlightedIndex}
                  multi={multi}
                  toggleSelected={this.toggleSelected}
                  toggleExpanded={this.toggleExpanded}
                />
              ))}
              <VisSensor
                key={`${shownItems.items.length}-${shownItems.limited}`}
                onChange={visible => visible && shownItems.limited && this.handleIncreaseLimit()}
                containment={this.menuContainer}
                partialVisibility
                offset={{ bottom: -50 }}
              >
                <div
                  style={{ width: '100%', minHeight: '1px', height: '1px', pointerEvents: 'none', marginTop: '-1px' }}
                />
              </VisSensor>
              {shownItems.limited && (
                <FlatButton
                  onClick={this.handleIncreaseLimit}
                  icon={<i className="material-icons">more_horiz</i>}
                  fullWidth
                />
              )}
            </Collapse>
          </div>
        }
        values={items}
        onRequestDeleteValue={item => this.toggleSelected(item, item.parent)}
      />
    );
  }
}

export default SuperSelect;
