import React from 'react';
import { connect } from 'react-redux';

import Button from '../../mui/Button';
import InfoCard from '../InfoCard';

import withIntl from '../../withIntl';

import * as participantActions from '../../../actions/entities/applicantActions';
import { formatMessage, selectQuantityMessage } from '../../../utils/utils';

const formatReminderThresholdConfig = ({ min }, messages) => {
  const msg = selectQuantityMessage(messages[min.unit], min.value);
  return `${min.value} ${msg}`;
};

const ReminderCandidatesInfoCard = ({ candidates, beginUnfinishedProfilesNotification, intl: { messages } }) => {
  return (
    <InfoCard
      bgIcon="notification_important"
      actions={[
        <Button
          key={'remind'}
          primary
          icon={<i className="material-icons">notification_important</i>}
          label={messages.remindLabel}
          onClick={() => beginUnfinishedProfilesNotification(candidates.participants)}
        />
      ]}
    >
      <div className="mui-padded">
        {formatMessage(selectQuantityMessage(messages.messages, candidates.participants.length), [
          <b key="pCount">{candidates.participants.length}</b>,
          <b key="tMsg">{formatReminderThresholdConfig(candidates.config, messages.thresholdMessages)}</b>
        ])}
      </div>
    </InfoCard>
  );
};

const intlMessages = {
  cs: {
    remindLabel: 'Připomenout',
    messages: {
      '1': 'Více než {1} od posledního připomenutí o nedokončené aktivitě {0} účasníka',
      '>1': 'Více než {1} od posledního připomenutí o nedokončené aktivitě {0} účastníků'
    },
    thresholdMessages: {
      day: {
        '0': 'dní',
        '1': 'den',
        '2-4': 'dny',
        '>4': 'dní'
      },
      week: {
        '0': 'týdnů',
        '1': 'týden',
        '2-4': 'týdny',
        '>4': 'týdnů'
      },
      month: {
        '0': 'měsíců',
        '1': 'měsíc',
        '2-4': 'měsíce',
        '>4': 'měsíců'
      },
      year: {
        '0': 'let',
        '1': 'rok',
        '2-4': 'roky',
        '>4': 'let'
      }
    }
  },
  sk: {
    remindLabel: 'Pripomenúť',
    messages: {
      '1': 'Viac ako {1} od posledného pripomenutia o nedokončenej aktivite {0} účastníka',
      '>1': 'Viac ako {1} od posledného pripomenutia o nedokončenej aktivite {0} účastníkov'
    },
    thresholdMessages: {
      day: {
        '0': 'dní',
        '1': 'deň',
        '2-4': 'dni',
        '>4': 'dní'
      },
      week: {
        '0': 'týždňov',
        '1': 'týždeň',
        '2-4': 'týždne',
        '>4': 'týždňov'
      },
      month: {
        '0': 'mesiacov',
        '1': 'mesiac',
        '2-4': 'mesiace',
        '>4': 'mesiacov'
      },
      year: {
        '0': 'rokov',
        '1': 'rok',
        '2-4': 'roky',
        '>4': 'rokov'
      }
    }
  },
  en: {
    remindLabel: 'Remind',
    messages: {
      '1': "There is {0} participant that hasn't been reminded of an unfinished activity for more than {1}",
      '>1': "There are {0} participants that haven't been reminded of an unfinished activity for more than {1}"
    },
    thresholdMessages: {
      day: {
        '0': 'days',
        '1': 'day',
        '>1': 'days'
      },
      week: {
        '0': 'weeks',
        '1': 'week',
        '>1': 'weeks'
      },
      month: {
        '0': 'months',
        '1': 'month',
        '>1': 'months'
      },
      year: {
        '0': 'years',
        '1': 'year',
        '>1': 'years'
      }
    }
  }
};

const actions = {
  beginUnfinishedProfilesNotification: participantActions.beginUnfinishedProfilesNotification
};

export default withIntl(connect(null, actions)(ReminderCandidatesInfoCard), intlMessages);
