import React from 'react';
import { pure } from 'recompose';
import Sticky from 'react-stickynode';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import filter from 'lodash/filter';
import VisSensor from 'react-visibility-sensor';

import IconButton from 'material-ui/IconButton';
import Popover from 'material-ui/Popover';
import Divider from 'material-ui/Divider';
import { Menu, MenuItem } from 'material-ui/Menu';
import { grey400, grey50 } from 'material-ui/styles/colors';

import { Grid, GridBody, GridFooter, GridHeader, GridPaginator, GridRow, GridCell } from '../grid';
import ApplicantListGridHeaderRow from './ApplicantListGridHeaderRow';
import QuestionnaireListGridHeaderRow from './QuestionnaireListGridHeaderRow';
import ApplicantListGridRow from './ApplicantListGridRow';
import QuestionnaireListGridRow from './QuestionnaireListGridRow';
import SelectionMessage from '../basic/messages/SelectionCountMessage';
import MenuItemText from '../mui/MenuItemText';
import Button from '../mui/Button';
import Tooltip from '../tooltip/Tooltip';
import GroupsPanel from './GroupsPanel';
import ProjectUserTags from './ProjectUserTags';

import { extractNotificationPreferences } from '../../utils/utils';
import { HEADER_SELECTOR } from '../../constants/constants';
import { EApplicationStatus, EProjectType, EParticipantBatchOperation } from '../../constants/enum';

export const STYLES = {
  alignRight: {
    textAlign: 'right'
  },
  checkboxCell: {
    padding: '15px 0px 15px 16px',
    overflow: 'visible'
  },
  emptyTable: {
    textAlign: 'center',
    verticalAlign: 'middle',
    height: '360px',
    cursor: 'default',
    color: grey400,
    backgroundColor: grey50
  }
};

const LazyRenderedRow = props => {
  const { children } = props;
  return (
    <VisSensor partialVisibility offset={{ bottom: -256, top: -256 }}>
      {({ isVisible }) =>
        isVisible ? (
          children
        ) : (
          <div style={{ minWidth: '1px', height: '64px', margin: '8px -16px', background: 'white' }} />
        )
      }
    </VisSensor>
  );
};

class ComparisonMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleOpenPopover = () => this.setState({ open: true });
  handleClosePopover = () => this.setState({ open: false });

  handleRequestCrossComparisonMenuOpen = () => {
    this.props.handleCrossCompareApplicants();
    this.handleClosePopover();
  };
  handleIntraCompare = () => {
    this.props.handleIntraCompare();
    this.handleClosePopover();
  };

  render() {
    const { messages, disabled } = this.props;
    const { open } = this.state;
    return (
      <div className="inline-container" ref={el => (this.anchorEl = el)}>
        <Button
          label={messages.compareButton}
          icon={<i className="material-icons">compare_arrows</i>}
          primary
          onClick={this.handleOpenPopover}
          disabled={disabled}
        />
        <Popover open={open} anchorEl={this.anchorEl} onRequestClose={this.handleClosePopover}>
          <Menu desktop>
            <MenuItem
              primaryText={
                <MenuItemText
                  label={messages.intraComparison.label}
                  description={messages.intraComparison.description}
                />
              }
              onClick={this.handleIntraCompare}
            />
            <MenuItem
              primaryText={
                <MenuItemText
                  label={messages.crossComparison.label}
                  description={messages.crossComparison.description}
                />
              }
              onClick={this.handleRequestCrossComparisonMenuOpen}
            />
          </Menu>
        </Popover>
      </div>
    );
  }
}

class IconMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleOpenPopover = () => this.setState({ open: true });
  handleClosePopover = () => this.setState({ open: false });

  render() {
    const { disabled, icon, children } = this.props;
    const { open } = this.state;
    return (
      <div className="inline-container" ref={el => (this.anchorEl = el)}>
        <IconButton style={{ width: 36, height: 36, padding: 0 }} disabled={disabled} onClick={this.handleOpenPopover}>
          {icon}
        </IconButton>
        <Popover open={open} anchorEl={this.anchorEl} onRequestClose={this.handleClosePopover}>
          <Menu desktop onItemClick={this.handleClosePopover}>
            {children}
          </Menu>
        </Popover>
      </div>
    );
  }
}

const ApplicantListGrid = props => {
  const {
    metricDefinitions,
    applicants,
    intl,
    invitationEnabled,
    options,
    project,
    projectCrudAllowed,
    participantSelection,
    additionalProjects
  } = props;
  const {
    handleToggleAllApplicantSelection,
    handleToggleApplicantSelection,
    handleApplicationStatusChange,
    handleNotifyUnfinishedProfile,
    handleSetApplicationStatusForSelected,
    handleCrossCompareApplicants,
    handleCompareApplicants,
    handleStartBatchOperation,
    handleAddToGroupsStart,
    handleTagsAssignmentStart,
    invokeUpgradeAccountModal,
    onActivateProject,
    onEditProject,
    onItemClicked,
    onPageNumber,
    onPageSize,
    onSort
  } = props;

  const {
    validity: { state },
    type,
    demo,
    company,
    id
  } = project || { validity: {} };
  const messages = intl.messages.components.pages.private.project.applicantList;
  const enumMessages = intl.messages.constants.enums;
  const applicantSelectionSize = participantSelection.counts.total;
  const canHaveApplicants = state === 'ACTIVE' || state === 'FINISHED';
  const listShown = canHaveApplicants && applicants.length > 0;
  const isAnyApplicantSelected = applicantSelectionSize > 0;
  const isAnyFinishedApplicantSelected = participantSelection.counts.finished > 0;
  const canRemindParticipants = state === 'ACTIVE' && !project.view;
  const notifiableSelectedApplicants =
    canRemindParticipants && !isAnyFinishedApplicantSelected
      ? filter(participantSelection.list || [], p => {
          if (p.result && p.result.metrics) {
            return false;
          }
          const {
            entryStatus,
            participant: { anonymous }
          } = p;
          const rejected = entryStatus && entryStatus.status === 'REJECTED';
          return !rejected && !anonymous && extractNotificationPreferences(p).canNotify;
        })
      : [];
  const sortProps = {
    onSort,
    currentSort: options.sort,
    currentOrder: options.order
  };
  const isQuestionnaire = type === EProjectType.QUESTIONNAIRE.key;
  const HeaderRowComponent = isQuestionnaire ? QuestionnaireListGridHeaderRow : ApplicantListGridHeaderRow;
  const RowComponent = isQuestionnaire ? QuestionnaireListGridRow : ApplicantListGridRow;
  return (
    <Grid className="applicant-list-grid">
      <Sticky top={HEADER_SELECTOR} innerZ={100} activeClass="sticky-active">
        <GridHeader>
          {isAnyApplicantSelected && (
            <GridRow>
              <div className="clearfix">
                <div className="applicant-list-selection-actions bg-primary-light">
                  <div className="pull-left applicant-list-selection-actions-text text-primary">
                    <SelectionMessage count={applicantSelectionSize} />
                  </div>
                  <div className="pull-right">
                    <div className="inline-container tooltip-element">
                      <Button
                        label={messages.highlightButton}
                        icon={<i className="material-icons">lightbulb_outline</i>}
                        primary
                        onClick={() => handleCrossCompareApplicants(project)}
                        disabled={!isAnyFinishedApplicantSelected}
                      />
                      <Tooltip>{messages.highlightHelperText}</Tooltip>
                    </div>
                    <ComparisonMenu
                      messages={messages}
                      handleIntraCompare={handleCompareApplicants}
                      handleCrossCompareApplicants={handleCrossCompareApplicants}
                      disabled={!isAnyFinishedApplicantSelected}
                    />
                    <div className="inline-container">
                      <IconMenu icon={<i className="material-icons text-primary">more_vert</i>} disabled={demo}>
                        <MenuItem
                          primaryText={messages.actions.copyLabel}
                          leftIcon={<i className="material-icons">file_copy</i>}
                          onClick={() =>
                            handleStartBatchOperation(EParticipantBatchOperation.COPY, participantSelection.list)
                          }
                          disabled={!projectCrudAllowed}
                        />
                        <MenuItem
                          primaryText={messages.actions.moveLabel}
                          leftIcon={<i className="material-icons">reply</i>}
                          onClick={() =>
                            handleStartBatchOperation(EParticipantBatchOperation.MOVE, participantSelection.list)
                          }
                          disabled={!projectCrudAllowed}
                        />
                        <MenuItem
                          primaryText={messages.actions.deleteLabel}
                          leftIcon={<i className="material-icons">delete</i>}
                          onClick={() =>
                            handleStartBatchOperation(EParticipantBatchOperation.DELETE, participantSelection.list)
                          }
                          disabled={!projectCrudAllowed}
                        />
                        {handleTagsAssignmentStart && <Divider />}
                        {handleTagsAssignmentStart && (
                          <MenuItem primaryText={messages.actions.addTagsLabel} onClick={handleTagsAssignmentStart} />
                        )}
                        {handleAddToGroupsStart && <Divider />}
                        {handleAddToGroupsStart && (
                          <MenuItem primaryText={messages.actions.addToTeamsLabel} onClick={handleAddToGroupsStart} />
                        )}
                        {canRemindParticipants && <Divider />}
                        {canRemindParticipants && (
                          <MenuItem
                            primaryText={messages.actions.remindLabel}
                            leftIcon={<i className="material-icons">notification_important</i>}
                            onClick={() => handleNotifyUnfinishedProfile(notifiableSelectedApplicants)}
                            disabled={!notifiableSelectedApplicants || notifiableSelectedApplicants.length <= 0}
                          />
                        )}
                        {!isQuestionnaire && <Divider />}
                        {!isQuestionnaire &&
                          map(orderBy(values(EApplicationStatus), ['order']), s => (
                            <MenuItem
                              key={`status-${s.value}`}
                              onClick={() => handleSetApplicationStatusForSelected(s.value)}
                              primaryText={enumMessages.EApplicationStatus[s.value].setStatusLabel}
                              disabled={!isAnyFinishedApplicantSelected}
                            />
                          ))}
                      </IconMenu>
                    </div>
                  </div>
                </div>
              </div>
            </GridRow>
          )}
          <HeaderRowComponent
            intl={intl}
            sortProps={sortProps}
            isAnyApplicantSelected={isAnyApplicantSelected}
            handleToggleAllApplicantSelection={handleToggleAllApplicantSelection}
          />
        </GridHeader>
      </Sticky>
      {listShown && (
        <GridBody>
          {map(applicants, row => (
            <LazyRenderedRow key={`${row.participant.id}-${row.projectId}`}>
              <div>
                <RowComponent
                  row={row}
                  metricDefinitions={metricDefinitions}
                  intl={intl}
                  selected={!!participantSelection.map[`${row.projectId}/${row.participant.id}`]}
                  project={project}
                  additionalProjects={additionalProjects}
                  {...sortProps}
                  // actions
                  handleToggleApplicantSelection={handleToggleApplicantSelection}
                  handleApplicationStatusChange={handleApplicationStatusChange}
                  invokeUpgradeAccountModal={invokeUpgradeAccountModal}
                  handleNotifyUnfinishedProfile={handleNotifyUnfinishedProfile}
                  onItemClicked={onItemClicked}
                />
                <div className="bg-white mui-padded-horizontal" style={{ margin: '-7px -16px 0', borderRadius: '0' }}>
                  <div className="container-flex-row ai-center fw-yes">
                    <GroupsPanel participant={row} />
                    <ProjectUserTags project={project} userId={row.participant.id} tags={row.participant.tags} />
                  </div>
                </div>
              </div>
            </LazyRenderedRow>
          ))}
        </GridBody>
      )}
      {!listShown && (
        <GridBody>
          <GridRow>
            <GridCell style={STYLES.emptyTable} xs={24}>
              {projectCrudAllowed && state === 'CONCEPT' && (
                <Button
                  raised
                  primary
                  label={intl.messages.components.iconMenu.activate}
                  onClick={() => onActivateProject(project)}
                />
              )}
              {projectCrudAllowed && state === 'SCHEDULED' && (
                <Button
                  raised
                  primary
                  label={intl.messages.components.iconMenu.edit}
                  onClick={() => onEditProject(company.id, id)}
                />
              )}
              {canHaveApplicants &&
                `${invitationEnabled ? `${messages.empty.valid} "${messages.invite}"` : messages.empty.invalid}`}
            </GridCell>
          </GridRow>
        </GridBody>
      )}
      <GridFooter>
        <GridRow>
          <GridCell xs={24} style={{ ...STYLES.alignRight, overflowX: 'auto', padding: '0 24px' }}>
            <GridPaginator onPageSize={onPageSize} onPageNumber={onPageNumber} options={options} />
          </GridCell>
        </GridRow>
      </GridFooter>
    </Grid>
  );
};

export default pure(ApplicantListGrid);
