import React from 'react';

import Competency from './Competency';
import { ERank } from '../../constants/enum';

const mapping = {
  plus: 's',
  minus: 'd',
  avg: 'f'
};

const Sign = ({ sign, rank, className, ...props }) => {
  const cn = `competency-icon-sign${className ? ` ${className}` : ''}`;
  return (
    <Competency symbol={mapping[sign || ERank[rank].sign]} className={cn} {...props} />
  );
};

export default Sign;
