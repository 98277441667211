import React from 'react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import FAB from 'material-ui/FloatingActionButton';
import IconButton from 'material-ui/IconButton';

import ImageUpload from './ImageUpload';

const Card = props => {
  const { children, style } = props;
  return (
    <div
      className="mui-card"
      style={{
        display: 'inline-block',
        margin: '8px',
        borderRadius: '2px',
        overflow: 'hidden',
        verticalAlign: 'top',
        ...style
      }}
    >
      {children}
    </div>
  );
};

class MultiImageUploadField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadOpen: false
    };
  }

  handleUploadOpen = idx => {
    this.setState({ uploadOpen: true, idx });
  };
  handleUploadClose = () => {
    this.setState({ uploadOpen: false });
  };
  handleUploadConfirm = url => {
    const { input: { onBlur, value } } = this.props;
    const { idx } = this.state;
    this.setState({ uploadOpen: false });
    const newValue = [...value];
    newValue[idx] = url;
    onBlur(newValue);
  };
  handleImageRemove = idx => {
    const { input: { onBlur, value } } = this.props;
    const newValue = _filter(value, (v, vIdx) => vIdx !== idx);
    onBlur(newValue);
  };

  render() {
    const {
      input: { value },
      meta: { touched, error },
      label,
      description,
      helperText,
      indicateRequired,
      renderImage,
      maxFileSize,
      fileHelperText
    } = this.props;
    const { uploadOpen } = this.state;
    const err = touched && error;
    const imageRenderer =
      renderImage || (value => <img src={value} alt="Image" style={{ maxHeight: '96px', maxWidth: '100%' }} />);
    return (
      <div className="mui-fields-container">
        <div className="mui-fields-label">
          {label}
          {indicateRequired && ' *'}
        </div>
        {description && <div className="mui-fields-description">{description}</div>}
        <div style={{ margin: '-8px' }}>
          {_map(value, (v, idx) => (
            <Card key={v}>
              <div className="text-center bg-primary-light">{imageRenderer(v, idx)}</div>
              <div style={{ textAlign: 'right' }}>
                <IconButton onClick={() => this.handleUploadOpen(idx)} style={{ width: 36, height: 36, padding: 6 }}>
                  <i className="material-icons">edit</i>
                </IconButton>
                <IconButton onClick={() => this.handleImageRemove(idx)} style={{ width: 36, height: 36, padding: 6 }}>
                  <i className="material-icons">clear</i>
                </IconButton>
              </div>
            </Card>
          ))}
          <Card style={{ boxShadow: 'none' }}>
            <div className="bg-primary-light" style={{ height: '132px', width: '132px', padding: '38px' }}>
              <FAB onClick={() => this.handleUploadOpen((value && value.length) || 0)}>
                <i className="material-icons">add</i>
              </FAB>
            </div>
          </Card>
        </div>
        {uploadOpen && (
          <ImageUpload
            onClose={this.handleUploadClose}
            onConfirm={this.handleUploadConfirm}
            maxFileSize={maxFileSize}
            helperText={fileHelperText}
          />
        )}
        {(helperText || err) && (
          <div className={`mui-fields-${err ? 'error' : 'helper'}-text`}>{helperText || err}</div>
        )}
      </div>
    );
  }
}

export default MultiImageUploadField;
