import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'material-ui/IconButton';
import Toggle from 'material-ui/Toggle';

import FilterBadge from './FilterBadge';
import BaseFilter from './BaseFilter';
import Brush from '../overviewChart/Brush';

const renderLabel = ({ value, start }, formatter) => {
  return (
    <label style={{ margin: 0, [start ? 'marginLeft' : 'marginRight']: -7 }}>
      {formatter ? formatter(value) : value}
    </label>
  );
};

const RangeFilter = props => {
  const { expanded, label, description, filter, activeValues, formatter } = props;
  const { onToggleExpanded, onRangeChange, onRangeSetEnabled } = props;
  return (
    <BaseFilter
      expanded={expanded}
      label={label}
      filter={filter}
      description={description}
      onToggleExpanded={onToggleExpanded}
      style={{ display: expanded ? 'block' : 'inline-block' }}
    >
      {expanded ? (
        <div className="range-container">
          <div className="range-container-control left">
            <Toggle
              style={{ marginTop: '13px', width: '48px' }}
              toggled={activeValues.enabled}
              onToggle={() => onRangeSetEnabled(filter, !activeValues.enabled)}
            />
            <div style={{ marginTop: '4px', fontSize: '13px', textAlign: 'center' }}>
              <FilterBadge all={filter.count} count={filter.countFiltered} />
            </div>
          </div>
          <Brush
            min={filter.min != undefined ? filter.min : filter.start}
            max={filter.max != undefined ? filter.max : filter.end}
            step={filter.step}
            startIndex={activeValues.start != undefined ? activeValues.start : filter.startFiltered}
            endIndex={activeValues.end != undefined ? activeValues.end : filter.endFiltered}
            labelStatic
            labelStart={data => renderLabel(data, formatter)}
            labelEnd={data => renderLabel(data, formatter)}
            onChange={({ startIndex, endIndex }) => onRangeChange(filter, { start: startIndex, end: endIndex })}
            disabled={!activeValues.enabled}
          />
          <div className="range-container-control right">
            <IconButton
              disabled={!activeValues.enabled}
              onClick={() => onRangeChange(filter, { start: filter.start, end: filter.end })}
              iconStyle={{ color: 'rgba(33, 33, 33, 0.54)' }}
            >
              <i className="material-icons">autorenew</i>
            </IconButton>
          </div>
        </div>
      ) : (
        activeValues.enabled && (
          <div>
            <Toggle
              style={{ height: '24px', width: '48px', display: 'inline-block', verticalAlign: 'top' }}
              toggled={activeValues.enabled}
              onToggle={() => onRangeSetEnabled(filter, !activeValues.enabled)}
            />
            <div style={{ fontSize: '13px', display: 'inline-block', verticalAlign: 'top', marginTop: '3px' }}>
              <FilterBadge all={filter.count} count={filter.countFiltered} />
            </div>
          </div>
        )
      )}
    </BaseFilter>
  );
};

RangeFilter.propTypes = {
  expanded: PropTypes.bool,
  label: PropTypes.any,
  description: PropTypes.any,
  filter: PropTypes.object.isRequired,
  activeValues: PropTypes.object.isRequired,
  formatter: PropTypes.func,
  onToggleExpanded: PropTypes.func,
  onRangeChange: PropTypes.func,
  onRangeSetEnabled: PropTypes.func
};

export default RangeFilter;
