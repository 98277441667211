import { handleActions } from 'redux-actions';
import { PROJECT_USER_COMPANY_TAGS_GET, GROUP_USER_COMPANY_TAGS_GET } from '../../constants/constants';

const initialState = {
  byProject: {},
  byGroup: {}
};

export default handleActions(
  {
    [PROJECT_USER_COMPANY_TAGS_GET.SUCCESS]: (state, { payload: { items }, meta }) => ({
      ...state,
      byProject: {
        ...meta,
        tags: items
      }
    }),
    [GROUP_USER_COMPANY_TAGS_GET.SUCCESS]: (state, { payload: { items }, meta }) => ({
      ...state,
      byGroup: {
        ...meta,
        tags: items
      }
    })
  },
  initialState
);
