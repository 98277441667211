import { handleActions } from 'redux-actions';

import * as c from '../../constants/constants';

export const defaultState = [];

export default handleActions(
  {
    [c.COMPANIES_REFRESH]: () => defaultState,
    [c.COMPANIES_GET]: (state, { payload }) => payload
  },
  defaultState
);
