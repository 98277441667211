import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { CardText } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';

import OverviewChart from '../../../components/overviewChart/OverviewChart';
import MetricsBubbleChartPanel from '../../../components/analytics/bubbleChart/MetricsBubbleChartPanel';
import SkillScatterChart from '../../../components/analytics/SkillScatterChart';
import LockedPlaceholder from '../../../components/placeholder/LockedPlaceholder';
import ExpandableCardHeader from '../../../components/mui/ExpandableCardHeader';
import Collapse from '../../../components/basic/Collapse';

import allActions from '../../../actions';
import * as projectOverviewActions from '../../../actions/options/projectOverviewActions';

import { selectSkillScatterChartData } from '../../../selectors/analytics';
import { getFilteredAndEvaluatedParticipants } from '../../../selectors/applicantList';
import { EUpgradeRequestSource, EProjectOverviewPart } from '../../../constants/enum';

const Divider = () => <div style={{ height: '1px', width: '100%', backgroundColor: 'rgba(0,0,0,0.05)' }} />;

const NoParticipants = ({ intl }) => (
  <div
    style={{
      padding: '32px 16px',
      width: '100%',
      borderRadius: '2px',
      backgroundColor: 'rgba(0,0,0,0.05)',
      textAlign: 'center'
    }}
  >
    <span className="text-muted">
      {intl.messages.components.pages.private.project.analytics.noParticipants}
    </span>
  </div>
);

class ProjectAnalytics extends React.Component {
  isPartActive = part => {
    const { projectOverview } = this.props;
    // by default it should be true
    return !projectOverview.parts || projectOverview.parts[part] !== false;
  };

  handleTogglePart = part => {
    const { project, toggleProjectOverviewPart } = this.props;
    toggleProjectOverviewPart(project.id, part);
  };
  handleToggleOverviewChart = () => this.handleTogglePart(EProjectOverviewPart.OVERVIEW_CHART);
  handleToggleBubbleChart = () => this.handleTogglePart(EProjectOverviewPart.BUBBLE_CHART);
  handleToggleScatterChart = () => this.handleTogglePart(EProjectOverviewPart.SCATTER_CHART);

  render() {
    const {
      applicants,
      auth,
      intl,
      overviewChart,
      scoreMetric,
      metricsConfig,
      scoreMappingMetrics,
      numericalMetrics,
      scatterChartData,
      project
    } = this.props;
    const {
      setActiveOverviewChart,
      setSortedOverviewChart,
      setWindowOverviewChart,
      setSkillScatterChartCompetencyValue,
      onParticipantClick,
      invokeUpgradeAccountModal
    } = this.props;
    const chartShown = this.isPartActive(EProjectOverviewPart.OVERVIEW_CHART);
    const scatterChartShown = this.isPartActive(EProjectOverviewPart.SCATTER_CHART);
    const bubbleChartShown = this.isPartActive(EProjectOverviewPart.BUBBLE_CHART);
    const evaluatedParticipants = applicants;
    const isDemoProject = project && project.demo;
    const noParticipants = evaluatedParticipants.length === 0;
    const canShowChart = auth.canUseDashboardChart || isDemoProject;
    return (
      <div>
        <ExpandableCardHeader
          title={intl.messages.filter.chart.title}
          subtitle={intl.messages.filter.chart.subtitle}
          avatar={<Avatar style={{ float: 'left' }} icon={<i className="material-icons">equalizer</i>} />}
          onClick={this.handleToggleOverviewChart}
          expanded={this.isPartActive(EProjectOverviewPart.OVERVIEW_CHART)}
        />
        <Collapse isOpened={chartShown} unmountClosed>
          <CardText style={{ paddingTop: 0 }}>
            {noParticipants ? (
              <NoParticipants intl={intl} />
            ) : canShowChart ? (
              <div className="mui-card" style={{ padding: '0 12px 16px', boxShadow: 'none' }}>
                <OverviewChart
                  applicants={evaluatedParticipants}
                  intl={intl}
                  setActiveOverviewChart={setActiveOverviewChart}
                  setSortedOverviewChart={setSortedOverviewChart}
                  setWindowOverviewChart={setWindowOverviewChart}
                  onItemClicked={onParticipantClick}
                  overviewChart={overviewChart}
                  scoreMetric={scoreMetric}
                  scoreMapping={metricsConfig.scoreMapping}
                  scoreMappingMetrics={scoreMappingMetrics}
                  numericalMetrics={numericalMetrics}
                />
              </div>
            ) : (
              <LockedPlaceholder
                message={intl.messages.components.pages.private.project.applicantList.lockedChartMessage}
                image={
                  <div style={{ paddingBottom: '16px' }}>
                    <img src="/img/misc/teasers/chart.svg" width="100%" alt="Chart teaser" />
                  </div>
                }
                upgradeAccount={invokeUpgradeAccountModal}
                source={EUpgradeRequestSource.DASHBOARD_CHART}
              />
            )}
          </CardText>
        </Collapse>
        {canShowChart && (
          <div>
            <Divider />
            <ExpandableCardHeader
              title={intl.messages.components.pages.private.project.analytics.metricBubbleChart.title}
              subtitle={intl.messages.components.pages.private.project.analytics.metricBubbleChart.subtitle}
              avatar={<Avatar style={{ float: 'left' }} icon={<i className="material-icons">linear_scale</i>} />}
              onClick={this.handleToggleBubbleChart}
              expanded={bubbleChartShown}
            />
            <Collapse isOpened={bubbleChartShown} unmountClosed>
              <CardText style={{ paddingTop: 0 }}>
                {noParticipants ? (
                  <NoParticipants intl={intl} />
                ) : (
                  <div className="mui-card" style={{ padding: '24px 12px 12px', boxShadow: 'none' }}>
                    <MetricsBubbleChartPanel participants={evaluatedParticipants} metrics={numericalMetrics} />
                  </div>
                )}
              </CardText>
            </Collapse>
          </div>
        )}
        <Divider />
        <ExpandableCardHeader
          title={intl.messages.components.pages.private.project.analytics.scatterChart.title}
          subtitle={intl.messages.components.pages.private.project.analytics.scatterChart.subtitle}
          avatar={<Avatar style={{ float: 'left' }} icon={<i className="material-icons">bubble_chart</i>} />}
          onClick={this.handleToggleScatterChart}
          expanded={scatterChartShown}
        />
        <Collapse isOpened={scatterChartShown} unmountClosed>
          <div className="mui-padded-horizontal-x2">
            <div className="text-muted small">
              {intl.messages.components.pages.private.project.analytics.scatterChart.description}
            </div>
          </div>
          <CardText style={{ paddingTop: 16 }}>
            {noParticipants ? (
              <NoParticipants intl={intl} />
            ) : (
              <div className="mui-card" style={{ boxShadow: 'none' }}>
                <SkillScatterChart
                  intl={intl}
                  data={scatterChartData}
                  setSkillScatterChartCompetencyValue={(competency, value) =>
                    setSkillScatterChartCompetencyValue({ projectId: project.id, competency, value })}
                />
              </div>
            )}
          </CardText>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { options: { overviewChart, projectOverview } } = state;
  const { project } = props;
  return {
    overviewChart,
    applicants: getFilteredAndEvaluatedParticipants(state, props),
    projectOverview: (project && projectOverview[project.id]) || {},
    scatterChartData: selectSkillScatterChartData(state, props)
  };
};

const actions = {
  ...allActions,
  ...projectOverviewActions
};

export default withRouter(connect(mapStateToProps, actions)(ProjectAnalytics));
