import * as C from '../../constants/constants';

export function addNotification(n) {
    return {
        type: C.NOTIFICATIONS_ADD,
        payload: {
            id: n.id || Math.random().toString(36).substring(2),
            timestamp: Date.now(),
            ...n
        }
    };
}

export function hideNotification(n) {
    return {
        type: C.NOTIFICATIONS_HIDE,
        payload: n
    };
}

export function dismissNotification(n) {
    return {
        type: C.NOTIFICATIONS_DISMISS,
        payload: n
    };
}

export function showHiddenNotifications() {
    return {
        type: C.NOTIFICATIONS_SHOW_HIDDEN
    };
}

export function hideActiveNotifications() {
    return {
        type: C.NOTIFICATIONS_HIDE_ACTIVE
    };
}
