import { handleActions } from 'redux-actions';

import {
  GUIDE_NEXT_STEP,
  GUIDE_PREV_STEP,
  GUIDE_SHOW,
  GUIDE_HIDE,
  GUIDE_STEP_MAX,
  GUIDE_FINISH,
  GUIDE_VERSION,
  //
  LOGIN_SUCCESS
} from '../../constants/constants';

const initialState = {
  step: 1,
  first: true,
  last: false,
  shown: false,
  stepCount: GUIDE_STEP_MAX,
  version: GUIDE_VERSION,
  finishedAt: null
};

const getStepProps = step => ({ step, first: step === 1, last: step === GUIDE_STEP_MAX });

export default handleActions(
  {
    [GUIDE_PREV_STEP]: state => ({ ...state, ...getStepProps(Math.max(1, state.step - 1)) }),
    [GUIDE_NEXT_STEP]: state => ({ ...state, ...getStepProps(Math.min(GUIDE_STEP_MAX, state.step + 1)) }),
    [GUIDE_SHOW]: state => ({ ...state, shown: true }),
    [GUIDE_HIDE]: state => ({ ...state, shown: false }),
    [GUIDE_FINISH]: state => ({
      ...state,
      shown: false,
      finishedAt: state.finishedAt || Date.now(),
      ...getStepProps(1)
    }),
    [LOGIN_SUCCESS]: (state, { payload: { guideStates } }) => {
      if (!guideStates) {
        return state;
      }
      const { dashboard } = guideStates;
      if (!dashboard || dashboard.version < GUIDE_VERSION) {
        return {
          ...state,
          shown: true
        };
      }
      return {
        ...state,
        ...getStepProps(dashboard.step),
        shown: dashboard.shown,
        finishedAt: dashboard.finishedAt
      };
    }
  },
  initialState
);
