import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';

import DualMetric from './DualMetric';
import ExpanderLabel from '../basic/ExpanderLabel';

import { checkEqualProperties } from '../../utils/utils';
import { convertMetricsToCrossComparisonData } from '../../utils/analytics';
import * as Api from '../../api/options/cultureFitApi';

const InterpretationToggle = props => {
  const { onToggle, metric, active, intl } = props;
  const messages = intl.messages.components.dualMetricsInterpretation;
  return (
    <div style={{ padding: '8px 4px 0' }}>
      <h5 className={!active && 'mui-label'} style={{ margin: active ? '4px 0 8px 0' : 0, transition: '0.2s margin' }}>
        <ExpanderLabel expanded={active} label={messages.interpretationLabel} toggleExpand={() => onToggle(metric)} />
      </h5>
    </div>
  );
};

class DualMetricsInterpretation extends React.Component {
  state = {
    interpretations: null,
    expandedInterpretations: {}
  };

  componentDidMount() {
    this.loadInterpretations(this.props);
  }

  componentDidUpdate(prevProps) {
    const eq = checkEqualProperties(prevProps, this.props, ['intl', 'metrics', 'activeParticipant']);
    if (!eq) {
      this.loadInterpretations(this.props);
    }
  }

  handleMetricInterpretationToggle = metric => {
    const { expandedInterpretations } = this.state;
    const { [metric.key]: expanded, ...newState } = expandedInterpretations;
    if (!expanded) {
      newState[metric.key] = true;
    }
    this.setState({ expandedInterpretations: newState });
  };

  loadInterpretations = props => {
    const { intl: { locale }, metrics, activeParticipant } = props;
    if (activeParticipant) {
      const convertedMetrics = convertMetricsToCrossComparisonData(metrics);
      const data = { metrics: convertedMetrics };
      Api.getInterpretations(data, locale).then(({ payload }) => {
        const expandedInterpretations = {};
        if (metrics[0]) {
          expandedInterpretations[metrics[0].metric.key] = true;
        }
        this.setState({ interpretations: payload, expandedInterpretations });
      });
    } else {
      this.setState({ interpretations: null });
    }
  };

  render() {
    const { metrics, intl, activeParticipant, colors } = this.props;
    const { interpretations, expandedInterpretations } = this.state;
    const interpretationsMetrics = (interpretations && interpretations.metrics) || {};

    const columns = [];
    let column;
    for (let i = 0; i < metrics.length; i += 1) {
      if (i === 0 || i === Math.floor(metrics.length / 2)) {
        column = [];
        columns.push(column);
      }
      const { metric, data, dual, mean } = metrics[i];
      column.push(
        <div className="dual-metrics-col clearfix" key={metric.key}>
          <DualMetric
            key={metric.key}
            metric={metric}
            data={data}
            dual={dual}
            mean={mean}
            colors={colors}
            multi
            activeParticipant={activeParticipant}
            interpretation={
              activeParticipant && expandedInterpretations[metric.key] && interpretationsMetrics[metric.key]
            }
          >
            {activeParticipant && (
              <InterpretationToggle
                onToggle={this.handleMetricInterpretationToggle}
                metric={metric}
                active={expandedInterpretations[metric.key]}
                intl={intl}
              />
            )}
          </DualMetric>
        </div>
      );
    }
    return (
      <div className="dual-metrics-interpretation">
        <div className="row">
          {_map(columns, (col, idx) => (
            <div key={idx} className="col-xs-12 col-sm-6">
              {col}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { intl } = state;
  return {
    intl
  };
};

export default connect(mapStateToProps)(DualMetricsInterpretation);
