import React from 'react';
import PropTypes from 'prop-types';

import Brick from './Brick';
import MetricLabel from './MetricValueLabel';

import { ERank } from '../../constants/enum';
import { reverseArray } from '../../utils/utils';

const CHART_CONFIGS = {
  DEFAULT: {
    count: 10
  },
  SEMI_STEN: {
    count: 5
  },
  FIAT: {
    count: 4
  },
  CATEGORY: {
    count: 3
  }
};

const determineChartConfig = metric => {
  const hasValue = metric.value != null;
  if (!hasValue || metric.type === 'CATEGORY') {
    return CHART_CONFIGS.CATEGORY;
  }
  switch (metric.valueType) {
    case 'SEMI_STEN':
      return CHART_CONFIGS.SEMI_STEN;
    case 'FIAT':
      return CHART_CONFIGS.FIAT;
    default:
      return CHART_CONFIGS.DEFAULT;
  }
};

const calculateStepValue = (step, totalSteps, value) => {
  const diff = value - step;
  if (diff > 1) {
    return {
      value: 1,
      showLabel: step === totalSteps - 1
    };
  }
  if (diff > 0) {
    return {
      value: diff,
      showLabel: true
    };
  }
  return {
    value: 0,
    showLabel: step === 0
  };
};

const extractValue = (metric, stepCount) => {
  if (metric.value != null && metric.type !== 'CATEGORY') {
    return metric.value * stepCount;
  }
  const rank = ERank[metric.rank];
  const rankValue = rank && rank.value;
  if (rankValue != null) {
    return rankValue + 1;
  }
  return 0;
};

const MetricChart = ({ children, horizontal, metric, style }) => {
  const config = determineChartConfig(metric);
  const value = extractValue(metric, config.count);
  const result = [];
  for (let i = 0; i < config.count; i += 1) {
    const stepValue = calculateStepValue(i, config.count, value);
    result.push(
      <Brick
        key={i}
        color={metric.color}
        value={stepValue.value * 100}
        formattedValue={stepValue.showLabel ? <MetricLabel metric={metric} /> : null}
        horizontal={horizontal}
        totalCount={config.count}
      />
    );
  }
  const chartStyle = {};
  if (!horizontal) {
    chartStyle.position = 'relative';
    chartStyle.padding = '0 10px';
    chartStyle.maxWidth = '240px';
    chartStyle.margin = '0 auto';
  }
  return (
    <div className={`chart-${horizontal ? 'row' : 'column'}`} style={style}>
      <div style={chartStyle}>
        {!horizontal && (
          <div
            className="mui-label"
            style={{
              margin: 0,
              position: 'absolute',
              bottom: -10,
              left: -4,
              transformOrigin: 'top left',
              transform: 'rotate(-90deg)',
              whiteSpace: 'nowrap',
              color: 'rgba(33, 33, 33, 0.87)'
            }}
          >
            {metric.label}
          </div>
        )}
        {horizontal ? result : reverseArray(result)}
      </div>
      {children}
    </div>
  );
};

MetricChart.propTypes = {
  children: PropTypes.node,
  horizontal: PropTypes.any,
  metric: PropTypes.object.isRequired,
  style: PropTypes.object
};

export default MetricChart;
