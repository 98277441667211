import { getAccountModes, canAccessProjects, canAccessDesigner, canAccessGroups } from './utils';
import { EContractPlanMode } from '../constants/enum';

export const redirectAuthenticated = (router, auth, companies) => {
  if (canAccessProjects(auth) || canAccessGroups(auth)) {
    const modes = getAccountModes(auth, companies || []);
    if (modes[EContractPlanMode.TEAMS.key]) {
      router.push({ pathname: '/groups' });
    } else if (modes[EContractPlanMode.SOURCING.key]) {
      router.push({ pathname: '/sourcing' });
    } else if (modes[EContractPlanMode.DEVELOPMENT.key] || modes[EContractPlanMode.RECRUITMENT.key]) {
      router.push({ pathname: '/projects' });
    } else {
      router.push({ pathname: '/sourcing' });
    }
  } else if (canAccessDesigner(auth)) {
    router.push({ pathname: '/designer' });
  } else {
    router.push({ pathname: '/logout' });
  }
};
