import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FlatButton from 'material-ui/FlatButton';
import Checkbox from 'material-ui/Checkbox';

import { GridRow, GridCell } from '../grid';
import ParticipantNameCellContent from './cells/ParticipantNameCellContent';

import { EActivityStatus } from '../../constants/enum';
import { formatMessage, extractNotificationPreferences } from '../../utils/utils';

const renderUnfinishedNotificationStatus = (row, notify, messages, locale) => {
  const { canNotify, timestamp } = extractNotificationPreferences(row);
  return (
    <div>
      <div style={{ marginRight: '8px', display: 'inline-block', verticalAlign: 'middle' }}>
        {timestamp ? (
          <div>
            <div>
              {formatMessage(messages.lastNotified, [
                moment(timestamp)
                  .locale(locale)
                  .fromNow()
              ])}
            </div>
            {!canNotify && <small>{messages.timeLimitReached}</small>}
          </div>
        ) : (
          notify && <span>{messages.neverNotified}</span>
        )}
      </div>
      {notify && (
        <div style={{ display: 'inline-block' }}>
          <FlatButton
            onClick={() => notify([row])}
            disabled={!canNotify}
            icon={<i className="material-icons">{canNotify ? 'notification_important' : 'notifications_off'}</i>}
            label={messages.buttonLabel}
            labelStyle={{ fontSize: '12px' }}
          />
        </div>
      )}
    </div>
  );
};

const renderStatus = (message, timestamp, locale, opacity) => {
  return (
    <p style={{ color: `rgba(33, 33, 33, ${opacity})` }}>
      <span>{message}</span>
      <br />
      <small>
        {moment(timestamp)
          .locale(locale)
          .format('L LT')}
      </small>
    </p>
  );
};

const UnfinishedListGridRow = props => {
  const { row, selected, project, intl, questionnaire } = props;
  const { handleNotifyUnfinishedProfile, handleToggleApplicantSelection } = props;
  const { ack, participant, simulation, status, loadingCounter, entryStatus, entryType } = row;
  const messages = intl.messages.components.pages.private.project.applicantList;
  const isNew = ack === null || ack.status !== status;
  const pending = !entryStatus;
  const rejected = entryStatus && entryStatus.status === 'REJECTED';

  const responsiveProps = {};
  if (questionnaire) {
    responsiveProps.check = { xs: 4, sm: 2 };
    responsiveProps.name = { xs: 20, sm: 15, md: 7, lg: 8 };
  } else {
    responsiveProps.check = { xs: 9, sm: 5, md: 4, lg: 3 };
    responsiveProps.name = { xs: 15, sm: 12, md: 5, lg: 7 };
  }
  return (
    <GridRow className="unselectable">
      {loadingCounter > 0 && <div className="applicant-loader" />}
      <GridCell {...responsiveProps.check} style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
        <Checkbox
          checked={selected}
          iconStyle={selected ? {} : { fill: '#757575' }}
          onCheck={e => handleToggleApplicantSelection(row, e)}
        />
      </GridCell>
      <GridCell {...responsiveProps.name} className="applicant-name">
        <ParticipantNameCellContent showEmail isNew={isNew} participant={participant} messages={messages} />
      </GridCell>
      <GridCell xs={24} sm={7} md={5}>
        {/* PENDING, REJECTED, REGISTERED, IN PROGRESS */}
        {pending
          ? renderStatus(messages.pending[entryType], simulation.createdAt, intl.locale, 0.49)
          : rejected
          ? renderStatus(messages.rejected[entryType], entryStatus.timestamp, intl.locale, 0.3)
          : status === EActivityStatus.IN_PROGRESS
          ? renderStatus(messages.inProgress, simulation.startedAt, intl.locale, 0.87)
          : renderStatus(messages.unstarted, simulation.createdAt, intl.locale, 0.68)}
      </GridCell>
      {project &&
        !project.view &&
        !rejected &&
        !participant.anonymous && (
          <GridCell
            xs={24}
            md={10}
            lg={9}
            className="sm-text-right"
            style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
          >
            {renderUnfinishedNotificationStatus(
              row,
              project && project.validity.state === 'ACTIVE' ? handleNotifyUnfinishedProfile : null,
              messages.notifications.unfinished,
              intl.locale
            )}
          </GridCell>
        )}
    </GridRow>
  );
};

UnfinishedListGridRow.propTypes = {
  row: PropTypes.object.isRequired,
  project: PropTypes.object,
  intl: PropTypes.object.isRequired,
  //
  handleNotifyUnfinishedProfile: PropTypes.func.isRequired
};

export default UnfinishedListGridRow;
