import React from 'react';
import PropTypes from 'prop-types';

import DatePickerField from 'material-ui/DatePicker';
import { grey300 } from 'material-ui/styles/colors';

const DatePicker = props => {
  const {
    intl,
    input: { onBlur, ...inputRest },
    label,
    helperText,
    meta: { visited, error },
    ...custom
  } = props;

  const dtf = Intl && Intl.DateTimeFormat;
  const locale = dtf ? intl.locale : 'en';
  const err = visited && error;
  return (
    <div>
      <DatePickerField
        hintText={label}
        floatingLabelText={label}
        underlineStyle={{ borderColor: err ? '#b00020' : grey300, borderBottomWidth: err ? '2px' : '1px' }}
        floatingLabelStyle={err ? { color: '#b00020' } : {}}
        underlineFocusStyle={{ borderBottomWidth: '2px' }}
        DateTimeFormat={dtf}
        locale={locale}
        {...intl.messages.components.datePicker}
        {...inputRest}
        {...custom}
        onChange={(event, value) => {
          inputRest.onChange(value);
        }}
      />
      {(helperText || err) && (
        <p
          className={err ? 'text-error' : 'text-muted'}
          style={{ fontSize: '12px', lineHeight: '12px', marginTop: '-1px', marginBottom: 0 }}
        >
          {helperText || err}
        </p>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  helperText: PropTypes.string,
  meta: PropTypes.object,
  custom: PropTypes.object
};

export default DatePicker;
