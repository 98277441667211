import { getResults } from '../../api/entities/applicantApi';
import { createApiCallAction } from '../apiCallAction';

import {
  CROSS_COMPARISON_CANCEL,
  CROSS_COMPARISON_START,
  CROSS_COMPARISON_UPDATE_PARTICIPANTS,
  CROSS_COMPARISON_SET_PARTICIPANT_ACTIVE,
  CROSS_COMPARISON_SET_MANAGERIAL,
  CROSS_COMPARISON_LOAD_PARTICIPANTS,
  MODAL_CROSS_COMPARISON_PROJECT_SELECT
} from '../../constants/constants';
import { switchModal } from './modalActions';

export function startCrossComparison(participants, targetProject, sourceProject) {
  return {
    type: CROSS_COMPARISON_START,
    payload: { participants, targetProject, sourceProject }
  };
}

export function updateCrossComparisonParticipants(participants) {
  return {
    type: CROSS_COMPARISON_UPDATE_PARTICIPANTS,
    payload: { participants }
  };
}

export function setCrossComparisonParticipantActive(index) {
  return {
    type: CROSS_COMPARISON_SET_PARTICIPANT_ACTIVE,
    payload: { index }
  };
}

export function setCrossComparisonManagerial(managerial) {
  return {
    type: CROSS_COMPARISON_SET_MANAGERIAL,
    payload: managerial
  };
}

export function cancelCrossComparison() {
  return {
    type: CROSS_COMPARISON_CANCEL,
    payload: null
  };
}

const loadCrossComparisonParticipantsAction = createApiCallAction(CROSS_COMPARISON_LOAD_PARTICIPANTS, getResults);
export function loadCrossComparisonParticipants(companySlug, projectSlug, lang) {
  return loadCrossComparisonParticipantsAction(companySlug, projectSlug, lang);
}

export const selectCrossComparisonProject = (participants, project, options) => {
  return switchModal({
    id: MODAL_CROSS_COMPARISON_PROJECT_SELECT,
    project,
    participants,
    options
  });
};
