import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import isURL from 'validator/lib/isURL';
import _get from 'lodash/get';

import Text from '../../../superfield/form/SuperText';
import Select from '../../../superfield/form/SuperSelect';

import { selectCompanyProfileToEdit } from '../../../../selectors/companyProfileBuilder';
import { ECompanyProfileEmployeeSize, ECompanyType } from '../../../../constants/enum';
import { valueHoldersToValues, enumToDataSource } from '../../../forms/FormUtils';

const messages = {
  nameLabel: 'Jméno společnosti',
  nameHelperText: 'Jak vás uvidí ostatní uživatelé',
  websiteUrlLabel: 'Váš web',
  websiteUrlHelperText: 'Zadejte adresu ve formátu "www.google.com"',
  hqAddressLabel: 'Adresa sídla',
  industriesLabel: 'Odvětví, ve kterých působíte',
  employeeSizeLabel: 'Velikost vaší společnosti',
  employeeSizeHelperText: 'Podle počtu zaměstnanců',
  typeLabel: 'Označujete se jako',
  requiredMessage: 'Vyberte možnost',
  requiredMessageFill: 'Vyplňte pole',
  requiredMessageMulti: 'Vyberte alespoň jednu možnost',
  invalidUrlMessage: 'Neplatný odkaz'
};

const BasicPart = props => {
  const { handleSubmit, dataSources } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="name"
            component={Text}
            label={messages.nameLabel}
            helperText={messages.nameHelperText}
            indicateRequired
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="websiteUrl"
            component={Text}
            label={messages.websiteUrlLabel}
            helperText={messages.websiteUrlHelperText}
            indicateRequired
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field name="hqAddress" component={Text} label={messages.hqAddressLabel} indicateRequired />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="industries"
            component={Select}
            label={messages.industriesLabel}
            dataSource={dataSources.industries}
            indicateRequired
            multi
            filterable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <Field
            name="employeeSize"
            component={Select}
            label={messages.employeeSizeLabel}
            dataSource={dataSources.employeeSizes}
            helperText={messages.employeeSizeHelperText}
            indicateRequired
          />
        </div>
        <div className="col-sm-6">
          <Field
            name="type"
            component={Select}
            label={messages.typeLabel}
            dataSource={dataSources.types}
            indicateRequired
          />
        </div>
      </div>
    </form>
  );
};

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = messages.requiredMessageFill;
  }
  if (!values.websiteUrl) {
    errors.websiteUrl = messages.requiredMessageFill;
  } else if (!isURL(values.websiteUrl)) {
    errors.websiteUrl = messages.invalidUrlMessage;
  }
  if (!values.hqAddress) {
    errors.hqAddress = messages.requiredMessageFill;
  }
  if (!values.industries || values.industries.length <= 0) {
    errors.industries = messages.requiredMessageMulti;
  }
  if (!values.employeeSize) {
    errors.employeeSize = messages.requiredMessage;
  }
  if (!values.type) {
    errors.type = messages.requiredMessage;
  }
  return errors;
};

const ID = 'companyProfileBasicPartForm';

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

BasicPart.ID = ID;

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    options: {
      dataSources: { all }
    },
    entities: {
      currentCompany: { item }
    }
  } = state;
  const profile = selectCompanyProfileToEdit(state, ownProps);
  const dataSources = {
    industries: (all && all.industries) || [],
    employeeSizes: enumToDataSource(ECompanyProfileEmployeeSize, {}, (msg, e) => e.label),
    types: enumToDataSource(ECompanyType, intl.messages.constants.enums.ECompanyType)
  };
  return {
    intl,
    dataSources,
    initialValues: {
      name: profile.name || item?.name,
      websiteUrl: profile.websiteUrl,
      hqAddress: _get(profile, 'locations[0].value'),
      industries: valueHoldersToValues(profile.industries),
      employeeSize: profile.employeeSize,
      type: profile.type
    }
  };
};

export default connect(mapStateToProps)(reduxForm(formConfig)(BasicPart));
