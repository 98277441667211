import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useTable, useBlockLayout, useSortBy, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _join from 'lodash/join';

import ParticipantName from '../basic/ParticipantName';
import InfoTooltip from '../basic/InfoTooltip';
import GridPaginator from '../grid/GridPaginator';
import Button from '../mui/Button';
import EchoGridCell from './EchoGridCell';
import EchoHelpPopover from './EchoHelpPopover';

import { filterAndOrderMetrics } from '../../utils/analytics';

import withIntl from '../withIntl';

const METRIC_COL_WIDTH = 43;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

const MIN_NAME_COL_WIDTH = 120;
const INIT_NAME_COL_WIDTH = 220;

// The download function takes a CSV string, the filename and mimeType as parameters
const downloadFile = function(content, fileName, mimeType) {
  const a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';

  if (navigator.msSaveBlob) { // IE10
    navigator.msSaveBlob(new Blob([content], {
      type: mimeType
    }), fileName);
  } else if (URL && 'download' in a) { //html5 A[download]
    a.href = URL.createObjectURL(new Blob([content], {
      type: mimeType
    }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
  }
}

const EchoGrid = props => {
  const {
    metricDefinitions,
    participants,
    intl: { messages },
    onItemClick
  } = props;
  const metrics = useMemo(() => filterAndOrderMetrics(metricDefinitions, m => m.displayOrder != null), [
    metricDefinitions
  ]);
  const tableRef = useRef(null);
  const windowSize = useWindowSize();
  const nameColWidth = useMemo(() => {
    const tableWidth = tableRef.current?.clientWidth || 0;
    const metricColsWidth = metrics.length * METRIC_COL_WIDTH;
    const actualSpace = tableWidth - metricColsWidth;
    if (actualSpace < INIT_NAME_COL_WIDTH) {
      // about 40% of the table width when size is small
      return Math.max(MIN_NAME_COL_WIDTH, tableWidth * 0.4);
    }
    return actualSpace;
  }, [tableRef.current, metrics, windowSize]);
  const columns = useMemo(() => {
    const result = [
      {
        Header: messages.nameLabel,
        sticky: 'left',
        width: nameColWidth,
        accessor: a => `${a.participant.lastName} ${a.participant.firstName}`,
        csvAccessor: a =>
          _join(
            _filter([a.participant.lastName, a.participant.firstName], x => !!x),
            ' '
          ) || 'N/A',
        Cell: ({
          cell: {
            row: { original }
          }
        }) => {
          return <ParticipantName {...original.participant} />;
        }
      }
    ];
    _forEach(metrics, m => {
      const headerCell = {
        Header: m.label,
        minWidth: METRIC_COL_WIDTH,
        width: METRIC_COL_WIDTH,
        maxWidth: METRIC_COL_WIDTH,
        accessor: a => a.result?.metrics[m.key]?.value || 0,
        csvAccessor: a => a.result?.metrics[m.key]?.formattedValue || '',
        Cell: ({
          cell: {
            row: { original }
          }
        }) => {
          const metricValue = original.result?.metrics[m.key] || {};
          const { color, formattedValue } = metricValue;
          return <EchoGridCell color={color} text={formattedValue} />;
        }
      };
      result.push(headerCell);
    });
    return result;
  }, [metrics, nameColWidth]);
  const data = participants;

  const defaultColumn = useMemo(
    () => ({ minWidth: MIN_NAME_COL_WIDTH, width: INIT_NAME_COL_WIDTH, maxWidth: 1200 }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize
  } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useBlockLayout,
    useSticky,
    useSortBy,
    usePagination
  );
  const paginatorOptions = useMemo(
    () => ({ pageNumber: pageIndex, pageSize, totalFilteredElements: data.length, totalPages: pageOptions.length }),
    [pageIndex, pageSize, data.length, pageOptions.length]
  );
  const handlePageNumber = useCallback(page => gotoPage(page), [gotoPage]);
  const handlePageSize = useCallback(size => setPageSize(size), [setPageSize]);

  const handleCsvExport = useCallback(() => {
    const headerRow = _join(
      _map(headerGroups[0].headers, column => column.Header),
      ','
    );
    const valueRows = _map(rows, r => {
      return _join(
        _map(headerGroups[0].headers, column => column.csvAccessor(r.original)),
        ','
      );
    });
    const csvRowsString = _join([headerRow, ...valueRows], '\n');
    downloadFile(csvRowsString, 'Well-Being Export.csv', 'text/csv;encoding:utf-8');
  }, []);

  return (
    <div className="echo-grid-container">
      <div className="container-flex-row jc-flex-end mui-padded">
        <Button onClick={handleCsvExport} label={messages.csvExportLabel} mini />
      </div>
      <div ref={tableRef} {...getTableProps()} className="echo-grid grid sticky">
        <div className="grid-header">
          {_map(headerGroups, headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className="grid-row border-color-highlight__100">
              {_map(headerGroup.headers, (column, idx) => (
                <div {...column.getHeaderProps()} className="grid-cell border-color-highlight__100">
                  <div
                    {...column.getSortByToggleProps()}
                    title=""
                    className={`grid-cell-content${column.isSorted ? ' sorted' : ''}`}
                  >
                    {idx > 0 && (
                      <InfoTooltip
                        dark
                        style={{ marginTop: '6px' }}
                        content={
                          <div>
                            <strong>{metrics[idx - 1].label}</strong>
                            <div
                              className="text-justify mui-margin-top-half"
                              dangerouslySetInnerHTML={{ __html: metrics[idx - 1].description }}
                            />
                          </div>
                        }
                      >
                        <i className="material-icons" style={{ fontSize: '16px', opacity: 0.7 }}>
                          help
                        </i>
                      </InfoTooltip>
                    )}
                    <div className="grid-cell-label">{column.render('Header')}</div>
                    {column.isSortedDesc === true && <i className="grid-sort-icon material-icons">arrow_drop_down</i>}
                    {column.isSortedDesc === false && <i className="grid-sort-icon material-icons">arrow_drop_up</i>}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="grid-body">
          {participants.length === 0 && (
            <div
              className="text-center text-muted border-bottom border-color-highlight__100 bg-primary-light"
              style={{ minHeight: '240px', padding: '32px' }}
            >
              <span className="text-sz-md">{messages.emptyStateTitle}</span>
              <br />
              <span className="text-sz-sm">{messages.emptyStateMessage}</span>
            </div>
          )}
          {_map(page, row => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="grid-row border-color-highlight__100">
                {_map(row.cells, cell => {
                  return (
                    <div
                      {...cell.getCellProps()}
                      className="grid-cell border-color-highlight__100"
                      onClick={() => onItemClick(row.original)}
                    >
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="container-flex-row jc-space-between mui-padded-horizontal ai-center fw-yes">
        <div className="mui-padded-horizontal-half">
          <EchoHelpPopover />
        </div>
        <div className="mui-padded-horizontal">
          <GridPaginator options={paginatorOptions} onPageNumber={handlePageNumber} onPageSize={handlePageSize} />
        </div>
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    nameLabel: 'Zaměstnanec',
    csvExportLabel: 'Export CSV',
    emptyStateTitle: 'Zjistěte více o Well-Being-u vašich zaměstnanců',
    emptyStateMessage: 'Ještě nemáte žádná data. Pozvěte zaměstnance.'
  },
  sk: {
    nameLabel: 'Zamestnanec',
    csvExportLabel: 'Export CSV',
    emptyStateTitle: 'Zistite viac o Well-Being-u vašich zamestnancov',
    emptyStateMessage: 'Ešte nemáte žiadne dáta. Pozvite zamestnancov.'
  },
  en: {
    nameLabel: 'Employee',
    csvExportLabel: 'Export CSV',
    emptyStateTitle: 'Learn more about your employees well-being',
    emptyStateMessage: 'There are no data available yet. Invite your employees.'
  }
};

export default withIntl(EchoGrid, intlMessages);
