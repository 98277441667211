import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, ReferenceLine } from 'recharts';
import debounce from 'lodash/debounce';
import keyBy from 'lodash/keyBy';

import Checkbox from 'material-ui/Checkbox';

import TooltipContent from './Tooltip';
import CustomNameTick from './NameTick';
import CustomBar from './Bar';
import Brush from './Brush';
import Select from '../superfield/SuperSelect';
import { calculateOverviewChart } from '../../utils/utils';

const buildDataSource = ({ scoreMetric, numericalMetrics, metricsByKey, intl }) => {
  const dataSourceCache = {};
  const competencyDataSource = [];
  const selectDataSource = [];
  if (scoreMetric) {
    selectDataSource.push({
      label: scoreMetric.label,
      value: scoreMetric.key
    });
  }
  selectDataSource.push({
    label: intl.messages.filter.competency,
    header: true,
    value: 'competencyHeader',
    children: competencyDataSource
  });
  for (let i = 0; i < numericalMetrics.length; i += 1) {
    const m = numericalMetrics[i];
    const parent = m.parent && metricsByKey[m.parent];
    let dataSourceParent = competencyDataSource;
    if (parent) {
      const parentVal = dataSourceCache[parent.key];
      if (parentVal) {
        if (!parentVal.children) {
          parentVal.children = [];
        }
        dataSourceParent = parentVal.children;
      }
    }
    const metricVal = {
      label: m.label,
      value: m.key
    };
    dataSourceCache[m.key] = metricVal;
    dataSourceParent.push(metricVal);
  }
  return selectDataSource;
};

const OverviewChart = props => {
  const {
    applicants,
    setActiveOverviewChart,
    setSortedOverviewChart,
    setWindowOverviewChart,
    overviewChart: { sorted, chartActive, startIndex, endIndex },
    scoreMetric,
    scoreMapping,
    scoreMappingMetrics,
    numericalMetrics,
    onItemClicked,
    intl
  } = props;
  const selectableMetrics = scoreMetric ? [scoreMetric, ...numericalMetrics] : numericalMetrics;
  const metricsByKey = keyBy(selectableMetrics, m => m.key);
  const currentMetric = metricsByKey[chartActive] || selectableMetrics[0];
  if (!currentMetric) {
    return null;
  }

  const { data, domain, ticks, scoreMean, scoreFormatter, tickFormatter } = calculateOverviewChart(
    applicants,
    currentMetric,
    scoreMetric && sorted,
    scoreMapping,
    scoreMappingMetrics
  );

  const defaultEndIdx = Math.min(40, data.length);
  const endIdx = typeof endIndex === 'number' ? Math.min(data.length, endIndex) : defaultEndIdx;
  const startIdx = Math.min(startIndex, endIdx - 1);
  const windowedData = data.slice(startIdx, endIdx);
  const yAxisWidth = 28;

  const selectDataSource = buildDataSource({ scoreMetric, numericalMetrics, metricsByKey, intl });

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'inline-block', verticalAlign: 'bottom', width: '280px' }}>
          <Select
            label={intl.messages.filter.competency}
            values={[currentMetric.key]}
            dataSource={selectDataSource}
            onChange={v => setActiveOverviewChart(v[0])}
          />
        </div>

        {scoreMetric && (
          <Checkbox
            label={intl.messages.filter.order}
            style={{ width: 280, margin: '20px 16px', height: 24, display: 'inline-block', verticalAlign: 'bottom' }}
            labelStyle={{ margin: 0, fontWeight: 400 }}
            disabled={chartActive === scoreMetric.key}
            onCheck={(e, val) => setSortedOverviewChart(val)}
            checked={sorted}
          />
        )}
      </div>

      <div style={{ fontSize: '12px' }}>
        <ResponsiveContainer height={400}>
          <BarChart
            data={windowedData}
            onClick={e => e && onItemClicked(e.activePayload[0].payload.data)}
            margin={{ top: 10 }}
          >
            <XAxis
              dataKey={'hackLabel'}
              tick={<CustomNameTick data={windowedData} />}
              tickLine={false}
              strokeWidth={1}
              height={125}
              axisLine={false}
            />
            <YAxis
              width={yAxisWidth}
              ticks={ticks}
              tickFormatter={tickFormatter}
              domain={domain}
              tickLine={false}
              axisLine={false}
            />
            <Tooltip content={<TooltipContent intl={intl} />} cursor={{ fill: '#333', fillOpacity: 0.2 }} />
            <Bar
              dataKey={'score'}
              name={intl.messages.filter.score}
              shape={<CustomBar gradient />}
              isAnimationActive={false}
            />
            <ReferenceLine
              y={scoreMean}
              label={{
                value: `Ø (${scoreFormatter(scoreMean)})`,
                position: 'insideBottomRight',
                offset: 5,
                fill: 'rgba(33,33,33,0.87)'
              }}
              stroke={'rgba(33,33,33,0.54)'}
              strokeDasharray={'5 3'}
              isFront
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div style={{ paddingLeft: yAxisWidth - 1, paddingRight: 20 }}>
        <Brush
          min={0}
          max={data.length}
          startIndex={startIdx}
          endIndex={Math.min(endIdx, data.length)}
          onChange={debounce(setWindowOverviewChart, 300)}
          labelStart={({ value }) => (data[value] ? `${value + 1} | ${data[value].shortName}` : '')}
          labelEnd={({ value }) => (data[value - 1] ? `${value} | ${data[value - 1].shortName}` : '')}
        />
      </div>
    </div>
  );
};

OverviewChart.propTypes = {
  applicants: PropTypes.array,
  intl: PropTypes.object,
  overviewChart: PropTypes.object,
  scoreMetric: PropTypes.object,
  scoreMapping: PropTypes.object,
  scoreMappingMetrics: PropTypes.array,
  //
  setActiveOverviewChart: PropTypes.func,
  setSortedOverviewChart: PropTypes.func,
  setWindowOverviewChart: PropTypes.func,
  onItemClicked: PropTypes.func
};

export default OverviewChart;
