import React from 'react';
import reduce from 'lodash/reduce';

const circlePath = (size, offset) => {
  const halfSize = size / 2;
  const arcSize = (size - offset) / 2;
  return `M${halfSize},${size - offset / 2} a${arcSize},${arcSize} 0 0,1 0,${-(
    size - offset
  )} a${arcSize},${arcSize} 0 0,1 0,${size - offset}`;
};

const DonutChart = props => {
  const { size, data, multi, segmentWidth, segmentPadding, label } = props;

  const sWidth = segmentWidth || 2;
  const sPadding = segmentPadding || 0;
  const dataLayers = multi ? data : [data];
  const circles = [];
  for (let l = 0; l < dataLayers.length; l += 1) {
    const dataLayer = dataLayers[l];
    const r = (size - segmentWidth) / 2 - l * segmentWidth;
    const circleLength = 2 * Math.PI * r;
    let offset = -circleLength / 4;
    const dataProps = reduce(
      dataLayer,
      (res, { value }) => {
        res.totalValue += value;
        return res;
      },
      { totalValue: 0 }
    );
    for (let i = 0; i < dataLayer.length; i += 1) {
      const item = dataLayer[i];
      const { value, color, className } = item;
      const fragmentLength = circleLength * value / dataProps.totalValue;
      const paddedFragmentLength = fragmentLength - 2 * sPadding;
      circles.push(
        <circle
          key={`circle-${i}-${l}`}
          className={className}
          cx="50%"
          cy="50%"
          r={r}
          style={{
            strokeWidth: item.segmentWidth || sWidth,
            stroke: color,
            fill: 'none',
            strokeDasharray: `${paddedFragmentLength} ${circleLength - paddedFragmentLength}`,
            strokeDashoffset: -(offset + sPadding)
          }}
        />
      );
      offset += fragmentLength;
    }
  }
  const id = Math.random()
    .toString(36)
    .substring(2);
  const TEXT_SIZE = 20;
  const actualSize = size + (label ? TEXT_SIZE : 0);
  return (
    <svg width={actualSize} height={actualSize}>
      {label && (
        <defs>
          <path id={`circlePath-${id}`} d={circlePath(actualSize, TEXT_SIZE * 0.75)} />
        </defs>
      )}
      <g>
        {circles}
        {label && (
          <text
            className="donut-chart-text"
            style={{ fontSize: '8px', textTransform: 'uppercase', fontWeight: 400, letterSpacing: 0 }}
          >
            <textPath textAnchor="middle" startOffset="50%" href={`#circlePath-${id}`}>
              {label}
            </textPath>
          </text>
        )}
      </g>
    </svg>
  );
};

const getVariantProps = sizeVariant => {
  const variantProps = { size: 64, fontSize: '16px', segmentWidth: 8 };
  if (sizeVariant === 'compact') {
    variantProps.size = 36;
    variantProps.fontSize = '11px';
    variantProps.segmentWidth = 4;
    variantProps.fitLabelHidden = true;
  } else if (sizeVariant === 'mini') {
    variantProps.size = 28;
    variantProps.fontSize = '10px';
    variantProps.fontWeight = 400;
    variantProps.segmentWidth = 2;
    variantProps.labelHidden = true;
    variantProps.fitLabelHidden = true;
  }
  return variantProps;
};

const complementDataItem = (value, eliminated, segmentWidth) => {
  const res = { value };
  if (eliminated) {
    res.className = 'fit-indicator-segment__background-error';
  } else {
    res.className = 'fit-indicator-segment__background';
    res.segmentWidth = segmentWidth / 2;
  }
  return res;
};

const toData = (fit, type, segmentWidth) => {
  const classNameSuffix = type ? `-${type}` : '';
  return [
    { value: fit.value, className: `fit-indicator-segment__main${classNameSuffix}` },
    complementDataItem(1 - fit.value, fit.eliminated, segmentWidth)
  ];
};

const toDataMulti = (fits, segmentWidth) => {
  const dataConfig = reduce(
    fits,
    (res, { value, type, eliminated }) => {
      const classNameSuffix = type ? `-${type}` : '';
      res.data.push({ value, className: `fit-indicator-segment__main${classNameSuffix} ` });
      res.complement += 1 - value;
      res.eliminated |= eliminated;
      return res;
    },
    { data: [], complement: 0, eliminated: false }
  );
  dataConfig.data.push({
    value: dataConfig.complement,
    className: 'fit-indicator-segment__background',
    segmentWidth: segmentWidth / 2
  });
  dataConfig.data.push(complementDataItem(1 - dataConfig.complement, dataConfig.eliminated, segmentWidth));
  return dataConfig.data;
};

const FitIndicator = props => {
  const { fit, label, fits, sizeVariant } = props;
  const variantProps = getVariantProps(sizeVariant);
  const isFitAvailable = fit && fit.value != null;
  const fitValue = isFitAvailable ? fit.value : 0;
  const data = fits
    ? toDataMulti(fits, variantProps.segmentWidth)
    : toData(isFitAvailable ? fit : { value: fitValue }, null, variantProps.segmentWidth);
  const multi = !!fits;
  return (
    <div className="fit-indicator">
      <DonutChart data={data} size={variantProps.size} segmentWidth={variantProps.segmentWidth} label={label} />
      <div
        className="container-flex-column ai-center jc-center"
        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <div
          className={isFitAvailable ? 'text-normal' : 'text-normal-sub'}
          style={{
            fontWeight: variantProps.fontWeight || 400,
            fontSize: variantProps.fontSize,
            lineHeight: variantProps.fontSize,
            marginTop: variantProps.fitLabelHidden ? 0 : '4px',
            letterSpacing: '-0.5px'
          }}
        >
          {isFitAvailable ? (
            <span>
              {Math.round(fitValue * 100)}
              <small className="text-normal-sub">%</small>
            </span>
          ) : (
            !multi && '?'
          )}
        </div>
        {!variantProps.fitLabelHidden &&
          (isFitAvailable || !multi) && (
            <div className="mui-label" style={{ margin: 0, lineHeight: '12px' }}>
              Fit
            </div>
          )}
      </div>
    </div>
  );
};

export default FitIndicator;
