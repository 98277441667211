import { authRequest } from '../Request.js';
import { API_URL } from '../../config';

export function getParticipantActivityEvents(companyId, options) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/companies/${companyId}/participants/activities/events`)
      .query(options)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
