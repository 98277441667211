import React from 'react';
import { Link } from 'react-router';

import PublicFooter from './PublicFooter';
import { formatMessage } from '../../../utils/utils';
import { BRAND } from '../../../constants/constants';

const LoginRedirectFooter = ({ intl }) => {
  const msgTemplate = intl.messages.components.pages.public.common.loginFooter;
  const msg = formatMessage(msgTemplate, (idx, content) => {
    if (idx === 0) {
      return BRAND.label;
    }
    return <Link to="/login">{content}</Link>;
  });
  return <PublicFooter message={msg} />;
};

export default LoginRedirectFooter;
