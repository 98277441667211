import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';

import SimpleModal from './SimpleModal';

class DemoPlayModal extends React.Component {
  componentDidMount() {
    const { getDemoUrl } = this.props;
    getDemoUrl();
  }

  render() {
    const { id, simulationDemo, intl } = this.props;
    const { switchModal } = this.props;
    const messages = intl.messages.components.modals.demoPlay;
    const message = simulationDemo.loading ? (
      <div>
        <CircularProgress style={{ verticalAlign: 'middle' }} />
        <span style={{ marginLeft: '16px', verticalAlign: 'middle' }}>{messages.loading}</span>
      </div>
    ) : simulationDemo.error ? (
      <div>{messages.error}</div>
    ) : (
      <div>{messages.message}</div>
    );
    const actions = [
      <FlatButton
        style={{ verticalAlign: 'top' }}
        label={messages.cancelButton}
        onClick={() => switchModal({ id, open: false })}
      />,
      <FlatButton
        primary
        disabled={simulationDemo.loading || simulationDemo.error}
        label={messages.playButton}
        onClick={() => switchModal({ id, open: false })}
        containerElement={
          <a href={simulationDemo.url} style={{ verticalAlign: 'top' }} target="_blank" rel="noopener noreferrer" />
        }
      />
    ];
    return <SimpleModal id={id} open switchModal={switchModal} customActions={actions} message={message} />;
  }
}

DemoPlayModal.propTypes = {
  intl: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  simulationDemo: PropTypes.object.isRequired,
  //
  switchModal: PropTypes.func.isRequired,
  getDemoUrl: PropTypes.func.isRequired
};

export default DemoPlayModal;
