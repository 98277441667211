import React from 'react';
import _reduce from 'lodash/reduce';

import Tooltip from '../../tooltip/Tooltip';
import TabLinkButton from './TabLinkButton';

import withIntl from '../../withIntl';

import { getNumericalMetrics } from '../../../utils/analytics';

import { ETab } from '../Constants';

const formatScore = s => (s * 10).toFixed(1);

const StatsBar = ({ min, max, avg }) => {
  return (
    <div
      className="border-radius bg-primary__100 tooltip-element"
      style={{ height: '12px', width: '120px', position: 'relative', cursor: 'pointer' }}
    >
      <div style={{ width: '100%', height: '100%', padding: `0 ${(1 - max) * 100}% 0 ${min * 100}%` }}>
        <div className="bg-highlight__300 border-radius" style={{ width: '100%', height: '100%' }} />
        <div
          style={{
            position: 'absolute',
            top: 2,
            bottom: 2,
            width: '8px',
            borderRadius: '4px',
            left: `${avg * 100}%`,
            marginLeft: '-4px'
          }}
          className="bg-highlight"
        />
      </div>
      <Tooltip position={'bottom'}>
        Min: {formatScore(min)} | Avg: {formatScore(avg)} | Max: {formatScore(max)}
      </Tooltip>
    </div>
  );
};

const CompetencySummary = props => {
  const { label, competencies, max } = props;
  const res = [];
  for (let i = 0; i < Math.min(max, competencies.length); i += 1) {
    const { avg, metric, min, max } = competencies[i];
    res.push(
      <div key={metric.key} className="container-flex-row ai-center mui-padded-half mui-padded-horizontal">
        <div>
          <div>
            <span style={{ fontSize: '12px' }}>{metric.label}</span>
          </div>
          <div style={{ paddingTop: '4px' }}>
            <StatsBar min={min} max={max} avg={avg} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="mui-padded mui-padded-horizontal show-on-hover-parent">
      <div className="mui-label mui-padded-horizontal container-flex-row fw-yes ai-center" style={{ margin: 0 }}>
        <span>{label}</span>
        <span className="show-on-hover">&nbsp;&middot;&nbsp;</span>
        <span className="show-on-hover" style={{ letterSpacing: 0, textTransform: 'none' }}>
          <TabLinkButton tab={ETab.ANALYTICS} />
        </span>
      </div>
      <div className="container-flex-row fw-yes">{res}</div>
    </div>
  );
};

const CompetenciesSummary = props => {
  const {
    activity,
    intl: { messages }
  } = props;
  const { managers, members } = activity;
  const metrics = getNumericalMetrics(activity.metricDefinitions);
  const metricsStats = _reduce(
    [...managers, ...members],
    (res, { result }) => {
      if (result && result.metrics) {
        for (let i = 0; i < metrics.length; i += 1) {
          const md = metrics[i];
          const m = result.metrics[md.key];
          if (m && m.value) {
            const v = m.value;
            let prevM = res[md.key];
            if (!prevM) {
              prevM = { key: md.key, metric: md };
              res[md.key] = prevM;
            }
            prevM.count = (prevM.count || 0) + 1;
            prevM.sum = (prevM.sum || 0) + v;
            prevM.min = prevM.min && prevM.min <= v ? prevM.min : v;
            prevM.max = prevM.max && prevM.max >= v ? prevM.max : v;
          }
        }
      }
      return res;
    },
    {}
  );
  const strengths = [];
  const weaknesses = [];
  for (let key in metricsStats) {
    if (metricsStats.hasOwnProperty(key)) {
      const m = metricsStats[key];
      if (m.count) {
        const avg = m.sum / m.count;
        if (avg > 0.6) {
          strengths.push({ ...m, avg });
        }
        if (avg < 0.5) {
          weaknesses.push({ ...m, avg });
        }
      }
    }
  }
  strengths.sort((a, b) => b.avg - a.avg);
  weaknesses.sort((a, b) => a.avg - b.avg);
  return (
    <div>
      {strengths.length > 0 || weaknesses.length > 0 ? (
        <div className="container-flex-row fw-yes">
          {strengths.length > 0 && (
            <CompetencySummary label={messages.strengthsLabel} competencies={strengths} max={3} />
          )}
          {weaknesses.length > 0 && (
            <div className={strengths.length > 0 ? 'border-left border-color-primary__50' : ''}>
              <CompetencySummary label={messages.weaknessLabel} competencies={weaknesses} max={1} />
            </div>
          )}
        </div>
      ) : (
        <em className="text-muted">N/A</em>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    strengthsLabel: 'Přednosti',
    weaknessLabel: 'Rezerva'
  },
  sk: {
    strengthsLabel: 'Prednosti',
    weaknessLabel: 'Rezerva'
  },
  en: {
    strengthsLabel: 'Strengths',
    weaknessLabel: 'Weakness'
  }
};

export default withIntl(CompetenciesSummary, intlMessages);
