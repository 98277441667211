import React from 'react';

import ProjectCardSummary from '../../cards/ProjectCardSummary';
import InfoCard from '../InfoCard';

const ProjectSummaryInfoCard = ({ project }) => {
  return (
    <InfoCard>
      <ProjectCardSummary project={project} />
    </InfoCard>
  );
};

export default ProjectSummaryInfoCard;
