import React from 'react';
import { connect } from 'react-redux';

import MUIDrawer from 'material-ui/Drawer';

import MenuItem from '../navigation/NavigationMenuItem';
import BetaBadge from '../badge/BetaBadge';

import { selectAccountPermissions } from '../../selectors/auth';
import { selectSidebarSettings } from '../../selectors/ui';

import * as sidebarActions from '../../actions/options/sidebarActions';
import * as guideActions from '../../actions/options/guideActions';
import { EContractPlanMode } from '../../constants/enum';

const Drawer = props => {
  return <MUIDrawer {...props} />;
};

const Sidebar = props => {
  const { sidebar, sidebarSettings, auth, intl } = props;
  const { toggleSidebar } = props;

  const sourcingEnabled = auth.modes[EContractPlanMode.SOURCING.key];
  const recruitmentEnabled = auth.modes[EContractPlanMode.RECRUITMENT.key];
  const developmentEnabled = auth.modes[EContractPlanMode.DEVELOPMENT.key];
  const teamsEnabled = auth.modes[EContractPlanMode.TEAMS.key];
  const assessmentsEnabled = recruitmentEnabled || developmentEnabled;

  const navigationMsgs = intl.messages.components.navigation;

  return (
    <div className="sidebar-container">
      <Drawer
        width={sidebarSettings.width || 256}
        open={sidebar.open || sidebarSettings.docked}
        docked={sidebarSettings.docked}
        onRequestChange={toggleSidebar}
        containerStyle={{
          boxShadow: 'none',
          background: sidebarSettings.docked ? 'rgba(255, 255, 255, 0.7)' : 'white'
        }}
        className="sidebar-drawer"
        containerClassName="sidebar-drawer-container"
      >
        <div className="container-flex fd-column ai-stretch sidebar" style={{ fontSize: '16px', height: '100%' }}>
          <div className="mui-padded-x2">
            <div className="sidebar__logo" />
          </div>
          <div className="flex1">
            {auth.projectsAccess && assessmentsEnabled && (
              <MenuItem
                icon={<i className="material-icons">home</i>}
                label={navigationMsgs.home.label}
                to="/home"
                inline
              />
            )}
            {auth.groupsAccess && teamsEnabled && (
              <MenuItem
                icon={<i className="material-icons">group</i>}
                label={navigationMsgs.groups.label}
                to="/groups"
                inline
                badge={<BetaBadge />}
              />
            )}
            {auth.projectsAccess && (
              <MenuItem
                icon={<i className="material-icons">{assessmentsEnabled ? 'assignment_ind' : 'lock'}</i>}
                label={navigationMsgs.assessments.label}
                to="/projects"
                inline
              />
            )}
            {auth.projectsAccess && (
              <MenuItem
                icon={<span className={`mdi mdi-${sourcingEnabled ? 'account-search' : 'lock'}`} />}
                label={navigationMsgs.sourcing.label}
                to="/sourcing"
                inline
              />
            )}
            {auth.projectsAccess && sourcingEnabled && (
              <div>
                <MenuItem label={navigationMsgs.sourcing.opportunitiesLabel} to="/sourcing/opportunities" sub />
                <MenuItem label={navigationMsgs.sourcing.companyProfileLabel} to="/sourcing/profile" sub />
              </div>
            )}
            {auth.designerAccess && (
              <MenuItem
                icon={<span className={`mdi mdi-${auth.designerAccess ? 'message-processing' : 'lock'}`} />}
                label={navigationMsgs.designer.label}
                to="/designer"
                inline
              />
            )}
          </div>
          {/* (
            <div>
              <div style={{ padding: '12px 0', textAlign: 'center', fontSize: '14px' }}>
                <span style={{ verticalAlign: 'bottom', fontWeight: '300' }}>Powered by</span>
                <img
                  src={`/img/branding/${BRAND.folder()}/logo.svg`}
                  alt={`Powered by ${BRAND.label}`}
                  style={{ height: '23px', marginLeft: '8px' }}
                />
              </div>
            </div>
          )*/}
        </div>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    options: { sidebar }
  } = state;
  return {
    intl,
    sidebar,
    auth: selectAccountPermissions(state, ownProps),
    sidebarSettings: selectSidebarSettings(state, ownProps)
  };
};

const actions = {
  toggleSidebar: sidebarActions.toggleSidebar,
  showGuide: guideActions.showGuide
};

export default connect(mapStateToProps, actions)(Sidebar);
