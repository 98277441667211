import React from 'react';
import clr from 'color';
import cn from 'classnames';

const Tag = props => {
  const { name, color, onRemove, rootClassName } = props;
  const colorObject = clr(color);
  const backgroundColor = colorObject.string();
  const isDark = false; // colorObject.isDark();
  const textColor = isDark ? 'white' : 'rgba(33,33,33,0.87)';
  const className = cn({ [rootClassName || 'tag']: true, 'bg-primary-light': true });
  return (
    <div className={className} style={{ borderLeft: `4px solid ${backgroundColor}` }}>
      <span style={{ color: textColor }}>{name}</span>
      {onRemove && (
        <div className="tag__remove-button-container">
          <a role="button" className="link-button" onClick={onRemove}>
            <i className="material-icons" style={{ color: textColor }}>clear</i>
          </a>
        </div>
      )}
    </div>
  );
};

export default Tag;
