import React from 'react';
import { connect } from 'react-redux';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, ReferenceArea, ReferenceLine } from 'recharts';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import color from 'color';

import Toggle from 'material-ui/Toggle';

import IconPanel from './IconPanel';

const Y_AXIS_WIDTH = 0;
const LEFT_PADDING = 4;
const RIGHT_PADDING = 32;

const r = v => Math.round(v);

const CustomTooltipContent = props => {
  const { payload, active } = props;
  const p = payload && payload.length && payload[0].payload;
  if (!p || !active) {
    //noinspection JSConstructorReturnsPrimitive
    return null;
  }
  return (
    <div className="chart__tooltip">
      <span>{p.label}</span>
    </div>
  );
};

const CustomBar = props => {
  const { x, y, width, height, gradient, fill, color, colors, metric, radius, dataKey, payload } = props;
  if (isNaN(width) || width < 0 || isNaN(height) || height < 0) {
    //noinspection JSConstructorReturnsPrimitive
    return null;
  }
  const cx = r(x);
  const cy = r(y);
  const bottom = y + height;
  const right = x + width;

  const gradientId = `gradientFill${Math.random().toString(36).substring(2)}`;

  const w = r(right - cx);
  const h = r(bottom - cy);

  const barData = payload[`${dataKey}Data`];
  const dataColors = barData && barData.colors;
  const actualColors =
    dataColors && dataColors.length > 0 ? dataColors : colors && colors.length > 0 ? colors : [color || fill];

  const barW = w / actualColors.length;
  return (
    <g style={{ opacity: metric?.inactive ? 0.3 : 1 }}>
      {gradient && (
        <defs>
          {_map(actualColors, (c, idx) => (
            <linearGradient key={`grad_${idx}`} id={`${gradientId}_${idx}`} x1={0} x2={0} y1={0} y2={4}>
              <stop offset="0%" stopColor={c} />
              <stop offset="100%" stopColor={'rgba(255, 255, 255, 0)'} />
            </linearGradient>
          ))}
        </defs>
      )}
      {_map(actualColors, (c, idx) => (
        <rect
          key={`rect_${idx}`}
          x={cx + idx * barW}
          y={cy}
          rx={radius == null ? 2 : radius}
          ry={radius == null ? 2 : radius}
          width={barW}
          height={h}
          fill={gradient ? `url(#${gradientId}_${idx})` : c}
          style={{ shapeRendering: 'geometricPrecision' }}
        />
      ))}
    </g>
  );
};

const calcX = (itemCount, index, width, x) => {
  return itemCount ? Y_AXIS_WIDTH + index * ((width - LEFT_PADDING - RIGHT_PADDING) / itemCount) : x;
};

const CustomMetricTick = props => {
  const { x, y, fill, width, index, data, payload } = props;
  const {
    metric: { inactive }
  } = data[index];
  const name = payload.value;
  const ax = calcX(data.length, index, width, x);
  return (
    <g transform={`translate(${ax},${y})`} className={`metric-tick${inactive ? ' inactive' : ''}`}>
      <text x={8} y={LEFT_PADDING + 8} fill={fill} textAnchor="start" transform="rotate(-90)">
        {name}
      </text>
    </g>
  );
};

// coefficients for n = 4: 0, 1, 2, 4
const calcQ = (v, n, rev) => {
  const x = rev ? v : n - v - 1;
  if (x === 0) {
    return 0;
  }
  return Math.pow(2, x - 1);
};

const COLORS = ['#722ed0', '#fb47c2', '#ffa0dc', '#ffd1ee'];
const DESATURATED_COLORS = ['#808080', '#a1a1a1', '#cfcfcf', '#e8e8e8'];
const INACTIVE_COLORS = DESATURATED_COLORS;

const calcColor = (colors, idx, n, rev) => {
  const i = rev ? n - idx - 1 : idx;
  const stepsPerColor = Math.ceil(n / colors.length);
  const colorIdx = i % colors.length;
  const step = stepsPerColor - Math.floor(i / colors.length);
  return color(colors[colorIdx])
    .alpha(0.2 + step * (0.8 / stepsPerColor))
    .rgb()
    .string();
};

// const renderCurlyBraceSvg = ({ w, h, ...props }) => {
//   const cx = 0; //w / 5;
//   return (
//     <g style={{ shapeRendering: 'geometricPrecision' }}>
//       <path d={`M 0 0 C ${w - cx} 0, ${cx} ${h / 2}, ${w} ${h / 2}`} {...props} />
//       <path d={`M ${w} ${h / 2} C ${cx} ${h / 2}, ${w - cx} ${h}, 0 ${h}`} {...props} />
//     </g>
//   );
// };
//
const ReferenceAreaLabel = props => {
  const { value, viewBox } = props;
  const { x, y, width, height } = viewBox;
  if (height <= 0 || width <= 0) {
    //noinspection JSConstructorReturnsPrimitive
    return null;
  }
  return (
    <g transform={`translate(${x + width},${y})`}>
      <g transform={`translate(0, 2)`}>
        <text transform={`rotate(90) translate(${height / 2}, -16)`} textAnchor="middle" fontWeight="300">
          {_map(value.split(/\s/), (v, idx) => (
            <tspan x="0" dy={idx > 0 ? '14' : '0'} key={v}>
              {v}
            </tspan>
          ))}
        </text>
      </g>
    </g>
  );
};

const renderLegend = (bars, messages) => {
  const res = [];
  for (let i = bars.length - 1; i > 0; i -= 1) {
    const { dataKey, color } = bars[i];
    const label = messages.legend[i];
    if (label) {
      res.push(
        <div key={dataKey} className="mui-padded-vertical-half mui-padded-horizontal">
          <div
            className="circle-indicator ci-size-8"
            style={{ backgroundColor: color, border: `1px solid ${color}` }}
          />
          <span style={{ verticalAlign: 'middle' }}>{label}</span>
        </div>
      );
    }
  }
  return res;
};

const isHighlightActive = metrics => {
  for (let i = 0; i < metrics.length; i += 1) {
    const m = metrics[i];
    const data = m?.data || [];
    for (let j = 0; j < data.length; j += 1) {
      const { colors } = getValueItem(data[j]);
      if (colors?.length > 0) {
        return true;
      }
    }
  }
  return false;
};

const getValueItem = dataItem => (dataItem.values && dataItem.values[0]) || {};

class DualMetricsMassChart extends React.Component {
  state = {
    sorted: true
  };

  handleToggleSorted = () => this.setState({ sorted: !this.state.sorted });

  render() {
    const { metrics, intl, width } = this.props;
    const { sorted } = this.state;
    const messages = intl.messages.components.analytics.dualMetricsMassChart;
    if (!metrics || metrics.length === 0) {
      return (
        <div
          className="border-radius text-center text-muted text-sz-reg bg-primary-light"
          style={{ padding: '48px 24px' }}
        >
          <em>{messages.noDataMessage}</em>
        </div>
      );
    }
    const baseColors = isHighlightActive(metrics) ? DESATURATED_COLORS : COLORS;
    const chart = _reduce(
      metrics,
      (res, m) => {
        const { data, metric } = m;
        if (!data || !metric) {
          return res;
        }
        const len = data.length;
        const colors = metric.inactive ? INACTIVE_COLORS : baseColors;
        const color = colors[0];
        const dataItem = {
          key: metric.key,
          metric: { key: metric.key, shortLabel: metric.shortLabel, inactive: metric.inactive },
          color,
          label: metric.label,
          totalValue: 0
        };
        const dataItemOpp = {
          key: `${metric.key}_opposite`,
          metric: { key: metric.key, shortLabel: metric.shortLabel, inactive: metric.inactive },
          opposite: true,
          color,
          label: metric.oppositeLabel,
          totalValue: 0
        };
        const bars = [];
        for (let i = 0; i < len; i += 1) {
          const valueItem = getValueItem(data[i]);
          const val = valueItem.value || 0;

          const itemColors = valueItem.colors || [];

          const q = calcQ(i, len, false);
          dataItem[`bar${len - i - 1}`] = val * q;
          dataItem[`bar${len - i - 1}Data`] = {
            colors: [...itemColors, calcColor(colors, i, len, false)],
            participants: valueItem.participants
          };
          dataItem.totalValue += val * q;

          const qRev = calcQ(i, len, true);
          dataItemOpp[`bar${i}`] = val * qRev;
          dataItemOpp[`bar${i}Data`] = {
            colors: [...itemColors, calcColor(colors, i, len, true)],
            participants: valueItem.participants
          };
          dataItemOpp.totalValue += val * qRev;

          bars.push({ dataKey: `bar${i}`, color: calcColor(baseColors, i, len, true), legend: '' });
        }
        if (metric.reversed) {
          res.data.push(dataItemOpp);
          res.data.push(dataItem);
        } else {
          res.data.push(dataItem);
          res.data.push(dataItemOpp);
        }
        res.bars = bars;
        return res;
      },
      { data: [], bars: [] }
    );
    if (sorted) {
      chart.data = _orderBy(chart.data, i => i.totalValue, ['desc']);
    }
    return (
      <div style={{ fontSize: '14px' }}>
        <div className="container-flex-row fw-yes">
          <div className="flex1" style={{ overflowX: 'auto', flexBasis: 'auto' }}>
            <div
              className="dual-metrics-mass-chart__container"
              style={{
                width,
                minWidth: Y_AXIS_WIDTH + chart.data.length * 42,
                maxWidth: Y_AXIS_WIDTH + chart.data.length * 72
              }}
            >
              <ResponsiveContainer height={300}>
                <BarChart
                  data={chart.data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0
                  }}
                  barCategoryGap={12}
                >
                  <ReferenceArea
                    y1={2}
                    fill="rgba(0,0,0,0.16)"
                    ifOverflow="extendDomain"
                    label={<ReferenceAreaLabel value={messages.zones[2]} />}
                  />
                  <ReferenceLine
                    y={2}
                    strokeDasharray="5 5"
                    stroke="rgba(0,0,0,0.24)"
                    ifOverflow="extendDomain"
                    isFront
                  />
                  <ReferenceArea
                    y1={1}
                    y2={2}
                    fill="rgba(0,0,0,0.10)"
                    ifOverflow="extendDomain"
                    label={<ReferenceAreaLabel value={messages.zones[1]} />}
                  />
                  <ReferenceLine
                    y={1}
                    strokeDasharray="5 5"
                    stroke="rgba(0,0,0,0.24)"
                    ifOverflow="extendDomain"
                    isFront
                  />
                  <ReferenceArea
                    y2={1}
                    fill="rgba(0,0,0,0.04)"
                    ifOverflow="extendDomain"
                    label={<ReferenceAreaLabel value={messages.zones[0]} />}
                  />
                  <Tooltip
                    wrapperStyle={{ zIndex: 100 }}
                    cursor={false}
                    content={<CustomTooltipContent data={chart.data} />}
                  />
                  <XAxis
                    dataKey="label"
                    interval={0}
                    tick={<CustomMetricTick data={chart.data} />}
                    tickLine={false}
                    axisLine={false}
                    height={10}
                    padding={{ left: LEFT_PADDING, right: RIGHT_PADDING }}
                  />
                  {Y_AXIS_WIDTH > 0 && <YAxis width={Y_AXIS_WIDTH} />}
                  {_map(chart.bars, b => (
                    <Bar
                      key={b.dataKey}
                      dataKey={b.dataKey}
                      stackId="a"
                      shape={<CustomBar gradient radius={0} dataKey={b.dataKey} />}
                      isAnimationActive={false}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
              <div style={{ padding: `0 ${RIGHT_PADDING}px 8px ${Y_AXIS_WIDTH + LEFT_PADDING}px`, marginTop: '2px' }}>
                <IconPanel data={chart.data} />
              </div>
              <div className="container-flex-row fw-yes mui-padded-vertical jc-center">
                {renderLegend(chart.bars, messages)}
              </div>
            </div>
          </div>
          <div className="mui-padded" style={{ minWidth: 200 }}>
            <Toggle
              className="dual-metrics-mass-chart__sort-toggle"
              label={messages.sortedLabel}
              labelStyle={{ margin: 0, fontWeight: 400, zIndex: 0 }}
              labelPosition="right"
              onToggle={this.handleToggleSorted}
              toggled={sorted}
            />
            <div className="text-muted text-sz-sm mui-padded-vertical-x2" style={{ maxWidth: '40em' }}>
              {messages.description}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ intl }) => ({ intl }))(DualMetricsMassChart);
