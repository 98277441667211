import * as Api from '../../api/entities/opportunityApi';
import {
  OPPORTUNITIES_GET,
  OPPORTUNITY_GET,
  OPPORTUNITY_PARTICIPANT_GET,
  OPPORTUNITIES_FILTER_FULL_TEXT_SET,
  OPPORTUNITY_PARTICIPANTS_SORT,
  OPPORTUNITY_PARTICIPANTS_RESPONSE_SET,
  OPPORTUNITY_PARTICIPANTS_SHOW_REJECTED_TOGGLE,
  OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_PERFORM,
  OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_SET_FILTER_PROPERTY
} from '../../constants/constants';
import { createApiCallAction } from '../apiCallAction';

const getOpportunitiesAction = createApiCallAction(OPPORTUNITIES_GET, Api.getOpportunities);
export const getOpportunities = () => getOpportunitiesAction();

const getOpportunityAction = createApiCallAction(OPPORTUNITY_GET, Api.getOpportunity);
export const getOpportunity = (id, lang) => getOpportunityAction(id, lang);

export const setOpportunitiesFilterFullText = value => ({ type: OPPORTUNITIES_FILTER_FULL_TEXT_SET, payload: value });

const getOpportunityParticipantAction = createApiCallAction(OPPORTUNITY_PARTICIPANT_GET, Api.getOpportunityParticipant);
export const getOpportunityParticipant = (opportunityId, participantId, lang) =>
  getOpportunityParticipantAction(opportunityId, participantId, lang);

export const sortOpportunityParticipants = (properties, order) => ({
  type: OPPORTUNITY_PARTICIPANTS_SORT,
  payload: { properties, order }
});

const setOpportunityParticipantsResponseAction = createApiCallAction(
  OPPORTUNITY_PARTICIPANTS_RESPONSE_SET,
  Api.setOpportunityParticipantsResponse
);
export const setOpportunityParticipantsResponse = (id, participantIds, response, rejectReason) =>
  setOpportunityParticipantsResponseAction(id, participantIds, response, rejectReason);

export const toggleShowRejectedOpportunityParticipants = show => ({
  type: OPPORTUNITY_PARTICIPANTS_SHOW_REJECTED_TOGGLE,
  payload: show
});

const performOpportunityActiveSearchAction = createApiCallAction(
  OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_PERFORM,
  Api.performOpportunityActiveSearch,
  { transformArgsToMeta: ([id, query, page]) => ({ page }) }
);
export const performOpportunityActiveSearch = (id, query, page) =>
  performOpportunityActiveSearchAction(id, query, page);

export const setOpportunityActiveSearchFilterProperty = (prop, value) => ({
  type: OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_SET_FILTER_PROPERTY,
  payload: { key: prop, value }
});
