import { handleActions } from 'redux-actions';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';

import {
  TALENT_MARKET_PLANS_GET,
  TALENT_MARKET_PLAN_SELECT,
  TALENT_MARKET_PLAN_CURRENCY_SELECT,
  TALENT_MARKET_PLAN_BILLING_PERIOD_SELECT
} from '../../constants/constants';
import { EBillingPeriod } from '../../constants/enum';

// imprecise, use with caution
const timeLimitToDays = tl => {
  if (!tl) {
    return null;
  }
  const { value, unit } = tl;
  switch (unit) {
    case 'DAYS':
      return value;
    case 'WEEKS':
      return value * 7;
    case 'MONTHS':
      return value * 30;
    case 'YEARS':
      return value * 365;
  }
  return null;
};

const orderByTimeLimit = (a, b) => {
  const atl = timeLimitToDays(a.constraints?.timeLimit?.value);
  const btl = timeLimitToDays(b.constraints?.timeLimit?.value);
  if (atl == null) {
    return btl == null ? 0 : 1;
  }
  if (btl == null) {
    return -1;
  }
  return atl > btl ? 1 : atl < btl ? -1 : 0;
};
const orderByPrice = (a, b, params) => {
  const ap = (a.monthlyPrices || {})[params.currency]?.value;
  const bp = (b.monthlyPrices || {})[params.currency]?.value;
  if (ap == null) {
    return bp == null ? 0 : 1;
  }
  if (bp == null) {
    return -1;
  }
  return ap > bp ? 1 : ap < bp ? -1 : 0;
};
const SORT_FNS = [orderByTimeLimit, orderByPrice];

const orderPlans = (items, currency) =>
  items.sort((a, b) => {
    for (let i = 0; i < SORT_FNS.length; i += 1) {
      const fn = SORT_FNS[i];
      const out = fn(a, b, { currency });
      if (out !== 0) {
        return out;
      }
    }
    return 0;
  });

const processPlans = items => {
  const { currencies, map } = _reduce(
    items,
    (res, plan) => {
      res.map[plan.id] = plan;
      const mp = plan.monthlyPrices || {};
      if (!_isEmpty(mp)) {
        if (!res.currencies) {
          res.currencies = [];
          for (let currency in mp) {
            if (mp.hasOwnProperty(currency)) {
              res.currencies.push(currency);
            }
          }
        } else {
          res.currencies = _filter(res.currencies, c => !!mp[c]);
        }
      }
      return res;
    },
    { currencies: null, map: {} }
  );
  const defaultCurrency = currencies && currencies[0];
  return {
    currencies,
    defaultCurrency,
    map,
    items: orderPlans(items, defaultCurrency)
  };
};

const initialState = {
  items: [],
  selectedId: null,
  currency: null,
  billingPeriod: EBillingPeriod.ANNUAL.key
};

export default handleActions(
  {
    [TALENT_MARKET_PLANS_GET.PENDING]: state => ({ ...state, loading: true }),
    [TALENT_MARKET_PLANS_GET.ERROR]: state => ({ ...state, loading: false, error: true }),
    [TALENT_MARKET_PLANS_GET.SUCCESS]: (state, { payload: { items } }) => ({
      ...state,
      ...processPlans(items),
      loading: false,
      error: false
    }),
    [TALENT_MARKET_PLAN_SELECT]: (state, { payload: { planId } }) => ({
      ...state,
      selectedId: planId
    }),
    [TALENT_MARKET_PLAN_BILLING_PERIOD_SELECT]: (state, { payload: { billingPeriod } }) => ({
      ...state,
      billingPeriod
    }),
    [TALENT_MARKET_PLAN_CURRENCY_SELECT]: (state, { payload: { currency } }) => ({
      ...state,
      currency
    })
  },
  initialState
);
