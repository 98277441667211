import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import { pure } from 'recompose';

import AnalyticsTeaserMessage from './AnalyticsTeaserMessage';
import MetricsBubbleChartPanel from '../analytics/bubbleChart/MetricsBubbleChartPanel';
import CultureFitAnalytics from './CultureFitAnalytics';
import EchoAnalytics from './EchoAnalytics';
import ActivityToggleHeader from './ActivityToggleHeader';
import Button from '../mui/Button';

import {
  getActivityMapKey,
  getActivityMetricDefinitions,
  transformMember,
  transformOrGenerateMember,
  saveSubgroupAnalyticsState,
  getSubgroupAnalyticsState
} from './TeamUtils';
import { getNumericalMetrics } from '../../utils/analytics';
import { formatMessage } from '../../utils/utils';

import { HIGHLIGHT_COLORS } from '../../constants/constants';
import { EGroupType, EActivityType } from '../../constants/enum';

import PRNG from '../../utils/prng';

const TeaserMessage = props => {
  const { groupType, onAdd, messages } = props;
  let icon = 'add';
  let message = (
    <span>
      {formatMessage(messages.teasers.addMembersMessages[groupType.key] || messages.teasers.addMembersMessage, [
        val => (
          <div key={'addButton'} className="inline-container mui-padded">
            <Button onClick={onAdd} primary raised label={val} />
          </div>
        )
      ])}
    </span>
  );
  return <AnalyticsTeaserMessage icon={icon} message={message} />;
};

let ActivityAnalytics = props => {
  const { members, readOnly, activity, configuration, manager, hidden, intl, groupType } = props;
  const { onAdd, onToggleHidden, onShowProfile } = props;
  const activityKey = getActivityMapKey(activity);
  const activityMetricDefinitions = (activity && getActivityMetricDefinitions(activity, configuration)) || {};
  const rng = new PRNG(1);
  const messages = intl.messages.components.teams.activityAnalytics;
  const finishedCount = _reduce(
    members,
    (count, m) => {
      const finished = !!_get(m, `meta.activities.map.${activityKey}.finishedAt`);
      return count + (finished ? 1 : 0);
    },
    0
  );
  const participants = [];
  for (let i = 0; i < (finishedCount > 0 ? members.length : 10); i += 1) {
    const m = i < members.length ? members[i] : null;
    const p =
      finishedCount > 0
        ? transformMember(m, activityKey)
        : transformOrGenerateMember(m, activity, activityMetricDefinitions, rng);
    participants.push(p);
  }
  const managerParticipant =
    groupType === EGroupType.TEAM
      ? finishedCount > 0
        ? transformMember(manager, activityKey)
        : transformOrGenerateMember(manager, activity, activityMetricDefinitions, rng)
      : null;
  if (managerParticipant) {
    managerParticipant.crossCompared = true;
    managerParticipant.crossComparedColor = HIGHLIGHT_COLORS[0];
  }
  return (
    <div className="team__competency-analytics-content">
      <div className="mui-padded-horizontal">
        <ActivityToggleHeader activity={activity} onToggle={onToggleHidden} expanded={!hidden} />
      </div>
      {!hidden && (
        <div>
          <div style={{ position: 'relative' }}>
            {finishedCount <= 0 && (
              <div className="teaser-overlay">
                {!readOnly && <TeaserMessage groupType={groupType} messages={messages} onAdd={onAdd} />}
              </div>
            )}
            {EActivityType.OFFICE_DAY.value === activity.key && (
              <div className="container-padded-big">
                <div className="container-chart-card">
                  <MetricsBubbleChartPanel
                    participants={participants}
                    metrics={getNumericalMetrics(activityMetricDefinitions)}
                  />
                </div>
              </div>
            )}
            {EActivityType.GAME_CHANGER.value === activity.key && (
              <div className="container-padded-big">
                <div className="container-chart-card">
                  <MetricsBubbleChartPanel
                    participants={participants}
                    metrics={getNumericalMetrics(activityMetricDefinitions)}
                  />
                </div>
              </div>
            )}
            {EActivityType.CULTURE_FIT.isLike(activity) && (
              <div>
                <CultureFitAnalytics
                  metricDefinitions={activityMetricDefinitions}
                  participants={participants}
                  manager={managerParticipant}
                />
              </div>
            )}
            {EActivityType.ECHO.value === activity.key && (
              <div>
                <EchoAnalytics
                  metricDefinitions={activityMetricDefinitions}
                  participants={participants}
                  onItemClick={item => onShowProfile(item, item.meta.activities.map[activityKey])}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ActivityAnalytics = connect(({ intl }) => ({ intl }))(ActivityAnalytics);

const getMembersCommon = ({ members, highlighted }) => {
  // TODO move
  const allMembers = [];
  for (let i = 0; i < members.length; i += 1) {
    let member = members[i];
    if (highlighted && highlighted[member.id]) {
      member = {
        ...member,
        crossCompared: true,
        crossComparedColor: highlighted[member.id]
      };
    }
    allMembers.push(member);
  }
  return {
    all: allMembers
  };
};

const cache = {};
const getMembers = ({ members, highlighted }) => {
  if (cache.members !== members || cache.highlighted !== highlighted) {
    cache.members = members;
    cache.highlighted = highlighted;
    cache.result = getMembersCommon(cache);
  }
  return cache.result;
};

const initialProps = {
  hiddenActivities: {}
};

class TeamCompetencyAnalytics extends React.Component {
  state = initialProps;

  componentDidMount() {
    this.loadState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.team !== prevProps.team) {
      this.loadState();
    } else {
      this.saveState();
    }
  }

  loadState = () => {
    const { team, type } = this.props;
    if (team && type) {
      const state = getSubgroupAnalyticsState(team, type);
      if (state) {
        this.setState(state);
      } else {
        this.setState(initialProps);
      }
    }
  };

  saveState = () => {
    const { team, type } = this.props;
    if (team && type) {
      saveSubgroupAnalyticsState(team, type, this.state);
    }
  };

  handleToggleActivity = a => {
    const key = getActivityMapKey(a);
    const { [key]: hidden, ...hiddenActivities } = this.state.hiddenActivities;
    if (!hidden) {
      hiddenActivities[key] = true;
    }
    this.setState({ hiddenActivities });
  };

  render() {
    const { members, readOnly, groupType, highlighted, activities, configuration, manager } = this.props;
    const { onAdd, onShowProfile } = this.props;
    const { hiddenActivities } = this.state;
    const m = getMembers({ members, highlighted });
    const commonProps = {
      members: m.all,
      configuration,
      readOnly,
      manager,
      onAdd,
      groupType,
      onShowProfile,
      onToggleHidden: this.handleToggleActivity
    };
    return (
      <div className="team__competency-analytics">
        {_map(activities, a => {
          const mapKey = getActivityMapKey(a);
          return <ActivityAnalytics key={mapKey} activity={a} hidden={hiddenActivities[mapKey]} {...commonProps} />;
        })}
      </div>
    );
  }
}

export default pure(TeamCompetencyAnalytics);
