import React from 'react';
import { Area, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import moment from 'moment';

import DatePicker from 'material-ui/DatePicker';

import Spinner from '../spinner/Spinner';

import * as OpportunityApi from '../../api/entities/opportunityApi';

const STATS_PROPS = [
  {
    key: 'DISPLAYED',
    color: 'gray'
  },
  {
    key: 'OPENED',
    color: 'lightcoral'
  },
  {
    key: 'LIKED',
    color: 'cornflowerblue'
  },
  {
    key: 'INTERESTED',
    color: 'mediumseagreen'
  },
  {
    key: 'ACCEPTED',
    color: 'seagreen'
  }
];

const interactionsToData = (items, from, to, locale) => {
  const format = d => d.locale(locale).format('L');
  let currentDate = moment(from).startOf('day');
  const maxDate = moment(to).startOf('day');
  const dateMap = {};
  const data = [];
  while (!currentDate.isAfter(maxDate)) {
    const df = format(currentDate);
    const dataItem = {
      name: df
    };
    forEach(STATS_PROPS, ({ key }) => (dataItem[key] = 0));
    data.push(dataItem);
    dateMap[df] = dataItem;
    currentDate = currentDate.add('days', 1);
  }
  forEach(
    items,
    ({ event, timestamp }) => {
      const date = format(moment(timestamp));
      const dataItem = dateMap[date];
      if (dataItem) {
        dataItem[event] += 1;
      }
    },
    items
  );
  return data;
};

const DatePickerField = props => {
  const { label, value, onChange, intl, ...rest } = props;
  const dtf = Intl && Intl.DateTimeFormat;
  const locale = dtf ? intl.locale : 'en';
  const messages = intl.messages.components.pages.private.opportunities.stats;
  return (
    <div style={{ width: '92px', margin: '0 4px' }}>
      <DatePicker
        hintText={label}
        floatingLabelText={label}
        DateTimeFormat={dtf}
        locale={locale}
        onChange={(event, value) => onChange(value)}
        value={value}
        fullWidth
        textFieldStyle={{ fontSize: '14px', height: '68px' }}
        cancelLabel={messages.cancelLabel}
        {...rest}
      />
    </div>
  );
};

const StatsRangeLinkButton = props => {
  const { onClick, children } = props;
  return (
    <a role="button" style={{ margin: '0 8px', fontSize: '14px' }} onClick={onClick}>
      {children}
    </a>
  );
};

class OpportunityStatsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interactions: [],
      loading: 0,
      from: moment().add('-30', 'days'),
      to: moment()
    };
  }

  componentDidMount() {
    const { opportunityId } = this.props;
    this.loadInteractions(opportunityId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunityId !== this.props.opportunityId) {
      this.loadInteractions(this.props.opportunityId);
    }
  }

  incrementLoader = inc => this.setState(({ loading }) => ({ loading: loading + inc }));

  loadInteractions = id => {
    this.incrementLoader(1);
    OpportunityApi.getOpportunityInteractions(id)
      .then(res => this.setState({ interactions: res.items }))
      .finally(() => this.incrementLoader(-1));
  };

  handleAddRangeUnit = (val, unit) => {
    this.setState(({ from, to }) => ({ from: moment(from).add(val, unit), to: moment(to).add(val, unit) }));
  };

  handleSetFrom = val => this.setState({ from: moment(val) });
  handleSetTo = val => this.setState({ to: moment(val) });

  render() {
    const { intl } = this.props;
    if (this.state.loading > 0) {
      return (
        <div className="container-flex mui-padded-x2 text-sz-sm relative-container" style={{ minHeight: '200px' }}>
          <Spinner local show size={32} stroke={4} />
        </div>
      );
    }
    const messages = intl.messages.components.pages.private.opportunities;
    const data = interactionsToData(this.state.interactions, this.state.from, this.state.to, intl.locale);
    return (
      <div style={{ padding: '0 16px 16px' }} className="text-sz-sm">
        <div className="container-flex-row ai-center jc-flex-end mui-padded-horizontal-half fw-yes">
          <div style={{ margin: '28px 8px 0' }}>
            <StatsRangeLinkButton onClick={() => this.handleAddRangeUnit(-1, 'week')}>
              - {messages.stats.addWeekButton}
            </StatsRangeLinkButton>
            <StatsRangeLinkButton onClick={() => this.handleAddRangeUnit(+1, 'week')}>
              + {messages.stats.addWeekButton}
            </StatsRangeLinkButton>
          </div>
          <DatePickerField
            intl={intl}
            label={messages.stats.fromLabel}
            value={this.state.from.toDate()}
            maxDate={this.state.to.toDate()}
            onChange={this.handleSetFrom}
          />
          <DatePickerField
            intl={intl}
            label={messages.stats.toLabel}
            value={this.state.to.toDate()}
            minDate={this.state.from.toDate()}
            maxDate={new Date()}
            onChange={this.handleSetTo}
          />
        </div>
        <ResponsiveContainer height={200}>
          <AreaChart
            data={data}
            margin={{
              top: 8,
              right: 0,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} axisLine={false} tickLine={false} width={36} />
            <Tooltip />
            <Legend />
            {map(STATS_PROPS, sp => (
              <Area
                stackId="1"
                key={sp.key}
                dataKey={sp.key}
                type="monotone"
                stroke={sp.color}
                fill={sp.color}
                name={messages.stats.props[sp.key].label}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default OpportunityStatsChart;
