import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change as changeForm } from 'redux-form';
import _get from 'lodash/get';

import Select from '../../../superfield/form/SuperSelect';

import { formatMessage } from '../../../../utils/utils';
import { getProgress } from '../Utils';
import { valueHoldersToValues } from '../../../forms/FormUtils';
import { selectOpportunityPostToEdit } from '../../../../selectors/opportunityBuilder';

export const ID = 'opportunityPersonalDevelopmentPartForm';

const messages = {
  personalDevelopmentAreasLabel: 'Tato příležitost umožňuje tyto druhy rozvoje',
  personalDevelopmentAreasSetFromCompanyMessage: '{0[Nastavit]} možnosti rozvoje jako u profilu společnosti'
};

const getCompanyProfileProperty = (profile, property) =>
  profile && (_get(profile, property) || _get(profile.draft || {}, property));

const PersonalDevelopmentPart = props => {
  const { dataSources, handleSubmit, companyProfile, changeForm } = props;
  const profilePDAs = getCompanyProfileProperty(companyProfile, 'personalDevelopmentAreas');
  const handleSetPDAs = () => changeForm(ID, 'personalDevelopmentAreas', valueHoldersToValues(profilePDAs));
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="personalDevelopmentAreas"
            label={messages.personalDevelopmentAreasLabel}
            component={Select}
            dataSource={dataSources.personalDevelopmentAreas}
            helperText={
              !!profilePDAs &&
              formatMessage(messages.personalDevelopmentAreasSetFromCompanyMessage, [
                val => (
                  <a key="0" role="button" onClick={handleSetPDAs}>
                    {val}
                  </a>
                )
              ])
            }
            multi
            filterable
          />
        </div>
      </div>
    </form>
  );
};

const validate = () => {
  return {};
};

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    options: {
      dataSources: { all }
    },
    entities: {
      companyProfile: { profile }
    }
  } = state;
  const post = selectOpportunityPostToEdit(state, ownProps);
  const opportunity = post.opportunity || {};
  const dataSources = {
    personalDevelopmentAreas: (all && all.personalDevelopmentAreas) || []
  };
  const matchConfig = opportunity.matchConfig || {};
  return {
    intl,
    dataSources,
    companyProfile: profile,
    initialValues: {
      personalDevelopmentAreas: valueHoldersToValues(_get(matchConfig, 'personalDevelopmentAreas.items'))
    }
  };
};

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

PersonalDevelopmentPart.ID = ID;
PersonalDevelopmentPart.getProgressFromPost = post => {
  const matchConfig = post?.opportunity?.matchConfig;
  return getProgress([() => matchConfig?.personalDevelopmentAreas?.items?.length > 0]);
};
PersonalDevelopmentPart.getProgressFromValues = v => getProgress([() => v?.personalDevelopmentAreas?.length > 0]);

export default connect(mapStateToProps, { changeForm })(reduxForm(formConfig)(PersonalDevelopmentPart));
