import React from 'react';
import PropTypes from 'prop-types';

const ExpanderLabel = ({ expanded, toggleExpand, label }) => {
  return (
    <a role="button" onClick={toggleExpand}>
      <span style={{ marginRight: '0.3em', verticalAlign: 'middle' }}>{label}</span>
      <i
        className="material-icons"
        style={{
          color: 'inherit',
          fontSize: 'inherit',
          verticalAlign: 'middle',
          lineHeight: 'inherit',
          marginRight: '0.5em'
        }}
      >
        {`keyboard_arrow_${expanded ? 'up' : 'down'}`}
      </i>
    </a>
  );
};

ExpanderLabel.propTypes = {
  expanded: PropTypes.bool,
  label: PropTypes.any.isRequired,
  toggleExpand: PropTypes.func.isRequired
};

export default ExpanderLabel;
