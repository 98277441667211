import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _reduce from 'lodash/reduce';

import Chip from '../../../components/mui/Chip';
import SuperSelect from '../../../components/superfield/SuperSelect';
import { getFilteredAndEvaluatedParticipants } from '../../../selectors/applicantList';

import withIntl from '../../../components/withIntl';

class HighlightSelect extends React.Component {
  state = {
    dataSource: [],
    values: []
  };

  componentDidMount() {
    this.computeStateFromProps();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.participants !== this.props.participants ||
      prevProps.selected !== this.props.selected ||
      prevProps.intl !== this.props.intl
    ) {
      this.computeStateFromProps();
    }
  }

  computeDataSource = () => {
    const {
      participants,
      selected,
      intl: { messages }
    } = this.props;
    const all = [...(participants || []), ...(selected || [])];
    return _reduce(
      all,
      (res, p) => {
        const {
          participant: { id, firstName, lastName, email, anonymous },
          projectId
        } = p;
        const value = `${projectId}/${id}`;
        if (!res.map[value]) {
          res.map[value] = true;
          res.dataSource.push({
            value,
            label: anonymous ? messages.anonymous : lastName && firstName ? `${lastName} ${firstName}` : email,
            description: !anonymous && lastName && firstName ? email : null
          });
        }
        return res;
      },
      { map: {}, dataSource: [] }
    );
  };

  computeValues = () => {
    const { selected } = this.props;
    return _reduce(
      selected || [],
      (res, { projectId, participant: { id }, crossComparedColor }) => {
        const vid = `${projectId}/${id}`;
        res.values.push(vid);
        res.highlighted[vid] = crossComparedColor;
        return res;
      },
      { values: [], highlighted: {} }
    );
  };

  computeStateFromProps = () => {
    this.setState({ ...this.computeDataSource(), ...this.computeValues() });
  };

  handleToggle = (id) => {
    const { onToggle } = this.props;
    const parts = id.split('/');
    onToggle({ projectId: parts[0], participantId: parts[1] });
  };

  handleChange = (values) => {
    const { participants, onChange } = this.props;
    const valMap = _reduce(
      values,
      (res, v) => {
        res[v] = true;
        return res;
      },
      {}
    );
    const selected = _reduce(
      participants,
      (res, p) => {
        const {
          projectId,
          participant: { id }
        } = p;
        if (valMap[`${projectId}/${id}`]) {
          res.push(p);
        }
        return res;
      },
      []
    );
    onChange(selected);
  };

  render() {
    const { dataSource, values, highlighted } = this.state;
    const {
      intl: { messages }
    } = this.props;
    return (
      <SuperSelect
        label={messages.label}
        multi
        filterable
        values={values}
        dataSource={dataSource}
        style={{ margin: 0 }}
        onChange={this.handleChange}
        renderChip={({ item, onRequestDelete }) => (
          <Chip onRequestDelete={() => onRequestDelete(item)} variant={'compact'}>
            <div className="circle-indicator ci-size-8" style={{ backgroundColor: highlighted[item.value] }} />
            {item.label}
          </Chip>
        )}
      />
    );
  }
}

const intlMessages = {
  cs: {
    label: 'Zvýraznění účastníci',
    anonymous: 'Anonymní'
  },
  sk: {
    label: 'Zvýraznení účastníci',
    anonymous: 'Anonymný'
  },
  en: {
    label: 'Highlighted participants',
    anonymous: 'Anonymous'
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    participants: getFilteredAndEvaluatedParticipants(state, ownProps)
  };
};

export default withRouter(withIntl(connect(mapStateToProps)(HighlightSelect), intlMessages));
