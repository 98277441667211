import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '../mui/Dialog';
import Button from '../mui/Button';

// TODO redo modals:
// 1) to get rid of N modals per project (normally it should be enough to have 1 modal in the whole component tree)
// 2) to avoid having callbacks (functions) in the redux state
const SimpleModal = props => {
  const {
    id,
    open,
    callback,
    message,
    confirmLabel,
    confirmDisabled,
    cancelLabel,
    style,
    bodyStyle,
    contentStyle,
    title,
    autoScrollBodyContent,
    customActions
  } = props;
  if (!open) {
    return null;
  }
  const { switchModal } = props;

  const onClose = () => {
    switchModal({ id, open: false });
    if (typeof callback === 'function') {
      callback(false);
    }
  };

  const onConfirm = () => {
    switchModal({ id, open: false });
    if (typeof callback === 'function') {
      callback(true);
    }
  };

  const actions = customActions || [];
  if (cancelLabel) {
    actions.push(<Button label={cancelLabel} onClick={onClose} />);
  }
  if (confirmLabel) {
    actions.push(<Button label={confirmLabel} primary onClick={onConfirm} style={{ marginLeft: '8px' }} disabled={confirmDisabled} />);
  }
  return (
    <Dialog
      modal
      open
      onRequestClose={onClose}
      autoDetectWindowHeight={false}
      style={{ overflow: 'auto', ...style }}
      actions={actions}
      title={title}
      bodyStyle={bodyStyle}
      contentStyle={{
        minWidth: '220px',
        width: '100%',
        maxWidth: '500px',
        transform: 'translate(0px, 0px)',
        ...contentStyle
      }}
      autoScrollBodyContent={autoScrollBodyContent}
    >
      {message}
    </Dialog>
  );
};

SimpleModal.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  callback: PropTypes.func,
  message: PropTypes.any,
  title: PropTypes.string,
  style: PropTypes.object,
  bodyStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  switchModal: PropTypes.func
};

export default SimpleModal;
