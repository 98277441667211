import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { formValueSelector, submit, touch, change as changeForm } from 'redux-form';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import Sticky from 'react-stickynode';
import VisSensor from 'react-visibility-sensor';

import BaseContainer from '../../basic/BaseContainer';
import BasicPart from './parts/BasicPart';
import CulturePart from './parts/CulturePart';
import BenefitsPart from './parts/BenefitsPart';
import DescriptionPart from './parts/DescriptionPart';
import EducationPart from './parts/EducationPart';
import WorkExperiencePart from './parts/WorkExperiencePart';
import SkillsPart from './parts/SkillsPart';
import PersonalDevelopmentPart from './parts/PersonalDevelopmentPart';
import OpportunityDisabledMessage from '../OpportunityDisabledMessage';
import Button from '../../mui/Button';
import IconButton from '../../mui/IconButton';
import Tooltip from '../../basic/Tooltip';
import Popover from '../../mui/Popover';
import NumberCounterAnimation from './NumberCounterAnimation';

import DataSourcesLoader from '../../../containers/common/DataSourcesLoader';

import { combineProgress, valuesToOpportunityPost } from './Utils';
import { getFormsByIds, handleSubmit } from '../../forms/FormUtils';
import * as companyActions from '../../../actions/entities/companyActions';
import * as talentPoolActions from '../../../actions/entities/talentPoolActions';
import * as opportunityPostBuilderActions from '../../../actions/entities/opportunityPostBuilderActions';
import { isMaxConcurrentOpportunitiesReached } from '../../../utils/talentMarketPlan';
import { HEADER_SELECTOR } from '../../../constants/constants';
import { selectWidthXS } from '../../../selectors/ui';
import HorizontalScrollPane from '../../basic/HorizontalScrollPane';

const messages = {
  basic: {
    title: 'Základní informace'
  },
  description: {
    title: 'Popis příležitosti'
  },
  entryCriteria: {
    title: 'Vaše požadavky',
    tabTitle: 'Požadavky'
  },
  education: {
    title: 'Vzdělání'
  },
  workExperience: {
    title: 'Zkušenosti'
  },
  skills: {
    title: 'Dovednosti'
  },
  culture: {
    title: 'Pracovní kultura'
  },
  benefits: {
    title: 'Benefity a odměna'
  },
  personalDevelopment: {
    title: 'Rozvoj'
  },
  emptyTitle: 'Pojmenujte příležitost...',
  title: 'Nastavení příležitosti',
  create: 'Vytvořit',
  createAndPublish: 'Vytvořit a zveřejnit',
  save: 'Uložit',
  saveAndPublish: 'Uložit a zveřejnit',
  addPartButton: 'Přidat sekci',
  matchingPotentialLabel: 'Využití matching potenciálu'
};

class CompanyPropsLoader extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadProfile(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.loadProfile(this.props);
    }
  }

  loadProfile = props => {
    const {
      companyId,
      getCompanyProfile,
      unsetCompanyProfile,
      getCompanyOperators,
      unsetCompanyOperators,
      getCompanyPools,
      getCompanyTalentMarketPlan,
      onCompanyPoolsLoaded,
      intl: { locale }
    } = props;
    if (companyId) {
      getCompanyProfile(companyId);
      getCompanyOperators(companyId);
      getCompanyTalentMarketPlan(companyId, locale);
      getCompanyPools(companyId).then(({ payload }) => onCompanyPoolsLoaded(payload));
    } else {
      unsetCompanyProfile();
      unsetCompanyOperators();
    }
  };

  render() {
    return <div style={{ display: 'none' }} />;
  }
}

const ConnectedCompanyPropsLoader = connect(({ intl }) => ({ intl }), { ...companyActions, ...talentPoolActions })(
  CompanyPropsLoader
);

const extractPropertiesFromPart = Part => {
  const { ID, getProgressFromPost, getProgressFromValues } = Part;
  return { id: ID, getProgressFromPost, getProgressFromValues, render: () => <Part /> };
};

const FORM_PARTS = [
  { ...extractPropertiesFromPart(BasicPart), getMessages: m => m.basic, main: true },
  { ...extractPropertiesFromPart(DescriptionPart), getMessages: m => m.description },
  { ...extractPropertiesFromPart(EducationPart), getMessages: m => m.education },
  { ...extractPropertiesFromPart(WorkExperiencePart), getMessages: m => m.workExperience },
  { ...extractPropertiesFromPart(SkillsPart), getMessages: m => m.skills },
  { ...extractPropertiesFromPart(CulturePart), getMessages: m => m.culture },
  { ...extractPropertiesFromPart(BenefitsPart), getMessages: m => m.benefits },
  { ...extractPropertiesFromPart(PersonalDevelopmentPart), getMessages: m => m.personalDevelopment }
];
const FORM_PARTS_MAP = _reduce(
  FORM_PARTS,
  (res, p) => {
    res[p.id] = p;
    return res;
  },
  {}
);

const toAnchorID = id => `section_${id}`;

const NavigationBar = props => {
  const { parts, shownParts, messages, singleLine, onNavigateToPart, onAddPartPopoverOpen } = props;
  const items = _map(parts, (p, idx) => (
    <div key={p.id} className="mui-padded-half" style={{ whiteSpace: 'nowrap' }}>
      {p.main || shownParts[p.id] ? (
        <a
          href={`#${toAnchorID(p.id)}`}
          className="link-button text-secondary"
          onClick={e => onNavigateToPart(p.id, e)}
        >
          {p.getMessages(messages).title}
        </a>
      ) : (
        <a role="button" className="text-muted text-sz-sm" onClick={e => onAddPartPopoverOpen(p.id, e)}>
          {p.getMessages(messages).title}
        </a>
      )}
      {idx < parts.length - 1 && (
        <span className="mui-padded-left" style={{ lineHeight: '12px' }}>
          &middot;
        </span>
      )}
    </div>
  ));
  if (singleLine) {
    return (
      <HorizontalScrollPane>
        <div className="container-flex-row">{items}</div>
      </HorizontalScrollPane>
    );
  }
  return <div className="container-flex-row fw-yes">{items}</div>;
};

class OpportunityPostForm extends React.Component {
  state = { shownParts: {}, anchorVerticalOffset: 0, buttonsVisible: false };

  componentDidMount() {
    this.loadPost();
    this.postUpdated();
    window.addEventListener('hashchange', this.handleHashChange);
  }
  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChange);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.postId !== this.props.postId) {
      this.loadPost();
    }
    if (prevProps.post !== this.props.post) {
      this.postUpdated();
    }
  }

  loadPost = () => {
    const { getOpportunityPost, clearOpportunityPost, postId } = this.props;
    if (postId) {
      getOpportunityPost(postId);
    } else {
      clearOpportunityPost();
    }
  };

  postUpdated = () => {
    const { post } = this.props;
    const { shownParts } = _reduce(
      FORM_PARTS,
      (res, p) => {
        const { getProgressFromPost } = p;
        if (getProgressFromPost) {
          const progress = getProgressFromPost(post);
          if (progress?.filled > 0) {
            res.shownParts[p.id] = true;
          }
        }
        return res;
      },
      { shownParts: {}, changed: false }
    );
    this.setState({ shownParts });
  };

  handleSubmit = (values, publish) => {
    const { post, onSubmit, createOpportunityPost, updateOpportunityPost, router } = this.props;
    const actualValues = publish ? { ...values, publishedAt: Date.now() } : values;
    onSubmit(actualValues);
    const newPost = valuesToOpportunityPost(post, actualValues);
    const handleSuccess = res => {
      const id = _get(res, 'payload.payload.opportunity.id');
      if (id) {
        router.push(`/sourcing/opportunities/${id}`);
      }
    };
    if (post.id) {
      updateOpportunityPost(post.id, newPost).then(handleSuccess);
    } else {
      createOpportunityPost(newPost).then(handleSuccess);
    }
  };

  handleDefaultTalentPoolSet = p => {
    const { items } = p || {};
    if (!items) {
      return;
    }
    const { poolIds, changeForm } = this.props;
    const itemsMap = _reduce(
      items,
      (res, item) => {
        res[item.id] = item;
        return res;
      },
      {}
    );
    const newPoolIds = [];
    for (let i = 0; i < poolIds.length; i += 1) {
      const id = poolIds[i];
      if (itemsMap[id]) {
        newPoolIds.push(id);
      }
    }
    if (items.length === 1 && newPoolIds.length === 0) {
      newPoolIds.push(items[0].id);
    }
    changeForm(BasicPart.ID, 'poolIds', newPoolIds);
  };

  getHeaderOffset = () => {
    const el = this.headerContainer;
    const headerHeight = el?.offsetHeight || 0;

    const navHeader = document.querySelector(HEADER_SELECTOR);
    const navHeaderHeight = navHeader?.offsetHeight || 0;

    return headerHeight + navHeaderHeight;
  };

  handleHashChange = () => {
    const {
      location: { hash }
    } = this.props;
    this.scrollIfAnchor(hash, false);
  };

  scrollIfAnchor = (href, pushToHistory) => {
    if (!href.startsWith('#')) {
      return false;
    }

    const match = document.getElementById(href.substring(1));

    if (match) {
      const rect = match.getBoundingClientRect();
      const anchorOffset = window.pageYOffset + rect.top - this.getHeaderOffset();
      window.scrollTo({ left: window.pageXOffset, top: anchorOffset, behavior: 'smooth' });

      // Add the state to history as-per normal anchor links
      if (pushToHistory) {
        window.history.pushState({}, document.title, location.pathname + href);
      }
    }

    return !!match;
  };

  handleNavigateToPart = (id, event) => {
    if (this.scrollIfAnchor(`#${toAnchorID(id)}`, true)) {
      event.preventDefault();
    }
  };
  handlePartAdd = id => {
    const { shownParts } = this.state;
    this.setState({ shownParts: { ...shownParts, [id]: true } });
    setTimeout(() => {
      this.scrollIfAnchor(`#${toAnchorID(id)}`, true);
    }, 100);
  };
  handlePartRemove = id => {
    const {
      shownParts: { [id]: present, ...shownParts }
    } = this.state;
    this.setState({ shownParts });
  };

  setHeader = el => {
    this.headerContainer = el;
  };

  save = publish => {
    const { forms, touch } = this.props;
    const { shownParts } = this.state;
    const onSubmitHandler = values => this.handleSubmit(values, publish);
    handleSubmit(forms, onSubmitHandler, touch, { isRemoved: f => f.name !== BasicPart.ID && !shownParts[f.name] });
  };
  handleSaveAndPublish = () => this.save(true);
  handleSave = () => this.save();

  handleAddPartPopoverOpen = (id, e) => {
    this.addPartPopoverTarget = e.target;
    this.setState({ addPartId: id });
  };
  handleAddPartPopoverClose = () => this.setState({ addPartId: null });
  handlePartAddFromPopover = () => {
    const { addPartId } = this.state;
    if (addPartId) {
      this.handlePartAdd(addPartId);
      this.setState({ addPartId: null });
    }
  };
  getMatchingPotential = () => {
    const { forms } = this.props;
    const { shownParts } = this.state;
    const p = _reduce(
      forms,
      (res, form) => {
        const formPart = FORM_PARTS_MAP[form.name];
        const getProgressFromValues = formPart?.getProgressFromValues;
        if (getProgressFromValues) {
          const p = getProgressFromValues(form.values);
          const shown = formPart.main || !!shownParts[formPart.id];
          return combineProgress(res, shown ? p : { ...p, filled: 0, completed: false });
        }
        return res;
      },
      null
    );
    return p?.total > 0 ? (p?.filled || 0) / p.total : 0;
  };
  handleButtonsVisibilityChange = buttonsVisible => this.setState({ buttonsVisible });

  render() {
    const { companyId, title, talentMarketPlan, post, loading, forms, widthXS } = this.props;
    const isNew = !post || !post.id;
    const publishDisabled = isMaxConcurrentOpportunitiesReached(talentMarketPlan);
    const publishTooltip = publishDisabled ? <OpportunityDisabledMessage /> : null;
    const { shownParts, addPartId, buttonsVisible } = this.state;
    const anyPartInvalid = _reduce(forms, (res, { valid }) => res || !valid, false);
    return (
      <div className="opportunity-post-form">
        <DataSourcesLoader />
        <ConnectedCompanyPropsLoader companyId={companyId} onCompanyPoolsLoaded={this.handleDefaultTalentPoolSet} />
        <BaseContainer>
          <div style={{ maxWidth: '768px', margin: '0 auto' }}>
            <h2 style={{ padding: '8px 24px' }}>{title ? title : <span>&nbsp;</span>}</h2>
            <div className="mui-card builder-form">
              <div>
                <Sticky top={HEADER_SELECTOR} innerZ={100} activeClass="sticky-active">
                  <div
                    className="mui-padded-x2 opportunity-post-form-header border-bottom border-color-secondary bg-secondary-light"
                    ref={this.setHeader}
                  >
                    <div
                      className="mui-padded-half container-flex-row ai-center fw-yes jc-space-between"
                      style={{ marginBottom: '-8px' }}
                    >
                      <div className="mui-padded-half text-sz-xl">{messages.title}</div>
                      <div className="mui-padded-half">
                        <span className="text-sz-reg-sm">
                          <span className="text-sz-xs text-uppercase text-muted">
                            {messages.matchingPotentialLabel}:{' '}
                          </span>
                          <b>
                            <NumberCounterAnimation value={Math.round(this.getMatchingPotential() * 100)} />
                          </b>
                          <span className="text-sz-xs text-muted">%</span>
                        </span>
                      </div>
                    </div>
                    <div className="container-flex-row ai-end fw-yes jc-space-between">
                      <div className="mui-padded-half" style={{ flex: '1 1 320px', maxWidth: '100%' }}>
                        <NavigationBar
                          parts={FORM_PARTS}
                          shownParts={shownParts}
                          messages={messages}
                          onNavigateToPart={this.handleNavigateToPart}
                          onAddPartPopoverOpen={this.handleAddPartPopoverOpen}
                          singleLine={widthXS}
                        />
                      </div>
                      {!buttonsVisible && !isNew && (
                        <div className="mui-padded-half">
                          <Button
                            primary
                            raised
                            disabled={loading || anyPartInvalid}
                            onClick={this.handleSave}
                            label={messages.save}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Sticky>
                <Popover
                  anchorEl={this.addPartPopoverTarget}
                  open={!!addPartId}
                  onRequestClose={this.handleAddPartPopoverClose}
                >
                  <div className="mui-padded-vertical">
                    <Button
                      label={messages.addPartButton}
                      icon={<i className="material-icons text-sz-lg">add</i>}
                      onClick={this.handlePartAddFromPopover}
                      mini
                      style={{ borderRadius: 0 }}
                    />
                  </div>
                </Popover>
                <div className="mui-padded">
                  {_map(
                    FORM_PARTS,
                    p =>
                      (p.main || shownParts[p.id]) && (
                        <div
                          key={p.id}
                          id={toAnchorID(p.id)}
                          className={`border-radius ${
                            p.main ? '' : 'border border-color-secondary mui-margin mui-margin-top-x2'
                          }`}
                        >
                          {!p.main && (
                            <div className="container-flex-row fw-yes jc-space-between ai-center mui-padded mui-padded-bottom-zero">
                              <div className="mui-label mui-padded" style={{ marginBottom: 0 }}>
                                {p.getMessages(messages).title}
                              </div>
                              <IconButton onClick={() => this.handlePartRemove(p.id)}>
                                <i className="material-icons">clear</i>
                              </IconButton>
                            </div>
                          )}
                          <div className="mui-padded-x2 mui-padded-top-zero">{p.render()}</div>
                        </div>
                      )
                  )}
                  <div className="container-flex-row fw-yes mui-padded-half">
                    {_map(
                      FORM_PARTS,
                      p =>
                        !p.main &&
                        !shownParts[p.id] && (
                          <div key={p.id} className="mui-padded-half">
                            <Button
                              onClick={() => this.handlePartAdd(p.id)}
                              label={p.getMessages(messages).title}
                              icon={<i className="material-icons">add</i>}
                              secondary
                            />
                          </div>
                        )
                    )}
                  </div>
                </div>
                <VisSensor onChange={this.handleButtonsVisibilityChange} partialVisibility>
                  <div className="container-flex-row fw-yes jc-flex-end" style={{ padding: '12px' }}>
                    <div className="mui-padded-half">
                      <Button
                        primary
                        raised={!!post.publishedAt}
                        onClick={this.handleSave}
                        label={isNew ? messages.create : messages.save}
                        disabled={loading || (!isNew && anyPartInvalid)}
                      />
                    </div>
                    {!post.publishedAt && (
                      <div className="mui-padded-half">
                        <Tooltip content={publishTooltip}>
                          <Button
                            primary
                            raised
                            onClick={this.handleSaveAndPublish}
                            label={isNew ? messages.createAndPublish : messages.saveAndPublish}
                            disabled={loading || publishDisabled || (!isNew && anyPartInvalid)}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </VisSensor>
              </div>
            </div>
          </div>
        </BaseContainer>
      </div>
    );
  }
}

const basicPartSelector = formValueSelector(BasicPart.ID);

const FORM_PARTS_IDS = _map(FORM_PARTS, p => p.id);

const DEFAULT_POST = {};

const mapStateToProps = (state, ownProps) => {
  const {
    entities: {
      opportunityPostBuilder: { loading, item, savedAt },
      currentCompany: { talentMarketPlan }
    },
    intl
  } = state;
  const { postId } = ownProps.params || {};
  return {
    intl,
    loading,
    savedAt,
    talentMarketPlan,
    widthXS: selectWidthXS(state, ownProps),
    post: item || DEFAULT_POST,
    title: basicPartSelector(state, 'title'),
    companyId: basicPartSelector(state, 'companyId'),
    poolIds: basicPartSelector(state, 'poolIds'),
    forms: getFormsByIds(FORM_PARTS_IDS, state),
    postId
  };
};

const actions = { ...opportunityPostBuilderActions, onSubmit: submit, touch, changeForm };
export default withRouter(connect(mapStateToProps, actions)(OpportunityPostForm));
