import React, { useCallback, useMemo, useState } from 'react';
import map from 'lodash/map';

import DiscreteMetricChart from '../chart/DiscreteMetricChart';
import DualMetricInterpretation from './DualMetricInterpretation';
import MetricIcon from '../icons/Metric';

import { getColors } from './AnalyticsUtils';
import { reverseArray } from '../../utils/utils';

import withIntl from '../withIntl';
import ParticipantName from '../basic/ParticipantName';

const ICON_COLOR = 'rgba(0,0,0,0.32)';

const mapLimited = (limit, array, transform) => {
  const res = [];
  for (let i = 0; i < Math.min(limit, array.length); i += 1) {
    res.push(transform(array[i], i, array, limit));
  }
  return res;
};

const determineMaxLimit = data => {
  let max = 0;
  for (let i = 0; i < data.length; i += 1) {
    const v = data[i].values || [];
    const pCount = (v[0]?.participants?.length || 0) + (v[1]?.participants?.length || 0);
    if (max < pCount) {
      max = pCount;
    }
  }
  return max;
};

const LIST_LIMIT = 10;

const ParticipantsList = props => {
  const { data, overlayColor } = props;

  const [limit, setLimit] = useState(LIST_LIMIT);

  const handleLimitIncrement = useCallback(() => setLimit(limit => limit + LIST_LIMIT), [setLimit]);
  const handleLimitDecrement = useCallback(() => setLimit(limit => Math.max(0, limit - LIST_LIMIT)), [setLimit]);
  const maxLimit = useMemo(() => determineMaxLimit(data), [data]);

  return (
    <div>
      <div className="container-flex-row participants-list">
        {map(data, (dataItem, idx) => {
          const { values } = dataItem;
          const baseLayerParticipants = values[0].participants || [];
          const overlayParticipants = values[1]?.participants || [];
          const opLen = overlayParticipants.length;
          const blpLen = baseLayerParticipants.length;
          const opLimit = limit > -1 ? Math.min(opLen, limit) : opLen;
          const blpLimit = limit > -1 ? Math.max(0, limit - opLimit) : blpLen;
          const hiddenCount = opLen + blpLen - limit;
          return (
            <div key={idx} className="text-center flex1" style={{ padding: '0 2px' }}>
              {overlayParticipants.length > 0 && (
                <div className="mui-margin-bottom-half">
                  {mapLimited(opLimit, overlayParticipants, p => (
                    <div
                      key={p.id}
                      className="text-sz-xs border-bottom"
                      style={{ borderColor: p.crossComparedColor || overlayColor }}
                    >
                      <ParticipantName {...p} variant="mini" />
                    </div>
                  ))}
                </div>
              )}
              {mapLimited(blpLimit, baseLayerParticipants, p => (
                <div
                  key={p.id}
                  className="text-sz-xs mui-margin-bottom-half"
                  style={{
                    lineHeight: '1.1em',
                    borderBottom: p.crossComparedColor ? `1px solid ${p.crossComparedColor}` : 'none'
                  }}
                >
                  <ParticipantName {...p} variant="mini" />
                </div>
              ))}
              {hiddenCount > 0 && <span className="text-muted text-sz-xs">+{hiddenCount}</span>}
            </div>
          );
        })}
      </div>
      {(limit > 0 || limit < maxLimit) && (
        <div className="container-flex-row ai-center" style={{ marginBottom: '-12px' }}>
          <div style={{ height: '1px' }} className="bg-primary-light flex1" />
          <span className="link-buttons">
            {limit > 0 && (
              <a role="button" className="link-button heavy" onClick={handleLimitDecrement}>
                <i className="material-icons">remove</i>
              </a>
            )}
            {limit < maxLimit && (
              <a role="button" className="link-button heavy" onClick={handleLimitIncrement}>
                <i className="material-icons">add</i>
              </a>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

const DualLabel = props => {
  const { metric, active } = props;
  const opposite = metric.reversed ? !props.opposite : props.opposite;
  const label = opposite ? metric.oppositeLabel : metric.label;
  return <div className={`metric-label${active ? ' active' : ''}`}>{label}</div>;
};

const DualMetricIcon = ({ active, ...props }) => (
  <div style={{ opacity: active ? 1 : 0.7 }}>
    <MetricIcon color={ICON_COLOR} {...props} />
  </div>
);

const DualMetric = props => {
  const {
    metric,
    intl,
    size,
    multi,
    data,
    dual,
    mean,
    interpretation,
    activeParticipant,
    participantsListShown,
    children
  } = props;
  const colors = getColors(props);
  const labels = dual ? intl.messages.constants.charts.labels.dual[metric.valueType] : null;
  const firstActive = metric.reversed ? mean < 0.5 : mean >= 0.5;
  const actualData = metric.reversed ? reverseArray(data) : data;
  const actualLabels = metric.reversed ? reverseArray(labels) : labels;

  const firstIcon = <DualMetricIcon metric={metric} opposite={metric.reversed} active={firstActive} />;
  const secondIcon = <DualMetricIcon metric={metric} opposite={!metric.reversed} active={!firstActive} />;
  return (
    <div className="metric-container dual-metric-container">
      <div className="container-flex-row jc-space-between relative-container mui-padded-half">
        <div className="container-flex-row ai-center" style={{ maxWidth: '50%' }}>
          {participantsListShown && <div className="mui-padded-right">{firstIcon}</div>}
          <DualLabel metric={metric} active={firstActive} />
        </div>
        <div className="container-flex-row ai-center" style={{ maxWidth: '50%' }}>
          <DualLabel metric={metric} active={!firstActive} opposite />
          {participantsListShown && <div className="mui-padded-left">{secondIcon}</div>}
        </div>
      </div>
      <div className="container-flex-row">
        {!participantsListShown && (
          <div className="mui-padded-half" style={{ paddingRight: 0 }}>
            {firstIcon}
          </div>
        )}
        <div className="flex1">
          <DiscreteMetricChart
            data={actualData}
            size={size}
            showPercentage={multi}
            labels={actualLabels}
            mainColor={colors.main}
            overlayColor={colors.overlay}
            intl={intl}
          />
          {participantsListShown && (
            <ParticipantsList data={actualData} mainColor={colors.main} overlayColor={colors.overlay} />
          )}
        </div>
        {!participantsListShown && (
          <div className="mui-padded-half" style={{ paddingLeft: 0 }}>
            {secondIcon}
          </div>
        )}
      </div>
      {children && <div className="dual-metric-container-children">{children}</div>}
      {metric.interpretation && (metric.interpretation.text || metric.interpretation.bullets) && (
        <div className="metric-container-content">
          {metric.interpretation.text && <p>{metric.interpretation.text}</p>}
          {metric.interpretation.bullets && metric.interpretation.bullets.length > 0 && (
            <ul>
              {map(metric.interpretation.bullets, (b, idx) => (
                <li key={idx}>{b}</li>
              ))}
            </ul>
          )}
        </div>
      )}
      {interpretation && (
        <DualMetricInterpretation
          interpretation={interpretation}
          activeParticipant={activeParticipant}
          metric={metric}
          colors={props.colors}
          color={props.color}
        />
      )}
    </div>
  );
};

export default withIntl(DualMetric);
