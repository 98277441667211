import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import SelectOverlay from '../../../components/select/SelectOverlay';
import BaseContainer from '../../../components/basic/BaseContainer';
import CompanyTalentMarketPlanBadge from '../../../components/plan/talentMarket/CompanyTalentMarketPlanBadge';

import * as companyActions from '../../../actions/entities/companyActions';

import { formatMessage } from '../../../utils/utils';
import { DEMO_COMPANY_SLUG } from '../../../constants/constants';

const Container = props => {
  const { prominent, children } = props;
  if (prominent) {
    return <div>{children}</div>;
  }
  return <BaseContainer>{children}</BaseContainer>;
};

class CompanySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  componentDidMount() {
    this.loadPlan();
  }

  componentDidUpdate(prevProps) {
    if (this.props.company?.id !== prevProps.company?.id || this.props.intl !== prevProps.intl) {
      this.loadPlan();
    }
  }

  loadPlan = () => {
    const {
      company,
      intl: { locale },
      getCompanyTalentMarketPlan
    } = this.props;
    if (company) {
      getCompanyTalentMarketPlan(company.id, locale);
    }
  };

  handleMenuOpen = () => this.setState({ menuOpen: true });
  handleMenuClose = () => this.setState({ menuOpen: false });
  handleSetCompany = c => {
    const { setCurrentCompany, onSelect } = this.props;
    setCurrentCompany(c);
    if (onSelect) {
      onSelect(c);
    }
  };

  handleCompanySelect = c => {
    this.handleSetCompany(c);
    this.handleMenuClose();
  };
  handleUnsetCompany = () => {
    this.handleSetCompany(null);
  };

  render() {
    const { companies, includeDemo, company, intl, required, talentMarketPlan } = this.props;
    const multiAccount = companies.length >= 2;
    if (!multiAccount && !talentMarketPlan) {
      return null;
    }
    const messages = intl.messages.components.pages.private.companySelect;
    const { menuOpen } = this.state;
    const prominent = required && !company;
    const className = classnames({
      'company-select': true,
      well: true,
      'bg-primary-light': true,
      prominent
    });
    return (
      <div className={className}>
        <Container prominent={prominent}>
          <div className="container-flex-row">
            <div className="flex1 mui-padded">
              {multiAccount && (
                <>
                  {company ? (
                    <div>
                      <span>
                        {formatMessage(messages.currentCompanyMessage, [
                          <strong key="0">
                            {company.name} <small>({company.slug})</small>
                          </strong>
                        ])}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <div>{messages.noCurrentCompanyMessage}</div>
                      {required && <small>{messages.noCurrentCompanyNote}</small>}
                    </div>
                  )}
                </>
              )}
              {talentMarketPlan && company && (
                <div className={`text-sz-reg ${multiAccount ? 'mui-margin-top-half' : ''}`}>
                  <CompanyTalentMarketPlanBadge plan={talentMarketPlan} company={company} />
                </div>
              )}
            </div>
            {multiAccount && (
              <div className="mui-padded">
                <a role="button" onClick={this.handleMenuOpen}>
                  {company ? messages.changeButton : messages.setButton}
                </a>
                {company && <span style={{ margin: '0 0.5em' }}>&middot;</span>}
                {company && (
                  <a role="button" onClick={this.handleUnsetCompany}>
                    {messages.unsetButton}
                  </a>
                )}
              </div>
            )}
          </div>
        </Container>
        {menuOpen && (
          <SelectOverlay
            currentItem={company}
            items={companies}
            messages={{ searchPlaceholder: messages.menuSearchCompany, unsetCurrentItem: messages.menuUnsetButton }}
            itemRenderer={c => (
              <span>
                {c.name} <small>({c.slug})</small>
              </span>
            )}
            filterFunction={(c, search) =>
              c.name.toLowerCase().indexOf(search) > -1 && (includeDemo || c.slug !== DEMO_COMPANY_SLUG)
            }
            onClose={this.handleMenuClose}
            onSelect={this.handleCompanySelect}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    intl,
    entities: {
      companies,
      currentCompany: { item, talentMarketPlan }
    }
  } = state;
  return {
    intl,
    companies,
    company: item,
    talentMarketPlan
  };
};

const actions = {
  setCurrentCompany: companyActions.setCurrentCompany,
  getCompanyTalentMarketPlan: companyActions.getCompanyTalentMarketPlan
};

export default connect(mapStateToProps, actions)(CompanySelect);
