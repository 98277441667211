import { authRequest } from '../Request';
import { API_URL } from '../../config';

export function createAccount(account) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/accounts`)
      .send(account)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function activateAccount(data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/users/activation`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function companyAccountExists(companyName) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/accounts/exists/by/company`)
      .query({ value: companyName && companyName.trim() })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function userAccountExists(email) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/accounts/exists/by/email`)
      .query({ value: email && email.trim() })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function accountValidate(type, value) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/accounts/validation/by/${type}`)
      .query({ value: value && value.trim() })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function upgradeAccount(data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/upgrades/requests`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function upgradeAccountCredits(data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/upgrades/requests/credits`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
