import { authRequest } from './../Request.js';
import { API_URL } from '../../config';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';

export function getProjects(projectTypeGroup = 'ASSESSMENTS_ALL_V2') {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v3/companies`)
      .query({ size: 9999, page: 0, projectTypeGroup })
      .end(
        (err, res) =>
          err
            ? reject(err.response)
            : resolve({
                items: flatMap(res.body.items, c =>
                  map(c.projects, p => ({ company: { id: c.id, slug: c.slug, name: c.name }, ...p }))
                )
              })
      );
  });
}

export function getProject(companyId, projectId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v2/admin/companies/${companyId}/projects/${projectId}`)
      .query(null)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getProjectTemplates(lang, companyId, templateId, projectType) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/admin/projects/templates`)
      .query({ lang, company: companyId || undefined, template: templateId || undefined, projectType })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function createProject(companyId, project) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v2/admin/companies/${companyId}/projects`)
      .send(project)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function editProject(companyId, projectId, data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .put(`${API_URL}/api/v2/admin/companies/${companyId}/projects/${projectId}`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function activateProject(companyId, projectId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v2/admin/companies/${companyId}/projects/${projectId}/activate`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function finishProject(companyId, projectId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v2/admin/companies/${companyId}/projects/${projectId}/finish`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function deleteProject(companyId, projectId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .delete(`${API_URL}/api/v2/admin/companies/${companyId}/projects/${projectId}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function setScoreMapping(companyId, projectId, mapping) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v2/admin/companies/${companyId}/projects/${projectId}/score_mapping`)
      .send(mapping)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function setMetricsConfig(companyId, projectId, config) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v2/admin/companies/${companyId}/projects/${projectId}/metrics_config`)
      .send(config)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function createProjectInvitation(companySlug, projectSlug, emails) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v2/companies/${companySlug}/projects/${projectSlug}/invitations`)
      .send({ items: map(emails, e => ({ email: e.trim() })) })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getProjectInvitationUrls(companySlug, projectSlug) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/companies/${companySlug}/projects/${projectSlug}/invitations`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function copyProject(companyId, projectId, data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v2/admin/companies/${companyId}/projects/${projectId}/copy`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function participantsBatchAction(type, sourceProjectId, participantIds, targetProjectId) {
  const url = `${API_URL}/api/v1/admin/projects/${sourceProjectId}/participants/${type.key.toLowerCase()}${targetProjectId
    ? `/${targetProjectId}`
    : ''}`;
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(url)
      .send({ ids: participantIds })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getParticipantsGroupMemberships(projectId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/admin/projects/${projectId}/participants/groups`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
