import React from 'react';
import cn from 'classnames';

import { formatRange } from './Utils';

const scaleFunction = (val, domain) => {
  const v0 = 0;
  const v1 = Math.log2(domain[1] / domain[0]);
  const range = v1 - v0;
  const normalizedVal = val / domain[0];
  if (normalizedVal < 1) {
    return 0;
  }
  return Math.log2(normalizedVal) / range;
};

const boundedScaleFunction = (val, domain) => {
  return Math.max(0, Math.min(scaleFunction(val, domain), 1));
};

const pairFormattingFunction = (val1, val2) => formatRange(val1, val2);

const MatchScaleBar = props => {
  const { lower, upper, domain, top, matches, eliminating, labelRenderer } = props;
  const l = boundedScaleFunction(lower != undefined ? lower : upper, domain);
  const u = boundedScaleFunction(upper != undefined ? upper : lower, domain);

  const point = lower == undefined || upper == undefined || Math.abs(u - l) < 0.02;
  // the upper end is closer to the end than the lower to the beginning
  const right = point ? l > 0.5 : 1 - u < l;

  const bgClassName = cn({ top, 'match-scale-bar__background': true, matches, eliminating });

  return (
    <div
      style={{
        left: `${l * 100}%`,
        width: point ? '8px' : `${(u - l) * 100}%`
      }}
      className={`match-scale-bar`}
    >
      <div className={bgClassName} style={{ left: point ? '-4px' : 0 }} />
      <div className={`match-scale-bar__container${top ? ' top' : ''}${right ? ' right' : ''}`}>
        <div className="match-scale-bar__label-container" style={point && right ? { right: '8px' } : {}}>
          <div className="match-scale-bar__label">
            {labelRenderer(pairFormattingFunction(lower, upper))}
            <div className="match-scale-bar__label-arrow" />
          </div>
        </div>
      </div>
    </div>
  );
};

const defaultLabelRenderer = (rangeType, formattedPair) => formattedPair;

const MatchScale = props => {
  const { range, matchedRange, domain, labelRenderer, matches, eliminating } = props;
  const actualLabelRenderer = labelRenderer || defaultLabelRenderer;
  return (
    <div className="match-scale">
      <div className="match-scale__container">
        <MatchScaleBar
          {...range}
          domain={domain}
          top
          labelRenderer={formattedPair => actualLabelRenderer('base', formattedPair)}
          matches={matches}
          eliminating={eliminating}
        />
        {matchedRange && (
          <MatchScaleBar
            {...matchedRange}
            domain={domain}
            labelRenderer={formattedPair => actualLabelRenderer('overlay', formattedPair)}
            matches={matches}
          />
        )}
      </div>
    </div>
  );
};

export default MatchScale;
