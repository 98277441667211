import { handleActions } from 'redux-actions';
import {
  OPPORTUNITY_GET,
  OPPORTUNITY_PARTICIPANT_GET,
  OPPORTUNITY_PARTICIPANTS_SORT,
  OPPORTUNITY_PARTICIPANTS_RESPONSE_SET,
  OPPORTUNITY_PARTICIPANTS_SHOW_REJECTED_TOGGLE,
  OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_PERFORM,
  OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_SET_FILTER_PROPERTY
} from '../../constants/constants';
import { EOpportunityParticipantsSort, ESortOrder } from '../../constants/enum';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';

const initialState = {
  item: {},
  participant: {},
  loading: false,
  sort: { properties: EOpportunityParticipantsSort.RESPONDED.sort, order: ESortOrder.DESC },
  activeSearch: {}
};

const mergeParticipants = (original, data) => {
  if (!original || original.length === 0) {
    return original;
  }
  const uMap = keyBy(data.participants, p => p.id);
  return map(original, p => {
    const up = uMap[p.id];
    if (!up) {
      return p;
    }
    return {
      ...p,
      firstName: up.firstName,
      lastName: up.lastName,
      email: up.email,
      companyResponse: {
        status: data.response,
        // might be better to get it from API
        timestamp: Date.now(),
        rejectReason: data.rejectReason
      }
    };
  });
};

const updateActiveSearch = (state, update, meta) => {
  const { items, page } = update;
  let newItems = state.activeSearch.items;
  if (page && items) {
    newItems = page.number > 0 ? [...(newItems || []), ...items] : items;
  }
  if (meta && (!meta.page || !meta.page.number)) {
    newItems = [];
  }
  return { ...state, activeSearch: { ...state.activeSearch, ...update, items: newItems } };
};

export default handleActions(
  {
    [OPPORTUNITY_GET.SUCCESS]: (state, { payload: { payload } }) => ({
      ...state,
      item: payload,
      loading: false,
      activeSearch: state.item && payload && state.item.id === payload.id ? state.activeSearch : {}
    }),
    [OPPORTUNITY_GET.PENDING]: state => ({ ...state, loading: true }),
    [OPPORTUNITY_GET.ERROR]: state => ({ ...state, loading: false, error: true }),
    [OPPORTUNITY_PARTICIPANT_GET.SUCCESS]: (state, { payload }) => ({
      ...state,
      participant: payload.payload,
      loading: false
    }),
    [OPPORTUNITY_PARTICIPANT_GET.PENDING]: state => ({ ...state, loading: true }),
    [OPPORTUNITY_PARTICIPANT_GET.ERROR]: state => ({ ...state, loading: false, error: true }),
    [OPPORTUNITY_PARTICIPANTS_SORT]: (state, { payload }) => ({ ...state, sort: payload }),
    [OPPORTUNITY_PARTICIPANTS_RESPONSE_SET.SUCCESS]: (state, { payload }) => ({
      ...state,
      item: {
        ...state.item,
        participants: mergeParticipants(state.item.participants, payload.payload)
      },
      participant: state.participant ? mergeParticipants([state.participant], payload.payload)[0] : {},
      activeSearch: {
        ...state.activeSearch,
        items: mergeParticipants(state.activeSearch?.items || [], payload.payload)
      }
    }),
    [OPPORTUNITY_PARTICIPANTS_SHOW_REJECTED_TOGGLE]: (state, { payload }) => ({
      ...state,
      showRejected: payload
    }),
    [OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_PERFORM.SUCCESS]: (state, action) => {
      if (!action.payload) {
        return updateActiveSearch(state, { loading: false, error: false, limitReached: true });
      }
      const { payload, items, page } = action.payload;
      return updateActiveSearch(state, { ...payload, items, page, loading: false, error: false, limitReached: false });
    },
    [OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_PERFORM.PENDING]: (state, { meta }) =>
      updateActiveSearch(state, { loading: true }, meta),
    [OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_PERFORM.ERROR]: state =>
      updateActiveSearch(state, { loading: false, error: true }),
    [OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_SET_FILTER_PROPERTY]: (state, { payload: { key, value } }) =>
      updateActiveSearch(state, { [key]: value })
  },
  initialState
);
