import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _join from 'lodash/join';

import {
  selectCurrentTemplate,
  selectCurrentCompany,
  selectCurrentProjectName,
  selectCurrentProjectPrimaryLanguage,
  selectCurrentPositionType
} from './GeneralSettingsPart';
import Text from '../../../superfield/form/SuperText';
import BasicText from '../../../superfield/SuperText';
import DialoguePreview from '../../../simulation/dialogue/Preview';

import { selectCurrentProjectType } from './ProjectTypePart';
import { selectProjectToEdit } from './projectEditSelectors';
import { EActivityType, EProjectType } from '../../../../constants/enum';
import { formatProjectTextTemplate, formatMessage, convertHTMLToTextAreaContent } from '../../../../utils/utils';
import i18n from '../../../../i18n';

class ResetButton extends React.Component {
  state = {
    confirm: false
  };

  handleConfirm = () => this.props.onClick();
  handleCancel = () => this.setState({ confirm: false });
  handleClick = () => {
    this.setState({ confirm: true });
  };

  render() {
    const { messages } = this.props;
    const { confirm } = this.state;
    if (confirm) {
      return (
        <span>
          <span>{messages.confirmMessage}</span>
          <a role="button" style={{ marginLeft: '8px' }} onClick={this.handleConfirm}>
            {messages.yes}
          </a>
          <a role="button" style={{ marginLeft: '8px' }} onClick={this.handleCancel}>
            {messages.no}
          </a>
        </span>
      );
    }
    return (
      <a role="button" onClick={this.handleClick}>
        {messages.button}
      </a>
    );
  }
}

const TextsPart = props => {
  const {
    intl,
    currentTemplate,
    projectName,
    projectLanguage,
    gameDefinitionKey,
    customizeIntroduction,
    customizeReport,
    internalPropsEnabled,
    internalName,
    internalDescription,
    defaultTemplates
  } = props;
  const { handleSubmit, change } = props;
  const messages = intl.messages.components.pages.private.projectEdit.texts;
  const introTextDisabled = currentTemplate && currentTemplate.format === 'QUESTIONNAIRE';
  const internalText = projectLanguage
    ? (i18n[projectLanguage] || i18n.en).constants.internalText[gameDefinitionKey]
    : {};
  return (
    <form onSubmit={handleSubmit}>
      {internalPropsEnabled &&
        internalText && (
          <div>
            <div className="row">
              <div className="col-sm-12">
                <Field
                  name="internalName"
                  component={Text}
                  label={formatMessage(messages.internalNameLabel, [<strong key="projectName">{projectName}</strong>])}
                  helperText={messages.internalNameHelper}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Field
                  name="internalDescription"
                  component={Text}
                  multiline
                  label={messages.internalDescriptionLabel}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div style={{ margin: '16px 0' }}>
                  <div className="text-muted mui-fields-description">{messages.internalTextPreviewHelper}</div>
                  <DialoguePreview>
                    {formatMessage(internalDescription ? internalText.text : internalText.descriptionFallback, [
                      <strong>{internalName || projectName}</strong>,
                      <strong>{internalDescription}</strong>
                    ])}
                  </DialoguePreview>
                </div>
              </div>
            </div>
          </div>
        )}
      {!introTextDisabled && (
        <div className="row">
          <div className="col-sm-12">
            <div className="mui-fields-container">
              <h6>{messages.introTitle}</h6>
              <div className="text-muted mui-fields-description">{_join(messages.introHints, ' ')}</div>
              {customizeIntroduction ? (
                <div>
                  <ResetButton
                    onClick={() => {
                      change('introductionTemplate', '');
                      change('customizeIntroduction', false);
                    }}
                    messages={messages.reset}
                  />
                  <Field name="introductionTemplate" component={Text} multiline rows={5} />
                </div>
              ) : (
                <div>
                  <a
                    role="button"
                    onClick={() => {
                      change('introductionTemplate', defaultTemplates.introduction);
                      change('customizeIntroduction', true);
                    }}
                  >
                    {messages.customize}
                  </a>
                  <BasicText multiline rows={5} value={defaultTemplates.introduction} disabled onChange={() => {}} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-12">
          <div className="mui-fields-container">
            <h6>{messages.reportTitle}</h6>
            <div className="text-muted mui-fields-description">{_join(messages.reportHints, ' ')}</div>
            {customizeReport ? (
              <div>
                <ResetButton
                  onClick={() => {
                    change('reportTemplate', '');
                    change('customizeReport', false);
                  }}
                  messages={messages.reset}
                />
                <Field name="reportTemplate" component={Text} multiline rows={5} />
              </div>
            ) : (
              <div>
                <a
                  role="button"
                  onClick={() => {
                    change('reportTemplate', defaultTemplates.report);
                    change('customizeReport', true);
                  }}
                >
                  {messages.customize}
                </a>
                <BasicText multiline rows={5} value={defaultTemplates.report} disabled onChange={() => {}} />
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

TextsPart.ID = 'projectTexts';

const formSelector = formValueSelector(TextsPart.ID);

const localSelectInitialValues = createSelector([selectProjectToEdit], p => {
  const cfg = p.config || {};
  const customIntroTemplate = convertHTMLToTextAreaContent(_get(cfg, 'landingText.introduction'));
  const customReportTemplate = convertHTMLToTextAreaContent(_get(cfg, 'reportText.content'));
  return {
    internalName: _get(cfg, 'gameDataProps.internalName'),
    internalDescription: _get(cfg, 'gameDataProps.internalDescription'),
    customizeIntroduction: !!customIntroTemplate,
    introductionTemplate: customIntroTemplate,
    customizeReport: !!customReportTemplate,
    reportTemplate: customReportTemplate
  };
});

const baseLocalSelectMessageTemplates = state => state.entities.activityTemplates.messageTemplates;

const localSelectDefaultTemplates = createSelector(
  [
    baseLocalSelectMessageTemplates,
    selectCurrentProjectPrimaryLanguage,
    selectCurrentCompany,
    selectCurrentProjectName
  ],
  (messageTemplates, lang, company, projectName) => {
    const companyName = company ? company.name : '';
    const messageTemplate = (messageTemplates || {})[lang];
    return {
      introduction: formatProjectTextTemplate(
        (messageTemplate && messageTemplate.introduction) || '',
        companyName,
        projectName
      ),
      report: formatProjectTextTemplate((messageTemplate && messageTemplate.report) || '', companyName, projectName)
    };
  }
);

const localSelectInternalPropsEnabled = createSelector(
  [selectCurrentProjectType, selectCurrentPositionType, selectCurrentTemplate],
  (type, positionType, template) => {
    const isOfficeDay = template && template.key === EActivityType.OFFICE_DAY.value;
    const isPosition = type === EProjectType.POSITION.key;
    return (
      isOfficeDay && isPosition && (positionType && positionType !== 'ADMINISTRATIVE' && positionType !== 'INTERNSHIP')
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const { intl } = state;
  const currentTemplate = selectCurrentTemplate(state, ownProps);
  return {
    intl,
    projectName: selectCurrentProjectName(state, ownProps),
    projectLanguage: selectCurrentProjectPrimaryLanguage(state, ownProps),
    currentTemplate,
    gameDefinitionKey: currentTemplate && currentTemplate.key,
    internalPropsEnabled: localSelectInternalPropsEnabled(state, ownProps),
    internalName: formSelector(state, 'internalName'),
    internalDescription: formSelector(state, 'internalDescription'),
    customizeIntroduction: formSelector(state, 'customizeIntroduction'),
    customizeReport: formSelector(state, 'customizeReport'),
    defaultTemplates: localSelectDefaultTemplates(state, ownProps),
    initialValues: localSelectInitialValues(state, ownProps)
  };
};

const validate = () => {
  return {};
};

const formConfig = {
  form: TextsPart.ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

export default connect(mapStateToProps)(reduxForm(formConfig)(TextsPart));
