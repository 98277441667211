import React, { useMemo, useState } from 'react';
import _map from 'lodash/map';
import clr from 'color';

import HorizontalScrollPane from '../../../basic/HorizontalScrollPane';
import Collapse from '../../../basic/Collapse';
import Tab from '../../../menu/MenuItem';
import Badge from '../../../badge/Badge';

import { filterRiskMetrics } from './EchoUtils';

import withIntl from '../../../withIntl';

const TextBox = ({ title, text, size, tag }) => {
  return (
    <div className={`mui-padded ${title ? '' : 'mui-padded-top-x2'}`}>
      {tag && <Badge text={tag} className={'mui-margin-bottom-x2'} />}
      {title && size === 'reg' && <h4>{title}</h4>}
      {title && size === 'reg-sm' && <h5>{title}</h5>}
      <div className={`text-sz-${size}`}>
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
};

const MetricTabs = props => {
  const { metrics, active, setActiveIndex } = props;
  return (
    <HorizontalScrollPane>
      <div className="container-flex-row">
        {_map(metrics, (m, idx) => (
          <Tab
            key={m.key}
            label={
              <div className="mui-padded-horizontal-half">
                <span>{m.label}</span>
                <div className="inline-container mui-margin-left">
                  <div
                    className="container-flex-row ai-center jc-center border-radius"
                    style={{
                      width: '24px',
                      height: '24px',
                      backgroundColor: m.color,
                      color: clr(m.color).isDark() ? 'white' : 'inherit'
                    }}
                  >
                    {m.formattedValue}
                  </div>
                </div>
              </div>
            }
            active={active?.key === m.key}
            onClick={() => setActiveIndex(idx)}
          />
        ))}
      </div>
    </HorizontalScrollPane>
  );
};

const ParticipantEchoDetail = props => {
  const {
    metrics,
    detailExpanded,
    onDetailToggle,
    intl: { messages }
  } = props;
  const riskMetrics = useMemo(() => filterRiskMetrics(metrics), [metrics]);
  const [activeMetricIndex, setActiveMetricIndex] = useState(0);
  const activeMetric = riskMetrics[activeMetricIndex];
  return (
    <div className="participant-echo-detail clearfix">
      <div className="border-top border-bottom border-color-primary__100 bg-primary-light">
        <div style={{ padding: '8px 24px' }}>
          <h3>{messages.title}</h3>
        </div>
        {riskMetrics.length > 0 && (
          <div className="mui-padded-top mui-padded-horizontal-x2">
            <MetricTabs metrics={riskMetrics} active={activeMetric} setActiveIndex={setActiveMetricIndex} />
          </div>
        )}
      </div>
      {riskMetrics.length > 0 ? (
        <div>
          <div className="mui-padded-top">
            {activeMetric.interpretation?.text && (
              <div className='row'>
                <div className='col-sm-12'>
                  <TextBox size={'md'} text={activeMetric.interpretation?.text} tag={activeMetric.interpretation?.label} />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-sm-6">
                <TextBox
                  title={messages.deriskingLabel}
                  size={'reg'}
                  text={activeMetric.interpretation?.recommendations?.derisking}
                />
              </div>
              <div className="col-sm-6">
                <TextBox
                  title={messages.questionsLabel}
                  size={'reg'}
                  text={activeMetric.interpretation?.recommendations?.questions}
                />
              </div>
            </div>
            <Collapse isOpened={detailExpanded}>
              <div className="row">
                <div className="col-sm-6">
                  <TextBox title={messages.causesLabel} size={'reg-sm'} text={activeMetric.cause} />
                </div>
                <div className="col-sm-6">
                  <TextBox
                    title={messages.operationalizationLabel}
                    size={'reg-sm'}
                    text={activeMetric.operationalization}
                  />
                </div>
              </div>
            </Collapse>
            <div style={{ padding: '12px 24px 8px' }}>
              <a role="button" className="link-button heavy" onClick={onDetailToggle}>
                <span>{detailExpanded ? messages.showLess : messages.showMore}</span>
                <i className="material-icons">{detailExpanded ? 'expand_less' : 'expand_more'}</i>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-sz-reg text-muted" style={{ padding: '32px 24px 24px' }}>
          <span className="text-sz-md">{messages.noRiskFactorsTitle}</span>
          <br />
          <span className="text-sz-sm">{messages.noRiskFactorsMessage}</span>
        </div>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    showMore: 'Zobrazit více',
    showLess: 'Zobrazit méně',
    noRiskFactorsTitle: 'Tento zaměstnanec je docela spokojený',
    noRiskFactorsMessage: 'Nezjistili jsme žádné rizikové faktory',
    operationalizationLabel: 'Popis faktoru',
    causesLabel: 'Nejčastější příčiny',
    questionsLabel: 'Ptejte se',
    deriskingLabel: 'Doporučení',
    title: 'Employee well-being - Rizikové faktory'
  },
  sk: {
    showMore: 'Zobraziť viac',
    showLess: 'Zobraziť menej',
    noRiskFactorsTitle: 'Tento zamestnanec je pomerne spokojný',
    noRiskFactorsMessage: 'Nezistili sme žiadne rizikové faktory',
    operationalizationLabel: 'Popis faktoru',
    causesLabel: 'Najčastejšie príčiny',
    questionsLabel: 'Pýtajte sa',
    deriskingLabel: 'Odporúčanie',
    title: 'Employee well-being - Rizikové faktory'
  },
  en: {
    showMore: 'Show more',
    showLess: 'Show less',
    noRiskFactorsTitle: 'This employee is pretty happy',
    noRiskFactorsMessage: 'We found no risk factors',
    operationalizationLabel: 'Factor description',
    causesLabel: 'Most common causes',
    questionsLabel: 'Ask',
    deriskingLabel: 'Recommendation',
    title: 'Employee well-being - Risk factors'
  }
};

export default withIntl(ParticipantEchoDetail, intlMessages);
