import React from 'react';

import CompanySelect from './CompanySelect';
import CompanyProfile from './CompanyProfile';
import BaseContainer from '../../../components/basic/BaseContainer';

const CompanyProfileContainer = () => {
  return (
    <div>
      <CompanySelect includeDemo={false} required />
      <BaseContainer style={{ maxWidth: '640px', margin: '0 auto' }}>
        <CompanyProfile />
      </BaseContainer>
    </div>
  );
};

export default CompanyProfileContainer;
