import React from 'react';
import { Link } from 'react-router';

import Button from '../mui/Button';

const EmptySectionMessage = props => {
  const { header, message, actionLabel, actionLink } = props;
  return (
    <div className="mui-padded-horizontal-x2 mui-padded-vertical mui-margin-horizontal text-muted">
      <div className="mui-padded-left-half">
        <div
          className="container-flex-row jc-space-between ai-center fw-yes mui-margin-bottom"
          style={{ minHeight: '32px' }}
        >
          <em className="text-sz-reg">{header}</em>
          {actionLabel && (
            <Button
              containerElement={<Link to={actionLink} />}
              label={actionLabel}
              primary
              mini
              icon={<i className="material-icons text-sz-lg">add</i>}
            />
          )}
        </div>
        {message && <div className="text-sz-reg-sm" dangerouslySetInnerHTML={{ __html: message }} />}
      </div>
    </div>
  );
};

export default EmptySectionMessage;
