import { handleActions } from 'redux-actions';
import * as c from '../../constants/constants';

export const defaultState = {
    open: false
};

export default handleActions({
    [c.SIDEBAR_TOGGLE]: (state) => {
        return {
            ...state,
            open: !state.open
        }
    }
}, defaultState);
