import React from 'react';
import { connect } from 'react-redux';

import MembersActivitiesSelectDialog from './MembersActivitiesSelectDialog';

import { formatMessage } from '../../utils/utils';

const MemberRemoveDialog = props => {
  const { intl, ...rest } = props;
  const messages = intl.messages.components.teams.dialogs.memberRemove;
  return (
    <MembersActivitiesSelectDialog
      {...rest}
      renderMessage={members => <span>{formatMessage(messages.message, [members])}</span>}
      cancelLabel={messages.cancelLabel}
      confirmLabel={messages.removeLabel}
      confirmIcon={<i className="material-icons">clear</i>}
    />
  );
};

export default connect(({ intl }) => ({ intl }))(MemberRemoveDialog);
