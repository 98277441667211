import { handleActions } from 'redux-actions';
import { get } from 'lodash';
import * as c from '../../constants/constants';

export const defaultState = {
  [c.MODAL_INVITE_APPLICANTS]: {
    open: false
  },
  [c.MODAL_EMAIL_SENT]: {
    open: false
  },
  [c.MODAL_SIMPLE]: {
    open: false
  },
  [c.MODAL_ACCOUNT_NEW_RESULT]: {
    open: false
  },
  [c.MODAL_ACCOUNT_UPGRADE]: {
    open: false
  },
  [c.MODAL_CONFIRM_ACTIVATE]: {
    open: false
  },
  [c.MODAL_CONFIRM_DELETE]: {
    open: false
  },
  [c.MODAL_CONFIRM_FINISH]: {
    open: false
  },
  [c.MODAL_DEMO_PLAY]: {
    open: false
  },
  [c.MODAL_UNFINISHED_NOTIFICATION]: {
    open: false
  },
  [c.MODAL_PROJECT_COPY]: {
    open: false
  },
  [c.MODAL_PARTICIPANT_BATCH_OPERATION]: {
    open: false
  },
  [c.MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE]: {
    open: false
  },
  [c.MODAL_OPPORTUNITY_POST_PUBLICATION]: {
    open: false
  },
  [c.MODAL_CHANGED_FORM_LEAVE]: {
    open: false
  },
  [c.MODAL_CROSS_COMPARISON_PROJECT_SELECT]: {
    open: false
  },
  [c.MODAL_COMPARISON_PARTICIPANTS_SELECT]: {
    open: false
  },
  [c.MODAL_TALENT_MARKET_PLAN_SELECT]: {
    open: false
  }
};

export default handleActions(
  {
    [c.SWITCH_MODAL]: (state, { payload }) => {
      return {
        ...state,
        [payload.id]: {
          open: !get(state, payload.id, false).open,
          ...payload
        }
      };
    },
    [c.SIMULATION_DEFINITION_CREATE.SUCCESS]: (state, { payload: { payload: { url, ...code } } }) => ({
      ...state,
      [c.MODAL_INVITE_APPLICANTS]: { open: true, link: url, code }
    }),
    [c.PROJECT_INVITATION_URLS_GET.SUCCESS]: (state, { payload: { payload: { url, anonymousUrl } }, meta }) => ({
      ...state,
      [c.MODAL_INVITE_APPLICANTS]: { open: true, link: url, anonymousLink: anonymousUrl, meta }
    })
  },
  defaultState
);
