import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import Sticky from 'react-stickynode';

import TeamInsightsControlsPanel from './TeamInsightsControlsPanel';
import TeamOverviewHeader from './TeamOverviewHeader';
import GroupActivityInsightsPanel from './GroupActivityInsightsPanel';
import MemberActivityInsightsPanel from './MemberActivityInsightsPanel';
import TeamInsightsList from './TeamInsightsList';
import TeamInsightHeader from './TeamInsightHeader';
import Note from './Note';

import { EActivityType, EGroupMembership, EGroupType } from '../../constants/enum';
import { getActivityMapKey, getActivityMetricDefinitions, getInsightsState, saveInsightsState } from './TeamUtils';

import * as Api from '../../api/entities/groupApi';
import * as CultureFitApi from '../../api/options/cultureFitApi';
import * as insightActions from '../../actions/entities/insightActions';

import { enhanceMember } from './teamMemberSelectors';
import {
  computeGroupMetricStats,
  EGroupFocus,
  EMetricFocus,
  extractMetricPropertiesFromGroup,
  extractMetricPropertiesFromIndividual,
  extractMetricsFromGroup,
  extractMetricsFromIndividual,
  createGetParticipantProps,
  toInsightEntity,
  fromInsightEntity
} from './TeamInsightsUtils';
import { b64ToObject, checkEqualProperties, objectToB64 } from '../../utils/utils';
import { computeCrossComparisonFit, convertMetricsToCrossComparisonData } from '../../utils/analytics';
import { HEADER_SELECTOR } from '../../constants/constants';

import withIntl from '../withIntl';

const initialState = {
  activeMemberId: null,
  activeMember: null,
  membersDataSource: { map: {}, list: [] },
  focusKey: EMetricFocus.SIGNIFICANT.key,
  focusedMetrics: {},
  groupFocusKey: EGroupFocus.FULL.key,
  activityMetricProperties: {},
  activityMetricPropertiesInteracted: false,
  activityCollapsed: {},
  activityChartsShown: {},
  activityParticipantsListShown: {},
  activityMetricStats: {}
};

const cleanBoolMap = boolMap =>
  _reduce(
    boolMap,
    (res, val, key) => {
      if (val) {
        res[key] = val;
      }
      return res;
    },
    {}
  );

const isObjectEmpty = obj => {
  if (!obj) {
    return true;
  }
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
};

const cleanNestedBoolMap = nestedBoolMap =>
  _reduce(
    nestedBoolMap,
    (res, boolMap, key) => {
      const bm = cleanBoolMap(boolMap);
      if (!isObjectEmpty(bm)) {
        res[key] = bm;
      }
      return res;
    },
    {}
  );

const extractFirstProp = obj => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return prop;
    }
  }
  return null;
};

const filterMembers = (filterable, toFilterOut) => {
  const toFilterOutMap = _keyBy(toFilterOut, m => m.id);
  return _filter(filterable, m => !toFilterOutMap[m.id]);
};

class TeamInsights extends React.Component {
  state = initialState;

  componentDidMount() {
    this.setDefaultGroupFocusKey();
    this.updateMembersDataSource();
    this.loadTeamState();
    this.fetchManager();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.team?.id !== this.props.team?.id) {
      this.loadTeamState();
    }
    if (prevProps.members !== this.props.members) {
      this.updateMembersDataSource();
    }
    if (prevProps.intl !== this.props.intl) {
      this.fetchActiveMember(this.state.activeMemberId);
    }
    if (prevProps.intl !== this.props.intl || prevProps.manager !== this.props.manager) {
      this.fetchManager();
    }
    if (
      !checkEqualProperties(prevState, this.state, [
        'activeMember',
        'manager',
        'focusKey',
        'focusedMetrics',
        'groupFocusKey'
      ]) ||
      !checkEqualProperties(prevProps, this.props, [
        'members',
        'managers',
        'intl',
        'highlighted',
        'targetGroup',
        'type'
      ])
    ) {
      this.updateActivities();
    }
    if (!checkEqualProperties(prevProps.location.query, this.props.location.query, ['insightId'])) {
      this.loadInsightFromQueryIntoState();
    }
    if (
      !checkEqualProperties(prevState, this.state, [
        'name',
        'note',
        'insightId',
        'nameChangedManually',
        'activeMemberId',
        'focusKey',
        'focusedMetrics',
        'groupFocusKey',
        'activityMetricProperties',
        'activityMetricPropertiesInteracted',
        'activityCollapsed',
        'activityChartsShown',
        'activityParticipantsListShown',
        'editing'
      ])
    ) {
      this.saveState();
    }
  }
  componentWillUnmount() {
    this.unmounted = true;
  }

  setDefaultGroupFocusKey = () => {
    const { members } = this.props;
    const membersList = members?.filtered || [];
    if (membersList.length === 1) {
      this.setState({ groupFocusKey: EGroupFocus.MEMBER.key });
    } else {
      this.setState({ groupFocusKey: EGroupFocus.FULL.key });
    }
  };

  saveState = () => {
    const {
      name,
      note,
      insightId,
      nameChangedManually,
      activeMemberId,
      focusKey,
      focusedMetrics,
      groupFocusKey,
      activityMetricProperties,
      activityMetricPropertiesInteracted,
      activityCollapsed,
      activityChartsShown,
      activityParticipantsListShown,
      editing
    } = this.state;
    const localState = { activeMemberId, insightId, name, note, editing, groupFocusKey, nameChangedManually };
    const globalState = {
      focusKey,
      focusedMetrics,
      activityMetricProperties,
      activityMetricPropertiesInteracted,
      activityCollapsed,
      activityChartsShown,
      activityParticipantsListShown
    };
    // this.saveToUrl({ ...localState, ...globalState });
    saveInsightsState(this.props.team, localState, globalState);
  };

  saveToUrl = s => {
    const {
      router,
      location,
      location: { query }
    } = this.props;
    const relevantState = {
      n: s.name,
      ncm: s.nameChangedManually,
      fk: s.focusKey,
      gfk: s.groupFocusKey,
      ac: cleanBoolMap(s.activityCollapsed),
      acs: cleanBoolMap(s.activityChartsShown),
      acpls: cleanBoolMap(s.activityParticipantsListShown),
      amp: cleanNestedBoolMap(s.activityMetricProperties),
      ampi: s.activityMetricPropertiesInteracted
    };
    if (s.focusKey === EMetricFocus.SELECTED.key) {
      relevantState.fm = cleanBoolMap(s.focusedMetrics);
    }
    if (s.groupFocusKey === EGroupFocus.MEMBER.key) {
      relevantState.ami = s.activeMemberId;
    }
    router.push({ ...location, query: { ...query, iqCfg: objectToB64(relevantState) } });
  };

  loadInsightFromQuery = () => {
    const {
      location: {
        query: { insightId }
      },
      team
    } = this.props;
    if (insightId) {
      const { getInsight } = this.props;
      return getInsight(team.id, insightId).then(res => {
        if (!res?.payload?.payload) {
          return null;
        }
        const {
          payload: { payload }
        } = res;
        const insight = fromInsightEntity(payload);
        insight.nameChangeManually = true;
        insight.activityMetricPropertiesInteracted = true;
        return insight;
      });
    } else {
      const { unsetInsight } = this.props;
      unsetInsight();
    }
    return Promise.resolve(null);
  };

  loadFromUrl = () => {
    const {
      location: {
        query: { iqCfg }
      }
    } = this.props;
    if (iqCfg) {
      const cfg = b64ToObject(iqCfg);
      if (!isObjectEmpty(cfg)) {
        return Promise.resolve({
          name: cfg.n,
          nameChangeManually: cfg.ncm,
          focusKey: cfg.fk,
          groupFocusKey: cfg.gfk,
          activityCollapsed: cfg.ac || {},
          activityChartsShown: cfg.acs || {},
          activityParticipantsListShown: cfg.acpls || {},
          activityMetricProperties: cfg.amp || {},
          activityMetricPropertiesInteracted: cfg.ampi,
          focusedMetrics: cfg.fm || {},
          activeMemberId: cfg.ami
        });
      }
    }
    return this.loadInsightFromQuery();
  };

  loadInsights = () => {
    const { getInsights, team } = this.props;
    if (team?.id) {
      return getInsights(team.id);
    }
    return Promise.resolve();
  };

  setLoadedState = (loadedState, callback) => {
    const { members } = this.props;
    const { activeMemberId, ...savedState } = loadedState || {};
    if (activeMemberId) {
      this.setActiveMember(activeMemberId);
    } else {
      const membersList = members?.filtered || [];
      const firstMember = membersList[0];
      if (firstMember) {
        this.setActiveMember(firstMember.id);
      }
    }
    if (!savedState.insightId) {
      savedState.name = null;
      savedState.editing = true;
    }
    this.setState(savedState, callback);
  };

  loadInsightFromQueryIntoState = () => {
    this.loadInsightFromQuery().then(loadedState => this.setLoadedState(loadedState));
  };

  loadTeamState = () => {
    const { team, unsetInsight, unsetInsights } = this.props;
    unsetInsight();
    unsetInsights();
    this.loadInsights();
    const savedState = getInsightsState(team);
    this.loadFromUrl().then(loadedState => {
      if (!loadedState) {
        const { insightId } = savedState || {};
        if (insightId) {
          this.handleInsightIdSelect(insightId);
        }
      }
      this.setLoadedState(loadedState || savedState);
    });
  };

  updateMembersDataSource = () => {
    const { members } = this.props;
    const memberList = members?.filtered || [];
    const membersDataSource = _reduce(
      memberList,
      (res, m) => {
        const { id, lastName, firstName, email } = m;
        res.list.push({
          value: id,
          label: lastName && firstName ? `${lastName} ${firstName}` : email,
          description: lastName && firstName ? email : null
        });
        res.map[id] = m;
        return res;
      },
      { map: {}, list: [] }
    );
    this.setState({ membersDataSource });
  };

  fetchManager = () => {
    const {
      team,
      manager,
      intl: { locale }
    } = this.props;
    if (manager) {
      Api.getGroupMember(team.id, manager.id, locale).then(res => {
        if (!this.unmounted) {
          this.setState({
            manager: { ...manager, ...enhanceMember(res.payload, this.getActivities(EGroupMembership.MANAGER.key), {}) }
          });
        }
      });
    } else {
      this.setState({ manager: null });
    }
  };

  fetchActiveMember = activeMemberId => {
    const {
      team,
      intl: { locale }
    } = this.props;
    if (activeMemberId) {
      Api.getGroupMember(team.id, activeMemberId, locale).then(res => {
        if (!this.unmounted) {
          const { membersDataSource } = this.state;
          const activeMember = activeMemberId ? membersDataSource.map[activeMemberId] : null;
          this.setState({
            activeMember: {
              ...activeMember,
              ...enhanceMember(res.payload, this.getActivities(EGroupMembership.MEMBER.key), {})
            }
          });
        }
      });
    } else {
      this.setState({ activeMember: null });
    }
  };

  setActiveMember = id => {
    this.setState({ activeMemberId: id });
    this.fetchActiveMember(id);
  };

  handleActiveMemberChange = id => {
    this.setActiveMember(id);
  };

  getActivities = key => {
    const { team } = this.props;
    const { groupFocusKey } = this.state;
    const activityConfigurationKey =
      groupFocusKey === EGroupFocus.MANAGER.key ? EGroupMembership.MANAGER.key : EGroupMembership.MEMBER.key;
    return _get(team, `data.activityConfigurations[${key || activityConfigurationKey}].activities`) || [];
  };

  handleFocusKeyChange = focusKey => this.setState({ focusKey });
  handleGroupFocusKeyChange = groupFocusKey => this.setState({ groupFocusKey });
  handleMetricFocusToggle = metric => {
    const {
      focusedMetrics: { [metric.key]: enabled, ...focusedMetrics }
    } = this.state;
    if (!enabled) {
      focusedMetrics[metric.key] = true;
    }
    this.setState({ focusedMetrics });
  };

  handleActivityMetricPropertyToggle = (a, prop) => {
    const aKey = a.mapKey;
    const {
      activityMetricProperties: { [aKey]: aProps, ...activityMetricProperties }
    } = this.state;
    const aProp = (aProps || {})[prop];
    activityMetricProperties[aKey] = { ...aProps, [prop]: !aProp };
    this.setState({ activityMetricProperties, activityMetricPropertiesInteracted: true });
  };

  handleActivityCollapsedToggle = a => {
    const {
      activityCollapsed: { [a.mapKey]: collapsed, ...activityCollapsed }
    } = this.state;
    if (!collapsed) {
      activityCollapsed[a.mapKey] = true;
    }
    this.setState({ activityCollapsed });
  };
  handleActivityChartsShownToggle = a => {
    const {
      activityChartsShown: { [a.mapKey]: shown, ...activityChartsShown }
    } = this.state;
    if (!shown) {
      activityChartsShown[a.mapKey] = true;
    }
    this.setState({ activityChartsShown });
  };
  handleActivityParticipantsListShownToggle = a => {
    const {
      activityParticipantsListShown: { [a.mapKey]: shown, ...activityParticipantsListShown }
    } = this.state;
    if (!shown) {
      activityParticipantsListShown[a.mapKey] = true;
    }
    this.setState({ activityParticipantsListShown });
  };

  enrichCultureFitActivity = (a, activeMember, targetMembers) => {
    const metricsList = a.metrics?.all;
    if (!metricsList || metricsList.length === 0 || !targetMembers) {
      return Promise.resolve({ activity: a });
    }
    const { manager, highlighted } = this.props;
    const getParticipantProps = createGetParticipantProps(a, highlighted);
    const sourceMembers = [];
    if (activeMember) {
      sourceMembers.push(activeMember);
    }
    if (manager) {
      sourceMembers.push(manager);
    }
    const activeIndex = activeMember ? 0 : null;
    const { metrics } = computeCrossComparisonFit(
      metricsList,
      sourceMembers,
      filterMembers(targetMembers, sourceMembers),
      activeIndex,
      getParticipantProps
    );
    a.metrics.data = _keyBy(metrics, m => m.metric.key);
    return Promise.resolve({ activity: a, metrics });
  };

  customizeActivity = (a, groupFocusKey, activeMember) => {
    if (groupFocusKey === EGroupFocus.FULL.key && EActivityType.CULTURE_FIT.isLike(a)) {
      const {
        members,
        intl: { locale }
      } = this.props;
      const targetMembers = members?.filtered;
      return this.enrichCultureFitActivity(a, null, targetMembers).then(
        ({ activity, metrics }) =>
          new Promise(resolve => {
            if (!metrics) {
              return resolve(activity);
            }
            const convertedMetrics = convertMetricsToCrossComparisonData(metrics);
            const data = { metrics: convertedMetrics };
            return CultureFitApi.getInterpretations(data, locale).then(({ payload }) => {
              const interpretedMetrics = payload?.metrics || {};
              _forEach(activity.metrics.all || [], m => {
                const i = interpretedMetrics[m.key];
                if (i) {
                  m.interpretation = i;
                }
              });
              return resolve(activity);
            });
          })
      );
    } else if (groupFocusKey === EGroupFocus.MEMBER.key && EActivityType.CULTURE_FIT.isLike(a)) {
      const { type, members, targetGroup } = this.props;
      const targetMembers = type === EGroupType.HIRING ? targetGroup?.members?.items || [] : members?.filtered || [];
      return this.enrichCultureFitActivity(a, activeMember, targetMembers).then(({ activity }) => activity);
    } else if (groupFocusKey === EGroupFocus.MANAGER.key && EActivityType.CULTURE_FIT.isLike(a)) {
      const { members } = this.props;
      const { manager } = this.state;
      const targetMembers = members?.filtered || [];
      return this.enrichCultureFitActivity(a, manager, targetMembers).then(({ activity }) => activity);
    }
    return Promise.resolve(a);
  };

  updateActivities = () => {
    const {
      manager,
      activeMember,
      focusKey,
      focusedMetrics,
      groupFocusKey,
      activityMetricPropertiesInteracted
    } = this.state;
    // stupid hack to prevent updating the state from an old promise
    const _updateCounter = (this._updateCounter || 0) + 1;
    this._updateCounter = _updateCounter;
    const { type, members, managers, targetGroup } = this.props;
    const baseActivities = this.getActivities();
    const targetMembers =
      type === EGroupType.HIRING && groupFocusKey === EGroupFocus.MEMBER.key
        ? targetGroup?.members?.items || []
        : members?.filtered || [];
    const activityMetricStats = computeGroupMetricStats(baseActivities, targetMembers);
    const activitiesPromises = _map(baseActivities, a => {
      const mapKey = getActivityMapKey(a);
      const metricDefinitions =
        EGroupFocus.MANAGER.key === groupFocusKey
          ? getActivityMetricDefinitions(a, managers?.payload?.configuration || {})
          : getActivityMetricDefinitions(a, members?.payload?.configuration || {});
      let metrics = { list: [], all: [], data: {} };
      let extractInsightProps = () => ({});
      const metricFocus = EMetricFocus[focusKey];
      if (EGroupFocus.MEMBER.key === groupFocusKey) {
        if (activeMember) {
          const memberActivity = activeMember.meta.activities.map[mapKey] || {};
          metrics = extractMetricsFromIndividual(memberActivity, metricDefinitions, metricFocus, focusedMetrics);
          extractInsightProps = metrics => extractMetricPropertiesFromIndividual(a, metrics);
        }
      } else if (EGroupFocus.MANAGER.key === groupFocusKey) {
        if (manager) {
          const managerActivity = manager.meta.activities.map[mapKey] || {};
          metrics = extractMetricsFromIndividual(managerActivity, metricDefinitions, metricFocus, focusedMetrics);
          extractInsightProps = metrics => extractMetricPropertiesFromIndividual(a, metrics);
        }
      } else {
        const metricStats = activityMetricStats[mapKey];
        metrics = extractMetricsFromGroup(members, metricStats, metricDefinitions, metricFocus, focusedMetrics);
        extractInsightProps = metrics => extractMetricPropertiesFromGroup(a, metrics);
      }
      return this.customizeActivity(
        {
          ...a,
          mapKey,
          metrics,
          metricDefinitions: _reduce(
            metrics.all,
            (res, m) => {
              res[m.key] = metricDefinitions[m.key];
              return res;
            },
            {}
          )
        },
        groupFocusKey,
        activeMember
      ).then(a => {
        a.insightProps = extractInsightProps(a.metrics?.list || []);
        const firstProp = extractFirstProp(a.insightProps);
        const initialInsightProps = firstProp ? { [firstProp]: true } : null;
        return { activity: a, initialInsightProps };
      });
    });
    Promise.all(activitiesPromises).then(activityResults => {
      if (!this.unmounted) {
        const { activities, initialActivityMetricProperties } = _reduce(
          activityResults,
          (res, ar) => {
            res.activities.push(ar.activity);
            res.initialActivityMetricProperties[ar.activity.mapKey] = ar.initialInsightProps;
            return res;
          },
          {
            activities: [],
            initialActivityMetricProperties: {}
          }
        );
        const newState = { activities, activityMetricStats, targetMembers };
        if (!activityMetricPropertiesInteracted) {
          newState.activityMetricProperties = initialActivityMetricProperties;
        }
        if (_updateCounter === this._updateCounter) {
          this._updateCounter = 0;
          this.setState(newState);
        }
      }
    });
  };

  saveInsight = (id, insight) => {
    const { updateInsight, createInsight, router, location } = this.props;
    const savePromise = id
      ? updateInsight(id, insight)
      : createInsight(insight).then(res => {
          const {
            payload: {
              payload: { id }
            }
          } = res;
          router.push({ ...location, query: { ...location.query, insightId: id } });
          return res;
        });
    return savePromise.then(res => {
      this.loadInsights();
      return res;
    });
  };

  handleNameChange = e => this.setState({ name: e.target.value, nameChangedManually: true });
  handleNameBlur = e => {
    const { insightId } = this.state;
    if (insightId) {
      const name = (e.target.value || '').trim();
      const {
        insight: { current }
      } = this.props;
      if (current && name && name !== current.name) {
        this.saveInsight(insightId, { ...current, name });
      }
    }
  };

  getDefaultName = () => {
    const { groupFocusKey, manager, activeMember } = this.state;
    const {
      team,
      intl: {
        messages: { defaultName }
      }
    } = this.props;
    const determineName = m => {
      if (m.lastName && m.firstName) {
        return `${m.lastName} ${m.firstName}`;
      }
      return m.email;
    };
    if (groupFocusKey === EGroupFocus.FULL.key) {
      return defaultName.group[team.type];
    }
    if (groupFocusKey === EGroupFocus.MANAGER.key) {
      return manager ? determineName(manager) : defaultName.manager;
    }
    return activeMember ? determineName(activeMember) : defaultName.member[team.type];
  };

  handleStarredToggle = () => {
    this.setState(({ starredAt, insightId }) => {
      const newStarredAt = starredAt ? null : Date.now();
      if (insightId) {
        const { insight } = this.props;
        this.saveInsight(insightId, { ...insight?.current, starredAt: newStarredAt });
      }
      return { starredAt: newStarredAt };
    });
  };
  handleInsightSave = () => {
    const { manager, activities, insightId } = this.state;
    const { team } = this.props;
    const insight = toInsightEntity(this.state, team, manager, this.getDefaultName(), activities);
    this.saveInsight(insightId, insight).then(() => this.setState({ editing: false }));
  };
  handleEditStart = () => this.setState({ editing: true });
  handleEditCancel = () => {
    const { insightId } = this.state;
    if (insightId) {
      const {
        insight: { current }
      } = this.props;
      const newState = { editing: false, ...(current ? fromInsightEntity(current) : {}) };
      this.setState(newState);
    } else {
      this.handleInsightIdSelect(this.state.prevInsightId);
      this.setState({ editing: false });
    }
  };
  handleInsightDelete = insight => {
    const {
      deleteInsight,
      insight: { current }
    } = this.props;
    deleteInsight(insight.group.id, insight.id).then(() => {
      if (insight.id === current.id) {
        this.setState({ insightId: null });
      }
      this.loadInsights();
    });
  };
  handleInsightIdSelect = id => {
    const { location, router } = this.props;
    const {
      query: { insightId, ...query }
    } = location;
    if (id) {
      query.insightId = id;
    }
    router.push({ ...location, query });
  };
  handleInsightSelect = insight => this.handleInsightIdSelect(insight ? insight.id : null);
  handleInsightAdd = () => {
    const { insightId } = this.state;
    this.handleInsightIdSelect(null);
    this.setState({ editing: true, name: '', note: null, prevInsightId: insightId, insightId: null, starredAt: null });
  };
  handleNoteChange = e => this.setState({ note: e.target.value });
  handleNoteBlur = e => {
    const { insightId } = this.state;
    if (insightId) {
      const note = (e.target.value || '').trim() || null;
      const {
        insight: { current }
      } = this.props;
      if (current && note !== current.note) {
        this.saveInsight(insightId, { ...current, note });
      }
    }
  };

  render() {
    const {
      insightId,
      name,
      nameChangedManually,
      note,
      starredAt,
      activeMemberId,
      membersDataSource,
      manager,
      activeMember,
      focusKey,
      focusedMetrics,
      groupFocusKey,
      activityMetricStats,
      activityMetricProperties,
      activityCollapsed,
      activityChartsShown,
      activityParticipantsListShown,
      activities,
      targetMembers,
      editing
    } = this.state;
    const {
      team,
      type,
      targetGroup,
      members,
      highlighted,
      sidebar,
      onAddStart,
      onProfileShow,
      insight,
      insights,
      intl: { messages }
    } = this.props;
    const filteredMembers = members?.filtered || [];
    const allMembers = members?.items || [];
    const controlsShown = editing && allMembers.length > 0;
    const insightListShown = !editing;
    const sidebarShown = sidebar || controlsShown || insightListShown;
    return (
      <div className="container-fluid">
        <div className="row container-flex-row fw-yes">
          {sidebarShown && (
            <div
              id={`teamFilter_${team?.id}`}
              className="team__insights-sidebar col-xs-12 col-md-3 col-md-push-9 border-color-primary__50"
              style={{ padding: 0 }}
            >
              <Sticky top={HEADER_SELECTOR} innerZ={100} bottomBoundary={`#teamFilter_${team?.id}`}>
                {sidebar}
                {controlsShown && (
                  <div
                    className={`mui-padded-horizontal-x2 mui-padded-bottom ${
                      sidebar ? 'mui-padded-top border-top border-color-primary__50' : ''
                    }`}
                  >
                    <TeamInsightsControlsPanel
                      team={team}
                      activeMemberId={activeMemberId}
                      membersDataSource={membersDataSource}
                      focusKey={focusKey}
                      focusedMetrics={focusedMetrics}
                      groupFocusKey={groupFocusKey}
                      activityMetricProperties={activityMetricProperties}
                      activities={activities}
                      activityChartsShown={activityChartsShown}
                      activityParticipantsListShown={activityParticipantsListShown}
                      onActiveMemberChange={this.handleActiveMemberChange}
                      onFocusKeyChange={this.handleFocusKeyChange}
                      onGroupFocusKeyChange={this.handleGroupFocusKeyChange}
                      onActivityMetricPropertyToggle={this.handleActivityMetricPropertyToggle}
                      onMetricFocusToggle={this.handleMetricFocusToggle}
                      onActivityChartsShownToggle={this.handleActivityChartsShownToggle}
                      onActivityParticipantsListShownToggle={this.handleActivityParticipantsListShownToggle}
                    />
                  </div>
                )}
                {insightListShown && (
                  <div className={sidebar ? 'border-top border-color-primary__50' : ''}>
                    <TeamInsightsList
                      insights={insights}
                      insight={insight}
                      team={team}
                      members={members}
                      manager={manager}
                      onInsightSelect={this.handleInsightSelect}
                      onInsightDelete={this.handleInsightDelete}
                      onInsightAdd={this.handleInsightAdd}
                    />
                  </div>
                )}
              </Sticky>
            </div>
          )}
          <div
            className={`${
              sidebarShown ? 'col-xs-12 col-md-9 col-md-pull-3' : 'col-xs-12'
            } team__content-panel border-color-primary__75`}
            style={{ minHeight: '520px' }}
          >
            {(editing || insight.current?.id) && (
              <div
                className="border-bottom border-color-primary__50"
                style={{ padding: '8px 16px', margin: '0 -16px' }}
              >
                <TeamInsightHeader
                  insight={insight}
                  insights={insights}
                  starredAt={starredAt}
                  editing={editing}
                  name={name || nameChangedManually ? name : this.getDefaultName()}
                  showNameHint={!name && !nameChangedManually && !insightId}
                  onEditStart={this.handleEditStart}
                  onEditCancel={this.handleEditCancel}
                  onInsightSave={this.handleInsightSave}
                  onStarredToggle={this.handleStarredToggle}
                  onNameChange={this.handleNameChange}
                  onNameBlur={this.handleNameBlur}
                />
                <div className={`mui-padded-bottom-x2 mui-padded-horizontal${note ? '' : ' hidden-print'}`}>
                  <Note
                    label={messages.note.label}
                    helperText={messages.note.helperText}
                    hint={messages.note.hint}
                    value={note}
                    onChange={this.handleNoteChange}
                    onBlur={this.handleNoteBlur}
                  />
                </div>
              </div>
            )}
            {filteredMembers.length > 0 ? (
              <div>
                {groupFocusKey === EGroupFocus.MEMBER.key && (
                  <MemberActivityInsightsPanel
                    member={activeMember}
                    type={type}
                    targetGroup={targetGroup}
                    activities={activities}
                    activityMetricProperties={activityMetricProperties}
                    activityMetricStats={activityMetricStats}
                    activityCollapsed={activityCollapsed}
                    activityChartsShown={activityChartsShown}
                    activityParticipantsListShown={activityParticipantsListShown}
                    onActivityCollapsedToggle={this.handleActivityCollapsedToggle}
                    onProfileShow={onProfileShow}
                    targetMembers={targetMembers}
                  />
                )}
                {groupFocusKey === EGroupFocus.MANAGER.key && (
                  <MemberActivityInsightsPanel
                    member={manager}
                    type={type}
                    targetGroup={targetGroup}
                    activities={activities}
                    activityMetricProperties={activityMetricProperties}
                    activityMetricStats={activityMetricStats}
                    activityCollapsed={activityCollapsed}
                    activityChartsShown={activityChartsShown}
                    activityParticipantsListShown={activityParticipantsListShown}
                    onActivityCollapsedToggle={this.handleActivityCollapsedToggle}
                    onProfileShow={onProfileShow}
                    targetMembers={targetMembers}
                  />
                )}
                {groupFocusKey === EGroupFocus.FULL.key && (
                  <div>
                    <div className="mui-padded-top-x2" style={{ margin: '0 -8px' }}>
                      <TeamOverviewHeader type={type} members={members} manager={manager} onAddStart={onAddStart} />
                    </div>
                    <GroupActivityInsightsPanel
                      members={filteredMembers}
                      allMembers={allMembers}
                      activities={activities}
                      highlighted={highlighted}
                      activityMetricProperties={activityMetricProperties}
                      activityMetricStats={activityMetricStats}
                      activityCollapsed={activityCollapsed}
                      activityChartsShown={activityChartsShown}
                      activityParticipantsListShown={activityParticipantsListShown}
                      onActivityCollapsedToggle={this.handleActivityCollapsedToggle}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="mui-padded-top-x2" style={{ margin: '0 -8px' }}>
                <TeamOverviewHeader type={type} members={members} manager={manager} onAddStart={onAddStart} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ entities: { insight, insights } }) => ({ insight, insights });
const actions = insightActions;

const intlMessages = {
  cs: {
    defaultName: {
      group: { TEAM: 'Celý tým', GROUP: 'Celá skupina', HIRING: 'Všichni kandidáti' },
      member: { TEAM: 'Člen týmu', GROUP: 'Člen skupiny', HIRING: 'Kandidát' },
      manager: 'Manažer'
    },
    note: {
      label: 'Poznámka',
      hint: 'Přidejte poznámku...',
      helperText: 'Přidejte si vlastní poznámku k poznatku'
    }
  },
  sk: {
    defaultName: {
      group: { TEAM: 'Celý tým', GROUP: 'Celá skupina', HIRING: 'Všetci kandidáti' },
      member: { TEAM: 'Člen tímu', GROUP: 'Člen skupiny', HIRING: 'Kandidát' },
      manager: 'Manažér'
    },
    note: {
      label: 'Poznámka',
      hint: 'Pridajte poznámku...',
      helperText: 'Pridajte si vlastnú poznámku k poznatku'
    }
  },
  en: {
    defaultName: {
      group: { TEAM: 'Whole team', GROUP: 'Whole group', HIRING: 'All candidates' },
      member: { TEAM: 'Team member', GROUP: 'Group member', HIRING: 'Candidate' },
      manager: 'Manager'
    },
    note: {
      label: 'Note',
      hint: 'Add a note...',
      helperText: 'Add your own note to the insight'
    }
  }
};

export default withRouter(withIntl(connect(mapStateToProps, actions)(TeamInsights), intlMessages));
