import React from 'react';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import color from 'color';

import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import slice from 'lodash/slice';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import join from 'lodash/join';

import { USER_TOKEN, GRIDS_OPTIONS, DEFAULT_WEIGHT, SIMULATION_RESOLUTION_MINIMUM } from '../constants/constants';
import { EApplicantListSort, EContractPlanMode, EActivityType } from '../constants/enum';
import { defaultOptions } from '../reducers/options/gridsReducer';

export const colorWithAlpha = (clr, alpha) => color(clr).fade(alpha).string();

export const colorBlendedWithWhite = (clr, amount) => color(clr).mix(color('white'), amount).string();

export function saveToken(userToken) {
  localStorage.setItem(USER_TOKEN, userToken);
}

export function loadToken() {
  const token = localStorage.getItem(USER_TOKEN);
  if (token) {
    try {
      const { exp } = jwtDecode(token);
      if (exp > Date.now() / 1000) {
        return token;
      }
    } catch (e) {
      console.warn('Invalid token', e);
    }
    deleteToken();
  }
  return null;
}

export function decodeToken() {
  try {
    return jwtDecode(loadToken());
  } catch (e) {
    return {};
  }
}

export function deleteToken() {
  localStorage.removeItem(USER_TOKEN);
}

export const get = (obj, path, defVal) => {
  if (!obj) {
    return defVal;
  }
  const p = typeof path === 'string' ? path.split('.') : path;
  let cur = obj;
  for (let i = 0; i < p.length && cur != null; i += 1) {
    cur = cur[p[i]];
  }
  if (cur !== undefined) {
    return cur;
  }
  return defVal;
};

export const mapObjectAsArray = (obj, mapper, predicate) => {
  const res = [];
  let idx = 0;
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      const val = obj[prop];
      if (!predicate || predicate(val)) {
        res.push(mapper(val, prop, obj, idx));
        idx += 1;
      }
    }
  }
  return res;
};

function sortDisplayedMetricsCommon(metrics, metricDefinitions, filterFn) {
  const shownMetrics = pickBy(metricDefinitions, d => !isNaN(d.displayOrder) && filterFn(d));
  const sortedMetrics = orderBy(
    map(keys(shownMetrics), m => ({ key: m, ...shownMetrics[m] })),
    'displayOrder'
  );
  return map(
    filter(sortedMetrics, m => metrics[m.key]),
    m => ({ ...m, ...metrics[m.key] })
  );
}

export function sortDisplayedMetrics(metrics, metricDefinitions, type = 'NUMERIC', parent = null) {
  return sortDisplayedMetricsCommon(
    metrics,
    metricDefinitions,
    d => d.type === type && (parent ? d.parent === parent : !d.parent)
  );
}

export function sortMetrics(metrics, property = 'value', order = 'desc') {
  return orderBy(metrics, [property], [order]);
}

export function saveGridsOptions(options) {
  localStorage.setItem(GRIDS_OPTIONS, JSON.stringify(options));
}

export function loadGridOptions() {
  return JSON.parse(localStorage.getItem(GRIDS_OPTIONS) || null);
}
export function getSidebarSettings(ui) {
  const { width } = ui;
  // if (width < 1152) {
  if (width < 1366) {
    return { docked: false, width: 256 };
  }
  // if (width < 1366) {
  //   return { docked: true, width: 64 };
  // }
  return { docked: true, width: 256 };
}

export function isSimulationResolutionValid(ui) {
  return ui.width >= SIMULATION_RESOLUTION_MINIMUM.WIDTH && ui.height >= SIMULATION_RESOLUTION_MINIMUM.HEIGHT;
}

export function resolveGridOptions(grid, projectSlug) {
  return { ...defaultOptions, ...get(grid, [`${projectSlug}`, 'options'], {}) };
}

export function extractWeight(scoreMapping, key) {
  return scoreMapping[key] || { value: DEFAULT_WEIGHT };
}

export function extractWeightValue(scoreMapping, key) {
  return extractWeight(scoreMapping, key).value;
}

export function extractMetric(item, key) {
  return get(item, `result.metrics.${key}`);
}

export function extractParticipantNameConfig(p, messages) {
  if (!p) {
    return {};
  }
  const { firstName, lastName, email, anonymous } = p;
  const available = !!(firstName || lastName || email);
  return {
    available,
    anonymous,
    email: email && !firstName && !lastName,
    text: anonymous
      ? (messages && messages.anonymous) || 'N/A'
      : firstName || lastName
      ? `${lastName}${firstName && lastName ? ' ' : ''}${firstName}`
      : email || 'N/A'
  };
}

export const reverseArray = arr => {
  if (!arr || arr.length === 0) {
    return arr;
  }
  const res = [];
  for (let i = 0; i < arr.length; i += 1) {
    res[i] = arr[arr.length - i - 1];
  }
  return res;
};

const range = (from, to, step) => {
  const arr = [];
  for (let i = from; i < to; i += step || 1) {
    arr.push(i);
  }
  return arr;
};

const FIAT_TICKS = ['F', 'I', 'A', 'T'];

export const formatFiatScore = s => FIAT_TICKS[Math.max(0, Math.min(Math.round(s * 4) - 1, 3))];

const determineValueTypeProps = (p, metric) => {
  const vt = get(p || {}, `result.metrics.${metric.key}.valueType`) || 'PLAIN';
  if (vt === 'STEN' || vt === 'SEMI_STEN' || vt === 'STEN_WEIGHED') {
    return {
      domain: [0, 1],
      ticks: range(0, 1.01, 0.1),
      scoreAmplifier: s => s,
      scoreFormatter: value => Math.round(value * 100) / 10,
      tickFormatter: s => Math.round(s * 100) / 10
    };
  }
  if (vt === 'FIAT') {
    return {
      domain: [0, 1],
      ticks: range(0, 1.1, 0.25),
      scoreAmplifier: s => s,
      scoreFormatter: formatFiatScore,
      tickFormatter: s => FIAT_TICKS[Math.ceil(s * 4) - 1] || ''
    };
  }
  return {
    domain: [0, 1],
    ticks: range(0, 1.01, 0.1),
    scoreAmplifier: s => s,
    scoreFormatter: value => `${Math.round(value * 100)} %`,
    tickFormatter: value => `${Math.round(value * 100)} %`
  };
};

export function calculateOverviewChart(participants, currentMetric, sortByScore, scoreMapping, scoreMappingMetrics) {
  const { scoreFormatter, scoreAmplifier, ticks, tickFormatter, domain } = determineValueTypeProps(
    participants[0],
    currentMetric
  );

  const weightedScoreExtractor = item => {
    const res = reduce(
      scoreMappingMetrics,
      ({ totalW, totalS }, { key }) => {
        const weight = extractWeightValue(scoreMapping, key);
        const metric = extractMetric(item, key) || { value: 0 };
        return {
          totalW: totalW + weight,
          totalS: totalS + weight * metric.value
        };
      },
      { totalW: 0, totalS: 0 }
    );
    const { scoreTotal } = item.result.metrics;
    if (!scoreTotal) {
      return {
        value: 0,
        formattedValue: 'N/A'
      };
    }
    const value = res.totalW === 0 ? scoreTotal.value : res.totalS / res.totalW;
    return {
      ...scoreTotal,
      value,
      formattedValue: scoreFormatter(value)
    };
  };

  const scoreExtractorFn =
    currentMetric.key !== 'scoreTotal' ? item => extractMetric(item, currentMetric.key) : weightedScoreExtractor;

  const shorten = label => {
    const maxLen = 18;
    if (label.length < maxLen) {
      return label;
    }
    return `${label.substring(0, maxLen - 3)}...`;
  };

  const dataConfig = reduce(
    participants,
    (res, p) => {
      if (!p.simulation.locked || (p.result && p.result.metrics)) {
        res.items.push(p);
        res.crossComparisonActive |= p.crossCompared;
        res.totalScore += scoreExtractorFn(p).value;
      }
      return res;
    },
    { items: [], crossComparisonActive: false, totalScore: 0 }
  );
  const scoreMean = dataConfig.totalScore / participants.length;
  const orderingScoreExtractorFn = sortByScore ? weightedScoreExtractor : scoreExtractorFn;
  const data = dataConfig.items
    .sort((a, b) => {
      const aScore = orderingScoreExtractorFn(a).value;
      const bScore = orderingScoreExtractorFn(b).value;
      if (aScore === bScore) {
        return 0;
      }
      return aScore > bScore ? -1 : 1;
    })
    .map((item, idx) => {
      const metric = scoreExtractorFn(item);
      const color = item.crossCompared
        ? item.crossComparedColor
        : dataConfig.crossComparisonActive
        ? 'rgba(0,0,0,0.24)'
        : metric.color;
      const name = item.participant ? extractParticipantNameConfig(item.participant).text : '';
      const shortName = shorten(name);
      return {
        name,
        shortName,
        score: scoreAmplifier(metric.value),
        formattedScore: metric.formattedValue,
        position: idx + 1,
        id: item.participant.id,
        color: colorBlendedWithWhite(color, 0.0),
        hackLabel: 'X',
        strokeColor: color,
        data: item
      };
    });
  return {
    data,
    domain,
    ticks,
    tickFormatter,
    scoreFormatter,
    scoreMean: scoreAmplifier(scoreMean)
  };
}

export function determineMetricDiscreteValueCount(m) {
  if (m.type === 'CATEGORY') {
    return 3;
  }
  switch (m.valueType) {
    case 'SEMI_STEN':
      return 5;
    case 'DISCRETE_FOUR':
      return 4;
    default:
      return 10;
  }
}

const COLLATOR = new Intl.Collator('cs');

function compare(a, b, coef = 1) {
  if (a === b) return 0;
  if (a === null) return 1;
  if (b === null) return -1;
  if (typeof a === 'string') {
    return coef * COLLATOR.compare(a, b);
  }
  return a < b ? coef * -1 : coef;
}

export function applyFilters(array, options) {
  const norm = val => (typeof val !== 'undefined' && val !== null ? val : null);
  const createSortFn = opts => {
    const asc = opts.order.toLowerCase() === 'asc';
    const coef = asc ? 1 : -1;
    const sorts = [];
    sorts.push({ prop: opts.sort, coef });
    if (opts.sort === EApplicantListSort.NAME) {
      sorts.push({ prop: 'participant.firstName', coef });
    }
    sorts.push({ prop: EApplicantListSort.POSITION, coef: coef * -1 });
    sorts.push({
      fn: (a, b) => {
        const aEs = get(a, 'entryStatus.status');
        const bEs = get(b, 'entryStatus.status');
        if (aEs === bEs) {
          return 0;
        }
        if (aEs === 'ACCEPTED' || bEs === 'REJECTED') {
          return -1;
        }
        if (bEs === 'ACCEPTED' || aEs === 'REJECTED') {
          return 1;
        }
        return 0;
      }
    });
    sorts.push({ prop: 'status', coef: 1 });
    sorts.push({ prop: 'statusTimestamp', coef: -1 });
    return (a, b) => {
      for (let i = 0; i < sorts.length; i += 1) {
        const sort = sorts[i];
        let cmpr = 0;
        if (sort.fn) {
          cmpr = sort.fn(a, b);
        } else {
          const valA = norm(get(a, sort.prop));
          const valB = norm(get(b, sort.prop));
          cmpr = compare(valA, valB, sort.coef);
        }
        if (cmpr !== 0) {
          return cmpr;
        }
      }
      return 0;
    };
  };
  const sortedArray = array.slice().sort(createSortFn(options));

  return slice(sortedArray, options.pageNumber * options.pageSize, (options.pageNumber + 1) * options.pageSize);
}

export function convertTextAreaContentToHTML(content) {
  if (!content) {
    return '';
  }
  return `<p>${content}</p>`.replace(/\r?\n/gi, '<br/>');
}

export function convertHTMLToTextAreaContent(html) {
  if (!html) {
    return '';
  }
  const newVal = html
    .replace(/&nbsp;/gi, ' ')
    .replace(/\r?\n/g, ' ') // remove artificial newlines
    .replace(/\s+/g, ' ') // more than two spaces replaced
    .split(/<br\s*\/?>/)
    .map(v => v.trim())
    .join('\n');
  const div = document.createElement('div');
  div.innerHTML = newVal;
  return (div.textContent || div.innerText || '').trim();
}

export function formatProjectTextTemplate(value, companyName, projectName) {
  const newVal = value
    .replace(/{companyName}/gi, companyName)
    // {positionName} kept for compatibility purposes
    .replace(/{positionName}/gi, projectName)
    .replace(/{projectName}/gi, projectName);
  return convertHTMLToTextAreaContent(newVal);
}

function parseDate(val) {
  return moment(val);
}

const findLastSelectedRow = selection => {
  let maxTs = null;
  let last = null;
  for (let prop in selection) {
    if (selection.hasOwnProperty(prop)) {
      const item = selection[prop];
      if (!maxTs || maxTs < item.ts) {
        maxTs = item.ts;
        last = { rowKey: prop, ...item };
      }
    }
  }
  return last;
};

const validateSelectionProps = p => {
  if (!p.ts) {
    throw new Error("Timestamp property 'ts' must be present!");
  }
  return p;
};

export const toggleListSelection = (selection, list, row, getRowKey, createSelectionProps, shift) => {
  const rowKey = getRowKey(row);
  const { [rowKey]: foundInSelection, ...newSelection } = selection;
  if (shift) {
    // cycle from last to current and (de)select all between
    const lastSelected = findLastSelectedRow(selection);
    const lastSelectedKey = lastSelected && lastSelected.rowKey;
    let started = false;
    for (let i = 0; i < list.length; i += 1) {
      const r = list[i];
      const rKey = getRowKey(r);
      const isBorderRow = rKey === rowKey || rKey === lastSelectedKey;
      const wasStarted = started;
      if (!started && isBorderRow) {
        started = true;
      }
      if (started) {
        if (foundInSelection) {
          delete newSelection[rKey];
        } else {
          newSelection[rKey] = validateSelectionProps(createSelectionProps(r));
        }
      }
      if (isBorderRow && (wasStarted || !lastSelectedKey || lastSelectedKey === rowKey)) {
        break;
      }
    }
  } else {
    if (!foundInSelection) {
      newSelection[rowKey] = validateSelectionProps(createSelectionProps(row));
    }
  }
  return newSelection;
};

export function parseValidity(start, end, alwaysOpen) {
  let validFrom = null;
  let validTo = null;
  if (!alwaysOpen) {
    validFrom = start ? parseDate(start).startOf('day').valueOf() : null;
    validTo = end ? parseDate(end).endOf('day').valueOf() : null;
  }
  return { validFrom, validTo };
}

export function hasFlag(auth, flag) {
  if (!auth || !auth.enabledFlags) {
    return false;
  }
  return auth.enabledFlags.indexOf(flag) > -1;
}

export function hasAnyAuthority(auth, ...authorities) {
  if (!auth || !auth.authorities) {
    return false;
  }
  return filter(authorities, a => auth.authorities.indexOf(a) !== -1).length > 0;
}

export function canAccessProjects(auth) {
  return hasAnyAuthority(auth, 'ADMIN', 'OPERATOR', 'ADMIN_LOCAL');
}

export function canAccessGroups(auth) {
  return hasAnyAuthority(auth, 'ADMIN', 'OPERATOR', 'ADMIN_LOCAL', 'ANALYST');
}

export function canAccessDesigner(auth) {
  return hasAnyAuthority(auth, 'ADMIN', 'DESIGNER');
}

export function isLimitedContractPlan(company) {
  return company && company.contractPlan && company.contractPlan.type === 'LIMITED';
}

export function anyUnlimitedContractPlan(companies) {
  return !!find(companies, c => !isLimitedContractPlan(c));
}

export function isFreeContractPlan(company) {
  return company && company.contractPlan && company.contractPlan.type === 'FREE';
}

export function isFreeAccount(companies) {
  return companies && companies.length === 1 && !!find(companies, c => isFreeContractPlan(c));
}

export function getAccountModes(auth, companies) {
  const isAdmin = hasAnyAuthority(auth, 'ADMIN', 'ADMIN_LOCAL');
  if (isAdmin) {
    return mapValues(EContractPlanMode, () => true);
  }
  return reduce(
    companies || [],
    (res, c) => {
      return mapValues(
        EContractPlanMode,
        ({ key }) => res[key] || !c.contractPlan || (c.contractPlan.modes && c.contractPlan.modes.indexOf(key) > -1)
      );
    },
    {}
  );
}
export function isGuideEnabled(companies) {
  return (
    companies &&
    !!find(
      companies,
      c =>
        isFreeContractPlan(c) &&
        c.contractPlan.modes &&
        c.contractPlan.modes.indexOf(EContractPlanMode.DEVELOPMENT.key) > -1
    )
  );
}

export function scoreMappingEqual(w0, w1) {
  w0 = w0 || {};
  w1 = w1 || {};
  return !find([...keys(w0), ...keys(w1)], key => checkForUpdate(extractWeight(w0, key), extractWeight(w1, key)));
}

/**
 * Selects the right message from the messages object selection based on the given quantity, e.g.:
 * messages = {
 *     "1": "1 item selected",
 *     ">1": "{0} items selected"
 * }
 * quantity = 5
 *
 * The function compares the quantity to expressions in the keys and returns the second message
 *
 * @param messages
 * @param quantity
 */
export function selectQuantityMessage(messages, quantity) {
  let anyMatchMessage = null;
  for (let expr in messages) {
    if (messages.hasOwnProperty(expr)) {
      // 4 types: single number ("5"), bounded interval ("1-4"), unbounded interval (">5"), or everything else "*"
      if (expr === '*') {
        anyMatchMessage = messages[expr];
      } else if (expr.indexOf('-') > 0) {
        const bounds = expr.split('-');
        if (parseInt(bounds[0], 10) <= quantity && quantity <= parseInt(bounds[1], 10)) {
          return messages[expr];
        }
      } else if (expr.indexOf('>') === 0) {
        const lowerBound = parseInt(expr.substr(1), 10);
        if (lowerBound < quantity) {
          return messages[expr];
        }
      } else if (!isNaN(expr) && quantity === parseInt(expr, 10)) {
        return messages[expr];
      }
    }
  }
  return anyMatchMessage;
}

export function formatMessage(message, args, html) {
  if (!args) {
    return message;
  }
  const r = /({\d+[^}]*})/;
  const d = /^({(\d+)(\[([^\]]+)])?})$/;
  return map(
    filter(message.split(r), s => s.length > 0),
    (part, idx) => {
      const res = d.exec(part);
      let val = part;
      if (res) {
        const i = parseInt(res[2], 10);
        val = typeof args === 'function' ? args(i, res[4]) : typeof args[i] === 'function' ? args[i](res[4]) : args[i];
      } else if (html) {
        return <span key={idx} dangerouslySetInnerHTML={{ __html: val }} />;
      }
      return <span key={idx}>{val}</span>;
    }
  );
}

export function formatDuration(seconds, omitUnit) {
  const min = Math.floor(seconds / 60);
  const sec = seconds - min * 60;
  const secS = sec < 10 ? `0${sec}` : sec;
  return `${min}:${secS}${omitUnit ? '' : ' min'}`;
}

function trimValues(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      const val = obj[prop];
      if (typeof val === 'string') {
        obj[prop] = val.trim();
      } else if (typeof val === 'object') {
        trimValues(val);
      }
    }
  }
}

export function b64ToObject(b64) {
  try {
    const json = atob(b64);
    const obj = JSON.parse(json);
    trimValues(obj);
    return obj;
  } catch (e) {
    //ignore
  }
  return null;
}

export function objectToB64(obj) {
  try {
    const json = JSON.stringify(obj);
    return btoa(json);
  } catch (e) {
    //ignore
  }
  return null;
}

//noinspection JSUnusedGlobalSymbols
export function checkForUpdate(props, nextProps, debug) {
  let res = false;
  for (let prop in props) {
    if (props.hasOwnProperty(prop)) {
      const eq = props[prop] === nextProps[prop];
      if (!eq) {
        if (debug) {
          console.log('NEQ', prop);
        }
        res = true;
        if (!debug) {
          return res;
        }
      }
    }
  }
  return res;
}

export const createCustomSplitter = (testRegex, splitRegex = /\s*(,|\s)\s*/) => {
  return (value, options) => {
    const { filterFunction, valueFactory } = options || {};
    const parts = value.split(splitRegex);
    if (!parts || parts.length === 0) {
      return { values: [], splitCount: 0 };
    }
    const values = [];
    let invalid = 0;
    for (let i = 0; i < parts.length; i += 1) {
      const v = parts[i];
      if (testRegex.test(v)) {
        if (!filterFunction || filterFunction(v)) {
          const val = valueFactory ? valueFactory(v) : v;
          values.push(val);
        }
      } else if (v.trim().length > 0) {
        invalid += 1;
      }
    }
    return { values: values, splitCount: parts.length, invalidCount: invalid };
  };
}

export const splitToEmails = createCustomSplitter(/^[^@]+@[^@]+\.[A-Za-z]{2,}$/);
export const splitToDomains = createCustomSplitter(/^[^@]+\.[A-Za-z]{2,}$/);

export function checkEqualProperties(a, b, propList) {
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  for (let i = 0; i < propList.length; i += 1) {
    const prop = propList[i];
    if (a[prop] !== b[prop]) {
      return false;
    }
  }
  return true;
}

export function projectToChatConfig(project) {
  if (!project) {
    return null;
  }
  const { chatConfig } = project;
  const { baseUrl, idProp } = chatConfig || {};
  return {
    accountId: project.company.slug,
    projectId: project.slug,
    baseUrl,
    idProp
  };
}

export const mirror = (arr, reverse) => {
  const res = [];
  const len = arr.length * 2;
  for (let i = 0; i < len; i += 1) {
    const idx = i >= arr.length ? len - i - 1 : i;
    res[i] = arr[reverse ? arr.length - idx - 1 : idx];
  }
  return res;
};

export const mirrorReversed = arr => mirror(arr, true);

export const formatPosition = (pos, locale) => {
  if (locale === 'en') {
    /*
     x0th
     x1st
     x2nd
     x3rd
     x4th
     x5th
     --but--
     x11th
     x12th
     x13th
     */
    if (9 < pos % 100 && pos % 100 < 20) {
      return `${pos}th`;
    }
    switch (pos % 10) {
      case 1:
        return `${pos}st`;
      case 2:
        return `${pos}nd`;
      case 3:
        return `${pos}rd`;
      default:
        return `${pos}th`;
    }
  }
  return `${pos}.`;
};

export const getProjectsBasePath = () => {
  return '/projects';
};

const getActivityProjectView = project => {
  return project.activity
    ? project
    : {
        ...project,
        activity: {
          key: project.gameDefinitionKey,
          variant: project.gameDefinitionVariant,
          version: project.gameDefinitionVersion
        }
      };
};

export const getProjectActivityMatcher = project => {
  const view = getActivityProjectView(project);
  for (let typeKey in EActivityType) {
    if (EActivityType.hasOwnProperty(typeKey)) {
      const type = EActivityType[typeKey];
      if (type.matches(view)) {
        if (type.variants) {
          for (let variantKey in type.variants) {
            if (type.variants.hasOwnProperty(variantKey)) {
              const variant = type.variants[variantKey];
              if (variant.matches(view)) {
                return variant;
              }
            }
          }
        }
        return type;
      }
    }
  }
  return { matches: () => true };
};

export const getProjectBaseUrl = () => {
  return '/projects';
};

export const extractNotificationPreferences = p => {
  const notifications = (p.userProjectData && p.userProjectData.notifications) || {};
  const { timestamp, count } = notifications.unfinished || {};
  const disabledUntil = timestamp ? timestamp + 2 * 24 * 60 * 60 * 1000 : null;
  const canNotify = !timestamp || disabledUntil < Date.now();
  return {
    canNotify,
    timestamp,
    count,
    disabledUntil
  };
};

export const objectToQueryString = obj => {
  const res = join(
    map(
      filter(keys(obj), key => obj[key] != null && obj[key] !== ''),
      key => {
        const val = obj[key];
        if (isArray(val)) {
          key = encodeURIComponent(key);
          return reduce(
            val,
            (str, item) => {
              return `${str}${str ? '&' : ''}${key}=${encodeURIComponent(item)}`;
            },
            ''
          );
        } else {
          key = encodeURIComponent(key);
          const param = encodeURIComponent(val);
          return `${key}=${param}`;
        }
      }
    ),
    '&'
  );
  if (res) {
    return `?${res}`;
  }
  return '';
};

// Asynchronous version of `setRouteLeaveHook`.
// Instead of synchronously returning a result, the hook is expected to
// return a promise.
export function setAsyncRouteLeaveHook(router, route, hook) {
  let withinHook = false;
  let finalResult;
  let finalResultSet = false;
  router.setRouteLeaveHook(route, nextLocation => {
    withinHook = true;
    if (!finalResultSet) {
      hook(nextLocation).then(result => {
        finalResult = result;
        finalResultSet = true;
        if (!withinHook && nextLocation) {
          // Re-schedule the navigation
          router.push(nextLocation);
        }
      });
    }
    const result = finalResultSet ? finalResult : false;
    withinHook = false;
    finalResult = undefined;
    finalResultSet = false;
    return result;
  });
}
