import React, { useState, useEffect } from 'react';

const TOTAL_TIMEOUT = 200;
const STEP_TIMEOUT = 20;
const STEPS = TOTAL_TIMEOUT / STEP_TIMEOUT;

const NumberCounterAnimation = props => {
  const { value } = props;
  const [shownValue, setShownValue] = useState(0);
  const [step, setStep] = useState(value / STEPS);

  useEffect(() => setStep((value - shownValue) / STEPS), [value]);
  useEffect(() => {
    const newValue = shownValue + step;
    const newShownValue = (step > 0 ? value > newValue : value < newValue) ? newValue : value;
    const timeout = setTimeout(() => setShownValue(newShownValue), STEP_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [value, shownValue, step]);

  return <span>{Math.round(shownValue)}</span>;
};

export default NumberCounterAnimation;
