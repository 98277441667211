import React from 'react';
import { Router as RRouter, Route, IndexRoute, IndexRedirect, Redirect, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import L from 'lodash';

import ErrorPage from '../components/error/ErrorPage';
import App from '../containers/App';
import allContainers from '../containers';

import { formatMessage } from '../utils/utils';
import { BRAND } from '../constants/constants';
import { EContractPlanMode, EContractPlanType } from '../constants/enum';

const notFound = containers => containers.NotFound;

const traverseRoutes = (parentProps = {}, nodes, fn) => {
  return L.flatMap(
    L.map(L.keys(nodes), key => {
      const absoluteKey = `${parentProps.key ? `${parentProps.key}.` : ''}${key}`;
      const routeDef = nodes[key];
      const { children } = routeDef;
      if (routeDef.disabled) {
        return [];
      }
      if (routeDef.index) {
        return fn({ ...routeDef, key: absoluteKey });
      }
      const paths = routeDef.path ? [routeDef.path] : routeDef.paths;
      return L.map(paths, (p, idx) => {
        const routeKey = `${absoluteKey}[${idx}]`;
        const absolutePath = parentProps.path ? `${parentProps.path === '/' ? '' : parentProps.path}/${p}` : p;
        const newRouteDef = {
          ...routeDef,
          absolutePath,
          key: routeKey,
          paths: null,
          path: null,
          relativePath: p
        };
        let flattenedChildren = [];
        if (newRouteDef.indexRedirect) {
          flattenedChildren = [fn(newRouteDef)];
        }
        if (children) {
          flattenedChildren = [
            ...flattenedChildren,
            ...traverseRoutes({ path: absolutePath, key: routeKey }, children, fn)
          ];
        }
        return fn({ ...newRouteDef, indexRedirect: null }, flattenedChildren);
      });
    })
  );
};

const renderRoutes = (nodes, containers) => {
  return traverseRoutes({}, nodes, (routeDef, children) => {
    const key = routeDef.key;
    if (routeDef.index) {
      return <IndexRoute key={key} component={routeDef.component(containers)} {...routeDef.props} />;
    } else if (routeDef.indexRedirect) {
      return <IndexRedirect key={key} to={routeDef.indexRedirect} {...routeDef.props} />;
    } else if (routeDef.redirect) {
      return <Redirect key={key} from={routeDef.relativePath} to={routeDef.redirect} {...routeDef.props} />;
    }
    return (
      <Route key={key} path={routeDef.relativePath} component={routeDef.component(containers)} {...routeDef.props}>
        {children}
      </Route>
    );
  });
};

const DelegatingComponent = props => {
  return props.children;
};

const getProjectsChildren = indexProps => {
  return {
    projects: {
      index: true,
      component: containers => containers.Projects,
      props: indexProps
    },
    newProject: {
      path: 'new',
      component: containers => containers.ProjectEditContainer
    },
    overviewParticipantsCompare: {
      path: 'overview/:companySlug/:projectSlug/participants/compare',
      component: containers => containers.ApplicantCompare
    },
    overviewParticipantDetail: {
      path: 'overview/:companySlug/:projectSlug/participants/:applicantId',
      component: containers => containers.ApplicantDetail
    },
    overview: {
      path: 'overview/:companySlug/:projectSlug',
      component: containers => containers.ProjectOverview
    },
    editProject: {
      path: ':companyId/:projectId/edit',
      component: containers => containers.ProjectEditContainer
    }
  };
};

const routes = {
  printParticipant: {
    path: '/print/projects/:companySlug/:projectSlug/participants',
    component: containers => containers.ApplicantPrint
  },
  printApplicant: {
    path: '/print/:companySlug/:projectSlug/applicants',
    component: containers => containers.ApplicantPrint
  },
  simulationDesigner: {
    path: '/designer',
    indexRedirect: 'simulations',
    component: containers => containers.SimulationDesigner,
    children: {
      modelList: {
        path: 'simulations',
        component: containers => containers.SimulationModelList
      },
      newSimulationModel: {
        path: 'simulations/new',
        component: containers => containers.SimulationModelEditor
      },
      editSimulationModel: {
        path: 'simulations/:modelId/edit',
        component: containers => containers.SimulationModelEditor
      }
    }
  },
  app: {
    path: '/',
    component: containers => containers.App,
    children: {
      login: {
        paths: ['login', 'logout'],
        component: containers => containers.Login,
        public: true
      },
      home: {
        path: 'home',
        component: containers => containers.HomeContainer
      },
      positions: {
        path: 'positions',
        redirect: '/'
      },
      projects: {
        path: 'projects',
        component: containers => containers.DelegatingModeBasedContainer,
        props: { modes: [EContractPlanMode.RECRUITMENT, EContractPlanMode.DEVELOPMENT] },
        children: getProjectsChildren()
      },
      resetPasswordEmail: {
        path: 'reset-password',
        component: containers => containers.ResetPasswordEmail,
        public: true
      },
      resetPassword: {
        path: 'user/password-reset',
        component: containers => containers.ResetPassword,
        public: true
      },
      accountNew: {
        path: 'account/new',
        component: containers => containers.AccountNew,
        public: true
      },
      accountTalentMarketNew: {
        path: 'account/talent-market/new',
        component: containers => containers.AccountNew,
        props: { planType: EContractPlanType.TALENT_MARKET.key },
        public: true
      },
      accountActivate: {
        path: 'account/activation',
        component: containers => containers.AccountActivation,
        public: true
      },
      sourcing: {
        path: 'sourcing',
        indexRedirect: 'opportunities',
        component: containers => containers.DelegatingModeBasedContainer,
        props: { mode: EContractPlanMode.SOURCING },
        children: {
          opportunities: {
            path: 'opportunities',
            component: () => DelegatingComponent,
            children: {
              opportunities: {
                index: true,
                component: containers => containers.OpportunitiesContainer
              },
              postCreate: {
                path: 'posts/new',
                component: containers => containers.OpportunityPostForm
              },
              postEdit: {
                path: 'posts/:postId/edit',
                component: containers => containers.OpportunityPostForm
              },
              opportunity: {
                path: ':opportunityId',
                component: containers => containers.Opportunity
              },
              talent: {
                path: ':opportunityId/participants/:participantId',
                component: containers => containers.TalentDetail
              }
            }
          },
          profile: {
            path: 'profile',
            component: () => DelegatingComponent,
            children: {
              profile: {
                index: true,
                component: containers => containers.CompanyProfileContainer
              },
              profileEdit: {
                path: ':companyId/edit',
                component: containers => containers.CompanyProfileForm
              }
            }
          }
        }
      },
      groups: {
        path: 'groups',
        component: () => DelegatingComponent,
        children: {
          groupsHome: {
            index: true,
            component: containers => containers.TeamsContainer
          },
          groupCreate: {
            path: 'new',
            component: containers => containers.TeamsContainer
          },
          groupEdit: {
            path: ':groupId',
            component: containers => containers.TeamsContainer
          }
        }
      },
      eapiKeys: {
        path: 'eapi/keys',
        component: () => DelegatingComponent,
        children: {
          keysHome: {
            index: true,
            component: containers => containers.EapiKeysContainer
          }
        }
      },
      companyPosition: {
        path: ':companySlug/:projectSlug',
        component: containers => containers.Project,
        children: {
          applicants: {
            path: 'applicants',
            component: containers => containers.ProjectOverview
          },
          applicantsCompare: {
            path: 'applicants/compare',
            component: containers => containers.ApplicantCompare
          },
          participantsCompare: {
            path: 'participants/compare',
            component: containers => containers.ApplicantCompare
          },
          applicantDetail: {
            path: 'applicants/:applicantId',
            component: containers => containers.ApplicantDetail
          },
          participantDetail: {
            path: 'participants/:applicantId',
            component: containers => containers.ApplicantDetail
          }
        }
      },
      asterisk: {
        path: '*',
        component: notFound
      },
      notFound: {
        path: 'not-found',
        component: notFound
      }
    }
  },
  notFound: {
    path: '*',
    component: notFound
  }
};

const publicRoutes = (() => {
  const result = [];
  traverseRoutes({}, routes, routeDef => {
    if (routeDef.public) {
      result.push(routeDef.absolutePath);
    }
  });
  return result;
})();

export const isPublic = ({ pathname }) => {
  return publicRoutes.indexOf(pathname) >= 0;
};

const Router = props => {
  if (props.apiDown) {
    const supportEmail = BRAND.email('support');
    return (
      <ErrorPage
        message={
          <span>
            {formatMessage(props.intl.messages.components.errors.apiDown, [
              BRAND.label,
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            ])}
          </span>
        }
        icon="build"
      />
    );
  }
  return (
    <RRouter {...props} history={browserHistory}>
      {renderRoutes(routes, { App, ...allContainers })}
    </RRouter>
  );
};

const mapStateToProps = ({ api: { down }, intl }) => ({ apiDown: down, intl });

export default connect(mapStateToProps)(Router);
