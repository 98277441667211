import * as Api from '../../api/entities/groupApi';
import { createApiCallAction } from '../apiCallAction';

import {
  GROUPS_GET,
  GROUP_GET,
  GROUP_CREATE,
  GROUP_UPDATE,
  GROUP_DELETE,
  GROUP_CURRENT_UNSET,
  GROUP_MEMBERS_GET,
  GROUP_MANAGERS_GET,
  GROUP_MEMBER_UPDATE,
  GROUP_MEMBER_DELETE,
  GROUP_MEMBER_CANDIDATES_GET,
  GROUP_MEMBER_SUGGESTED_CANDIDATES_GET,
  GROUP_TARGET_GET,
  GROUP_TARGET_UNSET,
  GROUP_SUBGROUP_SORT_SET,
  GROUP_SOURCES_GET
} from '../../constants/constants';
import { EGroupType, EGroupMembership } from '../../constants/enum';

const getGroupsAction = createApiCallAction(GROUPS_GET, Api.getGroups);
export const getGroups = (companyId, pagination) => getGroupsAction(EGroupType.TEAM.key, companyId, null, pagination);

const getGroupAction = createApiCallAction(GROUP_GET, Api.getGroup, { transformArgsToMeta: ([id]) => ({ id }) });
export const getGroup = id => getGroupAction(id);

const createGroupAction = createApiCallAction(GROUP_CREATE, Api.createGroup);
export const createGroup = group => createGroupAction(group);

const updateGroupAction = createApiCallAction(GROUP_UPDATE, Api.updateGroup);
export const updateGroup = (id, group) => updateGroupAction(id, group);

const deleteGroupAction = createApiCallAction(GROUP_DELETE, Api.deleteGroup);
export const deleteGroup = id => deleteGroupAction(id);

export const unsetCurrentGroup = () => ({ type: GROUP_CURRENT_UNSET });

// members
const getGroupMembersAction = createApiCallAction(GROUP_MEMBERS_GET, Api.getGroupMembers);
export const getGroupMembers = (id, lang, pagination) =>
  getGroupMembersAction(id, EGroupMembership.MEMBER.key, lang, pagination);

const getGroupManagersAction = createApiCallAction(GROUP_MANAGERS_GET, Api.getGroupMembers);
export const getGroupManagers = (id, lang, pagination) =>
  getGroupManagersAction(id, EGroupMembership.MANAGER.key, lang, pagination);

const updateGroupMemberAction = createApiCallAction(GROUP_MEMBER_UPDATE, Api.updateGroupMember);
export const updateGroupMember = (groupId, userId, data) => updateGroupMemberAction(groupId, userId, data);

const deleteGroupMemberAction = createApiCallAction(GROUP_MEMBER_DELETE, Api.deleteGroupMember);
export const deleteGroupMember = (groupId, userId) => deleteGroupMemberAction(groupId, userId);

const getGroupMemberCandidatesAction = createApiCallAction(GROUP_MEMBER_CANDIDATES_GET, Api.getGroupMemberCandidates);
export const getGroupMemberCandidates = (groupId, search, lang, pagination) =>
  getGroupMemberCandidatesAction(groupId, search, lang, false, pagination);

const getGroupMemberSuggestedCandidatesAction = createApiCallAction(
  GROUP_MEMBER_SUGGESTED_CANDIDATES_GET,
  Api.getGroupMemberCandidates
);
export const getGroupMemberSuggestedCandidates = (groupId, lang, pagination) =>
  getGroupMemberSuggestedCandidatesAction(groupId, null, lang, true, pagination);

// target group
export const getGroupTarget = (groupId, lang) => dispatch => {
  dispatch({ type: GROUP_TARGET_GET.PENDING });
  Promise.all([
    Api.getGroup(groupId),
    Api.getGroupMembers(groupId, EGroupMembership.MANAGER.key, lang, { page: 0 }),
    Api.getGroupMembers(groupId, EGroupMembership.MEMBER.key, lang, { page: 0 })
  ])
    .then(([group, managers, members]) => {
      dispatch({ type: GROUP_TARGET_GET.SUCCESS, payload: { group: group.payload, members, managers } });
    })
    .catch(() => {
      dispatch({ type: GROUP_TARGET_GET.ERROR });
    });
};

export const unsetGroupTarget = () => ({ type: GROUP_TARGET_UNSET });

export const setSubGroupSort = (type, sort) => ({ type: GROUP_SUBGROUP_SORT_SET, payload: { type, sort } });

const getGroupSourcesAction = createApiCallAction(GROUP_SOURCES_GET, Api.getGroupSources);
export const getGroupSources = groupId => getGroupSourcesAction(groupId);
