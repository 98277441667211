import * as Api from '../../api/entities/insightApi';
import {
  INSIGHTS_GET,
  INSIGHTS_UNSET,
  INSIGHT_UNSET,
  INSIGHT_GET,
  INSIGHT_DELETE,
  INSIGHT_UPDATE,
  INSIGHT_CREATE
} from '../../constants/constants';
import { createApiCallAction } from '../apiCallAction';

export const getInsights = createApiCallAction(INSIGHTS_GET, Api.getInsights);
export const unsetInsights = () => ({ type: INSIGHTS_UNSET });
export const getInsight = createApiCallAction(INSIGHT_GET, Api.getInsight);
export const unsetInsight = () => ({ type: INSIGHT_UNSET });
export const deleteInsight = createApiCallAction(INSIGHT_DELETE, Api.deleteInsight);
export const createInsight = createApiCallAction(INSIGHT_CREATE, Api.createInsight);
export const updateInsight = createApiCallAction(INSIGHT_UPDATE, Api.updateInsight);
