import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import moment from 'moment';

import Button from '../mui/Button';
import IconToggle from '../basic/IconToggle';
import IconToggleGroup from '../basic/IconToggleGroup';
import Tooltip from '../tooltip/Tooltip';
import ActivityLabel from '../activity/ActivityLabel';
import MetricsCellContent from '../applicant/cells/MetricsCellContent';
import InitialsAvatar from '../applicant/InitialsAvatar';
import { GridCell } from '../grid';
import ParticipantName from '../basic/ParticipantName';
import FinishedCellContent from '../applicant/cells/FinishedCellContent';
import GroupUserTags from '../applicant/GroupUserTags';
import BacksideToggle from '../mui/BacksideToggle';
import FitRankLabel from './FitRankLabel';
import MetricsStats from './MetricsStats';
import NumberIndicator from './NumberIndicator';
import FormatNumber from '../basic/FormatNumber';

import { getActivityMapKey, getActivityMetricDefinitions } from './TeamUtils';
import { formatMessage } from '../../utils/utils';
import { EGroupType } from '../../constants/enum';
import InfoTooltip from '../basic/InfoTooltip';
import TeamMemberRowActivityTooltipContent from './TeamMemberRowActivityTooltipContent';

const STYLES = {
  metricsCell: { padding: '4px 0 0 4px', overflow: 'visible', whiteSpace: 'normal' },
  finishedCell: { textAlign: 'right' }
};

const getFit = s => s.fit;

const renderContrib = m => {
  const c = m.contrib;
  const virtuallyZero = Math.round(Math.abs(c) * 100) <= 0;
  const iconColorClass = virtuallyZero ? 'text-muted' : c > 0 ? 'text-secondary' : 'text-highlight';
  return (
    <span className={virtuallyZero ? 'text-muted' : ''}>
      <span style={{ verticalAlign: 'middle', lineHeight: '1em' }}>
        {
          <span className={iconColorClass} style={{ fontFamily: 'monospace' }}>
            {c > 0 || virtuallyZero ? '+' : '-'}
          </span>
        }
        <FormatNumber value={Math.abs(c) * 10} fractionDigits={1} />
      </span>
      <i className={`material-icons text-sz-reg ${iconColorClass}`} style={{ verticalAlign: 'middle ' }}>
        {virtuallyZero ? 'remove' : c > 0 ? 'north_east' : 'south_east'}
      </i>
    </span>
  );
};

const ActivityStats = props => {
  const {
    activity: { stats },
    metrics: { scoreTotal },
    messages
  } = props;
  return (
    <div
      className="mui-padded mui-padded-horizontal-x2 bg-primary-light border-radius border-color-primary__50 mui-margin-bottom mui-appear"
      style={{ borderLeft: '2px solid' }}
    >
      {scoreTotal && (
        <NumberIndicator
          rankLabel={messages.totalScoreLabel}
          number={scoreTotal.value * 100}
          numberLabel={scoreTotal.formattedValue}
          present
          colorVariant="highlight"
        >
          {stats && stats.metricStats && (
            <div className="mui-padded-vertical-half">
              <MetricsStats metrics={stats.metricStats} short colorVariant="highlight" renderStat={renderContrib} />
            </div>
          )}
        </NumberIndicator>
      )}
      {stats && stats.fit != null && (
        <NumberIndicator
          rankLabel={
            <div>
              {messages.fitLabel}: <FitRankLabel value={stats.fit} />
            </div>
          }
          number={100 * stats.fit}
          present
          colorVariant="primary"
        >
          <div className="mui-padded-vertical-half">
            <MetricsStats metrics={stats.fits} short colorVariant="primary" getStat={getFit} />
          </div>
        </NumberIndicator>
      )}
      {!scoreTotal && !(stats && stats.fit != null) && <NumberIndicator fallback={<em>N/A</em>} />}
    </div>
  );
};

const TeamMemberRow = props => {
  const {
    intl,
    selected,
    activities,
    member,
    group,
    highlightable,
    highlighted,
    configuration,
    statsShown,
    columnWidths
  } = props;
  const { onRemove, onHighlight, onSelect, onInvite, onRemind, onShowProfile, setColumnWidth } = props;
  const messages = intl.messages.components.teams.memberRow;
  const statsActive = statsShown && group.type === EGroupType.HIRING.key;
  const readOnly = group?.readOnly;
  const renderActivityStatus = a => {
    const mapKey = getActivityMapKey(a);
    const activity = member.meta.activities.map[mapKey];
    let label;
    let icon;
    let onClick = e => e.preventDefault();
    let href;
    let disabled = false;
    let tooltip = null;
    let metrics = null;
    let metricDefinitions = null;
    if (activity) {
      if (activity.finishedAt) {
        const { result } = activity || {};
        metrics = (result || {}).metrics;
        if (!metrics) {
          return null;
        }
        metricDefinitions = getActivityMetricDefinitions(a, configuration);
        onClick = e => {
          e.preventDefault();
          onShowProfile(member, activity);
        };
        const { project } = activity;
        href = `/projects/overview/${project.company.slug}/${project.slug}/participants/${member.id}`;
        icon = 'check';
      } else {
        label = messages.remindLabel;
        icon = 'notifications';
        const { canNotify, disabledUntil } = activity.notificationPreferences || {};
        if (canNotify) {
          disabled = false;
          onClick = () => onRemind(member, [activity], member.meta.activities.notifiable);
        } else {
          disabled = true;
          tooltip = formatMessage(messages.remindDisabled, [moment(disabledUntil).locale(intl.locale).format('L LT')]);
        }
      }
    } else {
      label = messages.inviteLabel;
      icon = 'email';
      onClick = () => onInvite(member, [a], member.meta.activities.invitable);
    }
    if (readOnly) {
      disabled = true;
    }
    const colId = `col-${mapKey}-${statsActive ? 't' : 'f'}`;
    return (
      <div
        key={colId}
        className="container-flex-column"
        ref={el => el && setColumnWidth(colId, el.clientWidth)}
        style={{ padding: '4px 16px 0 0', minWidth: columnWidths[colId] }}
      >
        <InfoTooltip
          content={activity ? <TeamMemberRowActivityTooltipContent readOnly={readOnly} activity={activity} /> : null}
          delay={300}
        >
          <div>
            <div className="small mui-margin-right" style={{ marginBottom: '6px' }}>
              <ActivityLabel activity={a} />
            </div>
            {metrics ? (
              <div>
                {statsActive ? (
                  <ActivityStats activity={activity} metrics={metrics} messages={messages.activityStats} />
                ) : (
                  <MetricsCellContent metricDefinitions={metricDefinitions} metrics={metrics} />
                )}
                <div className="mui-margin-bottom hidden-print">
                  <a role="button" className="link-button heavy" onClick={onClick} href={href}>
                    <span className="text-uppercase text-sz-xs">{messages.profileLabel}</span>
                    <i className="material-icons">arrow_right</i>
                  </a>
                </div>
              </div>
            ) : (
              <div className="mui-margin-bottom mui-margin-right" style={{ paddingBottom: '26px' }}>
                <div className="tooltip-element border-radius border border-color-black__100">
                  <Button
                    label={label}
                    onClick={onClick}
                    icon={
                      icon && (
                        <i className="material-icons" style={{ fontSize: '18px' }}>
                          {icon}
                        </i>
                      )
                    }
                    style={{ width: '100%' }}
                    labelStyle={{ fontSize: '12px' }}
                    disabled={disabled}
                  />
                  {tooltip && <Tooltip position={'bottom'}>{tooltip}</Tooltip>}
                </div>
              </div>
            )}
          </div>
        </InfoTooltip>
      </div>
    );
  };
  const highlightColor = highlighted;
  const anyActivityFinished = member.meta.activities.finished.length > 0;
  const lastFinishedTimestamp = member.meta.lastFinishedTimestamp;
  const toggles = [];
  if ((highlightable && anyActivityFinished) || highlighted) {
    toggles.push(
      <IconToggle
        key={'highlight'}
        icon={'lightbulb_outline'}
        onToggle={() => onHighlight(member)}
        active={!!highlighted}
        tooltip={messages.highlightTooltip}
      />
    );
  }
  return (
    <div className="container-flex-row relative-container">
      {highlightColor && (
        <div
          className="team__subgroup-member-row-highlight-indicator"
          style={{
            backgroundColor: highlightColor,
            background: `linear-gradient(45deg, transparent -200%, ${highlightColor})`
          }}
        />
      )}
      {/* Content */}
      <div className="flex1">
        <GridCell xs={24} sm={16} md={8} style={{ padding: '8px 8px', overflow: 'visible' }}>
          <div className="container-flex-row ai-center">
            <div className="mui-padded-half">
              <BacksideToggle selected={selected} onToggle={e => onSelect(e, member)}>
                <InitialsAvatar
                  size={32}
                  firstName={member.firstName}
                  lastName={member.lastName}
                  identifier={member.id}
                />
              </BacksideToggle>
            </div>
            <div className="mui-padded-half mui-padded-horizontal">
              <ParticipantName variant="medium" {...member} />
            </div>
          </div>
          {!readOnly && (
            <div style={{ marginBottom: '-4px' }}>
              <GroupUserTags group={group} userId={member.id} tags={member.tags} />
            </div>
          )}
        </GridCell>
        {!statsActive && (
          <GridCell xs={24} sm={8} mdHide lgHide style={STYLES.finishedCell}>
            {lastFinishedTimestamp && (
              <FinishedCellContent finishedTimestamp={lastFinishedTimestamp} locale={intl.locale} />
            )}
          </GridCell>
        )}
        <GridCell xs={24} md={statsActive ? 16 : 12} style={STYLES.metricsCell}>
          <div className="container-flex-row fw-yes">{_map(activities, renderActivityStatus)}</div>
        </GridCell>
        {!statsActive && (
          <GridCell xsHide smHide md={4} style={STYLES.finishedCell}>
            {lastFinishedTimestamp && (
              <FinishedCellContent finishedTimestamp={lastFinishedTimestamp} locale={intl.locale} />
            )}
          </GridCell>
        )}
      </div>
      {/* Tools */}
      <div className={'mui-padded'}>
        <IconToggleGroup vertical>
          {!readOnly && (
            <IconToggle icon={'clear'} onToggle={() => onRemove([member])} tooltip={messages.removeTooltip} />
          )}
          {toggles}
        </IconToggleGroup>
      </div>
    </div>
  );
};

export default connect(({ intl }) => ({ intl }))(TeamMemberRow);
