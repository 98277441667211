import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import allActions from '../../../actions';
import ApplicantDetailPage from '../../../components/pages/private/position/ApplicantDetail';
import { getCurrentParticipant } from '../../../selectors/applicantDetail';
import { getProjectsBasePath } from '../../../utils/utils';

class ApplicantDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentActivityIndex: 0
    };
  }

  componentDidMount() {
    this.loadResult(this.props);
  }

  componentWillUnmount() {
    const { refreshApplicant } = this.props;

    refreshApplicant();
  }

  componentDidUpdate(prevProps) {
    const { applicant, intl: { locale }, setUserProjectAck } = this.props;
    if (
      applicant &&
      applicant.status &&
      !applicant.ackSent &&
      (applicant.ack === null || applicant.ack.status !== applicant.status)
    ) {
      setUserProjectAck(applicant.participant.id, applicant.projectId, applicant.status, applicant.statusTimestamp);
    }
    if (prevProps.intl.locale !== locale) {
      this.loadResult(this.props);
    }
  }

  loadResult = props => {
    const { params, paramsOverride, intl: { locale }, singleActivity } = props;
    const { getResult } = props;
    const { companySlug, projectSlug, applicantId } = paramsOverride || params;
    getResult(companySlug, projectSlug, applicantId, locale, !singleActivity);
  };

  getBasePath = () => {
    const { location: { pathname } } = this.props;
    return getProjectsBasePath(pathname);
  };

  handleCompare = () => {
    const { router, params: { companySlug, projectSlug, applicantId } } = this.props;
    router.push({
      pathname: `${this.getBasePath()}/overview/${companySlug}/${projectSlug}/participants/compare`,
      query: { ids: [applicantId] }
    });
  };

  handleSetCurrentActivityIndex = idx => this.setState({ currentActivityIndex: idx });

  render() {
    const {
      chart,
      auth,
      currentParticipant,
      setActiveChart,
      setToggleExpanded,
      setChartMode,
      setChartProperty,
      getApplicantEvaluationDescription,
      removeEvaluationDescription,
      invokeUpgradeAccountModal,
      disableStickyHeader
    } = this.props;
    const { currentActivityIndex } = this.state;
    if (!currentParticipant) {
      return null;
    }
    const { location: { query: { positionHidden } } } = this.props;
    const pageProps = {
      ...currentParticipant,
      auth,
      chart,
      positionShown: !positionHidden,
      currentActivityIndex,
      disableStickyHeader
    };
    const pageActions = {
      getApplicantEvaluationDescription,
      handleCompare: this.handleCompare,
      handleSetCurrentActivityIndex: this.handleSetCurrentActivityIndex,
      invokeUpgradeAccountModal,
      setActiveChart,
      setToggleExpanded,
      setChartMode,
      setChartProperty,
      removeEvaluationDescription
    };
    return <ApplicantDetailPage {...pageProps} {...pageActions} />;
  }
}

ApplicantDetail.propTypes = {
  currentApplicant: PropTypes.object,
  router: PropTypes.object,
  intl: PropTypes.object,
  chart: PropTypes.object,
  setActiveChart: PropTypes.func,
  setToggleExpanded: PropTypes.func,
  setChartMode: PropTypes.func,
  setChartProperty: PropTypes.func,
  refreshApplicant: PropTypes.func,
  getApplicant: PropTypes.func,
  getApplicantEvaluationDescription: PropTypes.func,
  removeEvaluationDescription: PropTypes.func,
  invokeUpgradeAccountModal: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { auth, intl, options: { chart }, entities: { applicant } } = state;
  return {
    auth,
    intl,
    chart,
    applicant,
    paramsOverride: ownProps.params,
    currentParticipant: getCurrentParticipant(state, ownProps)
  };
};

export default connect(mapStateToProps, allActions)(withRouter(ApplicantDetail));
