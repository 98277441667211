import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import Sticky from 'react-stickynode';

import Checkbox from 'material-ui/Checkbox';

import TeamMemberRow from './TeamMemberRow';
import TeamCompetencyAnalytics from './TeamCompetencyAnalytics';
import ManagerCompetencyAnalytics from './ManagerCompetencyAnalytics';
import Chip from '../mui/Chip';
import Button from '../mui/Button';
import SelectionCountMessage from '../basic/messages/SelectionCountMessage';
import { Grid, GridBody, GridPaginator, GridRow } from '../grid';
import Collapse from '../basic/Collapse';
import TeamSubGroupSelectionMenu from './TeamSubGroupSelectionMenu';
import TeamSubGroupHeaderRow from './TeamSubGroupHeaderRow';
import MembersCountMessage from './MembersCountMessage';

import { HEADER_SELECTOR } from '../../constants/constants';
import { EGroupMembership, EGroupType } from '../../constants/enum';
import { ETab } from './Constants';
import { getActivityMapKey, saveSubgroupState, getSubgroupState, filterWellBeing } from './TeamUtils';
import { formatMessage, toggleListSelection } from '../../utils/utils';

const _mapPaginated = (items, paginationOptions, transformFn) => {
  const { pageSize, pageNumber } = paginationOptions;
  const res = [];
  for (let i = pageSize * pageNumber; i < Math.min(items.length, pageSize * (pageNumber + 1)); i += 1) {
    const item = items[i];
    res.push(transformFn(item, i, items, paginationOptions));
  }
  return res;
};

const Suggestions = props => {
  const { items, messages, onAdd } = props;
  if (items.length <= 0) {
    //noinspection JSConstructorReturnsPrimitive
    return null;
  }
  return (
    <div className="mui-padded">
      <div className="mui-label">{messages.suggestedLabel}</div>
      <div>
        {_map(items, sm => (
          <div key={sm.id} className="mui-chip-container">
            <Chip variant="compact" iconLeft="add" onClick={() => onAdd([sm])}>
              {sm.lastName && sm.firstName ? `${sm.lastName} ${sm.firstName}` : sm.email}
            </Chip>
          </div>
        ))}
      </div>
    </div>
  );
};

const initialProps = {
  paginationOptions: { pageNumber: 0, pageSize: 20, totalFilteredElements: 0, totalPages: 0 },
  selected: {},
  statsShown: true,
  columnWidths: {}
};

class TeamSubGroup extends React.Component {
  state = initialProps;

  componentDidMount() {
    this.loadState();
    this.updatePagination();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.team !== prevProps.team) {
      this.loadState();
    } else {
      this.saveState();
    }
    if (this.props.items !== prevProps.items) {
      this.updatePagination();
    }
    if (this.props.items !== prevProps.items || this.state.selected !== prevState.selected) {
      this.updateSelectionCount();
    }
  }

  updateSelectionCount = () => {
    const { selected } = this.state;
    const { items } = this.props;
    const count = selected ? _reduce(items, (res, item) => res + (selected[item.id] ? 1 : 0), 0) : 0;
    this.setState({ selectedCount: count });
  };

  updatePagination = () => {
    const { items } = this.props;
    this.setState(({ paginationOptions }) => {
      const size = paginationOptions.pageSize;
      return {
        paginationOptions: {
          ...paginationOptions,
          pageNumber: 0,
          totalFilteredElements: items.length,
          totalPages: Math.ceil(items.length / size)
        }
      };
    });
  };

  loadState = () => {
    const { team, type } = this.props;
    if (team && type) {
      const state = getSubgroupState(team, type);
      this.setState({ ...initialProps, ...state });
    }
  };

  saveState = () => {
    const { team, type } = this.props;
    if (team && type) {
      const { columnWidths, ...state } = this.state;
      saveSubgroupState(team, type, state);
    }
  };

  getActivities = () => {
    const { team, type } = this.props;
    return _get(team, `data.activityConfigurations[${type.key}].activities`) || [];
  };

  handleInviteMember = (m, preselectedActivities, activitiesToInvite) => {
    const { onInvite } = this.props;
    onInvite([m], preselectedActivities, activitiesToInvite);
  };

  handleRemindMember = (m, preselectedActivities, activitiesToRemind) => {
    const { onRemind } = this.props;
    const projectMap = _reduce(
      activitiesToRemind || preselectedActivities,
      (res, act) => {
        const actKey = getActivityMapKey(act);
        const memberKey = m.id;
        const prev = res[memberKey] || {};
        prev[actKey] = act.project;
        res[memberKey] = prev;
        return res;
      },
      {}
    );
    onRemind([m], preselectedActivities, activitiesToRemind, projectMap);
  };

  handlePageNumber = num => {
    const { paginationOptions } = this.state;
    this.setState({ paginationOptions: { ...paginationOptions, pageNumber: num } });
  };
  handlePageSize = size => {
    const { paginationOptions } = this.state;
    const { totalFilteredElements } = paginationOptions;
    this.setState({
      paginationOptions: {
        ...paginationOptions,
        pageNumber: 0,
        pageSize: size,
        totalPages: Math.ceil(totalFilteredElements / size)
      }
    });
  };
  handleSelectedToggle = (e, m) => {
    const selected = toggleListSelection(
      this.state.selected,
      this.props.filtered,
      m,
      m => m.id,
      () => ({ ts: Date.now() }),
      e?.nativeEvent?.shiftKey
    );
    this.setState({ selected });
  };
  handleDeselectAll = () => {
    this.setState({ selected: {} });
  };

  performSelectionAction = action => {
    const { filtered } = this.props;
    const { selected } = this.state;
    const selection = [];
    for (let i = 0; i < filtered.length; i += 1) {
      const item = filtered[i];
      if (selected[item.id]) {
        selection.push(item);
      }
    }
    action(selection);
  };

  handleSelectionHighlight = () => this.performSelectionAction(this.props.onToggleHighlighted);
  handleSelectionRemove = () => this.performSelectionAction(this.props.onRemove);
  handleSelectionGroupCreate = () => this.performSelectionAction(this.props.onGroupCreate);
  handleSelectionCopy = () => this.performSelectionAction(this.props.onCopy);
  handleSelectionMove = () => this.performSelectionAction(this.props.onMove);
  handleSelectionTag = () => this.performSelectionAction(this.props.onTag);

  handleStatsShownToggle = () => this.setState(({ statsShown }) => ({ statsShown: !statsShown }));

  handleSortPropChange = (property, order) => this.props.onSortSet({ property, order });

  setColumnWidth = (id, w) => {
    const { ...columnWidths } = this._colWidths || {};
    const width = columnWidths[id];
    if (!width || width < w) {
      columnWidths[id] = w;
      this._colWidths = columnWidths;
      if (this._colWidthsTimeout) {
        clearTimeout(this._colWidthsTimeout);
      }
      this._colWidthsTimeout = setTimeout(() => {
        const cws = this.state.columnWidths;
        let changed = false;
        for (let prop in columnWidths) {
          if (columnWidths.hasOwnProperty(prop)) {
            if (!cws || columnWidths[prop] !== cws[prop]) {
              changed = true;
              break;
            }
          }
        }
        if (changed) {
          this.setState({ columnWidths });
        }
      }, 100);
    }
  };

  render() {
    const {
      team,
      items,
      sortProps,
      filtered,
      type,
      groupType,
      payload,
      suggestedItems,
      single,
      manager,
      expanded,
      highlighted,
      sort,
      intl,
      currentTab
    } = this.props;
    const messages = intl.messages.components.teams.subGroup;
    const { configuration } = payload || {};
    const { onAdd, onAddStart, onRemove, onShowProfile, onToggleExpanded, onToggleHighlighted } = this.props;
    const { selected, selectedCount, statsShown, columnWidths } = this.state;
    const paginationOptions = this.state.paginationOptions || initialProps.paginationOptions;
    const listCollapsed = currentTab !== ETab.LIST.key;
    const wellBeingCollapsed = currentTab !== ETab.WELL_BEING.key;
    const interpretationsCollapsed = wellBeingCollapsed;
    const activities = this.getActivities();
    const readOnly = team?.readOnly;
    const commonAnalyticsProperties = {
      team,
      type,
      groupType,
      activities: wellBeingCollapsed ? activities : filterWellBeing(activities),
      configuration,
      readOnly,
      interpretationsShown: !interpretationsCollapsed,
      chartsShown: !wellBeingCollapsed,
      onAdd: onAddStart,
      onInvite: this.handleInviteMember,
      onRemind: this.handleRemindMember,
      onShowProfile
    };
    const id = `teamSubgroup_${type.key}`;
    const titleMessages = messages.titles[type.key] || {};
    const title = titleMessages.variants
      ? titleMessages.variants[groupType.key] || titleMessages.title
      : titleMessages.title;

    return (
      <div className={`team__subgroup ${type.key}`} id={id}>
        <div className="team__subgroup-panel">
          <Sticky top={HEADER_SELECTOR} innerZ={100} bottomBoundary={`#${id}`}>
            <div className="team__subgroup-header">
              <div className="team__subgroup-header-deco" />
              <div className="container-flex-row ai-center jc-space-between">
                <div className="container-flex-row ai-center">
                  <h4 style={{ marginRight: '12px' }}>{title}</h4>
                  {!readOnly &&
                    (single ? (
                      items.length > 0 ? (
                        <a role="button" className="link-button" onClick={onAddStart}>
                          <i className="material-icons">autorenew</i>
                          <span>{messages.changeMemberLabel}</span>
                        </a>
                      ) : (
                        <a role="button" className="link-button" onClick={onAddStart}>
                          <i className="material-icons">add</i>
                          <span>{messages.setMemberLabel}</span>
                        </a>
                      )
                    ) : (
                      <a role="button" className="link-button" onClick={onAddStart}>
                        <i className="material-icons">add</i>
                        <span>{messages.addMemberLabel}</span>
                      </a>
                    ))}
                </div>
              </div>

              {items.length > 0 && (!single || listCollapsed) && (
                <div className="team__subgroup-member-count">
                  <span className="text-muted" style={{ fontSize: '10px' }}>
                    {single ? (
                      <span>
                        {items[0].lastName && items[0].firstName
                          ? `${items[0].lastName} ${items[0].firstName}`
                          : items[0].email}
                      </span>
                    ) : (
                      <MembersCountMessage type={groupType.key} count={items.length} />
                    )}
                  </span>
                </div>
              )}
              <Collapse isOpened={selectedCount > 0} unmountClosed>
                <div className="mui-padded-half border-radius container-flex-row ai-center fw-yes">
                  <div className="mui-padded-horizontal text-muted text-sz-reg-sm flex1">
                    <Checkbox
                      checked
                      label={selectedCount > 0 ? <SelectionCountMessage count={selectedCount} /> : undefined}
                      labelStyle={{ margin: 0, fontWeight: 400 }}
                      onCheck={this.handleDeselectAll}
                    />
                  </div>
                  <TeamSubGroupSelectionMenu
                    readOnly={readOnly}
                    onHighlight={this.handleSelectionHighlight}
                    onRemove={this.handleSelectionRemove}
                    onGroupCreate={this.handleSelectionGroupCreate}
                    onCopy={this.handleSelectionCopy}
                    onMove={this.handleSelectionMove}
                    onTag={this.handleSelectionTag}
                  />
                </div>
              </Collapse>
            </div>
          </Sticky>
        </div>
        {!listCollapsed && (
          <div style={single ? {} : { minHeight: '520px' }}>
            {items.length > 0 ? (
              <div>
                <Grid>
                  {!single && (
                    <TeamSubGroupHeaderRow
                      activities={activities}
                      sortProps={sortProps}
                      sort={sort}
                      statsShown={statsShown}
                      onStatsShownToggle={groupType.key === EGroupType.HIRING.key ? this.handleStatsShownToggle : null}
                      onSort={this.handleSortPropChange}
                    />
                  )}
                  <GridBody>
                    {_mapPaginated(filtered, single ? { pageSize: 1, pageNumber: 0 } : paginationOptions, m => (
                      <GridRow
                        key={m.id}
                        className={`unselectable border-color-primary__50 ${
                          selected[m.id] ? 'bg-highlight-light' : ''
                        }`}
                      >
                        <TeamMemberRow
                          key={m.id}
                          activities={activities}
                          member={m}
                          group={team}
                          onRemove={onRemove}
                          highlightable={type.key === EGroupMembership.MEMBER.key}
                          onHighlight={onToggleHighlighted}
                          selected={!!selected[m.id]}
                          highlighted={highlighted[m.id]}
                          onShowProfile={onShowProfile}
                          onExpand={onToggleExpanded}
                          onInvite={this.handleInviteMember}
                          onRemind={this.handleRemindMember}
                          expanded={expanded[m.id]}
                          statsShown={groupType.key === EGroupType.HIRING.key && statsShown}
                          configuration={configuration}
                          onSelect={this.handleSelectedToggle}
                          setColumnWidth={this.setColumnWidth}
                          columnWidths={columnWidths}
                        />
                      </GridRow>
                    ))}
                  </GridBody>
                </Grid>
                {!single && (
                  <div className="team__subgroup-members-footer container-flex-row fw-yes jc-space-between ai-start">
                    <div style={{ flex: '1 1 200px' }}>
                      {!readOnly && <Suggestions items={suggestedItems} messages={messages} onAdd={onAdd} />}
                    </div>
                    <div className="mui-padded text-right">
                      <GridPaginator
                        onPageSize={this.handlePageSize}
                        onPageNumber={this.handlePageNumber}
                        options={paginationOptions}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center text-muted mui-padded-x2">
                <div className="mui-padded">
                  {formatMessage(
                    single
                      ? messages.emptyManagerListMessage
                      : messages.emptyMemberListMessages[groupType.key] || messages.emptyMemberListMessage,
                    [
                      val => (
                        <div key={'inviteButton'} className="inline-container mui-padded">
                          <Button onClick={onAddStart} primary raised label={val} />
                        </div>
                      )
                    ]
                  )}
                </div>
                {!readOnly && <Suggestions items={suggestedItems} messages={messages} onAdd={onAdd} />}
              </div>
            )}
          </div>
        )}
        {single && <ManagerCompetencyAnalytics {...commonAnalyticsProperties} manager={items[0]} />}
        {!wellBeingCollapsed && !single && (
          <TeamCompetencyAnalytics
            {...commonAnalyticsProperties}
            members={filtered}
            manager={manager}
            highlighted={highlighted}
          />
        )}
      </div>
    );
  }
}

export default connect(({ intl }) => ({ intl }))(TeamSubGroup);
