import React from 'react';

import Button from '../../../mui/Button';
import ImageUpload from './ImageUpload';

class ImageUploadField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadOpen: false
    };
  }

  handleUploadOpen = () => {
    this.setState({ uploadOpen: true });
  };
  handleUploadClose = () => {
    this.setState({ uploadOpen: false });
  };
  handleUploadConfirm = url => {
    const { input: { onBlur } } = this.props;
    this.setState({ uploadOpen: false });
    onBlur(url);
  };

  render() {
    const {
      input: { value },
      label,
      helperText,
      meta: { error, touched },
      indicateRequired,
      aspectRatio,
      buttonSetLabel,
      buttonChangeLabel,
      renderImage,
      maxFileSize,
      fileHelperText
    } = this.props;
    const { uploadOpen } = this.state;
    const err = touched && error;
    const imageRenderer = renderImage || (value => <img src={value} alt="Image" />);
    return (
      <div className="mui-fields-container">
        <div className="mui-fields-label">
          {label}
          {indicateRequired && ' *'}
        </div>
        {value && imageRenderer(value)}
        <Button
          onClick={this.handleUploadOpen}
          label={value ? buttonChangeLabel : buttonSetLabel}
          primary
          raised={!value}
        />
        {uploadOpen && (
          <ImageUpload
            onClose={this.handleUploadClose}
            onConfirm={this.handleUploadConfirm}
            aspectRatio={aspectRatio}
            maxFileSize={maxFileSize}
            helperText={fileHelperText}
          />
        )}
        {(helperText || err) && (
          <div className={`mui-fields-${err ? 'error' : 'helper'}-text`}>{helperText || err}</div>
        )}
      </div>
    );
  }
}

export default ImageUploadField;
