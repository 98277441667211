import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import slice from 'lodash/slice';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';

import { getProjects, getCompanies, basicSelectCurrentCompany } from './base';

import languages from '../constants/languages';
import { EActivityType, EProjectType, EProjectState, EBool } from '../constants/enum';
import { get } from '../utils/utils';
import { getFilterValues, createSingleItemExtract, createFromValuesMatcher, matchesString } from './filterUtils';

// languages
// activity types
// project types
// project states

const FILTER_VALUES_EXTRACTORS = {
  language: {
    key: 'language',
    type: 'ENUM',
    enum: languages,
    enumName: 'languages',
    extract: createSingleItemExtract('activity.language')
  },
  activityType: {
    key: 'activityType',
    type: 'ENUM',
    enum: EActivityType,
    enumName: 'EActivityType',
    extract: p => {
      const key = get(p, ['activity', 'key']);
      // hackish
      if (key === 'cultureFit_pabk') {
        return [{ value: EActivityType.CULTURE_FIT.key }];
      }
      for (var prop in EActivityType) {
        if (EActivityType.hasOwnProperty(prop)) {
          const t = EActivityType[prop];
          if (t.matches(p)) {
            return [{ value: t.key }];
          }
        }
      }
      return [];
    }
  },
  projectType: {
    key: 'projectType',
    type: 'ENUM',
    enum: EProjectType,
    enumName: 'EProjectType',
    extract: p => {
      const res = [];
      if (p.type) {
        res.push({ value: p.type });
        if (p.type !== EProjectType.VIEW.key && p.view) {
          res.push({ value: EProjectType.VIEW.key });
        }
      }
      return res;
    }
  },
  projectState: {
    key: 'projectState',
    type: 'ENUM',
    enum: EProjectState,
    enumName: 'EProjectState',
    extract: createSingleItemExtract('validity.state')
  },
  finishedNonEmpty: {
    key: 'finishedNonEmpty',
    type: 'ENUM',
    enum: EBool,
    enumName: 'EBool',
    extract: createSingleItemExtract('activity.statusCounts.finished', v => (v > 0 ? EBool.YES.key : EBool.NO.key))
  }
};

const EXTRACTORS_LIST = values(FILTER_VALUES_EXTRACTORS);

// state selectors
const getProjectFilter = state => state.options.projectFilter;
const getProjectRouteProps = (state, props) => props.route;

// helper function

const matchFromValues = createFromValuesMatcher(FILTER_VALUES_EXTRACTORS);

const projectMatchesFilter = (p, filter, companies, currentCompany, routeProps) => {
  const { value, advanced, fromValues, demoShown } = filter;
  if (currentCompany && !p.demo && p.company.id !== currentCompany.id) {
    return false;
  }
  if (!demoShown && p.demo) {
    return false;
  }
  let match = true;
  if (advanced) {
    const fromValuesEmpty = isEmpty(fromValues);
    match &= fromValuesEmpty || matchFromValues(p, fromValues);
  } else if (!isEmpty(routeProps)) {
    if (routeProps.types) {
      match &= routeProps.types[p.type];
    }
    if (routeProps.activities) {
      match &= !!find(routeProps.activities, a => a.matches(p));
    }
  }
  const lcValue = value && value.toLowerCase();
  return (
    match &&
    (matchesString(p.name, lcValue) ||
      matchesString(p.slug, lcValue) ||
      matchesString(p.activity.name, lcValue) ||
      (companies.length > 1 && (matchesString(p.company.name, lcValue) || matchesString(p.company.slug, lcValue))))
  );
};

const orderProjects = (p, sort) => {
  const getProjectStateOrder = p => {
    const state = p && p.validity && p.validity.state;
    const stateEnum = EProjectState[state] || EProjectState.CONCEPT;
    return stateEnum.order;
  };
  const properties = [];
  const orders = [];
  // demo projects always last
  properties.push(p => !!p.demo);
  orders.push('asc');
  //
  if (sort && sort.activeFirst) {
    properties.push(getProjectStateOrder);
    orders.push('asc');
  }
  if (sort && sort.property) {
    // nulls last
    properties.push(p => !get(p, sort.property));
    orders.push('asc');
    properties.push(sort.property);
    orders.push(sort.order ? sort.order.toLowerCase() : 'asc');
  }
  properties.push('id');
  orders.push('asc');
  return orderBy(p, properties, orders);
};

const selectFilteredProjects = createSelector(
  [getProjects, getProjectFilter, getCompanies, basicSelectCurrentCompany, getProjectRouteProps],
  (projects, projectFilter, companies, currentCompany, routeProps) => {
    if (routeProps || currentCompany || (projectFilter.expanded && (projectFilter.value || projectFilter.advanced))) {
      return filter(projects, p => projectMatchesFilter(p, projectFilter, companies, currentCompany, routeProps));
    } else {
      return projects;
    }
  }
);

export const selectVisibleProjects = createSelector(
  [getProjects, getProjectFilter, selectFilteredProjects, getProjectRouteProps],
  (projects, projectFilter, filteredProjects, routeProps) => {
    const {
      pagination: { page, size, infinite },
      sort
    } = projectFilter;
    const totalPagesFiltered = Math.ceil(filteredProjects.length / size);
    const currentPage = Math.min(page, totalPagesFiltered - 1);
    const orderedProjects = orderProjects(filteredProjects, sort);
    const items = slice(orderedProjects, infinite ? 0 : currentPage * size, (currentPage + 1) * size);
    return {
      items,
      routeProps,
      pagination: {
        page: currentPage,
        infinite,
        size,
        totalSizeFiltered: filteredProjects.length,
        totalPagesFiltered: Math.ceil(filteredProjects.length / size),
        totalSize: projects.length
      }
    };
  }
);

export const selectProjectsFilterValues = createSelector(
  [getProjects, getProjectFilter, selectFilteredProjects],
  (projects, { fromValues }, filteredProjects) =>
    getFilterValues(EXTRACTORS_LIST, projects || [], filteredProjects, fromValues)
);
