import React from 'react';

class DisappearingContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shown: true
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(this.handleDisappear, this.getTimeout())
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  getTimeout = () => {
    return this.props.timeout || 500;
  };

  handleDisappear = () => {
    this.setState({ shown: false });
  };

  render() {
    const { children } = this.props;
    const { shown } = this.state;
    return (
      <div>
        {shown && children}
      </div>
    );
  }
}

export default DisappearingContent;
