import React from 'react';
import PropTypes from 'prop-types';

import SearchField from './SearchField';

class DebouncedSearchField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ value });
    }
  }

  componentWillUnmount() {
    this.clearDebounce();
  }

  clearDebounce = () => {
    if (this.debounce) {
      clearTimeout(this.debounce);
    }
  };

  propagateValue = val => {
    const { onValueChange } = this.props;
    if (onValueChange) {
      onValueChange(val);
    }
  };

  handleChange = value => {
    const { debounce } = this.props;
    this.setState({ value });
    if (this.debounce) {
      this.clearDebounce();
    }
    this.debounce = setTimeout(() => this.propagateValue(value), debounce);
  };

  render() {
    const { debounce, ...props } = this.props;
    return (
      <SearchField
        {...props}
        value={this.state.value}
        onValueChange={this.handleChange}
      />
    );
  }
}

DebouncedSearchField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  debounce: PropTypes.number.isRequired,
  onValueChange: PropTypes.func.isRequired
};

export default DebouncedSearchField;
