import { handleActions } from 'redux-actions';

import {
  PROJECT_OVERVIEW_PART_TOGGLE,
  PROJECT_OVERVIEW_TAB_SET,
  PROJECT_OVERVIEW_CUSTOM_PROP_SET
} from '../../constants/constants';
import localStorageDecorator from '../../store/localStorageDecorator';
import { getObject } from '../../persistence/storage';

const PERSISTENCE_KEY = 'options/projectOverviewV0';

const defaultState = getObject(PERSISTENCE_KEY) || {};

export default handleActions(
  localStorageDecorator(
    {
      [PROJECT_OVERVIEW_PART_TOGGLE]: (state, { payload: { projectId, part } }) => {
        const po = state[projectId] || {};
        const newPo = {
          ...po,
          parts: { ...po.parts, [part]: po.parts && po.parts[part] !== undefined ? !po.parts[part] : false }
        };
        return {
          ...state,
          [projectId]: newPo
        };
      },
      [PROJECT_OVERVIEW_TAB_SET]: (state, { payload: { projectId, tab } }) => {
        const po = state[projectId] || {};
        const newPo = {
          ...po,
          tab
        };
        return {
          ...state,
          [projectId]: newPo
        };
      },
      [PROJECT_OVERVIEW_CUSTOM_PROP_SET]: (state, { payload: { projectId, key, value } }) => {
        const po = state[projectId] || {};
        const newPo = {
          ...po,
          custom: {
            ...po.custom,
            [key]: value
          }
        };
        return {
          ...state,
          [projectId]: newPo
        };
      }
    },
    PERSISTENCE_KEY
  ),
  defaultState
);
