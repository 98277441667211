import React from 'react';
import map from 'lodash/map';

import MatchRange from './MatchRange';
import MatchBadge from './MatchBadge';

const renderRangeBoundary = (boundary, range, eliminating, labelRenderer) => {
  if (!boundary || boundary.value == undefined) {
    return null;
  }
  return (
    <MatchBadge matches={boundary.matches} eliminating={boundary.eliminating || range.eliminating || eliminating}>
      {labelRenderer(boundary)}
    </MatchBadge>
  );
};

const MatchItem = props => {
  const { range, list, bool, matches, eliminating, labelRenderer } = props;
  if (range) {
    return (
      <MatchRange
        lower={renderRangeBoundary(range.lower, range, eliminating, labelRenderer)}
        upper={renderRangeBoundary(range.upper, range, eliminating, labelRenderer)}
      />
    );
  }
  if (list) {
    return (
      <div>
        {map(list.items, item => (
          <MatchItem
            key={item.value}
            {...item}
            eliminating={item.eliminating || list.eliminating}
            labelRenderer={labelRenderer}
          />
        ))}
      </div>
    );
  }
  if (bool) {
    return (
      <MatchBadge matches={bool.matches} eliminating={bool.eliminating || eliminating}>
        {labelRenderer(bool)}
      </MatchBadge>
    );
  }
  return (
    <MatchBadge matches={matches} eliminating={eliminating}>
      {labelRenderer(props)}
    </MatchBadge>
  );
};

export default MatchItem;
