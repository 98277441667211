import { handleActions } from 'redux-actions';

import { ACTIVITY_TEMPLATES_GET, ACTIVITY_TEMPLATES_MESSAGES_GET } from '../../constants/constants';

const defaultState = {
  templates: [],
  messageTemplates: {}
};

export default handleActions(
  {
    [ACTIVITY_TEMPLATES_GET.SUCCESS]: (state, { payload: { items } }) => ({
      ...state,
      templates: items,
      loading: false
    }),
    [ACTIVITY_TEMPLATES_GET.PENDING]: state => ({ ...state, loading: true }),
    [ACTIVITY_TEMPLATES_GET.ERROR]: state => ({ ...state, error: true, loading: false }),
    [ACTIVITY_TEMPLATES_MESSAGES_GET.SUCCESS]: (state, { payload: { payload: { templates } } }) => ({
      ...state,
      messageTemplates: templates
    })
  },
  defaultState
);
