import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import {
  GROUP_MEMBERS_GET,
  GROUP_MANAGERS_GET,
  GROUP_MEMBER_CANDIDATES_GET,
  GROUP_MEMBER_SUGGESTED_CANDIDATES_GET,
  GROUP_CURRENT_UNSET
} from '../../constants/constants';

const updateLoading = (state, inc) => (state.loading || 0) + inc;

const createAsyncActionHandler = (asyncType, initialState) => {
  return handleActions(
    {
      [asyncType.PENDING]: state => ({ ...state, loading: updateLoading(state, 1) }),
      [asyncType.SUCCESS]: (state, { payload: { page, ...rest } }) => ({
        ...state,
        ...rest,
        pagination: page,
        error: false,
        loading: updateLoading(state, -1)
      }),
      [asyncType.ERROR]: state => ({ ...state, error: true, loading: updateLoading(state, -1) }),
      [GROUP_CURRENT_UNSET]: () => initialState
    },
    initialState
  );
};

export default combineReducers({
  suggestedCandidates: createAsyncActionHandler(GROUP_MEMBER_SUGGESTED_CANDIDATES_GET, { items: [] }),
  candidates: createAsyncActionHandler(GROUP_MEMBER_CANDIDATES_GET, { items: [] }),
  managers: createAsyncActionHandler(GROUP_MANAGERS_GET, { items: [] }),
  members: createAsyncActionHandler(GROUP_MEMBERS_GET, { items: [] })
});
