import React from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';

import MatchSection from '../matching/MatchSection';
import MatchSectionDivider from '../matching/MatchSectionDivider';
import MatchContainer from '../matching/MatchContainer';
import MatchBadge from '../matching/MatchBadge';
import MatchScale from '../matching/MatchScale';

import { renderDataSourceValue } from '../matching/Utils';
import { selectDataSourcesMap } from '../../selectors/dataSources';

const salaryToRange = s => (s ? { lower: s.min, upper: s.max } : null);
const renderItems = (items, dataSourceMap, eliminated) =>
  map(items, ({ value, matches }) => (
    <MatchBadge key={value} matches={matches} eliminating={eliminated}>
      {renderDataSourceValue({ value }, dataSourceMap)}
    </MatchBadge>
  ));
const renderList = (list, dataSourceMap) => renderItems(list.items, dataSourceMap, list.fit.eliminated);
const renderUnmatchedItems = (list, label, dataSourceMap) => {
  const { unmatchedItems } = list;
  if (!unmatchedItems || unmatchedItems.length === 0) {
    return null;
  }
  return (
    <div className="box-error" style={{ margin: '10px 4px 12px' }}>
      <MatchContainer title={label}>{renderItems(unmatchedItems, dataSourceMap)}</MatchContainer>
    </div>
  );
};

const TalentPreferencesMatch = props => {
  const { match, intl, dataSourcesMap } = props;
  if (!match) {
    return null;
  }
  const hasClassification = match.types || match.roles || match.specializations || match.industries || match.locations;
  const hasBenefitsAndGrowth = match.salary || match.benefits || match.personalDevelopmentAreas;
  if (!hasClassification && !hasBenefitsAndGrowth) {
    return null;
  }
  const messages = intl.messages.matching.preferences;
  return (
    <div style={{ padding: '0 0 8px' }}>
      {hasClassification && (
        <MatchSection icon="list" title={messages.classification.title}>
          {match.types && (
            <MatchContainer title={messages.classification.types.title}>
              {renderList(match.types, dataSourcesMap.opportunityTypes)}
            </MatchContainer>
          )}
          {match.roles && (
            <MatchContainer title={messages.classification.roles.title}>
              {renderList(match.roles, dataSourcesMap.roles)}
            </MatchContainer>
          )}
          {match.specializations && (
            <MatchContainer title={messages.classification.specializations.title}>
              {renderList(match.specializations, dataSourcesMap.specializations)}
            </MatchContainer>
          )}
          {match.industries && (
            <MatchContainer title={messages.classification.industries.title}>
              {renderList(match.industries, dataSourcesMap.industries)}
            </MatchContainer>
          )}
          {match.locations && (
            <MatchContainer title={messages.classification.locations.title}>
              {renderList(match.locations, dataSourcesMap.locations)}
            </MatchContainer>
          )}
        </MatchSection>
      )}
      {hasClassification && hasBenefitsAndGrowth && <MatchSectionDivider />}
      {hasBenefitsAndGrowth && (
        <MatchSection icon="card_giftcard" title={messages.benefitsAndGrowth.title}>
          {match.salary && (
            <MatchContainer title={messages.benefitsAndGrowth.salary.title}>
              <MatchScale
                range={salaryToRange(match.salary)}
                matchedRange={salaryToRange(match.salary.preferred)}
                matches={match.salary.matches}
                eliminating={match.salary.eliminating}
                domain={[5000, 640000]}
                labelRenderer={(type, formattedPair) => {
                  if (type === 'base') {
                    return `${messages.benefitsAndGrowth.salary.offerLabel}: ${formattedPair}`;
                  }
                  return formattedPair;
                }}
              />
            </MatchContainer>
          )}
          {match.benefits && (
            <MatchContainer title={messages.benefitsAndGrowth.benefits.title}>
              {renderList(match.benefits, dataSourcesMap.benefits)}
              {renderUnmatchedItems(
                match.benefits,
                messages.benefitsAndGrowth.benefits.unmatchedTitle,
                dataSourcesMap.benefits
              )}
            </MatchContainer>
          )}
          {match.personalDevelopmentAreas && (
            <MatchContainer title={messages.benefitsAndGrowth.personalDevelopmentAreas.title}>
              {renderList(match.personalDevelopmentAreas, dataSourcesMap.personalDevelopmentAreas)}
              {renderUnmatchedItems(
                match.personalDevelopmentAreas,
                messages.benefitsAndGrowth.personalDevelopmentAreas.unmatchedTitle,
                dataSourcesMap.personalDevelopmentAreas
              )}
            </MatchContainer>
          )}
        </MatchSection>
      )}
    </div>
  );
};


const mapStateToProps = (state, ownProps) => {
  return {
    intl: state.intl,
    dataSourcesMap: selectDataSourcesMap(state, ownProps)
  }
};

export default connect(mapStateToProps)(TalentPreferencesMatch);
