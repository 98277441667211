import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import EnhancedButton from 'material-ui/internal/EnhancedButton';

const buttonStyle = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'stretch'
};

const ButtonToggle = props => {
  const { active, outline, style, containerStyle, children } = props;
  const { onToggle, onMouseEnter, onMouseLeave } = props;
  const className = cn({
    [props.className]: props.className,
    'mui-button-toggle container-flex-row border-radius': true,
    'bg-highlight text-white': active && !outline,
    'bg-highlight-light box-outline-highlight-2px': active && outline,
    'bg-primary-light': !active,
    active,
    outline
  });
  return (
    <div className={className} style={containerStyle} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <EnhancedButton className="mui-button-toggle__button" style={{ ...buttonStyle, ...style }} onClick={onToggle}>
        <div className="mui-button-toggle__content container-flex-row text-left flex-grow">{children}</div>
      </EnhancedButton>
    </div>
  );
};

ButtonToggle.propTypes = {
  active: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  onToggle: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default ButtonToggle;
