import { isValid, isDirty, getFormValues, getFormSyncErrors } from 'redux-form';

import _map from 'lodash/map';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';

import { CULTURE_FIT_DIMENSIONS } from '../../constants/enum';

export const getFormsByIds = (formIds, state) => _map(formIds, form => ({
  name: form,
  valid: isValid(form)(state),
  values: getFormValues(form)(state),
  errors: getFormSyncErrors(form)(state),
  dirty: isDirty(form)(state)
}));

export const enumToDataSource = (e, messages, labelGetter) => {
  const res = [];
  const actualLabelGetter = labelGetter || ((eMsg, eVal) => (eMsg ? eMsg.label || eMsg : eVal.label));
  for (let prop in e) {
    if (e.hasOwnProperty(prop)) {
      const eMsg = messages[prop];
      const eVal = e[prop];
      res.push({ value: eVal.value || eVal.key || prop, label: actualLabelGetter(eMsg, eVal) });
    }
  }
  return res;
};

export const valueHoldersToValues = list => list && _map(list, l => l.value);
export const valuesToValueHolders = list => list && _map(list, l => ({ value: l }));

const flattenKeys = obj => {
  const res = [];
  const q = [{ path: '', o: obj }];
  while (q.length) {
    const { path, o } = q.shift();
    const isArr = _isArray(o);
    const isObj = _isObject(o);
    if (isArr || isObj) {
      for (let prop in o) {
        if (o.hasOwnProperty(prop)) {
          const val = o[prop];
          const pathPart = isArr ? `[${prop}]` : `${path ? '.' : ''}${prop}`;
          q.push({ path: `${path}${pathPart}`, o: val });
        }
      }
    } else {
      res.push(path);
    }
  }
  return res;
};

export const handleSubmit = (forms, onSubmit, touch, options = {}) => {
  const res = _reduce(
    forms,
    (res, f) => {
      const { valid, values, errors, name } = f;
      if (!valid) {
        touch(name, ...flattenKeys(errors));
      }
      const remove = options.isRemoved && options.isRemoved(f);
      for (let prop in values) {
        if (values.hasOwnProperty(prop)) {
          res.values[prop] = remove ? null : values[prop];
        }
      }
      res.valid = res.valid && valid;
      return res;
    },
    {
      values: {},
      valid: true
    }
  );
  if (res.valid) {
    onSubmit(res.values);
  }
};

export const cultureFitToFormValue = (matchConfig, cultureFitDimensionId) => {
  const val = _get(matchConfig || {}, `culture.metrics.${cultureFitDimensionId}.values[0]`);
  if (val != null) {
    return Math.round((1 - val) * 3);
  }
  return null;
};

export const cultureFromValues = values => {
  const res = {};
  let atLeastOne = false;
  for (let i = 0; i < CULTURE_FIT_DIMENSIONS.length; i += 1) {
    const dim = CULTURE_FIT_DIMENSIONS[i];
    const val = values[dim.formName];
    if (val != null) {
      const resVal = 1 - parseInt(val, 10) / 3;
      res[dim.key] = { values: [resVal] };
      atLeastOne = true;
    }
  }
  if (atLeastOne) {
    return { metrics: res };
  }
  return null;
};

