import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';

import DataSourcesLoader from '../../common/DataSourcesLoader';
import OpportunityCompanyResponseStatus from '../opportunity/OpportunityCompanyResponseStatus';
import OpportunityResponseStatus from '../opportunity/OpportunityResponseStatus';

import Spinner from '../../../components/spinner/Spinner';
import Button from '../../../components/mui/Button';
import ParticipantDetail from '../../../components/pages/private/position/ApplicantDetail';

import { extractActivityV2 } from '../../../selectors/applicantDetail';
import { EProfileFeature, EUserOpportunityPostResponse } from '../../../constants/enum';
import { BRAND, MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE } from '../../../constants/constants';

import allActions from '../../../actions';
import * as opportunityActions from '../../../actions/entities/opportunityActions';

const noop = () => {};

const TalentDetail = props => {
  const { talent, companyId, chart, currentActivityIndex, customActions, customHeaderAddendum, customTitle } = props;
  const {
    onSetActiveChart,
    onSetToggleExpandChart,
    onSetChartMode,
    onSetCurrentActivityIndex,
    invokeUpgradeAccountModal
  } = props;
  const pageProps = {
    ...talent,
    companyId,
    chart,
    currentActivityIndex,
    customActions,
    customHeaderAddendum,
    customTitle,
    //
    //  actions
    //
    setActiveChart: onSetActiveChart,
    setToggleExpanded: onSetToggleExpandChart,
    setChartMode: onSetChartMode,
    handleSetCurrentActivityIndex: onSetCurrentActivityIndex,
    invokeUpgradeAccountModal,
    getApplicantEvaluationDescription: noop,
    removeEvaluationDescription: noop,
    handleCompare: noop
  };
  return (
    <div>
      <DataSourcesLoader />
      <ParticipantDetail {...pageProps} />
    </div>
  );
};

class TalentDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentActivityIndex: 0
    };
  }

  componentDidMount() {
    this.loadTalent();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.opportunityId !== this.props.opportunityId ||
      prevProps.participantId !== this.props.participantId ||
      prevProps.intl.locale !== this.props.intl.locale
    ) {
      this.loadTalent();
    }
  }

  loadTalent = () => {
    const {
      opportunityId,
      participantId,
      intl: { locale },
      getOpportunityParticipant,
      getOpportunity
    } = this.props;
    getOpportunity(opportunityId, locale);
    getOpportunityParticipant(opportunityId, participantId, locale).then(() =>
      this.setState({ currentActivityIndex: 0 })
    );
  };

  handleSetCurrentActivityIndex = currentActivityIndex => this.setState({ currentActivityIndex });

  handleParticipantResponseSet = response => {
    const { opportunityId, switchModal, participant } = this.props;
    switchModal({ id: MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE, opportunityId, participant, response });
  };

  render() {
    const { talentDetail, chart, loading, participant, opportunity, intl } = this.props;
    const { setChartMode, setActiveChart, setToggleExpanded, invokeUpgradeAccountModal } = this.props;
    const { currentActivityIndex } = this.state;
    if (loading) {
      return <Spinner show />;
    }
    if (!talentDetail) {
      return null;
    }
    const messages = intl.messages.components.pages.private.opportunities;
    return (
      <TalentDetail
        talent={talentDetail}
        chart={chart}
        currentActivityIndex={currentActivityIndex}
        onSetToggleExpandChart={setToggleExpanded}
        onSetChartMode={setChartMode}
        onSetActiveChart={setActiveChart}
        onSetCurrentActivityIndex={this.handleSetCurrentActivityIndex}
        invokeUpgradeAccountModal={invokeUpgradeAccountModal}
        companyId={opportunity?.company?.id}
        customTitle={(opportunity?.posts || [])[0]?.title}
        customHeaderAddendum={
          participant &&
          participant.response && (
            <div className="text-sz-reg-sm">
              <OpportunityResponseStatus
                messages={messages.responseStatus}
                response={participant.response}
                intl={intl}
              />
            </div>
          )
        }
        customActions={
          <div className="text-sz-reg-sm">
            {participant.companyResponse && participant.companyResponse.status ? (
              <div className="mui-padded-vertical">
                <OpportunityCompanyResponseStatus
                  messages={messages.companyResponseStatus}
                  response={participant.companyResponse}
                  participantResponse={participant.response}
                  intl={intl}
                />
              </div>
            ) : (
              <div className="text-right">
                <Button
                  label={
                    participant.response
                      ? participant.response.status === EUserOpportunityPostResponse.ACCEPTED.key
                        ? messages.companyResponseButtons.ACCEPT_ACCEPTED
                        : messages.companyResponseButtons.ACCEPT_INTERESTED
                      : messages.companyResponseButtons.ACCEPT
                  }
                  primary
                  icon={
                    <i className="material-icons" style={{ color: 'inherit' }}>
                      check
                    </i>
                  }
                  onClick={() => this.handleParticipantResponseSet(EUserOpportunityPostResponse.ACCEPTED.key)}
                />
                {participant.response && (
                  <Button
                    label={messages.companyResponseButtons.REJECT}
                    icon={
                      <i className="material-icons" style={{ color: 'inherit' }}>
                        close
                      </i>
                    }
                    onClick={() => this.handleParticipantResponseSet(EUserOpportunityPostResponse.REJECTED.key)}
                  />
                )}
              </div>
            )}
          </div>
        }
      />
    );
  }
}

const basicSelectParticipant = state => state.entities.opportunity.participant;
const basicSelectIntl = state => state.intl;
const basicSelectAuth = state => state.auth;

const preSelectParticipant = createSelector([basicSelectParticipant], p => {
  return {
    ...p,
    activities: map(p.activities, a => ({ ...a, project: { ...a.project, activity: { key: a.key } } }))
  };
});

const extractActivityWithMatch = (a, match, metricDefinitions, intl) => {
  const matchedCultureId = get(match, 'culture.activity.id');
  const extractedActivity = extractActivityV2(a, {}, metricDefinitions, intl);
  const isMatchedCultureFit = matchedCultureId && a.id === matchedCultureId;
  const result = { ...extractedActivity, crossComparison: { opportunity: true } };
  if (isMatchedCultureFit) {
    const fit = get(match, 'culture.data.fit');
    const cultureFitMetrics = get(match, 'culture.data.metrics');
    result.fit = fit;
    const value = fit && fit.value ? Math.round(fit.value * 100) : 0;
    result.doughnutData = {
      ...result.doughnutData,
      fit: {
        label: `${value}%`,
        color: BRAND.theme.palette.accent1Color,
        value: { full: value, empty: 100 - value }
      }
    };
    result.crossComparisonFit = {
      metrics: map(extractedActivity.dualMetrics, dm => {
        const metricData = cultureFitMetrics[dm.key];
        return {
          metric: dm,
          ...metricData
        };
      })
    };
  }
  return result;
};

const selectParticipantDetail = createSelector(
  [preSelectParticipant, basicSelectIntl, basicSelectAuth],
  (p, intl, auth) => {
    const projectMessages = intl.messages.components.pages.private.project;
    const messages = projectMessages.applicantDetail;
    const disabledFeatures = auth.isAdmin ? [] : get(p, 'opportunity.company.contractPlan.disabledFeatures', []);
    const features = mapValues(EProfileFeature, f => disabledFeatures.indexOf(f) === -1);
    const match = p.match;
    return {
      intl,
      locked: false,
      features,
      match,
      userId: p.id,
      anonymous: p.anonymous,
      firstName: p.firstName,
      lastName: p.lastName,
      email: p.email,
      personal: p.personal,
      activities: map(p.activities, a => extractActivityWithMatch(a, match, p.metricDefinitions, intl)),
      evaluationDescriptionEnabled: false,
      evaluationDescription: null,
      publicReportUrl: null,
      print: { hidden: true },
      comparisonHidden: true,
      positionShown: false,
      messages,
      gameId: null,
      projectMessages, // should not be needed
      applicationStatus: null,
      projectType: null // ?
    };
  }
);

const mapStateToProps = (state, ownProps) => {
  const {
    auth,
    intl,
    options: { chart },
    entities: {
      opportunity: { loading, item, participant }
    }
  } = state;
  const { params } = ownProps;
  return {
    auth,
    intl,
    chart,
    loading,
    participant,
    opportunity: item,
    talentDetail: selectParticipantDetail(state, ownProps),
    opportunityId: params.opportunityId,
    participantId: params.participantId
  };
};

const actions = {
  invokeUpgradeAccountModal: allActions.invokeUpgradeAccountModal,
  setActiveChart: allActions.setActiveChart,
  setToggleExpanded: allActions.setToggleExpanded,
  setChartMode: allActions.setChartMode,
  switchModal: allActions.switchModal,
  ...opportunityActions
};

export default withRouter(connect(mapStateToProps, actions)(TalentDetailContainer));
