import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import cn from 'classnames';

import IconButton from '../mui/IconButton';

import { BRAND } from '../../constants/constants';
import { KC, determineKeyCode } from '../superfield/Utils';

const stopPropagation = e => e.stopPropagation();

const FULL_OVERLAY_STYLES = {
  closeButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    width: 32,
    height: 32,
    margin: 16,
    padding: 0,
    borderRadius: 16
  }
};

const BODY_CLASS = 'with-overlay';

class Overlay extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    full: PropTypes.bool,
    modal: PropTypes.bool,
    noBlur: PropTypes.bool,
    maxWidth: PropTypes.any,
    onRequestClose: PropTypes.func.isRequired
  };

  componentDidMount() {
    const body = this.getBody();
    if (body && body.classList) {
      if (!body.classList.contains(BODY_CLASS)) {
        body.classList.add(BODY_CLASS);
        this.original = true;
      }
    }
  }

  componentWillUnmount() {
    const body = this.getBody();
    if (body && body.classList && this.original) {
      body.classList.remove('with-overlay');
    }
  }

  getBody = () => document.body || document.getElementsByTagName('body')[0];

  handleKeyDown = e => {
    const kc = determineKeyCode(e);
    if (kc === KC.ESC) {
      const { onRequestClose } = this.props;
      if (onRequestClose) {
        onRequestClose();
      }
    }
  };

  render() {
    const { children, full, maxWidth, modal, noBlur } = this.props;
    const { onRequestClose, onClick } = this.props;
    const className = cn({
      'overlay-container': true,
      blur: !noBlur,
      [`brand-${BRAND.key.toLowerCase()}`]: true,
      full
    });
    return (
      <Portal>
        <div
          tabIndex="-1"
          className={className}
          onClick={modal ? onClick : onRequestClose}
          onKeyDown={this.handleKeyDown}
        >
          <div className="overlay-container-close-button-container">
            <IconButton onClick={onRequestClose} style={full ? FULL_OVERLAY_STYLES.closeButton : {}}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
          {full ? (
            <div className="overlay-content-container" onClick={stopPropagation}>
              {children}
            </div>
          ) : (
            <div className="container" style={{ maxWidth: maxWidth || '960px' }}>
              <div className="overlay-content-container" onClick={stopPropagation}>
                {children}
              </div>
            </div>
          )}
        </div>
      </Portal>
    );
  }
}

export default Overlay;
