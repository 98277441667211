import React from 'react';
import { connect } from 'react-redux';

import ParticipantActivityEventsContainer from './ParticipantActivityEventsContainer';
import ProjectsHomeContainer from './ProjectsHomeContainer';
import CompanySelect from '../../containers/private/company/CompanySelect';
import BaseContainer from '../basic/BaseContainer';
import GroupsHomeContainer from './GroupsHomeContainer';

import { EGroupType } from '../../constants/enum';

import * as activityTemplatesActions from '../../actions/entities/activityTemplateActions';

class HomeContainer extends React.Component {
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.company !== this.props.completedOnly) {
      this.loadData();
    }
  }

  loadData = () => {
    const { company, getActivityTemplates } = this.props;
    if (!company) {
      return;
    }
    getActivityTemplates(company.id);
  };

  render() {
    const { company } = this.props;

    return (
      <div>
        <CompanySelect required />
        {company && (
          <BaseContainer>
            <div className="row">
              <div className="col-md-6">
                <ParticipantActivityEventsContainer company={company} />
                <ProjectsHomeContainer company={company} />
              </div>
              <div className="col-md-6">
                <GroupsHomeContainer company={company} type={EGroupType.TEAM} />
                <GroupsHomeContainer company={company} type={EGroupType.GROUP} />
                <GroupsHomeContainer company={company} type={EGroupType.HIRING} />
              </div>
            </div>
          </BaseContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    ui,
    entities: {
      currentCompany: { item }
    }
  } = state;
  return { ui, company: item };
};

export default connect(mapStateToProps, activityTemplatesActions)(HomeContainer);
