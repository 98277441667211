import React from 'react';

import { formatMessage, selectQuantityMessage } from '../../../utils/utils';

import withIntl from '../../withIntl';

const TimeUnit = props => {
  const {
    value,
    unit,
    intl: { messages }
  } = props;
  return <span>{formatMessage(selectQuantityMessage(messages[unit] || {}, value || 0), [value])}</span>;
};

const intlMessages = {
  cs: {
    DAYS: {
      1: '{0} den',
      '2-4': '{0} dny',
      '*': '{0} dní'
    },
    WEEKS: {
      1: '{0} týden',
      '2-4': '{0} týdny',
      '*': '{0} týdnů'
    },
    MONTHS: {
      1: '{0} měsíc',
      '2-4': '{0} měsíce',
      '*': '{0} měsíců'
    },
    YEARS: {
      1: '{0} rok',
      '2-4': '{0} roky',
      '*': '{0} let'
    }
  },
  sk: {
    DAYS: {
      1: '{0} deň',
      '2-4': '{0} dni',
      '*': '{0} dní'
    },
    WEEKS: {
      1: '{0} týždeň',
      '2-4': '{0} týždne',
      '*': '{0} týždňov'
    },
    MONTHS: {
      1: '{0} mesiac',
      '2-4': '{0} mesiace',
      '*': '{0} mesiacov'
    },
    YEARS: {
      1: '{0} rok',
      '2-4': '{0} roky',
      '*': '{0} rokov'
    }
  },
  en: {
    DAYS: {
      1: '{0} day',
      '*': '{0} days'
    },
    WEEKS: {
      1: '{0} week',
      '*': '{0} weeks'
    },
    MONTHS: {
      1: '{0} month',
      '*': '{0} months'
    },
    YEARS: {
      1: '{0} year',
      '*': '{0} years'
    }
  }
};

export default withIntl(TimeUnit, intlMessages);
