import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const debounce = (fn, delay) => {
  let timeout = null;
  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      fn(...args);
      timeout = null;
    }, delay);
  };
};

class RichTextEditor extends React.Component {
  static createEditorState = value => {
    const contentBlock = htmlToDraft(value || '');
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  };

  constructor(props) {
    super(props);
    const {
      input: { value }
    } = props;
    this.state = {
      editorState: RichTextEditor.createEditorState(value),
      value: props.input.value,
      focused: false
    };
  }

  componentDidUpdate() {
    const {
      input: { value }
    } = this.props;
    if (value !== this.state.value) {
      // get old editor state and old editor selection
      const oldEditorState = this.state.editorState;
      const oldSelectionState = oldEditorState.getSelection();

      // create new editorState from content
      const newEditorState = RichTextEditor.createEditorState(value);

      // update cursor position base on old selection state
      const updatedSelection = newEditorState.getSelection().merge({
        anchorOffset: oldSelectionState.getAnchorOffset(),
        focusOffset: oldSelectionState.getFocusOffset(),
        isBackward: false
      });
      const newSelection = newEditorState.getSelection().merge(updatedSelection);

      // check whether editor has focus, if yes use forceSelection to manually update the position
      // if not, use acceptSelection
      const newEditorStateWithSelection = oldSelectionState.getHasFocus()
        ? EditorState.forceSelection(newEditorState, newSelection)
        : EditorState.acceptSelection(newEditorState, newSelection);

      this.setState({ value, editorState: newEditorStateWithSelection });
    }
  }

  debouncedOnChange = debounce(() => {
    const onChange = this.props.input.onChange;
    if (onChange) {
      const { value, editorState } = this.state;
      const rawValue = convertToRaw(editorState.getCurrentContent());
      const newValue = draftToHtml(rawValue).replace(/^<p><\/p>\n?$/, '');
      if (value !== newValue) {
        this.setState({ value: newValue }, () => onChange(newValue));
      }
    }
  }, 300);

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
    this.debouncedOnChange();
  };

  handleFocus = () => this.setState({ focused: true });
  handleBlur = () => this.setState({ focused: false });

  render() {
    const { editorState, focused } = this.state;
    const { label, placeholder, helperText } = this.props;
    return (
      <div className="mui-rich-text-editor">
        <div className="mui-rich-text-editor__field bg-primary-light">
          {label && <div className="mui-fields-label">{label}</div>}
          <Editor
            wrapperClassName={`text-editor${focused ? ' text-editor-focused' : ''}`}
            toolbar={{
              options: ['inline', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'],
              inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'] },
              list: { options: ['unordered', 'ordered'] }
            }}
            editorState={editorState}
            onEditorStateChange={this.onEditorStateChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            stripPastedStyles
            placeholder={placeholder}
          />
        </div>
        {helperText && <div className="mui-fields-helper-text">{helperText}</div>}
      </div>
    );
  }
}

export default RichTextEditor;
