import L from 'lodash';

import { get } from '../persistence/storage';
import { LOGIN_SUCCESS, BRAND } from '../constants/constants';

import languages from '../constants/languages';
import i18n from '../i18n';

const su = (...args) => {
  const { smartsupp } = window;
  if (smartsupp) {
    smartsupp(...args);
  }
};

const initTranslations = () => {
  L.map(L.keys(languages), k => {
    su('translate', i18n[k].smartsupp, k);
  });
};

const setSmartsuppLang = lang => {
  su('language', lang);
};

export default function smartsuppMiddleware() {
  const curLocale = get('locale') || 'cs';
  initTranslations();
  su('chat:align', 'right', 'bottom', 72);
  su('theme:color', BRAND.theme.palette.primary1Color);
  setSmartsuppLang(curLocale);
  return next => action => {
    if (action.type === '@@intl/UPDATE') {
      setSmartsuppLang(action.payload.locale);
    } else if (action.type === LOGIN_SUCCESS) {
      const { email, firstName, lastName } = action.payload;
      su('name', `${firstName} ${lastName}`);
      su('email', email);
    }
    return next(action);
  };
}
