import { ACCOUNT_NEW_LOADING, LOGIN_SUCCESS } from '../constants/constants';

export default function analyticsMiddleware() {
  const gat = (category, action, label) => {
    const ga = window.ga;
    if (ga) {
      ga('send', 'event', category, action, label);
    }
  };
  return next => action => {
    if (action.types && action.types.indexOf(ACCOUNT_NEW_LOADING) !== -1) {
      // new account creation
      gat('Dashboard', 'CompleteRegistration', 'Create account');
    } else if (action.type === LOGIN_SUCCESS && action.meta && action.meta.manual) {
      gat('Dashboard', 'Login', 'Login');
    }
    return next(action);
  };
};
