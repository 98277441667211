import * as c from '../../constants/constants';
import * as Api from '../../api/entities/simulationModelApi';
import { createApiCallAction } from '../apiCallAction';

export function clearSimulationModel() {
  return {
    type: c.SIMULATION_MODEL_CLEAR
  };
}

export function getSimulationModels() {
  return {
    type: c.API,
    payload: {
      asyncType: c.SIMULATION_MODELS_GET
    },
    meta: {
      url: '/api/v1/designer/simulations/models'
    }
  };
}

export function getSimulationModel(id) {
  return {
    type: c.API,
    payload: {
      asyncType: c.SIMULATION_MODEL_GET
    },
    meta: {
      url: `/api/v1/designer/simulations/models/${id}`
    }
  };
}

export const createSimulationModel = createApiCallAction(c.SIMULATION_MODEL_CREATE, Api.createSimulationModel);

export const updateSimulationModel = createApiCallAction(c.SIMULATION_MODEL_UPDATE, Api.updateSimulationModel);

export const getSimulationModelMetadata = createApiCallAction(
  c.SIMULATION_MODEL_METADATA_GET,
  Api.getSimulationModelMetadata
);

export function deleteSimulationModel(id) {
  return {
    type: c.API,
    payload: {
      asyncType: c.SIMULATION_MODEL_DELETE
    },
    meta: {
      url: `/api/v1/designer/simulations/models/${id}`,
      method: 'DELETE'
    }
  };
}

export function copySimulationModel(id) {
  return {
    type: c.API,
    payload: {
      asyncType: c.SIMULATION_MODEL_COPY
    },
    meta: {
      url: `/api/v1/designer/simulations/models/${id}/copy`,
      method: 'POST'
    }
  };
}

export function setSimulationModelsFilterText(payload) {
  return {
    type: c.SIMULATION_MODELS_FILTER_TEXT_SET,
    payload
  };
}
