import React from 'react';
import { useDrag } from 'react-dnd';
import cn from 'classnames';

import Toggle from 'material-ui/Toggle';

import MetricNodeList from './MetricNodeList';
import CompetencyIcon from '../../../components/icons/Metric';
import WeightSlider from '../../../components/overviewChart/WeightSlider';

import { EMetricType } from './ProjectMetricsConfig';
import { determineEWeightLevel } from '../../../components/overviewChart/WeightUtils';
import { extractWeight } from '../../../utils/utils';
import WeightLevelLabel from '../../../components/overviewChart/WeightLevelLabel';

const MetricNode = props => {
  const { parent, node, subTree, disabled, type, weights, weightsEnabled, modelEditing } = props;
  const { onDisabledSet, onPositionSet, canPositionSet, onWeightSet } = props;
  const [{ isDragging }, drag] = useDrag({
    item: { node, subTree, parent, type: `METRIC_NODE__${type}` },
    collect: monitor => ({ isDragging: monitor.isDragging() })
  });
  const className = cn({
    'metric-node-item': true,
    disabled: disabled[node.key],
    editing: modelEditing,
    dragging: isDragging
  });
  const hasWeightSettings = !parent && type === EMetricType.NUMERIC;
  const weight = hasWeightSettings && extractWeight(weights || {}, node.key);
  const level = hasWeightSettings && determineEWeightLevel(weight?.value);
  return (
    <div ref={modelEditing ? drag : null} className={className}>
      <div className="metric-node-item__header">
        <div className="container-flex-row fw-yes ai-center mui-padded-horizontal mui-padded-vertical-half">
          {modelEditing && (
            <div className="mui-padded-vertical-half" style={{ paddingBottom: hasWeightSettings ? 26 : 4 }}>
              <i className="material-icons" style={{ color: 'rgba(33, 33, 33, 0.24)' }}>
                drag_handle
              </i>
            </div>
          )}
          <div className="container-flex-row flex1">
            <div className="mui-padded-horizontal mui-padded-vertical-half">
              <CompetencyIcon
                metric={node}
                size={hasWeightSettings ? 32 : 20}
                style={{ color: 'rgba(0, 0, 0, 0.38)' }}
              />
            </div>
            <div className="mui-padded-horizontal mui-padded-vertical-half flex1" style={{ minHeight: 24 }}>
              <span style={{ fontSize: '14px' }}>{node.label}</span>
              {hasWeightSettings && (
                <>
                  <span style={{ margin: '0 4px' }}>&middot;</span>
                  <WeightLevelLabel className="small text-muted" level={level} weight={weight} extended />
                  <div>
                    <WeightSlider
                      weight={weight}
                      level={level}
                      onChange={value => onWeightSet(node.key, value)}
                      disabled={!weightsEnabled}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {modelEditing && (
            <div
              className="mui-padded-horizontal mui-padded-vertical-half"
              style={{ paddingBottom: hasWeightSettings ? 26 : 4 }}
            >
              <Toggle toggled={!disabled[node.key]} onToggle={() => onDisabledSet(node)} />
            </div>
          )}
        </div>
      </div>
      {modelEditing && node.children && node.children.length > 0 && (
        <div className="metric-node-item__children">
          <MetricNodeList
            parent={node}
            list={node.children}
            disabled={disabled}
            type={type}
            modelEditing={modelEditing}
            weights={weights}
            onWeightSet={onWeightSet}
            onDisabledSet={onDisabledSet}
            onPositionSet={onPositionSet}
            canPositionSet={canPositionSet}
          />
        </div>
      )}
    </div>
  );
};

export default MetricNode;
