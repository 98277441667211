import React from 'react';
import PropTypes from 'prop-types';

import Notification from './Notification';
import i18n from '../../i18n';

const CTANotification = (props) => {
    const { dismissNotification, hideNotification, notification, intl } = props;
    // supports only elf2017
    const messages = i18n.cs.components.notifications.cta.elf2017;
    const data = {
        ...notification,
        dismissible: false,
        ...messages
    };
    return (
        <Notification intl={intl} notification={data} dismissNotification={dismissNotification} hideNotification={hideNotification} />
    );
};

CTANotification.propTypes = {
    notification: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    //
    hideNotification: PropTypes.func.isRequired,
    dismissNotification: PropTypes.func.isRequired
};

export default CTANotification;
