import React from 'react';
import PropTypes from 'prop-types';

import withIntl from '../withIntl';

const MetricInsightPropertyMessage = props => {
  const {
    property,
    type,
    intl: { messages }
  } = props;
  const propMessage = messages[property];
  return <span>{propMessage ? propMessage[type] : `${property}.${type}`}</span>;
};

MetricInsightPropertyMessage.propTypes = {
  property: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['label', 'title']).isRequired
};

const intlMessages = {
  cs: {
    RECOMMENDATION_MOTIVATION: {
      label: 'Motivace',
      title: 'Jak motivovat'
    },
    RECOMMENDATION_MANAGEMENT: {
      label: 'Styl vedení',
      title: 'Jak vést'
    },
    RECOMMENDATION_REMUNERATION: {
      label: 'Odměňování',
      title: 'Jak odměňovat'
    },
    RECOMMENDATION_APPLICATION: {
      label: 'Příležitosti',
      title: 'Jak využít'
    },
    RECOMMENDATION_OBSTACLE: {
      label: 'Hrozby',
      title: 'Na co si dávat pozor'
    },
    RECOMMENDATION_DEVELOPMENT: {
      label: 'Rozvoj',
      title: 'Jak rozvíjet'
    },
    CF_TEAM_MANAGER_SITUATION: {
      label: 'Manažer',
      title: 'Interpretace - manažer'
    },
    CF_TEAM_SITUATION: {
      label: 'Tým',
      title: 'Interpretace - tým'
    },
    CF_TEAM_CHALLENGE: {
      label: 'Výzva',
      title: 'Výzva'
    },
    CF_TEAM_RESOLUTION: {
      label: 'Doporučení',
      title: 'Doporučení'
    },
    INTERPRETATION: {
      label: 'Interpretace',
      title: 'Interpretace'
    },
    INFO: {
      label: 'Info',
      title: 'Info - operacionalizace'
    },
    STATS: {
      label: 'Stats',
      title: 'Statistiky'
    },
    CULTURE_FIT: {
      label: 'Fit',
      title: 'Fit ke skupině'
    },
    CULTURE_CONVERGENCE: {
      label: 'Konvergence',
      title: 'Konvergence skupiny'
    }
  },
  sk: {
    RECOMMENDATION_MOTIVATION: {
      label: 'Motivácia',
      title: 'Ako motivovať'
    },
    RECOMMENDATION_MANAGEMENT: {
      label: 'Štýl vedenia',
      title: 'Ako viesť'
    },
    RECOMMENDATION_REMUNERATION: {
      label: 'Odmeňovanie',
      title: 'Ako odmeňovať'
    },
    RECOMMENDATION_APPLICATION: {
      label: 'Príležitosti',
      title: 'Ako využiť'
    },
    RECOMMENDATION_OBSTACLE: {
      label: 'Hrozby',
      title: 'Na čo si dávať pozor'
    },
    RECOMMENDATION_DEVELOPMENT: {
      label: 'Rozvoj',
      title: 'Ako rozvíjať'
    },
    CF_TEAM_MANAGER_SITUATION: {
      label: 'Manažér',
      title: 'Interpretácia - manažér'
    },
    CF_TEAM_SITUATION: {
      label: 'Tím',
      title: 'Interpretácia - tím'
    },
    CF_TEAM_CHALLENGE: {
      label: 'Výzva',
      title: 'Výzva'
    },
    CF_TEAM_RESOLUTION: {
      label: 'Odporúčanie',
      title: 'Odporúčanie'
    },
    INTERPRETATION: {
      label: 'Interpretácia',
      title: 'Interpretácia'
    },
    INFO: {
      label: 'Info',
      title: 'Info - operacionalizácia'
    },
    STATS: {
      label: 'Stats',
      title: 'Štatistiky'
    },
    CULTURE_FIT: {
      label: 'Fit',
      title: 'Fit k skupine'
    },
    CULTURE_CONVERGENCE: {
      label: 'Konvergencia',
      title: 'Konvergencia skupiny'
    }
  },
  en: {
    RECOMMENDATION_MOTIVATION: {
      label: 'Motivation',
      title: 'How to motivate'
    },
    RECOMMENDATION_MANAGEMENT: {
      label: 'Management style',
      title: 'How to lead'
    },
    RECOMMENDATION_REMUNERATION: {
      label: 'Reward',
      title: 'How to reward'
    },
    RECOMMENDATION_APPLICATION: {
      label: 'Opportunities',
      title: 'When it\'s advantage'
    },
    RECOMMENDATION_OBSTACLE: {
      label: 'Obstacles',
      title: 'Might hold them back'
    },
    RECOMMENDATION_DEVELOPMENT: {
      label: 'Development',
      title: 'How to develop'
    },
    CF_TEAM_MANAGER_SITUATION: {
      label: 'Manager',
      title: 'Interpretation - manager'
    },
    CF_TEAM_SITUATION: {
      label: 'Team',
      title: 'Interpretation - team'
    },
    CF_TEAM_CHALLENGE: {
      label: 'Challenge',
      title: 'Challenge'
    },
    CF_TEAM_RESOLUTION: {
      label: 'Recommendation',
      title: 'Recommendation'
    },
    INTERPRETATION: {
      label: 'Interpretation',
      title: 'Interpretation'
    },
    INFO: {
      label: 'Info',
      title: 'Info - operationalization'
    },
    STATS: {
      label: 'Stats',
      title: 'Stats'
    },
    CULTURE_FIT: {
      label: 'Fit',
      title: 'Group fit'
    },
    CULTURE_CONVERGENCE: {
      label: 'Convergence',
      title: 'Group convergence'
    }
  }
};

export default withIntl(MetricInsightPropertyMessage, intlMessages);
