import React from 'react';

import { formatMessage } from '../../utils/utils';

import withIntl from '../withIntl';

const StatsNoticeMessage = props => {
  const {
    group,
    intl: { messages }
  } = props;
  return (
    <div className="text-muted text-sz-sm">
      {group ? (
        <span>{formatMessage(messages.linkedMessage, [<b key="groupName">{group.name}</b>])}</span>
      ) : (
        <em>{messages.unlinkedMessage}</em>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    linkedMessage: 'Statistiky vypočtené vůči skupině {0}',
    unlinkedMessage: 'Připojte tým nebo skupinu pro lepší statistiky'
  },
  sk: {
    linkedMessage: 'Štatistiky vypočítané voči skupine {0}',
    unlinkedMessage: 'Pripojte tím alebo skupinu pre vylepšené štatistiky'
  },
  en: {
    linkedMessage: 'Stats calculated against {0}',
    unlinkedMessage: 'Link a team or a group for improved stats'
  }
};

export default withIntl(StatsNoticeMessage, intlMessages);
