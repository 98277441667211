import React from 'react';
import PropTypes from 'prop-types';

import DualMetric from '../../analytics/DualMetric';
import MetricValueLabel from '../../chart/MetricValueLabel';
import MetricIcon from '../../icons/Metric';
import MetricsBubbleChart from '../../analytics/bubbleChart/MetricsBubbleChart';

import { buildData } from '../../analytics/bubbleChart/MetricsBubbleChartUtils';

const DUAL_METRIC_CHART_SIZE = { min: 12, max: 24 };
const DUAL_METRIC_COLORS = {
  group: { main: '#4fc3f7', overlay: '#e6194b' },
  member: { main: 'rgba(0, 0, 0, 0.24)', overlay: '#4fc3f7' }
};

const MetricVisualization = props => {
  const { metric, metricsData, targetMembers, group, participantsListShown } = props;
  const { getParticipantProps } = props;
  const metrics = [metric];
  const metricData = metricsData[metric.key];
  return (
    <div>
      {metric.type === 'NUMERIC_DUAL' && metricData && (
        <div className="text-sz-md">
          <DualMetric
            {...metricData}
            size={DUAL_METRIC_CHART_SIZE}
            colors={group ? DUAL_METRIC_COLORS.group : DUAL_METRIC_COLORS.member}
            participantsListShown={participantsListShown}
          />
        </div>
      )}
      {metric.type !== 'NUMERIC_DUAL' && (
        <div className="mui-padded-vertical mui-padded-horizontal-half">
          <div className="container-flex-row ai-center mui-padded-bottom-half mui-margin-bottom">
            <div>
              <MetricIcon metric={metric} size={24} style={{ color: 'rgba(0, 0, 0, 0.32)' }} />
            </div>
            <div className="mui-padded-horizontal flex1">
              <div className="text-sz-reg">
                <strong>
                  {metric.label}
                  <span style={{ color: metric.color, marginLeft: 8 }}>
                    <MetricValueLabel metric={metric} />
                  </span>
                </strong>
              </div>
            </div>
          </div>
          <MetricsBubbleChart
            horizontal
            bubbleSize={28}
            showIcons={false}
            showLabels={false}
            {...buildData(targetMembers, metrics, getParticipantProps)}
          />
        </div>
      )}
    </div>
  );
};

MetricVisualization.propTypes = {
  metric: PropTypes.object.isRequired,
  metricsData: PropTypes.object.isRequired,
  targetMembers: PropTypes.array.isRequired,
  group: PropTypes.bool,
  getParticipantProps: PropTypes.func.isRequired
};

export default MetricVisualization;
