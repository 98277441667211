import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change as changeForm, formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import moment from 'moment';

import RadioButton from 'material-ui/RadioButton';

import Avatar from '../../../mui/Avatar';
import ButtonToggle from '../../../mui/ButtonToggle';
import Text from '../../../superfield/form/SuperText';
import Select from '../../../superfield/form/SuperSelect';
import Toggle from '../../../formFields/Toggle';
import DatePicker from '../../../formFields/DatePicker';

import { EOpportunityType } from '../../../../constants/enum';
import { getOpportunityTypeProps } from '../../../../utils/opportunity';
import { selectOpportunityPostToEdit } from '../../../../selectors/opportunityBuilder';
import { getProgress } from '../Utils';
import { valueHoldersToValues } from '../../../forms/FormUtils';
import { getMaxValidTo } from '../../../../utils/talentMarketPlan';
import { formatMessage } from '../../../../utils/utils';

export const ID = 'opportunityBasicPartForm';

const messages = {
  basic: {
    typeLabel: 'Vyberte typ příležitosti',
    companyLabel: 'Pro kterou společnost vytváříte příležitost',
    titleLabel: 'Název příležitosti',
    titleLabel_JOB: 'Název pozice',
    titleLabel_TALENT_ACADEMY: 'Název talentové akademie',
    rolesLabel: 'Typové role',
    rolesHelperText: 'Vyberte kategorie platné pro příležitost',
    specializationsLabel: 'Specializace',
    recipientsLabel: 'Kdo bude dostávat notifikace o aktivitě',
    recipientsHelperText: 'Když nikoho nespecifikujete, notifikace budou dostávat všichni',
    opportunityTypesLabel: 'Úvazek',
    validIndefinitelyLabel: 'Příležitost je otevřená nepřetržitě',
    validToLabel: 'Příležitost otevřená do',
    locationsLabel: 'Lokalita',
    industriesLabel: 'Jakých odvětví se příležitost týká',
    industriesSetFromCompanyMessage: '{0[Nastavit]} odvětví jako u profilu společnosti',
    maxValidityReached: 'Příležitost může být otevřená maximálně do {0}',
    requiredMessage: 'Vyberte možnost',
    requiredMessageFill: 'Vyplňte pole',
    requiredMessageMulti: 'Vyberte alespoň jednu možnost',
    invalidUrlMessage: 'Neplatný odkaz',
    requiredPoolMessage: 'Vyberte alespoň 1 cílový pool',
    poolsLabel: 'Cílové pooly talentů',
    poolsHelperText: 'Vyberte, do kterých poolů chcete příležitost zveřejnit'
  }
};

const TypeField = props => {
  const {
    input: { onChange, value },
    meta,
    intl,
    helperText,
    indicateRequired
  } = props;
  const { error, touched } = meta || {};
  const enumMessages = intl.messages.constants.enums;
  const renderTypeFieldButton = (type, { label, editDescription }) => {
    const typeProps = getOpportunityTypeProps(type);
    const active = value === type;
    return (
      <div className="col-xs-12 col-sm-6 container-flex-row" style={{ padding: 4 }}>
        <ButtonToggle active={active} onToggle={() => onChange(type)} className="flex-grow">
          <div className="mui-padded-x2 container-flex-row">
            <div>
              <RadioButton checked={active} iconStyle={active ? { fill: 'white' } : {}} />
            </div>
            <Avatar size={40} style={{ marginRight: '16px' }}>
              <span className={`mdi mdi-${typeProps.icon}`} style={{ fontSize: '24px', lineHeight: '24px' }} />
            </Avatar>
            <div className="flex1 text-left">
              <div>{label}</div>
              {editDescription && (
                <div>
                  <small>{editDescription}</small>
                </div>
              )}
            </div>
          </div>
        </ButtonToggle>
      </div>
    );
  };

  const err = touched && error;
  return (
    <div style={{ margin: '16px 0 8px' }}>
      <div className={`mui-fields-label${err ? ' text-error' : ''}`}>
        {messages.basic.typeLabel}
        {indicateRequired ? ' *' : ''}
      </div>
      <div
        className="row container-flex-row fw-yes bg-primary-light mui-padded-half border-radius"
        style={{ margin: 0 }}
      >
        {renderTypeFieldButton(EOpportunityType.JOB.key, enumMessages.EOpportunityType.JOB)}
        {renderTypeFieldButton(EOpportunityType.TALENT_ACADEMY.key, enumMessages.EOpportunityType.TALENT_ACADEMY)}
      </div>
      {err && <div className="mui-fields-error-text">{err || helperText}</div>}
    </div>
  );
};

const BasicPart = props => {
  const {
    dataSources,
    intl,
    validIndefinitely,
    companyProfile,
    type,
    currentCompanyId,
    changeForm,
    handleSubmit
  } = props;
  const profileIndustries = companyProfile?.industries || companyProfile?.draft?.industries;
  const profileHasIndustries = profileIndustries?.length > 0;
  const handleSetIndustries = () => changeForm(ID, 'industries', valueHoldersToValues(profileIndustries));
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12">
          <Field name="type" component={TypeField} intl={intl} indicateRequired />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="opportunityTypes"
            component={Select}
            dataSource={type ? dataSources.opportunityTypes[type] : []}
            label={messages.basic.opportunityTypesLabel}
            multi
            filterable
            indicateRequired
            disabled={!type}
          />
        </div>
      </div>
      {dataSources.companies.length > 1 && (
        <div className="row">
          <div className="col-sm-12">
            <Field
              name="companyId"
              component={Select}
              dataSource={dataSources.companies}
              filterable
              indicateRequired
              label={messages.basic.companyLabel}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="title"
            component={Text}
            label={(type && messages.basic[`titleLabel_${type}`]) || messages.basic.titleLabel}
            indicateRequired
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <Field
            name="roles"
            component={Select}
            label={messages.basic.rolesLabel}
            helperText={messages.basic.rolesHelperText}
            indicateRequired
            dataSource={dataSources.roles}
            multi
            filterable
          />
        </div>
        <div className="col-sm-6">
          <Field
            name="specializations"
            component={Select}
            label={messages.basic.specializationsLabel}
            indicateRequired
            dataSource={dataSources.specializations}
            multi
            filterable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="mui-fields-container mui-padded">
            <Field
              name="validIndefinitely"
              component={Toggle}
              label={messages.basic.validIndefinitelyLabel}
              labelPosition="right"
            />
            {!validIndefinitely && (
              <Field
                name="validTo"
                component={DatePicker}
                label={messages.basic.validToLabel}
                intl={intl}
                fullWidth
                disabled={validIndefinitely}
                style={{ marginTop: '-6px' }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="locations"
            label={messages.basic.locationsLabel}
            component={Select}
            dataSource={dataSources.locations}
            multi
            filterable
            indicateRequired
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="industries"
            label={messages.basic.industriesLabel}
            component={Select}
            dataSource={dataSources.industries}
            helperText={
              profileHasIndustries &&
              formatMessage(messages.basic.industriesSetFromCompanyMessage, [
                val => (
                  <a key="0" role="button" onClick={handleSetIndustries}>
                    {val}
                  </a>
                )
              ])
            }
            multi
            filterable
            indicateRequired
          />
        </div>
      </div>
      {currentCompanyId && (
        <div className="row">
          {dataSources.talentPools.length > 0 && (
            <div className="col-sm-12">
              <Field
                name="poolIds"
                component={Select}
                label={messages.basic.poolsLabel}
                helperText={messages.basic.poolsHelperText}
                dataSource={dataSources.talentPools}
                multi
                filterable
              />
            </div>
          )}
          <div className="col-sm-12">
            <Field
              name="recipientIds"
              component={Select}
              label={messages.basic.recipientsLabel}
              helperText={messages.basic.recipientsHelperText}
              dataSource={dataSources.companyOperators}
              multi
              filterable
            />
          </div>
        </div>
      )}
    </form>
  );
};

const validate = (values, props) => {
  const errors = {};
  if (!values.type) {
    errors.type = messages.basic.requiredMessage;
  }
  if (!values.companyId) {
    errors.companyId = messages.basic.requiredMessage;
  }
  if (!values.title) {
    errors.title = messages.basic.requiredMessageFill;
  }
  if (!values.roles || values.roles.length <= 0) {
    errors.roles = messages.basic.requiredMessageMulti;
  }
  if (!values.specializations || values.specializations.length <= 0) {
    errors.specializations = messages.basic.requiredMessageMulti;
  }
  if (
    props.dataSources &&
    props.dataSources.talentPools.length > 0 &&
    (!values.poolIds || values.poolIds.length <= 0)
  ) {
    errors.poolIds = messages.basic.requiredPoolMessage;
  }
  //
  if (!values.opportunityTypes || values.opportunityTypes.length <= 0) {
    errors.opportunityTypes = messages.basic.requiredMessageMulti;
  }
  if (!values.locations || values.locations.length <= 0) {
    errors.locations = messages.basic.requiredMessageMulti;
  }
  if (!values.industries || values.industries.length <= 0) {
    // so we don't cover up the "set from profile" link button
    errors.industries = true;
  }
  const { talentMarketPlan, intl } = props;
  const maxValidTo = getMaxValidTo(talentMarketPlan);
  if (maxValidTo) {
    const getErrorMessage = () =>
      messages.basic.maxValidityReached.replace('{0}', moment(maxValidTo).locale(intl.locale).format('L'));
    if (values.validIndefinitely) {
      errors.validIndefinitely = getErrorMessage();
    } else if (values.validTo > maxValidTo) {
      errors.validTo = getErrorMessage();
    }
  } else if (!values.validIndefinitely && !values.validTo) {
    errors.validTo = messages.basic.requiredMessageFill;
  }
  return errors;
};

const selector = formValueSelector(ID);

const basicSelectDataSources = state => state.options.dataSources.all;
const basicSelectCompanies = state => state.entities.companies;
const basicSelectCompanyOperators = state => state.entities.companyOperators;
const basicSelectCompanyTalentPools = state => state.entities.talentPools.companyPools;
const basicSelectCurrentCompanyId = state => selector(state, 'companyId');

const localSelectTalentPools = createSelector(
  [basicSelectCompanyTalentPools, basicSelectCurrentCompanyId],
  (tp, cId) => {
    if (tp.companyId === cId) {
      return tp.items;
    }
    return [];
  }
);

const localSelectDataSources = createSelector(
  [basicSelectDataSources, basicSelectCompanies, basicSelectCompanyOperators, localSelectTalentPools],
  (dataSources, companies, companyOperators, talentPools) => {
    return {
      companies: _map(companies, c => ({ value: c.id, label: c.name, description: c.slug })),
      roles: dataSources?.roles || [],
      specializations: dataSources?.specializations || [],
      companyOperators: _map(companyOperators, o => ({
        value: o.id,
        label: o.firstName && o.lastName ? `${o.firstName} ${o.lastName}` : o.email,
        description: o.firstName && o.lastName ? o.email : null
      })),
      talentPools: _map(talentPools, tp => ({ value: tp.id, label: tp.name, description: tp.description })),
      opportunityTypes: {
        [EOpportunityType.JOB.key]: _filter(dataSources?.opportunityTypes || [], o => o.value <= 2) || [],
        [EOpportunityType.TALENT_ACADEMY.key]:
          _filter(dataSources?.opportunityTypes || [], o => 2 < o.value && o.value <= 4) || []
      },
      locations: dataSources?.locations || [],
      industries: dataSources?.industries || []
    };
  }
);

const localSelectCurrentCompany = state => state.entities.currentCompany.item;
const localBasicSelectTalentMarketPlan = state => state.entities.currentCompany.talentMarketPlan;
const localSelectMaxValidTo = createSelector([localBasicSelectTalentMarketPlan], getMaxValidTo);

const localSelectInitialValues = createSelector(
  [
    selectOpportunityPostToEdit,
    basicSelectCompanies,
    localSelectCurrentCompany,
    localSelectTalentPools,
    localSelectMaxValidTo
  ],
  (post, companies, currentCompany, talentPools, maxValidTo) => {
    const opportunity = _get(post, 'opportunity') || {};
    const companyId =
      _get(opportunity, 'company.id') || (companies.length === 1 ? companies[0].id : currentCompany?.id);

    const { matchConfig } = opportunity || {};
    const validTo = (opportunity.validTo && new Date(opportunity.validTo)) || maxValidTo;

    return {
      type: opportunity.type,
      title: post.title,
      companyId,
      roles: valueHoldersToValues(_get(opportunity, 'matchConfig.roles.items')),
      specializations: valueHoldersToValues(_get(opportunity, 'matchConfig.specializations.items')),
      recipientIds: opportunity.recipientIds || [],
      poolIds:
        companyId !== opportunity?.company?.id
          ? talentPools.length === 1
            ? [talentPools[0].id]
            : []
          : _map(post.pools, p => p.pool.id),
      opportunityTypes: valueHoldersToValues(_get(matchConfig, 'types.items')),
      locations: valueHoldersToValues(_get(matchConfig, 'locations.items')),
      industries: valueHoldersToValues(_get(matchConfig, 'industries.items')),
      validTo,
      validIndefinitely: !validTo
    };
  }
);

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    entities: {
      companyProfile: { profile },
      currentCompany: { talentMarketPlan }
    }
  } = state;
  return {
    intl,
    companyProfile: profile,
    talentMarketPlan,
    type: selector(state, 'type'),
    currentCompanyId: selector(state, 'companyId'),
    validIndefinitely: selector(state, 'validIndefinitely'),
    dataSources: localSelectDataSources(state, ownProps),
    initialValues: localSelectInitialValues(state, ownProps)
  };
};

const actions = { changeForm };

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
  shouldError: () => true
};

BasicPart.ID = ID;
BasicPart.getProgressFromValues = v =>
  getProgress([
    () => !!v?.title,
    () => !!v?.type,
    () => v?.roles?.length > 0,
    () => v?.specializations?.length > 0,
    () => v?.opportunityTypes?.length > 0,
    () => v?.locations?.length > 0,
    () => v?.industries?.length > 0
  ]);

export default connect(mapStateToProps, actions)(reduxForm(formConfig)(BasicPart));
