import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _map from 'lodash/map';

import Chip from '../mui/Chip';
import SelectOverlay from '../select/SelectOverlay';

import { getProjectsBasePath } from '../../utils/utils';
import { getProjectsForCrossComparison } from '../../utils/analytics';

import * as ccActions from '../../actions/options/crossComparisonActions';
import withIntl from '../withIntl';

class CrossComparisonProjectSelect extends React.Component {
  getBasePath = () => {
    const { location: { pathname } } = this.props;
    return getProjectsBasePath(pathname);
  };

  handleClose = () => {
    const { switchModal, id } = this.props;
    switchModal({ id, open: false });
  };

  handleProjectSelect = targetProject => {
    const { participants, project, router, options, intl: { locale } } = this.props;
    const { startCrossComparison, loadCrossComparisonParticipants } = this.props;
    const sourceParticipants = _map(participants, p => {
      if (project.id !== p.projectId) {
        const additionalActivities = _get(p, `additionalActivities.results.${p.id}`, []);
        return _find(additionalActivities, a => a.projectId === project.id) || p;
      }
      return p;
    });
    startCrossComparison(sourceParticipants, targetProject, project);
    if (options && options.inPlace) {
      loadCrossComparisonParticipants(targetProject.company.slug, targetProject.slug, locale);
    } else {
      if (!project || targetProject.id !== project.id) {
        router.push(`${this.getBasePath()}/overview/${targetProject.company.slug}/${targetProject.slug}`);
      }
    }

    this.handleClose();
  };

  render() {
    const { projects, intl: { messages }, open } = this.props;
    if (!open) {
      return null;
    }

    const { other, disabled, current, multipleAccounts } = projects;
    return (
      <SelectOverlay
        items={[current, ...other, ...disabled]}
        messages={{ searchPlaceholder: messages.searchPlaceholder }}
        filterFunction={(p, search) => p.name.toLowerCase().indexOf(search) > -1}
        itemRenderer={p => (
          <div>
            {p.id === current.id ? (
              <div>
                <em>{messages.thisProjectLabel}</em>
                <div className="small">{messages.thisProjectHelper}</div>
              </div>
            ) : (
              <div>
                <div>{`${multipleAccounts ? `${p.company.name} - ` : ''}${p.name}`}</div>
                <div className="small">
                  {p.activity.name}
                  <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: 8 }}>
                    <Chip iconLeft={'language'} variant="mini">
                      {p.activity.language.toUpperCase()}
                    </Chip>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        onClose={this.handleClose}
        onSelect={this.handleProjectSelect}
      />
    );
  }
}

const intlMessages = {
  cs: {
    searchPlaceholder: 'Hledat projekt',
    thisProjectLabel: 'Současný projekt',
    thisProjectHelper: 'Zvýrazní účastníky v analytice'
  },
  sk: {
    searchPlaceholder: 'Hľadať projekt',
    thisProjectLabel: 'Súčasný projekt',
    thisProjectHelper: 'Zvýrazní účastníkov v analytike'
  },
  en: {
    searchPlaceholder: 'Search projects',
    thisProjectLabel: 'Current project',
    thisProjectHelper: 'Highlights participants in the analytics'
  }
};

const selectCurrentProject = (state, ownProps) => ownProps.project;
const selectAllProjects = state => state.entities.projects;
const selectProjectsForCrossComparison = createSelector(
  [selectCurrentProject, selectAllProjects],
  getProjectsForCrossComparison
);

const mapStateToProps = (state, ownProps) => {
  return {
    projects: selectProjectsForCrossComparison(state, ownProps)
  };
};

const actions = {
  startCrossComparison: ccActions.startCrossComparison,
  loadCrossComparisonParticipants: ccActions.loadCrossComparisonParticipants
};

export default withIntl(withRouter(connect(mapStateToProps, actions)(CrossComparisonProjectSelect)), intlMessages);
