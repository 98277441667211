import React from 'react';
import cn from 'classnames';

import ArrowDown from 'material-ui/svg-icons/navigation/arrow-downward';
import ArrowUp from 'material-ui/svg-icons/navigation/arrow-upward';

import GridLabel from './GridLabel';

const arrowStyle = {
  width: '16px',
  height: '16px',
  marginRight: '6px',
  color: 'rgba(33,33,33,.87)'
};

export default ({ sort, onSort, currentSort, currentOrder, className, children, ...props }) => (
  <GridLabel
    className={cn({ [className]: className, active: sort === currentSort, sortable: true })}
    {...props}
    onClick={e => onSort(e, sort, currentOrder)}
  >
    {sort === currentSort && currentOrder && currentOrder.toLowerCase() === 'asc' && <ArrowUp style={arrowStyle} />}
    {sort === currentSort && currentOrder && currentOrder.toLowerCase() === 'desc' && <ArrowDown style={arrowStyle} />}
    <div className="sortable-grid-label-content">{children}</div>
  </GridLabel>
);
