import React from 'react';

import withIntl from '../withIntl';

class IEUnsupportedPage extends React.Component {
  state = {
    hidden: true
  };

  componentDidMount() {
    const ua = window.navigator.userAgent;
    const isIE = /MSIE|Trident\//.test(ua);
    if (isIE) {
      this.setState({ hidden: false });
    }
  }

  render() {
    const { hidden } = this.state;
    if (hidden) {
      return null;
    }
    const { intl } = this.props;
    const messages = intl.messages.components.ieUnsupported;

    return (
      <div className="bg-white">
        <div className="bg-warning text-warning">
          <div className="container-flex-row ai-center jc-space-between mui-padded">
            <div className="mui-padded">
              <strong>{messages.title}</strong>
              <br/>
              <span style={{ opacity: 0.87, fontSize: '80%' }}>{messages.subtitle}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(IEUnsupportedPage);
