import analyticsMiddleware from './analyticsMiddleware';
import apiMiddleware from './apiMiddleware';
import apiMiddlewareV2 from './apiMiddlewareV2';
import smartsuppMiddleware from './smartsuppMiddleware';
import notificationsMiddleware from './notificationsMiddleware';
import flowMiddleware from './flowMiddleware';
import routeChangeMiddleware from './routeChangeMiddleware';

import guideMiddleware from './options/guideMiddleware';

export default [
  analyticsMiddleware,
  apiMiddleware,
  apiMiddlewareV2,
  smartsuppMiddleware,
  notificationsMiddleware,
  guideMiddleware,
  flowMiddleware,
  routeChangeMiddleware
];
