import React from 'react';
import _map from 'lodash/map';
import _values from 'lodash/values';

import Chip from '../mui/Chip';
import ActivityToggle from '../activity/ActivityToggle';
import SuperSelect from '../superfield/SuperSelect';

import languages from '../../constants/languages';

import { getActivityMapKey } from './TeamUtils';

import withIntl from '../withIntl';

const renderActivities = (team, templates, aMap, messages, onToggleActivity, onProjectEdit) => {
  return (
    <div className="row container-flex-row fw-yes bg-primary-light mui-padded-half border-radius">
      {_map(templates, t => {
        const mapKey = getActivityMapKey(t);
        const a = aMap[mapKey];
        const p = a && a.project;
        const active = !!a;
        return (
          <div key={mapKey} className="col-xs-12 col-sm-6 col-md-4 container-flex-row mui-padded-half">
            <div className="container-flex-column flex-grow">
              <div className="container-flex-row flex1">
                <ActivityToggle
                  type="checkbox"
                  active={active}
                  onToggle={() => onToggleActivity(t, !active)}
                  activity={t}
                />
              </div>
              {p && team && (
                <div
                  className="bg-highlight__100 mui-padded-x2 text-right"
                  style={{ borderRadius: '2px', marginTop: '2px' }}
                >
                  <a role="button" onClick={() => onProjectEdit(p)} className="link-button heavy">
                    <i className="material-icons">settings</i>
                    <span>{messages.goToProjectLabel}</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const langValues = _values(languages);

const renderLanguage = (l, active, disabled, onToggle, other) => (
  <div key={l.value} className="mui-padded-half inline-container">
    <Chip
      variant="compact"
      active={active}
      iconLeft={other ? (active ? 'remove' : 'add') : active && 'check'}
      onClick={!disabled && (() => onToggle(l, !active))}
      disabled={disabled}
    >
      {l.label}
    </Chip>
  </div>
);

const renderOtherLanguages = (lMap, primaryLang, onToggleLanguage) => {
  return (
    <div style={{ margin: '-4px' }}>
      {_map(langValues, l =>
        renderLanguage(l, l.value === primaryLang || !!lMap[l.value], l.value === primaryLang, onToggleLanguage, true)
      )}
    </div>
  );
};

const TeamSubGroupConfig = props => {
  const { config, intl, templates, team } = props;
  const { onPrimaryLanguageChange, onOtherLanguageToggle, onActivityToggle, onProjectEdit } = props;
  const { activities, languages, primaryLanguage } = config || {};
  const messages = intl.messages.components.teams.subGroup.config;
  return (
    <div className="team__subgroup-config">
      <div>
        {/*<div className="mui-padded-vertical">*/}
        {/*  <div className="text-muted">{messages.message}</div>*/}
        {/*</div>*/}
        <div className="team__subgroup-config-section">
          <h5 className="mui-margin-vertical">{messages.assessmentsLabel}</h5>
          <div className="mui-padded-vertical">
            <div className="container-fluid">
              {renderActivities(team, templates, activities, messages, onActivityToggle, onProjectEdit)}
            </div>
          </div>
        </div>
        <div className="team__subgroup-config-section">
          <h5 className="mui-margin-vertical">{messages.languagesLabel}</h5>
          <div className="mui-padded-vertical">
            <SuperSelect
              style={{ margin: 0, maxWidth: '320px' }}
              dataSource={langValues}
              values={primaryLanguage}
              onChange={onPrimaryLanguageChange}
              label={messages.primaryLanguageLabel}
            />
          </div>
          <div className="mui-padded-vertical">
            <div className="mui-fields-label">{messages.otherLanguagesLabel}</div>
            {renderOtherLanguages(languages, primaryLanguage && primaryLanguage[0], onOtherLanguageToggle)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withIntl(TeamSubGroupConfig);
