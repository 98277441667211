import { createSelector } from 'reselect';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';

const basicSelectDataSources = state => state.options.dataSources.all;

/*
 LOCATIONS
 INDUSTRIES
 BENEFITS
 PERSONAL_DEVELOPMENT_AREAS
 OPPORTUNITY_TYPES
 EDUCATION_FIELDS
 LANGUAGES
 */

const toMap = tree => {
  const q = map(tree, item => ({ item, path: [item.value] }));
  const res = {};
  while (q.length) {
    const { item: { children, ...item }, path } = q.shift();
    if (!res[item.value]) {
      for (let i = 0; i < (children || []).length; i += 1) {
        const c = children[i];
        q.push({ item: c, path: [...path, c.value] });
      }
      res[item.value] = { item, path };
    }
  }
  return res;
};

export const selectDataSourcesMap = createSelector([basicSelectDataSources], dataSources => {
  return mapValues(dataSources || {}, item => toMap(item));
});
