import React from 'react';
import PropTypes from 'prop-types';

import Popover from '../mui/Popover';

const ORIGINS = {
  top: {
    anchorOrigin: { horizontal: 'middle', vertical: 'top' },
    targetOrigin: { horizontal: 'middle', vertical: 'bottom' }
  },
  bottom: {
    anchorOrigin: { horizontal: 'middle', vertical: 'bottom' },
    targetOrigin: { horizontal: 'middle', vertical: 'top' }
  },
  'bottom-left': {
    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
    targetOrigin: { horizontal: 'left', vertical: 'top' }
  },
  left: {
    anchorOrigin: { horizontal: 'left', vertical: 'center' },
    targetOrigin: { horizontal: 'right', vertical: 'center' }
  },
  right: {
    anchorOrigin: { horizontal: 'right', vertical: 'center' },
    targetOrigin: { horizontal: 'left', vertical: 'center' }
  }
};

const getOrigins = position => ORIGINS[position] || ORIGINS.bottom;

class Tooltip extends React.Component {
  state = {
    over: false,
    open: false
  };

  componentWillUnmount() {
    this.clearTimeouts();
  }

  clearTimeouts = () => {
    this.clearShowTimeout();
    this.clearHideTimeout();
  };
  clearShowTimeout = () => {
    if (this.showTimeout) {
      clearTimeout(this.showTimeout);
    }
  };
  clearHideTimeout = () => {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
    }
  };
  setRef = el => {
    this.element = el;
  };
  handleMouseEnter = () => {
    const { delay } = this.props;
    this.clearHideTimeout();
    if (delay) {
      this.setState({ over: true });
      this.showTimeout = setTimeout(() => {
        this.setState({ open: true });
      }, delay);
    } else {
      this.setState({ over: true, open: true });
    }
  };
  handleMouseLeave = () => {
    this.setState({ over: false });
    this.clearTimeouts();
    this.hideTimeout = setTimeout(() => {
      this.setState({ open: false });
    }, 200);
  };
  handleClose = () => this.setState({ open: false });

  render() {
    const { content, position, children, style, contentStyle, tooltipStyle } = this.props;
    const { open } = this.state;
    const popoverStyle = { padding: '4px', background: 'none', boxShadow: 'none', ...tooltipStyle };
    return (
      <div
        ref={this.setRef}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={{ cursor: 'pointer', display: 'inline-block', ...style }}
      >
        {children}
        <Popover
          anchorEl={this.element}
          open={content && open}
          onRequestClose={this.handleClose}
          useLayerForClickAway={false}
          style={popoverStyle}
          {...getOrigins(position)}
        >
          <div
            className="mui-card mui-padded-horizontal mui-padded-half text-sz-sm text-white"
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            style={{ maxWidth: '30em', background: 'rgba(0, 0, 0, 0.72)', ...contentStyle }}
          >
            {content}
          </div>
        </Popover>
      </div>
    );
  }
}

Tooltip.propTypes = {
  content: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'bottom-left', 'left', 'right']),
  style: PropTypes.object,
  contentStyle: PropTypes.object,
  tooltipStyle: PropTypes.object,
  delay: PropTypes.number
};

export default Tooltip;
