import React from 'react';
import { ReactReduxContext, Provider } from 'react-redux';
import cn from 'classnames';

import MD from 'material-ui/Dialog';

import { BRAND } from '../../constants/constants';

const Dialog = (props) => {
  const { className, children, ...rest } = props;
  const dialogClassName = cn({
    [`dialog-container brand-${BRAND.key.toLowerCase()}`]: true,
    [className]: className
  });
  return (
    <ReactReduxContext.Consumer>
      {(ctx) => (
        <MD className={dialogClassName} {...rest}>
          <Provider store={ctx.store}>{children}</Provider>
        </MD>
      )}
    </ReactReduxContext.Consumer>
  );
};

export default Dialog;
