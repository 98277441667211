import React from 'react';
import PropTypes from 'prop-types';

import { formatMessage, selectQuantityMessage } from '../../utils/utils';

import withIntl from '../withIntl';

const MembersCountMessage = props => {
  const {
    type,
    count,
    filteredCount,
    intl: { messages }
  } = props;
  const baseMessages = (type && messages.countMessages[type]) || messages.countMessage;

  const quantity = filteredCount != null ? filteredCount : count;
  const quantityMessage = selectQuantityMessage(baseMessages, quantity);

  const args =
    filteredCount != null
      ? [
          <span key="membersCount">
            <b>{filteredCount}</b>
            <small> / {count}</small>
          </span>
        ]
      : [<b key="membersCount">{count}</b>];
  return <span>{formatMessage(quantityMessage, args)}</span>;
};

MembersCountMessage.propTypes = {
  type: PropTypes.string,
  count: PropTypes.number.isRequired,
  filteredCount: PropTypes.number
};

const intlMessages = {
  cs: {
    countMessage: {
      0: 'Zatím žádní členové',
      1: '{0} člen',
      '2-4': '{0} členové',
      '>4': '{0} členů'
    },
    countMessages: {
      HIRING: {
        0: 'Zatím žádní uchazeči',
        1: '{0} uchazeč',
        '2-4': '{0} uchazeči',
        '>4': '{0} uchazečů'
      }
    }
  },
  sk: {
    countMessage: {
      0: 'Zatiaľ žiadni členovia',
      1: '{0} člen',
      '2-4': '{0} členovia',
      '>4': '{0} členov'
    },
    countMessages: {
      HIRING: {
        0: 'Zatiaľ žiadni uchádzači',
        1: '{0} uchádzač',
        '2-4': '{0} uchádzači',
        '>4': '{0} uchádzačov'
      }
    }
  },
  en: {
    countMessage: {
      0: 'No members yet',
      1: '{0} member',
      '>1': '{0} members'
    },
    countMessages: {
      HIRING: {
        0: 'No candidates yet',
        1: '{0} candidate',
        '>1': '{0} candidates'
      }
    }
  }
};

export default withIntl(MembersCountMessage, intlMessages);
