import React from 'react';

class FadedContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      contentHeight: 0
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleElementUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleElementUpdate);
  }

  getMaxHeight = () => this.props.maxHeight || 64;

  handleExpand = () => this.setState({ expanded: true });
  handleCollapse = () => this.setState({ expanded: false });

  setContentElem = el => {
    this.contentElem = el;
    this.handleElementUpdate();
  };

  handleElementUpdate = () => {
    if (this.contentElem) {
      this.setState({ contentHeight: this.contentElem.offsetHeight });
    }
  };

  render() {
    const { children } = this.props;
    const { expanded, contentHeight } = this.state;
    const height = this.getMaxHeight();
    const overflow = height < contentHeight;
    const containerStyle = !expanded && overflow ? { height } : { height: contentHeight };
    return (
      <div className={`faded-content${overflow ? ' overflown' : ''}`}>
        <div className="faded-content__container" style={containerStyle}>
          <div ref={this.setContentElem}>{children}</div>
        </div>
        {overflow && (
          <div
            className={`faded-content__overlay-container${expanded ? ' expanded' : ''}`}
          >
            <div className="faded-content__overlay-content">
              <a
                role="button"
                onClick={expanded ? this.handleCollapse : this.handleExpand}
              >
                <span className={'mdi mdi-menu-down'} />
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FadedContent;
