import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import Checkbox from 'material-ui/Checkbox';

import Overlay from '../basic/Overlay';
import CopyToClipboardInput from '../basic/CopyToClipboardInput';
import SearchField from '../mui/SearchField';
import Button from '../mui/Button';
import Chip from '../mui/Chip';

import { KC, determineKeyCode } from '../superfield/Utils';
import { splitToEmails } from '../../utils/utils';

import * as Api from '../../api/entities/groupApi';

const DEFAULT_PAGINATION = { page: 0, size: 10 };

class TeamMemberSelect extends React.Component {
  state = {
    items: [],
    loading: 0,
    invitationLoading: 0,
    search: '',
    menuOpen: true,
    selection: {
      items: [],
      byId: {},
      byEmail: {}
    },
    invitation: {},
    autoInvitationEnabled: false
  };

  componentDidMount() {
    this.loadItems();
    this.loadInvitationData();
  }

  componentWillUnmount() {
    if (this._loadItemsTimeout) {
      clearTimeout(this._loadItemsTimeout);
    }
  }

  loadInvitationData = () => {
    const { groupId, membership } = this.props;
    this.setState({ invitationLoading: this.state.invitationLoading + 1 });
    Api.getGroupInvitationData(groupId, membership.key)
      .then(({ payload: { url } }) => this.setState({ invitation: { url } }))
      .finally(() => this.setState({ invitationLoading: Math.max(0, this.state.invitationLoading - 1) }));
  };

  loadItems = (search = '') => {
    const { groupId, intl } = this.props;
    this.setState({ search });
    if (this._loadItemsTimeout) {
      clearTimeout(this._loadItemsTimeout);
    }
    this._loadItemsTimeout = setTimeout(() => {
      this.setState({ loading: this.state.loading + 1 });
      Api.getGroupMemberCandidates(groupId, search, intl.locale, false, DEFAULT_PAGINATION)
        .then(({ items }) => {
          if (this.state.loading === 1) {
            this.setState({ items, menuOpen: true });
          }
        })
        .finally(() => this.setState({ loading: Math.max(0, this.state.loading - 1) }));
      this._loadItemsTimeout = null;
    }, 500);
  };

  setEmails = search => {
    const emails = this.valueToEmails(search);
    const { single } = this.props;
    if (emails.length > 0) {
      const { selection } = this.state;
      const newSelection = { ...selection };
      const byEmail = single ? {} : { ...newSelection.byEmail };
      const loopBound = single ? 1 : emails.length;
      for (let i = 0; i < loopBound; i += 1) {
        const { email } = emails[i];
        const emailKey = email.toLowerCase();
        if (!byEmail[emailKey]) {
          newSelection.items = single ? [{ email }] : [...newSelection.items, { email }];
        }
        byEmail[emailKey] = true;
      }
      newSelection.byEmail = byEmail;
      this.setState({ selection: newSelection, search: '', items: [], menuOpen: false });
      return true;
    }
    return false;
  };

  handleSearchChange = search => {
    if (!search) {
      this.setState({ search: '', items: [], loading: 0, menuOpen: false });
    } else {
      if (/(,|\s)/.test(search)) {
        if (this.setEmails(search)) {
          return;
        }
      }
      this.loadItems(search);
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleSelect = () => {
    const {
      selection: { items },
      autoInvitationEnabled
    } = this.state;
    this.props.onSelect(items, autoInvitationEnabled);
  };

  isInSelection = m => {
    const { id, email } = m;
    const { selection } = this.state;
    if (id) {
      return selection.byId[id];
    }
    return email && selection.byEmail[email.toLowerCase()];
  };

  valueToEmails = (value, filterFunction) => {
    const { auth } = this.props;
    if (!value || !auth.canUseEmailInvitation) {
      return [];
    }
    const valueFactory = v => ({ email: v });
    return splitToEmails(value, { filterFunction, valueFactory }).values;
  };

  splitSearchToEmails = () => {
    const { search } = this.state;
    return this.valueToEmails(search, ({ email }) => !this.isInSelection({ email }));
  };

  toggleSelection = (e, m) => {
    e.stopPropagation();
    const { single } = this.props;
    const { selection } = this.state;
    const { email, id } = m;
    const newSelection = { ...selection };
    let found = false;
    let byId = { ...selection.byId };
    let byEmail = { ...selection.byEmail };
    if (id) {
      if (byId[id]) {
        delete byId[id];
        found = true;
      } else {
        if (single) {
          byId = { [id]: true };
          byEmail = {};
        } else {
          byId[id] = true;
        }
      }
    } else if (email) {
      const emailKey = email.toLowerCase();
      if (byEmail[emailKey]) {
        delete byEmail[emailKey];
        found = true;
      } else {
        if (single) {
          byId = {};
          byEmail = { [emailKey]: true };
        } else {
          byEmail[emailKey] = true;
        }
      }
    } else {
      return false;
    }
    newSelection.byId = byId;
    newSelection.byEmail = byEmail;
    if (single) {
      newSelection.items = found ? [] : [m];
    } else {
      newSelection.items = found
        ? _filter(selection.items, i =>
            id ? i.id !== id : !i.email || !email || i.email.toLowerCase() !== email.toLowerCase()
          )
        : [...selection.items, m];
    }
    if (!m.id || single) {
      this.setState({ selection: newSelection, search: '', menuOpen: false });
    } else {
      this.setState({ selection: newSelection });
    }
  };

  handleCloseMenu = () => {
    this.setState({ menuOpen: false });
  };

  handleOpenMenu = () => {
    this.setState({ menuOpen: true });
  };

  handleKeyDown = e => {
    const kc = determineKeyCode(e);
    switch (kc) {
      case KC.ESC:
        this.setState({ menuOpen: false });
        return;
      case KC.ENTER:
        const { items, search } = this.state;
        if (items.length > 0) {
          this.toggleSelection(e, items[0]);
        } else {
          this.setEmails(search);
        }
        return;
      default:
        return;
    }
  };

  handleToggleAutoInvitation = () => this.setState({ autoInvitationEnabled: !this.state.autoInvitationEnabled });

  render() {
    const { single, intl, auth } = this.props;
    const { items, selection, search, menuOpen, invitation, autoInvitationEnabled, loading } = this.state;
    const menuItems = items.length > 0 ? items : this.splitSearchToEmails();
    let messages = intl.messages.components.teams.memberSelect;
    if (!auth.canUseEmailInvitation) {
      messages = { ...messages, ...messages.emailInvitationDisabled };
    }
    return (
      <Overlay onRequestClose={this.handleClose} full noBlur>
        <div className="team__member-select" onClick={this.handleCloseMenu}>
          <div className="team__member-select-container">
            <div className="mui-card">
              <div onClick={e => e.stopPropagation()} className="border-bottom border-color-primary__50">
                <SearchField
                  placeholder={messages.searchPlaceholder}
                  value={search}
                  loading={loading > 0}
                  onValueChange={this.handleSearchChange}
                  onFocus={this.handleOpenMenu}
                  onKeyDown={this.handleKeyDown}
                />
              </div>
              <div className="team__member-select-content clearfix">
                <div className="team__member-select-selection mui-padded">
                  {selection.items.length > 0 && (
                    <div className="mui-padded">
                      {_map(selection.items, m => (
                        <div key={m.id || m.email} className="mui-chip-container">
                          <Chip
                            variant="compact"
                            active
                            onClick={e => this.toggleSelection(e, m)}
                            iconRight={'remove_circle'}
                          >
                            {m.lastName && m.firstName ? `${m.lastName} ${m.firstName}` : m.email}
                          </Chip>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="mui-padded">
                    {selection.items.length > 0 ? (
                      <div>
                        <Checkbox
                          label={messages.autoInviteCheckboxLabel}
                          labelStyle={{ margin: 0, fontWeight: 400 }}
                          onCheck={this.handleToggleAutoInvitation}
                          checked={autoInvitationEnabled}
                        />
                      </div>
                    ) : (
                      <div className="mui-padded text-center text-muted">{messages.emptyMessage}</div>
                    )}
                  </div>
                </div>
                <div className="text-right mui-padded">
                  <Button label={messages.buttonCancel} onClick={this.handleClose} />
                  <Button
                    raised
                    primary
                    disabled={selection.items.length <= 0}
                    label={single ? messages.buttonConfirmSingle : messages.buttonConfirm}
                    onClick={this.handleSelect}
                    style={{ marginLeft: 8 }}
                  />
                </div>
              </div>
            </div>
            {invitation && invitation.url && (
              <div className="mui-padded-vertical-x2">
                <div className="well" style={{ backdropFilter: 'blur(2px)' }}>
                  {messages.invitationLinkMessage}
                  <CopyToClipboardInput label={messages.invitationLinkLabel} value={invitation.url} />
                </div>
              </div>
            )}
            {menuItems.length > 0 && menuOpen && (
              <div className="team__member-select-menu mui-card">
                {_map(menuItems, m => (
                  <div
                    key={m.id || m.email}
                    role="button"
                    className="team__member-select-menu-item container-flex-row ai-center mui-item-hoverable"
                    onClick={e => this.toggleSelection(e, m, menuItems)}
                  >
                    {m.id ? (
                      <div className="flex1">
                        {m.lastName && m.firstName && (
                          <span>
                            {m.lastName} {m.firstName}{' '}
                          </span>
                        )}
                        <small>{m.email}</small>
                      </div>
                    ) : (
                      <div className="flex1">{m.email}</div>
                    )}
                    {this.isInSelection(m) ? (
                      <div>
                        <i className="material-icons text-success">check</i>
                      </div>
                    ) : (
                      !m.id && <small>{messages.clickToAddLabel}</small>
                    )}
                  </div>
                ))}
                {items.length > 0 && (
                  <div className="team__member-select-menu-item mui-padded text-right" onClick={this.handleCloseMenu}>
                    <a role="button" className="link-button" onClick={this.handleCloseMenu}>
                      <span>{messages.closeMenuLabel}</span>
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Overlay>
    );
  }
}

export default connect(({ auth, intl }) => ({ auth, intl }))(TeamMemberSelect);
