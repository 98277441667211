import React from 'react';

import FlatButton from 'material-ui/FlatButton';

import Card from '../mui/Card';

const CallToActionCard = props => {
  const {
    title,
    message,
    icon,
    onActionClick,
    actionContainer,
    actionLabel,
    actionIcon,
    actionDisabled,
    actionHelperText
  } = props;
  return (
    <Card>
      <div className="container-flex-row ai-start">
        {icon && <div style={{ padding: '24px 0 0 16px' }}>{icon}</div>}
        <div className="mui-padded-x2">
          <h4>{title}</h4>
          <p className="text-muted mui-margin-top-x2">{message}</p>
        </div>
      </div>
      <div className="mui-card-actions with-background">
        <FlatButton
          primary
          containerElement={actionContainer}
          label={actionLabel}
          onClick={onActionClick}
          fullWidth
          style={{ height: '56px', lineHeight: '56px' }}
          icon={actionIcon}
          disabled={actionDisabled}
        />
        {actionHelperText && (
          <div className="border-color-primary__50 border-top mui-margin-top-half mui-padded-horizontal mui-padded-top text-muted text-sz-sm">
            {actionHelperText}
          </div>
        )}
      </div>
    </Card>
  );
};

export default CallToActionCard;
