import React from 'react';
import { pure } from 'recompose';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import CultureFitAnalytics from './CultureFitAnalytics';
import EchoAnalytics from './EchoAnalytics';
import CompetencyBarChart from '../analytics/CompetencyBarChart';
import ParticipantName from '../basic/ParticipantName';
import AnalyticsTeaserMessage from './AnalyticsTeaserMessage';
import ActivityParticipantInterpretations from './ActivityParticipantInterpretations';

import {
  getActivityMapKey,
  getActivityMetricDefinitions,
  transformOrGenerateMember,
  saveSubgroupAnalyticsState,
  getSubgroupAnalyticsState
} from './TeamUtils';
import { getNumericalMetrics } from '../../utils/analytics';
import { formatMessage } from '../../utils/utils';

import { EActivityType } from '../../constants/enum';
import ActivityToggleHeader from './ActivityToggleHeader';
import Button from '../mui/Button';

const TeaserMessage = props => {
  const { member, activity, memberActivity, onAdd, onInvite, onRemind, messages } = props;
  let icon;
  let message;
  if (!member) {
    icon = 'add';
    message = (
      <span>
        {formatMessage(messages.teasers.addManagerMessage, [
          val => (
            <div key={'addButton'} className="inline-container mui-padded">
              <Button onClick={onAdd} primary raised label={val} />
            </div>
          )
        ])}
      </span>
    );
  } else if (!memberActivity || memberActivity.sample) {
    icon = 'email';
    message = (
      <span>
        {formatMessage(messages.teasers.inviteManagerMessage, [<ParticipantName key="memberName" {...member} />])}
        <div className="inline-container mui-padded">
          <Button onClick={() => onInvite(member, [activity])} primary raised label={messages.teasers.inviteLabel} />
        </div>
      </span>
    );
  } else {
    const { canNotify } = memberActivity.notificationPreferences || {};
    const onClick = canNotify && (() => onRemind(member, [memberActivity], [memberActivity]));
    icon = canNotify ? 'alarm' : 'alarm_off';
    message = (
      <span>
        {formatMessage(messages.teasers.remindManagerMessage, [<ParticipantName key="memberName" {...member} />])}
        <div className="inline-container mui-padded">
          <Button onClick={onClick} primary raised label={messages.teasers.remindLabel} disabled={!canNotify} />
        </div>
      </span>
    );
  }
  return <AnalyticsTeaserMessage icon={icon} message={message} />;
};

let ActivityAnalytics = props => {
  const { configuration, readOnly, manager, activity, hidden, chartShown, interpretationShown, intl } = props;
  const { onAdd, onInvite, onRemind, onToggleHidden, onShowProfile } = props;
  const activityMetricDefinitions = (activity && getActivityMetricDefinitions(activity, configuration)) || {};
  const managerParticipant = transformOrGenerateMember(manager, activity, activityMetricDefinitions);
  const isSample = managerParticipant.result && managerParticipant.result.sample;
  const messages = intl.messages.components.teams.activityAnalytics;
  return (
    <div>
      <div>
        <div className="mui-padded-horizontal">
          <ActivityToggleHeader activity={activity} onToggle={onToggleHidden} expanded={!hidden} />
        </div>
        {!hidden && (
          <div className="team__manager-competency-analytics-content">
            {chartShown && (
              <div style={{ position: 'relative' }}>
                {isSample && (
                  <div className="teaser-overlay">
                    {!readOnly && (
                      <TeaserMessage
                        member={manager}
                        memberActivity={managerParticipant && managerParticipant.activity}
                        activity={activity}
                        onAdd={onAdd}
                        onInvite={onInvite}
                        onRemind={onRemind}
                        messages={messages}
                      />
                    )}
                  </div>
                )}
                {EActivityType.OFFICE_DAY.value === activity.key && (
                  <div className="container-padded-big">
                    <div className="container-chart-card">
                      <CompetencyBarChart
                        participant={managerParticipant}
                        metrics={getNumericalMetrics(activityMetricDefinitions)}
                      />
                    </div>
                  </div>
                )}
                {EActivityType.GAME_CHANGER.value === activity.key && (
                  <div className="container-padded-big">
                    <div className="container-chart-card">
                      <CompetencyBarChart
                        participant={managerParticipant}
                        metrics={getNumericalMetrics(activityMetricDefinitions)}
                      />
                    </div>
                  </div>
                )}
                {EActivityType.CULTURE_FIT.isLike(activity) && (
                  <div>
                    <CultureFitAnalytics
                      metricDefinitions={activityMetricDefinitions}
                      participants={[managerParticipant]}
                    />
                  </div>
                )}
                {EActivityType.ECHO.value === activity.key && (
                  <div>
                    <EchoAnalytics
                      metricDefinitions={activityMetricDefinitions}
                      participants={[managerParticipant]}
                      onItemClick={item => onShowProfile(item, activity)}
                    />
                  </div>
                )}
              </div>
            )}
            {!isSample && interpretationShown && (
              <ActivityParticipantInterpretations participant={managerParticipant} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ActivityAnalytics = connect(({ intl }) => ({ intl }))(ActivityAnalytics);

const initialProps = {
  hiddenActivities: {}
};

class ManagerCompetencyAnalytics extends React.Component {
  state = initialProps;

  componentDidMount() {
    this.loadState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.team !== prevProps.team) {
      this.loadState();
    } else {
      this.saveState();
    }
  }

  loadState = () => {
    const { team, type } = this.props;
    if (team && type) {
      const state = getSubgroupAnalyticsState(team, type);
      if (state) {
        this.setState(state);
      } else {
        this.setState(initialProps);
      }
    }
  };

  saveState = () => {
    const { team, type } = this.props;
    if (team && type) {
      saveSubgroupAnalyticsState(team, type, this.state);
    }
  };

  getActivities = () => {
    return _filter(this.props.activities, a => a.key !== EActivityType.CULTURE_FIT.value);
  };

  handleToggleActivity = a => {
    const key = getActivityMapKey(a);
    const { [key]: hidden, ...hiddenActivities } = this.state.hiddenActivities;
    if (!hidden) {
      hiddenActivities[key] = true;
    }
    this.setState({ hiddenActivities });
  };

  render() {
    const { configuration, readOnly, manager, chartsShown, interpretationsShown } = this.props;
    const { onAdd, onInvite, onRemind, onShowProfile } = this.props;
    if (!chartsShown && !interpretationsShown) {
      return null;
    }
    const { hiddenActivities } = this.state;
    const activities = this.getActivities();
    return (
      <div className="team__manager-competency-analytics">
        {_map(activities, a => {
          const mapKey = getActivityMapKey(a);
          return (
            <ActivityAnalytics
              key={mapKey}
              readOnly={readOnly}
              activity={a}
              configuration={configuration}
              manager={manager}
              hidden={hiddenActivities[mapKey]}
              onToggleHidden={this.handleToggleActivity}
              onAdd={onAdd}
              onRemind={onRemind}
              onInvite={onInvite}
              onShowProfile={onShowProfile}
              chartShown={chartsShown}
              interpretationShown={interpretationsShown}
            />
          );
        })}
      </div>
    );
  }
}

export default pure(ManagerCompetencyAnalytics);
