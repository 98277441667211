import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import allActions from './../../actions';

import {
  MODAL_CONFIRM_ACTIVATE,
  MODAL_CONFIRM_DELETE,
  MODAL_CONFIRM_FINISH,
  MODAL_PROJECT_COPY,
} from '../../constants/constants';
import { selectVisibleProjects } from '../../selectors/projects';

import CompanySelect from './company/CompanySelect';
import ProjectsPage from '../../components/pages/private/Projects';
import { getProjectsBasePath, loadToken } from '../../utils/utils';
import { selectAccountPermissions } from '../../selectors/auth';

class Projects extends React.Component {
  static propTypes = {
    getCompanies: PropTypes.func,
    getProjectsAll: PropTypes.func.isRequired,
    switchModal: PropTypes.func,
    router: PropTypes.object,
    intl: PropTypes.object,
    auth: PropTypes.object,
    companies: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
  };

  componentWillUnmount() {
    this.onProjectPageChange(0, false);
  }

  getBasePath = () => {
    const {
      location: { pathname },
    } = this.props;
    return getProjectsBasePath(pathname);
  };

  goTo = (path) => this.props.router.push(path);

  onProjectPageChange = (page, infinite) => {
    const { setProjectsPage } = this.props;
    setProjectsPage(page, infinite);
  };

  onProjectAdd = () => {
    this.goTo(`${this.getBasePath()}/new`);
  };

  onShowProjectDetail = (companySlug, projectSlug) => {
    this.goTo(`${this.getBasePath()}/overview/${companySlug}/${projectSlug}`);
  };

  onEditProject = (companyId, projectId) => {
    this.goTo(`${this.getBasePath()}/${companyId}/${projectId}/edit`);
  };

  onExportProject = (companyId, projectId, locale, type) => {
    setTimeout(() => {
      window.open(
        `/api/v1/companies/${companyId}/projects/${projectId}/results/data/export?type=${type}&lang=${locale}&access_token=${loadToken()}`
      );
    }, 100);
  };

  onActivateProject = (project) => {
    this.props.switchModal({
      id: MODAL_CONFIRM_ACTIVATE,
      open: true,
      projectName: project.name,
      args: [project],
    });
  };

  onFinishProject = (project) => {
    this.props.switchModal({ id: MODAL_CONFIRM_FINISH, open: true, projectName: project.name, args: [project] });
  };

  onDeleteProject = (project) => {
    this.props.switchModal({ id: MODAL_CONFIRM_DELETE, open: true, projectName: project.name, args: [project] });
  };

  onInviteApplicants = (companySlug, projectSlug) => {
    this.props.getProjectInvitationUrls(companySlug, projectSlug);
  };

  handleCopyProject = (project) => {
    this.props.switchModal({ id: MODAL_PROJECT_COPY, open: true, project });
  };

  refreshData = (callApi = true) => {
    const { getCompanies, getProjectsAll } = this.props;
    return getCompanies(callApi).then((companies) => {
      getProjectsAll(companies.items);
    });
  };

  render() {
    const { companies, currentCompany, projects, intl, auth, filter, visibleProjects } = this.props;

    if (companies === null) {
      return null;
    }

    const pageProps = {
      auth,
      intl,
      filter,
      currentCompany,
      projects: visibleProjects,
      basePath: this.getBasePath(),
      // TODO revisit
      highlightProjectCards: projects.length === 2,
      onShowProjectDetail: this.onShowProjectDetail,
      onProjectPageChange: this.onProjectPageChange,
      onRefresh: this.refreshData,
      onEditProject: this.onEditProject,
      onExportProject: this.onExportProject,
      onProjectAdd: this.onProjectAdd,
      onActivateProject: this.onActivateProject,
      onFinishProject: this.onFinishProject,
      onDeleteProject: this.onDeleteProject,
      onInviteApplicants: this.onInviteApplicants,
      onCopyProject: this.handleCopyProject
    };

    return (
      <div>
        <CompanySelect />
        <ProjectsPage {...pageProps} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { companies, projects, currentCompany: { item } },
    options: { projectFilter },
    intl,
  } = state;
  return {
    intl,
    companies,
    projects,
    currentCompany: item,
    filter: projectFilter,
    auth: selectAccountPermissions(state, ownProps),
    visibleProjects: selectVisibleProjects(state, ownProps),
  };
};

export default connect(mapStateToProps, allActions)(withRouter(Projects));
