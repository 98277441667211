import React from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';

import Button from '../../../components/mui/Button';

import OpportunityResponseStatus from './OpportunityResponseStatus';
import OpportunityCompanyResponseStatus from './OpportunityCompanyResponseStatus';

import FitIndicator from '../../../components/matching/FitIndicator';
import Personal from '../../../components/applicant/Personal';
import InitialsAvatar from '../../../components/applicant/InitialsAvatar';
import MetricsCellContent from '../../../components/applicant/cells/MetricsCellContent';
import { GridRow, GridCell } from '../../../components/grid';

import { EUserOpportunityPostResponse } from '../../../constants/enum';

const InlineIndicator = props => {
  const { className, ...rest } = props;
  return (
    <div className={className} style={{ display: 'inline-block', margin: '4px' }}>
      <FitIndicator sizeVariant={'compact'} {...rest} />
    </div>
  );
};

const MatchIndicators = props => {
  const { match } = props;
  return (
    <div>
      <div style={{ margin: -4 }}>
        <InlineIndicator className="matching-primary" fit={match && match.fits && match.fits.requirements} />
        <InlineIndicator className="matching-secondary" fit={match && match.fits && match.fits.offer} />
        <InlineIndicator
          className="matching-tertiary"
          fit={match && match.culture && match.culture.data && match.culture.data.fit}
        />
      </div>
    </div>
  );
};

const OpportunityParticipantRow = props => {
  const { participant, intl, metricDefinitions } = props;
  const { onClick, onResponseSet } = props;
  const messages = intl.messages.components.pages.private.opportunities;
  const createResponseHandler = response => e => {
    e.stopPropagation();
    onResponseSet(participant, response);
  };
  const handleClick = onClick ? () => onClick(participant) : null;
  const showResponsePanel = true; // participant.response || (participant.companyResponse && participant.companyResponse.status);
  return (
    <GridRow key={participant.id} onClick={handleClick} className={!onClick && 'unselectable'}>
      <GridCell
        xs={24}
        sm={12}
        md={6}
        style={{
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          padding: '4px 16px 10px 10px'
        }}
      >
        <div className="container-flex" style={{ margin: '20px 0 0 6px' }}>
          <InitialsAvatar
            size={32}
            identifier={participant.id}
            firstName={participant.firstName}
            lastName={participant.lastName}
          />
          <div style={{ marginLeft: '12px' }}>
            <div>
              {participant.lastName} {participant.firstName}
            </div>
            <div>
              <small>{participant.email}</small>
            </div>
          </div>
        </div>
      </GridCell>
      <GridCell xs={24} sm={12} md={5}>
        {participant.match && (
          <div style={{ marginTop: '14px' }}>
            <MatchIndicators match={participant.match} />
          </div>
        )}
      </GridCell>
      <GridCell xs={24} sm={12} md={9} style={{ overflow: 'visible', padding: '6px 16px 2px' }}>
        <div className="container-flex">
          {map(
            filter(participant.activities, a => a.result && a.result.metrics),
            (a, idx) => (
              <div key={idx}>
                <div className="mui-label">{a.name}</div>
                <MetricsCellContent metrics={a.result.metrics} metricDefinitions={metricDefinitions} />
              </div>
            )
          )}
        </div>
      </GridCell>
      <GridCell xs={24} sm={12} md={4}>
        {participant.response && (
          <div style={{ marginTop: '14px' }}>
            <OpportunityResponseStatus messages={messages.responseStatus} response={participant.response} intl={intl} />
          </div>
        )}
      </GridCell>
      <GridCell
        xs={24}
        style={{
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          padding: '10px 15px 10px 15px'
        }}
      >
        <div style={{ marginBottom: '14px' }}>
          <Personal {...participant.personal} />
        </div>
      </GridCell>
      {showResponsePanel && (
        <div className="col-xs-24">
          <div className="bg-primary-light" style={{ height: '1px' }} />
        </div>
      )}
      {showResponsePanel && (
        <GridCell xs={24}>
          {participant.companyResponse && participant.companyResponse.status ? (
            <div className="inline-container" style={{ textAlign: 'left' }}>
              <OpportunityCompanyResponseStatus
                messages={messages.companyResponseStatus}
                response={participant.companyResponse}
                participantResponse={participant.response}
                intl={intl}
              />
            </div>
          ) : (
            <div style={{ textAlign: 'right' }}>
              <Button
                label={
                  participant.response
                    ? participant.response.status === EUserOpportunityPostResponse.ACCEPTED.key
                      ? messages.companyResponseButtons.ACCEPT_ACCEPTED
                      : messages.companyResponseButtons.ACCEPT_INTERESTED
                    : messages.companyResponseButtons.ACCEPT
                }
                primary
                icon={<i className="material-icons">check</i>}
                onClick={createResponseHandler(EUserOpportunityPostResponse.ACCEPTED.key)}
              />
              {participant.response && (
                <Button
                  label={messages.companyResponseButtons.REJECT}
                  icon={<i className="material-icons">close</i>}
                  onClick={createResponseHandler(EUserOpportunityPostResponse.REJECTED.key)}
                />
              )}
            </div>
          )}
        </GridCell>
      )}
    </GridRow>
  );
};

export default OpportunityParticipantRow;
