import React from 'react';

export default ({ children, ...props }) => {
  return (
    <div className="dialogue-preview" {...props}>
      <div className="dialogue-preview-npc">
        <img src="/img/editor/npc-neutral-00.svg" alt="NPC" width="100%" height="100%" />
      </div>
      <div className="dialogue-preview-text-container">
        <div className="dialogue-preview-text">
          <p>{children}</p>
        </div>
        <div className="dialogue-preview-text-arrow" />
      </div>
    </div>
  );
};
