import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';

import Text from '../../../superfield/form/SuperText';
import Select from '../../../superfield/form/SuperSelect';
import MultiText from '../../../superfield/form/SuperMultiText';
import DatePicker from '../../../formFields/DatePicker';
import Toggle from '../../../formFields/Toggle';
import ExpanderLabel from '../../../basic/ExpanderLabel';
import ActivitySelectFormField from './ActivitySelectFormField';
import ProjectInvitationPreview from './ProjectInvitationPreview';

import { selectCurrentProjectType } from './ProjectTypePart';
import { selectProjectToEdit } from './projectEditSelectors';
import languages from '../../../../constants/languages';
import {
  EParticipantReport,
  EActivityEventType,
  EPoolEndpoint,
  EProjectType,
  EPersonalDetailsExtent,
  EActivityType,
  getEnumOrdered
} from '../../../../constants/enum';
import { splitToDomains } from '../../../../utils/utils';
import { toggleAdvancedGeneralSettings } from '../../../../actions/options/projectEditActions';

const AdvancedSettings = props => {
  const { messages, dataSources, templateId, participantReport, poolEndpoint } = props;
  const template = _find(dataSources.templates, ({ id }) => id === templateId);
  const posterEnabledSwitchDisabled =
    !template || !EActivityType.CULTURE_FIT.variants.CANDIDATE.matches({ activity: template });
  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="mui-fields-container">
            <div className="row">
              <div className="col-sm-6">
                <Field name="view" component={Toggle} label={messages.viewLabel} />
              </div>
            </div>
            <div className="text-muted mui-fields-description">{messages.viewDescription}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="reportChatId"
            component={Select}
            label={messages.reportChatLabel}
            helperText={messages.reportChatHelper}
            dataSource={dataSources.reportChatProjects}
            disabled={!participantReport || participantReport === EParticipantReport.NONE.key}
            filterable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="targetViewIds"
            component={Select}
            filterable
            multi
            label={messages.targetViewsLabel}
            helperText={messages.targetViewsHelper}
            dataSource={dataSources.viewProjects}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="seekerQualifierEventType"
            component={Select}
            label={messages.seekerQualifierEventTypeLabel}
            helperText={messages.seekerQualifierEventTypeHelperText}
            dataSource={dataSources.activityEventTypes}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <Field
            name="flow1ProjectId"
            component={Select}
            filterable
            label={messages.flowProjectIdLabel}
            helperText={messages.flowProjectIdHelper}
            dataSource={dataSources.flowProjects}
          />
        </div>
        <div className="col-sm-6">
          <Field
            name="flow1EventType"
            component={Select}
            label={messages.flowProjectEventTypeLabel}
            dataSource={dataSources.activityEventTypes}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="personalDetailsExtent"
            component={Select}
            label={messages.personalDetailsExtentLabel}
            helperText={messages.personalDetailsExtentHelper}
            dataSource={dataSources.personalDetailsExtents}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="poolEndpoint"
            component={Select}
            label={messages.poolEndpointLabel}
            dataSource={dataSources.poolEndpoints}
          />
        </div>
        {dataSources.talentPools.length > 0 && (
          <div className="col-sm-12">
            <Field
              name="poolIds"
              component={Select}
              filterable
              multi
              label={messages.talentPoolsLabel}
              helperText={messages.talentPoolsHelper}
              dataSource={dataSources.talentPools}
              disabled={poolEndpoint !== EPoolEndpoint.SOURCE.key}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="allowedEmailDomains"
            component={MultiText}
            label={messages.allowedEmailDomainsLabel}
            helperText={messages.allowedEmailDomainsHelper}
            splitter={splitToDomains}
            errorMessage={messages.allowedEmailDomainsErrorInvalid}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="mui-fields-container">
            <div className="row">
              <div className="col-sm-6">
                <Field
                  name="posterEnabled"
                  component={Toggle}
                  label={messages.posterEnabledLabel}
                  disabled={posterEnabledSwitchDisabled}
                />
              </div>
            </div>
            <div className="text-muted mui-fields-description">
              {posterEnabledSwitchDisabled
                ? messages.posterEnabledToggleDisabledDescription
                : messages.posterEnabledDescription}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GeneralSettingsPart = props => {
  const { intl, auth, dataSources, projectType, isProjectActive, isAlwaysOpen, isAdvancedShown, change } = props;
  const { handleSubmit, toggleAdvancedSettings } = props;
  const projectEditMessages = intl.messages.components.pages.private.projectEdit;
  const baseMessages = projectEditMessages.generalSettings;
  const messages = { ...baseMessages, ...(projectType && projectEditMessages.projectTypes[projectType].overrides) };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6">
          {!isProjectActive && dataSources.companies.length > 1 && (
            <Field
              name="companyId"
              component={Select}
              label={messages.companyLabel}
              dataSource={dataSources.companies}
              filterable
              indicateRequired
            />
          )}
          <Field
            name="name"
            component={Text}
            label={messages.projectNameLabel}
            helperText={messages.projectNameHelper}
            indicateRequired
          />
        </div>
        <div className="col-sm-6">
          <ProjectInvitationPreview />
        </div>
      </div>
      {!isProjectActive && (
        <div className="row">
          <div className="col-sm-12">
            <Field
              name="templateId"
              component={ActivitySelectFormField}
              label={messages.simNameLabel}
              activities={dataSources.activities}
              indicateRequired
              onChange={(e, v) => {
                const t = _find(dataSources.templates, ({ id }) => id === v);
                const newReport = getDefaultParticipantReport(t, projectType) || EParticipantReport.STANDARD.key;
                const newPersonalDetailsExtent =
                  t && t.format === 'QUESTIONNAIRE'
                    ? EPersonalDetailsExtent.MINIMAL.key
                    : EPersonalDetailsExtent.FULL.key;
                change('personalDetailsExtent', newPersonalDetailsExtent);
                change('participantReport', newReport);
              }}
            />
          </div>
        </div>
      )}
      {!isProjectActive && (
        <div className="row">
          <div className="col-sm-6">
            <Field
              name="language"
              component={Select}
              label={messages.languageLabel}
              dataSource={dataSources.languages}
              indicateRequired
            />
          </div>
        </div>
      )}
      {(auth.isAdmin || !isProjectActive) && (
        <div className="row">
          <div className="col-sm-6">
            <Field
              name="supportedLanguages"
              component={Select}
              multi
              label={messages.supportedLanguagesLabel}
              helperText={messages.supportedLanguagesHelper}
              dataSource={dataSources.languages}
            />
          </div>
        </div>
      )}
      {!isProjectActive && projectType === EProjectType.POSITION.key && dataSources.positionTypes.length > 0 && (
        <div className="row">
          <div className="col-sm-12">
            <Field
              name="positionType"
              component={Select}
              label={messages.posTypeLabel}
              helperText={messages.posTypeHelper}
              dataSource={dataSources.positionTypes}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-6">
          <div className="mui-fields-container">
            <h6>{messages.dateTitle}</h6>
            <div className="text-muted mui-fields-description">{messages.dateHelper}</div>
            <Field
              name="alwaysOpen"
              component={Toggle}
              label={messages.alwaysOpenLabel}
              fullWidth
              onToggle={(e, val) => {
                change('alwaysOpen', val);
                if (val) {
                  change('dateEnd', null);
                }
              }}
            />
            {!isAlwaysOpen && (
              <Field
                name="dateEnd"
                component={DatePicker}
                label={messages.dateEndLabel}
                hintText={messages.dateEndLabel}
                helperText={messages.dateEndHelperText}
                floatingLabelFixed
                intl={intl}
                fullWidth
                disabled={isAlwaysOpen}
                style={{ marginTop: '-14px' }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="mui-fields-container">
            <h6>{messages.reportTitle}</h6>
            <div className="text-muted mui-fields-description">{messages.reportHelper}</div>
            <Field
              name="participantReport"
              component={Select}
              label={messages.participantReportLabel}
              dataSource={dataSources.participantReports}
            />
          </div>
        </div>
      </div>
      {auth.isAdmin && (
        <div style={{ marginTop: 16 }}>
          <ExpanderLabel
            expanded={isAdvancedShown}
            label={messages.advancedSettings}
            toggleExpand={toggleAdvancedSettings}
          />
          {isAdvancedShown && <AdvancedSettings {...props} messages={messages} />}
        </div>
      )}
    </form>
  );
};

GeneralSettingsPart.ID = 'projectGeneralSettings';

const formSelector = formValueSelector(GeneralSettingsPart.ID);

const baseLocalSelectIntl = state => state.intl;
const baseLocalSelectCompanies = state => state.entities.companies;
const baseLocalSelectProjects = state => state.entities.projects;
const baseLocalSelectTemplates = state => state.entities.activityTemplates.templates;
const baseLocalSelectEditOptions = state => state.options.projectEdit;

const localSelectReportChatProjects = createSelector([baseLocalSelectEditOptions], editOptions => {
  const { chats } = editOptions;
  return _map(_orderBy(chats.items, ['modifiedAt'], ['desc']), c => ({
    value: c.id,
    label: c.name || '?',
    description: c.company && c.company.name
  }));
});

const localSelectViewProjects = createSelector([baseLocalSelectEditOptions], editOptions => {
  const { views } = editOptions;
  return _map(views.items, v => ({
    value: v.id,
    label: v.name || '?',
    description: v.company && v.company.name
  }));
});

const localSelectFlowProjects = createSelector([baseLocalSelectProjects], projects => {
  return _map(projects, p => ({
    value: p.id,
    label: p.name,
    description: p.company && p.company.name
  }));
});

const baseLocalSelectTemplateId = state => formSelector(state, 'templateId');
export const selectCurrentTemplate = createSelector(
  [selectProjectToEdit, baseLocalSelectTemplateId, baseLocalSelectTemplates],
  (p, templateId, templates) => {
    return findTemplate(templates, templateId || p.templateId);
  }
);

export const selectCurrentPositionType = state => formSelector(state, 'positionType');

const buildParticipantReportsDataSource = (template, auth, intl) => {
  const { config } = template || {};
  const { participantReports } = config || {};
  const { list } = participantReports || {};
  const useList = !auth.isAdmin && list;
  const arr = useList ? list : getEnumOrdered(EParticipantReport);
  const getKey = useList ? x => x : x => x.key;
  return _map(arr, val => {
    const key = getKey(val);
    let eMsg;
    if (arr.length <= 2) {
      eMsg = intl.messages.constants.enums.EParticipantReportSimple[key === EParticipantReport.NONE.key ? 'NO' : 'YES'];
    } else {
      eMsg = intl.messages.constants.enums.EParticipantReport[key];
    }
    return {
      value: key,
      label: eMsg.shortDescription ? `${eMsg.label} - ${eMsg.shortDescription}` : eMsg.label,
      description: eMsg.description
    };
  });
};

const baseLocalSelectAuth = state => state.auth;
const baseLocalSelectCompanyTalentPools = state => state.entities.talentPools.companyPools;
const baseLocalSelectCompanyId = state => formSelector(state, 'companyId');

const localSelectTalentPools = createSelector(
  [baseLocalSelectCompanyTalentPools, baseLocalSelectCompanyId],
  (tp, cId) => {
    if (tp.companyId === cId) {
      return tp.items;
    }
    return [];
  }
);

const localSelectDataSources = createSelector(
  [
    baseLocalSelectAuth,
    baseLocalSelectIntl,
    baseLocalSelectTemplates,
    baseLocalSelectTemplateId,
    baseLocalSelectCompanies,
    localSelectReportChatProjects,
    localSelectViewProjects,
    localSelectFlowProjects,
    localSelectTalentPools
  ],
  (auth, intl, templates, templateId, companies, reportChatProjects, viewProjects, flowProjects, talentPools) => {
    const { locale } = intl;
    const template = templateId ? findTemplate(templates, templateId) : null;
    const templateData = (template && template.config) || {};
    return {
      templates,
      activities: _map(templates, t => {
        const {
          id,
          key,
          properties: { [locale]: properties }
        } = t;
        return {
          id,
          key,
          ...(properties || t.properties.en)
        };
      }),
      companies: _map(companies, c => ({ value: c.id, label: c.name, description: c.slug })),
      languages: _mapValues(languages, l => ({
        ...l,
        disabled: templateData.languages && templateData.languages.indexOf(l.value) < 0
      })),
      participantReports: buildParticipantReportsDataSource(template, auth, intl),
      activityEventTypes: _map(getEnumOrdered(EActivityEventType), ({ key }) => {
        const eMsg = intl.messages.constants.enums.EActivityEventType[key];
        return {
          value: key,
          label: eMsg.label,
          description: eMsg.description
        };
      }),
      reportChatProjects,
      viewProjects,
      flowProjects,
      poolEndpoints: _map(getEnumOrdered(EPoolEndpoint), ({ key }) => {
        const eMsg = intl.messages.constants.enums.EPoolEndpoint[key];
        return {
          value: key,
          label: eMsg.label,
          description: eMsg.description
        };
      }),
      positionTypes: _map(templateData.positionTypes || [], type => {
        const tMsg = intl.messages.constants.positionTypes[type];
        return {
          value: type,
          label: tMsg.label,
          description: tMsg.description
        };
      }),
      personalDetailsExtents: _map(getEnumOrdered(EPersonalDetailsExtent), ({ key }) => {
        const eMsg = intl.messages.constants.enums.EPersonalDetailsExtent[key];
        return {
          value: key,
          label: eMsg.label,
          description: eMsg.description
        };
      }),
      talentPools: _map(talentPools, tp => ({ value: tp.id, label: tp.name, description: tp.description }))
    };
  }
);

const localSelectInitialReportChatId = createSelector(
  [selectProjectToEdit, baseLocalSelectEditOptions],
  (p, editOptions) => {
    const cfg = p.config || {};
    const reportChat = cfg.chats && cfg.chats.report;
    if (!reportChat) {
      return null;
    }
    const { chats } = editOptions;
    const items = (chats && chats.items) || [];
    const chat = _find(items, c => c.slug === reportChat.projectId && c.company.slug === reportChat.accountId);
    return chat ? chat.id : null;
  }
);

const findTemplate = (templates, id) => {
  for (let i = 0; i < templates.length; i += 1) {
    const t = templates[i];
    if (t.id === id) {
      return t;
    }
  }
  return null;
};

const localSelectTemplate = createSelector([selectProjectToEdit, baseLocalSelectTemplates], (p, templates) => {
  const { templateId } = p;
  return findTemplate(templates || [], templateId);
});

export const selectCurrentCompany = createSelector(
  [selectProjectToEdit, baseLocalSelectCompanyId, baseLocalSelectCompanies],
  (p, companyId, companies) => {
    const id = companyId || (p.company && p.company.id);
    return id ? _find(companies, c => c.id === id) : null;
  }
);

const baseLocalSelectName = state => formSelector(state, 'name');
export const selectCurrentProjectName = createSelector([selectProjectToEdit, baseLocalSelectName], (p, name) => {
  return name || p.name || '';
});

const baseLocalSelectLanguage = state => formSelector(state, 'language');
export const selectCurrentProjectPrimaryLanguage = createSelector(
  [selectProjectToEdit, baseLocalSelectLanguage],
  (p, lang) => lang || (p.languages && p.languages[0]) || 'en'
);

const getDefaultParticipantReport = (template, projectType) => {
  const { config } = template || {};
  const { participantReports } = config || {};
  const { defaults, defaultValue } = participantReports || {};
  return (defaults && projectType && defaults[projectType]) || defaultValue;
};

const localSelectInitialValues = createSelector(
  [selectProjectToEdit, localSelectTemplate, localSelectInitialReportChatId, baseLocalSelectCompanies],
  (project, template, reportChatId, companies) => {
    const cfg = (project && project.config) || {};
    const flowItems = (cfg.flows && cfg.flows.items) || [];
    const flow1 = flowItems[0];
    const targetViews = (cfg.views && cfg.views.items) || [];
    const companyId = (project.company && project.company.id) || (companies.length === 1 ? companies[0].id : null);
    const languages = project.languages || [];
    const [language, ...supportedLanguages] = languages;
    return {
      templateId: template && template.id,
      companyId,
      language,
      supportedLanguages,
      name: project.name,
      positionType: project.positionType,
      alwaysOpen: !project.validTo,
      dateEnd: project.validTo ? new Date(project.validTo) : null,
      view: project.view,
      participantReport: project.id
        ? cfg.participantReport
        : getDefaultParticipantReport(template, project.type) || EParticipantReport.STANDARD.key,
      reportChatId,
      targetViewIds: _map(targetViews, ({ projectId }) => projectId),
      seekerQualifierEventType: cfg.seekerQualifier && cfg.seekerQualifier.eventType,
      flow1ProjectId: flow1 && flow1.project && flow1.project.id,
      flow1EventType: flow1 && flow1.eventType,
      poolEndpoint: project.id ? project.poolEndpoint : EPoolEndpoint.NONE.key,
      poolIds: _map(project.talentPools || [], ({ id }) => id),
      personalDetailsExtent: project.id
        ? cfg.personalDetails && cfg.personalDetails.extent
        : EPersonalDetailsExtent.FULL.key,
      allowedEmailDomains: cfg.allowedEmailDomains,
      posterEnabled: cfg.posterEnabled
    };
  }
);

const mapStateToProps = (state, ownProps) => {
  const {
    auth,
    intl,
    options: { projectEdit }
  } = state;
  const project = selectProjectToEdit(state, ownProps);
  return {
    auth,
    intl,
    projectType: selectCurrentProjectType(state, ownProps),
    initialValues: localSelectInitialValues(state, ownProps),
    participantReport: formSelector(state, 'participantReport'),
    poolEndpoint: formSelector(state, 'poolEndpoint'),
    dataSources: localSelectDataSources(state, ownProps),
    isAlwaysOpen: formSelector(state, 'alwaysOpen'),
    templateId: formSelector(state, 'templateId'),
    isAdvancedShown: projectEdit.advancedGeneralSettings,
    isProjectActive: project.isActive
  };
};

const isLanguageDisabled = (lang, langs) => {
  const langObj = _find(langs, l => l.value === lang);
  return langObj && langObj.disabled;
};

const validate = (values, props) => {
  const { intl, projectType, dataSources } = props;
  const errors = {};
  const messages = intl.messages.components.pages.private.projectEdit.validation;
  if (!values.name) {
    errors.name = messages.projectNameEmpty;
  }
  if (!values.companyId) {
    errors.companyId = messages.companyEmpty;
  }
  if (!values.templateId) {
    errors.templateId = messages.simulationEmpty;
  }
  if (!values.language || isLanguageDisabled(values.language, dataSources.languages)) {
    errors.language = messages.languageEmpty;
  }
  if (!values.positionType && projectType === EProjectType.POSITION.key && dataSources.positionTypes.length > 0) {
    errors.positionType = messages.posTypeEmpty;
  }
  if (!values.alwaysOpen) {
    if (!values.dateEnd) {
      errors.dateEnd = messages.endDateNotSet;
    } else if (values.dateEnd < Date.now()) {
      errors.dateEnd = messages.endDateInPast;
    }
  }
  return errors;
};

const shouldValidate = () => true;

const formConfig = {
  form: GeneralSettingsPart.ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
  shouldValidate
};

const actions = {
  toggleAdvancedSettings: toggleAdvancedGeneralSettings
};

export default connect(mapStateToProps, actions)(reduxForm(formConfig)(GeneralSettingsPart));
