import React from 'react';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';

import Slider from 'material-ui/Slider';

import { STEP, MIN, MAX, WEIGHT_LEVELS } from './WeightUtils';

import WeightLevelLabel from './WeightLevelLabel';

const WeightSlider = props => {
  const { level, weight, disabled } = props;
  const { onChange } = props;
  return (
    <div className="show-on-hover-parent">
      <div className="container-flex-row" style={{ paddingBottom: '16px' }}>
        <div className="flex1" style={{ paddingRight: '16px' }}>
          <Slider
            onChange={_debounce((e, val) => onChange(val), 300)}
            sliderStyle={{ margin: '0' }}
            step={STEP}
            min={MIN}
            max={MAX}
            value={weight.value}
            disabled={disabled}
          />
          {!disabled && (
            <div className="container-flex-row jc-space-between">
              {_map(WEIGHT_LEVELS, l => (
                <div key={l.key} className="relative-container text-center show-on-hover">
                  <div style={{ position: 'absolute', left: 0, top: 4, transform: 'translateX(-50%)' }}>
                    <a
                      role="button"
                      className="link-button"
                      disabled={l === level.value && level.exact}
                      onClick={() => onChange(l.value)}
                    >
                      <WeightLevelLabel level={{ value: l }} />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <span className="small">{weight.value.toFixed(1)}</span>
      </div>
    </div>
  );
};

export default WeightSlider;
