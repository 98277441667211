import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import RaisedButton from 'material-ui/RaisedButton';
import TextField from '../superfield/form/SuperText';
import { BasicForm, BasicTitle, BasicParagraph } from '../basic';

const ResetPasswordEmail = props => {
  const { handleSubmit, intl } = props;
  const messages = intl.messages.components.pages.public.resetPasswordEmail;

  return (
    <BasicForm onSubmit={handleSubmit}>
      <BasicTitle title={messages.text} />
      <BasicParagraph text={messages.notice} />
      <div className={'row'}>
        <div className={'col-xs-12'}>
          <Field name={'email'} component={TextField} label={messages.setMail} type={'text'} autoFocus />
        </div>
      </div>

      <div className={'row'}>
        <div className={'col-xs-12'} />
      </div>

      <div className={'row'} style={{ marginTop: '25px' }}>
        <div className={'col-xs-12'}>
          <RaisedButton type={'submit'} label={messages.submit} primary style={{ float: 'right' }} />
        </div>
      </div>
    </BasicForm>
  );
};

ResetPasswordEmail.propTypes = {
  modal: PropTypes.object,
  intl: PropTypes.object,
  resetPassword: PropTypes.func,
  updateLocale: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default reduxForm({
  form: 'resetPasswordEmail'
})(ResetPasswordEmail);
