import React from 'react';
import { connect } from 'react-redux';

import Tooltip from '../basic/Tooltip';
import InlineInput from '../basic/InlineInput';

class TeamName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  handleChange = e => {
    const { onChange } = this.props;
    const { value } = e.target;
    this.setState({ value });
    if (onChange) {
      onChange(value);
    }
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(this.state.value);
    }
    this.setState({ focused: false });
  };

  handleFocus = () => this.setState({ focused: true });

  render() {
    const { intl, type } = this.props;
    const { value, focused } = this.state;

    const messages = intl.messages.components.teams.name;
    const placeholder = messages.placeholderVariants[type.key] || messages.placeholder;
    return (
      <div className="team__name">
        <Tooltip
          content={focused ? messages.focusedHelperText : messages.helperText}
          position="bottom-left"
          delay={300}
        >
          <InlineInput
            type="text"
            value={value}
            borderClassName="bg-color-highlight mui-appear"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            placeholder={placeholder}
            autoFocus={!value}
          />
        </Tooltip>
      </div>
    );
  }
}

export default connect(({ intl }) => ({ intl }))(TeamName);
