import React from 'react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import Overlay from '../basic/Overlay';
import Button from '../mui/Button';
import ParticipantsSelectPanel from './ParticipantsSelectPanel';
import TagAssignmentPopover from '../tag/TagAssignmentPopover';
import Tag from '../tag/Tag';

import withIntl from '../withIntl';

class ParticipantsTagsAssignmentOverlay extends React.Component {
  state = {
    selectedParticipants: [],
    selectedTags: [],
    popoverOpen: true
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { participants } = this.props;
    if (participants !== prevProps.participants) {
      this.init();
    }
  }

  init = () => {
    const { participants } = this.props;
    this.setState({ selectedParticipants: participants });
  };

  handleClose = () => {
    this.props.onClose();
  };
  handleConfirm = () => {
    const { selectedTags, selectedParticipants } = this.state;
    const { onConfirm } = this.props;
    onConfirm(selectedTags, selectedParticipants);
    this.handleClose();
  };
  setPopoverAnchor = el => {
    this.popoverAnchor = el;
  };
  removeTag = t => {
    this.setState({ selectedTags: _filter(this.state.selectedTags, tag => tag.id !== t.id) });
  };
  updateTag = (t, addIfNotFound) => {
    const { selectedTags } = this.state;
    let found = false;
    const newSelection = [];
    for (let i = 0; i < selectedTags.length; i += 1) {
      const tag = selectedTags[i];
      if (tag.id === t.id) {
        found = true;
        newSelection.push(t);
      } else {
        newSelection.push(tag);
      }
    }
    if (addIfNotFound && !found) {
      newSelection.push(t);
    }
    this.setState({ selectedTags: newSelection });
  };
  handleTagAssigned = (t, enabled) => {
    if (!enabled) {
      this.removeTag(t);
    } else {
      this.updateTag(t, true);
    }
  };
  handleTagSaved = (t, options) => {
    const { onTagSaved } = this.props;
    if (options && options.removed) {
      this.removeTag(t);
    } else {
      if (t.id) {
        this.updateTag(t);
      }
    }
    if (onTagSaved) {
      onTagSaved(t, options);
    }
  };

  handleSelectedParticipantsChanged = selectedParticipants => this.setState({ selectedParticipants });

  handlePopoverOpen = () => {
    this.setState({ popoverOpen: true });
  };
  handlePopoverClose = () => {
    this.setState({ popoverOpen: false });
  };

  render() {
    const {
      companyId,
      participants,
      getParticipant,
      intl: { messages }
    } = this.props;
    const { selectedParticipants, selectedTags, popoverOpen } = this.state;
    return (
      <Overlay onRequestClose={this.handleClose} maxWidth={480} noBlur>
        <div>
          <div className="mui-card">
            <div className="mui-padded" style={{ paddingTop: '16px' }}>
              <div className="mui-padded">
                <div className="mui-label">{messages.participantsLabel}</div>
                <div style={{ margin: '0 -16px' }}>
                  <div
                    className="bg-primary-light border-bottom border-color-primary__50 border-top mui-padded-vertical"
                    style={{ maxHeight: '240px', overflow: 'auto' }}
                  >
                    <ParticipantsSelectPanel
                      participants={participants}
                      getParticipant={getParticipant}
                      preselected={participants}
                      onSelectionChange={this.handleSelectedParticipantsChanged}
                    />
                  </div>
                </div>
              </div>
              <div className="mui-padded">
                <div className="mui-label">{messages.tagsLabel}</div>
                <div
                  ref={this.setPopoverAnchor}
                  className="container-flex-row ai-center fw-yes mui-padded-half"
                  style={{ margin: '0 -8px' }}
                >
                  {selectedTags.length === 0 && (
                    <div className="mui-padded-half">
                      <em className="small text-muted">{messages.noTagsMessage}</em>
                    </div>
                  )}
                  {_map(selectedTags, t => (
                    <div key={t.id} className="mui-padded-half">
                      <Tag {...t} onRemove={() => this.removeTag(t)} />
                    </div>
                  ))}
                  <a role="button" className="mui-padded-half link-button" onClick={this.handlePopoverOpen}>
                    <i className="material-icons">add</i>
                    <span>{messages.addTagLabel}</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="container-flex-row ai-center jc-flex-end mui-padded-half border-top border-color-primary__50">
              <div className="mui-padded-half">
                <Button label={messages.cancelLabel} onClick={this.handleClose} />
              </div>
              <div className="mui-padded-half inline-container">
                <Button
                  label={messages.confirmLabel}
                  onClick={this.handleConfirm}
                  disabled={selectedParticipants.length <= 0 || selectedTags.length <= 0}
                  primary
                  raised
                />
              </div>
            </div>
          </div>
          {popoverOpen && (
            <TagAssignmentPopover
              anchorEl={this.popoverAnchor}
              activeTags={selectedTags}
              companyId={companyId}
              onTagAssigned={this.handleTagAssigned}
              onTagSaved={this.handleTagSaved}
              onClose={this.handlePopoverClose}
            />
          )}
        </div>
      </Overlay>
    );
  }
}

const intlMessages = {
  cs: {
    cancelLabel: 'Zrušit',
    confirmLabel: 'Přidat',
    addTagLabel: 'Přidat tagy...',
    participantsLabel: 'Účastníci',
    tagsLabel: 'Tagy',
    noTagsMessage: 'Žádné tagy'
  },
  sk: {
    cancelLabel: 'Zrušiť',
    confirmLabel: 'Pridať',
    addTagLabel: 'Pridať tagy...',
    participantsLabel: 'Účastníci',
    tagsLabel: 'Tagy',
    noTagsMessage: 'Žiadne tagy'
  },
  en: {
    cancelLabel: 'Cancel',
    confirmLabel: 'Add',
    addTagLabel: 'Add tags...',
    participantsLabel: 'Participants',
    tagsLabel: 'Tags',
    noTagsMessage: 'No tags'
  }
};

export default withIntl(ParticipantsTagsAssignmentOverlay, intlMessages);
