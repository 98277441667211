import { createStore, applyMiddleware, compose } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

import { get } from '../persistence/storage';
import middlewares from '../middlewares';
import rootReducer from '../reducers';
import i18n from '../i18n';

const logger = createLogger();

const locale = get('locale') || 'en';
const initialState = {
  intl: {
    locale,
    messages: {
      ...i18n[locale]
    }
  }
};

export default function configureStore() {
  const reduxRouterMiddleware = routerMiddleware(browserHistory);
  let store;

  if (module.hot) {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        responsiveStoreEnhancer,
        applyMiddleware(thunkMiddleware, logger, reduxRouterMiddleware, ...middlewares),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
      )
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        responsiveStoreEnhancer,
        applyMiddleware(thunkMiddleware, reduxRouterMiddleware, ...middlewares),
        (f) => f
      )
    );
  }

  return store;
}
