import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import map from 'lodash/map';

import Button from '../mui/Button';
import TextField from '../superfield/form/SuperText';
import { phoneValidate } from './Validator';

const PhoneForm = props => {
  const { messages } = props;
  const { handleSubmit, submitPhone, onCancel } = props;
  return (
    <form onSubmit={handleSubmit(submitPhone)}>
      <div style={{ padding: '16px 24px 24px' }}>
        <div>
          {map(messages.message, (s, idx) => (
            <p key={idx}>{s}</p>
          ))}
        </div>
        <div>
          <Field
            name="phone"
            component={TextField}
            label={messages.fields.phone.label}
            helperText={messages.fields.phone.helperText}
          />
        </div>
      </div>
      <div className="clearfix" style={{ padding: '8px 8px 8px 0' }}>
        <div className="pull-right">
          <Button label={messages.cancelButton} onClick={onCancel} style={{ marginLeft: '8px' }} />
          <Button raised label={messages.confirmButton} primary type="submit" style={{ marginLeft: '8px' }} />
        </div>
      </div>
    </form>
  );
};

PhoneForm.propTypes = {
  messages: PropTypes.object.isRequired,
  //
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitPhone: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    initialValues: {
      phone: state.auth && state.auth.phone
    }
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'phone',
    validate: phoneValidate
  })(PhoneForm)
);
