import React from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import classNames from 'classnames';

import { extractParticipantNameConfig } from '../../utils/utils';
import { getColors } from './AnalyticsUtils';

const renderInterpretationPart = (title, { text, bullets }, boxVariant = false) => {
  const className = classNames({ 'dual-metric-interpretation-part': true, [`box-${boxVariant}`]: boxVariant });
  return (
    <div className={className}>
      <div className="mui-label">{title}</div>
      {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
      {bullets &&
        bullets.length > 0 && (
          <ul className="dashed">
            {map(bullets, (b, idx) => <li key={idx} dangerouslySetInnerHTML={{ __html: b }} />)}
          </ul>
        )}
    </div>
  );
};

const renderTitleWithIndicator = (title, color) => {
  return (
    <div>
      <div className="circle-indicator ci-size-8" style={{ backgroundColor: color }} />
      <span>{title}</span>
    </div>
  );
};

const DualMetricInterpretation = props => {
  const { intl, interpretation, activeParticipant } = props;
  const colors = getColors(props);
  const messages = intl.messages.components.dualMetric;
  const intMessages = messages.interpretation;
  const activeParticipantName = activeParticipant
    ? extractParticipantNameConfig(activeParticipant.participant).text
    : '';
  return (
    <div className="metric-container-content">
      {interpretation.situations &&
        interpretation.situations[1] &&
        activeParticipant &&
        renderInterpretationPart(
          renderTitleWithIndicator(activeParticipantName, activeParticipant.crossComparedColor),
          interpretation.situations[1].situation
        )}
      {interpretation.situations &&
        interpretation.situations[0] &&
        renderInterpretationPart(
          renderTitleWithIndicator(intMessages.teamTitle, colors.main),
          interpretation.situations[0].situation
        )}
      {interpretation.challenge &&
        renderInterpretationPart(intMessages.challengeTitle, interpretation.challenge, 'warning')}
      {interpretation.opportunity &&
        renderInterpretationPart(intMessages.opportunityTitle, interpretation.opportunity, 'info')}
      {interpretation.resolution &&
        renderInterpretationPart(intMessages.resolutionTitle, interpretation.resolution, 'success')}
    </div>
  );
};

export default connect(({ intl }) => ({ intl }))(DualMetricInterpretation);
