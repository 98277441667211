import _range from 'lodash/range';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import { metricsToTree } from '../../../utils/analytics';
import { formatFiatScore } from '../../../utils/utils';

const INACTIVE_COLOR = 'rgba(0, 0, 0, 0.12)';

const calculateMetricData = (m, metricIndex, dataConfig, otherData) => {
  return _reduce(
    dataConfig.items,
    (res, p) => {
      const mv = _get(p, `result.metrics.${m.key}.value`);
      if (mv == null) {
        return res;
      }
      const score = mv;
      const scoreData = res.main[score] || { val: 0, baseColor: null, colors: [], names: [] };
      scoreData.val += 1;
      const { firstName, lastName, email } = p.participant || {};
      scoreData.names.push(lastName && firstName ? `${lastName} ${firstName}` : email);
      if (dataConfig.crossComparisonActive) {
        if (!scoreData.baseColor) {
          scoreData.baseColor = m.inactive ? INACTIVE_COLOR : 'rgba(0, 0, 0, 0.24)';
        }
        const ccc = p.crossComparedColor ? (m.inactive ? 'rgba(0, 0, 0, 0.16)' : p.crossComparedColor) : null;
        if (ccc) {
          const otherDataKey = `crossCompared-${p.id}`;
          const crossComparedParticipant = otherData[otherDataKey] || {
            data: [],
            id: p.id,
            color: ccc
          };
          crossComparedParticipant.data.push({
            score,
            metricIndex,
            metricKey: m.key,
            value: 1,
            count: 1,
            color: ccc
          });
          otherData[otherDataKey] = crossComparedParticipant;
          if (scoreData.colors.indexOf(ccc) < 0) {
            scoreData.colors.push(ccc);
          }
        }
      } else if (!scoreData.baseColor) {
        const color = m.inactive ? INACTIVE_COLOR : _get(p, `result.metrics.${m.key}.color`);
        if (color) {
          scoreData.baseColor = color;
        }
      }
      if (!scoreData.baseColor) {
        scoreData.baseColor = 'black';
      }
      res.main[score] = scoreData;
      return res;
    },
    {
      main: {}
    }
  );
};

const getDataItem = (m, metricIndex, dataConfig, otherData) => {
  const size = dataConfig.items.length;
  const metricData = calculateMetricData(m, metricIndex, dataConfig, otherData);
  const scoresData = metricData.main;
  const data = [];
  for (let prop in scoresData) {
    if (scoresData.hasOwnProperty(prop)) {
      const scoreData = scoresData[prop] || {};
      const countVal = scoreData.val || 0;
      const countPctg = size ? countVal / size : 0;
      data.push({
        value: parseFloat(prop),
        percentage: countPctg,
        color: scoreData.baseColor,
        colors: scoreData.colors,
        names: scoreData.names || [],
        index: 1,
        count: countVal,
        metricLabel: m.label
      });
    }
  }
  return {
    index: metricIndex,
    metric: m,
    label: m.label,
    data: _orderBy(data, v => v.value)
  };
};

const buildMetricsData = (metrics, dataConfig) => {
  const otherData = {};
  const metricsData = [];
  const tree = metricsToTree(metrics);
  let metricIndex = 0;
  for (let i = 0; i < tree.length; i += 1) {
    const r = tree[i];
    const { children } = r;
    if (children && children.length) {
      for (let j = 0; j < children.length; j += 1) {
        const c = children[j];
        const dataItem = getDataItem(c, metricIndex, dataConfig, otherData);
        dataItem.parent = { metric: r, label: r.label, childrenCount: children.length };
        if (j === 0) {
          dataItem.firstChild = true;
        }
        metricsData.push(dataItem);
        metricIndex += 1;
      }
    } else {
      const dataItem = getDataItem(r, metricIndex, dataConfig, otherData);
      metricsData.push(dataItem);
      metricIndex += 1;
    }
  }
  return { metricsData, otherData };
};

const getValueTypeProperties = (p, metric) => {
  const vt = (metric && _get(p || {}, `result.metrics.${metric.key}.valueType`)) || 'PLAIN';
  if (vt === 'FIAT') {
    return {
      ticks: _range(0.25, 1.1, 0.25),
      domain: [0.25, 1],
      scoreFormatter: formatFiatScore,
      tickFormatter: formatFiatScore
    };
  }
  if (vt === 'GRADE') {
    return {
      ticks: _range(0.2, 1.1, 0.2),
      domain: [0.2, 1],
      scoreFormatter: value => Math.round(value * 50) / 10,
      tickFormatter: value => Math.round(value * 50) / 10
    }
  }
  return {
    ticks: _range(0.1, 1.1, 0.1),
    domain: [0.1, 1],
    scoreFormatter: value => Math.round(value * 100) / 10,
    tickFormatter: s => Math.round(s * 100) / 10
  };
};

const buildStatItem = (metricIndex, metric, score) => ({
  metricIndex,
  score,
  metricKey: metric.key,
  value: 1,
  stat: true,
  inactive: metric.inactive
});

const buildChartData = (metricsData, size) => {
  const data = [];
  const meanData = [];
  const medianData = [];
  for (let i = 0; i < metricsData.length; i += 1) {
    const m = metricsData[i];
    let sum = 0;
    let weights = 0;
    let count = 0;
    let median;
    let prevValue;
    for (let j = 0; j < m.data.length; j += 1) {
      const d = m.data[j];
      if (d.percentage > 0) {
        weights += d.percentage;
        sum += d.percentage * d.value;
        data.push({
          metricIndex: i,
          metricKey: m.metric.key,
          score: d.value,
          value: d.percentage,
          count: d.count,
          color: d.color,
          colors: d.colors,
          names: d.names
        });
        const prevCount = count;
        count += d.count;
        if (!median && prevCount <= size / 2 && count > size / 2) {
          if (prevCount === size / 2) {
            median = (d.value + (prevValue ? prevValue : d.value)) / 2;
          } else {
            median = d.value;
          }
        }
        prevValue = d.value;
      }
    }
    if (weights > 0) {
      meanData.push(buildStatItem(i, m.metric, sum / weights));
    }
    if (median) {
      medianData.push(buildStatItem(i, m.metric, median));
    }
  }
  return {
    data,
    meanData,
    medianData
  };
};

const buildDataConfig = (participants, getParticipantProps) => {
  const defaultParticipantPropsGetter = p => ({
    id: p.id,
    participant: p.participant,
    result: p.result,
    crossCompared: p.crossCompared,
    crossComparedColor: p.crossComparedColor
  });
  const participantPropsGetter = getParticipantProps || defaultParticipantPropsGetter;
  return _reduce(
    participants,
    (res, p) => {
      const pProps = participantPropsGetter(p);
      if (pProps.result?.metrics) {
        res.items.push(pProps);
        res.crossComparisonActive |= pProps.crossCompared;
      }
      return res;
    },
    { items: [], crossComparisonActive: false }
  );
};

export const buildData = (participants, metrics, getParticipantProps) => {
  const dataConfig = buildDataConfig(participants, getParticipantProps);
  const { metricsData, otherData } = buildMetricsData(metrics, dataConfig);
  const { data, meanData, medianData } = buildChartData(metricsData, dataConfig.items.length);
  const { ticks, domain, scoreFormatter, tickFormatter } = getValueTypeProperties(dataConfig.items[0], metrics[0]);
  return {
    metricsData,
    otherData,
    //
    data,
    meanData,
    medianData,
    //
    ticks,
    domain,
    scoreFormatter,
    tickFormatter
  };
};
