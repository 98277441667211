import { createSelector } from 'reselect';
import map from 'lodash/map';
import filter from 'lodash/filter';

import { HIGHLIGHT_COLORS } from '../constants/constants';

// entities
export const getCompanies = state => state.entities.companies;
export const getProjects = state => state.entities.projects;
export const getApplicants = state => state.entities.applicants;
export const getApplicant = state => state.entities.applicant;
export const getTagsByProject = state => state.entities.tags.byProject;
export const getTagsByGroup = state => state.entities.tags.byGroup;
export const basicSelectCurrentCompany = state => state.entities.currentCompany.item;
// options
const basicSelectCrossComparison = state => state.options.crossComparison;
const selectCrossComparisonParticipants = createSelector(
  [basicSelectCrossComparison],
  ({ participants }) => participants
);
const selectCrossComparisonSourceProject = createSelector(
  [basicSelectCrossComparison],
  ({ sourceProject }) => sourceProject
);
const selectCrossComparisonTargetProject = createSelector(
  [basicSelectCrossComparison],
  ({ targetProject }) => targetProject
);
const selectCrossComparisonRelevantParticipants = createSelector(
  [selectCrossComparisonParticipants, selectCrossComparisonSourceProject, selectCrossComparisonTargetProject],
  (participants, sourceProject, targetProject) => {
    if (!participants) {
      return null;
    }
    const finishedParticipants = filter(participants, p => p.result && p.result.metrics);
    return map(finishedParticipants, (p, idx) => {
      const currentProject = p.project || sourceProject;
      const project = targetProject && targetProject.id === p.projectId ? null : currentProject;
      return {
        ...p,
        project,
        crossCompared: true,
        crossComparedColor: HIGHLIGHT_COLORS[idx % HIGHLIGHT_COLORS.length]
      };
    });
  }
);

export const getCrossComparison = createSelector(
  [basicSelectCrossComparison, selectCrossComparisonRelevantParticipants],
  (crossComparison, participants) => {
    if (!participants) {
      return crossComparison;
    }
    return { ...crossComparison, participants };
  }
);
