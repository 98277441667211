import React from 'react';
import { Portal } from 'react-portal';

const Rect = ({ onClick, ...style }) => {
  return <div onClick={onClick} style={{ position: 'absolute', pointerEvents: 'all', ...style }} />;
};

const MenuFrame = ({ onClick, rect }) => {
  return (
    <div style={{ position: 'absolute', pointerEvents: 'none', top: 0, left: 0, right: 0, bottom: 0 }}>
      {/*TOP*/}
      {rect.top > 0 && <Rect onClick={onClick} top={0} left={0} right={0} height={rect.top} />}
      {/*LEFT*/}
      {rect.left > 0 && <Rect onClick={onClick} top={rect.top} left={0} height={rect.height} width={rect.left} />}
      {/*RIGHT*/}
      <Rect onClick={onClick} top={rect.top} left={rect.right} height={rect.height} right={0} />
      <Rect onClick={onClick} top={rect.bottom} left={0} bottom={0} right={0} />
    </div>
  );
};

let ticking = false;

function viewport() {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return null;
  }
  let e = window;
  let a = 'inner';
  if (!('innerWidth' in window)) {
    a = 'client';
    e = document.documentElement || document.body;
  }
  return { width: e[a + 'Width'], height: e[a + 'Height'] };
}

function shouldShowOnBottom(rect) {
  const { height } = viewport() || {};
  if (!height) {
    return true;
  }
  return rect.bottom < height / 2;
}

class SuperTextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener('resize', this.update);
    window.addEventListener('scroll', this.update);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
    window.removeEventListener('scroll', this.update);
  }

  update = () => {
    if (this.props.open) {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.forceUpdate();
          ticking = false;
        });

        ticking = true;
      }
    }
  };

  setMenuRef = el => {
    const { onSetMenuContainer } = this.props;
    if (onSetMenuContainer) {
      onSetMenuContainer(el);
    }
  };

  render() {
    const { open, anchorEl, children, onMouseDown, onRequestClose } = this.props;
    const rect = (anchorEl && anchorEl.getBoundingClientRect()) || { top: 0, left: 0, width: 0, height: 0 };
    const width = (rect.width && `${rect.width}px`) || 'auto';
    const showOnBottom = shouldShowOnBottom(rect);

    let containerStyle = {};
    if (showOnBottom) {
      containerStyle.top = rect.bottom;
      containerStyle.bottom = 0;
    } else {
      containerStyle.top = 0;
      containerStyle.height = rect.top;
    }

    return (
      <Portal>
        <div
          className={`mui-suptext-menu-overlay${open ? ' open' : ''}`}
          style={{
            width: !open && 0,
            height: !open && 0
          }}
        >
          <MenuFrame bottom={showOnBottom} rect={rect} onClick={onRequestClose} />
          <div
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              left: rect.left,
              width,
              ...containerStyle
            }}
          >
            {open && (
              <div
                className={`mui-suptext-menu scale-in-ver-top${showOnBottom ? '' : ' top'}`}
                onMouseDown={onMouseDown}
                ref={this.setMenuRef}
              >
                {children}
              </div>
            )}
          </div>
        </div>
      </Portal>
    );
  }
}

export default SuperTextMenu;
