import React from 'react';

import withIntl from '../../withIntl';

const CompanyProfileDisabledMessage = ({ intl: {messages} }) => {
  return <span>{messages.message}</span>
};

const intlMessages = {
  cs: { message: 'Váš současný plán vám nedovoluje si vytvořit profil společnosti' },
  sk: { message: 'Váš súčasný plán vám nedovoľuje si vytvoriť profil spoločnosti' },
  en: { message: 'Your current plan doesn\'t allow you to create a company profile' }
};

export default withIntl(CompanyProfileDisabledMessage, intlMessages);
