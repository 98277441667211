import React from 'react';

import IB from 'material-ui/IconButton';

const OldIconButton = props => {
  const { style, ...rest } = props;
  return (
    <IB
      style={{
        width: '36px',
        height: '36px',
        padding: '6px',
        ...style
      }}
      {...rest}
    />
  );
};

export default OldIconButton;
