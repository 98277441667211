import * as c from '../../constants/constants';

export function setGridOptions(gridType, gridKey, values) {
  return dispatch =>
    dispatch({
      type: c.GRID_SET_OPTIONS,
      payload: {
        gridType,
        gridKey,
        values
      }
    });
}
