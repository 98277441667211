import React from 'react';
import { connect } from 'react-redux';

export default (Component, messages) => {
  const cache = {};
  const mapStateToProps = ({ intl }) => {
    const { locale } = intl;
    if (!messages) {
      return { intl };
    }
    if (cache.messages === messages && cache.locale === locale) {
      return { intl: cache.intl };
    }
    let localeMessages = messages[locale];
    if (!localeMessages) {
      let fallbackLocale;
      for (fallbackLocale in messages) {
        if (messages.hasOwnProperty(fallbackLocale)) {
          break;
        }
      }
      // find any and log!
      console.warn(`Could not find messages for locale ${locale}, falling back to ${fallbackLocale}`);
      localeMessages = messages[fallbackLocale];
    }
    cache.messages = messages;
    cache.locale = locale;
    cache.intl = { locale, messages: localeMessages, globalMessages: intl.messages };
    return { intl: cache.intl };
  };
  return connect(mapStateToProps)(Component);
};
