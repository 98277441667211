import { handleActions } from 'redux-actions';
import { keyBy, map } from 'lodash';

import * as c from '../../constants/constants';

export const defaultState = {
  items: [],
  metricDefinitions: {}
};

const withLoadingCounter = (participant, inc) => ({
  loadingCounter: Math.max(0, (participant.loadingCounter || 0) + inc)
});

const handleApplicantNotifications = (state, values, status) => {
  const valMap = keyBy(values, v => `${v.userId}/${v.projectId}`);
  const items = map(state.items, a => {
    const val = valMap[`${a.participant.id}/${a.projectId}`];
    if (val) {
      const notifications = (a.userProjectData && a.userProjectData.notifications) || {};
      const notification =
        status === 'SUCCESS'
          ? {
              ...notifications[val.type],
              ...val.notification,
              error: !!val.notification
            }
          : {
              ...notifications[val.type]
            };
      return {
        ...a,
        userProjectData: {
          ...a.userProjectData,
          notifications: {
            ...notifications,
            [val.type]: notification
          }
        },
        ...withLoadingCounter(a, status === 'PENDING' ? 1 : -1)
      };
    }
    return a;
  });
  return {
    ...state,
    items
  };
};

export default handleActions(
  {
    [c.RESULTS_GET.SUCCESS]: (state, { payload }) => payload,
    [c.RESULTS_GET.ERROR]: (state) => ({ ...state, error: true }),
    [c.APPLICANT_PROJECT_ACK_SUCCESS]: (state, { payload: { userId, projectId, ...ack } }) => {
      const items = map(state.items, a => {
        if (a.participant && a.participant.id === userId && a.projectId === projectId) {
          return { ...a, ack };
        }
        return a;
      });
      return {
        ...state,
        items
      };
    },
    [c.APPLICANT_PROJECTS_ACKS]: (state, { payload }) => {
      const ackMap = keyBy(payload, ack => `${ack.userId}/${ack.projectId}`);
      const items = map(state.items, a => {
        const ack = ackMap[`${a.participant.id}/${a.projectId}`];
        if (ack) {
          return { ...a, ackSent: Date.now() };
        }
        return a;
      });
      return {
        ...state,
        items
      };
    },
    [c.APPLICANT_SET_APPLICATION_STATUS.SUCCESS]: (state, { payload: { payload: { values } } }) => {
      const valMap = keyBy(values, v => `${v.userId}/${v.projectId}`);
      const items = map(state.items, a => {
        const val = valMap[`${a.participant.id}/${a.projectId}`];
        if (val) {
          return { ...a, applicationStatus: val.applicationStatus, ...withLoadingCounter(a, -1) };
        }
        return a;
      });
      return {
        ...state,
        items
      };
    },
    [c.APPLICANT_SET_APPLICATION_STATUS.PENDING]: (state, { payload: { values } }) => {
      const valMap = keyBy(values, v => `${v.userId}/${v.projectId}`);
      const items = map(state.items, a => {
        const val = valMap[`${a.participant.id}/${a.projectId}`];
        if (val) {
          return { ...a, ...withLoadingCounter(a, 1) };
        }
        return a;
      });
      return {
        ...state,
        items
      };
    },
    [c.APPLICANT_SET_APPLICATION_STATUS.ERROR]: (state, { originalBody: { values } }) => {
      const valMap = keyBy(values, v => `${v.userId}/${v.projectId}`);
      const items = map(state.items, a => {
        const val = valMap[`${a.participant.id}/${a.projectId}`];
        if (val) {
          return { ...a, ...withLoadingCounter(a, -1) };
        }
        return a;
      });
      return {
        ...state,
        items
      };
    },
    [c.APPLICANT_NOTIFICATIONS.SUCCESS]: (state, { payload: { payload: { values } } }) =>
      handleApplicantNotifications(state, values, 'SUCCESS'),
    [c.APPLICANT_NOTIFICATIONS.PENDING]: (state, { meta: { values } }) =>
      handleApplicantNotifications(state, values, 'PENDING'),
    [c.APPLICANT_NOTIFICATIONS.ERROR]: (state, { meta: { values } }) =>
      handleApplicantNotifications(state, values, 'ERROR')
  },
  defaultState
);
