import { handleActions } from 'redux-actions';

import { API_STATUS } from '../../constants/constants';

const defaultState = {
  down: false
};

export default handleActions(
  {
    [API_STATUS]: (state, { payload: { statusCode } }) => ({
      down: !statusCode || statusCode === 502 || statusCode === 503 || statusCode === 504
    })
  },
  defaultState
);
