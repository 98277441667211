import { isEmpty } from 'lodash';
import { matches } from 'validator';
import { parseValidity } from '../../utils/utils';
import * as accountApi from '../../api/entities/accountApi';

export const isEmail = email => matches(email, /^[A-Za-z0-9+_.-]+@[a-zA-Z0-9.-]+$/);

export const isPhone = phone => {
  return matches(phone, /^\+?(\s*-?\s*\d+\s*-?\s*)+$/);
};

export const validateDates = (validity, messages) => {
  const { validTo } = validity;
  const errors = {};
  if (!validTo) {
    errors.dateEnd = messages.endDateNotSet;
  } else if (validTo < Date.now()) {
    errors.dateEnd = messages.endDateInPast;
  }
  return errors;
};

export const newProjectValidate = (values, state) => {
  let errors = {};
  const messages = state.intl.messages.components.pages.private.projectEdit.validation;

  if (!values.projectType) {
    errors.projectType = messages.projectTypeEmpty;
  }
  if (!values.name) {
    errors.name = messages.projectNameEmpty;
  }
  if (state.positionTypeEnabled && !values.positionType) {
    errors.positionType = messages.posTypeEmpty;
  }

  if (!values.company) {
    errors.company = messages.companyEmpty;
  }

  if (!values.templateId) {
    errors.templateId = messages.simulationEmpty;
  }

  const lang = values.language && state.languages ? state.languages[values.language] : null;
  if (!values.language || (lang && lang.disabled)) {
    errors.language = messages.languageEmpty;
  }

  if (!values.alwaysOpen) {
    const validity = parseValidity(values.dateStart, values.dateEnd, values.alwaysOpen);
    errors = {
      ...errors,
      ...validateDates(validity, messages)
    };
  }

  if (isEmpty(values.operator) && values.frequency && values.frequency !== 'NEVER') {
    errors.operator = messages.notificationsRecipientsEmpty;
  }

  if (!values.frequency && !isEmpty(values.operator)) {
    errors.frequency = messages.frequencyEmpty;
  }

  return errors;
};

export const editProjectValidate = (values, state) => {
  let errors = {};
  const messages = state.intl.messages.components.pages.private.projectEdit.validation;

  if (!values.name) {
    errors.name = messages.projectNameEmpty;
  }
  if (state.positionTypeEnabled && !values.positionType) {
    errors.positionType = messages.posTypeEmpty;
  }

  if (!values.templateId) {
    errors.templateId = messages.simulationEmpty;
  }

  const lang = values.language && state.languages ? state.languages[values.language] : null;
  if (!values.language || (lang && lang.disabled)) {
    errors.language = messages.languageEmpty;
  }

  if (!values.alwaysOpen) {
    const validity = parseValidity(values.dateStart, values.dateEnd, values.alwaysOpen);
    errors = {
      ...errors,
      ...validateDates(validity, messages)
    };
  }

  if (isEmpty(values.operator) && values.frequency && values.frequency !== 'NEVER') {
    errors.operator = messages.notificationsRecipientsEmpty;
  }

  if (!values.frequency && !isEmpty(values.operator)) {
    errors.frequency = messages.frequencyEmpty;
  }

  return errors;
};

const validateLength = (value, msgTemplate, min, max) => {
  if (value && ((typeof min === 'number' && min > value.length) || (typeof max === 'number' && max < value.length))) {
    return msgTemplate.replace('{0}', min).replace('{1}', max);
  }
  return null;
};

export const accountNewValidate = (values, state) => {
  const errors = {};
  const messages = state.intl.messages.components.pages.public.accountNew;
  const valueLengthErrorMsg = messages.valueLengthError;

  // if (!values.firstName) {
  //   errors.firstName = messages.fieldRequired;
  // } else {
  //   errors.firstName = validateLength(values.firstName, valueLengthErrorMsg, 1, 255);
  // }
  //
  // if (!values.lastName) {
  //   errors.lastName = messages.fieldRequired;
  // } else {
  //   errors.lastName = validateLength(values.lastName, valueLengthErrorMsg, 1, 255);
  // }

  if (!values.email) {
    errors.email = messages.fieldRequired;
  } else if (!isEmail(values.email)) {
    errors.email = messages.fields.email.invalidEmail;
  } else {
    errors.email = validateLength(values.email, valueLengthErrorMsg, 1, 255);
  }

  // if (values.phone) {
  //   if (!isPhone(values.phone)) {
  //     errors.phone = messages.fields.phone.invalidPhone;
  //   } else {
  //     errors.phone = validateLength(values.phone, valueLengthErrorMsg, 6, 255);
  //   }
  // }

  if (!values.password) {
    errors.password = messages.fieldRequired;
  } else {
    errors.password = validateLength(values.password, valueLengthErrorMsg, 8, 255);
  }

  // if (!values.repeatPassword) {
  //   errors.repeatPassword = messages.fieldRequired;
  // } else if (values.repeatPassword !== values.password) {
  //   errors.repeatPassword = messages.fields.repeatPassword.passwordsDontMatch;
  // }

  // if (!values.language) {
  //   errors.language = messages.fieldRequired;
  // }

  if (!values.companyName) {
    errors.companyName = messages.fieldRequired;
  } else {
    errors.companyName = validateLength(values.companyName, valueLengthErrorMsg, 1, 255);
  }

  // if (!values.companyConfirm) {
  //   errors.companyConfirm = messages.fields.companyConfirm.required;
  // }

  if (!values.tosConfirm) {
    errors.tosConfirm = messages.fields.tosConfirm.required;
  }

  return errors;
};

const accountNewValidateFields = {
  companyName: v => accountApi.accountValidate('slug', v),
  email: v => accountApi.accountValidate('email', v)
};

export const accountNewValidateAsync = (values, dispatch, state, field) => {
  const previousErrors = state.asyncErrors;
  const messages = state.intl.messages.components.pages.public.accountNew;
  const operation = accountNewValidateFields[field];
  if (operation) {
    return operation(values[field]).then(({ payload: { present, free } }) => {
      if (present) {
        throw { ...previousErrors, [field]: messages.fields[field].alreadyExists };
      } else if (field === 'email' && free) {
        throw { ...previousErrors, [field]: messages.fields[field].freeEmail };
      } else if (previousErrors) {
        throw previousErrors;
      }
    });
  }
  return Promise.resolve();
};

export const phoneValidate = (values, state) => {
  const errors = {};
  const messages = state.messages;
  if (values.phone) {
    if (!isPhone(values.phone)) {
      errors.phone = messages.fields.phone.invalid;
    } else {
      errors.phone = validateLength(values.phone, messages.valueLengthError, 6, 255);
    }
  }
  return errors;
};
