import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { MenuItem } from 'material-ui';

import Button from '../../mui/Button';
import SplitButton from '../../mui/SplitButton';
import MenuItemText from '../../mui/MenuItemText';
import ParticipantName from '../../basic/ParticipantName';
import InfoCard from '../InfoCard';

import withIntl from '../../withIntl';
import { formatMessage } from '../../../utils/utils';

import * as crossComparisonActions from '../../../actions/options/crossComparisonActions';
import * as participantActions from '../../../actions/entities/applicantActions';

const SUFFICIENT_HIGHLIGHT_PROFILES_COUNT = 2;
const SUFFICIENT_COMPARE_PROFILES_COUNT = 2;

const LatestProfileInfoCard = props => {
  const { project, profile, profiles, intl: { messages } } = props;
  const canHighlight = profiles.length >= SUFFICIENT_HIGHLIGHT_PROFILES_COUNT;
  const canCompare = profiles.length >= SUFFICIENT_COMPARE_PROFILES_COUNT;
  const { startCrossComparison, selectCrossComparisonProject, selectComparisonParticipants } = props;
  const link = `/projects/overview/${project.company.slug}/${project.slug}/participants/${profile.participant.id}`;
  const primaryActionProps = {
    primary: true,
    icon: <i className="material-icons">assignment_ind</i>,
    label: messages.viewLabel,
    containerElement: <Link to={link} />
  };
  const secondaryActions = [];
  if (canHighlight) {
    secondaryActions.push(
      <MenuItem
        key={'highlight'}
        primaryText={messages.highlightLabel}
        leftIcon={<i className="material-icons">lightbulb_outline</i>}
        onClick={() => {
          startCrossComparison([profile], project, project);
        }}
      />
    );
  }
  if (canCompare) {
    secondaryActions.push(
      <MenuItem
        key={'compare'}
        primaryText={<MenuItemText label={messages.compareLabel} description={messages.compareHelper} />}
        leftIcon={
          <i className="material-icons" style={{ marginTop: '12px' }}>
            compare_arrows
          </i>
        }
        onClick={() => selectComparisonParticipants(profiles, project, [profile])}
      />
    );
  }
  secondaryActions.push(
    <MenuItem
      key={'crossCompare'}
      primaryText={<MenuItemText label={messages.crossCompareLabel} description={messages.crossCompareHelper} />}
      leftIcon={
        <i className="material-icons" style={{ marginTop: '12px' }}>
          compare_arrows
        </i>
      }
      onClick={() => selectCrossComparisonProject([profile], project)}
    />
  );
  const actions = [];
  if (secondaryActions.length > 0) {
    actions.push(
      <SplitButton key={'visit'} {...primaryActionProps}>
        {secondaryActions}
      </SplitButton>
    );
  } else {
    actions.push(<Button key={'visit'} {...primaryActionProps} />);
  }
  return (
    <InfoCard bgIcon="assignment_ind" actions={actions}>
      <div className="mui-padded">
        {formatMessage(messages.message, [
          <b key="ptcName">
            <ParticipantName variant="mini" {...profile.participant} />
          </b>
        ])}
      </div>
    </InfoCard>
  );
};

const intlMessages = {
  cs: {
    viewLabel: 'Zobrazit',
    highlightLabel: 'Zvýraznit',
    crossCompareLabel: 'Porovnat napříč projekty',
    crossCompareHelper: 'Porovnat s účastníky z jiných projektů',
    compareLabel: 'Porovnat s ostatními účastníky',
    compareHelper: 'Porovnat s účastníky v rámci současného projektu',
    message: 'Nejnovější dosud nenavštívený profil je {0}'
  },
  sk: {
    viewLabel: 'Zobraziť',
    highlightLabel: 'Zvýrazniť',
    crossCompareLabel: 'Porovnať naprieč projektami',
    crossCompareHelper: 'Porovnať s účastníkmi z iných projektov',
    compareLabel: 'Porovnať s ostatnými účastníkmi',
    compareHelper: 'Porovnať s účastníkmi v rámci súčasného projektu',
    message: 'Najnovší doposiaľ nenavštívený profil je {0}'
  },
  en: {
    viewLabel: 'Take a look',
    highlightLabel: 'Highlight',
    crossCompareLabel: 'Cross-compare',
    crossCompareHelper: 'Compare with participants from other projects',
    compareLabel: 'Compare with other participants',
    compareHelper: 'Compare with participants from this project',
    message: 'Latest unseen profile is {0}'
  }
};

const actions = {
  startCrossComparison: crossComparisonActions.startCrossComparison,
  selectCrossComparisonProject: crossComparisonActions.selectCrossComparisonProject,
  selectComparisonParticipants: participantActions.selectComparisonParticipants
};

export default withIntl(connect(null, actions)(LatestProfileInfoCard), intlMessages);
