import { createAction } from 'redux-actions';

import { SCORE_MAPPING_SET, SCORE_TOTAL_ENABLED_SET, CORE_METRICS_SET } from '../../constants/constants';

import * as Api from '../../api/entities/projectApi';

export const setScoreMapping = createAction(SCORE_MAPPING_SET, payload => payload);
export const setTotalScoreEnabled = createAction(SCORE_TOTAL_ENABLED_SET, (projectId, enabled) => ({
  projectId,
  enabled
}));
export const setCoreMetrics = createAction(CORE_METRICS_SET, (projectId, coreMetrics) => ({ projectId, coreMetrics }));
export const saveMetricsConfig = (project, config, scoreMapping) => () => {
  const { analytics } = project;
  const { metricsConfig } = analytics || {};
  return Api.setScoreMapping(project.company.id, project.id, scoreMapping).then(() =>
    Api.setMetricsConfig(project.company.id, project.id, { ...metricsConfig, ...config })
  );
};
