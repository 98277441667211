import { createSelector } from 'reselect';

import { EProjectState } from '../../../../constants/enum';

const baseSelectProjectToEdit = state => state.entities.project;
export const selectProjectToEdit = createSelector([baseSelectProjectToEdit], p => ({
  ...p,
  isActive: isProjectActive(p)
}));

const isProjectActive = (project) => {
  const projectState = project.state ? EProjectState[project.state] : null;
  return projectState && projectState !== EProjectState.CONCEPT;
};
