import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import L from 'lodash';

import CanvasNode from './CanvasNode';
import CanvasEdge from './CanvasEdge';
import DragLayer from './DragLayer';
import snap from './snap';

const target = {
  drop: (props, monitor) => {
    const delta = monitor.getDifferenceFromInitialOffset();
    const item = monitor.getItem();
    const position = item.position;

    const left = snap(position.left + delta.x);
    const top = snap(position.top + delta.y);

    props.moveElement(item.id, { left, top });
  }
};

function getSizeStyle(nodes) {
  const res = L.reduce(
    nodes,
    (o, n) => {
      const newW = n.g.position.left + n.g.size.width + 320;
      const newH = n.g.position.top + n.g.size.height + 232;
      return { w: Math.max(o.w, newW), h: Math.max(o.h, newH) };
    },
    { w: 0, h: 0 }
  );
  return { width: `${res.w}px`, height: `${res.h}px` };
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

const Canvas = props => {
  const { connectDropTarget } = props;
  const { nodes, edges, linkingNodeId, messages } = props;
  const linkingNode = linkingNodeId && nodes[linkingNodeId];
  const overlayZ = 100;
  const sizeStyle = getSizeStyle(nodes);
  const parents = {};
  L.forEach(edges, e => (parents[e.dest] = e.src));

  return connectDropTarget(
    <div style={{ ...sizeStyle, position: 'relative', overflow: 'hidden', minWidth: '100%', minHeight: '100%' }}>
      {L.map(edges, (e, idx) => (
        <CanvasEdge key={idx} srcNode={nodes[e.src].g} destNode={nodes[e.dest].g} />
      ))}
      {L.map(L.keys(nodes), k => (
        <CanvasNode
          key={k}
          node={nodes[k]}
          linkingNode={linkingNode}
          onLinkFinish={props.linkFinish}
          onLinkStart={props.linkStart}
          onTextChange={props.setNodeText}
          onPropertyChange={props.setNodeProperty}
          onNodeRemove={props.removeNode}
          onNodeAdd={props.addNode}
          onNodeSettings={props.nodeSettingsOpen}
          messages={messages.node}
        />
      ))}
      {linkingNode && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: overlayZ
          }}
          onClick={() => props.linkFinish()}
        />
      )}
      <DragLayer />
    </div>
  );
};

Canvas.propTypes = {
  children: PropTypes.any,
  messages: PropTypes.object.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired
};

export default DropTarget('DRAGGABLE', target, collect)(Canvas);
