import React from 'react';
import chunk from 'lodash/chunk';
import map from 'lodash/map';
import isArray from 'lodash/isArray';

import Checkbox from 'material-ui/Checkbox';
import ChartIcon from 'material-ui/svg-icons/editor/insert-chart';

import Card from '../../../mui/Card';
import FeatureIndicator from '../../../mui/FeatureIndicator';
import PerformanceIndicator from '../../../applicant/PerformanceIndicator';
import DualMetric from '../../../analytics/DualMetric';
import Interpretation from '../../../applicant/Interpretation';
import Doughnut from '../../../doughnut/Doughnut';
import Chart from '../../../chart/Chart';

import ParticipantCrossComparisonProjectSelect from './ParticipantCrossComparisonProjectSelect';

import { EActivityType, EProfileFeature } from '../../../../constants/enum';
import { BRAND } from '../../../../constants/constants';
import { computeDualMetricData } from '../../../../utils/analytics';
import DualMetricsRecommendation from '../../../analytics/DualMetricsRecommendation';
import ParticipantEchoDetail from './ParticipantEchoDetail';

const renderOverviewItem = ({ label, value }, idx) => (
  <div key={idx} className="overview-item col-md-3 col-sm-6 col-xs-12">
    <div>
      <span className="label-item">{label}</span>
    </div>
    <div>
      <span>{isArray(value) ? value.join(' ') : value}</span>
    </div>
  </div>
);

const renderCustomParticipantDataItem = ({ label, value }, idx) => (
  <div key={idx} className="overview-item col-xs-12">
    <div>
      <span className="label-item">{label}</span>
    </div>
    <div>
      <span dangerouslySetInnerHTML={{ __html: value }} />
    </div>
  </div>
);

const renderTimeStatsItem = ({ label, value }, idx, items) => (
  <div key={idx} className={`col-xs-12 grid-item${idx < items.length - 1 ? ' separator-bottom' : ''}`}>
    <div className="time-stats-item">
      <div className="time-stats-item-value">
        <span>{value}</span>
      </div>
      <div className="time-stats-item-title">
        <span className="label-item">{label}</span>
      </div>
    </div>
  </div>
);

const renderCardTitle = ({ label, action }) => (
  <div className="applicant-detail-card-title">
    <span className="label-section">{label}</span>
    {action && <div className="applicant-detail-card-action">{action}</div>}
  </div>
);

const renderDoughnut = ({ data, subtitle, children }, idx) => (
  <div key={idx} className={`col-sm-3 col-xs-6 grid-item separator-right${idx === 1 ? ' separator-xs-hidden' : ''}`}>
    <Doughnut {...data}>
      <div className="doughnut-subtitle">
        {children}
        {subtitle && <div className="label-item">{subtitle}</div>}
      </div>
    </Doughnut>
  </div>
);

const ParticipantActivityDetail = props => {
  // per project
  const {
    finished,
    overviewItems,
    timeStatsItems,
    doughnutData,
    competencies,
    otherCompetencies,
    competencyFactors,
    performanceIndicators,
    dualMetrics,
    crossComparison,
    crossComparisonFit,
    customParticipantDataItems,
    interpretations,
    weighted,
    project,
    //
    intl,
    chart,
    features,
    messages,
    projectMessages,
    positionShown,
    //
    setChartMode,
    setActiveChart,
    setChartProperty,
    setToggleExpanded,
    invokeUpgradeAccountModal
  } = props;

  if (!finished) {
    return (
      <div>
        {weighted && (
          <FeatureIndicator
            className="mui-card"
            style={{ borderRadius: 0 }}
            name={projectMessages.weighted.name}
            description={projectMessages.weighted.description}
          />
        )}

        {overviewItems && overviewItems.length > 0 && (
          <div className="applicant-detail-section">
            <Card className="applicant-detail-card applicant-detail-card-overview">
              <div className="row overview">{map(overviewItems, renderOverviewItem)}</div>
            </Card>
          </div>
        )}

        <div className="applicant-detail-section">
          <Card className="applicant-detail-card applicant-detail-card-unfinished">
            <div>
              <p>{messages.unfinishedMessage}</p>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  const staticComparison = crossComparison && crossComparison.opportunity;
  const doughnuts = [];
  if (positionShown && doughnutData.position) {
    doughnuts.push({ data: doughnutData.position, subtitle: messages.chart.position });
  }
  if (doughnutData.score) {
    doughnuts.push({ data: doughnutData.score, subtitle: messages.chart.score });
  }
  if (dualMetrics && dualMetrics.length) {
    const doughnutContainerStyle = staticComparison
      ? {}
      : {
          paddingBottom: '0.5em',
          marginBottom: '0.5em',
          borderBottom: '1px solid rgba(0,0,0,0.05)'
        };
    doughnuts.push({
      data: doughnutData.fit || { label: 'N/A', color: '#ccc', value: { full: 0, empty: 1 }, unavailable: true },
      children: (
        <div>
          <div style={doughnutContainerStyle}>
            <span className="label-item">Fit</span>
          </div>
          {!staticComparison && (
            <div style={{ fontSize: '12px' }}>
              <ParticipantCrossComparisonProjectSelect project={project} />
            </div>
          )}
        </div>
      )
    });
  }
  if (doughnutData.hints) {
    doughnuts.push({ data: doughnutData.hints, subtitle: messages.chart.skipped });
  }
  return (
    <div>
      {weighted && (
        <FeatureIndicator
          className="mui-card"
          style={{ borderRadius: 0 }}
          name={projectMessages.weighted.name}
          description={projectMessages.weighted.description}
        />
      )}

      <div className="applicant-detail-section">
        <Card className="applicant-detail-card applicant-detail-card-overview">
          <div className="row overview">{map(overviewItems, renderOverviewItem)}</div>
        </Card>
      </div>
      {customParticipantDataItems.length > 0 && (
        <div className="applicant-detail-section">
          <Card className="applicant-detail-card applicant-detail-card-overview">
            <div className="row overview">{map(customParticipantDataItems, renderCustomParticipantDataItem)}</div>
          </Card>
        </div>
      )}

      {/* overview */}
      {doughnuts.length > 0 && (
        <div className="applicant-detail-section">
          <Card className="applicant-detail-card applicant-detail-card-summary-score">
            <div className="row">
              {map(doughnuts, renderDoughnut)}
              <div className="col-sm-3 col-xs-6">
                <div className={'time-stats-container'}>
                  <div className="row">{map(timeStatsItems, renderTimeStatsItem)}</div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}

      {/* competency chart */}
      {EActivityType.ECHO.matches(project) ? (
        <div className="applicant-detail-section">
          <div className="mui-card applicant-detail-card">
            <ParticipantEchoDetail
              metrics={competencies}
              onDetailToggle={setToggleExpanded}
              detailExpanded={chart.toggleActive}
            />
          </div>
        </div>
      ) : (
        competencies.length > 0 && (
          <div className="applicant-detail-section">
            <Card className="applicant-detail-card applicant-detail-card-overview-chart">
              {renderCardTitle({
                label: messages.competencies,
                action: (
                  <Checkbox
                    checkedIcon={<ChartIcon />}
                    uncheckedIcon={<i className="material-icons">insert_chart_outlined</i>}
                    checked={chart.mode === 'CHART'}
                    onCheck={() => setChartMode(chart.mode === 'LIST' ? 'CHART' : 'LIST')}
                  />
                )
              })}
              <Chart
                competencies={competencies}
                otherCompetencies={otherCompetencies}
                {...{
                  chart,
                  competencyFactors,
                  intl,
                  descriptionEnabled: features[EProfileFeature.SKILLS_DESCRIPTION],
                  setActiveChart,
                  setToggleExpanded,
                  invokeUpgradeAccountModal,
                  setChartProperty
                }}
              />
            </Card>
          </div>
        )
      )}

      {/* performance */}
      {performanceIndicators && performanceIndicators.length > 0 && (
        <div className="applicant-detail-section">
          <Card className="applicant-detail-card">
            {renderCardTitle({ label: messages.indicators })}
            {map(chunk(performanceIndicators, performanceIndicators.length > 3 ? 2 : 3), (c, idx) => (
              <div key={idx} className="row">
                {map(c, performanceIndicator => (
                  <div className={`col-md-${c.length === 2 ? 6 : 4} col-xs-12`} key={performanceIndicator.key}>
                    <PerformanceIndicator
                      performanceIndicator={performanceIndicator}
                      intl={intl}
                      operationalizationExpanded={chart.toggleActive}
                      invokeUpgradeAccountModal={invokeUpgradeAccountModal}
                      toggleOperationalization={setToggleExpanded}
                    />
                  </div>
                ))}
              </div>
            ))}
          </Card>
        </div>
      )}

      {/* dual metrics */}
      {dualMetrics && dualMetrics.length > 0 && (
        <div className="applicant-detail-section">
          <Card className="applicant-detail-card metrics-container applicant-detail-card-dual-metrics">
            {crossComparison && !crossComparison.loading && crossComparisonFit ? (
              <div>
                {map(chunk(crossComparisonFit.metrics, 2), (c, idx) => (
                  <div key={idx} className="row">
                    {map(c, ({ metric, data, dual, mean }) => (
                      <div key={metric.key} className="col-sm-6 col-xs-12">
                        <DualMetric
                          metric={metric}
                          data={data}
                          dual={dual}
                          mean={mean}
                          multi
                          colors={{
                            main: BRAND.theme.palette.customAccentColor || BRAND.theme.palette.primary2Color,
                            overlay: metric.color
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {map(chunk(dualMetrics, 2), (c, idx) => (
                  <div key={idx} className="row">
                    {map(c, dm => (
                      <div key={dm.key} className="col-sm-6 col-xs-12">
                        <DualMetric metric={dm} size={{ min: 12, max: 24 }} {...computeDualMetricData(dm)} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
            <div>
              <DualMetricsRecommendation metrics={dualMetrics} />
            </div>
          </Card>
        </div>
      )}

      {/* interpretations */}
      {interpretations && interpretations.length > 0 && (
        <div className="applicant-detail-section">
          <Card className="applicant-detail-card applicant-detail-card-interpretations">
            {renderCardTitle({ label: messages.interpretations })}
            <div>
              {map(interpretations, (interpretation, idx) => (
                <Interpretation key={idx} {...interpretation} />
              ))}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default ParticipantActivityDetail;
