import React from 'react';
import PropTypes from 'prop-types';

import { GridRow, GridCell } from '../grid';
import LockedPlaceholder from '../placeholder/LockedPlaceholder';
import { EUpgradeRequestSource } from '../../constants/enum';

const LockedListGridRow = props => {
  const { intl, invokeUpgradeAccountModal } = props;
  const messages = intl.messages.components.pages.private.project.applicantList;
  return (
    <GridRow className="unselectable">
      <GridCell xs={24} style={{ padding: '8px' }}>
        <LockedPlaceholder
          inline
          message={messages.upgradeMessage}
          upgradeAccount={invokeUpgradeAccountModal}
          source={EUpgradeRequestSource.PARTICIPANT_PROFILE_LIST}
        />
      </GridCell>
    </GridRow>
  );
};

LockedListGridRow.propTypes = {
  intl: PropTypes.object.isRequired,
  //
  invokeUpgradeAccountModal: PropTypes.func.isRequired
};

export default LockedListGridRow;
