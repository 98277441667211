import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import FeatureIndicator from '../../../mui/FeatureIndicator';
import AddToGroupsDialog from '../../../team/AddToGroupsDialog';
import ApplicantListGrid from '../../../applicant/ApplicantListGrid';
import ProjectParticipantsTagsAssignmentOverlay from '../../../applicant/ProjectParticipantsTagsAssignmentOverlay';

const ApplicantListPage = props => {
  // props from state
  const {
    intl,
    applicants,
    metricDefinitions,
    projectCrudAllowed,
    participantSelection,
    additionalProjects,
    groupsSelectOpen,
    tagsAssignmentOpen
  } = props;

  // actions
  const {
    onActivateProject,
    onEditProject,
    invokeUpgradeAccountModal,
    handleToggleApplicantSelection,
    handleToggleAllApplicantSelection,
    handleApplicationStatusChange,
    handleNotifyUnfinishedProfile,
    handleSetApplicationStatusForSelected,
    handleCrossCompareApplicants,
    handleCompareApplicants,
    handleStartBatchOperation,
    handleAddToGroupsStart,
    handleAddToGroupsCancel,
    handleAddToGroupsConfirm,
    handleTagsAssignmentStart,
    handleTagsAssignmentCancel
  } = props;

  // computed props
  const { invitationEnabled, gridOptions, project } = props;
  // methods in applicantList scope
  const { onItemClicked, onSort, onPageSize, onPageNumber } = props;

  const projectMessages = intl.messages.components.pages.private.project;

  const hasCustomMapping = !!project?.analytics.customMapping;
  const metricsConfig = project?.analytics.metricsConfig || {};
  const hasCustomModel = (metricsConfig.disabled || []).length > 0 || !_isEmpty(metricsConfig.definitions || {});

  return (
    <div>
      <div className="mui-feature-indicators">
        {hasCustomMapping && (
          <FeatureIndicator name={projectMessages.weighted.name} description={projectMessages.weighted.description} />
        )}
        {hasCustomModel && (
          <FeatureIndicator
            name={projectMessages.customModel.name}
            description={projectMessages.customModel.description}
          />
        )}
      </div>
      <ApplicantListGrid
        applicants={applicants}
        metricDefinitions={metricDefinitions}
        intl={intl}
        invitationEnabled={invitationEnabled}
        options={gridOptions}
        project={project}
        projectCrudAllowed={projectCrudAllowed}
        participantSelection={participantSelection}
        additionalProjects={additionalProjects}
        // actions
        handleToggleAllApplicantSelection={handleToggleAllApplicantSelection}
        handleToggleApplicantSelection={handleToggleApplicantSelection}
        handleApplicationStatusChange={handleApplicationStatusChange}
        handleNotifyUnfinishedProfile={handleNotifyUnfinishedProfile}
        handleStartBatchOperation={handleStartBatchOperation}
        handleSetApplicationStatusForSelected={handleSetApplicationStatusForSelected}
        handleCrossCompareApplicants={handleCrossCompareApplicants}
        handleCompareApplicants={handleCompareApplicants}
        handleAddToGroupsStart={handleAddToGroupsStart}
        handleTagsAssignmentStart={handleTagsAssignmentStart}
        invokeUpgradeAccountModal={invokeUpgradeAccountModal}
        onActivateProject={onActivateProject}
        onEditProject={onEditProject}
        onItemClicked={onItemClicked}
        onPageNumber={onPageNumber}
        onPageSize={onPageSize}
        onSort={onSort}
      />
      {groupsSelectOpen && (
        <AddToGroupsDialog
          mode={'ADD'}
          onClose={handleAddToGroupsCancel}
          onConfirm={handleAddToGroupsConfirm}
          participants={participantSelection.list}
        />
      )}
      {tagsAssignmentOpen && (
        <ProjectParticipantsTagsAssignmentOverlay
          project={project}
          participants={participantSelection.list}
          onClose={handleTagsAssignmentCancel}
        />
      )}
    </div>
  );
};

ApplicantListPage.propTypes = {
  intl: PropTypes.object,
  applicants: PropTypes.array,
  invitationEnabled: PropTypes.bool,
  gridOptions: PropTypes.object,
  project: PropTypes.object,
  onItemClicked: PropTypes.func,
  onSort: PropTypes.func,
  onPageSize: PropTypes.func,
  onPageNumber: PropTypes.func,
  invokeUpgradeAccountModal: PropTypes.func.isRequired
};

export default ApplicantListPage;
