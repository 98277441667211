import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import color from 'color';
import _map from 'lodash/map';

const BASE_COLOR = '#f44336';
const BASE_COLORS = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b'
];

const generateColors = () => {
  const res = [];
  for (let i = 0; i < BASE_COLORS.length; i += 1) {
    const current = color(BASE_COLORS[i]);
    res.push({ color: current.hex().toString(), dark: current.isDark() });
  }
  return res;
};

const COLORS = generateColors();

export const getRandomColor = () => {
  return COLORS[Math.floor(Math.random() * COLORS.length)].color;
};

const ColorPicker = props => {
  const { onChange } = props;
  const [current, setCurrent] = useState(props.color || BASE_COLOR);
  const handleSetColor = c => {
    setCurrent(c);
    if (onChange) {
      onChange(c);
    }
  };
  return (
    <div className="color-picker">
      <div className="color-picker__basic-colors">
        {_map(COLORS, c => (
          <div key={c.color} className="color-picker__basic-container">
            <button
              className="color-picker__basic"
              style={{ backgroundColor: c.color }}
              onClick={() => handleSetColor(c.color)}
            >
              {current === c.color && (
                <i className="material-icons" style={c.dark ? { color: 'white' } : {}}>
                  check
                </i>
              )}
            </button>
          </div>
        ))}
      </div>
      <HexColorPicker color={current} onChange={handleSetColor} />
    </div>
  );
};

export default ColorPicker;
