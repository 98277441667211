import React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import map from 'lodash/map';

import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';

import Card from '../../../mui/Card';
import Dialog from '../../../mui/Dialog';
import CardActions from '../../../mui/CardActions';
import CardHeader from '../../../mui/CardHeader';
import Divider from '../../../mui/Divider';
import Personal from '../../../applicant/Personal';
import InitialsAvatar from '../../../applicant/InitialsAvatar';
import TalentRequirementsMatch from '../../../applicant/TalentRequirementsMatch';
import TalentPreferencesMatch from '../../../applicant/TalentPreferencesMatch';
import LockedPlaceholder from '../../../placeholder/LockedPlaceholder';
import Tooltip from '../../../tooltip/Tooltip';
import Badge from '../../../badge/Badge';
import HorizontalScrollPane from '../../../basic/HorizontalScrollPane';
import ParticipantName from '../../../basic/ParticipantName';
import FitIndicator from '../../../matching/FitIndicator';
import MenuItem from '../../../menu/MenuItem';
import UserTags from '../../../tag/UserTags';

import ParticipantActivityDetail from './ParticipantActivityDetail';

import BaseContainer from '../../../basic/BaseContainer';

import { EApplicationStatus, EProjectType, EUpgradeRequestSource } from '../../../../constants/enum';
import { HEADER_SELECTOR } from '../../../../constants/constants';

const renderActionIconButton = ({ icon, tooltipText, containerElement, onClick, disabled }, idx) => {
  return (
    <div key={idx} style={{ display: 'inline-block' }} className="tooltip-element">
      <IconButton containerElement={containerElement} onClick={onClick} disabled={disabled}>
        <i className="material-icons">{icon}</i>
      </IconButton>
      <Tooltip>{tooltipText}</Tooltip>
    </div>
  );
};

const renderActionButton = ({ icon, label, tooltipText, containerElement, onClick, disabled }, idx) => {
  return (
    <div key={idx} style={{ display: 'inline-block' }} className="tooltip-element">
      <FlatButton
        label={label}
        containerElement={containerElement}
        onClick={onClick}
        disabled={disabled}
        icon={<i className="material-icons">{icon}</i>}
      />
      {label !== tooltipText && <Tooltip>{tooltipText}</Tooltip>}
    </div>
  );
};

const ApplicantDetailPage = props => {
  // props
  const {
    userId,
    intl,
    locked,
    features,
    anonymous,
    firstName,
    lastName,
    email,
    gameId,
    activities,
    chart,
    evaluationDescriptionEnabled,
    evaluationDescription,
    publicReportUrl,
    print,
    comparisonHidden,
    projectMessages,
    applicationStatus,
    messages,
    positionShown,
    personal,
    currentActivityIndex,
    match,
    customActions,
    customHeaderAddendum,
    customTitle,
    disableStickyHeader,
    //
    companyId
  } = props;
  // actions
  const {
    setActiveChart,
    setToggleExpanded,
    setChartMode,
    setChartProperty,
    getApplicantEvaluationDescription,
    removeEvaluationDescription,
    invokeUpgradeAccountModal,
    handleCompare,
    handleSetCurrentActivityIndex
  } = props;

  if (locked) {
    return (
      <BaseContainer>
        <div className="applicant-detail">
          {locked && (
            <Card style={{ marginTop: '30px', padding: '16px' }}>
              <LockedPlaceholder
                message={messages.lockedProfileMessage}
                upgradeAccount={invokeUpgradeAccountModal}
                source={EUpgradeRequestSource.PARTICIPANT_PROFILE}
              />
            </Card>
          )}
        </div>
      </BaseContainer>
    );
  }

  const enumMessages = intl.messages.constants.enums;

  const tabs = [];
  if (match) {
    tabs.push({
      title: intl.messages.matching.requirements.title,
      fit: match.fits.requirements || {},
      MatchContainer: TalentRequirementsMatch,
      className: 'matching-primary'
    });
    tabs.push({
      title: intl.messages.matching.preferences.title,
      fit: match.fits.offer || {},
      MatchContainer: TalentPreferencesMatch,
      className: 'matching-secondary'
    });
  }
  for (let i = 0; i < activities.length; i += 1) {
    const a = activities[i];
    tabs.push({
      title: a.activityName,
      subtitle: a.projectName,
      fit: a.fit,
      className: a.fit ? 'matching-tertiary' : null,
      activityIndex: i
    });
  }
  const currentTab = tabs[currentActivityIndex];
  const currentActivity = currentTab && currentTab.activityIndex != null && activities[currentTab.activityIndex];
  const { MatchContainer } = currentTab || {};

  const isQuestionnaire = currentActivity && currentActivity.projectType === EProjectType.QUESTIONNAIRE.key;
  const isComparable = !isQuestionnaire;
  const isDescribable = !isQuestionnaire;
  const isPrintable = true;

  const actions = [];
  if (publicReportUrl) {
    actions.push({
      icon: 'assignment_ind',
      containerElement: <a href={publicReportUrl} target="_blank" rel="noopener noreferrer" />,
      label: messages.publicProfile,
      tooltipText: messages.publicProfile
    });
  }
  if (evaluationDescriptionEnabled && isDescribable) {
    actions.push({
      icon: 'assignment',
      onClick: () => getApplicantEvaluationDescription(gameId),
      label: messages.evaluation,
      tooltipText: messages.evaluation
    });
  }
  if (!comparisonHidden && isComparable) {
    actions.push({
      icon: 'compare_arrows',
      onClick: handleCompare,
      label: messages.compareTooltip,
      tooltipText: messages.compareTooltip
    });
  }
  if (!print.hidden && isPrintable) {
    const aIdxParam = currentTab && currentTab.activityIndex != null ? `&aidx=${currentTab.activityIndex}` : '';
    const href = `${print.href}${aIdxParam}`;
    actions.push({
      icon: 'print',
      onClick: () => window.open(href, '_blank'),
      disabled: !print.enabled,
      label: messages.print,
      tooltipText: print.enabled ? messages.print : messages.lockedPrintMessage
    });
  }

  return (
    <BaseContainer>
      <div className="applicant-detail">
        {customTitle && (
          <div className="mui-label" style={{ margin: '8px 16px' }}>
            {customTitle}
          </div>
        )}
        <div className="applicant-detail-section applicant-detail-section-summary">
          {(isComparable ||
            isPrintable ||
            (evaluationDescriptionEnabled && isDescribable) ||
            publicReportUrl ||
            !anonymous) && (
            <Sticky top={HEADER_SELECTOR} enabled={!disableStickyHeader} innerZ={700} activeClass="sticky-active">
              <Card className="applicant-detail-card applicant-detail-card-summary">
                <div className="container-flex-row ai-center fw-yes" style={{ padding: '8px' }}>
                  <CardHeader
                    className="flex1"
                    style={{ padding: '8px', minHeight: '54px' }}
                    title={
                      <span>
                        <ParticipantName
                          variant="mini"
                          firstName={firstName}
                          lastName={lastName}
                          anonymous={anonymous}
                        />
                        {EApplicationStatus[applicationStatus] && !EApplicationStatus[applicationStatus].none && (
                          <Badge
                            color={EApplicationStatus[applicationStatus].color}
                            inverted
                            text={enumMessages.EApplicationStatus[applicationStatus].name}
                            style={{ marginLeft: 8 }}
                          />
                        )}
                      </span>
                    }
                    subtitle={anonymous ? undefined : email}
                    image={
                      <InitialsAvatar
                        identifier={userId}
                        firstName={anonymous ? undefined : firstName}
                        lastName={anonymous ? undefined : lastName}
                      />
                    }
                  >
                    <div className="applicant-detail-card-summary-actions hidden-xs">
                      {map(actions, renderActionIconButton)}
                    </div>
                  </CardHeader>
                  {customHeaderAddendum && <div style={{ padding: '8px' }}>{customHeaderAddendum}</div>}
                </div>
                {actions.length > 0 && (
                  <div className="visible-xs">
                    <Divider />
                    <CardActions>{map(actions, renderActionButton)}</CardActions>
                  </div>
                )}
              </Card>
            </Sticky>
          )}
        </div>
        {personal && (
          <div className="applicant-detail-section applicant-detail-section-summary" style={{ borderRadius: 0 }}>
            <Card className="applicant-detail-card applicant-detail-card-summary">
              <div style={{ padding: '8px 16px 16px', fontWeight: 400 }}>
                <Personal {...personal} />
              </div>
            </Card>
          </div>
        )}
        {customActions && (
          <div className="applicant-detail-section applicant-detail-section-summary" style={{ borderRadius: 0 }}>
            <Card className="applicant-detail-card applicant-detail-card-summary">
              <div style={{ padding: '0 16px' }}>
                <div style={{ background: 'rgba(0,0,0,0.05)', height: '1px', width: '100%' }} />
              </div>
              <div style={{ padding: '8px 16px' }}>{customActions}</div>
            </Card>
          </div>
        )}
        <div className="bg-primary-light mui-padded-horizontal">
          <UserTags userId={userId} companyId={companyId || activities[0]?.project?.company?.id} />
        </div>
        {(tabs.length > 1 || (tabs[0] && tabs[0].fit)) && (
          <div style={{ background: 'white' }}>
            <div className="mui-card applicant-detail-card bg-primary-light" style={{ padding: 0 }}>
              <HorizontalScrollPane>
                <div className="container-flex-row">
                  {map(tabs, (tab, idx) => (
                    <MenuItem
                      key={idx}
                      label={
                        <div
                          className={`container-flex${tab.className ? ` ${tab.className}` : ''}`}
                          style={{ padding: '6px' }}
                        >
                          <div style={{ padding: '6px' }}>
                            {tab.title}
                            {tab.subtitle && (
                              <div>
                                <small style={{ color: 'inherit', textTransform: 'none', fontWeight: 300 }}>
                                  {tab.subtitle}
                                </small>
                              </div>
                            )}
                          </div>
                          {tab.fit && (
                            <div style={{ padding: 6 }}>
                              <FitIndicator fit={tab.fit} sizeVariant="compact" />
                            </div>
                          )}
                        </div>
                      }
                      active={idx === currentActivityIndex}
                      onClick={() => handleSetCurrentActivityIndex(idx)}
                    />
                  ))}
                </div>
              </HorizontalScrollPane>
            </div>
          </div>
        )}
        {MatchContainer && (
          <div className={`applicant-detail-section${currentTab.className ? ` ${currentTab.className}` : ''}`}>
            <div className="mui-card applicant-detail-card">
              <MatchContainer match={match} />
            </div>
          </div>
        )}
        {currentTab && currentTab.activityIndex != null && (
          <ParticipantActivityDetail
            {...activities[currentTab.activityIndex]}
            intl={intl}
            messages={messages}
            projectMessages={projectMessages}
            chart={chart}
            features={features}
            positionShown={positionShown}
            //
            setChartMode={setChartMode}
            setActiveChart={setActiveChart}
            setToggleExpanded={setToggleExpanded}
            setChartProperty={setChartProperty}
            invokeUpgradeAccountModal={invokeUpgradeAccountModal}
          />
        )}

        {evaluationDescription && (
          <Dialog
            actions={[<FlatButton label="Close" primary onClick={removeEvaluationDescription} />]}
            modal={false}
            autoScrollBodyContent
            open
            title="Evaluation description"
            bodyStyle={{ color: 'rgba(33, 33, 33, 0.87)' }}
            onRequestClose={removeEvaluationDescription}
          >
            {map(evaluationDescription.split('\n'), (par, idx) => {
              const e = /\[(Task[^\]]+)]/.exec(par);
              if (e) {
                return <h4 key={idx}>{e[1]}</h4>;
              }
              return <p key={idx}>{par}</p>;
            })}
          </Dialog>
        )}
      </div>
    </BaseContainer>
  );
};

ApplicantDetailPage.propTypes = {
  intl: PropTypes.object,
  locked: PropTypes.bool,
  features: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  gameId: PropTypes.string,
  identifier: PropTypes.string,
  applicantId: PropTypes.string,
  doughnutData: PropTypes.object,
  chart: PropTypes.object,
  params: PropTypes.object,
  evaluationDescription: PropTypes.string,
  //
  setActiveChart: PropTypes.func,
  setToggleExpanded: PropTypes.func,
  getApplicantEvaluationDescription: PropTypes.func,
  removeEvaluationDescription: PropTypes.func,
  invokeUpgradeAccountModal: PropTypes.func
};

export default ApplicantDetailPage;
