import React from 'react';
import PropTypes from 'prop-types';
import L from 'lodash';
import { TransitionMotion, spring, presets } from 'react-motion';

import FloatingActionButton from 'material-ui/FloatingActionButton';
import NotificationsIcon from 'material-ui/svg-icons/social/notifications';

import Notification from './Notification';
import CTANotification from './CTANotification';
import { NOTIFICATIONS_TYPE_CTA } from '../../constants/constants';

const Notifications = props => {
  const {
    notifications: { active, hidden },
    intl
  } = props;
  const { dismissNotification, hideNotification, showHiddenNotifications } = props;
  if (hidden.length + active.length <= 0) {
    return null;
  }
  return (
    <div style={{ position: 'fixed', bottom: '0px', right: '0px', zIndex: 1001, padding: '16px' }}>
      <TransitionMotion
        willLeave={() => ({ x: spring(0, presets.gentle) })}
        willEnter={() => ({ x: 0 })}
        styles={L.map(active, a => ({
          key: a.id,
          style: { x: spring(1, presets.gentle) },
          data: a
        }))}
      >
        {styles => (
          <div style={{ position: 'relative' }}>
            {L.map(styles, ({ key, style, data }) => (
              <div
                key={key}
                style={{ marginTop: '16px', width: '288px', opacity: style.x, transform: `scale(${style.x})` }}
              >
                {data.type === NOTIFICATIONS_TYPE_CTA && (
                  <CTANotification
                    notification={data}
                    intl={intl}
                    dismissNotification={dismissNotification}
                    hideNotification={hideNotification}
                  />
                )}
                {data.type !== NOTIFICATIONS_TYPE_CTA && (
                  <Notification
                    notification={data}
                    intl={intl}
                    dismissNotification={dismissNotification}
                    hideNotification={hideNotification}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </TransitionMotion>
      <div className="pull-right" style={{ marginTop: '16px' }}>
        <FloatingActionButton mini secondary onClick={showHiddenNotifications} disabled={hidden.length === 0}>
          <NotificationsIcon />
        </FloatingActionButton>
      </div>
    </div>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  //
  addNotification: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
  dismissNotification: PropTypes.func.isRequired,
  showHiddenNotifications: PropTypes.func.isRequired
};

export default Notifications;
