import {
  GROUP_MEMBER_FILTER_OPEN,
  GROUP_MEMBER_FILTER_CLOSE,
  GROUP_MEMBER_FILTER_SET_FULL_TEXT,
  GROUP_MEMBER_FILTER_SET_FROM_VALUES
} from '../../constants/constants';

export const openGroupMemberFilter = groupId => ({ type: GROUP_MEMBER_FILTER_OPEN, payload: { groupId } });

export const closeGroupMemberFilter = groupId => ({ type: GROUP_MEMBER_FILTER_CLOSE, payload: { groupId } });

export const setGroupMemberFilterFullText = (groupId, fullText) => ({
  type: GROUP_MEMBER_FILTER_SET_FULL_TEXT,
  payload: { groupId, fullText }
});

export const setGroupMemberFilterFromValues = (groupId, filterKey, values) => ({
  type: GROUP_MEMBER_FILTER_SET_FROM_VALUES,
  payload: { groupId, filterKey, values }
});
