import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import withIntl from '../withIntl';
import { formatMessage } from '../../utils/utils';
import InfoTooltip from '../basic/InfoTooltip';

const ProjectGroupLabel = props => {
  const {
    group,
    groupMembership,
    project,
    intl: { messages }
  } = props;
  const tMessages = messages.typeMessages[group.type];
  const mMessages = (tMessages.byMembership && tMessages.byMembership[groupMembership]) || {};
  const labelMessage = mMessages?.label || tMessages.label || '';
  const title = formatMessage(labelMessage, [<b key="groupName">{group.name}</b>], true);
  return (
    <div>
      <InfoTooltip content={title} delay={500}>
        <Link className="text-sz-sm" to={`/groups/${group.id}`}>
          <div
            className={`circle-indicator ci-size-8 bg-color-${groupMembership.toLowerCase()}`}
            style={{ verticalAlign: 'baseline' }}
          />
          <span>
            <span className="text-uppercase">{mMessages.name || tMessages.name}</span>
            {group.name !== project.name && <span> <small>({group.name})</small></span>}
          </span>
        </Link>
      </InfoTooltip>
    </div>
  );
};

ProjectGroupLabel.propTypes = {
  group: PropTypes.object.isRequired,
  groupMembership: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired
};

const intlMessages = {
  cs: {
    typeMessages: {
      TEAM: {
        name: 'Tým',
        byMembership: {
          MANAGER: { name: 'Tým / Manažer', label: 'Assessment pro <b>manažery</b> týmu {0}' },
          MEMBER: { name: 'Tým / Členové', label: 'Assessment pro <b>členy</b> týmu {0}' }
        }
      },
      GROUP: {
        name: 'Skupina',
        label: 'Assessment pro členy skupiny {0}'
      },
      HIRING: {
        name: 'Otevřená pozice',
        label: 'Assessment pro kandidáty na pozici {0}'
      }
    }
  },
  sk: {
    typeMessages: {
      TEAM: {
        name: 'Tím',
        byMembership: {
          MANAGER: { name: 'Tím / Manažér', label: 'Assessment pre <b>manažérov</b> tímu {0}' },
          MEMBER: { name: 'Tím / Členovia', label: 'Assessment pre <b>členov</b> tímu {0}' }
        }
      },
      GROUP: {
        name: 'Skupina',
        label: 'Assessment pre členov skupiny {0}'
      },
      HIRING: {
        name: 'Otvorená pozícia',
        label: 'Assessment pre kandidátov na pozíciu {0}'
      }
    }
  },
  en: {
    typeMessages: {
      TEAM: {
        name: 'Team',
        byMembership: {
          MANAGER: { name: 'Team / Manager', label: 'Assessment for {0} team <b>managers</b>' },
          MEMBER: { name: 'Team / Members', label: 'Assessment for {0} team <b>members</b>' }
        }
      },
      GROUP: {
        name: 'Group',
        label: 'Assessment for {0} group members'
      },
      HIRING: {
        name: 'Open position',
        label: 'Assessment for {0} position candidates'
      }
    }
  }
};

export default withIntl(ProjectGroupLabel, intlMessages);
