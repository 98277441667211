import React from 'react';
import { connect } from 'react-redux';

const MatchRange = props => {
  const { lower, upper, intl } = props;
  const messages = intl.messages.matching.range;
  return (
    <ul style={{ margin: 0, fontSize: '13px', paddingLeft: '20px' }}>
      <li>
        {lower && (
          <div style={{ display: 'inline-block' }}>
            <span>{messages.lower.label}</span> {lower}
          </div>
        )}
        {upper && (
          <div style={{ display: 'inline-block' }}>
            <span style={{ marginLeft: lower ? '4px' : 0 }}>
              {lower && messages.and} {messages.upper.label}
            </span>{' '}
            {upper}
          </div>
        )}
      </li>
    </ul>
  );
};

export default connect(({ intl }) => ({ intl }))(MatchRange);
