import { authRequest } from './../Request.js';
import { API_URL } from '../../config';

export function createSimulationModel(data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/designer/simulations/models`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function updateSimulationModel(id, data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .put(`${API_URL}/api/v1/designer/simulations/models/${id}`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getSimulationModelMetadata(lang) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/designer/simulations/models_metadata`)
      .query({ lang })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
