import React from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import take from 'lodash/take';
import range from 'lodash/range';

import Chip from '../mui/Chip';
import Badge from '../badge/Badge';

const CHIP_STYLE = {
  margin: '0 1px 1px 0',
  display: 'inline-block',
  verticalAlign: 'middle',
  transition: 'all 0.4s'
};

const EXPANDER_STYLE = {
  margin: '0 1px 1px 0',
  background: 'rgba(0,0,0,0.05)',
  padding: '0 3px',
  height: '18px',
  display: 'inline-block',
  verticalAlign: 'middle',
  fontSize: '12px',
  borderRadius: '2px'
};

const ICON_STYLE = {
  fontSize: '12px',
  color: 'rgba(0,0,0,0.38)',
  lineHeight: '18px',
  verticalAlign: 'top'
};

const MAX_ITEMS = 5;

const PersonalChip = ({ icon, style, chipStyle, children, variant }) => {
  return (
    <div style={{ ...CHIP_STYLE, ...style }}>
      <Chip
        className={`square${icon ? ' no-left-icon-background' : ''}`}
        variant={variant || 'nano'}
        iconLeft={icon}
        style={chipStyle}
      >
        {children}
      </Chip>
    </div>
  );
};

const withCurrency = (currency, text) => {
  if (currency === 'EUR') {
    return `${text} €`;
  }
  if (currency === 'USD') {
    return `$ ${text}`;
  }
  if (currency === 'CZK') {
    return `${text} Kč`;
  }
  return `${text} ${currency};`;
};

const formatMoney = val => {
  return (val / 1000).toFixed(0);
};

const forEachFreeTextArray = (items, callback) => {
  forEach(items, item => {
    const parsedItems = item.value ? item.value.split(/\s*,\s*/) : [];
    forEach(parsedItems, pi => callback(pi));
  });
};

const enumToString = (e, enumMessages) => {
  if (!e) {
    return '';
  }
  const msg = enumMessages[e];
  return msg ? msg.shortLabel || msg.label || msg : e.toLowerCase().replace(/_/g, ' ');
};

const toGroups = chips => {
  const groupMap = {};
  const result = [];
  forEach(chips, c => {
    const g = c.group;
    if (!g) {
      result.push({ items: [c] });
    } else {
      let group = groupMap[g];
      if (!group) {
        group = { items: [], key: g, icon: c.icon };
        groupMap[g] = group;
        result.push(group);
      }
      group.items.push(c);
    }
  });
  return result;
};

const GROUP = {
  BIO: 'BIO',
  EDU: 'EDU',
  WORK_EXPERIENCE: 'WORK_EXPERIENCE',
  LANGUAGES: 'LANGUAGES',
  SKILLS: 'SKILLS',
  ROLES: 'ROLES',
  SPECIALIZATIONS: 'SPECIALIZATIONS',
  INDUSTRIES: 'INDUSTRIES',
  PDAS: 'PDAS',
  LOCATIONS: 'LOCATIONS',
  OPPORTUNITY_TYPES: 'OPPORTUNITY_TYPES',
  SALARY: 'SALARY',
  BENEFITS: 'BENEFITS',
  LOVE_BRANDS: 'LOVE_BRANDS'
};

const renderLanguageSkillLevel = level => {
  const maxStars = 3;
  let stars = 1;
  switch (level) {
    case 'NATIVE':
    case 'FLUENT': {
      stars = 3;
      break;
    }
    case 'PROFICIENT': {
      stars = 2;
      break;
    }
    default: {
      stars = 1;
    }
  }
  return (
    <span style={{ marginLeft: '2px' }}>
      {map(range(maxStars), i => (
        <i key={i} className="material-icons" style={ICON_STYLE}>
          {stars > i ? 'star' : 'star_outline'}
        </i>
      ))}
    </span>
  );
};

const CURRENT_YEAR = new Date().getFullYear();

const buildChips = props => {
  const { yearOfBirth, education, employment, skills, preferences, enumMessages, messages } = props;
  const chips = [];
  if (!isNaN(yearOfBirth) && yearOfBirth > 0) {
    chips.push({ icon: 'cake', content: CURRENT_YEAR - yearOfBirth, group: GROUP.BIO });
  }
  if (education) {
    const { fields } = education;
    if (education.education) {
      chips.push({
        icon: 'school',
        content: (
          <span>
            {enumToString(education.education, enumMessages.EEducation)}{' '}
            <i className="material-icons" style={ICON_STYLE}>
              {education.currentlyStudying ? 'timelapse' : 'check'}
            </i>
          </span>
        ),
        group: GROUP.EDU
      });
    }
    forEach(fields || [], f => chips.push({ icon: 'school', content: f.label, group: GROUP.EDU }));
  }
  if (employment) {
    const { workExperience, lastWorkPosition } = employment;
    if (workExperience) {
      chips.push({
        icon: 'work',
        content: enumToString(workExperience, enumMessages.EWorkExperienceYears),
        group: GROUP.WORK_EXPERIENCE
      });
    }
    if (lastWorkPosition) {
      chips.push({
        icon: 'assignment_ind',
        content: enumToString(lastWorkPosition, enumMessages.EWorkExperiencePosition),
        group: GROUP.WORK_EXPERIENCE
      });
    }
  }
  if (skills) {
    const { languages, other } = skills;
    forEach(languages || [], l => {
      if (l && l.label && l.level) {
        chips.push({
          icon: 'language',
          content: (
            <span>
              {l.label} {renderLanguageSkillLevel(l.level)}
            </span>
          ),
          group: GROUP.LANGUAGES
        });
      }
    });
    forEachFreeTextArray(other || [], o => chips.push({ icon: 'palette', content: o, group: GROUP.SKILLS }));
  }
  if (preferences) {
    const {
      opportunityTypes,
      industries,
      locations,
      personalDevelopmentAreas,
      salaryRange,
      salaryFree,
      benefits,
      loveBrands,
      roles,
      specializations
    } = preferences;
    forEach(opportunityTypes || [], ot =>
      chips.push({ icon: 'thumb_up', content: ot.label, group: GROUP.OPPORTUNITY_TYPES })
    );
    forEach(industries || [], i => chips.push({ icon: 'domain', content: i.label, group: GROUP.INDUSTRIES }));
    forEach(roles || [], i => chips.push({ icon: 'how_to_reg', content: i.label, group: GROUP.ROLES }));
    forEach(specializations || [], i =>
      chips.push({ icon: 'supervisor_account', content: i.label, group: GROUP.SPECIALIZATIONS })
    );
    forEach(locations || [], l => chips.push({ icon: 'place', content: l.label, group: GROUP.LOCATIONS }));
    forEach(personalDevelopmentAreas || [], pda =>
      chips.push({ icon: 'trending_up', content: pda.label, group: GROUP.PDAS })
    );
    if (salaryFree) {
      chips.push({ icon: 'attach_money', content: messages.salaryFreeLabel, group: GROUP.SALARY });
    } else if (salaryRange && salaryRange.min != null) {
      const { min, max, currency } = salaryRange;
      chips.push({
        icon: 'attach_money',
        content: withCurrency(currency, `${formatMoney(min)} - ${formatMoney(max)}k`),
        group: GROUP.SALARY
      });
    }
    forEach(benefits || [], b => chips.push({ icon: 'card_giftcard', content: b.label, group: GROUP.BENEFITS }));
    forEachFreeTextArray(loveBrands, b => chips.push({ icon: 'favorite', content: b, group: GROUP.LOVE_BRANDS }));
  }
  return {
    chips,
    groups: toGroups(chips)
  };
};

const renderChip = (c, idx) => (
  <PersonalChip key={idx} icon={c.icon} style={c.style}>
    {c.content}
  </PersonalChip>
);

const Group = props => {
  const { icon, title, children, expanded } = props;
  return (
    <div style={{ padding: expanded ? '8px' : 0, display: 'inline-block' }}>
      {expanded && (
        <div className="container-flex" style={{ marginBottom: 0 }}>
          <i
            className="material-icons"
            style={{ fontSize: '16px', color: 'rgba(33,33,33,0.54)', margin: '4px 4px 4px 0' }}
          >
            {icon}
          </i>
          <div className="mui-label" style={{ marginBottom: 0 }}>
            {title}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

const renderSeekerStatusBadge = (status, enumMessages) => {
  return (
    <div style={{ margin: '0 8px 1px 0', display: 'inline-block', verticalAlign: 'middle' }}>
      <Badge color="rgb(33, 33, 33)" inverted text={enumMessages.ESeekerStatus[status].label} />
    </div>
  );
};

class Personal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      chips: []
    };
  }

  componentDidMount() {
    this.setState({ ...buildChips(this.props) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...buildChips(this.props) });
    }
  }

  handleToggleExpand = e => {
    e.stopPropagation();
    this.setState(({ expanded }) => ({ expanded: !expanded }));
  };

  render() {
    const { seekerStatus, messages, enumMessages } = this.props;
    const { expanded, chips, groups } = this.state;
    const collapsed = chips.length > MAX_ITEMS && !expanded;
    return (
      <div style={{ margin: expanded ? '-8px' : 0 }}>
        {seekerStatus && (
          <Group icon="my_location" title={messages.seekerStatusTitle} expanded={expanded}>
            {renderSeekerStatusBadge(seekerStatus, enumMessages)}
          </Group>
        )}
        {expanded
          ? map(groups, ({ key, items, icon }) => {
              const groupMessages = messages.groupTitles[key];
              return (
                <Group key={key} icon={icon} title={(groupMessages && groupMessages.title) || 'N/A'} expanded>
                  {map(items, (c, idx) => (
                    <PersonalChip key={idx} variant="nano" chipStyle={{ backgroundColor: 'rgba(0,0,0,0.08)' }}>
                      {c.content}
                    </PersonalChip>
                  ))}
                </Group>
              );
            })
          : map(collapsed ? take(chips, MAX_ITEMS) : chips, renderChip)}
        {(collapsed || expanded) && (
          <div style={{ display: 'inline-block', verticalAlign: 'bottom', padding: expanded ? '8px' : 0 }}>
            <a role="button" onClick={this.handleToggleExpand} style={EXPANDER_STYLE}>
              <i
                className="material-icons"
                style={{
                  color: 'inherit',
                  fontSize: '14px',
                  lineHeight: '18px',
                  transition: '0.4s transform',
                  transform: `rotate(${collapsed ? 0 : 180}deg)`
                }}
              >
                {'keyboard_arrow_right'}
              </i>
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default connect(({ intl }) => ({
  enumMessages: intl.messages.constants.enums,
  messages: intl.messages.components.participant.personal
}))(Personal);
