import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import classNames from 'classnames';
import VisSensor from 'react-visibility-sensor';

import IconButton from 'material-ui/IconButton';

import ProjectFilter from './ProjectFilter';
import ProjectCard from '../../cards/ProjectCard';
import Button from '../../mui/Button';
import BaseContainer from '../../basic/BaseContainer';

import { formatMessage, isLimitedContractPlan } from '../../../utils/utils';
import { EProjectFilterLayout } from '../../../constants/enum';

const PAGE_BTNS_START = 1;
const PAGE_BTNS_END = 1;
const PAGE_BTNS_CENTER = 1;

const calculatePageClusters = ({ page, totalPagesFiltered }) => {
  const res = [];
  if (totalPagesFiltered <= 1) {
    return res;
  }
  let p = 0;
  res.push([]);
  while (p < totalPagesFiltered) {
    if (p >= PAGE_BTNS_START && p < page - PAGE_BTNS_CENTER) {
      p = page - PAGE_BTNS_CENTER;
      res.push([]);
    } else if (p > page + PAGE_BTNS_CENTER && p < totalPagesFiltered - PAGE_BTNS_END) {
      p = totalPagesFiltered - PAGE_BTNS_END;
      res.push([]);
    } else {
      res[res.length - 1].push(p);
      p += 1;
    }
  }
  return res;
};

const groupProjects = (items, currentCompany) => {
  if (currentCompany) {
    return [
      {
        name: currentCompany.name,
        items
      }
    ];
  }
  const groupedProjects = [];
  let group;
  let pp;
  for (let i = 0; i < items.length; i += 1) {
    const p = items[i];
    if (!group || !pp || p.company.id !== pp.company.id) {
      group = { name: p.company.name, items: [] };
      groupedProjects.push(group);
    }
    group.items.push(p);
    pp = p;
  }
  return groupedProjects;
};

const Projects = props => {
  const { auth, intl, projects, currentCompany, highlightProjectCards, basePath, filter } = props;
  const {
    onEditProject,
    onExportProject,
    onProjectAdd,
    onRefresh,
    onActivateProject,
    onFinishProject,
    onDeleteProject,
    onCopyProject,
    onShowProjectDetail,
    onInviteApplicants,
    onProjectPageChange
  } = props;
  const messages = intl.messages.components.cards;
  const { pagination, items } = projects;
  const pageClusters = calculatePageClusters(pagination);
  const pageClustersElems = [];
  for (let i = 0; i < pageClusters.length; i += 1) {
    if (i > 0) {
      pageClustersElems.push(
        <div
          key={`split-${i}`}
          style={{
            display: 'inline-block',
            height: '36px',
            width: '36px',
            lineHeight: '36px',
            verticalAlign: 'middle'
          }}
        >
          ...
        </div>
      );
    }
    const cluster = pageClusters[i];
    for (let j = 0; j < cluster.length; j += 1) {
      const p = cluster[j];
      pageClustersElems.push(
        <Button
          raised={pagination.page === p}
          key={p}
          onClick={() => onProjectPageChange(p)}
          style={{ minWidth: 0, verticalAlign: 'middle' }}
          label={p + 1}
        />
      );
    }
  }

  const layoutClassName = classNames({
    row: true,
    [`project-filter-layout-${filter.layout ? filter.layout.key : 'CARD'}`]: true
  });
  const itemContainerClassName = classNames({
    'full-card': true,
    'col-xs-12': true,
    'col-sm-6 col-md-4': filter.layout === EProjectFilterLayout.CARD
  });

  const groupedProjects = groupProjects(items, currentCompany);

  return (
    <BaseContainer>
      <div className="row">
        <div className="col-xs-12">
          <ProjectFilter onRefresh={onRefresh} onAdd={onProjectAdd} projects={projects} />
        </div>
      </div>
      {pagination.page > 0 && !pagination.infinite && (
        <div className="row">
          <div className="col-xs-12">
            <div className="text-right" style={{ fontSize: '12px' }}>
              <span className="text-muted">
                {formatMessage(messages.pagination.pageInfoMessage, [<b key={'pageNo'}>{pagination.page + 1}</b>])}
              </span>
              <span style={{ margin: '0 0.5em' }}>&middot;</span>
              <a role="button" className="link-button" onClick={() => onProjectPageChange(0, false)}>
                {messages.pagination.goToStartLabel}
              </a>
            </div>
          </div>
        </div>
      )}
      {map(groupedProjects, (group, idx) => (
        <div key={`group-${idx}`} className={layoutClassName}>
          {groupedProjects.length > 1 && (
            <div className="col-xs-12">
              <div className="mui-label mui-margin mui-appear">{group.name}</div>
            </div>
          )}
          {map(group.items, project => (
            <div key={project.id} className={itemContainerClassName}>
              <ProjectCard
                auth={auth}
                basePath={basePath}
                highlight={highlightProjectCards}
                project={project}
                intl={intl}
                projectCrudAllowed={auth.canEditProjects && !isLimitedContractPlan(project.company)}
                onInviteApplicants={onInviteApplicants}
                onActivateProject={onActivateProject}
                onFinishProject={onFinishProject}
                onDeleteProject={onDeleteProject}
                onEditProject={onEditProject}
                onExportProject={onExportProject}
                onShowProjectDetail={onShowProjectDetail}
                onCopyProject={onCopyProject}
                layout={filter.layout}
              />
            </div>
          ))}
        </div>
      ))}
      {pagination.totalPagesFiltered > 1 && (
        <div className="projects-page-pagination row">
          {pagination.page < pagination.totalPagesFiltered - 1 && (pagination.page === 0 || pagination.infinite) && (
            <VisSensor
              key={pagination.page}
              partialVisibility
              offset={{ bottom: -100 }}
              onChange={v => v && onProjectPageChange(pagination.page + 1, true)}
            >
              <div style={{ height: '1px', width: '100%', pointerEvents: 'none', marginTop: '-1px' }} />
            </VisSensor>
          )}
          <div className="col-xs-12" style={{ textAlign: 'center' }}>
            <IconButton
              onClick={() => onProjectPageChange(pagination.page - 1)}
              disabled={pagination.page <= 0}
              style={{ width: 36, height: 36, padding: 0, verticalAlign: 'middle' }}
            >
              <i className="material-icons">chevron_left</i>
            </IconButton>
            {pageClustersElems}
            <IconButton
              onClick={() => onProjectPageChange(pagination.page + 1, pagination.page === 0 || pagination.infinite)}
              disabled={pagination.page >= pagination.totalPagesFiltered - 1}
              style={{ width: 36, height: 36, padding: 0, verticalAlign: 'middle' }}
            >
              <i className="material-icons">chevron_right</i>
            </IconButton>
          </div>
        </div>
      )}
    </BaseContainer>
  );
};

Projects.propTypes = {
  intl: PropTypes.object,
  auth: PropTypes.object,
  highlightProjectCards: PropTypes.bool,
  projects: PropTypes.object.isRequired,
  onInviteApplicants: PropTypes.func,
  onProjectAdd: PropTypes.func,
  onProjectPageChange: PropTypes.func,
  onEditProject: PropTypes.func,
  onExportProject: PropTypes.func,
  onActivateProject: PropTypes.func,
  onFinishProject: PropTypes.func,
  onDeleteProject: PropTypes.func,
  onShowProjectDetail: PropTypes.func
};

export default Projects;
