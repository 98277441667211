import React from 'react';
import { connect } from 'react-redux';
import { touch, submit } from 'redux-form';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _get from 'lodash/get';

import ProjectTypePart, { selectCurrentProjectType } from './parts/project/ProjectTypePart';
import GeneralSettingsPart, {
  selectCurrentCompany,
  selectCurrentTemplate,
  selectCurrentProjectName,
  selectCurrentProjectPrimaryLanguage
} from './parts/project/GeneralSettingsPart';
import TextsPart from './parts/project/TextsPart';
import NotificationsPart from './parts/project/NotificationsPart';
import FormPartContainer from './parts/FormPartContainer';
import ProjectCardHeader from '../cards/ProjectCardHeader';
import ProjectCardStatus from '../cards/ProjectCardStatus';

import { selectProjectToEdit } from './parts/project/projectEditSelectors';
import { getFormsByIds } from './FormUtils';
import {
  checkEqualProperties,
  parseValidity,
  projectToChatConfig,
  convertTextAreaContentToHTML
} from '../../utils/utils';
import { EProjectType, EProjectState, ENotificationFrequency, EPoolEndpoint } from '../../constants/enum';

import * as companyActions from '../../actions/entities/companyActions';
import * as projectActions from '../../actions/entities/projectActions';
import * as activityTemplateActions from '../../actions/entities/activityTemplateActions';
import * as talentPoolActions from '../../actions/entities/talentPoolActions';
import * as projectEditActions from '../../actions/options/projectEditActions';
import { selectAccountPermissions } from '../../selectors/auth';

class AsyncPropsLoader extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadOperators(this.props);
    this.loadActivityTemplates(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.loadOperators(this.props);
      this.loadActivityTemplates(this.props);
    }
  }

  loadOperators = props => {
    const { companyId, getCompanyOperators, getCompanyPools, unsetCompanyOperators } = props;
    if (companyId) {
      getCompanyOperators(companyId);
      getCompanyPools(companyId);
    } else {
      unsetCompanyOperators();
    }
  };

  loadActivityTemplates = props => {
    const { getActivityTemplates, companyId } = props;
    getActivityTemplates(companyId);
  };

  //noinspection JSMethodCanBeStatic
  render() {
    return <div style={{ display: 'none' }} />;
  }
}

const ConnectedAsyncPropsLoader = connect(null, {
  ...companyActions,
  ...activityTemplateActions,
  ...talentPoolActions
})(AsyncPropsLoader);

const ProjectFormHeader = props => {
  const { project, intl, company } = props;

  if (!project.id) {
    //noinspection JSConstructorReturnsPrimitive
    return null;
  }

  const projectView = {
    ...project,
    company,
    validity: {
      state: project.state,
      from: project.validFrom,
      to: project.validTo
    },
    activity: {
      key: project.gameDefinitionKey,
      name: project.simulationName,
      language: project.languages && project.languages[0]
    },
    tags: project.config && project.config.tags,
    type: project.projectType
  };

  return (
    <div style={{ padding: 8 }}>
      <ProjectCardHeader project={projectView} />
      <ProjectCardStatus project={projectView} intl={intl} style={{ height: 'auto' }} />
    </div>
  );
};

const extractViews = values => {
  const { targetViewIds } = values;
  if (targetViewIds && targetViewIds.length > 0) {
    const items = _map(targetViewIds, id => ({ projectId: id }));
    return { items };
  }
  return null;
};

const extractFlows = values => {
  const { flow1ProjectId, flow1EventType } = values;
  return flow1ProjectId ? { items: [{ project: { id: flow1ProjectId }, eventType: flow1EventType }] } : null;
};

const mergeLanguages = (lang, other = []) => {
  const res = [];
  if (lang) {
    res.push(lang);
  }
  for (let i = 0; i < other.length; i += 1) {
    const otherLang = other[i];
    if (lang !== otherLang) {
      res.push(otherLang);
    }
  }
  return res;
};

const getTemplateSimulationName = (template, lang) => _get(template, `properties.${lang}.name`);

class ProjectForm extends React.Component {
  componentDidMount() {
    const { loadAvailableChatProjects, loadAvailableViewProjects } = this.props;

    loadAvailableChatProjects();
    loadAvailableViewProjects();

    this.loadProject();
  }

  componentDidUpdate(prevProps) {
    if (!checkEqualProperties(prevProps, this.props, ['projectId', 'companyId'])) {
      this.loadProject();
    }
  }

  loadProject = () => {
    const { companyId, projectId, getProject, unsetProject } = this.props;
    if (companyId && projectId) {
      getProject(companyId, projectId, true);
    } else {
      unsetProject();
    }
  };

  loadTemplateMessages = () => {
    const { company, template, projectType, projectName, projectLanguage } = this.props;
    const { getActivityTemplateMessages } = this.props;
    const data = {
      name: projectName,
      simulationName: getTemplateSimulationName(template, projectLanguage),
      languages: [projectLanguage],
      projectType,
      companyId: company && company.id
    };
    getActivityTemplateMessages(template.id, data);
  };

  getReportChat = values => {
    const { reportChatId } = values;
    if (!reportChatId) {
      return null;
    }
    const { chatProjects } = this.props;
    const { items } = chatProjects || {};
    const chatProject = _find(items, i => i.id === reportChatId);

    return chatProject ? projectToChatConfig(chatProject) : null;
  };

  handlePartActivate = partIdx => {
    // texts
    const textsIdx = this.props.project.isActive ? 1 : 2;
    if (partIdx === textsIdx) {
      this.loadTemplateMessages();
    }
  };

  handleSubmit = (values, activate) => {
    const { project, companyId, template, editProject, createProject, onClose } = this.props;

    const isNew = !project.id;
    const { isActive, ...updatedProject } = project;
    updatedProject.config = { ...updatedProject.config };

    if (!isActive) {
      updatedProject.templateId = values.templateId;
      if (template) {
        updatedProject.simulationName = getTemplateSimulationName(template, values.language);
      }
      updatedProject.positionType = values.type === EProjectType.POSITION.key ? values.positionType : null;
      updatedProject.type = values.type;
    }
    updatedProject.languages = mergeLanguages(values.language, values.supportedLanguages);
    updatedProject.name = values.name;

    const validity = parseValidity(null, values.dateEnd, values.alwaysOpen);
    updatedProject.validFrom = validity.validFrom;
    updatedProject.validTo = validity.validTo;

    // advanced
    updatedProject.poolEndpoint = values.poolEndpoint;
    updatedProject.talentPools = values.poolEndpoint === EPoolEndpoint.SOURCE.key
      ? _map(values.poolIds, id => ({ id }))
      : [];
    updatedProject.config.participantReport = values.participantReport;
    updatedProject.config.chats = { ...updatedProject.config.chats, report: this.getReportChat(values) };
    updatedProject.config.views = extractViews(values);
    updatedProject.config.flows = extractFlows(values);
    updatedProject.config.seekerQualifier = values.seekerQualifierEventType
      ? { eventType: values.seekerQualifierEventType }
      : null;
    updatedProject.view = values.view;
    updatedProject.config.personalDetails = {
      ...updatedProject.config.personalDetails,
      extent: values.personalDetailsExtent
    };
    updatedProject.config.allowedEmailDomains = values.allowedEmailDomains;
    updatedProject.config.posterEnabled = values.posterEnabled;

    // texts
    updatedProject.config.gameDataProps = {
      ...updatedProject.config.gameDataProps,
      internalName: values.internalName,
      internalDescription: values.internalDescription
    };
    updatedProject.config.landingText = {
      ...updatedProject.config.landingText,
      introduction: values.customizeIntroduction ? convertTextAreaContentToHTML(values.introductionTemplate) : null
    };
    updatedProject.config.reportText = {
      ...updatedProject.config.reportText,
      content: values.customizeReport ? convertTextAreaContentToHTML(values.reportTemplate) : null
    };

    // notifications
    const frequency =
      values.frequency && values.frequency !== ENotificationFrequency.NEVER.key ? values.frequency : null;
    updatedProject.schedule = {
      frequency,
      userIds: frequency ? values.operators : []
    };

    if (activate) {
      updatedProject.activated = true;
    }

    // save
    if (isNew) {
      createProject(values.companyId, updatedProject, onClose);
    } else {
      editProject(isActive ? companyId : values.companyId, project.id, updatedProject, onClose);
    }
  };

  getAvailableFormParts = array => {
    if (!this.props.project.isActive) {
      return array;
    }
    const res = [];
    // do not include the first part
    for (let i = 1; i < array.length; i += 1) {
      res.push(array[i]);
    }
    return res;
  };

  render() {
    const { project, intl, company, touch, auth } = this.props;
    const partsMessages = intl.messages.wizard;
    const messages = intl.messages.components.pages.private.projectEdit;

    const isNew = !project.id;

    const allForms = this.props.forms;
    const allParts = [
      {
        title: partsMessages.step1,
        render: () => <ProjectTypePart />
      },
      {
        title: partsMessages.step2,
        render: () => <GeneralSettingsPart />
      },
      {
        title: partsMessages.step3,
        render: () => <TextsPart />
      },
      {
        title: partsMessages.step4,
        render: () => <NotificationsPart />
      }
    ];

    const forms = this.getAvailableFormParts(allForms);
    const parts = this.getAvailableFormParts(allParts);

    return (
      <div className="builder-form">
        <ConnectedAsyncPropsLoader companyId={company && company.id} />
        <ProjectFormHeader project={project} company={company} intl={intl} />
        {project.state === EProjectState.CONCEPT.key && (
          <div className="well text-muted" style={{ margin: '0 16px 16px' }}>
            {messages.projectStatus.concept}
          </div>
        )}
        <FormPartContainer
          isNew={isNew}
          forms={forms}
          touch={touch}
          intl={intl}
          onSubmit={this.handleSubmit}
          onPartActivate={this.handlePartActivate}
          parts={parts}
          publish={!project.isActive}
          customSaveAndPublishLabel={messages.buttons.saveActivate}
          customCreateAndPublishLabel={messages.buttons.createActivate}
          saveDisabled={!auth.canEditProjects}
        />
      </div>
    );
  }
}

const FORMS = [ProjectTypePart.ID, GeneralSettingsPart.ID, TextsPart.ID, NotificationsPart.ID];

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    options: {
      projectEdit: { chats }
    }
  } = state;
  const project = selectProjectToEdit(state, ownProps);
  return {
    auth: selectAccountPermissions(state, ownProps),
    intl,
    project,
    chatProjects: chats,
    company: selectCurrentCompany(state, ownProps),
    projectName: selectCurrentProjectName(state, ownProps),
    projectLanguage: selectCurrentProjectPrimaryLanguage(state, ownProps),
    projectType: selectCurrentProjectType(state, ownProps),
    template: selectCurrentTemplate(state, ownProps),
    forms: getFormsByIds(FORMS, state)
  };
};

const actions = { onSubmit: submit, touch, ...projectActions, ...projectEditActions, ...activityTemplateActions };

export default connect(mapStateToProps, actions)(ProjectForm);
