import React from 'react';
import { connect } from 'react-redux';

import Badge from './Badge';

const NewBadge = props => {
  const { intl } = props;
  const { label } = intl.messages.components.pages.private.newBadge;
  return (
    <Badge
      color="rgb(176, 0, 32)"
      text={label}
      style={{ textTransform: 'uppercase', padding: '3px 4px 2px', fontSize: '11px', lineHeight: '11px' }}
    />
  );
};

export default connect(({ intl }) => ({ intl }))(NewBadge);
