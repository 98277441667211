import React from 'react';

import IconButton from 'material-ui/IconButton';
import ExpandIcon from 'material-ui/svg-icons/navigation/expand-more';
import CollapseIcon from 'material-ui/svg-icons/navigation/expand-less';

import CardHeader from 'material-ui/Card/CardHeader';

const ExpandableCardHeader = props => {
  const { expanded, style, ...rest } = props;
  return (
    <CardHeader
      style={{
        cursor: 'pointer',
        ...style
      }}
      textStyle={{
        display: 'block',
        paddingLeft: '56px',
        paddingRight: '32px',
        width: '100%'
      }}
      {...rest}
    >
      <IconButton style={{ position: 'absolute', right: '4px', top: 0, bottom: 0, margin: 'auto' }}>
        {expanded && <CollapseIcon />}
        {!expanded && <ExpandIcon />}
      </IconButton>
    </CardHeader>
  );
};

export default ExpandableCardHeader;
