import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import _values from 'lodash/values';
import { withRouter, Link } from 'react-router';

import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';

import Popover from '../mui/Popover';
import CardHeader from '../mui/CardHeader';
import FAB from '../mui/FloatingActionButton';
import InitialsAvatar from '../applicant/InitialsAvatar';

import languages from '../../constants/languages';
import withIntl from '../withIntl';

import * as localeActions from '../../actions/options/localeActions';
import * as authActions from '../../actions/auth/authActions';
import * as sidebarActions from '../../actions/options/sidebarActions';
import * as modalActions from '../../actions/options/modalActions';

import { selectAccountPermissions } from '../../selectors/auth';
import { MODAL_ACCOUNT_UPGRADE } from '../../constants/constants';

const anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
const targetOrigin = { horizontal: 'right', vertical: 'top' };

class UserMenuButton extends React.Component {
  state = {
    menuOpen: false,
    style: {}
  };

  handleMenuOpen = () => this.setState({ menuOpen: true });
  handleMenuClose = () => this.setState({ menuOpen: false });

  handleSetAnchor = el => (this.anchorEl = el);

  handleLogout = () => {
    const { router } = this.props;
    this.handleMenuClose();
    router.push('/logout');
  };
  handleLanguageUpdate = lang => {
    const { auth, intl, setLanguage, updateLocale } = this.props;
    if (intl.locale !== lang) {
      updateLocale(lang);
      setLanguage(auth, lang);
    }
    this.handleMenuClose();
  };
  handleAccountUpgrade = data => {
    this.props.switchModal({ id: MODAL_ACCOUNT_UPGRADE, open: true, status: null, data });
    this.handleMenuClose();
  };

  handleMouseEnter = () => {
    this.setState({ style: { opacity: 0.7 } });
  };
  handleMouseLeave = () => {
    this.setState({ style: {} });
  };

  render() {
    const {
      auth: { firstName, lastName, email, id, upgradable },
      intl: { messages, locale },
      currentCompany
    } = this.props;
    const { menuOpen, style } = this.state;
    const name = firstName && lastName ? `${firstName} ${lastName}` : null;
    return (
      <div
        className="inline-container"
        style={{ transition: '0.2s ease-in-out', ...style }}
        ref={this.handleSetAnchor}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <FAB
          onClick={this.handleMenuOpen}
          tooltip={name || email}
          backgroundColor={'white'}
          mini
          position={'relative'}
          style={{ boxShadow: '2px 2px 14px 0 rgba(0,0,0,0.12)' }}
        >
          <div style={{ padding: '2px' }}>
            <InitialsAvatar size={36} firstName={firstName} lastName={lastName} email={email} identifier={id} />
          </div>
        </FAB>
        <Popover
          open={menuOpen}
          anchorEl={this.anchorEl}
          anchorOrigin={anchorOrigin}
          targetOrigin={targetOrigin}
          onRequestClose={this.handleMenuClose}
          style={{ marginTop: '8px' }}
        >
          <Menu desktop listStyle={{ paddingTop: 8, paddingBottom: 8 }}>
            <div style={{ fontSize: '16px' }}>
              <CardHeader
                style={{ padding: '8px 16px' }}
                title={name || email}
                subtitle={name && email}
                image={<InitialsAvatar firstName={firstName} lastName={lastName} email={email} identifier={id} />}
              />
            </div>
            <Divider />
            <MenuItem
              primaryText={messages.language}
              leftIcon={<i className="material-icons">language</i>}
              rightIcon={<i className="material-icons">arrow_right</i>}
              menuItems={_map(_values(languages), ({ value, label }) => (
                <MenuItem
                  key={value}
                  primaryText={label}
                  leftIcon={
                    <i className="material-icons">
                      {locale === value ? 'radio_button_checked' : 'radio_button_unchecked'}
                    </i>
                  }
                  onClick={() => this.handleLanguageUpdate(value)}
                />
              ))}
            />
            {currentCompany?.contractPlan?.eapiEnabled && (
              <MenuItem
                leftIcon={<i className="material-icons">api</i>}
                primaryText={'EAPI'}
                containerElement={<Link to={'/eapi/keys'} />}
                onClick={this.handleMenuClose}
              />
            )}
            <Divider />
            {upgradable && (
              <MenuItem
                leftIcon={<i className="material-icons">upgrade</i>}
                primaryText={messages.upgrade}
                onClick={() => this.handleAccountUpgrade(null)}
              />
            )}
            <MenuItem
              leftIcon={<i className="material-icons">power_settings_new</i>}
              primaryText={messages.logout}
              onClick={this.handleLogout}
            />
          </Menu>
        </Popover>
      </div>
    );
  }
}

const intlMessages = {
  cs: {
    logout: 'Odhlásit',
    upgrade: 'Premium',
    language: 'Jazyk'
  },
  sk: {
    logout: 'Odhlásiť',
    upgrade: 'Prémium',
    language: 'Jazyk'
  },
  en: {
    logout: 'Logout',
    upgrade: 'Premium',
    language: 'Language'
  }
};

const mapStateToProps = (state, ownProps) => {
  const {
    entities: {
      companies,
      currentCompany: { item }
    }
  } = state;
  return { auth: selectAccountPermissions(state, ownProps), companies, currentCompany: item };
};
const actions = {
  updateLocale: localeActions.updateLocale,
  setLanguage: authActions.setLanguage,
  toggleSidebar: sidebarActions.toggleSidebar,
  switchModal: modalActions.switchModal
};

const IntlUserMenuButton = withIntl(UserMenuButton, intlMessages);
const ConnectedUserMenuButton = connect(mapStateToProps, actions)(IntlUserMenuButton);

export default withRouter(ConnectedUserMenuButton);
