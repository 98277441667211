import React from 'react';
import { pure } from 'recompose';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';

import Checkbox from 'material-ui/Checkbox';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { GridRow, GridCell, GridLabel, SortableGridLabel } from '../grid';
import { STYLES } from './ApplicantListGrid';
import MetricsCellContent from './cells/MetricsCellContent';
import FinishedCellContent from './cells/FinishedCellContent';
import ParticipantNameCellContent from './cells/ParticipantNameCellContent';
import Separator from './cells/Separator';
import UnfinishedListGridRow from './UnfinishedListGridRow';
import LockedListGridRow from './LockedListGridRow';

import { EApplicationStatus, EApplicantListSort } from '../../constants/enum';
import { formatPosition, formatMessage } from '../../utils/utils';

const stopPropagation = e => e.stopPropagation();

const FinishedCell = ({ row, locale, messages, onSort, currentSort, currentOrder, ...props }) => (
  <GridCell style={STYLES.alignRight} {...props}>
    <SortableGridLabel
      smHide
      mdHide
      lgHide
      sort={EApplicantListSort.FINISHED_AT}
      currentSort={currentSort}
      currentOrder={currentOrder}
      onSort={onSort}
    >
      {messages.cols.finishedAt}
    </SortableGridLabel>
    <FinishedCellContent finishedTimestamp={row.simulation.finishedAt} locale={locale} metrics={row.result.metrics} />
  </GridCell>
);

const getMetricDefinitions = (projectId, additionalProjects, defaultMetricDefinitions) => {
  const config = additionalProjects && additionalProjects[projectId];
  return (config && config.metricDefinitions) || defaultMetricDefinitions;
};

const renderAdditionalMetrics = (activities, additionalProjects, metricDefinitions) => {
  return map(activities, ({ result: { metrics }, projectId }) => (
    <MetricsCellContent
      key={projectId}
      metrics={metrics}
      metricDefinitions={getMetricDefinitions(projectId, additionalProjects, metricDefinitions)}
    />
  ));
};

const ApplicantListGridRow = props => {
  const { row, metricDefinitions, additionalProjects, intl, selected, project } = props;
  const {
    handleApplicationStatusChange,
    handleToggleApplicantSelection,
    handleNotifyUnfinishedProfile,
    invokeUpgradeAccountModal,
    onItemClicked
  } = props;
  const sortProps = {
    currentSort: props.currentSort,
    currentOrder: props.currentOrder,
    onSort: (e, ...args) => {
      e.stopPropagation();
      props.onSort && props.onSort(e, ...args);
    }
  };
  const messages = intl.messages.components.pages.private.project.applicantList;
  const enumMessages = intl.messages.constants.enums;
  const locked = row.simulation.locked;
  const displayed = !locked || (row.result && !isEmpty(row.result.metrics));
  if (!displayed) {
    return <LockedListGridRow intl={intl} invokeUpgradeAccountModal={invokeUpgradeAccountModal} />;
  }
  if (!row.result) {
    return (
      <UnfinishedListGridRow
        row={row}
        intl={intl}
        project={project}
        handleNotifyUnfinishedProfile={handleNotifyUnfinishedProfile}
        handleToggleApplicantSelection={handleToggleApplicantSelection}
        selected={selected}
      />
    );
  }
  const isNew = row.ack === null || row.ack.status !== row.status;
  const locale = intl.locale;
  const outside = row.project && row.project.id !== project.id;

  const { scoreTotal } = row.result.metrics;
  return (
    <GridRow onClick={() => onItemClicked(row)}>
      {row.crossCompared && (
        <GridCell xs={24} style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
          <div
            style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: '12px',
              verticalAlign: 'top',
              background: row.crossComparedColor
            }}
          />
          {outside && (
            <div className="mui-label" style={{ margin: 0, verticalAlign: 'top', display: 'inline-block' }}>
              {formatMessage(messages.crossComparedFromProjectMessage, [<b>{row.project.name}</b>])}
            </div>
          )}
        </GridCell>
      )}
      {row.loadingCounter > 0 && <div className="applicant-loader" />}
      {EApplicationStatus[row.applicationStatus] &&
        !EApplicationStatus[row.applicationStatus].none &&
        !outside && (
          <div
            className="application-status-container"
            style={{ backgroundColor: EApplicationStatus[row.applicationStatus].color }}
          />
        )}
      <GridCell xs={3} sm={2} md={1} style={STYLES.checkboxCell} onClick={stopPropagation}>
        <Checkbox
          checked={selected}
          iconStyle={selected ? {} : { fill: '#757575' }}
          onCheck={e => handleToggleApplicantSelection(row, e)}
        />
      </GridCell>
      <GridCell xs={6} sm={3} lg={2} style={STYLES.alignRight}>
        {(outside || !scoreTotal) ? <em className="text-muted">N/A</em> : formatPosition(row.result.position, locale)}
      </GridCell>
      <GridCell xs={9} sm={12} md={5} lg={7} className="applicant-name">
        <ParticipantNameCellContent isNew={isNew} participant={row.participant} messages={messages} />
      </GridCell>
      <GridCell xs={6} sm={3} lg={2} style={STYLES.alignRight}>
        {scoreTotal ? scoreTotal.formattedValue : <em className="text-muted">N/A</em>}
      </GridCell>
      <FinishedCell xsHide sm={4} md={3} mdPush={6} row={row} locale={locale} messages={messages} {...sortProps} />
      <Separator />
      <GridCell xs={24} sm={16} md={6} mdPull={3} className="applicant-metrics">
        <GridLabel mdHide lgHide>
          {messages.cols.competencies}
        </GridLabel>
        <MetricsCellContent metrics={row.result.metrics} metricDefinitions={metricDefinitions} />
        {row.finishedAdditionalActivities &&
          row.finishedAdditionalActivities.length > 0 &&
          renderAdditionalMetrics(row.finishedAdditionalActivities, additionalProjects, metricDefinitions)}
      </GridCell>
      <Separator smHide />
      <FinishedCell xs={12} smHide mdHide lgHide row={row} locale={locale} messages={messages} {...sortProps} />
      <GridCell xs={12} sm={8} md={3} onClick={stopPropagation}>
        <GridLabel mdHide lgHide>
          {messages.cols.applicationStatus}
        </GridLabel>
        <div>
          <SelectField
            value={row.applicationStatus}
            style={{ width: '100%', fontSize: '13px', height: '36px' }}
            iconStyle={{ right: '-12px', top: '-6px' }}
            labelStyle={{
              paddingRight: '24px',
              height: '36px',
              lineHeight: '36px',
              top: 0,
              color: row.applicationStatus === EApplicationStatus.NONE ? 'rgba(33,33,33,.27)' : 'rgba(33,33,33,.87)'
            }}
            underlineStyle={{ bottom: 0 }}
            autoWidth
            disabled={project && project.demo}
            onChange={(e, key, val) => handleApplicationStatusChange(row, val)}
          >
            {map(orderBy(values(EApplicationStatus), ['order']), s => (
              <MenuItem key={s.value} value={s.value} primaryText={enumMessages.EApplicationStatus[s.value].name} />
            ))}
          </SelectField>
        </div>
      </GridCell>
    </GridRow>
  );
};

export default pure(ApplicantListGridRow);
