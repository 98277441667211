import { createApiCallAction } from '../apiCallAction';
import * as Api from '../../api/entities/talentMarketPlanApi';
import {
  TALENT_MARKET_PLANS_GET,
  TALENT_MARKET_PLAN_SELECT,
  TALENT_MARKET_PLAN_CURRENCY_SELECT,
  TALENT_MARKET_PLAN_BILLING_PERIOD_SELECT
} from '../../constants/constants';

const getTalentMarketPlansAction = createApiCallAction(TALENT_MARKET_PLANS_GET, Api.getTalentMarketPlans, {
  transformArgsToMeta: ([lang]) => ({ lang })
});
export const getTalentMarketPlans = lang => getTalentMarketPlansAction(lang);

export const selectTalentMarketPlan = planId => ({ type: TALENT_MARKET_PLAN_SELECT, payload: { planId } });
export const selectTalentMarketPlanCurrency = currency => ({
  type: TALENT_MARKET_PLAN_CURRENCY_SELECT,
  payload: { currency }
});
export const selectTalentMarketPlanBillingPeriod = billingPeriod => ({
  type: TALENT_MARKET_PLAN_BILLING_PERIOD_SELECT,
  payload: { billingPeriod }
});
