import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _reduce from 'lodash/reduce';

import { ERank } from '../../constants/enum';
import { getActivityMapKey } from './TeamUtils';

export const EMetricFocus = {
  SIGNIFICANT: {
    key: 'SIGNIFICANT'
  },
  SELECTED: {
    key: 'SELECTED'
  },
  ALL: {
    key: 'ALL'
  }
};

export const EGroupFocus = {
  FULL: {
    key: 'FULL'
  },
  MANAGER: {
    key: 'MANAGER'
  },
  MEMBER: {
    key: 'MEMBER'
  }
};

export const EMetricInsightProperty = {
  INFO: {
    key: 'INFO',
    path: 'operationalization'
  },
  INTERPRETATION: {
    key: 'INTERPRETATION',
    paths: ['interpretation.bullets', 'interpretation.text']
  },
  CF_TEAM_MANAGER_SITUATION: {
    key: 'CF_TEAM_MANAGER_SITUATION',
    path: 'interpretation.situations[1].situation.bullets'
  },
  CF_TEAM_SITUATION: {
    key: 'CF_TEAM_SITUATION',
    path: 'interpretation.situations[0].situation.bullets'
  },
  CF_TEAM_CHALLENGE: {
    key: 'CF_TEAM_CHALLENGE',
    path: 'interpretation.challenge.bullets'
  },
  CF_TEAM_RESOLUTION: {
    key: 'CF_TEAM_RESOLUTION',
    path: 'interpretation.resolution.bullets'
  },
  RECOMMENDATION_MOTIVATION: {
    key: 'RECOMMENDATION_MOTIVATION',
    path: 'interpretation.recommendations.motivation'
  },
  RECOMMENDATION_MANAGEMENT: {
    key: 'RECOMMENDATION_MANAGEMENT',
    path: 'interpretation.recommendations.management'
  },
  RECOMMENDATION_REMUNERATION: {
    key: 'RECOMMENDATION_REMUNERATION',
    path: 'interpretation.recommendations.remuneration'
  },
  RECOMMENDATION_APPLICATION: {
    key: 'RECOMMENDATION_APPLICATION',
    path: 'interpretation.recommendations.application'
  },
  RECOMMENDATION_OBSTACLE: {
    key: 'RECOMMENDATION_OBSTACLE',
    path: 'interpretation.recommendations.obstacle'
  },
  RECOMMENDATION_DEVELOPMENT: {
    key: 'RECOMMENDATION_DEVELOPMENT',
    path: 'interpretation.recommendations.development'
  },
  STATS: {
    key: 'STATS'
  },
  CULTURE_FIT: {
    key: 'CULTURE_FIT'
  },
  CULTURE_CONVERGENCE: {
    key: 'CULTURE_CONVERGENCE'
  }
};

const childrenOnly = definitions => {
  const res = { ...definitions };
  for (let key in res) {
    if (res.hasOwnProperty(key)) {
      const m = res[key];
      if (m.parent) {
        delete res[m.parent];
      }
    }
  }
  return res;
};

const markInactive = (all, active) => {
  if (!active || active.length === 0) {
    // treat all as active if provided array is empty
    return;
  }
  const activeMap = _reduce(
    active,
    (res, m) => {
      res[m.key] = m;
      return res;
    },
    {}
  );
  _forEach(all, m => {
    if (!activeMap[m.key]) {
      m.inactive = true;
    }
  });
};
const metricSortFunction = (a, b) => (a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0);

export const extractMetricsFromGroup = (members, metricStats, definitions, focus, focusedMetrics = {}) => {
  const list = [];
  const all = [];
  const childrenDefinitions = childrenOnly(definitions);
  _forEach(metricStats, (ms, key) => {
    const md = childrenDefinitions[key];
    if (!md || md.displayOrder == null || md.type === 'CATEGORY') {
      return;
    }
    const metric = { key, ...md, stats: ms };
    all.push(metric);
    if (focus === EMetricFocus.SIGNIFICANT) {
      // TODO think about this
      if (md.type === 'NUMERIC_DUAL') {
        if (ms.avg < 0.33 || ms.avg > 0.67) {
          list.push(metric);
        }
      } else if (ms.avg < 0.4 || ms.avg > 0.7) {
        list.push(metric);
      }
    } else if (focus === EMetricFocus.SELECTED && !!focusedMetrics[key]) {
      list.push(metric);
    } else if (focus === EMetricFocus.ALL) {
      list.push(metric);
    }
  });
  markInactive(all, list);
  list.sort(metricSortFunction);
  all.sort(metricSortFunction);
  return { list, all };
};

export const extractMetricsFromIndividual = (a, definitions, focus, focusedMetrics = {}) => {
  const list = [];
  const all = [];
  const metrics = a.result?.metrics || {};
  const childrenDefinitions = childrenOnly(definitions);
  for (let key in metrics) {
    if (metrics.hasOwnProperty(key)) {
      // use only metric that has display order
      const md = childrenDefinitions[key];
      if (!md || md.displayOrder == null || md.type === 'CATEGORY') {
        continue;
      }
      const baseMetric = metrics[key];
      const metric = { key, ...md, ...baseMetric };
      all.push(metric);
      if (focus === EMetricFocus.SIGNIFICANT) {
        if (metric.rank === ERank.HIGH.key || metric.rank === ERank.LOW.key) {
          list.push(metric);
        }
      } else if (focus === EMetricFocus.SELECTED && !!focusedMetrics[key]) {
        list.push(metric);
      } else if (focus === EMetricFocus.ALL) {
        list.push(metric);
      }
    }
  }
  markInactive(all, list);
  list.sort(metricSortFunction);
  all.sort(metricSortFunction);
  return {
    list,
    all
  };
};

const findPropInMetric = (m, paths) => {
  for (let i = 0; i < paths.length; i += 1) {
    if (_get(m, paths[i])) {
      return true;
    }
  }
  return false;
};

const extractMetricPropertiesCommon = (a, metricsList, isGroup) => {
  const res = {};
  for (let i = 0; i < metricsList.length; i += 1) {
    const m = metricsList[i];
    for (let prop in EMetricInsightProperty) {
      if (EMetricInsightProperty.hasOwnProperty(prop)) {
        const eVal = EMetricInsightProperty[prop];
        if (!res[eVal.key]) {
          if (eVal === EMetricInsightProperty.STATS) {
            if (isGroup && m.type !== 'NUMERIC_DUAL') {
              res[eVal.key] = true;
            } else if (m.valueType === 'STEN' || m.valueType === 'SEMI_STEN' || m.valueType === 'STEN_WEIGHED') {
              res[eVal.key] = true;
            }
          } else if (eVal === EMetricInsightProperty.CULTURE_CONVERGENCE) {
            if (isGroup && m.type === 'NUMERIC_DUAL') {
              res[eVal.key] = true;
            }
          } else if (eVal === EMetricInsightProperty.CULTURE_FIT) {
            if (m.valueType === 'DISCRETE_FOUR') {
              res[eVal.key] = true;
            }
          } else {
            const paths = eVal.paths || [eVal.path];
            const found = findPropInMetric(m, paths);
            if (found) {
              res[eVal.key] = true;
            }
          }
        }
      }
    }
  }
  return res;
};

export const extractMetricPropertiesFromIndividual = (a, metricsList) => extractMetricPropertiesCommon(a, metricsList);
export const extractMetricPropertiesFromGroup = (a, metricsList) => extractMetricPropertiesCommon(a, metricsList, true);

const forEachMemberMetric = (m, callback) => {
  const fas = m.activities || [];
  for (let j = 0; j < fas.length; j += 1) {
    const fa = fas[j];
    const faKey = getActivityMapKey(fa);
    const fam = fa.result?.metrics || {};
    for (let key in fam) {
      if (fam.hasOwnProperty(key)) {
        callback(faKey, fa, key, fam[key], fam);
      }
    }
  }
};

const computeIfAbsent = (map, key, compute) => {
  let val = map[key];
  if (val != null) {
    return val;
  }
  val = compute(key);
  map[key] = val;
  return val;
};

const forEachResultMetric = (res, callback) => {
  for (let aKey in res) {
    if (res.hasOwnProperty(aKey)) {
      const mRes = res[aKey];
      for (let mKey in mRes) {
        if (mRes.hasOwnProperty(mKey)) {
          callback(aKey, mRes, mKey, mRes[mKey]);
        }
      }
    }
  }
};

export const computeGroupMetricStats = (activities, members) => {
  const res = {};
  for (let i = 0; i < members.length; i += 1) {
    const member = members[i];
    forEachMemberMetric(member, (activityKey, activity, key, m) => {
      const actStats = computeIfAbsent(res, activityKey, () => ({}));
      const prev = computeIfAbsent(actStats, key, () => ({ count: 0, sum: 0, metrics: [] }));
      //
      prev.count += 1;
      prev.sum += m.value;
      if (prev.min == null || prev.min > m.value) {
        prev.min = m.value;
      }
      if (prev.max == null || prev.max < m.value) {
        prev.max = m.value;
      }
      prev.metrics.push(m);
    });
  }
  forEachResultMetric(res, (aKey, mRes, mKey, stats) => {
    const metrics = stats.metrics;
    metrics.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
    stats.avg = stats.sum / stats.count;
    const midPoint = Math.floor((metrics.length - 1) / 2);
    if (metrics.length > 0) {
      stats.median =
        metrics.length % 2 === 0
          ? (metrics[midPoint].value + metrics[midPoint + 1].value) / 2
          : metrics[midPoint].value;
    } else {
      stats.median = 0;
    }
  });
  return res;
};

export const createGetParticipantProps = (activity, highlighted) => m => {
  const metrics = _get(m, `meta.activities.map[${activity.mapKey}].result.metrics`);
  const result = metrics ? { metrics } : null;
  return {
    id: m.id,
    participant: m,
    result,
    crossCompared: !!highlighted[m.id],
    crossComparedColor: highlighted[m.id]
  };
};

const updateActivityConfigs = (activityConfigsBuilder, activityConfigPartProvider, updater, activities) => {
  for (let i = 0; i < activities.length; i += 1) {
    const a = activities[i];
    const part = activityConfigPartProvider(a);
    if (part != null) {
      let cfg = activityConfigsBuilder.map[a.mapKey];
      if (!cfg) {
        cfg = { key: a.key, variant: a.variant };
        activityConfigsBuilder.list.push(cfg);
        activityConfigsBuilder.map[a.mapKey] = cfg;
      }
      updater(part, cfg);
    }
  }
};

export const toInsightEntity = (
  {
    name,
    note,
    activeMemberId,
    focusKey,
    focusedMetrics,
    groupFocusKey,
    activityMetricProperties,
    activityChartsShown,
    activityParticipantsListShown,
    activityCollapsed,
    starredAt
  },
  team,
  manager,
  fallbackName,
  activities
) => {
  let individual;
  if (activeMemberId && EGroupFocus.MEMBER.key) {
    individual = { id: activeMemberId };
  } else if (manager && EGroupFocus.MANAGER.key) {
    individual = { id: manager.id };
  }
  const activityBuilderConfig = { list: [], map: {} };
  updateActivityConfigs(
    activityBuilderConfig,
    a => activityMetricProperties[a.mapKey],
    (p, cfg) => {
      for (let prop in p) {
        if (p.hasOwnProperty(prop) && p[prop]) {
          if (!cfg.metricProperties) {
            cfg.metricProperties = [];
          }
          cfg.metricProperties.push(prop);
        }
      }
    },
    activities
  );
  updateActivityConfigs(
    activityBuilderConfig,
    a => activityChartsShown[a.mapKey],
    (p, cfg) => {
      if (p) {
        cfg.chartsShown = true;
      }
    },
    activities
  );
  updateActivityConfigs(
    activityBuilderConfig,
    a => activityParticipantsListShown[a.mapKey],
    (p, cfg) => {
      if (p) {
        cfg.participantsListShown = true;
      }
    },
    activities
  );
  updateActivityConfigs(
    activityBuilderConfig,
    a => activityCollapsed[a.mapKey],
    (p, cfg) => {
      if (p) {
        cfg.collapsed = true;
      }
    },
    activities
  );
  updateActivityConfigs(
    activityBuilderConfig,
    a => a.metrics.all,
    (metrics, cfg) => {
      if (metrics) {
        cfg.focusedMetrics = _reduce(
          metrics,
          (res, m) => {
            if (focusedMetrics[m.key]) {
              res.push(m.key);
            }
            return res;
          },
          []
        );
      }
    },
    activities
  );
  return {
    group: { id: team.id },
    name: (name || fallbackName).trim(),
    note: (note && note.trim()) || null,
    individual,
    metricFocus: focusKey,
    groupFocus: groupFocusKey,
    starredAt,
    activityConfigs: activityBuilderConfig.list
  };
};

export const fromInsightEntity = insight => {
  const groupFocusKey = insight.groupFocus;
  const focusedMetrics = {};
  const activityMetricProperties = {};
  const activityChartsShown = {};
  const activityParticipantsListShown = {};
  const activityCollapsed = {};
  for (let i = 0; i < insight.activityConfigs.length; i += 1) {
    const cfg = insight.activityConfigs[i];
    const mapKey = getActivityMapKey(cfg);
    if (cfg.collapsed) {
      activityCollapsed[mapKey] = true;
    }
    if (cfg.chartsShown) {
      activityChartsShown[mapKey] = true;
    }
    if (cfg.participantsListShown) {
      activityParticipantsListShown[mapKey] = true;
    }
    activityMetricProperties[mapKey] = _reduce(
      cfg.metricProperties || [],
      (res, mp) => {
        res[mp] = true;
        return res;
      },
      {}
    );
    _forEach(cfg.focusedMetrics || [], m => (focusedMetrics[m] = true));
  }
  return {
    insightId: insight.id,
    name: insight.name,
    note: insight.note,
    activeMemberId: groupFocusKey === EGroupFocus.MEMBER.key ? insight.individual?.id : null,
    focusKey: insight.metricFocus,
    focusedMetrics,
    groupFocusKey,
    activityMetricProperties,
    activityChartsShown,
    activityParticipantsListShown,
    activityCollapsed,
    starredAt: insight.starredAt
  };
};
