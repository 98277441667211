import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../tooltip/Tooltip';

import { ERank } from '../../constants/enum';

import withIntl from '../withIntl';

const renderStars = (count, value, message) => {
  const stars = [];
  for (let idx = 0; idx < count; idx += 1) {
    stars.push(
      <i
        key={idx}
        className="material-icons"
        style={{ opacity: value - 1 >= idx ? 1 : 0.3, fontSize: '16px', color: 'inherit' }}
      >
        star
      </i>
    );
  }
  return (
    <div
      className="tooltip-element"
      style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '-1px', cursor: 'pointer' }}
    >
      {stars}
      <Tooltip position="bottom">{message}</Tooltip>
    </div>
  );
};

const MetricValueLabel = ({ metric, intl: { messages } }) => {
  const enumMessages = messages.constants.enums;
  if (metric.type === 'CATEGORY' && metric.interpretation?.label) {
    return metric.interpretation?.label;
  }
  if (metric.formattedValue) {
    return metric.formattedValue;
  }
  const rank = ERank[metric.rank];
  const message = enumMessages.ERank[metric.rank];
  const rankValue = rank && rank.value;
  if (rankValue != null) {
    return renderStars(3, rankValue + 1, message && message.label);
  }
  return null;
};

MetricValueLabel.propTypes = {
  metric: PropTypes.object.isRequired
};

export default withIntl(MetricValueLabel);
