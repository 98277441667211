import React from 'react';
import { Link } from 'react-router';
import join from 'lodash/join';
import map from 'lodash/map';
import filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, YAxis, ReferenceLine } from 'recharts';
import color from 'color';

import FlatButton from 'material-ui/FlatButton';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

import Card from '../../../mui/Card';
import CardHeader from '../../../mui/CardHeader';
import Avatar from '../../../mui/Avatar';
import OldIconButton from '../../../mui/OldIconButton';
import CardActions from '../../../mui/CardActions';
import MetricIcon from '../../../icons/Metric';
import MenuItem from '../../../menu/MenuItem';
import CharacteristicsItem from '../../../applicant/CharacteristicsItem';
import SelectOverlay from '../../../select/SelectOverlay';
import MetricsBubbleChartPanel from '../../../analytics/bubbleChart/MetricsBubbleChartPanel';
import DualMetricsInterpretation from '../../../analytics/DualMetricsInterpretation';
import CustomBar from '../../../overviewChart/Bar';

import { formatDuration } from '../../../../utils/utils';
import { computeCrossComparisonFit, getNumericalMetrics, getNumericDualMetrics } from '../../../../utils/analytics';

class Section extends React.Component {
  state = {
    collapsed: false
  };

  handleCollapseToggle = () => this.setState({ collapsed: !this.state.collapsed });

  render() {
    const { title, children, hidden } = this.props;
    const { collapsed } = this.state;

    if (hidden) {
      return null;
    }
    return (
      <div>
        <div className="container-flex-row ai-center jc-space-between" onClick={this.handleCollapseToggle}>
          <div className="mui-padded inline-container">
            <MenuItem active={!collapsed} label={title} onClick={this.handleCollapseToggle} />
          </div>
          <div className="mui-padded">
            <OldIconButton onClick={this.handleCollapseToggle}>
              <i className="material-icons">{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</i>
            </OldIconButton>
          </div>
        </div>
        {!collapsed && children}
      </div>
    );
  }
}

const renderUserTooltip = ({ firstName, lastName, fill }, subtitle) => (
  <Card style={{ minWidth: 220 }}>
    <CardHeader
      image={<Avatar color={fill}>{getInitials(lastName, firstName)}</Avatar>}
      title={`${lastName} ${firstName}`}
      subtitle={subtitle}
    />
  </Card>
);

const renderScoreTooltip = ({ payload }) => {
  const data = payload[0] && payload[0].payload;
  if (data) {
    const { title, label } = data;
    return renderUserTooltip(data, `${title}: ${label}`);
  }
  return null;
};

const renderTimeTooltip = ({ payload }) => {
  const data = payload[0] && payload[0].payload;
  if (data) {
    const { title, titlePreparation, titleSimulation, preparation, simulation } = data;
    return renderUserTooltip(
      data,
      <div>
        <div>{`${titlePreparation}: ${formatDuration(preparation)}`}</div>
        <div>{`${titleSimulation}: ${formatDuration(simulation)}`}</div>
        <div>{`${title}: ${formatDuration(preparation + simulation)}`}</div>
      </div>
    );
  }
  return null;
};

const _mapAtMost = (values, max, reversed, mapper) => {
  const res = [];
  for (let i = 0; i < Math.min(max, values.length); i += 1) {
    const idx = reversed ? values.length - i - 1 : i;
    const val = values[idx];
    res.push(mapper(val, idx, values));
  }
  return res;
};

const renderCompetencyTooltip = ({ payload }) => {
  const data = payload[0] && payload[0].payload;
  if (data) {
    const { title, label } = data;
    return renderUserTooltip(data, `${title}: ${label}`);
  }
  return null;
};

const getInitials = (...values) => join(map(filter(values, v => !!v), v => v[0]), '');

const renderScoreReferenceLines = () => {
  const res = [];
  for (let i = 0; i < 10; i += 1) {
    res.push(<ReferenceLine key={`rl${i}`} y={(i + 1) / 10} stroke={'rgba(0,0,0,0.12)'} strokeDasharray="5 5" />);
  }
  return res;
};

const ScoreLabel = props => {
  const { x, y, width, fill, data, index } = props;
  const { label } = data[index] || {};
  return (
    <text x={x + width / 2} y={y} width={width} fill={fill} textAnchor="middle" dy={-4}>
      {label}
    </text>
  );
};

class SelectMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidUpdate() {
    if (this.props.selectDisabled && this.state.open) {
      this.handleClose();
    }
  }

  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  handleFilterParticipant = (p, search) => {
    const { participant: { firstName, lastName, email } } = p;
    const values = [firstName, lastName, email];
    for (let i = 0; i < values.length; i += 1) {
      const val = values[i];
      if (val && val.toLowerCase().indexOf(search) > -1) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { participants, selectDisabled, messages } = this.props;
    const { onSelect } = this.props;
    const { open } = this.state;
    return (
      <div>
        <FloatingActionButton disabled={participants.length === 0 || selectDisabled} onClick={this.handleOpen}>
          <ContentAdd />
        </FloatingActionButton>
        {open && (
          <SelectOverlay
            items={participants}
            filterFunction={this.handleFilterParticipant}
            itemRenderer={p => (
              <div>
                <div>
                  <span>
                    {p.participant.firstName} {p.participant.lastName} <small>({p.participant.email})</small>
                  </span>
                </div>
                {p.project && (
                  <div>
                    <small>{p.project.name}</small>
                  </div>
                )}
              </div>
            )}
            messages={{ searchPlaceholder: messages.searchPlaceholder }}
            onSelect={onSelect}
            onClose={this.handleClose}
          />
        )}
      </div>
    );
  }
}

const SCORE_DOMAIN = [0, 1];

const ApplicantCompare = props => {
  const { intl, comparison: { compared, comparable }, metricDefinitions, addDisabled } = props;
  const { handleRemoveFromSelection, handleAddToSelection } = props;
  const widthPercentage = 100 / compared.length;
  const maxWidth = compared.length * 350;
  const rowStyle = { maxWidth, minWidth: '100%' };
  const colStyle = { width: `${widthPercentage}%` };
  const renderParticipantCol = (participants, renderFn) => (
    <div className="applicant-compare-row">
      {map(participants, (p, idx) => (
        <div key={`${p.id}-${p.projectId}`} className="applicant-compare-col" style={colStyle}>
          {renderFn(p, idx)}
        </div>
      ))}
    </div>
  );

  const competencies = getNumericalMetrics(metricDefinitions);
  const competencyCharts = map(competencies, ({ key, label, shortLabel }) => {
    const data = map(compared, ({ participant: { firstName, lastName }, result: { metrics }, crossComparedColor }) => {
      const value = metrics[key];
      return {
        firstName,
        lastName,
        value: value ? value.value : 0,
        label: value ? value.formattedValue : 'N/A',
        fill: crossComparedColor,
        title: label
      };
    });
    return {
      competency: {
        key,
        label,
        shortLabel
      },
      data
    };
  });
  const dualMetrics = getNumericDualMetrics(metricDefinitions);
  //
  const dualMetricsData = computeCrossComparisonFit(dualMetrics, compared, compared);
  const messages = {
    ...intl.messages.components.pages.private.project.applicantDetail,
    ...intl.messages.components.pages.private.project.applicantCompare
  };
  const scoreChart = _reduce(
    compared,
    (res, { participant: { firstName, lastName }, result, crossComparedColor }) => {
      const score = result.metrics.scoreTotal;
      if (score) {
        res.present = true;
      }
      res.data.push({
        value: score ? score.value : 0,
        label: score ? score.formattedValue : 'N/A',
        firstName,
        lastName,
        title: messages.chart.score,
        fill: crossComparedColor
      });
      return res;
    },
    { data: [], present: false }
  );
  const timeChartData = map(
    compared,
    ({ participant: { firstName, lastName }, result: { metrics }, crossComparedColor }) => {
      const preparation = (metrics.durationPreparation && metrics.durationPreparation.value) || 0;
      const simulation = (metrics.durationTasks && metrics.durationTasks.value) || 0;
      return {
        firstName,
        lastName,
        preparation,
        simulation,
        titlePreparation: messages.durationStats.preparation,
        titleSimulation: messages.durationStats.simulation,
        title: messages.durationStats.total,
        fill: crossComparedColor,
        prepFill: color(crossComparedColor)
          .mix(color('white'), 0.5)
          .string()
      };
    }
  );

  return (
    <div className="applicant-compare">
      {compared.length > 0 && (
        <div className="applicant-compare-container" style={rowStyle}>
          <div className="applicant-compare-intro">
            {renderParticipantCol(compared, p => (
              <div>
                <Link to={{ pathname: p.url }}>
                  <CardHeader
                    image={
                      <Avatar color={p.crossComparedColor}>
                        {getInitials(p.participant.lastName, p.participant.firstName)}
                      </Avatar>
                    }
                    title={`${p.participant.lastName} ${p.participant.firstName}`}
                    subtitle={
                      <div>
                        <div>{p.participant.email}</div>
                        {p.project && (
                          <div className="small" style={{ whiteSpace: 'normal', fontWeight: 400 }}>
                            {p.project.name}
                          </div>
                        )}
                      </div>
                    }
                  />
                </Link>
                <CardActions>
                  <FlatButton
                    icon={<i className="material-icons">clear</i>}
                    label={messages.removeButton}
                    onClick={() => handleRemoveFromSelection(p)}
                  />
                </CardActions>
              </div>
            ))}
          </div>
          <div className="applicant-compare-cards">
            <Section title={messages.chart.score} hidden={!scoreChart.present}>
              <div className="applicant-compare-score">
                <ResponsiveContainer height={300}>
                  <BarChart
                    data={scoreChart.data}
                    barGap={1}
                    barCategoryGap={8}
                    margin={{ top: 24, left: 8, bottom: 0, right: 8 }}
                  >
                    {renderScoreReferenceLines()}
                    <Tooltip content={renderScoreTooltip} />
                    <Bar
                      name="label"
                      label={<ScoreLabel data={scoreChart.data} />}
                      shape={<CustomBar gradient />}
                      isAnimationActive={false}
                      dataKey="value"
                    />
                    <YAxis domain={SCORE_DOMAIN} hide />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Section>
            <Section title={messages.durationStats.total}>
              <div className="applicant-compare-time">
                <div className="text-muted mui-padded-horizontal-x2 mui-padded-vertical" style={{ fontSize: '14px' }}>
                  {messages.durationStats.description}
                </div>
                <ResponsiveContainer height={200}>
                  <BarChart
                    data={timeChartData}
                    barGap={1}
                    barCategoryGap={8}
                    margin={{ top: 24, left: 8, bottom: 8, right: 8 }}
                  >
                    <Tooltip content={renderTimeTooltip} />
                    <Bar
                      isAnimationActive={false}
                      dataKey="simulation"
                      stackId="a"
                      shape={<CustomBar gradient radius={0} />}
                    />
                    <Bar isAnimationActive={false} dataKey="preparation" stackId="a">
                      {map(timeChartData, ({ prepFill }, idx) => <Cell key={`cell-${idx}`} fill={prepFill} />)}
                    </Bar>
                    <YAxis hide domain={[0, 'dataMax']} />
                  </BarChart>
                </ResponsiveContainer>
                <div>
                  {map(timeChartData, ({ preparation, simulation }, idx) => (
                    <div
                      key={`time-chart-${idx}`}
                      className="applicant-compare-chart-label-col"
                      style={{ width: `${100 / timeChartData.length}%` }}
                    >
                      <span>{formatDuration(preparation + simulation)}</span>
                    </div>
                  ))}
                </div>
              </div>
            </Section>
            <Section title={messages.strengths} hidden={competencies.length === 0}>
              <div className="applicant-compare-strengths">
                {renderParticipantCol(compared, ({ result: { strengths } }) => (
                  <div>
                    {_mapAtMost(strengths, 3, false, data => (
                      <CharacteristicsItem key={data.key} data={data} showInterpretation />
                    ))}
                  </div>
                ))}
              </div>
            </Section>
            <Section title={messages.weakness} hidden={competencies.length === 0}>
              <div className="applicant-compare-weakness">
                {renderParticipantCol(compared, ({ result: { weaknesses } }) => (
                  <div>
                    {weaknesses.length > 0 ? (
                      _mapAtMost(weaknesses, 1, true, data => (
                        <CharacteristicsItem key={data.key} data={data} showInterpretation />
                      ))
                    ) : (
                      <div className="mui-padded-x2">
                        <div
                          className="mui-padded-x2 text-center bg-primary-light text-muted small"
                          style={{ borderRadius: '2px' }}
                        >
                          N/A
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Section>
            <Section title={messages.competencies} hidden={competencies.length === 0}>
              <div className="applicant-compare-chart">
                <div className="mui-padded-x2">
                  <MetricsBubbleChartPanel participants={compared} metrics={competencies} />
                </div>
                <div style={{ overflowX: 'auto' }}>
                  <div className="applicant-compare-chart-well container-flex-row">
                    {map(competencyCharts, ({ competency, data }, idx) => (
                      <div
                        key={`skill-chart-${idx}`}
                        style={{
                          width: `${100 / competencyCharts.length}%`,
                          minWidth: `${compared.length * 24 + 24}px`,
                          display: 'inline-block',
                          position: 'relative',
                          padding: '0 12px'
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '42px',
                            left: 0,
                            transform: 'rotate(-90deg)',
                            transformOrigin: 'top left'
                          }}
                        >
                          <span className="mui-label" style={{ margin: 0, whiteSpace: 'nowrap' }}>
                            {competency.label}
                          </span>
                        </div>
                        <ResponsiveContainer height={360}>
                          <BarChart
                            data={data}
                            barCategoryGap={0.5}
                            barGap={0}
                            margin={{ top: 24, left: 0, bottom: 0, right: 0 }}
                          >
                            {renderScoreReferenceLines()}
                            <Tooltip content={renderCompetencyTooltip} />
                            <Bar
                              dataKey="value"
                              name="label"
                              label={<ScoreLabel data={data} />}
                              isAnimationActive={false}
                              shape={<CustomBar gradient />}
                            />
                            <YAxis domain={SCORE_DOMAIN} hide />
                          </BarChart>
                        </ResponsiveContainer>
                        <div className="applicant-compare-chart-label-col">
                          <MetricIcon metric={competency} size={24} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Section>
            <Section title={messages.dimensions} hidden={dualMetrics.length === 0}>
              <div className="applicant-compare-chart">
                <div className="mui-padded-vertical">
                  <DualMetricsInterpretation metrics={dualMetricsData.metrics} />
                </div>
              </div>
            </Section>
          </div>
        </div>
      )}
      <div
        className={`applicant-compare-button-add${compared.length === 0 ? ' applicant-compare-button-add-first' : ''}`}
      >
        <SelectMenu
          participants={comparable}
          selectDisabled={addDisabled}
          onSelect={handleAddToSelection}
          messages={messages}
        />
        <div className="applicant-compare-button-add-desc">
          <span>{messages.addButton}</span>
        </div>
      </div>
    </div>
  );
};

export default ApplicantCompare;
