import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconButton from 'material-ui/IconButton';
import Spinner from '../spinner/Spinner';

class SearchField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.focus && this.props.focus && this.input) {
      this.input.focus();
    }
  }

  handleKeyDown = e => {
    const { onKeyDown } = this.props;
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  handleFocus = e => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(e);
    }
    this.setState({ focused: true });
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }
    this.setState({ focused: false });
  };

  setInput = el => (this.input = el);

  render() {
    const { value, placeholder, onValueChange, compact, loading } = this.props;
    return (
      <div
        className={cn({ 'mui-search-field': true, 'mui-search-field-focused': this.state.focused, compact })}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        <input
          ref={this.setInput}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={e => onValueChange && onValueChange(e.target.value)}
          onKeyDown={this.handleKeyDown}
          autoFocus
        />
        {loading ? (
          <div style={{ position: 'absolute', top: 0, right: 0, padding: '12px' }}>
            <Spinner show relative size={24} stroke={3} />
          </div>
        ) : (
          <IconButton
            className={`mui-icon-button${value ? '' : ' mui-disappear'}`}
            style={{ position: 'absolute', top: 0, right: 0 }}
            iconStyle={{ color: 'rgba(33,33,33,0.54)' }}
            onClick={() => onValueChange && onValueChange('')}
          >
            <i className="material-icons">clear</i>
          </IconButton>
        )}
        {!loading && (
          <IconButton
            className={`mui-icon-button${value ? ' mui-disappear' : ''}`}
            style={{ position: 'absolute', top: 0, right: 0 }}
            iconStyle={{ color: 'rgba(33,33,33,0.54)' }}
          >
            <i className="material-icons">search</i>
          </IconButton>
        )}
      </div>
    );
  }
}

SearchField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func.isRequired
};

export default SearchField;
