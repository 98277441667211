import React from 'react';
import { Link } from 'react-router';

import FinishedCellContent from '../applicant/cells/FinishedCellContent';

import withIntl from '../withIntl';

const TeamMemberRowActivityTooltipContent = props => {
  const {
    readOnly,
    activity: { finishedAt, startedAt, createdAt, project },
    intl: { messages, locale }
  } = props;
  return (
    <div className="text-normal text-sz-reg-sm mui-padded">
      <div className="mui-label">
        {finishedAt ? messages.finishedAtLabel : startedAt ? messages.startedAtLabel : messages.createdAtLabel}
      </div>
      <FinishedCellContent finishedTimestamp={finishedAt || startedAt || createdAt} locale={locale} />
      <div className="mui-label mui-margin-top-x2">{messages.projectLabel}</div>
      {readOnly ? (
        <span>{project.name}</span>
      ) : (
        <Link to={`/projects/overview/${project.company.slug}/${project.slug}`}>
          <span>{project.name}</span>
        </Link>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    finishedAtLabel: 'Dokončeno',
    startedAtLabel: 'Započato',
    createdAtLabel: 'Vytvořeno',
    projectLabel: 'Assessmentový projekt'
  },
  sk: {
    finishedAtLabel: 'Dokončené',
    startedAtLabel: 'Započaté',
    createdAtLabel: 'Vytvorené',
    projectLabel: 'Assessmentový projekt'
  },
  en: {
    finishedAtLabel: 'Finished at',
    startedAtLabel: 'Started at',
    createdAtLabel: 'Created at',
    projectLabel: 'Assessment project'
  }
};

export default withIntl(TeamMemberRowActivityTooltipContent, intlMessages);
