import _map from 'lodash/map';
import _reduce from 'lodash/reduce';

import { EBool, EMediaType } from '../../../constants/enum';
import { valuesToValueHolders, cultureFromValues } from '../../forms/FormUtils';

const createEducation = values => {
  const res = {};
  res.level = values.minimumEducation
    ? { range: { lower: { value: values.minimumEducation } }, eliminating: !!values.minimumEducationRequired }
    : null;
  const fields = valuesToValueHolders(values.educationFields);
  res.fields =
    fields && fields.length ? { list: { items: fields, eliminating: !!values.educationFieldsRequired } } : null;
  res.currentlyStudying =
    values.suitableForStudents != null ? { bool: { value: values.suitableForStudents ? 'NONE' : EBool.NO.key } } : null;
  return res;
};

const createWorkExperience = values => {
  const res = {};
  res.years = values.minimumWorkExperienceYears
    ? {
        range: { lower: { value: values.minimumWorkExperienceYears } },
        eliminating: !!values.minimumWorkExperienceYearsRequired
      }
    : null;
  const roles = valuesToValueHolders(values.workExperienceRoles);
  res.role =
    roles && roles.length ? { list: { items: roles, eliminating: !!values.workExperienceRolesRequired } } : null;
  return res;
};

const createSkills = values => {
  const res = {};
  const languages = values.languages;
  res.languages =
    languages && languages.length
      ? {
          list: {
            items: _map(languages, ({ value, minimumLevel, eliminating }) => ({
              value,
              range: { lower: { value: minimumLevel, eliminating } }
            }))
          }
        }
      : null;
  const other = valuesToValueHolders(values.otherSkills);
  res.other = other && other.length ? { list: { items: other } } : null;
  return res;
};

const createSalary = values => {
  const min = values.salaryMin;
  const max = values.salaryMax;
  const cur = values.salaryCurrency;
  if (cur && (min != null || max != null)) {
    return {
      min,
      max,
      currency: cur
    };
  }
  return null;
};

const valuesToMatchList = values => {
  const items = valuesToValueHolders(values);
  if (items) {
    return { items };
  }
  return null;
};

const createMatchConfig = (matchConfig, values) => {
  const mc = matchConfig || {};
  const res = { ...mc };
  res.education = { ...mc.education, ...createEducation(values) };
  res.workExperience = { ...mc.workExperience, ...createWorkExperience(values) };
  res.skills = { ...mc.skills, ...createSkills(values) };
  res.types = valuesToMatchList(values.opportunityTypes);
  res.industries = valuesToMatchList(values.industries);
  res.locations = valuesToMatchList(values.locations);
  res.salary = createSalary(values);
  res.benefits = valuesToMatchList(values.benefits);
  res.personalDevelopmentAreas = valuesToMatchList(values.personalDevelopmentAreas);
  res.roles = valuesToMatchList(values.roles);
  res.specializations = valuesToMatchList(values.specializations);
  const newCulture = cultureFromValues(values);
  res.culture = newCulture ? { ...mc.culture, ...newCulture } : null;
  return res;
};

export const valuesToOpportunityPost = (post, values) => {
  const oldPost = post || {};
  const oldOpp = oldPost.opportunity || {};
  const newPost = {
    ...oldPost,
    opportunity: null
  };
  const newOpp = {
    ...oldOpp,
    matchConfig: { ...oldOpp.matchConfig }
  };
  const lang = values.language && values.language.toUpperCase();
  const validTo = values.validIndefinitely ? null : values.validTo ? new Date(values.validTo) : null;
  newPost.title = values.title;
  newPost.language = lang || 'CS';
  newPost.tags = values.tags || [];
  newPost.description = values.description;
  newPost.displayedTo = validTo;
  if (values.publishedAt) {
    newPost.publishedAt = values.publishedAt;
  }
  newPost.pools = _map(values.poolIds, pId => ({ pool: { id: pId } }));
  newOpp.language = lang || 'CS';
  if (!newOpp.tags) {
    newOpp.tags = [];
  }
  newOpp.contentUrl = values.contentUrl;
  newOpp.contentType = values.contentUrl ? EMediaType.WEBSITE.key : null;
  newOpp.type = values.type;
  newOpp.validTo = validTo;
  if (values.companyId) {
    newOpp.company = { id: values.companyId };
    newOpp.recipientIds = values.recipientIds;
  } else {
    newOpp.company = null;
    newOpp.recipientIds = [];
  }
  newOpp.matchConfig = createMatchConfig(oldOpp.matchConfig, values);
  newPost.opportunity = newOpp;
  return newPost;
};

export const getProgress = predicates => {
  const total = predicates.length;
  const filled = _reduce(predicates, (res, predicate) => res + (predicate() ? 1 : 0), 0);
  return {
    total,
    filled,
    completed: total <= filled
  };
};

export const combineProgress = (p1, p2) => {
  if (!p1) {
    return p2;
  }
  if (!p2) {
    return p1;
  }
  return {
    total: (p1.total || 0) + (p2.total || 0),
    filled: (p1.filled || 0) + (p2.filled || 0),
    completed: p1.completed !== false && p2.completed !== false
  };
};
