import { authRequest } from '../Request.js';
import { API_URL } from '../../../src/config';

//TODO some caching might be good
export function getPreferencesDataSources(lang) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/data_sources/preferences`)
      .query({ lang: lang.toUpperCase() })
      .end((err, res) => (err ? reject(err.response.body) : resolve(res.body)));
  });
}

export function getAllDataSources(lang) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/data_sources`)
      .query({ lang: lang.toUpperCase() })
      .end((err, res) => (err ? reject(err.response.body) : resolve(res.body)));
  });
}
