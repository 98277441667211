import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';

import FontIcon from 'material-ui/FontIcon';
import FlatButton from 'material-ui/FlatButton';

import ErrorPage from '../../components/error/ErrorPage';
import { formatMessage } from '../../utils/utils';
import { BRAND } from '../../constants/constants';

class NotFound extends Component {
  onHomeClicked = () => {
    const { router } = this.props;
    router.push('/projects');
  };

  render() {
    const { intl } = this.props;
    const messages = intl.messages.containers.common.notFound;
    const supportEmail = BRAND.email('support');

    return (
      <ErrorPage
        message={
          <span>
            {formatMessage(messages.message, [<Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>])}
          </span>
        }
        statusCode="404"
        icon="sentiment_dissatisfied"
        action={
          <FlatButton
            label={messages.button}
            onClick={this.onHomeClicked}
            primary
            icon={<FontIcon className="material-icons">home</FontIcon>}
          />
        }
      />
    );
  }
}

NotFound.propTypes = {
  router: PropTypes.object,
  intl: PropTypes.object
};

const mapStateToProps = ({ intl }) => ({ intl });

export default connect(mapStateToProps)(withRouter(NotFound));
