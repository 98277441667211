import React from 'react';

const FilterBadge = props => {
  const { count, all } = props;
  const countDefined = count != undefined && count !== all;
  return (
    <small
      className="basic-badge"
      style={{ marginLeft: '0.4em', color: 'inherit', opacity: 0.87 }}
    >
      {countDefined && <span>{count}</span>}
      {countDefined && <span style={{ opacity: countDefined ? 0.6 : 1 }}>/</span>}
      <span style={{ opacity: countDefined ? 0.6 : 1 }}>{all}</span>
    </small>
  );
};

export default FilterBadge;
