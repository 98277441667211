import React from 'react';

import withIntl from '../withIntl';

const ParticipantName = props => {
  const { firstName, lastName, email, anonymous, variant, intl: { messages } } = props;
  if (anonymous) {
    return <em>{messages.anonymous}</em>;
  }
  const mini = variant === 'mini';
  const medium = variant === 'medium';
  if (firstName && lastName) {
    if (mini || !email) {
      return (
        <span>
          {lastName} {firstName}
        </span>
      );
    }
    if (medium) {
      return (
        <div>
          <div>{lastName} {firstName}</div>
          <small>{email}</small>
        </div>
      );
    }
    return (
      <span>
        {lastName} {firstName} <small>({email})</small>
      </span>
    );
  }
  return email ? <span>{email}</span> : <em>N/A</em>;
};

const intlMessages = {
  cs: { anonymous: 'Anonymní' },
  sk: { anonymous: 'Anonymný' },
  en: { anonymous: 'Anonymous' }
};

export default withIntl(ParticipantName, intlMessages);
