export const KC = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  COMMA: 188
};

export const determineKeyCode = event => {
  const kc = event.which || event.keyCode;
  const input = event.target;

  if (kc && kc !== 229) {
    return kc;
  }
  const ss = input.selectionStart - 1;
  const ssv = ss || 0;
  if (!input.value) {
    return kc;
  }
  const char = input.value.substr(ssv, 1);
  return char.charCodeAt(0);
};
