import React from 'react';

import FlatButton from 'material-ui/FlatButton';

import RaisedButton from './RaisedButton';
import Spinner from '../spinner/Spinner';

const Button = props => {
  const { loading, raised, mini, labelStyle, style, ...rest } = props;
  if (loading) {
    rest.label = <Spinner local show size={mini ? 20 : 24} stroke={3} />;
    rest.icon = null;
    rest.disabled = true;
  }
  if (mini) {
    rest.style = { lineHeight: '32px', height: '32px', ...style };
    rest.labelStyle = { fontSize: '12px', ...labelStyle };
    rest.iconStyle = { fontSize: '20px' };
  } else {
    rest.style = style;
    rest.labelStyle = labelStyle;
  }
  if (raised) {
    return <RaisedButton {...rest} />;
  }
  return <FlatButton {...rest} />;
};

export default Button;
