import React from 'react';
import { ReactReduxContext, Provider } from 'react-redux';
import cn from 'classnames';

import MP from 'material-ui/Popover';

import { BRAND } from '../../constants/constants';

const Popover = props => {
  const { className, children, style, ...rest } = props;
  const popoverClassName = cn({
    [`dialog-container brand-${BRAND.key.toLowerCase()}`]: true,
    [className]: className
  });
  return (
    <ReactReduxContext.Consumer>
      {ctx => (
        <MP
          className={popoverClassName}
          style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 4px 4px 24px', ...style }}
          {...rest}
        >
          <Provider store={ctx.store}>{children}</Provider>
        </MP>
      )}
    </ReactReduxContext.Consumer>
  );
};

export default Popover;
