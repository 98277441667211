import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';

import ParticipantsTagsAssignmentOverlay from '../applicant/ParticipantsTagsAssignmentOverlay';

import { getGroupUserCompanyTags } from '../../actions/entities/tagActions';
import * as Api from '../../api/entities/tagApi';

const getParticipant = m => m;

class GroupMembersTagsAssignmentOverlay extends React.Component {
  handleConfirm = (tags, members) => {
    const { group, getGroupUserCompanyTags, onConfirm } = this.props;
    if (!group) {
      return;
    }
    const data = { tagIds: _map(tags, t => t.id), userIds: _map(members, m => m.id) };
    Api.assignUserCompanyTags(group?.company?.id, data).then(() => {
      getGroupUserCompanyTags(group).then(onConfirm);
    });
  };
  handleTagSaved = () => {
    const { group, getGroupUserCompanyTags } = this.props;
    getGroupUserCompanyTags(group);
  };

  render() {
    const { group, members, onClose } = this.props;
    return (
      <ParticipantsTagsAssignmentOverlay
        companyId={group?.company?.id}
        participants={members}
        getParticipant={getParticipant}
        onConfirm={this.handleConfirm}
        onClose={onClose}
        onTagSaved={this.handleTagSaved}
      />
    );
  }
}

export default connect(null, { getGroupUserCompanyTags })(GroupMembersTagsAssignmentOverlay);
