import React from 'react';
import cn from 'classnames';

import Chip from '../mui/Chip';

const MatchBadge = props => {
  const { children, matches, eliminating, mini } = props;
  const className = cn({ 'match-badge': true, matches, eliminating });
  const matchProps = {};
  if (matches) {
    matchProps.icon = 'check';
  } else if (eliminating) {
    matchProps.icon = 'close';
  }
  return (
    <div className={className}>
      <Chip variant={mini ? 'mini' : 'compact'}>
        <div style={{ verticalAlign: 'top', display: 'inline-block' }}>{children}</div>
        {matchProps.icon && (
          <i
            className="material-icons"
            style={{ verticalAlign: 'top', marginLeft: '4px', fontSize: '16px', lineHeight: mini ? '20px' : '24px' }}
          >
            {matchProps.icon}
          </i>
        )}
      </Chip>
    </div>
  );
};

export default MatchBadge;
