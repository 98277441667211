import React from 'react';

import Avatar from '../../../components/mui/Avatar';
import MuiButton from '../../../components/mui/Button';
import IconButton from '../../../components/mui/IconButton';
import CompanyProfileDisabledMessage from '../../../components/company/profile/CompanyProfileDisabledMessage';
import OpportunityDisabledMessage from '../../../components/opportunity/OpportunityDisabledMessage';

import { isCompanyProfilePublishDisabled, isMaxConcurrentOpportunitiesReached } from '../../../utils/talentMarketPlan';

const STATUS = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  DONE: 'DONE',
  LIMITED: 'LIMITED'
};

const StepContainer = props => {
  const { step, title, message, description, actions, status, active } = props;
  const limited = status === STATUS.LIMITED;
  const done = status === STATUS.DONE;
  const pending = status === STATUS.PENDING;
  const avatar = (
    <Avatar
      size={24}
      className={
        done
          ? 'bg-success text-success'
          : pending
          ? 'bg-warning text-warning'
          : limited
          ? 'bg-muted text-muted'
          : active
          ? 'bg-secondary text-white'
          : ''
      }
    >
      {done || pending || limited ? (
        <i style={{ fontSize: '18px' }} className="material-icons">
          {done ? 'check' : pending ? 'access_time' : 'clear'}
        </i>
      ) : (
        step
      )}
    </Avatar>
  );
  return (
    <div
      style={{ marginTop: '8px', borderRadius: '2px' }}
      className={`bg-${done ? 'success' : pending ? 'warning' : limited ? 'muted' : 'primary-light'}`}
    >
      <div style={{ padding: '8px' }}>
        <div style={{ padding: '8px 8px 0', margin: 0 }} className="mui-label">
          {title}
        </div>
        <div className="container-flex-row fw-yes ai-center">
          <div className="container-flex-row fw-no ai-center">
            <div style={{ padding: '8px' }}>{avatar}</div>
            <div style={{ padding: '8px' }}>
              <div>{message}</div>
              {description && <div className="small">{description}</div>}
            </div>
          </div>
          {actions && (
            <div className="flex1" style={{ padding: '8px', textAlign: 'right' }}>
              {actions}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Button = props => {
  const { active, label, icon, disabled, onClick } = props;
  return (
    <MuiButton
      raised={active}
      style={{ verticalAlign: 'middle' }}
      label={label}
      icon={icon}
      secondary={active}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

const PreviewButton = props => {
  const { url, ...rest } = props;
  const btnProps = { ...rest };
  if (url) {
    btnProps.containerElement = <a href={url} target="_blank" rel="noopener noreferrer" />;
  }
  return (
    <IconButton style={{ padding: 6, width: 36, height: 36, verticalAlign: 'middle' }} {...btnProps}>
      <i className="material-icons">visibility</i>
    </IconButton>
  );
};

const determineProfileStatus = (profile, plan) => {
  const limited = isCompanyProfilePublishDisabled(plan);
  if (limited) {
    return STATUS.LIMITED;
  }
  const { updatedAt, draft, publishedAt } = profile || {};
  if (updatedAt || draft) {
    if (publishedAt) {
      return STATUS.DONE;
    }
    return STATUS.PENDING;
  }
  return STATUS.NONE;
};

const determineOpportunityStatus = (publishedOpportunityCount, opportunityCount, plan) => {
  if (isMaxConcurrentOpportunitiesReached(plan)) {
    return STATUS.LIMITED;
  }
  if (publishedOpportunityCount > 0) {
    return STATUS.DONE;
  }
  if (opportunityCount > 0) {
    return STATUS.PENDING;
  }
  return STATUS.NONE;
};

class SourcingProfileBuilder extends React.Component {
  handleProfileActionClick = () => {
    this.props.onProfileSet();
  };
  handleOpportunityActionClick = oppStatus => {
    if (oppStatus === STATUS.PENDING) {
      this.props.onOpportunityPublish();
    } else {
      this.props.onOpportunityAdd();
    }
  };

  render() {
    const { intl, opportunityCount, publishedOpportunityCount, companyProfile, talentMarketPlan } = this.props;
    const messages = intl.messages.components.pages.private.sourcingProfileBuilder;
    const profileStatus = determineProfileStatus(companyProfile, talentMarketPlan);
    const oppStatus = determineOpportunityStatus(publishedOpportunityCount, opportunityCount, talentMarketPlan);
    const profileActions = [];
    if (profileStatus === STATUS.DONE) {
      profileActions.push(
        <PreviewButton key="preview" url={companyProfile.previewUrl} tooltip={messages.profile.previewButton} />
      );
    }
    const profileStatusMessages = messages.profile[profileStatus] || {};
    const oppStatusMessages = messages.opportunities[oppStatus] || {};
    if (profileStatus !== STATUS.LIMITED) {
      profileActions.push(
        <Button
          key="action"
          active={oppStatus !== STATUS.NONE && profileStatus === STATUS.NONE}
          disabled={profileStatus === STATUS.LIMITED}
          label={profileStatusMessages.button}
          onClick={() => this.handleProfileActionClick(profileStatus)}
        />
      );
    }
    return (
      <div style={{ margin: '32px 0 64px', animation: 'vb-appear 0.4s ease-in-out' }}>
        <div className="mui-card">
          <div
            className="bg-highlight"
            style={{
              position: 'absolute',
              top: '-30px',
              left: '16px',
              borderRadius: '50%',
              width: '60px',
              height: '60px',
              padding: '6px',
              color: 'white'
            }}
          >
            <span className="mdi mdi-rocket" style={{ fontSize: '48px', lineHeight: '48px' }} />
          </div>
          <div style={{ padding: '32px 16px 16px' }}>
            <h4>{messages.title}</h4>
            <p>{messages.message}</p>
            <div>
              <StepContainer
                step="1"
                active={oppStatus === STATUS.NONE}
                status={oppStatus}
                title={messages.opportunities.title}
                message={oppStatus === STATUS.LIMITED ? <OpportunityDisabledMessage /> : oppStatusMessages.message}
                description={oppStatusMessages.description}
                actions={
                  oppStatus !== STATUS.LIMITED && (
                    <Button
                      active={oppStatus === STATUS.NONE}
                      label={oppStatusMessages.button}
                      secondary
                      disabled={oppStatus === STATUS.LIMITED}
                      onClick={() => this.handleOpportunityActionClick(oppStatus)}
                    />
                  )
                }
              />
              <StepContainer
                step="2"
                active={oppStatus !== STATUS.NONE && profileStatus === STATUS.NONE}
                status={profileStatus}
                title={messages.profile.title}
                message={
                  profileStatus === STATUS.LIMITED ? <CompanyProfileDisabledMessage /> : profileStatusMessages.message
                }
                description={profileStatusMessages.description}
                actions={profileActions}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SourcingProfileBuilder;
