import { handleActions } from 'redux-actions';
import map from 'lodash/map';

import * as c from '../../constants/constants';

export const defaultState = [];

export default handleActions(
  {
    [c.PROJECTS_REFRESH]: () => defaultState,
    [c.PROJECTS_GET]: (state, action) => [...state, ...action.payload],
    [c.PROJECTS_GET_ALL]: (state, { payload }) => payload,
    // update when applicants are loaded
    [c.RESULTS_GET.SUCCESS]: (state, { payload: { project } }) =>
      project ? map(state, p => (p.id === project.id ? project : p)) : state
  },
  defaultState
);
