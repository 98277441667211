import React from 'react';
import PropTypes from 'prop-types';

import withIntl from '../withIntl';

const FormatNumber = props => {
  const {
    value,
    intl: { locale },
    fractionDigits,
    minimumFractionDigits,
    maximumFractionDigits
  } = props;
  const options = {
    minimumFractionDigits: minimumFractionDigits != null ? minimumFractionDigits : fractionDigits,
    maximumFractionDigits: maximumFractionDigits != null ? maximumFractionDigits : fractionDigits
  };
  const numberFormatter = new Intl.NumberFormat(locale, options).format;
  return <span>{numberFormatter(value)}</span>;
};

FormatNumber.propTypes = {
  value: PropTypes.number.isRequired,
  fractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number
};

export default withIntl(FormatNumber);
