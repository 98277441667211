import { handleActions } from 'redux-actions';
import { INSIGHTS_GET, INSIGHTS_UNSET } from '../../constants/constants';

const defaultState = {
  items: [],
  pagination: {},
  loading: false
};

export default handleActions(
  {
    [INSIGHTS_GET.PENDING]: state => ({ ...state, loading: true }),
    [INSIGHTS_GET.ERROR]: state => ({ ...state, loading: false }),
    [INSIGHTS_GET.SUCCESS]: (state, { payload }) => ({
      ...state,
      items: payload.items,
      pagination: payload.page,
      loading: false
    }),
    [INSIGHTS_UNSET]: () => defaultState
  },
  defaultState
);
