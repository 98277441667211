import { createAction } from 'redux-actions';
import * as c from '../../constants/constants';

export const setActiveChart = createAction(c.CHART_SET_ACTIVE);
export const setToggleExpanded = createAction(c.CHART_TOGGLE_EXPAND);
export const setChartMode = createAction(c.CHART_SET_MODE);
export const setChartProperty = (property, value) => {
  return {
    type: c.CHART_SET_PROPERTY,
    payload: { property, value }
  };
};
