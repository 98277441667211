import { mirrorReversed} from '../utils/utils';

export default {
  common: {
    labels: {
      upgrade: 'Premium'
    }
  },
  components: {
    ieUnsupported: {
      title: 'Bohužel již nepodporujeme prohlížeč Internet Explorer',
      subtitle: 'Místo toho použijte Google Chrome, Firefox, Safari nebo MS Edge'
    },
    navigation: {
      sourcing: {
        label: 'Talent Market',
        opportunitiesLabel: 'Příležitosti',
        companyProfileLabel: 'Profil společnosti'
      },
      home: {
        label: 'Domů'
      },
      assessments: {
        label: 'Assessmenty'
      },
      designer: {
        label: 'HR-Boti'
      },
      groups: {
        label: 'Týmy a skupiny'
      },
      lockedModeMessages: {
        SOURCING: {
          message:
            'Přilákejte potenciální kandidáty inzerováním pracovních pozic, kariérních akcí nebo oblíbených knih o osobním rozvoji, videí atd. Vyzkoušejte {0[Premium účet]} a začněte dodávat svůj obsah talentům.'
        },
        RECRUITMENT: {
          title: 'Potřebujete ověřit dovednosti vašich lidí?',
          message:
            'Vyzkoušejte {0[Premium účet]} a získejte přístup k simulačním hrám a HR-botům',
          button: 'Vyzkoušet premium'
        },
        DEVELOPMENT: {
          title: 'Chcete strategicky řídit vzdělávání a rozvoj vašich lidí?',
          message:
            'Vyzkoušejte {0[Premium účet]} a využijte sílu behaviorálních dat pro váš společný růst.',
          button: 'Vyzkoušet premium'
        }
      }
    },
    modals: {
      emailSent: {
        text: 'Na váš e-mail byl odeslán odkaz, pomocí kterého si nastavíte nové heslo',
        redirect: 'PŘIHLÁSIT SE'
      },
      invitation: {
        invite: 'Pozvat účastníky do projektu',
        sendLink: 'Odkaz níže odešlete e-mailem nebo to {0[nechte na nás]}',
        sendLinkManual: 'Odkaz níže odešlete e-mailem',
        anonymous: 'Anonymní',
        anonymousHelper: 'Anonymní odkaz funguje bez nutnosti registrace. Nebudete však schopni určit, kdo odkaz použil.',
        copyToClipboard: 'Zkopírovat odkaz do schránky',
        codeDescription: 'Nebo, jestli chcete dialóg používat přímo na vaší stránce, zkopírujte následující kód a vložte ho mezi tagy <head> a </head>:',
        done: 'Hotovo',
        cancel: 'Zrušit',
        send: 'Odeslat',
        inviteButton: 'Pozvat',
        sendFromSystem: {
          enterEmailsText: 'Zadejte e-maily účastníků nebo jim {0[odkaz odešlete osobně]}',
          emailsLabel: 'E-maily',
          emailsHintText: 'Oddělte adresy čárkou nebo mezerou',
          emailsInvalidText: 'Neplatný e-mail'
        },
        invitationSent: {
          title: 'Pozvánky odeslány',
          invitedParticipantsLabel: 'Pozvaní účastníci',
          alreadyExistingParticipantsLabel: 'Již jsou součástí projektu',
          noParticipantsInvitedText: 'Žádní další účastníci nebyli pozváni. Všechny zadané e-mailové adresy jsou již součástí projektu.',
          inviteMoreText: 'Chcete {0[pozvat další]}?'
        },
        invitationError: {
          message: 'Nepodařilo se nám odeslat pozvánky. Chcete to {0[zkusit znovu]}?'
        }
      },
      changeFormLeave: {
        message: 'Máte neuložené změny. Chcete je zahodit?',
        confirm: 'Zahodit',
        cancel: 'Zpět'
      },
      confirmActivate: {
        msg1: 'Aktivovat projekt',
        msg2: 'Po aktivaci již nebude možné měnit název a související texty.',
        snackbar1: 'Projekt',
        snackbar2: 'byl aktivován',
        yes: 'Aktivovat',
        createYes: 'Vytvořit a aktivovat',
        no: 'Zpět'
      },
      confirmDelete: {
        msg1: 'Trvale odstranit projekt',
        msg2: 'Akce již nelze vrátit zpět.',
        snackbar1: 'Projekt',
        snackbar2: 'byl trvale smazán',
        yes: 'Odstranit',
        no: 'Zpět'
      },
      confirmFinish: {
        msg1: 'Ukončit projekt',
        msg2: 'Účastníci se do něj dále nebudou moci přihlásit.',
        snackbar1: 'Projekt',
        snackbar2: 'byl ukončen',
        yes: 'Ukončit',
        no: 'Zpět'
      },
      accountUpgrade: {
        title: 'Vyzkoušejte Premium na 14 dní zdarma',
        htmlMessage: '<p>' +
        'Odemkněte si všechny nástroje pro usnadnění náboru, výběru a rozvoje lidí:' +
        '<ul>' +
        '<li><b>simulační hry</b> pro testování dovedností</li>' +
        '<li><b>HR-boti</b> pro zvýšení konverze inzerátů a ověření culture-fitu</li>' +
        '<li>veškerá <b>analytická data</b> pro sledování výkonu a návratnosti</li>' +
        '</ul>' +
        'Stačí odeslat formulář. My se s vámi spojíme a vše vám nastavíme přesně na míru.' +
        '</p>',
        fields: {
          phone: {
            label: 'Vaše telefonní číslo',
            helperText: 'Nepovinné',
            invalid: 'Neplatné telefonní číslo'
          }
        },
        fieldRequired: 'Povinné pole',
        valueLengthError: 'Délka musí být mezi {0} a {1} znaky',
        confirmButton: 'Odeslat',
        cancelButton: 'Zrušit',
        confirmationMessage: {
          success: 'Děkujeme, přijali jsme váš požadavek. Budeme vás v nejbližší době kontaktovat.',
          error:
            'Nepodařilo se nám zpracovat váš požadavek. Pokud problémy přetrvávají, kontaktujte naší podporu na {0} anebo využijte chat v levém dolním rohu.',
          button: 'Rozumím'
        }
      },
      creditTopup: {
        title: 'Objevte s námi více kvalitních profilů',
        messages: {
          priceListIntro: 'Děkujeme, že používáte {0} Dashboard. Potřebujete navýšit počet dostupných kreditů? Vyzkoušejte jeden z následujících balíků:',
          customIntro: 'Nevybrali jste si? Nevadí, stačí odeslat formulář, my se s vámi spojíme a sestavíme pro vás nejlepší nabídku přesně podle vašich potřeb.',
          noPriceList: [
            'Děkujeme, že používáte {0} Dashboard. Potřebujete navýšit počet dostupných kreditů?',
            'Stačí odeslat formulář, my se s vámi spojíme a sestavíme pro vás nejlepší nabídku přesně podle vašich potřeb.'
          ]
        },
        confirmButton: 'Chci nabídku na míru'
      },
      demoPlay: {
        loading: 'Prosím čekejte...',
        cancelButton: 'Zrušit',
        playButton: 'Hrát DEMO',
        error: 'Nastala neočekávaná chyba. Zkuste akci opakovat později, případně nás kontaktujte.',
        message: 'Máte pár minut? Vyzkoušejte si krátké DEMO simulační hry.'
      },
      notifications: {
        unfinished: {
          confirmButton: 'Odeslat',
          cancelButton: 'Teď ne',
          text: 'Vybraným účastníkům, kteří zatím nedokončili, odešleme e-mailem přátelské připomenutí s odkazem, přes který mohou pohodlně navázat tam, kde skončili.',
          textQuestion: 'Chcete odeslat připomenutí vybraným účastníkům?',
          textSingle: 'Účastníkovi {0} odešleme e-mailem přátelské připomenutí s odkazem, přes který může pohodlně navázat tam, kde skončil. Chcete odeslat připomenutí?'
        }
      },
      projectCopy: {
        message: 'Vytvořte seskupení založené na projektu {0}',
        nameLabel: 'Název seskupení',
        groupingAddendum: 'Seskupení',
        copyAllParticipantsLabel: 'Kopírovat všechny účastníky s dokončenou aktivitou',
        groupingToggleLabel: 'Seskupení',
        groupingToggleHelperText: 'Obyčejně se doporučuje vytvořit seskupení (pohled). V některých situacích je ale možné vytvořit standardní projekt. Rozdíl je v interakci s účastníky - zatím co do standardního projektu ich můžete pozývat, seskupení je pro ně neviditelné.',
        confirmButton: 'Vytvořit',
        cancelButton: 'Zrušit'
      },
      participantBatchOperation: {
        COPY: {
          searchPlaceholder: 'Vyberte projekt, do kterého chcete účastníky kopírovat',
          thisProjectLabel: 'Současný projekt',
          message: 'Profily vybraných účastníků budou zkopírovány z projektu {0} do projektu {1}',
          changeProjectLabel: 'Změnit cílový projekt',
          participantListHelper: 'Můžete odznačit položky, které nechcete kopírovat',
          operationWarning: 'Pozor, chystáte se zkopírovat účastníka do jiného projektu, než do kterého byl primárně pozván. <b>Název tohoto projektu se zobrazí účastníkovi v portálu.</b> Pokud to nechcete, využijte možnost kopírovat do Seskupení.',
          snackbarMessage: {
            '1': '1 účastník zkopírován do projektu {1}',
            '2-4': '{0} účastníci zkopírováni do projektu {1}',
            '>4': '{0} účastníků zkopírovano do projektu {1}'
          },
          confirmButton: 'Kopírovat',
          cancelButton: 'Zrušit'
        },
        MOVE: {
          searchPlaceholder: 'Vyberte projekt, do kterého chcete účastníky přesunout',
          thisProjectLabel: 'Současný projekt',
          message: 'Profily vybraných účastníků budou přesunuty z projektu {0} do projektu {1}',
          changeProjectLabel: 'Změnit cílový projekt',
          participantListHelper: 'Můžete odznačit položky, které nechcete přesouvat',
          operationWarning: 'Pozor, chystáte se přesunout účastníka do jiného projektu, než do kterého byl primárně pozván. <b>Název tohoto projektu se zobrazí účastníkovi v portálu. Původní projekt již neuvidí.</b> Pokud to nechcete, využijte možnost kopírovat/přesunout do Seskupení.',
          snackbarMessage: {
            '1': '1 účastník přesunut do projektu {1}',
            '2-4': '{0} účastníci přesunuti do projektu {1}',
            '>4': '{0} účastníků přesunuto do projektu {1}'
          },
          confirmButton: 'Přesunout',
          cancelButton: 'Zrušit'
        },
        DELETE: {
          message: 'Profily vybraných účastníků budou odstraněny z projektu {0}',
          participantListHelper: 'Můžete odznačit položky, které nechcete odstranit',
          snackbarMessage: {
            '1': '1 účastník odstraněn',
            '2-4': '{0} účastníci odstraněni',
            '>4': '{0} účastníků odstraněno'
          },
          confirmButton: 'Odstranit',
          cancelButton: 'Zrušit'
        }
      },
      opportunityParticipantsSetResponse: {
        cancelLabel: 'Zrušit',
        confirmLabel: {
          ACCEPTED: 'Projevit zájem',
          ACCEPTED_INTERESTED: 'Potvrdit zájem',
          ACCEPTED_ACCEPTED: 'Potvrdit přihlášku',
          REJECTED: 'Odmítnout'
        },
        message: {
          ACCEPTED_ACCEPTED: {
            main: 'Chcete přijmout přihlášku uchazeče {0}?',
            description: 'Přijetím jeho přihlášky mu dávate vědět, že s ním počítáte ve výběrovém procesu. Také získáte jeho kontaktní údaje, takže s ním můžete komunikovat napřímo.'
          },
          ACCEPTED_INTERESTED: {
            main: 'Chcete potvrdit svůj zájem o uchazeče {0}?',
            description: 'Dáte mu tak najevo, že o něj stojíte a pokud se přihlásí, automaticky jeho přihlášku přijmete. Obdrží notifikaci s výzvou, aby se přihlásil. Pokud tak učiní, získáte ihned jeho kontaktní informace.'
          },
          ACCEPTED: {
            main: 'Chcete projevit svůj zájem o uchazeče {0}?',
            description: 'Dáte mu tak najevo, že o něj stojíte a pokud se přihlásí, automaticky jeho přihlášku přijmete. Obdrží notifikaci s výzvou, aby se přihlásil. Pokud tak učiní, získáte ihned jeho kontaktní informace.'
          },
          REJECTED: {
            main: 'Prosím, uveďte důvod pro odmítnutí uchazeče {0}'
          }
        },
        rejectReasonsLabel: 'Vyberte důvod',
        rejectReasonTextLabel: 'Napište vaše odůvodnení',
        rejectReasonTextHelperText: 'Tento text se zobrazí uchazeči'
      }
    },
    cards: {
      pagination: {
        pageInfoMessage: 'Jste na stránce {0}',
        goToStartLabel: 'Přejít na začátek'
      },
      project: {
        from: 'od',
        to: 'do',
        noRestriction: 'Bez časového omezení',
        latestProfileLabel: 'Nejnovější profil',
        applicantCount: 'Počet účastníků',
        ended: 'dokončilo',
        started: 'zahájilo',
        new: 'nezapočato',
        showApplicants: 'Detail',
        tags: {
          DEMO: 'Ukázka'
        }
      },
      projectAdd: {
        text: 'Pracujeme na tom, abyste si již brzy mohli sami vytvářet projekty',
        textDisabled:
          'Nový projekt budete moci vytvořit, jakmile autorizujeme váš účet. Je možné, že vás budeme za tímto účelem do 24 hodin kontaktovat. Děkujeme za váš zájem.'
      },
      demoPlay: {
        text: 'Zajímá vás, jak vypadá zážitek účastníka v simulaci?',
        button: 'Vyzkoušet DEMO',
        resolutionError: 'Rozlíšení okna vašeho prohlížeče není dostatečné pro plnohodnotný zážitek ze simulace. Je potřeba minimálně 1200x638 pixelů.'
      },
      guideShow: {
        text: 'Vraťte se do průvodce a připomeňte si nejdůležitější vlastnosti platformy {0}',
        button: 'Vrátit se'
      }
    },
    grid: {
      paginator: {
        rows: 'Řádků na stránku:',
        of: 'z'
      }
    },
    iconMenu: {
      edit: 'Upravit',
      delete: 'Smazat',
      activate: 'Aktivovat',
      export: 'Export',
      exportXLSX: 'Export do XLSX',
      exportCSV: 'Export do CSV',
      finish: 'Ukončit',
      reactivate: 'Znovu aktivovat',
      detail: 'Detail',
      invite: 'Pozvat účastníky',
      createGrouping: 'Vytvořit seskupení'
    },
    snackbar: {
      error: 'Neočekávaná chyba. Vyčkejte chvíli a zkuste akci provést znovu.',
      link: {
        common: 'Odkaz byl zkopírován do schránky, stiskem Ctrl + V ho vložíte do textu',
        mac: 'Odkaz byl zkopírován do schránky, stiskem Cmd + V ho vložíte do textu'
      }
    },
    sidebar: {
      overview: 'Přehled',
      new: 'Vytvořit nový',
      back: 'Zpět',
      help: 'Nápověda',
      userGuide: {
        label: 'Návod',
        link: 'dash-user-guide'
      },
      guide: {
        label: 'Průvodce'
      }
    },
    projectFilter: {
      hint: 'Hledat',
      advanced: 'Pokročilé filtrování',
      projectTypesLabel: 'Typy projektů',
      activityTypesLabel: 'Aktivity',
      languagesLabel: 'Jazyky',
      projectStatesLabel: 'Stavy projektů',
      refreshTooltip: 'Znovu načíst',
      sortTooltip: 'Řadit dle',
      activeFirstLabel: 'Aktivní projekty první',
      showDemoProjects: 'Zobrazit ukázkové projekty',
      showDemoProjectsHelper: 'Vždy seřazené jako poslední',
      newLabel: 'Nový',
      newLabelHelper: 'Vytvořit nový assessment',
      noProjectFoundMessage: 'Nenalezli jsme žádný assessment',
      noProjectFoundMessageCanAdd: 'Nenalezli jsme žádný assessment. Můžete jej vytvořit tlačítkem "NOVÝ" vpravo nahoře.',
      fromValuesFilters: {
        activityType: {
          label: 'Aktivity'
        },
        language: {
          label: 'Jazyky'
        },
        projectState: {
          label: 'Stavy projektů'
        },
        projectType: {
          label: 'Typy projektů'
        },
        finishedNonEmpty: {
          label: 'Dokončené profily'
        }
      },
      layout: {
        CARD: { tooltip: 'Zobrazit jako karty' },
        LIST: { tooltip: 'Zobrazit jako seznam' }
      }
    },
    guide: {
      initial: {
        slide1: {
          title: 'Víte, proč jste tady?',
          subtitle: 'Jste tu, protože se chcete něco dozvědět o lidech. Chcete znát odpovědi, které často hledáte pomocí intuice, životopisů nebo testových otázek. Víte však, že dnes už to nestačí. Víte, že vám chybí fakta. O uchazečích i zaměstnancích. Pro ně jste sem přišli. A jste tu správně.'
        },
        slide2: {
          title: 'Lidé chtějí dostat férovou šanci ukázat, co v nich je',
          subtitle: 'Dovolte jim to. Jak? Pozvěte je do simulační hry. V ní se mohou svobodně projevit a ještě si odnesou skvělý zážitek z vašeho náboru. Vy si přitom ověříte jejich kompetence a nebude vás to stát žádný čas.',
          buttonLabel: 'Hrát DEMO'
        },
        slide3: {
          title: 'Vaše intuice už nebude nikdy sama',
          subtitle: 'Budete mít přehledná fakta vždy na dosah ruky. Behaviorální diagnostická data o lidech, která vám pomohou odhalit jejich silné i slabé stránky. Získáte závěry a doporučení, se kterými budete dělat lepší rozhodnutí při náboru, výběru a rozvoji svých lidí.'
        },
        slide4: {
          title: 'Nezklamete nikoho',
          subtitle: 'Sympatie silných si získáte dobře mířenou pochvalou a těm slabším dáte možnost odejít hrdě s hlavou vztyčenou. Kompetenční odznaky a report na míru získá každý. Pro nikoho tak nebude výběrové řízení zbytečně investovaný čas.'
        },
        slide5: {
          title: 'Jste připraveni inovovat své HR?',
          subtitle: 'Klíčové je, koho do týmu pustíte. Čím více dobrých rozhodnutí uděláte hned na začátku, o to méně problémů budete muset řešit v budoucnu.'
        }
      },
      initialV2: {
        slide1: {
          title: 'Cesta k unikátním datům o vašich talentech právě začíná',
          subtitle: 'Vítejte! V krátkosti vám představíme základní principy Behavera Talent Decision Cloud.'
        },
        slide2: {
          title: 'Nechte své talenty zahrát si hru',
          subtitle: 'K získávání dat používáme online simulační hru, která automaticky měří a interpretuje behaviorální kompetence z projevů hráčů v různých situacích. Byla navržena týmem I/O psychologů, HR profesionálů a manažerů a otestována na tisících lidech. Není to nudný dotazník ani logická hádanka. Je to assessmentová hra nové generace.',
          buttonLabel: 'Zahrajte si krátké demo'
        },
        slide3: {
          title: 'Pozvěte 3 vybrané lidi ze svého týmu',
          subtitle: 'Přes internetový odkaz se snadno přihlásí a získají přístup k simulační hře. Vyberte 3 členy ze svého týmu, kteří vás rádi podpoří v objevování nových řešení. Dopřejte jim dostatek času i soustředění pro dokončení hry. Získáte tak své první tři profily a vaši kolegové obdrží digitální odznaky se svými nejsilnějšími kompetencemi.'
        },
        slide4: {
          title: 'Získejte tu správnou perspektivu',
          subtitle: 'Jakmile hráči simulaci dokončí, jejich profily vám budou ihned k dispozici. V této chvíli nastává ten správný čas přizpůsobit výsledky vašim potřebám. S tím vám rádi pomůžeme a ukážeme vám, jak na to.'
        },
        slide5: {
          title: 'Odstartujte novou éru v práci se svými talenty',
          subtitle: 'Pracujte s unikátními daty pro výběr i rozvoj talentů. Objevujte silné stránky týmů i potenciál rozvoje jednotlivců. Váš Talent Decision Cloud je připraven. Pusťte se do toho.'
        }
      }
    },
    pages: {
      public: {
        login: {
          text: 'Přihlaste se',
          claims: [
            'Přitáhněte lidi simulační hrou',
            'Změřte kompetence behaviorálními daty',
            'Změňte svůj hiring a development s Talent Decision Cloud-em'
          ],
          forgottenPwd: 'Zapomenuté heslo',
          submit: 'PŘIHLÁSIT',
          lang: 'Jazyk',
          email: 'E-mail',
          pwd: 'Heslo',
          error: 'Neplatné heslo / e-mail',
          errorAccessDenied: 'Přístup odepřen'
        },
        resetPassword: {
          text: 'Zapomenuté heslo',
          newPwd: 'Zadejte nové heslo',
          repeatNewPwd: 'Zopakujte heslo',
          lang: 'Jazyk',
          errorEqual: 'Hesla se musí shodovat',
          errorWidth: 'Hesla musí mít minimálně 8 znaků',
          submit: 'Odeslat'
        },
        resetPasswordEmail: {
          text: 'Zapomenuté heslo',
          lang: 'Jazyk',
          notice: 'Obdržíte zprávu s odkazem pro restartování hesla',
          setMail: 'Zadejte e-mail',
          submit: 'Odeslat',
          error: 'Nesprávně zadaná adresa',
          errorNotFound: 'Uživatel se zadanou e-mailovou adresou nenalezen'
        },
        accountNew: {
          text: 'Vytvořte si účet',
          submit: 'Vytvořit účet',
          fields: {
            firstName: {
              label: 'Jméno'
            },
            lastName: {
              label: 'Příjmení'
            },
            email: {
              label: 'Váš pracovní e-mail',
              invalidEmail: 'Neplatný e-mail',
              alreadyExists: 'Uživatel s daným e-mailem již existuje',
              freeEmail: 'Nepodporovaný e-mail, použijte pracovní'
            },
            phone: {
              label: 'Vaše telefonní číslo',
              invalidPhone: 'Neplatné telefonní číslo'
            },
            promoCode: {
              label: 'Promo kód',
              helperText: 'Máte promo kód? Zadejte jej nyní a získejte nárok na slevu a další výhody'
            },
            password: {
              label: 'Zvolte si heslo'
            },
            repeatPassword: {
              label: 'Zopakujte heslo',
              passwordsDontMatch: 'Hesla se liší'
            },
            language: {
              label: 'Jazyk'
            },
            companyName: {
              label: 'Název společnosti',
              alreadyExists: 'Společnost s daným identifikátorem již existuje'
            },
            companyConfirm: {
              label: 'Prohlašuji, že pracuji pro tuto společnost',
              required: 'Potvrďte prosím, že pro uvedenou společnost pracujete'
            },
            marketingConfirm: {
              label: 'Zašlete mi občas e-mailem novinky o tom, co děláte a jaké nové simulační hry a funkce připravujete'
            },
            tosConfirm: {
              label: '{0[Podmínky služby]} a {1[Zásady ochrany soukromí]} jsem četl/a a souhlasím s nimi',
              required: 'Pro využití služby musíte souhlasit'
            }
          },
          requiredMessage: '* Povinné pole',
          fieldRequired: 'Povinné pole',
          valueLengthError: 'Délka musí být mezi {0} a {1} znaky',
          successMessage: {
            text1: 'Váš účet byl vytvořen a na uvedenou adresu jsme vám zaslali potvrzovací e-mail.',
            text2: 'Kliknutím na odkaz v e-mailu svůj účet aktivujete.',
            text3: 'Pokud zpráva nedorazí do 20 minut, podívejte se také do složky SPAM, případně zkontrolujte také jiné složky, které pro třídění pošty používáte.',
            button: 'Rozumím'
          },
          errorMessage: {
            text1:
              'Nepodařilo se nám vytvořit váš nový účet. Pokud problémy přetrvávají, kontaktujte naší podporu na {0} anebo využijte chat v pravém dolním rohu.',
            button: 'Rozumím'
          }
        },
        accountActivation: {
          text: 'Aktivujte účet pro e-mail {0}',
          submit: 'Aktivovat',
          successMessage: 'Účet úspěšně aktivován',
          errorMessage: 'Účet se nám nepodařilo aktivovat. Zkontrolujte platnost odkazu'
        },
        common: {
          loginFooter: 'Máte již {0} účet? {1[Přihlaste se]}',
          newAccountFooter: 'Nemáte {0} účet? {1[Vytvořte si ho]}'
        }
      },
      private: {
        project: {
          applicantDetail: {
            back: 'Zpět',
            print: 'Vytisknout',
            evaluation: 'Evaluace',
            publicProfile: 'Veřejný profil',
            idSim: 'IDSIM',
            projectName: 'Projekt',
            simulationName: 'Aktivita',
            added: 'Účastník přidán',
            finished: 'Dokončil/a',
            exported: 'Exportováno',
            strengths: 'Přednosti',
            weakness: 'Rezerva',
            competencies: 'Přehled kompetencí',
            wellBeing: 'Employee well-being - Rizikové faktory',
            noRiskFactorsMessage: 'Žádné rizikové faktory',
            causesLabel: 'Nejčastější příčiny',
            indicators: 'Pracovní ukazatele',
            recommendations: 'Doporučení pro práci s účastníkem',
            interpretations: 'Závěry a doporučení',
            dimensions: 'Dimenze',
            unfinishedMessage: 'Účastník ještě nedokončil aktivitu',
            operationalization: 'Operacionalizace',
            operationalizations: 'Operacionalizace',
            coreCompetenciesLabel: 'Klíčové',
            otherCompetenciesLabel: 'Další',
            hideLabel: 'Skrýt',
            showLabel: 'Zobrazit',
            factors: 'Faktory',
            durationStats: {
              preparation: 'Přípravy',
              simulation: 'Práce na úkolech',
              total: 'Celkový čas',
              description: 'Celkový čas je tvořen časem stráveným na přípravách a prací na úkolech'
            },
            chart: {
              position: 'Umístění',
              score: 'Skóre',
              skipped: 'Přeskočených úkolů'
            },
            compareTooltip: 'Porovnat',
            lockedPrintMessage: 'Pro tisk přejděte na plnou verzi',
            lockedProfileMessage: 'Pro zobrazení profilu přejděte na {0[plnou verzi]}',
            lockedRecommendationsMessage:
              'Zjistěte, na co se zaměřit v další práci s účastníkem. Přejděte na {0[plnou verzi]}',
            lockedSkillsDescriptionMessage:
              'Pro detailní popis kompetence a shrnutí skóre účastníka přejděte na {0[plnou verzi]}',
            lockedIndicatorMessage: 'Zajímá vás detailní popis ukazatele? Přejděte na {0[plnou verzi]}'
          },
          applicantCompare: {
            removeButton: 'Odstranit',
            addButton: 'Přidat profil k srovnání',
            searchPlaceholder: 'Hledat profil'
          },
          applicantList: {
            anonymous: 'Anonymní',
            back: 'Seznam projektů',
            invite: 'Pozvat účastníky',
            inProgress: 'probíhá',
            unstarted: 'nezapočato',
            searchTooltip: 'Hledat účastníky',
            highlightTooltip: 'Zvýraznit účastníky v analytice',
            refreshTooltip: 'Znovu načíst',
            rejected: {
              INVITATION: 'pozvání odmítnuto',
              REGISTRATION: 'registrace zrušená'
            },
            pending: {
              INVITATION: 'pozvaný',
              REGISTRATION: 'registrace nepotvrzená'
            },
            inviteTooltip: {
              CONCEPT: 'Projekt ještě nebyl aktivován',
              SCHEDULED: 'Projekt ještě není aktivní',
              ACTIVE: 'Pozvěte účastníky',
              FINISHED: 'Projekt již byl ukončen'
            },
            demo: {
              weightApplyDisabled: 'Demo projekt neumožňuje uložení nastavení'
            },
            empty: {
              valid: 'Zatím nemáte žádné účastníky. Pozvěte je přes tlačítko ',
              invalid: 'Seznam účastníků je prázdný'
            },
            cols: {
              position: 'Pořadí',
              name: 'Jméno',
              score: 'Skóre',
              goodAt: 'Přednosti',
              weakAt: 'Rezerva',
              num: 'Num',
              finishedAt: 'Dokončeno',
              competencies: 'Kompetence',
              applicationStatus: 'Status',
              metrics: 'Metriky'
            },
            applicationStatusEnum: {
              NONE: 'Žádný',
              REJECTED: 'Odmítnutý',
              ACCEPTED: 'Přijatý'
            },
            lockedChartMessage:
              'Přehledně zobrazíte a seřadíte profily podle vybraných parametrů. Tato funkce je dostupná v {0[plné verzi]}',
            upgradeMessage: 'Pro zobrazení profilu přejděte na {0[plnou verzi]}',
            compareButton: 'Porovnat',
            highlightButton: 'Zvýraznit',
            highlightHelperText: 'Zvýraznit účastníky v analytice',
            intraComparison: {
              label: 'Mezi sebou',
              description: 'Porovnat profily mezi sebou'
            },
            crossComparison: {
              label: 'Napříč projekty',
              description: 'Porovnat s účastníky jiného projektu'
            },
            markAsAccepted: 'Označit jako přijaté',
            markAsRejected: 'Označit jako odmítnuté',
            unsetApplicationStatus: 'Zrušit nastavený status',
            notifications: {
              unfinished: {
                lastNotified: 'Připomenuto {0}',
                neverNotified: 'Zatím nepřipomenuto',
                maxCountReached: 'Odeslán maximální počet připomenutí',
                timeLimitReached: 'Další připomenutí můžete odeslat nejdříve po 2 dnech',
                buttonLabel: 'Připomenout'
              }
            },
            crossComparedFromProjectMessage: 'Z projektu {0}',
            actions: {
              copyLabel: 'Kopírovat do...',
              moveLabel: 'Přesunout do...',
              deleteLabel: 'Odstranit',
              remindLabel: 'Připomenout',
              addToTeamsLabel: 'Přidat do skupin/týmů...',
              addTagsLabel: 'Přidat tagy...'
            }
          },
          weighted: {
            name: 'Váhy aktivní',
            description: 'Byly nastaveny vlastní váhy kompetencí pro tento projekt'
          },
          customModel: {
            name: 'Vlastní kompetenční model',
            description: 'Byl nastavený vlastní kompetenční model pro tento projekt'
          },
          overview: {
            tabs: {
              activity: 'Aktivita',
              list: 'Seznam účastníků',
              analytics: 'Analytika',
              context: 'Kontext'
            }
          },
          participantFilter: {
            fullTextPlaceholder: 'Hledat',
            matchAllLabel: 'Splňuje všechny vybrané',
            fromValuesFilters: {
              activityStatus: {
                label: 'Stav aktivity'
              },
              applicationStatus: {
                label: 'Status'
              },
              metricsScore: {
                label: 'Rozsah skóre'
              },
              metricsDuration: {
                label: 'Trvání aktivity'
              },
              registeredAt: {
                label: 'Registrováno'
              },
              finishedAt: {
                label: 'Dokončeno'
              },
              bioAge: {
                label: 'Věk'
              },
              seekerStatus: {
                label: 'Hledá příležitosti'
              },
              skillsLanguages: {
                label: 'Jazyky'
              },
              educationFields: {
                label: 'Obory studia'
              },
              educationLevel: {
                label: 'Vzdělání'
              },
              educationCurrentlyStudying: {
                label: 'Stále studuje'
              },
              employmentWorkExperience: {
                label: 'Pracovní zkušenosti'
              },
              employmentLastWorkPosition: {
                label: 'Poslední pracovní pozice'
              },
              preferencesRoles: {
                label: 'Role'
              },
              preferencesSpecializations: {
                label: 'Specializace'
              },
              preferencesIndustries: {
                label: 'Odvětví'
              },
              preferencesOpportunityTypes: {
                label: 'Typy příležitostí'
              },
              preferencesLocations: {
                label: 'Lokalita'
              },
              preferencesPersonalDevelopmentAreas: {
                label: 'Možnosti rozvoje'
              },
              preferencesBenefits: {
                label: 'Benefity'
              },
              preferencesSalaryRange: {
                label: 'Očekávaný plat',
                description: 'V Kč za měsíc, přepočteno podle přibližného předem stanoveného kurzu'
              },
              preferencesLoveBrands: {
                label: 'Love brands'
              },
              skillsOther: {
                label: 'Jiné dovednosti'
              },
              tags: {
                label: 'Tagy',
                noneLabel: 'Bez tagů'
              }
            }
          },
          analytics: {
            noParticipants: 'Zatím žadní účastníci nedokončili simulaci',
            metricBubbleChart: {
              title: 'Kompetenční mapa skupiny',
              subtitle: 'Přehled četnosti skóre v jednotlivých kompetencích'
            },
            scatterChart: {
              title: 'Kompetenční fit',
              subtitle: 'Graf vztahu hlavních a vedlejších kompetencí',
              description: 'Graf zobrazuje vztah vámi vybraných hlavních a vedlejších (všech ostatních) kompetencí účastníků. Na ose X (horizontální) a Y (vertikální) je promítnutý vážený průměr hlavních, resp. vedlejších kompetencí. Zařazení do jednoho ze 4 vyobrazených kvadrantů vyjadřuje, jak daný účastník vaši konfiguraci splňuje.',
              competenciesLabel: 'Kompetence',
              primaryCompetenciesLabel: 'Hlavní kompetence',
              secondaryCompetenciesLabel: 'Vedlejší kompetence'
            },
            crossComparison: {
              button: 'Srovnání napříč',
              cancelButton: 'Zrušit srovnání',
              cancelHighlightButton: 'Zrušit zvýraznění',
              thisProjectLabel: 'Tento projekt',
              noProjectsLabel: 'Žádné další projekty',
              searchProject: 'Hledat projekt',
              projectSelect: {
                cancel: 'Zrušit',
                change: 'Změnit',
                comparisonEmptyMessage: '{0[Srovnat]} s projektem',
                comparisonMessage: 'Srovnání s projektem {0}'
              }
            }
          },
          questionnaireAnalytics: {
            removeParticipantFromComparisonButton: 'Odstranit',
            anonymous: 'Anonymní',
            singleComparisonMessage: 'Pro srovnání jediného účastníka klikněte na příslušný řádek. Opakovaný klik opět aktivuje všechny řádky.',
            managerialComparisonLabel: 'Srovnat jako manažera týmu',
            overviewTitle: 'Týmový přehled',
            overviewSubtitle: 'Jak vypadá týmový profil na základě vyplněných Culture Fit dotazníků',
            overviewSubtitleComparison: 'Srovnání jednotlivců s týmovým profilem a percentuální fit týmové kultury'
          }
        },
        projectEdit: {
          buttons: {
            back: 'Zpět',
            next: 'Další',
            close: 'Zrušit',
            save: 'Uložit',
            create: 'Dokončit',
            saveActivate: 'Uložit a aktivovat',
            createActivate: 'Dokončit a aktivovat'
          },
          projectTypes: {
            POSITION: {
              label: 'Výběrové řízení',
              tag: 'Výběrové řízení',
              description: 'Zvolte tuto možnost, pokud chcete provést screenning nebo assessment účastníků v rámci výběrového řízení na konkrétní pozici',
              overrides: {
                projectNameLabel: 'Název pozice',
                projectNameHelper: 'Uveďte název pozice výběrového řízení, ke kterému se simulace bude vztahovat'
              }
            },
            GROUP_ASSESSMENT: {
              label: 'Tým nebo skupinu',
              tag: 'Assessment skupiny',
              description: 'Zvolte tuto možnost, pokud chcete  provést screenning nebo assessment v rámci konkrétní interní skupiny nebo týmu',
              overrides: {
                projectNameLabel: 'Název skupiny',
                projectNameHelper: 'Uveďte název týmu nebo skupiny, pro kterou bude určen'
              }
            },
            TALENT_PROGRAM: {
              label: 'Talent program',
              tag: 'Talent program',
              description: 'Zvolte tuto možnost, pokud chcete provést screenning nebo assessment v rámci výběru stážistů nebo svého programu pro hledání mladých talentů např. z řad studentů apod',
              overrides: {
                projectNameLabel: 'Název talentového programu',
                projectNameHelper: 'Uveďte název talentového programu (např. akademie nebo stáž)'
              }
            },
            VIEW: {
              label: 'Seskupení',
              tag: 'Seskupení',
              description: 'Zvolte tuto možnost, pokud chcete seskupit profily z jiných assessmentů. Tento projekt je pro účastníka skrytý a slouží zejména pro analytické a statistické účely.',
              overrides: {
                projectNameLabel: 'Název seskupení',
                projectNameHelper: 'Uveďte název seskupení'
              }
            },
            QUESTIONNAIRE: {
              label: 'Dotazník',
              tag: 'Dotazník'
            }
          },
          intro: {
            title: 'Vytvořit nový assessment pro',
            editTitle: 'Assessment pro'
          },
          generalSettings: {
            simNameLabel: 'Aktivita',
            projectNameLabel: 'Název projektu',
            projectNameHelper: 'Uveďte název projektu, ke kterému se aktivita bude vztahovat',
            posTypeLabel: 'Typ pozice',
            posTypeHelper: 'Slouží k přizpůsobení příběhu pro účastníka aktivity',
            companyLabel: 'Společnost',
            languageLabel: 'Jazyk aktivity',
            dateTitle: 'Platnost projektu',
            dateHelper: 'Zadejte datum, do kterého se mohou účastníci přihlásit a dokončit zadání',
            reportTitle: 'Report',
            reportHelper: 'Vyberte, jestli chcete účastníkovi okamžitě po ukončení aktivity zpřístupnit report. Nastavení je později možné měnit globálně i pro jednotlivce.',
            alwaysOpenLabel: 'Vždy otevřeno',
            dateStartLabel: 'Datum otevření',
            dateEndLabel: 'Datum ukončení',
            dateEndHelperText: 'Včetně nastaveného data',
            advancedSettings: 'Pokročilé nastavení',
            participantReportLabel: 'Report pro účastníka',
            supportedLanguagesLabel: 'Další podporované jazyky',
            supportedLanguagesHelper: 'Přidáním dalších jazyků umožníte účastníkovi vybrat jazyk aktivity',
            viewLabel: 'Projekt je seskupení',
            viewDescription: 'Seskupení neumožňují účastníkům přímou interakci - jsou pro ně skryté. Seskupení slouží jako obálka účastníků, kteří prošli stejným typem assessmentu, jaký je nakonfigurovaný pro tento projekt.',
            poolEndpointLabel: 'Talent pool endpoint',
            talentPoolsLabel: 'Talent pooly',
            talentPoolsHelper: 'Vyberte talent pooly, do kterých chcete účastníka projektu kvalifikovat',
            allowedEmailDomainsLabel: 'Povolené e-mailové domény',
            allowedEmailDomainsHelper: 'Můžete omezit přístup k registraci do projektu pro konkrétní e-mailové domény (např. "gmail.com"). Pozvánek z platofrmy se nastavení netýká.',
            allowedEmailDomainsErrorInvalid: 'Neplatná doména, zkuste formát "gmail.com"',
            posterEnabledLabel: 'Poster pro účastníka',
            posterEnabledToggleDisabledDescription: 'Momentálně je dostupný pouze pro preferenční Culture Fit',
            posterEnabledDescription: 'Dostupný přímo u reportu v portálu po dokončení aktivity',
            reportChatLabel: 'Chatbot u reportu',
            reportChatHelper: 'Vyberte chatbota, který se účastníkovi objeví u reportu',
            targetViewsLabel: 'Cílové seskupení',
            targetViewsHelper: 'Profily se budou automaticky kopírovat do vybraných seskupení',
            flowProjectIdLabel: 'Další projekt',
            flowProjectIdHelper: 'Můžete nastavit tzv. flow projektů, kdy po registraci, nebo pozvání bude účastník automaticky pozván i do vybraného projektu',
            flowProjectEventTypeLabel: 'Kdy pozvat',
            seekerQualifierEventTypeLabel: 'Kvalifikovat hledače',
            seekerQualifierEventTypeHelperText: 'Projekt může účastníka kvalifikovat do role hledače',
            personalDetailsExtentLabel: 'Rozsah povinných údajů',
            personalDetailsExtentHelper: 'Zadejte rozsah dat, které účastník musí mít vyplněné před vstupem do assessmentu'
          },
          texts: {
            introTitle: 'Uvítací zpráva na kartě projektu',
            introHints: [
              'Uvítací zpráva má za cíl uvést účastníky do kontextu a poskytnout jim informace o tom, co je čeká.',
              'Můžete nechat přednastavenou zprávu nebo text přizpůsobit.'
            ],
            customize: 'Přizpůsobit',
            reset: {
              button: 'Resetovat',
              confirmMessage: 'Skutečně chcete resetovat text?',
              yes: 'Ano',
              no: 'Ne'
            },
            internalNameLabel: 'Účastník se bude hlásit na pozici {0}, které ale interně říkame',
            internalNameHelper: 'Pojmenujte pozici tak, jak chcete, aby se o ní mluvilo v aktivite',
            internalDescriptionLabel: 'A ve své práci se bude primárně zaměřovat na',
            internalTextPreviewHelper: 'Takto například to bude pro účastníka v aktivite vypadat. Upravte text výše, aby se vám výsledná věta líbila.',
            reportTitle: 'Zpráva po dokončení aktivity',
            reportHints: [
              'Zpráva se zobrazí účastníkovi po dokončení aktivity.',
              'Můžete nechat přednastavenou zprávu nebo vložit vlastní text.'
            ]
          },
          notifications: {
            recipientsLabel: 'Váš tým',
            recipientsHelper: 'Kdo z vašeho týmu bude dostávat notifikace o dění v rámci tohoto projektu?',
            select: 'Vyberte',
            frequencyLabel: 'Frekvence notifikací',
            frequencyHelper: 'Vyberte frekvenci notifikací',
            frequencyEnum: {
              NEVER: 'Nikdy',
              HOURLY: 'Hodinově',
              DAILY: 'Denně',
              WEEKLY: 'Týdně'
            }
          },
          snackbar: {
            createdMessage: 'Projekt {0} byl vytvořen',
            savedMessage: 'Změny pro projekt {0} byly uloženy'
          },
          validation: {
            languageChangeWarn: 'Změnili jste jazyk aktivity. Pokud změny uložíte, bude obsah v záložce TEXTY nahrazen výchozím pro nový jazyk.',
            projectTypeEmpty: 'Vyberte typ projektu',
            projectNameEmpty: 'Uveďtě název projektu',
            posTypeEmpty: 'Vyberte typ pozice',
            companyEmpty: 'Vyberte společnost',
            simulationEmpty: 'Vyberte aktivitu',
            languageEmpty: 'Vyberte jazyk',
            startDateAfterEndDate: 'Datum otevření musí být před datem ukončení',
            shortenedDateInterval: 'Platnost projektu může být jenom prodloužená',
            endDateInPast: 'Datum ukončení musí být v budoucnosti',
            endDateNotSet: 'Nastavte datum ukončení',
            notificationsRecipientsEmpty: 'Vyberte, kdo bude notifikace dostávat, nebo nastavte frekvenci na "Nikdy"',
            frequencyEmpty: 'Vyberte frekvenci, nebo odstraňte příjemce'
          },
          projectStatus: {
            activeWithEnd: 'Tento projekt je aktivní a bude končit {0}',
            activeUnlimited: 'Tento projekt je aktivní a bez časového omezení',
            planned: 'Tento projekt bude aktivní {0}',
            concept: 'Tento projekt je ve stavu “Koncept”. Abyste do něj mohli pozvat účastníka, musíte ho aktivovat.'
          }
        },
        designer: {
          canvas: {
            node: {
              placeholder_DIALOGUE_IMPULSE: 'Napište repliku...',
              placeholder_DIALOGUE_REPLY: 'Napište odpověď...',
              defaultPersonName: 'Bibi Talent',
              defaultPersonInfo: 'Digital Recruitment Guru',
              emptyLabel: 'Prázdné'
            }
          },
          editor: {
            saveButton: 'Uložit',
            autoArrangeButton: 'Uspořádat',
            namePlaceholder: 'Pojmenujte mě...',
            propertiesForm: {
              redirectUrlLabel: 'URL k přesměrování',
              redirectTimeoutLabel: 'Časová prodleva přesměrování',
              redirectReportLabel: 'Přesměrovat na report',
              redirectTopLabel: 'Přesměrovat celou stránku',
              summaryMailTitle: 'Souhrnný e-mail',
              summaryMailDescription: 'Po dokončení simulace vám můžeme zaslat souhrn proměnných a jejich hodnot.',
              summaryMailLabel: 'E-mailová adresa',
              defEnvLabel: 'Výchozí prostředí',
              preview: 'Náhled',
              envLabel: {
                PARK: 'Park',
                OFFICE: 'Kancelář',
                COUNTER: 'Přepážka'
              },
              defLangLabel: 'Jazyk',
              languageTitle: 'Jazyk konverzace',
              languageDescription: 'Konverzace, její kontext a ovládací prvky se primárně řídí nastaveným jazykem',
              companyTitle: 'Společnost',
              companyDescription: 'Vyberte společnost, pro kterou je konverzace určena',
              companyLabel: 'Společnost',
              environmentTitle: 'Prostředí',
              environmentDescription:
                'Simulace se bude odehrávát ve vybraném prostředí. V součastnosti se mění pouze pozadí.',
              variableDefinitionsTitle: 'Proměnné',
              variableDefinitionsDescription: 'Definujte proměnné, které můžou být použity počas simulace na řízení toku nebo nastavení externích parametrů. Jméno proměnné se může skládat jen ze znaků abecedy, např. \'mojaPromenna\' (žádné mezery, pomlčky nebo jiné zvláštní znaky).',
              variableDefinitionAddButton: 'Přidat proměnnou',
              variableDefinitionNameLabel: 'Jméno proměnné',
              variableDefinitionValueLabel: 'Počáteční hodnota',
              inputParamsTitle: 'Proměnné simulace',
              inputParamsDescription: 'Seznam proměnných v simulaci, které v jejím průběhu mohou nabývat různých hodnot podle operací definovaných na uživatelských akcích (např. vybraná odpověď v dialogu). Tyto proměnné taky mohou vstupovat do simulace prostřednictvím její URL jako parametry. Proměnné pak slouží na sběr dat (reportovaných nebo ne), řízení přesměrování nebo taky na přizpůsobení zpráv v dialogu - použití v textu jako {{nazevPromenne}}. Identifikační proměnné se užívají k určení, zda probíhá příslušná simulace.',
              redirectTitle: 'Přesměrování',
              redirectDescription:
                'Po úspěšném dokončení simulace může být uživatel přesměrován na specifikované URL. To může obsahovat proměnné definované v předešlé sekci, např. http://www.priklad.cz/identifikator={nazevPromenne}',
              redirectTimeoutDescription:
                'Můžete také definovat čas v sekundách, po uplynutí kterého bude uživatel přesměrován. Výchozí hodnota je 6 sekund.',
              inputParamNameLabel: 'Název proměnné',
              inputParamIdentifyingLabel: 'Identifikační',
              inputParamTypeLabel: 'Typ',
              inputParamReportedLabel: 'Reportována',
              inputParamLabelLabel: 'Reportovaný název',
              inputParamMapsToMetricLabel: 'Mapováno na metriku',
              inputParamMetricLabel: 'Metrika',
              inputParamMetricMinValueLabel: 'Minimální hodnota',
              inputParamMetricMaxValueLabel: 'Maximální hodnota',
              inputParamMetricMappingReversedLabel: 'Reverzní mapování',
              addInputParamButton: 'Přidat proměnnou',
              removeInputParamButton: 'Odstranit',
              inputParamTypes: {
                STRING: 'Řetězec znaků',
                NUMBER: 'Číslo'
              },
              projectTitle: 'Projekt',
              projectDescription: 'Nastavení projektu',
              projectNameLabel: 'Název projektu',
              projectNameHelperText: 'Ve výchozím stavu přebírá název modelu',
              simulationTitle: 'Simulace',
              simulationDescription: 'Nastavení simulace jako takové. Typ simulace definuje metriky, na které mohou být číselné proměnné mapovány.',
              simulationKeyLabel: 'Typ',
              simulationVariantLabel: 'Varianta',
              simulationVariants: {
                candidate: 'Preferenční',
                manager: 'Vnímaný - Manažer',
                employee: 'Vnímaný - Zaměstnanec'
              },
              simulationNameLabel: 'Název',
              simulationNameHelperText: 'Ve výchozím stavu přebírá název typu simulace, případně název modelu',
              lafTitle: 'Vzhled',
              lafDescription: 'Konfigurace vzhledu simulace',
              lafAnswerFormatLabel: 'Formát odpovědí',
              lafAnswerFormatTypes: {
                SAME: 'Stejné'
              },
              lafTypingDurationEnabledLabel: 'Efekt psaní povolen',
              validation: {
                unknownParameter: "Neznámá proměnná '{0}'",
                languageRequired: 'Vyberte jazyk',
                companyRequired: 'Vyberte společnost',
                parameterNameRequired: 'Název je povinný',
                parameterNameNotUnique: 'Název již existuje',
                parameterNameInvalid: 'Nesprávný formát - může obsahovat jen velké a malé písmena nebo podtržítko',
                parameterLabelRequired: 'Nesmí být prázdné',
                parameterTypeRequired: 'Vyberte typ',
                redirectTimeoutNegative: 'Časová prodleva nesmí být záporná',
                projectNameRequired: 'Název je povinný',
                simulationNameRequired: 'Název je povinný',
                nameLengthAtLeast: 'Délka názvu musí být alespoň {0}',
                mappingMetricRequired: 'Vyberte metriku',
                mappingMinValueRequired: 'Zadejte minimální hodnotu',
                mappingMaxValueLessThanMin: 'Maximální hodnota nesmí být menší než minimální',
                invalidEmail: 'Neplatný e-mail'
              }
            },
            nodeSettingsForm: {
              confirmButton: 'Potvrdit',
              cancelButton: 'Zrušit',
              variableOperationsTitle: 'Operace na proměnných',
              variableOperationsDescription: 'Definujte operace na proměnných, které budou vykonány, když si uživatel zvolí tuto cestu v simulaci (např. tím, že vybere danou odpověď)',
              variableOperationsEmpty: 'Žádné operace zatím nebyli definovány',
              variableOperationVariableLabel: 'Proměnná',
              variableDefinitionsEmpty: 'Nejdřív definujte proměnné v boční liště',
              variableOperationOperationLabel: 'Operace',
              variableOperationValueLabel: 'Hodnota',
              variableOperationAddButton: 'Přidat operaci',
              variableOperationVariableRequired: 'Vyberte proměnnou',
              variableOperationOperationRequired: 'Vyberte operaci',
              variableOperationValueRequired: 'Nastavte hodnotu',
              variableOperationValueNotVariable: 'Neexistující proměnná',
              operations: {
                ASSIGN: {
                  label: 'přiraď'
                },
                ADD: {
                  label: 'přičítej'
                },
                MULTIPLY: {
                  label: 'vynásob'
                },
                ASSIGN_VARIABLE: {
                  label: 'přiraď proměnnou'
                },
                ASSIGN_RESULT: {
                  label: 'přiraď obsah'
                }
              }
            }
          },
          modelList: {
            filterPlaceholder: 'Hledat',
            createButton: 'Vytvořit',
            confirmDeleteButton: 'Potvrdit',
            emptyListMessage:
              'Žádné modely simulací tady nejsou. Můžete začít přidáním modelu klikem na tlačítko "VYTVOŘIT"',
            unnamedItemName: 'Bez názvu',
            modelCountMessage: '{0} modelů celkem',
            nameHeader: 'Název',
            createdHeader: 'Vytvořeno',
            modifiedHeader: 'Poslední změna'
          }
        },
        opportunities: {
          search: 'Hledat',
          previewTooltip: 'Náhled',
          editLabel: 'Upravit',
          presentedLabel: 'Zveřejnění',
          descriptionLabel: 'Popis',
          tagsLabel: 'Tagy',
          statsLabel: 'Statistiky',
          participantsLabel: 'Účastníci',
          showDetailButton: 'Zobrazit detail',
          showStatsButton: 'Zobrazit statistiky',
          displayedFrom: 'Od',
          displayedTo: 'do',
          notDisplayedAnymore: 'Již neprezentováno (do {0})',
          notPublished: 'Nezveřejněno',
          publish: 'Zveřejnit',
          unpublish: 'Pozastavit',
          invalidOpportunityMessage: 'Neplatná příležitost',
          noParticipantsMessage: 'Zatím se nikdo nepřihlásil nebo neprojevil zájem',
          table: {
            nameLabel: 'Jméno',
            fitLabel: 'Fit',
            fitRequirementsLabel: 'Požadujeme',
            fitPreferencesLabel: 'Nabízíme',
            fitCultureLabel: 'Kultura',
            activitiesLabel: 'Aktivity',
            respondedLabel: 'Reakce',
            toggleRejected: {
              show: 'Zobrazit odmítnuté ({0})',
              hide: 'Skrýt odmítnuté ({0})',
            }
          },
          stats: {
            addWeekButton: '1 týden',
            fromLabel: 'Od',
            toLabel: 'Do',
            cancelLabel: 'Zrušit',
            props: {
              DISPLAYED: { label: 'Zobrazeno' },
              OPENED: { label: 'Otevřeno' },
              LIKED: { label: 'Líbí se' },
              INTERESTED: { label: 'Zajímá' },
              ACCEPTED: { label: 'Přihlášeno' }
            }
          },
          responseStatus: {
            INTERESTED: { label: 'Zajímá se' },
            ACCEPTED: { label: 'Přihlásil se' },
            REJECTED: { label: 'Odmítl' }
          },
          companyResponseStatus: {
            INTERESTED: { label: 'Máme zájem' },
            ACCEPTED: { label: 'Máme zájem' },
            ACCEPTED_ACCEPTED: { label: 'Jsme propojení' },
            REJECTED: { label: 'Odmítli jsme' }
          },
          companyResponseButtons: {
            ACCEPT: 'Projevit zájem',
            ACCEPT_INTERESTED: 'Potvrdit zájem',
            ACCEPT_ACCEPTED: 'Potvrdit přihlášku',
            REJECT: 'Odmítnout'
          },
          loadError: {
            message: 'Omlouváme se, někdo (pravděpodobně já) něco pokazil a teď nemůžeme načíst příležitosti. {0[Zkuste znovu]} nebo kontaktujte podporu na {1}.',
            homeButton: 'Domů'
          },
          noOpportunitiesMessage: 'Žádné příležitosti nenalezeny',
          group1Title: 'Pracovní nabídky, události, talentové akademie...',
          group2Title: 'E-knihy, videá, online kurzy...',
          createButtonTooltip: 'Vytvořit příležitost',
          createButtonLabel: 'Nová',
          tabInterested: 'Zájemci',
          tabActiveSearch: 'Objevování talentů',
          activeSearch: {
            searchingMessage: 'Hledáme...',
            noMoreTalentsMessage: 'Více vhodných talentů jsme nenalezli',
            errorMessage: 'Při vyhledávání nastala chyba, zkuste to znovu později',
            discoverTalentsMessage: 'Objevte více vhodných talentů a rovnou je oslovte',
            discoverButton: 'Objevit',
            eliminatedIncludedLabel: 'Ukázat i talenty nesplňující K.O. kritéria',
            refreshLabel: 'Znovu načíst',
            emailsShownLabel: 'Zobrazit e-mailové adresy',
            emailsShownHelperText: 'Pouze pro administrátory',
            discoveryUnlimitedMessage: 'Můžete objevit neomezený počet talentů',
            discoveryLimitedMessage: 'Můžete objevit {0} talentů'
          }
        },
        companyProfile: {
          noProfileAvailable: {
            title: 'Zatím nemáte žádný profil',
            message: 'Zapůsobte na potenciální kandidáty pečlivě vytvořeným profilem společnosti. Stačí jen vyplnit formulář a my se postaráme o zbytek.',
            actionButton: 'Začít zde'
          },
          notPublished: 'Profil není zveřejněn',
          publishedAt: 'Zveřejněn {0}',
          draftUpdated: 'Koncept profilu aktualizován {0}',
          nameLabel: 'Název',
          logoLabel: 'Logo',
          websiteLabel: 'Webové stránky',
          headerImageLabel: 'Titulní obrázek',
          descriptionLabel: 'Popis',
          locationsLabel: 'Lokalita',
          industriesLabel: 'Odvětví',
          momentsLabel: 'Momentky',
          employeeSizeLabel: 'Počet zaměstnanců',
          typeLabel: 'Typ společnosti',
          benefitsLabel: 'Benefity',
          personalDevelopmentAreasLabel: 'Možnosti rozvoje',
          editButton: 'Upravit',
          actions: {
            preview: {
              label: 'Náhled'
            },
            show: {
              label: 'Ukázat profil'
            },
            publishDraft: {
              label: 'Zveřejnit koncept'
            },
            publish: {
              label: 'Zveřejnit',
              confirmLabel: 'Zveřejnit',
              cancelLabel: 'Zrušit',
              confirmMessage: 'Profil bude veřejně dostupný. Chcete ho zveřejnit?'
            },
            unpublish: {
              label: 'Zrušit zveřejnění',
              confirmLabel: 'Zrušit zveřejnění',
              cancelLabel: 'Zavřít',
              confirmMessage: 'Profil nebude veřejně dostupný. Chcete zrušit jeho zveřejnění?'
            },
            discard: {
              label: 'Zahodit koncept',
              confirmLabel: 'Zahodit',
              cancelLabel: 'Zrušit',
              confirmMessage: 'Koncept bude odstráněn, ponechá se jen předcházející zveřejněný profil. Chcete zahodit koncept?'
            },
            unknown: {
              confirmLabel: 'Potvrdit',
              cancelLabel: 'Zrušit',
              confirmMessage: 'Potvrdit vaši akci?'
            }
          }
        },
        companySelect: {
          currentCompanyMessage: 'Aktuálně vybrána společnost {0}',
          noCurrentCompanyMessage: 'Společnost nevybrána',
          noCurrentCompanyNote: 'Pro pokračování vyberte aktivní účet',
          setButton: 'Vybrat',
          unsetButton: 'Zrušit',
          changeButton: 'Změnit',
          menuUnsetButton: 'Zrušit aktuální výběr společnosti',
          menuSearchCompany: 'Hledat společnost'
        },
        sourcingProfileBuilder: {
          title: 'Oslovte nové talenty ve 2 jednoduchých krocích',
          message: 'Už za 15 minut můžete získat pozornost více než 3 500 motivovaných uchazečů z celé ČR',
          profile: {
            title: 'Váš firemní profil, který mohou navštěvovat zájemci o vaše příležitosti',
            previewButton: 'Náhled',
            NONE: {
              message: 'Vytvořte profil vaší společnosti',
              description: 'Propojte vaši značku s komunitou talentů, představte jim firemní kulturu a sdílejte s nimi váš obsah',
              button: 'Vytvořit'
            },
            PENDING: {
              message: 'Profil máte vytvořený. Zbývá ho jen zveřejnit.',
              button: 'Zveřejnit'
            },
            DONE: {
              message: 'Profil vaší společnosti je připraven',
              button: 'Upravit'
            }
          },
          opportunities: {
            title: 'Vaše pracovní pozice, stáže, nebo programy pro talenty',
            NONE: {
              message: 'Vytvořte jednu či více příležitostí',
              description: 'Představte talentům otevřené pracovní pozice nebo talentové programy, které nabízíte',
              button: 'Vytvořit'
            },
            PENDING: {
              message: 'Příležitost již máte vytvořenou. Zbývá ji jen zveřejnit.',
              button: 'Zveřejnit'
            },
            DONE: {
              message: 'Již máte zveřejněné příležitosti',
              button: 'Vytvořit další'
            }
          }
        },
        newBadge: {
          label: 'Nové'
        }
      }
    },
    notifications: {
      cta: {
        // english translation not necessary
        elf2017: {
          title: 'Zaujalo vás demo naší simulace?',
          message: 'Věnujte nám 1 minutu a získejte přístup k plné verzi',
          action: {
            label: 'Mám zájem',
            url: 'https://goo.gl/forms/2w4jzBH9yhJzKyPf1'
          }
        }
      },
      default: {
        source: 'Notifikace',
        buttons: {
          dismiss: 'Zrušit',
          hide: 'Skrýt'
        }
      },
      freeAccountBlocked: {
        title: 'Můžeme vám pomoci?',
        message: 'Registrace proběhla úspešně, do 24 hodin vám účet ověříme. Zatím můžete zkusit naše DEMO.'
      }
    },
    errors: {
      apiDown: 'Momentálně vylepšujeme {0} Dashboard a brzy budeme zpět. Pokud máte nějaké dotazy, použijte chat níže nebo nás kontaktujte na {1}.'
    },
    priceList: {
      creditAmount: 'Počet kreditů',
      creditPrice: 'Cena za kredit',
      creditPriceDiscount: 'Vaše sleva',
      numberOfTODProfiles: 'Počet profilů',
      expiry: 'Platnost kreditů',
      totalPrice: 'Cena celkem',
      discountedPrice: 'Vaše snížená cena',
      topUpButton: 'Vybrat',
      monthFormat: {
        '1': '1 měsíc',
        '2-4': '{0} měsíce',
        '>4': '{0} měsíců'
      },
      yearFormat: {
        '1': '1 rok',
        '2-4': '{0} roky',
        '>4': '{0} let'
      }
    },
    datePicker: {
      okLabel: 'OK',
      cancelLabel: 'Zrušit'
    },
    participant: {
      personal: {
        seekerStatusTitle: 'Status',
        salaryFreeLabel: 'BEZPLATNĚ',
        groupTitles: {
          BIO: { title: 'Bio' },
          EDU: { title: 'Vzdělání' },
          WORK_EXPERIENCE: { title: 'Pracovní zkušenosti' },
          LANGUAGES: { title: 'Jazyky' },
          SKILLS: { title: 'Jiné dovednosti' },
          ROLES: { title: 'Role' },
          SPECIALIZATIONS: { title: 'Specializace' },
          INDUSTRIES: { title: 'Odvětví' },
          PDAS: { title: 'Možnosti rozvoje' },
          LOCATIONS: { title: 'Lokalita' },
          OPPORTUNITY_TYPES: { title: 'Typy příležitostí' },
          SALARY: { title: 'Mzda' },
          BENEFITS: { title: 'Benefity' },
          LOVE_BRANDS: { title: 'Love Brands' }
        }
      }
    },
    creditLimits: {
      creditsUsed: 'Využito kreditů',
      creditsFree: 'Dostupných kreditů',
      creditsLimit: 'Limit počtu kreditů',
      unlimited: 'Bez limitu počtu kreditů',
      creditsInfo: '1 profil = 5 kreditů',
      upgrade: {
        unlock: '{0[Odemknout]} uzamčené profily',
        raise: '{0[Navýšit]} počet kreditů'
      },
      expiration: {
        unset: 'Bez expirace',
        set: 'Kredity expirují {0}'
      }
    },
    discreteMetricChart: {
      anonymousParticipants: '{0} anonymních účastníků',
      anonymous: 'Anonymní'
    },
    dualMetric: {
      interpretation: {
        teamTitle: 'Tým',
        challengeTitle: 'Výzva',
        opportunityTitle: 'Příležitost',
        resolutionTitle: 'Doporučení'
      }
    },
    analytics: {
      metricsBubbleChart: {
        meanLabel: 'Průměr',
        medianLabel: 'Střední hodnota'
      },
      dualMetricsMassChart: {
        noDataMessage: 'Žádná dostupná data',
        sortedLabel: 'Uspořádané',
        legend: ['Orientovaní na opačnou dimenzi', 'Méně orientovaní', 'Spíše orientovaní', 'Výrazně orientovaní'],
        zones: ['Upozaděné', 'Nevyhraněné', 'Vyhraněné'],
        description: 'Graf popisuje motivační a pracovně kulturní faktory a míru jejich důležitosti pro jednotlivce v týmu. Čím výrazněji se větší počet jednotlivců orientuje na konkrétní oblasti, tím více kulturně vyhraněná je skupina jako celek.'
      }
    },
    copyToClipboardInput: {
      label: 'Hodnota',
      helperText: '{0[Zkopírujte do schránky]} nebo vyberte obsah a stiskněte Ctrl + C',
      helperTextCopied: 'Úspěšně zkopírováno do schránky'
    },
    teams: {
      teamSelect: {
        searchPlaceholder: 'Vyhledejte tým, skupinu...'
      },
      memberSelect: {
        invitationLinkMessage: 'Případně můžete poslat následující odkaz:',
        invitationLinkLabel: 'Zvací odkaz',
        searchPlaceholder: 'Vyhledejte nebo zadejte e-mailovou adresu',
        autoInviteCheckboxLabel: 'Automaticky pozvat do nastavených aktivit',
        emptyMessage: 'Hledejte profily v rámci platformy nebo pozvěte lidi e-mailem',
        buttonCancel: 'Zrušit',
        buttonConfirm: 'Přidat',
        buttonConfirmSingle: 'Nastavit',
        clickToAddLabel: 'Klepnutím přidejte',
        closeMenuLabel: 'Zavřít',
        emailInvitationDisabled: {
          searchPlaceholder: 'Vyhledejte',
          emptyMessage: 'Hledejte profily v rámci platformy',
        }
      },
      memberRow: {
        remindLabel: 'Připomenout',
        remindMaxNotificationsReached: 'Bylo dosaženo maximálního počtu notifikací',
        remindDisabled: 'Notifikace deaktivována do {0}',
        inviteLabel: 'Pozvat',
        highlightTooltip: 'Zvýraznit',
        removeTooltip: 'Odstranit',
        detailLabel: 'Detail',
        profileLabel: 'Profil',
        activityStats: {
          totalScoreLabel: 'Celkové skóre',
          fitLabel: 'Fit'
        }
      },
      dialogs: {
        activityInvite: {
          inviteLabel: 'Pozvat',
          cancelLabel: 'Zrušit',
          message: 'Pozvat {0} do následujících aktivit:',
          messageSingle: 'Pozvat {0} do aktivity {1}?'
        },
        activityRemind: {
          remindLabel: 'Připomenout',
          cancelLabel: 'Zrušit',
          message: 'Připomenout {0} následující aktivity:',
          messageSingle: 'Připomenout {0} aktivitu {1}?'
        },
        memberRemove: {
          cancelLabel: 'Zrušit',
          removeLabel: 'Odstranit',
          message: 'Odstranit {0} z týmu?'
        }
      },
      activityAnalytics: {
        teasers: {
          addManagerMessage: '{0[Přidejte]} manažera týmu, platforma jej otestuje a zde se zobrazí jeho profil',
          addMembersMessage: '{0[Přidejte]} členy týmu, platforma je otestuje a zde se zobrazí jejich týmový profil',
          addMembersMessages: {
            TEAM: '{0[Přidejte]} členy týmu, platforma je otestuje a zde se zobrazí jejich týmový profil',
            GROUP: '{0[Přidejte]} členy skupiny, platforma je otestuje a zde se zobrazí jejich skupinový profil',
            HIRING: '{0[Přidejte]} uchazeče, platforma je otestuje a zde se zobrazí jejich souhrnný profil'
          },
          inviteManagerMessage: '{0} nepozván do aktivity',
          inviteLabel: 'Pozvat',
          remindManagerMessage: '{0} nedokončeno',
          remindLabel: 'Připomenout'
        }
      },
      subGroup: {
        config: {
          message: 'Vyberte kombinaci assessmentů a jazykové verze',
          assessmentsLabel: 'Assessmenty',
          languagesLabel: 'Jazyky',
          primaryLanguageLabel: 'Primární jazyk',
          otherLanguagesLabel: 'Další podporované jazyky',
          cancelLabel: 'Zrušit',
          goToProjectLabel: 'Nastavení projektu'
        },
        saveLabel: 'Uložit',
        changeMemberLabel: 'Změnit',
        setMemberLabel: 'Nastavit',
        addMemberLabel: 'Přidat',
        listTooltip: 'Seznam',
        chartTooltip: 'Graf',
        interpretationsTooltip: 'Interpretace',
        configureTooltip: 'Nastavení',
        configDoneLabel: 'Hotovo',
        memberCountMessage: '{0} z {1} členů',
        memberCountHelperText: 'Můžete změnit počet zobrazených členů',
        emptyMemberListMessage: '{0[Přidejte nebo pozvěte]} členy týmu',
        emptyManagerListMessage: '{0[Přidejte nebo pozvěte]} manažera týmu',
        emptyMemberListMessages: {
          TEAM: '{0[Přidejte nebo pozvěte]} členy týmu',
          GROUP: '{0[Přidejte nebo pozvěte]} členy skupiny',
          HIRING: '{0[Přidejte nebo pozvěte]} uchazeče',
        },
        suggestedLabel: 'Navrhované',
        titles: {
          MANAGER: {
            title: 'Manažer'
          },
          MEMBER: {
            title: 'Tým',
            variants: {
              TEAM: 'Tým',
              GROUP: 'Skupina',
              HIRING: 'Uchazeči'
            }
          }
        }
      },
      name: {
        placeholder: 'Pojmenujte tým...',
        placeholderVariants: {
          TEAM: 'Pojmenujte tým...',
          GROUP: 'Pojmenujte skupinu...',
          HIRING: 'Pojmenujte otevřenou pozici...'
        },
        helperText: 'Přejmenujte klepnutím',
        focusedHelperText: 'Uložte klepnutím vedle'
      },
      team: {
        switchLabel: 'Změnit',
        newLabel: 'Nový',
        settingsLabel: 'Nastavení',
        shareLabel: 'Sdílet',
        deleteLabel: 'Odstranit',
        generateMembersLabel: 'Vygenerovat členy',
        changeTypeLabel: 'Změnit typ skupiny',
        linkHiringLabel: 'Připojit otevřenou pozici',
        typeSelect: {
          header: 'Vyberte typ skupiny',
          message: '...a získejte unikátní vhled do kompetencí, schopností, postojů a hodnot {0}',
          dynamicMessageTexts: ['lidí ve vašich týmech', 'libovolných jednotlivců', 'uchazečů o práci']
        },
        tabs: {
          overview: 'Přehled',
          insights: 'Poznatky',
          list: 'Lidé',
          analytics: 'Analytika',
          wellBeing: 'Well-being'
        },
        searchTooltip: 'Filtrovat',
        reloadTooltip: 'Aktualizovat'
      }
    },
    dualMetricsInterpretation: {
      interpretationLabel: 'Interpretace'
    }
  },
  containers: {
    common: {
      notFound: {
        message: 'Zkontrolujte prosím správnost odkazu. Máte-li dojem, že by zde něco mělo být, kontaktujte naši podporu na {0}.',
        button: 'Seznam projektů'
      }
    }
  },
  project: {
    active: {
      card: 'Aktivní'
    },
    scheduled: {
      card: 'Plánováno'
    },
    finished: {
      card: 'Ukončeno'
    },
    concept: {
      card: 'Koncept'
    },
    edit: {
      basic: 'Základní nastavení',
      texts: 'Texty',
      notifications: 'Notifikace'
    }
  },
  filter: {
    all: 'Celkové skóre',
    graph: 'Graf účastníků',
    competency: 'Kompetence',
    order: 'Uspořádat podle celkového skóre',
    score: 'Skóre',
    weights: {
      title: 'Nastavení kompetencí',
      subtitle: 'Celkové skóre, váhy, klíčové kompetence',
      weightsInfo: 'Ovlivňují způsob výpočtu celkového skóre a mohou ovlivnit pořadí účastníků',
      totalScoreLabel: 'Povolit celkové skóre',
      totalScoreHelper: 'Váhy jsou aktivní, pouze pokud je povoleno celkové skóre',
      weightsTitle: 'Váhy kompetencí',
      coreCompetenciesTitle: 'Klíčové kompetence',
      buttons: {
        apply: 'Použít',
        reset: 'Resetovat',
        resetHelper: 'Resetovat na poslední uložené nastavení',
        level: 'Vyrovnat'
      }
    },
    chart: {
      title: 'Graf účastníků',
      subtitle: 'Zobrazení všech účastníků v sloupcovém grafu'
    }
  },
  forms: {
    partContainer: {
      buttons: {
        back: 'Zpět',
        next: 'Další',
        cancel: 'Zrušit',
        create: 'Vytvořit',
        createAndPublish: 'Vytvořit a zveřejnit',
        save: 'Uložit',
        saveAndPublish: 'Uložit a zveřejnit'
      },
      savedMessage: 'Uloženo'
    }
  },
  wizard: {
    step1: 'Zaměření',
    step2: 'Základní nastavení',
    step3: 'Přizpůsobení textů',
    step4: 'Notifikace'
  },
  matching: {
    range: {
      and: 'a',
      upper: { label: 'maximálně' },
      lower: { label: 'alespoň' }
    },
    requirements: {
      title: 'Požadujeme',
      education: {
        title: 'Vzdělání',
        level: {
          title: 'Úroveň'
        },
        fields: {
          title: 'Obory studia'
        },
        currentlyStudying: {
          title: 'Vhodné pro studující'
        }
      },
      workExperience: {
        title: 'Pracovní zkušenosti',
        years: {
          title: 'Léta praxe'
        },
        previousRole: {
          title: 'Zkušenosti s rolí'
        }
      },
      skills: {
        title: 'Dovednosti',
        languages: {
          title: 'Jazyky'
        },
        other: {
          title: 'Jiné'
        }
      }
    },
    preferences: {
      title: 'Nabízíme',
      classification: {
        title: 'Zařazení',
        types: {
          title: 'Typ příležitosti'
        },
        roles: {
          title: 'Role'
        },
        specializations: {
          title: 'Specializace'
        },
        industries: {
          title: 'Odvětví'
        },
        locations: {
          title: 'Lokalita'
        }
      },
      benefitsAndGrowth: {
        title: 'Ohodnecní a růst',
        salary: {
          title: 'Měsíční odměna',
          offerLabel: 'Nabízíme'
        },
        benefits: {
          title: 'Benefity',
          unmatchedTitle: 'Jeho další preferované benefity'
        },
        personalDevelopmentAreas: {
          title: 'Možnosti rozvoje',
          unmatchedTitle: 'Navíc preferuje tyto možnosti rozvoje'
        }
      }
    }
  },
  constants: {
    internalText: {
      officeDay: {
        text: 'Vítejte! Vím, že jste u nás {0} a budete mít na starost {1}, ale dnes bych od vás potřeboval pomoci s jinou věcí.',
        descriptionFallback: 'Vítejte! Vím, že jste u nás {0} a určitě se už těšíte, že se pustíte do svých úkolů, ale dnes bych od vás potřeboval pomoci s jinou věcí.'
      }
    },
    positionTypes: {
      INTERNSHIP: {
        label: 'V rámci stáže nebo akademie',
        description: 'Vyberte tuto možnost, pokud vybíráte stážisty nebo v rámci programu rozvoje mladých talentů ve vaší organizaci'
      },
      ADMINISTRATIVE: {
        label: 'Asistentská / Administrativní',
        description: 'Vybíráte na pozici typu osobní asistent/ka, asistent/ka oddělení, sekretářka, back-office ' +
        'pracovník nebo na jinou pozicim jejíž hlavní pracovní náplní je administrativa'
      },
      SALES: {
        label: 'Obchodní',
        description: 'Vybíráte na pozici jako je obchodník, nákupčí, referent prodeje, pracovník zákaznické podpory ' +
        'nebo takové, jejímž primárním zaměřením je obchodní činnost, její podpora či související agendy'
      },
      MARKETING: {
        label: 'Marketingová',
        description: 'Vybíráte na pozici pracovníka v marketingu, ať už se jedná o online či offline zaměření, ' +
        'kreativce nebo správce'
      },
      OTHER: {
        label: 'Jiná',
        description: 'Vybíráte-li na jinou pozici, než jsou výše uvedené'
      }
    },
    enums: {
      EActivityStatus: {
        REGISTERED: {
          name: 'Nezapočato'
        },
        IN_PROGRESS: {
          name: 'Probíhá'
        },
        FINISHED: {
          name: 'Dokončeno'
        }
      },
      EApplicationStatus: {
        NONE: {
          name: 'Žádný',
          setStatusLabel: 'Zrušit nastavený status'
        },
        REJECTED: {
          name: 'Odmítnutý',
          setStatusLabel: 'Označit jako odmítnuté'
        },
        ACCEPTED: {
          name: 'Přijatý',
          setStatusLabel: 'Označit jako přijaté'
        },
        IN_PROGRESS: {
          name: 'V procesu',
          setStatusLabel: 'Označit jako v procesu'
        },
        FUTURE_FIT: {
          name: 'Do budoucna',
          setStatusLabel: 'Označit jako do budoucna'
        },
        WITHDRAWN: {
          name: 'Odstoupil/a',
          setStatusLabel: 'Označit jako odstoupeno'
        },
        OFFER_REJECTED: {
          name: 'Nabídka nepřijatá',
          setStatusLabel: 'Označit jako nepřijatou nabídku'
        }
      },
      EEducation: {
        VOCATIONAL: {
          label: 'Střední škola – výuční list',
          shortLabel: 'Výuční list'
        },
        SECONDARY: {
          label: 'Střední škola – maturita',
          shortLabel: 'Maturita'
        },
        POST_SECONDARY: {
          label: 'Vyšší odborná škola – DiS.',
          shortLabel: 'DiS.'
        },
        UNDERGRADUATE: {
          label: 'Vysoká škola – Bc.',
          shortLabel: 'Bc.'
        },
        GRADUATE: {
          label: 'Vysoká škola – Mgr., Ing.',
          shortLabel: 'Mgr./Ing.'
        },
        POST_GRADUATE: {
          label: 'Vysoká škola – doktorský titul',
          shortLabel: 'Doktorský titul'
        },
        PROFESSIONAL: {
          label: 'Profesní titul – MBA, LL.M',
          shortLabel: 'MBA, LL.M'
        }
      },
      EWorkExperienceYears: {
        ZERO: {
          label: 'Bez pracovní zkušenosti',
          shortLabel: '0'
        },
        ONE: {
          label: 'Méně než rok',
          shortLabel: '<1 rok'
        },
        THREE: {
          label: '1 – 3 roky',
          shortLabel: '1–3 roky'
        },
        TEN: {
          label: '4 – 10 let',
          shortLabel: '4–10 let'
        },
        MORE: {
          label: 'Více než 10 let',
          shortLabel: '>10 let'
        }
      },
      ESeekerStatus: {
        ACTIVE: {
          label: 'Aktivní'
        },
        OPEN: {
          label: 'Otevřený'
        },
        TENTATIVE: {
          label: 'Rozhlíží se'
        },
        INACTIVE: {
          label: 'Nehledá'
        }
      },
      EWorkExperiencePosition: {
        NO_SPECIALIZATION: 'Bez specializace',
        ADMINISTRATIVE: 'Administrativní pozice',
        SALES: 'Obchodní pozice',
        EXPERT: 'Specialista',
        ENTREPRENEURIAL: 'Podnikání',
        LINE_MANAGEMENT: 'Nižší management',
        MIDDLE_MANAGEMENT: 'Střední management',
        TOP_MANAGEMENT: 'Top management'
      },
      EFieldOfStudyCategory: {
        ARTS: 'Umění',
        HUMANITIES: 'Humanitní vědy',
        SOCIAL_SCIENCES: 'Sociální vědy',
        NATURAL_SCIENCES: 'Přírodní vědy',
        APPLIED_SCIENCES: 'Aplikovaná studia'
      },
      EFieldOfStudy: {
        GENERAL: 'Obecné',
        SERVICES: 'Služby',
        BUSINESS_TRADE: 'Obchod',
        TECHNICAL: 'Řemeslo',
        OTHER: 'Jiné',
        PERFORMING_ARTS: 'Múzická umění',
        VISUAL_ARTS: 'Výtvarná umění',
        GEOGRAPHY: 'Zeměpis',
        HISTORY: 'Historie',
        LANGUAGES: 'Jazyky',
        EDUCATION: 'Vzdělávání',
        PHILOSOPHY: 'Filozofie',
        THEOLOGY: 'Teologie',
        ECONOMICS: 'Ekonomika / podnikání',
        LAW: 'Právo',
        POLITICAL_SCIENCE: 'Politologie',
        PSYCHOLOGY: 'Psychologie',
        SOCIOLOGY: 'Sociologie',
        BIOLOGY: 'Biologie',
        CHEMISTRY: 'Chemie',
        MATHEMATICS: 'Matematika',
        PHYSICS: 'Fyzika',
        AGRICULTURE: 'Zemědělství',
        COMPUTER_SCIENCE: 'Výpočetní technika',
        ENGINEERING: 'Strojírenství a technologie',
        MEDICINE: 'Medicínské a zdravotní vědy'
      },
      EParticipantReport: {
        NONE: {
          label: 'Žádný',
          description: 'Účastník nedostane report'
        },
        STANDARD: {
          label: 'Základní',
          description: 'Účastník dostane základní interpretace'
        },
        ENHANCED: {
          label: 'Rozšířený',
          description: 'Účastník dostane interpretace s doporučeními a graf kompetencí'
        },
        FULL: {
          label: 'Plný',
          description: 'Účastník dostane interpretace s doporučeními a plný graf kompetencí'
        }
      },
      EParticipantReportSimple: {
        NO: {
          label: 'Ne',
          description: 'Účastník nedostane report'
        },
        YES: {
          label: 'Ano',
          description: 'Účastník dostane report s interpretacemi a grafem kompetencí'
        }
      },
      EPoolEndpoint: {
        NONE: {
          label: 'Ne',
          description: 'Projekt nebude sloužit jako zdroj, ani jako cíl profilů pro talent pool'
        },
        SOURCE: {
          label: 'Zdroj',
          description: 'Projekt poskytne profily do talent poolu'
        },
        DESTINATION: {
          label: 'Cíl',
          description: 'Projekt je cílem pro profily z talent poolu'
        }
      },
      EActivityEventType: {
        IMMEDIATE: {
          label: 'Okamžitě',
          description: 'Okamžitě po registraci k aktuálnímu projektu'
        },
        COMPLETED: {
          label: 'Po dokončení',
          description: 'Po dokončení aktivity v rámci aktuálního projektu'
        }
      },
      EActivityTypeVariant: {
        CULTURE_FIT: {
          CANDIDATE: { label: 'Preferenční' },
          MANAGER: { label: 'Vnímaný - Manažer' },
          EMPLOYEE: { label: 'Vnímaný - Zaměstnanec' }
        }
      },
      EBool: {
        YES: {
          label: 'Ano'
        },
        NO: {
          label: 'Ne'
        }
      },
      EOpportunityType: {
        JOB: {
          label: 'Pracovní pozice',
          editDescription: 'Čerstvě i dlouhodobě otevřená pracovní místa, která chcete obsadit'
        },
        EVENT: {
          label: 'Událost'
        },
        EBOOK: {
          label: 'E-kniha'
        },
        VIDEO: {
          label: 'Video'
        },
        COACH: {
          label: 'Trenér'
        },
        ONLINE_COURSE: {
          label: 'Online kurz'
        },
        TALENT_ACADEMY: {
          label: 'Talentová akademie',
          editDescription: 'Trainee programy nebo stáže pro studenty a absolventy'
        },
        BOOK: {
          label: 'Kniha'
        },
        ARTICLE: {
          label: 'Článek'
        }
      },
      ELanguageLevel: {
        CONVERSANT: {
          label: 'Základní'
        },
        PROFICIENT: {
          label: 'Pokročilý'
        },
        FLUENT: {
          label: 'Profesionální'
        },
        NATIVE: {
          label: 'Rodilý mluvčí'
        }
      },
      ERank: {
        LOW: { label: 'Nízká úroveň' },
        MID_LOW: { label: 'Nízká úroveň' },
        MID: { label: 'Střední úroveň' },
        MID_HIGH: { label: 'Vysoká úroveň' },
        HIGH: { label: 'Vysoká úroveň' },
        NEUTRAL: { label: 'Neutrální úroveň' },
        LOCKED: { label: '?' }
      },
      ECompanyRejectReason: {
        NONE: { label: 'Bez udání důvodu', description: "Když nechcete uvést důvod" },
        FILLED: { label: 'Již obsazeno', description: "Pokud jste již pozici obsadili" },
        OVERFLOW: { label: 'Příliš mnoho kandidátů', description: 'Pokud již máte dostatek rozjednaných uchazečů' },
        BAD_FIT: { label: 'Nesplněná kritéria', description: 'Protože nesplňuje vaše kritéria' },
        CUSTOM: { label: 'Jiný důvod', description: 'Napište vlastní text' }
      },
      EProjectType: {
        POSITION: {
          label: 'Výběrové řízení'
        },
        GROUP_ASSESSMENT: {
          label: 'Assessment skupiny'
        },
        TALENT_PROGRAM: {
          label: 'Talent program'
        },
        VIEW: {
          label: 'Seskupení'
        },
        QUESTIONNAIRE: {
          label: 'Dotazník'
        }
      },
      EProjectState: {
        ACTIVE: {
          label: 'Aktivní'
        },
        SCHEDULED: {
          label: 'Plánováno'
        },
        FINISHED: {
          label: 'Ukončené'
        },
        CONCEPT: {
          label: 'Koncepty'
        }
      },
      EProjectFilterSort: {
        NAME: {
          label: 'Název'
        },
        MODIFIED_AT: {
          label: 'Poslední modifikace'
        },
        LATEST_PROFILE_ADDED_AT: {
          label: 'Nejnovější profil'
        }
      },
      ECompanyType: {
        STARTUP: { label: 'Startup' },
        ENTERPRISE: { label: 'Velký podnik' },
        FAMILY_OFFICE: { label: 'Rodinná firma' },
        GLOBAL_ENTERPRISE: { label: 'Globální podnik' },
        SME: { label: 'Malý a střední podnik' },
        MICRO_COMPANY: { label: 'Mikro společnost' },
        NNO: { label: 'Nestátní nezisková organizace' },
        GOVERNMENT: { label: 'Státní organizace' },
        NGO: { label: 'Nestátní organizace' },
        TEAM: { label: 'Tým' },
        ASSOCIATION: { label: 'Spolek' }
      },
      EOpportunityParticipantStatus: {
        UNPROCESSED: {
          label: 'Čekající'
        },
        WAITING: {
          label: 'Čekáme na potvrzení'
        },
        CONNECTED: {
          label: 'Ve spojení'
        },
        REJECTED: {
          label: 'Odmítnuto'
        }
      },
      EPersonalDetailsExtent: {
        NONE: {
          label: 'Žádný',
          description: 'Žádné osobní údaje - může způsobit problémy u simulací'
        },
        MINIMAL: {
          label: 'Minimální',
          description: 'Pouze jméno a příjmení'
        },
        LIGHT: {
          label: 'Základní',
          description: 'Jméno, příjmení, oslovení v simulaci'
        },
        FULL: {
          label: 'Plný',
          description: 'Základní bio, vzdělání, pracovní zkušenosti a dovednosti'
        }
      },
      EGroupType: {
        TEAM: {
          label: 'Tým'
        },
        GROUP: {
          label: 'Skupina'
        },
        HIRING: {
          label: 'Otevřená pozice'
        }
      }
    },
    charts: {
      labels: {
        dual: {
          DISCRETE_FOUR: mirrorReversed(['Spíše', 'Výrazně'])
        }
      }
    }
  },
  smartsupp: {
    offline: {
      title: 'Napište nám zprávu',
      notice: 'Jsme vám k dispozici. Napište nám pomocí formuláře níže.',
      hideTitle: 'Skrýt',
      required: '',
      name: 'Jméno',
      email: 'E-mail',
      number: 'Telefon',
      message: 'Vaše zpráva',
      submit: 'Odeslat zprávu',
      messageRequiredAlert: 'Je třeba vyplnit tělo zprávy',
      emailRequiredAlert: 'Je třeba vyplnit e-mailovou adresu',
      emailInvalidAlert: 'Zadaná e-mailová adresa není platná',
      sendInfo: 'Odesílání zprávy...',
      successTitle: 'Děkujeme',
      successDesc: 'Vyše zpráva byla úspěšně odeslána. Budeme vás kontaktovat',
      failureTitle: 'Chyba',
      failureDesc: 'Je nám líto, ale zpráva nebyla odeslána'
    },
    widget: {
      offline: 'Napište nám zprávu'
    }
  }
};
