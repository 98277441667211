import { handleActions } from 'redux-actions';

import {
  GROUP_GET,
  GROUP_CREATE,
  GROUP_UPDATE,
  GROUP_CURRENT_UNSET,
  GROUP_TARGET_GET,
  GROUP_TARGET_UNSET,
  GROUP_SUBGROUP_SORT_SET,
  GROUP_SOURCES_GET
} from '../../constants/constants';
import { getObject, putObject } from '../../persistence/storage';

const defaultState = {
  current: null,
  target: null,
  loading: false,
  sort: {},
  sources: []
};

const handleUpdateCurrent = (state, { payload: { payload } }) => {
  const newId = payload?.id;
  let sort = state.sort;
  if (newId && state.current?.id !== newId) {
    sort = getObject(`groups/reducer/sort/${newId}`) || {};
  }
  return {
    ...state,
    loading: false,
    changing: false,
    current: payload,
    sort
  };
};

export default handleActions(
  {
    [GROUP_GET.PENDING]: (state, { meta: { id } }) => ({ ...state, loading: true, changing: state.current?.id !== id }),
    [GROUP_GET.SUCCESS]: handleUpdateCurrent,
    [GROUP_GET.ERROR]: state => ({ ...state, loading: false, error: true }),
    [GROUP_UPDATE.SUCCESS]: handleUpdateCurrent,
    [GROUP_CREATE.SUCCESS]: handleUpdateCurrent,
    [GROUP_CURRENT_UNSET]: () => defaultState,
    [GROUP_TARGET_GET.PENDING]: state => ({ ...state, targetLoading: true }),
    [GROUP_TARGET_GET.ERROR]: state => ({ ...state, targetLoading: false, targetError: true }),
    [GROUP_TARGET_GET.SUCCESS]: (state, { payload }) => ({
      ...state,
      target: payload,
      targetLoading: false,
      targetError: false
    }),
    [GROUP_TARGET_UNSET]: state => ({ ...state, target: null, targetLoading: false, targetError: false }),
    [GROUP_SUBGROUP_SORT_SET]: (state, { payload: { type, sort } }) => {
      const prevSort = state.sort;
      const newSort = { ...prevSort, [type.key]: sort };
      putObject(`groups/reducer/sort/${state.current.id}`, newSort);
      return { ...state, sort: newSort };
    },
    [GROUP_SOURCES_GET.PENDING]: state => ({ ...state, sourcesLoading: true }),
    [GROUP_SOURCES_GET.ERROR]: state => ({ ...state, sourcesLoading: false, sourcesError: true }),
    [GROUP_SOURCES_GET.SUCCESS]: (state, { payload: { items } }) => ({
      ...state,
      sources: items,
      sourcesLoading: false,
      sourcesError: false
    })
  },
  defaultState
);
