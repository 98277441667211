import React from 'react';

import withIntl from '../withIntl';

const renderRecommendation = (r, title, icon, variant) => {
  return (
    <div className="metric-recommendation">
      <div className={'mui-label'}>
        {icon && <i className={`material-icons text-${variant}`}>{icon}</i>}
        <span style={icon ? {} : { marginLeft: '2px' }}>{title}</span>
      </div>
      <p dangerouslySetInnerHTML={{ __html: r }} />
    </div>
  );
};

const MetricRecommendations = props => {
  const {
    application,
    development,
    obstacle,
    derisking,
    questions,
    intl: { messages }
  } = props;
  if (!application && !development && !obstacle && !derisking && !questions) {
    return null;
  }
  const m = messages;
  return (
    <div className="metric-recommendations">
      {application && renderRecommendation(application, m.applicationTitle, 'play_arrow', 'success')}
      {development && renderRecommendation(development, m.developmentTitle, 'eject', 'info')}
      {obstacle && renderRecommendation(obstacle, m.obstacleTitle, 'stop', 'warning')}
      {derisking && renderRecommendation(derisking, m.deriskingTitle)}
      {questions && renderRecommendation(questions, m.questionsTitle)}
    </div>
  );
};

const intlMessages = {
  cs: {
    applicationTitle: 'Kdy je to výhoda',
    developmentTitle: 'Doporučení pro rozvoj',
    obstacleTitle: 'Mohlo by jej brzdit',
    deriskingTitle: 'Doporučení',
    questionsTitle: 'Ptejte se'
  },
  sk: {
    applicationTitle: 'Kedy je to výhoda',
    developmentTitle: 'Odporúčanie na rozvoj',
    obstacleTitle: 'Mohlo by ho brzdiť',
    deriskingTitle: 'Odporúčanie',
    questionsTitle: 'Pýtajte sa'
  },
  en: {
    applicationTitle: "When it's advantage",
    developmentTitle: 'How to develop',
    obstacleTitle: 'Might hold them back',
    deriskingTitle: 'Recommendation',
    questionsTitle: 'What to ask'
  }
};

export default withIntl(MetricRecommendations, intlMessages);
