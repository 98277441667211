import React from 'react';
import PropTypes from 'prop-types';

import PublicBase from './PublicBase';
import AccountNewForm from '../../forms/AccountNew';
import LoginRedirectFooter from './LoginRedirectFooter';
import TalentMarketPlanSelect from '../../plan/talentMarket/TalentMarketPlanSelect';
import Collapse from '../../basic/Collapse';
import Spinner from '../../spinner/Spinner';

const AccountNew = props => {
  const {
    intl,
    loading,
    fields,
    plans,
    plan,
    selectingPlan,
    currencies,
    currency,
    defaultCurrency,
    billingPeriod
  } = props;
  const {
    updateLocale,
    createNewAccount,
    onPlanSelect,
    onPlanSelectStart,
    onCurrencySelect,
    onBillingPeriodSelect
  } = props;
  const plansSelectable = plans?.length > 0;
  return (
    <PublicBase
      {...{
        intl,
        updateLocale,
        colMaxWidth: selectingPlan && plansSelectable ? 1200 : 600,
        style: { width: '100%', transition: '0.2s all' }
      }}
      footer={<LoginRedirectFooter intl={intl} />}
    >
      {loading ? (
        <div style={{ padding: '96px 16px' }} className="text-center">
          <Spinner relative show />
        </div>
      ) : (
        <>
          {plansSelectable && (
            <TalentMarketPlanSelect
              plans={plans}
              currencies={currencies}
              plan={plan}
              selecting={selectingPlan}
              currency={currency}
              defaultCurrency={defaultCurrency}
              billingPeriod={billingPeriod}
              onPlanSelect={onPlanSelect}
              onPlanSelectStart={onPlanSelectStart}
              onCurrencySelect={onCurrencySelect}
              onBillingPeriodSelect={onBillingPeriodSelect}
            />
          )}
          <Collapse isOpened={!selectingPlan || !plansSelectable}>
            <AccountNewForm {...{ intl, fields, createNewAccount }} />
          </Collapse>
        </>
      )}
    </PublicBase>
  );
};

AccountNew.propTypes = {
  intl: PropTypes.object.isRequired,
  fields: PropTypes.object,
  //
  updateLocale: PropTypes.func.isRequired,
  createNewAccount: PropTypes.func.isRequired
};

export default AccountNew;
