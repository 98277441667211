import React, { useCallback, useRef, useState } from 'react';

const InlineInput = props => {
  const {
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    value,
    helperText,
    style,
    placeholder,
    borderClassName,
    ...inputProps
  } = props;
  const [val, setVal] = useState(value || '');
  const [focused, setFocused] = useState(false);

  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(e);
      } else {
        setVal(e.target.value);
      }
    },
    [onChange]
  );
  const handleBlur = useCallback(
    e => {
      if (onBlur) {
        onBlur(e);
      }
      setFocused(false);
    },
    [onBlur]
  );
  const inputRef = useRef(null);
  const handleFocus = useCallback(
    e => {
      if (onFocus) {
        onFocus(e);
      }
      setFocused(true);
      inputRef?.current?.focus();
    },
    [inputRef]
  );
  const handleKeyDown = useCallback(
    e => {
      const kc = e.which || e.keyCode;
      if (kc === 13) {
        if (inputRef?.current) {
          inputRef.current.blur();
        }
      }
      if (onKeyDown) {
        onKeyDown(e);
      }
    },
    [inputRef, onKeyDown]
  );

  const inputStyle = focused ? {} : { position: 'absolute', opacity: 0, zIndex: -1 };
  const actualValue = onChange ? value : val;

  return (
    <div className="input-inline" style={{ display: 'inline', position: 'relative' }}>
      <input
        {...inputProps}
        style={{ ...style, ...inputStyle }}
        ref={inputRef}
        placeholder={placeholder}
        value={actualValue || ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      {focused ? (
        <div className={`input-inline__border${borderClassName ? ` ${borderClassName}` : ''}`} />
      ) : (
        <span role="button" onClick={handleFocus}>
          {actualValue ? actualValue : <i>{placeholder}</i>}
        </span>
      )}
      {helperText && (
        <div
          className="input-inline__helper-text text-muted text-sz-xs"
          style={{ bottom: focused ? '-20px' : '-14px' }}
        >
          {helperText}
        </div>
      )}
    </div>
  );
};

export default InlineInput;
