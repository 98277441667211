import { handleActions } from 'redux-actions';
import { COMPANY_TALENT_POOLS_GET } from '../../constants/constants';

const initialState = {
  companyPools: {
    companyId: null,
    items: []
  }
};

export default handleActions(
  {
    [COMPANY_TALENT_POOLS_GET.SUCCESS]: (state, { payload: { items }, meta: { companyId } }) => ({
      ...state,
      companyPools: {
        companyId,
        items
      }
    })
  },
  initialState
);
