import React, { Component } from 'react';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';

import ApplicantComparePage from '../../../components/pages/private/position/ApplicantCompare';
import BaseContainer from '../../../components/basic/BaseContainer';
import allActions from './../../../actions';
import withRouter from 'react-router/es/withRouter';
import { MAX_APPLICANTS_COMPARED } from '../../../constants/constants';
import { selectComparedParticipants } from '../../../selectors/intraComparison';

class ApplicantCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participantMenuShown: false
    };
  }

  componentWillMount() {
    this.loadData(this.props);
  }

  componentDidUpdate(prevProps) {
    const oldParams = prevProps.params;
    const oldIntl = prevProps.intl;
    const { params: { companySlug, projectSlug }, intl: { locale } } = this.props;
    if (companySlug !== oldParams.companySlug || projectSlug !== oldParams.projectSlug || locale !== oldIntl.locale) {
      this.loadData(this.props, true);
    }
  }

  loadData = (props, forceLoad) => {
    const {
      params: { companySlug, projectSlug },
      intl: { locale },
      location: { query: { use_cache } },
      getResults
    } = props;

    getResults(companySlug, projectSlug, locale, { interpretations: true }, forceLoad || !use_cache);
  };

  removeFromSelection = p => {
    const { router, location, comparison: { project } } = this.props;
    const { query: { ids, ...restOfQuery } } = location;
    const idsNorm = isArray(ids) ? ids : [ids];
    const identifier = p.projectId === project.id ? p.participant.id : `${p.participant.id}/${p.projectId}`;
    const newIds = filter(idsNorm, i => i !== identifier);
    router.push({ ...location, query: { ...restOfQuery, ids: newIds } });
  };

  addToSelection = p => {
    const { router, location, comparison: { project } } = this.props;
    const { query: { ids, ...restOfQuery } } = location;
    const idsNorm = isArray(ids) ? ids : [ids];
    const identifier = p.projectId === project.id ? p.participant.id : `${p.participant.id}/${p.projectId}`;
    const newIds = idsNorm.indexOf(identifier) >= 0 ? idsNorm : [...idsNorm, identifier];
    router.push({ ...location, query: { ...restOfQuery, ids: newIds } });
  };

  setParticipantMenuShown = value => {
    this.setState({ participantMenuShown: value });
  };

  render() {
    const { intl, comparison, metricDefinitions } = this.props;
    const pageProps = {
      intl: intl,
      comparison,
      metricDefinitions,
      participantMenuShown: this.state.participantMenuShown,
      addDisabled: comparison.compared.length >= MAX_APPLICANTS_COMPARED,
      handleRemoveFromSelection: this.removeFromSelection,
      handleAddToSelection: this.addToSelection,
      handleSetParticipantMenuShown: this.setParticipantMenuShown
    };
    return (
      <BaseContainer>
        <ApplicantComparePage {...pageProps} />
      </BaseContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { options: { modal, overviewChart }, entities: { applicants }, intl, auth } = state;

  return {
    modal,
    overviewChart,
    metricDefinitions: applicants.metricDefinitions,
    comparison: selectComparedParticipants(state, props),
    intl,
    auth
  };
};

export default connect(mapStateToProps, allActions)(withRouter(ApplicantCompare));
