import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

const cardSource = {
  beginDrag({ id, position, size }) {
    return { id, position, size };
  },
  endDrag(props, monitor, component) {
    if (props.handleEndDrag) {
      props.handleEndDrag(props, monitor, component);
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

class Draggable extends Component {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true,
    });
  }

  render() {
    const { children, style, isDragging, connectDragSource } = this.props;
    return connectDragSource(
      <div style={{ ...style, opacity: isDragging ? 0.5 : 1 }}>
        {children}
      </div>
    );
  }
}

Draggable.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any,
  style: PropTypes.object,
  // Injected by React DnD:
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired
};

export default DragSource('DRAGGABLE', cardSource, collect)(Draggable);
