import React from 'react';

const MatchContainer = props => {
  const { title, children } = props;
  return (
    <div className="match-container">
      <div className="mui-label">{title}</div>
      <div style={{ margin: '-2px' }}>{children}</div>
    </div>
  );
};

export default MatchContainer;
