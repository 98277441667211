import reduce from 'lodash/reduce';

function accessValue(val, accessor) {
  if (!accessor) {
    return val;
  }
  return accessor(val);
}

export function average(data, dataValueAccessor) {
  return reduce(data, (res, val) => res + accessValue(val, dataValueAccessor), 0) / data.length;
}

function stdCommon(data, sample, avg, dataValueAccessor) {
  const actualAvg = avg != undefined ? avg : average(data, dataValueAccessor);

  const variance = (
    reduce(data, (res, val) => res + Math.pow(accessValue(val, dataValueAccessor) - actualAvg, 2), 0) /
    (data.length - (sample ? 1 : 0))
  );
  return Math.sqrt(variance);
}

export function sampleStd(data, avg, dataValueAccessor) {
  if (data.length === 1) {
    return 0;
  }
  return stdCommon(data, true, avg, dataValueAccessor);
}
