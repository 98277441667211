import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isValid, isDirty, getFormValues, getFormSyncErrors, submit, touch } from 'redux-form';
import _map from 'lodash/map';

import BasicPart from './parts/BasicPart';
import MediaPart from './parts/MediaPart';
import CulturePart from './parts/CulturePart';
import BenefitsPart from './parts/BenefitsPart';
import DataSourcesLoader from '../../../containers/common/DataSourcesLoader';
import BaseContainer from '../../basic/BaseContainer';
import FormPartContainer from '../../forms/parts/FormPartContainer';

import * as companyActions from '../../../actions/entities/companyActions';
import { selectCompanyProfileToEdit } from '../../../selectors/companyProfileBuilder';
import { valuesToValueHolders, cultureFromValues } from '../../forms/FormUtils';
import { isCompanyProfilePublishDisabled } from '../../../utils/talentMarketPlan';
import CompanyProfileDisabledMessage from './CompanyProfileDisabledMessage';

const messages = {
  basic: {
    title: 'Základní údaje o vaší společnosti',
    tabTitle: 'Základní údaje'
  },
  media: {
    title: 'Vaše logo, fotky a příběh',
    tabTitle: 'Logo, fotky a příběh'
  },
  culture: {
    title: 'Vaše kultura a hodnoty',
    tabTitle: 'Kultura a hodnoty'
  },
  benefits: {
    title: 'Benefity a rozvoj'
  }
};

class CompanyProfileForm extends React.Component {
  componentDidMount() {
    this.loadProfile(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.loadProfile(this.props);
    }
  }

  loadProfile = props => {
    const { getCompanyProfile, getCompanyTalentMarketPlan, intl: { locale }, unsetCompanyProfile, companyId } = props;
    if (companyId) {
      getCompanyProfile(companyId);
      getCompanyTalentMarketPlan(companyId, locale);
    } else {
      unsetCompanyProfile();
    }
  };

  handleSubmit = (values, publish) => {
    const { onSubmit, companyId, profile, saveCompanyProfile } = this.props;
    onSubmit(values);
    const newCulture = cultureFromValues(values);
    const newProfile = {
      ...profile,
      name: values.name,
      logoUrl: values.logoUrl,
      headerImageUrl: values.headerImageUrl,
      industries: valuesToValueHolders(values.industries),
      type: values.type,
      employeeSize: values.employeeSize,
      description: values.description,
      locations: values.hqAddress ? [{ value: values.hqAddress }] : null,
      moments: values.moments ? _map(values.moments, m => ({ url: m })) : null,
      websiteUrl: values.websiteUrl,
      benefits: valuesToValueHolders(values.benefits),
      personalDevelopmentAreas: valuesToValueHolders(values.personalDevelopmentAreas),
      culture: profile.culture || newCulture ? { ...profile.culture, ...newCulture } : null,
      publish
    };
    saveCompanyProfile(companyId, newProfile).then(() => this.props.router.push('/sourcing/profile'));
  };

  render() {
    const { profile, forms, touch, intl, loading, talentMarketPlan } = this.props;

    const publishDisabled = isCompanyProfilePublishDisabled(talentMarketPlan);
    return (
      <div>
        <DataSourcesLoader />
        <BaseContainer>
          <div className="mui-card builder-form" style={{ maxWidth: '768px', margin: '0 auto' }}>
            <FormPartContainer
              isNew={!profile.updatedAt}
              forms={forms}
              touch={touch}
              intl={intl}
              disabled={loading}
              publish
              publishDisabled={publishDisabled}
              publishTooltip={publishDisabled ? <CompanyProfileDisabledMessage /> : null}
              savedAt={profile.updatedAt}
              onSubmit={this.handleSubmit}
              parts={[
                { title: messages.basic.title, tabTitle: messages.basic.tabTitle, render: () => <BasicPart /> },
                { title: messages.media.title, tabTitle: messages.media.tabTitle, render: () => <MediaPart /> },
                { title: messages.culture.title, tabTitle: messages.culture.tabTitle, render: () => <CulturePart /> },
                { title: messages.benefits.title, tabTitle: messages.benefits.tabTitle, render: () => <BenefitsPart /> }
              ]}
            />
          </div>
        </BaseContainer>
      </div>
    );
  }
}

const FORMS = [BasicPart.ID, MediaPart.ID, CulturePart.ID, BenefitsPart.ID];

const mapStateToProps = (state, ownProps) => {
  const { intl, entities: { currentCompany: { talentMarketPlan } } } = state;
  const { companyId } = ownProps.params || {};
  return {
    intl,
    companyId,
    profile: selectCompanyProfileToEdit(state, ownProps),
    talentMarketPlan,
    forms: _map(FORMS, form => ({
      name: form,
      valid: isValid(form)(state),
      values: getFormValues(form)(state),
      errors: getFormSyncErrors(form)(state),
      dirty: isDirty(form)(state)
    }))
  };
};

const actions = { ...companyActions, onSubmit: submit, touch };

export default withRouter(connect(mapStateToProps, actions)(CompanyProfileForm));
