import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import ApplicantListPage from '../../../components/pages/private/position/ApplicantList';
import { ESortOrder, EProjectState, EContractPlanMode } from '../../../constants/enum';
import { MODAL_CONFIRM_ACTIVATE, MODAL_PARTICIPANT_BATCH_OPERATION } from '../../../constants/constants';
import allActions from '../../../actions';
import {
  getFilteredAndPaginatedParticipants,
  selectProjectsForCrossComparison,
  selectParticipantSelection
} from '../../../selectors/applicantList';
import { selectParticipants } from '../../../selectors/index';
import { selectAccountPermissions } from '../../../selectors/auth';
import { getProjectsBasePath, getProjectActivityMatcher, toggleListSelection } from '../../../utils/utils';

class ApplicantList extends Component {
  // TODO not up-to-date
  static propTypes = {
    getApplicants: PropTypes.func,
    setGridOptions: PropTypes.func,
    inviteApplicantsModal: PropTypes.func,
    switchModal: PropTypes.func,
    setSortedOverviewChart: PropTypes.func,
    setActiveOverviewChart: PropTypes.func,
    setWindowOverviewChart: PropTypes.func,
    //
    getCompanies: PropTypes.func,
    getProjectsAll: PropTypes.func.isRequired,
    invokeUpgradeAccountModal: PropTypes.func,
    //
    overviewChart: PropTypes.object,
    auth: PropTypes.object,
    items: PropTypes.array,
    applicants: PropTypes.array,
    page: PropTypes.object,
    intl: PropTypes.object,
    params: PropTypes.object,
    router: PropTypes.object
  };

  state = {
    addingToGroups: false,
    tagsAssignmentOpen: false
  };

  componentDidUpdate() {
    const { filteredApplicants, setUserProjectsAcks } = this.props;
    const unackedApplicants = filter(
      filteredApplicants,
      a => a.status && a.status !== 'FINISHED' && !a.ackSent && (a.ack === null || a.ack.status !== a.status)
    );
    const acks = map(unackedApplicants, a => ({
      userId: a.participant.id,
      projectId: a.projectId,
      status: a.status,
      statusTimestamp: a.statusTimestamp
    }));
    if (acks.length) {
      setUserProjectsAcks(acks);
    }
  }

  getBasePath = () => {
    const {
      location: { pathname }
    } = this.props;
    return getProjectsBasePath(pathname);
  };

  onSort = (event, dir, newOrder) => {
    const {
      params: { companySlug, projectSlug },
      intl: { locale },
      getResults,
      setGridOptions,
      gridOptions
    } = this.props;

    const customOptions = { ...gridOptions };
    customOptions.sort = dir;
    customOptions.order = newOrder === ESortOrder.DESC ? ESortOrder.ASC : ESortOrder.DESC;

    const { sort, order } = customOptions;

    setGridOptions('applicantsGrid', projectSlug, { sort, order });

    getResults(companySlug, projectSlug, locale, customOptions);
  };

  onPageSize = size => {
    const {
      params: { companySlug, projectSlug },
      intl: { locale },
      getResults,
      setGridOptions,
      gridOptions
    } = this.props;

    const customOptions = { ...gridOptions };
    customOptions.pageSize = size;
    customOptions.pageNumber = 0;

    const { pageSize, pageNumber } = customOptions;

    setGridOptions('applicantsGrid', projectSlug, { pageNumber, pageSize });

    getResults(companySlug, projectSlug, locale, customOptions);
  };

  onPageNumber = num => {
    const {
      params: { companySlug, projectSlug },
      intl: { locale },
      getResults,
      setGridOptions,
      gridOptions
    } = this.props;

    const customOptions = { ...gridOptions, pageNumber: num };

    setGridOptions('applicantsGrid', projectSlug, { pageNumber: num });

    getResults(companySlug, projectSlug, locale, customOptions);
  };

  onEditProject = (companyId, projectId) => {
    this.props.router.push(`${this.getBasePath()}/${companyId}/${projectId}/edit`);
  };

  // TODO from ProjectOverview
  onActivateProject = project => {
    this.props.switchModal({
      id: MODAL_CONFIRM_ACTIVATE,
      open: true,
      projectName: project.name,
      args: [project]
    });
  };

  getParticipantId = ({ participant: { id }, projectId }) => `${projectId}/${id}`;

  handleToggleApplicantSelection = (p, e) => {
    const createSelectionProps = p => ({ userId: p.participant.id, projectId: p.projectId, ts: Date.now() });
    const {
      params: { projectSlug },
      participantSelection,
      setGridOptions
    } = this.props;
    const newSelection = toggleListSelection(
      participantSelection.map,
      this.props.filteredApplicants,
      p,
      p => this.getParticipantId(p),
      createSelectionProps,
      e?.nativeEvent?.shiftKey
    );
    setGridOptions('applicantsGrid', projectSlug, { selectedApplicants: newSelection });
  };

  handleToggleAllApplicantSelection = () => {
    const {
      params: { projectSlug },
      filteredApplicants,
      participantSelection,
      setGridOptions
    } = this.props;
    const newSelection = {};
    if (participantSelection.counts.total === 0) {
      forEach(filteredApplicants, p => {
        const {
          participant: { id },
          projectId
        } = p;
        newSelection[this.getParticipantId(p)] = {
          projectId,
          userId: id,
          ts: Date.now()
        };
      });
    }
    setGridOptions('applicantsGrid', projectSlug, { selectedApplicants: newSelection });
  };

  handleApplicationStatusChange = ({ participant: { id }, projectId }, applicationStatus) => {
    this.props.setApplicationStatuses([{ userId: id, projectId, applicationStatus }]);
  };

  handleNotifyUnfinishedProfile = participants => {
    this.props.beginUnfinishedProfilesNotification(participants);
  };

  handleStartBatchOperation = (type, participants) => {
    const { project, projectsForCrossComparison, gridOptions } = this.props;
    const { current, other, disabled } = projectsForCrossComparison;
    const activityMatcher = getProjectActivityMatcher(project);
    const filtered = filter(
      [...other, ...disabled, current],
      p => !p.demo && activityMatcher && activityMatcher.matches(p)
    );
    const projects = sortBy(filtered, [
      p => !p.company || !project.company || p.company.id !== project.company.id,
      p => p.company && p.company.name,
      p => p.company && p.company.id,
      p => p.id === project.id,
      p => p.activity.key,
      p => p.activity.variant,
      p => p.activity.version
    ]);
    this.props.switchModal({
      id: MODAL_PARTICIPANT_BATCH_OPERATION,
      type,
      sourceProject: project,
      participants,
      projects,
      gridOptions
    });
  };

  handleSetApplicationStatusForSelected = status => {
    const { participantSelection, setApplicationStatuses } = this.props;
    const values = [];
    for (let participantId in participantSelection.map) {
      if (participantSelection.map.hasOwnProperty(participantId)) {
        const { userId, projectId } = participantSelection.map[participantId];
        values.push({ userId, projectId, applicationStatus: status });
      }
    }
    setApplicationStatuses(values);
  };

  handleCompareApplicants = () => {
    const { router, location, participantSelection, project } = this.props;
    const idsWithTimestamp = [];
    for (let participantId in participantSelection.map) {
      if (participantSelection.map.hasOwnProperty(participantId)) {
        idsWithTimestamp.push({ id: participantId, ...participantSelection.map[participantId] });
      }
    }
    const ids = map(sortBy(idsWithTimestamp, 'ts'), v => {
      if (v.projectId !== project.id) {
        return `${v.userId}/${v.projectId}`;
      }
      return v.userId;
    });
    router.push({
      ...location,
      pathname: `${location.pathname}/participants/compare`,
      query: { ...location.query, ids }
    });
  };

  handleCrossCompareApplicants = targetProject => {
    const {
      participantSelection,
      project,
      allApplicants,
      startCrossComparison,
      selectCrossComparisonProject,
      router
    } = this.props;
    const applicants = filter(allApplicants, p => participantSelection.map[this.getParticipantId(p)]);
    if (targetProject) {
      startCrossComparison(applicants, targetProject, project);
      if (!project || targetProject.id !== project.id) {
        router.push(`${this.getBasePath()}/overview/${targetProject.company.slug}/${targetProject.slug}`);
      }
    } else {
      selectCrossComparisonProject(applicants, project);
    }
    // TODO
    // startCrossComparison(applicants, targetProject, project);
    // if (!project || targetProject.id !== project.id) {
    //   router.push(`${this.getBasePath()}/overview/${targetProject.company.slug}/${targetProject.slug}`);
    // }
  };

  handleAddToGroupsStart = () => {
    this.setState({ addingToGroups: true });
  };
  handleAddToGroupsCancel = () => {
    this.setState({ addingToGroups: false });
  };
  handleAddToGroupsConfirm = () => {
    this.handleAddToGroupsCancel();
    this.props.loadData();
  };

  //
  handleTagsAssignmentStart = () => {
    this.setState({ tagsAssignmentOpen: true });
  };
  handleTagsAssignmentCancel = () => {
    this.setState({ tagsAssignmentOpen: false });
  };

  render() {
    const {
      filteredApplicants,
      metricDefinitions,
      setActiveOverviewChart,
      setSortedOverviewChart,
      setWindowOverviewChart,
      invokeUpgradeAccountModal,
      intl,
      auth,
      gridOptions,
      project,
      projectCrudAllowed,
      onParticipantClick,
      additionalProjects,
      participantSelection,
      groupsEnabled
    } = this.props;
    const { addingToGroups, tagsAssignmentOpen } = this.state;

    const projectState = project && project.validity.state;
    const invitationEnabled = EProjectState.ACTIVE.key === projectState && project && !project.view;

    const pageProps = {
      intl,
      auth,
      invitationEnabled,
      applicants: filteredApplicants,
      metricDefinitions,
      gridOptions,
      project,
      participantSelection,
      projectCrudAllowed,
      additionalProjects,
      // actions
      setActiveOverviewChart,
      setSortedOverviewChart,
      setWindowOverviewChart,
      invokeUpgradeAccountModal,
      groupsSelectOpen: addingToGroups,
      tagsAssignmentOpen,
      onItemClicked: onParticipantClick,
      onSort: this.onSort,
      onPageSize: this.onPageSize,
      onPageNumber: this.onPageNumber,
      onEditProject: this.onEditProject,
      onActivateProject: this.onActivateProject,
      handleToggleApplicantSelection: this.handleToggleApplicantSelection,
      handleToggleAllApplicantSelection: this.handleToggleAllApplicantSelection,
      handleApplicationStatusChange: this.handleApplicationStatusChange,
      handleNotifyUnfinishedProfile: this.handleNotifyUnfinishedProfile,
      handleSetApplicationStatusForSelected: this.handleSetApplicationStatusForSelected,
      handleCompareApplicants: this.handleCompareApplicants,
      handleCrossCompareApplicants: this.handleCrossCompareApplicants,
      handleStartBatchOperation: this.handleStartBatchOperation,
      handleAddToGroupsStart: groupsEnabled ? this.handleAddToGroupsStart : null,
      handleAddToGroupsCancel: this.handleAddToGroupsCancel,
      handleAddToGroupsConfirm: this.handleAddToGroupsConfirm,
      //
      handleTagsAssignmentStart: this.handleTagsAssignmentStart,
      handleTagsAssignmentCancel: this.handleTagsAssignmentCancel
    };

    return <ApplicantListPage {...pageProps} />;
  }
}

const mapStateToProps = (state, props) => {
  const { metricDefinitions, items, additionalActivities } = selectParticipants(state, props);
  const { projects } = additionalActivities || {};
  const auth = selectAccountPermissions(state, props);
  const groupsEnabled = auth.modes[EContractPlanMode.TEAMS.key];
  return {
    groupsEnabled,
    metricDefinitions,
    additionalProjects: projects,
    allApplicants: items,
    filteredApplicants: getFilteredAndPaginatedParticipants(state, props),
    projectsForCrossComparison: selectProjectsForCrossComparison(state, props),
    participantSelection: selectParticipantSelection(state, props)
  };
};

export default withRouter(connect(mapStateToProps, allActions)(ApplicantList));
