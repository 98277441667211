import React from 'react';
import _get from 'lodash/get';
import _map from 'lodash/map';

import MetricInsightPropertyMessage from '../MetricInsightPropertyMessage';
import FitRankLabel from '../FitRankLabel';
import FormatNumber from '../../basic/FormatNumber';

import { EMetricInsightProperty } from '../TeamInsightsUtils';

import withIntl from '../../withIntl';
import { calculateContribution } from '../../../utils/analytics';

const renderByPaths = (metric, paths) => {
  const res = [];
  for (let i = 0; i < paths.length; i += 1) {
    const p = paths[i];
    const val = _get(metric, p);
    if (val) {
      if (p.endsWith('.bullets')) {
        res.push(
          <ul key={p} style={{ paddingLeft: '24px' }}>
            {_map(val || [], (b, idx) => (
              <li key={idx} dangerouslySetInnerHTML={{ __html: b }} />
            ))}
          </ul>
        );
      } else {
        res.push(<div key={p} dangerouslySetInnerHTML={{ __html: val }} />);
      }
    }
  }
  return res;
};

const formatDelta = (d, large) => {
  const virtuallyZero = Math.round(Math.abs(d) * 100) <= 0;
  const iconColorClass = virtuallyZero ? 'text-muted' : d > 0 ? 'text-secondary' : 'text-highlight';
  return (
    <span className={virtuallyZero ? 'text-muted' : ''}>
      <span style={{ verticalAlign: 'middle ' }}>
        {
          <span className={iconColorClass} style={{ fontFamily: 'monospace' }}>
            {d > 0 || virtuallyZero ? '+' : '-'}
          </span>
        }
        <FormatNumber value={Math.abs(d) * 10} fractionDigits={1} />
      </span>
      {!virtuallyZero && (
        <i
          className={`material-icons ${iconColorClass}${large ? ' text-sz-xl' : ' text-sz-reg'}`}
          style={{ verticalAlign: 'middle ' }}
        >
          {d > 0 ? 'north_east' : 'south_east'}
        </i>
      )}
    </span>
  );
};

const formatValue = v => <FormatNumber value={v} fractionDigits={1} />;

const renderDeltaRow = (label, value) => {
  return (
    <div className="container-flex-row">
      <div className="flex1 text-muted" style={{ whiteSpace: 'nowrap' }}>
        {label}
      </div>
      <div className="flex1 mui-padded-horizontal">{formatDelta(value)}</div>
    </div>
  );
};

const renderSpecial = (eInsight, metric, metricStats = {}, metricData = {}, messages) => {
  if (eInsight === EMetricInsightProperty.STATS && metric.type !== 'NUMERIC_DUAL') {
    if (metric.value != null) {
      const popDelta = metric.value - 0.55;
      const avgDelta = metricStats.avg != null ? metric.value - metricStats.avg : null;
      // const medianDelta = metricStats.median != null ? metric.value - metricStats.median : null;
      const contrib =
        metricStats.count > 1 ? calculateContribution(metric.value, metricStats.count, metricStats.avg) : null;
      const stats = (
        <div key="stats">
          <div className="container-flex-row fw-yes" style={{ margin: '-8px' }}>
            <div className="mui-padded flex1">
              <div className="mui-label">STEN</div>
              <div>
                <div className="text-sz-xxl">
                  <span>{metric.formattedValue}</span>
                  <span className="text-muted text-sz-lg">/10</span>
                </div>
              </div>
            </div>
            <div className="mui-padded flex2">
              <div className="mui-label">{messages.contribLabel}</div>
              <div>
                <div className="text-sz-xxl">
                  <span>{contrib != null ? formatDelta(contrib, true) : <em className="text-muted">N/A</em>}</span>
                </div>
              </div>
            </div>
            <div className="mui-padded flex3">
              <div className="mui-label">Delta</div>
              <div>
                <div className="text-sz-reg-sm">
                  {renderDeltaRow('Pop. Ø', popDelta)}
                  {avgDelta != null && renderDeltaRow('Grp. Ø', avgDelta)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      return [stats];
    } else {
      // min, max, avg, median
      return [
        <div key="stats">
          <div className="container-flex-row fw-yes" style={{ margin: '0 -8px -8px' }}>
            <div className="mui-padded flex1">
              <div className="mui-label">{messages.avgLabel} Ø</div>
              <div>
                <div className="text-sz-xxl">
                  <span>{formatValue(metricStats.avg * 10)}</span>
                </div>
              </div>
            </div>
            <div className="mui-padded flex1">
              <div className="mui-label">{messages.medianLabel}</div>
              <div>
                <div className="text-sz-xxl">
                  <span>{formatValue(metricStats.median * 10)}</span>
                </div>
              </div>
            </div>
            <div className="mui-padded flex1">
              <div className="mui-label">{messages.extremesTitle}</div>
              <div>
                <div className="text-sz-reg-sm">
                  <div>
                    <span className="text-muted">Min:</span> {formatValue(metricStats.min * 10)}
                  </div>
                  <div>
                    <span className="text-muted">Max:</span> {formatValue(metricStats.max * 10)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ];
    }
  } else if (eInsight === EMetricInsightProperty.CULTURE_CONVERGENCE) {
    if (metricData.convergence == null) {
      return [];
    }
    return [
      <div key="stats">
        <div className="container-flex-row ai-center">
          <div className="text-sz-xl" style={{ lineHeight: '1.2em' }}>
            <FormatNumber value={metricData.convergence * 100} fractionDigits={0} />
            <small>%</small>
          </div>
          <div className="mui-padded-horizontal">
            <b className="text-uppercase text-sz-reg">
              <FitRankLabel value={metricData.convergence} type="convergence" />
            </b>
          </div>
        </div>
      </div>
    ];
  } else if (eInsight === EMetricInsightProperty.CULTURE_FIT) {
    if (metric.value == null) {
      return [];
    }
    if (metricStats.avg == null) {
      return [
        <div key="cultureFit">
          <div className="text-sz-xl" style={{ lineHeight: '1.2em' }}>
            <em className="text-muted">N/A</em>
          </div>
        </div>
      ];
    }
    const fit = 1 - Math.abs(metric.value - metricStats.avg);
    return [
      <div key="cultureFit">
        <div className="container-flex-row ai-center">
          <div className="text-sz-xl" style={{ lineHeight: '1.2em' }}>
            <FormatNumber value={fit * 100} fractionDigits={0} />
            <small>%</small>
          </div>
          <div className="mui-padded-horizontal">
            <b className="text-uppercase text-sz-reg">
              <FitRankLabel value={fit} />
            </b>
          </div>
        </div>
      </div>
    ];
  }
  return [];
};

const Insight = props => {
  const {
    insightKey,
    metric,
    metricStats,
    metricData,
    intl: { messages }
  } = props;
  const key = insightKey;
  const eInsight = EMetricInsightProperty[key];
  const paths = eInsight.paths || (eInsight.path ? [eInsight.path] : []);
  const res =
    paths.length > 0
      ? renderByPaths(metric, paths)
      : renderSpecial(eInsight, metric, metricStats, metricData, messages);
  if (res.length > 0) {
    return (
      <div key={key} className="team__member-activity-insights-metric-insight mui-appear">
        {eInsight !== EMetricInsightProperty.STATS && (
          <div className="mui-fields-label">
            <MetricInsightPropertyMessage property={key} type="title" />
          </div>
        )}
        <div className="text-sz-reg-sm">{res}</div>
      </div>
    );
  }
  return null;
};

const intlMessages = {
  cs: {
    contribLabel: 'Přínos',
    avgLabel: 'Průměr',
    medianLabel: 'Median',
    extremesTitle: 'Extrémy'
  },
  sk: {
    contribLabel: 'Prínos',
    avgLabel: 'Priemer',
    medianLabel: 'Medián',
    extremesTitle: 'Extrémy'
  },
  en: {
    contribLabel: 'Contrib',
    avgLabel: 'Avg',
    medianLabel: 'Median',
    extremesTitle: 'Extremes'
  }
};

export default withIntl(Insight, intlMessages);
