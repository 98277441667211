import React from 'react';
import _map from 'lodash/map';

import Button from '../mui/Button';
import ButtonToggle from '../mui/ButtonToggle';

import withIntl from '../../components/withIntl';

import { EGroupType } from '../../constants/enum';

const Select = props => {
  const { type, active, children, onSelect } = props;
  return (
    <ButtonToggle active={active} onToggle={() => onSelect(type)}>
      <div className="mui-padded-x2 text-center">{children}</div>
    </ButtonToggle>
  );
};

const TYPES = [EGroupType.TEAM, EGroupType.GROUP, EGroupType.HIRING];

class GroupTypeSelect extends React.Component {
  state = {
    type: null
  };

  componentDidMount() {
    this.updateTypeFromProps();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.type !== this.props.type) {
      this.updateTypeFromProps();
    }
  }

  updateTypeFromProps = () => {
    const { type } = this.props;
    this.setState({ type });
  };

  handleSelectType = type => this.setState({ type });

  handleConfirm = () => {
    const { type } = this.state;
    const { onSelect } = this.props;
    onSelect(type);
  };

  render() {
    const { type } = this.state;
    const {
      change,
      intl: { messages }
    } = this.props;
    const { typeMessages } = messages;
    return (
      <div>
        <div className="container-flex-row fw-yes mui-padded">
          {_map(TYPES, t => {
            const msg = typeMessages[t.key];
            return (
              <div
                key={t.key}
                className="container-flex-row flex1 mui-padded"
                style={{ flexBasis: '33%', minWidth: '200px' }}
              >
                <Select type={t} active={type === t} onSelect={this.handleSelectType}>
                  {t.icon && (
                    <div>
                      {t.icon.type === 'mdi' ? (
                        <span className={`mdi mdi-${t.icon.value}`} style={{ fontSize: '32px' }} />
                      ) : (
                        <i className="material-icons" style={{ fontSize: '32px' }}>
                          {t.icon.value}
                        </i>
                      )}
                    </div>
                  )}
                  <h5 style={{ margin: '8px 0' }}>{msg.title}</h5>
                  <div className="text-muted">{msg.description}</div>
                  {msg.bullets && msg.bullets.length > 0 && (
                    <ul
                      style={{
                        margin: '16px 0 0',
                        padding: '12px 0 0 16px',
                        borderTop: `1px solid ${type === t ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.08)'}`
                      }}
                      className="small text-left"
                    >
                      {_map(msg.bullets, (b, idx) => (
                        <li style={{ marginTop: '4px' }} key={idx}>
                          {b}
                        </li>
                      ))}
                    </ul>
                  )}
                </Select>
              </div>
            );
          })}
        </div>
        {type && (
          <div className="mui-padded" style={{ paddingTop: 0 }}>
            <Button
              primary
              onClick={this.handleConfirm}
              label={change ? messages.confirmButton : messages.nextButton}
              icon={<i className="material-icons">{change ? 'check' : 'arrow_forward'}</i>}
              labelPosition={change ? 'after' : 'before'}
              fullWidth
              style={{ height: '56px', lineHeight: '56px' }}
            />
          </div>
        )}
      </div>
    );
  }
}

const intlMessages = {
  cs: {
    typeMessages: {
      TEAM: {
        title: 'Tým',
        description: 'Zobrazte v kontextu tým (a jeho manažera)',
        bullets: [
          'profilování stávajícího týmu pro rozvoj nebo prediktivní recruitment',
          'srovnání manažera a týmu',
          'identifikace kulturního profilu týmu a kompetenčních mezer'
        ]
      },
      GROUP: {
        title: 'Skupina',
        description: 'Seskupení vybraných jednotlivců',
        bullets: [
          'ad hoc seskupení vybraných uživatelských profilů',
          'analýza kompetenčních mezer a kulturního profilu u typových rolí nebo pozic',
          'identifikace rozvojových potřeb jednotlivců'
        ]
      },
      HIRING: {
        title: 'Uchazeči',
        description: 'Lidé ve výběrovém řízení pro vybranou pozici',
        bullets: [
          'assessment v rámci recruitmentu',
          'prescreening zájemců',
          'srovnání jednotlivců',
          'shortlisting kandidátů',
          'srovnání s existujícím týmem'
        ]
      }
    },
    nextButton: 'Pokračovat',
    confirmButton: 'Změnit'
  },
  en: {
    typeMessages: {
      TEAM: {
        title: 'Team',
        description: 'Show the team and their manager in context',
        bullets: [
          'team profiling for professional development or predictive recruitment',
          'manager and team dynamics comparison',
          'competence gaps and cultural profile identification'
        ]
      },
      GROUP: {
        title: 'Group',
        description: 'A group of selected individuals',
        bullets: [
          'ad hoc  grouping of selected profiles',
          'competence gaps analysis and cultural profiling of job roles or positions',
          'development needs identification'
        ]
      },
      HIRING: {
        title: 'Candidates',
        description: 'People in the hiring process for your open position',
        bullets: [
          'competence and work culture assessment for recruitment',
          'applicants prescreening',
          'applicants comparison',
          'candidates shortlisting',
          'candidate team fit'
        ]
      }
    },
    nextButton: 'Continue',
    confirmButton: 'Change'
  },
  sk: {
    typeMessages: {
      TEAM: {
        title: 'Tím',
        description: 'Zobrazte v kontexte tím (a jeho manažéra)',
        bullets: [
          'profilovanie súčasného tímu na rozvoj alebo prediktívny recruitment',
          'porovnanie manažéra a tímu',
          'identifikácia kultúrneho profilu tímu a kompetenčných medzier'
        ]
      },
      GROUP: {
        title: 'Skupina',
        description: 'Zoskupenie vybraných jednotlivcov',
        bullets: [
          'ad hoc zoskupenie vybraných používateľských profilov',
          'analýza kompetenčných medzier a kultúrneho profilu u typových rolí alebo pozícií',
          'identifikácia rozvojových potrieb jednotlivcov'
        ]
      },
      HIRING: {
        title: 'Uchádzači',
        description: 'Ľudia vo výberovom konaní na vybranú pozíciu',
        bullets: [
          'assessment v rámci recruitmentu',
          'prescreening záujemcov',
          'porovnanie jednotlivcov',
          'shortlisting kandidátov',
          'porovnanie s existujúcim tímom'
        ]
      }
    },
    nextButton: 'Pokračovať',
    confirmButton: 'Zmeniť'
  }
};

export default withIntl(GroupTypeSelect, intlMessages);
