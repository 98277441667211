import React from 'react';

import MenuItem from 'material-ui/MenuItem';

import SplitButton from '../../mui/SplitButton';
import Button from '../../mui/Button';

import { EGroupMembership, EGroupType } from '../../../constants/enum';

import withIntl from '../../withIntl';

const AddMembersButton = props => {
  const {
    type,
    onAddStart,
    members,
    manager,
    intl: { messages }
  } = props;
  const team = type === EGroupType.TEAM;
  const allMembers = members?.items || [];
  const highlight = allMembers.length === 0 || (team && !manager);
  const buttonProps = {
    primary: highlight,
    raised: highlight,
    label: messages.addLabel,
    icon: <i className={`material-icons${highlight ? ' text-white' : ''}`}>person_add</i>,
    onClick: () => onAddStart(EGroupMembership.MEMBER)
  };
  return (
    <div>
      {team ? (
        <SplitButton {...buttonProps}>
          <MenuItem
            primaryText={manager ? messages.changeManagerLabel : messages.setManagerLabel}
            leftIcon={<span className="mdi mdi-account-tie-outline mdi-24px" style={{ lineHeight: '24px' }} />}
            onClick={() => onAddStart(EGroupMembership.MANAGER)}
          />
        </SplitButton>
      ) : (
        <Button {...buttonProps} />
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    setManagerLabel: 'Přidat manažera',
    changeManagerLabel: 'Změnit manažera',
    addLabel: 'Přidat'
  },
  sk: {
    setManagerLabel: 'Pridať manažéra',
    changeManagerLabel: 'Zmeniť manažéra',
    addLabel: 'Pridať'
  },
  en: {
    setManagerLabel: 'Add manager',
    changeManagerLabel: 'Change manager',
    addLabel: 'Add'
  }
};

export default withIntl(AddMembersButton, intlMessages);
