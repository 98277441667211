import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { reset as resetForm } from 'redux-form';
import _map from 'lodash/map';
import _take from 'lodash/take';
import _forEach from 'lodash/forEach';
import _reduce from 'lodash/reduce';

import Button from '../../mui/Button';
import HorizontalScrollPane from '../../basic/HorizontalScrollPane';
import DisappearingContent from '../../basic/DisappearingContent';
import Tooltip from '../../basic/Tooltip';
import MenuItem from '../../menu/MenuItem';
import ChangedFormLeaveModal from '../../modals/ChangedFormLeave';

import { MODAL_CHANGED_FORM_LEAVE } from '../../../constants/constants';
import { setAsyncRouteLeaveHook } from '../../../utils/utils';
import * as modalActions from '../../../actions/options/modalActions';

import { handleSubmit } from '../FormUtils';

const FormPartTitle = props => {
  const { children } = props;
  return <h4 style={{ marginTop: '24px' }}>{children}</h4>;
};

const Collapsible = props => {
  const { children, collapsed } = props;
  return <div style={{ display: collapsed ? 'none' : 'block' }}>{children}</div>;
};

const SAVED_TIMESTAMP_TIMEOUT = 5000;

const TabWithIndicator = props => {
  const { children, indicate, invalid } = props;
  return (
    <div className={invalid && 'text-error'}>
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{children}</div>
      {indicate && (
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '8px',
            height: '8px',
            border: '4px solid',
            borderRadius: '50%',
            margin: '0 0 6px 6px'
          }}
        />
      )}
    </div>
  );
};

const SavedTimestamp = props => {
  const { timestamp, messages } = props;
  return (
    <div style={{ display: 'inline-block', padding: '8px' }}>
      <DisappearingContent timeout={SAVED_TIMESTAMP_TIMEOUT} key={timestamp}>
        <small>{messages.savedMessage}</small>
      </DisappearingContent>
    </div>
  );
};

class FormPartContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0
    };
  }

  componentWillMount() {
    const { router } = this.props;
    const route = router.routes[router.routes.length - 1];
    setAsyncRouteLeaveHook(router, route, this.routerWillLeave);
  }

  componentDidMount() {
    this.setStep(0);
  }

  componentWillUnmount() {
    const { forms } = this.props;
    const { resetForm } = this.props;
    _forEach(forms, f => resetForm(f.name));
  }

  routerWillLeave = () => {
    const { switchModal, forms } = this.props;
    const formDirty = _reduce(forms, (d, { dirty }) => d || dirty, false);
    if (!formDirty) {
      return Promise.resolve(true);
    }
    return new Promise(resolve => {
      switchModal({ id: MODAL_CHANGED_FORM_LEAVE, callback: resolve });
    });
  };

  setStep = step => {
    const { onPartActivate } = this.props;
    this.setState({ step });
    if (onPartActivate) {
      onPartActivate(step);
    }
  };

  handleNextStep = () => {
    const { forms, touch, parts, isNew } = this.props;
    const { step } = this.state;
    const activeForms = isNew ? _take(forms, step + 1) : forms;
    handleSubmit(
      activeForms,
      () => {
        const newStep = Math.min(parts.length - 1, step + 1);
        this.setStep(newStep);
        if (this.formTop) {
          window.scrollTo(0, this.formTop.offsetTop);
        }
      },
      touch
    );
  };

  handlePrevStep = () => {
    const { step } = this.state;
    const newStep = Math.max(0, step - 1);
    this.setStep(newStep);
  };

  handleSaveCommon = publish => {
    const { forms, touch, onSubmit } = this.props;
    const onSubmitHandler = values => onSubmit(values, publish);
    handleSubmit(forms, onSubmitHandler, touch);
  };

  handleSave = () => {
    this.handleSaveCommon();
  };

  handleSaveAndPublish = () => {
    this.handleSaveCommon(this.props.publish);
  };

  handleCreate = () => {
    this.handleSaveCommon();
  };

  handleCreateAndPublish = () => {
    this.handleSaveCommon(this.props.publish);
  };

  setFormTop = el => (this.formTop = el);

  render() {
    const {
      isNew,
      forms,
      disabled,
      intl,
      savedAt,
      parts,
      publish,
      modal,
      customSaveAndPublishLabel,
      customCreateAndPublishLabel,
      saveDisabled,
      publishDisabled,
      publishTooltip
    } = this.props;
    const { switchModal } = this.props;
    const { step } = this.state;
    const savedRecently = savedAt && savedAt > Date.now() - SAVED_TIMESTAMP_TIMEOUT;
    const finalStep = parts.length - 1;
    const messages = intl.messages.forms.partContainer;
    return (
      <div>
        {!isNew && (
          <div>
            <HorizontalScrollPane style={{ padding: '4px 4px 0' }} className="bg-primary-light">
              <div className="container-flex-row">
                {_map(parts, ({ title, tabTitle }, idx) => (
                  <div key={idx} className="inline-container">
                    <MenuItem
                      label={
                        <TabWithIndicator indicate={forms[idx].dirty} invalid={!forms[idx].valid}>
                          {tabTitle || title}
                        </TabWithIndicator>
                      }
                      active={step === idx}
                      onClick={() => this.setStep(idx)}
                    />
                  </div>
                ))}
              </div>
            </HorizontalScrollPane>
          </div>
        )}
        <div className="clearfix" style={{ padding: isNew ? '16px' : '0 16px 16px' }} ref={this.setFormTop}>
          <FormPartTitle>
            {isNew && (
              <span style={{ marginRight: 8 }}>
                {step + 1}
                <small>/{parts.length}</small>
              </span>
            )}
            {parts[step].title}
          </FormPartTitle>
          {_map(parts, ({ render }, idx) => (
            <Collapsible key={idx} collapsed={step !== idx}>
              {render()}
            </Collapsible>
          ))}
          {isNew ? (
            <div className="container-flex-row fw-yes ai-end">
              <div className="flex1">
                {step > 0 && (
                  <div>
                    <div className="mui-fields-helper-text" style={{ margin: '0 0 8px' }}>
                      {step}) {parts[step - 1].title}
                    </div>
                    <Button
                      onClick={this.handlePrevStep}
                      label={messages.buttons.back}
                      icon={<i className="material-icons">keyboard_arrow_left</i>}
                      disabled={disabled}
                    />
                  </div>
                )}
              </div>
              <div style={{ textAlign: 'right' }}>
                {step < finalStep && (
                  <div className="mui-fields-helper-text" style={{ margin: '0 0 8px', textAlign: 'right' }}>
                    {step + 2}) {parts[step + 1].title}
                  </div>
                )}
                {step === finalStep && publish && (
                  <Button
                    onClick={this.handleCreate}
                    label={messages.buttons.create}
                    primary
                    disabled={disabled || (step === finalStep && saveDisabled)}
                  />
                )}
                <Tooltip content={step === finalStep ? publishTooltip : null}>
                  <Button
                    onClick={step === finalStep ? this.handleCreateAndPublish : this.handleNextStep}
                    label={
                      step === finalStep
                        ? publish
                          ? customCreateAndPublishLabel || messages.buttons.createAndPublish
                          : messages.buttons.create
                        : messages.buttons.next
                    }
                    labelPosition="before"
                    icon={
                      step === finalStep ? null : (
                        <i className="material-icons" style={{ color: 'white' }}>
                          keyboard_arrow_right
                        </i>
                      )
                    }
                    raised
                    primary
                    disabled={disabled || (step === finalStep && (saveDisabled || publishDisabled))}
                    style={{ marginLeft: '8px' }}
                  />
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className="text-right">
              {savedRecently && <SavedTimestamp timestamp={savedAt} messages={messages} />}
              <Button
                onClick={this.handleSave}
                label={messages.buttons.save}
                raised={!publish}
                primary
                disabled={disabled || saveDisabled}
              />
              {publish && (
                <Tooltip content={publishTooltip}>
                  <Button
                    onClick={this.handleSaveAndPublish}
                    label={customSaveAndPublishLabel || messages.buttons.saveAndPublish}
                    raised
                    primary
                    disabled={disabled || saveDisabled || publishDisabled}
                    style={{ marginLeft: '8px' }}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
        {modal[MODAL_CHANGED_FORM_LEAVE].open && (
          <ChangedFormLeaveModal
            {...modal[MODAL_CHANGED_FORM_LEAVE]}
            id={MODAL_CHANGED_FORM_LEAVE}
            intl={intl}
            switchModal={switchModal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    options: { modal }
  } = state;
  return {
    modal
  };
};

const actions = {
  switchModal: modalActions.switchModal,
  resetForm
};

export default connect(mapStateToProps, actions)(withRouter(FormPartContainer));
