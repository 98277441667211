export const STEP = 0.1;
export const MIN = 0;
export const MAX = 2;

export const EWeightLevel = {
  OFF: {
    key: 'OFF',
    value: 0
  },
  LOW: {
    key: 'LOW',
    value: 0.5
  },
  MEDIUM: {
    key: 'MEDIUM',
    value: 1
  },
  HIGH: {
    key: 'HIGH',
    value: 1.5
  },
  MAX: {
    key: 'MAX',
    value: 2
  }
};

export const WEIGHT_LEVELS = [EWeightLevel.OFF, EWeightLevel.LOW, EWeightLevel.MEDIUM, EWeightLevel.HIGH, EWeightLevel.MAX];

export const determineEWeightLevel = w => {
  let nextLevel = EWeightLevel.OFF;
  let foundLevel = EWeightLevel.MAX;
  for (let i = 0; i < WEIGHT_LEVELS.length - 1; i += 1) {
    const level = WEIGHT_LEVELS[i];
    const maxVal = (level.value + nextLevel.value) / 2;
    if (w <= maxVal) {
      foundLevel = level;
      break;
    }
    nextLevel = WEIGHT_LEVELS[i + 2];
  }
  return {
    value: foundLevel,
    exact: w === foundLevel.value
  };
};
