import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Chip = props => {
  const { className, style, contentStyle, children, active, iconLeft, iconRight, variant, disabled } = props;
  const { onRequestDelete, onClick } = props;
  return (
    <div
      role={onClick ? 'button' : undefined}
      className={cn({
        'mui-chip': true,
        active,
        disabled,
        [variant]: variant,
        [className]: className
      })}
      style={style}
      onClick={disabled ? undefined : onClick}
    >
      {iconLeft && (
        <div className="mui-chip-icon-left">
          <i className="material-icons">{iconLeft}</i>
        </div>
      )}
      <div className="mui-chip-content" style={contentStyle}>
        {children}
      </div>
      {(iconRight || onRequestDelete) && (
        <button type="button" className="mui-chip-icon-right" onClick={disabled ? undefined : onRequestDelete}>
          <i className="material-icons">{iconRight || 'cancel'}</i>
        </button>
      )}
    </div>
  );
};

Chip.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  contentStyle: PropTypes.object,
  children: PropTypes.any,
  active: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  variant: PropTypes.oneOf(['compact', 'mini', 'nano']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onRequestDelete: PropTypes.func
}

export default Chip;
