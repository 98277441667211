import { updateIntl } from 'react-intl-redux';
import i18n from './../../i18n';
import { put } from './../../persistence/storage';

// Switch site locale
export const updateLocale = (locale) => dispatch => {
    dispatch(updateIntl({
        locale,
        messages: i18n[locale]
    }));
    put('locale', locale);
};
