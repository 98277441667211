import React from 'react';
import cn from 'classnames';
import map from 'lodash/map';
import autosize from 'autosize';

import Chip from '../mui/Chip';

import Menu from './SuperTextMenu';
import button from '../mui/Button';

const Input = props => {
  const { onRef, ...rest } = props;
  return <input ref={onRef} {...rest} />;
};

class Textarea extends React.Component {
  componentWillUnmount() {
    if (this.area) {
      autosize.destroy(this.area);
    }
  }

  componentDidUpdate() {
    this.updateSize();
  }

  setTextArea = el => {
    this.area = el;
    autosize(el);
    const { onRef } = this.props;
    if (typeof onRef === 'function') {
      onRef(el);
    }
  };

  updateSize = () => {
    if (this.area) {
      autosize.update(this.area);
    }
  };

  render() {
    // noinspection JSUnusedLocalSymbols
    const { onRef, ...props } = this.props;
    return <textarea ref={this.setTextArea} {...props} />;
  }
}

const renderHelperText = (helperText, error) => {
  // error might be true (boolean), but if no helper text is present, we do not render
  const errorText = error && error.length > 0 ? error : helperText;
  if (!helperText && !errorText) {
    return null;
  }
  return <div className="mui-suptext-helper-text">{error ? errorText : helperText}</div>;
};

class SuperText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: props.focused,
      value: props.value || '',
      menuOpen: props.menuOpen
    };
  }

  hasInput = () => {
    return !!this.input;
  };
  isEventFromInput = e => {
    return this.hasInput() && e.target === this.input;
  };
  focusInput = e => {
    if (this.hasInput() && !this.isEventFromInput(e)) {
      this.input.focus();
    }
  };
  blurInput = () => {
    if (this.hasInput()) {
      this.input.blur();
    }
  };
  handleBlur = e => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    } else {
      this.setState({ focused: false, menuOpen: false });
    }
    this.blurInput();
  };
  handleFocus = e => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(e);
    } else {
      this.setState({ focused: true, menuOpen: true });
    }
    this.focusInput(e);
  };
  handleChange = e => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(e);
    } else {
      this.setState({ value: e.target.value });
    }
  };
  handleMenuClose = e => {
    const { onRequestMenuClose } = this.props;
    if (onRequestMenuClose) {
      onRequestMenuClose(e);
    } else {
      this.setState({ menuOpen: false });
    }
  };
  handleMenuOpen = e => {
    const { onRequestMenuOpen } = this.props;
    if (onRequestMenuOpen) {
      onRequestMenuOpen(e);
    } else {
      this.setState({ menuOpen: true });
    }
  };
  setInputRef = el => (this.input = el);
  setAnchorElRef = el => (this.anchorEl = el);
  isMenuOpen = () => {
    const { menu, onRequestMenuClose, menuOpen } = this.props;
    return menu && (onRequestMenuClose ? menuOpen : this.state.menuOpen);
  };
  isFocused = () => {
    const { onFocus, focused } = this.props;
    return onFocus ? focused : this.state.focused;
  };
  handleFieldMouseDown = e => {
    if (e.target !== this.input) {
      e.preventDefault();
    }
  };
  handleElementClick = (e, closeOpenMenu) => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    if (this.isMenuOpen()) {
      if (closeOpenMenu) {
        this.handleMenuClose(e);
      }
    } else {
      if (this.isFocused()) {
        this.handleMenuOpen(e);
      } else {
        this.focusInput(e);
      }
    }
  };
  handleFieldClick = e => this.handleElementClick(e, !this.props.filter);
  handleSelectButtonClick = e => this.handleElementClick(e, true);
  preventEvent = e => e.preventDefault();

  renderChip = ({ item, onRequestDelete, variant }) => {
    const { renderChip } = this.props;
    if (renderChip) {
      return renderChip({ item, onRequestDelete, variant });
    }
    return (
      <Chip onRequestDelete={() => onRequestDelete(item)} active variant={variant}>
        {item.label}
      </Chip>
    );
  };

  handleClearValue = e => {
    const { onChange } = this.props;
    if (onChange) {
      onChange({ ...e, target: { ...e.target, value: '' } });
    } else {
      this.setState({ value: '' });
    }
  };

  render() {
    //noinspection JSUnusedLocalSymbols
    const {
      children,
      values,
      multi,
      helperText,
      label,
      value,
      placeholder,
      error,
      menu,
      select,
      filter,
      onChange,
      onRequestDeleteValue,
      disabled,
      style,
      focused,
      menuOpen,
      onRequestMenuClose,
      onRequestMenuOpen,
      onSetMenuContainer,
      variant,
      multiline,
      renderChip,
      clearable,
      ...props
    } = this.props;
    const currentValue = onChange ? value : this.state.value;

    const isFocused = this.isFocused();
    const isMenuOpen = this.isMenuOpen();

    const handleChange = disabled ? null : this.handleChange;
    const handleBlur = disabled ? null : this.handleBlur;
    const handleFocus = disabled ? null : this.handleFocus;
    const handleMenuClose = disabled ? null : this.handleMenuClose;
    const handleRequestDeleteValue = (...args) => !disabled && onRequestDeleteValue(...args);
    const handleClearValue = disabled ? null : this.handleClearValue;
    const inputProps = {
      ...props,
      placeholder,
      onRef: this.setInputRef,
      className: 'mui-suptext-input',
      onFocus: handleFocus,
      onBlur: handleBlur,
      onChange: handleChange,
      value: currentValue,
      disabled: disabled
    };
    return (
      <div
        className={cn({
          'mui-suptext': true,
          'with-focus': isFocused,
          'with-menu-open': isMenuOpen,
          'with-children': (children && children.length !== 0) || (values && values.length),
          'with-value': !!currentValue || !!placeholder,
          'with-error': error,
          'with-select': select,
          'with-filter': filter,
          'with-multi': multi,
          'with-label': !!label,
          disabled: disabled
        })}
        style={style}
      >
        {label && <span className="mui-suptext-label">{label}</span>}
        <div
          ref={this.setAnchorElRef}
          onMouseDown={this.handleFieldMouseDown}
          onClick={this.handleFieldClick}
          className="mui-suptext-content"
        >
          <div className="mui-suptext-children-container">
            {children}
            {multi
              ? map(values, (item, idx) => (
                  <div key={typeof item.value !== 'undefined' ? item.value : idx} className="chip-container">
                    {this.renderChip({ item, variant, onRequestDelete: handleRequestDeleteValue })}
                  </div>
                ))
              : values && values[0] && <div className="mui-suptext-single-select-value">{values[0].label}</div>}
            {multiline ? <Textarea {...inputProps} /> : <Input {...inputProps} />}
            {clearable && currentValue &&
              <button className="mui-suptext-clear-button" onClick={handleClearValue}>
                <i className="material-icons">clear</i>
              </button>
            }
          </div>
          {select && (
            <button type="button" className="mui-suptext-trailing-icon" onClick={this.handleSelectButtonClick}>
              <i className="material-icons">arrow_drop_down</i>
            </button>
          )}
          <div className="mui-suptext-border" />
        </div>
        {renderHelperText(helperText, error)}
        {menu && (
          <Menu
            open={isMenuOpen}
            anchorEl={this.anchorEl}
            onMouseDown={this.preventEvent}
            onRequestClose={handleMenuClose}
            onSetMenuContainer={onSetMenuContainer}
          >
            {menu}
          </Menu>
        )}
      </div>
    );
  }
}

export default SuperText;
