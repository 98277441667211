import jwtDecode from 'jwt-decode';
import { indexOf } from 'lodash';

import * as Api from '../../api/auth/authApi';
import * as c from '../../constants/constants';
import { saveToken } from '../../utils/utils';
import { switchLoader } from './../options/loaderActions';
import { HTTPErrorCodes } from './../../constants/enum';

function loginSuccess(response, manual = false) {
  return {
    type: c.LOGIN_SUCCESS,
    payload: response,
    meta: {
      manual
    }
  };
}

function loginFailure(error) {
  return {
    type: c.LOGIN_FAILURE,
    payload: error
  };
}

function setLanguageSuccess(response) {
  return {
    type: c.SET_LANGUAGE_SUCCESS,
    payload: response
  };
}

function setLanguageFailure(error) {
  return {
    type: c.SET_LANGUAGE_FAILURE,
    payload: error
  };
}

export function loginWithCredentials(user, pwd, manual) {
  return dispatch => {
    dispatch(switchLoader(c.LOGIN_LOADING));
    return Api.login(user, pwd).then(
      response => {
        const decodedToken = jwtDecode(response.access_token);

        if (
          indexOf(decodedToken.authorities, 'ADMIN') === -1 &&
          indexOf(decodedToken.authorities, 'ADMIN_LOCAL') === -1 &&
          indexOf(decodedToken.authorities, 'OPERATOR') === -1 &&
          indexOf(decodedToken.authorities, 'ANALYST') === -1 &&
          indexOf(decodedToken.authorities, 'DESIGNER') === -1
        ) {
          dispatch(loginFailure({ error: true, statusCode: HTTPErrorCodes.FORBIDDEN }));
          dispatch(switchLoader(c.LOGIN_LOADING));
          throw { status: HTTPErrorCodes.FORBIDDEN };
        }

        saveToken(response.access_token);
        dispatch(loginSuccess(response, manual));
        dispatch(switchLoader(c.LOGIN_LOADING));

        return response;
      },
      error => {
        dispatch(loginFailure(error));
        dispatch(switchLoader(c.LOGIN_LOADING));
        throw error;
      }
    );
  };
}

export function getUser(user) {
  return dispatch => {
    dispatch(switchLoader(c.USER_LOADING));
    return Api.getUser(user.user_id).then(
      response => {
        dispatch(switchLoader(c.USER_LOADING));
        dispatch(loginSuccess({ ...user, ...response.payload }));

        return response;
      },
      error => {
        dispatch(loginFailure(error));
        dispatch(switchLoader(c.USER_LOADING));
        return error;
      }
    );
  };
}

export function setLanguage(user, language) {
  return dispatch => {
    return Api.setLanguage(user.user_id, language).then(
      response => {
        dispatch(setLanguageSuccess({ ...user, ...response.payload }));
        return response;
      },
      error => {
        dispatch(setLanguageFailure(error));
        return error;
      }
    );
  };
}

export function sendEmail(userEmail) {
  return dispatch => {
    dispatch(switchLoader(c.EMAIL_LOADING));
    return Api.sendEmail(userEmail).then(
      response => {
        dispatch(switchLoader(c.EMAIL_LOADING));
        return response;
      },
      error => {
        dispatch(switchLoader(c.EMAIL_LOADING));
        dispatch(loginFailure(error));
        return error;
      }
    );
  };
}

export function resetPassword(password, email, token) {
  return dispatch => {
    dispatch(switchLoader(c.PASSWORD_RESET_LOADING));
    return Api.resetPassword(password, email, token).then(
      response => {
        dispatch(switchLoader(c.PASSWORD_RESET_LOADING));
        return response;
      },
      error => {
        dispatch(switchLoader(c.PASSWORD_RESET_LOADING));
        dispatch(loginFailure(error));
        return error;
      }
    );
  };
}

export function logout() {
  return {
    type: c.LOGOUT
  };
}
