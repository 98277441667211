import React from 'react';
import { connect } from 'react-redux';

import UserTags from '../tag/UserTags';

import { getProjectUserCompanyTags } from '../../actions/entities/tagActions';

const ProjectUserTags = props => {
  const { userId, tags, project, getProjectUserCompanyTags } = props;
  return (
    <UserTags
      companyId={project?.company?.id}
      userId={userId}
      tags={tags}
      onLoad={() => getProjectUserCompanyTags(project)}
      preventInitialLoad
    />
  );
};

export default connect(null, { getProjectUserCompanyTags })(ProjectUserTags);
