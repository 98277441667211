import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import MetricIcon from '../icons/Metric';

const Interpretation = ({ metrics, text }) => (
  <div className="applicant-detail-card-interpretation row">
    <div className="col-sm-12 col-md-4 col-lg-3">
      <strong>{text.title}</strong>
      <div className="applicant-detail-card-interpretation-icons">
        <div style={{ margin: '-1px'}}>
          {map(metrics, m => (
            <div key={m.key} className="inline-container" style={{ margin: '1px' }}>
              <MetricIcon metric={m} style={{ color: m.color }} size={40} />
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="col-sm-12 col-md-8 col-lg-9">
      <p>{text.conclusion}</p>
      {text.recommendation && (
        <div className="applicant-detail-card-interpretation-recommendation">
          <p>{text.recommendation}</p>
        </div>
      )}
    </div>
  </div>
);

Interpretation.propTypes = {
  metrics: PropTypes.array.isRequired,
  text: PropTypes.object.isRequired
};

export default Interpretation;
