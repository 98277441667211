import Defaults from 'superagent-defaults';
import { browserHistory } from 'react-router';
import { loadToken } from '../utils/utils';
import * as c from '../constants/constants';

const BASE_URL = '';

const createApiStatusActions = (err, res) => {
  if (err) {
    return { type: c.API_STATUS, payload: { error: true } };
  }
  return { type: c.API_STATUS, payload: res.body || {} };
};

const ping = dispatch => {
  const superagent = new Defaults();
  superagent
    .get(`${BASE_URL}/api/v1/public/status`)
    .query({ v: VERSION })
    .end((err, res) => {
      dispatch(createApiStatusActions(err, res));
    });
};

export default function apiMiddleware({ dispatch }) {
  // setup PING
  ping(dispatch);
  setInterval(() => ping(dispatch), 5000);
  return next => action => {
    if (action.type !== c.API) {
      return next(action);
    }
    const { payload: { asyncType }, meta } = action;
    const superagent = new Defaults();
    const token = loadToken();
    if (token) {
      superagent.set('Authorization', `Bearer ${token}`);
    }

    const url = `${BASE_URL}${meta.url}`;
    const method = meta.method || 'GET';
    const body = meta.body || null;
    const handleError = (error, res) => {
      console.error(error);
      dispatch(createApiStatusActions(error, res));
      dispatch({ type: asyncType.ERROR, payload: res, originalBody: body });
    };
    dispatch({ type: asyncType.PENDING, payload: body });
    let req;
    switch (method) {
      case 'POST':
        req = superagent.post(url).send(body);
        break;
      case 'PUT':
        req = superagent.put(url).send(body);
        break;
      case 'GET':
        req = superagent.get(url);
        break;
      case 'DELETE':
        req = superagent.del(url);
        break;
      default:
        throw Error(`Unknown method ${method}`);
    }
    return req.end((err, res) => {
      if (err) {
        handleError(err, res);
      } else {
        dispatch({ type: asyncType.SUCCESS, payload: res.body });
        if (meta.redirect) {
          browserHistory.push(meta.redirect);
        }
      }
    });
  };
}
