import { authRequest } from '../Request';
import { API_URL } from '../../config';
import { ESortOrder } from '../../constants/enum';

export function getCompanies() {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v3/companies`)
      .query({ sort: `name,${ESortOrder.ASC}`, size: 9999, page: 0 })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getCompanyOperators(companyId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/admin/companies/${companyId}/operators`)
      .query(null)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getCompanyProfile(companyId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/companies/profiles/${companyId}`)
      .query(null)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function saveCompanyProfile(companyId, profile) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .put(`${API_URL}/api/v1/companies/profiles/${companyId}`)
      .send(profile)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

function companyProfileDraftAction(action, companyId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/companies/profiles/${companyId}/draft/${action}`)
      .query(null)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function publishCompanyProfileDraft(companyId) {
  return companyProfileDraftAction('publish', companyId);
}

export function unpublishCompanyProfileDraft(companyId) {
  return companyProfileDraftAction('unpublish', companyId);
}

export function discardCompanyProfileDraft(companyId) {
  return companyProfileDraftAction('discard', companyId);
}

export function getCompanyTalentMarketPlan(companyId, lang) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/companies/${companyId}/talent_market_plans/current`)
      .query({ lang })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function setCompanyTalentMarketPlan(companyId, plan, lang) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/companies/${companyId}/talent_market_plans/current`)
      .query({ lang })
      .send(plan)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
