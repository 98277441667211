export const renderDataSourceValue = ({ value }, dataSourceMap) => {
  const entry = (dataSourceMap || {})[value];
  const { item } = entry || {};
  if (!item || !item.label) {
    return value;
  }
  return item.label;
};

export const renderEnumValue = ({ value }, intl, enumName) => {
  const enumMessages = intl.messages.constants.enums[enumName];
  if (enumMessages) {
    const msg = enumMessages[value];
    if (msg) {
      return msg.matchLabel || msg.label || msg;
    }
  }
  return value;
};

const formatBoundary = (val, toK, addK) => {
  if (toK) {
    const roundedVal = Math.round(val / 100);
    const dec = roundedVal % 10;
    return `${Math.floor(roundedVal / 10)}${dec ? `,${dec}` : ''}${addK ? 'k' : ''}`;
  }
  return val;
};

export const formatRange = (lower, upper) => {
  const toK = upper > 1000 || lower > 1000;
  if (lower != undefined && upper != undefined && lower < upper) {
    return `${formatBoundary(lower, toK)} - ${formatBoundary(upper, toK, true)}`;
  }
  return formatBoundary(upper || lower, toK, true);
};
