import React from 'react';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import map from 'lodash/map';
import find from 'lodash/find';
import get from 'lodash/get';

import Button from '../../../components/mui/Button';
import IconButton from '../../../components/mui/IconButton';
import Text from '../../../components/superfield/form/SuperText';
import Select from '../../../components/superfield/form/SuperSelect';
import Checkbox from '../../../components/formFields/Checkbox';

import { getEnumOrdered, ESimulationVariableOperation } from '../../../constants/enum';

const createSimpleValidator = messageProp => (val, values, props) => {
  return !val && props.messages[messageProp];
};
const variableValidator = createSimpleValidator('variableOperationVariableRequired');
const operationValidator = createSimpleValidator('variableOperationOperationRequired');
const valueValidator = (val, values, props, fieldName) => {
  const op = get(values, fieldName.replace('.value', 'operation'));
  if (val && op === ESimulationVariableOperation.ASSIGN_VARIABLE.key) {
    return (
      !find(props.variables, v => v.name === val) && `${props.messages.variableOperationValueNotVariable} - '${val}'`
    );
  }
  return !val && op !== ESimulationVariableOperation.ASSIGN_RESULT.key && props.messages.variableOperationValueRequired;
};

const VARIABLE_OPS = getEnumOrdered(ESimulationVariableOperation);

const renderVariableOperations = ({ fields, variables, messages }) => {
  return (
    <div>
      {fields.length === 0 && <p>{messages.variableOperationsEmpty}</p>}
      {fields.map((f, idx) => {
        const fld = fields.get(idx);
        const variable = find(variables, v => v.name === fld.variable);
        const operation = ESimulationVariableOperation[fld.operation];
        return (
          <div key={idx} className="sim-model-variable-operation-container">
            <div className="sim-model-variable-operation-field">
              <Field
                name={`${f}.variable`}
                component={Select}
                style={{ margin: 0 }}
                label={messages.variableOperationVariableLabel}
                validate={variableValidator}
                dataSource={
                  variables.length === 0
                    ? [{ label: messages.variableDefinitionsEmpty, disabled: true, value: null }]
                    : map(variables, vd => ({ label: vd.name, value: vd.name }))
                }
              />
            </div>
            <div className="sim-model-variable-operation-field">
              <Field
                name={`${f}.operation`}
                component={Select}
                style={{ margin: 0 }}
                label={messages.variableOperationOperationLabel}
                validate={operationValidator}
                dataSource={map(VARIABLE_OPS, op => ({ label: messages.operations[op.key].label, value: op.key }))}
              />
            </div>
            <div className="sim-model-variable-operation-field">
              <Field
                name={`${f}.value`}
                component={Text}
                style={{ margin: 0 }}
                label={messages.variableOperationValueLabel}
                type={variable && variable.type === 'NUMBER' ? 'number' : 'text'}
                disabled={operation && operation.fieldDisabled}
                validate={valueValidator}
              />
            </div>
            <div className="sim-model-variable-operation-remove-button">
              <IconButton onClick={() => fields.remove(idx)}>
                <i className="material-icons">clear</i>
              </IconButton>
            </div>
          </div>
        );
      })}
      <div style={{ marginTop: 16 }}>
        <Button
          label={messages.variableOperationAddButton}
          onClick={() => fields.push({})}
          icon={<i className="material-icons">add</i>}
        />
      </div>
    </div>
  );
};

const NodeSettingsForm = props => {
  const { handleSubmit, onCancel, onConfirm, variables, messages, multiChoiceEnabled, node } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="sim-model-properties-form node-settings" style={{ paddingBottom: 0 }}>
        {node.type === 'DIALOGUE_REPLY' && (
          <div className="sim-model-properties-form-section" style={{ margin: 0 }}>
            <h4>{messages.variableOperationsTitle}</h4>
            <p>{messages.variableOperationsDescription}</p>
            <FieldArray
              name="variableOperations"
              component={renderVariableOperations}
              variables={variables}
              messages={messages}
              rerenderOnEveryChange
            />
          </div>
        )}
        {node.type === 'DIALOGUE_IMPULSE' && (
          <div className="sim-model-properties-form-section" style={{ margin: 0 }}>
            <h4>Reply settings</h4>
            <div className="mui-fields-container">
              <Field
                name="multiChoiceEnabled"
                component={Checkbox}
                labelStyle={{ margin: 0 }}
                label={'Multiple choice'}
              />
            </div>
            {multiChoiceEnabled && (
              <>
                <Field name={'choiceMin'} component={Text} type={'number'} label={'Minimum choices'} />
                <Field name={'choiceMax'} component={Text} type={'number'} label={'Maximum choices'} />
              </>
            )}
            <div className="mui-fields-container">
              <Field name="randomizable" component={Checkbox} labelStyle={{ margin: 0 }} label={'Randomizable'} />
            </div>
          </div>
        )}
      </div>
      <div style={{ textAlign: 'right', padding: 8 }}>
        <Button label={messages.cancelButton} onClick={onCancel} />
        <Button primary label={messages.confirmButton} onClick={handleSubmit(onConfirm)} />
      </div>
    </form>
  );
};

const modelPropsSelector = formValueSelector('simulationModelProperties');
const selector = formValueSelector('simulationNodeSettings');

const mapStateToProps = (state, { node }) => {
  const data = node?.data || {};
  return {
    initialValues: {
      variableOperations: data.variableOperations || [],
      multiChoiceEnabled: data.choiceMin != null || data.choiceMax != null,
      choiceMin: data.choiceMin,
      choiceMax: data.choiceMax,
      randomizable: data.randomizable
    },
    multiChoiceEnabled: selector(state, 'multiChoiceEnabled'),
    variables: modelPropsSelector(state, 'inputParams')
  };
};

const formConfig = {
  form: 'simulationNodeSettings'
};

export default connect(mapStateToProps)(reduxForm(formConfig)(NodeSettingsForm));
