import React from 'react';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import classnames from 'classnames';

import ActivityToggleHeader from './ActivityToggleHeader';
import Insight from './insights/Insight';
import MetricVisualization from './insights/MetricVisualization';
import DualMetricsMassChart from '../analytics/DualMetricsMassChart';
import MetricsBubbleChartPanel from '../analytics/bubbleChart/MetricsBubbleChartPanel';

import { createGetParticipantProps } from './TeamInsightsUtils';
import { mapObjectAsArray } from '../../utils/utils';
import { EActivityType } from '../../constants/enum';

import withIntl from '../withIntl';

const mergeMembers = (m, list) => {
  const res = [];
  let found = false;
  for (let i = 0; i < list.length; i += 1) {
    const lm = list[i];
    if (lm.id === m.id) {
      found = true;
    }
    res.push(lm);
  }
  if (!found) {
    res.push(m);
  }
  return res;
};

const MemberActivityInsights = props => {
  const {
    member,
    activity,
    expanded,
    chartShown,
    participantsListShown,
    onExpandedToggle,
    onProfileShow,
    metricProps,
    metricStats,
    targetMembers,
    intl: { messages }
  } = props;
  const metricsList = activity.metrics?.list || [];
  const allMetricsList = activity.metrics?.all || [];
  const metricsData = activity.metrics?.data || {};
  const memberActivity = member.meta.activities.map[activity.mapKey];
  const mergedMembers = mergeMembers(member, targetMembers);
  const getParticipantProps = createGetParticipantProps(activity, { [member.id]: allMetricsList[0]?.color });

  const className = classnames({ 'team__member-activity-insights mui-padded-top': true, 'hidden-print': !expanded });
  return (
    <div className={className}>
      <div className="mui-padded-bottom border-top border-color-primary__50" />
      <div
        style={{ transition: '0.2s padding', padding: expanded ? '8px 0' : 0 }}
        className="container-flex-row ai-center fw-yes jc-space-between"
      >
        <ActivityToggleHeader activity={activity} expanded={expanded} onToggle={onExpandedToggle} />
        {memberActivity && memberActivity.finishedAt && (
          <div className="mui-padded-horizontal hidden-print">
            <a role="button" className="link-button heavy" onClick={() => onProfileShow(member, memberActivity)}>
              <span className="text-uppercase">{messages.profileLabel}</span>
              <i className="material-icons">arrow_right</i>
            </a>
          </div>
        )}
      </div>
      {expanded && (
        <div className="mui-padded-bottom-x2">
          <div className="border border-color-primary__50 border-radius">
            {chartShown && allMetricsList.length > 0 && (
              <div>
                <div className="mui-padded-x2">
                  <h4 style={{ fontWeight: 300, marginBottom: '24px' }}>{messages.chartTitle}</h4>
                  {EActivityType.CULTURE_FIT.isLike(activity) ? (
                    <DualMetricsMassChart
                      metrics={_reduce(
                        allMetricsList,
                        (res, m) => {
                          const dataItem = metricsData[m.key];
                          if (!dataItem) {
                            return res;
                          }
                          const { crossComparedValues, data, ...metricData } = dataItem;
                          metricData.data = [{}, {}, {}, {}];
                          const memberVal = crossComparedValues[0];
                          if (memberVal) {
                            metricData.data[Math.round((1 - memberVal.value) * 3)] = { values: [{ value: 1 }] };
                          }
                          metricData.metric = { ...metricData.metric, ...m };
                          res.push(metricData);
                          return res;
                        },
                        []
                      )}
                    />
                  ) : (
                    <MetricsBubbleChartPanel
                      participants={mergedMembers}
                      getParticipantProps={getParticipantProps}
                      metrics={allMetricsList}
                      medianShown={false}
                    />
                  )}
                </div>
                <div className="mui-padded-horizontal">
                  <div className="border-bottom border-color-primary__50" />
                </div>
              </div>
            )}
            {metricsList.length > 0 ? (
              <div>
                {_map(metricsList, (m, idx) => (
                  <div key={m.key} className="team__member-activity-insights-metric">
                    {idx > 0 && (
                      <div className="mui-padded-horizontal">
                        <div className="border-top border-color-primary__50" />
                      </div>
                    )}
                    <div className="row container-flex-row fw-yes mui-padded-x2">
                      <div className={participantsListShown ? 'col-xs-12' : 'col-sm-6 col-xs-12'}>
                        <MetricVisualization
                          metric={m}
                          metricsData={metricsData}
                          targetMembers={mergedMembers}
                          getParticipantProps={createGetParticipantProps(activity, { [member.id]: m.color })}
                          participantsListShown={participantsListShown}
                        />
                      </div>
                      <div className="col-sm-6 col-xs-12">
                        {mapObjectAsArray(
                          metricProps,
                          (ip, key) => (
                            <Insight key={key} insightKey={key} metric={m} metricStats={metricStats[m.key]} />
                          ),
                          ip => !!ip
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-muted mui-padded-x2">
                <em>{messages.noDataMessage}</em>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    noDataMessage: 'Žádná dostupná data',
    profileLabel: 'Profil',
    chartTitle: 'Graf'
  },
  sk: {
    noDataMessage: 'Žiadne dostupné dáta',
    profileLabel: 'Profil',
    chartTitle: 'Graf'
  },
  en: {
    noDataMessage: 'No data available',
    profileLabel: 'Profile',
    chartTitle: 'Chart'
  }
};

export default withIntl(MemberActivityInsights, intlMessages);
