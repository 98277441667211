import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import VisSensor from 'react-visibility-sensor';
import m from 'moment';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';

import FlatButton from 'material-ui/FlatButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import ShareIcon from 'material-ui/svg-icons/social/share';
import RemoveIcon from 'material-ui/svg-icons/action/delete-forever';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import CopyIcon from 'material-ui/svg-icons/content/content-copy';

import { formatMessage, loadToken } from '../../../utils/utils';

import CompanySelect from '../company/CompanySelect';
import Card from '../../../components/mui/Card';
import IconButton from '../../../components/mui/IconButton';
import SearchField from '../../../components/mui/DebouncedSearchField';
import CardActions from '../../../components/mui/CardActions';
import Spinner from '../../../components/spinner/Spinner';

import * as simulationModelActions from '../../../actions/entities/simulationModelActions';
import * as simulationDefinitionActions from '../../../actions/entities/simulationDefinitionActions';
import { selectModelsGroupedByCompanies } from '../../../selectors/designer';

const Row = props => {
  const { children, ...rest } = props;
  return (
    <VisSensor partialVisibility offset={{ bottom: -32 }}>
      {({ isVisible }) =>
        isVisible ? (
          <div {...rest}>{children}</div>
        ) : (
          <div {...rest}>
            <div style={{ height: '48px', width: '100%' }} />
          </div>
        )
      }
    </VisSensor>
  );
};

const Cell = props => {
  const { children, className, ...rest } = props;
  return (
    <div className={`model-list-body-cell${className ? ` ${className}` : ''}`} {...rest}>
      {children}
    </div>
  );
};

const SimulationModel = ({ model, messages, intl, confirmDeleteId, onEdit, onCopy, onDelete, onShare }) => {
  return (
    <div onClick={e => !e.isDefaultPrevented() && onEdit(model.id)}>
      <Cell className="col-xs-6 col-md-3">
        <div className="model-list-inline-label hidden-md hidden-lg">{messages.nameHeader}</div>
        <div className="model-list-value">
          {model.name || <span style={{ fontStyle: 'italic' }}>{messages.unnamedItemName}</span>}
          {model.config && model.config.projectName && model.config.projectName !== model.name && (
            <div>
              <small>{model.config.projectName}</small>
            </div>
          )}
        </div>
      </Cell>
      <Cell className="col-xs-6 col-md-3">
        <div className="model-list-inline-label hidden-md hidden-lg">{messages.createdHeader}</div>
        <div className="model-list-value">{m(model.createdAt).locale(intl.locale).format('L LT')}</div>
      </Cell>
      <Cell className="col-xs-6 col-md-3">
        <div className="model-list-inline-label hidden-md hidden-lg">{messages.modifiedHeader}</div>
        <div className="model-list-value">{m(model.modifiedAt).locale(intl.locale).format('L LT')}</div>
      </Cell>
      <Cell className="col-xs-6 col-md-3 model-list-action-cell">
        {confirmDeleteId !== model.id && (
          <div>
            <IconButton
              onClick={e => {
                e.preventDefault();
                onShare(model.id);
              }}
            >
              <ShareIcon />
            </IconButton>
            <IconButton
              onClick={e => {
                e.preventDefault();
                setTimeout(() => {
                  window.open(
                    `/api/v1/designer/simulations/models/${
                      model.id
                    }/definitions/results/raw.csv?access_token=${loadToken()}`
                  );
                }, 100);
                return false;
              }}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              onClick={e => {
                e.preventDefault();
                onCopy(model.id);
              }}
            >
              <CopyIcon />
            </IconButton>
            <IconButton onClick={e => onDelete(e, model.id)}>
              <RemoveIcon />
            </IconButton>
          </div>
        )}
        {confirmDeleteId === model.id && (
          <FlatButton
            icon={<RemoveIcon />}
            primary
            label={messages.confirmDeleteButton}
            onClick={e => onDelete(e, model.id)}
          />
        )}
      </Cell>
    </div>
  );
};

class SimulationModelList extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    simulationModels: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    //
    getSimulationModels: PropTypes.func.isRequired,
    deleteSimulationModel: PropTypes.func.isRequired,
    createSimulationDefinition: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      confirmDeleteId: null
    };
  }

  componentWillMount() {
    this.loadModels(null, this.props);
  }

  componentWillUpdate(nextProps) {
    this.loadModels(this.props, nextProps);
  }

  loadModels = (oldProps, newProps) => {
    const {
      auth: { email },
      getSimulationModels
    } = newProps;
    if (email && (!oldProps || !oldProps.auth || !oldProps.auth.email || oldProps.auth.email !== email)) {
      getSimulationModels();
    }
  };

  editModel = id => {
    const { router } = this.props;
    router.push({ pathname: `/designer/simulations/${id}/edit` });
  };

  deleteItem = (e, id) => {
    const { confirmDeleteId } = this.state;
    const { deleteSimulationModel } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (confirmDeleteId === id) {
      // delete for sure;
      this.setState({ confirmDeleteId: null });
      deleteSimulationModel(id);
    } else {
      this.setState({ confirmDeleteId: id });
    }
  };

  handleFilterValueChange = value => this.props.setSimulationModelsFilterText(value);

  render() {
    const { confirmDeleteId } = this.state;
    const {
      models: { items, loading, itemsByCompany, filterText },
      intl
    } = this.props;
    const { createSimulationDefinition, copySimulationModel } = this.props;
    const messages = intl.messages.components.pages.private.designer.modelList;

    if (loading) {
      return (
        <div>
          <Spinner show />
        </div>
      );
    }

    return (
      <div>
        <CompanySelect />
        <div className="container model-list-container">
          <div className="row" style={{ marginBottom: '16px' }}>
            <div className="col-xs-12">
              <SearchField
                onValueChange={this.handleFilterValueChange}
                value={filterText}
                placeholder={messages.filterPlaceholder}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Card>
                <CardActions>
                  <FlatButton
                    label={messages.createButton}
                    icon={<AddIcon />}
                    containerElement={<Link to="/designer/simulations/new" />}
                  />
                </CardActions>
                {items.length === 0 ? (
                  <div className="designer-list-message">
                    <span>{messages.emptyListMessage}</span>
                  </div>
                ) : (
                  <div className="model-list container-fluid">
                    <div className="model-list-header-row row visible-md visible-lg">
                      <Cell className="col-md-3">{messages.nameHeader}</Cell>
                      <Cell className="col-md-3">{messages.createdHeader}</Cell>
                      <Cell className="col-md-3">{messages.modifiedHeader}</Cell>
                    </div>
                    <div className="model-list-body">
                      {flatMap(itemsByCompany, ({ c, items }) => [
                        itemsByCompany.length > 1 && (
                          <Row key={c.id} className="model-list-header-row bg-primary-light row">
                            <Cell className="col-xs-12">
                              <span>{c.name}</span>
                            </Cell>
                          </Row>
                        ),
                        ...map(items, dm => (
                          <Row key={dm.id} className="model-list-body-row row">
                            <SimulationModel
                              model={dm}
                              messages={messages}
                              intl={intl}
                              confirmDeleteId={confirmDeleteId}
                              onEdit={this.editModel}
                              onDelete={this.deleteItem}
                              onCopy={copySimulationModel}
                              onShare={createSimulationDefinition}
                            />
                          </Row>
                        ))
                      ])}
                    </div>
                    <div className="model-list-footer-row row">
                      <Cell className="col-xs-12">{formatMessage(messages.modelCountMessage, [items.length])}</Cell>
                    </div>
                  </div>
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    entities: { simulationModels },
    auth,
    intl
  } = state;
  return { simulationModels, auth, intl, models: selectModelsGroupedByCompanies(state) };
};

export default withRouter(
  connect(mapStateToProps, { ...simulationModelActions, ...simulationDefinitionActions })(SimulationModelList)
);
