import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import InfoTooltip from '../../basic/InfoTooltip';

import { switchModal } from '../../../actions/options/modalActions';
import { MODAL_TALENT_MARKET_PLAN_SELECT } from '../../../constants/constants';

import { formatMessage } from '../../../utils/utils';
import withIntl from '../../withIntl';
import TalentMarketPlanItem from './TalentMarketPlanItem';

const timeFormatter = (t, locale) => moment(t).locale(locale).calendar();

const CompanyTalentMarketPlanBadge = props => {
  const {
    plan,
    company,
    switchModal,
    intl: { locale, messages }
  } = props;
  if (!company) {
    return null;
  }
  const { name, constraints, constraintValues, end, requested } = plan || {};
  const expired = end && end < Date.now();
  const concurrentOpportunities = constraintValues.concurrentOpportunities || 0;
  return (
    <div className="container-flex-row ai-center fw-yes">
      <div className="mui-margin-right-x2">
        <InfoTooltip
          contentStyle={{ padding: 0 }}
          content={
            <div className="text-normal">
              <TalentMarketPlanItem active plan={plan} currency={plan.currency} billingPeriod={plan.billingPeriod} />
            </div>
          }
        >
          {formatMessage(messages.planLabel, [<b key="planName">{name}</b>])}
        </InfoTooltip>
        {constraints.maxConcurrentOpportunities != null && (
          <span className="small">
            <span> &ndash; </span>
            {formatMessage(messages.activeOpportunitiesMessage, [
              <span key="msg">
                <span className={concurrentOpportunities >= constraints.maxConcurrentOpportunities ? 'text-error' : ''}>
                  {concurrentOpportunities}
                </span>
                <span className="small">/{constraints.maxConcurrentOpportunities}</span>
              </span>
            ])}
            {end && (
              <span>
                ,{' '}
                <span className={expired ? 'text-error' : ''}>
                  {formatMessage(messages.expireMessage[expired ? 1 : 0], [timeFormatter(end, locale)])}
                </span>
              </span>
            )}
            {requested && (
              <span>
                {' '}
                &ndash; {formatMessage(messages.requestedPlanLabel, [<b key="planName">{requested.name}</b>])}
              </span>
            )}
          </span>
        )}
      </div>
      <div style={{ marginTop: '2px' }}>
        <a
          role="button"
          className="link-button heavy"
          onClick={() => switchModal({ id: MODAL_TALENT_MARKET_PLAN_SELECT, open: true })}
        >
          <span>{messages.changeButton}</span>
        </a>
      </div>
    </div>
  );
};

CompanyTalentMarketPlanBadge.propTypes = {
  plan: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired
};

const intlMessages = {
  cs: {
    changeButton: 'Změnit',
    planLabel: 'Plán {0}',
    requestedPlanLabel: 'Požádáno o plán {0}',
    activeOpportunitiesMessage: '{0} aktivních příležitostí',
    expireMessage: ['vyprší {0}', 'vypršel {0}']
  },
  sk: {
    changeButton: 'Zmeniť',
    planLabel: 'Plán {0}',
    requestedPlanLabel: 'Požiadané o plán {0}',
    activeOpportunitiesMessage: '{0} aktívnych príležitostí',
    expireMessage: ['vyprší {0}', 'vypršal {0}']
  },
  en: {
    changeButton: 'Change',
    planLabel: '{0} plan',
    requestedPlanLabel: 'Requested {0} plan',
    activeOpportunitiesMessage: '{0} active opportunities',
    expireMessage: ['expires {0}', 'expired {0}']
  }
};
const actions = { switchModal };

export default withIntl(connect(null, actions)(CompanyTalentMarketPlanBadge), intlMessages);
