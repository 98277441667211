import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change as changeForm } from 'redux-form';
import _get from 'lodash/get';

import Text from '../../../superfield/form/SuperText';
import Select from '../../../superfield/form/SuperSelect';

import { ECurrency } from '../../../../constants/enum';
import { formatMessage } from '../../../../utils/utils';
import { getProgress } from '../Utils';
import { enumToDataSource, valueHoldersToValues } from '../../../forms/FormUtils';
import { selectOpportunityPostToEdit } from '../../../../selectors/opportunityBuilder';

export const ID = 'opportunityBenefitsPartForm';

const messages = {
  benefitsLabel: 'Benefity pro tuto příležitost',
  benefitsSetFromCompanyMessage: '{0[Nastavit]} benefity jako u profilu společnosti',
  salaryLabel: 'Měsíční odměna',
  salaryMinLabel: 'Od',
  salaryMaxLabel: 'Do',
  salaryCurrencyLabel: 'Měna',
  requiredCurrencyMessage: 'Vyberte měnu',
  salaryMaxLessThanMinMessage: 'Neplatný rozsah mzdy',
  salaryBothFieldsMessage: 'Vyplňte plný rozsah'
};

const currencyFormatter = locale => {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  // this works because fraction digits is set to 0!
  return {
    format: v => {
      return (v || v === 0) && !isNaN(v) ? formatter.format(v) : '';
    },
    parse: value => {
      const parsed = parseInt(value.replace(/[^\d]/g, ''), 10);
      return !isNaN(parsed) ? parsed : '';
    }
  };
};

const getCompanyProfileProperty = (profile, property) =>
  profile && (_get(profile, property) || _get(profile.draft || {}, property));

const BenefitsPart = props => {
  const { dataSources, intl, handleSubmit, companyProfile, changeForm } = props;
  const salaryFormatter = currencyFormatter(intl.locale);
  const profileBenefits = getCompanyProfileProperty(companyProfile, 'benefits');
  const handleSetBenefits = () => changeForm(ID, 'benefits', valueHoldersToValues(profileBenefits));
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="benefits"
            component={Select}
            label={messages.benefitsLabel}
            dataSource={dataSources.benefits}
            helperText={
              !!profileBenefits &&
              formatMessage(messages.benefitsSetFromCompanyMessage, [
                val => (
                  <a key="0" role="button" onClick={handleSetBenefits}>
                    {val}
                  </a>
                )
              ])
            }
            multi
            filterable
          />
        </div>
      </div>
      <h6>{messages.salaryLabel}</h6>
      <div className="row">
        <div className="col-sm-12" style={{ padding: '0 4px' }}>
          <div className="container-flex-row fw-yes">
            <div className="flex1" style={{ padding: '0 8px' }}>
              <Field name="salaryMin" component={Text} label={messages.salaryMinLabel} {...salaryFormatter} />
            </div>
            <div className="flex1" style={{ padding: '0 8px' }}>
              <Field name="salaryMax" component={Text} label={messages.salaryMaxLabel} {...salaryFormatter} />
            </div>
            <div style={{ width: '100px', padding: '0 8px' }}>
              <Field
                name="salaryCurrency"
                component={Select}
                label={messages.salaryCurrencyLabel}
                dataSource={dataSources.currencies}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const validate = values => {
  const errors = {};
  if (values.salaryMin != null || values.salaryMax != null) {
    if (!values.salaryCurrency) {
      errors.salaryCurrency = messages.requiredCurrencyMessage;
    }
    if (values.salaryMin == null || values.salaryMax == null) {
      errors.salaryMin = messages.salaryBothFieldsMessage;
    }
    if (values.salaryMin != null && values.salaryMax != null && values.salaryMin > values.salaryMax) {
      errors.salaryMin = messages.salaryMaxLessThanMinMessage;
    }
  }
  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    options: {
      dataSources: { all }
    },
    entities: {
      companyProfile: { profile }
    }
  } = state;
  const post = selectOpportunityPostToEdit(state, ownProps);
  const opportunity = post.opportunity || {};
  const dataSources = {
    currencies: enumToDataSource(ECurrency, {}, (msg, ec) => ec.key),
    benefits: (all && all.benefits) || []
  };
  const matchConfig = opportunity.matchConfig || {};
  return {
    intl,
    dataSources,
    companyProfile: profile,
    initialValues: {
      benefits: valueHoldersToValues(_get(matchConfig, 'benefits.items')),
      salaryMin: _get(matchConfig, 'salary.min'),
      salaryMax: _get(matchConfig, 'salary.max'),
      salaryCurrency: _get(matchConfig, 'salary.currency')
    }
  };
};

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

BenefitsPart.ID = ID;
BenefitsPart.getProgressFromPost = post => {
  const matchConfig = post?.opportunity?.matchConfig;
  return getProgress([
    () => matchConfig?.benefits?.items?.length > 0,
    () => matchConfig?.salary?.min != null || matchConfig?.salary?.max != null
  ]);
};
BenefitsPart.getProgressFromValues = v =>
  getProgress([() => v?.benefits?.length > 0, () => v?.salaryMin != null || v?.salaryMax != null]);

export default connect(mapStateToProps, { changeForm })(reduxForm(formConfig)(BenefitsPart));
