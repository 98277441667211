import React, { useState, useRef, useEffect, useCallback } from 'react';
import _map from 'lodash/map';

import IconButton from '../mui/IconButton';
import Popover from '../mui/Popover';
import EchoGridCell from './EchoGridCell';

import { putObject, getObject } from '../../persistence/storage';
import withIntl from '../withIntl';

const ECHO_HELP_VISITED_KEY = '/components/echo/help/v0/visited';

const ECHO_LEVELS = [
  { value: 1, color: '#91CA44' },
  { value: 2, color: '#F4F5F7' },
  { value: 3, color: '#F4F5F7' },
  { value: 4, color: '#E9AB44' },
  { value: 5, color: '#BF4844' }
];

const EchoHelpPopover = ({ intl: { messages } }) => {
  const [open, setOpen] = useState(!getObject(ECHO_HELP_VISITED_KEY));

  useEffect(() => putObject(ECHO_HELP_VISITED_KEY, true), []);

  const handleToggle = useCallback(() => setOpen(open => !open), [setOpen]);
  const container = useRef(null);

  return (
    <div ref={container}>
      <IconButton onClick={handleToggle}>
        <i className="material-icons">menu_book</i>
      </IconButton>
      <Popover open={open} onRequestClose={handleToggle} anchorEl={container?.current}>
        <div className="mui-padded-x2" style={{ maxWidth: '420px' }}>
          <div className="container-flex-row jc-space-between">
            <h3>{messages.title}</h3>
            <IconButton onClick={handleToggle}>
              <i className="material-icons">clear</i>
            </IconButton>
          </div>
          <div className="mui-padded-vertical">
            {_map(ECHO_LEVELS, (l, idx) => (
              <div key={l.value} className="container-flex-row mui-padded-vertical-half">
                <div style={{ width: '48px', height: '48px', minWidth: '48px' }} className="mui-padded-half">
                  <EchoGridCell color={l.color} text={l.value} />
                </div>
                <div className="mui-padded-half mui-padded-horizontal">
                  <strong>{messages.levels[idx].title}</strong>
                  <br />
                  <p>{messages.levels[idx].description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};

const intlMessages = {
  cs: {
    title: 'Jak chápat Well-being',
    levels: [
      {
        title: 'Pásmo vysoké spokojenosti',
        description: 'V této oblasti vše klape! Není nutná žádná akce.'
      },
      {
        title: 'Pásmo spokojenosti',
        description: 'Převládají pocity spokojenosti. Doporučujeme pouze dlouhodobě sledovat.'
      },
      {
        title: 'Středové-neutrální pásmo',
        description: 'Doporučujeme sledovat - zejména v období změn (transformace, akvizice, změny vedení apod.).'
      },
      {
        title: 'Pásmo vynořující se nespokojenosti',
        description:
          'Doporučujeme s oblastí aktivně pracovat. Hledat rychlé náplasti i dlouhodobé (strategické) cesty ke zlepšení.'
      },
      {
        title: 'Volání o pomoc, pásmo nespokojenosti',
        description:
          'Doporučujeme rychlou akci na podchycení akutních rizik a větší strukturální změny, které pomohou docílit trvalé změny.'
      }
    ]
  },
  sk: {
    title: 'Ako chápať Well-being',
    levels: [
      {
        title: 'Pásmo vysokej spokojnosti',
        description: 'V tejto oblasti všetko klape! Nie je nutná žiadna akcia.'
      },
      {
        title: 'Pásmo spokojnosti',
        description: 'Prevládajú pocity spokojnosti. Odporúčame len dlhodobo sledovať.'
      },
      {
        title: 'Stredovo-neutrálne pásmo',
        description: 'Odporúčame sledovať - najmä v období zmien (transformácie, akvizície, zmeny vedenia apod.).'
      },
      {
        title: 'Pásmo vynorujúcej sa nespokojnosti',
        description:
          'Odporúčame s oblasťou aktívne pracovať. Hľadať rýchle náplasti aj dlhodobé (strategické) cesty k zlepšeniu.'
      },
      {
        title: 'Volanie o pomoc, pásmo nespokojnosti',
        description:
          'Odporúčame rýchlu akciu na podchytenie akútnych rizík a väčšie štrukturálne zmeny, ktoré pomôžu docieliť trvalé zmeny.'
      }
    ]
  },
  en: {
    title: 'How to understand Well-being',
    levels: [
      {
        title: 'High satisfaction zone',
        description: 'Everything is going well in this area! No action required.'
      },
      {
        title: 'Satisfaction zone',
        description: 'Feelings of satisfaction prevail. We recommend only long-term follow-up.'
      },
      {
        title: 'Middle-neutral zone',
        description:
          'We recommend monitoring - especially in times of change (transformations, acquisitions, management changes, etc.).'
      },
      {
        title: 'Emerging discontent zone',
        description:
          'We recommend working actively with the area. Look for both quick fixes and long-term (strategic) paths to improvement.'
      },
      {
        title: 'Discontent zone (cry for help)',
        description:
          'We recommend swift action to address acute risks and larger structural changes to help achieve lasting change.'
      }
    ]
  }
};

export default withIntl(EchoHelpPopover, intlMessages);
