import React from 'react';
import { Link } from 'react-router';

import Button from '../mui/Button';
import TeamSelect from './TeamSelect';

import withIntl from '../withIntl';
import MembersCountMessage from './MembersCountMessage';
import { formatMessage } from '../../utils/utils';

class TargetGroupConnectPanel extends React.Component {
  state = {
    connecting: false
  };

  handleConnectStart = () => this.setState({ connecting: true });
  handleConnectClose = () => this.setState({ connecting: false });
  handleConnectConfirm = grp => {
    const { onConnect } = this.props;
    onConnect(grp);
    this.handleConnectClose();
  };
  handleDisconnect = () => {
    const { onConnect } = this.props;
    onConnect(null);
  };

  footerRenderer = () => {
    const {
      intl: { messages }
    } = this.props;
    return (
      <div className="mui-padded text-muted border-top border-color-black__50">
        <div className="container-flex-row ai-center fw-yes">
          <div className="mui-padded-horizontal">{messages.createGroupMessage}</div>
          <div className="mui-padded-half mui-padded-horizontal">
            <Button
              containerElement={<Link to={'/groups/new'} />}
              label={messages.createButton}
              icon={<i className="material-icons text-sz-lg">add</i>}
              mini
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      team,
      target,
      intl: { messages }
    } = this.props;
    const { connecting } = this.state;
    return (
      <div style={{ padding: '8px 8px 0' }}>
        {target?.group ? (
          <div className="text-primary bg-highlight-light border-radius-top mui-padded-half container-flex-row ai-center fw-yes">
            <div className="mui-padded-half mui-padded-horizontal">
              {formatMessage(messages.linkedToMessages[target.group.type], [
                <b key={'groupName'}>
                  <Link to={`/groups/${target.group.id}`}>{target.group.name}</Link>
                </b>
              ])}{' '}
              <span className="text-primary-sub text-sz-sm">
                (<MembersCountMessage type={target.group.type} count={target.members?.items?.length || 0} />)
              </span>
            </div>
            <div className="mui-padded-half">
              <Button
                mini
                label={messages.changeLabel}
                icon={<i className="material-icons text-sz-lg">link</i>}
                onClick={this.handleConnectStart}
              />
            </div>
            <div className="mui-padded-half">
              <Button
                mini
                label={messages.unlinkLabel}
                icon={<i className="material-icons text-sz-lg">link_off</i>}
                onClick={this.handleDisconnect}
              />
            </div>
          </div>
        ) : (
          <div className="bg-secondary-light border-radius-top mui-padded-half">
            <div className="container-flex-row fw-yes ai-center">
              <div className="mui-padded text-secondary">
                {messages.linkGroupMessage}
                <div className="text-sz-sm text-muted">{messages.linkGroupDescription}</div>
              </div>
              <div className="mui-padded-half">
                <Button
                  label={messages.linkLabel}
                  onClick={this.handleConnectStart}
                  secondary
                  icon={<i className="material-icons text-sz-lg">link</i>}
                  mini
                />
              </div>
            </div>
          </div>
        )}
        {connecting && (
          <TeamSelect
            currentTeamId={target?.group?.id || team.id}
            companyId={team.company.id}
            onSelect={this.handleConnectConfirm}
            onClose={this.handleConnectClose}
            footerRenderer={this.footerRenderer}
          />
        )}
      </div>
    );
  }
}

const intlMessages = {
  cs: {
    createButton: 'Vytvořit',
    createGroupMessage: 'Vytvořte tým nebo skupinu',
    linkedToMessages: {
      TEAM: 'Připojeno k týmu {0}',
      GROUP: 'Připojeno ke skupině {0}',
      HIRING: 'Připojeno k otevřené pozici {0}'
    },
    linkGroupMessage: 'Připojte tým nebo skupinu...',
    linkGroupDescription:
      '... a zjistěte, jak uchazeči odpovídají její kultuře nebo jak přispívají ke složení kompetencí',
    unlinkLabel: 'Odpojit',
    changeLabel: 'Změnit',
    linkLabel: 'Připojit'
  },
  sk: {
    createButton: 'Vytvoriť',
    createGroupMessage: 'Vytvorte tím alebo skupinu',
    linkedToMessages: {
      TEAM: 'Pripojené k tímu {0}',
      GROUP: 'Pripojené k skupine {0}',
      HIRING: 'Pripojené k otvorenej pozícii {0}'
    },
    linkGroupMessage: 'Pripojte tím alebo skupinu...',
    linkGroupDescription:
      '... a zistite, ako uchádzači zapadajú do jej kultúry alebo ako prispievajú k zloženiu kompetencií',
    unlinkLabel: 'Odpojiť',
    changeLabel: 'Zmeniť',
    linkLabel: 'Pripojiť'
  }
  ,
  en: {
    createButton: 'Create',
    createGroupMessage: 'Create a team or a group',
    linkedToMessages: {
      TEAM: 'Linked to {0} team',
      GROUP: 'Linked to {0} group',
      HIRING: 'Linked to {0} open position'
    },
    linkGroupMessage: 'Link a team or a group...',
    linkGroupDescription:
      '... and see how the candidates fit its culture or how they contribute to competency composition',
    unlinkLabel: 'Unlink',
    changeLabel: 'Change',
    linkLabel: 'Link'
  }
};

export default withIntl(TargetGroupConnectPanel, intlMessages);
