import React from 'react';

import TabLinkButton from './TabLinkButton';
import InfoTooltip from '../../basic/InfoTooltip';
import FitRankLabel from '../FitRankLabel';
import NumberIndicator from '../NumberIndicator';
import MetricsStats from '../MetricsStats';

import { computeCrossComparisonFit, getNumericDualMetrics } from '../../../utils/analytics';

import { EGroupMembership, EGroupType } from '../../../constants/enum';
import { ETab } from '../Constants';

import withIntl from '../../withIntl';

const Title = props => {
  const { children, help } = props;
  return (
    <div className="mui-label container-flex-row ai-center fw-yes">
      <span style={{ verticalAlign: 'middle' }}>{children}</span>
      {help && (
        <InfoTooltip
          style={{ marginLeft: '4px' }}
          content={<div className="text-justify" dangerouslySetInnerHTML={{ __html: help }} />}
        >
          <i className="material-icons" style={{ fontSize: '16px', opacity: 0.7 }}>
            help
          </i>
        </InfoTooltip>
      )}
      <span className="show-on-hover">&nbsp;&middot;&nbsp;</span>
      <span className="show-on-hover" style={{ letterSpacing: 0, textTransform: 'none' }}>
        <TabLinkButton tab={ETab.ANALYTICS} />
      </span>
    </div>
  );
};

const getConvergenceStats = metrics => {
  return [...metrics].sort((a, b) => b.convergence - a.convergence);
};

const getFitStats = metrics => {
  const res = [];
  for (let i = 0; i < metrics.length; i += 1) {
    const m = metrics[i];
    const data = m.data || [];
    let fit = 0;
    for (let j = 0; j < data.length; j += 1) {
      const { values } = data[j];
      // overlay is 2nd
      if (values && values[1] && values[1].value > 0) {
        fit = values[1].fit;
        break;
      }
    }
    res.push({ ...m, fit });
  }
  return res.sort((a, b) => b.fit - a.fit);
};

const getConvergence = m => m.convergence;
const getFit = m => m.fit;

const CultureFitScores = props => {
  const {
    activity,
    type,
    intl: { messages }
  } = props;
  const metrics = getNumericDualMetrics(activity.metricDefinitions);
  const activeIndex = activity.managers.length > 0 ? 0 : null;
  const data = computeCrossComparisonFit(metrics, activity.managers, activity.members, activeIndex);
  const {
    participantMetrics,
    convergence,
    participantCounts: { sourceFinished, targetFinished }
  } = data;
  return (
    <div className="container-flex-row fw-yes">
      {type === EGroupType.TEAM && (
        <div className="border-right border-color-primary__50">
          <div className="mui-padded mui-padded-horizontal-x2 show-on-hover-parent">
            <Title help={messages.fitHelp}>{messages.managerLabel}</Title>
            <NumberIndicator
              rankLabel={sourceFinished > 0 && <FitRankLabel value={participantMetrics[0].fit} />}
              number={sourceFinished > 0 && 100 * participantMetrics[0].fit}
              present={sourceFinished > 0}
              fallback={<em>N/A</em>}
              colorVariant={EGroupMembership.MANAGER.key}
            >
              {sourceFinished > 0 && (
                <div className="mui-padded-vertical-half">
                  <MetricsStats
                    metrics={getFitStats(data.metrics)}
                    colorVariant={EGroupMembership.MANAGER.key}
                    getStat={getFit}
                  />
                </div>
              )}
            </NumberIndicator>
          </div>
        </div>
      )}
      <div className="mui-padded mui-padded-horizontal-x2 show-on-hover-parent">
        <Title help={messages.convergenceHelp}>
          {messages.convergenceLabels[type.key] || messages.convergenceLabel}
        </Title>
        <NumberIndicator
          rankLabel={targetFinished > 0 && <FitRankLabel value={convergence} type="convergence" />}
          number={100 * convergence}
          present={targetFinished > 0}
          fallback={<em>N/A</em>}
          colorVariant={EGroupMembership.MEMBER.key}
        >
          {targetFinished > 0 && (
            <div className="mui-padded-vertical-half">
              <MetricsStats
                metrics={getConvergenceStats(data.metrics)}
                colorVariant={EGroupMembership.MEMBER.key}
                getStat={getConvergence}
              />
            </div>
          )}
        </NumberIndicator>
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    managerLabel: 'Fit manažera',
    fitHelp:
      'Popisuje míru shody preferencí manažera se skupinou. Vyšší číslo značí vyšší míru shody. Nižší číslo znamená, že manažer může mít tendence jednat v otázkách jednotlivých hodnot odlišně. Vyšší číslo značí, že manažer je podobně „nastaven“ jako většina týmu. Nižší číslo naopak může znamenat, že se v některých aspektech neshodují a bez vědomé snahy může manažer méně efektivně působit na jednotlivé členy skupiny v oblasti motivace, vedení a odměňování. To zvyšuje také riziko nižší spokojenosti, angažovanosti a výkonu u jednotlivců, kteří vnímají jednotlivé aspekty odlišně a mohou mít pocit, že jim jejich okolí méně rozumí.',
    convergenceLabel: 'Konvergence týmu',
    convergenceLabels: {
      TEAM: 'Konvergence týmu',
      GROUP: 'Konvergence skupiny',
      HIRING: 'Konvergence kandidátů'
    },
    convergenceHelp:
      'Popisuje míru, do jaké skupina preferuje nebo vnímá aspekty firemní kultury stejně. Vyšší číslo značí vyšší jednotu a soulad jednotlivců ve skupině. Nižší číslo značí, že jednotliví členové mohou vnímat některé aspekty odlišně. Více konvergující skupiny mají podobné potřeby v otázkách motivace, vedení a odměňování a lze k nim proto přistupovat jednotným způsobem. Čím méně konvergentní je skupina, tím více se budou lišit individuální potřeby jednotlivců a pro zachování jejich adekvátního výkonu, motivace a spokojenosti bude proto důležitý individuální, transformačně-situační přístup (např. manažera nebo firemních politik a mechanismů).'
  },
  sk: {
    managerLabel: 'Fit manažéra',
    fitHelp:
      'Popisuje mieru zhody preferencií manažéra so skupinou. Vyššie číslo znamená vyššiu mieru zhody. Nižšie číslo znamená, že manažér môže mať tendencie konať v otázkach jednotlivých hodnôt odlišne. Vyššie číslo znamená, že manažér je podobne „nastavený“ ako väčšina tímu. Nižšie číslo naopak môže znamenať, že sa v niektorých aspektoch nezhodujú a bez vedomej snahy môže manažér menej efektívne pôsobiť na jednotlivých členov skupiny v oblasti motivácie, vedenia a odmeňovania. To tiež zvyšuje riziko nižšej spokojnosti, angažovanosti a výkonu u jednotlivcov, ktorí vnímajú jednotlivé aspekty odlišne a môžu mať pocit, že im ich okolie menej rozumie.',
    convergenceLabel: 'Konvergencia tímu',
    convergenceLabels: {
      TEAM: 'Konvergencia tímu',
      GROUP: 'Konvergencia skupiny',
      HIRING: 'Konvergencia kandidátov'
    },
    convergenceHelp:
      'Popisuje mieru, do akej skupina preferuje alebo vníma aspekty firemnej kultúry rovnako. Vyššie číslo znamená vyššiu jednotu a súlad jednotlivcov v skupine. Nižšie číslo znamená, že jednotliví členovia môžu vnímať niektoré aspekty odlišne. Viac konvergujúce skupiny majú podobné potreby v otázkach motivácie, vedenia a odmeňovania a dá sa k nim preto pristupovať jednotným spôsobom. Čím menej konvergentná je skupina, tým viac sa budú líšiť individuálne potreby jednotlivcov a na zachovanie ich adekvátneho výkonu, motivácie a spokojnosti bude preto dôležitý individuálny, transformačne-situačný prístup (napr. manažéra alebo firemných politík a mechanizmov).'
  },
  en: {
    managerLabel: 'Manager Fit',
    fitHelp:
      "Describes the degree of balance between the manager's preferences and the group. A higher number indicates a higher degree of harmony. A lower number means that the manager's value and work culture setup are different from those of the group. A higher number means that the manager and the group are similarly “set up” on the value and motivation level, supporting mutual understanding. On the contrary, a lower number may mean differences, which need to be approached consciously by the manager to influence, lead, and reward individual group members. It also increases the risk of lower satisfaction, engagement, and performance in individuals who perceive aspects differently and may feel less understood by the manager.",
    convergenceLabel: 'Team Convergence',
    convergenceLabels: {
      TEAM: 'Team Convergence',
      GROUP: 'Group Convergence',
      HIRING: 'Candidates Convergence'
    },
    convergenceHelp:
      'The extent to which the group prefers or perceives work culture elements in the same way. A higher number indicates a higher unity and understanding. A lower number suggests that individual members may perceive some aspects differently. More converging groups have similar needs in terms of motivation, leadership, and reward and can be approached uniformly. The less convergent the group, the more personal needs will differ. To maintain adequate performance, motivation, and satisfaction, a more tailored, transformational, or situational approach (e.g., from the manager or through company policies and mechanisms) will be necessary.'
  }
};

export default withIntl(CultureFitScores, intlMessages);
