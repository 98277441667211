import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import find from 'lodash/find';
import values from 'lodash/values';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';

import { get } from '../utils/utils';

const inRange = (val, min, max) => (min == undefined || min <= val) && (max == undefined || val <= max);

export const matchesString = (value, flt) => !flt || (value && value.toLowerCase().indexOf(flt) > -1);

export const createMatcher = (filterValues, type) => {
  const { items, matchAll, enabled, start, end } = filterValues;
  if (type === 'RANGE' || type === 'DATE') {
    if (!enabled) {
      return () => true;
    }
    return values => !!find(values, v => inRange(v.value, start, end));
  }
  if (matchAll) {
    const count = size(filter(items, i => i));
    return values => values.length >= count && filter(values, v => items[v.value]).length === count;
  }
  return values => !!find(values, v => items[v.value]);
};

export const createFromValuesMatcher = (filterValuesExtractors) => (item, fromValues) => {
  let matches = true;
  for (let key in fromValues) {
    if (fromValues.hasOwnProperty(key)) {
      const keyParts = key.split('_');
      const valueExtractor = filterValuesExtractors[keyParts[0]];
      if (valueExtractor) {
        const { extract, custom, type } = valueExtractor;
        const isRange = type === 'RANGE' || type === 'DATE';
        const filterValues = fromValues[key];
        if (
          !filterValues ||
          isEmpty(filterValues) ||
          (isRange && !filterValues.enabled) ||
          (!isRange && isEmpty(filterValues.items))
        ) {
          continue;
        }
        const extractedValues = extract(item);
        const values = custom ? (extractedValues[keyParts[1]] || {}).items : extractedValues;
        const matcher = createMatcher(filterValues, type);
        matches &= matcher(values || []);
      }
      // early break
      if (!matches) {
        return false;
      }
    }
  }
  return matches;
};

export const createSingleItemExtract = (path, valueTransformer) => p => {
  const value = get(p, path);
  const transformedValue = valueTransformer ? valueTransformer(value) : value;
  if (transformedValue != undefined) {
    return [{ value: transformedValue }];
  }
  return [];
};

/*
 RANGE:
 - min
 - max
 - start
 - end
 - filteredStart
 - filteredEnd
 - step
 */

const nMin = (a, b) => (a == undefined ? b : b == undefined ? a : Math.min(a, b));
const nMax = (a, b) => (a == undefined ? b : b == undefined ? a : Math.max(a, b));

export const extractRange = (e, items, filteredItemsMap, fromValues) => {
  const { extract, ...rest } = e;
  // refactored from reduce to improve performance
  const fv = fromValues && fromValues[e.key];
  const r = { start: fv && fv.start || null, end: fv && fv.end || null, startFiltered: null, endFiltered: null, count: 0, countFiltered: 0 };
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    const values = extract(item);
    const filtered = filteredItemsMap[item.id];
    const v = { start: null, end: null };
    // refactored from reduce to improve performance
    for (let j = 0; j < values.length; j += 1) {
      const { value } = values[j];
      v.start = nMin(v.start, value);
      v.end = nMax(v.end, value);
    }
    if (v.start != undefined && v.end != undefined) {
      r.start = nMin(r.start, v.start);
      r.end = nMax(r.end, v.end);
      r.startFiltered = filtered ? nMin(r.startFiltered, v.start) : r.startFiltered;
      r.endFiltered = filtered ? nMax(r.endFiltered, v.end) : r.endFiltered;
      r.count = r.count + 1;
      r.countFiltered = r.countFiltered + (filtered ? 1 : 0);
    }
  }
  if (r.start != undefined && r.end != undefined && r.start !== r.end) {
    return {
      [e.key]: {
        ...rest,
        ...r
      }
    };
  }
  return null;
};

export const extractCategory = (e, items, filteredItemsMap, fromValues) => {
  const { extract, ...rest } = e;
  // refactored from reduce to improve performance
  const valuesMap = {};
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    const values = e.custom ? extract(item) : { [e.key]: { ...rest, items: extract(item) } };
    const presentInFiltered = filteredItemsMap[item.id];
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        const newItems = presentInFiltered ? map(value.items, i => ({ ...i, presentInFiltered })) : value.items;
        const prev = valuesMap[key] || { ...value, items: [] };
        for (let j = 0; j < newItems.length; j += 1) {
          prev.items.push(newItems[j]);
        }
        valuesMap[key] = prev;
      }
    }
  }
  const filterValuesMap = {};
  if (fromValues) {
    if (e.custom) {
      for (let prop in fromValues) {
        if (fromValues.hasOwnProperty(prop)) {
          if (prop.startsWith(`${e.key}_`)) {
            filterValuesMap[prop] = fromValues[prop];
          }
        }
      }
    } else {
      const fv = fromValues[e.key];
      if (fv) {
        filterValuesMap[e.key] = fv;
      }
    }
  }
  if (isEmpty(valuesMap) && isEmpty(filterValuesMap)) {
    return null;
  }
  const newResult = {};
  for (let key in valuesMap) {
    if (valuesMap.hasOwnProperty(key)) {
      const value = valuesMap[key];
      // refactored from reduce to improve performance
      const itemsMap = {};
      const arr = value.items || [];
      for (let i = 0; i < arr.length; i += 1) {
        const { value, label, color, none, presentInFiltered } = arr[i];
        const prev = itemsMap[value] || { count: 0, countFiltered: 0 };
        itemsMap[value] = {
          count: prev.count + 1,
          countFiltered: prev.countFiltered + (presentInFiltered ? 1 : 0),
          value,
          label,
          color,
          none
        };
      }
      const items = orderBy(values(itemsMap), ['count'], ['desc']);
      if (items.length > 0) {
        newResult[key] = {
          ...value,
          items
        };
      }
    }
  }
  for (let key in filterValuesMap) {
    if (filterValuesMap.hasOwnProperty(key)) {
      const filterValue = filterValuesMap[key];
      const value = newResult[key] || { items: [] };
      const fvItems = filterValue && { ...filterValue.items } || {};
      for (let i = 0; i < value.items.length; i += 1) {
        const item = value.items[i];
        if (fvItems[item.value]) {
          delete fvItems[item.value];
        }
      }
      for (let fvVal in fvItems) {
        if (fvItems.hasOwnProperty(fvVal)) {
          if (fvItems[fvVal]) {
            value.items.push({ value: fvVal, count: 0, countFiltered: 0 });
          }
        }
      }
    }
  }
  return newResult;
};

export const getFilterValues = (extractors, items, filteredItems, fromValues) => {
  const filteredItemsMap = {};
  // refactored from reduce to improve performance
  for (let i = 0; i < filteredItems.length; i += 1) {
    const { id } = filteredItems[i];
    filteredItemsMap[id] = true;
  }
  const result = {};
  for (let i = 0; i < extractors.length; i += 1) {
    const e = extractors[i];
    const r =
      (e.type === 'RANGE' || e.type === 'DATE')
        ? extractRange(e, items, filteredItemsMap, fromValues)
        : extractCategory(e, items, filteredItemsMap, fromValues);
    if (r) {
      for (let prop in r) {
        if (r.hasOwnProperty(prop)) {
          result[prop] = r[prop];
        }
      }
    }
  }
  return result;
};
