import { browserHistory } from 'react-router';
import { toggleSidebar } from '../actions/options/sidebarActions';

export default function routeChangeMiddleware({ dispatch, getState }) {
  browserHistory.listen(() =>  {
    const { options: { sidebar } } = getState();
    if (sidebar && sidebar.open) {
      dispatch(toggleSidebar());
    }
  });
  return next => action => next(action);
};
