import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change as changeForm, formValueSelector } from 'redux-form';
import _map from 'lodash/map';

import RichText from '../../../formFields/RichTextEditor';
import ImageUploadField from './ImageUploadField';
import MultiImageUploadField from './MultiImageUploadField';

import { selectCompanyProfileToEdit } from '../../../../selectors/companyProfileBuilder';

const ID = 'companyProfileMediaPartForm';

const messages = {
  descriptionLabel: 'Řekněte lidem o vaší společnosti',
  descriptionPlaceholder: 'Proč jste zajímavý partner pro talenty a jejich kariéru?',
  descriptionHelperText: 'Ideální délka do 500 znaků. Tip: Do textu můžete vkládat obrázky pomocí URL adresy. Stačí kliknout na ikonu obrázku v liště nástrojů.',
  logoLabel: 'Logo',
  logoChangeButton: 'Upravit',
  logoSetButton: 'Nahrát',
  logoFileHelperText: 'Doporučujeme čtvercový formát, alespoň 100x100 px',
  headerImageLabel: 'Úvodní fotka',
  headerImageFileHelperText: 'Doporučujeme formát 4:1, alespoň 640x160 px',
  momentsLabel: 'Momenty',
  momentsHelperText: 'Nahrajte další fotky, které nejlépe vystihují vaši společnost',
  momentsFileHelperText: 'Libovolný formát, doporučujeme alespoň 640x480 px',
  // not used
  imageRequiredMessage: 'Vyberte obrázek',
  imageRequiredMultiMessage: 'Vyberte alespoň 1 obrázek'
};

const MediaPart = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="description"
            component={RichText}
            label={messages.descriptionLabel}
            placeholder={messages.descriptionPlaceholder}
            helperText={messages.descriptionHelperText}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="logoUrl"
            component={ImageUploadField}
            label={messages.logoLabel}
            buttonChangeLabel={messages.logoChangeButton}
            buttonSetLabel={messages.logoSetButton}
            aspectRatio={1}
            maxFileSize={1024 * 1024}
            fileHelperText={messages.logoFileHelperText}
            renderImage={url => (
              <div
                style={{
                  width: 64,
                  height: 64,
                  backgroundImage: `url(${url})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  marginRight: '8px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  borderRadius: 2
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="headerImageUrl"
            component={ImageUploadField}
            label={messages.headerImageLabel}
            buttonChangeLabel={messages.logoChangeButton}
            buttonSetLabel={messages.logoSetButton}
            aspectRatio={4}
            maxFileSize={5 * 1024 * 1024}
            fileHelperText={messages.headerImageFileHelperText}
            renderImage={url => (
              <div
                style={{
                  width: '100%',
                  paddingTop: '25%',
                  backgroundImage: `url(${url})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  marginBottom: '8px'
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="moments"
            component={MultiImageUploadField}
            label={messages.momentsLabel}
            description={messages.momentsHelperText}
            maxFileSize={10 * 1024 * 1024}
            fileHelperText={messages.momentsFileHelperText}
            renderImage={(url, idx) => (
              <img
                src={url}
                alt={`Moment #${idx + 1}`}
                style={{
                  maxWidth: '100%',
                  maxHeight: '96px'
                }}
              />
            )}
          />
        </div>
      </div>
    </form>
  );
};

const validate = () => {
  return {};
};

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

MediaPart.ID = ID;

const selector = formValueSelector(ID);

const mapStateToProps = (state, ownProps) => {
  const profile = selectCompanyProfileToEdit(state, ownProps);
  return {
    logoUrl: selector(state, 'logoUrl'),
    headerImageUrl: selector(state, 'headerImageUrl'),
    initialValues: {
      logoUrl: profile.logoUrl,
      headerImageUrl: profile.headerImageUrl,
      moments: profile.moments && _map(profile.moments, m => m.url),
      description: profile.description
    }
  };
};

export default connect(mapStateToProps, { changeForm })(reduxForm(formConfig)(MediaPart));
