import { combineReducers } from 'redux';

import chartReducer from './chartReducer';
import overviewChartReducer from './overviewChartReducer';
import gridsReducer from './gridsReducer';
import sidebarReducer from './sidebarReducer';
import loaderReducer from './loaderReducer';
import modalReducer from './modalReducer';
import snackbarsReducer from './snackbarsReducer';
import projectFilterReducer from './projectFilterReducer';
import notificationsReducer from './notificationsReducer';
import metricsConfigReducer from './metricsConfigReducer';
import skillScatterChartReducer from './skillScatterChartReducer';
import guideReducer from './guideReducer';
import projectEditReducer from './projectEditReducer';
import crossComparisonReducer from './crossComparisonReducer';
import participantFiltersReducer from './participantFiltersReducer';
import dataSourcesReducer from './dataSourcesReducer';
import projectOverviewReducer from './projectOverviewReducer';
import groupMemberFiltersReducer from './groupMemberFiltersReducer';

const reducer = combineReducers({
  chart: chartReducer,
  overviewChart: overviewChartReducer,
  grids: gridsReducer,
  sidebar: sidebarReducer,
  loader: loaderReducer,
  modal: modalReducer,
  snackbars: snackbarsReducer,
  projectFilter: projectFilterReducer,
  notifications: notificationsReducer,
  metricsConfig: metricsConfigReducer,
  skillScatterChart: skillScatterChartReducer,
  guide: guideReducer,
  projectEdit: projectEditReducer,
  crossComparison: crossComparisonReducer,
  participantFilters: participantFiltersReducer,
  dataSources: dataSourcesReducer,
  projectOverview: projectOverviewReducer,
  groupMemberFilters: groupMemberFiltersReducer
});

export default reducer;
