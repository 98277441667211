import React, { useEffect, useMemo, useState } from 'react';

import SuperText from './SuperText';

const DebouncedSuperText = ({  debounce, value, onChange, ...props }) => {
  const [val, setVal] = useState(value);

  const handleChange = useMemo(() => e => setVal(e.target.value), []);

  useEffect(() => {
    const timeout = setTimeout(() => onChange(val), debounce);
    return () => clearTimeout(timeout);
  }, [val, debounce]);

  return <SuperText value={val} onChange={handleChange} {...props} />;
};

export default DebouncedSuperText;
