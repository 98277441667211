import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Tooltip from '../tooltip/Tooltip';

const IconButton = props => {
  const { tooltip, tooltipPosition, style, children, ...rest } = props;
  const btnStyle = {
    minWidth: 0,
    width: 36,
    borderRadius: 18,
    ...style
  };
  return (
    <div className="inline-container tooltip-element">
      <Button icon={children} style={btnStyle} {...rest} />
      {tooltip && <Tooltip position={tooltipPosition}>{tooltip}</Tooltip>}
    </div>
  );
};

IconButton.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  tooltip: PropTypes.any
};

export default IconButton;
