import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _values from 'lodash/values';

import RadioButton from 'material-ui/RadioButton/RadioButton';

import RadioButtonGroup from '../../../formFields/RadioButtonGroup';

import { selectProjectToEdit } from './projectEditSelectors';
import { EProjectType } from '../../../../constants/enum';

const descStyle = {
  lineHeight: '1.4em',
  maxWidth: '40em'
};
const buttonStyle = {
  padding: '8px'
};

const ProjectTypePart = props => {
  const { intl, isEdit, dataSources } = props;
  const { handleSubmit } = props;
  const messages = intl.messages.components.pages.private.projectEdit.intro;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12">
          <div className="mui-fields-container">
            <Field
              name="type"
              label={isEdit ? messages.editTitle : messages.title}
              indicateRequired
              component={RadioButtonGroup}
              fullWidth
            >
              {_map(dataSources.projectTypes, ({ value, label, description }) => (
                <RadioButton
                  key={value}
                  value={value}
                  label={
                    <div>
                      <div>{label}</div>
                      <div style={descStyle}>
                        <small>{description}</small>
                      </div>
                    </div>
                  }
                  style={buttonStyle}
                />
              ))}
            </Field>
          </div>
        </div>
      </div>
    </form>
  );
};

ProjectTypePart.ID = 'projectProjectType';

const baseLocalSelectIntl = state => state.intl;

const localSelectDataSources = createSelector([baseLocalSelectIntl], intl => {
  return {
    projectTypes: _map(
      _filter(_values(EProjectType), t => !t.view && !t.chat),
      ({ key }) => {
        const tMsg = intl.messages.components.pages.private.projectEdit.projectTypes[key];
        return {
          value: key,
          label: tMsg.label,
          description: tMsg.description
        };
      }
    )
  };
});

const selector = formValueSelector(ProjectTypePart.ID);

const baseSelectCurrentProjectType = state => selector(state, 'type');
export const selectCurrentProjectType = createSelector([baseSelectCurrentProjectType, selectProjectToEdit], (t, p) => {
  return p.isActive ? p.type || p.projectType : t;
});

const mapStateToProps = (state, ownProps) => {
  const { intl } = state;
  const project = selectProjectToEdit(state, ownProps);
  return {
    intl,
    dataSources: localSelectDataSources(state, ownProps),
    isEdit: project && project.id,
    initialValues: {
      type: project.type || project.projectType
    }
  };
};

const validate = (values, state) => {
  const errors = {};
  const messages = state.intl.messages.components.pages.private.projectEdit.validation;
  if (!values.type) {
    errors.type = messages.projectTypeEmpty;
  }
  return errors;
};

const formConfig = {
  form: ProjectTypePart.ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

export default connect(mapStateToProps)(reduxForm(formConfig)(ProjectTypePart));
