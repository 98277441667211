import { createAction } from 'redux-actions';
import * as c from '../../constants/constants';

export const setProjectFilterValue = createAction(c.PROJECT_FILTER_VALUE_SET, payload => payload);
export const toggleAdvancedProjectFilter = createAction(c.PROJECT_FILTER_ADVANCED_TOGGLE);
export const setProjectsPage = createAction(c.PAGE_SET_PROJECTS, (page, infinite) => ({ page, infinite }));
export const setProjectFilterLayout = createAction(c.PROJECT_FILTER_LAYOUT_SET, view => view);
export const setProjectFilterFromValues = createAction(c.PROJECT_FILTER_FROM_VALUES_SET, payload => payload);
export const setProjectFilterSort = createAction(c.PROJECT_FILTER_SORT_SET, payload => payload);
export const setProjectFilterDemoShown = createAction(c.PROJECT_FILTER_DEMO_SHOWN_SET, payload => payload);
