import React from 'react';

import ProjectCardStatus from '../../cards/ProjectCardStatus';
import InfoCard from '../InfoCard';

import withIntl from '../../withIntl';

const ProjectStatusInfoCard = ({ project, intl }) => {
  return (
    <InfoCard>
      <ProjectCardStatus project={project} intl={intl} compact />
    </InfoCard>
  );
};

export default withIntl(ProjectStatusInfoCard);
