import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Popover from '../mui/Popover';

class InfoTooltip extends React.Component {
  state = {
    over: false,
    open: false
  };

  componentWillUnmount() {
    this.clearTimeouts();
  }

  clearTimeouts = () => {
    this.clearShowTimeout();
    this.clearHideTimeout();
  };
  clearShowTimeout = () => {
    if (this.showTimeout) {
      this.showTimeout = clearTimeout(this.showTimeout);
    }
  };
  clearHideTimeout = () => {
    if (this.hideTimeout) {
      this.hideTimeout = clearTimeout(this.hideTimeout);
    }
  };
  setRef = el => {
    this.element = el;
  };
  handleMouseEnter = () => {
    const { delay } = this.props;
    this.clearHideTimeout();
    if (delay) {
      this.setState({ over: true });
      this.showTimeout = setTimeout(() => {
        this.setState({ open: true });
      }, delay);
    } else {
      this.setState({ over: true, open: true });
    }
  };
  handleMouseLeave = () => {
    this.setState({ over: false });
    this.clearTimeouts();
    this.hideTimeout = setTimeout(() => {
      this.setState({ open: false });
    }, 200);
  };
  handleClose = () => this.setState({ open: false });

  render() {
    const { content, children, style, dark, compact, contentStyle, tooltipStyle } = this.props;
    const { open } = this.state;
    if (!content) {
      return children;
    }
    const popoverStyle = dark ? { ...tooltipStyle, background: 'rgba(0, 0, 0, 0.82)' } : tooltipStyle;
    const contentClassName = cn({
      'mui-padded-horizontal mui-padded-half': compact,
      'mui-padded-x2': !compact,
      small: true,
      'text-white': dark,
      'text-muted': !dark
    });
    return (
      <div
        ref={this.setRef}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={{ cursor: 'pointer', display: 'inline-block', ...style }}
      >
        {children}
        <Popover
          anchorEl={this.element}
          open={content && open}
          onRequestClose={this.handleClose}
          useLayerForClickAway={false}
          style={popoverStyle}
        >
          <div
            className={contentClassName}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            style={{ maxWidth: '30em', ...contentStyle }}
          >
            {content}
          </div>
        </Popover>
      </div>
    );
  }
}

InfoTooltip.propTypes = {
  content: PropTypes.any,
  contentStyle: PropTypes.object,
  tooltipStyle: PropTypes.object,
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
  dark: PropTypes.bool,
  compact: PropTypes.bool,
  delay: PropTypes.number
};

export default InfoTooltip;
