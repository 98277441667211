import { createSelector } from 'reselect';

const baseSelectCompanyProfile = state => state.entities.companyProfile.profile;

export const selectCompanyProfileToEdit = createSelector([baseSelectCompanyProfile], item => {
  const profile = item || {};
  const draft = profile.draft;
  if (draft) {
    if (!profile.publishedAt || profile.publishedAt < draft.updatedAt) {
      return draft;
    }
  }
  return profile;
});
