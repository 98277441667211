import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import _values from 'lodash/values';
import _isEmpty from 'lodash/isEmpty';

import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';

import SearchField from '../../mui/DebouncedSearchField';
import IconButton from '../../mui/IconButton';
import Button from '../../mui/Button';
import ToggleIconButtonGroup from '../../mui/ToggleIconButtonGroup';
import HorizontalScrollPane from '../../basic/HorizontalScrollPane';
import CategoryFilter from '../../filter/CategoryFilter';

import { EProjectFilterLayout, ESortOrder, EProjectFilterSort } from '../../../constants/enum';

import * as filterActions from '../../../actions/options/projectFilterActions';
import { selectProjectsFilterValues } from '../../../selectors/projects';
import Collapse from '../../basic/Collapse';
import MenuItemText from '../../mui/MenuItemText';
import Tooltip from '../../tooltip/Tooltip';
import { selectAccountPermissions } from '../../../selectors/auth';

class ProjectFilter extends React.Component {
  extractFilterFromValues = filter => {
    const { fromValues } = this.props.filter;
    const { [filter.key]: obj } = fromValues || {};
    return obj || {};
  };

  handleToggleFromValues = (filter, value) => {
    const { setProjectFilterFromValues } = this.props;
    const { items, ...rest } = this.extractFilterFromValues(filter);
    const { [value]: oldVal, ...newItems } = items || {};
    if (!oldVal) {
      newItems[value] = true;
    } else {
      // encountered weird 'rest' operator behaviour on numerical keys
      delete newItems[value];
    }
    setProjectFilterFromValues({
      filterKey: filter.key,
      values: { ...rest, items: _isEmpty(newItems) ? null : newItems }
    });
  };

  handleSortPropertyClick = ({ property, defaultOrder }) => {
    const {
      filter: { sort },
      setProjectFilterSort
    } = this.props;
    const newSort = { ...sort };
    const initialOrder = defaultOrder || ESortOrder.ASC;
    const finalOrder = initialOrder === ESortOrder.ASC ? ESortOrder.DESC : ESortOrder.ASC;
    if (sort && sort.property === property) {
      if (sort.order === finalOrder) {
        newSort.property = null;
        newSort.order = null;
      } else {
        newSort.order = finalOrder;
      }
    } else {
      newSort.property = property;
      newSort.order = initialOrder;
    }
    setProjectFilterSort(newSort);
  };

  handleActiveProjectsFirstClick = () => {
    const {
      filter: { sort },
      setProjectFilterSort
    } = this.props;
    setProjectFilterSort({ ...sort, activeFirst: !(sort && sort.activeFirst) });
  };

  handleToggleDemoProjectsClick = () => {
    const {
      filter: { demoShown },
      setProjectFilterDemoShown
    } = this.props;
    setProjectFilterDemoShown(!demoShown);
  };

  render() {
    const {
      auth,
      intl,
      filterValues,
      projects: { pagination },
      filter: { value, advanced, layout, fromValues, sort, demoShown }
    } = this.props;
    const { onRefresh, onAdd } = this.props;
    const { setProjectFilterValue, setProjectFilterLayout, toggleAdvancedProjectFilter } = this.props;
    const messages = intl.messages.components.projectFilter;
    const enumMessages = intl.messages.constants.enums;
    const notFound = pagination.totalSizeFiltered === 0;
    return (
      <div className="project-filter" style={{ maxWidth: '100%', margin: '12px 0' }}>
        <div className="container-flex-row fw-yes" style={{ margin: '0 -4px' }}>
          <div className="flex1" style={{ margin: '4px' }}>
            <SearchField
              placeholder={messages.hint}
              value={value}
              onValueChange={setProjectFilterValue}
              debounce={100}
            />
          </div>
          <ToggleIconButtonGroup
            onClick={setProjectFilterLayout}
            value={layout}
            style={{ margin: '4px' }}
            buttons={[
              { value: EProjectFilterLayout.CARD, icon: 'view_module', tooltip: messages.layout.CARD.tooltip },
              { value: EProjectFilterLayout.LIST, icon: 'view_headline', tooltip: messages.layout.LIST.tooltip }
            ]}
          />
          <ToggleIconButtonGroup
            onClick={toggleAdvancedProjectFilter}
            value={advanced}
            style={{ margin: '4px' }}
            buttons={[{ value: true, icon: 'filter_alt', tooltip: messages.advanced }]}
          />
          <IconMenu
            iconButtonElement={
              <IconButton style={{ margin: '10px 4px' }} tooltip={messages.sortTooltip} tooltipPosition={'bottom'}>
                <i className="material-icons text-muted">sort</i>
              </IconButton>
            }
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            targetOrigin={{ horizontal: 'right', vertical: 'top' }}
            desktop
            clickCloseDelay={0}
          >
            {_map(_values(EProjectFilterSort), s => (
              <MenuItem
                key={s.key}
                primaryText={enumMessages.EProjectFilterSort[s.key].label}
                insetChildren={!sort || sort.property !== s.property}
                leftIcon={
                  sort &&
                  sort.property === s.property && (
                    <i className="material-icons">
                      {sort.order === ESortOrder.ASC ? 'arrow_upward' : 'arrow_downward'}
                    </i>
                  )
                }
                onClick={() => this.handleSortPropertyClick(s)}
              />
            ))}
            <Divider />
            <MenuItem
              primaryText={messages.activeFirstLabel}
              leftIcon={
                <i className="material-icons">
                  {!!(sort && sort.activeFirst) ? 'check_box' : 'check_box_outline_blank'}
                </i>
              }
              onClick={this.handleActiveProjectsFirstClick}
            />
            <MenuItem
              primaryText={
                <MenuItemText label={messages.showDemoProjects} description={messages.showDemoProjectsHelper} />
              }
              leftIcon={
                <i className="material-icons" style={{ marginTop: '12px' }}>
                  {!!demoShown ? 'check_box' : 'check_box_outline_blank'}
                </i>
              }
              onClick={this.handleToggleDemoProjectsClick}
            />
          </IconMenu>
          <IconButton
            style={{ margin: '10px 4px' }}
            onClick={onRefresh}
            tooltip={messages.refreshTooltip}
            tooltipPosition={'bottom'}
          >
            <i className="material-icons text-muted">refresh</i>
          </IconButton>
          {auth.canEditProjects && (
            <div className="tooltip-element" style={{ margin: '10px 4px' }}>
              <Button
                secondary
                raised
                icon={<i className={`material-icons text-white${notFound ? ' vb-anim-ripple x5' : ''}`}>add</i>}
                label={messages.newLabel}
                onClick={onAdd}
              />
              <Tooltip>{messages.newLabelHelper}</Tooltip>
            </div>
          )}
        </div>
        <Collapse isOpened={advanced} unmountClosed>
          <div style={{ margin: '0 -16px' }}>
            <HorizontalScrollPane>
              <div className="container-flex-row">
                {_map(_values(filterValues), fv => {
                  if (!auth.isAdmin && fv.adminOnly) {
                    return null;
                  }
                  const filterMessages = messages.fromValuesFilters[fv.key] || { label: fv.key };
                  const activeValues = (fromValues && fromValues[fv.key]) || {};
                  const label = fv.label || filterMessages.label;
                  const description = filterMessages.description;
                  return (
                    <CategoryFilter
                      key={fv.key}
                      alwaysExpanded
                      filter={fv}
                      activeValues={activeValues}
                      label={label}
                      matchAllLabel={messages.matchAllLabel}
                      description={description}
                      filterMessages={filterMessages}
                      enumMessages={enumMessages}
                      onToggleValue={this.handleToggleFromValues}
                    />
                  );
                })}
              </div>
            </HorizontalScrollPane>
          </div>
        </Collapse>
        {notFound && (
          <div className="text-muted text-center" style={{ padding: 24 }}>
            {auth.canEditProjects ? messages.noProjectFoundMessageCanAdd : messages.noProjectFoundMessage}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    options: { projectFilter }
  } = state;
  return {
    auth: selectAccountPermissions(state, ownProps),
    intl,
    filter: projectFilter,
    filterValues: selectProjectsFilterValues(state, ownProps)
  };
};

export default connect(mapStateToProps, filterActions)(ProjectFilter);
