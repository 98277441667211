import React from 'react';

import withIntl from '../withIntl';

const OpportunityDisabledMessage = ({ intl: { messages } }) => <span>{messages.message}</span>;

const intlMessages = {
  cs: { message: 'Již byl dosažený limit počtu aktivních příležitostí' },
  sk: { message: 'Už ste dosiahli limit počtu aktívnych príležitostí' },
  en: { message: 'You have already reached the maximum active opportunities' }
};

export default withIntl(OpportunityDisabledMessage, intlMessages);
