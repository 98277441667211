import React from 'react';
import { withRouter } from 'react-router';

import { getBackPath } from './paths';
import withIntl from '../withIntl';
import NavigationButton from './NavigationButton';

const BackButton = (props) => {
  const {
    location,
    intl: { messages },
    router,
  } = props;
  const backPath = getBackPath(location);
  if (!backPath) {
    return null;
  }
  const onClick = () => router.push(backPath);
  return <NavigationButton onClick={onClick} icon="arrow_back" tooltip={messages.back} />;
};

const intlMessages = {
  cs: {
    back: 'Zpět',
  },
  sk: {
    back: 'Späť',
  },
  en: {
    back: 'Back',
  },
};

export default withRouter(withIntl(BackButton, intlMessages));
