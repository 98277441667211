import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';

import Button from '../mui/Button';
import TextField from '../superfield/form/SuperText';
import { HTTPErrorCodes } from '../../constants/enum';

import { BasicForm, BasicTitle } from '../basic';

const Login = props => {
  const { login, handleSubmit, resetPassword, intl } = props;
  const messages = intl.messages.components.pages.public.login;

  return (
    <BasicForm
      onSubmit={handleSubmit(values =>
        login(values).catch(err => {
          let errorMessage = '';
          if (err.status === HTTPErrorCodes.FORBIDDEN) {
            errorMessage = messages.errorAccessDenied;
          } else if (err.status === HTTPErrorCodes.BAD_REQUEST) {
            errorMessage = messages.error;
          }
          if (errorMessage) {
            throw new SubmissionError({
              password: errorMessage,
              _error: 'Login failed!'
            });
          }
        })
      )}
    >
      <BasicTitle title={messages.text} />
      <div className={'row'}>
        <div className={'col-xs-12'}>
          <Field name={'username'} component={TextField} label={messages.email} autoFocus />
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-xs-12'}>
          <Field name={'password'} component={TextField} label={messages.pwd} type={'password'} />
        </div>
      </div>

      <div className="container-flex-row ai-center fw-yes jc-space-between" style={{ marginTop: '25px' }}>
        <Button label={messages.forgottenPwd} onClick={resetPassword} />
        <Button type={'submit'} label={messages.submit} primary raised />
      </div>
    </BasicForm>
  );
};

Login.propTypes = {
  intl: PropTypes.object,
  initialValues: PropTypes.object,
  resetPassword: PropTypes.func,
  handleSubmit: PropTypes.func
};

const mapStateToProps = (state, { email, ...ownProps }) => {
  return {
    ...ownProps,
    initialValues: {
      username: email
    }
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'login'
  })(Login)
);
