import React, { useCallback, useMemo, useState } from 'react';

import Text from '../superfield/SuperText';
import Select from '../superfield/SuperSelect';
import Button from '../mui/Button';

import { EGroupMembership, EGroupType } from '../../constants/enum';
import withIntl from '../withIntl';
import * as Api from '../../api/entities/groupApi';

const GenerateMembersDialog = ({ team, type, onClose, onReload, intl: { messages } }) => {
  const [count, setCount] = useState(5);
  const [membership, setMembership] = useState(EGroupMembership.MEMBER.key);
  const [loading, setLoading] = useState(false);

  const handleCountChange = useCallback(e => setCount(e.target.value), [setCount]);
  const handleMembershipChange = useCallback(
    ([val]) => {
      setMembership(val);
      if (val === EGroupMembership.MANAGER.key) {
        setCount(1);
      }
    },
    [setMembership]
  );
  const handleConfirm = useCallback(() => {
    setLoading(true);
    Api.generateGroupMembers(team.id, membership || EGroupMembership.MEMBER.key, count)
      .then(() => {
        setLoading(false);
        onClose();
        onReload();
      })
      .catch(() => setLoading(false));
  }, [membership, count, team]);

  const dataSource = useMemo(
    () => [
      { label: messages.memberships.MEMBER, value: EGroupMembership.MEMBER.key },
      { label: messages.memberships.MANAGER, value: EGroupMembership.MANAGER.key }
    ],
    [messages, type]
  );
  const membershipValues = useMemo(() => [membership], [membership]);
  return (
    <div>
      <div className="mui-padded-x2">
        {type === EGroupType.TEAM && (
          <Select
            label={messages.membershipLabel}
            dataSource={dataSource}
            values={membershipValues}
            onChange={handleMembershipChange}
          />
        )}
        {membership !== EGroupMembership.MANAGER.key && (
          <Text
            type="number"
            value={count}
            onChange={handleCountChange}
            label={messages.countLabel}
            helperText={messages.countHelperText}
          />
        )}
      </div>
      <div className="mui-padded-half container-flex-row fw-yes jc-flex-end">
        <div className="mui-padded-half">
          <Button label={messages.cancelLabel} onClick={onClose} />
        </div>
        <div className="mui-padded-half">
          <Button
            label={messages.confirmLabel}
            onClick={handleConfirm}
            disabled={count <= 0 || !membership || loading}
            loading={loading}
            primary
          />
        </div>
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    cancelLabel: 'Zrušit',
    confirmLabel: 'Vygenerovat',
    countLabel: 'Počet',
    countHelperText: 'Musí být alespoň 1',
    membershipLabel: 'Typ členství (člen / manažer)',
    memberships: { MEMBER: 'Člen', MANAGER: 'Manažer' }
  },
  sk: {
    cancelLabel: 'Zrušiť',
    confirmLabel: 'Vygenerovať',
    countLabel: 'Počet',
    countHelperText: 'Musí byť aspoň 1',
    membershipLabel: 'Typ členstva (člen / manažér)',
    memberships: { MEMBER: 'Člen', MANAGER: 'Manažér' }
  },
  en: {
    cancelLabel: 'Cancel',
    confirmLabel: 'Generate',
    countLabel: 'Count',
    countHelperText: 'Must be at least 1',
    membershipLabel: 'Membership type (member / manager)',
    memberships: { MEMBER: 'Member', MANAGER: 'Manager' }
  }
};

export default withIntl(GenerateMembersDialog, intlMessages);
