import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Tooltip from '../tooltip/Tooltip';
import { selectActivityTemplates } from '../team/teamActivityTemplatesSelector';

const ActivityLabel = props => {
  const {
    activity: { key, variant },
    templates,
    withoutTooltip
  } = props;
  const template = (variant && templates[`${key}/${variant}`]) || templates[key];
  if (!template) {
    return <div className="activity-label">{key}</div>;
  }
  return (
    <div className="activity-label tooltip-element">
      <span>{template.label}</span>
      {!withoutTooltip && <Tooltip position={'bottom'}>{template.name}</Tooltip>}
    </div>
  );
};

ActivityLabel.propTypes = {
  activity: PropTypes.object.isRequired,
  withoutTooltip: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    templates: selectActivityTemplates(state, ownProps).map
  };
};

export default connect(mapStateToProps)(ActivityLabel);
