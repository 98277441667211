import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _get from 'lodash/get';

import Select from '../../../superfield/form/SuperSelect';
import RequiredCriteriaToggle from './RequiredCriteriaToggle';

import { getProgress } from '../Utils';
import { enumToDataSource, valueHoldersToValues } from '../../../forms/FormUtils';
import { EWorkExperiencePosition, EWorkExperienceYears } from '../../../../constants/enum';
import { selectOpportunityPostToEdit } from '../../../../selectors/opportunityBuilder';

export const ID = 'opportunityWorkExperiencePartForm';

const messages = {
  requiredCriteriaLabel: 'Toto kritérium MUSÍ splňovat',
  minimumWorkExperienceYearsLabel: 'Praxe a zkušenosti',
  workExperienceRolesLabel: 'Preferované zkušenosti na pozici',
  requiredMessage: 'Vyberte možnost'
};

const WorkExperiencePart = props => {
  const { dataSources, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="minimumWorkExperienceYears"
            label={messages.minimumWorkExperienceYearsLabel}
            component={Select}
            dataSource={dataSources.workExperienceYears}
          />
        </div>
        <div className="col-sm-6">
          <div className="mui-padded-horizontal">
            <Field
              name="minimumWorkExperienceYearsRequired"
              component={RequiredCriteriaToggle}
              label={messages.requiredCriteriaLabel}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="workExperienceRoles"
            label={messages.workExperienceRolesLabel}
            component={Select}
            dataSource={dataSources.workExperiencePositions}
            multi
            filterable
          />
        </div>
        <div className="col-sm-6">
          <div className="mui-padded-horizontal">
            <Field
              name="workExperienceRolesRequired"
              component={RequiredCriteriaToggle}
              label={messages.requiredCriteriaLabel}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const validate = () => {
  return {};
};

const mapStateToProps = (state, ownProps) => {
  const { intl } = state;
  const post = selectOpportunityPostToEdit(state, ownProps);
  const opportunity = _get(post, 'opportunity') || {};
  const enumMessages = intl.messages.constants.enums;
  const dataSources = {
    workExperienceYears: enumToDataSource(EWorkExperienceYears, enumMessages.EWorkExperienceYears),
    workExperiencePositions: enumToDataSource(EWorkExperiencePosition, enumMessages.EWorkExperiencePosition)
  };
  const matchConfig = opportunity.matchConfig || {};
  return {
    intl,
    dataSources,
    initialValues: {
      minimumWorkExperienceYears: _get(matchConfig, 'workExperience.years.range.lower.value'),
      minimumWorkExperienceYearsRequired:
        _get(matchConfig, 'workExperience.years.range.lower.eliminating') ||
        _get(matchConfig, 'workExperience.years.range.eliminating') ||
        _get(matchConfig, 'workExperience.years.eliminating'),
      workExperienceRoles: valueHoldersToValues(_get(matchConfig, 'workExperience.role.list.items')),
      workExperienceRolesRequired:
        _get(matchConfig, 'workExperience.role.list.eliminating') ||
        _get(matchConfig, 'workExperience.role.eliminating')
    }
  };
};

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

WorkExperiencePart.ID = ID;
WorkExperiencePart.getProgressFromPost = post => {
  const matchConfig = post?.opportunity?.matchConfig;
  return getProgress([
    () => matchConfig?.workExperience?.years?.range?.lower?.value != null,
    () => matchConfig?.workExperience?.role?.list?.items?.length > 0
  ]);
};
WorkExperiencePart.getProgressFromValues = v =>
  getProgress([() => v?.minimumWorkExperienceYears != null, () => v?.workExperienceRoles?.length > 0]);

export default connect(mapStateToProps)(reduxForm(formConfig)(WorkExperiencePart));
