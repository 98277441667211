import { createSelector } from 'reselect';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import _map from 'lodash/map';

import { getProjects, getCompanies, getApplicants, getCrossComparison, getTagsByProject } from './base';

// state selectors
const getPathParams = (state, props) => (props && props.params) || {};

//TODO improve
export const getCompanyPathParam = createSelector([getPathParams], params => params.companySlug || params.companyId);
export const getProjectPathParam = createSelector([getPathParams], params => params.projectSlug || params.projectId);

export const getCurrentCompany = createSelector([getCompanies, getCompanyPathParam], (companies, company) =>
  find(companies, c => c.slug === company)
);
export const getCurrentProject = createSelector(
  [getProjects, getCompanyPathParam, getProjectPathParam],
  (projects, companySlug, projectSlug) =>
    find(projects, ({ slug, company }) => slug === projectSlug && company.slug === companySlug)
);

const getUserProjectId = p => `${p.id}/${p.projectId}`;

const enrichParticipantsWithTags = (participants, tags) => {
  const tMap = reduce(
    tags,
    (res, t) => {
      res[t.userId] = t;
      return res;
    },
    {}
  );
  return _map(participants, p => {
    const { participant } = p;
    const pTags = tMap[participant.id];
    if (!pTags) {
      return p;
    }
    return { ...p, participant: { ...participant, tags: pTags.tags } };
  });
};

export const selectParticipants = createSelector(
  [getApplicants, getCurrentProject, getCrossComparison, getTagsByProject],
  (participants, project, crossComparison, tagsByProject) => {
    const { targetProject } = crossComparison;
    if (project && tagsByProject.projectId === project.id && tagsByProject.tags && tagsByProject.tags.length > 0) {
      participants = { ...participants, items: enrichParticipantsWithTags(participants.items, tagsByProject.tags) };
    }
    const crossParticipants = targetProject && targetProject.id === project.id ? crossComparison.participants : null;
    if (!crossParticipants) {
      return participants;
    }
    const crossParticipantsMap = reduce(
      crossParticipants,
      (res, p) => {
        res[getUserProjectId(p)] = p;
        return res;
      },
      {}
    );
    const items = [];
    const pCount = participants.items.length;
    const cpCount = crossParticipants.length;
    for (let i = 0; i < pCount + cpCount; i += 1) {
      const isCrossParticipant = i >= pCount;
      const p = isCrossParticipant ? crossParticipants[i - pCount] : participants.items[i];
      const isCurrentProject = p.projectId === project.id;
      if (isCrossParticipant && isCurrentProject) {
        continue;
      }
      const pId = getUserProjectId(p);
      const newP = crossParticipantsMap[pId] || p;
      items.push(newP);
    }
    return {
      ...participants,
      pagination: {
        ...participants.pagination,
        totalElements: items.length
      },
      items
    };
  }
);
