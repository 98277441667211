import React from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';

import snap from './snap';

function getItemStyles(props) {
  const { initialOffset, currentOffset, item: { position } } = props;
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }

  let { x, y } = currentOffset;

  x += position.left - initialOffset.x;
  y += position.top - initialOffset.y;
  x = snap(x);
  y = snap(y);

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}

const CustomDragLayer = props => {
  const { item, isDragging } = props;

  if (!isDragging) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1001,
        backgroundColor: 'rgba(255,255,255,0.4)'
      }}
    >
      <div
        style={{
          ...getItemStyles(props),
          ...item.size,
          backgroundColor: 'rgba(0,0,0,0.4)',
          borderRadius: '3px'
        }}
      />
    </div>
  );
};

CustomDragLayer.propTypes = {
  item: PropTypes.object,
  itemType: PropTypes.string,
  initialOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  currentOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  isDragging: PropTypes.bool.isRequired
};

export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging()
}))(CustomDragLayer);
