import React from 'react';
import PropTypes from 'prop-types';

import LanguageSwitcher from '../../switcher/LanguageSwitcher';
import Card from '../../mui/Card';

import { BRAND } from '../../../constants/constants';

const PublicBase = props => {
  const { children, intl, updateLocale, colMaxWidth = 500, footer, containerStyle, style, perex } = props;
  const responsiveColClass = `col-xs-12 ${perex ? 'col-md-6' : 'col-md-12'}`;
  /*
   className="flex1"
   style={{
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'stretch',
   flexDirection: 'column'
   }}
   */
  return (
    <div
      className="flex1"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        flexDirection: 'column',
        ...containerStyle
      }}
    >
      <div
        className="container"
        style={{
          margin: '0 auto',
          minWidth: '220px',
          maxWidth: perex ? colMaxWidth * 2 : colMaxWidth,
          fontSize: '16px',
          paddingBottom: '36px',
          ...style
        }}
      >
        <div className="row">
          <div className={responsiveColClass}>
            <div className="mui-padded container-flex-row ai-end jc-space-between fw-yes">
              <div className="mui-padded">
                <img
                  src={`/img/branding/${BRAND.folder()}/logo.svg`}
                  alt={BRAND.label}
                  style={{ maxWidth: '196px' }}
                />
              </div>
              <div className="mui-padded">
                <LanguageSwitcher {...{ intl, updateLocale }} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {perex && <div className={`${responsiveColClass} col-md-push-6`}>{perex}</div>}
          <div className={`${responsiveColClass}${perex ? ' col-md-pull-6' : ''}`}>
            <Card>{children}</Card>
          </div>
        </div>
        {footer && (
          <div className="row">
            <div className={responsiveColClass}>
              <div style={{ padding: '1em', textAlign: 'center' }}>{footer}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PublicBase.propTypes = {
  children: PropTypes.any.isRequired,
  intl: PropTypes.object.isRequired,
  style: PropTypes.any,
  //
  updateLocale: PropTypes.func.isRequired
};

export default PublicBase;
