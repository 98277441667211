import { createSelector } from 'reselect';
import reduce from 'lodash/reduce';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import filter from 'lodash/filter';

import { basicSelectCurrentCompany } from './base';

const basicSelectSimulationModels = state => state.entities.simulationModels;

const containsIgnoreCase = (string, lowerValue) => string && string.toLowerCase().indexOf(lowerValue) > -1;

const createModelTextFilter = text => {
  if (!text) {
    return () => true;
  }
  const lowerFilterText = text.toLowerCase().trim();
  return m =>
    containsIgnoreCase(m.name, lowerFilterText) ||
    (m.config && m.config.projectName && containsIgnoreCase(m.config.projectName, lowerFilterText));
};

const createModelCompanyFilter = company => {
  if (!company) {
    return () => true;
  }
  return m => m.company && m.company.id === company.id;
};

const createModelFilter = (filterText, company) => {
  const tf = createModelTextFilter(filterText);
  const cf = createModelCompanyFilter(company);
  return m => tf(m) && cf(m);
};

export const selectModelsGroupedByCompanies = createSelector(
  [basicSelectSimulationModels, basicSelectCurrentCompany],
  (models, currentCompany) => {
    const { items, filterText } = models;
    const filteredItems =
      filterText || currentCompany ? filter(items, createModelFilter(filterText, currentCompany)) : items;
    const companyMap = reduce(
      filteredItems,
      (res, i) => {
        const key = i.company.id;
        const companyItems = res[key] || { c: i.company, items: [] };
        return {
          ...res,
          [key]: {
            ...companyItems,
            items: [...companyItems.items, i]
          }
        };
      },
      {}
    );
    return { ...models, itemsByCompany: orderBy(values(companyMap), ['c.name']) };
  }
);
