import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import _map from 'lodash/map';
import _get from 'lodash/get';

import Select from '../../../superfield/form/SuperSelect';

import { selectProjectToEdit } from './projectEditSelectors';
import { ENotificationFrequency, getEnumOrdered } from '../../../../constants/enum';

const NotificationsPart = props => {
  const { intl, dataSources, frequency } = props;
  const { handleSubmit } = props;
  const messages = intl.messages.components.pages.private.projectEdit.notifications;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="frequency"
            component={Select}
            label={messages.frequencyLabel}
            helperText={messages.frequencyHelper}
            dataSource={dataSources.frequencies}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="operators"
            component={Select}
            label={messages.recipientsLabel}
            helperText={messages.recipientsHelper}
            dataSource={dataSources.companyOperators}
            disabled={!frequency || frequency === ENotificationFrequency.NEVER.key}
            multi
            filterable
          />
        </div>
      </div>
    </form>
  );
};

NotificationsPart.ID = 'projectNotifications';

const baseLocalSelectIntl = state => state.intl;
const baseLocalSelectCompanyOperators = state => state.entities.companyOperators;

const localSelectDataSources = createSelector(
  [baseLocalSelectIntl, baseLocalSelectCompanyOperators],
  (intl, companyOperators) => {
    return {
      frequencies: _map(getEnumOrdered(ENotificationFrequency), ({ key }) => {
        return {
          value: key,
          label: intl.messages.components.pages.private.projectEdit.notifications.frequencyEnum[key]
        };
      }),
      companyOperators: _map(companyOperators, o => ({
        value: o.id,
        label: `${o.firstName} ${o.lastName}`,
        description: o.email
      }))
    };
  }
);

const localSelectInitialValues = createSelector([selectProjectToEdit], p => {
  return {
    frequency: _get(p, 'schedule.frequency'),
    operators: _get(p, 'schedule.userIds')
  };
});

const selector = formValueSelector(NotificationsPart.ID);

const mapStateToProps = (state, ownProps) => {
  const { intl } = state;
  return {
    intl,
    frequency: selector(state, 'frequency'),
    dataSources: localSelectDataSources(state, ownProps),
    initialValues: localSelectInitialValues(state, ownProps)
  };
};

const validate = (values, state) => {
  const errors = {};
  const messages = state.intl.messages.components.pages.private.projectEdit.validation;
  const operatorsEmpty = (values.operators || []).length <= 0;

  if (operatorsEmpty && values.frequency && values.frequency !== ENotificationFrequency.NEVER.key) {
    errors.operators = messages.notificationsRecipientsEmpty;
  }

  if (!values.frequency && !operatorsEmpty) {
    errors.frequency = messages.frequencyEmpty;
  }

  return errors;
};

const formConfig = {
  form: NotificationsPart.ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

export default connect(mapStateToProps)(reduxForm(formConfig)(NotificationsPart));
