import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Select from '../../../superfield/form/SuperSelect';

import { valueHoldersToValues } from '../../../forms/FormUtils';
import { selectCompanyProfileToEdit } from '../../../../selectors/companyProfileBuilder';

export const ID = 'companyProfileBenefitsPartForm';

const messages = {
  benefitsLabel: 'Jaké benefity nabízíte',
  personalDevelopmentAreasLabel: 'Nabízíte tyto možnosti rozvoje'
};

const BenefitsPart = props => {
  const { dataSources, intl, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="benefits"
            component={Select}
            label={messages.benefitsLabel}
            dataSource={dataSources.benefits}
            multi
            filterable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="personalDevelopmentAreas"
            label={messages.personalDevelopmentAreasLabel}
            component={Select}
            dataSource={dataSources.personalDevelopmentAreas}
            multi
            filterable
          />
        </div>
      </div>
    </form>
  );
};

const validate = () => {
  return {};
};

const mapStateToProps = (state, ownProps) => {
  const { intl, options: { dataSources: { all } } } = state;
  const profile = selectCompanyProfileToEdit(state, ownProps);
  const dataSources = {
    benefits: (all && all.benefits) || [],
    personalDevelopmentAreas: (all && all.personalDevelopmentAreas) || []
  };
  return {
    intl,
    dataSources,
    companyProfile: profile,
    initialValues: {
      benefits: valueHoldersToValues(profile.benefits),
      personalDevelopmentAreas: valueHoldersToValues(profile.personalDevelopmentAreas)
    }
  };
};

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

BenefitsPart.ID = ID;

export default connect(mapStateToProps)(reduxForm(formConfig)(BenefitsPart));
