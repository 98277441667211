import React from 'react';

import withIntl from '../withIntl';
import NavigationButton from './NavigationButton';

class GoToTopButton extends React.Component {
  state = {
    show: false
  };

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    this.checkPosition();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.checkPosition, 50);
  };

  checkPosition = () => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    this.setState({ show: scrollTop > 500 });
  };

  handleGoToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  render() {
    const {
      intl: { messages }
    } = this.props;
    const { show } = this.state;
    if (!show) {
      return null;
    }
    return <NavigationButton onClick={this.handleGoToTop} icon="keyboard_arrow_up" tooltip={messages.goToTop} />;
  }
}

const intlMessages = {
  cs: {
    goToTop: 'Nahoru'
  },
  sk: {
    goToTop: 'Hore'
  },
  en: {
    goToTop: 'To top'
  }
};

export default withIntl(GoToTopButton, intlMessages);
