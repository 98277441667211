import React from 'react';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import map from 'lodash/map';
import values from 'lodash/values';
import keys from 'lodash/keys';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import Button from '../mui/Button';
import Toggle from '../formFields/Toggle';
import Select from '../superfield/form/SuperSelect';
import Text from '../superfield/form/SuperText';

import languages from '../../constants/languages';

import { isEmail } from './Validator';

const inputFontStyle = {
  fontSize: 14
};

const renderInputParams = ({ fields, messages, simulation }) => {
  return (
    <div>
      {fields.map((f, idx) => {
        const field = fields.get(idx);
        return (
          <div key={idx} className="param-container">
            <div className="param-container-fields">
              <div className="param-field param-field-input">
                <Field name={`${f}.name`} component={Text} label={messages.inputParamNameLabel} />
              </div>
              <div className="param-field param-field-checkbox">
                <Field
                  name={`${f}.identifying`}
                  component={Toggle}
                  label={messages.inputParamIdentifyingLabel}
                  fullWidth
                  style={inputFontStyle}
                />
              </div>
              <div className="param-field param-field-select">
                <Field
                  name={`${f}.type`}
                  component={Select}
                  label={messages.inputParamTypeLabel}
                  dataSource={[
                    { value: 'STRING', label: messages.inputParamTypes.STRING },
                    { value: 'NUMBER', label: messages.inputParamTypes.NUMBER }
                  ]}
                />
              </div>
              <div className="param-field param-field-toggle">
                <Field
                  name={`${f}.reported`}
                  component={Toggle}
                  label={messages.inputParamReportedLabel}
                  fullWidth
                  style={inputFontStyle}
                />
              </div>
              {field.reported && (
                <div className="param-field param-field-input">
                  <Field name={`${f}.label`} component={Text} label={messages.inputParamLabelLabel} />
                </div>
              )}
              {simulation && simulation.metricDefinitions && (
                <div>
                  <br />
                  <div className="param-field param-field-toggle">
                    <Field
                      name={`${f}.mapsToMetric`}
                      component={Toggle}
                      label={messages.inputParamMapsToMetricLabel}
                      fullWidth
                      style={inputFontStyle}
                      disabled={field.type !== 'NUMBER'}
                    />
                  </div>
                  {field.mapsToMetric && (
                    <div>
                      <div className="param-field param-field-select">
                        <Field
                          name={`${f}.metric`}
                          component={Select}
                          label={messages.inputParamMetricLabel}
                          dataSource={map(keys(simulation.metricDefinitions), m => ({
                            value: m,
                            label: simulation.metricDefinitions[m].label
                          }))}
                        />
                      </div>
                      <div className="param-field param-field-input">
                        <Field
                          name={`${f}.metricMinValue`}
                          component={Text}
                          label={messages.inputParamMetricMinValueLabel}
                          type="number"
                        />
                      </div>
                      <div className="param-field param-field-input">
                        <Field
                          name={`${f}.metricMaxValue`}
                          component={Text}
                          label={messages.inputParamMetricMaxValueLabel}
                          type="number"
                        />
                      </div>
                      <div className="param-field param-field-toggle">
                        <Field
                          name={`${f}.metricMappingReversed`}
                          component={Toggle}
                          label={messages.inputParamMetricMappingReversedLabel}
                          fullWidth
                          style={inputFontStyle}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="param-container-buttons">
              <Button
                onClick={() => fields.remove(idx)}
                label={messages.removeInputParamButton}
                icon={<i className="material-icons">delete</i>}
              />
            </div>
          </div>
        );
      })}
      <Button
        label={messages.addInputParamButton}
        onClick={() => fields.push({})}
        icon={<i className="material-icons">add</i>}
      />
    </div>
  );
};

const DATA_SOURCE_LANGUAGES = values(languages);

const SimulationModelPropertiesForm = props => {
  const {
    handleSubmit,
    handleSubmitFail,
    messages,
    metadata: { companies = [], simulations = [] },
    simulation,
    redirectReport
  } = props;

  return (
    <form onSubmit={handleSubmit} onSubmitFail={handleSubmitFail} className="sim-model-properties-form">
      <div className="sim-model-properties-form-section">
        <h4>{messages.languageTitle}</h4>
        <p className="no-margin">{messages.languageDescription}</p>
        <Field
          name="defaultLanguage"
          component={Select}
          label={messages.defLangLabel}
          dataSource={DATA_SOURCE_LANGUAGES}
        />
      </div>
      {companies && companies.length !== 1 && (
        <div className="sim-model-properties-form-section">
          <h4>{messages.companyTitle}</h4>
          <p className="no-margin">{messages.companyDescription}</p>
          <Field
            name="company"
            component={Select}
            label={messages.companyLabel}
            filterable
            dataSource={map(orderBy(companies, ['name', 'slug']), c => ({
              value: c.id,
              label: c.name,
              description: c.slug
            }))}
          />
        </div>
      )}
      <div className="sim-model-properties-form-section">
        <h4>{messages.projectTitle}</h4>
        <p className="no-margin">{messages.projectDescription}</p>
        <Field
          name="projectName"
          component={Text}
          label={messages.projectNameLabel}
          helperText={messages.projectNameHelperText}
        />
      </div>
      <div className="sim-model-properties-form-section">
        <h4>{messages.simulationTitle}</h4>
        <p className="no-margin">{messages.simulationDescription}</p>
        <Field
          name="simulationKey"
          component={Select}
          label={messages.simulationKeyLabel}
          dataSource={[{ value: null, label: '' }, ...map(simulations, s => ({ value: s.key, label: s.name }))]}
        />
        {simulation && simulation.variants && simulation.variants.length > 0 && (
          <Field
            name="simulationVariant"
            component={Select}
            label={messages.simulationVariantLabel}
            dataSource={[
              { value: null, label: '' },
              ...map(simulation.variants, v => ({ value: v, label: messages.simulationVariants[v] || v }))
            ]}
          />
        )}
        <Field
          name="simulationName"
          component={Text}
          label={messages.simulationNameLabel}
          helperText={messages.simulationNameHelperText}
        />
      </div>
      <div className="sim-model-properties-form-section">
        <h4>{messages.inputParamsTitle}</h4>
        <p>{messages.inputParamsDescription}</p>
        <FieldArray
          name="inputParams"
          component={renderInputParams}
          messages={messages}
          simulation={simulation}
          rerenderOnEveryChange
        />
      </div>
      <div className="sim-model-properties-form-section">
        <h4>{messages.redirectTitle}</h4>
        <p>{messages.redirectDescription}</p>
        <p>{messages.redirectTimeoutDescription}</p>
        <Field
          name={'redirectReport'}
          component={Toggle}
          label={messages.redirectReportLabel}
          fullWidth
          style={inputFontStyle}
        />
        <Field
          name={'redirectUrl'}
          component={Text}
          label={messages.redirectUrlLabel}
          disabled={redirectReport}
          multiline
        />
        <Field name={'redirectTimeout'} component={Text} type="number" label={messages.redirectTimeoutLabel} />
        <Field
          name={'redirectTop'}
          component={Toggle}
          label={messages.redirectTopLabel}
          fullWidth
          style={inputFontStyle}
        />
      </div>
      <div className="sim-model-properties-form-section">
        <h4>{messages.summaryMailTitle}</h4>
        <p>{messages.summaryMailDescription}</p>
        <Field
          name={'summaryEmail'}
          component={Text}
          label={messages.summaryMailLabel}
          fullWidth
          style={inputFontStyle}
        />
      </div>
      <div className="sim-model-properties-form-section">
        <h4>{messages.lafTitle}</h4>
        <p className="no-margin">{messages.lafDescription}</p>
        <Field
          name={'lafAnswerFormat'}
          component={Select}
          label={messages.lafAnswerFormatLabel}
          dataSource={[
            { value: null, label: '' },
            { value: 'SAME', label: messages.lafAnswerFormatTypes.SAME }
          ]}
        />
        <br />
        <Field
          name={'lafTypingDurationEnabled'}
          component={Toggle}
          label={messages.lafTypingDurationEnabledLabel}
          fullWidth
          style={inputFontStyle}
        />
      </div>
    </form>
  );
};

const validate = (values, state) => {
  const errors = {};
  const messages = state.messages.validation;
  const inputParamsErrors = [];
  const paramNames = {};
  if (values.inputParams && values.inputParams.length) {
    values.inputParams.forEach((param, idx) => {
      const paramErrors = {};
      if (!param.name) {
        paramErrors.name = messages.parameterNameRequired;
      } else if (!/^([A-Za-z_]+)$/g.exec(param.name)) {
        paramErrors.name = messages.parameterNameInvalid;
      } else if (paramNames[param.name]) {
        paramErrors.name = messages.parameterNameNotUnique;
      } else {
        paramNames[param.name] = param.name;
      }
      if (!param.type) {
        paramErrors.type = messages.parameterTypeRequired;
      }
      if (param.reported && !param.label) {
        paramErrors.label = messages.parameterLabelRequired;
      }
      if (param.mapsToMetric) {
        if (!param.metric) {
          paramErrors.metric = messages.mappingMetricRequired;
        }
        if (!isNaN(param.metricMaxValue) && isNaN(param.metricMinValue)) {
          paramErrors.metricMinValue = messages.mappingMinValueRequired;
        } else if (parseFloat(param.metricMaxValue) < parseFloat(param.metricMinValue)) {
          paramErrors.metricMaxValue = messages.mappingMaxValueLessThanMin;
        }
      }
      if (!isEmpty(paramErrors)) {
        inputParamsErrors[idx] = paramErrors;
      }
    });
    if (inputParamsErrors.length) {
      errors.inputParams = inputParamsErrors;
    }
  }
  if (!values.defaultLanguage) {
    errors.defaultLanguage = messages.languageRequired;
  }
  if (!values.company) {
    errors.company = messages.companyRequired;
  }
  if (values.redirectTimeout && values.redirectTimeout < 0) {
    errors.redirectTimeout = messages.redirectTimeoutNegative;
  }
  if (!values.projectName) {
    errors.projectName = messages.projectNameRequired;
  } else if (values.projectName.length < 3) {
    errors.projectName = messages.nameLengthAtLeast.replace('{0}', 3);
  }
  if (!values.simulationName) {
    errors.simulationName = messages.simulationNameRequired;
  } else if (values.simulationName.length < 3) {
    errors.simulationName = messages.nameLengthAtLeast.replace('{0}', 3);
  }
  if (values.summaryEmail && !isEmail(values.summaryEmail)) {
    errors.summaryEmail = messages.invalidEmail;
  }
  const r = /{([^}]+)}/g;
  let match = r.exec(values.redirectUrl);
  while (match) {
    const paramName = match[1];
    if (!paramNames[paramName] && !paramName.startsWith('virtubio_')) {
      errors.redirectUrl = messages.unknownParameter.replace('{0}', paramName);
      break;
    }
    match = r.exec(values.redirectUrl);
  }
  return errors;
};

const selector = formValueSelector('simulationModelProperties');

const mapStateToProps = (state, ownProps) => {
  const {
    entities: {
      simulationModel: { item, metadata }
    }
  } = state;
  const { modelName } = ownProps;
  const { companies = [], simulations = [] } = metadata;
  const config = (item && item.config) || {};
  const redirect = config.redirect || {};
  const lookAndFeel = config.lookAndFeel || {};
  const typingDuration = lookAndFeel.typingDuration;
  const simulationKey = selector(state, 'simulationKey');
  const redirectReport = selector(state, 'redirectReport');
  const simulation = simulationKey && find(simulations, s => s.key === simulationKey);
  return {
    initialValues: {
      ...config,
      inputParams: map(config.inputParams || [], ip => {
        const metricMapping = ip.metricMapping || {};
        return {
          ...ip,
          mapsToMetric: !!metricMapping.metric,
          metric: metricMapping.metric,
          metricMinValue: metricMapping.minValue,
          metricMaxValue: metricMapping.maxValue,
          metricMappingReversed: metricMapping.reversed
        };
      }),
      redirectReport: redirect.type === 'REPORT',
      redirectUrl: redirect.url || null,
      redirectTimeout: redirect.timeoutInSeconds || null,
      redirectTop: redirect.top || true,
      defaultEnvironment: config.defaultEnvironment || null,
      defaultLanguage: config.defaultLanguage || null,
      company: (item && item.companyId) || (companies.length === 1 && companies[0].id) || null,
      simulationName: config.simulationName || (simulation && simulation.name) || modelName,
      projectName: config.projectName || modelName,
      simulationKey: config.simulationKey || (simulation && simulation.key),
      simulationVariant: config.simulationVariant,
      lafMode: lookAndFeel.mode,
      lafAnswerFormat: lookAndFeel.answerFormat,
      lafTypingDurationEnabled: !(typingDuration && typingDuration.disabled)
    },
    redirectReport,
    metadata,
    simulation
  };
};

const formConfig = {
  form: 'simulationModelProperties',
  enableReinitialize: true,
  updateUnregisteredFields: true,
  keepDirtyOnReinitialize: true,
  validate
};

export default connect(mapStateToProps)(reduxForm(formConfig)(SimulationModelPropertiesForm));
