import { handleActions } from 'redux-actions';

import {
  INSIGHT_GET,
  INSIGHT_CREATE,
  INSIGHT_UPDATE,
  INSIGHT_UNSET
} from '../../constants/constants';

const defaultState = {
  current: null,
  loading: false
};

const handleUpdateCurrent = (state, { payload: { payload } }) => {
  return {
    ...state,
    loading: false,
    changing: false,
    saving: false,
    error: false,
    current: payload
  };
};

export default handleActions(
  {
    [INSIGHT_GET.PENDING]: state => ({ ...state, loading: true }),
    [INSIGHT_GET.ERROR]: state => ({ ...state, loading: false, error: true }),
    [INSIGHT_GET.SUCCESS]: handleUpdateCurrent,
    [INSIGHT_UPDATE.PENDING]: state => ({ ...state, saving: true }),
    [INSIGHT_UPDATE.ERROR]: state => ({ ...state, saving: false, error: true }),
    [INSIGHT_UPDATE.SUCCESS]: handleUpdateCurrent,
    [INSIGHT_CREATE.PENDING]: state => ({ ...state, saving: true }),
    [INSIGHT_CREATE.ERROR]: state => ({ ...state, saving: false, error: true }),
    [INSIGHT_CREATE.SUCCESS]: handleUpdateCurrent,
    [INSIGHT_UNSET]: () => defaultState
  },
  defaultState
);
