import { push } from 'react-router-redux';

import { switchLoader } from '../actions/options/loaderActions';
import { showSnackbar } from '../actions/options/snackbarsActions';
import { HTTPErrorCodes } from '../constants/enum';
import { USER_TOKEN } from '../constants/constants';

export default function callAPIMiddleware({ dispatch, getState }) {
    return next => action => {
        const { types, callAPI, forcedCall, entity, meta } = action;

        if (!types) {
            // Normal action: pass it on
            return next(action);
        }

        if (
            !Array.isArray(types) ||
            (types.length !== 2 && types.length !== 3) ||
            !types.every(type => typeof type === 'string')
        ) {
            throw new Error('Expected an array of two or three string types.');
        }

        if (typeof callAPI !== 'function') {
            throw new Error('Expected callAPI to be a function.');
        }

        const { entities, intl } = getState();
        if (!forcedCall && entity && entities[entity].length !== 0) {
            return new Promise(() => entities[entity]);
        }

        const [successType, loadingType, errorType] = types;

        dispatch(switchLoader(loadingType));

        return callAPI().then(
            response => {
                const payload = response.items ? response.items : response.payload;

                dispatch({
                    payload: (meta && meta.successPayload) || payload,
                    type: successType
                });
                if (meta && meta.successRedirect) {
                    dispatch(push(meta.successRedirect));
                }

                dispatch(switchLoader(loadingType));

                return response;
            },
            error => {
                if (error.statusCode === HTTPErrorCodes.UNAUTHORIZED) {
                    localStorage.removeItem(USER_TOKEN);
                    window.location = '/login';
                } else if (error.statusCode === HTTPErrorCodes.NOT_FOUND) {
                    window.location = '/not-found';
                } else if (error.statusCode !== HTTPErrorCodes.FORBIDDEN) {
                    dispatch(showSnackbar({ id: 'ERROR', message: intl.messages.components.snackbar.error }))
                }

                dispatch(switchLoader(loadingType));
                if (errorType) {
                    dispatch({ type: errorType, payload: meta && meta.errorPayload });
                }
                if (meta && meta.errorRedirect) {
                    dispatch(push(meta.errorRedirect));
                }
                return new Promise((resolve, reject) => reject(error));
            }
        )
    }
}

