import moment from 'moment';

export const isCompanyProfilePublishDisabled = plan => {
  if (!plan) {
    return false;
  }
  return plan.constraints?.companyProfilePublishable === false;
};

export const isMaxConcurrentOpportunitiesReached = plan => {
  if (!plan) {
    return false;
  }
  const limit = plan.constraints?.maxConcurrentOpportunities;
  if (!limit) {
    return false;
  }
  const value = plan.constraintValues?.concurrentOpportunities || 0;
  return value >= limit;
};

export const getActiveSearchLimit = plan => {
  if (!plan) {
    return null;
  }
  return plan.constraints?.activeSearchLimit;
};

export const getMaxValidTo = plan => {
  const maxValidity = plan?.constraints?.maxOpportunityValidity;
  if (!maxValidity) {
    return null;
  }
  return moment.utc().add(maxValidity.unit.toLowerCase(), maxValidity.value).toDate();
};
