import { authRequest } from '../Request';
import { API_URL } from '../../config';

const DEFAULT_PAGINATION = { size: 1000 };

export function getGroups(type, companyId, search, pagination) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/groups`)
      .query({ ...DEFAULT_PAGINATION, ...pagination, type, search, companyId })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getGroup(groupId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/groups/${groupId}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getGroupSources(groupId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/groups/${groupId}/sources`)
      .query({ meta: true })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function createGroup(group) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/groups`)
      .send(group)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function updateGroup(groupId, group) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .put(`${API_URL}/api/v1/groups/${groupId}`)
      .send(group)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function deleteGroup(groupId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .delete(`${API_URL}/api/v1/groups/${groupId}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getGroupMembers(groupId, membership, lang, pagination) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/groups/${groupId}/members`)
      .query({ ...DEFAULT_PAGINATION, ...pagination, membership, lang })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getGroupMember(groupId, userId, lang) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/groups/${groupId}/members/${userId}`)
      .query({ lang })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getGroupInvitationData(groupId, membership) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/groups/${groupId}/invitations/${membership.toLowerCase()}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function createGroupMembers(groupId, data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/groups/${groupId}/members`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function updateGroupMember(groupId, userId, data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/groups/${groupId}/members/${userId}`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function deleteGroupMember(groupId, userId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .delete(`${API_URL}/api/v1/groups/${groupId}/members/${userId}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function deleteGroupMembers(groupId, items) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .delete(`${API_URL}/api/v1/groups/${groupId}/members`)
      .send({ items })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getGroupMemberCandidates(groupId, search, lang, suggested, pagination) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/groups/${groupId}/members/candidates`)
      .query({ ...DEFAULT_PAGINATION, ...pagination, search, lang, suggested })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function shareGroup(groupId, data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/groups/${groupId}/share`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function generateGroupMembers(groupId, groupMembership, count) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/admin/games/generate`)
      .send({ count, groupId, groupMembership })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
