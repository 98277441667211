import React, { useMemo } from 'react';

import EchoGrid from '../../../components/analytics/EchoGrid';

const ProjectOverviewEchoGrid = ({ participants, ...props }) => {
  const adapted = useMemo(() => {
    const res = [];
    for (let i = 0; i < participants.length; i += 1) {
      const p = participants[i];
      if (p.simulation?.finishedAt) {
        res.push(p);
      }
    }
    return res;
  }, [participants]);

  return <EchoGrid {...props} participants={adapted} />;
};

export default ProjectOverviewEchoGrid;
