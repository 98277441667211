import * as Api from '../../api/entities/activityTemplateApi';
import { createApiCallAction } from '../apiCallAction';
import { ACTIVITY_TEMPLATES_GET, ACTIVITY_TEMPLATES_MESSAGES_GET } from '../../constants/constants';

const getActivityTemplatesAction = createApiCallAction(ACTIVITY_TEMPLATES_GET, Api.getActivityTemplates);
export function getActivityTemplates(companyId) {
  return getActivityTemplatesAction(companyId);
}

const getActivityTemplateMessagesAction = createApiCallAction(
  ACTIVITY_TEMPLATES_MESSAGES_GET,
  Api.getActivityTemplateMessages
);
export function getActivityTemplateMessages(templateId, data) {
  return getActivityTemplateMessagesAction(templateId, data);
}
