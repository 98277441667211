import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { pure } from 'recompose';

import ProjectCardHeader from './ProjectCardHeader';
import ProjectCardStatus from './ProjectCardStatus';
import ProjectCardSummary from './ProjectCardSummary';

import Button from '../mui/Button';
import IconButton from '../mui/IconButton';

import ProjectActionsIconMenu from '../iconMenu/ProjectActionsIconMenu';
import ProjectCardView from './ProjectCardView';

import { EProjectFilterLayout, EProjectState } from '../../constants/enum';

const ProjectCard = props => {
  const { project, intl, projectCrudAllowed, highlight, basePath, layout, auth } = props;
  const {
    onActivateProject,
    onFinishProject,
    onDeleteProject,
    onEditProject,
    onExportProject,
    onShowProjectDetail,
    onInviteApplicants,
    onCopyProject
  } = props;
  const {
    validity: { state },
    demo,
    company,
    slug,
    id,
    activity: { statusCounts }
  } = project;
  const iconMenuMessages = intl.messages.components.iconMenu;
  const linkToApplicants = `${basePath}/overview/${company.slug}/${slug}`;
  const getMainActionButton = () => {
    if (!projectCrudAllowed || demo || state === EProjectState.ACTIVE.key || state === EProjectState.FINISHED.key) {
      return null;
    }
    if (state === EProjectState.CONCEPT.key) {
      return (
        <Button
          raised
          primary
          label={iconMenuMessages.activate}
          onClick={() => onActivateProject(project)}
          className={highlight ? 'vb-anim-ripple vb-rect-button' : ''}
        />
      );
    }
    return <Button label={iconMenuMessages.edit} onClick={() => onEditProject(company.id, id)} />;
  };
  const mainAction = getMainActionButton();
  const sideActions = [];
  if ((state === EProjectState.SCHEDULED.key || state === EProjectState.ACTIVE.key) && !project.view) {
    sideActions.push(
      <IconButton
        key="inviteButton"
        className={statusCounts.total === 0 && highlight ? 'vb-anim-ripple' : ''}
        onClick={() => onInviteApplicants(company.slug, slug)}
        tooltip={iconMenuMessages.invite}
      >
        <i className="material-icons">group_add</i>
      </IconButton>
    );
  }
  if (projectCrudAllowed && !demo) {
    sideActions.push(
      <ProjectActionsIconMenu
        {...{
          auth,
          intl,
          project: project,
          onActivateProject,
          onDeleteProject,
          onFinishProject,
          onEditProject,
          onExportProject,
          onShowProjectDetail,
          onCopyProject
        }}
      />
    );
  }
  return (
    <Link to={{ pathname: linkToApplicants }} style={{ textDecoration: 'none' }}>
      <ProjectCardView
        header={<ProjectCardHeader project={project} auth={auth} companyNameDisabled />}
        status={<ProjectCardStatus project={project} intl={intl} compact={layout === EProjectFilterLayout.LIST} />}
        summary={<ProjectCardSummary project={project} />}
        mainAction={mainAction}
        sideActions={sideActions}
        layout={layout}
      />
    </Link>
  );
};

ProjectCard.propTypes = {
  card: PropTypes.object,
  intl: PropTypes.object,
  auth: PropTypes.object,
  projectCrudAllowed: PropTypes.bool.isRequired,
  highlight: PropTypes.bool,
  //
  onInviteApplicants: PropTypes.func,
  onActivateProject: PropTypes.func,
  onFinishProject: PropTypes.func,
  onDeleteProject: PropTypes.func,
  onEditProject: PropTypes.func,
  onExportProject: PropTypes.func,
  onShowProjectDetail: PropTypes.func
};

export default pure(ProjectCard);
