import { GUIDE_NEXT_STEP, GUIDE_PREV_STEP, GUIDE_SHOW, GUIDE_HIDE, GUIDE_FINISH } from '../../constants/constants';

export const nextGuideStep = () => dispatch => dispatch({
  type: GUIDE_NEXT_STEP
});

export const prevGuideStep = () => dispatch => dispatch({
  type: GUIDE_PREV_STEP
});

export const showGuide = () => dispatch => dispatch({
  type: GUIDE_SHOW
});

export const hideGuide = () => dispatch => dispatch({
  type: GUIDE_HIDE
});

export const finishGuide = () => dispatch => dispatch({
  type: GUIDE_FINISH
});
