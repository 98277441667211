import { authRequest } from '../Request';
import { API_URL } from '../../config';

export function getActivityTemplates(companyId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/admin/activities/templates`)
      .query({ companyId })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getActivityTemplateMessages(templateId, data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/admin/activities/templates/${templateId}/messages`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
