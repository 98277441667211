import React from 'react';
import PropTypes from 'prop-types';

import { formatMessage } from '../../utils/utils';
import { BRAND } from '../../constants/constants';

import SimpleModal from './SimpleModal';

const NewAccountResult = props => {
  const { success, intl, switchModal, id, router } = props;
  const messages = intl.messages.components.pages.public.accountNew;
  const cancelLabel = !success && messages.errorMessage.button;
  let message;
  if (success) {
    message = (
      <div>
        <p>
          {messages.successMessage.text1} <b>{messages.successMessage.text2}</b>
        </p>
        <p>{messages.successMessage.text3}</p>
      </div>
    );
  } else {
    const supportEmail = BRAND.email('support');
    message = (
      <p>{formatMessage(messages.errorMessage.text1, [<a href={`mailto:${supportEmail}`}>{supportEmail}</a>])}</p>
    );
  }
  const callback = () => {
    if (success) {
      router.push({ pathname: '/login' });
    }
  };
  return (
    <SimpleModal
      open
      cancelLabel={cancelLabel}
      switchModal={switchModal}
      callback={callback}
      id={id}
      message={message}
    />
  );
};

NewAccountResult.propTypes = {
  success: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  //
  switchModal: PropTypes.func.isRequired
};

export default NewAccountResult;
