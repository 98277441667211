import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import { ResponsiveContainer, BarChart, Bar, YAxis } from 'recharts';

import RightArrowIcon from 'material-ui/svg-icons/navigation/subdirectory-arrow-right';

import LockedPlaceholder from '../../../placeholder/LockedPlaceholder';
import FeatureIndicator from '../../../mui/FeatureIndicator';
import DualMetric from '../../../analytics/DualMetric';
import DualMetricsRecommendation from '../../../analytics/DualMetricsRecommendation';
import Metric from '../../../icons/Metric';
import Sign from '../../../icons/Sign';
import MetricRecommendations from '../../../applicant/MetricRecommendations';
import ParticipantName from '../../../basic/ParticipantName';

import { EProfileFeature, EActivityType } from '../../../../constants/enum';
import { computeDualMetricData } from '../../../../utils/analytics';
import { filterRiskMetrics } from './EchoUtils';

const CHART_TOP_MARGIN = 0;

const CustomBar = props => {
  const OFFSET = CHART_TOP_MARGIN;
  const GAP = 2;
  const { x, y, width, height, payload, value } = props;
  const barCount = payload.valueType === 'FIAT' ? 4 : 10;
  const chartHeight = y + height - OFFSET;
  const barHeight = (chartHeight + GAP) / barCount;
  const paths = [];
  const color = payload.color;
  for (let i = 0; i < barCount; i += 1) {
    const maxVal = barCount - i;
    const minVal = barCount - i - 1;
    const valAdj = value * barCount;
    const top = i * barHeight + OFFSET;
    const bottom = top + barHeight - GAP;
    paths.push(
      <path
        key={`${i}_0`}
        d={`M${x},${top} H${x + width} V${bottom} H${x}Z`}
        fill={valAdj >= maxVal ? color : '#ededed'}
      />
    );
    if (minVal < valAdj && valAdj < maxVal) {
      const fraction = (valAdj - minVal) * barHeight;
      paths.push(<path key={`${i}_1`} d={`M${x},${bottom - fraction} H${x + width} V${bottom} H${x}Z`} fill={color} />);
    }
  }
  const valueOverBar = value < 1 - 0.8 / barCount;
  const textColor = valueOverBar ? color : 'white';
  const textY = valueOverBar ? y - 5 : y + 5 + barHeight / 2;
  return (
    <g>
      {paths}
      <text fill={textColor} fontWeight={500} x={x + width / 2} y={textY} textAnchor="middle">
        {payload.formattedValue}
      </text>
    </g>
  );
};

CustomBar.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  payload: PropTypes.object,
  value: PropTypes.number
};

const signSize = 16;
const SignIcon = ({ rank, color }) => {
  return (
    <div className="sign" style={{ width: signSize, height: signSize }}>
      <svg
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
        width={signSize}
        height={signSize}
        viewBox={`0 0 ${signSize} ${signSize}`}
      >
        <g>
          <circle cx={signSize / 2} cy={signSize / 2} r={signSize / 2} fill="#fff" />
        </g>
      </svg>
      <Sign size={signSize} rank={rank} style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, color }} />
    </div>
  );
};

const FactorBar = ({ value, color }) => {
  return (
    <div style={{ marginBottom: '8px' }}>
      <svg width="100%" height="16">
        <g>
          <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" fill="rgba(33,33,33,0.08)" />
          <rect x="0" y="0" rx="2" ry="2" width={`${value * 100}%`} height="100%" fill={color} />
        </g>
      </svg>
    </div>
  );
};

const MetricIcon = props => {
  return <Metric {...props} />;
};

const getInterpretationBullets = ({ interpretation }) => {
  return [...((interpretation && interpretation.bullets) || [])];
};

const renderTimestamp = ({ label, value }, idx) => {
  const values = [];
  if (isArray(value)) {
    for (let i = 0; i < value.length; i += 1) {
      if (i > 0) {
        values.push(<br key={`br-${i}`} />);
      }
      values.push(value[i]);
    }
  } else {
    values.push(value);
  }
  return (
    <div key={idx} className="col-xs-2 text-center">
      <p>
        <label className="top">{label}</label>
        {values}
      </p>
    </div>
  );
};

const renderInterpretationText = m => {
  const { text } = m.interpretation || {};
  if (text) {
    return (
      <div>
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    );
  }
};

const renderInterpretationBullets = m => {
  const bullets = getInterpretationBullets(m);
  if (bullets.length === 0) {
    return null;
  }
  return (
    <div>
      <ul>
        {map(bullets, (d, idx) => (
          <li key={idx}>
            <span>{d}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const renderRecommendations = ({ interpretation }) => {
  return interpretation && <MetricRecommendations {...interpretation.recommendations} />;
};

const renderFactors = (m, factorsMap) => {
  const factors = factorsMap[m.key];
  if (factors && factors.length) {
    return (
      <div style={{ marginTop: '12px' }}>
        {map(factors, f => (
          <div key={f.key} className="competency-factor-container">
            <div className="header">
              <div className="icon">
                <MetricIcon size={24} metric={f} style={{ color: f.color }} />
              </div>
              <span className="competency-factor-label">{f.label}</span>
              <span>{f.formattedValue}</span>
              <SignIcon rank={f.rank || 'LOW'} color={f.color} />
            </div>
            <FactorBar value={f.value} color={f.color} />
            {renderInterpretationText(f)}
            {renderInterpretationBullets(f)}
            {renderRecommendations(f)}
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const ApplicantPrintPage = props => {
  const { intl, activities, activityIndex, locked, anonymous, firstName, lastName, email, features } = props;
  const activity = activityIndex != null ? activities[activityIndex] : activities[0];
  const {
    overviewItems,
    timeStatsItems,
    doughnutData,
    competencyFactors,
    performanceIndicators,
    dualMetrics,
    interpretations,
    weighted,
    activityName,
    project
  } = activity;

  const projectMessages = intl.messages.components.pages.private.project;
  const messages = projectMessages.applicantDetail;

  if (locked) {
    return (
      <div className="applicant-print">
        <div className="item-block">
          <LockedPlaceholder message={messages.lockedProfileMessage} />
        </div>
      </div>
    );
  }

  const wellBeing = EActivityType.ECHO.matches(project);

  const allCompetencies = [...activity.competencies, ...activity.otherCompetencies];
  const competencies = wellBeing ? filterRiskMetrics(allCompetencies) : allCompetencies;
  const projectName = project.name;
  const [identifier, ...simTimestamps] = overviewItems;
  const domain = [0, 1];

  const pairedCompetencies = chunk(competencies, 2);
  const groupedIndicators = chunk(performanceIndicators, performanceIndicators.length > 3 ? 2 : 3);
  const operationalizedMetrics = filter([...competencies, ...performanceIndicators], c => !!c.operationalization);

  return (
    <div className="applicant-print">
      <div className="applicant-summary">
        <div className="clearfix">
          <div className="name">
            <ParticipantName
              anonymous={anonymous}
              firstName={firstName}
              lastName={lastName}
              email={email}
              variant="mini"
            />
          </div>
          {!anonymous && <span className="email pull-right">{email}</span>}
        </div>
        <div>
          <div className="row">
            <div className="col-xs-6">
              <p>
                <label className="id-info">{messages.idSim}</label>
                {identifier.value}
                <br />
                <label className="id-info">{messages.projectName}</label>
                {projectName}
                <br />
                <label className="id-info">{messages.simulationName}</label>
                {activityName}
              </p>
            </div>
            {map(simTimestamps, renderTimestamp)}
          </div>
          {weighted && (
            <div className="row">
              <div className="col-xs-12">
                <FeatureIndicator
                  name={projectMessages.weighted.name}
                  description={projectMessages.weighted.description}
                />
              </div>
            </div>
          )}
          {(doughnutData.score || doughnutData.hints || timeStatsItems.length > 0) && (
            <div className="row results">
              {/*<div className="col-xs-2 text-center">*/}
              {/*<p>{doughnutData.position.label}</p>*/}
              {/*<label className="top">{messages.chart.position}</label>*/}
              {/*</div>*/}
              {doughnutData.score && (
                <div className="col-xs-2 text-center">
                  <label className="top">{messages.chart.score}</label>
                  <p>{doughnutData.score.label}</p>
                </div>
              )}
              {doughnutData.hints && (
                <div className="col-xs-2 text-center">
                  <label className="top">{messages.chart.skipped}</label>
                  <p>{doughnutData.hints.label}</p>
                </div>
              )}
              {(doughnutData.score || doughnutData.hints) && (
                <div className={`col-xs-${doughnutData.score && doughnutData.hints ? '2' : '4'}`} />
              )}
              {map(timeStatsItems, (ts, idx) => (
                <div key={idx} className="col-xs-2 text-center">
                  <label className="top">{ts.label}</label>
                  <p>{ts.value}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {(competencies.length > 0 || wellBeing) && (
        <div className="section competencies">
          <span className="title">{wellBeing ? messages.wellBeing : messages.competencies}</span>
          {!wellBeing && (
            <div className="chart">
              <ResponsiveContainer height={200}>
                <BarChart data={competencies} margin={{ top: CHART_TOP_MARGIN }} barCategoryGap={1}>
                  <Bar dataKey="value" fill="#444" shape={<CustomBar />} isAnimationActive={false} />
                  <YAxis domain={domain} hide />
                </BarChart>
              </ResponsiveContainer>
              <div className="chart-xaxis">
                {map(competencies, competency => (
                  <div
                    key={competency.key}
                    className="chart-xaxis-item"
                    style={{ width: `${100 / competencies.length}%` }}
                  >
                    <div className="icon">
                      <MetricIcon size={30} metric={competency} style={{ color: competency.color }} />
                    </div>
                    <br />
                    <span style={{ color: competency.color }}>{competency.shortLabel}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {competencies.length === 0 && wellBeing && <p>{messages.noRiskFactorsMessage}</p>}
          {features[EProfileFeature.SKILLS_DESCRIPTION] &&
            map(pairedCompetencies, (pair, pairIdx) => (
              <div className="row" key={pairIdx}>
                {map(pair, competency => (
                  <div key={competency.key} className="col-xs-6">
                    <div className="item-block">
                      <div className="header">
                        <div className="icon">
                          <MetricIcon size={30} metric={competency} style={{ color: competency.color }} />
                        </div>
                        <span className="title">{competency.label}</span>
                        <span>{competency.formattedValue}</span>
                        <SignIcon rank={competency.rank} color={competency.color} />
                      </div>
                      {renderInterpretationText(competency)}
                      {renderInterpretationBullets(competency)}
                      {renderRecommendations(competency)}
                      {renderFactors(competency, competencyFactors)}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          {!features[EProfileFeature.SKILLS_DESCRIPTION] && (
            <div className="row">
              <div className="col-xs-12">
                <div className="item-block">
                  <LockedPlaceholder message={messages.lockedSkillsDescriptionMessage} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {groupedIndicators.length > 0 && (
        <div className="section indicators">
          <span className="title">{messages.indicators}</span>
          {map(groupedIndicators, (group, idx) => (
            <div key={idx} className="row">
              {map(group, indicator => {
                const { key, rank, label, color, interpretation } = indicator;
                return (
                  <div key={key} className={group.length === 2 ? 'col-xs-6' : 'col-xs-4'}>
                    <div className="item-block">
                      {rank !== 'LOCKED' ? (
                        <div>
                          <div className="header">
                            <div className="icon">
                              <MetricIcon metric={indicator} size={30} style={{ color }} />
                              <SignIcon rank={rank} color={color} />
                            </div>
                            <div>
                              <div className="title">{label}</div>
                              {interpretation && <div>{`${interpretation.label}`}</div>}
                            </div>
                          </div>
                          {renderInterpretationText(indicator)}
                          {renderInterpretationBullets(indicator)}
                          {renderRecommendations(indicator)}
                        </div>
                      ) : (
                        <LockedPlaceholder message={messages.lockedIndicatorMessage} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}
      {dualMetrics && dualMetrics.length > 0 && (
        <div className="section section__dual-metrics">
          <span className="title">{messages.dimensions}</span>
          {map(chunk(dualMetrics, 2), (c, idx) => (
            <div key={idx} className="row">
              {map(c, dm => (
                <div key={dm.key} className="col-xs-6">
                  <div className="item-block" style={{ padding: 0 }}>
                    <DualMetric metric={dm} size={{ min: 12, max: 24 }} {...computeDualMetricData(dm)} />
                  </div>
                </div>
              ))}
            </div>
          ))}
          <DualMetricsRecommendation metrics={dualMetrics} />
        </div>
      )}

      {interpretations && interpretations.length > 0 && (
        <div className="section interpretations">
          <span className="title">{messages.interpretations}</span>
          {map(interpretations, ({ id, metrics, text }) => (
            <div key={id} className="item-block">
              <div className="row">
                <div className="col-xs-3">
                  <div className="header">
                    <span className="title">{text.title}</span>
                    <div className="metrics">
                      {map(metrics, m => (
                        <div key={m.key} className="icon">
                          <MetricIcon metric={m} style={{ color: m.color }} size={24} />
                          <SignIcon rank={m.rank} color={m.color} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-xs-9">
                  <p>{text.conclusion}</p>
                  {text.recommendation && (
                    <div className="interpretation-recommendation">
                      <RightArrowIcon />
                      <p>{text.recommendation}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {operationalizedMetrics.length > 0 && (
        <div className="section operationalizations">
          <span className="title">{messages.operationalizations}</span>
          {map(
            filter([...competencies, ...performanceIndicators], c => !!c.operationalization),
            c => {
              const subFactors = competencyFactors[c.key];
              return (
                <div key={c.key} className="item-block">
                  <div className="header">
                    <div className="icon">
                      <MetricIcon size={24} metric={c} style={{ color: 'rgba(33, 33, 33, 0.87)' }} />
                    </div>
                    <span className="title">{c.label}</span>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: c.operationalization }} />
                  {c.cause && (
                    <>
                      <h6>{messages.causesLabel}</h6>
                      <p dangerouslySetInnerHTML={{ __html: c.cause }} />
                    </>
                  )}
                  {subFactors && subFactors.length > 0 && (
                    <div>
                      {map(subFactors, f => (
                        <div key={f.key} className="competency-factor-container">
                          <div className="header">
                            <div className="icon">
                              <MetricIcon size={20} metric={f} style={{ color: 'rgba(33, 33, 33, 0.87)' }} />
                            </div>
                            <span className="competency-factor-label">{f.label}</span>
                          </div>
                          <p dangerouslySetInnerHTML={{ __html: f.operationalization }} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

ApplicantPrintPage.propTypes = {
  features: PropTypes.object,
  intl: PropTypes.object,
  isSten: PropTypes.bool,
  locked: PropTypes.bool,
  softSkills: PropTypes.object,
  hardSkills: PropTypes.object,
  sortedSkills: PropTypes.array,
  performanceIndicators: PropTypes.array,
  doughnutData: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  identifier: PropTypes.string,
  projectName: PropTypes.string,
  activityName: PropTypes.string
};

export default ApplicantPrintPage;
