import React, { useState } from 'react';
import { Field } from 'redux-form';
import _map from 'lodash/map';

import RadioButton from 'material-ui/RadioButton/RadioButton';

import { CULTURE_FIT_DIMENSIONS } from '../../../constants/enum';

const DimensionSelect = props => {
  const {
    input: { onChange, value },
    meta: { error, touched },
    label,
    description,
    optionLabels,
    changeLabel,
    indicateRequired
  } = props;
  const activeValue = value !== null ? (optionLabels && optionLabels[value]) || value : null;
  const [editing, setEditing] = useState(!activeValue);
  const buttons = [];
  for (let i = 0; i < 4; i += 1) {
    buttons.push(
      <div className="builder-dimension-select__item" key={`dimButton${i}`}>
        <div className="builder-dimension-select__item__line" />
        <div
          className="builder-dimension-select__item__button"
          role="button"
          onClick={() => {
            setEditing(false);
            return onChange(i);
          }}
        >
          <div className="mui-padded-half">
            <RadioButton
              value={i}
              checked={value === i}
              iconStyle={{ left: 0, margin: '0 auto', float: 'none', background: 'white', borderRadius: '50%' }}
            />
          </div>
          <div style={{ padding: '4px' }}>{(optionLabels && optionLabels[i]) || i}</div>
        </div>
      </div>
    );
  }

  const err = touched && error;
  return (
    <div className="builder-dimension-select">
      <div className="mui-fields-label">
        {label}
        {indicateRequired ? ' *' : ''}
      </div>
      {editing && description && <div className="mui-fields-description">{description}</div>}
      {editing && <div>{buttons}</div>}
      {!editing && (
        <div>
          {activeValue}{' '}
          <a
            role="button"
            onClick={() => setEditing(true)}
            className="mui-margin-horizontal link-button heavy"
            style={{ lineHeight: 'inherit' }}
          >
            <span>{changeLabel || 'Change'}</span>
          </a>
        </div>
      )}
      {err && <div className="mui-fields-error-text">{err}</div>}
    </div>
  );
};

const messages = {
  cfStability: {
    label: 'Na co je zaměřené vaše pracovní prostředí',
    description:
      'Klade firemní prostředí důraz spíše na stabilitu, dlouhodobé vize a vsází zejména na pracovní a zaměstnanecké jistoty? A nebo je kultura společnosti dynamická s vysokými nároky na adaptabilitu zaměstnanců a značnou mírou nejistot?',
    options: ['Výrazně jistoty', 'Spíše jistoty', 'Spíše dynamika', 'Výrazně dynamika']
  },
  cfPerformance: {
    label: 'Co jsou hlavní hodnoty a motivace, které prostředí oceňuje',
    description:
      'Oceňuje firemní kultura zejména podávání vysokých výkonů, plnění plánů a překonávání výkonových milníků? A nebo je zaměřená na kulturu vztahů, pospolitosti a přátelskosti napříč organizací?',
    options: ['Výrazně výkon', 'Spíše výkon', 'Spíše vztahy', 'Výrazně vztahy']
  },
  cfIndividuality: {
    label: 'Jaký pracovní styl prostředí podporuje',
    description:
      'Zaměřujete se na posilování individualit, jejich růst a vzdělávání, protože víte, že na silných jednotlivcích stojí úspěch celku? A nebo si více zakládáte na skupinové synergii a věříte v platnost rovnice 1+1 > 2?',
    options: ['Výrazně individuality', 'Spíše individuality', 'Spíše týmy', 'Výrazně týmy']
  },
  cfIndependence: {
    label: 'Jaké pracovní podmínky prostředí vytváří',
    description:
      'Preferujete, když si pracovníci dovedou sami poradit, fungují nezávisle a vnímáte je spíše jako suverénní „jednotky“? A nebo více ctíte hierarchickou strukturu, ve které je nadřízený odpovědný a zároveň nápomocný?',
    options: ['Výrazně samostatnost', 'Spíše samostatnost', 'Spíše hierarchie', 'Výrazně hierarchie']
  },
  cfOpenMindedness: {
    label: 'Jaký vztah k inovacím prostředí podporuje',
    description:
      'Je prostředí otevřené novinkám a trendům, nebojíte se experimentovat a rychle implementovat nové metody a postupy? Nebo preferujete ověřené, stabilní a vyzkoušené postupy, a zbytečně se do experimentů nepouštíte?',
    options: ['Výrazně otevřenost', 'Spíše otevřenost', 'Spíše tradicionalismus', 'Výrazně tradicionalismus']
  },
  cfChaos: {
    label: 'Panuje u vás spíše chaos nebo řád',
    description:
      'Je pro vaši kulturu specifický chaos, časté změny a turbulentní prostředí, ve kterém je méně jistoty? A nebo je pro vás typický pevně nastavený řád, na který se mohou všichni spolehnout i na dlouhou dobu dopředu?',
    options: ['Výrazně chaos', 'Spíše chaos', 'Spíše řád', 'Výrazně řád']
  },
  cfStatus: {
    label: 'Jaký profesní růst prostředí pracovníkům nabízí',
    description:
      'Jakým způsobem cílíte dlouhodobý rozvoj pracovníků? Je pro vás typičtější, že klíčoví pracovníci budují své osobní postavení skrze manažerské či ředitelské pozice, a nebo se zaměřují na rozvoj své profesní odbornosti?',
    options: ['Výrazně osobní postavení', 'Spíše osobní postavení', 'Spíše odbornost', 'Výrazně odbornost']
  },
  requiredMessage: 'Vyberte možnost',
  changeLabel: 'Změnit'
};

const CultureFitCommonPart = () => {
  return (
    <div>
      {_map(CULTURE_FIT_DIMENSIONS, dim => (
        <div key={dim.key} className="row">
          <div className="col-sm-12">
            <Field
              name={dim.formName}
              component={DimensionSelect}
              label={messages[dim.key].label}
              description={messages[dim.key].description}
              optionLabels={messages[dim.key].options}
              changeLabel={messages.changeLabel}
              indicateRequired
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export const validate = values => {
  const errors = {};
  for (let i = 0; i < CULTURE_FIT_DIMENSIONS.length; i += 1) {
    const dim = CULTURE_FIT_DIMENSIONS[i];
    if (values[dim.formName] == null) {
      errors[dim.formName] = messages.requiredMessage;
    }
  }
  return errors;
};

export default CultureFitCommonPart;
