import { handleActions } from 'redux-actions';

import { getObject } from '../../persistence/storage';
import localStorageDecorator from '../../store/localStorageDecorator';

import * as c from '../../constants/constants';
import { EProjectFilterLayout, EProjectFilterSort, ESortOrder } from '../../constants/enum';

const PERSISTENCE_KEY = 'projectFilterV0';

const getSavedState = () => {
  const obj = getObject(PERSISTENCE_KEY);
  if (obj && obj.pagination) {
    const layout = (obj.layout && EProjectFilterLayout[obj.layout.key]) || EProjectFilterLayout.CARD;
    return { ...obj, layout };
  }
  return null;
};

const defaultState = getSavedState() || {
  shown: false,
  expanded: true,
  advanced: true,
  value: '',
  fromValues: {},
  layout: EProjectFilterLayout.CARD,
  demoShown: true,
  sort: {
    property: EProjectFilterSort.LATEST_PROFILE_ADDED_AT.property,
    order: ESortOrder.DESC,
    activeFirst: true
  },
  pagination: {
    page: 0,
    size: EProjectFilterLayout.CARD.pageSize
  }
};

const withUpdatedFilter = (state, property, value) => ({
  ...state,
  [property]: value,
  pagination: { ...state.pagination, page: 0 }
});

const withUpdatedFromValues = (state, filterKey, values) => ({
  ...state,
  fromValues: {
    ...state.fromValues,
    [filterKey]: values
  },
  pagination: { ...state.pagination, page: 0 }
});

const withLayout = (state, layout) => ({
  ...state,
  layout,
  pagination: { size: layout.pageSize, page: 0 }
});

export default handleActions(
  localStorageDecorator(
    {
      [c.PROJECT_FILTER_VALUE_SET]: (state, { payload }) => withUpdatedFilter(state, 'value', payload),
      [c.PAGE_SET_PROJECTS]: (state, { payload }) => ({ ...state, pagination: { ...state.pagination, ...payload } }),
      [c.PROJECT_FILTER_ADVANCED_TOGGLE]: state => withUpdatedFilter(state, 'advanced', !state.advanced),
      [c.PROJECT_FILTER_LAYOUT_SET]: (state, { payload }) => withLayout(state, payload),
      [c.PROJECT_FILTER_SORT_SET]: (state, { payload }) => withUpdatedFilter(state, 'sort', payload),
      [c.PROJECT_FILTER_FROM_VALUES_SET]: (state, { payload: { filterKey, values } }) =>
        withUpdatedFromValues(state, filterKey, values),
      [c.PROJECT_FILTER_DEMO_SHOWN_SET]: (state, { payload }) => withUpdatedFilter(state, 'demoShown', payload)
    },
    PERSISTENCE_KEY
  ),
  defaultState
);
