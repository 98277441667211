import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import RaisedButton from '../mui/RaisedButton';

import { formatMessage } from '../../utils/utils';

const LockedPlaceholder = props => {
  const { inline, title, message, image, source, button } = props;
  const { upgradeAccount } = props;
  const className = classNames({ 'locked-placeholder': true, 'bg-primary-light': true, inline });
  const upgradeMessage = formatMessage(
    message,
    (idx, content) =>
      upgradeAccount ? (
        <a href="#upgrade" className="text-secondary" onClick={() => upgradeAccount({ source })}>
          {content}
        </a>
      ) : (
        content
      ),
    true
  );
  return (
    <div className={className}>
      {image && <div className="locked-placeholder-image">{image}</div>}
      <i className="material-icons">lock</i>
      {title && <h5>{title}</h5>}
      <div>{upgradeMessage}</div>
      {button && (
        <div className="mui-card-actions">
          <RaisedButton secondary onClick={() => upgradeAccount({ source })} label={button} />
        </div>
      )}
    </div>
  );
};

LockedPlaceholder.propTypes = {
  inline: PropTypes.bool,
  message: PropTypes.string.isRequired,
  image: PropTypes.any,
  source: PropTypes.object,
  upgradeAccount: PropTypes.func
};

export default LockedPlaceholder;
