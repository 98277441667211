import { EOpportunityType } from '../constants/enum';

export const getOpportunityTypeProps = type => {
  switch (type) {
    case EOpportunityType.JOB.key: return { icon: 'briefcase', registrable: true };
    case EOpportunityType.EBOOK.key: return { icon: 'book' };
    case EOpportunityType.BOOK.key: return { icon: 'book-open-page-variant' };
    case EOpportunityType.ARTICLE.key: return { icon: 'newspaper' };
    case EOpportunityType.EVENT.key: return { icon: 'calendar', registrable: true };
    case EOpportunityType.COACH.key: return { icon: 'teach' };
    case EOpportunityType.VIDEO.key: return { icon: 'television-play' };
    case EOpportunityType.ONLINE_COURSE.key: return { icon: 'presentation' };
    case EOpportunityType.TALENT_ACADEMY.key: return { icon: 'account-star', registrable: true };
  }
  return { icon: 'question' };
};
