import { handleActions } from 'redux-actions';

import { PROJECT_PARTICIPANTS_GROUP_MEMBERSHIPS_GET } from '../../constants/constants';

export const defaultState = {
  groups: {}
};

export default handleActions(
  {
    [PROJECT_PARTICIPANTS_GROUP_MEMBERSHIPS_GET.SUCCESS]: (state, { payload: { payload: { members } } }) => ({
      ...state,
      groups: { members, loading: false }
    })
  },
  defaultState
);
