import React from 'react';
import PropTypes from 'prop-types';

const MenuItemText = ({ label, description, style }) => {
  return (
    <div style={{ padding: '0.7em 0', lineHeight: '1.3em', whiteSpace: 'normal', ...style }}>
      <div>{label}</div>
      {description && <div><small>{description}</small></div>}
    </div>
  );
};

MenuItemText.propTypes = {
  label: PropTypes.any.isRequired,
  description: PropTypes.any,
  style: PropTypes.object
};

export default MenuItemText;
