import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import configureStore from './store/configureStore';

import theme from './containers/_muiTheme';
import Router from './routes';

import './index.less';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider muiTheme={theme}>
      <Router />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);
