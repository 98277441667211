import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import FlatButton from 'material-ui/FlatButton';

import MenuItem from '../../../components/menu/MenuItem';
import HorizontalScrollPane from '../../../components/basic/HorizontalScrollPane';

import BaseContainer from '../../../components/basic/BaseContainer';
import ProjectAnalytics from './ProjectAnalytics';
import ProjectHeader from './ProjectHeader';
import QuestionnaireAnalytics from './QuestionnaireAnalytics';
import ParticipantFilter from './ParticipantFilter';
import ApplicantList from '../position/ApplicantList';
import InfoCards from '../../../components/project/InfoCards';
import ProjectContext from './ProjectContext';
import ParticipantActivityStatusChart from '../../../components/analytics/ParticipantActivityStatusChart';
import HighlightSelect from './HighlightSelect';
import ProjectOverviewEchoGrid from './ProjectOverviewEchoGrid';

import allActions from '../../../actions';
import { openParticipantFilter } from '../../../actions/options/participantFiltersActions';
import { setProjectOverviewTab, setProjectOverviewCustomProp } from '../../../actions/options/projectOverviewActions';
import { getProjectUserCompanyTags } from '../../../actions/entities/tagActions';
import { EActivityStatus, EActivityType, EProjectType } from '../../../constants/enum';
import {
  MODAL_CONFIRM_ACTIVATE,
  MODAL_CONFIRM_DELETE,
  MODAL_CONFIRM_FINISH,
  MODAL_PROJECT_COPY,
  RESULTS_GET
} from '../../../constants/constants';
import { getGridOptions } from '../../../selectors/applicantList';
import {
  selectScoreMetric,
  selectScoreMappingMetrics,
  selectNumericalMetrics,
  selectMetricsConfig
} from '../../../selectors/analytics';
import { getCurrentProject, getCurrentCompany, selectParticipants } from '../../../selectors/index';
import { isLimitedContractPlan, getProjectsBasePath, loadToken } from '../../../utils/utils';
import { getCrossComparison } from '../../../selectors/base';

const ETab = {
  ACTIVITY: {
    key: 'ACTIVITY',
    icon: 'offline_bolt'
  },
  LIST: {
    key: 'LIST',
    icon: 'view_list'
  },
  ANALYTICS: {
    key: 'ANALYTICS',
    icon: 'insert_chart'
  },
  CONTEXT: {
    key: 'CONTEXT',
    icon: 'tune'
  }
};

const OverviewTabs = ({ currentTab, onTabSwitch, messages, analyticsEnabled, contextEnabled }) => {
  const getTabProps = tab => ({
    onClick: () => onTabSwitch(tab.key),
    active: currentTab === tab.key,
    icon: <i className="material-icons">{tab.icon}</i>
  });

  return (
    <HorizontalScrollPane>
      <div className="container-flex-row">
        <MenuItem label={messages.activity} {...getTabProps(ETab.ACTIVITY)} />
        <MenuItem label={messages.list} {...getTabProps(ETab.LIST)} />
        {analyticsEnabled && <MenuItem label={messages.analytics} {...getTabProps(ETab.ANALYTICS)} />}
        {contextEnabled && <MenuItem label={messages.context} {...getTabProps(ETab.CONTEXT)} />}
      </div>
    </HorizontalScrollPane>
  );
};

const isProjectUpdated = (props, prevProps) => {
  const { project } = props;
  return project && (!prevProps.project || project.id !== prevProps.project.id);
};
const isQueryTabUpdated = (props, prevProps) => {
  const {
    location: {
      query: { tab }
    }
  } = props;
  return tab && (!prevProps.location || !prevProps.location.query || tab !== prevProps.location.query.tab);
};
const isCurrentTabUpdated = (props, prevProps) => {
  const {
    projectOverview: { tab }
  } = props;
  return tab && (!prevProps.projectOverview || tab !== prevProps.projectOverview.tab);
};

const determineDefaultActiveTab = (props, prevProps) => {
  const { project, crossComparison } = props;
  if (!project || (project === prevProps.project && crossComparison === prevProps.crossComparison)) {
    return null;
  }
  const { targetProject } = crossComparison || {};
  const crossComparisonActive = targetProject && targetProject.id === project.id;
  //
  return crossComparisonActive && ETab.ANALYTICS.key;
};

class ProjectOverview extends React.Component {
  componentDidMount() {
    this.loadData(this.props, false);
    this.updateCurrentTab(this.props, {});
  }

  componentDidUpdate(prevProps) {
    const oldIntl = prevProps.intl;
    const {
      intl: { locale }
    } = this.props;
    if (isProjectUpdated(this.props, prevProps) || locale !== oldIntl.locale) {
      this.loadData(this.props, true);
    }
    this.updateCurrentTab(this.props, prevProps);
  }

  handleChangeQueryTab = tab => {
    if (!tab) {
      return;
    }
    const { location, router } = this.props;
    const { query } = location;
    const tlc = tab.toLowerCase();
    if (!query.tab || query.tab.toLowerCase() !== tlc) {
      router.push({ ...location, query: { ...query, tab: tlc } });
    }
  };

  updateCurrentTab = (props, prevProps) => {
    const {
      location: { query },
      projectOverview: { tab }
    } = this.props;
    const defaultActiveTab = determineDefaultActiveTab(props, prevProps);
    if (isProjectUpdated(this.props, prevProps)) {
      if (defaultActiveTab && !isQueryTabUpdated(this.props, prevProps)) {
        this.handleTabSwitch(defaultActiveTab);
      } else if (query.tab) {
        this.handleTabSwitch(query.tab.toUpperCase());
      } else if (tab) {
        this.handleChangeQueryTab(tab);
      }
    } else if (isCurrentTabUpdated(this.props, prevProps)) {
      this.handleChangeQueryTab(tab);
    } else if (isQueryTabUpdated(this.props, prevProps)) {
      this.handleTabSwitch(query.tab.toUpperCase());
    } else if (defaultActiveTab) {
      this.handleTabSwitch(defaultActiveTab);
    }
  };

  getBasePath = () => {
    const {
      location: { pathname }
    } = this.props;
    return getProjectsBasePath(pathname);
  };

  refreshData = (callApi = true) => {
    const { getCompanies, getProjectsAll } = this.props;
    return getCompanies(callApi).then(companies => {
      getProjectsAll(companies.items);
    });
  };

  loadData = (props = this.props, forceLoad = true) => {
    const {
      location: {
        query: { use_cache }
      },
      intl: { locale },
      project,
      gridOptions,
      getResults,
      getProjectParticipantsGroupMemberships,
      getProjectUserCompanyTags
    } = props;

    if (project) {
      getResults(project.company.slug, project.slug, locale, gridOptions, forceLoad || !use_cache);
      getProjectUserCompanyTags(project);
      getProjectParticipantsGroupMemberships(project.id);
    }
  };

  handleTabSwitch = currentTab => {
    const {
      project,
      setProjectOverviewTab,
      projectOverview: { tab }
    } = this.props;
    if (!project || !currentTab) {
      return;
    }
    const tuc = currentTab.toUpperCase();
    if (!tab || tab.toUpperCase() !== tuc) {
      setProjectOverviewTab(project.id, tuc);
    }
  };

  handleParticipantClick = item => {
    const {
      router,
      params: { companySlug, projectSlug }
    } = this.props;
    if (
      item &&
      item.status === EActivityStatus.FINISHED &&
      item.participant.id &&
      (!item.simulation.locked || (item.result && item.result.metrics))
    ) {
      const cSlug = item.project ? item.project.company.slug : companySlug;
      const pSlug = item.project ? item.project.slug : projectSlug;
      router.push(`${this.getBasePath()}/overview/${cSlug}/${pSlug}/participants/${item.participant.id}`);
    }
  };

  onEditProject = (companyId, projectId) => {
    this.props.router.push(`${this.getBasePath()}/${companyId}/${projectId}/edit`);
  };

  onExportProject = (companyId, projectId, locale, type) => {
    setTimeout(() => {
      window.open(
        `/api/v1/companies/${companyId}/projects/${projectId}/results/data/export?type=${type}&lang=${locale}&access_token=${loadToken()}`
      );
    }, 100);
  };

  onActivateProject = project => {
    this.props.switchModal({
      id: MODAL_CONFIRM_ACTIVATE,
      open: true,
      projectName: project.name,
      args: [project]
    });
  };

  onFinishProject = project => {
    this.props.switchModal({ id: MODAL_CONFIRM_FINISH, open: true, projectName: project.name, args: [project] });
  };

  onDeleteProject = project => {
    this.props.switchModal({ id: MODAL_CONFIRM_DELETE, open: true, projectName: project.name, args: [project] });
  };

  handleCopyProject = project => {
    this.props.switchModal({ id: MODAL_PROJECT_COPY, open: true, project });
  };

  onOpenFilter = () => {
    const { project } = this.props;
    const { openParticipantFilter, setGridOptions } = this.props;
    openParticipantFilter(project.id);
    setGridOptions('applicantsGrid', project.slug, { pageNumber: 0 });
  };

  onCancelCrossComparison = () => {
    const {
      crossComparison: { sourceProject },
      project,
      router
    } = this.props;
    const { cancelCrossComparison } = this.props;
    cancelCrossComparison();
    if (sourceProject.id !== project.id) {
      router.push(`${this.getBasePath()}/overview/${sourceProject.company.slug}/${sourceProject.slug}`);
    }
  };

  onUpdateCrossComparisonParticipants = p => {
    const { updateCrossComparisonParticipants } = this.props;
    updateCrossComparisonParticipants(p);
  };

  onSetCrossComparisonParticipantActive = (p, idx) => {
    const {
      crossComparison: { activeIndex }
    } = this.props;
    const { setCrossComparisonParticipantActive } = this.props;
    setCrossComparisonParticipantActive(activeIndex === idx ? null : idx);
  };

  handleToggleCrossComparisonManagerial = () => {
    const {
      crossComparison: { managerial }
    } = this.props;
    const { setCrossComparisonManagerial } = this.props;
    setCrossComparisonManagerial(!managerial);
  };

  getCurrentTab = () => {
    const {
      projectOverview: { tab }
    } = this.props;
    return tab || ETab.ACTIVITY.key;
  };

  handleSetActivityStatusChartDateRange = range => {
    const { project, setProjectOverviewCustomProp } = this.props;
    setProjectOverviewCustomProp(project.id, 'activityStatusChartDateRange', range);
  };

  getCrossComparisonProperties = () => {
    const { project, crossComparison } = this.props;
    const { sourceProject, targetProject } = crossComparison;
    return {
      isSource: sourceProject && sourceProject.id === project.id,
      isTarget: targetProject && targetProject.id === project.id
    };
  };

  handleOpenHighlight = () => {
    const { isTarget } = this.getCrossComparisonProperties();
    if (!isTarget) {
      const { project, startCrossComparison } = this.props;
      startCrossComparison([], project, project);
    }
  };

  render() {
    const {
      auth,
      intl,
      project,
      company,
      participants,
      scoreMetric,
      metricsConfig,
      scoreMappingMetrics,
      numericalMetrics,
      metricDefinitions,
      crossComparison,
      projects,
      gridOptions,
      loader,
      projectOverview
    } = this.props;
    const { getProjectInvitationUrls } = this.props;
    const currentTab = this.getCurrentTab();
    const projectCrudAllowed = auth.canEditProjects && !isLimitedContractPlan(company);
    const commonProps = {
      auth,
      intl,
      project,
      projects,
      company,
      scoreMetric,
      metricsConfig,
      scoreMappingMetrics,
      numericalMetrics,
      projectCrudAllowed,
      loadData: this.loadData,
      refreshData: this.refreshData,
      onParticipantClick: this.handleParticipantClick
    };
    const resultsLoading = loader[RESULTS_GET.PENDING];
    if (!project || resultsLoading) {
      return null;
    }
    const isWellBeing = EActivityType.ECHO.matches(project);
    const isQuestionnaire = project.type === EProjectType.QUESTIONNAIRE.key;
    const crossCompProps = this.getCrossComparisonProperties();
    const analyticsMessages = intl.messages.components.pages.private.project.analytics;
    return (
      <BaseContainer>
        <ProjectHeader
          projectCrudAllowed={projectCrudAllowed}
          project={project}
          intl={intl}
          auth={auth}
          getProjectInvitationUrls={getProjectInvitationUrls}
          onActivateProject={this.onActivateProject}
          onDeleteProject={this.onDeleteProject}
          onFinishProject={this.onFinishProject}
          onEditProject={this.onEditProject}
          onOpenFilter={this.onOpenFilter}
          onCopyProject={this.handleCopyProject}
          onExportProject={this.onExportProject}
          onOpenHighlight={this.handleOpenHighlight}
          onRefresh={() => this.loadData(this.props, true)}
        />
        <ParticipantFilter project={project} />
        {(crossCompProps.isSource || crossCompProps.isTarget) && (
          <div
            className="bg-primary-light container-flex-row fw-yes mui-padded-half mui-appear mui-backdrop-blur print-none"
            style={{ borderRadius: '2px' }}
          >
            <div className="mui-padded-half flex1">
              {crossCompProps.isTarget && (
                <HighlightSelect
                  selected={crossComparison.participants}
                  onChange={this.onUpdateCrossComparisonParticipants}
                />
              )}
            </div>
            <div className="mui-padded-half">
              <FlatButton
                label={
                  crossCompProps.isSource && crossCompProps.isTarget
                    ? analyticsMessages.crossComparison.cancelHighlightButton
                    : analyticsMessages.crossComparison.cancelButton
                }
                onClick={this.onCancelCrossComparison}
                icon={<i className="material-icons">close</i>}
              />
            </div>
          </div>
        )}
        <div className="project-overview-content mui-card">
          <div className="project-overview-tabs">
            <OverviewTabs
              currentTab={currentTab}
              onTabSwitch={this.handleTabSwitch}
              messages={intl.messages.components.pages.private.project.overview.tabs}
              analyticsEnabled={true}
              contextEnabled={!isQuestionnaire}
            />
          </div>
          <div className="project-overview-tab-content bg-primary-light clearfix">
            {currentTab === ETab.ACTIVITY.key && (
              <div className="mui-padded-half">
                <InfoCards project={project} participants={participants} />
                <div style={{ padding: '0 4px 8px' }}>
                  <ParticipantActivityStatusChart
                    participants={participants}
                    dateRange={projectOverview.custom && projectOverview.custom.activityStatusChartDateRange}
                    onDateRangeChange={this.handleSetActivityStatusChartDateRange}
                  />
                </div>
              </div>
            )}
            {currentTab === ETab.LIST.key && <ApplicantList {...commonProps} gridOptions={gridOptions} />}
            {currentTab === ETab.ANALYTICS.key &&
              (isWellBeing ? (
                <ProjectOverviewEchoGrid
                  metricDefinitions={metricDefinitions}
                  participants={participants}
                  onItemClick={p => this.handleParticipantClick(p)}
                />
              ) : isQuestionnaire ? (
                <QuestionnaireAnalytics
                  project={project}
                  crossComparison={crossComparison}
                  onUpdateCrossComparisonParticipants={this.onUpdateCrossComparisonParticipants}
                  onSetCrossComparisonParticipantActive={this.onSetCrossComparisonParticipantActive}
                  onToggleCrossComparisonManagerial={this.handleToggleCrossComparisonManagerial}
                />
              ) : (
                <ProjectAnalytics {...commonProps} loadData={this.loadData} />
              ))}
            {currentTab === ETab.CONTEXT.key && <ProjectContext {...commonProps} loadData={this.loadData} />}
          </div>
        </div>
      </BaseContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    auth,
    intl,
    options: { loader, projectOverview },
    entities: { projects }
  } = state;
  const { items, metricDefinitions } = selectParticipants(state, props);
  const project = getCurrentProject(state, props);
  const po = (project && projectOverview[project.id]) || {};
  return {
    auth,
    intl,
    metricDefinitions,
    crossComparison: getCrossComparison(state, props),
    projects,
    loader,
    scoreMetric: selectScoreMetric(state, props),
    metricsConfig: selectMetricsConfig(state, props),
    scoreMappingMetrics: selectScoreMappingMetrics(state, props),
    numericalMetrics: selectNumericalMetrics(state, props),
    project,
    projectOverview: po,
    company: getCurrentCompany(state, props),
    gridOptions: getGridOptions(state, props),
    participants: items
  };
};

const actions = {
  ...allActions,
  openParticipantFilter,
  setProjectOverviewTab,
  setProjectOverviewCustomProp,
  getProjectUserCompanyTags
};

export default withRouter(connect(mapStateToProps, actions)(ProjectOverview));
