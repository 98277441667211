import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change as changeForm } from 'redux-form';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';

import { ID as BasicPartID } from './BasicPart';
import CultureFitCommonPart, { validate as cultureFitValidate } from '../../../forms/parts/CultureFitCommonPart';

import { CULTURE_FIT_DIMENSIONS } from '../../../../constants/enum';
import { formatMessage } from '../../../../utils/utils';
import { getProgress } from '../Utils';
import { cultureFitToFormValue } from '../../../forms/FormUtils';
import { selectOpportunityPostToEdit } from '../../../../selectors/opportunityBuilder';

export const ID = 'opportunityCulturePartForm';

const messages = {
  setFromProfileMessage: 'Jestli je týmová kultura stejná jako ve vaší firmě, můžete ji jednoduše {0[nastavit]}'
};

const getCompanyProfileProperty = (profile, property) =>
  profile && (_get(profile, property) || _get(profile.draft || {}, property));

const CulturePart = props => {
  const { companyProfile, handleSubmit, changeForm } = props;
  const companyCulture = getCompanyProfileProperty(companyProfile, 'culture');
  const setCultureFromCompanyProfile = () => {
    for (let i = 0; i < CULTURE_FIT_DIMENSIONS.length; i += 1) {
      const dim = CULTURE_FIT_DIMENSIONS[i];
      changeForm(ID, dim.formName, cultureFitToFormValue({ culture: companyCulture }, dim.key));
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      {companyCulture && (
        <div className="row">
          <div className="col-sm-12">
            <div className="mui-fields-container">
              {formatMessage(messages.setFromProfileMessage, [
                val => (
                  <a key="0" role="button" onClick={setCultureFromCompanyProfile}>
                    {val}
                  </a>
                )
              ])}
            </div>
          </div>
        </div>
      )}
      <CultureFitCommonPart />
    </form>
  );
};

const validate = (values, props) => {
  return cultureFitValidate(values, props);
};

const basicPartSelector = formValueSelector(BasicPartID);

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    entities: {
      companies,
      companyProfile: { profile }
    }
  } = state;
  const companyId = basicPartSelector(state, 'companyId');
  const company = companyId ? _find(companies, c => c.id === companyId) : null;
  const post = selectOpportunityPostToEdit(state, ownProps);
  const opportunity = _get(post, 'opportunity') || {};
  const dataSources = {};
  const { matchConfig } = opportunity;
  const initialValues = _reduce(
    CULTURE_FIT_DIMENSIONS,
    (res, d) => {
      res[d.formName] = cultureFitToFormValue(matchConfig, d.key);
      return res;
    },
    {}
  );
  return {
    intl,
    company,
    dataSources,
    initialValues,
    companyProfile: profile
  };
};

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

CulturePart.ID = ID;
CulturePart.getProgressFromPost = post => {
  const matchConfig = post?.opportunity?.matchConfig;
  return getProgress([
    () => {
      for (let i = 0; i < CULTURE_FIT_DIMENSIONS.length; i += 1) {
        if (cultureFitToFormValue(matchConfig, CULTURE_FIT_DIMENSIONS[i].key) != null) {
          return true;
        }
      }
      return false;
    }
  ]);
};
CulturePart.getProgressFromValues = v =>
  getProgress([
    () => {
      for (let prop in v) {
        if (v.hasOwnProperty(prop)) {
          if (v[prop] != null) {
            return true;
          }
        }
      }
      return false;
    }
  ]);

export default connect(mapStateToProps, { changeForm })(reduxForm(formConfig)(CulturePart));
