import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import AccountActivationPage from '../../components/pages/public/AccountActivation';
import * as Api from '../../api/entities/accountApi';

import allActions from './../../actions';

class AccountActivation extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    //
    updateLocale: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired
  };

  activateAccount = () => {
    const {
      location: { query },
      router,
      showSnackbar
    } = this.props;
    const { email } = query;
    const snackbarId = `snackbarAccountActivation_${email}`;
    Api.activateAccount(query)
      .then(({ payload: { access_token } }) => {
        showSnackbar({
          id: snackbarId,
          messageKey: 'components.pages.public.accountActivation.successMessage'
        });
        router.push({ pathname: '/login', query: { email, access_token } });
      })
      .catch(e => {
        console.error(e);
        showSnackbar({
          id: snackbarId,
          messageKey: 'components.pages.public.accountActivation.errorMessage'
        });
        router.push({ pathname: '/login', query: { email } });
      });
  };

  render() {
    const { intl, location } = this.props;
    const { updateLocale } = this.props;
    const pageProps = {
      intl,
      email: location.query.email,
      updateLocale,
      activateAccount: this.activateAccount
    };
    return <AccountActivationPage {...pageProps} />;
  }
}

const mapStateToProps = ({ intl }) => ({ intl });

export default withRouter(connect(mapStateToProps, allActions)(AccountActivation));
