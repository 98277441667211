import React from 'react';

import SuperMultiText from '../SuperMultiText';

const SuperMultiTextField = props => {
  const { input: { value, ...input }, meta: { touched, error, active }, indicateRequired, label, ...other } = props;
  const fieldProps = {
    error: touched && error,
    focused: active,
    values: value,
    label: indicateRequired ? <span>{label} *</span> : label,
    ...input,
    ...other
  };
  return <SuperMultiText {...fieldProps} />;
};

export default SuperMultiTextField;
