import React from 'react';
import PropTypes from 'prop-types';
import { forEach, values } from 'lodash';

import FlatButton from 'material-ui/FlatButton';

import languages from '../../constants/languages';

const LanguageSwitcher = props => {
  const { intl, updateLocale } = props;

  const lngBtnLabelStyle = {
    textTransform: 'none',
    padding: '0px 8px'
  };

  const components = [];
  forEach(values(languages), ({ value }, idx) => {
    if (idx > 0) {
      components.push(
        <div
          key={`divider-${idx}`}
          style={{ height: '36px', width: '2px', display: 'inline-block', padding: '10px 0', verticalAlign: 'top' }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(33, 33, 33, 0.54)'
            }}
          />
        </div>
      );
    }
    components.push(
      <FlatButton
        key={`switch-${value}`}
        hoverColor={'white'}
        onClick={() => updateLocale(value)}
        label={value}
        style={{
          color: `rgba(33, 33, 33, ${intl.locale === value ? 0.87 : 0.54})`,
          verticalAlign: 'top',
          minWidth: 30
        }}
        labelStyle={lngBtnLabelStyle}
      />
    );
  });

  return <div>{components}</div>;
};

LanguageSwitcher.propTypes = {
  intl: PropTypes.object,
  updateLocale: PropTypes.func
};

export default LanguageSwitcher;
