import React from 'react';
import moment from 'moment';
import _reduce from 'lodash/reduce';

import HorizontalScrollPane from '../basic/HorizontalScrollPane';
import { EActivityStatus, EProjectState } from '../../constants/enum';

import { extractNotificationPreferences } from '../../utils/utils';

import ProjectSummaryInfoCard from './infoCards/ProjectSummaryInfoCard';
import ProjectStatusInfoCard from './infoCards/ProjectStatusInfoCard';
import ProjectInvitationInfoCard from './infoCards/ProjectInvitationInfoCard';
import LatestProfileInfoCard from './infoCards/LatestProfileInfoCard';
import ReminderCandidatesInfoCard from './infoCards/ReminderCandidatesInfoCard';

const getLatestUnseenProfileProps = participants => {
  return _reduce(
    participants,
    (res, p) => {
      if (p.status === EActivityStatus.FINISHED) {
        res.finished.push(p);
        if (!p.ack || p.ack.status !== p.status) {
          if (res.profile === null || res.profile.statusTimestamp < p.statusTimestamp) {
            res.profile = p;
          }
        }
      }
      return res;
    },
    { profile: null, finished: [] }
  );
};

const REMINDER_CANDIDATES_THRESHOLDS = [
  { max: { value: 1, unit: 'month' }, min: { value: 2, unit: 'week' } },
  { min: { value: 1, unit: 'week' } },
  { min: { value: 4, unit: 'day' } }
];

const SUFFICIENT_REMINDER_CANDIDATES_COUNT = 5;

const determineLastReminderLikeTimestamp = p => {
  const np = extractNotificationPreferences(p);
  if (!np.canNotify) {
    return null;
  }
  const { entryStatus, simulation } = p;
  const lastReminderTs = np.timestamp || 0;
  const entryStatusTs = (entryStatus || {}).timestamp || 0;
  const { startedAt, createdAt } = simulation;
  const tsCandidates = [lastReminderTs, entryStatusTs, startedAt, createdAt];
  return _reduce(
    tsCandidates,
    (res, t) => {
      if (t && (!res || res < t)) {
        return t;
      }
      return res;
    },
    null
  );
};

const getReminderCandidates = participants => {
  const now = moment(Date.now());
  const thresholds = _reduce(
    REMINDER_CANDIDATES_THRESHOLDS,
    (res, t) => {
      res.push({
        config: t,
        min: t.min && moment(now).add(-t.min.value, t.min.unit),
        max: t.max && moment(now).add(-t.max.value, t.max.unit)
      });
      return res;
    },
    []
  );
  const candidates = _reduce(
    participants,
    (res, p) => {
      if (p.status !== EActivityStatus.FINISHED && !p.participant.anonymous) {
        const ts = determineLastReminderLikeTimestamp(p);
        if (ts) {
          const mts = moment(ts);
          for (let i = 0; i < thresholds.length; i += 1) {
            const t = thresholds[i];
            if (t.max && ts < t.max) {
              // too old, do not notify
              return res;
            }
            if ((!t.max || !mts.isBefore(t.max)) && (!t.min || !mts.isAfter(t.min))) {
              if (!res[i]) {
                res[i] = { ...t, participants: [] };
              }
              res[i].participants.push(p);
            }
          }
        }
      }
      return res;
    },
    []
  );
  const lastC = candidates[candidates.length - 1];
  for (let i = 0; i < candidates.length; i += 1) {
    const c = candidates[i];
    if (c) {
      if (c.participants.length > SUFFICIENT_REMINDER_CANDIDATES_COUNT) {
        return c;
      }
      if (c.participants.length > 0 && lastC && lastC.participants.length === c.participants.length) {
        return c;
      }
    }
  }
  return null;
};

const InfoCards = props => {
  const { project, participants } = props;

  const lupProps = getLatestUnseenProfileProps(participants);
  const reminderCandidates = getReminderCandidates(participants);

  const projectState = project && project.validity.state;
  const isActive = projectState === EProjectState.ACTIVE.key;

  return (
    <div>
      <HorizontalScrollPane stretch>
        <div style={{ padding: '8px' }}>
          <div className="container-flex-row ai-start">
            {participants.length === 0 && isActive && !project.view ? (
              <ProjectInvitationInfoCard project={project} />
            ) : (
              <ProjectSummaryInfoCard project={project} />
            )}
            <ProjectStatusInfoCard project={project} />
            {lupProps.profile && (
              <LatestProfileInfoCard
                profile={lupProps.profile}
                profiles={lupProps.finished}
                project={project}
              />
            )}
            {isActive &&
              !project.view &&
              reminderCandidates &&
              reminderCandidates.participants.length > 0 && (
                <ReminderCandidatesInfoCard candidates={reminderCandidates} />
              )}
          </div>
        </div>
      </HorizontalScrollPane>
    </div>
  );
};

export default InfoCards;
