import React from 'react';
import take from 'lodash/take';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

import SuperText from './SuperText';

import { KC, determineKeyCode } from './Utils';

const SEPARATORS = {
  COMMA: {
    keyCode: KC.COMMA,
    separator: /\s*,\s*/
  },
  SPACE: {
    keyCode: KC.SPACE,
    separator: /\s+/
  }
};

class SuperMultiText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      value: '',
      focused: false
    };
  }

  componentDidMount() {
    this.updateFromProps({}, this.props);
  }

  componentDidUpdate(prevProps) {
    this.updateFromProps(prevProps, this.props);
  }

  getSeparator = () => {
    const { spaceSeparated, splitter } = this.props;
    if (splitter) {
      return null;
    }
    if (spaceSeparated) {
      return SEPARATORS.SPACE;
    }
    return SEPARATORS.COMMA;
  };

  getSplitter = () => {
    const { splitter } = this.props;
    if (splitter) {
      return splitter;
    }
    const { separator } = this.getSeparator();
    return val => {
      const parts = (val || '').split(separator);
      return {
        values: parts,
        splitCount: parts.length,
        invalidCount: 0
      };
    };
  };

  split = value => this.getSplitter()(value);

  updateFromProps = (props, nextProps) => {
    if (props.values !== nextProps.values) {
      this.setState({ values: nextProps.values });
    }
  };

  setValue = (value, multi) => {
    const { formatter } = this.props;
    const { values } = this.state;
    const split = this.split(value);
    if (multi && split.splitCount <= 1) {
      this.setState({ value, error: false });
      return { values, changed: false };
    }
    if (split.values.length <= 0) {
      const error = split.invalidCount > 0;
      this.setState({ value, error });
      return { values, changed: false };
    }
    let addedValues = [];
    for (let i = 0; i < split.values.length; i += 1) {
      const part = split.values[i].trim();
      const newValue = formatter ? formatter(part) : part;
      if (newValue && !includes(values, newValue) && !includes(addedValues, newValue)) {
        addedValues.push(newValue);
      }
    }
    const newValues = addedValues.length > 0 ? [...values, ...addedValues] : values;
    this.setState({ value: '', values: newValues, error: false });
    return { values: newValues, changed: addedValues.length > 0 };
  };

  handleValueChange = e => {
    const { values, changed } = this.setValue(e.target.value, true);
    if (changed && this.props.onChange) {
      this.props.onChange(values);
    }
  };

  handleKeyDown = e => {
    const { value, values } = this.state;
    const len = values.length;
    const keyCode = determineKeyCode(e);
    const separator = this.getSeparator();
    let newValues = values;
    if (keyCode === KC.BACKSPACE && !value && len > 0) {
      const newValue = values[len - 1];
      newValues = take(values, len - 1);
      this.setState({
        value: newValue,
        values: newValues,
        error: false
      });
      e.preventDefault();
    } else if (keyCode === KC.ENTER || keyCode === KC.TAB || (separator && keyCode === separator.keyCode)) {
      const res = this.setValue(value);
      if (res.changed || (separator && keyCode === separator.keyCode)) {
        e.preventDefault();
      }
      newValues = res.values;
    }
    if (newValues !== values && this.props.onChange) {
      this.props.onChange(newValues);
    }
  };

  handleFocus = e => {
    this.setState({ focused: true });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  handleBlur = () => {
    const { values } = this.setValue(this.state.value);
    if (this.props.onBlur) {
      this.props.onBlur(values);
    }
    this.setState({ focused: false });
  };

  handleDeleteValue = ({ value }) => {
    const newValues = filter(this.state.values, v => v !== value);
    this.setState({ values: newValues });
    if (this.props.onChange) {
      this.props.onChange(newValues);
    }
  };

  render() {
    const { label, helperText, error, disabled, variant } = this.props;
    const { values, value, focused } = this.state;
    return (
      <SuperText
        label={label}
        helperText={helperText}
        error={error || (this.state.error && this.props.errorMessage)}
        values={map(values, v => ({ value: v, label: v }))}
        variant={variant}
        value={value}
        focused={focused}
        onChange={this.handleValueChange}
        onKeyDown={this.handleKeyDown}
        onRequestDeleteValue={this.handleDeleteValue}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        multi
        filter
        disabled={disabled}
      />
    );
  }
}

export default SuperMultiText;
