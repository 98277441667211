import React from 'react';

import MultiColorDonut from '../../chart/MultiColorDonut';

const HIGHLIGHT_BORDER = 3;

const MultiColorCell = props => {
  const { x, y, fill, width, payload, maxSize } = props;
  if (!width) {
    return null;
  }
  const baseColor = payload?.color || fill;
  const colors = payload?.colors || [];
  const highlightSize = maxSize - 2 * HIGHLIGHT_BORDER;
  const highlightOffset = (width - highlightSize) / 2;
  const regularSize = colors.length > 0
    ? Math.min(highlightSize - 2 * (HIGHLIGHT_BORDER + 1), width)
    : width;
  // const regularSize = colors.length > 0 ? highlightSize - 2 * (HIGHLIGHT_BORDER + 1) : width;
  const regularOffset = (width - regularSize) / 2;
  return (
    <g>
      <MultiColorDonut
        x={x + regularOffset}
        y={y + regularOffset}
        colors={[baseColor]}
        size={regularSize}
        wihinSvg
        options={{ borderWidth: regularSize / 2 }}
      />
      {colors.length > 0 && (
        <MultiColorDonut
          x={x + highlightOffset}
          y={y + highlightOffset}
          colors={colors}
          size={highlightSize}
          wihinSvg
          options={{ borderWidth: HIGHLIGHT_BORDER }}
        />
      )}
    </g>
  );
};

export default MultiColorCell;
