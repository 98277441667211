import _reduce from 'lodash/reduce';

import { EActivityType } from '../../constants/enum';

// prettier-ignore
const GENDERS = ['MALE', 'FEMALE'];
// prettier-ignore
const NAMES = {
  MALE: [
    "Adam", "Albert", "Alec", "Andrew", "Barry", "Ben", "Bob", "Carry", "Charlie", "Chester", "Chuck", "Clark",
    "George", "Greg", "Guy", "Harry", "Huck", "Hugh", "Jack", "Jake", "James", "Jason", "Joe", "John", "Leo",
    "Lester", "Luke", "Marty", "Matt", "Owen", "Paul", "Peter", "Randy", "Ray", "Ron", "Rupert", "Steve", "William"
  ],
  FEMALE: [
    "Carry", "Charlie", "Christina", "Claire", "Edith", "Elisa", "Emma", "Eve", "Grace", "Halle", "Holly", "Jennifer",
    "Laurie", "Lisa", "Martha", "Mary", "Olivia", "Penny", "Sandy", "Susan", "Wanda", "Wendy"
  ]
};
// prettier-ignore
const SURNAMES = [
  "Abraham", "Andrews", "Bale", "Bachman", "Berry", "Bond", "Bourne", "Brien", "Calhoun", "Cline", "Dambrough",
  "Dartford", "Dennison", "Edison", "Ewing", "Fitzgerald", "Ford", "Gerard", "Gibbs", "Gibson", "Gilbert",
  "Gillies", "Griffin", "Hammond", "Hanks", "Hendricks", "Herbert", "Irvine", "Jeffries", "Johnson", "Jones",
  "Kelly", "Kutcher", "Lampard", "Lawson", "Livingston", "Lucas", "Marsh", "May", "Moore", "Nayward", "Newton",
  "Otis", "Patrick", "Peterson", "Quaid", "Quaile", "Roberts", "Rush", "Simpson", "Smith", "Sneider", "Stark",
  "Terry", "Travolta", "Underwood", "Vaughn", "Veiling", "Young", "Yohnson", "Zander"
];

const LANGUAGES = ['cs', 'sk', 'en'];

const COMPANY = { id: '88383d5a-bf37-43ad-9bbc-fdff9fbda47a', slug: 'company', name: 'Company' };

const ACTIVITIES = {
  [EActivityType.GAME_CHANGER.value]: {
    name: 'The Game Changer',
    sample: true,
    project: {
      id: '24625aa1-7733-401f-8c86-daf987f2221f',
      slug: 'management-assessment',
      name: 'Management Assessment',
      type: 'GROUP_ASSESSMENT',
      company: COMPANY
    }
  },
  [EActivityType.OFFICE_DAY.value]: {
    name: 'The Office Day',
    sample: true,
    project: {
      id: '85083909-f1fb-4020-8b2a-0b33b9ad73d1',
      slug: 'team-assessment',
      name: 'Team Assessment',
      type: 'GROUP_ASSESSMENT',
      company: COMPANY
    }
  },
  [EActivityType.CULTURE_FIT.value]: {
    name: 'Culture Fit',
    sample: true,
    project: {
      id: '6cf0ad83-bfc1-4f27-9403-5c5221d3d9f2',
      slug: '8d750544-e202-49b8-9d34-463af208520f',
      name: 'Culture Fit',
      type: 'QUESTIONNAIRE',
      company: COMPANY
    }
  }
};

const generateMetricValue = ({ valueType, baseColor }, rng) => {
  let value;
  let formattedValue;
  switch (valueType) {
    case 'STEN': {
      const baseVal = Math.floor(rng.nextFloat() * 10) + 1;
      value = baseVal / 10;
      formattedValue = `${baseVal}`;
      break;
    }
    case 'STEN_WEIGHED': {
      const baseVal = rng.nextFloat() * 10 + 1;
      value = baseVal / 10;
      formattedValue = `${baseVal.toFixed(1)}`;
      break;
    }
    case 'SEMI_STEN': {
      const baseVal = Math.floor(rng.nextFloat() * 5) + 1;
      value = baseVal / 5;
      formattedValue = `${baseVal}`;
      break;
    }
    case 'DISCRETE_FOUR': {
      const baseVal = Math.floor(rng.nextFloat() * 4);
      value = baseVal / 3;
      formattedValue = `${baseVal}`;
      break;
    }
    case 'PERCENTAGE': {
      value = rng.nextFloat();
      formattedValue = `${(value * 100).toFixed(0)}%`;
      break;
    }
    default: {
      value = rng.nextFloat();
      formattedValue = value.toFixed(2);
      break;
    }
  }
  let rank;
  if (value < 0.2) {
    rank = 'LOW';
  } else if (value < 0.4) {
    rank = 'MID_LOW';
  } else if (value < 0.6) {
    rank = 'MID';
  } else if (value < 0.8) {
    rank = 'MID_HIGH';
  } else {
    rank = 'HIGH';
  }
  return {
    valueType,
    color: baseColor,
    rank,
    value,
    formattedValue
  };
};

const generateMetricValues = (metricDefinitions, rng) => {
  return _reduce(
    metricDefinitions,
    (res, md, key) => {
      const { displayOrder, parent, type } = md;
      let valueType = 'PLAIN';
      let baseColor = '#135625';
      if (displayOrder != null) {
        valueType = 'STEN';
        if (type === 'NUMERIC_DUAL') {
          baseColor = '#521346';
          valueType = 'DISCRETE_FOUR';
        } else if (parent) {
          baseColor = '#521346';
        }
      }
      res[key] = generateMetricValue({ valueType, baseColor }, rng);
      return res;
    },
    {}
  );
};

const nextInteger = (rng, min, max) => {
  return Math.floor(min + rng.nextFloat() * (max - min));
};

export const generateParticipant = rng => {
  const gender = rng.item(GENDERS);
  const firstName = rng.item(NAMES[gender]);
  const lastName = rng.item(SURNAMES);
  const email = `${firstName.toLowerCase()}.${lastName.toLowerCase()}@bhvra.com`;
  const id = rng.uuid();
  return {
    id,
    email,
    firstName,
    lastName,
    sample: true
  };
};

export const generateActivity = ({ key, variant }, metricDefinitions, rng) => {
  const baseActivity = ACTIVITIES[key] || {};
  const createdAt = new Date(2018 + nextInteger(rng, 0, 3), nextInteger(rng, 0, 12), nextInteger(rng, 1, 29));
  // finished X hours later
  const finishedAt = new Date(createdAt.getTime() + nextInteger(rng, 2, 10) * 60 * 60 * 1000);
  return {
    ...baseActivity,
    sample: true,
    result: {
      sample: true,
      metrics: generateMetricValues(metricDefinitions, rng)
    },
    key,
    variant,
    language: rng.item(LANGUAGES),
    id: rng.uuid(),
    identifier: rng.uuid(),
    createdAt,
    finishedAt
  };
};
