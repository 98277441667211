import React from 'react';
import cn from 'classnames';

import Button from 'material-ui/internal/EnhancedButton';

const ScrollButton = props => {
  const { onClick, disabled, icon } = props;
  return (
    <Button
      className="horizontal-scroll-pane__button"
      onClick={onClick}
      disabled={disabled}
      style={{ padding: 0, width: '24px', height: '100%', opacity: disabled ? 0.38 : 1 }}
    >
      <i className="material-icons">{icon}</i>
    </Button>
  );
};

class HorizontalScrollPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollLeft: 0
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.measureElements);
  }

  componentDidUpdate() {
    this.measureElements();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    window.removeEventListener('resize', this.measureElements);
  }

  measureElements = () => {
    if (!this.timeout && this.menuElement) {
      this.timeout = setTimeout(() => {
        const newState = {
          height: this.menuElement.clientHeight,
          width: this.menuElement.clientWidth,
          totalWidth: this.menuElement.scrollWidth,
          scrollLeft: this.menuElement.scrollLeft
        };
        if (
          newState.height !== this.state.height ||
          newState.width !== this.state.width ||
          newState.totalWidth !== this.state.totalWidth ||
          newState.scrollLeft !== this.state.scrollLeft
        ) {
          this.setState(newState);
        }
        this.timeout = null;
      }, 300);
    }
  };

  handleSetMenu = el => {
    this.menuElement = el;
    this.measureElements();
  };

  scrollIncrement = x => {
    if (this.menuElement) {
      this.menuElement.scrollLeft += x;
      this.measureElements();
    }
  };

  handleScrollLeft = () => {
    this.scrollIncrement(-this.state.width);
  };

  handleScrollRight = () => {
    this.scrollIncrement(this.state.width);
  };

  render() {
    const { className, style, children, stretch } = this.props;
    const { height, width, totalWidth, scrollLeft } = this.state;
    const showButtons = width < totalWidth;
    const cName = cn({ 'container-flex-row': true, 'ai-center': true, [className]: className });
    const contentStyle = { overflow: 'hidden', maxHeight: height };
    if (stretch) {
      contentStyle.width = '100%';
    }
    return (
      <div className={cName} style={{ overflow: 'hidden', ...style }}>
        {showButtons && (
          <ScrollButton onClick={this.handleScrollLeft} disabled={scrollLeft <= 0} icon="keyboard_arrow_left" />
        )}
        <div style={contentStyle}>
          <div
            ref={this.handleSetMenu}
            onScroll={this.measureElements}
            style={{ overflow: 'auto hidden', scrollBehavior: 'smooth' }}
          >
            {children}
          </div>
        </div>
        {showButtons && (
          <ScrollButton
            onClick={this.handleScrollRight}
            disabled={width + scrollLeft >= totalWidth}
            icon="keyboard_arrow_right"
          />
        )}
      </div>
    );
  }
}

export default HorizontalScrollPane;
