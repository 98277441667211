export const update = (state, id, data) => ({ ...state, [id]: { ...state[id], ...data } });

export const updateFromValues = (state, id, filterKey, values) => {
  const filter = state[id] || {};
  const fromValues = {
    ...filter.fromValues
  };
  if (values) {
    fromValues[filterKey] = values;
  } else {
    delete fromValues[filterKey];
  }
  return {
    ...state,
    [id]: {
      ...filter,
      fromValues
    }
  };
};
