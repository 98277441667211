import React from 'react';

import CompanySelect from './company/CompanySelect';
import Opportunities from './opportunity/Opportunities';

const OpportunitiesContainer = () => {
  return (
    <div>
      <CompanySelect includeDemo={false} />
      <Opportunities />
    </div>
  );
};

export default OpportunitiesContainer;
