import React from 'react';
import { connect } from 'react-redux';

import actions from '../../actions';
import { MODAL_DEMO_PLAY } from '../../constants/constants';
import { isSimulationResolutionValid } from '../../utils/utils';
import Guide from '../../components/guide/Guide';
import Slide from '../../components/guide/slides/Slide';

const Media = ({ src }) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      backgroundImage: `url(${src})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }}
  />
);

const GuideContainer = props => {
  const { guide, ui, intl } = props;
  const { shown, first, last } = guide;
  const { nextGuideStep, prevGuideStep, finishGuide, hideGuide, switchModal } = props;
  const messages = intl.messages.components.guide && intl.messages.components.guide.initialV2;
  // do not show for untranslated
  if (!shown || !messages) {
    return null;
  }
  return (
    <Guide
      guide={guide}
      closeable
      onHide={hideGuide}
      onNext={last ? finishGuide : nextGuideStep}
      onPrev={first ? null : prevGuideStep}
    >
      <Slide {...messages.slide1} media={<Media src="/img/misc/guides/v2/slide1.png" />} />
      <Slide
        {...messages.slide2}
        buttonLabel={isSimulationResolutionValid(ui) ? messages.slide2.buttonLabel : null}
        onButtonClick={() => switchModal({ id: MODAL_DEMO_PLAY })}
        media={<Media src="/img/misc/guides/v2/slide2.png" />}
      />
      <Slide {...messages.slide3} media={<Media src="/img/misc/guides/v2/slide3.png" />} />
      <Slide {...messages.slide4} media={<Media src="/img/misc/guides/v2/slide4.png" />} />
      <Slide {...messages.slide5} media={<Media src="/img/misc/guides/v2/slide5.png" />} />
      <div />
    </Guide>
  );
};

const mapStateToProps = state => ({ guide: state.options.guide, intl: state.intl, ui: state.ui });

export default connect(mapStateToProps, actions)(GuideContainer);
