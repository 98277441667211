import { handleActions } from 'redux-actions';

import * as C from '../../constants/constants';

const defaultState = {};

export default handleActions(
  {
    [C.SKILL_SCATTER_CHART_SET_COMPETENCY_VALUE]: (state, { payload: { projectId, competency, value } }) => ({
      [projectId]: {
        ...state[projectId],
        competencies: { ...(state[projectId] || {}).competencies, [competency]: value }
      }
    })
  },
  defaultState
);
