import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../mui/IconButton';

const STYLES = {
  base: {
    display: 'inline-block',
    width: '24px',
    height: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    borderRadius: '2px',
    margin: '2px',
    padding: 0
  },
  icon: { fontSize: '20px', lineHeight: '24px' }
};

const IconToggle = props => {
  const { icon, inverted, active, onToggle, tooltip } = props;
  const className = active ? (inverted ? 'bg-primary-light' : 'bg-white') : undefined;
  return (
    <IconButton className={className} style={STYLES.base} onClick={onToggle} tooltip={tooltip} tooltipPosition="left">
      {typeof icon === 'string' ? (
        <i className={'material-icons text-primary'} style={STYLES.icon}>
          {icon}
        </i>
      ) : (
        icon
      )}
    </IconButton>
  );
};

IconToggle.propTypes = {
  icon: PropTypes.any.isRequired,
  active: PropTypes.bool,
  onToggle: PropTypes.func.isRequired
};

export default IconToggle;
