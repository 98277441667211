import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import Toggle from 'material-ui/Toggle';

import Button from '../../mui/Button';
import TextField from '../../superfield/SuperText';

import { formatMessage } from '../../../utils/utils';

const UrlsContent = props => {
  const { link, code, anonymousSwitchShown, anonymous, messages, snackbarMessage } = props;
  const { toggleSendFromSystem, toggleAnonymous, showSnackbar } = props;
  return (
    <div>
      <div>
        {formatMessage(toggleSendFromSystem ? messages.sendLink : messages.sendLinkManual, (idx, val) => (
          <a key={idx} role="button" onClick={toggleSendFromSystem}>
            {val}
          </a>
        ))}
      </div>
      {anonymousSwitchShown && (
        <div>
          <br />
          <Toggle
            label={messages.anonymous}
            toggled={anonymous}
            onToggle={toggleAnonymous}
            labelStyle={{ marginRight: '8px' }}
            style={{ width: '88px' }}
          />
          <small>{messages.anonymousHelper}</small>
        </div>
      )}
      <TextField key={link} value={link} readOnly />
      <CopyToClipboard text={link}>
        <Button
          raised
          onClick={() => showSnackbar({ id: 'INVITATION', message: snackbarMessage })}
          label={messages.copyToClipboard}
        />
      </CopyToClipboard>
      {code && (
        <div>
          <br />
          <p>{messages.codeDescription}</p>
          <pre style={{ fontSize: '11px' }}>
            &lt;script&gt;{'\n'}
            {code.script}
            {'\n'}
            {code.config}
            {'\n'}
            &lt;/script&gt;
          </pre>
        </div>
      )}
    </div>
  );
};

export default UrlsContent;
