import { authRequest } from '../Request';
import { API_URL } from '../../config';

export function getResults(companySlug, projectSlug, lang, query) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v2/companies/${companySlug}/projects/${projectSlug}/results`)
      .query({
        lang,
        ack: true,
        activities: 'CULTURE_FIT',
        page: 0,
        size: 9999,
        sort: 'result.metrics.scoreTotal,DESC',
        interpretations: query && query.interpretations
      })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getResult(companySlug, projectSlug, participantId, lang, loadExtraActivities) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v2/companies/${companySlug}/projects/${projectSlug}/results/${participantId}`)
      .query({ ack: true, activities: loadExtraActivities ? 'CULTURE_FIT' : null, lang })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getApplicantEvaluationDescription(gameId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/admin/evaluation/description`)
      .query({ gameId })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function setUserProjectsStatusAcks(acks) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/users/projects/acks`)
      .send({ acks })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function notifyProfiles(values) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/users/projects/notifications`)
      .send({ values })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
