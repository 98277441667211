import { handleActions } from 'redux-actions';

import { DEMO_SIMULATION_URL_GET } from '../../constants/constants';

const initialState = {};

export default handleActions({
  [DEMO_SIMULATION_URL_GET.PENDING]: (state) => ({ ...state, loading: true }),
  [DEMO_SIMULATION_URL_GET.SUCCESS]: (state, { payload: { payload: { url } } }) => ({ ...state, loading: false, url }),
  [DEMO_SIMULATION_URL_GET.ERROR]: (state) => ({ ...state, loading: false, error: true })
}, initialState);
