import { authRequest } from '../Request.js';
import { API_URL } from '../../config';

export function getTags(companyId, search, page) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/companies/${companyId}/tags`)
      .query({ ...page, search, sort: 'createdAt' })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function saveTag(companyId, tag) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/companies/${companyId}/tags${tag.id ? `/${tag.id}` : ''}`)
      .send(tag)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function deleteTag(companyId, tag) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .delete(`${API_URL}/api/v1/companies/${companyId}/tags/${tag.id}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

//
// user company tags
//

export function getUsersCompanyTags(companyId, params) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/companies/${companyId}/users/tags`)
      .query(params)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getUserCompanyTags(companyId, userId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/companies/${companyId}/users/${userId}/tags`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function deleteUserCompanyTag(companyId, id) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .delete(`${API_URL}/api/v1/companies/${companyId}/users/tags/${id}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function assignUserCompanyTags(companyId, payload) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/companies/${companyId}/users/tags`)
      .send(payload)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

