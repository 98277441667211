import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';

import { IconMenu, MenuItem, IconButton } from 'material-ui';

import CompanySelect from '../../../containers/private/company/CompanySelect';
import BaseContainer from '../../basic/BaseContainer';
import Button from '../../mui/Button';
import Dialog from '../../mui/Dialog';
import { Grid, GridBody, GridCell, GridFooter, GridHeader, GridLabel, GridRow } from '../../grid';
import CopyToClipboardInput from '../../basic/CopyToClipboardInput';
import Spinner from '../../spinner/Spinner';

import { formatMessage } from '../../../utils/utils';

import * as actions from '../../../actions/entities/eapiKeyActions';

import withIntl from '../../withIntl';

class EapiKeysContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.loadKeys();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company) {
      this.loadKeys();
    }
  }

  loadKeys = () => {
    const { company, getEapiKeys } = this.props;
    if (company) {
      getEapiKeys(company.id);
    }
  };

  handleHide = () => this.props.hideEapiKey();

  handleDeleteStart = key => {
    this.setState({ keyToDelete: key });
  };
  handleDeleteCancel = () => this.setState({ keyToDelete: null });
  handleDeleteConfirm = () => {
    const { keyToDelete } = this.state;
    const { deleteEapiKey, company } = this.props;
    deleteEapiKey(company.id, keyToDelete.id);
    this.handleDeleteCancel();
  };
  handleCreateNewConfirm = () => {
    const { createEapiKey, company } = this.props;
    createEapiKey(company.id);
  };

  render() {
    const {
      company,
      loading,
      items,
      current,
      creating,
      intl: { messages }
    } = this.props;
    const { keyToDelete } = this.state;
    return (
      <div>
        <CompanySelect includeDemo={false} onSelect={this.handleSelect} required />
        {company && company.contractPlan?.eapiEnabled && (
          <BaseContainer>
            <h2>{messages.title}</h2>
            <Grid style={{ margin: '0 -8px' }}>
              <GridHeader>
                <GridRow>
                  <GridCell sm={12}>
                    <GridLabel>{messages.shortKeyLabel}</GridLabel>
                  </GridCell>
                  <GridCell xsHide sm={12}>
                    <GridLabel>{messages.shortSecretLabel}</GridLabel>
                  </GridCell>
                </GridRow>
              </GridHeader>
              <GridBody>
                {_map(items, eapiKey => (
                  <GridRow
                    key={eapiKey.id}
                    className="unselectable"
                    style={{ animation: 'vb-appear 0.2s ease-in-out', position: 'relative' }}
                  >
                    <GridCell sm={12}>
                      {current && current.id === eapiKey.id ? (
                        <CopyToClipboardInput label={messages.keyLabel} value={eapiKey.key} />
                      ) : (
                        <span>{eapiKey.key}</span>
                      )}
                    </GridCell>
                    <GridCell sm={12}>
                      {current && current.id === eapiKey.id ? (
                        <CopyToClipboardInput value={current.secret} label={messages.secretLabel} />
                      ) : (
                        <code>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</code>
                      )}
                    </GridCell>
                    {current && current.id === eapiKey.id ? (
                      <GridCell className="container-flex-row ai-center jc-space-between">
                        <div
                          className="text-muted"
                          style={{ maxWidth: '40em', whiteSpace: 'normal', paddingRight: '8px' }}
                        >
                          {messages.warningMessage}
                        </div>
                        <Button
                          label={messages.doneLabel}
                          icon={<i className="material-icons">check</i>}
                          onClick={this.handleHide}
                        />
                      </GridCell>
                    ) : (
                      <GridCell style={{ position: 'absolute', top: 0, right: 0, padding: 1 }}>
                        <IconMenu
                          iconButtonElement={
                            <IconButton style={{ width: 36, height: 36, padding: 0 }}>
                              <i className="material-icons">more_vert</i>
                            </IconButton>
                          }
                        >
                          <MenuItem
                            leftIcon={<i className="material-icons">delete</i>}
                            primaryText={messages.deleteLabel}
                            onClick={() => this.handleDeleteStart(eapiKey)}
                          />
                        </IconMenu>
                      </GridCell>
                    )}
                  </GridRow>
                ))}
                {creating && (
                  <GridRow style={{ animation: 'vb-appear 0.2s ease-in-out' }}>
                    <GridCell style={{ minHeight: '48px' }}>
                      <Spinner local show size={32} stroke={4} />
                    </GridCell>
                  </GridRow>
                )}
                {items.length === 0 && !creating && !loading && (
                  <GridRow className={'bg-primary-light unselectable'}>
                    <GridCell>
                      <div
                        className="mui-padded-x2 text-muted text-center"
                        style={{ maxWidth: '40em', margin: '0 auto', whiteSpace: 'normal' }}
                      >
                        {messages.emptyMessage}
                      </div>
                    </GridCell>
                  </GridRow>
                )}
              </GridBody>
              <GridFooter>
                <GridRow>
                  <GridCell className={'text-right'}>
                    <Button
                      icon={<i className="material-icons">add</i>}
                      label={messages.createNewLabel}
                      onClick={this.handleCreateNewConfirm}
                    />
                  </GridCell>
                </GridRow>
              </GridFooter>
            </Grid>
            <Dialog
              open={!!keyToDelete}
              contentStyle={{ maxWidth: '520px' }}
              actions={[
                <Button label={messages.cancelLabel} onClick={this.handleDeleteCancel} />,
                <Button
                  primary
                  label={messages.deleteLabel}
                  icon={<i className="material-icons">delete</i>}
                  onClick={this.handleDeleteConfirm}
                />
              ]}
            >
              <p>{formatMessage(messages.deleteMessage, [<b key="keyName">{keyToDelete && keyToDelete.key}</b>])}</p>
            </Dialog>
          </BaseContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    entities: {
      eapiKeys,
      currentCompany: { item }
    }
  } = state;
  return {
    ...eapiKeys,
    company: item
  };
};

const intlMessages = {
  cs: {
    title: 'EAPI klíče',
    emptyMessage: 'Zatím nemáte žádné EAPI klíče. Přidejte nový klepnutím na tlačítko "VYTVOŘIT NOVÝ" níže.',
    warningMessage:
      'Uchovávejte tajemství v bezpečí. Po potvrzení tlačítkem "HOTOVO" ho již nebude možné znovu získat.',
    deleteMessage: 'Odstranit klíč {0}?',
    deleteLabel: 'Odstranit',
    cancelLabel: 'Zrušit',
    createNewLabel: 'Vytvořit nový',
    doneLabel: 'Hotovo',
    keyLabel: 'EAPI klíč',
    shortKeyLabel: 'Klíč',
    secretLabel: 'EAPI tajemství',
    shortSecretLabel: 'Tajemství'
  },
  sk: {
    title: 'EAPI kľúče',
    emptyMessage: 'Zatiaľ nemáte žiadne EAPI kľúče. Pridajte nový kliknutím na tlačidlo "VYTVORIŤ NOVÝ" nižšie.',
    warningMessage: 'Uchovávajte tajomstvo v bezpečí. Po potvrdení tlačidlom "HOTOVO" ho už nebude možné získať znovu.',
    deleteMessage: 'Odstrániť kľúč {0}?',
    deleteLabel: 'Odstrániť',
    cancelLabel: 'Zrušiť',
    createNewLabel: 'Vytvoriť nový',
    doneLabel: 'Hotovo',
    keyLabel: 'EAPI kľúč',
    shortKeyLabel: 'Kľúč',
    secretLabel: 'EAPI tajomstvo',
    shortSecretLabel: 'Tajomstvo'
  },
  en: {
    title: 'EAPI Keys',
    emptyMessage: "There are no EAPI keys yet. Add a new one by clicking on the 'CREATE NEW' button down below.",
    warningMessage:
      'Make sure to keep the secret safe. After you confirm by clicking on "DONE" you won\'t be able to retrieve it again.',
    deleteMessage: 'Delete key {0}?',
    deleteLabel: 'Delete',
    cancelLabel: 'Cancel',
    createNewLabel: 'Create new',
    doneLabel: 'Done',
    keyLabel: 'EAPI Key',
    shortKeyLabel: 'Key',
    secretLabel: 'EAPI Secret',
    shortSecretLabel: 'Secret'
  }
};

export default connect(mapStateToProps, actions)(withIntl(EapiKeysContainer, intlMessages));
