import * as colors from 'material-ui/styles/colors';

const asyncActionType = type => ({
  SUCCESS: `${type}_SUCCESS`,
  PENDING: `${type}_LOADING`,
  ERROR: `${type}_ERROR`
});

// loginActions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';
export const SET_LANGUAGE_FAILURE = 'SET_LANGUAGE_FAILURE';

// entitiesActions
export const APPLICANT_REFRESH = 'APPLICANT_REFRESH';
export const APPLICANT_EVALUATION_DESCRIPTION_GET = 'APPLICANT_EVALUATION_DESCRIPTION_GET';
export const APPLICANT_PROJECT_ACK = 'APPLICANT_PROJECT_ACK';
export const APPLICANT_PROJECTS_ACKS = 'APPLICANT_PROJECTS_ACKS';
export const APPLICANT_PROJECT_ACK_SUCCESS = 'APPLICANT_PROJECT_ACK_SUCCESS';
export const APPLICANT_PROJECTS_ACKS_SUCCESS = 'APPLICANT_PROJECTS_ACKS_SUCCESS';
export const APPLICANT_PROJECT_ACK_ERROR = 'APPLICANT_PROJECT_ACK_ERROR';
export const APPLICANT_PROJECTS_ACKS_ERROR = 'APPLICANT_PROJECTS_ACKS_ERROR';
export const APPLICANT_SET_APPLICATION_STATUS = asyncActionType('APPLICANT_SET_APPLICATION_STATUS');
export const APPLICANT_NOTIFICATIONS = asyncActionType('APPLICANT_NOTIFICATIONS');
export const COMPANIES_REFRESH = 'COMPANIES_REFRESH';
export const COMPANY_OPERATORS_GET = 'COMPANY_OPERATORS_GET';
export const COMPANY_OPERATORS_UNSET = 'COMPANY_OPERATORS_UNSET';
export const COMPANIES_GET = 'COMPANIES_GET';
export const COMPANY_CURRENT_SET = 'COMPANY_CURRENT_SET';
export const COMPANY_PROFILE_GET = asyncActionType('COMPANY_PROFILE_GET');
export const COMPANY_PROFILE_SAVE = asyncActionType('COMPANY_PROFILE_SAVE');
export const COMPANY_PROFILE_UNSET = 'COMPANY_PROFILE_UNSET';
export const COMPANY_PROFILE_DRAFT_PUBLISH = asyncActionType('COMPANY_PROFILE_DRAFT_PUBLISH');
export const COMPANY_PROFILE_DRAFT_UNPUBLISH = asyncActionType('COMPANY_PROFILE_DRAFT_UNPUBLISH');
export const COMPANY_PROFILE_DRAFT_DISCARD = asyncActionType('COMPANY_PROFILE_DRAFT_DISCARD');
export const COMPANY_TALENT_MARKET_PLAN_CURRENT_GET = asyncActionType('COMPANY_TALENT_MARKET_PLAN_CURRENT_GET');
export const COMPANY_TALENT_MARKET_PLAN_CURRENT_SET = asyncActionType('COMPANY_TALENT_MARKET_PLAN_CURRENT_SET');
export const PROJECTS_REFRESH = 'PROJECTS_REFRESH';
export const PROJECTS_GET = 'PROJECTS_GET';
export const PROJECTS_GET_ALL = 'PROJECTS_GET_ALL';
export const PROJECT_GET = 'PROJECT_GET';
export const PROJECT_TEMPLATES_GET = asyncActionType('PROJECT_TEMPLATES_GET');
export const PROJECT_INVITATION_CREATE = asyncActionType('PROJECT_INVITATION_CREATE');
export const PROJECT_INVITATION_URLS_GET = asyncActionType('PROJECT_INVITATION_URLS_GET');
export const PROJECT_PARTICIPANTS_BATCH_ACTION = asyncActionType('PROJECT_PARTICIPANTS_BATCH_ACTION');
export const PROJECT_COPY = asyncActionType('PROJECT_COPY');
export const PROJECT_PARTICIPANTS_GROUP_MEMBERSHIPS_GET = asyncActionType('PROJECT_PARTICIPANTS_GROUP_MEMBERSHIPS_GET');
export const RESULTS_GET = asyncActionType('RESULTS_GET');
export const RESULT_GET = asyncActionType('RESULT_GET');
export const ACTIVITY_TEMPLATES_GET = asyncActionType('ACTIVITY_TEMPLATES_GET');
export const ACTIVITY_TEMPLATES_MESSAGES_GET = asyncActionType('ACTIVITY_TEMPLATES_MESSAGES_GET');

export const GROUPS_GET = asyncActionType('GROUPS_GET');
export const GROUP_GET = asyncActionType('GROUP_GET');
export const GROUP_CREATE = asyncActionType('GROUP_CREATE');
export const GROUP_UPDATE = asyncActionType('GROUP_UPDATE');
export const GROUP_DELETE = asyncActionType('GROUP_DELETE');
export const GROUP_CURRENT_UNSET = 'GROUP_CURRENT_UNSET';
export const GROUP_MEMBERS_GET = asyncActionType('GROUP_MEMBERS_GET');
export const GROUP_MANAGERS_GET = asyncActionType('GROUP_MANAGERS_GET');
export const GROUP_MEMBER_CANDIDATES_GET = asyncActionType('GROUP_MEMBER_CANDIDATES_GET');
export const GROUP_MEMBER_SUGGESTED_CANDIDATES_GET = asyncActionType('GROUP_MEMBER_SUGGESTED_CANDIDATES_GET');
export const GROUP_MEMBER_UPDATE = asyncActionType('GROUP_MEMBER_UPDATE');
export const GROUP_MEMBER_DELETE = asyncActionType('GROUP_MEMBER_DELETE');
export const GROUP_TARGET_GET = asyncActionType('GROUP_TARGET_GET');
export const GROUP_TARGET_UNSET = asyncActionType('GROUP_TARGET_UNSET');
export const GROUP_SOURCES_GET = asyncActionType('GROUP_SOURCES_GET');
export const GROUP_SUBGROUP_SORT_SET = 'GROUP_SUBGROUP_SORT_SET';

export const INSIGHTS_GET = asyncActionType('INSIGHTS_GET');
export const INSIGHTS_UNSET = 'INSIGHTS_UNSET';
export const INSIGHT_GET = asyncActionType('INSIGHT_GET');
export const INSIGHT_UNSET = 'INSIGHT_UNSET';
export const INSIGHT_CREATE = asyncActionType('INSIGHT_CREATE');
export const INSIGHT_UPDATE = asyncActionType('INSIGHT_UPDATE');
export const INSIGHT_DELETE = asyncActionType('INSIGHT_DELETE');

export const COMPANY_TALENT_POOLS_GET = asyncActionType('COMPANY_TALENT_POOLS_GET');

export const TALENT_MARKET_PLANS_GET = asyncActionType('TALENT_MARKET_PLANS_GET');
export const TALENT_MARKET_PLAN_SELECT = 'TALENT_MARKET_PLAN_SELECT';
export const TALENT_MARKET_PLAN_CURRENCY_SELECT = 'TALENT_MARKET_PLAN_CURRENCY_SELECT';
export const TALENT_MARKET_PLAN_BILLING_PERIOD_SELECT = 'TALENT_MARKET_PLAN_BILLING_PERIOD_SELECT';

export const EAPI_KEYS_GET = asyncActionType('EAPI_KEYS_GET');
export const EAPI_KEY_CREATE = asyncActionType('EAPI_KEY_CREATE');
export const EAPI_KEY_DELETE = asyncActionType('EAPI_KEY_DELETE');
export const EAPI_KEY_HIDE = 'EAPI_KEY_HIDE';

// optionsActions
export const GRID_SET_OPTIONS = 'GRID_SET_OPTIONS';
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';
export const CHART_SET_ACTIVE = 'CHART_SET_ACTIVE';
export const CHART_TOGGLE_EXPAND = 'CHART_TOGGLE_EXPAND';
export const CHART_SET_MODE = 'CHART_SET_MODE';
export const CHART_SET_PROPERTY = 'CHART_SET_PROPERTY';
export const OVERVIEW_CHART_SET_ACTIVE = 'OVERVIEW_CHART_SET_ACTIVE';
export const OVERVIEW_CHART_SET_SORTED = 'OVERVIEW_CHART_SET_SORTED';
export const OVERVIEW_CHART_SET_WINDOW = 'OVERVIEW_CHART_SET_WINDOW';
export const SWITCH_LOADER = 'SWITCH_LOADER';
export const SWITCH_MODAL = 'SWITCH_MODAL';

// loader properties
export const LOGIN_LOADING = 'isLoginLoading';
export const USER_LOADING = 'isUserLoading';
export const EMAIL_LOADING = 'isEmailLoading';
export const PASSWORD_RESET_LOADING = 'isPasswordResetLoading';
export const APPLICANT_LOADING = 'isApplicantLoading';
export const APPLICANTS_LOADING = 'isApplicantsLoading';
export const COMPANIES_LOADING = 'isCompaniesLoading';
export const PROJECTS_LOADING = 'isProjectsLoading';
export const COMPANY_OPERATORS_LOADING = 'isCompanyOperatorsLoading';
export const PROJECT_LOADING = 'isProjectLoading';
export const ACCOUNT_NEW_LOADING = 'isAccountNewLoading';
export const ACCOUNT_ACTIVATION_LOADING = 'isAccountActivationLoading';
export const UPGRADE_ACCOUNT_LOADING = 'isUpgradeAccountLoading';

// modal properties
export const MODAL_EMAIL_SENT = 'MODAL_EMAIL_SENT';
export const MODAL_INVITE_APPLICANTS = 'MODAL_INVITE_APPLICANTS';
export const MODAL_CONFIRM_ACTIVATE = 'MODAL_CONFIRM_ACTIVATE';
export const MODAL_CONFIRM_DELETE = 'MODAL_CONFIRM_DELETE';
export const MODAL_CONFIRM_FINISH = 'MODAL_CONFIRM_FINISH';
export const MODAL_SIMPLE = 'MODAL_SIMPLE';
export const MODAL_ACCOUNT_NEW_RESULT = 'MODAL_ACCOUNT_NEW_RESULT';
export const MODAL_ACCOUNT_UPGRADE = 'MODAL_ACCOUNT_UPGRADE';
export const MODAL_DEMO_PLAY = 'MODAL_DEMO_PLAY';
export const MODAL_UNFINISHED_NOTIFICATION = 'MODAL_UNFINISHED_NOTIFICATION';
export const MODAL_PROJECT_COPY = 'MODAL_PROJECT_COPY';
export const MODAL_PARTICIPANT_BATCH_OPERATION = 'MODAL_PARTICIPANT_BATCH_OPERATION';
export const MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE = 'MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE';
export const MODAL_OPPORTUNITY_POST_PUBLICATION = 'MODAL_OPPORTUNITY_POST_PUBLICATION';
export const MODAL_CHANGED_FORM_LEAVE = 'MODAL_CHANGED_FORM_LEAVE';
export const MODAL_CROSS_COMPARISON_PROJECT_SELECT = 'MODAL_CROSS_COMPARISON_PROJECT_SELECT';
export const MODAL_COMPARISON_PARTICIPANTS_SELECT = 'MODAL_COMPARISON_PARTICIPANTS_SELECT';
export const MODAL_TALENT_MARKET_PLAN_SELECT = 'MODAL_TALENT_MARKET_PLAN_SELECT';

// localStorageKeys
export const USER_TOKEN = 'USER_TOKEN';
export const GRIDS_OPTIONS = 'GRIDS_OPTIONS';

// search actions
export const PROJECT_FILTER_VALUE_SET = 'PROJECT_FILTER_VALUE_SET';
export const PROJECT_FILTER_ADVANCED_TOGGLE = 'PROJECT_FILTER_ADVANCED_TOGGLE';
export const PROJECT_FILTER_LAYOUT_SET = 'PROJECT_FILTER_LAYOUT_SET';
export const PROJECT_FILTER_FROM_VALUES_SET = 'PROJECT_FILTER_FROM_VALUES_SET';
export const PROJECT_FILTER_SORT_SET = 'PROJECT_FILTER_SORT_SET';
export const PROJECT_FILTER_DEMO_SHOWN_SET = 'PROJECT_FILTER_DEMO_SHOWN_SET';
export const PAGE_SET_PROJECTS = 'PAGE_SET_PROJECTS';

// notifications actions
export const NOTIFICATIONS_ADD = 'NOTIFICATIONS_ADD';
export const NOTIFICATIONS_HIDE = 'NOTIFICATIONS_HIDE';
export const NOTIFICATIONS_DISMISS = 'NOTIFICATIONS_DISMISS';
export const NOTIFICATIONS_SHOW_HIDDEN = 'NOTIFICATIONS_SHOW_HIDDEN';
export const NOTIFICATIONS_HIDE_ACTIVE = 'NOTIFICATIONS_HIDE_ACTIVE';

export const NOTIFICATIONS_TYPE_CTA = 'NOTIFICATIONS_TYPE_CTA';

// score mapping actions
export const SCORE_MAPPING_SET = 'SCORE_MAPPING_SET';
export const SCORE_TOTAL_ENABLED_SET = 'SCORE_TOTAL_ENABLED_SET';
export const CORE_METRICS_SET = 'CORE_METRICS_SET';

// skill scatter chart actions
export const SKILL_SCATTER_CHART_SET_COMPETENCY_VALUE = 'SKILL_SCATTER_CHART_SET_COMPETENCY_VALUE';

// project overview actions
export const PROJECT_OVERVIEW_PART_TOGGLE = 'PROJECT_OVERVIEW_PART_TOGGLE';
export const PROJECT_OVERVIEW_TAB_SET = 'PROJECT_OVERVIEW_TAB_SET';
export const PROJECT_OVERVIEW_CUSTOM_PROP_SET = 'PROJECT_OVERVIEW_CUSTOM_PROP_SET';

export const DEFAULT_WEIGHT = 1.0;

// snackbars actions
export const SNACKBAR_SHOW = 'SNACKBAR_SHOW';
export const SNACKBAR_HIDE = 'SNACKBAR_HIDE';

export const FLAGS_POSITION_CREATION = 'POSITION_CREATION';
export const FLAGS_PROJECT_DATA_EXPORT = 'PROJECT_DATA_EXPORT';
export const FLAGS_DASHBOARD_CHART = 'DASHBOARD_CHART';
export const FLAGS_WEIGHTS = 'WEIGHTS';
export const FLAGS_EMAIL_INVITATION = 'EMAIL_INVITATION';

export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_ERROR = 'STATUS_ERROR';

// designer actions
export const SIMULATION_MODELS_GET = asyncActionType('SIMULATION_MODELS_GET');
export const SIMULATION_MODELS_FILTER_TEXT_SET = 'SIMULATION_MODELS_FILTER_TEXT_SET';
export const SIMULATION_MODEL_GET = asyncActionType('SIMULATION_MODEL_GET');
export const SIMULATION_MODEL_METADATA_GET = asyncActionType('SIMULATION_MODEL_METADATA_GET');
export const SIMULATION_MODEL_CREATE = asyncActionType('SIMULATION_MODEL_CREATE');
export const SIMULATION_MODEL_UPDATE = asyncActionType('SIMULATION_MODEL_UPDATE');
export const SIMULATION_MODEL_DELETE = asyncActionType('SIMULATION_MODEL_DELETE');
export const SIMULATION_MODEL_COPY = asyncActionType('SIMULATION_MODEL_COPY');
export const SIMULATION_MODEL_CLEAR = 'SIMULATION_MODEL_CLEAR';

export const SIMULATION_DEFINITION_CREATE = asyncActionType('SIMULATION_DEFINITION_CREATE');

// simulation actions
export const DEMO_SIMULATION_URL_GET = asyncActionType('DEMO_SIMULATION_URL_GET');

export const API = 'API';
export const API_STATUS = 'API_STATUS';

export const MAX_APPLICANTS_COMPARED = 7;

// guide actions
export const GUIDE_NEXT_STEP = 'GUIDE_NEXT_STEP';
export const GUIDE_PREV_STEP = 'GUIDE_PREV_STEP';
export const GUIDE_FINISH = 'GUIDE_FINISH';
export const GUIDE_SHOW = 'GUIDE_SHOW';
export const GUIDE_HIDE = 'GUIDE_HIDE';
export const GUIDE_STEP_MAX = 5;
export const GUIDE_VERSION = 2;

// project edit actions
export const PROJECT_EDIT_ADVANCED_GENERAL_SETTINGS_TOGGLE = 'PROJECT_EDIT_ADVANCED_GENERAL_SETTINGS_TOGGLE';
export const PROJECT_EDIT_LOAD_VIEWS = asyncActionType('PROJECT_EDIT_LOAD_VIEWS');
export const PROJECT_EDIT_LOAD_CHATS = asyncActionType('PROJECT_EDIT_LOAD_CHATS');
// project user tags actions
export const PROJECT_USER_COMPANY_TAGS_GET = asyncActionType('PROJECT_USER_COMPANY_TAGS_GET');
export const GROUP_USER_COMPANY_TAGS_GET = asyncActionType('GROUP_USER_COMPANY_TAGS_GET');

// cross comparison actions
export const CROSS_COMPARISON_START = 'CROSS_COMPARISON_START';
export const CROSS_COMPARISON_UPDATE_PARTICIPANTS = 'CROSS_COMPARISON_UPDATE_PARTICIPANTS';
export const CROSS_COMPARISON_SET_PARTICIPANT_ACTIVE = 'CROSS_COMPARISON_SET_PARTICIPANT_ACTIVE';
export const CROSS_COMPARISON_SET_MANAGERIAL = 'CROSS_COMPARISON_SET_MANAGERIAL';
export const CROSS_COMPARISON_CANCEL = 'CROSS_COMPARISON_CANCEL';
export const CROSS_COMPARISON_LOAD_PARTICIPANTS = asyncActionType('CROSS_COMPARISON_LOAD_PARTICIPANTS');

// participant filters
export const PARTICIPANT_FILTER_OPEN = 'PARTICIPANT_FILTER_OPEN';
export const PARTICIPANT_FILTER_CLOSE = 'PARTICIPANT_FILTER_CLOSE';
export const PARTICIPANT_FILTER_SET_FULL_TEXT = 'PARTICIPANT_FILTER_SET_FULL_TEXT';
export const PARTICIPANT_FILTER_SET_FROM_VALUES = 'PARTICIPANT_FILTER_SET_FROM_VALUES';
// group member filters
export const GROUP_MEMBER_FILTER_OPEN = 'GROUP_MEMBER_FILTER_OPEN';
export const GROUP_MEMBER_FILTER_CLOSE = 'GROUP_MEMBER_FILTER_CLOSE';
export const GROUP_MEMBER_FILTER_SET_FULL_TEXT = 'GROUP_MEMBER_FILTER_SET_FULL_TEXT';
export const GROUP_MEMBER_FILTER_SET_FROM_VALUES = 'GROUP_MEMBER_FILTER_SET_FROM_VALUES';

// opportunities actions
export const OPPORTUNITIES_GET = asyncActionType('OPPORTUNITIES_GET');
export const OPPORTUNITY_GET = asyncActionType('OPPORTUNITY_GET');
export const OPPORTUNITY_PARTICIPANT_GET = asyncActionType('OPPORTUNITY_PARTICIPANT_GET');
export const OPPORTUNITY_PARTICIPANTS_RESPONSE_SET = asyncActionType('OPPORTUNITY_PARTICIPANTS_RESPONSE_SET');
export const OPPORTUNITY_PARTICIPANTS_SORT = 'OPPORTUNITY_PARTICIPANTS_SORT';
export const OPPORTUNITIES_FILTER_FULL_TEXT_SET = 'OPPORTUNITIES_FILTER_FULL_TEXT_SET';
export const OPPORTUNITY_PARTICIPANTS_SHOW_REJECTED_TOGGLE = 'OPPORTUNITY_PARTICIPANTS_SHOW_REJECTED_TOGGLE';
export const OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_PERFORM = asyncActionType('OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_PERFORM');
export const OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_SET_FILTER_PROPERTY = 'OPPORTUNITY_PARTICIPANTS_ACTIVE_SEARCH_SET_FILTER_PROPERTY';
// opportunity post actions
export const OPPORTUNITY_POST_CLEAR = 'OPPORTUNITY_POST_CLEAR';
export const OPPORTUNITY_POST_GET = asyncActionType('OPPORTUNITY_POST_GET');
export const OPPORTUNITY_POST_CREATE = asyncActionType('OPPORTUNITY_POST_CREATE');
export const OPPORTUNITY_POST_UPDATE = asyncActionType('OPPORTUNITY_POST_UPDATE');

// data sources actions
export const DATA_SOURCES_GET = asyncActionType('DATA_SOURCES_GET');

export const DEMO_COMPANY_SLUG = 'demo-company';

export const HEADER_CLASS = "navigation-bar";
export const HEADER_SELECTOR = `.${HEADER_CLASS}`;

export const SIMULATION_RESOLUTION_MINIMUM = {
  WIDTH: 1200,
  HEIGHT: 638
};

// https://stackoverflow.com/questions/309149/generate-distinctly-different-rgb-colors-in-graphs
export const HIGHLIGHT_COLORS = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#008080',
  '#9a6324',
  '#800000',
  '#808000',
  '#000075',
  '#fabebe',
  '#e6beff',
  '#fffac8',
  '#aaffc3',
  '#ffd8b1',
  '#808080'
];

const BASE_BRAND = {
  email: function(prefix) {
    return `${prefix}@${this.domain}`;
  },
  folder: function() {
    return this.key.toLowerCase();
  }
};

export const COLORS = {
  locked: colors.grey500,
  low: colors.red500,
  midLow: colors.deepOrange500,
  mid: colors.orange500,
  midHigh: colors.lightGreen500,
  high: colors.green500,
  neutral: colors.blue500
};

const BRANDS = {
  BEHAVERA: {
    ...BASE_BRAND,
    domain: 'behavera.com',
    label: 'Behavera',
    key: 'BEHAVERA',
    theme: {
      palette: {
        primary1Color: '#722ed0', //'#854bd6',
        primary2Color: '#854bd6',
        accent1Color: '#fb47c2',
        mainColor: '#722ed0',
        pickerHeaderColor: '#722ed0',
        customAccentColor: '#60ffd5',
        applicationStatus: {
          accepted: '#4caf50',
          futureFit: '#03a9f4',
          rejected: '#f44336',
          inProgress: '#ffeb3b',
          withdrawn: '#607d8b',
          offerRejected: '#9c27b0'
        },
        projects: {
          cards: {
            active: '#6dcb45',
            scheduled: '#dd4b64',
            finished: '#46617c',
            concept: '#9e9e9e'
          }
        }
      },
      tabs: {
        backgroundColor: '#1b0343'
      }
    }
  },
  VIRTUBIO: {
    ...BASE_BRAND,
    domain: 'virtub.io',
    label: 'Virtubio',
    key: 'VIRTUBIO',
    theme: {
      palette: {
        primary1Color: '#049BE5',
        primary2Color: '#029AE4',
        accent1Color: '#FF9700',
        mainColor: '#049BE5',
        pickerHeaderColor: '#049BE5',
        applicationStatus: {
          accepted: '#4caf50',
          futureFit: '#03a9f4',
          rejected: '#f44336',
          inProgress: '#ffeb3b',
          withdrawn: '#607d8b',
          offerRejected: '#9c27b0'
        },
        projects: {
          cards: {
            active: '#4caf50',
            scheduled: '#FF9700',
            finished: '#049BE5',
            concept: '#9e9e9e'
          }
        }
      },
      tabs: {
        backgroundColor: '#0177bc'
      }
    }
  }
};

const determineBrand = () => {
  const host = (window.location && window.location.host) || '';
  if (host.indexOf('behavera') > -1) {
    return BRANDS.BEHAVERA;
  }
  if (host.indexOf('virtub') > -1) {
    return BRANDS.VIRTUBIO;
  }
  return BRANDS.BEHAVERA;
};

export const BRAND = determineBrand();
