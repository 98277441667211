import { authRequest } from '../Request';
import { API_URL } from '../../config';

export function getTalentMarketPlans(lang) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/talent_market_plans`)
      .query({ lang })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
