import React from 'react';
import map from 'lodash/map';
import range from 'lodash/range';
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  Cell,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  ReferenceArea,
  ReferenceLine
} from 'recharts';

import Paper from 'material-ui/Paper';

import MetricIcon from '../icons/Metric';
import IconToggle from '../basic/IconToggle';
import IconToggleGroup from '../basic/IconToggleGroup';
import MultiColorDonut from '../chart/MultiColorDonut';

const MAX_BUBBLE_SIZE = 40;
const MIN_BUBBLE_SIZE = 15;

const GRADIENT_COLOR_LEVELS = ['#d02e3b', '#d14d3a', '#d16539', '#cf7b37', '#ca8f35', '#c1a333', '#b1b930', '#8cd02e'];

const GradientOverlay = props => {
  const { viewBox: { x, y, width, height }, colors = GRADIENT_COLOR_LEVELS, gId } = props;
  const gradId = `gradientOverlay${gId}`;
  const gradientR = Math.sqrt(width * width + height * height) / width;
  return (
    <g>
      <defs>
        <radialGradient id={gradId} cx="0" cy="100%" r={gradientR}>
          {map(colors, (c, idx) => <stop key={idx} offset={idx / (colors.length - 1)} stopColor={c} />)}
        </radialGradient>
      </defs>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={`url('#${gradId}')`}
        rx={2}
        ry={2}
        fillOpacity={0.4}
        style={{ shapeRendering: 'geometricPrecision' }}
      />
    </g>
  );
};

// const CustomLabel = props => {
//   const { viewBox: { x, y, width, height }, fill, stars } = props;
//   const size = Math.min(width, height) * 2 / (2 + Math.sqrt(3));
//   const hDiff = Math.max(0, height - width) / 2;
//   const fullOpacity = 0.5;
//   if (true) {
//     return null;
//   }
//   const data = [
//     { x: x + width / 2, y: y + hDiff + size, full: stars > 2 },
//     { x: x + (width - size) / 2, y: y + height - hDiff, full: stars > 0 },
//     { x: x + (width + size) / 2, y: y + height - hDiff, full: stars > 1 }
//   ];
//   return (
//     <g>
//       {map(data, (d, idx) => (
//         <text key={idx} x={d.x} y={d.y} textAnchor="middle" fill={fill} fillOpacity={fullOpacity}>
//           <tspan className="material-icons" style={{ fontSize: size }}>
//             {d.full ? 'star' : 'star_border'}
//           </tspan>
//         </text>
//       ))}
//     </g>
//   );
// };

const Z_AXIS_RANGE = [
  Math.PI * Math.pow((MIN_BUBBLE_SIZE - 2) / 2, 2),
  Math.PI * Math.pow((MAX_BUBBLE_SIZE - 2) / 2, 2)
];
const Z_AXIS_DOMAIN = [0, 1];
const BASE_DOMAIN = [0.5, 10.5];
const BASE_TICKS = range(1, 11);

const MultiColorCell = props => {
  const { x, y, fill, width, payload } = props;
  if (!width) {
    return null;
  }
  const colors = (payload && payload.colors) || [fill];
  return <MultiColorDonut x={x} y={y} colors={colors} size={width} wihinSvg options={{ borderWidth: width / 2 }} />;
};

const Chart = ({ data, messages }) => {
  const axisProps = {
    type: 'number',
    strokeWidth: 1,
    ticks: BASE_TICKS,
    domain: BASE_DOMAIN
  };
  return (
    <ResponsiveContainer height={400}>
      <ScatterChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <XAxis dataKey="x" {...axisProps} height={25} axisLine={false} tickLine={false} />
        <YAxis dataKey="y" {...axisProps} width={25} axisLine={false} tickLine={false} />
        <ZAxis dataKey="z" range={Z_AXIS_RANGE} domain={Z_AXIS_DOMAIN} />
        {map(BASE_TICKS, row => (
          <ReferenceLine key={`vertical-${row}`} y={row} strokeDasharray="5 5" stroke="rgba(0,0,0,0.12)" />
        ))}
        {map(BASE_TICKS, col => (
          <ReferenceLine key={`horizontal-${col}`} x={col} strokeDasharray="5 5" stroke="rgba(0,0,0,0.12)" />
        ))}
        <ReferenceArea x1={0.5} y1={0.5} fill="none" label={<GradientOverlay gId="1" />} />
        <Tooltip
          content={props => {
            const { payload } = props;
            const data = payload && payload.length && payload[0].payload;
            if (!data) {
              return null;
            }
            return (
              <Paper zDepth={1} style={{ padding: '16px' }}>
                <strong>{messages.primaryCompetenciesLabel}</strong>
                <span style={{ marginLeft: '8px' }}>{data.x.toFixed(1)}</span>
                <br />
                <strong>{messages.secondaryCompetenciesLabel}</strong>
                <span style={{ marginLeft: '8px' }}>{data.y.toFixed(1)}</span>
                <br />
                <br />
                <div>
                  {map(data.applicants, a => (
                    <div key={a.id}>
                      {a.participant.lastName} {a.participant.firstName}
                    </div>
                  ))}
                </div>
              </Paper>
            );
          }}
        />
        <Scatter data={data} isAnimationActive={false} shape={<MultiColorCell />}>
          {map(data, (e, idx) => <Cell key={`ssc-${idx}`} fill={e.color} fillOpacity={0.8} />)}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
};

const SkillScatterChart = props => {
  const { data: { scoreMappingMetrics, data, competencyClasses }, intl } = props;
  const { setSkillScatterChartCompetencyValue } = props;
  const messages = intl.messages.components.pages.private.project.analytics.scatterChart;
  return (
    <div className="container-flex-row fw-yes mui-padded">
      <div className="flex1" style={{ maxWidth: 400 }}>
        <div className="mui-padded">
          <div style={{ marginBottom: '8px' }}>
            <div className="mui-label">{messages.competenciesLabel}</div>
            <small>1 - {messages.primaryCompetenciesLabel}</small>
            <br />
            <small>2 - {messages.secondaryCompetenciesLabel}</small>
          </div>
          {map(scoreMappingMetrics, metric => {
            const classValue = competencyClasses[metric.key];
            const iconStyle = {
              color: 'rgba(0,0,0,0.08)'
            };
            const className = classValue === 1 ? 'text-primary' : classValue === 2 ? 'text-primary-sub' : '';
            const { key, label } = metric;
            return (
              <div key={key} className="container-flex-row ai-center jc-space-between fw-yes mui-padded-vertical-half">
                <div className="container-flex-row ai-center">
                  <MetricIcon metric={metric} style={iconStyle} className={className} />
                  <span className="mui-padded-horizontal">{label}</span>
                </div>
                <IconToggleGroup>
                  <IconToggle
                    icon={<b>1</b>}
                    active={classValue === 1}
                    onToggle={() => {
                      setSkillScatterChartCompetencyValue(key, classValue === 1 ? 0 : 1);
                    }}
                  />
                  <IconToggle
                    icon={<b>2</b>}
                    active={classValue === 2}
                    onToggle={() => {
                      setSkillScatterChartCompetencyValue(key, classValue === 2 ? 0 : 2);
                    }}
                  />
                </IconToggleGroup>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex2" style={{ fontSize: '12px' }}>
        <div className="mui-padded">
          <Chart data={data} messages={messages} />
        </div>
      </div>
    </div>
  );
};

export default SkillScatterChart;
