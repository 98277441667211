import React from 'react';
import PropTypes from 'prop-types';

import InvitationSentContent from './invitation/InvitationSentContent';
import SendFromSystemContent from './invitation/SendFromSystemContent';
import UrlsContent from './invitation/UrlsContent';
import Overlay from '../basic/Overlay';
import Button from '../mui/Button';

import { formatMessage } from '../../utils/utils';

class InvitationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anonymous: false,
      sendFromSystem: true,
      emails: [],
      invitationSent: false,
      invitationError: false,
      invitations: null
    };
  }

  toggleAnonymous = () => {
    this.setState(state => ({ anonymous: !state.anonymous }));
  };

  toggleSendFromSystem = () => {
    this.setState(state => ({ sendFromSystem: !state.sendFromSystem }));
  };

  handleEmailsChange = emails => this.setState({ emails });

  handleInviteAgain = () => this.setState({ invitationSent: false, invitations: null, invitationError: false });

  sendInvitations = () => {
    const { createProjectInvitation, meta } = this.props;
    const { companySlug, projectSlug } = meta || {};
    const { emails } = this.state;
    if (companySlug && projectSlug) {
      createProjectInvitation(companySlug, projectSlug, emails).then(res => {
        if (res.payload && res.payload.statusCode === 200) {
          const {
            payload: {
              payload: { items }
            }
          } = res;
          this.setState({ invitationSent: true, invitations: items });
        } else {
          this.setState({ invitationError: true });
        }
      });
    }
  };

  render() {
    const { auth, intl, open, link, anonymousLink, code } = this.props;
    const { hideModal, showSnackbar } = this.props;
    const { anonymous, invitationSent, invitationError, invitations, emails } = this.state;
    const sendFromSystem = auth.canUseEmailInvitation && this.state.sendFromSystem;

    const messages = intl.messages.components.modals.invitation;
    const linkMessages = intl.messages.components.snackbar.link;
    const snackbarMessage = window.navigator.userAgent.indexOf('Mac') >= 0 ? linkMessages.mac : linkMessages.common;
    const invitationSuccess = invitationSent && !invitationError;

    const actualLink = anonymousLink && anonymous ? anonymousLink : link;
    if (!open) {
      return null;
    }
    return (
      <Overlay onRequestClose={hideModal} maxWidth={640} modal noBlur>
        <div className="mui-card mui-appear">
          <div
            className="mui-padded-x2 border-bottom border-color-primary__50"
            style={{ paddingTop: '24px', lineHeight: '32px', fontSize: '22px' }}
          >
            {invitationSuccess ? messages.invitationSent.title : messages.invite}
          </div>
          <div className="text-muted mui-padded-x2">
            {invitationError ? (
              <p>
                {formatMessage(messages.invitationError.message, (idx, val) => (
                  <a key={idx} role="button" onClick={this.handleInviteAgain}>
                    {val}
                  </a>
                ))}
              </p>
            ) : invitationSent ? (
              <InvitationSentContent messages={messages} results={invitations} inviteMore={this.handleInviteAgain} />
            ) : sendFromSystem ? (
              <SendFromSystemContent
                messages={messages}
                toggleSendFromSystem={this.toggleSendFromSystem}
                onEmailsChange={this.handleEmailsChange}
                emails={emails}
              />
            ) : (
              <UrlsContent
                link={actualLink}
                code={code}
                anonymousSwitchShown={!!anonymousLink}
                anonymous={anonymous}
                messages={messages}
                snackbarMessage={snackbarMessage}
                showSnackbar={showSnackbar}
                toggleAnonymous={this.toggleAnonymous}
                toggleSendFromSystem={auth.canUseEmailInvitation && this.toggleSendFromSystem}
              />
            )}
          </div>
          <div className="text-right mui-padded border-top border-color-primary__50">
            {!invitationSuccess && sendFromSystem ? (
              <>
                <Button label={messages.cancel} onClick={hideModal} style={{ verticalAlign: 'top' }} />
                <Button
                  raised
                  primary
                  icon={<i className={`material-icons text-${emails.length ? 'white' : 'muted'}`}>email</i>}
                  label={messages.inviteButton}
                  onClick={this.sendInvitations}
                  disabled={!emails.length}
                  style={{ verticalAlign: 'top', marginLeft: '8px' }}
                />
              </>
            ) : (
              <Button primary label={messages.done} onClick={hideModal} />
            )}
          </div>
        </div>
      </Overlay>
    );
  }
}

InvitationModal.propTypes = {
  intl: PropTypes.object,
  open: PropTypes.bool,
  link: PropTypes.string,
  showSnackbar: PropTypes.func,
  hideModal: PropTypes.func
};

export default InvitationModal;
