import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import { SubmissionError } from 'redux-form';

import { MODAL_EMAIL_SENT } from '../../constants/constants';
import { HTTPErrorCodes } from '../../constants/enum';
import allActions from './../../actions';

import ResetPasswordEmailPage from './../../components/pages/public/ResetPasswordEmail';
import { isEmail } from '../../components/forms/Validator';

class ResetPasswordEmail extends Component {
  onSubmit = ({ email }) => {
    const { switchModal, sendEmail, intl, showSnackbar } = this.props;

    const messages = intl.messages.components.pages.public.resetPasswordEmail;

    if (!email || (email && !isEmail(email)))
      return new Promise(() => {
        throw new SubmissionError({
          email: messages.error
        });
      });

    return sendEmail(email.trim()).then(res => {
      if (res.errorMessage) {
        if (res.statusCode === HTTPErrorCodes.NOT_FOUND) {
          showSnackbar({ id: 'ERROR', message: messages.errorNotFound });
        } else {
          showSnackbar({ id: 'ERROR', message: intl.messages.components.snackbar.error });
        }
      } else {
        switchModal({ id: MODAL_EMAIL_SENT });
      }
    });
  };

  onModalClose = () => {
    const { router, switchModal } = this.props;

    switchModal({ id: MODAL_EMAIL_SENT });
    router.push({ pathname: '/login' });
  };

  render() {
    const { intl, auth, modal, updateLocale } = this.props;
    const pageProps = {
      intl,
      auth,
      opened: modal[MODAL_EMAIL_SENT].open,
      updateLocale,
      onModalClose: this.onModalClose,
      onSubmit: this.onSubmit
    };

    return <ResetPasswordEmailPage {...pageProps} />;
  }
}

ResetPasswordEmail.propTypes = {
  intl: PropTypes.object,
  router: PropTypes.object,
  auth: PropTypes.object,
  modal: PropTypes.object,
  updateLocale: PropTypes.func,
  emailSentModal: PropTypes.func,
  sendEmail: PropTypes.func,
  switchModal: PropTypes.func,
  showSnackbar: PropTypes.func
};

const mapStateToProps = state => {
  const { auth, intl, options: { modal } } = state;
  return {
    auth,
    intl,
    modal
  };
};

const mapDispatchToProps = dispatch => ({ ...bindActionCreators(allActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordEmail));
