import color from 'color';
import map from 'lodash/map';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import values from 'lodash/values';
import reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import { BRAND } from './constants';

const theme = BRAND.theme;

export const EApplicantListSort = {
  POSITION: 'result.position',
  NAME: 'participant.lastName',
  SCORE: 'result.metrics.scoreTotal.value',
  FINISHED_AT: 'simulation.finishedAt'
};

export const EActivityStatus = {
  REGISTERED: 'REGISTERED',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED'
};

export const EProjectType = {
  POSITION: {
    key: 'POSITION'
  },
  GROUP_ASSESSMENT: {
    key: 'GROUP_ASSESSMENT'
  },
  TALENT_PROGRAM: {
    key: 'TALENT_PROGRAM'
  },
  VIEW: {
    key: 'VIEW',
    view: true
  },
  QUESTIONNAIRE: {
    key: 'QUESTIONNAIRE',
    chat: true
  }
};

export const ERank = {
  LOCKED: {
    key: 'LOCKED',
    sign: 'avg',
    value: 0
  },
  LOW: {
    key: 'LOW',
    sign: 'minus',
    value: 0
  },
  MID_LOW: {
    key: 'MID_LOW',
    sign: 'minus',
    value: 0
  },
  MID: {
    key: 'MID',
    sign: 'avg',
    value: 1
  },
  MID_HIGH: {
    key: 'MID_HIGH',
    sign: 'plus',
    value: 2
  },
  HIGH: {
    key: 'HIGH',
    sign: 'plus',
    value: 2
  },
  NEUTRAL: {
    key: 'NEUTRAL',
    sign: 'avg',
    value: 1
  }
};

export const ESortOrder = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const EApplicationStatus = {
  NONE: {
    value: 'NONE',
    none: true,
    order: 0
  },
  ACCEPTED: {
    value: 'ACCEPTED',
    color: theme.palette.applicationStatus.accepted,
    order: 2
  },
  FUTURE_FIT: {
    value: 'FUTURE_FIT',
    color: theme.palette.applicationStatus.futureFit,
    order: 3
  },
  REJECTED: {
    value: 'REJECTED',
    color: theme.palette.applicationStatus.rejected,
    order: 4
  },
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    color: theme.palette.applicationStatus.inProgress,
    order: 1
  },
  WITHDRAWN: {
    value: 'WITHDRAWN',
    color: theme.palette.applicationStatus.withdrawn,
    order: 5
  },
  OFFER_REJECTED: {
    value: 'OFFER_REJECTED',
    color: theme.palette.applicationStatus.offerRejected,
    order: 6
  }
};

export const EProfileFeature = {
  SKILLS_DESCRIPTION: 'SKILLS_DESCRIPTION',
  RECOMMENDATIONS: 'RECOMMENDATIONS',
  PROFILE_PRINT: 'PROFILE_PRINT'
};

export const ENotificationFrequency = {
  NEVER: {
    key: 'NEVER',
    order: 0
  },
  HOURLY: {
    key: 'HOURLY',
    order: 1
  },
  DAILY: {
    key: 'DAILY',
    order: 2
  },
  WEEKLY: {
    key: 'WEEKLY',
    order: 3
  }
};

export const getEnumOrdered = e => {
  const res = [];
  let i = 0;
  for (let prop in e) {
    if (e.hasOwnProperty(prop)) {
      res[e[prop].order || i] = { key: prop, ...e[prop] };
      i += 1;
    }
  }
  return res;
};

export const EProjectState = {
  ACTIVE: {
    key: 'ACTIVE',
    filter: 'ACTIVE',
    color: theme.palette.projects.cards.active,
    colorTransparent: color(theme.palette.projects.cards.active).alpha(0.2).rgb().string(),
    order: 0,
    hasCounts: true
  },
  SCHEDULED: {
    key: 'SCHEDULED',
    filter: 'SCHEDULED',
    color: theme.palette.projects.cards.scheduled,
    colorTransparent: color(theme.palette.projects.cards.scheduled).alpha(0.2).rgb().string(),
    order: 1
  },
  FINISHED: {
    key: 'FINISHED',
    filter: 'FINISHED',
    color: theme.palette.projects.cards.finished,
    colorTransparent: color(theme.palette.projects.cards.finished).alpha(0.2).rgb().string(),
    order: 2,
    hasCounts: true
  },
  CONCEPT: {
    key: 'CONCEPT',
    filter: 'CONCEPT',
    color: theme.palette.projects.cards.concept,
    colorTransparent: color(theme.palette.projects.cards.concept).alpha(0.2).rgb().string(),
    order: 3
  }
};

export const EProjectFilterLayout = {
  CARD: {
    key: 'CARD',
    pageSize: 6
  },
  LIST: {
    key: 'LIST',
    pageSize: 10
  }
};

export const HTTPErrorCodes = {
  FORBIDDEN: 409,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  BAD_REQUEST: 400
};

export const EPricePackage = {
  MINI: {
    id: 'MINI',
    order: 0
  },
  BASIC: {
    id: 'BASIC',
    order: 1
  },
  STANDARD: {
    id: 'STANDARD',
    order: 2
  },
  ADVANCED: {
    id: 'ADVANCED',
    order: 3
  }
};

export const EProjectTag = {
  DEMO: {
    id: 'DEMO',
    color: theme.palette.accent1Color
  }
};

export const EEducation = {
  VOCATIONAL: {
    key: 'VOCATIONAL',
    order: 0
  },
  SECONDARY: {
    key: 'SECONDARY',
    order: 1
  },
  POST_SECONDARY: {
    key: 'POST_SECONDARY',
    order: 2
  },
  UNDERGRADUATE: {
    key: 'UNDERGRADUATE',
    order: 3
  },
  GRADUATE: {
    key: 'GRADUATE',
    order: 4
  },
  POST_GRADUATE: {
    key: 'POST_GRADUATE',
    order: 5
  },
  PROFESSIONAL: {
    key: 'PROFESSIONAL',
    order: 6
  }
};

export const EFieldOfStudyCategory = {
  ARTS: {
    key: 'ARTS',
    order: 0
  },
  HUMANITIES: {
    key: 'HUMANITIES',
    order: 1
  },
  SOCIAL_SCIENCES: {
    key: 'SOCIAL_SCIENCES',
    order: 2
  },
  NATURAL_SCIENCES: {
    key: 'NATURAL_SCIENCES',
    order: 3
  },
  APPLIED_SCIENCES: {
    key: 'APPLIED_SCIENCES',
    order: 4
  }
};

export const EFieldOfStudy = {
  GENERAL: { key: 'GENERAL' },
  SERVICES: { key: 'SERVICES' },
  BUSINESS_TRADE: { key: 'BUSINESS_TRADE' },
  TECHNICAL: { key: 'TECHNICAL' },
  OTHER: { key: 'OTHER' },
  PERFORMING_ARTS: {
    key: 'PERFORMING_ARTS',
    category: EFieldOfStudyCategory.ARTS.key
  },
  VISUAL_ARTS: {
    key: 'VISUAL_ARTS',
    category: EFieldOfStudyCategory.ARTS.key
  },
  GEOGRAPHY: {
    key: 'GEOGRAPHY',
    category: EFieldOfStudyCategory.HUMANITIES.key
  },
  HISTORY: {
    key: 'HISTORY',
    category: EFieldOfStudyCategory.HUMANITIES.key
  },
  LANGUAGES: {
    key: 'LANGUAGES',
    category: EFieldOfStudyCategory.HUMANITIES.key
  },
  EDUCATION: {
    key: 'EDUCATION',
    category: EFieldOfStudyCategory.HUMANITIES.key
  },
  PHILOSOPHY: {
    key: 'PHILOSOPHY',
    category: EFieldOfStudyCategory.HUMANITIES.key
  },
  THEOLOGY: {
    key: 'THEOLOGY',
    category: EFieldOfStudyCategory.HUMANITIES.key
  },
  ECONOMICS: {
    key: 'ECONOMICS',
    category: EFieldOfStudyCategory.SOCIAL_SCIENCES.key
  },
  LAW: {
    key: 'LAW',
    category: EFieldOfStudyCategory.SOCIAL_SCIENCES.key
  },
  POLITICAL_SCIENCE: {
    key: 'POLITICAL_SCIENCE',
    category: EFieldOfStudyCategory.SOCIAL_SCIENCES.key
  },
  PSYCHOLOGY: {
    key: 'PSYCHOLOGY',
    category: EFieldOfStudyCategory.SOCIAL_SCIENCES.key
  },
  SOCIOLOGY: {
    key: 'SOCIOLOGY',
    category: EFieldOfStudyCategory.SOCIAL_SCIENCES.key
  },
  BIOLOGY: {
    key: 'BIOLOGY',
    category: EFieldOfStudyCategory.NATURAL_SCIENCES.key
  },
  CHEMISTRY: {
    key: 'CHEMISTRY',
    category: EFieldOfStudyCategory.NATURAL_SCIENCES.key
  },
  MATHEMATICS: {
    key: 'MATHEMATICS',
    category: EFieldOfStudyCategory.NATURAL_SCIENCES.key
  },
  PHYSICS: {
    key: 'PHYSICS',
    category: EFieldOfStudyCategory.NATURAL_SCIENCES.key
  },
  AGRICULTURE: {
    key: 'AGRICULTURE',
    category: EFieldOfStudyCategory.APPLIED_SCIENCES.key
  },
  COMPUTER_SCIENCE: {
    key: 'COMPUTER_SCIENCE',
    category: EFieldOfStudyCategory.APPLIED_SCIENCES.key
  },
  ENGINEERING: {
    key: 'ENGINEERING',
    category: EFieldOfStudyCategory.APPLIED_SCIENCES.key
  },
  MEDICINE: {
    key: 'MEDICINE',
    category: EFieldOfStudyCategory.APPLIED_SCIENCES.key
  }
};

const EFieldOfStudyArray = values(EFieldOfStudy);

export const EFieldOfStudyByCategory = map([null, ...keys(EFieldOfStudyCategory)], category => ({
  category,
  fields: filter(EFieldOfStudyArray, elem => (category === null ? !elem.category : elem.category === category))
}));

export const EWorkExperienceYears = {
  ZERO: {
    key: 'ZERO',
    order: 0
  },
  ONE: {
    key: 'ONE',
    order: 1
  },
  THREE: {
    key: 'THREE',
    order: 2
  },
  TEN: {
    key: 'TEN',
    order: 3
  },
  MORE: {
    key: 'MORE',
    order: 4
  }
};

export const EWorkExperiencePosition = {
  NO_SPECIALIZATION: {
    key: 'NO_SPECIALIZATION',
    order: 0
  },
  ADMINISTRATIVE: {
    key: 'ADMINISTRATIVE',
    order: 1
  },
  SALES: {
    key: 'SALES',
    order: 2
  },
  EXPERT: {
    key: 'EXPERT',
    order: 3
  },
  ENTREPRENEURIAL: {
    key: 'ENTREPRENEURIAL',
    order: 4
  },
  LINE_MANAGEMENT: {
    key: 'LINE_MANAGEMENT',
    order: 5
  },
  MIDDLE_MANAGEMENT: {
    key: 'MIDDLE_MANAGEMENT',
    order: 6
  },
  TOP_MANAGEMENT: {
    key: 'TOP_MANAGEMENT',
    order: 7
  }
};

export const EParticipantReport = {
  NONE: {
    key: 'NONE',
    order: 0
  },
  STANDARD: {
    key: 'STANDARD',
    order: 1
  },
  ENHANCED: {
    key: 'ENHANCED',
    order: 2
  },
  FULL: {
    key: 'FULL',
    order: 3
  }
};

export const EPoolEndpoint = {
  NONE: {
    key: 'NONE',
    order: 0
  },
  SOURCE: {
    key: 'SOURCE',
    order: 1
  },
  DESTINATION: {
    key: 'DESTINATION',
    order: 2
  }
};

export const ESimulationVariableOperation = {
  ASSIGN: {
    key: 'ASSIGN',
    label: ':=',
    order: 0
  },
  ADD: {
    key: 'ADD',
    label: '+=',
    order: 1
  },
  MULTIPLY: {
    key: 'MULTIPLY',
    label: '*=',
    order: 2
  },
  ASSIGN_VARIABLE: {
    key: 'ASSIGN_VARIABLE',
    label: '=',
    order: 3
  },
  ASSIGN_RESULT: {
    key: 'ASSIGN_RESULT',
    label: '=',
    fieldDisabled: true,
    order: 4
  }
};

const BASE_ACTIVITY_TYPE = {
  matches: function (p) {
    return p && p.activity && p.activity.key === this.value;
  }
};

export const EActivityType = {
  OFFICE_DAY: {
    ...BASE_ACTIVITY_TYPE,
    key: 'OFFICE_DAY',
    value: 'officeDay',
    label: 'The Office Day',
    order: 0,
    sampleMetrics: ['effectiveCommunication', 'informationProcessing', 'integrity', 'problemSolving', 'selfKnowledge']
  },
  GAME_CHANGER: {
    ...BASE_ACTIVITY_TYPE,
    key: 'GAME_CHANGER',
    value: 'manSim',
    label: 'The Game Changer',
    order: 1,
    sampleMetrics: ['leadership', 'openness', 'motivation', 'processOrientation', 'teamOrientation']
  },
  CULTURE_FIT: {
    ...BASE_ACTIVITY_TYPE,
    key: 'CULTURE_FIT',
    value: 'cultureFit',
    label: 'Culture Fit',
    order: 2,
    isLike: ({ key }) => /(^|_)cultureFit($|_)/.test(key),
    sampleMetrics: ['cfStability', 'cfPerformance', 'cfIndependence', 'cfChaos', 'cfStatus_opposite'],
    variants: {
      CANDIDATE: {
        key: 'CANDIDATE',
        value: 'candidate',
        order: 0,
        matches: function (p) {
          const { activity } = p || {};
          const { key, variant } = activity || {};
          return key === EActivityType.CULTURE_FIT.value && variant === this.value;
        }
      },
      EMPLOYEE: {
        key: 'EMPLOYEE',
        value: 'employee',
        order: 1,
        matches: function (p) {
          const { activity } = p || {};
          const { key, variant } = activity || {};
          return key === EActivityType.CULTURE_FIT.value && variant === this.value;
        }
      },
      MANAGER: {
        key: 'MANAGER',
        value: 'manager',
        order: 2,
        matches: function (p) {
          const { activity } = p || {};
          const { key, variant } = activity || {};
          return key === EActivityType.CULTURE_FIT.value && variant === this.value;
        }
      }
    }
  },
  ECHO: {
    ...BASE_ACTIVITY_TYPE,
    key: 'ECHO',
    value: 'echo',
    label: 'Echo',
    order: 3
  },
  OFFICE_DAY_DEMO: {
    ...BASE_ACTIVITY_TYPE,
    key: 'OFFICE_DAY_DEMO',
    value: 'officeDay_demo1',
    label: 'The Office Day DEMO',
    order: 4
  }
};

export const determineEActivityType = p => {
  for (var prop in EActivityType) {
    if (EActivityType.hasOwnProperty(prop)) {
      const type = EActivityType[prop];
      if (type.matches(p)) {
        if (type.variants) {
          for (var vProp in type.variants) {
            if (type.variants.hasOwnProperty(vProp)) {
              const variant = type.variants[vProp];
              if (variant.matches(p)) {
                return {
                  type,
                  variant
                };
              }
            }
          }
        }
        return { type };
      }
    }
  }
  return null;
};

export const EActivityEventType = {
  IMMEDIATE: {
    key: 'IMMEDIATE',
    order: 0
  },
  COMPLETED: {
    key: 'COMPLETED',
    order: 1
  }
};

export const ESeekerStatus = {
  ACTIVE: {
    key: 'ACTIVE',
    order: 0
  },
  OPEN: {
    key: 'OPEN',
    order: 1
  },
  TENTATIVE: {
    key: 'TENTATIVE',
    order: 2
  },
  INACTIVE: {
    key: 'INACTIVE',
    order: 3
  }
};

export const ECurrency = {
  CZK: {
    key: 'CZK',
    order: 0
  },
  EUR: {
    key: 'EUR',
    order: 1,
    baseRate: 25.5
  },
  USD: {
    key: 'USD',
    order: 2,
    baseRate: 22.5
  }
};

export const EBool = {
  YES: {
    key: 'YES',
    order: 0
  },
  NO: {
    key: 'NO',
    order: 1
  }
};

export const EMediaType = {
  IMAGE: {
    key: 'IMAGE'
  },
  VIDEO: {
    key: 'VIDEO'
  },
  FILE: {
    key: 'FILE'
  },
  WEBSITE: {
    key: 'WEBSITE'
  }
};

export const EOpportunityType = {
  BOOK: {
    key: 'BOOK'
  },
  EBOOK: {
    key: 'EBOOK'
  },
  EVENT: {
    key: 'EVENT'
  },
  ONLINE_COURSE: {
    key: 'ONLINE_COURSE'
  },
  COACH: {
    key: 'COACH'
  },
  VIDEO: {
    key: 'VIDEO'
  },
  JOB: {
    key: 'JOB'
  },
  TALENT_ACADEMY: {
    key: 'TALENT_ACADEMY'
  },
  ARTICLE: {
    key: 'ARTICLE'
  }
};

export const EContractPlanMode = {
  SOURCING: {
    key: 'SOURCING'
  },
  RECRUITMENT: {
    key: 'RECRUITMENT'
  },
  DEVELOPMENT: {
    key: 'DEVELOPMENT'
  },
  TEAMS: {
    key: 'TEAMS'
  }
};

export const EUpgradeRequestSource = {
  CREDIT_LIMITS: { key: 'CREDIT_LIMITS' },
  DASHBOARD_CHART: { key: 'DASHBOARD_CHART' },
  WEIGHTS_CONTROLS: { key: 'WEIGHTS_CONTROLS' },
  PARTICIPANT_PROFILE: { key: 'PARTICIPANT_PROFILE' },
  PARTICIPANT_PROFILE_LIST: { key: 'PARTICIPANT_PROFILE_LIST' },
  COMPETENCY_DESCRIPTION: { key: 'COMPETENCY_DESCRIPTION' },
  PERFORMANCE_INDICATOR: { key: 'PERFORMANCE_INDICATOR' },
  MODE_SOURCING: { key: 'MODE_SOURCING' },
  MODE_RECRUITMENT: { key: 'MODE_RECRUITMENT' },
  MODE_DEVELOPMENT: { key: 'MODE_DEVELOPMENT' },
  MODE_TEAMS: { key: 'MODE_TEAMS' },
  DESIGNER: { key: 'DESIGNER' }
};

export const EParticipantBatchOperation = {
  DELETE: { key: 'DELETE' },
  COPY: { key: 'COPY' },
  MOVE: { key: 'MOVE' }
};

export const ECompanyRejectReason = {
  NONE: { key: 'NONE', order: 0 },
  FILLED: { key: 'FILLED', order: 1 },
  OVERFLOW: { key: 'OVERFLOW', order: 2 },
  BAD_FIT: { key: 'BAD_FIT', order: 3 },
  CUSTOM: { key: 'CUSTOM', order: 4 }
};

export const EUserOpportunityPostResponse = {
  ACCEPTED: { key: 'ACCEPTED' },
  INTERESTED: { key: 'INTERESTED' },
  REJECTED: { key: 'REJECTED' }
};

export const EProjectFilterSort = {
  NAME: {
    key: 'NAME',
    property: 'name'
  },
  MODIFIED_AT: {
    key: 'MODIFIED_AT',
    property: 'modifiedAt',
    defaultOrder: ESortOrder.DESC
  },
  LATEST_PROFILE_ADDED_AT: {
    key: 'LATEST_PROFILE_ADDED_AT',
    property: 'activity.latestProfileAddedAt',
    defaultOrder: ESortOrder.DESC
  }
};

export const ECultureFitDimension = {
  cfStability: { key: 'cfStability', formName: 'cultureFitStability' },
  cfPerformance: { key: 'cfPerformance', formName: 'cultureFitPerformance' },
  cfIndividuality: { key: 'cfIndividuality', formName: 'cultureFitIndividuality' },
  cfIndependence: { key: 'cfIndependence', formName: 'cultureFitIndependence' },
  cfOpenMindedness: { key: 'cfOpenMindedness', formName: 'cultureFitOpenMindedness' },
  cfChaos: { key: 'cfChaos', formName: 'cultureFitChaos' },
  cfStatus: { key: 'cfStatus', formName: 'cultureFitStatus' }
};

export const CULTURE_FIT_DIMENSIONS = values(ECultureFitDimension);

const companyProfileEmployeeSizes = [
  'S_1_10',
  'S_11_50',
  'S_51_100',
  'S_101_250',
  'S_251_500',
  'S_501_1000',
  'S_1001_2000',
  'S_2000_PLUS'
];

export const ECompanyProfileEmployeeSize = reduce(
  companyProfileEmployeeSizes,
  (res, s) => {
    const key = s;
    const value = s.replace('S_', '').replace('_PLUS', '+').replace('_', '-');
    res[key] = {
      key,
      value,
      label: value
    };
    return res;
  },
  {}
);

export const ECompanyType = {
  TEAM: { key: 'TEAM' },
  STARTUP: { key: 'STARTUP' },
  SME: { key: 'SME' },
  FAMILY_OFFICE: { key: 'FAMILY_OFFICE' },
  ENTERPRISE: { key: 'ENTERPRISE' },
  GLOBAL_ENTERPRISE: { key: 'GLOBAL_ENTERPRISE' },
  ASSOCIATION: { key: 'ASSOCIATION' },
  NNO: { key: 'NNO' },
  NGO: { key: 'NGO' },
  GOVERNMENT: { key: 'GOVERNMENT' }
};

const isResponseIn = (r, res) => r && r.status && filter(res, re => re.key === r.status).length > 0;
const isResponseEqual = (r, re) => r && r.status === re.key;
const isResponseRejected = r => isResponseEqual(r, EUserOpportunityPostResponse.REJECTED);
const isParticipantRejected = p => isResponseRejected(p.response) || isResponseRejected(p.companyResponse);
const isParticipantActive = p => _get(p, 'personal.seekerStatus') === ESeekerStatus.ACTIVE.key;
const isParticipantAccepted = p =>
  isResponseIn(p.companyResponse, [EUserOpportunityPostResponse.INTERESTED, EUserOpportunityPostResponse.ACCEPTED]);

export const EOpportunityParticipantStatus = {
  UNPROCESSED: {
    key: 'UNPROCESSED',
    matches: p => p.response && !p.companyResponse,
    order: 0
  },
  WAITING: {
    key: 'WAITING',
    matches: p =>
      ((isResponseEqual(p.response, EUserOpportunityPostResponse.INTERESTED) && !isParticipantActive(p)) ||
        !p.response) &&
      isParticipantAccepted(p),
    order: 1
  },
  CONNECTED: {
    key: 'CONNECTED',
    matches: p =>
      ((isResponseEqual(p.response, EUserOpportunityPostResponse.INTERESTED) && isParticipantActive(p)) ||
        isResponseEqual(p.response, EUserOpportunityPostResponse.ACCEPTED)) &&
      isParticipantAccepted(p),
    order: 2
  },
  REJECTED: {
    key: 'REJECTED',
    matches: p => isParticipantRejected(p),
    order: 3
  }
};

export const OPPORTUNITY_PARTICIPANT_STATUSES = _orderBy(values(EOpportunityParticipantStatus), ['order']);

export const EOpportunityParticipantsSort = {
  NAME: { sort: ['lastName', 'firstName'] },
  FIT_REQUIREMENTS: { sort: ['match.fits.requirements.value'] },
  FIT_PREFERENCES: { sort: ['match.fits.offer.value'] },
  FIT_CULTURE: { sort: ['match.culture.data.fit.value'] },
  RESPONDED: { sort: ['response.timestamp'] }
};

export const EPersonalDetailsExtent = {
  NONE: { key: 'NONE', order: 0 },
  MINIMAL: { key: 'MINIMAL', order: 1 },
  LIGHT: { key: 'LIGHT', order: 2 },
  FULL: { key: 'FULL', order: 3 }
};

export const EGroupType = {
  TEAM: { key: 'TEAM', icon: { type: 'mdi', value: 'account-group-outline' } },
  GROUP: { key: 'GROUP', icon: { type: 'mdi', value: 'vector-combine' } },
  HIRING: { key: 'HIRING', icon: { type: 'mdi', value: 'clipboard-account-outline' } }
};

export const EGroupMembership = {
  MEMBER: { key: 'MEMBER' },
  MANAGER: { key: 'MANAGER' }
};

export const EProjectOverviewPart = {
  WEIGHTS: 'WEIGHTS',
  OVERVIEW_CHART: 'OVERVIEW_CHART',
  BUBBLE_CHART: 'BUBBLE_CHART',
  SCATTER_CHART: 'SCATTER_CHART'
};

export const EBillingPeriod = {
  MONTHLY: { key: 'MONTHLY' },
  ANNUAL: { key: 'ANNUAL' }
};

export const EContractPlanType = {
  TALENT_MARKET: { key: 'TALENT_MARKET' }
};
