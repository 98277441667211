import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { submit } from 'redux-form';
import L from 'lodash';

import SaveIcon from 'material-ui/svg-icons/content/save';
import ArrangeIcon from 'material-ui/svg-icons/image/view-comfy';
import CircularProgress from 'material-ui/CircularProgress';

import NodeSettingsPanel from './NodeSettingsPanel';
import SimulationModelPropertiesForm from '../../../components/forms/SimulationModelPropertiesForm';
import Spinner from '../../../components/spinner/Spinner';
import IconButton from '../../../components/mui/IconButton';
import FlatButton from '../../../components/mui/Button';

import * as simulationModelActions from '../../../actions/entities/simulationModelActions';
import * as simulationDefinitionActions from '../../../actions/entities/simulationDefinitionActions';

import Canvas from './editor/Canvas';
import snap from './editor/snap';

const HEIGHT_SPACING = 48;
const WIDTH_SPACING = 64;

function newId() {
  return Math.random().toString(36).substring(2);
}

function withUpdatedComputedProperties(node, inAddendum, outAddendum, solitaryChild) {
  const computed = node.computed || { in: 0, out: 0, initial: node.level === 0, solitaryChildren: 0 };
  return {
    ...node,
    computed: {
      ...computed,
      in: computed.in + inAddendum,
      out: computed.out + outAddendum,
      solitaryChildren: computed.solitaryChildren + (solitaryChild ? 1 : 0)
    }
  };
}

// does not like siblings
function isSolitaryType(type) {
  return type === 'DIALOGUE_IMPULSE';
}

function isSolitaryNode(node) {
  return isSolitaryType(node.type);
}

function withComputedProperties(nodes, edges) {
  // computed: initial, in, out
  const newNodes = { ...nodes };
  L.forEach(edges, e => {
    const srcNode = newNodes[e.src];
    const destNode = newNodes[e.dest];
    const srcLevel = srcNode.level === null || isNaN(srcNode.level) ? 0 : srcNode.level;
    const destLevel = destNode.level === null || isNaN(destNode.level) ? srcLevel + 1 : destNode.level;
    newNodes[e.src] = withUpdatedComputedProperties({ ...srcNode, level: srcLevel }, 0, 1, isSolitaryNode(destNode));
    newNodes[e.dest] = withUpdatedComputedProperties({ ...destNode, level: destLevel }, 1, 0);
  });
  return L.mapValues(newNodes, n => ({
    ...n,
    computed: n.computed || { in: 0, out: 0, initial: n.level === 0, solitaryChildren: 0 }
  }));
}

function getInitialSize(type) {
  switch (type) {
    case 'DIALOGUE_REPLY':
      return { width: 208, height: 48 };
    default:
      return { width: 208, height: 144 };
  }
}

function getInitialState() {
  const INIT_ID = newId();
  const type = 'DIALOGUE_IMPULSE';
  return {
    nodes: {
      [INIT_ID]: {
        id: INIT_ID,
        level: 0,
        type,
        computed: {
          initial: true,
          in: 0,
          out: 0,
          solitaryChildren: 0
        },
        g: {
          position: {
            top: snap(HEIGHT_SPACING),
            left: snap(32)
          },
          size: getInitialSize(type)
        }
      }
    },
    edges: [],
    linkingNodeId: null,
    title: '',
    toolbarShown: false,
    settingsForNode: null
  };
}

function haveCommonElements(arr1, arr2) {
  return L.intersection(arr1 || [], arr2 || []).length > 0;
}

class SimulationModelEditor extends Component {
  static propTypes = {
    simulationModel: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    //
    createSimulationModel: PropTypes.func.isRequired,
    updateSimulationModel: PropTypes.func.isRequired,
    getSimulationModel: PropTypes.func.isRequired,
    clearSimulationModel: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = getInitialState();
  }

  componentDidMount() {
    const { clearSimulationModel, getSimulationModelMetadata, intl } = this.props;
    getSimulationModelMetadata(intl.locale);
    clearSimulationModel();
    this.loadSimulationModelIfPossible(null, this.props);
  }

  componentWillUpdate(nextProps) {
    const {
      simulationModel: { item }
    } = nextProps;
    this.loadSimulationModelIfPossible(this.props, nextProps);
    if (nextProps.intl.locale !== this.props.intl.locale) {
      nextProps.getSimulationModelMetadata(nextProps.intl.locale);
    }
    if (item !== this.props.simulationModel.item) {
      // set new state
      if (item && item.graph) {
        this.setState({
          nodes: withComputedProperties(item.graph.nodes, item.graph.edges),
          edges: L.map(item.graph.edges, e => ({ ...e, id: newId() })),
          title: item.name
        });
      } else {
        this.setState(getInitialState());
      }
    }
  }

  autoArrange = () => {
    const { edges, nodes } = this.state;
    const edgeMapUnsorted = L.reduce(
      edges,
      (c, { src, dest }) => {
        return {
          ...c,
          [src]: [...(c[src] || []), nodes[dest]]
        };
      },
      {}
    );
    const edgeMap = L.mapValues(edgeMapUnsorted, nodes => L.orderBy(nodes, ['g.position.top']));
    const processed = {};
    const perLevelMaxBottom = {};
    const nextLevelMaxBottom = { '-1': 0 };
    const perLevelLeft = { 0: 0 };
    const destMap = L.reduce(edges, (res, { src, dest }) => ({ ...res, [dest]: [...(res[dest] || []), src] }), {});
    const initialNodes = L.map(
      L.filter(L.keys(nodes), nodeId => {
        const sources = destMap[nodeId];
        if (!sources || sources.length === 0) {
          return true;
        }
        const nodeLevel = nodes[nodeId].level;
        if (nodeLevel === 0) {
          return true;
        }
        return !!L.find(sources, s => nodes[s] && nodes[s].level < nodeLevel);
      }),
      nodeId => ({
        node: nodes[nodeId],
        parentId: null,
        level: 0
      })
    );
    const q = L.orderBy(initialNodes, ['node.level'], ['desc']);
    while (q.length) {
      const { node, parentId, level, prevSibling } = q.pop();
      if (processed[node.id]) {
        continue;
      }
      const children = edgeMap[node.id] || [];
      for (let i = children.length - 1; i >= 0; i -= 1) {
        const c = children[i];
        const prevC = children[i - 1];
        q.push({ node: c, parentId: node.id, level: level + 1, prevSibling: prevC });
      }
      // do the positioning
      const size = getInitialSize(node.type);
      const left = snap(perLevelLeft[level]);
      const siblingsWithCommonChild = prevSibling && haveCommonElements(edgeMap[prevSibling.id], edgeMap[node.id]);
      const bottom = Math.max(
        perLevelMaxBottom[level] || 0,
        (!siblingsWithCommonChild && nextLevelMaxBottom[level]) || 0
      );
      const top = snap(Math.max(bottom + HEIGHT_SPACING, parentId ? processed[parentId].g.position.top : 0));
      if (isNaN(perLevelLeft[level + 1])) {
        perLevelLeft[level + 1] = left + size.width + WIDTH_SPACING;
      }
      const newBottom = top + size.height;
      perLevelMaxBottom[level] = Math.max(perLevelMaxBottom[level] || 0, newBottom);
      nextLevelMaxBottom[level - 1] = Math.max(nextLevelMaxBottom[level - 1] || 0, newBottom);
      processed[node.id] = {
        ...node,
        level: level === 0 ? node.level : level,
        g: {
          ...node.g,
          position: {
            top,
            left
          },
          size
        }
      };
    }
    this.setState({ nodes: { ...nodes, ...processed } });
  };

  setNodeProperty = (nodeId, property, value) => {
    const { nodes } = this.state;
    const node = nodes[nodeId];
    const newNodeProps = {
      [property]: value
    };
    //
    if (property === 'free' || property === 'empty') {
      if (value) {
        newNodeProps._oldText = node.text;
        newNodeProps.text = '';
      } else {
        newNodeProps.text = node._oldText;
      }
    }
    this.setState({
      nodes: { ...nodes, [nodeId]: { ...node, ...newNodeProps } }
    });
  };

  setNodeText = (nodeId, val) => {
    this.setNodeProperty(nodeId, 'text', val);
  };

  setTitle = e => {
    this.setState({
      title: e.target.value
    });
  };

  addNode = (parent, type) => {
    if (parent.computed.solitaryChildren > 0 || (parent.computed.out > 0 && isSolitaryType(type))) {
      // do not add anything
      return;
    }
    const id = newId();
    const { edges, nodes } = this.state;
    const size = getInitialSize(type);
    const level = parent.level + 1;
    const minTop = parent.g.position.top;
    const siblings = L.orderBy(
      L.map(L.keys(L.pickBy(nodes, n => n.level === level)), k => nodes[k]),
      ['g.position.top']
    );
    const minVertSpace = size.height + HEIGHT_SPACING;
    const minHoriSpace = size.width + WIDTH_SPACING;
    const newPos = L.reduce(
      siblings,
      (r, s) => {
        if (
          s.g.position.top - r.top >= minVertSpace ||
          s.g.position.left + s.g.size.width <= r.left ||
          s.g.position.left - r.left >= minHoriSpace
        ) {
          return r;
        }
        return { top: s.g.position.top + s.g.size.height, left: Math.min(r.left, s.g.position.left) };
      },
      { top: minTop - HEIGHT_SPACING, left: parent.g.position.left + parent.g.size.width }
    );
    const newNode = {
      id,
      level,
      g: {
        size,
        position: {
          top: snap(newPos.top + HEIGHT_SPACING),
          left: snap(newPos.left + WIDTH_SPACING)
        }
      },
      computed: {
        in: 1,
        out: 0,
        solitaryChildren: 0
      },
      type
    };
    const newEdge = {
      id: newId(),
      src: parent.id,
      dest: id
    };
    this.setState({
      nodes: {
        ...nodes,
        [parent.id]: {
          ...parent,
          computed: {
            ...parent.computed,
            out: parent.computed.out + 1,
            solitaryChildren: parent.computed.solitaryChildren + (isSolitaryNode(newNode) ? 1 : 0)
          }
        },
        [id]: newNode
      },
      edges: [...edges, newEdge]
    });
  };

  removeNode = node => {
    const { nodes, edges } = this.state;
    const nodeIds = {};
    const edgeIds = {};
    const nq = [node.id];
    while (nq.length) {
      const n = nq.pop();
      nodeIds[n] = n;
      L.forEach(edges, e => {
        if (e.src === n) {
          if (!nodeIds[e.dest] && nodes[e.dest].computed.in === 1 && !nodes[e.dest].computed.initial) {
            nq.push(e.dest);
          }
          edgeIds[e.id] = e.id;
        } else if (e.dest === n) {
          edgeIds[e.id] = e.id;
        }
      });
    }
    const newEdges = L.filter(edges, e => !edgeIds[e.id]);
    const newNodes = L.mapValues(
      L.pickBy(nodes, n => !nodeIds[n.id]),
      n => ({
        ...n,
        computed: { ...n.computed, in: 0, out: 0, solitaryChildren: 0 }
      })
    );
    L.forEach(newEdges, e => {
      newNodes[e.src].computed.out += 1;
      newNodes[e.src].computed.solitaryChildren += isSolitaryNode(newNodes[e.dest]) ? 1 : 0;
      newNodes[e.dest].computed.in += 1;
    });
    const newState = {
      nodes: newNodes,
      edges: newEdges
    };
    // check for one node minimum
    for (var prop in newNodes) {
      if (newNodes.hasOwnProperty(prop)) {
        this.setState(newState);
        return;
      }
    }
  };

  linkStart = node => {
    this.setState({
      linkingNodeId: node.id
    });
  };

  linkFinish = destNode => {
    const { nodes, edges, linkingNodeId } = this.state;
    const oldEdge = L.filter(edges, e => e.src === linkingNodeId)[0];
    if (destNode && oldEdge && destNode.id === oldEdge.dest) {
      // same destination
      this.setState({
        linkingNodeId: null
      });
    } else {
      let newEdges = [...edges];
      const newNodes = { ...nodes };
      const linkingNode = nodes[linkingNodeId];
      if (oldEdge) {
        const oldDest = nodes[oldEdge.dest];
        newNodes[oldDest.id] = { ...oldDest, computed: { ...oldDest.computed, in: oldDest.computed.in - 1 } };
        newEdges = L.filter(newEdges, e => e.id !== oldEdge.id);
      }
      if (destNode) {
        newNodes[linkingNodeId] = {
          ...linkingNode,
          computed: { ...linkingNode.computed, out: 1, solitaryChildren: isSolitaryNode(destNode) ? 1 : 0 }
        };
        newNodes[destNode.id] = { ...destNode, computed: { ...destNode.computed, in: destNode.computed.in + 1 } };
        newEdges = [...newEdges, { id: newId(), src: linkingNodeId, dest: destNode.id }];
      } else {
        newNodes[linkingNodeId] = {
          ...linkingNode,
          computed: { ...linkingNode.computed, out: 0, solitaryChildren: 0 }
        };
      }
      this.setState({
        nodes: newNodes,
        edges: newEdges,
        linkingNodeId: null
      });
    }
  };

  moveElement = (elemId, position) => {
    const { nodes } = this.state;
    const node = nodes[elemId];
    this.setState({
      nodes: {
        ...nodes,
        [elemId]: {
          ...node,
          g: {
            ...node.g,
            position
          }
        }
      }
    });
  };

  submitModel = () => {
    const { submitForm } = this.props;
    submitForm('simulationModelProperties');
  };

  saveModel = values => {
    const {
      createSimulationModel,
      updateSimulationModel,
      simulationModel: { saving, item }
    } = this.props;
    if (saving) {
      return Promise.reject();
    }
    const { nodes, edges, title } = this.state;
    const {
      inputParams,
      company,
      redirectUrl,
      redirectTimeout,
      redirectTop,
      redirectReport,
      lafMode,
      lafAnswerFormat,
      lafTypingDurationEnabled,
      ...rest
    } = values;
    const config = {
      ...rest,
      inputParams:
        inputParams &&
        L.map(inputParams, ip => {
          const { mapsToMetric, metric, metricMinValue, metricMaxValue, metricMappingReversed, ...param } = ip;
          const res = {
            ...param
          };
          if (mapsToMetric) {
            res.metricMapping = {
              metric,
              minValue: metricMinValue,
              maxValue: metricMaxValue,
              reversed: metricMappingReversed
            };
          }
          return res;
        })
    };
    if (redirectUrl || redirectReport) {
      config.redirect = {
        type: redirectReport ? 'REPORT' : null,
        url: redirectReport ? null : redirectUrl,
        timeoutInSeconds: redirectTimeout,
        top: redirectTop
      };
    } else {
      config.redirect = null;
    }
    if (lafMode || lafAnswerFormat || !lafTypingDurationEnabled) {
      config.lookAndFeel = {
        mode: lafMode,
        answerFormat: lafAnswerFormat
      };
      if (!lafTypingDurationEnabled) {
        config.lookAndFeel.typingDuration = { disabled: true };
      }
    } else {
      config.lookAndFeel = null;
    }
    const data = {
      graph: { nodes, edges },
      name: title,
      companyId: company,
      config
    };
    if (item.id) {
      return updateSimulationModel(item.id, data);
    } else {
      return createSimulationModel(data);
    }
  };

  loadSimulationModelIfPossible = (oldProps, newProps) => {
    const {
      params: { modelId },
      getSimulationModel
    } = newProps;
    if (modelId && (!oldProps || !oldProps.params || oldProps.params.modelId !== modelId)) {
      getSimulationModel(modelId);
    }
  };

  toggleToolbar = e => {
    if (!e.isDefaultPrevented()) {
      this.setState({ toolbarShown: !this.state.toolbarShown });
    }
    e.preventDefault();
  };

  showToolbar = () => {
    if (!this.state.toolbarShown) {
      this.setState({ toolbarShown: true });
    }
  };

  nodeSettingsOpen = node => {
    this.setState({
      settingsForNode: node
    });
  };

  nodeSettingsClose = () => {
    this.setState({
      settingsForNode: null
    });
  };

  nodeSettingsConfirm = data => {
    const { nodes, settingsForNode } = this.state;
    const nodeId = settingsForNode.id;
    const node = nodes[nodeId];
    const { multiChoiceEnabled, choiceMin, choiceMax, ...cleanData } = data || {};
    if (multiChoiceEnabled) {
      cleanData.choiceMin = choiceMin;
      cleanData.choiceMax = choiceMax;
    }
    this.setState({
      nodes: {
        ...nodes,
        [nodeId]: {
          ...node,
          data: cleanData
        }
      },
      settingsForNode: null
    });
  };

  render() {
    const {
      simulationModel: { saving, loading, sharing, item },
      intl
    } = this.props;
    const { createSimulationDefinition } = this.props;
    if (loading) {
      return <Spinner show />;
    }
    const { nodes, edges, linkingNodeId, title, toolbarShown, settingsForNode } = this.state;
    const messages = intl.messages.components.pages.private.designer;
    return (
      <DndProvider backend={HTML5Backend}>
        <div style={{ position: 'absolute', top: 106, bottom: 0, right: 0, left: 0 }}>
          <div className={`designer-editor${toolbarShown ? ' designer-editor-toolbar-shown' : ''}`}>
            <div className="designer-toolbar">
              <SimulationModelPropertiesForm
                modelName={title}
                messages={messages.editor.propertiesForm}
                onSubmit={this.saveModel}
                onSubmitFail={this.showToolbar}
              />
              <div className="designer-toolbar-bottom" />
              <div className="designer-toolbar-toggle">
                <IconButton onClick={this.toggleToolbar}>
                  {toolbarShown ? <i className="material-icons">close</i> : <i className="material-icons">settings</i>}
                </IconButton>
              </div>
            </div>
            <div className="designer-title">
              <input type="text" placeholder={messages.editor.namePlaceholder} onChange={this.setTitle} value={title} />
              <div className="designer-title-tools">
                <IconButton
                  onClick={item && item.id ? () => createSimulationDefinition(item.id) : () => {}}
                  disabled={sharing || saving || !item || !item.id}
                >
                  {sharing ? <CircularProgress size={24} /> : <i className="material-icons">share</i>}
                </IconButton>
                <FlatButton
                  onClick={this.autoArrange}
                  label={messages.editor.autoArrangeButton}
                  icon={<ArrangeIcon />}
                />
                <FlatButton
                  onClick={this.submitModel}
                  label={messages.editor.saveButton}
                  disabled={saving}
                  icon={saving ? <CircularProgress size={24} /> : <SaveIcon />}
                />
              </div>
            </div>
            <div className="designer-canvas">
              <Canvas
                nodes={nodes}
                edges={edges}
                linkingNodeId={linkingNodeId}
                moveElement={this.moveElement}
                addNode={this.addNode}
                removeNode={this.removeNode}
                setNodeText={this.setNodeText}
                setNodeProperty={this.setNodeProperty}
                linkStart={this.linkStart}
                linkFinish={this.linkFinish}
                nodeSettingsOpen={this.nodeSettingsOpen}
                messages={messages.canvas}
              />
            </div>
            {settingsForNode && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  zIndex: 400,
                  textAlign: 'center'
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    margin: '0 auto',
                    textAlign: 'left',
                    width: '100%',
                    minWidth: 320,
                    maxWidth: 960
                  }}
                >
                  <NodeSettingsPanel
                    messages={messages.editor.nodeSettingsForm}
                    node={settingsForNode}
                    onCancel={this.nodeSettingsClose}
                    onConfirm={this.nodeSettingsConfirm}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </DndProvider>
    );
  }
}

const mapStateToProps = state => {
  const {
    entities: { simulationModel },
    intl
  } = state;
  return {
    simulationModel,
    intl
  };
};

const actions = {
  ...simulationModelActions,
  ...simulationDefinitionActions,
  submitForm: submit
};

export default withRouter(connect(mapStateToProps, actions)(SimulationModelEditor));
