import React from 'react';
import isArray from 'lodash/isArray';

import SuperSelect from '../SuperSelect';

const createChangeHandler = (fn, multi) => (multi ? fn : val => fn(val.length ? val[0] : null));

const SuperSelectField = props => {
  const {
    input: { value, onChange, onBlur, ...input },
    meta: { touched, error, active },
    multi,
    label,
    indicateRequired,
    ...other
  } = props;
  const valueIsArray = isArray(value);
  const fieldProps = {
    error: touched && error,
    focused: active,
    values: valueIsArray ? value : value ? [value] : [],
    onChange: createChangeHandler(onChange, multi),
    onBlur: createChangeHandler(onBlur, multi),
    label: indicateRequired ? <span>{label} *</span> : label,
    multi,
    ...input,
    ...other
  };
  return <SuperSelect {...fieldProps} />;
};

export default SuperSelectField;
