import { createSelector } from 'reselect';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _values from 'lodash/values';

import { EActivityType, EGroupMembership } from '../../constants/enum';
import { extractNotificationPreferences, get } from '../../utils/utils';
import { getActivityMapKey } from './TeamUtils';
import { getTagsByGroup } from '../../selectors/base';
import { createFromValuesMatcher, getFilterValues, matchesString } from '../../selectors/filterUtils';
import {
  calculateContribution,
  computeCrossComparisonFit,
  getNumericalMetrics,
  getNumericDualMetrics
} from '../../utils/analytics';
import { computeGroupMetricStats } from './TeamInsightsUtils';

const baseSelectGroupMembers = state => state.entities.groupMembers;
const baseSelectGroup = state => state.entities.group.current;
const baseSelectGroupSort = state => state.entities.group.sort;
const baseSelectTargetGroup = state => state.entities.group.target;
const baseSelectGroupMemberFilters = state => state.options.groupMemberFilters;

const fGet = (obj, path) => get(obj, path, []) || [];

const FILTER_VALUES_EXTRACTORS = {
  tags: {
    key: 'tags',
    filterLimit: 20,
    extract: m => {
      const tags = fGet(m, 'tags');
      if (tags.length === 0) {
        return [{ value: 'emptyTag', none: true }];
      }
      return _map(tags, t => ({ value: t.tagId, label: t.name, color: t.color }));
    },
    supportsMatchAll: true
  },
  members: {
    key: 'members',
    filterLimit: 20,
    extract: m => {
      let label;
      const ln = m.lastName || '';
      const fn = m.firstName || '';
      if (ln || fn) {
        label = `${ln}${ln && fn ? ' ' : ''}${fn}`;
      } else {
        label = m.email;
      }
      return [{ value: m.id, label }];
    }
  }
};

const EXTRACTORS_LIST = _values(FILTER_VALUES_EXTRACTORS);

export const enhanceMember = (member, activities, tagsMap, getMemberStats) => {
  const activityMap = _reduce(
    member.activities,
    (res, a) => {
      const mapKey = getActivityMapKey(a);
      res[mapKey] = {
        ...a,
        mapKey,
        notificationPreferences: extractNotificationPreferences(a)
      };
      return res;
    },
    {}
  );
  const getOrInit = (obj, prop, init) => {
    let val = obj[prop];
    if (!val) {
      val = init ? init() : {};
    }
    obj[prop] = val;
    return val;
  };
  const { existing, finished, notifiable, invitable, orderScore, sortProps, lastFinishedTimestamp } = _reduce(
    activities,
    (res, a) => {
      const mapKey = getActivityMapKey(a);
      const memberActivity = activityMap[mapKey];
      if (!memberActivity) {
        res.invitable.push(a);
      } else {
        const stats = getMemberStats ? getMemberStats(a.memberStats || []) : null;
        if (stats?.fit != null) {
          getOrInit(res.sortProps, mapKey).fit = true;
        }
        memberActivity.stats = stats;
        res.existing.push(memberActivity);
        if (memberActivity.finishedAt) {
          if (!res.lastFinishedTimestamp || res.lastFinishedTimestamp < memberActivity.finishedAt) {
            res.lastFinishedTimestamp = memberActivity.finishedAt;
          }
          if (memberActivity.result?.metrics?.scoreTotal) {
            getOrInit(res.sortProps, mapKey).scoreTotal = true;
          }
          res.finished.push(memberActivity);
          res.orderScore += 3;
        } else {
          const { canNotify } = memberActivity.notificationPreferences || {};
          if (canNotify) {
            res.notifiable.push(memberActivity);
          }
          res.orderScore += 1;
        }
      }
      return res;
    },
    {
      existing: [],
      finished: [],
      notifiable: [],
      invitable: [],
      orderScore: 0,
      sortProps: {},
      lastFinishedTimestamp: null
    }
  );
  return {
    ...member,
    activities: existing,
    tags: tagsMap[member.id]?.tags,
    meta: {
      activities: {
        finished,
        notifiable,
        invitable,
        map: activityMap
      },
      lastFinishedTimestamp,
      orderScore,
      sortProps
    }
  };
};

const getMemberItemsAndProps = (items, sort, tagsMap, activities, single) => {
  const arr = items || [];
  const res = single ? (arr.length ? [arr[0]] : []) : arr;
  const { enhancedMembers, sortProps } = _reduce(
    res,
    (res, m, idx) => {
      const item = enhanceMember(m, activities, tagsMap, s => s[idx]);
      res.enhancedMembers.push(item);
      const sortProps = item.meta.sortProps;
      if (sortProps) {
        for (let actKey in sortProps) {
          if (sortProps.hasOwnProperty(actKey)) {
            const prev = res.sortProps[actKey] || {};
            res.sortProps[actKey] = { ...prev, ...sortProps[actKey] };
          }
        }
      }
      return res;
    },
    { enhancedMembers: [], sortProps: {} }
  );
  let sortProperty = m => m.meta.orderScore;
  let sortOrder = sort.order?.toLowerCase() || 'asc';
  if (sort.property === 'lastFinishedAt') {
    sortProperty = m => m.meta.lastFinishedTimestamp;
  } else if (sort.property === 'name') {
    sortProperty = m => (m.lastName && m.firstName ? `${m.lastName} ${m.firstName}` : null);
  } else if (sort.property) {
    const [activityKey, prop] = sort.property.split('#');
    if (activityKey && prop) {
      sortProperty = m => {
        const act = m.meta.activities.map[activityKey] || {};
        if (prop === 'fit') {
          return act?.stats?.fit || -1;
        } else if (prop === 'scoreTotal') {
          return act?.result?.metrics?.scoreTotal?.value || -1;
        }
        return 0;
      };
    }
  }
  return {
    items: _orderBy(enhancedMembers, [sortProperty, m => m.membershipCreatedAt], [sortOrder, 'desc']),
    sortProps
  };
};

const createGetParticipantProps = activityKey => m => {
  const a = _find(m.activities, a => getActivityMapKey(a) === activityKey);
  const metrics = a?.result?.metrics || null;
  const result = metrics ? { metrics } : null;
  return {
    id: m.id,
    participant: m,
    result
  };
};

const buildCultureFitMemberStats = stats =>
  _map(stats, s => {
    if (!s) {
      return null;
    }
    const { fit, fits } = s;
    const sortedFits = [...fits].sort((a, b) => (a.fit > b.fit ? -1 : a.fit < b.fit ? 1 : 0));
    return {
      fit,
      fits: sortedFits
    };
  });

const enhanceActivity = (a, targetGroup, targetGroupStats, members) => {
  // could move some of the logic to enhanceMember
  if (EActivityType.CULTURE_FIT.isLike(a)) {
    const getParticipantProps = createGetParticipantProps(getActivityMapKey(a));
    const sourceParticipants = members.items;
    const targetParticipants = targetGroup.members?.items || [];
    const metrics = getNumericDualMetrics(a.metricDefinitions);
    const fit = computeCrossComparisonFit(metrics, sourceParticipants, targetParticipants, null, getParticipantProps);
    return { ...a, memberStats: buildCultureFitMemberStats(fit.participantMetrics) };
  }
  const getParticipantProps = createGetParticipantProps(getActivityMapKey(a));
  const stats = targetGroupStats[getActivityMapKey(a)];
  const metrics = getNumericalMetrics(a.metricDefinitions);
  const memberStats =
    stats && metrics.length > 0
      ? _map(members.items || [], member => {
          const participantMetrics = getParticipantProps(member).result?.metrics || {};
          const metricStats = _map(metrics, m => {
            const participantMetric = participantMetrics[m.key];
            const metricStat = stats[m.key];
            const contrib =
              participantMetric && metricStat
                ? calculateContribution(participantMetric.value, metricStat.count, metricStat.avg)
                : null;
            return {
              metric: m,
              contrib
            };
          }).sort((a, b) => (a.contrib > b.contrib ? -1 : a.contrib < b.contrib ? 1 : 0));
          return { metricStats };
        })
      : null;
  return { ...a, memberStats };
};

const enhanceMembers = (members, type, tagsMap = {}, groupSort = {}, targetGroup = null) => {
  const memberActivities = members.payload?.configuration?.activities || [];
  const sort = (groupSort && groupSort[type.key]) || {};
  const single = type === EGroupMembership.MANAGER;
  let enhancedMemberActivities = memberActivities;
  if (targetGroup) {
    const targetGroupStats = computeGroupMetricStats(memberActivities, targetGroup.members?.items || []);
    enhancedMemberActivities = _map(memberActivities, a => enhanceActivity(a, targetGroup, targetGroupStats, members));
  }
  return {
    ...members,
    ...getMemberItemsAndProps(members.items, sort, tagsMap, enhancedMemberActivities, single)
  };
};

export const selectTargetGroup = createSelector(baseSelectTargetGroup, g => {
  if (!g) {
    return null;
  }
  return {
    ...g,
    members: g.members ? enhanceMembers(g.members, EGroupMembership.MEMBER) : null,
    managers: g.managers ? enhanceMembers(g.managers, EGroupMembership.MANAGER) : null
  };
});

const selectGroupMembers = createSelector(
  [baseSelectGroup, baseSelectGroupSort, baseSelectTargetGroup, baseSelectGroupMembers, getTagsByGroup],
  (group, sort, targetGroup, groupMembers, tagsByGroup) => {
    const tags = tagsByGroup && group && tagsByGroup.groupId === group.id ? tagsByGroup.tags : [];
    const tagsMap = _reduce(
      tags,
      (res, t) => {
        res[t.userId] = t;
        return res;
      },
      {}
    );
    return {
      ...groupMembers,
      members: enhanceMembers(groupMembers.members, EGroupMembership.MEMBER, tagsMap, sort, targetGroup),
      managers: enhanceMembers(groupMembers.managers, EGroupMembership.MANAGER, tagsMap, sort, targetGroup)
    };
  }
);

const matchesFullTextFilter = ({ firstName, lastName, email }, flt) =>
  !flt ||
  matchesString(firstName, flt) ||
  matchesString(lastName, flt) ||
  matchesString(email, flt) ||
  (firstName &&
    lastName &&
    (matchesString(firstName + ' ' + lastName, flt) || matchesString(lastName + ' ' + firstName, flt)));

const fromValuesMatcher = createFromValuesMatcher(FILTER_VALUES_EXTRACTORS);
const createFilter = filter => {
  const { open, fullText, fromValues } = filter;
  if (!open) {
    return i => i;
  }
  const ft = fullText && fullText.toLowerCase();
  const fvEmpty = _isEmpty(fromValues);
  if (fvEmpty && !ft) {
    return i => i;
  }
  return i => {
    const matches = m => matchesFullTextFilter(m, ft) && (fvEmpty || fromValuesMatcher(m, fromValues));
    return _filter(i, m => matches(m, filter));
  };
};

export const selectGroupMembersFilter = createSelector(
  [baseSelectGroupMemberFilters, baseSelectGroup],
  (filters, group) => {
    return (filters && group && filters[group.id]) || {};
  }
);

export const selectFilteredGroupMembers = createSelector(
  [selectGroupMembers, selectGroupMembersFilter],
  (groupMembers, filter) => {
    const filterMembers = createFilter(filter);
    return {
      ...groupMembers,
      members: {
        ...groupMembers.members,
        filtered: filterMembers(groupMembers.members.items)
      },
      managers: {
        ...groupMembers.managers,
        filtered: groupMembers.managers.items
      }
    };
  }
);

export const selectGroupMembersFilterValues = createSelector(
  [selectFilteredGroupMembers, selectGroupMembersFilter],
  (members, filter) => {
    const { fromValues } = filter;
    const allItems = members.members.items || [];
    const filteredItems = members.members.filtered || [];
    return getFilterValues(EXTRACTORS_LIST, allItems, filteredItems, fromValues);
  }
);
