import React, { useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import MetricNode from './MetricNode';

const CHILDREN_OFFSET = 24;
const CHILDREN_OFFSET_PADDING = `${CHILDREN_OFFSET}px`;

const DropIndicator = () => {
  return (
    <div className="relative-container">
      <div className="bg-secondary" style={{ height: '2px', left: 0, top: -1, right: 0, position: 'absolute' }} />
    </div>
  );
};

const DropNode = props => {
  const { parent, node, subTree, type, children, canPositionSet, onPositionSet } = props;
  const ref = useRef(null);
  const [{ dropPosition, dropParentKey, dropSubTree }, setDropProps] = useState({});
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: `METRIC_NODE__${type}`,
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        onPositionSet(item.node, item.subTree, dropSubTree, dropPosition, dropParentKey);
      }
    },
    canDrop: item => {
      const n = item.node;
      return n !== node && canPositionSet(n, dropParentKey);
    },
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      // Don't replace items with themselves
      if (!item || item.node === node) {
        return;
      }
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverChildBoundaryX = 2 * CHILDREN_OFFSET;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      let dropPosition;
      let dropParentKey = parent?.key;
      let dropSubTree = subTree;
      if (hoverClientY < hoverMiddleY) {
        dropPosition = node.displayOrder;
      } else {
        if (hoverClientX <= hoverChildBoundaryX || !canPositionSet(item.node, node.key)) {
          dropPosition = node.displayOrder + 1;
        } else {
          dropPosition = 0;
          dropSubTree = node.children;
          dropParentKey = node.key;
        }
      }
      setDropProps({
        dropPosition,
        dropParentKey,
        dropSubTree
      });
    },
    collect: monitor => {
      const item = monitor.getItem();
      return {
        isOver: monitor.isOver({ shallow: item && canPositionSet(item.node, node.key) }),
        canDrop: monitor.canDrop()
      };
    }
  });

  drop(ref);
  const dropAsChild = dropParentKey === node.key;
  return (
    <div ref={ref} className="metric-node-item__container">
      {isOver && canDrop && !dropAsChild && dropPosition === node.displayOrder && <DropIndicator />}
      {children}
      {isOver && canDrop && !dropAsChild && dropPosition === node.displayOrder + 1 && <DropIndicator />}
      {isOver && canDrop && dropAsChild && (
        <div style={{ paddingLeft: CHILDREN_OFFSET_PADDING }}>
          <DropIndicator />
        </div>
      )}
    </div>
  );
};

const MetricNodeList = props => {
  const { parent, type, list, disabled, onDisabledSet, onPositionSet, canPositionSet, ...rest } = props;
  const shownItems = rest.modelEditing ? list : _filter(list, item => !disabled[item.key]);
  return (
    <div style={{ paddingLeft: parent ? CHILDREN_OFFSET_PADDING : '0' }} className="metric-node-list">
      {_map(shownItems || [], item => (
        <DropNode
          key={item.key}
          parent={parent}
          node={item}
          subTree={list}
          onPositionSet={onPositionSet}
          canPositionSet={canPositionSet}
          type={type}
        >
          <MetricNode
            {...rest}
            parent={parent}
            node={item}
            subTree={list}
            disabled={disabled}
            onDisabledSet={onDisabledSet}
            onPositionSet={onPositionSet}
            canPositionSet={canPositionSet}
            type={type}
          />
        </DropNode>
      ))}
    </div>
  );
};

export default MetricNodeList;
