import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class DialogueTextEditor extends Component {
  constructor(props) {
    super(props);
    const contentBlock = htmlToDraft(props.value || '');
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    this.state = {
      editorState,
      value: props.value
    };
  }

  onEditorStateChange = editorState => {
    const { onChange } = this.props;
    this.setState({
      editorState
    });
    if (onChange) {
      const { value } = this.state;
      const newValue = editorState ? draftToHtml(convertToRaw(editorState.getCurrentContent())) : null;
      if (value !== newValue) {
        this.setState({ value: newValue });
        onChange(newValue);
      }
    }
  };

  render() {
    const { editorState } = this.state;
    const { handleFocus, handleBlur, expanded } = this.props;
    return (
      <div>
        <Editor
          toolbarHidden={!expanded}
          wrapperClassName={`text-editor text-editor-${expanded ? 'expanded' : 'collapsed'}`}
          toolbar={{
            options: ['inline', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'],
            inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'] },
            list: { options: ['unordered', 'ordered'] }
          }}
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          stripPastedStyles
        />
      </div>
    );
  }
}

export default DialogueTextEditor;
