import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import VisSensor from 'react-visibility-sensor';

import Checkbox from 'material-ui/Checkbox';

import Tag from './Tag';
import TagEditor, { MIN_NAME_LENGTH } from './TagEditor';
import TagRemovalConfirm from './TagRemovalConfirm';
import Button from '../mui/Button';
import IconButton from '../mui/IconButton';
import SearchField from '../mui/DebouncedSearchField';
import Spinner from '../spinner/Spinner';

import { getRandomColor } from '../color/ColorPicker';
import { determineKeyCode, KC } from '../superfield/Utils';

import * as Api from '../../api/entities/tagApi';
import { formatMessage } from '../../utils/utils';

import withIntl from '../withIntl';

class TagAssignmentPanel extends React.Component {
  state = {
    search: '',
    tags: [],
    activeTagsMap: {}
  };

  componentDidMount() {
    this.load();
    this.updateActiveTags();
  }

  componentDidUpdate(prevProps) {
    const { companyId, activeTags } = this.props;
    if (companyId !== prevProps.companyId) {
      this.load();
    }
    if (activeTags !== prevProps.activeTags) {
      this.updateActiveTags();
    }
  }

  orderTags = tags => tags; // no sort for now _orderBy(tags, i => !this.state.activeTagsMap[i.id]);

  load = () => {
    const { companyId } = this.props;
    const { search } = this.state;
    if (!companyId) {
      return;
    }
    this.setState({ loading: true });
    Api.getTags(companyId, search)
      .then(res => this.setState({ tags: this.orderTags(res.items), page: res.page }))
      .finally(() => this.setState({ loading: false }));
  };
  loadMore = () => {
    const { companyId } = this.props;
    const { page, search } = this.state;
    const { size, number, totalPages } = page || {};
    if (number < totalPages - 1) {
      Api.getTags(companyId, search, { page: number + 1, size }).then(res =>
        this.setState({ tags: this.orderTags([...this.state.tags, ...res.items]), page: res.page })
      );
    }
  };

  updateActiveTags = () => {
    const { activeTags } = this.props;
    const activeTagsMap = _reduce(
      activeTags,
      (res, t) => {
        res[t.tagId || t.id] = t;
        return res;
      },
      {}
    );
    this.setState({ activeTagsMap });
  };

  handleChangeSearch = search => {
    this.setState({ search }, this.load);
  };

  handleEditingStart = t => {
    this.setState({ editing: t });
  };
  saveTag = t => {
    const { companyId, onTagSaved } = this.props;
    return Api.saveTag(companyId, t).then(({ payload }) => {
      if (onTagSaved) {
        onTagSaved(t);
      }
      if (!t.id) {
        this.handleTagAssigned(payload, true);
      }
      this.handleChangeSearch('');
    });
  };
  handleEditingNewStart = () => {
    const newTag = { name: this.state.search || '', color: getRandomColor() };
    if (newTag.name.length >= MIN_NAME_LENGTH) {
      this.saveTag(newTag);
    } else {
      this.handleEditingStart(newTag);
      this.handleChangeSearch('');
    }
  };
  handleEditingConfirm = t => {
    this.handleEditingCancel();
    this.saveTag(t);
  };
  handleEditingCancel = () => this.setState({ editing: null });
  handleTagAssigned = (tag, enabled) => {
    const { onTagAssigned } = this.props;
    const { activeTagsMap: { [tag.id]: present, ...activeTagsMap } } = this.state;
    if (enabled) {
      activeTagsMap[tag.id] = tag;
    }
    this.setState({ activeTagsMap });
    if (onTagAssigned) {
      onTagAssigned(tag, enabled);
    }
  };
  handleRemovingStart = tag => this.setState({ removing: tag });
  handleRemovingCancel = () => this.setState({ removing: null });
  handleRemovingConfirm = () => {
    const tag = this.state.removing;
    const { companyId, onTagSaved } = this.props;
    Api.deleteTag(companyId, tag).then(() => {
      if (onTagSaved) {
        onTagSaved(tag, { removed: true });
      }
      this.load();
    });
    this.handleRemovingCancel();
  };
  handleSearchKeyDown = e => {
    const kc = determineKeyCode(e);
    if (kc === KC.ENTER) {
      this.handleEditingNewStart();
    } else if (kc === KC.ESC) {
      const { onClose } = this.props;
      if (onClose) {
        onClose();
      }
    }
  };

  render() {
    const { intl: { messages } } = this.props;
    const { loading, editing, removing, tags, search, activeTagsMap, page } = this.state;
    const trimmedSearch = search && search.trim();
    return (
      <div>
        {editing || removing ? (
          <div>
            {editing && (
              <TagEditor
                key={editing.id || 'new'}
                {...editing}
                onSave={this.handleEditingConfirm}
                onCancel={this.handleEditingCancel}
              />
            )}
            {removing && (
              <TagRemovalConfirm
                tag={removing}
                onCancel={this.handleRemovingCancel}
                onConfirm={this.handleRemovingConfirm}
              />
            )}
          </div>
        ) : (
          <div>
            <SearchField
              compact
              value={search}
              onValueChange={this.handleChangeSearch}
              debounce={300}
              placeholder={messages.searchPlaceholder}
              onKeyDown={this.handleSearchKeyDown}
            />
            <div
              ref={el => (this.scrollContainer = el)}
              className="mui-padded border-top border-bottom border-color-primary__50"
              style={{ maxHeight: '176px', overflow: 'auto' }}
            >
              <div>
                {_map(tags, t => {
                  const assigned = !!activeTagsMap[t.id];
                  return (
                    <div
                      key={t.id}
                      className="container-flex-row fw-yes ai-center show-on-hover-parent mui-padded-vertical-half"
                    >
                      <div className="flex1">
                        <Checkbox
                          checked={assigned}
                          iconStyle={assigned ? {} : { fill: '#757575' }}
                          label={<Tag {...t} />}
                          onCheck={() => this.handleTagAssigned(t, !assigned)}
                          labelStyle={{ margin: '2px 0', lineHeight: '1em' }}
                        />
                      </div>
                      <IconButton
                        className="show-on-hover"
                        onClick={() => this.handleRemovingStart(t)}
                        style={{ lineHeight: '24px', height: '24px', width: '24px' }}
                        tooltip={messages.deleteTooltip}
                      >
                        <i className="material-icons text-error" style={{ fontSize: '16px' }}>
                          delete_forever
                        </i>
                      </IconButton>
                      <IconButton
                        className="show-on-hover"
                        onClick={() => this.handleEditingStart(t)}
                        style={{ lineHeight: '24px', height: '24px', width: '24px' }}
                        tooltip={messages.editTooltip}
                      >
                        <i className="material-icons text-muted" style={{ fontSize: '16px' }}>
                          edit
                        </i>
                      </IconButton>
                    </div>
                  );
                })}
                {page && page.number < page.totalPages - 1 && (
                  <VisSensor
                    key={`${page.number}-${search}`}
                    containment={this.scrollContainer}
                    onChange={visible => visible && this.loadMore()}
                  >
                    <div style={{ minHeight: '1px', minWidth: '1px', marginTop: '-1px', display: 'inline-block' }} />
                  </VisSensor>
                )}
                {tags.length === 0 && (
                  <div className="text-muted">
                    <em className="small">{messages.noTagsMessage}</em>
                  </div>
                )}
              </div>
            </div>
            {loading && (
              <div className="mui-padded border-bottom border-color-primary__50">
                <Spinner size={16} stroke={2} relative show />
              </div>
            )}
            <div className="mui-padded-vertical">
              <Button
                label={
                  <span style={{ textTransform: 'none' }}>
                    {formatMessage(messages.addButtonLabel, [
                      trimmedSearch && trimmedSearch.length >= MIN_NAME_LENGTH ? ` "${trimmedSearch}"` : ''
                    ])}
                  </span>
                }
                icon={
                  <i className="material-icons" style={{ fontSize: '20px' }}>
                    add
                  </i>
                }
                onClick={this.handleEditingNewStart}
                primary
                labelStyle={{ fontSize: '12px' }}
                style={{ minWidth: '100%', textAlign: 'left' }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

TagAssignmentPanel.propTypes = {
  companyId: PropTypes.string,
  activeTags: PropTypes.array,
  onTagSaved: PropTypes.func,
  onTagAssigned: PropTypes.func,
  onClose: PropTypes.func
};

const intlMessages = {
  cs: {
    addButtonLabel: 'PŘIDAT{0}',
    searchPlaceholder: 'Hledat',
    noTagsMessage: 'Žádné tagy nenalezeny',
    deleteTooltip: 'Odstranit',
    editTooltip: 'Upravit'
  },
  sk: {
    addButtonLabel: 'PRIDAŤ{0}',
    searchPlaceholder: 'Hľadať',
    noTagsMessage: 'Žiadne tagy nenájdené',
    deleteTooltip: 'Odstrániť',
    editTooltip: 'Upraviť'
  },
  en: {
    addButtonLabel: 'ADD{0}',
    searchPlaceholder: 'Search',
    noTagsMessage: 'No tags found',
    deleteTooltip: 'Remove',
    editTooltip: 'Edit'
  }
};

export default withIntl(TagAssignmentPanel, intlMessages);
