import React from 'react';

const IconChip = ({ size = 32, icon, text, children, style, variant = 'normal', className, ...props }) => {
  const halfSize = size / 2;
  const fontSize = Math.max(Math.floor((size - 4) / 2), 10);
  const padding = (size - 8) / 2;
  const iconSize = Math.max(Math.min(size, 18), size - 8);
  const iconMargin = Math.max(0, (size - iconSize)) / 2;
  return (
    <div
      className={`mui-icon-chip ${variant}${className ? ` ${className}` : ''}`}
      style={{
        borderRadius: `${halfSize}px`,
        ...style
      }}
      {...props}
    >
      {icon && (
        <div
          className="mui-icon-chip-icon"
          style={{
            width: iconSize,
            height: iconSize,
            lineHeight: `${iconSize}px`,
            margin: iconMargin
          }}
        >
          <i
            className="material-icons"
            style={{ lineHeight: `${iconSize}px`, fontSize: `${iconSize * 0.6}px`, width: `${iconSize * 0.6}px` }}
          >
            {icon}
          </i>
        </div>
      )}
      <span
        className="mui-icon-text"
        style={{
          lineHeight: `${size}px`,
          padding: `0 ${padding}px 0 ${padding * (icon ? 2 / 3 : 1) - (icon ? iconMargin : 0)}px`,
          fontSize
        }}
      >
        {text || children}
      </span>
    </div>
  );
};

export default IconChip;
