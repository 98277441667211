import React from 'react';

import SortSelect from './SortSelect';
import ActivityLabel from '../activity/ActivityLabel';

import { getActivityMapKey } from './TeamUtils';

import withIntl from '../withIntl';

const TeamSubGroupSortSelect = props => {
  const {
    activities,
    sortProps,
    property,
    order,
    onChange,
    intl: { messages }
  } = props;
  const dataSource = [
    { value: 'default', label: messages.dataMessages.default.label },
    { value: 'lastFinishedAt', label: messages.dataMessages.lastFinishedAt.label },
    { value: 'name', label: messages.dataMessages.name.label }
  ];
  for (let i = 0; i < activities.length; i += 1) {
    const a = activities[i];
    const mapKey = getActivityMapKey(a);
    const asp = sortProps[mapKey];
    if (asp) {
      for (let prop in asp) {
        if (asp.hasOwnProperty(prop)) {
          const label = messages.dataMessages[prop]?.label || prop;
          dataSource.push({
            value: `${mapKey}#${prop}`,
            label,
            description: <ActivityLabel activity={a} withoutTooltip />
          });
        }
      }
    }
  }
  return <SortSelect dataSource={dataSource} property={property} order={order} onChange={onChange} />;
};

const intlMessages = {
  cs: {
    dataMessages: {
      default: { label: 'Výchozí' },
      lastFinishedAt: { label: 'Poslední aktivita' },
      name: { label: 'Jméno' },
      fit: { label: 'Kulturní fit' },
      scoreTotal: { label: 'Celkové skóre' }
    }
  },
  sk: {
    dataMessages: {
      default: { label: 'Východzie' },
      lastFinishedAt: { label: 'Posledná aktivita' },
      name: { label: 'Meno' },
      fit: { label: 'Kultúrny fit' },
      scoreTotal: { label: 'Celkové skóre' }
    }
  },
  en: {
    dataMessages: {
      default: { label: 'Default' },
      lastFinishedAt: { label: 'Last activity' },
      name: { label: 'Name' },
      fit: { label: 'Culture fit' },
      scoreTotal: { label: 'Total score' }
    }
  }
};

export default withIntl(TeamSubGroupSortSelect, intlMessages);
