import React from 'react';

import MetricIcon from '../icons/Metric';

const IconPanel = props => {
  const { data, vertical } = props;
  const icons = [];
  const parents = [];
  for (let i = 0; i < data.length; i += 1) {
    const { metric, parent, firstChild, opposite } = data[i];
    icons.push(
      <div
        key={`${metric.key}${opposite ? '_opposite' : ''}`}
        className="text-center inline-container"
        style={{ [vertical ? 'height' : 'width']: `${100 / data.length}%`, verticalAlign: vertical ? 'middle' : 'top' }}
      >
        <MetricIcon
          metric={metric}
          color={metric.inactive ? 'rgba(0, 0, 0, 0.16)' : 'rgba(0, 0, 0, 0.32)'}
          opposite={opposite}
        />
      </div>
    );
    if (!vertical && parent && firstChild) {
      const childrenCount = parent.childrenCount || 1;
      parents.push(
        <div
          key={parent.metric.key}
          className="text-center text-muted inline-container"
          style={{ width: `${(100 * childrenCount) / data.length}%`, fontSize: '12px', verticalAlign: 'top' }}
        >
          <div
            style={{
              height: '4px',
              borderRadius: '2px',
              background: parent.color || 'rgba(0,0,0,0.38)',
              margin: '0 4px 4px'
            }}
          />
          {parent.label}
        </div>
      );
    }
  }
  return (
    <div
      className={vertical ? 'container-flex-row' : 'container-flex-column'}
      style={{ [vertical ? 'height' : 'width']: '100%' }}
    >
      <div className={vertical ? 'container-flex-column flex1' : 'container-flex-row'}>{icons}</div>
      {!vertical && parents.length > 0 && <div style={{ marginTop: '12px' }}>{parents}</div>}
    </div>
  );
};

export default IconPanel;
