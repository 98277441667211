import React from 'react';

import Paper from 'material-ui/Paper';

import NodeSettingsForm from './NodeSettingsForm';

export default (props) => (
  <Paper zDepth={1} style={{ borderRadius: '0 0 2px 2px', maxHeight: '100%', overflow: 'auto' }}>
    <NodeSettingsForm {...props} />
  </Paper>
);
