import { createSelector } from 'reselect';
import _ from 'lodash';

import { getCurrentProject } from './index';
import { getApplicants, getCrossComparison } from './base';
import { getFilteredAndEvaluatedParticipants } from './applicantList';
import { extractWeightValue, extractMetric } from '../utils/utils';
import { computeCrossComparisonFit, getNumericalMetrics, getNumericDualMetrics } from '../utils/analytics';
import { BRAND } from '../constants/constants';

const baseSelectMetricsConfig = state => state.options.metricsConfig;
const getSkillScatterChartFromState = state => state.options.skillScatterChart;

export const selectScoreMetric = createSelector([getApplicants], ({ metricDefinitions: { scoreTotal } }) => {
  return scoreTotal ? { key: 'scoreTotal', ...scoreTotal } : null;
});

export const selectScoreMappingMetrics = createSelector([getApplicants], ({ metricDefinitions }) => {
  const metrics = _.pickBy(metricDefinitions, md => md.type === 'NUMERIC' && !md.parent && !isNaN(md.displayOrder));
  return _.orderBy(_.map(_.keys(metrics), key => ({ key, ...metrics[key] })), ['displayOrder']);
});

export const selectNumericalMetrics = createSelector([getApplicants], ({ metricDefinitions }) =>
  getNumericalMetrics(metricDefinitions)
);

const selectNumericDualMetrics = createSelector([getApplicants], ({ metricDefinitions }) =>
  getNumericDualMetrics(metricDefinitions)
);

const buildScoreMapping = (scoreMapping, mappingMetrics) => {
  return _.reduce(
    mappingMetrics,
    (res, metric) => ({
      ...res,
      [metric.key]: { ...scoreMapping[metric.key], value: extractWeightValue(scoreMapping, metric.key) }
    }),
    {}
  );
};

export const selectScoreMapping = createSelector(
  [baseSelectMetricsConfig, getCurrentProject, selectScoreMappingMetrics],
  (localMetricsConfig, project, mappingMetrics) => {
    const projectMetricsConfig = project && localMetricsConfig[project.id];
    const scoreMapping = project
      ? (projectMetricsConfig && projectMetricsConfig.scoreMapping) || project.analytics.scoreMapping || {}
      : {};
    return buildScoreMapping(scoreMapping, mappingMetrics);
  }
);

export const selectMetricsConfig = createSelector(
  [baseSelectMetricsConfig, getCurrentProject, selectScoreMappingMetrics],
  (localMetricsConfig, project, mappingMetrics) => {
    if (!project) {
      return {};
    }
    const projectMetricsConfig = localMetricsConfig[project.id] || {};
    const { analytics } = project;
    const { scoreMapping, metricsConfig } = analytics || {};
    const { totalScoreEnabled, core, disabled, definitions } = metricsConfig || {};
    return {
      totalScoreEnabled:
        projectMetricsConfig.totalScoreEnabled != undefined
          ? projectMetricsConfig.totalScoreEnabled
          : totalScoreEnabled,
      coreMetrics: projectMetricsConfig.coreMetrics || core,
      scoreMapping: buildScoreMapping(projectMetricsConfig.scoreMapping || scoreMapping || {}, mappingMetrics),
      disabledMetrics: projectMetricsConfig.disabledMetrics || disabled,
      definitions: projectMetricsConfig.definitions || definitions
    };
  }
);

const getCompetencyClassValuesFromWeights = (scoreMappingMetrics, scoreMapping) => {
  const list = _.orderBy(
    _.map(scoreMappingMetrics, se => ({ ...se, weight: extractWeightValue(scoreMapping, se.key) })),
    ['weight'],
    ['desc']
  );
  const res = {};
  for (let i = 0; i < list.length; i += 1) {
    const { key } = list[i];
    res[key] = i < Math.floor(list.length / 2) ? 1 : 2;
  }
  return res;
};

const calcScore = (item, mappingMetrics) => {
  return _.reduce(
    mappingMetrics,
    (r, m) => {
      const metric = extractMetric(item, m.key) || { value: 0 };
      return {
        sum: r.sum + m.weight * metric.value,
        weightSum: r.weightSum + m.weight
      };
    },
    { sum: 0, weightSum: 0 }
  );
};

const calcScoreValue = s => (s.weightSum ? Math.round(100 * s.sum / s.weightSum) / 100 : 0);

export const selectSkillScatterChartData = createSelector(
  [
    getSkillScatterChartFromState,
    selectScoreMapping,
    selectScoreMappingMetrics,
    getCurrentProject,
    getFilteredAndEvaluatedParticipants
  ],
  (c, scoreMapping, scoreMappingMetrics, p, participants) => {
    const competencyClasses = {
      ...getCompetencyClassValuesFromWeights(scoreMappingMetrics, scoreMapping),
      ...(p && c[p.id] && c[p.id].competencies)
    };
    const metricsWithWeight = _.map(scoreMappingMetrics, m => ({
      ...m,
      weight: extractWeightValue(scoreMapping, m.key)
    }));
    const xMetrics = [];
    const yMetrics = [];
    _.forEach(metricsWithWeight, wm => {
      const classValue = competencyClasses[wm.key];
      if (classValue === 1) {
        xMetrics.push(wm);
      } else if (classValue === 2) {
        yMetrics.push(wm);
      }
    });
    const totalCount = participants.length;
    const dataMap = _.reduce(
      participants,
      (d, p) => {
        const xScore = calcScore(p, xMetrics);
        const yScore = calcScore(p, yMetrics);
        const xVal = calcScoreValue(xScore) * 10;
        const yVal = calcScoreValue(yScore) * 10;
        const key = `${xVal}/${yVal}`;
        const primaryColor = p.crossCompared ? p.crossComparedColor : null;
        const entry = d[key] || {
          x: xVal,
          y: yVal,
          z: 0,
          applicants: [],
          color: primaryColor || BRAND.theme.palette.mainColor
        };
        return {
          ...d,
          [key]: {
            ...entry,
            z: entry.z + 1 / totalCount,
            color: primaryColor || entry.color,
            applicants: [...entry.applicants, p]
          }
        };
      },
      {}
    );
    const data = _.values(dataMap);
    return {
      scoreMappingMetrics,
      competencyClasses,
      data
    };
  }
);

const filterTargetParticipants = (participants, sourceParticipants, projectId) => {
  const spMap = _.keyBy(sourceParticipants, p => p.simulation.id);
  return _.filter(participants, p => p.projectId === projectId && !spMap[p.simulation.id]);
};

const getActiveSourceParticipants = (sourceParticipants, activeIndex) => {
  const source = sourceParticipants || [];
  if (source.length > 0) {
    if (activeIndex != undefined) {
      const activeParticipant = source[activeIndex];
      if (activeParticipant) {
        return [activeParticipant];
      }
    }
    if (source.length === 1) {
      return [source[0]];
    }
  }
  return [];
};

export const selectDualMetricsGroupedData = createSelector(
  [getFilteredAndEvaluatedParticipants, getCurrentProject, selectNumericDualMetrics, getCrossComparison],
  (currentParticipants, currentProject, metrics, crossComparison) => {
    const { participants, targetProject, activeIndex, managerial } = crossComparison;
    const isCurrentCrossComparison = currentProject && targetProject && targetProject.id === currentProject.id;
    const crossComparedParticipants = isCurrentCrossComparison ? participants : [];
    // filter out target participants
    const activeSourceParticipants = managerial
      ? getActiveSourceParticipants(crossComparedParticipants, activeIndex)
      : [];
    const targetParticipants = isCurrentCrossComparison
      ? filterTargetParticipants(currentParticipants, activeSourceParticipants, currentProject.id)
      : currentParticipants;
    return computeCrossComparisonFit(metrics, crossComparedParticipants, targetParticipants, activeIndex);
  }
);
