import React from 'react';

const MatchSection = props => {
  const { icon, title, children } = props;

  return (
    <div className="match-section">
      <div style={{ padding: '12px 8px 8px' }}>
        <div style={{ display: 'inline-block' }}>
          <i className="material-icons match-section-title__icon">{icon}</i>
          <div
            style={{
              display: 'inline-block',
              fontSize: '16px',
              fontWeight: '400',
              verticalAlign: 'middle',
              marginRight: '8px',
              lineHeight: '18px'
            }}
          >
            {title}
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default MatchSection;
