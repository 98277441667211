import React from 'react';

import Button from '../../../components/mui/Button';
import CardActions from '../../../components/mui/CardActions';
import IconButton from '../../../components/mui/IconButton';
import ProjectCardHeader from '../../../components/cards/ProjectCardHeader';
import ProjectActionsIconMenu from '../../../components/iconMenu/ProjectActionsIconMenu';
import Tooltip from '../../../components/tooltip/Tooltip';

import { EProjectState } from '../../../constants/enum';

const ProjectHeader = props => {
  const { project, projectCrudAllowed, intl, auth } = props;
  const {
    getProjectInvitationUrls,
    onOpenFilter,
    onRefresh,
    onActivateProject,
    onDeleteProject,
    onFinishProject,
    onEditProject,
    onCopyProject,
    onExportProject,
    onOpenHighlight
  } = props;
  if (!project) {
    //noinspection JSConstructorReturnsPrimitive
    return null;
  }
  const { validity: { state }, company, slug } = project;
  const invitationEnabled = state === EProjectState.ACTIVE.key && !project.view;
  // TODO move messages
  const messages = intl.messages.components.pages.private.project.applicantList;
  return (
    <div className="project-overview-header row">
      <div className="col-sm-12 col-md-8">
        <ProjectCardHeader project={project} />
      </div>
      <div className="col-sm-12 col-md-4">
        <CardActions>
          {!project.view && (
            <div className="tooltip-element">
              <Button
                label={messages.invite}
                onClick={() => getProjectInvitationUrls(company.slug, slug)}
                disabled={!invitationEnabled}
                primary
              />
              {!invitationEnabled && state && <Tooltip>{messages.inviteTooltip[state]}</Tooltip>}
            </div>
          )}
          {onOpenFilter && (
            <div>
              <IconButton onClick={onOpenFilter} tooltip={messages.searchTooltip}>
                <i className="material-icons">search</i>
              </IconButton>
            </div>
          )}
          {onOpenHighlight && (
            <div>
              <IconButton onClick={onOpenHighlight} tooltip={messages.highlightTooltip}>
                <i className="material-icons">lightbulb_outline</i>
              </IconButton>
            </div>
          )}
          <div>
            <IconButton onClick={onRefresh} tooltip={messages.refreshTooltip}>
              <i className="material-icons">refresh</i>
            </IconButton>
          </div>
          {projectCrudAllowed &&
            !project.demo && (
              <div>
                <ProjectActionsIconMenu
                  showMainAction
                  {...{
                    auth,
                    intl,
                    project,
                    onActivateProject,
                    onDeleteProject,
                    onFinishProject,
                    onEditProject,
                    onCopyProject,
                    onExportProject
                  }}
                />
              </div>
            )}
        </CardActions>
      </div>
    </div>
  );
};

export default ProjectHeader;
