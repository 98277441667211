import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';

import ParticipantsTagsAssignmentOverlay from './ParticipantsTagsAssignmentOverlay';

import { getProjectUserCompanyTags } from '../../actions/entities/tagActions';
import * as Api from '../../api/entities/tagApi';

class ProjectParticipantsTagsAssignmentOverlay extends React.Component {
  handleConfirm = (tags, participants) => {
    const { project, getProjectUserCompanyTags } = this.props;
    const data = { tagIds: _map(tags, t => t.id), userIds: _map(participants, p => p.participant.id) };
    Api.assignUserCompanyTags(project?.company?.id, data).then(() => {
      getProjectUserCompanyTags(project);
    });
  };
  handleTagSaved = () => {
    const { project, getProjectUserCompanyTags } = this.props;
    getProjectUserCompanyTags(project);
  };

  render() {
    const { project, participants, onClose } = this.props;
    return (
      <ParticipantsTagsAssignmentOverlay
        companyId={project?.company?.id}
        participants={participants}
        onConfirm={this.handleConfirm}
        onClose={onClose}
        onTagSaved={this.handleTagSaved}
      />
    );
  }
}

export default connect(null, { getProjectUserCompanyTags })(ProjectParticipantsTagsAssignmentOverlay);
