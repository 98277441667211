import React from 'react';

import IconButton from '../../mui/IconButton';

import withIntl from '../../withIntl';

const CountIndicator = props => {
  const { count } = props;
  return (
    <div
      className="bg-white text-primary"
      style={{
        borderRadius: '8px',
        lineHeight: '11px',
        padding: '3px 5px',
        fontWeight: 500,
        fontSize: '10px'
      }}
    >
      {count}
    </div>
  );
};

const ActivityStatusControls = props => {
  const {
    activity,
    activity: { toInvite, toNotify },
    readOnly,
    onNotify,
    onInvite,
    intl: { messages }
  } = props;
  if (readOnly || (toNotify.members.length === 0 && toInvite.members.length === 0)) {
    return null;
  }
  const activities = [activity];
  return (
    <div className="mui-padded-half text-right">
      {toInvite.members.length > 0 && (
        <div className="mui-padded-half relative-container">
          <IconButton
            primary
            tooltip={messages.inviteAllTooltip}
            onClick={() => onInvite(toInvite.members, activities, activities, true)}
          >
            <i className="material-icons">email</i>
          </IconButton>
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <CountIndicator count={toInvite.members.length} />
          </div>
        </div>
      )}
      {toNotify.members.length > 0 && (
        <div className="mui-padded-half relative-container">
          <IconButton
            primary
            tooltip={messages.remindAllTooltip}
            onClick={() => onNotify(toNotify.members, activities, activities, toNotify.projectMap)}
          >
            <i className="material-icons">notification_important</i>
          </IconButton>
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <CountIndicator count={toNotify.members.length} />
          </div>
        </div>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    remindAllTooltip: 'Připomenout všem',
    inviteAllTooltip: 'Pozvat všechny'
  },
  sk: {
    remindAllTooltip: 'Pripomenúť všetkým',
    inviteAllTooltip: 'Pozvať všetkých'
  },
  en: {
    remindAllTooltip: 'Remind all',
    inviteAllTooltip: 'Invite all'
  }
};

export default withIntl(ActivityStatusControls, intlMessages);
