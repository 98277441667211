import { createSelector } from 'reselect';
import moment from 'moment';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';

import { getCompanyPathParam, getProjectPathParam } from './index';
import { getApplicant, getCrossComparison } from './base';
import { EProfileFeature } from '../constants/enum';
import { BRAND, COLORS } from '../constants/constants';
import { formatDuration, sortDisplayedMetrics } from '../utils/utils';
import { computeCrossComparisonFit } from '../utils/analytics';

const getIntl = state => state.intl;
const getAuth = state => state.auth;

const formatTimestamp = (ts, locale) => [
  moment(ts)
    .locale(locale)
    .format('L'),
  moment(ts)
    .locale(locale)
    .format('LT')
];

const splitByCore = (competencies, coreMetrics) => {
  const { core, other } = reduce(
    competencies,
    (res, c) => {
      if (coreMetrics.indexOf(c.key) >= 0) {
        res.core.push(c);
      } else {
        res.other.push(c);
      }
      return res;
    },
    { core: [], other: [] }
  );
  // if there's no explicit core competency set, treat all as "core"
  return core.length > 0 ? { core, other } : { core: other, other: [] };
};

const extractMetricsResults = (metrics, metricDefinitions, participant, crossComparison) => {
  const { project } = participant;
  const { analytics } = project || {};
  const { metricsConfig } = analytics || {};
  const { core } = metricsConfig || {};
  const allCompetencies = sortDisplayedMetrics(metrics, metricDefinitions, 'NUMERIC');
  const splitCompetencies = splitByCore(allCompetencies, core || []);
  const competencyFactors = mapValues(
    reduce(
      metricDefinitions,
      (res, val, key) => {
        const { parent } = val;
        if (parent) {
          return {
            ...res,
            [parent]: [...(res[parent] || []), { key, ...val, ...metrics[key] }]
          };
        }
        return res;
      },
      {}
    ),
    v => orderBy(v, 'displayOrder')
  );
  const performanceIndicators = sortDisplayedMetrics(metrics, metricDefinitions, 'CATEGORY');
  const dualMetrics = sortDisplayedMetrics(metrics, metricDefinitions, 'NUMERIC_DUAL');

  const { sourceProject, targetParticipants, loading } = crossComparison;
  const isCurrentCrossComparison = project && sourceProject && project.id === sourceProject.id;
  let crossComparisonFit = null;
  if (isCurrentCrossComparison && !loading) {
    crossComparisonFit = computeCrossComparisonFit(dualMetrics, [participant], targetParticipants);
  }

  return {
    competencies: splitCompetencies.core,
    otherCompetencies: splitCompetencies.other,
    competencyFactors,
    performanceIndicators,
    dualMetrics,
    crossComparisonFit,
    crossComparison: isCurrentCrossComparison ? crossComparison : null
  };
};

const extractFinishedActivityProperties = (participant, crossComparison, metricDefinitions, intl) => {
  const { result } = participant;
  const { finishedCount, position, metrics, groupInterpretations } = result;
  const metricsResults = extractMetricsResults(metrics, metricDefinitions, participant, crossComparison);
  const doughnutData = {};
  const score = metrics.scoreTotal;
  if (score) {
    if (position && finishedCount) {
      doughnutData.position = {
        label: position,
        labelOf: finishedCount,
        color: BRAND.theme.palette.primary1Color,
        value: {
          full: finishedCount - position,
          empty: position - 1
        }
      };
    }
    doughnutData.score = {
      label: score.formattedValue,
      color: score.color,
      value: {
        full: Math.round(score.value * 100),
        empty: 100 - Math.round(score.value * 100)
      }
    };
  }
  if (metricsResults.crossComparisonFit) {
    const pm = metricsResults.crossComparisonFit.participantMetrics || [];
    const currentParticipantFit = (pm[0] || {}).fit;
    if (currentParticipantFit != undefined) {
      const fitVal = currentParticipantFit * 100;
      doughnutData.fit = {
        label: `${fitVal.toFixed(0)}%`,
        color: BRAND.theme.palette.accent1Color,
        value: {
          full: fitVal,
          empty: 100 - fitVal
        }
      };
    }
  }
  const tasks = metrics.tasksTotal;
  if (tasks) {
    const skips = metrics.tasksSkipped || { value: 0, color: COLORS.high };
    doughnutData.hints = {
      label: skips.value,
      labelOf: tasks.value,
      color: skips.color,
      value: {
        full: skips.value === 0 ? tasks.value : skips.value,
        empty: skips.value === 0 ? 0 : tasks.value - skips.value
      }
    };
  }
  const projectMessages = intl.messages.components.pages.private.project;
  const messages = projectMessages.applicantDetail;
  const durationTasks = (metrics.durationTasks || {}).value;
  const durationPreparation = (metrics.durationPreparation || {}).value;
  const timeStatsItems = [];
  if (durationTasks) {
    if (durationPreparation) {
      timeStatsItems.push({
        label: messages.durationStats.preparation,
        value: formatDuration(durationPreparation)
      });
      timeStatsItems.push({
        label: messages.durationStats.simulation,
        value: formatDuration(durationTasks)
      });
    }
    timeStatsItems.push({
      label: messages.durationStats.total,
      value: formatDuration((durationPreparation || 0) + durationTasks)
    });
  }
  const interpretations = map(groupInterpretations, gi => ({
    ...gi,
    metrics: map(gi.metrics, m => ({ key: m, ...metricDefinitions[m], ...metrics[m] }))
  }));

  return {
    finished: true,
    timeStatsItems,
    doughnutData,
    metrics,
    interpretations,
    ...metricsResults
  };
};

const extractActivity = (applicant, crossComparison, auth, intl) => {
  if (isEmpty(applicant)) {
    return null;
  }

  const { weighted, applicationStatus, metricDefinitions, userProjectData, project } = applicant;
  const { customParticipantData } = userProjectData || {};
  const { identifier, createdAt, finishedAt } = applicant.simulation;
  const projectName = project.name;
  const projectType = project.type;
  const activityName = project.activity.name;
  const gameId = applicant.simulation.id;
  const projectMessages = intl.messages.components.pages.private.project;
  const messages = projectMessages.applicantDetail;
  const overviewItems = [
    { label: messages.idSim, value: identifier },
    { label: messages.added, value: formatTimestamp(createdAt, intl.locale) },
    { label: messages.finished, value: finishedAt ? formatTimestamp(finishedAt, intl.locale) : 'N/A' },
    { label: messages.exported, value: formatTimestamp(new Date(), intl.locale) }
  ];
  const finishedProps =
    applicant.result && applicant.result.metrics
      ? extractFinishedActivityProperties(applicant, crossComparison, metricDefinitions, intl)
      : null;
  const customParticipantDataItems = filter(
    (customParticipantData && customParticipantData.items) || [],
    i => i.reported
  );
  return {
    overviewItems,
    gameId,
    customParticipantDataItems,
    weighted,
    projectName,
    projectType,
    activityName,
    applicationStatus,
    project,
    ...finishedProps
  };
};

//TODO merge logic with previous method
export const extractActivityV2 = (activity, crossComparison, metricDefinitions, intl) => {
  if (isEmpty(activity)) {
    return null;
  }

  const { result, identifier, createdAt, finishedAt, name, userProjectData, project } = activity;
  const { customParticipantData } = userProjectData || {};
  const projectName = project.name;
  const projectType = project.type;
  const activityName = name;
  const projectMessages = intl.messages.components.pages.private.project;
  const messages = projectMessages.applicantDetail;
  const overviewItems = [
    { label: messages.idSim, value: identifier },
    { label: messages.added, value: formatTimestamp(createdAt, intl.locale) },
    { label: messages.finished, value: finishedAt ? formatTimestamp(finishedAt, intl.locale) : 'N/A' },
    { label: messages.exported, value: formatTimestamp(new Date(), intl.locale) }
  ];
  const finishedProps =
    result && result.metrics
      ? extractFinishedActivityProperties({ result, project }, crossComparison, metricDefinitions, intl)
      : null;
  const customParticipantDataItems = filter(
    (customParticipantData && customParticipantData.items) || [],
    i => i.reported
  );
  return {
    overviewItems,
    customParticipantDataItems,
    projectName,
    projectType,
    activityName,
    project,
    ...finishedProps
  };
};

const extractActivities = (participant, defaultMetricDefinitions, crossComparison, auth, intl) => {
  const { additionalActivities } = participant;
  const { results, projects } = additionalActivities || {};
  const participantResults = (results || {})[participant.id];
  return map(participantResults || [], pr => {
    const pc = projects[pr.projectId];
    const { metricDefinitions } = pc || {};
    const md = metricDefinitions || defaultMetricDefinitions;
    return extractActivity(
      {
        project: pc || participant.project,
        metricDefinitions: md,
        ...pr
      },
      crossComparison,
      auth,
      intl
    );
  });
};

export const getCurrentParticipant = createSelector(
  [getApplicant, getAuth, getIntl, getCompanyPathParam, getProjectPathParam, getCrossComparison],
  (applicant, auth, intl, companyParam, projectParam, crossComparison) => {
    if (isEmpty(applicant)) {
      return null;
    }

    const { evaluationDescription, metricDefinitions } = applicant;
    const { metrics } = applicant.result;
    const { id, firstName, lastName, email, personal, anonymous } = applicant.participant;
    const { locked } = applicant.simulation;
    const participantId = applicant.participant.id;
    const disabledFeatures = auth.isAdmin ? [] : get(applicant, 'project.company.contractPlan.disabledFeatures', []);
    const features = mapValues(EProfileFeature, f => disabledFeatures.indexOf(f) === -1);
    const print = {
      href: `/print/projects/${companyParam}/${projectParam}/participants?id=${encodeURI(participantId)}`,
      enabled: features[EProfileFeature.PROFILE_PRINT]
    };
    const projectMessages = intl.messages.components.pages.private.project;
    const messages = projectMessages.applicantDetail;
    const additionalActivities = extractActivities(applicant, metricDefinitions, crossComparison, auth, intl);
    const mainActivity = extractActivity(applicant, crossComparison, auth, intl);

    // per project
    /*
      overviewItems,
      timeStatsItems,
      gameId,
      doughnutData,
      metrics,
      competencies,
      competencyFactors,
      performanceIndicators,
      dualMetrics,
      customParticipantDataItems,
      interpretations,
      weighted,
      projectName,
      projectType,
      activityName,
      applicationStatus
     */

    // per participant
    /*
      anonymous,
      firstName,
      lastName,
      email,
      personal
     */

    // could be moved, but not yet
    /*
      features,
      evaluationDescription,
      evaluationDescriptionEnabled,
      publicReportUrl,
      locked,
      print
     */

    // common
    /*
      intl,
      messages,
      projectMessages
     */

    return {
      // participant
      userId: id,
      anonymous,
      firstName,
      lastName,
      email,
      personal,
      //,
      activities: [mainActivity, ...additionalActivities],
      ...mainActivity,
      //
      print,
      features,
      evaluationDescription,
      evaluationDescriptionEnabled: auth.canUseEvaluationDescription,
      locked: locked && isEmpty(metrics),
      publicReportUrl: applicant.publicReportUrl,
      //
      intl,
      messages,
      projectMessages
    };
  }
);
