import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';
import map from 'lodash/map';

import { colorWithAlpha } from '../../utils/utils';

const SIZE = 100;

const Doughnut = props => {
  const {
    label,
    labelOf,
    color,
    value: { full, empty },
    children,
    unavailable
  } = props;
  const chartData = [
    { name: 'full', value: full + empty !== 0 ? full : 1, color },
    { name: 'empty', value: empty, color: colorWithAlpha(color, 0.5) }
  ];
  return (
    <div className={'doughnut-container'}>
      {unavailable ? (
        <div className="text-muted text-sz-xl">
          <em>N/A</em>
        </div>
      ) : (
        <div className="doughnut">
          <PieChart width={SIZE} height={SIZE} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <Pie
              data={chartData}
              dataKey={'value'}
              innerRadius={'80%'}
              outerRadius={'100%'}
              startAngle={90}
              endAngle={-270}
              isAnimationActive={false}
            >
              {map(chartData, e => (
                <Cell key={e.name} fill={e.color} stroke="none" />
              ))}
            </Pie>
          </PieChart>
          <div className="doughnut-text">
            <span>{label}</span>
            {labelOf && <small>/{labelOf}</small>}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

Doughnut.propTypes = {
  label: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.any
};

export default Doughnut;
