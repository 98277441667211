import React from 'react';

const CardHeader = ({ image, title, subtitle, children, className, style }) => {
  return (
    <div className={`mui-card-header${className ? ` ${className}` : ''}${image ? ' with-image' : ''}`} style={style}>
      {image && <div className="mui-card-header-image">{image}</div>}
      <div className="mui-card-header-text">
        <div className="mui-card-header-title">{title}</div>
        <div className="mui-card-header-subtitle">{subtitle}</div>
      </div>
      {children}
    </div>
  );
};

export default CardHeader;
