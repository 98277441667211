import React from 'react';

import BaseFilter from './BaseFilter';
import DateRangeSelect from '../mui/DateRangeSelect';
import Toggle from 'material-ui/Toggle';
import FilterBadge from './FilterBadge';

const DateRangeFilter = props => {
  const { expanded, label, description, filter, activeValues } = props;
  const { onToggleExpanded, onRangeChange, onRangeSetEnabled } = props;

  return (
    <BaseFilter
      expanded={expanded}
      label={label}
      filter={filter}
      description={description}
      onToggleExpanded={onToggleExpanded}
      style={{ display: expanded ? 'block' : 'inline-block' }}
    >
      {(expanded || activeValues.enabled) && (
        <div className="container-flex-row ai-center">
          <Toggle
            style={{ height: '24px', width: '48px', verticalAlign: 'top' }}
            toggled={activeValues.enabled}
            onToggle={() => onRangeSetEnabled(filter, !activeValues.enabled)}
          />
          <div style={{ fontSize: '13px', verticalAlign: 'top', marginRight: '12px' }}>
            <FilterBadge all={filter.count} count={filter.countFiltered} />
          </div>
          {expanded && (
            <DateRangeSelect
              from={activeValues.start != undefined ? activeValues.start : filter.startFiltered}
              to={activeValues.end != undefined ? activeValues.end : filter.endFiltered}
              onChange={({ from, to }) =>
                onRangeChange(filter, { start: from.toDate().getTime(), end: to.toDate().getTime() })}
              disabled={!activeValues.enabled}
            />
          )}
        </div>
      )}
    </BaseFilter>
  );
};

export default DateRangeFilter;
