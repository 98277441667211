import { combineReducers } from 'redux';

import { intlReducer } from 'react-intl-redux';
import { responsiveStateReducer } from 'redux-responsive';
import { reducer as formReducer } from 'redux-form';

import apiReducer from './api/apiReducer';
import authReducer from './auth/authReducer';
import entitiesReducer from './entities/_entitiesReducer';
import optionsReducer from './options/_optionsReducer';
import simulationReducer from './simulation/_simulationReducer';

const reducer = combineReducers({
  api: apiReducer,
  auth: authReducer,
  entities: entitiesReducer,
  options: optionsReducer,
  intl: intlReducer,
  ui: responsiveStateReducer,
  form: formReducer,
  simulation: simulationReducer
});

export default reducer;
