import React from 'react';
import PropTypes from 'prop-types';

import StatusCountsChart from '../analytics/StatusCountsChart';

import { EProjectState } from '../../constants/enum';

const ProjectCardSummary = props => {
  const { project: { activity: { statusCounts }, validity: { state } }, style, chartSize, ...rest } = props;
  const projectState = EProjectState[state];
  return (
    <StatusCountsChart
      statusCounts={projectState.hasCounts ? statusCounts : null}
      icon={projectState === EProjectState.SCHEDULED ? 'event' : 'edit'}
      color={projectState.color}
      colorTransparent={projectState.colorTransparent}
      style={style}
      size={chartSize}
      {...rest}
    />
  );
};

ProjectCardSummary.propTypes = {
  project: PropTypes.object.isRequired,
  chartSize: PropTypes.number,
  style: PropTypes.object
};

export default ProjectCardSummary;
