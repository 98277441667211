import React from 'react';
import map from 'lodash/map';

import MetricIcon from '../icons/Metric';
import CardHeader from '../mui/CardHeader';
import LockedPlaceholder from '../placeholder/LockedPlaceholder';
import ExpanderLabel from '../basic/ExpanderLabel';

import { EUpgradeRequestSource } from '../../constants/enum';
import MetricRecommendations from './MetricRecommendations';

const PerformanceIndicator = ({
  performanceIndicator,
  intl,
  operationalizationExpanded,
  toggleOperationalization,
  invokeUpgradeAccountModal
}) => {
  const { interpretation = {}, operationalization } = performanceIndicator;
  const style = { color: performanceIndicator.color };
  const locked = performanceIndicator.rank === 'LOCKED';
  const messages = intl.messages.components.pages.private.project.applicantDetail;

  return (
    <div className="applicant-detail-performance">
      <CardHeader
        title={performanceIndicator.label}
        subtitle={locked ? null : <span style={style}>{interpretation.label}</span>}
        image={<MetricIcon metric={performanceIndicator} size={40} style={style} />}
      />
      <div>
        {locked ? (
          <LockedPlaceholder
            message={messages.lockedIndicatorMessage}
            upgradeAccount={invokeUpgradeAccountModal}
            source={EUpgradeRequestSource.PERFORMANCE_INDICATOR}
          />
        ) : (
          <div>
            <div>
              <p>{interpretation.text}</p>
              {interpretation.bullets &&
                interpretation.bullets.length > 0 && (
                  <ul className={'interpretations'} style={style}>
                    {map(interpretation.bullets, (bullet, idx) => {
                      return (
                        <li key={idx}>
                          <p className={'interpretation'}>{bullet}</p>
                        </li>
                      );
                    })}
                  </ul>
                )}
                <MetricRecommendations {...interpretation.recommendations} />
            </div>
            {operationalization && (
              <div>
                <h4>
                  <ExpanderLabel
                    label={messages.operationalization}
                    expanded={operationalizationExpanded}
                    toggleExpand={toggleOperationalization}
                  />
                </h4>
                {operationalizationExpanded && <p>{operationalization}</p>}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PerformanceIndicator;
