import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import { selectCurrentProjectType } from './ProjectTypePart';
import { selectCurrentCompany, selectCurrentProjectName, selectCurrentTemplate } from './GeneralSettingsPart';

import { formatMessage } from '../../../../utils/utils';
import { EActivityType } from '../../../../constants/enum';

import withIntl from '../../../withIntl';

const ProjectInvitationPreview = props => {
  const {
    type,
    name,
    company,
    template,
    intl: { messages, locale }
  } = props;

  const cElem = company ? <b>{company.name}</b> : <b className="text-muted">[{messages.noCompanyLabel}]</b>;

  const activityName = _get(template || {}, `properties[${locale}].name`);
  const aElem = activityName ? (
    <b key="activityName">{activityName}</b>
  ) : (
    <b key="activityName" className="text-muted">
      [{messages.noActivityLabel}]
    </b>
  );

  const pMessages = messages.EProjectType[type || 'QUESTIONNAIRE'];
  const pElem = formatMessage(pMessages.message, [
    name ? (
      <b key="projectName">{name}</b>
    ) : (
      <b key="projectName" className="text-muted">
        [{pMessages.emptyLabel}]
      </b>
    )
  ]);

  const gameType =
    template && (EActivityType.CULTURE_FIT.isLike(template) || EActivityType.ECHO.key === template.key)
      ? 'QUESTIONNAIRE'
      : 'SIMULATION';
  const gtMessages = messages.EGameType[gameType];

  return (
    <div className="mui-fields-container">
      <div className="mui-fields-label">{messages.label}</div>
      <div className="border border-color-primary__50 mui-card mui-margin-vertical mui-padded-x2">
        <div className="text-center">
          <div className="text-sz-xl">{cElem}</div>
          <div className="text-sz-reg" style={{ fontWeight: 300 }}>
            {formatMessage(messages.message, [gtMessages.message, aElem, pElem])}
          </div>
        </div>
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    label: 'Jak bude vypadat součást pozvánky pro účastníka',
    message: 'Tě zve do {0} {1} {2}',
    noCompanyLabel: 'SPOLEČNOST',
    noActivityLabel: 'NÁZEV AKTIVITY',
    EGameType: {
      SIMULATION: {
        message: 'online simulační hry'
      },
      QUESTIONNAIRE: {
        message: 'online dotazníku'
      }
    },
    EProjectType: {
      POSITION: {
        message: 'v rámci výběrového řízení na pozici {0}',
        emptyLabel: 'NÁZEV POZICE'
      },
      GROUP_ASSESSMENT: {
        message: 'v rámci assessmentu skupiny {0}',
        emptyLabel: 'NÁZEV SKUPINY'
      },
      TALENT_PROGRAM: {
        message: 'v rámci programu hledání talentů {0}',
        emptyLabel: 'NÁZEV TALENTOVÉHO PROGRAMU'
      },
      QUESTIONNAIRE: {
        message: 'v rámci projektu {0}',
        emptyLabel: 'NÁZEV PROJEKTU'
      }
    }
  },
  sk: {
    label: 'Ako bude vyzerať súčasť pozvánky pre účastníka',
    message: 'Ťa pozýva do {0} {1} {2}',
    noCompanyLabel: 'SPOLOČNOSŤ',
    noActivityLabel: 'NÁZOV AKTIVITY',
    EGameType: {
      SIMULATION: {
        message: 'online simulačnej hry'
      },
      QUESTIONNAIRE: {
        message: 'online dotazníka'
      }
    },
    EProjectType: {
      POSITION: {
        message: 'v rámci výberového konania na pozíciu {0}',
        emptyLabel: 'NÁZOV POZÍCIE'
      },
      GROUP_ASSESSMENT: {
        message: 'v rámci assessmentu skupiny {0}',
        emptyLabel: 'NÁZOV SKUPINY'
      },
      TALENT_PROGRAM: {
        message: 'v rámci programu hľadania talentov {0}',
        emptyLabel: 'NÁZOV TALENTOVÉHO PROGRAMU'
      },
      QUESTIONNAIRE: {
        message: 'v rámci projektu {0}',
        emptyLabel: 'NÁZOV PROJEKTU'
      }
    }
  },
  en: {
    label: 'What will the part of the participant invitation look like',
    message: 'Invites you into {1} {0} {2}',
    noCompanyLabel: 'COMPANY',
    noActivityLabel: 'ACTIVITY NAME',
    EGameType: {
      SIMULATION: {
        message: 'simulation game'
      },
      QUESTIONNAIRE: {
        message: 'questionnaire'
      }
    },
    EProjectType: {
      POSITION: {
        message: 'as a part of the assessment for {0} position',
        emptyLabel: 'POSITION NAME'
      },
      GROUP_ASSESSMENT: {
        message: 'as a part of the assessment of {0}',
        emptyLabel: 'GROUP NAME'
      },
      TALENT_PROGRAM: {
        message: 'as a part of the assessment for {0}',
        emptyLabel: 'TALENT PROGRAM NAME'
      },
      QUESTIONNAIRE: {
        message: 'as a part of {0} project',
        emptyLabel: 'PROJECT NAME'
      }
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    type: selectCurrentProjectType(state, ownProps),
    name: selectCurrentProjectName(state, ownProps),
    company: selectCurrentCompany(state, ownProps),
    template: selectCurrentTemplate(state, ownProps)
  };
};

export default withIntl(connect(mapStateToProps)(ProjectInvitationPreview), intlMessages);
