import { createSelector } from 'reselect';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

import { getCurrentProject, selectParticipants } from './index';
import { sortMetrics, getProjectBaseUrl } from '../utils/utils';
import { getNumericalBaseMetrics } from '../utils/analytics';
import { MAX_APPLICANTS_COMPARED, HIGHLIGHT_COLORS } from '../constants/constants';

const basicSelectQueryParams = (state, props) => (props && props.location && props.location.query) || {};

export const selectParticipantsForComparison = createSelector([selectParticipants], participants => {
  return { ...participants, items: filter(participants.items, p => p.result && p.result.metrics) };
});

export const selectComparedParticipants = createSelector(
  [getCurrentProject, selectParticipantsForComparison, basicSelectQueryParams],
  (project = {}, participants, query) => {
    const compared = [];
    const comparable = [];
    const ids = query.ids ? (isArray(query.ids) ? query.ids : [query.ids]) : [];
    const idMap = {};
    forEach(ids, (id, index) => {
      idMap[id] = { index };
    });
    const numericalMetrics = getNumericalBaseMetrics(participants.metricDefinitions);

    forEach(participants.items, p => {
      const pId = p.participant.id;
      const isFromCurrentProject = p.projectId === project.id;
      const pProject = isFromCurrentProject ? project : p.project;
      const identifier = isFromCurrentProject ? pId : `${pId}/${p.projectId}`;
      const isCompared = !!idMap[identifier];
      if (isCompared && compared.length < MAX_APPLICANTS_COMPARED) {
        const cSlug = pProject.company && pProject.company.slug;
        const pSlug = pProject.slug;
        const sortedMetrics = sortMetrics(
          map(numericalMetrics, m => ({
            ...m,
            ...p.result.metrics[m.key]
          }))
        );
        compared.push({
          ...p,
          url: `${getProjectBaseUrl(pProject)}/overview/${cSlug}/${pSlug}/participants/${pId}`,
          result: {
            ...p.result,
            strengths: filter(sortedMetrics, c => c.value >= 0.6),
            weaknesses: filter(sortedMetrics, c => c.value < 0.4)
          },
          originalIndex: idMap[identifier].index,
          crossCompared: true,
          crossComparedColor: HIGHLIGHT_COLORS[compared.length]
        });
      } else {
        comparable.push(p);
      }
    });
    compared.sort((a, b) => a.originalIndex - b.originalIndex);
    return {
      compared,
      comparable,
      project
    };
  }
);
