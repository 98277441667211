import React, { useCallback } from 'react';

import InlineInput from '../basic/InlineInput';
import IconButton from '../mui/IconButton';
import Button from '../mui/Button';
import TeamInsightLinkCopyToClipboard from './TeamInsightLinkCopyToClipboard';

import withIntl from '../withIntl';

const TeamInsightHeader = props => {
  const {
    insight,
    insights,
    starredAt,
    editing,
    name,
    showNameHint,
    onEditStart,
    onEditCancel,
    onInsightSave,
    onStarredToggle,
    onNameChange,
    onNameBlur,
    intl: { messages }
  } = props;

  const handlePrint = useCallback(() => new Promise(resolve => setTimeout(resolve, 100)).then(window.print), []);

  return (
    <div className="container-flex-row fw-yes jc-space-between">
      <div className="mui-padded mui-padded-vertical-x2">
        <span className="text-muted text-fw-light text-sz-lg" style={{ verticalAlign: 'middle' }}>
          {messages.insightLabel}{' '}
          <b>
            <InlineInput
              type="text"
              value={name}
              borderClassName="bg-highlight mui-appear"
              placeholder={messages.namePlaceholder}
              onChange={onNameChange}
              onBlur={onNameBlur}
              helperText={showNameHint && messages.nameHint}
            />
          </b>
        </span>
      </div>
      {starredAt && (
        <div className="mui-padded-vertical visible-print" style={{ paddingTop: '11px' }}>
          <i className="material-icons text-color-star" style={{ paddingTop: '6px' }}>
            star
          </i>
        </div>
      )}
      <div className="mui-padded-vertical hidden-print" style={{ paddingTop: '11px' }}>
        <IconButton onClick={onStarredToggle} tooltip={messages.starTooltip}>
          <i className={`material-icons text-${starredAt ? 'color-star' : 'muted'}`}>
            {starredAt ? 'star' : 'star_outline'}
          </i>
        </IconButton>
        <div
          className="inline-container bg-primary-light mui-margin-horizontal"
          style={{ height: '100%', width: '1px' }}
        />
        {!editing && insight.current && (
          <TeamInsightLinkCopyToClipboard insight={insight.current}>
            <IconButton tooltip={messages.shareTooltip}>
              <i className="material-icons text-muted">link</i>
            </IconButton>
          </TeamInsightLinkCopyToClipboard>
        )}
        <IconButton onClick={handlePrint} tooltip={messages.printTooltip}>
          <i className="material-icons text-muted">print</i>
        </IconButton>
        {editing ? (
          <>
            <Button
              label={messages.saveButton}
              icon={<i className="material-icons">save</i>}
              primary
              onClick={onInsightSave}
              loading={insight.saving}
              disabled={insight.saving}
            />
            {insights.items?.length > 0 && <Button label={messages.cancelButton} onClick={onEditCancel} />}
          </>
        ) : (
          <IconButton onClick={onEditStart} tooltip={messages.editTooltip}>
            <i className="material-icons text-muted">edit</i>
          </IconButton>
        )}
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    namePlaceholder: 'Pojmenujte poznatek...',
    insightLabel: 'Poznatek',
    saveButton: 'Uložit',
    cancelButton: 'Zrušit',
    editTooltip: 'Upravit',
    starTooltip: 'Označit jako oblíbený',
    printTooltip: 'Tisknout',
    shareTooltip: 'Kopírovat odkaz k sdílení',
    nameHint: 'Můžete upravit název'
  },
  sk: {
    namePlaceholder: 'Pomenujte poznatok...',
    insightLabel: 'Poznatok',
    saveButton: 'Uložiť',
    cancelButton: 'Zrušiť',
    editTooltip: 'Upraviť',
    starTooltip: 'Označiť ako obľúbený',
    printTooltip: 'Tlačiť',
    shareTooltip: 'Kopírovať odkaz na zdieľanie',
    nameHint: 'Môžete upraviť názov'
  },
  en: {
    namePlaceholder: 'Name the insight...',
    insightLabel: 'Insight',
    saveButton: 'Save',
    cancelButton: 'Cancel',
    editTooltip: 'Edit',
    starTooltip: 'Mark as favorite',
    printTooltip: 'Print',
    shareTooltip: 'Copy link to share',
    nameHint: 'Click to edit the name'
  }
};

export default withIntl(TeamInsightHeader, intlMessages);
