import React from 'react';
import _map from 'lodash/map';

import Dialog from '../mui/Dialog';
import Button from '../mui/Button';
import ParticipantsSelectPanel from '../applicant/ParticipantsSelectPanel';
import SuperText from '../superfield/SuperText';
import Spinner from '../spinner/Spinner';

import * as Api from '../../api/entities/groupApi';
import { EGroupMembership, EGroupType } from '../../constants/enum';

import withIntl from '../withIntl';

class NewGroupDialog extends React.Component {
  state = {
    name: '',
    selectedMembers: null
  };

  componentDidUpdate(prevProps) {
    if (this.props.members !== prevProps.members) {
      this.setState({ selectedMembers: null });
    }
  }

  handleConfirm = () => {
    const { onConfirm, members, team, company } = this.props;
    const { name, selectedMembers, creating } = this.state;
    if (creating) {
      return;
    }
    const data = team.data || {};
    const {
      [EGroupMembership.MANAGER.key]: managerConfigUnnecessary,
      ...activityConfigurations
    } = data.activityConfigurations;
    const group = { name: name.trim(), company, type: EGroupType.GROUP.key, data: { ...data, activityConfigurations } };
    const actualMembers = selectedMembers || members;
    this.setState({ creating: true });
    Api.createGroup(group)
      .then(res => {
        const memberItems = _map(actualMembers, m => ({ id: m.id }));
        const groupId = res.payload.id;
        return Api.createGroupMembers(groupId, {
          items: memberItems,
          membership: EGroupMembership.MEMBER.key,
          autoInvite: false
        }).then(() => onConfirm(groupId));
      })
      .finally(() => this.setState({ creating: false }));
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  handleMemberSelectionChange = selectedMembers => {
    this.setState({ selectedMembers });
  };

  handleNameChange = e => this.setState({ name: e.target.value });

  render() {
    const {
      open,
      members,
      intl: { messages }
    } = this.props;
    const { name, creating } = this.state;
    return (
      <Dialog
        open={open}
        contentStyle={{ maxWidth: '520px' }}
        actions={[
          <Button label={messages.cancelLabel} onClick={this.handleCancel} />,
          <Button
            primary
            label={messages.confirmLabel}
            onClick={this.handleConfirm}
            disabled={!name.trim() || creating}
            icon={
              creating ? (
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <Spinner size={16} stroke={2} relative show />
                </div>
              ) : undefined
            }
          />
        ]}
      >
        <div>
          <div>{messages.title}</div>
          <SuperText
            autoFocus
            value={name}
            onChange={this.handleNameChange}
            label={name ? messages.groupNameLabel : undefined}
            placeholder={messages.groupNamePlaceholder}
          />
          <div
            className="bg-primary-light border border-color-primary__50 border-radius mui-padded-vertical"
            style={{ maxHeight: '200px', overflow: 'auto', marginTop: '16px' }}
          >
            <ParticipantsSelectPanel
              participants={members}
              getParticipant={m => m}
              onSelectionChange={this.handleMemberSelectionChange}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

const intlMessages = {
  cs: {
    title: 'Vytvořte novou skupinu z vybraných lidí',
    groupNameLabel: 'Název skupiny',
    groupNamePlaceholder: 'Pojmenujte skupinu...',
    cancelLabel: 'Zrušit',
    confirmLabel: 'Vytvořit'
  },
  sk: {
    title: 'Vytvorte skupinu z vybraných ľudí',
    groupNameLabel: 'Názov skupiny',
    groupNamePlaceholder: 'Pomenujte skupinu...',
    cancelLabel: 'Zrušiť',
    confirmLabel: 'Vytvoriť'
  },
  en: {
    title: 'Create a new group from the selected people',
    groupNameLabel: 'Group name',
    groupNamePlaceholder: 'Name the group...',
    cancelLabel: 'Cancel',
    confirmLabel: 'Create'
  }
};

export default withIntl(NewGroupDialog, intlMessages);
