import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';
import map from 'lodash/map';
import join from 'lodash/join';
import flatMap from 'lodash/flatMap';
import keyBy from 'lodash/keyBy';

import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';

import Card from '../../../components/mui/Card';
import RaisedButton from '../../../components/mui/RaisedButton';
import CallToActionCard from '../../../components/mui/CallToActionCard';
import HorizontalScrollPane from '../../../components/basic/HorizontalScrollPane';
import Overlay from '../../../components/basic/Overlay';
import FadedContent from '../../../components/basic/FadedContent';
import Badge from '../../../components/badge/Badge';
import Modal from '../../../components/modals/SimpleModal';
import CompanyProfileDisabledMessage from '../../../components/company/profile/CompanyProfileDisabledMessage';
import Button from '../../../components/mui/Button';
import IconButton from '../../../components/mui/IconButton';

import * as companyActions from '../../../actions/entities/companyActions';
import * as DataSourceApi from '../../../api/entities/dataSourceApi';

import { BRAND } from '../../../constants/constants';
import { loadToken, formatMessage } from '../../../utils/utils';
import { isCompanyProfilePublishDisabled } from '../../../utils/talentMarketPlan';
import Tooltip from '../../../components/basic/Tooltip';

const Section = props => {
  const { children, noBorder } = props;
  return (
    <div style={{ padding: '16px', borderBottom: noBorder ? 'none' : '1px solid rgba(0,0,0,0.05)' }}>{children}</div>
  );
};

const Image = props => {
  const { src, height, width, keepOriginalRatio, contain } = props;
  const ratio = props.ratio || 1;

  if (keepOriginalRatio) {
    return <img src={src} style={{ height, width, borderRadius: '2px' }} />;
  }
  return (
    <div
      style={{
        height,
        width,
        paddingTop: height ? 0 : `${ratio * 100}%`,
        paddingLeft: width ? 0 : `${ratio * 100}%`,
        backgroundImage: `url(${src})`,
        backgroundSize: contain ? 'contain' : 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '2px',
        backgroundPosition: 'center',
        display: 'inline-block'
      }}
    />
  );
};

const MoreMenu = props => {
  const { children } = props;
  return (
    <IconMenu
      iconButtonElement={
        <IconButton style={{ padding: 6, width: 36, height: 36, verticalAlign: 'middle', marginLeft: '8px' }}>
          <i className="material-icons">more_vert</i>
        </IconButton>
      }
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      targetOrigin={{ horizontal: 'right', vertical: 'top' }}
      desktop
    >
      {children}
    </IconMenu>
  );
};

const PreviewButton = props => {
  const { url, ...rest } = props;
  const btnProps = { ...rest };
  if (url) {
    btnProps.containerElement = <a href={url} target="_blank" rel="noopener noreferrer" />;
  }
  return (
    <div>
      <IconButton style={{ padding: 6, width: 36, height: 36 }} {...btnProps}>
        <i className="material-icons">visibility</i>
      </IconButton>
    </div>
  );
};

const ConfirmModal = props => {
  const { intl, action, onCallback } = props;
  const profileMessages = intl.messages.components.pages.private.companyProfile;
  const messages = profileMessages.actions[action] || profileMessages.actions.unknown;
  return (
    <Modal
      open={!!action}
      callback={confirmed => onCallback(confirmed, action)}
      switchModal={() => {}}
      cancelLabel={messages.cancelLabel}
      confirmLabel={messages.confirmLabel}
      message={messages.confirmMessage}
    />
  );
};

const ActionSection = props => {
  const { text, actions, ...rest } = props;
  return (
    <Section {...rest}>
      <div className="container-flex" style={{ flexWrap: 'wrap' }}>
        <div className="flex1 text-muted">
          <div style={{ padding: '8px 16px 8px 0' }}>{text}</div>
        </div>
        <div className="text-right">{actions}</div>
      </div>
    </Section>
  );
};

const flatten = tree => {
  return flatMap(tree, ({ children, ...node }) => {
    if (children && children.length > 0) {
      return [node, ...flatten(children)];
    }
    return [node];
  });
};

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      industries: {}
    };
  }

  componentDidMount() {
    this.loadProfile(this.props);
    this.loadIndustries(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.intl.locale !== this.props.intl.locale) {
      this.loadIndustries(this.props);
    }
    if (prevProps.company !== this.props.company) {
      this.loadProfile(this.props);
    }
  }

  loadProfile = props => {
    const { getCompanyProfile, company } = props;
    if (company) {
      getCompanyProfile(company.id);
    }
  };

  loadIndustries = props => {
    DataSourceApi.getPreferencesDataSources(props.intl.locale).then(res => {
      const industries = keyBy(res.payload.industries, ({ value }) => value);
      const benefits = keyBy(res.payload.benefits, ({ value }) => value);
      const personalDevelopmentAreas = keyBy(flatten(res.payload.personalDevelopmentAreas), ({ value }) => value);
      this.setState({ industries, benefits, personalDevelopmentAreas });
    });
  };

  performCompanyAction = action => {
    action(this.props.company.id);
  };

  openConfirmModal = action => {
    this.setState({ confirmModal: { action } });
  };
  closeConfirmModal = () => this.setState({ confirmModal: null });
  handleConfirmCallback = confirmed => {
    const { confirmModal } = this.state;
    const { action } = confirmModal || {};
    if (confirmed) {
      switch (action) {
        case 'publish': {
          this.performCompanyAction(this.props.publishCompanyProfileDraft);
          break;
        }
        case 'unpublish': {
          this.performCompanyAction(this.props.unpublishCompanyProfileDraft);
          break;
        }
        case 'discard': {
          this.performCompanyAction(this.props.discardCompanyProfileDraft);
          break;
        }
      }
    }
    this.closeConfirmModal();
  };

  handlePublish = () => {
    this.openConfirmModal('publish');
  };

  handleUnpublish = () => {
    this.openConfirmModal('unpublish');
  };

  handleDiscard = () => {
    this.openConfirmModal('discard');
  };

  calendarFormat = date => {
    const { intl } = this.props;
    return moment(date).locale(intl.locale).calendar();
  };

  handlePreview = previewUrl => this.setState({ previewUrl });
  handleCancelPreview = () => this.setState({ previewUrl: null });

  render() {
    const {
      profile: { profile },
      company,
      talentMarketPlan,
      intl
    } = this.props;
    if (!company) {
      return null;
    }
    const profileDisabled = isCompanyProfilePublishDisabled(talentMarketPlan);
    const { draft } = profile || {};
    const messages = intl.messages.components.pages.private.companyProfile;
    const enumMessages = intl.messages.constants.enums;
    if (!profile || (!draft && !profile.updatedAt)) {
      return (
        <div style={{ marginTop: '32px' }}>
          <CallToActionCard
            title={messages.noProfileAvailable.title}
            message={messages.noProfileAvailable.message}
            icon={<span className="mdi mdi-heart text-secondary" style={{ fontSize: '40px', lineHeight: '40px' }} />}
            actionContainer={profileDisabled ? undefined : <Link to={`/sourcing/profile/${company.id}/edit`} />}
            actionLabel={messages.noProfileAvailable.actionButton}
            actionIcon={<i className="material-icons">edit</i>}
            actionDisabled={profileDisabled}
            actionHelperText={profileDisabled && <CompanyProfileDisabledMessage />}
          />
        </div>
      );
    }
    const profileProp = (prop, format) => {
      const val = draft ? draft[prop] : profile[prop];
      if (format && val) {
        return format(val);
      }
      return val || <em className="text-muted">N/A</em>;
    };
    const profilePropBadges = (prop, labelsMap) => {
      return profileProp(prop, items => {
        if (items.length > 0) {
          return map(items, i => {
            const item = labelsMap && labelsMap[i.value];
            if (!item) {
              return null;
            }
            return (
              <Badge
                key={i.value}
                text={item.label}
                color={BRAND.theme.palette.primary1Color}
                style={{ margin: '4px 4px 0 0' }}
              />
            );
          });
        }
        return <em className="text-muted">N/A</em>;
      });
    };
    return (
      <div>
        {(!profile.publishedAt || (draft && draft.updatedAt > profile.publishedAt)) && (
          <ActionSection
            noBorder={!profile.publishedAt}
            text={
              draft
                ? formatMessage(messages.draftUpdated, [this.calendarFormat(draft.updatedAt)])
                : messages.notPublished
            }
            actions={
              <div>
                <Tooltip content={profileDisabled ? <CompanyProfileDisabledMessage /> : null}>
                  <RaisedButton
                    primary
                    label={draft ? messages.actions.publishDraft.label : messages.actions.publish.label}
                    onClick={this.handlePublish}
                    disabled={profileDisabled}
                  />
                </Tooltip>
                {draft && (
                  <MoreMenu>
                    <MenuItem
                      primaryText={messages.actions.preview.label}
                      onClick={() => this.handlePreview(`${profile.previewUrl}?draft=true&token=${loadToken()}`)}
                    />
                    <MenuItem primaryText={messages.actions.discard.label} onClick={this.handleDiscard} />
                    {!profile.publishedAt && (
                      <MenuItem
                        primaryText={messages.editButton}
                        containerElement={<Link to={`/sourcing/profile/${company.id}/edit`} />}
                      />
                    )}
                  </MoreMenu>
                )}
              </div>
            }
          />
        )}
        {profile.publishedAt && (
          <ActionSection
            noBorder
            text={formatMessage(messages.publishedAt, [this.calendarFormat(profile.publishedAt)])}
            actions={
              <div className="container-flex">
                <PreviewButton url={profile.previewUrl} tooltip={messages.actions.show.label} />
                <MoreMenu>
                  <MenuItem primaryText={messages.actions.unpublish.label} onClick={this.handleUnpublish} />
                  <MenuItem
                    primaryText={messages.editButton}
                    containerElement={<Link to={`/sourcing/profile/${company.id}/edit`} />}
                  />
                </MoreMenu>
              </div>
            }
          />
        )}
        <Card>
          <Section>
            <div className="mui-label">{messages.nameLabel}</div>
            <div>{profileProp('name')}</div>
          </Section>
          <Section>
            <div className="mui-label">{messages.logoLabel}</div>
            <div>
              {profileProp('logoUrl', url => (
                <Image src={url} height={64} width={64} contain />
              ))}
            </div>
          </Section>
          <Section>
            <div className="mui-label">{messages.websiteLabel}</div>
            <div>{profileProp('websiteUrl')}</div>
          </Section>
          {/*<Section>*/}
          {/*<div className="mui-label">Motto</div>*/}
          {/*<div>{profileProp('motto')}</div>*/}
          {/*</Section>*/}
          <Section>
            <div className="mui-label">{messages.headerImageLabel}</div>
            <div>
              {profileProp('headerImageUrl', url => (
                <Image src={url} ratio={0.25} width={'100%'} />
              ))}
            </div>
          </Section>
          <Section>
            <div className="mui-label">{messages.descriptionLabel}</div>
            <div>
              {profileProp('description', d => (
                <FadedContent maxHeight={96}>
                  <div className="clearfix" dangerouslySetInnerHTML={{ __html: d }} />
                </FadedContent>
              ))}
            </div>
          </Section>
          <Section>
            <div className="mui-label">{messages.typeLabel}</div>
            <div>
              {profileProp('type', t => {
                const msg = enumMessages.ECompanyType[t];
                if (msg) {
                  return msg.label;
                }
              })}
            </div>
          </Section>
          <Section>
            <div className="mui-label">{messages.employeeSizeLabel}</div>
            <div>{profileProp('employeeSize')}</div>
          </Section>
          <Section>
            <div className="mui-label">{messages.locationsLabel}</div>
            <div>
              {profileProp('locations', locations => {
                if (locations.length > 0) {
                  return join(
                    map(locations, l => l.value),
                    ', '
                  );
                }
                return 'N/A';
              })}
            </div>
          </Section>
          <Section>
            <div className="mui-label">{messages.industriesLabel}</div>
            <div>{profilePropBadges('industries', this.state.industries)}</div>
          </Section>
          <Section>
            <div className="mui-label">{messages.benefitsLabel}</div>
            <div>{profilePropBadges('benefits', this.state.benefits)}</div>
          </Section>
          <Section>
            <div className="mui-label">{messages.personalDevelopmentAreasLabel}</div>
            <div>{profilePropBadges('personalDevelopmentAreas', this.state.personalDevelopmentAreas)}</div>
          </Section>
          <Section noBorder>
            <div className="mui-label">{messages.momentsLabel}</div>
            <div>
              {profileProp('moments', moments => {
                if (moments.length > 0) {
                  return (
                    <HorizontalScrollPane>
                      <div className="container-flex">
                        {map(moments, (m, idx) => (
                          <div key={idx} style={{ paddingLeft: idx > 0 ? '8px' : 0 }}>
                            <Image src={m.url} keepOriginalRatio height={128} />
                          </div>
                        ))}
                      </div>
                    </HorizontalScrollPane>
                  );
                }
                return 'N/A';
              })}
            </div>
          </Section>
          <div className="mui-card-actions text-right with-background">
            <Button
              primary
              label={messages.editButton}
              containerElement={<Link to={`/sourcing/profile/${company.id}/edit`} />}
              fullWidth
              style={{ height: '56px', lineHeight: '56px' }}
              icon={<i className="material-icons">edit</i>}
            />
          </div>
        </Card>
        {this.state.previewUrl && (
          <Overlay onRequestClose={this.handleCancelPreview}>
            <iframe
              src={this.state.previewUrl}
              style={{ width: '100%', height: '100%', border: 'none', background: 'white' }}
            />
          </Overlay>
        )}
        <ConfirmModal intl={intl} {...this.state.confirmModal} onCallback={this.handleConfirmCallback} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth,
    intl,
    entities: {
      companyProfile,
      currentCompany: { item, talentMarketPlan }
    }
  } = state;
  return {
    auth,
    intl,
    profile: companyProfile,
    company: item,
    talentMarketPlan
  };
};

const actions = {
  getCompanyProfile: companyActions.getCompanyProfile,
  publishCompanyProfileDraft: companyActions.publishCompanyProfileDraft,
  unpublishCompanyProfileDraft: companyActions.unpublishCompanyProfileDraft,
  discardCompanyProfileDraft: companyActions.discardCompanyProfileDraft
};

export default connect(mapStateToProps, actions)(CompanyProfile);
