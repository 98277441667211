import find from 'lodash/find';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import pickBy from 'lodash/pickBy';
import keys from 'lodash/keys';
import get from 'lodash/get';

import { EActivityType } from '../constants/enum';

import { average, sampleStd } from './math';
import { determineMetricDiscreteValueCount } from './utils';

const getProjectComparisonProperties = project => {
  const { company } = project;
  const propExtractors = [p => company && p.company.id === company.id];
  const orders = ['desc'];
  if (EActivityType.CULTURE_FIT.matches(project)) {
    if (EActivityType.CULTURE_FIT.variants.CANDIDATE.matches(project)) {
      propExtractors.push(p => !EActivityType.CULTURE_FIT.variants.CANDIDATE.matches(p));
      orders.push('desc');
    }
  }
  return {
    propExtractors,
    orders
  };
};

export const getProjectsForCrossComparison = (project, projects) => {
  let current = project;
  if (!current.company) {
    current = find(projects, p => p.id === project.id) || project;
  }
  const { id, activity, company } = current;
  const other = [];
  const disabled = [];
  let multipleAccounts = false;
  if (activity) {
    forEach(projects || [], p => {
      if (p.activity && p.activity.key === activity.key && p.id !== id) {
        if (p.activity && p.activity.statusCounts && p.activity.statusCounts.finished === 0) {
          disabled.push(p);
        } else {
          other.push(p);
        }
        if (company && p.company && p.company.id !== company.id) {
          multipleAccounts = true;
        }
      }
    });
  }
  const comparisonProperties = getProjectComparisonProperties(current);
  return {
    current,
    multipleAccounts,
    other: orderBy(other, comparisonProperties.propExtractors, comparisonProperties.orders),
    disabled: orderBy(disabled, comparisonProperties.propExtractors, comparisonProperties.orders)
  };
};

const extractMetricValues = (participants, getParticipantProps) =>
  reduce(
    participants,
    (res, p) => {
      const { result, crossComparedColor, participant } = getParticipantProps ? getParticipantProps(p) : p;
      res.counts.total += 1;
      if (!result || !result.metrics) {
        return res;
      }
      res.counts.finished += 1;
      for (let key in result.metrics) {
        if (result.metrics.hasOwnProperty(key)) {
          const metric = result.metrics[key];
          const prev = res.metrics[key] || { values: [], m: metric };
          prev.values.push({
            value: metric.value,
            color: crossComparedColor,
            participant: { ...participant, crossComparedColor }
          });
          res.metrics[key] = prev;
        }
      }
      return res;
    },
    { metrics: {}, counts: { total: 0, finished: 0 } }
  );

const MAX_STD = sampleStd([0, 1]);

const filterValues = values => filter(values, v => v.value != null);

const toDataValues = (values, dual, count, mean) =>
  reduce(
    values,
    (r, { value, color, participant }) => {
      const val = Math.round(value * (count - 1));
      const idx = dual ? count - val - 1 : val;
      const prev = r[idx] || { value: 0, fit: 1 - Math.abs(value - mean), color, colors: [], participants: [] };
      if (color) {
        prev.colors.push(color);
      }
      if (participant) {
        prev.participants.push(participant);
      }
      prev.value = prev.value + 1 / values.length;
      r[idx] = prev;
      return r;
    },
    {}
  );

const computeDualMetricDataFromValueLayers = (valueLayers, dual, count, meanVal) => {
  const dataValueLayers = map(valueLayers, vl => toDataValues(vl, dual, count, meanVal));
  return map(range(0, count), idx => {
    const values = map(dataValueLayers, dvl => ({ value: 0, ...dvl[idx] }));
    return { values };
  });
};

export const computeDualMetricData = metric => {
  const count = determineMetricDiscreteValueCount(metric);
  const dual = metric.type === 'NUMERIC_DUAL';
  const { value, values, valueLayers } = metric;
  const actualValueLayers = valueLayers || (values && [values]) || (value != null && [[{ value }]]) || [[]];
  const baseLayer = actualValueLayers[0];
  const mean = baseLayer.length > 0 ? reduce(baseLayer, (res, val) => res + val.value, 0) / baseLayer.length : 0;
  return {
    data: computeDualMetricDataFromValueLayers(actualValueLayers, dual, count, mean),
    dual,
    mean
  };
};

export const computeCrossComparisonFit = (
  metrics,
  sourceParticipants,
  targetParticipants,
  activeIndex,
  getParticipantProps
) => {
  const metricValues = extractMetricValues(targetParticipants || [], getParticipantProps);
  const crossComparedMetricValues = extractMetricValues(sourceParticipants, getParticipantProps);
  const resultMetrics = map(metrics, m => {
    const mv = metricValues.metrics[m.key] || {};
    const metric = { ...mv.m, ...m };
    const cmv = crossComparedMetricValues.metrics[m.key] || {};
    const values = mv.values || [];
    // != is on purpose, would not work with !==
    const activeCrossComparedValues =
      activeIndex != null
        ? cmv.values && cmv.values[activeIndex] != null
          ? [cmv.values[activeIndex]]
          : []
        : cmv.values;
    const baseValues = filterValues(values);
    const mean = baseValues.length > 0 ? average(baseValues, v => v.value) : null;
    const valueLayers = [baseValues, filterValues(activeCrossComparedValues) || []];
    const valueCount = determineMetricDiscreteValueCount(metric);
    const dual = metric.type === 'NUMERIC_DUAL';
    const data = computeDualMetricDataFromValueLayers(valueLayers, dual, valueCount, mean);
    const std = values.length > 0 ? sampleStd(baseValues, mean, v => v.value) : null;
    const weight = 1 - (std || 0) / MAX_STD;
    return {
      metric,
      mean,
      dual,
      data,
      weight,
      convergence: weight,
      crossComparedValues: filterValues(cmv.values)
    };
  });
  const convergence = reduce(resultMetrics, (res, rm) => res + rm.weight, 0) / resultMetrics.length;
  const { participantMetrics } = reduce(
    sourceParticipants,
    (res, p) => {
      const { result } = getParticipantProps ? getParticipantProps(p) : p;
      if (!result || !result.metrics) {
        res.participantMetrics.push(null);
        return res;
      }
      const idx = res.idx;
      const { weight, fit, fits } = reduce(
        resultMetrics,
        (res, rm) => {
          const ccItem = rm.crossComparedValues[idx];
          const pVal = ccItem && ccItem.value;
          const mVal = rm.mean;
          const weight = rm.weight;
          const fit = pVal != null && mVal != null ? 1 - Math.abs(pVal - mVal) : null;
          if (fit != null) {
            if (res.fit == null) {
              res.fit = 0;
            }
            res.fits.push({ fit, metric: rm.metric });
            res.fit += fit * weight;
            res.weight += weight;
          }
          return res;
        },
        { weight: 0, fit: null, fits: [] }
      );
      res.idx += 1;
      res.participantMetrics.push({ fit: weight ? fit / weight : null, fits });
      return res;
    },
    { participantMetrics: [], idx: 0 }
  );
  return {
    metrics: resultMetrics,
    convergence,
    participantMetrics,
    participantCounts: {
      source: crossComparedMetricValues.counts.total,
      sourceFinished: crossComparedMetricValues.counts.finished,
      target: metricValues.counts.total,
      targetFinished: metricValues.counts.finished
    }
  };
};

const createParentDisplayOrderGetter = metricDefinitions => ({ parent }) =>
  parent ? get(metricDefinitions, `${parent}.displayOrder`, Number.MAX_VALUE) : -1;

export const filterAndOrderMetrics = (metricDefinitions, filterFunction) => {
  const metrics = pickBy(metricDefinitions, filterFunction);
  return orderBy(
    map(keys(metrics), key => ({ key, ...metrics[key] })),
    [createParentDisplayOrderGetter(metricDefinitions), 'displayOrder'],
    ['asc', 'asc']
  );
};

export const metricsToTree = metrics => {
  const mMap = reduce(
    metrics,
    (res, m) => {
      res[m.key] = { ...m };
      return res;
    },
    {}
  );
  const result = reduce(
    metrics,
    (res, metric) => {
      const m = mMap[metric.key];
      const p = m.parent && mMap[m.parent];
      if (p) {
        if (!p.children) {
          p.children = [];
        }
        p.children.push(m);
      } else {
        res.tree.push(m);
      }
      return res;
    },
    { tree: [] }
  );
  return result.tree;
};

export const getNumericalMetrics = metricDefinitions =>
  filterAndOrderMetrics(metricDefinitions, md => md.type === 'NUMERIC' && md.displayOrder != null);

export const getNumericalBaseMetrics = metricDefinitions => {
  const metricMap = { ...metricDefinitions };
  for (let key in metricDefinitions) {
    if (metricDefinitions.hasOwnProperty(key)) {
      const m = metricDefinitions[key];
      if (m.parent) {
        delete metricMap[m.parent];
      }
    }
  }
  return getNumericalMetrics(metricMap);
};

export const getNumericDualMetrics = metricDefinitions =>
  filterAndOrderMetrics(metricDefinitions, md => md.type === 'NUMERIC_DUAL' && md.displayOrder != null);

export const convertMetricsToCrossComparisonData = metrics => {
  return reduce(
    metrics,
    (res, { metric, data }) => {
      const newData = map(data, di => {
        const values = di.values || [];
        const baseLayer = values[0];
        const upperLayer = values[1];
        const newDataItem = { values: [] };
        if (baseLayer) {
          newDataItem.values.push({ value: baseLayer.value });
        }
        if (upperLayer) {
          // only the first one!
          newDataItem.values.push({ value: upperLayer.value });
        }
        return newDataItem;
      });
      res[metric.key] = { data: newData };
      return res;
    },
    {}
  );
};

export const calculateContribution = (val, n, avg) => avg - (n * avg - val) / (n - 1);
