import { handleActions } from 'redux-actions';

import {
  GROUP_MEMBER_FILTER_OPEN,
  GROUP_MEMBER_FILTER_CLOSE,
  GROUP_MEMBER_FILTER_SET_FULL_TEXT,
  GROUP_MEMBER_FILTER_SET_FROM_VALUES
} from '../../constants/constants';

import { update, updateFromValues } from './filtersReducerUtil';

const initialState = {};

export default handleActions(
  {
    [GROUP_MEMBER_FILTER_OPEN]: (state, { payload: { groupId } }) => update(state, groupId, { open: true }),
    [GROUP_MEMBER_FILTER_CLOSE]: (state, { payload: { groupId } }) => update(state, groupId, { open: false }),
    [GROUP_MEMBER_FILTER_SET_FULL_TEXT]: (state, { payload: { groupId, fullText } }) =>
      update(state, groupId, { fullText }),
    [GROUP_MEMBER_FILTER_SET_FROM_VALUES]: (state, { payload: { groupId, filterKey, values } }) =>
      updateFromValues(state, groupId, filterKey, values)
  },
  initialState
);
