import * as authActions from './auth/authActions';

import * as applicantActions from './entities/applicantActions';
import * as companyActions from './entities/companyActions';
import * as projectActions from './entities/projectActions';
import * as accountActions from './entities/accountActions';

import * as gridActions from './options/gridActions';
import * as sidebarActions from './options/sidebarActions';
import * as chartActions from './options/chartActions';
import * as overviewChartActions from './options/overviewChartActions';
import * as modalActions from './options/modalActions';
import * as snackbarsActions from './options/snackbarsActions';
import * as localeActions from './options/localeActions';
import * as searchActions from './options/projectFilterActions';
import * as notificationsActions from './options/notificationsActions';
import * as skillScatterChartActions from './options/skillScatterChartActions';
import * as guideActions from './options/guideActions';
import * as crossComparisonActions from './options/crossComparisonActions';

import * as demoActions from './simulation/demoActions';

export default {
  ...authActions,
  ...applicantActions,
  ...companyActions,
  ...projectActions,
  ...accountActions,
  ...gridActions,
  ...sidebarActions,
  ...chartActions,
  ...overviewChartActions,
  ...modalActions,
  ...snackbarsActions,
  ...localeActions,
  ...searchActions,
  ...notificationsActions,
  ...demoActions,
  ...guideActions,
  ...skillScatterChartActions,
  ...crossComparisonActions
};
