import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import Text from '../superfield/SuperText';

import { formatMessage } from '../../utils/utils';

class CopyToClipboardInput extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.any,
    helperText: PropTypes.any,
    helperTextCopied: PropTypes.any
  };

  state = {
    copied: false
  };

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleCopy = () => {
    this.setState({ copied: true });
    this.timeout = setTimeout(() => this.setState({ copied: false }), 2000);
  };

  render() {
    const { value, label, intl, helperText, helperTextCopied } = this.props;
    const { copied } = this.state;
    const messages = intl.messages.components.copyToClipboardInput;
    const actualHelperText = copied
      ? helperTextCopied || messages.helperTextCopied
      : formatMessage(helperText || messages.helperText, [
          val => (
            <CopyToClipboard key="copy" text={value}>
              <a role="button" onClick={this.handleCopy}>
                {val}
              </a>
            </CopyToClipboard>
          )
        ]);
    return <Text value={value} readOnly label={label || messages.label} helperText={actualHelperText} />;
  }
}

export default connect(({ intl }) => ({ intl }))(CopyToClipboardInput);
