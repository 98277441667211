import React from 'react';
import { RadioButtonGroup } from 'material-ui/RadioButton';

export default ({ input, meta: { touched, error, warning } = {}, label, children, indicateRequired, ...props }) => {
  const err = touched && (error || warning);
  return (
    <div>
      {label && (
        <div className={`mui-fields-label${err ? ' text-error' : ''}`}>
          {label}
          {indicateRequired ? ' *' : ''}
        </div>
      )}
      <RadioButtonGroup {...props} {...input} valueSelected={input.value}>
        {children}
      </RadioButtonGroup>
      {err && <div className="mui-fields-error-text">{err}</div>}
    </div>
  );
};
