import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';

import Toggle from 'material-ui/Toggle';

import Overlay from '../basic/Overlay';
import SearchField from '../mui/DebouncedSearchField';
import Chip from '../mui/Chip';
import Button from '../mui/Button';
import ParticipantsSelectPanel from '../applicant/ParticipantsSelectPanel';

import withIntl from '../withIntl';

import { KC, determineKeyCode } from '../superfield/Utils';
import { EGroupMembership, EGroupType } from '../../constants/enum';

import * as Api from '../../api/entities/groupApi';
import { formatMessage } from '../../utils/utils';
import IconButton from '../mui/IconButton';

const stopPropagation = e => e.stopPropagation();

class AddToGroupsDialog extends React.Component {
  constructor(props) {
    super(props);
    const participants = props.participants || [];
    this.state = {
      checked: _reduce(
        participants,
        (res, p) => {
          res[this.getParticipantId(p)] = true;
          return res;
        },
        {}
      ),
      selectedParticipants: participants,
      checkedCount: participants.length,
      search: '',
      groups: [],
      loading: 0,
      selection: { items: [], map: {}, teamsCount: 0 },
      menuOpen: false,
      manager: false
    };
  }

  componentDidMount() {
    this.handleSearchChange('');
  }

  getParticipantProps = p => {
    const { getParticipant } = this.props;
    return getParticipant ? getParticipant(p) : p.participant;
  };

  getParticipantId = p => `${p.projectId}/${this.getParticipantProps(p).id}`;

  handleParticipantSelectionChanged = selectedParticipants => {
    this.setState({ selectedParticipants, checkedCount: selectedParticipants.length });
  };

  handleSearchChange = search => {
    this.setState({ search });
    Api.getGroups(null, null, search, { page: 0, size: 10 }).then(({ items }) => {
      this.setState({ groups: items });
    });
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleConfirm = () => {
    const { onConfirm, mode, currentGroup } = this.props;
    const { selection, selectedParticipants, manager } = this.state;

    const groups = selection.items;
    const isManager = this.canManagerBeSelected() && manager;
    const participants = _map(selectedParticipants, p => ({ id: this.getParticipantProps(p).id }));
    const membership = isManager ? EGroupMembership.MANAGER.key : EGroupMembership.MEMBER.key;

    const promises = _map(groups, g => Api.createGroupMembers(g.id, { membership, items: participants }));
    if (mode === 'MOVE' && currentGroup) {
      promises.push(Api.deleteGroupMembers(currentGroup.id, participants));
    }

    return Promise.all(promises).then(() => onConfirm(groups, selectedParticipants, isManager));
  };

  isInSelection = ({ id }) => this.state.selection.map[id];
  handleToggleGroup = (e, g) => {
    stopPropagation(e);
    const {
      selection: {
        items,
        map: { [g.id]: selected, ...map },
        teamsCount
      },
      search
    } = this.state;
    // clear search
    if (search) {
      this.handleSearchChange('');
    }
    // update selection
    const newSelection = {};
    if (!selected) {
      newSelection.items = [...items, g];
      map[g.id] = true;
      newSelection.teamsCount = teamsCount + (g.type === EGroupType.TEAM.key ? 1 : 0);
    } else {
      newSelection.items = _filter(items, i => i.id !== g.id);
      newSelection.teamsCount = teamsCount - (g.type === EGroupType.TEAM.key ? 1 : 0);
    }
    newSelection.map = map;
    this.setState({ selection: newSelection, menuOpen: false });
  };

  handleKeyDown = e => {
    const kc = determineKeyCode(e);
    switch (kc) {
      case KC.ESC:
        this.handleSearchChange('');
        return;
      case KC.ENTER:
        const { groups } = this.state;
        if (groups.length > 0) {
          this.handleToggleGroup(e, groups[0]);
        }
        return;
      default:
        return;
    }
  };

  handleMenuOpen = e => {
    stopPropagation(e);
    this.setState({ menuOpen: true });
  };
  handleMenuClose = () => this.setState({ menuOpen: false });

  handleToggleManager = () => this.setState({ manager: !this.state.manager });

  canManagerBeSelected = () => {
    const { selection, selectedParticipants } = this.state;
    const checkedCount = selectedParticipants.length;
    return checkedCount === 1 && selection.teamsCount > 0 && selection.teamsCount === selection.items.length;
  };

  render() {
    const {
      intl: { messages, globalMessages },
      multiCompanies,
      participants,
      mode
    } = this.props;
    const { groups, search, selection, menuOpen, selectedParticipants, manager } = this.state;
    const enumMessages = globalMessages.constants.enums.EGroupType;
    const modeMessages = messages.modes[mode || 'ADD'];
    const checkedCount = selectedParticipants.length;
    return (
      <Overlay onRequestClose={this.handleClose} modal maxWidth={560} noBlur onClick={this.handleMenuClose}>
        <div className="mui-card" onClick={this.handleMenuClose}>
          <div className="border-bottom border-color-primary__50" onClick={stopPropagation}>
            <SearchField
              focus={menuOpen}
              value={search}
              placeholder={messages.searchPlaceholder}
              onValueChange={this.handleSearchChange}
              debounce={300}
              onKeyDown={this.handleKeyDown}
              onFocus={this.handleMenuOpen}
            />
          </div>
          <div style={{ position: 'relative' }}>
            <div className="mui-padded-horizontal-x2 clearfix">
              <div className="mui-fields-container">
                <div className="mui-fields-label">{messages.teamsLabel}</div>
                {selection.items.length > 0 ? (
                  <div>
                    {_map(selection.items, g => (
                      <div key={g.id} className="mui-chip-container">
                        <Chip
                          variant="compact"
                          active
                          onClick={e => this.handleToggleGroup(e, g)}
                          iconRight={'remove_circle'}
                        >
                          <span>
                            {multiCompanies && `${g.company.name} - `}
                            {g.name}
                          </span>
                          <small style={{ marginLeft: '6px', color: 'rgba(255,255,255,0.7)' }}>
                            {enumMessages[g.type].label}
                          </small>
                        </Chip>
                      </div>
                    ))}
                    <div className="mui-chip-container">
                      <IconButton style={{ width: '24px', height: '24px' }} onClick={this.handleMenuOpen}>
                        <i className="material-icons text-muted">add</i>
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <div className="mui-padded-x2 text-center text-muted bg-muted border-radius">
                    {formatMessage(messages.noTeamsMessage, [
                      val => (
                        <a
                          key="actionButton"
                          role="button"
                          className="link-button heavy text-sz-reg mui-margin-horizontal-half"
                          onClick={this.handleMenuOpen}
                        >
                          <i className="material-icons">search</i>
                          <span>{val}</span>
                        </a>
                      )
                    ])}
                  </div>
                )}
              </div>
              <div className="mui-fields-container">
                <div className="mui-fields-label">{messages.participantsLabel}</div>
                <div
                  className="bg-primary-light border border-color-primary__50 border-radius mui-padded-vertical"
                  style={{ maxHeight: '200px', overflow: 'auto' }}
                >
                  <ParticipantsSelectPanel
                    getParticipant={this.getParticipantProps}
                    participants={participants}
                    onSelectionChange={this.handleParticipantSelectionChanged}
                  />
                </div>
                <div className="mui-fields-helper-text">{messages.participantsSelectHelper}</div>
                {this.canManagerBeSelected() && (
                  <div style={{ fontSize: '14px' }} className="mui-padded-vertical">
                    <Toggle
                      label={messages.addAsManagerLabel}
                      toggled={manager}
                      onToggle={this.handleToggleManager}
                      labelPosition={'right'}
                      labelStyle={{ margin: 0, fontWeight: 400, zIndex: 0 }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mui-padded-half text-right">
              <div className="mui-padded-half inline-container">
                <Button label={messages.cancelButton} onClick={this.handleClose} />
              </div>
              <div className="mui-padded-half inline-container">
                <Button
                  label={modeMessages.confirmLabel}
                  onClick={this.handleConfirm}
                  disabled={selection.items <= 0 || checkedCount <= 0}
                  primary
                  raised
                />
              </div>
            </div>
            {menuOpen && (
              <div
                className="container-flex-column mui-card vb-appear mui-appear"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  maxHeight: 'calc(100vh - 80px)'
                }}
                onClick={stopPropagation}
              >
                <div className="mui-padded-vertical flex1" style={{ overflow: 'auto' }}>
                  {_map(groups, g => {
                    const inSelection = this.isInSelection(g);
                    return (
                      <div
                        key={g.id}
                        className="mui-padded-vertical mui-padded-horizontal-x2 mui-item-hoverable"
                        role="button"
                        onClick={e => this.handleToggleGroup(e, g)}
                      >
                        <div className="container-flex-row ai-center jc-space-between">
                          <div style={{ fontWeight: inSelection ? 500 : 400, minHeight: '24px' }}>
                            <div>{g.name}</div>
                            <div className="small text-muted">
                              {enumMessages[g.type].label}
                              {multiCompanies && (
                                <span>
                                  <span style={{ margin: '0 4px' }}>&middot;</span>
                                  {g.company.name}
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            {inSelection && (
                              <div>
                                <i className="material-icons text-success mui-appear">check</i>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {groups.length === 0 && (
                    <div className="mui-padded mui-padded-horizontal-x2">
                      <em className="text-muted">{messages.noTeamsFoundMessage}</em>
                    </div>
                  )}
                </div>
                <div
                  className="mui-padded text-right border-top border-color-primary__50"
                  onClick={this.handleMenuClose}
                >
                  <Button label={messages.closeMenuLabel} mini onClick={this.handleMenuClose} />
                </div>
              </div>
            )}
          </div>
        </div>
      </Overlay>
    );
  }
}

const intlMessages = {
  en: {
    searchPlaceholder: 'Search groups & teams',
    closeMenuLabel: 'Close',
    participantsLabel: 'Participants',
    teamsLabel: 'Groups / Teams',
    participantsSelectHelper: 'You can deselect participants you do not wish to add to groups / teams',
    addAsManagerLabel: 'Add as the manager',
    noTeamsMessage: '{0[Search]} for groups or teams to which you wish to add participants',
    cancelButton: 'Cancel',
    modes: {
      ADD: { confirmLabel: 'Add' },
      COPY: { confirmLabel: 'Copy' },
      MOVE: { confirmLabel: 'Move' }
    },
    noTeamsFoundMessage: 'No groups / teams found'
  },
  cs: {
    searchPlaceholder: 'Hledat v skupinách a týmech',
    closeMenuLabel: 'Zavřít',
    participantsLabel: 'Účastníci',
    teamsLabel: 'Skupiny / týmy',
    participantsSelectHelper: 'Můžete odznačit účastníky, které do skupin / týmů nechcete přidat',
    addAsManagerLabel: 'Přidat jako manažera',
    noTeamsMessage: '{0[Vyhledejte]} skupiny nebo týmy, do kterých chcete účastníky přidat',
    cancelButton: 'Zrušit',
    modes: {
      ADD: { confirmLabel: 'Přidat' },
      COPY: { confirmLabel: 'Kopírovat' },
      MOVE: { confirmLabel: 'Přesunout' }
    },
    noTeamsFoundMessage: 'Žádné týmy / skupiny nenalezeny'
  },
  sk: {
    searchPlaceholder: 'Hľadať v skupinách a tímoch',
    closeMenuLabel: 'Zavrieť',
    participantsLabel: 'Účastníci',
    teamsLabel: 'Skupiny / tímy',
    participantsSelectHelper: 'Môžete odznačiť účastníkov, ktorých do skupín / tímov nechcete pridať',
    addAsManagerLabel: 'Pridať ako manažéra',
    noTeamsMessage: '{0[Vyhľadajte]} skupiny alebo tímy, do ktorých chcete účastníkov pridať',
    cancelButton: 'Zrušiť',
    modes: {
      ADD: { confirmLabel: 'Pridať' },
      COPY: { confirmLabel: 'Kopírovať' },
      MOVE: { confirmLabel: 'Presunúť' }
    },
    noTeamsFoundMessage: 'Žiadne tímy / skupiny nenájdené'
  }
};

const mapStateToProps = state => {
  const {
    entities: { companies }
  } = state;
  return {
    multiCompanies: companies.length > 1
  };
};

export default connect(mapStateToProps)(withIntl(AddToGroupsDialog, intlMessages));
