import React, { useCallback, useState } from 'react';

import SuperText from '../superfield/SuperText';
import Collapse from '../basic/Collapse';

const Note = props => {
  const { label, helperText, hint, value, onChange, onBlur } = props;

  const [val, setVal] = useState(value);
  const [editing, setEditing] = useState(false);

  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(e);
      }
      setVal(e.target.value);
    },
    [setVal, onChange]
  );
  const handleBlur = useCallback(
    e => {
      if (onBlur) {
        onBlur(e);
      }
      setEditing(false);
    },
    [setEditing]
  );
  const handleEditStart = useCallback(() => setEditing(true), [setEditing]);

  const actualValue = onChange ? value : val;

  return (
    <Collapse isOpened>
      {editing ? (
        <div className="text-width-reading text-sz-reg">
          <SuperText
            label={label}
            helperText={helperText}
            rows={5}
            multiline
            value={actualValue || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ fontSize: '14px', margin: 0 }}
            autoFocus
          />
        </div>
      ) : (
        <div className="text-width-reading text-sz-sm text-muted" onClick={handleEditStart}>
          <div className="container-flex-row">
            <div>
              <a role="button" className="link-button heavy mui-padded-horizontal" onClick={handleEditStart}>
                <i className="material-icons">edit_note</i>
              </a>
              {!actualValue && <em className="text-muted">{hint}</em>}
            </div>
            {actualValue && <p>{actualValue}</p>}
          </div>
        </div>
      )}
    </Collapse>
  );
};

export default Note;
