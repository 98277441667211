import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import _get from 'lodash/get';

import Snackbar from 'material-ui/Snackbar';

import * as snackbarsActions from '../../actions/options/snackbarsActions';

const Snackbars = (props) => {
  const { snackbars, intl, hideSnackbar } = props;
  return (
    <div>
      {_map(snackbars, ({ id, message, duration, messageKey }) => {
        const msg = message || (messageKey && _get(intl.messages, messageKey));
        return (
          <Snackbar
            key={id}
            open
            message={msg}
            autoHideDuration={duration || 4000}
            onRequestClose={() => hideSnackbar({ id })}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = ({ intl, options: { snackbars } }) => ({ intl, snackbars });

const actions = {
  hideSnackbar: snackbarsActions.hideSnackbar
};

export default connect(mapStateToProps, actions)(Snackbars);
