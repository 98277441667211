import React, { useCallback, useMemo, useState } from 'react';

import Checkbox from 'material-ui/Checkbox';

import MetricsBubbleChart from './MetricsBubbleChart';

import { buildData } from './MetricsBubbleChartUtils';

import withIntl from '../../withIntl';

const MetricsBubbleChartPanel = props => {
  const { participants, meanShown, medianShown, metrics, intl, getParticipantProps } = props;

  const [mean, setMean] = useState(meanShown);
  const [median, setMedian] = useState(medianShown == null ? true : medianShown);

  const handleMeanToggle = useCallback(() => setMean(mean => !mean), [setMean]);
  const handleMedianToggle = useCallback(() => setMedian(median => !median), [setMedian]);

  const chartProps = useMemo(() => buildData(participants, metrics, getParticipantProps), [
    participants,
    metrics,
    getParticipantProps
  ]);

  const messages = intl.messages.components.analytics.metricsBubbleChart;

  return (
    <div>
      <div className="flex1" style={{ maxWidth: '100%' }}>
        <MetricsBubbleChart {...chartProps} mean={mean} median={median} />
      </div>
      <div className="mui-padded-x2 hidden-print">
        <div className="mui-padded-half">
          <Checkbox
            label={messages.medianLabel}
            iconStyle={{ marginRight: '8px' }}
            labelStyle={{ margin: 0, fontSize: '13px' }}
            onCheck={handleMedianToggle}
            checked={median}
          />
        </div>
        <div className="mui-padded-half">
          <Checkbox
            label={messages.meanLabel}
            iconStyle={{ marginRight: '8px' }}
            labelStyle={{ margin: 0, fontSize: '13px' }}
            onCheck={handleMeanToggle}
            checked={mean}
          />
        </div>
      </div>
    </div>
  );
};

export default withIntl(MetricsBubbleChartPanel);
