import React from 'react';
import { connect } from 'react-redux';

import Button from '../../mui/Button';
import InfoCard from '../InfoCard';

import withIntl from '../../withIntl';
import * as projectActions from '../../../actions/entities/projectActions';

const ProjectInvitationInfoCard = ({ project, getProjectInvitationUrls, intl: { messages } }) => {
  return (
    <InfoCard
      bgIcon={'group_add'}
      actions={[
        <Button
          key={'invite'}
          icon={
            <i className="material-icons" style={{ color: 'white' }}>
              group_add
            </i>
          }
          label={messages.inviteLabel}
          primary
          raised
          onClick={() => getProjectInvitationUrls(project.company.slug, project.slug)}
        />
      ]}
    >
      <div className="mui-padded">{messages.message}</div>
    </InfoCard>
  );
};

const intlMessages = {
  cs: {
    inviteLabel: 'Pozvat účastníky',
    message: 'Začněte tím, že do projektu pozvete účastníky'
  },
  sk: {
    inviteLabel: 'Pozvať účastníkov',
    message: 'Začnite tým, že do projektu pozvete účastníkov'
  },
  en: {
    inviteLabel: 'Invite participants',
    message: 'Start by inviting participants into the project'
  }
};

const actions = {
  getProjectInvitationUrls: projectActions.getProjectInvitationUrls
};

export default withIntl(connect(null, actions)(ProjectInvitationInfoCard), intlMessages);
