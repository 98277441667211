import { putObject } from '../persistence/storage';

const localStorageDecorator = (actionMap, persistenceKey) => {
  const newActionMap = {};
  for (let prop in actionMap) {
    if (actionMap.hasOwnProperty(prop)) {
      const fn = actionMap[prop];
      newActionMap[prop] = (...args) => {
        const res = fn(...args);
        putObject(persistenceKey, res);
        return res;
      };
    }
  }
  return newActionMap;
};

export default localStorageDecorator;
