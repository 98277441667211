export const createApiCallAction = (asyncActionType, apiCall, options) => (...args) => {
  const { transformArgsToMeta, onSuccess, onError, transformPayload } = options || {};
  return (dispatch, getState) => {
    const meta = transformArgsToMeta ? transformArgsToMeta(args) : null;
    dispatch({ type: asyncActionType.PENDING, meta });
    return apiCall(...args).then(
      p => {
        const payload = transformPayload ? transformPayload(p, args) : p;
        if (onSuccess) {
          onSuccess({ dispatch, getState, payload });
        }
        return dispatch({ type: asyncActionType.SUCCESS, payload, meta });
      },
      err => {
        if (onError) {
          onError({ dispatch, getState, error: err });
        }
        return dispatch({ type: asyncActionType.ERROR, payload: null, meta });
      }
    );
  };
};
