import React from 'react';

import ActivityLabel from '../activity/ActivityLabel';

const ActivityToggleHeader = props => {
  const { activity, expanded, onToggle } = props;
  const handleToggle = () => onToggle(activity);
  return (
    <div className="mui-padded" onClick={handleToggle}>
      <div role="button" className="mui-label" style={{ margin: 0 }} onClick={handleToggle}>
        <span className="inline-container">
          <ActivityLabel activity={activity} />
        </span>
        <span className="inline-container hidden-print" style={{ margin: '-1px 0 0 4px' }}>
          <i className="material-icons" style={{ fontSize: '16px' }}>
            {expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </i>
        </span>
      </div>
    </div>
  );
};

export default ActivityToggleHeader;
