import React from 'react';
import PropTypes from 'prop-types';

/**
 * Stupid hack to improve rendering of labels. Recharts library calculates whether a label should be displayed
 * based on its length not taking the rotation into account. That's why I use 'X' as a label to fool the library,
 * however I didn't find a way to pass more info (e.g. real label) to the tick properly so I had to pass the whole
 * data object.
 *
 * I hope you find a better way to do this.
 */
const CustomNameTick = (props) => {
    const { x, y, fill, data, payload } = props;
    const name = data[payload.index].shortName;
    return (
        <g transform={`translate(${x - 10},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill={fill} transform="rotate(-75)">{name}</text>
        </g>
    );
};

CustomNameTick.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    data: PropTypes.array,
    fill: PropTypes.string,
    payload: PropTypes.object
};

export default CustomNameTick;
