import React from 'react';
import { connect } from 'react-redux';

import Spinner from '../../../spinner/Spinner';

import * as actions from '../../../../actions/options/crossComparisonActions';
import { formatMessage } from '../../../../utils/utils';
import { getCrossComparison } from '../../../../selectors/base';

class ParticipantCrossComparisonProjectSelect extends React.Component {
  state = {
    initial: null
  };

  componentDidMount() {
    const { crossComparison, project, intl, loadCrossComparisonParticipants } = this.props;
    const { sourceProject, targetProject } = crossComparison || {};
    if (targetProject && sourceProject && sourceProject.id === project.id) {
      loadCrossComparisonParticipants(targetProject.company.slug, targetProject.slug, intl.locale);
    }
    this.setState({ initial: crossComparison });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.intl !== prevProps.intl ||
      this.props.participant !== prevProps.participant ||
      this.props.project !== prevProps.project
    ) {
      this.cancelCrossComparison();
    }
  }

  componentWillUnmount() {
    const { crossComparison } = this.props;
    const { initial } = this.state;
    if (initial !== crossComparison) {
      this.cancelCrossComparison();
    }
  }

  cancelCrossComparison = () => this.props.cancelCrossComparison();

  handleSelectProject = () => {
    const { selectCrossComparisonProject } = this.props;
    const { participant, project } = this.props;
    selectCrossComparisonProject([participant], project, { inPlace: true });
  };

  render() {
    const { project, intl, crossComparison } = this.props;
    const { sourceProject, targetProject } = crossComparison;
    const isCurrent = project && sourceProject && project.id === sourceProject.id;
    const messages = intl.messages.components.pages.private.project.analytics.crossComparison;
    return (
      <div>
        {isCurrent ? (
          crossComparison.loading ? (
            <div style={{ position: 'relative', height: 32, width: 32, display: 'inline-block' }}>
              <Spinner local show size={32} stroke={4} />
            </div>
          ) : (
            <div>
              <p>
                {formatMessage(messages.projectSelect.comparisonMessage, [
                  <strong key="0">{targetProject.name}</strong>
                ])}
                <br />
              </p>
              <div style={{ marginTop: '0.5em' }}>
                <a role="button" ref={el => (this.anchorEl = el)} onClick={this.handleSelectProject}>
                  {messages.projectSelect.change}
                </a>
                <span style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>&middot;</span>
                <a role="button" onClick={this.cancelCrossComparison}>
                  {messages.projectSelect.cancel}
                </a>
              </div>
            </div>
          )
        ) : (
          <p>
            {formatMessage(messages.projectSelect.comparisonEmptyMessage, (idx, val) => (
              <a ref={el => (this.anchorEl = el)} role="button" onClick={this.handleSelectProject}>
                {val}
              </a>
            ))}
          </p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { intl, entities: { applicant } } = state;
  return {
    intl,
    crossComparison: getCrossComparison(state, ownProps),
    participant: applicant
  };
};

export default connect(mapStateToProps, actions)(ParticipantCrossComparisonProjectSelect);
