import React from 'react';
import Cropper from 'cropperjs';

import Overlay from '../../../basic/Overlay';
import Button from '../../../mui/Button';

import { uploadFile } from '../../../../api/options/fileUpload';
import { formatMessage } from '../../../../utils/utils';

const messages = {
  cancelButton: 'Zrušit',
  confirmButton: 'Potvrdit',
  chooseFileButton: 'Vyberte soubor',
  dropHereMessage: '...anebo ho přesuňte sem',
  maxAllowedSizeMessage: 'Maximální povolená velikost je {0}',
  fileSizeMessage: 'Výsledná velikost obrázku: {0}'
};

const SIZE_UNITS = ['B', 'kB', 'MB'];
const formatFileSize = size => {
  let maxSize = 1024;
  for (let i = 0; i < SIZE_UNITS.length; i += 1) {
    const unit = SIZE_UNITS[i];
    if (size < maxSize) {
      const unitSize = size / (maxSize / 1024);
      const formattedUnitSize = i === SIZE_UNITS.length - 1 ? unitSize.toFixed(1) : Math.round(unitSize);
      return `${formattedUnitSize}${unit}`;
    }
    maxSize = maxSize * 1024;
  }
  return `${size}B`;
};
const MAX_BLOB_SIZE = 1024 * 1024;

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setImageElement = el => {
    if (el) {
      this.initCropper(el);
      el.addEventListener('ready', () => {
        this.setState(({ url }) => ({ dKey: url }));
      });
    }
  };

  componentWillUnmount() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  }

  initCropper = el => {
    const component = this;
    const eventHandler = function() {
      this.cropper.getCroppedCanvas().toBlob(blob => {
        component.setState({ blobSize: blob.size });
      });
    };
    this.cropper = new Cropper(el, {
      aspectRatio: this.props.aspectRatio,
      viewMode: 1,
      ready: eventHandler,
      cropend: eventHandler,
      zoom: eventHandler
    });
  };

  openFile = file => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      this.cropper.replace(dataUrl);
      this.setState({ url: dataUrl });
    };
    reader.readAsDataURL(file.target.files[0]);
  };

  handleUpload = (e, { callback }) => {
    this.cropper.getCroppedCanvas().toBlob(blob => {
      this.setState({ uploading: true });
      uploadFile(blob)
        .then(res => {
          this.setState({ uploading: false });
          const url = res.body.payload.url;
          if (callback) {
            callback(url);
          }
        })
        .catch(e => {
          this.setState({ uploading: false });
          console.error('ERROR', e);
        });
    });
  };

  handleClose = () => {
    this.props.onClose();
  };
  handleConfirm = e => {
    this.handleUpload(e, { callback: this.props.onConfirm });
  };

  render() {
    const { url, uploading, blobSize } = this.state;
    const { helperText } = this.props;
    const maxFileSize = this.props.maxFileSize || MAX_BLOB_SIZE;
    const overSizeLimit = blobSize > maxFileSize;
    const actions = [
      <Button key="cancelAction" onClick={this.handleClose} label={messages.cancelButton} />,
      <Button
        key="confirmAction"
        onClick={this.handleConfirm}
        label={messages.confirmButton}
        primary
        raised
        disabled={!url || uploading || overSizeLimit}
        style={{ marginLeft: '8px' }}
      />
    ];
    return (
      <Overlay onRequestClose={this.handleClose} modal>
        <div className="mui-card">
          <div style={{ padding: '16px 16px 0' }}>
            <div className="upload-btn-wrapper" style={url && { padding: '16px' }}>
              <Button label={messages.chooseFileButton} secondary />
              <input type="file" name="uploadedFile" onChange={this.openFile} />
              <small>{messages.dropHereMessage}</small>
            </div>
            <div style={{ paddingTop: '16px', display: url ? 'block' : 'none' }}>
              <img ref={this.setImageElement} src={url} style={{ maxWidth: '100%' }} alt="Uploaded image" />
            </div>
            <div className={`mui-fields-${overSizeLimit ? 'error' : 'helper'}-text`}>
              {helperText}
              {helperText && <span style={{ margin: '0 8px' }}>&middot;</span>}
              {formatMessage(messages.maxAllowedSizeMessage, [formatFileSize(maxFileSize)])}
              {url && blobSize && <span style={{ margin: '0 8px' }}>&middot;</span>}
              {url && blobSize && formatMessage(messages.fileSizeMessage, [formatFileSize(blobSize)])}
            </div>
          </div>
          <div className="text-right" style={{ padding: '8px' }}>
            {actions}
          </div>
        </div>
      </Overlay>
    );
  }
}

export default ImageUpload;
