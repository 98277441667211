import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import VisSensor from 'react-visibility-sensor';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';

import ParticipantName from '../basic/ParticipantName';

import withIntl from '../withIntl';
import EmptySectionMessage from './EmptySectionMessage';

const EParticipantActivityEvent = {
  COMPLETED: {
    key: 'COMPLETED'
  }
};

const EventItem = ({ event, projectsMap, messages, timeFormatter, sameParticipantAbove, sameParticipantBelow }) => {
  const projects = _reduce(
    event.projectIds,
    (res, pId) => {
      const p = projectsMap[pId];
      if (p) {
        if (!res.first) {
          res.first = p;
        }
        if (res.all.length > 0) {
          res.all.push(
            <div key={`${p.id}-dividier`} className="mui-margin-horizontal">
              {' '}
              |{' '}
            </div>
          );
        }
        res.all.push(
          <div key={p.id}>
            <Link to={`/projects/overview/${p.company.slug}/${p.slug}`}>{p.name}</Link>
          </div>
        );
      }
      return res;
    },
    { first: null, all: [] }
  );
  const isCompleted = event.type === EParticipantActivityEvent.COMPLETED.key;
  const typeMessages = messages.byType[event.type] || {};
  const content = (
    <div
      className="mui-padded mui-margin text-sz-reg-sm mui-appear border-radius"
      style={
        isCompleted ? { backgroundColor: 'rgba(255, 255, 255, 1)' } : { backgroundColor: 'rgba(255, 255, 255, 0.4)' }
      }
    >
      <div className="container-flex-row jc-space-between">
        <div className="mui-padded-half">
          <div className={`circle-indicator ci-size-8 mui-margin-top ${event.type}`} />
        </div>
        <div className="mui-padded-half flex1">
          <div>
            <ParticipantName {...event.participant} variant="mini" />
            <span> {typeMessages.label}</span>
            {projects.first && (
              <span>
                {' '}
                <b>{projects.first.activity.name}</b>
              </span>
            )}
          </div>
          <div className="text-muted text-sz-sm container-flex-row fw-yes">{projects.all}</div>
        </div>
        <div className="mui-padded-half">
          <small>{timeFormatter(event.timestamp)}</small>
        </div>
      </div>
      {sameParticipantAbove && <div className="connection-above bg-primary__100" />}
      {sameParticipantBelow && <div className="connection-below bg-primary__100" />}
    </div>
  );
  return isCompleted && projects.first ? (
    <Link
      to={`/projects/overview/${projects.first.company.slug}/${projects.first.slug}/participants/${event.participant.id}`}
      className="participant-activity-event-item participant-activity-event-link"
    >
      {content}
    </Link>
  ) : (
    <div className="participant-activity-event-item">{content}</div>
  );
};

const ParticipantActivityEventsList = props => {
  const {
    items,
    page,
    projectsMap,
    loading,
    //
    intl: { messages, locale },
    //
    onShowMore,
    onLoadMore
  } = props;

  const timeFormatter = t => moment(t).locale(locale).calendar();

  return items.length > 0 ? (
    <div className="mui-padded">
      {_map(items, (i, idx) => (
        <EventItem
          key={`${i.gameInstanceId}-${i.type}`}
          event={i}
          projectsMap={projectsMap}
          timeFormatter={timeFormatter}
          sameParticipantAbove={idx > 0 && items[idx - 1].participant.id === i.participant.id}
          sameParticipantBelow={idx < items.length - 1 && items[idx + 1].participant.id === i.participant.id}
          messages={messages.eventItem}
        />
      ))}
      {page.number < page.totalPages - 1 && (
        <div className="mui-padded mui-margin-left">
          {onShowMore ? (
            <a role="button" className="link-button" onClick={onShowMore}>
              <span>{messages.showMoreLabel}</span>
            </a>
          ) : (
            !loading && (
              <VisSensor key={`${page.number}-${page.totalPages}`} onChange={visible => visible && onLoadMore()}>
                <div style={{ minHeight: '1px', minWidth: '1px', marginTop: '-1px', display: 'inline-block' }} />
              </VisSensor>
            )
          )}
        </div>
      )}
    </div>
  ) : (
    <EmptySectionMessage header={messages.emptyMessage} />
  );
};

const intlMessages = {
  cs: {
    emptyMessage: 'Zatím žádná aktivita',
    showMoreLabel: 'Více...',
    eventItem: {
      byType: {
        INVITED: {
          label: 'pozvaný/á do aktivity'
        },
        INVITATION_ACCEPTED: {
          label: 'přijal/a pozvání do aktivity'
        },
        REGISTERED: {
          label: 'se registroval/a do aktivity'
        },
        STARTED: {
          label: 'zahájil/a'
        },
        COMPLETED: {
          label: 'dokončil/a'
        }
      }
    }
  },
  sk: {
    emptyMessage: 'Zatiaľ žiadna aktivita',
    showMoreLabel: 'Viac...',
    eventItem: {
      byType: {
        INVITED: {
          label: 'pozvaný/á do aktivity'
        },
        INVITATION_ACCEPTED: {
          label: 'prijal/a pozvanie do aktivity'
        },
        REGISTERED: {
          label: 'sa registroval/a do aktivity'
        },
        STARTED: {
          label: 'zahájil/a'
        },
        COMPLETED: {
          label: 'dokončil/a'
        }
      }
    }
  },
  en: {
    emptyMessage: 'No activity yet',
    showMoreLabel: 'More...',
    eventItem: {
      byType: {
        INVITED: {
          label: 'invited into'
        },
        INVITATION_ACCEPTED: {
          label: 'accepted invitation into'
        },
        REGISTERED: {
          label: 'registered into'
        },
        STARTED: {
          label: 'started'
        },
        COMPLETED: {
          label: 'finished'
        }
      }
    }
  }
};

export default withIntl(ParticipantActivityEventsList, intlMessages);
