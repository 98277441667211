import React from 'react';

import Button from '../mui/Button';
import Tooltip from '../tooltip/Tooltip';

const style = { width: '36px', minWidth: 0, borderRadius: '18px' };

const NavigationButton = props => {
  const { icon, onClick, tooltip } = props;
  return (
    <div className="tooltip-element inline-container">
      <Button
        className="bg-primary-light navigation-button mui-appear"
        icon={<i className="material-icons" style={{ color: 'rgba(33, 33, 33, 0.54)' }}>{icon}</i>}
        style={style}
        onClick={onClick}
      />
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </div>
  );
}

export default NavigationButton;
