import { createSelector } from 'reselect';
import find from 'lodash/find';

import { getCompanies } from './base';
import {
  hasAnyAuthority,
  isFreeContractPlan,
  isLimitedContractPlan,
  getAccountModes,
  isGuideEnabled,
  anyUnlimitedContractPlan
} from '../utils/utils';

const baseSelectAuth = state => state.auth;

export const selectAccountPermissions = createSelector([baseSelectAuth, getCompanies], (auth, companies) => {
  const result = { ...auth };
  const isAdmin = hasAnyAuthority(auth, 'ADMIN', 'ADMIN_LOCAL');
  result.modes = getAccountModes(auth, companies);
  result.upgradable = !isAdmin && !!find(companies, c => isFreeContractPlan(c) || isLimitedContractPlan(c));
  result.projectsAccess = hasAnyAuthority(auth, 'ADMIN', 'OPERATOR', 'ADMIN_LOCAL');
  result.groupsAccess = hasAnyAuthority(auth, 'ADMIN', 'OPERATOR', 'ADMIN_LOCAL', 'ANALYST');
  result.designerAccess = hasAnyAuthority(auth, 'ADMIN', 'DESIGNER');
  result.multipleCompanies = companies && companies.length > 1;
  result.guideEnabled = isGuideEnabled(companies);
  result.projectAddAllowed = anyUnlimitedContractPlan(companies);

  return result;
});
