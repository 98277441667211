import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import take from 'lodash/take';
import VisSensor from 'react-visibility-sensor';

import Toggle from 'material-ui/Toggle';

import SearchField from '../mui/DebouncedSearchField';
import FilterChip from './FilterChip';
import BaseFilter from './BaseFilter';

const getEnumLabel = (enumType, enumName, value, enumMessages) => {
  const msg = (enumMessages[enumName] || {})[value];
  if (msg) {
    return msg.label || msg.name || msg;
  }
  const enumObject = enumType[value];
  if (enumObject && enumObject.label) {
    return enumObject.label;
  }
  return value;
};

class CategoryFilterChips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
      loadedItems: 20
    };
  }

  handleFilterTextChange = filterText => this.setState({ filterText, loadedItems: 20 });
  handleLoadMore = () => this.setState(({ loadedItems }) => ({ loadedItems: loadedItems + 20 }));
  setScrollContainer = el => (this.scrollContainer = el);

  getLabel = (value, label, none) => {
    const { filter, enumMessages, filterMessages } = this.props;
    if (none) {
      const noneLabel = filterMessages?.noneLabel;
      if (noneLabel) {
        return noneLabel;
      }
    }
    return filter.type === 'ENUM' ? getEnumLabel(filter.enum, filter.enumName, value, enumMessages) : label || value;
  };

  render() {
    const { items, filter, activeValues } = this.props;
    const { onToggleValue } = this.props;
    const { filterText, loadedItems } = this.state;
    const shouldFilter =
      filter.filterLimit == null ? items.length > 6 : filter.filterLimit > 0 && items.length > filter.filterLimit;
    const activeItems = activeValues.items || {};
    const mappedItems = map(items, ({ value, label, color, none, count, countFiltered }) => ({
      value,
      count,
      countFiltered,
      active: activeItems[value],
      label: this.getLabel(value, label, none),
      color,
      none
    }));
    const filteredItems =
      filterText && shouldFilter
        ? _filter(mappedItems, i => i.label.toLowerCase().indexOf(filterText.toLowerCase()) > -1)
        : mappedItems;
    const canLoadMore = filteredItems.length > loadedItems;
    const filteredSortedItems = filteredItems;
    const filteredLoadedItems = canLoadMore ? take(filteredSortedItems, loadedItems) : filteredSortedItems;
    return (
      <div className="category-filter__chips">
        {shouldFilter && (
          <div style={{ paddingBottom: 6 }}>
            <SearchField value={filterText} onValueChange={this.handleFilterTextChange} debounce={10} compact />
          </div>
        )}
        <div className="category-filter__chips-container" ref={this.setScrollContainer}>
          {map(filteredLoadedItems, item => (
            <FilterChip key={item.value} handleToggle={() => onToggleValue(filter, item.value)} {...item} />
          ))}
          <VisSensor
            key={`${loadedItems}-${items.length}-${filterText}`}
            containment={this.scrollContainer}
            onChange={visible => visible && canLoadMore && this.handleLoadMore()}
          >
            <div style={{ minHeight: '1px', minWidth: '1px', display: 'inline-block' }} />
          </VisSensor>
        </div>
      </div>
    );
  }
}

const CategoryFilter = props => {
  const {
    filter,
    label,
    matchAllLabel,
    unsetAllLabel,
    description,
    expanded,
    alwaysExpanded,
    activeValues,
    filterMessages,
    enumMessages
  } = props;
  const { onToggleExpanded, onToggleValue, onUnsetAll, onSetMatchAll } = props;
  const activeItems = activeValues.items || {};
  let renderedItems = filter.items;
  const isExpanded = alwaysExpanded || expanded;
  if (!isExpanded) {
    renderedItems = _filter(filter.items, ({ value }) => activeItems[value]);
  }
  const activeItemsSize = _size(activeItems);
  return (
    <BaseFilter
      expanded={isExpanded}
      alwaysExpanded={alwaysExpanded}
      label={label}
      description={description}
      filter={filter}
      onToggleExpanded={onToggleExpanded}
    >
      {renderedItems.length > 0 && (
        <CategoryFilterChips
          items={renderedItems}
          filter={filter}
          activeValues={activeValues}
          filterMessages={filterMessages}
          enumMessages={enumMessages}
          onToggleValue={onToggleValue}
        />
      )}
      {filter.supportsMatchAll && (isExpanded || activeValues.matchAll) && activeItemsSize > 1 && (
        <Toggle
          style={{ marginTop: '8px' }}
          toggled={activeValues.matchAll}
          onToggle={() => onSetMatchAll(filter, !activeValues.matchAll)}
          label={matchAllLabel}
          labelPosition={'right'}
        />
      )}
      {onUnsetAll && activeItemsSize > 1 && (
        <a role="button" className="link-button mui-margin-half" onClick={() => onUnsetAll(filter)}>
          <span>{unsetAllLabel}</span>
        </a>
      )}
    </BaseFilter>
  );
};

CategoryFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  label: PropTypes.any,
  matchAllLabel: PropTypes.string,
  description: PropTypes.any,
  expanded: PropTypes.bool,
  activeValues: PropTypes.object.isRequired,
  enumMessages: PropTypes.object.isRequired,
  onToggleExpanded: PropTypes.func,
  onToggleValue: PropTypes.func.isRequired,
  onSetMatchAll: PropTypes.func
};

export default CategoryFilter;
