import React, { useState } from 'react';

import Text from '../superfield/SuperText';
import ColorPicker from '../color/ColorPicker';
import Button from '../mui/Button';

import { formatMessage } from '../../utils/utils';

import withIntl from '../withIntl';

export const MIN_NAME_LENGTH = 3;

const TagEditor = props => {
  const {
    onSave,
    onCancel,
    intl: { messages },
    ...rest
  } = props;
  const [name, setName] = useState(rest.name);
  const [color, setColor] = useState(rest.color);
  const [nameError, setNameError] = useState(false);
  const handleChangeName = e => {
    setName(e.target.value);
    if (nameError) {
      setNameError(false);
    }
  };
  const handleSave = () => {
    if (!name || name.length < MIN_NAME_LENGTH) {
      setNameError(true);
    } else {
      onSave({ ...rest, name, color });
    }
  };
  return (
    <div>
      <div className="mui-padded mui-padded-horizontal-x2">
        <div className="mui-fields-container">
          <div className="mui-fields-label">{messages.nameLabel}</div>
          <Text
            value={name}
            onChange={handleChangeName}
            style={{ margin: 0, maxWidth: '164px' }}
            autoFocus
            error={nameError && formatMessage(messages.validation.nameLength, [MIN_NAME_LENGTH])}
          />
        </div>
        <div className="mui-fields-container">
          <div className="mui-fields-label">{messages.colorLabel}</div>
          <ColorPicker color={color} onChange={setColor} />
        </div>
      </div>
      <div className="mui-padded-half border-top border-color-primary__50 container-flex-row ai-center fw-yes jc-flex-end">
        <div className="mui-padded-half">
          <Button label={messages.cancelLabel} onClick={onCancel} />
        </div>
        <div className="mui-padded-half">
          <Button label={messages.confirmLabel} primary onClick={handleSave} />
        </div>
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    validation: {
      nameLength: 'Použijte minimálně {0} znaky'
    },
    colorLabel: 'Barva',
    nameLabel: 'Název tagu',
    cancelLabel: 'Zrušit',
    confirmLabel: 'Uložit'
  },
  sk: {
    validation: {
      nameLength: 'Použite aspoň {0} znaky'
    },
    colorLabel: 'Farba',
    nameLabel: 'Názov tagu',
    cancelLabel: 'Zrušiť',
    confirmLabel: 'Uložiť'
  },
  en: {
    validation: {
      nameLength: 'Use at least {0} characters'
    },
    colorLabel: 'Color',
    nameLabel: 'Tag name',
    cancelLabel: 'Cancel',
    confirmLabel: 'Save'
  }
};

export default withIntl(TagEditor, intlMessages);
