import { handleActions } from 'redux-actions';
import * as c from '../../constants/constants';

export const defaultState = [];

export default handleActions(
  {
    [c.COMPANY_OPERATORS_GET]: (state, action) => {
      return [...action.payload];
    },
    [c.COMPANY_OPERATORS_UNSET]: () => defaultState
  },
  defaultState
);
