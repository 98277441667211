import getMuiThemeMU from 'material-ui/styles/getMuiTheme';

import { BRAND } from '../constants/constants';

const muiTheme = getMuiThemeMU({
  ...BRAND.theme,
  zIndex: {
    appBar: 700,
    layer: 1000,
    drawerOverlay: 800,
    drawer: 900,
    ...BRAND.theme.zIndex
  },
  palette: {
    primary1Color: '#049BE5',
    primary2Color: '#029AE4',
    accent1Color: '#FF9700',
    pickerHeaderColor: '#049BE5',
    textColor: 'rgba(33, 33, 33, 0.87)',
    ...BRAND.theme.palette
  }
});

export default muiTheme;
