import { handleActions } from 'redux-actions';
import * as c from '../../constants/constants';

const defaultState = {
  loading: false,
  saving: false,
  saved: false,
  item: {},
  metadata: {}
};

export default handleActions(
  {
    [c.SIMULATION_MODEL_GET.PENDING]: state => ({ ...state, loading: true }),
    [c.SIMULATION_MODEL_GET.SUCCESS]: (state, { payload: { payload } }) => ({
      ...state,
      loading: false,
      item: payload
    }),
    [c.SIMULATION_MODEL_GET.ERROR]: state => ({ ...state, loading: false }),
    [c.SIMULATION_MODEL_CREATE.PENDING]: state => ({ ...state, saving: true }),
    [c.SIMULATION_MODEL_UPDATE.PENDING]: state => ({ ...state, saving: true }),
    [c.SIMULATION_MODEL_CREATE.SUCCESS]: (state, { payload: { payload } }) => ({
      ...state,
      item: payload,
      saving: false,
      saved: true
    }),
    [c.SIMULATION_MODEL_UPDATE.SUCCESS]: (state, { payload: { payload } }) => ({
      ...state,
      item: payload,
      saving: false,
      saved: true
    }),
    [c.SIMULATION_MODEL_CREATE.ERROR]: state => ({ ...state, saving: false, saved: false }),
    [c.SIMULATION_MODEL_UPDATE.ERROR]: state => ({ ...state, saving: false, saved: false }),
    [c.SIMULATION_MODEL_METADATA_GET.PENDING]: state => ({ ...state, loading: true }),
    [c.SIMULATION_MODEL_METADATA_GET.SUCCESS]: (state, { payload: { payload } }) => ({
      ...state,
      loading: false,
      metadata: payload
    }),
    [c.SIMULATION_MODEL_METADATA_GET.ERROR]: state => ({ ...state, loading: false }),
    [c.SIMULATION_DEFINITION_CREATE.PENDING]: state => ({ ...state, sharing: true }),
    [c.SIMULATION_DEFINITION_CREATE.SUCCESS]: state => ({ ...state, sharing: false }),
    [c.SIMULATION_DEFINITION_CREATE.ERROR]: state => ({ ...state, sharing: false }),
    [c.SIMULATION_MODEL_CLEAR]: () => defaultState
  },
  defaultState
);
