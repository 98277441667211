import React, { useState } from 'react';
import cn from 'classnames';

import IconButton from './IconButton';

const BacksideToggle = props => {
  const { selected, children, onToggle } = props;
  const [hovered, setHovered] = useState(false);
  const className = cn({ 'mui-backside-toggle': true, hovered, selected });
  return (
    <div
      className={className}
      role="button"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onToggle}
    >
      <div
        className={`container-flex-row ai-center jc-center mui-backside-toggle__back-face bg-highlight${
          selected ? '' : '-light'
        }`}
      >
        <IconButton primary={selected}>
          <i className={`material-icons text-${selected ? 'white' : 'muted'}`}>check</i>
        </IconButton>
      </div>
      <div className="mui-backside-toggle__front-face">{children}</div>
    </div>
  );
};

export default BacksideToggle;
