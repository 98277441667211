import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import BaseContainer from '../basic/BaseContainer';
import CompanySelect from '../../containers/private/company/CompanySelect';
import TeamContainer from './TeamContainer';

import { getLastGroupId } from './TeamUtils';
import * as groupActions from '../../actions/entities/groupActions';
import * as companyActions from '../../actions/entities/companyActions';
import * as activityTemplateActions from '../../actions/entities/activityTemplateActions';
import * as GroupApi from '../../api/entities/groupApi';

const BASE_PATH = '/groups';
const TEAM_NEW_PATH = `${BASE_PATH}/new`;

class TeamsContainer extends React.Component {
  state = {
    teamsCount: 0,
    initialLoad: false
  };

  componentDidMount() {
    const { groupId, company, setCurrentCompany } = this.props;
    const companyPromise = groupId
      ? GroupApi.getGroup(groupId).then(({ payload: { company } }) => company)
      : Promise.resolve();
    companyPromise
      .then(c => {
        if (c && (!company || company.id !== c.id)) {
          setCurrentCompany(c);
        } else {
          this.loadTeams(this.props);
        }
      })
      .finally(() => this.setState({ initialLoad: true }));
    // if (groupId) {
    //   // initialize
    //   GroupApi.getGroup(groupId).then(({ payload }) => {
    //     if (!company || company.id !== payload.company.id) {
    //       setCurrentCompany(company);
    //     } else {
    //       this.loadTeams(this.props);
    //     }
    //   });
    // } else {
    //   this.loadTeams(this.props);
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company?.id !== this.props.company?.id || prevProps.groupId !== this.props.groupId) {
      this.loadTeams(this.props);
    }
  }

  loadTeams = props => {
    const {
      company,
      router,
      location: { pathname }
    } = props;
    const { getActivityTemplates } = props;
    if (!company) {
      return;
    }
    getActivityTemplates(company.id);
    GroupApi.getGroups(null, company.id, null, { page: 0, size: 1 }).then(({ items, page }) => {
      this.setState({ teamsCount: page.totalElements });
      if (pathname === BASE_PATH) {
        if (items.length > 0) {
          const lastTeamId = getLastGroupId(company.id);
          router.push(`${BASE_PATH}/${lastTeamId || items[0].id}`);
        } else {
          router.push(TEAM_NEW_PATH);
        }
      }
    });
  };

  handleSelect = () => {
    const { router } = this.props;
    router.push(BASE_PATH);
  };

  render() {
    const { company, groupId } = this.props;
    const { teamsCount, initialLoad } = this.state;
    return (
      <div>
        <CompanySelect includeDemo={false} onSelect={this.handleSelect} required />
        {company && initialLoad && (
          <BaseContainer>
            <TeamContainer company={company} groupId={groupId} teamsCount={teamsCount} />
          </BaseContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    entities: {
      currentCompany: { item }
    }
  } = state;
  const { groupId } = ownProps.params || {};
  return {
    intl,
    groupId,
    company: item
  };
};

const actions = {
  ...groupActions,
  ...companyActions,
  ...activityTemplateActions
};

export default withRouter(connect(mapStateToProps, actions)(TeamsContainer));
