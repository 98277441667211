import React from 'react';
import PropTypes from 'prop-types';

import ToggleField from 'material-ui/Toggle';

const Toggle = props => {
  const {
    input,
    label,
    meta: { visited, error },
    ...custom
  } = props;

  const errorText = visited && error;
  return (
    <div>
      <ToggleField
        label={label}
        labelStyle={{ fontWeight: 400, lineHeight: '23px' }}
        toggled={!!input.value}
        onToggle={input.onChange}
        {...input}
        {...custom}
      />
      {errorText && <div className="text-error text-sz-sm">{errorText}</div>}
    </div>
  );
};

Toggle.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  custom: PropTypes.object
};

export default Toggle;
