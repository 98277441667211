import { createAction } from 'redux-actions';

import * as c from '../../constants/constants';
import * as Api from '../../api/entities/applicantApi';

import { createApiCallAction } from '../apiCallAction';
import { switchModal } from '../options/modalActions';
import { MODAL_COMPARISON_PARTICIPANTS_SELECT, MODAL_UNFINISHED_NOTIFICATION } from '../../constants/constants';

export const refreshApplicant = createAction(c.APPLICANT_REFRESH);

function requestIdentifier(companySlug, projectSlug, lang) {
  return companySlug + '/' + projectSlug + '/?' + lang;
}

export function getResults(companySlug, projectSlug, lang, query, forceLoad) {
  return (dispatch, getState) => {
    const identifier = requestIdentifier(companySlug, projectSlug, lang);
    const { entities: { applicants } } = getState();
    if (!forceLoad && applicants.identifier === identifier) {
      dispatch({ type: c.RESULTS_GET.SUCCESS, payload: applicants });
      return Promise.resolve({ payload: applicants });
    } else {
      dispatch({ type: c.RESULTS_GET.PENDING });
      return Api.getResults(companySlug, projectSlug, lang, query).then(
        res => dispatch({ type: c.RESULTS_GET.SUCCESS, payload: { identifier, ...res.payload } }),
        () => dispatch({ type: c.RESULTS_GET.ERROR, payload: null })
      );
    }
  };
}

export function getResult(companySlug, projectSlug, participantId, lang, loadExtraActivities) {
  return dispatch => {
    dispatch({ type: c.RESULT_GET.PENDING });
    return Api.getResult(companySlug, projectSlug, participantId, lang, loadExtraActivities).then(
      res => dispatch({ type: c.RESULT_GET.SUCCESS, payload: res.payload }),
      () => dispatch({ type: c.RESULT_GET.ERROR, payload: null })
    );
  };
}

export function getApplicantEvaluationDescription(gameId) {
  return {
    types: [c.APPLICANT_EVALUATION_DESCRIPTION_GET, c.APPLICANT_LOADING],
    callAPI: () => Api.getApplicantEvaluationDescription(gameId),
    forcedCall: true,
    entity: 'applicant'
  };
}

export function setUserProjectAck(userId, projectId, status, statusTimestamp) {
  return dispatch => {
    const ack = { userId, projectId, status, statusTimestamp };
    dispatch({ type: c.APPLICANT_PROJECT_ACK, payload: ack });
    return Api.setUserProjectsStatusAcks([ack]).then(
      () => dispatch({ type: c.APPLICANT_PROJECT_ACK_SUCCESS, payload: ack }),
      () => dispatch({ type: c.APPLICANT_PROJECT_ACK_ERROR, payload: ack })
    );
  };
}

export function setUserProjectsAcks(acks) {
  return dispatch => {
    dispatch({ type: c.APPLICANT_PROJECTS_ACKS, payload: acks });
    // TODO -- not the best approach, rather use response
    return Api.setUserProjectsStatusAcks(acks).then(
      () => dispatch({ type: c.APPLICANT_PROJECTS_ACKS_SUCCESS, payload: acks }),
      () => dispatch({ type: c.APPLICANT_PROJECTS_ACKS_ERROR, payload: acks })
    );
  };
}

export function removeEvaluationDescription() {
  return {
    type: c.APPLICANT_EVALUATION_DESCRIPTION_GET,
    payload: null
  };
}

export function setApplicationStatuses(values) {
  return {
    type: c.API,
    payload: {
      asyncType: c.APPLICANT_SET_APPLICATION_STATUS
    },
    meta: {
      url: '/api/v1/users/projects/application_statuses',
      method: 'POST',
      body: {
        values
      }
    }
  };
}

export const beginUnfinishedProfilesNotification = participants => {
  return switchModal({
    id: MODAL_UNFINISHED_NOTIFICATION,
    values: participants
  });
};

export const selectComparisonParticipants = (participants, project, preselected) => {
  return switchModal({
    id: MODAL_COMPARISON_PARTICIPANTS_SELECT,
    participants,
    project,
    preselected
  });
};

export const notifyProfiles = createApiCallAction(c.APPLICANT_NOTIFICATIONS, Api.notifyProfiles, {
  transformArgsToMeta: ([values]) => ({ values })
});
