import React from 'react';
import PropTypes from 'prop-types';

import ExpanderLabel from '../basic/ExpanderLabel';

class FilterPart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: !props.collapsible
    };
  }

  toggleExpand = () => {
    const { onToggleExpanded } = this.props;
    if (onToggleExpanded) {
      onToggleExpanded(!this.props.expanded);
    } else {
      this.setState(({ expanded }) => ({ expanded: !expanded }));
    }
  };

  isExpanded = () => {
    return this.props.onToggleExpanded ? this.props.expanded : this.state.expanded;
  };

  render() {
    const { label, description, collapsible, children, render } = this.props;
    const expanded = this.isExpanded();
    return (
      <div className={`filter-part${expanded ? ' expanded' : ''}`}>
        {collapsible ? (
          <div className="filter-part-title">
            <ExpanderLabel expanded={expanded} label={label} toggleExpand={this.toggleExpand} />
          </div>
        ) : (
          <div className="filter-part-title">{label}</div>
        )}
        {expanded && description && <div className="filter-part-description">{description}</div>}
        {children && <div className="filter-part-children">{children}</div>}
        {render && <div className="filter-part-children">{render({ expanded })}</div>}
      </div>
    );
  }
}

FilterPart.propTypes = {
  collapsible: PropTypes.bool,
  label: PropTypes.any.isRequired,
  children: PropTypes.any,
  render: PropTypes.func
};

export default FilterPart;
