import React from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';

import MatchSection from '../matching/MatchSection';
import MatchSectionDivider from '../matching/MatchSectionDivider';
import MatchContainer from '../matching/MatchContainer';
import MatchItem from '../matching/MatchItem';
import MatchBadge from '../matching/MatchBadge';

import { renderDataSourceValue, renderEnumValue } from '../matching/Utils';

import { EBool } from '../../constants/enum';
import { selectDataSourcesMap } from '../../selectors/dataSources';

const LanguageMatchItem = props => {
  const { value, range, eliminating, matches, dataSourceMap, intl } = props;
  const itemLabel = renderDataSourceValue({ value }, dataSourceMap);
  if (!range) {
    return (
      <MatchBadge matches={matches} eliminating={eliminating}>
        {itemLabel}
      </MatchBadge>
    );
  }
  const { lower, upper } = range;
  let msg;
  let rangeMatches;
  let rangeEliminating = eliminating || range.eliminating;
  const enumIdentifier = 'ELanguageLevel';
  if (!upper) {
    msg = renderEnumValue({ value: lower.value }, intl, enumIdentifier);
    rangeMatches = lower.matches;
    rangeEliminating |= lower.eliminating;
  } else {
    if (!lower) {
      msg = `< ${renderEnumValue({ value: lower.upper }, intl, enumIdentifier)}`;
      rangeMatches = upper.matches;
      rangeEliminating |= upper.eliminating;
    } else {
      const lMsg = renderEnumValue({ value: lower.value }, intl, enumIdentifier);
      const uMsg = renderEnumValue({ value: lower.upper }, intl, enumIdentifier);
      msg = `${lMsg} – ${uMsg}`;
      rangeMatches = lower.matches && upper.matches;
      rangeEliminating |= lower.eliminating || upper.eliminating;
    }
  }
  return (
    <MatchBadge matches={rangeMatches} eliminating={rangeEliminating}>
      {itemLabel}: {msg}
    </MatchBadge>
  );
};

const TalentRequirementsMatch = props => {
  const { match, intl, dataSourcesMap } = props;
  if (!match) {
    return null;
  }
  const messages = intl.messages.matching.requirements;
  const eBoolMessages = intl.messages.constants.enums.EBool;
  return (
    <div style={{ padding: '0 0 8px' }}>
      {match.education && (
        <MatchSection icon="school" title={messages.education.title}>
          {match.education.level && (
            <MatchContainer title={messages.education.level.title}>
              <MatchItem
                {...match.education.level}
                labelRenderer={({ value }) => renderEnumValue({ value }, intl, 'EEducation')}
              />
            </MatchContainer>
          )}
          {match.education.fields && (
            <MatchContainer title={messages.education.fields.title}>
              <MatchItem
                {...match.education.fields}
                labelRenderer={({ value }) => renderDataSourceValue({ value }, dataSourcesMap.educationFields)}
              />
            </MatchContainer>
          )}
          {match.education.currentlyStudying && (
            <MatchContainer title={messages.education.currentlyStudying.title}>
              <MatchItem
                {...match.education.currentlyStudying}
                labelRenderer={({ value }) =>
                  value === EBool.NO.key ? eBoolMessages.NO.label : eBoolMessages.YES.label}
              />
            </MatchContainer>
          )}
        </MatchSection>
      )}
      {match.education && (match.workExperience || match.skills) && <MatchSectionDivider />}
      {match.workExperience && (
        <MatchSection icon="work" title={messages.workExperience.title}>
          {match.workExperience.years && (
            <MatchContainer title={messages.workExperience.years.title}>
              <MatchItem
                {...match.workExperience.years}
                labelRenderer={({ value }) => renderEnumValue({ value }, intl, 'EWorkExperienceYears')}
              />
            </MatchContainer>
          )}
          {match.workExperience.role && (
            <MatchContainer title={messages.workExperience.previousRole.title}>
              <MatchItem
                {...match.workExperience.role}
                labelRenderer={({ value }) => renderEnumValue({ value }, intl, 'EWorkExperiencePosition')}
              />
            </MatchContainer>
          )}
        </MatchSection>
      )}
      {match.workExperience && match.skills && <MatchSectionDivider />}
      {match.skills && (
        <MatchSection icon="palette" title={messages.skills.title}>
          {match.skills.languages && (
            <MatchContainer title={messages.skills.languages.title}>
              {map(match.skills.languages.list.items || [], l => (
                <LanguageMatchItem key={l.value} {...l} intl={intl} dataSourceMap={dataSourcesMap.languages} />
              ))}
            </MatchContainer>
          )}
          {match.skills.other && (
            <MatchContainer title={messages.skills.other.title}>
              <MatchItem {...match.skills.other} labelRenderer={({ value }) => value} />
            </MatchContainer>
          )}
        </MatchSection>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    intl: state.intl,
    dataSourcesMap: selectDataSourcesMap(state, ownProps)
  }
};

export default connect(mapStateToProps)(TalentRequirementsMatch);
