import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import Overlay from '../basic/Overlay';
import Button from '../mui/Button';
import ParticipantName from '../basic/ParticipantName';

import { formatMessage } from '../../utils/utils';
import ParticipantsSelectPanel from '../applicant/ParticipantsSelectPanel';

class UnfinishedNotifications extends React.Component {
  state = {
    selectedValues: []
  };

  componentDidMount() {
    this.setState({ selectedValues: this.props.values });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values !== this.props.values) {
      this.setState({ selectedValues: this.props.values });
    }
  }

  handleParticipantSelectionChange = selectedValues => this.setState({ selectedValues });

  render() {
    const { id, intl, values } = this.props;
    const { notifyProfiles, switchModal } = this.props;
    const { selectedValues } = this.state;

    const messages = intl.messages.components.modals.notifications.unfinished;

    const close = () => switchModal({ id, open: false });
    return (
      <Overlay onRequestClose={close} full>
        <div
          style={{ maxWidth: 552, margin: '0 auto', height: '100%', padding: '32px 16px' }}
          className="container-flex-column jc-center"
        >
          <div
            className="mui-card"
            style={{ maxHeight: '100%', overflow: 'auto', animation: 'vb-appear 0.2s ease-in-out' }}
          >
            <div className="mui-padded-x2 text-muted" style={{ fontSize: '14px' }}>
              {values.length === 1 ? (
                <div>
                  {formatMessage(messages.textSingle, [
                    <b key="participant">
                      <ParticipantName variant="mini" {...values[0].participant} />
                    </b>
                  ])}
                </div>
              ) : (
                <div>
                  <div className="mui-padded-vertical">{messages.text}</div>
                  <div
                    className="mui-padded-vertical bg-primary-light"
                    style={{ margin: '8px -16px 16px', maxHeight: 336, overflow: 'auto' }}
                  >
                    <ParticipantsSelectPanel
                      participants={values}
                      onSelectionChange={this.handleParticipantSelectionChange}
                    />
                  </div>
                  <div>{messages.textQuestion}</div>
                </div>
              )}
            </div>
            <div className="mui-padded text-right">
              <Button label={messages.cancelButton} onClick={close} />
              <Button
                primary
                style={{ marginLeft: 8 }}
                label={messages.confirmButton}
                disabled={selectedValues.length === 0}
                onClick={() => {
                  const data = _map(selectedValues, ({ participant: { id }, projectId }) => ({
                    userId: id,
                    projectId,
                    type: 'unfinished'
                  }));
                  notifyProfiles(data);
                  close();
                }}
              />
            </div>
          </div>
        </div>
      </Overlay>
    );
  }
}

UnfinishedNotifications.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  switchModal: PropTypes.func.isRequired,
  notifyProfiles: PropTypes.func.isRequired
};

export default UnfinishedNotifications;
