import { createApiCallAction } from '../apiCallAction';
import * as Api from '../../api/entities/companyApi';
import * as c from '../../constants/constants';

export function getCompanies(forced = false) {
  return {
    types: [c.COMPANIES_GET, c.COMPANIES_LOADING],
    callAPI: () => Api.getCompanies(),
    forcedCall: forced,
    entity: 'companies'
  };
}

export function getCompanyOperators(companyId, forced = true) {
  return {
    types: [c.COMPANY_OPERATORS_GET, c.COMPANY_OPERATORS_LOADING],
    callAPI: () => Api.getCompanyOperators(companyId),
    forcedCall: forced,
    entity: 'companyOperators'
  };
}
export const unsetCompanyOperators = () => ({ type: c.COMPANY_OPERATORS_UNSET });

const getCompanyProfileAction = createApiCallAction(c.COMPANY_PROFILE_GET, Api.getCompanyProfile);
export const getCompanyProfile = companyId => getCompanyProfileAction(companyId);
export const unsetCompanyProfile = () => ({ type: c.COMPANY_PROFILE_UNSET });

const saveCompanyProfileAction = createApiCallAction(c.COMPANY_PROFILE_SAVE, Api.saveCompanyProfile);
export const saveCompanyProfile = (companyId, profile) => saveCompanyProfileAction(companyId, profile);

const publishCompanyProfileDraftAction = createApiCallAction(
  c.COMPANY_PROFILE_DRAFT_PUBLISH,
  Api.publishCompanyProfileDraft
);
export const publishCompanyProfileDraft = companyId => publishCompanyProfileDraftAction(companyId);

const unpublishCompanyProfileDraftAction = createApiCallAction(
  c.COMPANY_PROFILE_DRAFT_UNPUBLISH,
  Api.unpublishCompanyProfileDraft
);
export const unpublishCompanyProfileDraft = companyId => unpublishCompanyProfileDraftAction(companyId);

const discardCompanyProfileDraftAction = createApiCallAction(
  c.COMPANY_PROFILE_DRAFT_DISCARD,
  Api.discardCompanyProfileDraft
);
export const discardCompanyProfileDraft = companyId => discardCompanyProfileDraftAction(companyId);

export const setCurrentCompany = company => ({ type: c.COMPANY_CURRENT_SET, payload: company });

const getCompanyTalentMarketPlanAction = createApiCallAction(
  c.COMPANY_TALENT_MARKET_PLAN_CURRENT_GET,
  Api.getCompanyTalentMarketPlan
);
export const getCompanyTalentMarketPlan = (companyId, lang) => getCompanyTalentMarketPlanAction(companyId, lang);

const setCompanyTalentMarketPlanAction = createApiCallAction(
  c.COMPANY_TALENT_MARKET_PLAN_CURRENT_SET,
  Api.setCompanyTalentMarketPlan,
  {
    transformArgsToMeta: ([, , lang]) => ({ lang })
  }
);
export const setCompanyTalentMarketPlan = (companyId, plan, lang) =>
  setCompanyTalentMarketPlanAction(companyId, plan, lang);
