import { push } from 'react-router-redux';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';

import * as companyActions from './companyActions';
import { switchLoader } from '../options/loaderActions';
import { showSnackbar } from '../options/snackbarsActions';
import { toggleSidebar } from '../options/sidebarActions';
import * as Api from '../../api/entities/projectApi';
import * as c from '../../constants/constants';
import { createApiCallAction } from '../apiCallAction';
import { getSidebarSettings, getProjectBaseUrl } from '../../utils/utils';

// special projects (e.g. DEMO) have company defined, because it may differ from the current one
export function getProjectsAll(companies) {
  return {
    type: c.PROJECTS_GET_ALL,
    payload: flatMap(companies, ({ projects, ...company }) => map(projects, p => ({ company, ...p })))
  };
}

export function getProject(companyId, projectId) {
  return dispatch => {
    dispatch(switchLoader(c.PROJECT_LOADING));
    return Api.getProject(companyId, projectId)
      .then(
        res => {
          const p = res.payload;
          const payload = p.company ? p : { ...p, company: { id: companyId } };
          return dispatch({ type: c.PROJECT_GET, payload });
        },
        () => dispatch({ type: c.PROJECT_GET, payload: null })
      )
      .finally(() => dispatch(switchLoader(c.PROJECT_LOADING)));
  };
}

export function unsetProject() {
  return {
    type: c.PROJECT_GET,
    payload: {}
  };
}

export function getProjectTemplates(lang, companyId, templateId, projectType) {
  return dispatch => {
    dispatch({ type: c.PROJECT_TEMPLATES_GET.PENDING });
    return Api.getProjectTemplates(lang, companyId, templateId, projectType).then(
      res => dispatch({ type: c.PROJECT_TEMPLATES_GET.SUCCESS, payload: res.items }),
      () => dispatch({ type: c.PROJECT_TEMPLATES_GET.ERROR, payload: null })
    );
  };
}

export function reloadProjects() {
  return dispatch =>
    dispatch(companyActions.getCompanies(true)).then(companies => dispatch(getProjectsAll(companies.items)));
}

function shouldToggleSidebar(getState) {
  const {
    options: { sidebar },
    ui
  } = getState();
  return sidebar.open && !getSidebarSettings(ui).docked;
}

function hideSidebarIfPossible(dispatch, getState) {
  if (shouldToggleSidebar(getState)) {
    dispatch(toggleSidebar());
  }
}

export function createProject(companyId, project, onClose) {
  return (dispatch, getState) =>
    Api.createProject(companyId, project)
      .then(() => {
        dispatch({ type: c.PROJECT_GET, payload: project });
        const message = getState().intl.messages.components.pages.private.projectEdit.snackbar.createdMessage;
        const snackbarData = {
          id: 'newProjectSnackbar',
          message: message.replace('{0}', project.name)
        };
        dispatch(showSnackbar(snackbarData));

        return dispatch(reloadProjects());
      })
      .then(() => {
        if (onClose) {
          return onClose();
        } else {
          return dispatch(push(getProjectBaseUrl(project)));
        }
      });
}

export function editProject(companyId, projectId, project, onClose) {
  return (dispatch, getState) => {
    return Api.editProject(companyId, projectId, project)
      .then(() => {
        dispatch({ type: c.PROJECT_GET, payload: project });
        const message = getState().intl.messages.components.pages.private.projectEdit.snackbar.savedMessage;
        const snackbarData = {
          id: 'editedProjectSnackbar',
          message: message.replace('{0}', project.name)
        };
        dispatch(showSnackbar(snackbarData));
        return dispatch(reloadProjects());
      })
      .then(() => {
        if (onClose) {
          return onClose();
        } else {
          return dispatch(push(getProjectBaseUrl(project)));
        }
      });
  };
}

export function activateProject(project) {
  return (dispatch, getState) =>
    Api.activateProject(project.company.id, project.id).then(() => {
      hideSidebarIfPossible(dispatch, getState);
      // not the best approach -- snackbar should have rather be aware of its type and display message on its own
      const messages = getState().intl.messages.components.modals.confirmActivate;
      const snackbarData = {
        id: 'activatedProjectSnackbar',
        message: `${messages.snackbar1} ${project.name} ${messages.snackbar2}`
      };
      dispatch(showSnackbar(snackbarData));
      return dispatch(reloadProjects());
    });
}

export function finishProject(project) {
  return (dispatch, getState) =>
    Api.finishProject(project.company.id, project.id).then(() => {
      hideSidebarIfPossible(dispatch, getState);
      const messages = getState().intl.messages.components.modals.confirmFinish;
      const snackbarData = {
        id: 'finishedProjectSnackbar',
        message: `${messages.snackbar1} ${project.name} ${messages.snackbar2}`
      };
      dispatch(showSnackbar(snackbarData));
      return dispatch(reloadProjects());
    });
}

export function deleteProject(project) {
  return (dispatch, getState) =>
    Api.deleteProject(project.company.id, project.id)
      .then(() => {
        hideSidebarIfPossible(dispatch, getState);
        const messages = getState().intl.messages.components.modals.confirmDelete;
        const snackbarData = {
          id: 'deletedProjectSnackbar',
          message: `${messages.snackbar1} ${project.name} ${messages.snackbar2}`
        };
        dispatch(showSnackbar(snackbarData));
        return dispatch(reloadProjects());
      })
      .then(() => dispatch(push(getProjectBaseUrl(project))));
}

const copyProjectAction = createApiCallAction(c.PROJECT_COPY, Api.copyProject, {
  onSuccess: ({
    dispatch,
    getState,
    payload: {
      payload: { name }
    }
  }) => {
    const message = getState().intl.messages.components.pages.private.projectEdit.snackbar.createdMessage;
    dispatch(
      showSnackbar({
        id: 'copiedProjectSnackbar',
        message: message.replace('{0}', name)
      })
    );
    dispatch(reloadProjects());
  }
});
export function copyProject(project, data) {
  return copyProjectAction(project.company.id, project.id, data);
}

const getProjectInvitationUrlsAction = createApiCallAction(
  c.PROJECT_INVITATION_URLS_GET,
  Api.getProjectInvitationUrls,
  { transformArgsToMeta: ([companySlug, projectSlug]) => ({ companySlug, projectSlug }) }
);
export function getProjectInvitationUrls(companySlug, projectSlug) {
  return getProjectInvitationUrlsAction(companySlug, projectSlug);
}

const createProjectInvitationAction = createApiCallAction(c.PROJECT_INVITATION_CREATE, Api.createProjectInvitation);
export function createProjectInvitation(companySlug, projectSlug, emails) {
  return createProjectInvitationAction(companySlug, projectSlug, emails);
}

const projectParticipantsBatchAction = createApiCallAction(
  c.PROJECT_PARTICIPANTS_BATCH_ACTION,
  Api.participantsBatchAction
);
export function executeProjectParticipantsBatchAction(type, sourceProjectId, participantIds, targetProjectId) {
  return projectParticipantsBatchAction(type, sourceProjectId, participantIds, targetProjectId);
}

const getProjectParticipantsGroupMembershipsAction = createApiCallAction(
  c.PROJECT_PARTICIPANTS_GROUP_MEMBERSHIPS_GET,
  Api.getParticipantsGroupMemberships
);
export function getProjectParticipantsGroupMemberships(projectId) {
  return getProjectParticipantsGroupMembershipsAction(projectId);
}
