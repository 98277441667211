import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'material-ui/Checkbox';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';

import SimpleModal from './SimpleModal';

import { formatMessage } from '../../utils/utils';

class ProjectCopy extends React.Component {
  constructor(props) {
    super(props);
    const messages = props.intl.messages.components.modals.projectCopy;
    this.state = {
      name: props.project.name + ' - ' + messages.groupingAddendum,
      view: true,
      copyAllParticipants: false
    };
  }

  handleNameChange = e => this.setState({ name: e.target.value });
  handleViewToggle = () => this.setState({ view: !this.state.view });
  handleCopyAllCheck = () => this.setState({ copyAllParticipants: !this.state.copyAllParticipants });

  render() {
    const { id, auth, intl, project } = this.props;
    const { copyProject, switchModal } = this.props;

    const messages = intl.messages.components.modals.projectCopy;

    const { name, view, copyAllParticipants } = this.state;

    return (
      <SimpleModal
        open
        id={id}
        callback={confirmed => {
          if (confirmed) {
            copyProject(project, this.state);
          }
        }}
        switchModal={switchModal}
        confirmLabel={messages.confirmButton}
        cancelLabel={messages.cancelButton}
        message={
          <div>
            <p>{formatMessage(messages.message, [<b key="0">{project.name}</b>])}</p>
            <div>
              <TextField
                floatingLabelText={messages.nameLabel}
                floatingLabelFixed
                hintText={messages.nameLabel}
                value={name}
                onChange={this.handleNameChange}
                name="name"
                fullWidth
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <Checkbox
                label={messages.copyAllParticipantsLabel}
                onCheck={this.handleCopyAllCheck}
                checked={copyAllParticipants}
              />
            </div>
            {auth.isAdmin && (
              <div style={{ marginTop: '16px' }}>
                <Toggle label={messages.groupingToggleLabel} toggled={view} onToggle={this.handleViewToggle} />
                <p className="small">{messages.groupingToggleHelperText}</p>
              </div>
            )}
          </div>
        }
      />
    );
  }
}

ProjectCopy.propTypes = {
  id: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  project: PropTypes.any.isRequired,
  intl: PropTypes.object.isRequired,
  switchModal: PropTypes.func.isRequired,
  copyProject: PropTypes.func.isRequired
};

export default ProjectCopy;
