import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import InfoTooltip from '../basic/InfoTooltip';

import withIntl from '../withIntl';
import FormatNumber from '../basic/FormatNumber';

const ERank = {
  PERFECT: { key: 'PERFECT', low: 0.95, exclusive: true },
  GREAT: { key: 'GREAT', low: 0.9 },
  GOOD: { key: 'GOOD', low: 0.75 },
  FAIR: { key: 'FAIR', low: 0.6 },
  AVERAGE: { key: 'AVERAGE', low: 0.4 },
  LOW: { key: 'LOW', low: 0.2 },
  VERY_LOW: { key: 'VERY_LOW', low: 0 }
};

const RANKS = [ERank.PERFECT, ERank.GREAT, ERank.GOOD, ERank.FAIR, ERank.AVERAGE, ERank.LOW, ERank.VERY_LOW];

const getConvergenceRank = c => {
  for (let i = 0; i < RANKS.length; i += 1) {
    const r = RANKS[i];
    if (r.exclusive ? c > r.low : c >= r.low) {
      return r;
    }
  }
  return ERank.VERY_LOW;
};

const getFitRank = f => {
  return getConvergenceRank(f);
};

const FitRankRanges = props => {
  const { rank, type, messages } = props;
  const isConvergence = type === 'convergence';
  const typeMessages = isConvergence ? messages.convergenceRank : messages.fitRank;
  return (
    <div style={{ minWidth: '220px' }}>
      {_map(RANKS, (r, idx) => (
        <div key={r.key} className="row" style={{ fontWeight: rank.key === r.key ? 500 : 400 }}>
          <div className="col-xs-5">
            <FormatNumber value={r.low * 100} fractionDigits={0} />
            {idx === 0 ? (
              '+'
            ) : (
              <span>
                {' '}
                &mdash; <FormatNumber value={RANKS[idx - 1].low * 100} fractionDigits={0} />
              </span>
            )}
          </div>
          <div className="col-xs-7">{typeMessages[r.key]}</div>
        </div>
      ))}
    </div>
  );
};

const FitRankLabel = props => {
  const {
    value,
    type,
    intl: { messages }
  } = props;

  const isConvergence = type === 'convergence';
  const rank = isConvergence ? getConvergenceRank(value) : getFitRank(value);
  const typeMessages = isConvergence ? messages.convergenceRank : messages.fitRank;

  return (
    <InfoTooltip dark content={<FitRankRanges rank={rank} type={type} messages={messages} />}>
      <span>{typeMessages[rank.key]}</span>
    </InfoTooltip>
  );
};

FitRankLabel.propTypes = {
  value: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['fit', 'convergence'])
};

const intlMessages = {
  cs: {
    fitRank: {
      PERFECT: 'Perfektný',
      GREAT: 'Výborný',
      GOOD: 'Dobrý',
      FAIR: 'Adekvátní',
      AVERAGE: 'Průměrný',
      LOW: 'Nízký',
      VERY_LOW: 'Velmi nízký'
    },
    convergenceRank: {
      PERFECT: 'Perfektná',
      GREAT: 'Výborná',
      GOOD: 'Dobrá',
      FAIR: 'Adekvátní',
      AVERAGE: 'Průměrná',
      LOW: 'Nízká',
      VERY_LOW: 'Velmi nízká'
    }
  },
  sk: {
    fitRank: {
      PERFECT: 'Perfektný',
      GREAT: 'Výborný',
      GOOD: 'Dobrý',
      FAIR: 'Adekvátny',
      AVERAGE: 'Priemerný',
      LOW: 'Nízky',
      VERY_LOW: 'Veľmi nízky'
    },
    convergenceRank: {
      PERFECT: 'Perfektná',
      GREAT: 'Výborná',
      GOOD: 'Dobrá',
      FAIR: 'Adekvátna',
      AVERAGE: 'Priemerná',
      LOW: 'Nízka',
      VERY_LOW: 'Veľmi nízka'
    }
  },
  en: {
    fitRank: {
      PERFECT: 'Perfect',
      GREAT: 'Great',
      GOOD: 'Good',
      FAIR: 'Fair',
      AVERAGE: 'Average',
      LOW: 'Low',
      VERY_LOW: 'Very low'
    },
    convergenceRank: {
      PERFECT: 'Perfect',
      GREAT: 'Great',
      GOOD: 'Good',
      FAIR: 'Fair',
      AVERAGE: 'Average',
      LOW: 'Low',
      VERY_LOW: 'Very low'
    }
  }
};

export default withIntl(FitRankLabel, intlMessages);
