import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import map from 'lodash/map';
import filter from 'lodash/filter';

import Toggle from 'material-ui/Toggle';
import Avatar from 'material-ui/Avatar';

import Badge from '../../../components/badge/Badge';
import MetricsCellContent from '../../../components/applicant/cells/MetricsCellContent';
import Separator from '../../../components/applicant/cells/Separator';
import DualMetricsMassChart from '../../../components/analytics/DualMetricsMassChart';
import DualMetricsInterpretation from '../../../components/analytics/DualMetricsInterpretation';
import CardHeader from '../../../components/mui/CardHeader';

import { BRAND } from '../../../constants/constants';
import { extractParticipantNameConfig } from '../../../utils/utils';
import { selectDualMetricsGroupedData } from '../../../selectors/analytics';
import IconButton from '../../../components/mui/IconButton';

const ParticipantRow = (props) => {
  const { row, metrics, onRemove, onToggleActive, messages, status, metricDefinitions } = props;
  const inactive = status === 'inactive';
  const nameConfig = extractParticipantNameConfig(row.participant, messages);
  return (
    <div className={`questionnaire-analytics-participant-row${inactive ? ' inactive' : ''}`} onClick={onToggleActive}>
      <div className="row flex1">
        <div className="col-xs-6 col-sm-4 questionnaire-analytics-participant-row-name">
          <div
            className="questionnaire-analytics-participant-row-indicator"
            style={
              inactive ? {} : { backgroundColor: row.crossComparedColor, border: `1px solid ${row.crossComparedColor}` }
            }
          />
          {nameConfig.available ? (
            <span style={{ verticalAlign: 'middle' }}>{nameConfig.text}</span>
          ) : (
            <em style={{ verticalAlign: 'middle' }} className="text-muted">
              {nameConfig.text}
            </em>
          )}
        </div>
        <div className="col-xs-6 col-sm-3">
          {!inactive && (
            <div>
              Fit: <Badge text={`${(metrics.fit * 100).toFixed(0)} %`} />
            </div>
          )}
        </div>
        <Separator className="col-xs-12" style={{ paddingTop: 1 }} smHide />
        <div className="col-sm-5" style={{ paddingBottom: 2 }}>
          <MetricsCellContent metrics={row.result.metrics} metricDefinitions={metricDefinitions} />
        </div>
      </div>
      <div className="mui-padded questionnaire-analytics-participant-row-button">
        <IconButton onClick={e => {
          e.stopPropagation();
          onRemove();
        }}>
          <i className="material-icons">clear</i>
        </IconButton>
      </div>
    </div>
  );
};

class QuestionnaireAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartWidth: null
    };
  }

  isComparisonActive = (props) => {
    const {
      project,
      crossComparison: { targetProject },
      dualMetricsGroupedData: { participantCounts }
    } = props;
    return (
      project &&
      targetProject &&
      project.id === targetProject.id &&
      (participantCounts.source > 0 || participantCounts.target > 0)
    );
  };

  getSingleActiveParticipant = (props) => {
    const {
      crossComparison: { participants, activeIndex }
    } = props;
    if (!participants) {
      return null;
    }
    if (participants.length === 1) {
      return participants[0];
    }
    if (activeIndex >= 0) {
      return participants[activeIndex];
    }
    return null;
  };

  handleRemoveParticipant = (p) => {
    const { crossComparison, onUpdateCrossComparisonParticipants } = this.props;
    const { participants } = crossComparison;
    onUpdateCrossComparisonParticipants(filter(participants, (ptp) => ptp.id !== p.id));
  };

  beforePrint = () => {
    return new Promise(resolve => this.setState({ chartWidth: '180mm' }, resolve));
  };
  afterPrint = () => {
    this.setState({ chartWidth: null });
  };

  handlePrint = () => {
    this.beforePrint()
      .then(() => new Promise(resolve => setTimeout(resolve, 100)))
      .then(window.print)
      .then(this.afterPrint);
  };

  render() {
    const {
      crossComparison: { participants, activeIndex, interpretations, managerial },
      dualMetricsGroupedData: { metrics, participantMetrics },
      metricDefinitions,
      intl
    } = this.props;
    const { onSetCrossComparisonParticipantActive, onToggleCrossComparisonManagerial } = this.props;
    const { chartWidth } = this.state;
    const messages = intl.messages.components.pages.private.project.questionnaireAnalytics;
    const isComparisonActive = this.isComparisonActive(this.props);
    const colors = {
      main: isComparisonActive
        ? 'rgba(0,0,0,0.125)'
        : BRAND.theme.palette.customAccentColor || BRAND.theme.palette.primary2Color,
      overlay: BRAND.theme.palette.accent1Color
    };
    const activeParticipant = isComparisonActive && this.getSingleActiveParticipant(this.props);
    return (
      <div className="questionnaire-analytics-container">
        <div className="container-flex-row ai-center">
          <div className="flex1">
          <CardHeader
            title={messages.overviewTitle}
            subtitle={(isComparisonActive ? messages.overviewSubtitleComparison : messages.overviewSubtitle)}
            image={<Avatar icon={<i className="material-icons">linear_scale</i>} />}
          />
          </div>
          <div className="mui-padded-x2 print-none">
            <IconButton onClick={this.handlePrint}>
              <i className="material-icons">print</i>
            </IconButton>
          </div>
        </div>
        {isComparisonActive && participants && participants.length > 0 && (
          <div className="row questionnaire-analytics-participants" style={{ fontSize: '13px' }}>
            {map(participants, (p, idx) => (
              <div key={`${p.projectId}-${p.id}`} className="col-sm-12">
                <ParticipantRow
                  row={p}
                  metrics={participantMetrics[idx]}
                  onRemove={() => this.handleRemoveParticipant(p)}
                  onToggleActive={() => onSetCrossComparisonParticipantActive(p, idx)}
                  metricDefinitions={metricDefinitions}
                  messages={messages}
                  status={activeIndex != undefined ? (activeIndex === idx ? 'active' : 'inactive') : ''}
                />
              </div>
            ))}
          </div>
        )}
        {isComparisonActive && participants && participants.length > 1 && (
          <div className="row print-none">
            <div className="col-sm-12">
              <div style={{ padding: '0 16px 8px' }}>
                <small>{messages.singleComparisonMessage}</small>
              </div>
            </div>
          </div>
        )}
        {isComparisonActive && activeParticipant && (
          <div style={{ fontSize: '14px' }} className="mui-padded-horizontal-x2 mui-padded-vertical print-none">
            <Toggle
              label={messages.managerialComparisonLabel}
              toggled={managerial}
              onToggle={onToggleCrossComparisonManagerial}
              labelPosition={'right'}
              labelStyle={{ margin: 0, fontWeight: 400, zIndex: 0 }}
            />
          </div>
        )}
        <div className="mui-padded-x2">
          <DualMetricsMassChart metrics={metrics} width={chartWidth} />
        </div>
        <DualMetricsInterpretation
          metrics={metrics}
          colors={colors}
          interpretations={interpretations}
          activeParticipant={isComparisonActive && managerial && activeParticipant}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    intl,
    entities: {
      applicants: { metricDefinitions }
    }
  } = state;
  return {
    intl,
    metricDefinitions,
    dualMetricsGroupedData: selectDualMetricsGroupedData(state, props)
  };
};

export default withRouter(connect(mapStateToProps)(QuestionnaireAnalytics));
