import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import _values from 'lodash/values';

import TextField from '../superfield/DebouncedSuperText';
import CategoryFilter from '../filter/CategoryFilter';

import { selectGroupMembersFilter, selectGroupMembersFilterValues } from './teamMemberSelectors';
import * as groupMemberFiltersActions from '../../actions/options/groupMemberFiltersActions';
import withIntl from '../withIntl';
import isEmpty from 'lodash/isEmpty';

const eMessages = {};

class TeamFilter extends React.Component {
  state = {
    collapsed: {}
  };

  extractFilterFromValues = filter => {
    const { fromValues } = this.props.filter;
    const { [filter.key]: obj } = fromValues || {};
    return obj || {};
  };

  handleFilterChange = () => {};

  handleToggleFromValues = (filter, value) => {
    const { group, setGroupMemberFilterFromValues } = this.props;
    const { items, ...rest } = this.extractFilterFromValues(filter);
    const { [value]: oldVal, ...newItems } = items || {};
    if (!oldVal) {
      newItems[value] = true;
    } else {
      // encountered weird 'rest' operator behaviour on numerical keys
      delete newItems[value];
    }
    setGroupMemberFilterFromValues(group.id, filter.key, { ...rest, items: isEmpty(newItems) ? null : newItems });
    this.handleFilterChange();
  };

  handleSetMatchAllValues = (filter, matchAll) => {
    const { group, setGroupMemberFilterFromValues } = this.props;
    const values = this.extractFilterFromValues(filter);
    setGroupMemberFilterFromValues(group.id, filter.key, { ...values, matchAll });
    this.handleFilterChange();
  };

  handleFullTextChange = val => {
    const { group, setGroupMemberFilterFullText } = this.props;
    setGroupMemberFilterFullText(group.id, val);
  };

  handleUnsetAll = filter => {
    const { group, setGroupMemberFilterFromValues } = this.props;
    setGroupMemberFilterFromValues(group.id, filter.key, {});
    this.handleFilterChange();
  };

  handleFilterExpandedToggle = f => {
    const {
      collapsed: { [f.key]: off, ...collapsed }
    } = this.state;
    if (!off) {
      collapsed[f.key] = true;
    }
    this.setState({ collapsed });
  };

  render() {
    const {
      filter: { fullText, fromValues },
      filterValues,
      intl: { messages }
    } = this.props;
    const { collapsed } = this.state;
    return (
      <div className="team__filter">
        <TextField
          onChange={this.handleFullTextChange}
          value={fullText || ''}
          placeholder={messages.searchPlaceholder}
          autoFocus
          clearable
          debounce={300}
        />
        {_map(_values(filterValues), fv => {
          const activeValues = (fromValues && fromValues[fv.key]) || {};
          const filterMessages = messages.fromValuesFilters[fv.key] || { label: fv.key };
          const label = fv.label || filterMessages.label;
          const description = filterMessages.description;
          const commonProps = {
            expanded: !collapsed[fv.key],
            filter: fv,
            activeValues,
            label,
            description,
            filterMessages
          };
          return (
            <CategoryFilter
              key={fv.key}
              {...commonProps}
              matchAllLabel={messages.matchAllLabel}
              unsetAllLabel={messages.unsetAllLabel}
              enumMessages={eMessages}
              onToggleExpanded={this.handleFilterExpandedToggle}
              onToggleValue={this.handleToggleFromValues}
              onSetMatchAll={this.handleSetMatchAllValues}
              onUnsetAll={this.handleUnsetAll}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    group: state.entities.group.current,
    filter: selectGroupMembersFilter(state, ownProps),
    filterValues: selectGroupMembersFilterValues(state, ownProps)
  };
};

const intlMessages = {
  cs: {
    searchPlaceholder: 'Filtrovat dle jména',
    matchAllLabel: 'Splňuje všechny vybrané',
    unsetAllLabel: 'Zrušit výběr',
    fromValuesFilters: {
      tags: {
        label: 'Tagy',
        noneLabel: 'Bez tagů'
      },
      members: {
        label: 'Členové'
      }
    }
  },
  sk: {
    searchPlaceholder: 'Filtrovať podľa mena',
    matchAllLabel: 'Spĺňa všetky vybrané',
    unsetAllLabel: 'Zrušiť výber',
    fromValuesFilters: {
      tags: {
        label: 'Tagy',
        noneLabel: 'Bez tagov'
      },
      members: {
        label: 'Členovia'
      }
    }
  },
  en: {
    searchPlaceholder: 'Filter by name',
    matchAllLabel: 'Matches all',
    unsetAllLabel: 'Unset all',
    fromValuesFilters: {
      tags: {
        label: 'Tags',
        noneLabel: 'Without tags'
      },
      members: {
        label: 'Members'
      }
    }
  }
};

export default connect(mapStateToProps, groupMemberFiltersActions)(withIntl(TeamFilter, intlMessages));
