import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import Button from '../../../mui/Button';
import IconButton from '../../../mui/IconButton';
import Select from '../../../superfield/form/SuperSelect';
import MultiText from '../../../superfield/form/SuperMultiText';
import RequiredCriteriaToggle from './RequiredCriteriaToggle';

import { getProgress } from '../Utils';
import { valueHoldersToValues, enumToDataSource } from '../../../forms/FormUtils';
import { selectOpportunityPostToEdit } from '../../../../selectors/opportunityBuilder';

export const ID = 'opportunitySkillsPartForm';

const generateId = () => Math.random().toString(36).substring(2);

const messages = {
  languagesTitle: 'Jazykové dovednosti',
  otherTitle: 'Jiné dovednosti',
  languageLabel: 'Jazyk',
  languageMinimumLevelLabel: 'Minimální úroveň',
  addLanguageLabel: 'Přidat požadovaný jazyk',
  requiredCriteriaLabel: 'Toto kritérium MUSÍ splňovat',
  removeLanguageTooltip: 'Odstranit jazyk',
  otherSkillsLabel: 'Jiné požadované dovednosti, znalosti nebo certifkáty',
  otherSkillsHelperText: 'Položky oddělte čárkou, nebo klávesou TAB',
  requiredMessage: 'Vyberte možnost'
};

const renderLanguages = ({ fields, dataSources }) => {
  return (
    <div>
      {fields.map((f, idx) => {
        const field = fields.get(idx);
        return (
          <div key={field.gid} className="row">
            <div className="container-flex-row fw-yes" style={{ padding: '0 4px' }}>
              <div className="flex1" style={{ padding: '0 8px', minWidth: '240px' }}>
                <Field
                  name={`${f}.value`}
                  component={Select}
                  label={messages.languageLabel}
                  dataSource={dataSources.languages}
                />
              </div>
              <div className="flex1" style={{ padding: '0 8px', minWidth: '240px' }}>
                <Field
                  name={`${f}.minimumLevel`}
                  component={Select}
                  label={messages.languageMinimumLevelLabel}
                  dataSource={dataSources.languageLevels}
                />
              </div>
              <div style={{ marginTop: '20px' }}>
                <IconButton onClick={() => fields.remove(idx)} tooltip={messages.removeLanguageTooltip}>
                  <i className="material-icons">clear</i>
                </IconButton>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mui-padded-horizontal">
                <Field
                  name={`${f}.eliminating`}
                  component={RequiredCriteriaToggle}
                  label={messages.requiredCriteriaLabel}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className="mui-fields-container">
        <Button
          mini
          onClick={() => fields.push({ gid: generateId() })}
          label={messages.addLanguageLabel}
          icon={<i className="material-icons text-sz-lg">add</i>}
        />
      </div>
    </div>
  );
};

const SkillsPart = props => {
  const { dataSources, intl, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <h6>{messages.languagesTitle}</h6>
      <div>
        <FieldArray
          name="languages"
          component={renderLanguages}
          intl={intl}
          dataSources={dataSources}
          rerenderOnEveryChange={false}
        />
      </div>
      <h6>{messages.otherTitle}</h6>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="otherSkills"
            label={messages.otherSkillsLabel}
            component={MultiText}
            helperText={messages.otherSkillsHelperText}
          />
        </div>
      </div>
    </form>
  );
};

const validate = values => {
  const errors = {};
  if (values.languages && values.languages.length > 0) {
    const langErrors = [];
    values.languages.forEach((l, idx) => {
      const lErr = {};
      if (!l.value) {
        lErr.value = messages.requiredMessage;
      }
      if (!l.minimumLevel) {
        lErr.minimumLevel = messages.requiredMessage;
      }
      if (!_isEmpty(lErr)) {
        langErrors[idx] = lErr;
      }
    });
    if (langErrors.length > 0) {
      errors.languages = langErrors;
    }
  }
  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    options: {
      dataSources: { all }
    }
  } = state;
  const post = selectOpportunityPostToEdit(state, ownProps);
  const opportunity = _get(post, 'opportunity') || {};
  const enumMessages = intl.messages.constants.enums;
  const dataSources = {
    languages: (all && all.languages) || [],
    languageLevels: enumToDataSource(enumMessages.ELanguageLevel, {})
  };
  const matchConfig = opportunity.matchConfig || {};
  return {
    intl,
    dataSources,
    initialValues: {
      languages: _map(_get(matchConfig, 'skills.languages.list.items') || [], ({ value, range, eliminating }, idx) => ({
        gid: idx,
        value,
        eliminating: _get(range || {}, 'lower.eliminating') || _get(range || {}, 'eliminating') || eliminating,
        minimumLevel: _get(range || {}, 'lower.value')
      })),
      otherSkills: valueHoldersToValues(_get(matchConfig, 'skills.other.list.items'))
    }
  };
};

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

SkillsPart.ID = ID;
SkillsPart.getProgressFromPost = post => {
  const matchConfig = post?.opportunity?.matchConfig;
  return getProgress([
    () => matchConfig?.skills?.languages?.list?.items?.length > 0,
    () => matchConfig?.skills?.other?.list?.items?.length > 0
  ]);
};
SkillsPart.getProgressFromValues = v => getProgress([() => v?.languages?.length > 0, () => v?.otherSkills?.length > 0]);

export default connect(mapStateToProps)(reduxForm(formConfig)(SkillsPart));
