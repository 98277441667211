import React from 'react';
import PropTypes from 'prop-types';

import SimpleModal from './SimpleModal';

const ConfirmActivate = props => {
  const { id, intl, projectName, args } = props;
  const { activateProject, switchModal } = props;

  const messages = intl.messages.components.modals.confirmActivate;

  return (
    <SimpleModal
      open
      id={id}
      callback={confirmed => {
        if (confirmed) {
          activateProject(...args);
        }
      }}
      switchModal={switchModal}
      confirmLabel={messages.yes}
      cancelLabel={messages.no}
      message={
        <div>
          <p>{`${messages.msg1} ${projectName}?`}</p>
          <p>{messages.msg2}</p>
        </div>
      }
    />
  );
};

ConfirmActivate.propTypes = {
  modal: PropTypes.object,
  card: PropTypes.object,
  intl: PropTypes.object,
  switchModal: PropTypes.func,
  editProject: PropTypes.func,
  createProject: PropTypes.func,
  activateProject: PropTypes.func
};

export default ConfirmActivate;
