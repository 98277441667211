import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'material-ui/Checkbox';

import { EApplicantListSort } from '../../constants/enum';
import { STYLES } from './ApplicantListGrid';
import { RESPONSIVE_HEADER_PROPS } from './QuestionnaireListGridRow';
import { GridLabel, GridRow, GridCell, SortableGridLabel } from '../grid';

const QuestionnaireListGridHeaderRow = props => {
  const { intl, isAnyApplicantSelected, sortProps } = props;
  const { handleToggleAllApplicantSelection } = props;
  const messages = intl.messages.components.pages.private.project.applicantList;
  return (
    <GridRow>
      <GridCell {...RESPONSIVE_HEADER_PROPS.check} style={STYLES.checkboxCell}>
        <Checkbox
          style={STYLES.checkbox}
          checked={isAnyApplicantSelected}
          iconStyle={isAnyApplicantSelected ? {} : { fill: '#757575' }}
          onCheck={handleToggleAllApplicantSelection}
        />
      </GridCell>
      <GridCell {...RESPONSIVE_HEADER_PROPS.name}>
        <SortableGridLabel sort={EApplicantListSort.NAME} {...sortProps}>
          {messages.cols.name}
        </SortableGridLabel>
      </GridCell>
      <GridCell {...RESPONSIVE_HEADER_PROPS.metrics}>
        <GridLabel>{messages.cols.metrics}</GridLabel>
      </GridCell>
      <GridCell {...RESPONSIVE_HEADER_PROPS.finished} style={STYLES.alignRight}>
        <SortableGridLabel sort={EApplicantListSort.FINISHED_AT} {...sortProps}>
          {messages.cols.finishedAt}
        </SortableGridLabel>
      </GridCell>
    </GridRow>
  );
};

QuestionnaireListGridHeaderRow.propTypes = {
  intl: PropTypes.object.isRequired,
  sortProps: PropTypes.object.isRequired,
  isAnyApplicantSelected: PropTypes.bool,
  //
  handleToggleAllApplicantSelection: PropTypes.func.isRequired
};

export default QuestionnaireListGridHeaderRow;
