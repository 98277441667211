import React from 'react';

import Button from '../mui/Button';

import { formatMessage } from '../../utils/utils';

import withIntl from '../withIntl';

const TagRemovalConfirm = props => {
  const {
    tag,
    onCancel,
    onConfirm,
    intl: { messages }
  } = props;
  return (
    <div>
      <div className="mui-padded mui-padded-x2 text-muted" style={{ maxWidth: '280px' }}>
        {formatMessage(messages.message, [<b key="name">{tag.name}</b>])}
      </div>
      <div className="mui-padded-half border-top border-color-primary__50 container-flex-row ai-center fw-yes jc-flex-end">
        <div className="mui-padded-half">
          <Button label={messages.cancelLabel} onClick={onCancel} />
        </div>
        <div className="mui-padded-half">
          <Button
            className={'text-error'}
            label={messages.confirmLabel}
            icon={<i className="material-icons">delete_forever</i>}
            primary
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    cancelLabel: 'Zrušit',
    confirmLabel: 'Odstranit',
    message: 'Skutečně chcete permanentně odstranit tag {0}? Odstraní se tím i z již přirazených profilů.'
  },
  sk: {
    cancelLabel: 'Zrušiť',
    confirmLabel: 'Odstrániť',
    message: 'Naozaj chcete permanentne odstrániť tag {0}? Odstráni sa tým aj z už priradených profilov.'
  },
  en: {
    cancelLabel: 'Cancel',
    confirmLabel: 'Remove',
    message:
      'Would you like to permanently remove the tag {0}? It will also remove all the tag assignments from participants.'
  }
};

export default withIntl(TagRemovalConfirm, intlMessages);
