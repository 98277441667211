import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import VisSensor from 'react-visibility-sensor';
import _map from 'lodash/map';

import HomeSectionHeader from './HomeSectionHeader';
import EmptySectionMessage from './EmptySectionMessage';
import ParticipantName from '../basic/ParticipantName';
import Overlay from '../basic/Overlay';
import ActivityLabel from '../activity/ActivityLabel';
import Chip from '../mui/Chip';
import IconButton from '../mui/IconButton';
import LockedPlaceholder from '../placeholder/LockedPlaceholder';
import MembersCountMessage from '../team/MembersCountMessage';

import * as Api from '../../api/entities/groupApi';
import { invokeUpgradeAccountModal } from '../../actions/entities/accountActions';
import { getActivityMapKey } from '../team/TeamUtils';
import { selectAccountPermissions } from '../../selectors/auth';
import { EContractPlanMode, EGroupType, EUpgradeRequestSource } from '../../constants/enum';

import withIntl from '../withIntl';

const extractAllActivities = g => {
  const acs = g.data.activityConfigurations || {};
  const res = [];
  const acMap = {};
  for (let prop in acs) {
    if (acs.hasOwnProperty(prop)) {
      const ac = acs[prop];
      const as = ac.activities || [];
      for (let i = 0; i < as.length; i += 1) {
        const a = as[i];
        const ak = getActivityMapKey(a);
        if (!acMap[ak]) {
          acMap[ak] = true;
          res.push(a);
        }
      }
    }
  }
  return res;
};

const PAGE_SIZE = 5;

class GroupsHomeContainer extends React.Component {
  state = {
    items: [],
    page: {
      size: PAGE_SIZE,
      number: 0
    },
    showMore: false
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.company !== this.props.company) {
      this.load();
    }
  }

  load = (pageNumber = 0) => {
    const { company, type } = this.props;
    const { loading } = this.state;
    if (!company || loading) {
      return;
    }
    this.setState({ loading: true });
    Api.getGroups(type.key, company.id, null, { page: pageNumber, size: PAGE_SIZE, meta: true })
      .then(({ items, page }) =>
        this.setState({ items: pageNumber === 0 ? items : [...this.state.items, ...items], page })
      )
      .finally(() => this.setState({ loading: false }));
  };

  handleLoadMore = () => {
    const page = this.state.page.number + 1;
    this.load(page);
  };

  handleShowMore = () => this.setState({ showMore: true });
  handleHideMore = () => this.setState({ showMore: false });

  render() {
    const {
      type,
      infinite,
      intl: { messages },
      auth: { modes },
      invokeUpgradeAccountModal
    } = this.props;
    const { items, page, loading, showMore } = this.state;
    const typeMessages = messages.byType[type.key] || {};
    const createGroupLink = `/groups/new?type=${type.key}`;

    const teamsEnabled = modes[EContractPlanMode.TEAMS.key];

    return (
      <div>
        <HomeSectionHeader
          title={typeMessages.title}
          icon={type.icon}
          loading={loading}
          menu={
            items.length > 0 &&
            teamsEnabled && (
              <IconButton
                style={{ width: '24px', height: '24px' }}
                containerElement={<Link to={createGroupLink} />}
                tooltip={messages.addLabel}
              >
                <i className="material-icons text-muted" style={{ fontSize: '20px', lineHeight: '24px' }}>
                  add
                </i>
              </IconButton>
            )
          }
        />
        {teamsEnabled ? (
          <div>
            <div>
              {items.length > 0 ? (
                <div className="mui-padded">
                  {_map(items, g => (
                    <Link key={g.id} to={`/groups/${g.id}`} className="group-home-link">
                      <div className="mui-padded-half mui-margin bg-white border-radius text-normal">
                        <div className="container-flex-row jc-space-between fw-yes">
                          <div className="mui-padded-half">{g.name}</div>
                          <div className="mui-padded-half text-muted text-sz-reg-sm">
                            {type === EGroupType.TEAM && (
                              <span>
                                <span
                                  className="mdi mdi-account-tie-outline mdi-18px mui-margin-right-half"
                                  style={{ lineHeight: '1em', verticalAlign: 'middle' }}
                                />
                                {g.metadata?.manager ? (
                                  <ParticipantName {...g.metadata.manager} variant="mini" />
                                ) : (
                                  <em>N/A</em>
                                )}
                                <span className="mui-margin-horizontal-half">&middot;</span>
                              </span>
                            )}
                            <MembersCountMessage type={type.key} count={g.metadata?.memberCount || 0} />
                          </div>
                        </div>
                        <div className="container-flex-row ai-center fw-yes">
                          {_map(extractAllActivities(g), a => (
                            <div key={getActivityMapKey(a)} className="mui-padded-half">
                              <Chip variant="mini" className="bg-highlight-light">
                                <span className="text-muted">
                                  <ActivityLabel activity={a} />
                                </span>
                              </Chip>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Link>
                  ))}
                  {page.number < page.totalPages - 1 && !loading && (
                    <div className="mui-padded mui-margin-left">
                      {!infinite ? (
                        <a role="button" className="link-button" onClick={this.handleShowMore}>
                          <span>{messages.showMoreLabel}</span>
                        </a>
                      ) : (
                        <VisSensor
                          key={`${page.number}-${page.totalPages}`}
                          onChange={visible => visible && this.handleLoadMore()}
                        >
                          <div
                            style={{ minHeight: '1px', minWidth: '1px', marginTop: '-1px', display: 'inline-block' }}
                          />
                        </VisSensor>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <EmptySectionMessage
                  header={typeMessages.emptyTitle}
                  message={typeMessages.emptyMessage}
                  actionLabel={messages.addLabel}
                  actionLink={createGroupLink}
                />
              )}
            </div>
            {!infinite && showMore && (
              <Overlay onRequestClose={this.handleHideMore} noBlur maxWidth={'480px'}>
                <GroupsHomeContainer {...this.props} infinite />
              </Overlay>
            )}
          </div>
        ) : (
          <div className="mui-margin-x2 text-muted">
            <LockedPlaceholder
              upgradeAccount={invokeUpgradeAccountModal}
              title={typeMessages.lockedTitle}
              message={typeMessages.lockedMessage}
              source={EUpgradeRequestSource.MODE_TEAMS}
            />
          </div>
        )}
      </div>
    );
  }
}

const intlMessages = {
  cs: {
    showMoreLabel: 'Více...',
    addLabel: 'Vytvořit',
    byType: {
      TEAM: {
        title: 'Týmy',
        emptyTitle: 'Nemáte zatím žádné týmy',
        emptyMessage:
          'Podívejte se na své <b>týmy a manažery v kontextu</b>, sestavte <b>profil týmu</b> a zjistěte <b>silné stránky</b> i příležitosti pro <b>rozvoj</b>. Využívejte všechny assessmenty, kombinujte je a pozvěte členy týmu jedním kliknutím.',
        lockedTitle: 'Týmy jsou prémiová funkce',
        lockedMessage:
          'Podívejte se na své <b>týmy a manažery v kontextu</b>, sestavte <b>profil týmu</b> a zjistěte <b>silné stránky</b> i příležitosti pro <b>rozvoj</b>. Využívejte všechny assessmenty, kombinujte je a pozvěte členy týmu jedním kliknutím. {0[Získat funkci Týmy]}.'
      },
      GROUP: {
        title: 'Skupiny',
        emptyTitle: 'Nemáte zatím žádné skupiny',
        emptyMessage:
          'Sdružujte <b>profily do skupin</b>, organizujte <b>interní assessmentové projekty</b>, vytvářejte <b>reporty</b> napříč organizací. Využívejte všechny assessmenty, kombinujte je a pozvěte členy jedním kliknutím.',
        lockedTitle: 'Skupiny jsou prémiová funkce',
        lockedMessage:
          'Sdružujte <b>profily do skupin</b>, organizujte <b>interní assessmentové projekty</b>, vytvářejte <b>reporty</b> napříč organizací. Využívejte všechny assessmenty, kombinujte je a pozvěte členy jedním kliknutím. {0[Získat funkci Skupiny]}.'
      },
      HIRING: {
        title: 'Otevřené pozice',
        emptyTitle: 'Nemáte zatím žádné otevřené pozice',
        emptyMessage:
          'Otestujte <b>uchazeče</b> jedním nebo kombinací assessmentů a <b>pozvěte</b> je jedním kliknutím, <b>srovnejte</b> je s profily týmů. Odhalte, kdo k vám jak zapadne a jak promění vaší týmovou mapu.',
        lockedTitle: 'Otevřené pozice jsou prémiová funkce',
        lockedMessage:
          'Otestujte <b>uchazeče</b> jedním nebo kombinací assessmentů a <b>pozvěte</b> je jedním kliknutím, <b>srovnejte</b> je s profily týmů. Odhalte, kdo k vám jak zapadne a jak promění vaší týmovou mapu. {0[Získat funkci Otevřené pozice]}.'
      }
    }
  },
  sk: {
    showMoreLabel: 'Viac...',
    addLabel: 'Vytvoriť',
    byType: {
      TEAM: {
        title: 'Tímy',
        emptyTitle: 'Nemáte zatiaľ žiadne tímy',
        emptyMessage:
          'Pozrite sa na svoje <b>tímy a manažérov v kontexte</b>, zostavte <b>profil tímu</b> a zistite <b>silné stránky</b> aj príležitosti na <b>rozvoj</b>. Využívajte všetky assessmenty, kombinujte ich a pozvite členov tímu jedným kliknutím.',
        lockedTitle: 'Tímy sú prémiová funkcia',
        lockedMessage:
          'Pozrite sa na svoje <b>tímy a manažérov v kontexte</b>, zostavte <b>profil tímu</b> a zistite <b>silné stránky</b> aj príležitosti na <b>rozvoj</b>. Využívajte všetky assessmenty, kombinujte ich a pozvite členov tímu jedným kliknutím. {0[Získať funkciu Tímy]}.'
      },
      GROUP: {
        title: 'Skupiny',
        emptyTitle: 'Nemáte zatiaľ žiadne skupiny',
        emptyMessage:
          'Združujte <b>profily do skupín</b>, organizujte <b>interné assessmentové projekty</b>, vytvárajte <b>reporty</b> naprieč organizáciou. Využívajte všetky assessmenty, kombinujte ich a pozvite členov jedným kliknutím.',
        lockedTitle: 'Skupiny sú prémiová funkcia',
        lockedMessage:
          'Združujte <b>profily do skupín</b>, organizujte <b>interné assessmentové projekty</b>, vytvárajte <b>reporty</b> naprieč organizáciou. Využívajte všetky assessmenty, kombinujte ich a pozvite členov jedným kliknutím. {0[Získať funkciu Skupiny]}.'
      },
      HIRING: {
        title: 'Otvorené pozície',
        emptyTitle: 'Nemáte zatiaľ žiadne otvorené pozície',
        emptyMessage:
          'Otestujte <b>uchádzačov</b> jedným alebo kombináciou assessmentov a <b>pozvite</b> ich jedným kliknutím, <b>porovnajte</b> ich s profilami tímov. Odhaľte, kto k vám ako zapadne a ako premení vašu tímovú mapu.',
        lockedTitle: 'Otvorené pozície sú prémiová funkcia',
        lockedMessage:
          'Otestujte <b>uchádzačov</b> jedným alebo kombináciou assessmentov a <b>pozvite</b> ich jedným kliknutím, <b>porovnajte</b> ich s profilami tímov. Odhaľte, kto k vám ako zapadne a ako premení vašu tímovú mapu. {0[Získať funkciu Otvorené pozície]}.'
      }
    }
  },
  en: {
    showMoreLabel: 'More...',
    addLabel: 'Create',
    byType: {
      TEAM: {
        title: 'Teams',
        emptyTitle: 'You have no teams yet',
        emptyMessage:
          'See your <b>teams and managers in context</b>. Build <b>team profiles</b> and identify <b>strengths and opportunities</b>. Use all assessments, combine them and invite team members with one click.',
        lockedTitle: 'Teams are a premium feature',
        lockedMessage:
          'See your <b>teams and managers in context</b>. Build <b>team profiles</b> and identify <b>strengths and opportunities</b>. Use all assessments, combine them and invite team members with one click. {0[Get the Teams feature]}.'
      },
      GROUP: {
        title: 'Groups',
        emptyTitle: 'You have no groups yet',
        emptyMessage:
          'Organize <b>profiles into groups</b>. Carry out <b>internal assessment projects</b>. Create <b>reports</b> across the organization. Use all assessments, combine them and invite team members with one click.',
        lockedTitle: 'Groups are a premium feature',
        lockedMessage:
          'Organize <b>profiles into groups</b>. Carry out <b>internal assessment projects</b>. Create <b>reports</b> across the organization. Use all assessments, combine them and invite team members with one click. {0[Get the Groups feature]}.'
      },
      HIRING: {
        title: 'Open positions',
        emptyTitle: 'You have no open positions yet',
        emptyMessage:
          'Test <b>candidates</b> with one or more assessments. <b>Invite</b> them with one click. <b>Compare</b> them with existing team profiles. Find out who fits and how they improve your teams.',
        lockedTitle: 'Open positions are a premium feature',
        lockedMessage:
          'Test <b>candidates</b> with one or more assessments. <b>Invite</b> them with one click. <b>Compare</b> them with existing team profiles. Find out who fits and how they improve your teams. {0[Get the Open Positions function]}.'
      }
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  return { auth: selectAccountPermissions(state, ownProps) };
};

const actions = { invokeUpgradeAccountModal };

export default withIntl(connect(mapStateToProps, actions)(GroupsHomeContainer), intlMessages);
