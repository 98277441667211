import { LOGIN_SUCCESS } from '../constants/constants';
import { addNotification } from '../actions/options/notificationsActions';
import { put } from '../persistence/storage';

const ACCOUNT_BLOCKED_NOTIFICATION_ENABLED = false;

export default function notificationsMiddleware({ dispatch }) {
  return next => action => {
    const { type } = action;
    if (type === LOGIN_SUCCESS) {
      const { payload: { email, stats } } = action;
      const { loginStats } = stats || {};
      // disable for now
      if (
        ACCOUNT_BLOCKED_NOTIFICATION_ENABLED &&
        email &&
        loginStats &&
        loginStats.DASHBOARD &&
        loginStats.DASHBOARD.count <= 1
      ) {
        put('freeAccountBlockedShowed', true);
        setTimeout(() => dispatch(addNotification({ intlKey: 'freeAccountBlocked' })), 2000);
      }
    }
    return next(action);
  };
}
