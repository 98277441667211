import React from 'react';
import _map from 'lodash/map';

import GroupActivityInsights from './GroupActivityInsights';

const GroupActivityInsightsPanel = props => {
  const {
    members,
    activities,
    highlighted,
    activityMetricProperties,
    activityMetricStats,
    activityCollapsed,
    activityChartsShown,
    activityParticipantsListShown
  } = props;
  const { onActivityCollapsedToggle } = props;
  return (
    <div className="mui-padded-vertical">
      <div>
        {_map(activities, a => (
          <GroupActivityInsights
            key={a.mapKey}
            activity={a}
            members={members}
            highlighted={highlighted}
            metricProps={activityMetricProperties[a.mapKey] || {}}
            metricStats={activityMetricStats[a.mapKey] || {}}
            expanded={!activityCollapsed[a.mapKey]}
            chartShown={activityChartsShown && activityChartsShown[a.mapKey]}
            participantsListShown={activityParticipantsListShown && activityParticipantsListShown[a.mapKey]}
            onExpandedToggle={onActivityCollapsedToggle}
          />
        ))}
      </div>
    </div>
  );
};

export default GroupActivityInsightsPanel;
