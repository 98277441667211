import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import SwipeableViews from 'react-swipeable-views';

import IconButton from 'material-ui/IconButton';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import LeftIcon from 'material-ui/svg-icons/navigation/arrow-back';
import RightIcon from 'material-ui/svg-icons/navigation/arrow-forward';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

import Card from '../mui/Card';

class Guide extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    guide: PropTypes.object.isRequired,
    onPrev: PropTypes.func,
    onNext: PropTypes.func
  };

  //noinspection JSMethodCanBeStatic
  componentDidMount() {
    document.body.classList.add('guide-shown');
  }

  //noinspection JSMethodCanBeStatic
  componentWillUnmount() {
    document.body.classList.remove('guide-shown');
  }

  render() {
    const { onPrev, onNext, onHide, closeable, children, guide: { step, stepCount } } = this.props;
    return (
      <div className="guide-overlay">
        <div className="guide-container">
          <Card
            className="guide-card"
            style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}
          >
            <SwipeableViews
              index={step - 1}
              onChangeIndex={(idx, lastIdx) => (idx < lastIdx ? onPrev() : onNext())}
              containerStyle={{ height: '100%' }}
            >
              {children}
            </SwipeableViews>
          </Card>
          <div className="guide-nav-button guide-nav-button-left">
            <FloatingActionButton secondary mini onClick={onPrev} disabled={!onPrev}>
              <LeftIcon />
            </FloatingActionButton>
          </div>
          <div className="guide-nav-button guide-nav-button-right">
            <FloatingActionButton secondary mini onClick={onNext} disabled={!onNext}>
              <RightIcon />
            </FloatingActionButton>
          </div>
          {closeable && (
            <div className="guide-close-button">
              <IconButton onClick={onHide}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          <div className="guide-progress-indicator">
            <div className="guide-nav-button guide-nav-button-left">
              <IconButton onClick={onPrev} disabled={!onPrev}>
                <LeftIcon color="#fff" />
              </IconButton>
            </div>
            {map(new Array(stepCount), (x, idx) => (
              <div
                key={idx}
                className={`guide-progress-indicator-dot${idx + 1 === step
                  ? ' guide-progress-indicator-dot-active'
                  : ''}`}
              />
            ))}
            <div className="guide-nav-button guide-nav-button-right">
              <IconButton onClick={onNext} disabled={!onNext}>
                <RightIcon color="#fff" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Guide;
