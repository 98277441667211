import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DraggableCore as Draggable } from 'react-draggable';

import { BRAND } from '../../constants/constants';

class BrushHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      active: false
    };
  }

  performIfNotDisabled = action => {
    if (!this.props.disabled) {
      action();
    }
  };

  handleDragStart = (e, data) => {
    this.performIfNotDisabled(() => {
      this.props.handleDragStart(e, data);
    });
  };
  handleDrag = (e, data) => {
    this.performIfNotDisabled(() => {
      this.props.handleDrag(e, data);
    });
  };
  handleDragStop = (e, data) => {
    this.performIfNotDisabled(() => {
      this.props.handleDragStop(e, data);
      this.setState({ active: false });
    });
  };
  handleMouseEnter = () => {
    this.performIfNotDisabled(() => this.setState({ hovered: true }));
  };
  handleMouseLeave = () => {
    this.performIfNotDisabled(() => this.setState({ hovered: false }));
  };
  handleMouseDown = () => {
    this.performIfNotDisabled(() => this.setState({ active: true }));
  };
  handleMouseUp = () => {
    this.performIfNotDisabled(() => this.setState({ active: false }));
  };

  render() {
    const { size, disabled } = this.props;
    const { hovered, active } = this.state;
    const pad = 2;
    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
      >
        <Draggable
          onStart={this.handleDragStart}
          onDrag={this.handleDrag}
          onStop={this.handleDragStop}
        >
          <div
            style={{
              width: size,
              height: size,
              padding: active ? 2 : 5,
              backgroundColor: hovered && !active ? BRAND.theme.palette.primary1Color + '34' : 'transparent',
              borderRadius: '50%',
              cursor: disabled ? 'not-allowed' : 'pointer',
              transition: 'all 0.2s ease-in'
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                padding: pad + (active ? 3 : 0),
                backgroundColor: BRAND.theme.palette.primary1Color,
                borderRadius: '50%',
                transition: 'all 0.2s ease-in'
              }}
            >
              <div
                style={{
                  borderRadius: '50%',
                  backgroundColor: '#fff',
                  width: '100%',
                  height: '100%',
                  display: 'inline-block'
                }}
              />
            </div>
          </div>
        </Draggable>
      </div>
    );
  }
}

BrushHandle.propTypes = {
  size: PropTypes.number.isRequired,
  handleDragStart: PropTypes.func.isRequired,
  handleDrag: PropTypes.func.isRequired,
  handleDragStop: PropTypes.func.isRequired
};

export default BrushHandle;
