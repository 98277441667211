const NAV_BACK_PATH_MATCHERS = [
  {
    expression: /^\/sourcing\/profile\/([^\/]+)\/edit/,
    getPath: () => '/sourcing/profile'
  },
  {
    expression: /^\/sourcing\/opportunities\/([^\/]+)\/participants/,
    getPath: match => `/sourcing/opportunities/${match[1]}`
  },
  {
    expression: /^\/sourcing\/opportunities\/.+/,
    getPath: () => '/sourcing/opportunities'
  },
  {
    expression: /^\/projects\/overview(\/([^\/]+)\/([^\/]+)\/.+)?/,
    getPath: match => `/projects${match[1] ? `/overview/${match[2]}/${match[3]}` : ''}`
  },
  {
    expression: /(^\/projects\/([^\/]+)\/([^\/]+)\/edit|^\/projects\/new)/,
    getPath: () => '/projects'
  },
  {
    expression: /^\/designer\/simulations\/.+/,
    getPath: () => '/designer/simulations'
  }
];

export const getBackPath = ({ pathname, query: { bpq } }) => {
  for (let i = 0; i < NAV_BACK_PATH_MATCHERS.length; i += 1) {
    const { expression, getPath } = NAV_BACK_PATH_MATCHERS[i];
    const match = expression.exec(pathname);
    if (match) {
      const path = getPath(match);
      if (bpq) {
        return { pathname: path, query: JSON.parse(bpq) };
      }
      return path;
    }
  }
  return null;
};
