import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import FilterPart from './FilterPart';

const BaseFilter = props => {
  const { filter, alwaysExpanded, expanded, label, description, style, children } = props;
  const { onToggleExpanded } = props;
  const isExpanded = alwaysExpanded || expanded;
  const handleToggleExpanded = () => onToggleExpanded(filter);
  return (
    <div className={cn({ 'base-filter clearfix': true, 'base-filter__expanded': isExpanded })} style={style}>
      <div className="filter-part-from-values-container">
        <FilterPart
          collapsible={!alwaysExpanded}
          label={label}
          description={description}
          expanded={isExpanded}
          onToggleExpanded={handleToggleExpanded}
          render={() => children}
        />
      </div>
    </div>
  );
};

BaseFilter.propTypes = {
  filter: PropTypes.object,
  expanded: PropTypes.bool,
  label: PropTypes.any,
  description: PropTypes.any,
  style: PropTypes.object,
  children: PropTypes.any
};

export default BaseFilter;
