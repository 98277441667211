import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Divider from 'material-ui/Divider';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';

import IconButton from '../mui/IconButton';
import Overlay from '../basic/Overlay';
import HomeSectionHeader from './HomeSectionHeader';
import ParticipantActivityEventsList from './ParticipantActivityEventsList';

import withIntl from '../withIntl';
import { getObject, putObject } from '../../persistence/storage';
import * as Api from '../../api/entities/participantActivityEventApi';

const STATE_PERSISTENCE_KEY = '/ParticipantActivityEventsContainer/v0/state';
const PAGE_SIZE = 5;

class ParticipantActivityEventsContainer extends React.Component {
  state = {
    autoRefresh: true,
    completedOnly: false,
    ...getObject(STATE_PERSISTENCE_KEY),
    loading: false,
    items: [],
    page: { number: 0, size: PAGE_SIZE }
  };

  componentDidMount() {
    this.load();
    if (!this.props.infinite) {
      this.setAutoRefresh();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.company !== this.props.company) {
      this.load();
    }
    if (this.state !== prevState) {
      const { items, page, loading, ...savedState } = this.state;
      putObject(STATE_PERSISTENCE_KEY, savedState);
    }
  }

  componentWillUnmount() {
    this.unsetAutoRefresh();
  }

  setAutoRefresh = () => {
    this.unsetAutoRefresh();
    this.refreshInterval = setInterval(this.load, 10_000);
  };
  unsetAutoRefresh = () => {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  };

  load = (pageNumber = 0) => {
    const { company } = this.props;
    if (!company) {
      return;
    }
    const { loading, completedOnly } = this.state;
    if (loading) {
      return;
    }
    this.setState({ loading: true });
    Api.getParticipantActivityEvents(company.id, {
      loadGroups: true,
      loadProjects: false,
      size: PAGE_SIZE,
      page: pageNumber,
      completedOnly
    })
      .then(({ items, page }) =>
        this.setState({ items: pageNumber === 0 ? items : [...this.state.items, ...items], page })
      )
      .finally(() => this.setState({ loading: false }));
  };

  handleLoadMore = () => {
    const page = this.state.page.number + 1;
    this.load(page);
  };

  handleToggleCompleted = () => this.setState(({ completedOnly }) => ({ completedOnly: !completedOnly }), this.load);

  handleToggleAutoRefresh = () =>
    this.setState(({ autoRefresh }) => {
      const newVal = !autoRefresh;
      if (newVal) {
        this.setAutoRefresh();
      } else {
        this.unsetAutoRefresh();
      }
      return { autoRefresh: newVal };
    });

  handleShowMore = () => this.setState({ showMore: true });
  handleHideMore = () => this.setState({ showMore: false });

  handleRefresh = () => this.load();

  render() {
    const { items, page, loading, completedOnly, autoRefresh, showMore } = this.state;
    const {
      infinite,
      projectsMap,
      intl: { messages }
    } = this.props;
    return (
      <div>
        <div>
          <HomeSectionHeader
            icon={'local_fire_department'}
            title={messages.title}
            loading={loading}
            menu={
              !infinite && (
                <IconMenu
                  iconButtonElement={
                    <IconButton style={{ width: '24px', height: '24px' }}>
                      <i className="material-icons text-muted" style={{ fontSize: '20px' }}>
                        more_vert
                      </i>
                    </IconButton>
                  }
                  desktop
                >
                  <MenuItem
                    leftIcon={<i className="material-icons">{autoRefresh ? 'check_box' : 'check_box_outline_blank'}</i>}
                    primaryText={messages.autoRefreshLabel}
                    onClick={this.handleToggleAutoRefresh}
                  />
                  <MenuItem
                    leftIcon={
                      <i className="material-icons">{completedOnly ? 'check_box' : 'check_box_outline_blank'}</i>
                    }
                    primaryText={messages.completedOnlyLabel}
                    onClick={this.handleToggleCompleted}
                  />
                  <Divider />
                  <MenuItem
                    leftIcon={<i className="material-icons">refresh</i>}
                    primaryText={messages.refreshLabel}
                    onClick={this.handleRefresh}
                  />
                </IconMenu>
              )
            }
          />
          <ParticipantActivityEventsList
            items={items}
            page={page}
            loading={loading}
            projectsMap={projectsMap}
            onShowMore={infinite ? null : this.handleShowMore}
            onLoadMore={infinite ? this.handleLoadMore : null}
          />
        </div>
        {showMore && !infinite && (
          <Overlay onRequestClose={this.handleHideMore} noBlur maxWidth={'480px'}>
            <ParticipantActivityEventsContainer {...this.props} infinite />
          </Overlay>
        )}
      </div>
    );
  }
}

const baseSelectCompany = (state, props) => props.company;
const baseSelectProjects = state => state.entities.projects;

const localSelectCompanyProjectsMap = createSelector([baseSelectCompany, baseSelectProjects], (company, projects) => {
  if (!company || !projects) {
    return {};
  }
  const res = {};
  for (let i = 0; i < projects.length; i += 1) {
    const p = projects[i];
    if (p.company.id === company.id) {
      res[p.id] = p;
    }
  }
  return res;
});

const mapStateToProps = (state, ownProps) => {
  return {
    projectsMap: localSelectCompanyProjectsMap(state, ownProps)
  };
};

const intlMessages = {
  cs: {
    title: 'Poslední aktivita',
    refreshLabel: 'Aktualizovat',
    autoRefreshLabel: 'Aktualizovat automaticky',
    completedOnlyLabel: 'Zobrazit pouze dokončené'
  },
  sk: {
    title: 'Posledná aktivita',
    refreshLabel: 'Aktualizovať',
    autoRefreshLabel: 'Aktualizovať automaticky',
    completedOnlyLabel: 'Zobraziť len dokončené'
  },
  en: {
    title: 'Latest activity',
    refreshLabel: 'Refresh',
    autoRefreshLabel: 'Autorefresh',
    completedOnlyLabel: 'Show completed only'
  }
};

export default withIntl(connect(mapStateToProps)(ParticipantActivityEventsContainer), intlMessages);
