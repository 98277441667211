import React from 'react';

import RadioButton from 'material-ui/RadioButton';

import ButtonToggle from '../../mui/ButtonToggle';
import FormatNumber from '../../basic/FormatNumber';
import ComingSoonIndicator from './ComingSoonIndicator';
import TimeUnit from './TimeUnit';

import { EBillingPeriod, ECurrency } from '../../../constants/enum';
import { formatMessage, selectQuantityMessage } from '../../../utils/utils';

import withIntl from '../../withIntl';
import Badge from '../../badge/Badge';

const renderConstraint = (title, text, faded) => {
  return (
    <div className="mui-margin-vertical">
      <div className="text-sz-xs text-muted text-uppercase">{title}</div>
      <div className={`text-sz-reg ${faded ? ' text-muted' : ''}`} style={{ fontWeight: 500 }}>
        {text}
      </div>
    </div>
  );
};

const formatPrice = (val, currency) => {
  let symbol = currency;
  let before = true;
  switch (currency) {
    case ECurrency.CZK.key: {
      symbol = ' Kč';
      before = false;
      break;
    }
    case ECurrency.USD.key: {
      symbol = '$';
      break;
    }
    case ECurrency.EUR.key: {
      symbol = '€';
    }
  }
  return (
    <span>
      {before && <span>{symbol}</span>}
      <span>{val}</span>
      {!before && <span>{symbol}</span>}
    </span>
  );
};

const Price = withIntl(
  props => {
    const {
      billingPeriod,
      currency,
      monthlyPrices,
      annualPrices,
      intl: { messages }
    } = props;
    const isAnnual = billingPeriod === EBillingPeriod.ANNUAL.key;
    const prices = isAnnual ? annualPrices : monthlyPrices;
    if (!prices) {
      return <b className="text-sz-lg">{messages.noPriceMessage}</b>;
    }
    if (!currency) {
      return (
        <div className="text-sz-lg text-muted">
          <em>{messages.noCurrencyMessage}</em>
        </div>
      );
    }
    const { value } = prices[currency];
    const val = isAnnual ? value / 12 : value;
    const savedAnnually = isAnnual ? monthlyPrices[currency].value * 12 - value : 0;
    return (
      <div>
        <b className="text-sz-lg">
          {val > 0 ? formatPrice(<FormatNumber value={val} fractionDigits={2} />, currency) : messages.freeLabel}
        </b>
        {val > 0 && <span className="text-sz-sm text-muted"> / {messages.perMonth}</span>}
        {val > 0 && savedAnnually > 0 && (
          <div className="text-sz-reg">
            {formatMessage(messages.savingMessage, [
              <b key="saved">{formatPrice(<FormatNumber value={savedAnnually} fractionDigits={2} />, currency)}</b>
            ])}
          </div>
        )}
      </div>
    );
  },
  {
    cs: {
      savingMessage: 'Ušetří vám {0} za rok!',
      perMonth: 'měsíc',
      noCurrencyMessage: 'Prosím, nejdříve vyberte měnu',
      noPriceMessage: 'Cenu nastavíme společně s vámi',
      freeLabel: 'Zadarmo'
    },
    sk: {
      savingMessage: 'Ušetrí vám {0} za rok!',
      perMonth: 'mesiac',
      noCurrencyMessage: 'Prosím, najskôr vyberte menu',
      noPriceMessage: 'Cenu nastavíme spoločne s vami',
      freeLabel: 'Zadarmo'
    },
    en: {
      savingMessage: 'Saves you {0} a year!',
      perMonth: 'month',
      noCurrencyMessage: 'Please, select currency first',
      noPriceMessage: 'We will set the price with you',
      freeLabel: 'Free'
    }
  }
);

const TalentMarketPlanItem = props => {
  const {
    plan,
    active,
    requested,
    currency,
    billingPeriod,
    onChange,
    intl: { messages }
  } = props;
  const {
    name,
    description,
    constraints: {
      timeLimit,
      maxConcurrentOpportunities,
      maxOpportunityValidity,
      companyProfilePublishable,
      presentedAsPartner,
      eventsEnabled,
      careerPostsEnabled,
      activeSearchLimit
    },
    monthlyPrices,
    annualPrices
  } = plan;
  const content = (
    <div className="relative-container">
      {requested && (
        <div style={{ position: 'absolute', top: '-4px', left: '16px' }}>
          <Badge text={messages.requestedBadge} className="bg-highlight text-white" />
        </div>
      )}
      <div className="container-flex-row border-bottom border-color-primary__50" style={{ padding: '24px 16px' }}>
        {onChange && (
          <div style={{ marginTop: '5px' }}>
            <RadioButton checked={active} />
          </div>
        )}
        <div className="flex1 text-left">
          <b className="text-sz-xl">{name}</b>
          {description && <div className="text-sz-sm text-muted">{description}</div>}
        </div>
      </div>
      <div className="mui-padded mui-padded-horizontal-x2">
        {renderConstraint(
          messages.maxConcurrentOpportunitiesLabel,
          <span>{maxConcurrentOpportunities != null ? maxConcurrentOpportunities : messages.unlimitedMessage}</span>
        )}
        {renderConstraint(
          messages.maxOpportunityValidityLabel,
          <span>
            {maxOpportunityValidity ? (
              <TimeUnit value={maxOpportunityValidity.value} unit={maxOpportunityValidity.unit} />
            ) : (
              messages.unlimitedTimeMessage
            )}
          </span>
        )}
        {renderConstraint(
          messages.companyProfilePublishableLabel,
          <span>{companyProfilePublishable ? messages.yesMessage : messages.noMessage}</span>,
          !companyProfilePublishable
        )}
        {renderConstraint(
          messages.presentedAsPartnerLabel,
          <span>{presentedAsPartner ? messages.yesMessage : messages.noMessage}</span>,
          !presentedAsPartner
        )}
        {renderConstraint(
          <span>
            {messages.eventsEnabledLabel} <ComingSoonIndicator />
          </span>,
          <span>{eventsEnabled ? messages.yesMessage : messages.noMessage}</span>,
          !eventsEnabled
        )}
        {renderConstraint(
          <span>
            {messages.careerPostsEnabledLabel} <ComingSoonIndicator />
          </span>,
          <span>{careerPostsEnabled ? messages.yesMessage : messages.noMessage}</span>,
          !careerPostsEnabled
        )}
        {renderConstraint(
          messages.activeSearchLimitLabel,
          <span>
            {activeSearchLimit === 0
              ? messages.noMessage
              : activeSearchLimit != null
              ? formatMessage(selectQuantityMessage(messages.activeSearchLimitValueMessage, activeSearchLimit), [
                  activeSearchLimit
                ])
              : messages.unlimitedMessage}
          </span>,
          activeSearchLimit === 0
        )}
      </div>
      <div className="border-top border-color-primary__50 mui-padded-x2">
        <Price
          billingPeriod={billingPeriod}
          currency={currency}
          monthlyPrices={monthlyPrices}
          annualPrices={annualPrices}
        />
        {timeLimit && (
          <div>
            <TimeUnit {...timeLimit} />
          </div>
        )}
      </div>
    </div>
  );
  if (!onChange) {
    return content;
  }
  return (
    <div className="mui-padded-half flex-grow" style={{ minWidth: '240px' }}>
      <ButtonToggle outline active={active} onToggle={() => onChange(plan)} className="flex-grow">
        {content}
      </ButtonToggle>
    </div>
  );
};

const intlMessages = {
  cs: {
    maxConcurrentOpportunitiesLabel: 'Aktivní příležitosti',
    maxOpportunityValidityLabel: 'Příležitost platná',
    companyProfilePublishableLabel: 'Můžete publikovat profil spoločnosti',
    presentedAsPartnerLabel: 'Vaše spoločnost bude prezentováná jako partner služby',
    eventsEnabledLabel: 'Můžete publikovat události',
    careerPostsEnabledLabel: 'Můžete publikovat kariérní obsah',
    activeSearchLimitLabel: 'Aktivní vyhledávaní talentů',
    activeSearchLimitValueMessage: {
      1: '{0} profil',
      '2-4': '{0} profily',
      '*': '{0} profilů'
    },
    noMessage: 'Ne',
    yesMessage: 'Ano',
    unlimitedTimeMessage: 'Bez omezení',
    unlimitedMessage: 'Bez omezení',
    requestedBadge: 'Požádán'
  },
  sk: {
    maxConcurrentOpportunitiesLabel: 'Aktívne príležitosti',
    maxOpportunityValidityLabel: 'Príležitosť platná',
    companyProfilePublishableLabel: 'Môžete publikovať profil spoločnosti',
    presentedAsPartnerLabel: 'Vaša spoločnosť bude prezentovaná ako partner služby',
    eventsEnabledLabel: 'Môžete publikovať udalosti',
    careerPostsEnabledLabel: 'Môžete publikovať kariérny obsah',
    activeSearchLimitLabel: 'Aktívne vyhľadávanie talentov',
    activeSearchLimitValueMessage: {
      1: '{0} profil',
      '2-4': '{0} profily',
      '*': '{0} profilov'
    },
    noMessage: 'Nie',
    yesMessage: 'Áno',
    unlimitedTimeMessage: 'Bez obmedzenia',
    unlimitedMessage: 'Bez obmedzenia',
    requestedBadge: 'Požiadaný'
  },
  en: {
    maxConcurrentOpportunitiesLabel: 'Active opportunities at the same time',
    maxOpportunityValidityLabel: 'Opportunity valid for',
    companyProfilePublishableLabel: 'Can publish company profile',
    presentedAsPartnerLabel: 'Presented as a partner',
    eventsEnabledLabel: 'Can post events',
    careerPostsEnabledLabel: 'Can post career content',
    activeSearchLimitLabel: 'Active talent search',
    activeSearchLimitValueMessage: {
      1: '{0} profile',
      '*': '{0} profiles'
    },
    noMessage: 'No',
    yesMessage: 'Yes',
    unlimitedTimeMessage: 'Unlimited time',
    unlimitedMessage: 'Unlimited',
    requestedBadge: 'Requested'
  }
};

export default withIntl(TalentMarketPlanItem, intlMessages);
