import React from 'react';
import map from 'lodash/map';
import { pure } from 'recompose';

import Popover from 'material-ui/Popover';

import MultiColorDonut from './MultiColorDonut';

const LINE_SIZE = 2;

const renderMultiColorDonut = (colors, size) => {
  return <MultiColorDonut colors={colors} size={size} options={{ borderWidth: 3, padding: 2 }} />;
};

const EMPTY_COLOR = 'rgba(0,0,0,0.08)';

class ValueIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false
    };
  }

  setContainerElement = el => {
    this.containerElement = el;
  };

  handleOpenPopover = () => this.setState({ popoverOpen: !!this.props.popoverContent });
  handleClosePopover = () => this.setState({ popoverOpen: false });

  render() {
    const { size, empty, colors, children, popoverContent, first, last } = this.props;
    const halfSize = size / 2;
    const offset = (size - LINE_SIZE) / 2;
    const emptyColor = EMPTY_COLOR; //'white';
    const renderLine = transparent => (
      <div
        className="value-indicator-line"
        style={{
          marginTop: offset,
          height: LINE_SIZE,
          borderStyle: 'solid',
          borderColor: transparent ? 'transparent' : emptyColor,
          borderWidth: '1px 0',
          width: `calc(50% - ${halfSize}px)`
        }}
      />
    );
    const actualColors = empty ? [emptyColor] : colors;
    return (
      <div
        ref={this.setContainerElement}
        style={{ width: '100%', lineHeight: '0', position: 'relative', cursor: 'pointer' }}
        onClick={this.handleOpenPopover}
      >
        {renderLine(first)}
        <MultiColorDonut colors={actualColors} size={size} options={{ borderWidth: size / 2 }} />
        {renderLine(last)}
        {children}
        {popoverContent && (
          <Popover
            open={this.state.popoverOpen}
            anchorEl={this.containerElement}
            onRequestClose={this.handleClosePopover}
            style={{
              boxShadow: '0 0 16px rgba(0,0,0,0.12)',
              background: 'rgba(255,255,255,0.9)',
              backdropFilter: 'blur(5px)'
            }}
          >
            <div style={{ padding: '16px 12px' }}>{popoverContent}</div>
          </Popover>
        )}
      </div>
    );
  }
}

const DOMAIN = {
  min: 0,
  max: 1,
  size: 1
};

const createRange = (min = 8, max = 38) => ({
  minSqrt: Math.sqrt(min),
  size: Math.sqrt(max) - Math.sqrt(min)
});

const RANGE = createRange();

const calcSize = (value, domain, range) => {
  const relativeValue = Math.max((value - domain.min) / domain.size, 0);
  const val = Math.round(Math.pow(relativeValue * range.size + range.minSqrt, 2));
  return val - (val % 2);
};

const renderOverlayValue = (value, domain, range, overlayColor) => {
  const size = calcSize(1, domain, range);
  const color = (value && value.color) || overlayColor;
  const valueColors = value && value.colors && value.colors.length > 0 ? value.colors : [color];
  return (
    <div className="value-indicator-overlay">
      {value && value.fit != null && (
        <div className="value-indicator-overlay-fit-label">
          Fit <strong>{(value.fit * 100).toFixed(0)}</strong>
          <span>%</span>
        </div>
      )}
      {renderMultiColorDonut(valueColors, size)}
    </div>
  );
};

const renderParticipantItem = (id, name, color) => (
  <div key={id} style={{ padding: '2px' }}>
    {color && <div className="circle-indicator ci-size-8" style={{ backgroundColor: color }} />}
    <span>{name}</span>
  </div>
);

const renderParticipantList = (participants, colors, intl) => {
  const messages = intl.messages.components.discreteMetricChart;
  if (!participants || !participants.length) {
    return null;
  }
  return (
    <div>
      {map(participants, p => {
        const { id, anonymous, firstName, lastName, email, crossComparedColor } = p;
        const color = crossComparedColor || (colors ? colors[colors.length - 1] : null);
        if (anonymous) {
          return renderParticipantItem(id, <em>{messages.anonymous}</em>, color);
        }
        return renderParticipantItem(id, lastName || firstName ? `${lastName} ${firstName}` : email, color);
      })}
    </div>
  );
};

const determineLayerColors = (layer, fallbackColor) => {
  const { participants, colors } = layer;
  if (participants && colors && colors.length && participants.length === colors.length) {
    return colors;
  }
  if (colors) {
    return [...colors, fallbackColor];
  }
  return [fallbackColor];
};

const ValueIndicatorGroup = props => {
  const { data, labels, mainColor, overlayColor, showPercentage, size, intl } = props;
  const domain = DOMAIN;
  const range = size ? createRange(size.min, size.max) : RANGE;
  const maxSize = calcSize(1, domain, range);
  const dataSize = data.length;
  return (
    <div className="value-indicator-group-container">
      {map(data, ({ values }, idx) => {
        const baseLayer = values[0];
        const overlay = values[1];
        const val = baseLayer.value;
        const empty = !val || val <= 0;
        const size = calcSize(val, domain, range);
        const verticalPadding = (maxSize - size) / 2;
        const label = labels && labels[idx];
        const baseLayerColors = determineLayerColors(baseLayer, mainColor);
        const overlayColors = overlay && determineLayerColors(overlay, overlayColor);
        const overlayParticipants = overlay && renderParticipantList(overlay.participants, overlayColors, intl);
        const baseParticipants = renderParticipantList(baseLayer.participants, baseLayerColors, intl);
        const popoverContent = (baseParticipants || overlayParticipants) && (
          <div className="text-sz-sm" style={{ lineHeight: '1.4em' }}>
            {overlayParticipants}
            {overlayParticipants && baseParticipants && (
              <div style={{ height: '1px', background: EMPTY_COLOR, width: '100%', margin: '8px 0' }} />
            )}
            {baseParticipants}
          </div>
        );
        return (
          <div key={idx} className="value-indicator-container" style={{ width: `${100 / dataSize}%` }}>
            <div style={{ padding: `${verticalPadding}px 0` }}>
              <ValueIndicator
                first={idx === 0}
                last={idx === dataSize - 1}
                size={size}
                empty={empty}
                colors={baseLayerColors}
                popoverContent={popoverContent}
              >
                {showPercentage && (
                  <div className={`value-indicator-percentage${empty ? ' empty' : ''}`}>{(val * 100).toFixed(0)} %</div>
                )}
                {overlay && overlay.value > 0 && renderOverlayValue(overlay, domain, range, overlayColor)}
                {label != null && <div className={`value-indicator-label${empty ? ' empty' : ''}`}>{label}</div>}
              </ValueIndicator>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const DiscreteMetricChart = props => {
  return <ValueIndicatorGroup {...props} />;
};

export default pure(DiscreteMetricChart);
