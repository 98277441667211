import React from 'react';
import cn from 'classnames';

import Button from 'material-ui/internal/EnhancedButton';

const MenuItem = props => {
  const { icon, className, label, active, badge, disabled, sub, inline, inverted, onClick, containerElement } = props;
  return (
    <Button disabled={disabled} style={{ width: '100%' }} onClick={onClick} containerElement={containerElement}>
      <div className={cn({ 'menu-item': true, [className]: className, active, disabled, sub, inline, inverted })}>
        {icon && <div className="menu-item__icon">{icon}</div>}
        {label && <div className="menu-item__label">{label}</div>}
        {active && <div className="menu-item__active-indicator" />}
        {badge && <div className="menu-item__badge-container">{badge}</div>}
      </div>
    </Button>
  );
};

export default MenuItem;
