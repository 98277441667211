import _find from 'lodash/find';
import _filter from 'lodash/filter';

import { EGroupMembership, EActivityType } from '../../constants/enum';
import languages from '../../constants/languages';

import { generateActivity, generateParticipant } from './TeamDataUtils';
import PRNG from '../../utils/prng';
import * as Storage from '../../persistence/storage';

const ALL_LANGUAGES = [languages.cs.value, languages.en.value, languages.sk.value];

export const getDefaultActivityConfiguration = (membership, langs) => {
  if (membership === EGroupMembership.MANAGER) {
    return {
      activities: [
        { key: EActivityType.GAME_CHANGER.value, languages: langs || ALL_LANGUAGES },
        {
          key: EActivityType.CULTURE_FIT.value,
          variant: EActivityType.CULTURE_FIT.variants.CANDIDATE.value,
          languages: langs || ALL_LANGUAGES
        }
      ]
    };
  }
  return {
    activities: [
      { key: EActivityType.OFFICE_DAY.value, languages: langs || ALL_LANGUAGES },
      {
        key: EActivityType.CULTURE_FIT.value,
        variant: EActivityType.CULTURE_FIT.variants.CANDIDATE.value,
        languages: langs || ALL_LANGUAGES
      }
    ]
  };
};

export const filterWellBeing = activities => _filter(activities, a => EActivityType.ECHO.value === a.key);

export const getActivityMapKey = a => {
  const { key, variant } = a;
  if (EActivityType.CULTURE_FIT.isLike(a)) {
    return variant ? `${key}/${variant}` : key;
  }
  return key;
};

export const getActivityMetricDefinitions = (activity, configuration) => {
  const { activities } = configuration || {};
  for (let i = 0; i < (activities || []).length; i += 1) {
    const a = activities[i];
    if (a.key === activity.key && a.variant === activity.variant) {
      return a.metricDefinitions || {};
    }
  }
  return {};
};

const getSampleActivity = (activity, metricDefinitions, rng) => {
  if (!activity) {
    return null;
  }
  return generateActivity(activity, metricDefinitions, rng);
};

const getSampleActivityResult = (activity, metricDefinitions, rng) => {
  const a = getSampleActivity(activity, metricDefinitions, rng);
  if (a) {
    return a.result;
  }
  return null;
};

export const memberToParticipant = (m, activity) => {
  const { firstName, lastName, email, anonymous, id, ...rest } = m;
  return {
    id,
    ...rest,
    participant: { firstName, lastName, email, anonymous, id },
    result: activity ? activity.result : null,
    activity
  };
};

const findMemberActivity = (m, activityKey) => _find(m.activities, a => getActivityMapKey(a) === activityKey);

export const transformMember = (m, activityKey) => {
  if (!m) {
    return null;
  }
  const a = findMemberActivity(m, activityKey);
  return memberToParticipant(m, a);
};

export const transformOrGenerateMember = (m, activity, metricDefinitions, randomGenerator) => {
  const rng = randomGenerator || new PRNG(1);
  if (!activity) {
    return memberToParticipant(generateParticipant(rng));
  }
  const activityKey = getActivityMapKey(activity);
  if (!m) {
    return memberToParticipant(generateParticipant(rng), getSampleActivity(activity, metricDefinitions, rng));
  }
  const a = findMemberActivity(m, activityKey);
  const memberActivity = a
    ? a.result ? a : { ...a, result: getSampleActivityResult(activity, metricDefinitions, rng) }
    : getSampleActivity(activity, metricDefinitions, rng);
  return memberToParticipant(m, memberActivity);
};

export const saveLastGroupId = (companyId, groupId) => {
  const key = `/companies/${companyId}/groups/last/id`;
  if (!groupId) {
    Storage.remove(key);
  } else {
    Storage.put(key, groupId);
  }
};

export const getLastGroupId = companyId => {
  return Storage.get(`/companies/${companyId}/groups/last/id`);
};

export const saveTeamState = (group, state) => {
  Storage.putObject(`/groups/${group.id}/state`, state);
};

export const getGroupState = group => {
  return Storage.getObject(`/groups/${group.id}/state`);
};

export const saveSubgroupState = (group, type, state) => {
  Storage.putObject(`/groups/${group.id}/subgroups/${type.key}/state`, state);
};

export const getSubgroupState = (group, type) => {
  return Storage.getObject(`/groups/${group.id}/subgroups/${type.key}/state`);
};

export const saveSubgroupAnalyticsState = (group, type, state) => {
  Storage.putObject(`/groups/${group.id}/subgroups/${type.key}/analytics/state`, state);
};
export const getSubgroupAnalyticsState = (group, type) => {
  return Storage.getObject(`/groups/${group.id}/subgroups/${type.key}/analytics/state`);
};

export const saveInsightsState = (group, localState, globalState) => {
  Storage.putObject(`/groups/${group.id}/insights/state`, localState);
  Storage.putObject(`/global/groups/insights/state`, globalState);
};
export const getInsightsState = group => {
  const groupLocal = Storage.getObject(`/groups/${group.id}/insights/state`);
  const globalState = Storage.getObject(`/global/groups/insights/state`);
  return {
    ...globalState,
    ...groupLocal
  };
};
