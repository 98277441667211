import React, { useCallback, useMemo, useRef, useState } from 'react';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import moment from 'moment';

import MenuItem from 'material-ui/MenuItem';
import Menu from 'material-ui/Menu';
import Divider from 'material-ui/Divider';

import IconButton from '../mui/IconButton';
import Button from '../mui/Button';
import Popover from '../mui/Popover';
import ParticipantName from '../basic/ParticipantName';
import Spinner from '../spinner/Spinner';
import TeamInsightLinkCopyToClipboard from './TeamInsightLinkCopyToClipboard';

import { EGroupFocus } from './TeamInsightsUtils';

import { formatMessage } from '../../utils/utils';

import withIntl from '../withIntl';

const insightItemButtonStyle = {
  display: 'block',
  flex: '1 1 88px',
  width: '100%',
  textAlign: 'left',
  padding: 0,
  lineHeight: 'normal',
  height: 'auto'
};

const InsightItem = props => {
  const {
    insight,
    team,
    membersMap,
    manager,
    active,
    onSelect,
    onDelete,
    intl: { messages, locale }
  } = props;

  const [menuShown, setMenuShown] = useState(false);

  const btn = useRef(null);
  const handleMenuOpen = useCallback(() => setMenuShown(true), [setMenuShown]);
  const handleMenuClose = useCallback(() => setMenuShown(false), [setMenuShown]);

  const handleSelect = useCallback(() => onSelect(insight), [onSelect, insight]);
  const handleDelete = useCallback(() => onDelete(insight), [onDelete, insight]);

  const { name, starredAt, groupFocus, individual, modifiedAt } = insight;
  return (
    <div className="container-flex-row">
      <Button onClick={handleSelect} style={insightItemButtonStyle}>
        <div className="mui-padded" style={{ fontWeight: active ? 500 : 400 }}>
          <div className="container-flex-row jc-space-between">
            <div className="text-sz-reg">{name}</div>
            {starredAt && (
              <i className="material-icons text-color-star-sub text-sz-md" style={{ marginLeft: '4px' }}>
                star
              </i>
            )}
          </div>
          <div className="text-sz-sm text-muted mui-margin-top-half">
            {groupFocus === EGroupFocus.FULL.key && messages.groupLabel[team.type]}
            {groupFocus === EGroupFocus.MEMBER.key && (
              <ParticipantName key="name" {...membersMap[individual?.id]} variant="mini" />
            )}
            {groupFocus === EGroupFocus.MANAGER.key &&
              formatMessage(messages.managerLabel, [<ParticipantName key="name" {...manager} variant="mini" />])}
          </div>
          <div className="text-sz-xs text-muted mui-margin-top-half">
            {moment(modifiedAt).locale(locale).calendar()}
          </div>
        </div>
      </Button>
      <div ref={btn} style={{ marginTop: '2px' }}>
        <IconButton onClick={handleMenuOpen} style={{ width: '28px', height: '28px' }}>
          <i className="material-icons text-muted text-sz-md">more_vert</i>
        </IconButton>
        <Popover
          open={menuShown}
          anchorEl={btn?.current}
          onRequestClose={handleMenuClose}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <Menu desktop listStyle={{ paddingTop: 8, paddingBottom: 8 }}>
            <TeamInsightLinkCopyToClipboard insight={insight} onCopy={handleMenuClose}>
              <MenuItem
                style={{ fontWeight: '400', fontSize: '14px', minHeight: '32px', lineHeight: '32px' }}
                innerDivStyle={{ padding: '0px 24px 0px 64px' }}
                primaryText={messages.shareLabel}
                leftIcon={
                  <i className="material-icons" style={{ margin: 0, top: 4, left: 24 }}>
                    link
                  </i>
                }
              />
            </TeamInsightLinkCopyToClipboard>
            <Divider />
            <MenuItem
              style={{ fontWeight: '400', fontSize: '14px' }}
              primaryText={messages.deleteLabel}
              leftIcon={<i className="material-icons">delete</i>}
              onClick={handleDelete}
            />
          </Menu>
        </Popover>
      </div>
    </div>
  );
};

const TeamInsightsList = props => {
  const {
    team,
    members,
    manager,
    insights,
    insight,
    onInsightSelect,
    onInsightDelete,
    onInsightAdd,
    intl,
    intl: { messages }
  } = props;
  const insightItems = insights.items || [];
  const membersMap = useMemo(() => _keyBy(members.items, m => m.id), [members]);
  const empty = insightItems.length === 0;
  return (
    <div className="mui-padded">
      <div className="container-flex-row jc-space-between">
        <div className="mui-label mui-padded">{messages.title}</div>
        {!empty &&
          (insights.loading ? (
            <div style={{ padding: '6px' }}>
              <Spinner show relative size={16} stroke={2} />
            </div>
          ) : (
            <IconButton style={{ width: '28px', height: '28px' }} onClick={onInsightAdd} tooltip={messages.addButton}>
              <i className="material-icons text-muted text-sz-md">add</i>
            </IconButton>
          ))}
      </div>
      {_map(insightItems, i => (
        <InsightItem
          key={i.id}
          insight={i}
          active={i.id === insight.current?.id}
          team={team}
          membersMap={membersMap}
          manager={manager}
          intl={intl}
          onSelect={onInsightSelect}
          onDelete={onInsightDelete}
        />
      ))}
      {empty && (
        <div className="mui-padded-x2 text-muted text-center">
          {insights.loading ? (
            <Spinner show relative size={24} stroke={3} />
          ) : (
            <>
              <IconButton onClick={onInsightAdd} tooltip={messages.addButton}>
                <i className="material-icons text-color-highlight">add_circle</i>
              </IconButton>
              <br />
              {messages.emptyMessage}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    title: 'Mé uložené poznatky',
    emptyMessage: 'Vytvořte si váš první poznatek',
    groupLabel: { TEAM: 'Tým', GROUP: 'Skupina', HIRING: 'Kandidáti' },
    managerLabel: 'Manažer {0}',
    deleteLabel: 'Odstranit',
    shareLabel: 'Kopírovat odkaz k sdílení',
    addButton: 'Vytvořit poznatek'
  },
  sk: {
    title: 'Moje uložené poznatky',
    emptyMessage: 'Vytvorte si váš prvý poznatok',
    groupLabel: { TEAM: 'Tím', GROUP: 'Skupina', HIRING: 'Kandidáti' },
    managerLabel: 'Manažér {0}',
    deleteLabel: 'Odstrániť',
    shareLabel: 'Kopírovať odkaz na zdieľanie',
    addButton: 'Vytvoriť poznatok'
  },
  en: {
    title: 'My saved Insights',
    emptyMessage: 'Create your first insight',
    groupLabel: { TEAM: 'Team', GROUP: 'Group', HIRING: 'Candidates' },
    managerLabel: 'Manager {0}',
    deleteLabel: 'Remove',
    shareLabel: 'Copy link to share',
    addButton: 'Create insight'
  }
};

export default withIntl(TeamInsightsList, intlMessages);
