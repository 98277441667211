import React from 'react';
import PropTypes from 'prop-types';

const r = v => Math.round(v);

const CustomBar = props => {
  const { x, y, width, height, gradient, fill, payload: { color, colorFrom, colorTo }, radius } = props;
  if (isNaN(width) || width < 0 || isNaN(height) || height < 0) {
    //noinspection JSConstructorReturnsPrimitive
    return null;
  }
  const cx = r(x);
  const cy = r(y);
  const bottom = y + height;
  const right = x + width;

  const gradientId = `gradientFill${Math.random().toString(36).substring(2)}`;

  const w = r(right - cx);
  const h = r(bottom - cy);

  const fallbackColor = fill || color;

  return (
    <g>
      {gradient && (
        <defs>
          <linearGradient id={gradientId} x1={0} x2={0} y1={0} y2={4}>
            <stop offset="0%" stopColor={colorTo || fallbackColor} />
            <stop offset="100%" stopColor={colorFrom || 'transparent'} />
          </linearGradient>
        </defs>
      )}
      <rect
        x={cx}
        y={cy}
        rx={radius == undefined ? 2 : radius}
        ry={radius == undefined ? 2 : radius}
        width={w}
        height={h}
        fill={gradient ? `url(#${gradientId})` : fallbackColor}
        style={{ shapeRendering: 'geometricPrecision' }}
      />
    </g>
  );
};

CustomBar.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  payload: PropTypes.object
};

export default CustomBar;
