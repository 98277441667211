import React from 'react';
import clr from 'color';

const EchoGridCell = props => {
  const { color, text } = props;
  const colorObject = clr(color);
  const style = {
    background: color,
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500
  };
  if (colorObject.isDark()) {
    style.color = 'white';
  }
  return <div style={style}>{text}</div>;
};

export default EchoGridCell;
