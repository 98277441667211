import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const determineBrickHeight = count => {
  return 390 / count;
  // if (count > 5) {
  //   return 40;
  // }
  // if (count > 3) {
  //   return 60;
  // }
  // return 80;
};

const Brick = props => {
  const { value, formattedValue, color, horizontal, totalCount } = props;
  const percentage = `${value}%`;
  const style = {
    width: horizontal ? `${100 / totalCount}%` : '100%',
    height: horizontal ? '100%' : determineBrickHeight(totalCount)
  };
  return (
    <div className={cn({ 'brick-wrapper': true, horizontal })} style={style}>
      {formattedValue && !horizontal && (
        <p className={'brick-label'} style={{ bottom: percentage, color }}>
          {formattedValue}
        </p>
      )}
      <div className={'brick-background'}>
        <div
          className={`brick`}
          style={{
            height: horizontal ? '100%' : percentage,
            width: horizontal ? percentage : '100%',
            backgroundColor: color
          }}
        />
      </div>
    </div>
  );
};

Brick.propTypes = {
  color: PropTypes.string,
  value: PropTypes.number,
  formattedValue: PropTypes.any
};

export default Brick;
