export default {
  cs: {
    value: 'cs',
    label: 'Česky'
  },
  sk: {
    value: 'sk',
    label: 'Slovensky'
  },
  en: {
    value: 'en',
    label: 'English'
  }
};
