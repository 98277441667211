import React from 'react';
import map from 'lodash/map';

import SimpleModal from './SimpleModal';
import Select from '../superfield/SuperSelect';
import Text from '../superfield/SuperText';

import { formatMessage } from '../../utils/utils';
import { ECompanyRejectReason, EUserOpportunityPostResponse, getEnumOrdered } from '../../constants/enum';

const REJECT_REASONS = getEnumOrdered(ECompanyRejectReason);

class OpportunityParticipantsSetResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectReasonType: '',
      rejectReasonText: '',
      rejectReasonsDataSource: []
    };
  }

  componentDidMount() {
    this.buildDataSource();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.intl !== this.props.intl) {
      this.buildDataSource();
    }
  }

  handleRejectReasonChange = key => {
    this.setState({ rejectReasonType: key });
  };

  handleRejectReasonBlur = val => {
    this.setState({ rejectReasonType: val, rejectReasonTouched: true });
  };

  handleRejectReasonTextChange = e => {
    this.setState({ rejectReasonText: e.target.value });
  };

  handleRejectReasonTextBlur = e => {
    this.setState({ rejectReasonText: e.target.value, rejectReasonTextTouched: true });
  };

  isFormValid = () => {
    const { rejectReasonText, rejectReasonType } = this.state;
    const { response } = this.props;
    return (
      response !== EUserOpportunityPostResponse.REJECTED.key ||
      (rejectReasonType && (rejectReasonType !== ECompanyRejectReason.CUSTOM.key || rejectReasonText))
    );
  };

  handleCallback = confirmed => {
    const { opportunityId, response, participant } = this.props;
    const { setResponse } = this.props;
    if (confirmed) {
      const ids = [participant.id];
      let rejectReason = null;
      if (response === EUserOpportunityPostResponse.REJECTED.key) {
        const { rejectReasonType, rejectReasonText } = this.state;
        rejectReason = { type: rejectReasonType, text: rejectReasonText && rejectReasonText.trim() };
      }
      setResponse(opportunityId, ids, response, rejectReason);
    }
  };

  buildDataSource = () => {
    const { intl } = this.props;
    const rejectReasonMessages = intl.messages.constants.enums.ECompanyRejectReason;
    const rejectReasonsDataSource = map(REJECT_REASONS, r => ({
      value: r.key,
      ...rejectReasonMessages[r.key]
    }));
    this.setState({ rejectReasonsDataSource });
  };

  render() {
    const { id, intl, response, participant } = this.props;
    const { switchModal } = this.props;
    const {
      rejectReasonType,
      rejectReasonText,
      rejectReasonTouched,
      rejectReasonTextTouched,
      rejectReasonsDataSource
    } = this.state;
    const messages = intl.messages.components.modals.opportunityParticipantsSetResponse;
    const participantResponse = participant.response && participant.response.status;
    const combinedResponse = `${response}_${participantResponse}`;
    const messageTemplate = messages.message[combinedResponse] || messages.message[response];
    return (
      <SimpleModal
        open
        id={id}
        style={{ zIndex: 1000 }}
        switchModal={switchModal}
        callback={this.handleCallback}
        cancelLabel={messages.cancelLabel}
        confirmLabel={messages.confirmLabel[combinedResponse] || messages.confirmLabel[response]}
        confirmDisabled={!this.isFormValid()}
        message={
          <div>
            <p>
              {formatMessage(messageTemplate.main, [
                <b key="0">
                  {participant.lastName} {participant.firstName}
                </b>
              ])}
            </p>
            {messageTemplate.description && <p className="small">{messageTemplate.description}</p>}
            {response === EUserOpportunityPostResponse.REJECTED.key && (
              <div>
                <Select
                  label={messages.rejectReasonsLabel}
                  dataSource={rejectReasonsDataSource}
                  values={rejectReasonType ? [rejectReasonType] : []}
                  onChange={v => this.handleRejectReasonChange(v[0])}
                  onBlur={v => this.handleRejectReasonBlur(v[0])}
                  error={rejectReasonTouched && !rejectReasonType}
                />
                {rejectReasonType === ECompanyRejectReason.CUSTOM.key && (
                  <Text
                    multiline
                    label={messages.rejectReasonTextLabel}
                    helperText={messages.rejectReasonTextHelperText}
                    onChange={this.handleRejectReasonTextChange}
                    onBlur={this.handleRejectReasonTextBlur}
                    value={rejectReasonText}
                    rows={3}
                    error={rejectReasonTextTouched && !rejectReasonText}
                  />
                )}
              </div>
            )}
          </div>
        }
      />
    );
  }
}

export default OpportunityParticipantsSetResponse;
