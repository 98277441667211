import React from 'react';
import PropTypes from 'prop-types';

import { checkForUpdate } from '../../../../utils/utils';

const edgeColor = 'rgba(33, 33, 33, 0.87)';
const r = Math.round;
const c = p => r(p - 0.5) + 0.5;

const renderPath = (src, dst) => {
  if (src.x > dst.x) {
    const xSpan = 60;
    const ySpan = 120;
    const distX = src.x - dst.x;
    const sgn = Math.sign(src.y - dst.y) || 1;

    const pts = [
      { x: src.x + xSpan, y: src.y },
      { x: src.x + xSpan, y: src.y + (sgn * ySpan) },
      { x: src.x - (distX / 2), y: src.y + (sgn * ySpan) },
      { x: dst.x - xSpan, y: src.y + (sgn * ySpan) },
      { x: dst.x - xSpan, y: dst.y },
      { x: dst.x, y: dst.y }
    ];
    return (
      <svg width={r(Math.max(src.x, dst.x) + xSpan)} height={r(Math.max(dst.y, src.y) + ySpan)}>
        <path
          d={`M ${src.x} ${src.y} C ${pts.map(p => `${p.x} ${p.y}`).join(' ')}`}
          style={{ fill: 'none', stroke: edgeColor, strokeWidth: 1, strokeDasharray: '10,5', cursor: 'pointer' }}
        />
      </svg>
    )
  }
  const distThird = (dst.x - src.x) / 3;
  return (
    <svg width={r(Math.max(src.x, dst.x) + 10)} height={r(Math.max(dst.y, src.y) + 10)}>
      <path
        d={`M ${src.x} ${src.y} C ${dst.x - distThird} ${src.y} ${src.x + distThird} ${dst.y} ${dst.x} ${dst.y}`}
        style={{ fill: 'none', stroke: edgeColor, strokeWidth: 1, strokeDasharray: '10,5', cursor: 'pointer' }}
      />
    </svg>
  );
};

class CanvasEdge extends React.Component {
  shouldComponentUpdate(nextProps) {
    return checkForUpdate(this.props, nextProps);
  }

  render() {
    const { srcNode, destNode } = this.props;
    const distFromTopSrc = r(Math.min(16, srcNode.size.height / 2));
    const distFromTopDest = r(Math.min(16, destNode.size.height / 2));
    const srcX = c(srcNode.position.left + srcNode.size.width);
    const srcY = c(srcNode.position.top + distFromTopSrc);
    const destX = c(destNode.position.left - 2);
    const destY = c(destNode.position.top + distFromTopDest);
    const connectorSize = 6;
    const src = { x: srcX, y: srcY };
    const dst = { x: destX, y: destY };
    return (
      <div style={{ position: 'absolute', top: 0, left: 0 }}>
        {renderPath(src, dst)}
        <div
          style={{
            position: 'absolute',
            top: `${destY - (connectorSize / 2)}px`,
            left: `${destX - connectorSize}px`,
            width: `${connectorSize}px`,
            height: `${connectorSize}px`,
            borderRadius: '50%',
            backgroundColor: edgeColor,
            cursor: 'pointer',
            zIndex: 51
          }}
        />
      </div>
    );
  }
}

CanvasEdge.propTypes = {
  srcNode: PropTypes.object.isRequired,
  destNode: PropTypes.object.isRequired
};

export default CanvasEdge;
