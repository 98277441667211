import { handleActions } from 'redux-actions';
import * as c from '../../constants/constants';

export const defaultState = {
  [c.APPLICANT_LOADING]: false,
  [c.APPLICANTS_LOADING]: false,
  [c.COMPANIES_LOADING]: false,
  [c.PROJECTS_LOADING]: false,
  [c.USER_LOADING]: false,
  [c.LOGIN_LOADING]: false,
  [c.EMAIL_LOADING]: false,
  [c.PASSWORD_RESET_LOADING]: false,
  [c.PROJECT_INVITATION_CREATE.PENDING]: false,
  [c.PROJECT_INVITATION_URLS_GET.PENDING]: false
};

const createAsyncActionHandler = asyncAction => ({
  [asyncAction.PENDING]: state => ({ ...state, [asyncAction.PENDING]: true }),
  [asyncAction.ERROR]: state => ({ ...state, [asyncAction.PENDING]: false }),
  [asyncAction.SUCCESS]: state => ({ ...state, [asyncAction.PENDING]: false }),
});

export default handleActions(
  {
    [c.SWITCH_LOADER]: (state, action) => {
      return {
        ...state,
        [action.payload]: !state[action.payload]
      };
    },
    ...createAsyncActionHandler(c.PROJECT_INVITATION_CREATE),
    ...createAsyncActionHandler(c.PROJECT_INVITATION_URLS_GET),
    ...createAsyncActionHandler(c.RESULTS_GET),
    ...createAsyncActionHandler(c.RESULT_GET),
    ...createAsyncActionHandler(c.PROJECT_TEMPLATES_GET)
  },
  defaultState
);
