import { handleActions } from 'redux-actions';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

import * as c from '../../constants/constants';

const defaultState = {
  loading: false,
  items: [],
  filterText: ''
};

export default handleActions(
  {
    [c.SIMULATION_MODELS_GET.PENDING]: state => ({ ...state, loading: true }),
    [c.SIMULATION_MODELS_GET.SUCCESS]: (state, { payload: { payload } }) => ({
      ...state,
      loading: false,
      items: payload
    }),
    [c.SIMULATION_MODELS_GET.ERROR]: state => ({ ...state, loading: false }),
    [c.SIMULATION_MODEL_COPY.PENDING]: state => ({ ...state, loading: true }),
    [c.SIMULATION_MODEL_COPY.ERROR]: state => ({ ...state, loading: false }),
    [c.SIMULATION_MODEL_COPY.SUCCESS]: (state, { payload: { payload } }) => ({
      ...state,
      items: sortBy([...state.items, payload], [model => model.name]),
      loading: false
    }),
    [c.SIMULATION_MODEL_DELETE.SUCCESS]: (state, { payload: { payload: { id } } }) => ({
      ...state,
      items: filter(state.items, i => i.id !== id)
    }),
    [c.SIMULATION_MODELS_FILTER_TEXT_SET]: (state, { payload }) => ({ ...state, filterText: payload })
  },
  defaultState
);
