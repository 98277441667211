import React from 'react';

import Toggle from '../../../formFields/Toggle';

const RequiredCriteriaToggle = props => {
  return (
    <div style={{ transform: 'scale(0.9)', transformOrigin: 'left' }}>
      <Toggle labelPosition="right" labelStyle={{ fontWeight: 400, lineHeight: '21px' }} {...props} />
    </div>
  );
};

export default RequiredCriteriaToggle;
