import React from 'react';
import cn from 'classnames';

const Avatar = ({ color, className, style, size, children }) => {
  const newStyle = { ...style };
  if (color) {
    newStyle.backgroundColor = color;
  }
  if (size) {
    newStyle.width = size;
    newStyle.minWidth = size;
    newStyle.height = size;
    newStyle.minHeight = size;
    newStyle.lineHeight = isNaN(size) ? size : `${size}px`;
    newStyle.fontSize = `${size / 2}px`;
  }
  return (
    <div className={cn({ 'mui-avatar': true, [className]: className })} style={newStyle}>
      <div className="mui-avatar-content">
        {children}
      </div>
    </div>
  );
};

export default Avatar;
