import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _values from 'lodash/values';

import RichText from '../../../formFields/RichTextEditor';

import languages from '../../../../constants/languages';
import { selectOpportunityPostToEdit } from '../../../../selectors/opportunityBuilder';
import { getProgress } from '../Utils';

export const ID = 'opportunityDescriptionPartForm';

const messages = {
  description: {
    descriptionLabel: 'Řekněte lidem, co mohou od příležitosti očekávat',
    descriptionPlaceholder: 'Zaměřte se především na popis pracovní náplně',
    descriptionHelperText:
      'Tip: Do textu můžete vkládat obrázky pomocí URL adresy. Stačí kliknout na ikonu obrázku v liště nástrojů.',
    contentUrlLabel: 'Odkaz na inzerát',
    contentUrlHelperText: 'Pokud máte inzerát na příležitost dostupný online',
    tagsLabel: 'Tagy',
    tagsHelperText: 'Příležitost si můžete otagovat. Tagy oddělíte mezerou.',
    languageLabel: 'Jazyk příspěvku'
  }
};

// const tagFormatter = value => value && value.toLowerCase();

const DescriptionPart = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="description"
            component={RichText}
            label={messages.description.descriptionLabel}
            helperText={messages.description.descriptionHelperText}
            placeholder={messages.description.descriptionPlaceholder}
          />
        </div>
      </div>
      {/*<div className="row">*/}
      {/*  <div className="col-sm-12">*/}
      {/*    <Field*/}
      {/*      name="contentUrl"*/}
      {/*      component={Text}*/}
      {/*      label={messages.description.contentUrlLabel}*/}
      {/*      helperText={messages.description.contentUrlHelperText}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="row">*/}
      {/*  <div className="col-sm-12">*/}
      {/*    <Field*/}
      {/*      name="tags"*/}
      {/*      component={MultiText}*/}
      {/*      label={messages.description.tagsLabel}*/}
      {/*      helperText={messages.description.tagsHelperText}*/}
      {/*      formatter={tagFormatter}*/}
      {/*      spaceSeparated*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="row">*/}
      {/*  <div className="col-sm-6">*/}
      {/*    <Field*/}
      {/*      name="language"*/}
      {/*      component={Select}*/}
      {/*      label={messages.description.languageLabel}*/}
      {/*      dataSource={dataSources.languages}*/}
      {/*      indicateRequired*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </form>
  );
};

const validate = () => {
  // if (!values.language) {
  //   errors.language = messages.description.requiredMessage;
  // }
  // if (values.contentUrl && !isURL(values.contentUrl)) {
  //   errors.contentUrl = messages.description.invalidUrlMessage;
  // }
  return {};
};

const localSelectDataSources = createSelector([], () => {
  return {
    languages: _values(languages)
  };
});

const localSelectInitialValues = createSelector([selectOpportunityPostToEdit], post => {
  const opportunity = _get(post, 'opportunity') || {};
  return {
    description: post.description,
    language: post.language && post.language.toLowerCase(),
    tags: post.tags,
    contentUrl: opportunity.contentUrl
  };
});

const mapStateToProps = (state, ownProps) => {
  const { intl } = state;
  return {
    intl,
    dataSources: localSelectDataSources(state, ownProps),
    initialValues: localSelectInitialValues(state, ownProps)
  };
};

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
  shouldError: () => true
};

DescriptionPart.ID = ID;

const getProgressCommon = o => getProgress([
  () => !!o?.description
])
DescriptionPart.getProgressFromPost = getProgressCommon;
DescriptionPart.getProgressFromValues = getProgressCommon;

export default connect(mapStateToProps)(reduxForm(formConfig)(DescriptionPart));
