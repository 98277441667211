import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Tooltip = props => {
  const { position, children, className, ...rest } = props;
  const cls = cn({
    'tooltip-text': true,
    [`tooltip-text__${position}`]: position,
    [className]: className
  });
  return (
    <div className={cls} {...rest}>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any.isRequired,
  position: PropTypes.string
};

export default Tooltip;
