import React from 'react';
import cn from 'classnames';

const IconToggleGroup = props => {
  const { children, inverted, vertical } = props;
  const className = cn({
    'container-flex-row': !vertical,
    'container-flex-column': vertical,
    'fw-yes ai-center': true,
    'bg-primary-light': !inverted,
    'bg-white': inverted
  });
  return (
    <div className={className} style={{ padding: '2px', borderRadius: '2px' }}>
      {React.Children.map(children, child => child && React.cloneElement(child, { inverted }))}
    </div>
  );
};

export default IconToggleGroup;
