import React from 'react';
import PropTypes from 'prop-types';

const Project = props => <div>{props.children}</div>;

Project.propTypes = {
  children: PropTypes.object
};

export default Project;
