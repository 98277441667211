import React from 'react';
import PropTypes from 'prop-types';

import PublicBase from './PublicBase';
import LoginForm from './../../forms/Login';

const Login = props => {
  const { auth, intl, updateLocale, login, resetPassword, email } = props;
  return (
    <PublicBase {...{ intl, updateLocale }}>
      <LoginForm login={login} {...{ auth, intl, resetPassword, email }} />
    </PublicBase>
  );
};

Login.propTypes = {
  auth: PropTypes.object,
  intl: PropTypes.object,
  login: PropTypes.func,
  email: PropTypes.string,
  resetPassword: PropTypes.func,
  updateLocale: PropTypes.func
};

export default Login;
