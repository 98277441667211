import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FlatButton from 'material-ui/FlatButton';
import MessageIcon from 'material-ui/svg-icons/communication/message';

import Card from '../mui/Card';
import CardActions from '../mui/CardActions';

const Notification = props => {
  const { notification, intl } = props;
  const { hideNotification, dismissNotification } = props;
  const { intlKey, dismissible, action } = notification;
  const messages = intl.messages.components.notifications.default;
  const messageSource = intlKey ? intl.messages.components.notifications[intlKey] : notification;
  return (
    <Card>
      <div style={{ padding: '16px' }}>
        <div style={{ fontSize: '12px', color: 'rgba(33,33,33,.54)', marginBottom: '1px' }}>
          <MessageIcon
            color="#ff9700"
            style={{ width: '16px', height: '16px', marginRight: '6px', verticalAlign: 'middle' }}
          />
          <span style={{ color: '#ff9700', marginRight: '3px', verticalAlign: 'middle' }}>{messages.source}</span>
          <span style={{ marginRight: '3px', fontSize: '16px', verticalAlign: 'middle', fontWeight: '500' }}>
            &middot;
          </span>
          <span style={{ verticalAlign: 'middle' }}>
            {moment(notification.timestamp)
              .locale(intl.locale)
              .calendar(null, { sameDay: 'LT' })}
          </span>
        </div>
        <div style={{ fontSize: '13px' }}>
          <span style={{ color: 'rgba(33,33,33,.87)', display: 'block' }}>{messageSource.title}</span>
          <span style={{ color: 'rgba(33,33,33,.54)', display: 'block' }}>{messageSource.message}</span>
        </div>
      </div>
      <CardActions style={{ backgroundColor: '#eee' }}>
        {dismissible && (
          <FlatButton
            primary
            style={{ verticalAlign: 'top' }}
            onClick={() => dismissNotification(notification)}
            label={messages.buttons.dismiss}
          />
        )}
        {action && (
          <a href={action.url} target="_blank" rel="noopener noreferrer">
            <FlatButton primary style={{ verticalAlign: 'top' }} label={action.label} />
          </a>
        )}
        <FlatButton
          primary
          style={{ verticalAlign: 'top' }}
          onClick={() => hideNotification(notification)}
          label={messages.buttons.hide}
        />
      </CardActions>
    </Card>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  //
  hideNotification: PropTypes.func.isRequired,
  dismissNotification: PropTypes.func.isRequired
};

export default Notification;
