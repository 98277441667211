import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import map from 'lodash/map';

import CardHeader from '../mui/CardHeader';
import Chip from '../mui/Chip';
import Badge from '../badge/Badge';
import ProjectTypeBadge from '../project/ProjectTypeBadge';

import { EProjectTag, determineEActivityType } from '../../constants/enum';
import { selectAccountPermissions } from '../../selectors/auth';
import ProjectGroupLabel from '../projects/ProjectGroupLabel';

const determineVariantMessage = (activityType, intl) => {
  if (!activityType || !activityType.variant || !activityType.type) {
    return null;
  }
  const typeMessages = intl.messages.constants.enums.EActivityTypeVariant[activityType.type.key];
  if (!typeMessages) {
    return null;
  }
  const variantMessage = typeMessages[activityType.variant.key];
  if (!variantMessage) {
    return null;
  }
  return variantMessage.label;
};

const ProjectCardHeader = props => {
  const {
    project: { name, activity, tags, company, group, groupMembership },
    companyNameDisabled,
    auth,
    intl,
    style
  } = props;
  const messages = intl.messages.components.cards.project;
  const lang = activity.language && activity.language.toUpperCase();
  const activityType = determineEActivityType(props.project);
  const variantMessage = determineVariantMessage(activityType, intl);
  const multiCompanies = auth.multipleCompanies;
  return (
    <CardHeader
      className="project-card-header"
      style={style}
      title={name}
      subtitle={
        <div>
          <div className="container-flex-row ai-center fw-yes" style={{ margin: '0 -8px' }}>
            {multiCompanies && company && !companyNameDisabled && (
              <div className="mui-label text-sz-sm mui-margin-horizontal">{company.name}</div>
            )}
            {group && (
              <div className="mui-margin-horizontal mui-margin-bottom-half">
                <ProjectGroupLabel project={props.project} group={group} groupMembership={groupMembership} />
              </div>
            )}
          </div>
          <span>
            <span>{activity.name}</span>
            {variantMessage && <small style={{ marginLeft: '4px' }}>({variantMessage})</small>}
            <div style={{ display: 'inline-block', verticalAlign: 'middle', margin: '0 8px' }}>
              <Chip iconLeft={'language'} variant="mini">
                {lang}
              </Chip>
            </div>
            <ProjectTypeBadge project={props.project} />
            {tags &&
              map(tags, (t, idx) => (
                <Badge
                  key={idx}
                  text={t.custom ? t.value : messages.tags[t.value]}
                  style={{ color: 'white', backgroundColor: t.color || EProjectTag[t.value].color }}
                />
              ))}
          </span>
        </div>
      }
    />
  );
};

ProjectCardHeader.propTypes = {
  project: PropTypes.object.isRequired,
  style: PropTypes.object
};

const mapStateToProps = (state, ownProps) => ({ intl: state.intl, auth: selectAccountPermissions(state, ownProps) });

export default connect(mapStateToProps)(ProjectCardHeader);
