import Defaults from 'superagent-defaults';
import { loadToken } from '../utils/utils';

export function authRequest(auth) {
  const superagent = new Defaults();

  const token = loadToken();

  if (auth) {
    superagent.set('Authorization', 'Basic ZGFzaGJvYXJkQ2xpZW50Og==');
  } else if (token) {
    superagent.set('Authorization', `Bearer ${token}`);
  }

  return superagent;
}
