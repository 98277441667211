import React from 'react';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _orderBy from 'lodash/orderBy';

import Spinner from '../spinner/Spinner';
import MetricIcon from '../icons/Metric';
import InfoTooltip from '../basic/InfoTooltip';

import * as Api from '../../api/entities/applicantApi';

import { getNumericalBaseMetrics } from '../../utils/analytics';
import { ERank } from '../../constants/enum';

import withIntl from '../withIntl';

const renderMetricInterpretation = (m, metricResult) => {
  const { color, interpretation } = metricResult || {};
  const bullets = (interpretation && interpretation.bullets) || [];
  return (
    <div key={m.key} className="mui-padded-vertical-half container-flex-row">
      <InfoTooltip content={m.label} compact dark delay={200}>
        <MetricIcon metric={m} color={color} />
      </InfoTooltip>
      <ul style={{ margin: 0, paddingLeft: '30px' }}>
        {_map(bullets, (b, idx) => (
          <li key={idx}>{b}</li>
        ))}
      </ul>
    </div>
  );
};

const sortMetrics = m => _orderBy(m, [x => x.result.value, x => x.metric.displayOrder], ['desc', 'asc']);

const groupMetrics = (metrics, metricsResults) => {
  const { significant, other } = _reduce(
    metrics,
    (res, metric) => {
      const mr = metricsResults[metric.key];
      if (mr) {
        const value = { metric, result: mr };
        if (mr.rank === ERank.HIGH.key || mr.rank === ERank.LOW.key) {
          res.significant.push(value);
        } else {
          res.other.push(value);
        }
      }
      return res;
    },
    { significant: [], other: [] }
  );
  return {
    significant: sortMetrics(significant),
    other: sortMetrics(other)
  };
};

const renderMetrics = (metrics, type, messages) => {
  const msg = messages.byType[type];
  return (
    <div>
      <div className="mui-label">{msg.title}</div>
      {metrics.length > 0 ? (
        _map(metrics, ({ metric, result }) => (
          <div key={metric.key} className="mui-padded-vertical-half">
            {renderMetricInterpretation(metric, result)}
          </div>
        ))
      ) : (
        <div className="mui-padded-x2 border-radius bg-muted-light text-muted text-center text-sz-reg-sm mui-margin-vertical">
          {msg.emptyMessage}
        </div>
      )}
    </div>
  );
};

class ActivityParticipantInterpretation extends React.Component {
  state = {
    loading: 0,
    result: null,
    metricDefinitions: null
  };

  componentDidMount() {
    this.loadData(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.participant !== this.props.participant || prevProps.intl !== this.props.intl) {
      this.loadData(this.props);
    }
  }

  loadData = props => {
    const {
      participant: {
        participant: { id },
        activity: { project }
      },
      intl: { locale }
    } = props;
    this.setState({ loading: this.state.loading + 1 });
    Api.getResult(project.company.slug, project.slug, id, locale)
      .then(({ payload }) => {
        const { metricDefinitions, result } = payload || {};
        this.setState({ metricDefinitions, result });
      })
      .finally(() => this.setState({ loading: this.state.loading - 1 }));
  };

  render() {
    const { loading, metricDefinitions, result } = this.state;
    const {
      intl: { messages }
    } = this.props;
    if (loading > 0) {
      return (
        <div style={{ position: 'relative', minHeight: '96px' }}>
          <Spinner show local />
        </div>
      );
    }
    if (!metricDefinitions || !result) {
      return null;
    }
    const metrics = getNumericalBaseMetrics(metricDefinitions);
    if (metrics.length === 0) {
      return null;
    }
    const { significant, other } = groupMetrics(metrics, result.metrics);
    return (
      <div className="team__activity-participant-interpretations">
        <div className="row container-flex-row fw-yes" style={{ padding: '0 24px' }}>
          <div className=" col-xs-12 col-sm-6">{renderMetrics(significant, 'SIGNIFICANT', messages)}</div>
          <div className=" col-xs-12 col-sm-6">{renderMetrics(other, 'MODERATE', messages)}</div>
        </div>
      </div>
    );
  }
}

const intlMessages = {
  cs: {
    byType: {
      SIGNIFICANT: {
        title: 'Výrazné faktory',
        emptyMessage: 'Účastník není výrazně profilovaný (vyhraněný)'
      },
      MODERATE: {
        title: 'Ostatní faktory',
        emptyMessage: 'Účastník je pouze výrazně profilovaný (vyhraněný)'
      }
    }
  },
  sk: {
    byType: {
      SIGNIFICANT: {
        title: 'Výrazné faktory',
        emptyMessage: 'Účastník nie je výrazne profilovaný (vyhranený)'
      },
      MODERATE: {
        title: 'Ostatné faktory',
        emptyMessage: 'Účastník je len výrazne profilovaný (vyhranený)'
      }
    }
  },
  en: {
    byType: {
      SIGNIFICANT: {
        title: 'Significant factors',
        emptyMessage: "The participant doesn't show significant factors"
      },
      MODERATE: {
        title: 'Other factors',
        emptyMessage: 'The participant only shows significant factors'
      }
    }
  }
};

export default withIntl(ActivityParticipantInterpretation, intlMessages);
