import React from 'react';
import PropTypes from 'prop-types';

import SelectOverlay from '../select/SelectOverlay';

import withIntl from '../withIntl';
import * as Api from '../../api/entities/groupApi';

class TeamSelect extends React.Component {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
    currentTeamId: PropTypes.string,
    //
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired
  };

  state = {
    items: [],
    loading: 0
  };

  componentDidMount() {
    this.loadItems(null);
  }

  loadItems = search => {
    const { companyId, type } = this.props;
    this.setState({ loading: this.state.loading + 1 });
    Api.getGroups(type, companyId, search, { page: 0, size: 100, sort: 'name,desc' })
      .then(({ items, page }) => {
        if (this.state.loading === 1) {
          this.setState({ items, page });
        }
      })
      .finally(() => this.setState({ loading: this.state.loading - 1 }));
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleSelect = t => {
    this.props.onSelect(t);
  };

  footerRenderer = data => {
    const { footerRenderer } = this.props;
    if (footerRenderer) {
      return footerRenderer({ data, state: this.state });
    }
    return null;
  };

  render() {
    const { items } = this.state;
    const { intl, currentTeamId } = this.props;
    const messages = intl.messages.components.teams.teamSelect;
    const enumMessages = intl.messages.constants.enums.EGroupType;
    return (
      <SelectOverlay
        items={items}
        messages={{ searchPlaceholder: messages.searchPlaceholder }}
        itemRenderer={g => (
          <div style={{ fontWeight: currentTeamId === g.id ? '500' : '400' }}>
            <div>{g.name}</div>
            <div className="small">{enumMessages[g.type] ? enumMessages[g.type].label : g.type}</div>
          </div>
        )}
        footerRenderer={this.footerRenderer}
        onSearchChange={this.loadItems}
        onClose={this.handleClose}
        onSelect={this.handleSelect}
      />
    );
  }
}

export default withIntl(TeamSelect);
