import { authRequest } from '../Request';
import { API_URL } from '../../config';

export function getOpportunities() {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/opportunities`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getOpportunity(id, lang) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/opportunities/${id}`)
      .query({ lang })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getOpportunityInteractions(id) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/opportunities/${id}/interactions`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getOpportunityParticipant(id, participantId, lang) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/opportunities/${id}/participants/${participantId}`)
      .query({ lang })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function setOpportunityParticipantsResponse(id, participantIds, response, rejectReason) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/opportunities/${id}/participants/response`)
      .send({ participantIds, response, rejectReason })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function performOpportunityActiveSearch(id, query, page) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/opportunities/${id}/talents/search`)
      .query({ ...query, page: (page && page.number) || 0, size: (page && page.size) || 20 })
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function getOpportunityPost(id) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/admin/opportunities/posts/${id}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function createOpportunityPost(data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/admin/opportunities/posts`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function updateOpportunityPost(id, data) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .put(`${API_URL}/api/v1/admin/opportunities/posts/${id}`)
      .send(data)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
