import { handleActions } from 'redux-actions';

import {
  COMPANY_PROFILE_GET,
  COMPANY_PROFILE_DRAFT_DISCARD,
  COMPANY_PROFILE_DRAFT_PUBLISH,
  COMPANY_PROFILE_DRAFT_UNPUBLISH,
  COMPANY_PROFILE_UNSET,
  COMPANY_PROFILE_SAVE
} from '../../constants/constants';

const initialState = {
  profile: null,
  loading: false
};

const handleProfileSet = (state, { payload: { payload } }) => ({
  ...state,
  profile: payload,
  loading: false,
  error: false
});
const handlePending = state => ({ ...state, loading: true });
const handleError = state => ({ ...state, loading: false, error: true });

export default handleActions(
  {
    [COMPANY_PROFILE_GET.SUCCESS]: handleProfileSet,
    [COMPANY_PROFILE_GET.PENDING]: handlePending,
    [COMPANY_PROFILE_GET.ERROR]: handleError,
    [COMPANY_PROFILE_SAVE.SUCCESS]: handleProfileSet,
    [COMPANY_PROFILE_SAVE.PENDING]: handlePending,
    [COMPANY_PROFILE_SAVE.ERROR]: handleError,
    [COMPANY_PROFILE_DRAFT_DISCARD.SUCCESS]: handleProfileSet,
    [COMPANY_PROFILE_DRAFT_DISCARD.PENDING]: handlePending,
    [COMPANY_PROFILE_DRAFT_DISCARD.ERROR]: handleError,
    [COMPANY_PROFILE_DRAFT_PUBLISH.SUCCESS]: handleProfileSet,
    [COMPANY_PROFILE_DRAFT_PUBLISH.PENDING]: handlePending,
    [COMPANY_PROFILE_DRAFT_PUBLISH.ERROR]: handleError,
    [COMPANY_PROFILE_DRAFT_UNPUBLISH.SUCCESS]: handleProfileSet,
    [COMPANY_PROFILE_DRAFT_UNPUBLISH.PENDING]: handlePending,
    [COMPANY_PROFILE_DRAFT_UNPUBLISH.ERROR]: handleError,
    [COMPANY_PROFILE_UNSET]: () => initialState
  },
  initialState
);
