import React from 'react';

const MetricTick = props => {
  const { x, y, fill, metrics, payload, horizontal } = props;
  const { metric } = metrics[payload.index];
  const name = metric.label;
  const className = `metrics-bubble-chart__metric-label${metric.inactive ? ' inactive' : ''}`;
  if (horizontal) {
    return (
      <text x={x + 6} y={y - 10} fill={fill} textAnchor="start" className={className}>
        {name}
      </text>
    );
  }
  return (
    <g transform={`translate(${x - 10},${y})`}>
      <text x={6} y={0} dy={0} textAnchor="start" fill={fill} transform="rotate(-90)" className={className}>
        {name}
      </text>
    </g>
  );
};

export default MetricTick;
