import * as Api from '../../api/entities/accountApi';
import * as c from '../../constants/constants';

export function createNewAccount(data, basePath = '/account/new') {
  const account = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: data.phone,
    password: data.password,
    companyName: data.companyName,
    language: data.language,
    marketingAllowed: !!data.marketingAllowed,
    promoCode: data.promoCode,
    talentMarketPlan: data.talentMarketPlan
  };
  return {
    types: [c.SWITCH_MODAL, c.ACCOUNT_NEW_LOADING, c.SWITCH_MODAL],
    callAPI: () => Api.createAccount(account),
    forcedCall: true,
    meta: {
      successPayload: {
        id: c.MODAL_ACCOUNT_NEW_RESULT,
        success: true
      },
      successRedirect: `${basePath}?success`,
      errorPayload: {
        id: c.MODAL_ACCOUNT_NEW_RESULT,
        success: false
      },
      errorRedirect: `${basePath}?error`
    }
  };
}

export function activateAccount(data, successMessageKey, errorMessageKey, successRedirect) {
  const snackbarId = `snackbarAccountActivation_${data.email}`;
  return {
    types: [c.SNACKBAR_SHOW, c.ACCOUNT_ACTIVATION_LOADING, c.SNACKBAR_SHOW],
    callAPI: () => Api.activateAccount(data),
    forcedCall: true,
    meta: {
      successRedirect,
      successPayload: {
        id: snackbarId,
        messageKey: successMessageKey
      },
      errorPayload: {
        id: snackbarId,
        messageKey: errorMessageKey
      }
    }
  };
}

export function upgradeAccount(data) {
  return {
    types: [c.SWITCH_MODAL, c.UPGRADE_ACCOUNT_LOADING, c.SWITCH_MODAL],
    callAPI: () => (isNaN(data.credits) ? Api.upgradeAccount(data) : Api.upgradeAccountCredits(data)),
    forcedCall: true,
    meta: {
      successPayload: {
        id: c.MODAL_ACCOUNT_UPGRADE,
        open: true,
        status: c.STATUS_SUCCESS
      },
      errorPayload: {
        id: c.MODAL_ACCOUNT_UPGRADE,
        open: true,
        status: c.STATUS_ERROR
      }
    }
  };
}

export function invokeUpgradeAccountModal(data) {
  return {
    type: c.SWITCH_MODAL,
    payload: {
      id: c.MODAL_ACCOUNT_UPGRADE,
      status: null,
      data
    }
  };
}
