import { handleActions } from 'redux-actions';

import { PROJECT_TEMPLATES_GET } from '../../constants/constants';

const defaultState = {
  templates: []
};

export default handleActions({
  [PROJECT_TEMPLATES_GET.SUCCESS]: (state, { payload }) => ({ ...state, templates: payload }),
  [PROJECT_TEMPLATES_GET.ERROR]: (state) => ({ ...state, error: true })
}, defaultState);
