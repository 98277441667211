import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import allActions from './../../actions';
import { decodeToken, deleteToken, canAccessProjects, canAccessGroups } from '../../utils/utils';
import { redirectAuthenticated } from '../../utils/route';
import { HTTPErrorCodes } from '../../constants/enum';

import LoginPage from './../../components/pages/public/Login';

class Login extends React.Component {
  componentWillMount() {
    const { location } = this.props;
    if (location.pathname === '/logout') {
      deleteToken();
      this.props.logout();
    }
  }

  login = ({ username, password }) => {
    const { loginWithCredentials, getUser, getCompanies, getProjectsAll, showSnackbar, router, intl } = this.props;
    const messages = intl.messages.components.snackbar;

    return loginWithCredentials(username, password, true)
      .then(() => {
        const decodedToken = decodeToken();
        if (isEmpty(decodedToken)) {
          router.push({ pathname: '/logout' });
        } else {
          router.push({ pathname: '/' });
        }
        const promiseArray = [getUser(decodedToken).then(({ payload }) => ({ ...decodedToken, ...payload }))];
        if (canAccessProjects(decodedToken) || canAccessGroups(decodedToken)) {
          promiseArray.push(getCompanies(true));
        }
        return Promise.all(promiseArray);
      })
      .then(([auth, companiesRes]) => {
        const companies = companiesRes && companiesRes.items;
        if (companies) {
          getProjectsAll(companies);
        }
        return redirectAuthenticated(router, auth, companies);
      })
      .catch(err => {
        if (err.status !== HTTPErrorCodes.BAD_REQUEST && err.status !== HTTPErrorCodes.FORBIDDEN) {
          showSnackbar({ id: 'ERROR', message: messages.error });
        }
        return Promise.reject(err);
      });
  };

  resetPassword = () => {
    const { router } = this.props;
    router.push({ pathname: '/reset-password' });
  };

  render() {
    const { auth, intl, location, updateLocale } = this.props;
    const pageProps = {
      auth,
      intl,
      updateLocale,
      email: location.query.email,
      login: this.login,
      resetPassword: this.resetPassword
    };

    return <LoginPage {...pageProps} />;
  }
}

Login.propTypes = {
  auth: PropTypes.object,
  intl: PropTypes.object,
  router: PropTypes.object,
  location: PropTypes.object,
  updateLocale: PropTypes.func,
  loginWithCredentials: PropTypes.func,
  getUser: PropTypes.func,
  getCompanies: PropTypes.func,
  getProjectsAll: PropTypes.func,
  showSnackbar: PropTypes.func
};

const mapStateToProps = state => {
  const { auth, intl } = state;
  return {
    auth,
    intl
  };
};

const mapDispatchToProps = dispatch => ({ ...bindActionCreators(allActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
