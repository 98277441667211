import { handleActions } from 'redux-actions';
import indexOf from 'lodash/indexOf';

import * as c from '../../constants/constants';
import { hasFlag } from '../../utils/utils';

const defaultState = {};

const enhanceAuth = auth => ({
  ...auth,
  isAdmin: indexOf(auth.authorities, 'ADMIN') >= 0 || indexOf(auth.authorities, 'ADMIN_LOCAL') >= 0,
  canUseEvaluationDescription: hasFlag(auth, 'EVALUATION_DESCRIPTION'),
  canEditProjects: hasFlag(auth, c.FLAGS_POSITION_CREATION),
  canExportProjects: hasFlag(auth, c.FLAGS_PROJECT_DATA_EXPORT),
  canUseDashboardChart: hasFlag(auth, c.FLAGS_DASHBOARD_CHART),
  canUseWeights: hasFlag(auth, c.FLAGS_WEIGHTS),
  canUseEmailInvitation: hasFlag(auth, c.FLAGS_EMAIL_INVITATION)
});

export default handleActions(
  {
    [c.LOGIN_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...enhanceAuth(payload),
      error: null
    }),
    [c.LOGIN_FAILURE]: (state, { payload }) => ({
      ...payload
    }),
    [c.SET_LANGUAGE_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      error: null
    }),
    [c.SET_LANGUAGE_FAILURE]: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    [c.LOGOUT]: (state, action) => defaultState
  },
  defaultState
);
