import React from 'react';
import { Link, withRouter } from 'react-router';

import MenuItem from '../menu/MenuItem';

const NavigationMenuItem = props => {
  //noinspection JSUnusedLocalSymbols
  const { to, external, disabled, location, params, router, routes, ...rest } = props;
  const active = to && location.pathname.startsWith(to);
  if (disabled || !to) {
    return <MenuItem active={active} disabled={disabled} {...rest} />;
  }
  if (external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer">
        <MenuItem active={active} {...rest} />
      </a>
    );
  }
  return (
    <Link to={to}>
      <MenuItem active={active} {...rest} />
    </Link>
  );
};

export default withRouter(NavigationMenuItem);
