import { handleActions } from 'redux-actions';
import _filter from 'lodash/filter';

import * as C from '../../constants/constants';

const defaultState = {
  advancedGeneralSettings: false,
  views: {
    items: []
  },
  chats: {
    items: []
  }
};

const filterChats = payload => {
  return {
    ...payload,
    items: _filter(payload.items || [], i => i.type === 'CHAT')
  };
};

export default handleActions(
  {
    [C.PROJECT_EDIT_ADVANCED_GENERAL_SETTINGS_TOGGLE]: (state, { payload }) => ({
      ...state,
      advancedGeneralSettings: !state.advancedGeneralSettings
    }),
    [C.PROJECT_EDIT_LOAD_VIEWS.SUCCESS]: (state, { payload }) => ({ ...state, views: { ...payload } }),
    [C.PROJECT_EDIT_LOAD_VIEWS.ERROR]: state => ({ ...state, views: {} }),
    [C.PROJECT_EDIT_LOAD_CHATS.SUCCESS]: (state, { payload }) => ({ ...state, chats: filterChats(payload || {}) }),
    [C.PROJECT_EDIT_LOAD_CHATS.ERROR]: state => ({ ...state, chats: {} })
  },
  defaultState
);
