import React from 'react';
import { Bar, XAxis, YAxis, BarChart, ResponsiveContainer, ReferenceLine, Tooltip } from 'recharts';
import _map from 'lodash/map';
import _get from 'lodash/get';

import CustomBar from '../overviewChart/Bar';
import IconPanel from './IconPanel';

import { metricsToTree } from '../../utils/analytics';

const Y_AXIS_WIDTH = 27;

const calcX = (itemCount, index, width, x) => {
  return itemCount ? Y_AXIS_WIDTH + index * (width / itemCount) : x;
};

const CustomTooltipContent = props => {
  const { payload, active } = props;
  const p = payload && payload.length && payload[0].payload;
  if (!p || !active) {
    //noinspection JSConstructorReturnsPrimitive
    return null;
  }
  return (
    <div className="chart__tooltip">
      <div>
        <span>{p.label}</span> <strong>{p.score}</strong>
      </div>
    </div>
  );
};

const CustomMetricTick = props => {
  const { x, y, fill, width, index, data } = props;
  const item = data[index];
  const name = item.label;
  const ax = calcX(data.length, index, width, x);
  return (
    <g transform={`translate(${ax},${y})`} className="metric-tick">
      <text x={8} y={12} fill={fill} textAnchor="start" transform="rotate(-90)">
        {name}
      </text>
    </g>
  );
};

const getDataItem = (m, participant) => {
  const pm = _get(participant, `result.metrics.${m.key}`);
  const pmv = pm && pm.value;
  const dataItem = { metric: m };
  if (pmv != undefined) {
    dataItem.score = Math.round(pmv * 10);
    dataItem.color = pm.color;
    dataItem.label = m.label;
  }
  return dataItem;
};

const buildData = (metrics, participant) => {
  const data = [];
  const tree = metricsToTree(metrics);
  const push = item => {
    item.index = data.length;
    data.push(item);
  };
  for (let i = 0; i < tree.length; i += 1) {
    const r = tree[i];
    const { children } = r;
    if (children && children.length) {
      for (let j = 0; j < children.length; j += 1) {
        const c = children[j];
        const dataItem = getDataItem(c, participant);
        dataItem.parent = getDataItem(r, participant);
        dataItem.parent.childrenCount = children.length;
        if (j === 0) {
          dataItem.firstChild = true;
        }
        push(dataItem);
      }
    } else {
      const dataItem = getDataItem(r, participant);
      push(dataItem);
    }
  }
  return data;
};

const TICKS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const Y_DOMAIN = [0, 10];

const CompetencyBarChart = props => {
  const { metrics, participant } = props;

  const data = buildData(metrics, participant);

  return (
    <div style={{ overflowX: 'auto' }}>
      <div
        style={{
          minWidth: Y_AXIS_WIDTH + data.length * 52,
          maxWidth: Y_AXIS_WIDTH + data.length * 72
        }}
      >
        <ResponsiveContainer height={240}>
          <BarChart data={data} barCategoryGap={12}>
            {_map(TICKS, t => <ReferenceLine key={t} y={t} strokeDasharray="5 5" stroke="rgba(0,0,0,0.08)" />)}
            <XAxis
              type={'category'}
              dataKey={'index'}
              tick={<CustomMetricTick data={data} />}
              tickLine={false}
              axisLine={false}
              height={10}
            />
            <YAxis
              domain={Y_DOMAIN}
              ticks={TICKS}
              interval={0}
              tickLine={false}
              axisLine={false}
              width={Y_AXIS_WIDTH}
              tickSize={15}
            />
            <Tooltip cursor={false} content={<CustomTooltipContent />} />
            <Bar dataKey={'score'} shape={<CustomBar gradient />} isAnimationActive={false} />
          </BarChart>
        </ResponsiveContainer>
        <div style={{ padding: `0 5px 8px ${Y_AXIS_WIDTH + 5}px`, marginTop: '-3px' }}>
          <IconPanel data={data} />
        </div>
      </div>
    </div>
  );
};

export default CompetencyBarChart;
