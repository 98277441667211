import React from 'react';
import map from 'lodash/map';

import PropTypes from 'prop-types';

import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';
import NavigationChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import NavigationChevronRight from 'material-ui/svg-icons/navigation/chevron-right';

import GridLabel from './GridLabel';

import withIntl from '../withIntl';

const GridPaginator = props => {
  const { intl, options: { pageNumber, pageSize, totalFilteredElements, totalPages } } = props;

  const menuItems = map([10, 20, 30, 50, 100, 200], option => (
    <MenuItem value={option} key={option} primaryText={`${option}`} />
  ));

  const minCurrent = pageNumber * pageSize + 1;
  let maxCurrent = minCurrent + pageSize - 1;
  if (maxCurrent > totalFilteredElements) {
    maxCurrent = totalFilteredElements;
  }

  const onPageSizeChanged = (event, key, value) => {
    props.onPageSize(value);
  };

  const onShiftRight = () => {
    if (pageNumber < totalPages - 1) {
      props.onPageNumber(pageNumber + 1);
    }
  };

  const onShiftLeft = () => {
    if (pageNumber > 0) {
      props.onPageNumber(pageNumber - 1);
    }
  };
  const messages = intl.messages.components.grid.paginator;
  const fontSettings = {
    color: 'rgba(33,33,33,.54)',
    fontSize: '12px'
  };
  const styles = {
    btn: { minWidth: '24px', width: '24px', height: '24px', marginLeft: '24px' },
    dropdown: {
      style: { ...fontSettings, verticalAlign: 'middle', height: '56px', bottom: '1px' },
      labelStyle: { ...fontSettings, paddingLeft: '8px' },
      iconStyle: { color: fontSettings.color, fill: fontSettings.color },
      underlineStyle: { display: 'none' }
    }
  };

  return (
    <div className="grid-paginator">
      <GridLabel> {messages.rows} </GridLabel>
      <DropDownMenu value={pageSize} onChange={onPageSizeChanged} {...styles.dropdown}>
        {menuItems}
      </DropDownMenu>
      <GridLabel> {`${minCurrent}-${maxCurrent} ${messages.of} ${totalFilteredElements}`} </GridLabel>
      <FlatButton
        icon={<NavigationChevronLeft color={minCurrent <= 1 ? 'rgba(0,0,0,.19)' : fontSettings.color} />}
        onClick={onShiftLeft}
        disabled={minCurrent <= 1}
        style={styles.btn}
      />
      <FlatButton
        icon={
          <NavigationChevronRight
            color={maxCurrent >= totalFilteredElements ? 'rgba(0,0,0,.19)' : fontSettings.color}
          />
        }
        onClick={onShiftRight}
        disabled={maxCurrent >= totalFilteredElements}
        style={styles.btn}
      />
    </div>
  );
};

GridPaginator.propTypes = {
  onPageSize: PropTypes.func,
  onPageNumber: PropTypes.func,
  options: PropTypes.object,
  page: PropTypes.number,
  styles: PropTypes.object,
  intl: PropTypes.object
};

export default withIntl(GridPaginator);
