import React from 'react';
import PropTypes from 'prop-types';

import ResetPasswordForm from './../../forms/ResetPassword';
import PublicBase from './PublicBase';

const ResetPassword = props => {
  const { intl, passwordReset, updateLocale } = props;
  return (
    <PublicBase {...{ intl, updateLocale }}>
      <ResetPasswordForm {...{ intl, passwordReset }} />
    </PublicBase>
  );
};

ResetPassword.propTypes = {
  modal: PropTypes.object,
  intl: PropTypes.object,
  passwordReset: PropTypes.func,
  updateLocale: PropTypes.func,
  onModalClose: PropTypes.func
};

export default ResetPassword;
