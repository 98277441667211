import React from 'react';

import RaisedButton from 'material-ui/RaisedButton';

const Slide = props => {
  const { media, title, subtitle, buttonLabel, onButtonClick } = props;
  return (
    <div className="guide-slide">
      <div className="guide-slide-media">{media}</div>
      <div className="guide-slide-content">
        {title && <div className="guide-slide-title">{title}</div>}
        {subtitle && <div className="guide-slide-subtitle">{subtitle}</div>}
        {buttonLabel && (
          <div className="guide-slide-button">
            <RaisedButton label={buttonLabel} onClick={onButtonClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Slide;
