import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import withIntl from '../withIntl';

import Tooltip from '../tooltip/Tooltip';

import { selectAccountPermissions } from '../../selectors/auth';
import { EContractPlanMode } from '../../constants/enum';

const _mapAtMost = (values, max, mapper) => {
  const res = [];
  for (let i = 0; i < Math.min(max, values.length); i += 1) {
    res.push(mapper(values[i], i, values));
  }
  return res;
};

const SHOW_LIMIT = 1;

class GroupsPanel extends React.Component {
  state = {
    expanded: true
  };

  handleToggleExpanded = () => this.setState({ expanded: !this.state.expanded });

  render() {
    const {
      participant,
      multiCompanies,
      groupsEnabled,
      containerElement,
      intl: { messages }
    } = this.props;
    if (!groupsEnabled) {
      return null;
    }
    const { expanded } = this.state;
    const { groups } = participant;
    if (!groups || groups.length <= 0) {
      return null;
    }
    const elem = (
      <div className="mui-padded-half">
        <div className="container-flex-row fw-yes ai-center">
          <div className="tooltip-element inline-container text-primary-sub" style={{ marginRight: '4px' }}>
            <i className="material-icons" style={{ fontSize: '16px', cursor: 'default' }}>
              group
            </i>
            <Tooltip position="bottom">{messages.groupsLabel}</Tooltip>
          </div>
          {_mapAtMost(groups, expanded ? groups.length : SHOW_LIMIT, (g) => (
            <Link key={g.id} to={`/groups/${g.id}`}>
              <div className="mui-padded-half">
                <div className="bg-primary-light" style={{ borderRadius: '2px', padding: '2px 6px' }}>
                  <div className={`circle-indicator ci-size-8 ${g.membership}`} />
                  <span>{g.name}</span>
                  {multiCompanies && <small style={{ marginLeft: '4px' }}>{g.company.name}</small>}
                </div>
              </div>
            </Link>
          ))}
          {groups.length > SHOW_LIMIT &&
            (expanded ? (
              <span className="link-buttons">
                <a role="button" className="link-button" onClick={this.handleToggleExpanded}>
                  <span>{messages.lessLabel}...</span>
                </a>
              </span>
            ) : (
              <span className="link-buttons">
                <a role="button" className="link-button" onClick={this.handleToggleExpanded}>
                  <span>+{groups.length - SHOW_LIMIT}...</span>
                </a>
              </span>
            ))}
        </div>
      </div>
    );
    if (containerElement) {
      return React.cloneElement(containerElement, { children: elem });
    }
    return elem;
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { companies }
  } = state;
  const auth = selectAccountPermissions(state, ownProps);
  const groupsEnabled = auth.modes[EContractPlanMode.TEAMS.key];
  return {
    multiCompanies: companies.length > 1,
    groupsEnabled
  };
};

const intlMessages = {
  en: {
    groupsLabel: 'Groups & teams',
    lessLabel: 'Less'
  },
  cs: {
    groupsLabel: 'Skupiny a týmy',
    lessLabel: 'Méně'
  },
  sk: {
    groupsLabel: 'Skupiny a tímy',
    lessLabel: 'Menej'
  }
};

export default connect(mapStateToProps)(withIntl(GroupsPanel, intlMessages));
