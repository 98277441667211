import { handleActions } from 'redux-actions';

import {
  PARTICIPANT_FILTER_OPEN,
  PARTICIPANT_FILTER_CLOSE,
  PARTICIPANT_FILTER_SET_FULL_TEXT,
  PARTICIPANT_FILTER_SET_FROM_VALUES
} from '../../constants/constants';

import { update, updateFromValues } from './filtersReducerUtil';

const initialState = {};

export default handleActions(
  {
    [PARTICIPANT_FILTER_OPEN]: (state, { payload: { projectId } }) => update(state, projectId, { open: true }),
    [PARTICIPANT_FILTER_CLOSE]: (state, { payload: { projectId } }) => update(state, projectId, { open: false }),
    [PARTICIPANT_FILTER_SET_FULL_TEXT]: (state, { payload: { projectId, fullText } }) =>
      update(state, projectId, { fullText }),
    [PARTICIPANT_FILTER_SET_FROM_VALUES]: (state, { payload: { projectId, filterKey, values } }) =>
      updateFromValues(state, projectId, filterKey, values)
  },
  initialState
);
