import { combineReducers } from 'redux';

import applicantReducer from './applicantReducer';
import applicantsReducer from './applicantsReducer';
import companiesReducer from './companiesReducer';
import projectReducer from './projectReducer';
import projectsReducer from './projectsReducer';
import projectTemplatesReducer from './projectTemplatesReducer';
import projectParticipantsReducer from './projectParticipantsReducer';
import companyOperatorsReducer from './companyOperatorsReducer';
import companyProfileReducer from './companyProfileReducer';
import currentCompanyReducer from './currentCompanyReducer';
import simulationModelReducer from './simulationModelReducer';
import simulationModelsReducer from './simulationModelsReducer';
import opportunitiesReducer from './opportunitiesReducer';
import opportunityReducer from './opportunityReducer';
import opportunityPostBuilderReducer from './opportunityPostBuilderReducer';
import groupReducer from './groupReducer';
import groupsReducer from './groupsReducer';
import groupMembersReducer from './groupMembersReducer';
import activityTemplatesReducer from './activityTemplatesReducer';
import eapiKeysReducer from './eapiKeysReducer';
import tagsReducer from './tagsReducer';
import talentPoolsReducer from './talentPoolsReducer';
import talentMarketPlansReducer from './talentMarketPlansReducer';
import insightReducer from './insightReducer';
import insightsReducer from './insightsReducer';

const reducer = combineReducers({
  applicant: applicantReducer,
  applicants: applicantsReducer,
  companies: companiesReducer,
  project: projectReducer,
  projects: projectsReducer,
  projectTemplates: projectTemplatesReducer,
  projectParticipants: projectParticipantsReducer,
  companyOperators: companyOperatorsReducer,
  companyProfile: companyProfileReducer,
  currentCompany: currentCompanyReducer,
  simulationModel: simulationModelReducer,
  simulationModels: simulationModelsReducer,
  opportunity: opportunityReducer,
  opportunities: opportunitiesReducer,
  opportunityPostBuilder: opportunityPostBuilderReducer,
  groups: groupsReducer,
  group: groupReducer,
  groupMembers: groupMembersReducer,
  activityTemplates: activityTemplatesReducer,
  eapiKeys: eapiKeysReducer,
  tags: tagsReducer,
  talentPools: talentPoolsReducer,
  talentMarketPlans: talentMarketPlansReducer,
  insight: insightReducer,
  insights: insightsReducer
});

export default reducer;
