import React from 'react';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import classnames from 'classnames';

import ActivityToggleHeader from './ActivityToggleHeader';
import Insight from './insights/Insight';
import MetricVisualization from './insights/MetricVisualization';
import MetricsBubbleChartPanel from '../analytics/bubbleChart/MetricsBubbleChartPanel';
import DualMetricsMassChart from '../analytics/DualMetricsMassChart';

import { createGetParticipantProps } from './TeamInsightsUtils';
import { mapObjectAsArray } from '../../utils/utils';
import { EActivityType } from '../../constants/enum';

import withIntl from '../withIntl';

const GroupActivityInsights = props => {
  const {
    members,
    highlighted,
    activity,
    expanded,
    chartShown,
    onExpandedToggle,
    metricProps,
    metricStats,
    participantsListShown,
    intl: { messages }
  } = props;
  const metricsList = activity.metrics?.list || [];
  const allMetricsList = activity.metrics?.all || [];
  const metricsData = activity.metrics?.data || {};
  const getParticipantProps = createGetParticipantProps(activity, highlighted);

  const className = classnames({ 'team__member-activity-insights mui-padded-top': true, 'hidden-print': !expanded });
  return (
    <div className={className}>
      <div className="mui-padded-bottom border-top border-color-primary__50" />
      <div style={{ transition: '0.2s padding', padding: expanded ? '8px 0' : 0 }}>
        <ActivityToggleHeader activity={activity} expanded={expanded} onToggle={onExpandedToggle} />
      </div>
      {expanded && (
        <div className="mui-padded-bottom-x2">
          <div className="border border-color-primary__50 border-radius">
            {chartShown && allMetricsList.length > 0 && (
              <div>
                <div className="mui-padded-x2">
                  <h4 style={{ fontWeight: 300, marginBottom: '24px' }}>{messages.chartTitle}</h4>
                  {EActivityType.CULTURE_FIT.isLike(activity) ? (
                    <DualMetricsMassChart
                      metrics={_reduce(
                        allMetricsList,
                        (res, m) => {
                          const dataItem = metricsData[m.key];
                          if (dataItem) {
                            res.push({ ...dataItem, metric: { ...dataItem.metric, ...m } });
                          }
                          return res;
                        },
                        []
                      )}
                    />
                  ) : (
                    <MetricsBubbleChartPanel
                      participants={members}
                      getParticipantProps={getParticipantProps}
                      metrics={allMetricsList}
                      medianShown={false}
                    />
                  )}
                </div>
                <div className="mui-padded-horizontal">
                  <div className="border-bottom border-color-primary__50" />
                </div>
              </div>
            )}
            {metricsList.length > 0 ? (
              <div>
                {_map(metricsList, (m, idx) => (
                  <div key={m.key} className="team__member-activity-insights-metric">
                    {idx > 0 && (
                      <div className="mui-padded-horizontal">
                        <div className="border-top border-color-primary__50" />
                      </div>
                    )}
                    <div className="row container-flex-row fw-yes mui-padded-x2">
                      <div className={participantsListShown ? 'col-xs-12' : 'col-sm-6 col-xs-12'}>
                        <MetricVisualization
                          metric={m}
                          metricsData={metricsData}
                          targetMembers={members}
                          getParticipantProps={getParticipantProps}
                          participantsListShown={participantsListShown}
                          group
                        />
                      </div>
                      <div className="col-sm-6 col-xs-12">
                        {mapObjectAsArray(
                          metricProps,
                          (ip, key) => (
                            <Insight
                              key={key}
                              insightKey={key}
                              metric={m}
                              metricStats={metricStats[m.key]}
                              metricData={metricsData[m.key]}
                            />
                          ),
                          ip => !!ip
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-muted mui-padded-x2">
                <em>{messages.noDataMessage}</em>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    noDataMessage: 'Žádná dostupná data',
    chartTitle: 'Graf'
  },
  sk: {
    noDataMessage: 'Žiadne dostupné dáta',
    chartTitle: 'Graf'
  },
  en: {
    noDataMessage: 'No data available',
    chartTitle: 'Chart'
  }
};

export default withIntl(GroupActivityInsights, intlMessages);
