import React from 'react';
import { connect } from 'react-redux';

import Overlay from '../../basic/Overlay';
import Button from '../../mui/Button';
import TalentMarketPlanSelect from './TalentMarketPlanSelect';

import { switchModal } from '../../../actions/options/modalActions';
import * as talentMarketPlanActions from '../../../actions/entities/talentMarketPlanActions';
import { setCompanyTalentMarketPlan } from '../../../actions/entities/companyActions';

import withIntl from '../../withIntl';

class TalentMarketPlanSelectModal extends React.Component {
  state = {
    loading: false
  };
  componentDidMount() {
    this.loadPlans();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.intl !== this.props.intl) {
      this.loadPlans();
    }
  }

  loadPlans = () => {
    const {
      getTalentMarketPlans,
      intl: { locale }
    } = this.props;
    getTalentMarketPlans(locale);
  };

  handleClose = () => {
    const { id, switchModal, selectTalentMarketPlan } = this.props;
    selectTalentMarketPlan(null);
    switchModal({ id, open: false });
  };
  handleConfirm = () => {
    const {
      company,
      intl: { locale },
      talentMarketPlans: { billingPeriod, currency, defaultCurrency }
    } = this.props;
    const { setCompanyTalentMarketPlan } = this.props;
    const planId = this.getPlanId();
    if (!company || !planId) {
      // handle
      return;
    }
    this.setState({ loading: true });
    setCompanyTalentMarketPlan(company.id, { planId, billingPeriod, currency: currency || defaultCurrency }, locale)
      .then(() => {
        this.setState({ loading: false });
        this.handleClose();
      })
      .catch(() => this.setState({ loading: false }));
  };

  handlePlanSelect = p => {
    const { selectTalentMarketPlan } = this.props;
    selectTalentMarketPlan(p.id);
  };

  getPlanId = () => {
    const { talentMarketPlan, talentMarketPlans } = this.props;
    const { selectedId } = talentMarketPlans;
    return selectedId ? selectedId : talentMarketPlan?.planId;
  };

  getRequestedPlanId = () => {
    const {
      talentMarketPlan: { requested }
    } = this.props;
    return requested?.planId;
  };

  render() {
    const {
      auth,
      company,
      talentMarketPlans,
      intl: { messages }
    } = this.props;
    const { selectTalentMarketPlanCurrency, selectTalentMarketPlanBillingPeriod } = this.props;
    const { items, map, currency, defaultCurrency, currencies, billingPeriod } = talentMarketPlans;
    const { loading } = this.state;
    const planId = this.getPlanId();
    const requestedPlanId = this.getRequestedPlanId();
    return (
      <Overlay onRequestClose={this.handleClose} modal maxWidth={1200}>
        <div className="mui-card">
          <div className="mui-padded-horizontal">
            <TalentMarketPlanSelect
              selecting
              plans={items}
              plan={planId ? (map || {})[planId] : null}
              requestedPlan={requestedPlanId ? (map || {})[requestedPlanId] : null}
              currency={currency}
              defaultCurrency={defaultCurrency}
              currencies={currencies}
              billingPeriod={billingPeriod}
              onPlanSelect={this.handlePlanSelect}
              onCurrencySelect={selectTalentMarketPlanCurrency}
              onBillingPeriodSelect={selectTalentMarketPlanBillingPeriod}
            />
          </div>
          <div className="container-flex-row fw-yes mui-padded-half jc-flex-end">
            <div className="mui-padded-half">
              <Button onClick={this.handleClose} label={messages.cancelButton} />
            </div>
            <div className="mui-padded-half">
              <Button
                primary
                onClick={this.handleConfirm}
                label={auth.isAdmin ? messages.confirmButton : messages.requestButton}
                loading={loading}
                disabled={!company || !billingPeriod || !planId}
              />
            </div>
          </div>
        </div>
      </Overlay>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth,
    entities: {
      talentMarketPlans,
      currentCompany: { item, talentMarketPlan }
    }
  } = state;
  return {
    auth,
    company: item,
    talentMarketPlan,
    talentMarketPlans
  };
};

const actions = { switchModal, setCompanyTalentMarketPlan, ...talentMarketPlanActions };

const intlMessages = {
  cs: {
    confirmButton: 'Vybrat',
    requestButton: 'Požádat',
    cancelButton: 'Zrušit'
  },
  sk: {
    confirmButton: 'Vybrať',
    requestButton: 'Požiadať',
    cancelButton: 'Zrušiť'
  },
  en: {
    confirmButton: 'Select',
    requestButton: 'Request',
    cancelButton: 'Cancel'
  }
};

export default withIntl(connect(mapStateToProps, actions)(TalentMarketPlanSelectModal), intlMessages);
