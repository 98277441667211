import * as Api from '../../api/entities/tagApi';
import { createApiCallAction } from '../apiCallAction';

import { PROJECT_USER_COMPANY_TAGS_GET, GROUP_USER_COMPANY_TAGS_GET } from '../../constants/constants';

const getProjectUserCompanyTagsAction = createApiCallAction(PROJECT_USER_COMPANY_TAGS_GET, Api.getUsersCompanyTags, {
  transformArgsToMeta: ([companyId, { projectId }]) => ({
    companyId,
    projectId
  })
});
export const getProjectUserCompanyTags = project =>
  getProjectUserCompanyTagsAction(project.company.id, { projectId: project.id });

const getGroupUserCompanyTagsAction = createApiCallAction(GROUP_USER_COMPANY_TAGS_GET, Api.getUsersCompanyTags, {
  transformArgsToMeta: ([companyId, { groupId }]) => ({
    companyId,
    groupId
  })
});
export const getGroupUserCompanyTags = group =>
  getGroupUserCompanyTagsAction(group.company.id, { groupId: group.id });
