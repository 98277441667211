import React from 'react';

import Button from '../mui/Button';
import Dialog from '../mui/Dialog';

import withIntl from '../withIntl';
import { formatMessage } from '../../utils/utils';

const TeamDeleteDialog = props => {
  const { open, intl: { messages }, onCancel, onConfirm, team } = props;
  return (
    <Dialog
      open={open}
      contentStyle={{ maxWidth: '520px' }}
      actions={[
        <Button label={messages.cancelLabel} onClick={onCancel} />,
        <Button
          primary
          label={messages.confirmLabel}
          icon={<i className="material-icons">delete</i>}
          onClick={onConfirm}
        />
      ]}
    >
      <p>{formatMessage(messages.message, [<b key="groupName">{(team && team.name) || <em>N/A</em>}</b>])}</p>
    </Dialog>
  );
};

const intlMessages = {
  cs: {
    cancelLabel: 'Zrušit',
    confirmLabel: 'Odstranit',
    message: 'Odstranit skupinu {0}?'
  },
  sk: {
    cancelLabel: 'Zrušiť',
    confirmLabel: 'Odstrániť',
    message: 'Odstrániť skupinu {0}?'
  },
  en: {
    cancelLabel: 'Cancel',
    confirmLabel: 'Delete',
    message: 'Delete the group {0}?'
  }
};

export default withIntl(TeamDeleteDialog, intlMessages);
