import React from 'react';

import OpportunityResponseStatus from './OpportunityResponseStatus';

const RESPONSE_PROPS = {
  ACCEPTED: {
    icon: 'star',
    variant: 'primary'
  },
  ACCEPTED_ACCEPTED: {
    icon: 'check',
    variant: 'success'
  },
  REJECTED: {
    icon: 'close',
    variant: 'error'
  },
  INTERESTED: {
    icon: 'star',
    variant: 'primary'
  }
};

const OpportunityCompanyResponseStatus = props => {
  const { messages, response, participantResponse, intl } = props;
  const { status } = response;
  const participantStatus = participantResponse && participantResponse.status;
  const rProps = (participantStatus && RESPONSE_PROPS[`${status}_${participantStatus}`]) || RESPONSE_PROPS[status];
  const rejectReasonMessages = intl.messages.constants.enums.ECompanyRejectReason;
  return (
    <div className={`container-flex-row ai-center text-${rProps.variant}`}>
      <i className="material-icons" style={{ marginRight: '12px', color: 'inherit' }}>
        {rProps.icon}
      </i>
      <OpportunityResponseStatus
        messages={messages}
        response={response}
        secondaryResponse={participantResponse}
        intl={intl}
        rejectReasonMessages={rejectReasonMessages}
      />
    </div>
  );
};

export default OpportunityCompanyResponseStatus;
