import React from 'react';
import cn from 'classnames';

const Separator = ({ className, smHide, style }) => (
  <div
    className={cn({
      'col-xs-24': true,
      'hidden-md': true,
      'hidden-lg': true,
      'hidden-sm': smHide,
      [className]: className
    })}
    style={style}
  >

    <div style={{ height: '1px', border: 'none', margin: '-1px 0 0', backgroundColor: 'rgba(0,0,0,0.05)' }} />
  </div>
);

export default Separator;
