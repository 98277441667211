import React from 'react';

import ActivityStatusChart from './ActivityStatusChart';
import withIntl from '../withIntl';

const getParticipantActivities = p => (p.simulation ? [p.simulation] : []);

const ParticipantActivityStatusChart = props => {
  const {
    intl: { messages }
  } = props;
  return <ActivityStatusChart {...props} title={messages.title} getParticipantActivities={getParticipantActivities} />;
};

const intlMessages = {
  cs: {
    title: 'Aktivita v projektu'
  },
  sk: {
    title: 'Aktivita v projekte'
  },
  en: {
    title: 'Project activity'
  }
};

export default withIntl(ParticipantActivityStatusChart, intlMessages);
