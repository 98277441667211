import { handleActions } from 'redux-actions';

import * as c from '../../constants/constants';

export const defaultState = {};

export default handleActions(
  {
    [c.PROJECT_GET]: (state, { payload }) => payload
  },
  defaultState
);
