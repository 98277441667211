import React from 'react';

import cn from 'classnames';

export default ({
  primaryClassName,
  children,
  className,
  xs,
  sm,
  md,
  lg,
  xsOffset,
  smOffset,
  mdOffset,
  lgOffset,
  xsPull,
  smPull,
  mdPull,
  lgPull,
  xsPush,
  smPush,
  mdPush,
  lgPush,
  xsHide,
  smHide,
  mdHide,
  lgHide,
  ...props
}) => (
  <div
    className={cn({
      [primaryClassName]: primaryClassName,
      [className]: className,
      [`col-xs-${xs}`]: xs,
      [`col-sm-${sm}`]: sm,
      [`col-md-${md}`]: md,
      [`col-lg-${lg}`]: lg,
      [`col-xs-offset-${xsOffset}`]: !isNaN(xsOffset),
      [`col-sm-offset-${smOffset}`]: !isNaN(smOffset),
      [`col-md-offset-${mdOffset}`]: !isNaN(mdOffset),
      [`col-lg-offset-${lgOffset}`]: !isNaN(lgOffset),
      [`col-xs-push-${xsPush}`]: !isNaN(xsPush),
      [`col-sm-push-${smPush}`]: !isNaN(smPush),
      [`col-md-push-${mdPush}`]: !isNaN(mdPush),
      [`col-lg-push-${lgPush}`]: !isNaN(lgPush),
      [`col-xs-pull-${xsPull}`]: !isNaN(xsPull),
      [`col-sm-pull-${smPull}`]: !isNaN(smPull),
      [`col-md-pull-${mdPull}`]: !isNaN(mdPull),
      [`col-lg-pull-${lgPull}`]: !isNaN(lgPull),
      'hidden-xs': xsHide,
      'hidden-sm': smHide,
      'hidden-md': mdHide,
      'hidden-lg': lgHide
    })}
    {...props}
  >
    {children}
  </div>
);
