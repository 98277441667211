import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import IconButton from 'material-ui/IconButton';

import HorizontalScrollPane from '../basic/HorizontalScrollPane';
import NavMenuItem from './NavigationMenuItem';
import RouteMatch from './RouteMatch';
import BetaBadge from '../badge/BetaBadge';
import UserMenuButton from '../user/UserMenuButton';

import * as sidebarActions from '../../actions/options/sidebarActions';

import { HEADER_CLASS } from '../../constants/constants';
import { EContractPlanMode } from '../../constants/enum';
import { selectAccountPermissions } from '../../selectors/auth';
import { getBackPath } from './paths';

const SubMenu = ({ children }) => {
  return (
    <div style={{ padding: 4 }}>
      <HorizontalScrollPane>
        <div className="container-flex">{children}</div>
      </HorizontalScrollPane>
    </div>
  );
};

const SubNavMenuItem = props => {
  return <NavMenuItem sub inline inverted {...props} />;
};

const NavBackMenuItem = ({ to }) => {
  return <SubNavMenuItem active={false} icon={<i className="material-icons">arrow_back</i>} to={to} />;
};

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  render() {
    const { auth, intl, location, sidebarDisabled } = this.props;
    const { toggleSidebar } = this.props;

    const rightMenu = (
      <div className="mui-padded-horizontal">
        <UserMenuButton />
      </div>
    );
    const backPath = getBackPath(location);

    const sourcingEnabled = auth.modes[EContractPlanMode.SOURCING.key];
    const recruitmentEnabled = auth.modes[EContractPlanMode.RECRUITMENT.key];
    const developmentEnabled = auth.modes[EContractPlanMode.DEVELOPMENT.key];
    const teamsEnabled = auth.modes[EContractPlanMode.TEAMS.key];
    const assessmentsEnabled = recruitmentEnabled || developmentEnabled;

    const navigationMsgs = intl.messages.components.navigation;

    const isSourcing = sourcingEnabled && !!/^\/sourcing/.exec(location.pathname);

    return (
      <div className={HEADER_CLASS}>
        <div className="navigation-bar__menu">
          {sidebarDisabled ? (
            <div style={{ paddingLeft: '16px' }}>
              <div className="navigation-bar__logo" />
            </div>
          ) : (
            <IconButton className="navigation-bar__icon-button" onClick={toggleSidebar}>
              <i className="material-icons">menu</i>
            </IconButton>
          )}
          <div className="flex1" style={{ overflow: 'hidden' }}>
            <HorizontalScrollPane>
              <div className="container-flex">
                {auth.projectsAccess && assessmentsEnabled && (
                  <NavMenuItem
                    icon={<i className="material-icons">home</i>}
                    label={navigationMsgs.home.label}
                    to="/home"
                  />
                )}
                {auth.groupsAccess && teamsEnabled && (
                  <NavMenuItem
                    icon={<i className="material-icons">group</i>}
                    label={<div style={{ minWidth: 80 }}>{navigationMsgs.groups.label}</div>}
                    to="/groups"
                    badge={<BetaBadge />}
                  />
                )}
                {auth.projectsAccess && (
                  <NavMenuItem
                    icon={<i className="material-icons">{assessmentsEnabled ? 'assignment_ind' : 'lock'}</i>}
                    label={navigationMsgs.assessments.label}
                    to="/projects"
                  />
                )}
                {auth.projectsAccess && (
                  <NavMenuItem
                    icon={<span className={`mdi mdi-${sourcingEnabled ? 'account-search' : 'lock'}`} />}
                    label={navigationMsgs.sourcing.label}
                    to="/sourcing"
                  />
                )}
                {auth.designerAccess && (
                  <NavMenuItem
                    icon={<span className={`mdi mdi-${auth.designerAccess ? 'message-processing' : 'lock'}`} />}
                    label={navigationMsgs.designer.label}
                    to="/designer"
                  />
                )}
              </div>
            </HorizontalScrollPane>
          </div>
          {rightMenu}
        </div>
        <div className="navigation-bar__submenu">
          {isSourcing ? (
            <RouteMatch expression="^/sourcing">
              <SubMenu>
                {backPath && <NavBackMenuItem to={backPath} />}
                <SubNavMenuItem label={navigationMsgs.sourcing.opportunitiesLabel} to="/sourcing/opportunities" />
                <SubNavMenuItem label={navigationMsgs.sourcing.companyProfileLabel} to="/sourcing/profile" />
              </SubMenu>
            </RouteMatch>
          ) : (
            backPath && (
              <SubMenu>
                <NavBackMenuItem to={backPath} />
              </SubMenu>
            )
          )}
        </div>
      </div>
    );
  }
}

Navbar.propTypes = {
  auth: PropTypes.object,
  intl: PropTypes.object,
  //
  toggleSidebar: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  const { intl } = state;
  return {
    intl,
    auth: selectAccountPermissions(state, ownProps)
  };
};

const actions = {
  toggleSidebar: sidebarActions.toggleSidebar
};

export default withRouter(connect(mapStateToProps, actions)(Navbar));
