import React from 'react';
import PropTypes from 'prop-types';

import RaisedButton from 'material-ui/RaisedButton';

import PublicBase from './PublicBase';
import { BasicParagraph } from '../../basic';

import { formatMessage } from '../../../utils/utils';

const AccountActivation = props => {
  const { intl, email } = props;
  const { updateLocale, activateAccount } = props;
  const messages = intl.messages.components.pages.public.accountActivation;
  return (
    <PublicBase {...{ intl, updateLocale }}>
      <div style={{ padding: '1em' }}>
        <BasicParagraph text={formatMessage(messages.text, [<b>{email}</b>])} />
      </div>
      <div className={'row'} style={{ padding: '0.5em' }}>
        <div className={'col-xs-12'}>
          <RaisedButton
            primary
            label={messages.submit}
            style={{ float: 'right' }}
            onClick={activateAccount}
          />
        </div>
      </div>
    </PublicBase>
  );
};

AccountActivation.propTypes = {
  intl: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  //
  updateLocale: PropTypes.func.isRequired,
  activateAccount: PropTypes.func.isRequired
};

export default AccountActivation;
