import React from 'react';
import cn from 'classnames';

import GridComponent from './GridComponent';

export default ({ children, sub, ...props }) => (
  <GridComponent primaryClassName={cn({ 'grid-label': true, sub })} {...props}>
    {children}
  </GridComponent>
);
