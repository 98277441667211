import React from 'react';

import ParticipantName from '../basic/ParticipantName';
import AddMembersButton from './overview/AddMembersButton';
import MembersCountMessage from './MembersCountMessage';

import withIntl from '../withIntl';

import { EGroupMembership, EGroupType } from '../../constants/enum';

const MembershipIcon = props => {
  const { membership } = props;
  const iconPart = membership === EGroupMembership.MANAGER ? 'tie' : 'group';
  return (
    <div
      className={`bg-color-${membership.key}__50 text-center`}
      style={{ borderRadius: '50%', width: '40px', height: '40px', lineHeight: '40px' }}
    >
      <span
        className={`mdi mdi-account-${iconPart}-outline text-color-${membership.key}__900`}
        style={{ fontSize: '28px' }}
      />
    </div>
  );
};

const MembershipHeader = props => {
  const { membership, children } = props;
  return (
    <div className="mui-padded-half mui-padded-horizontal container-flex-row ai-center fw-yes">
      <div className="mui-padded-half">
        <MembershipIcon membership={membership} />
      </div>
      <div className="mui-padded-half mui-padded-horizontal mui-margin-right-x2 text-sz-lg">{children}</div>
    </div>
  );
};

const TeamOverviewHeader = props => {
  const {
    type,
    members,
    manager,
    readOnly,
    //
    onAddStart,
    //
    intl: { messages }
  } = props;
  const filteredMembers = members?.filtered || [];
  const allMembers = members?.items || [];
  const filteredCount = filteredMembers.length < allMembers.length ? filteredMembers.length : null;
  const membersCountMessage = (
    <MembersCountMessage type={type.key} count={allMembers.length} filteredCount={filteredCount} />
  );
  return (
    <div className="container-flex-row fw-yes jc-space-between">
      <div className="container-flex-row ai-center fw-yes">
        {type === EGroupType.TEAM && (
          <MembershipHeader membership={EGroupMembership.MANAGER}>
            {manager ? (
              <ParticipantName variant="medium" {...manager} />
            ) : (
              <em className="text-muted">{messages.noManagerMessage}</em>
            )}
          </MembershipHeader>
        )}
        <MembershipHeader membership={EGroupMembership.MEMBER}>
          {filteredMembers.length > 0 ? membersCountMessage : <em className="text-muted">{membersCountMessage}</em>}
        </MembershipHeader>
      </div>
      {!readOnly && (
        <div className="mui-padded hidden-print">
          <AddMembersButton members={members} manager={manager} type={type} onAddStart={onAddStart} />
        </div>
      )}
    </div>
  );
};

const intlMessages = {
  cs: {
    noManagerMessage: 'Zatím žádný manažer'
  },
  sk: {
    noManagerMessage: 'Zatiaľ žiadny manažér'
  },
  en: {
    noManagerMessage: 'No manager yet'
  }
};

export default withIntl(TeamOverviewHeader, intlMessages);
