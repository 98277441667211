import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import TextField from '../superfield/form/SuperText';
import Checkbox from '../formFields/Checkbox';
import Button from '../mui/Button';
import BasicForm from '../basic/BasicForm';

import { formatMessage } from '../../utils/utils';
import { BRAND } from '../../constants/constants';
import { accountNewValidate, accountNewValidateAsync } from './Validator';

const formatTosConfirmLabel = (label, links, locale) => {
  const msg = formatMessage(label, (idx, linkLabel) => {
    const p = encodeURIComponent(`/${locale}/${links[idx]}`);
    return (
      <a
        href={`https://game.${BRAND.domain}/api/v1/public/redirects/external?p=${p}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkLabel}
      </a>
    );
  });
  return <span>{msg}</span>;
};

const AccountNew = props => {
  const { intl, handleSubmit, createNewAccount } = props;
  const messages = intl.messages.components.pages.public.accountNew;

  return (
    <BasicForm onSubmit={handleSubmit(createNewAccount)}>
      {/*<div className="row">*/}
      {/*  <div className="col-xs-12 col-sm-6">*/}
      {/*    <Field*/}
      {/*      name="firstName"*/}
      {/*      component={TextField}*/}
      {/*      label={messages.fields.firstName.label}*/}
      {/*      indicateRequired*/}
      {/*      autoFocus*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className="col-xs-12 col-sm-6">*/}
      {/*    <Field name="lastName" component={TextField} label={messages.fields.lastName.label} indicateRequired />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="row">
        <div className="col-xs-12">
          <Field name="companyName" component={TextField} label={messages.fields.companyName.label} indicateRequired />
        </div>
      </div>
      {/*<div className="row">*/}
      {/*  <div className="col-xs-12">*/}
      {/*    <Field*/}
      {/*      name={'companyConfirm'}*/}
      {/*      component={Checkbox}*/}
      {/*      label={messages.fields.companyConfirm.label}*/}
      {/*      indicateRequired*/}
      {/*      fullWidth*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="row">
        <div className="col-xs-12">
          <Field name="email" component={TextField} label={messages.fields.email.label} indicateRequired />
        </div>
        {/*<div className="col-xs-12 col-sm-6">*/}
        {/*  <Field name="phone" component={TextField} label={messages.fields.phone.label} />*/}
        {/*</div>*/}
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field
            name="password"
            component={TextField}
            label={messages.fields.password.label}
            type="password"
            indicateRequired
          />
        </div>
        {/*<div className="col-xs-12 col-sm-6">*/}
        {/*  <Field*/}
        {/*    name="repeatPassword"*/}
        {/*    component={TextField}*/}
        {/*    label={messages.fields.repeatPassword.label}*/}
        {/*    type="password"*/}
        {/*    indicateRequired*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      {/*<div className="row">*/}
      {/*  <div className="col-xs-12">*/}
      {/*    <Field*/}
      {/*      name="language"*/}
      {/*      component={SelectField}*/}
      {/*      label={messages.fields.language.label}*/}
      {/*      indicateRequired*/}
      {/*      dataSource={values(languages)}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="row mui-margin-top-x2">
        <div className="col-xs-12">
          <Field
            name={'tosConfirm'}
            component={Checkbox}
            label={formatTosConfirmLabel(
              messages.fields.tosConfirm.label,
              ['terms-of-service', 'privacy-policy'],
              intl.locale
            )}
            labelStyle={{ zIndex: 999 }}
            fullWidth
            indicateRequired
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field
            name={'marketingAllowed'}
            component={Checkbox}
            label={messages.fields.marketingConfirm.label}
            fullWidth
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <p className="small" style={{ marginTop: '12px' }}>
            {messages.requiredMessage}
          </p>
        </div>
      </div>

      <div className={'row'}>
        <div className={'col-xs-12'}>
          <Button raised type={'submit'} label={messages.submit} style={{ float: 'right' }} primary />
        </div>
      </div>
    </BasicForm>
  );
};

AccountNew.propTypes = {
  intl: PropTypes.object.isRequired,
  plans: PropTypes.array,
  //
  handleSubmit: PropTypes.func.isRequired,
  createNewAccount: PropTypes.func.isRequired
};

const mapStateToProps = (state, { fields, ...ownProps }) => ({
  ...ownProps,
  initialValues: {
    ...fields
  }
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'accountNew',
    validate: accountNewValidate,
    asyncValidate: accountNewValidateAsync,
    asyncBlurFields: ['email', 'companyName']
  })(AccountNew)
);
