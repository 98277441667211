import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import InfoTooltip from '../basic/InfoTooltip';

const VerticalExpander = props => {
  const { expanded, onChange } = props;
  const handleChange = () => onChange(!expanded);
  return (
    <div className="container-flex-column ai-center jc-space-between vertical-expander" onClick={handleChange}>
      <i className="material-icons text-muted" style={{ fontSize: '16px' }}>
        keyboard_arrow_up
      </i>
      <i className="material-icons text-muted" style={{ fontSize: '16px' }}>
        keyboard_arrow_down
      </i>
    </div>
  );
};

const MetricStat = props => {
  const {
    metric: { metric },
    short,
    renderStat
  } = props;
  const fullLabel = (
    <span>
      {metric.label}
      {metric.oppositeLabel && (
        <>
          <span style={{ opacity: '0.7' }}> x </span>
          <span>{metric.oppositeLabel}</span>
        </>
      )}
    </span>
  );
  return (
    <div>
      <div className="container-flex-row jc-space-between" style={{ fontSize: '12px' }}>
        {short ? (
          <InfoTooltip content={fullLabel} dark compact>
            <span>{metric.shortLabel}</span>
          </InfoTooltip>
        ) : (
          fullLabel
        )}
        <span className="mui-margin-left" style={{ whiteSpace: 'nowrap' }}>
          {renderStat(props.metric)}
        </span>
      </div>
    </div>
  );
};

const MetricsStats = ({ metrics, short, colorVariant, getStat, renderStat }) => {
  const [expanded, setExpanded] = useState(false);
  if (metrics.length <= 0) {
    return null;
  }
  const lastIdx = metrics.length - 1;
  const commonProps = {
    short: short && !expanded,
    renderStat:
      renderStat ||
      (m => {
        const stat = getStat(m);
        return <span className={`text-color-${colorVariant}`}>{(stat * 100).toFixed(0)}</span>;
      })
  };
  return (
    <div className="container-flex-row">
      {expanded ? (
        <div>
          {_map(metrics, m => (
            <MetricStat key={m.metric.key} metric={m} {...commonProps} />
          ))}
        </div>
      ) : (
        <div>
          <MetricStat metric={metrics[0]} {...commonProps} />
          <MetricStat metric={metrics[lastIdx]} {...commonProps} />
        </div>
      )}
      <VerticalExpander expanded={expanded} onChange={setExpanded} />
    </div>
  );
};

MetricsStats.propTypes = {
  metrics: PropTypes.array.isRequired,
  getStat: PropTypes.func,
  renderStat: PropTypes.func,
  colorVariant: PropTypes.oneOf(['primary', 'secondary', 'highlight', 'MANAGER', 'MEMBER'])
};

export default MetricsStats;
