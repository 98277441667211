import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _take from 'lodash/take';
import VisSensor from 'react-visibility-sensor';

import EnhancedButton from 'material-ui/FlatButton';

import Overlay from '../basic/Overlay';
import Card from '../mui/Card';
import SearchField from '../mui/SearchField';

const MenuItem = props => {
  const { children, onClick } = props;
  return (
    <EnhancedButton
      onClick={onClick}
      style={{
        padding: '8px 16px',
        width: '100%',
        textAlign: 'left',
        borderRadius: '0',
        height: 'auto',
        lineHeight: 'auto'
      }}
      labelStyle={{ fontSize: '13px' }}
    >
      {children}
    </EnhancedButton>
  );
};

const SHOWN_ITEMS_INCREMENT = 10;

class SelectOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      shownItemsCount: SHOWN_ITEMS_INCREMENT
    };
  }

  handleSearchValueChange = searchValue => {
    const { onSearchChange } = this.props;
    if (onSearchChange) {
      onSearchChange(searchValue);
    }
    this.setState({ searchValue, shownItemsCount: SHOWN_ITEMS_INCREMENT });
  };
  handleSelect = item => {
    this.props.onSelect(item);
    this.setState({ searchValue: '' });
  };
  handleShowMoreItems = () => {
    this.setState({ shownItemsCount: this.state.shownItemsCount + SHOWN_ITEMS_INCREMENT });
  };
  setScrollMenu = el => (this.scrollMenu = el);

  render() {
    const { currentItem, items, messages } = this.props;
    const { onClose, filterFunction, itemRenderer, footerRenderer } = this.props;
    const { searchValue, shownItemsCount } = this.state;
    const search = searchValue && searchValue.toLowerCase();
    const filteredItems = search && filterFunction ? _filter(items, item => filterFunction(item, search)) : items;
    const shownItems = _take(filteredItems, shownItemsCount);
    const canShowMore = filteredItems.length > shownItems.length;
    return (
      <Overlay onRequestClose={onClose} noBlur>
        <Card
          className="container-flex-column"
          style={{ maxHeight: 'calc(100vh - 64px)', animation: 'vb-appear 0.2s ease-in-out' }}
        >
          <div>
            <SearchField
              placeholder={messages.searchPlaceholder}
              onValueChange={this.handleSearchValueChange}
              value={searchValue}
              debounce={100}
            />
          </div>
          <div
            className="flex1"
            style={{ overflow: 'auto', padding: '8px 0', borderTop: '1px solid rgba(0,0,0,0.05)' }}
            ref={this.setScrollMenu}
          >
            {_map(shownItems, item => (
              <MenuItem key={item.id} onClick={() => this.handleSelect(item)}>
                <span style={{ fontWeight: currentItem && item.id === currentItem.id ? 500 : 400 }}>
                  {itemRenderer(item)}
                </span>
              </MenuItem>
            ))}
            <VisSensor
              key={`${shownItems.length}-${filteredItems.length}-${items.length}`}
              partialVisibility
              offset={{ bottom: -100 }}
              onChange={v => v && canShowMore && this.handleShowMoreItems()}
              containment={this.scrollMenu}
            >
              <div style={{ height: '1px', width: '100%', pointerEvents: 'none', marginTop: '-1px' }} />
            </VisSensor>
          </div>
          {currentItem && (
            <div style={{ padding: '16px', fontSize: '12px', borderTop: '1px solid rgba(0,0,0,0.05)' }}>
              <a role="button" onClick={() => this.handleSelect(null)}>
                {messages.unsetCurrentItem}
              </a>
            </div>
          )}
          {footerRenderer && footerRenderer({ searchValue, items })}
        </Card>
      </Overlay>
    );
  }
}

SelectOverlay.propTypes = {
  currentItem: PropTypes.object,
  items: PropTypes.array.isRequired,
  messages: PropTypes.object.isRequired,
  filterFunction: PropTypes.func,
  itemRenderer: PropTypes.func.isRequired,
  footerRenderer: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func
};

export default SelectOverlay;
