import React from 'react';
import PropTypes from 'prop-types';

import withIntl from '../../withIntl';
import { formatMessage, selectQuantityMessage } from '../../../utils/utils';

const SelectionCountMessage = props => {
  const {
    count,
    intl: { messages }
  } = props;
  return <span>{formatMessage(selectQuantityMessage(messages.message, count), [<b key="count">{count}</b>])}</span>;
};

const intlMessages = {
  cs: {
    message: {
      1: '1 položka vybraná',
      '2-4': '{0} položky vybrané',
      '>4': '{0} položek vybraných'
    }
  },
  sk: {
    message: {
      1: '1 položka vybraná',
      '2-4': '{0} položky vybrané',
      '>4': '{0} položiek vybraných'
    }
  },
  en: {
    message: {
      1: '1 item selected',
      '>1': '{0} items selected'
    }
  }
};

SelectionCountMessage.propTypes = {
  count: PropTypes.number.isRequired
};

export default withIntl(SelectionCountMessage, intlMessages);
