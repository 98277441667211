import filter from 'lodash/filter';

import { getProjectInvitationUrls } from '../actions/entities/projectActions';
import { LOGIN_SUCCESS, COMPANIES_GET, GUIDE_VERSION, GUIDE_HIDE, GUIDE_FINISH } from '../constants/constants';
import { isFreeAccount } from '../utils/utils';

function getInvitationProperties(companies) {
  if (!isFreeAccount(companies)) {
    return null;
  }
  const company = companies[0];
  const projects = filter(company.projects, p => !p.demo);
  if (projects.length !== 1) {
    return null;
  }
  const project = projects[0];
  if (project.totalCount !== 0) {
    return null;
  }
  return { companySlug: company.slug, projectSlug: project.slug };
}

export default function flowMiddleware({ dispatch }) {
  const localState = {};
  return next => action => {
    const { type, payload } = action;
    switch (type) {
      case LOGIN_SUCCESS: {
        const { guideStates } = payload;
        const { dashboard } = guideStates || {};
        const { shown } = dashboard && dashboard.version >= GUIDE_VERSION ? dashboard : { shown: true };
        localState.guideHidden = !shown;
        break;
      }
      case COMPANIES_GET: {
        localState.invitationProperties = getInvitationProperties(payload);
        break;
      }
      case GUIDE_HIDE:
      case GUIDE_FINISH: {
        localState.guideHidden = true;
        break;
      }
      default:
        return next(action);
    }
    if (localState.invitationProperties && localState.guideHidden) {
      // show invite
      const { companySlug, projectSlug } = localState.invitationProperties;
      dispatch(getProjectInvitationUrls(companySlug, projectSlug));
    }
    return next(action);
  };
}
