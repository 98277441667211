import React from 'react';

import FontIcon from 'material-ui/FontIcon';

const ErrorPage = props => {
  const titleStyle = {
    height: '140px',
    display: 'inline-block',
    fontSize: '140px',
    color: 'rgba(33,33,33,0.54)',
    lineHeight: '1',
    verticalAlign: 'middle'
  };

  return (
    <div style={{ textAlign: 'center', padding: '0 16px' }}>
      <div style={{ padding: '100px 0 40px 0' }}>
        {props.statusCode && <span style={titleStyle}>{props.statusCode}</span>}
        {props.icon && (
          <FontIcon style={titleStyle} className="material-icons">
            {props.icon}
          </FontIcon>
        )}
      </div>
      <div>
        <p style={{ color: 'rgba(33,33,33,0.54)' }}>{props.message}</p>
      </div>
      {props.action && <div>{props.action}</div>}
    </div>
  );
};

export default ErrorPage;
