import React from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Toggle from 'material-ui/Toggle';

import CoreMetricsControl from '../../../components/analytics/CoreMetricsControl';
import MetricNodeList from './MetricNodeList';
import Button from '../../../components/mui/Button';
import Tooltip from '../../../components/tooltip/Tooltip';
import Avatar from 'material-ui/Avatar';
import WeightsIcon from 'material-ui/svg-icons/image/tune';
import ExpandableCardHeader from '../../../components/mui/ExpandableCardHeader';
import Collapse from '../../../components/basic/Collapse';
import WeightsLockedPlaceholder from './WeightsLockedPlaceholder';

import { extractWeight } from '../../../utils/utils';
import withIntl from '../../../components/withIntl';

export const EMetricType = {
  CATEGORY: 'CATEGORY',
  NUMERIC: 'NUMERIC'
};

const toMap = values =>
  _reduce(
    values || [],
    (res, d) => {
      res[d] = true;
      return res;
    },
    {}
  );

const toList = boolMap => {
  const res = [];
  for (let key in boolMap) {
    if (boolMap.hasOwnProperty(key)) {
      if (boolMap[key]) {
        res.push(key);
      }
    }
  }
  return res;
};

const renderDivider = () => {
  return (
    <div style={{ padding: '16px 0 12px' }}>
      <div className="bg-muted" style={{ width: '100%', height: '1px' }} />
    </div>
  );
};

class ProjectMetricsConfig extends React.Component {
  state = {
    disabled: {},
    definitions: {},
    numericTree: [],
    categoryTree: [],
    modelEditing: false,
    weights: {},
    coreMetrics: {},
    totalScoreEnabled: false
  };

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { defaultMetricDefinitions, metricDefinitions, project } = this.props;
    if (
      defaultMetricDefinitions !== prevProps.defaultMetricDefinitions ||
      metricDefinitions !== prevProps.metricDefinitions ||
      project !== prevProps.project
    ) {
      this.initialize();
    }
  }

  buildTree = (metrics, definitionOverrides, type) => {
    const defMap = {};
    const topLevel = [];
    for (let key in metrics) {
      if (metrics.hasOwnProperty(key)) {
        const m = metrics[key];
        if (m.type !== type || m.displayOrder == null) {
          continue;
        }
        const metric = { ...defMap[key], key, ...m };
        const override = definitionOverrides[key];
        if (override) {
          metric.parent = override.parent;
          metric.displayOrder = override.displayOrder;
          metric.type = override.type;
        }
        defMap[key] = metric;
        const parent = metric.parent;
        if (parent) {
          if (!defMap[parent]) {
            defMap[parent] = { key: parent, children: [] };
          } else if (!defMap[parent].children) {
            defMap[parent].children = [];
          }
          defMap[parent].children.push(key);
        } else {
          topLevel.push(key);
        }
      }
    }
    const compareFn = (a, b) => a.displayOrder - b.displayOrder;
    const q = _map(topLevel, key => defMap[key]).sort(compareFn);
    const res = [];
    while (q.length) {
      const node = q.shift();
      if (node.children) {
        node.children = _map(node.children, key => defMap[key]).sort(compareFn);
        _forEach(node.children, c => q.push(c));
      }
      if (!node.parent) {
        res.push(node);
      }
    }
    return res;
  };

  getMetricsConfig = () => {
    const { project } = this.props;
    return _get(project, 'analytics.metricsConfig') || {};
  };

  getWeights = () => {
    const { project } = this.props;
    return _get(project, 'analytics.scoreMapping') || {};
  };

  initialize = () => {
    const { defaultMetricDefinitions } = this.props;
    const { disabled, definitions, core, totalScoreEnabled } = this.getMetricsConfig();
    this.setState({
      disabled: toMap(disabled),
      definitions,
      numericTree: this.buildTree(defaultMetricDefinitions, definitions || {}, EMetricType.NUMERIC),
      categoryTree: this.buildTree(defaultMetricDefinitions, definitions || {}, EMetricType.CATEGORY),
      weights: this.getWeights(),
      coreMetrics: toMap(core),
      totalScoreEnabled
    });
  };

  handleSetDisabled = m => {
    const {
      disabled: { [m.key]: disabled, ...rest }
    } = this.state;
    if (!disabled) {
      rest[m.key] = true;
    }
    this.setState({ disabled: rest });
  };

  handleModelEditingToggle = () => this.setState(({ modelEditing }) => ({ modelEditing: !modelEditing }));

  canSetToPosition = (n, parent) => {
    const { defaultMetricDefinitions } = this.props;
    const defaultDef = defaultMetricDefinitions[n.key];
    return !parent || defaultDef.parent === parent;
  };

  handleSetPosition = (n, srcSubTree, destSubTree, pos, parent, type) => {
    const { defaultMetricDefinitions } = this.props;
    const { definitions } = this.state;
    const defaultDef = defaultMetricDefinitions[n.key];
    if (parent && defaultDef.parent !== parent) {
      console.debug('Invalid parent', n, parent);
      return;
    }
    const position = srcSubTree === destSubTree && pos > n.displayOrder ? pos - 1 : pos;
    if (n.parent === parent && n.displayOrder === position) {
      return;
    }
    const newDefinitions = {
      ...definitions,
      [n.key]: { parent, type: n.type, displayOrder: position }
    };
    for (let i = 0; i < srcSubTree.length; i += 1) {
      const item = srcSubTree[i];
      if (item !== n) {
        if (srcSubTree !== destSubTree) {
          if (item.displayOrder >= n.displayOrder) {
            newDefinitions[item.key] = { parent: item.parent, type: item.type, displayOrder: item.displayOrder - 1 };
          }
        } else {
          const lowerBound = position < n.displayOrder ? position : n.displayOrder;
          const upperBound = position < n.displayOrder ? n.displayOrder : position;
          const q = position < n.displayOrder ? 1 : -1;
          if (lowerBound <= item.displayOrder && item.displayOrder <= upperBound) {
            newDefinitions[item.key] = { parent: item.parent, type: item.type, displayOrder: item.displayOrder + q };
          }
        }
      }
    }
    if (destSubTree && srcSubTree !== destSubTree) {
      for (let i = 0; i < destSubTree.length; i += 1) {
        const item = destSubTree[i];
        if (item.displayOrder >= position) {
          newDefinitions[item.key] = { parent: item.parent, type: item.type, displayOrder: item.displayOrder + 1 };
        }
      }
    }
    const tree = this.buildTree(defaultMetricDefinitions, newDefinitions, type);
    const treeId = type === EMetricType.NUMERIC ? 'numericTree' : 'categoryTree';
    this.setState({ [treeId]: tree, definitions: newDefinitions });
  };

  handleSetPositionNumeric = (n, srcSubTree, destSubTree, pos, parent) => {
    return this.handleSetPosition(n, srcSubTree, destSubTree, pos, parent, EMetricType.NUMERIC);
  };

  handleSetPositionCategory = (n, srcSubTree, destSubTree, pos, parent) => {
    return this.handleSetPosition(n, srcSubTree, destSubTree, pos, parent, EMetricType.CATEGORY);
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { disabled, definitions, weights, numericTree, coreMetrics, totalScoreEnabled } = this.state;
    const metricsConfig = this.getMetricsConfig();
    const newConfig = {
      ...metricsConfig,
      core: toList(coreMetrics),
      disabled: toList(disabled),
      totalScoreEnabled,
      definitions
    };
    const scoreMapping = _reduce(
      numericTree,
      (res, { key }) => {
        if (!disabled[key]) {
          res[key] = extractWeight(weights, key);
        }
        return res;
      },
      {}
    );
    onSave(newConfig, scoreMapping);
  };

  handleReset = () => {
    this.initialize();
  };

  handleSetDefaults = () => {
    const { defaultMetricDefinitions } = this.props;
    this.setState({
      numericTree: this.buildTree(defaultMetricDefinitions, {}, EMetricType.NUMERIC),
      categoryTree: this.buildTree(defaultMetricDefinitions, {}, EMetricType.CATEGORY),
      definitions: {},
      disabled: {},
      weights: {},
      coreMetrics: {}
    });
  };

  handleWeightSet = (key, value) => {
    const { weights } = this.state;
    const w = weights[key];
    const newW = { ...w, value };
    this.setState({ weights: { ...weights, [key]: newW } });
  };

  handleCoreMetricToggle = m => {
    const {
      coreMetrics: { [m.key]: enabled, ...coreMetrics }
    } = this.state;
    if (!enabled) {
      coreMetrics[m.key] = true;
    }
    this.setState({ coreMetrics });
  };

  handleTotalScoreEnabledToggle = () =>
    this.setState(({ totalScoreEnabled }) => ({ totalScoreEnabled: !totalScoreEnabled }));

  handleToggle = () => {
    const { onExpandedToggle } = this.props;
    onExpandedToggle();
  };

  render() {
    const {
      auth,
      project,
      projectCrudAllowed,
      intl: { messages },
      expanded
    } = this.props;
    const isDemoProject = project && project.demo;
    const canShowConfig = auth.canUseWeights || isDemoProject;
    //
    const { numericTree, categoryTree, disabled, modelEditing, weights, coreMetrics, totalScoreEnabled } = this.state;
    const commonProps = {
      disabled: disabled,
      modelEditing: modelEditing,
      onDisabledSet: this.handleSetDisabled,
      canPositionSet: this.canSetToPosition,
      weights,
      onWeightSet: this.handleWeightSet,
      weightsEnabled: totalScoreEnabled
    };
    return (
      <div>
        <ExpandableCardHeader
          title={messages.title}
          subtitle={messages.subtitle}
          avatar={<Avatar style={{ float: 'left' }} icon={<WeightsIcon />} />}
          onClick={this.handleToggle}
          expanded={expanded}
        />
        <Collapse isOpened={canShowConfig && expanded} unmountClosed>
          <div className={`mui-card project-metrics-config${modelEditing ? ' editing' : ''}`}>
            <div>
              <Toggle
                label={
                  <div style={{ marginLeft: '4px' }}>
                    <div>{messages.totalScoreLabel}</div>
                    <div className="small">{messages.totalScoreHelper}</div>
                  </div>
                }
                labelPosition="right"
                labelStyle={{ margin: 0, fontWeight: 400, lineHeight: '1.3em' }}
                toggled={totalScoreEnabled}
                onToggle={this.handleTotalScoreEnabledToggle}
              />
            </div>
            {renderDivider()}
            <div className="mui-padded-half container-flex-row fw-yes ai-center jc-space-between">
              <div className="mui-label mui-padded-half" style={{ margin: 0 }}>
                {messages.weightsTitle}
              </div>
              <div className="mui-padded-half container-flex-row ai-center">
                <a role="button" className="link-button" onClick={this.handleSetDefaults}>
                  <span>{messages.buttons.setDefault}</span>
                </a>
                <span style={{ margin: '0 6px', fontSize: '12px' }}>&middot;</span>
                <a role="button" className="link-button tooltip-element" onClick={this.handleReset}>
                  <span>{messages.buttons.reset}</span>
                  <Tooltip>{messages.buttons.resetHelper}</Tooltip>
                </a>
                <span style={{ margin: '0 6px', fontSize: '12px' }}>&middot;</span>
                {modelEditing ? (
                  <a role="button" className="link-button text-secondary" onClick={this.handleModelEditingToggle}>
                    <i className="material-icons">check</i>
                    <span>{messages.buttons.done}</span>
                  </a>
                ) : (
                  <div>
                    <a role="button" className="link-button" onClick={this.handleModelEditingToggle}>
                      <i className="material-icons">edit</i>
                      <span>{messages.buttons.editModel}</span>
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="mui-padded-horizontal mui-margin-bottom-x2">
              <div className="small">{messages.weightsInfo}</div>
              {modelEditing && !project.view && (
                <div className="text-warning border-radius bg-warning text-sz-sm mui-padded-x2 mui-margin-top">
                  <div className="text-width-reading" dangerouslySetInnerHTML={{ __html: messages.modelEditWarning }} />
                </div>
              )}
            </div>
            <DndProvider backend={HTML5Backend}>
              <MetricNodeList
                type={EMetricType.NUMERIC}
                list={numericTree}
                onPositionSet={this.handleSetPositionNumeric}
                {...commonProps}
              />
            </DndProvider>
            {categoryTree.length > 0 && modelEditing && (
              <div style={{ marginTop: '8px' }}>
                <div className="mui-label mui-padded" style={{ margin: 0 }}>
                  {messages.indicatorsTitle}
                </div>
                <DndProvider backend={HTML5Backend}>
                  <MetricNodeList
                    type={EMetricType.CATEGORY}
                    list={categoryTree}
                    onPositionSet={this.handleSetPositionCategory}
                    {...commonProps}
                  />
                </DndProvider>
              </div>
            )}
            {renderDivider()}
            <div>
              <div className="mui-label mui-padded" style={{ margin: 0 }}>
                {messages.coreCompetenciesTitle}
              </div>
              <div className="mui-padded-horizontal" style={{ marginBottom: '16px' }}>
                <div className="small">
                  {messages.coreCompetenciesHelperPart1}{' '}
                  {project.view ? messages.coreCompetenciesHelperPart2View : messages.coreCompetenciesHelperPart2NoView}
                </div>
              </div>
              <CoreMetricsControl
                metrics={_filter(numericTree, ({ key }) => !disabled[key])}
                core={coreMetrics}
                onToggle={this.handleCoreMetricToggle}
              />
            </div>
          </div>
          <div className="mui-padded text-right">
            <div className="tooltip-element inline-container mui-padded-half">
              <Button
                label={messages.buttons.apply}
                disabled={isDemoProject || !projectCrudAllowed}
                onClick={this.handleSave}
                primary
                raised
              />
              {isDemoProject && <Tooltip>{messages.demoApplyDisabled}</Tooltip>}
            </div>
          </div>
        </Collapse>
        <Collapse isOpened={!canShowConfig && expanded} unmountClosed>
          <div style={{ padding: '0 16px 16px 16px' }}>
            <WeightsLockedPlaceholder />
          </div>
        </Collapse>
      </div>
    );
  }
}

const intlMessages = {
  cs: {
    title: 'Nastavení kompetencí',
    subtitle: 'Celkové skóre, váhy, klíčové kompetence',
    weightsInfo: 'Váhy ovlivňují způsob výpočtu celkového skóre a mohou ovlivnit pořadí účastníků',
    modelEditWarning:
      'Pozor, změna kompetenčního modelu se promítne i do reportu pro účastníka assessmentu. Jestli to není žádoucí, použijte <b>seskupení</b>.',
    totalScoreLabel: 'Povolit celkové skóre',
    totalScoreHelper: 'Váhy jsou aktivní, pouze pokud je povoleno celkové skóre',
    weightsTitle: 'Kompetence',
    coreCompetenciesTitle: 'Klíčové kompetence',
    coreCompetenciesHelperPart1: 'Vyberte kompetence, které chcete v reportech zvýraznit.',
    coreCompetenciesHelperPart2NoView: 'Nastavení platí jak pro vaše, tak i účastníkovo zobrazení.',
    coreCompetenciesHelperPart2View: 'Nastavení platí pouze pro vaše zobrazení. Účastníkovo zobrazení se nezmění.',
    indicatorsTitle: 'Indikátory',
    demoApplyDisabled: 'Demo projekt neumožňuje uložení nastavení',
    buttons: {
      apply: 'Použít',
      reset: 'Resetovat',
      resetHelper: 'Resetovat na poslední uložené nastavení',
      setDefault: 'Původní nastavení',
      done: 'Hotovo',
      editModel: 'Upravit model'
    }
  },
  sk: {
    title: 'Nastavenie kompetencií',
    subtitle: 'Celkové skóre, váhy, kľúčové kompetencie',
    weightsInfo: 'Váhy ovplyvňujú spôsob výpočtu celkového skóre a môžu ovplyvniť poradie účastníkov',
    modelEditWarning:
      'Pozor, zmena kompetenčného modelu sa premietne aj do reportu pre účastníka assessmentu. Ak si to neželáte, použite <b>zoskupenie</b>.',
    totalScoreLabel: 'Povoliť celkové skóre',
    totalScoreHelper: 'Váhy sú aktívne, len pokiaľ je povolené celkové skóre',
    weightsTitle: 'Kompetencie',
    coreCompetenciesTitle: 'Kľúčové kompetencie',
    coreCompetenciesHelperPart1: 'Vyberte kompetencie, ktoré chcete v reportoch zvýrazniť.',
    coreCompetenciesHelperPart2NoView: 'Nastavenie platí ako pre vaše, tak aj účastníkovo zobrazenie.',
    coreCompetenciesHelperPart2View: 'Nastavenie platí len pre vaše zobrazenie. Účastníkovo zobrazenie sa nezmení.',
    indicatorsTitle: 'Indikátory',
    demoApplyDisabled: 'Demo projekt neumožňuje uloženie nastavenia',
    buttons: {
      apply: 'Použiť',
      reset: 'Resetovať',
      resetHelper: 'Resetovať na posledné uložené nastavenie',
      setDefault: 'Pôvodné nastavenie',
      done: 'Hotovo',
      editModel: 'Upraviť model'
    }
  },
  en: {
    title: 'Competencies settings',
    subtitle: 'Controls the settings of individual competencies',
    weightsInfo:
      'Weights influence the way the overall score is calculated and may impact the order of the participants',
    modelEditWarning:
      "Heads up! If you change the competency model here, it will also adjust the report for the assessment participant. If it's not desired, you may use a <b>Grouping</b>.",
    totalScoreLabel: 'Enable overall score',
    totalScoreHelper: 'The weights are active only if the overall score is enabled',
    weightsTitle: 'Competencies',
    coreCompetenciesTitle: 'Core competencies',
    coreCompetenciesHelperPart1: "Select competencies which you'd like to highlight in reports.",
    coreCompetenciesHelperPart2NoView: "Applies both to your and the participant's view.",
    coreCompetenciesHelperPart2View: "Applies only to your view. Participant's view won't be influenced.",
    indicatorsTitle: 'Indicators',
    demoApplyDisabled: 'Custom settings cannot be saved for DEMO project',
    buttons: {
      apply: 'Apply',
      reset: 'Reset',
      resetHelper: 'Reset to last saved setting',
      setDefault: 'Default settings',
      done: 'Done',
      editModel: 'Edit model'
    }
  }
};

const mapStateToProps = state => {
  const {
    auth,
    entities: {
      applicants: { metricDefinitions, defaultMetricDefinitions }
    }
  } = state;
  return {
    auth,
    metricDefinitions,
    defaultMetricDefinitions
  };
};

export default connect(mapStateToProps)(withIntl(ProjectMetricsConfig, intlMessages));
