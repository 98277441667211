import React from 'react';

const BasicTitle = ({ title, subtitle, inverted }) => (
  <div className="basic-title">
    <div className="basic-title-text" style={inverted ? { color: 'white' } : {}}>{title}</div>
    {subtitle && <div className="basic-subtitle-text">{subtitle}</div>}
  </div>
);

export default BasicTitle;
