import React from 'react';
import PropTypes from 'prop-types';

import SimpleModal from './SimpleModal';

const ConfirmFinish = (props) => {
  const { id, intl, projectName, args } = props;
  const { finishProject, switchModal } = props;

  const messages = intl.messages.components.modals.confirmFinish;

  return (
    <SimpleModal
      open
      id={id}
      callback={confirmed => {
        if (confirmed) {
          finishProject(...args);
        }
      }}
      switchModal={switchModal}
      confirmLabel={messages.yes}
      cancelLabel={messages.no}
      message={
        <div>
          <p> {`${messages.msg1} ${projectName}?`} </p>
          <p> {messages.msg2} </p>
        </div>
      }
    />
  );
};

ConfirmFinish.propTypes = {
  id: PropTypes.string,
  projectName: PropTypes.string,
  args: PropTypes.array,
  intl: PropTypes.object,
  switchModal: PropTypes.func,
  deleteProject: PropTypes.func
};

export default ConfirmFinish;
