import React from 'react';
import PropTypes from 'prop-types';
import CheckboxField from 'material-ui/Checkbox';

const Checkbox = ({
  input: { onChange, value, ...inputProps },
  meta: { touched, error },
  onCheck,
  label,
  indicateRequired,
  defaultChecked,
  ...props
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <CheckboxField
        {...inputProps}
        {...props}
        label={indicateRequired ? <span>{label} *</span> : label}
        checked={value ? true : false}
        onCheck={(e, i) => {
          onChange(i);
          if (onCheck) {
            onCheck(i);
          }
        }}
      />
      {touched &&
        error && (
          <p
            className="text-error"
            style={{
              position: 'relative',
              fontSize: '12px',
              lineHeight: '12px',
              transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
              textAlign: 'left'
            }}
          >
            {error}
          </p>
        )}
    </div>
  );
};

Checkbox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.any,
  meta: PropTypes.object,
  custom: PropTypes.object
};

export default Checkbox;
