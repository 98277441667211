import React from 'react';
import cn from 'classnames';

const InfoCard = props => {
  const { className, style, children, actions, bgIcon } = props;
  const cls = cn({ 'project-info-card mui-card container-flex-column jc-space-between': true, [className]: className });
  const containerStyle = { ...style };
  if (bgIcon) {
    containerStyle.minHeight = '72px';
  }
  const childrenStyle = actions ? { paddingBottom: 0 } : {};
  return (
    <div className={cls} style={{ margin: '4px', ...style }}>
      {bgIcon && (
        <div className="project-info-card__bg-icon">
          <i className="material-icons" style={{ fontSize: '64px' }}>
            {bgIcon}
          </i>
        </div>
      )}
      <div className="project-info-card__children mui-padded" style={childrenStyle}>
        {children}
      </div>
      {actions && <div className="mui-padded text-right">{actions}</div>}
    </div>
  );
};

export default InfoCard;
