import React from 'react';
import { connect } from 'react-redux';

import SimpleModal from './SimpleModal';
import Spinner from '../spinner/Spinner';

import { formatMessage } from '../../utils/utils';

import * as opportunityActions from '../../actions/entities/opportunityActions';
import * as opportunityPostBuilderActions from '../../actions/entities/opportunityPostBuilderActions';
import { getCompanyTalentMarketPlan } from '../../actions/entities/companyActions';
import { switchModal } from '../../actions/options/modalActions';

const messages = {
  publish: {
    message: 'Přejete si zveřejnit příležitost {0}? Začne se tak zobrazovat a nabízet talentům v naší platformě.',
    cancelButton: 'Zrušit',
    confirmButton: 'Zveřejnit'
  },
  unpublish: {
    message:
      'Přejete si pozastavit zveřejnění příležitosti {0}? Přestane se tak zobrazovat a nabízet talentům v naší platformě.',
    cancelButton: 'Zrušit',
    confirmButton: 'Pozastavit'
  }
};

class OpportunityPostPublication extends React.Component {
  componentDidMount() {
    this.loadPost(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.postId !== this.props.postId) {
      this.loadPost(this.props);
    }
  }

  loadPost = props => {
    const { getOpportunityPost, clearOpportunityPost, postId } = props;
    if (postId) {
      getOpportunityPost(postId);
    } else {
      clearOpportunityPost();
    }
  };

  isPublished = () => {
    const {
      opportunityPostBuilder: { item }
    } = this.props;
    return item && !!item.publishedAt;
  };

  handleConfirm = confirmed => {
    if (!confirmed) {
      return;
    }
    const {
      opportunityPostBuilder: { item },
      postId,
      reloadAll,
      intl
    } = this.props;
    const { updateOpportunityPost, getOpportunity, getOpportunities, getCompanyTalentMarketPlan } = this.props;
    if (!item) {
      return;
    }
    const newPost = { ...item };
    if (this.isPublished()) {
      newPost.publishedAt = null;
    } else {
      newPost.publishedAt = Date.now();
    }
    updateOpportunityPost(postId, newPost).then(() => {
      if (reloadAll) {
        getOpportunities();
      } else {
        getOpportunity(item.opportunity.id, intl.locale);
      }
      const companyId = item.opportunity?.company?.id;
      if (companyId) {
        getCompanyTalentMarketPlan(companyId, intl.locale);
      }
    });
  };

  render() {
    const {
      id,
      opportunityPostBuilder: { item, loading }
    } = this.props;
    const { switchModal } = this.props;
    const published = this.isPublished();
    const msg = published ? messages.unpublish : messages.publish;
    return (
      <SimpleModal
        open
        id={id}
        callback={this.handleConfirm}
        switchModal={switchModal}
        cancelLabel={msg.cancelButton}
        confirmLabel={msg.confirmButton}
        message={
          loading ? (
            <div style={{ position: 'relative', minHeight: '96px' }}>
              <Spinner show local />
            </div>
          ) : (
            formatMessage(msg.message, [<b key="0">{item && item.title}</b>])
          )
        }
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    intl,
    entities: { opportunityPostBuilder }
  } = state;
  return { intl, opportunityPostBuilder };
};

const actions = {
  switchModal,
  getCompanyTalentMarketPlan,
  ...opportunityActions,
  ...opportunityPostBuilderActions
};

export default connect(mapStateToProps, actions)(OpportunityPostPublication);
