import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import cn from 'classnames';

const STROKE = 6;
const SIZE = 64;

const renderCircle = (size, stroke) => {
  return (
    <svg className="spinner" width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`}>
      <circle
        className="spinner-path"
        fill="none"
        strokeWidth={stroke}
        cx={size / 2}
        cy={size / 2}
        r={(size - stroke) / 2}
      />
    </svg>
  );
};

const Spinner = ({ show, local, size, relative, stroke }) => {
  if (!show) {
    return null;
  }
  const actualSize = size || SIZE;
  const className = cn({ 'spinner-content': true, local, relative });
  return (
    <div className={className} style={relative ? { height: actualSize, width: actualSize } : {}}>
      <div className="spinner-div">{renderCircle(actualSize, stroke || STROKE)}</div>
    </div>
  );
};

Spinner.propTypes = {
  show: PropTypes.bool.isRequired,
  local: PropTypes.bool,
  relative: PropTypes.bool,
  size: PropTypes.number,
  stroke: PropTypes.number
};

export default pure(Spinner);
