import React from 'react';

class TypingEffect extends React.Component {
  state = { typing: false, currentIndex: 0 };

  componentDidMount() {
    const { backwards, message } = this.props;
    if (backwards) {
      this.setState({ currentIndex: message.length - 1 });
    }
    this.start();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  start = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const { delay } = this.props;
    this.timeout = setTimeout(() => {
      const { currentIndex } = this.state;
      const { message, onFinished, backwards } = this.props;
      const inProgress = backwards ? currentIndex > 0 : message.length > currentIndex - 1;
      if (inProgress) {
        this.setState({ currentIndex: currentIndex + (backwards ? -1 : 1), typing: true });
        this.start();
      } else {
        this.setState({ typing: false });
        if (onFinished) {
          onFinished();
        }
      }
    }, delay || 100);
  };

  render() {
    const { message } = this.props;
    const { currentIndex, typing } = this.state;
    return (
      <span style={typing ? { borderRight: '1px solid', marginRight: '-2px', paddingRight: '1px' } : {}}>
        {message.substring(0, currentIndex)}
      </span>
    );
  }
}

class DynamicMessage extends React.Component {
  state = { currentIndex: 0, backwards: false };

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  next = () => {
    const { messages } = this.props;
    this.setState(({ currentIndex, backwards }) => ({
      currentIndex: backwards ? (currentIndex + 1) % messages.length : currentIndex,
      backwards: !backwards
    }));
  };

  start = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const { delay } = this.props;
    const { backwards } = this.state;
    this.timeout = setTimeout(() => this.next(), backwards ? 10 : delay || 2000);
  };

  render() {
    const { messages } = this.props;
    const { currentIndex, backwards } = this.state;
    return (
      <TypingEffect
        key={`${currentIndex}-${backwards}`}
        backwards={backwards}
        delay={backwards ? 20 : 100}
        message={messages[currentIndex]}
        onFinished={this.start}
      />
    );
  }
}

export default DynamicMessage;
