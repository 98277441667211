import React from 'react';
import cn from 'classnames';

const BaseContainer = ({ fluid, lgFluid, children, style }) => {
  const className = cn({ 'base-container': true, fluid, 'fluid-lg': lgFluid });
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default BaseContainer;
