import React from 'react';
import PropTypes from 'prop-types';
import L from 'lodash';

import MuiIconMenu from 'material-ui/IconMenu';
import Divider from 'material-ui/Divider';
import MenuItem from 'material-ui/MenuItem';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';

import IconButton from '../mui/IconButton';

const ProjectActionsIconMenu = props => {
  const {
    auth,
    intl,
    project,
    showInviteApplicants,
    showMainAction,
    onEditProject,
    onExportProject,
    onDeleteProject,
    onActivateProject,
    onFinishProject,
    onShowProjectDetail,
    onInviteApplicants,
    onCopyProject,
    ...rest
  } = props;
  const messages = intl.messages.components.iconMenu;
  const {
    validity: { state },
    company,
    slug,
    id
  } = project;

  const items = [];
  // invite applicants
  if (showInviteApplicants && L.includes(['SCHEDULED', 'ACTIVE'], state) && !project.view) {
    items.push(
      <MenuItem key="0" primaryText={messages.invite} onClick={() => onInviteApplicants(company.slug, slug)} />
    );
  }
  // activate
  if (showMainAction && state === 'CONCEPT') {
    items.push(<MenuItem key="1" primaryText={messages.activate} onClick={() => onActivateProject(project)} />);
  }
  // edit & reactivate
  if (state !== 'SCHEDULED' || showMainAction) {
    items.push(
      <MenuItem
        key="2"
        primaryText={state === 'FINISHED' ? messages.reactivate : messages.edit}
        onClick={() => onEditProject(company.id, id)}
      />
    );
  }
  // detail
  if (!showMainAction && L.includes(['CONCEPT', 'SCHEDULED'], state)) {
    items.push(
      <MenuItem key="4" primaryText={messages.detail} onClick={() => onShowProjectDetail(company.slug, slug)} />
    );
  }
  if (onCopyProject) {
    items.push(<MenuItem key="6" primaryText={messages.createGrouping} onClick={() => onCopyProject(project)} />);
  }
  // export
  if (L.includes(['SCHEDULED', 'ACTIVE', 'FINISHED'], state) && auth.canExportProjects) {
    items.push(
      <MenuItem
        key="7"
        rightIcon={<i className="material-icons">arrow_right</i>}
        primaryText={messages.export}
        menuItems={[
          <MenuItem
            key="8"
            onClick={() => onExportProject(company.id, id, intl.locale, 'xlsx')}
            rightIcon={<DownloadIcon />}
            primaryText={messages.exportXLSX}
          />,
          <MenuItem
            key="9"
            onClick={() => onExportProject(company.id, id, intl.locale, 'csv')}
            rightIcon={<DownloadIcon />}
            primaryText={messages.exportCSV}
          />
        ]}
      />
    );
  }
  // finish
  if (L.includes(['SCHEDULED', 'ACTIVE'], state)) {
    if (items.length) {
      items.push(<Divider key="beforeFinish"/>);
    }
    items.push(<MenuItem key="5" primaryText={messages.finish} onClick={() => onFinishProject(project)} />);
  }
  // delete
  if (L.includes(['CONCEPT', 'FINISHED'], state)) {
    if (items.length) {
      items.push(<Divider key="beforeDelete"/>);
    }
    items.push(<MenuItem key="3" primaryText={messages.delete} onClick={() => onDeleteProject(project)} />);
  }

  return (
    <MuiIconMenu
      desktop
      {...rest}
      iconButtonElement={
        <IconButton>
          <i className="material-icons">more_vert</i>
        </IconButton>
      }
    >
      {items}
    </MuiIconMenu>
  );
};

ProjectActionsIconMenu.propTypes = {
  intl: PropTypes.object,
  auth: PropTypes.object,
  project: PropTypes.object,
  showMainAction: PropTypes.bool,
  showInviteApplicants: PropTypes.bool,
  //
  onInviteApplicants: PropTypes.func,
  onDeleteProject: PropTypes.func,
  onActivateProject: PropTypes.func,
  onFinishProject: PropTypes.func,
  onEditProject: PropTypes.func,
  onExportProject: PropTypes.func,
  onShowProjectDetail: PropTypes.func,
  onCopyProject: PropTypes.func
};

export default ProjectActionsIconMenu;
