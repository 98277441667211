import React from 'react';

import SuperText from '../SuperText';

const SuperTextField = props => {
  const { input, meta: { touched, error, active }, label, indicateRequired, ...other } = props;
  const fieldProps = {
    error: touched && error,
    focused: active,
    label: indicateRequired ? <span>{label} *</span> : label,
    ...input,
    ...other
  };
  return <SuperText {...fieldProps} />;
};

export default SuperTextField;
