import React from 'react';
import moment from 'moment';
import _map from 'lodash/map';
import _range from 'lodash/range';
import _find from 'lodash/find';

import Button from './Button';

import withIntl from '../withIntl';
import { Popover } from 'material-ui';

const asFrom = m => m.startOf('day');
const asTo = m => m.endOf('day');

const createPresets = messages => {
  const res = [
    { key: 'last7Days', from: asFrom(moment().add(-6, 'days')), to: asTo(moment()), label: messages.last7Days },
    { key: 'last28Days', from: asFrom(moment().add(-27, 'days')), to: asTo(moment()), label: messages.last28Days }
  ];
  const monthNames = moment.months();
  for (let i = 0; i < 4; i += 1) {
    const m = moment().add(-i, 'months');
    const mIdx = m.month();
    res.push({
      key: `month${mIdx}`,
      from: asFrom(m.startOf('month')),
      to: asTo(
        moment()
          .add(-i, 'months')
          .endOf('month')
      ),
      label: `${monthNames[mIdx]} ${m.year()}`
    });
  }
  return res;
};

const anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
const targetOrigin = { horizontal: 'right', vertical: 'top' };

const DateCell = props => {
  const { date, month, year, from, to, currentDate, start } = props;
  const { onMouseEnter, onMouseLeave, onClick } = props;
  const isInMonth = date.year() === year && date.month() === month;
  const disabled = !start && from && from.isAfter(date, 'day');
  const style = {
    width: 36,
    minWidth: 0,
    height: 36,
    cursor: disabled ? 'default' : 'pointer',
    borderRadius: 0
  };
  if (from && from.isSameOrBefore(date, 'day') && to && to.isSameOrAfter(date, 'day')) {
    style.backgroundColor = 'rgba(0, 0, 0, 0.08)';
  }
  if ((start && from && from.isSame(date, 'day')) || (!start && to && to.isSame(date, 'day'))) {
    style.backgroundColor = 'rgba(0, 0, 0, 0.16)';
  }
  if (currentDate && currentDate.isSame(date, 'day')) {
    style.backgroundColor = 'rgba(0, 0, 0, 0.24)';
  }
  return (
    <div
      role="button"
      className="container-flex-row ai-center jc-center"
      style={style}
      onMouseEnter={() => onMouseEnter(date)}
      onMouseLeave={() => onMouseLeave(date)}
      onClick={disabled ? () => {} : () => onClick(date)}
    >
      <div className={isInMonth && !disabled ? 'text-normal' : 'text-muted'}>{date.date()}</div>
    </div>
  );
};

const formatPeriod = p => {
  if (!p) {
    return;
  }
  const firstChar = p[0];
  return firstChar.toUpperCase() + p.substring(1);
};

class DateRangeSelectPart extends React.Component {
  state = { month: moment().month(), year: moment().year() };

  componentDidMount() {
    this.updateRangeFromProps();
  }

  componentDidUpdate(prevProps) {
    const { from, to } = this.state;
    let updated = false;
    if (prevProps.from !== this.props.from && this.props.from !== from) {
      updated = true;
    }
    if (prevProps.to !== this.props.to && this.props.to !== to) {
      updated = true;
    }
    if (updated) {
      this.updateRangeFromProps();
    }
  }

  updateRangeFromProps = () => {
    const { from, to, start } = this.props;
    const date = start
      ? from ||
        to ||
        moment(Date.now())
          .add(-1, 'month')
          .toDate()
      : to || from || Date.now();
    this.updateRange(date, { from, to });
  };

  updateRange = (date, other) => {
    const month = moment(date).month();
    const year = moment(date).year();
    this.setState({ month, year, ...other });
  };

  handleMouseEnterDate = date => this.setState({ currentDate: date });
  handleMouseLeaveDate = () => this.setState({ currentDate: null });
  handleClickDate = date => {
    const { start, onChange } = this.props;
    this.updateRange(date, start ? { from: date } : { to: date });
    if (onChange) {
      onChange(date);
    }
  };

  handleSetPrevMonth = () => {
    this.setState(({ month, year }) => ({
      month: month === 0 ? 11 : month - 1,
      year: month === 0 ? year - 1 : year
    }));
  };
  handleSetNextMonth = () => {
    this.setState(({ month, year }) => ({
      month: month === 11 ? 0 : month + 1,
      year: month === 11 ? year + 1 : year
    }));
  };

  render() {
    const { start } = this.props;
    const { month, year, from } = this.state;
    const startOfMonth = moment([year, month]).startOf('month');
    const startCandidate = moment(startOfMonth).startOf('week');
    const startDate = startOfMonth.isSame(startCandidate, 'day') ? startCandidate.add(-1, 'week') : startCandidate;
    const commonProps = {
      ...this.state,
      start: this.props.start,
      onMouseEnter: this.handleMouseEnterDate,
      onMouseLeave: this.handleMouseLeaveDate,
      onClick: this.handleClickDate
    };
    const weekdays = _map(moment.weekdaysMin(), formatPeriod);
    const months = moment.monthsShort();
    const firstDOW = moment.localeData().firstDayOfWeek();
    const normalize = start ? asFrom : asTo;
    return (
      <div className="mui-padded">
        <div className="container-flex-row jc-space-between ai-center">
          <div>
            <Button
              icon={<i className="material-icons">chevron_left</i>}
              onClick={this.handleSetPrevMonth}
              style={{ minWidth: 36 }}
              disabled={
                !start &&
                from &&
                moment(from)
                  .startOf('month')
                  .isSameOrAfter(startOfMonth, 'day')
              }
            />
          </div>
          <div>
            {formatPeriod(months[month])} {year}
          </div>
          <div>
            <Button
              icon={<i className="material-icons">chevron_right</i>}
              onClick={this.handleSetNextMonth}
              style={{ minWidth: 36 }}
            />
          </div>
        </div>
        <div className={'container-flex-row'}>
          {_map(weekdays, (dow, idx, arr) => (
            <div key={idx} className="text-center" style={{ width: `${100 / 7}%`, height: 36, lineHeight: '36px' }}>
              <small>{arr[(idx + firstDOW) % 7]}</small>
            </div>
          ))}
        </div>
        {_map(_range(6), w => (
          <div className={'container-flex-row'} key={w}>
            {_map(_range(7), dow => (
              <DateCell key={dow} date={normalize(moment(startDate).add(w * 7 + dow, 'days'))} {...commonProps} />
            ))}
          </div>
        ))}
      </div>
    );
  }
}

class DatePresetSelect extends React.Component {
  constructor(props) {
    super(props);
    const { from, to } = props;
    this.state = {
      from,
      to
    };
  }

  componentDidUpdate(prevProps) {
    const { from, to } = this.state;
    if (prevProps.from !== this.props.from && this.props.from !== from) {
      this.setState({ from: this.props.from });
    }
    if (prevProps.to !== this.props.to && this.props.to !== to) {
      this.setState({ to: this.props.to });
    }
  }

  handleSelectPreset = p => {
    const { from, to } = p;
    const { onChange } = this.props;
    this.setState({ from, to });
    if (onChange) {
      onChange(from, to);
    }
  };

  render() {
    const { from, to } = this.state;
    const { presets } = this.props;
    return (
      <div className="mui-padded-half">
        {_map(presets, (p, idx) => {
          const active = from && from.isSame(p.from, 'day') && to && to.isSame(p.to, 'day');
          const style = { width: '100%' };
          if (active) {
            style.backgroundColor = 'rgba(0, 0, 0, 0.12)';
          }
          return (
            <div key={idx} className="mui-padded-half">
              <Button onClick={() => this.handleSelectPreset(p)} label={p.label} style={style} />
            </div>
          );
        })}
      </div>
    );
  }
}

class DateRangeSelect extends React.Component {
  constructor(props) {
    super(props);
    const { from, to } = props;
    this.state = {
      from: from && moment(from),
      to: to && moment(to)
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
      this.setState({ from: this.props.from, to: this.props.to });
    }
  }

  handleChangeFrom = date => {
    this.setState(({ to }) => {
      if (to && date.isAfter(to)) {
        return {
          from: date,
          to: moment(date).add(7, 'days')
        };
      }
      return { from: date };
    });
  };
  handleChangeTo = date => {
    this.setState({ to: date });
  };
  handleChangeRange = (from, to) => this.setState({ from, to });
  handleConfirm = () => {
    const { onConfirm } = this.props;
    if (onConfirm) {
      const { from, to } = this.state;
      onConfirm({ from, to });
    }
  };
  handleCancel = () => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
  };

  render() {
    const { from, to } = this.state;
    const { presets, intl: { messages } } = this.props;
    return (
      <div>
        <div className="mui-padded">
          <div className="container-flex-row">
            <DateRangeSelectPart from={from} to={to} onChange={this.handleChangeFrom} start />
            <DateRangeSelectPart from={from} to={to} onChange={this.handleChangeTo} />
            <DatePresetSelect from={from} to={to} onChange={this.handleChangeRange} presets={presets} />
          </div>
        </div>
        <div className="mui-padded text-right">
          <Button label={messages.cancelLabel} onClick={this.handleCancel} style={{ marginRight: '8px' }} />
          <Button raised primary label={messages.okLabel} onClick={this.handleConfirm} disabled={!from || !to} />
        </div>
      </div>
    );
  }
}

class DateRangeSelectMenu extends React.Component {
  state = {
    open: false
  };

  componentDidMount() {
    this.initData();
    this.setRangeFromProps();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.intl.locale !== this.props.intl.locale) {
      this.initData();
    }
    if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
      this.setRangeFromProps();
    }
  }

  setRangeFromProps = () => {
    const { from, to } = this.props;
    this.setState({
      from: from && moment(from),
      to: to && moment(to)
    });
  };

  initData = () => {
    const { intl: { locale, messages } } = this.props;
    moment.locale(locale);
    this.setState({
      presets: createPresets(messages.presets)
    });
  };

  formatLabel = () => {
    const { from, to, presets } = this.state;
    if (!from && !to) {
      return 'N/A';
    }
    if (!from || !to) {
      return moment(from || to).format('L');
    }
    const preset = _find(presets, p => p.from.isSame(from, 'day') && p.to.isSame(to, 'day'));
    if (preset) {
      return preset.label;
    }
    return `${moment(from).format('L')} - ${moment(to).format('L')}`;
  };

  handleOpenSelect = () => {
    this.setState({ open: true });
  };
  handleCloseSelect = () => {
    this.setState({ open: false });
  };

  setElem = el => (this.anchorEl = el);

  handleConfirm = ({ from, to }) => {
    const { onChange } = this.props;
    this.setState({ from, to });
    if (onChange) {
      onChange({ from, to });
    }
    this.handleCloseSelect();
  };

  render() {
    const { open, from, to, presets } = this.state;
    const { intl, disabled } = this.props;
    return (
      <div ref={this.setElem} style={{ display: 'inline-block' }}>
        <Button
          label={this.formatLabel()}
          onClick={this.handleOpenSelect}
          icon={<i className="material-icons">today</i>}
          disabled={disabled}
        />
        <Popover
          open={open}
          anchorEl={this.anchorEl}
          anchorOrigin={anchorOrigin}
          targetOrigin={targetOrigin}
          onRequestClose={this.handleCloseSelect}
        >
          <DateRangeSelect
            from={from}
            to={to}
            presets={presets}
            onConfirm={this.handleConfirm}
            onCancel={this.handleCloseSelect}
            intl={intl}
          />
        </Popover>
      </div>
    );
  }
}

const intlMessages = {
  cs: {
    okLabel: 'OK',
    cancelLabel: 'Zrušit',
    presets: {
      last7Days: 'Posledních 7 dní',
      last28Days: 'Posledních 28 dní'
    }
  },
  sk: {
    okLabel: 'OK',
    cancelLabel: 'Zrušiť',
    presets: {
      last7Days: 'Posledných 7 dní',
      last28Days: 'Posledných 28 dní'
    }
  },
  en: {
    okLabel: 'OK',
    cancelLabel: 'Cancel',
    presets: {
      last7Days: 'Last 7 days',
      last28Days: 'Last 28 days'
    }
  }
};

export default withIntl(DateRangeSelectMenu, intlMessages);
