import { handleActions } from 'redux-actions';

import {
  CROSS_COMPARISON_START,
  CROSS_COMPARISON_CANCEL,
  CROSS_COMPARISON_UPDATE_PARTICIPANTS,
  CROSS_COMPARISON_SET_PARTICIPANT_ACTIVE,
  CROSS_COMPARISON_SET_MANAGERIAL,
  CROSS_COMPARISON_LOAD_PARTICIPANTS
} from '../../constants/constants';

const defaultState = {};

export default handleActions(
  {
    [CROSS_COMPARISON_START]: (state, { payload }) => ({ ...state, ...payload }),
    [CROSS_COMPARISON_UPDATE_PARTICIPANTS]: (state, { payload: { participants } }) => ({ ...state, participants }),
    [CROSS_COMPARISON_SET_PARTICIPANT_ACTIVE]: (state, { payload: { index } }) => ({ ...state, activeIndex: index }),
    [CROSS_COMPARISON_SET_MANAGERIAL]: (state, { payload: managerial }) => ({ ...state, managerial }),
    [CROSS_COMPARISON_CANCEL]: state => defaultState,
    [CROSS_COMPARISON_LOAD_PARTICIPANTS.SUCCESS]: (state, { payload: { payload: { items } } }) => ({
      ...state,
      targetParticipants: items,
      loading: false
    }),
    [CROSS_COMPARISON_LOAD_PARTICIPANTS.PENDING]: state => ({
      ...state,
      loading: true
    }),
    [CROSS_COMPARISON_LOAD_PARTICIPANTS.ERROR]: state => ({
      ...state,
      loading: false,
      error: true
    })
  },
  defaultState
);
