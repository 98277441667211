import React from 'react';
import _map from 'lodash/map';

import TeamOverviewHeader from './TeamOverviewHeader';
import ActivityStatusChart from '../analytics/ActivityStatusChart';
import ActivityLabel from '../activity/ActivityLabel';
import StatusCountsChart from '../analytics/StatusCountsChart';
import CultureFitScores from './overview/CultureFitScores';
import CompetenciesSummary from './overview/CompetenciesSummary';
import ActivityStatusControls from './overview/ActivityStatusControls';

import withIntl from '../withIntl';

import { getActivityMapKey, getActivityMetricDefinitions, memberToParticipant } from './TeamUtils';
import { EActivityType, EGroupMembership } from '../../constants/enum';

const getActivities = (team, { managers, members }) => {
  const configs = team?.data?.activityConfigurations || {};
  const activityMap = {};
  const res = [];
  for (let prop in configs) {
    if (configs.hasOwnProperty(prop)) {
      const c = configs[prop];
      const activities = c.activities || [];
      for (let i = 0; i < activities.length; i += 1) {
        const a = activities[i];
        const am = getActivityMapKey(a);
        if (!activityMap[am]) {
          const configuration =
            (prop === EGroupMembership.MANAGER.key && managers?.payload?.configuration) ||
            members?.payload?.configuration;
          const activity = {
            ...a,
            metricDefinitions: getActivityMetricDefinitions(a, configuration),
            statusCounts: { finished: 0, started: 0, total: 0 },
            managers: [],
            members: [],
            participants: [],
            toInvite: { members: [] },
            toNotify: { members: [], projectMap: {} }
          };
          activityMap[am] = activity;
          res.push(activity);
        }
      }
    }
  }
  const allParticipants = [...managers.items, ...members.filtered];
  const processActivities = (act, callback) => {
    for (let i = 0; i < act.length; i += 1) {
      const a = act[i];
      const am = activityMap[getActivityMapKey(a)];
      callback(a, am);
    }
  };
  for (let i = 0; i < allParticipants.length; i += 1) {
    const p = allParticipants[i];
    const activities = p.activities || [];
    processActivities(activities, (a, sa) => {
      if (sa) {
        if (a.finishedAt) {
          sa.statusCounts.finished += 1;
        } else if (a.startedAt) {
          sa.statusCounts.started += 1;
        }
        sa.statusCounts.total += 1;
        //
        const mp = memberToParticipant(p, a);
        if (i < managers.items.length) {
          sa.managers.push(mp);
        } else {
          sa.members.push(mp);
        }
        sa.participants.push(mp);
      }
    });
    processActivities(p.meta.activities.invitable, (a, sa) => {
      if (sa) {
        sa.toInvite.members.push(p);
      }
    });
    processActivities(p.meta.activities.notifiable, (a, sa) => {
      if (sa) {
        sa.toNotify.members.push(p);
        sa.toNotify.projectMap[p.id] = { [getActivityMapKey(a)]: a.project };
      }
    });
  }
  return res;
};

const TeamOverview = props => {
  const {
    team,
    type,
    intl: { messages },
    onInvite,
    onNotify,
    onAddStart
  } = props;
  const { managers, members } = props.members;
  const activities = getActivities(team, props.members);
  const readOnly = team?.readOnly;
  return (
    <div className="mui-padded mui-padded-vertical-x2">
      <TeamOverviewHeader
        type={type}
        members={members}
        manager={(managers?.items || [])[0]}
        onAddStart={onAddStart}
        intl={props.intl}
        readOnly={readOnly}
      />
      {activities.length > 0 ? (
        <div className="container-flex-row fw-yes">
          {_map(activities, a => {
            const ak = getActivityMapKey(a);
            return (
              <div key={ak} className="mui-padded">
                <div className="mui-label mui-padded">
                  <ActivityLabel activity={a} />
                </div>
                <div className="container-flex-row fw-yes" style={{ margin: '-8px' }}>
                  <div className="mui-padded">
                    <div
                      className="border border-radius border-color-primary__50 container-flex-row fw-yes"
                      style={{ overflow: 'hidden' }}
                    >
                      <div className="flex-grow mui-padded" style={{ minWidth: '200px' }}>
                        <StatusCountsChart statusCounts={a.statusCounts} />
                      </div>
                      <div className="flex-grow bg-primary-light">
                        <ActivityStatusControls
                          activity={a}
                          readOnly={readOnly}
                          onInvite={onInvite}
                          onNotify={onNotify}
                        />
                      </div>
                    </div>
                  </div>
                  {EActivityType.CULTURE_FIT.isLike(a) && (
                    <div className="mui-padded">
                      <div className="mui-padded border border-radius border-color-primary__50">
                        <CultureFitScores activity={a} type={type} />
                      </div>
                    </div>
                  )}
                  {(EActivityType.GAME_CHANGER.value === a.key || EActivityType.OFFICE_DAY.value === a.key) && (
                    <div className="mui-padded">
                      <div className="mui-padded border border-radius border-color-primary__50">
                        <CompetenciesSummary activity={a} type={type} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mui-padded-x2">
          <div className="text-muted text-center">
            <em>{messages.noActivitiesMessage}</em>
          </div>
        </div>
      )}
      <div>
        <ActivityStatusChart
          participants={[...managers.items, ...members.filtered]}
          getParticipantActivities={p => p.activities}
          border
        />
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    noActivitiesMessage: 'Zatím žádné aktivity'
  },
  sk: {
    noActivitiesMessage: 'Zatiaľ žiadne aktivity'
  },
  en: {
    noActivitiesMessage: 'No activities yet'
  }
};

export default withIntl(TeamOverview, intlMessages);
