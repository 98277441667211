// common containters
import NotFound from './common/NotFound';

// private containters
import Project from './private/Project';
import Projects from './private/Projects';
import OpportunitiesContainer from './private/OpportunitiesContainer';
import Opportunity from './private/opportunity/Opportunity';
import OpportunityPostForm from '../components/opportunity/builder/OpportunityPostForm';
import ProjectEditContainer from '../components/project/ProjectEditContainer';
import TalentDetail from './private/talent/TalentDetail';
import DelegatingModeBasedContainer from './private/DelegatingModeBasedContainer';
import CompanyProfileContainer from './private/company/CompanyProfileContainer';
import CompanyProfileForm from '../components/company/profile/CompanyProfileForm';
import TeamsContainer from '../components/team/TeamsContainer';
import EapiKeysContainer from '../components/eapi/keys/EapiKeysContainer';
import HomeContainer from '../components/home/HomeContainer';
//
import SimulationDesigner from './private/designer/SimulationDesigner';
import SimulationModelEditor from './private/designer/SimulationModelEditor';
import SimulationModelList from './private/designer/SimulationModelList';

import ApplicantDetail from './private/position/ApplicantDetail';
import ApplicantList from './private/position/ApplicantList';
import ApplicantPrint from './private/position/ApplicantPrint';
import ApplicantCompare from './private/position/ApplicantCompare';
import ProjectAnalytics from './private/project/ProjectAnalytics';
import ProjectOverview from './private/project/ProjectOverview';

// public containters
import Login from './public/Login';
import ResetPasswordEmail from './public/ResetPasswordEmail';
import ResetPassword from './public/ResetPassword';
import AccountNew from './public/AccountNew';
import AccountActivation from './public/AccountActivation';

export default {
  NotFound,

  Project,
  Projects,
  OpportunitiesContainer,
  Opportunity,
  OpportunityPostForm,
  TalentDetail,
  SimulationDesigner,
  SimulationModelEditor,
  SimulationModelList,
  DelegatingModeBasedContainer,
  CompanyProfileContainer,
  CompanyProfileForm,
  ProjectEditContainer,
  EapiKeysContainer,

  TeamsContainer,
  HomeContainer,

  ApplicantDetail,
  ApplicantList,
  ApplicantPrint,
  ApplicantCompare,
  ProjectAnalytics,
  ProjectOverview,

  Login,
  ResetPasswordEmail,
  ResetPassword,
  AccountNew,
  AccountActivation
};
