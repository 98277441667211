import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';

import Checkbox from 'material-ui/Checkbox';
import ParticipantName from '../basic/ParticipantName';
import SearchField from '../mui/SearchField';

class ParticipantsSelectPanel extends React.Component {
  static propTypes = {
    participants: PropTypes.array,
    preselected: PropTypes.array,
    withFilter: PropTypes.bool,
    limit: PropTypes.number,
    getParticipant: PropTypes.func,
    onSelectionChange: PropTypes.func
  };

  state = {
    checked: {},
    checkedCount: 0
  };

  componentDidMount() {
    this.initFromProps();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.participants !== this.props.participants || prevProps.preselected !== this.props.preselected) {
      this.initFromProps();
    }
  }

  getParticipantProps = p => {
    const { getParticipant } = this.props;
    return getParticipant ? getParticipant(p) : p.participant;
  };

  getParticipantId = p => {
    const pProps = this.getParticipantProps(p);
    return `${p.projectId}/${pProps.id}`;
  };

  initFromProps = () => {
    const { participants, preselected } = this.props;
    const preselectedValues = preselected || participants || [];
    const newState = _reduce(
      preselectedValues,
      (res, p) => {
        res.checked[this.getParticipantId(p)] = true;
        res.checkedCount += 1;
        return res;
      },
      { checked: {}, checkedCount: 0 }
    );
    this.setState(newState);
  };

  handleToggle = p => {
    const { checked, checkedCount } = this.state;
    const pId = this.getParticipantId(p);
    const { [pId]: value, ...rest } = checked;
    rest[pId] = !value;
    this.setState({ checked: rest, checkedCount: checkedCount + (value ? -1 : 1) });
    const { onSelectionChange } = this.props;
    if (onSelectionChange) {
      onSelectionChange(
        _reduce(
          this.props.participants || [],
          (res, p) => {
            if (rest[this.getParticipantId(p)]) {
              res.push(p);
            }
            return res;
          },
          []
        )
      );
    }
  };

  handleFilterTextChange = filterText => this.setState({ filterText });

  render() {
    const { participants, withFilter, limit } = this.props;
    const { checked, checkedCount, filterText } = this.state;
    const filteredParticipants =
      filterText && withFilter
        ? _filter(participants, p => {
            const { firstName, lastName, email } = this.getParticipantProps(p);
            const flt = filterText.toLowerCase();
            return (
              (email && email.toLowerCase().indexOf(flt) >= 0) ||
              ((lastName || '') + ' ' + (firstName || '')).toLowerCase().indexOf(flt) >= 0
            );
          })
        : participants;
    return (
      <div>
        {withFilter && (
          <div className="mui-padded-horizontal-x2 mui-padded-vertical">
            <SearchField value={filterText} onValueChange={this.handleFilterTextChange} debounce={10} compact />
          </div>
        )}
        <div>
          {_map(filteredParticipants, p => {
            const pId = this.getParticipantId(p);
            return (
              <div key={pId}>
                <label
                  className="container-flex-row ai-center"
                  htmlFor={`cb-${pId}`}
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    margin: 0,
                    cursor: 'pointer',
                    padding: '8px 16px'
                  }}
                >
                  <div>
                    <Checkbox
                      id={`cb-${pId}`}
                      onCheck={() => this.handleToggle(p)}
                      checked={checked[pId]}
                      disabled={limit && limit <= checkedCount && !checked[pId]}
                    />
                  </div>
                  <div className="flex1">
                    <div>
                      <ParticipantName {...this.getParticipantProps(p)} />
                    </div>
                    {p.project && <div className="small text-muted">{p.project.name}</div>}
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ParticipantsSelectPanel;
