import React from 'react';

const AnalyticsTeaserMessage = props => {
  const { icon, message } = props;
  return (
    <div className="analytics-teaser-message text-center" style={{ padding: '32px 16px' }}>
      <div>
        <i className="material-icons text-primary" style={{ fontSize: '48px', marginBottom: '8px' }}>
          {icon}
        </i>
      </div>
      <div className="analytics-teaser-message-text text-muted">{message}</div>
    </div>
  );
};

export default AnalyticsTeaserMessage;
