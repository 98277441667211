import React from 'react';

import Competency from './Competency';

const mapping = {
  effectiveCommunication: {
    mdi: 'comment-check'
  },
  informationProcessing: {
    mdi: 'file-search'
  },
  integrity: {
    mdi: 'set-left-center'
  },
  numeracy: {
    mdi: 'plus-minus-variant'
  },
  problemSolving: {
    mdi: 'lightbulb-on'
  },
  selfKnowledge: {
    mdi: 'reflect-horizontal'
  },
  customerOrientation: {
    mdi: 'cart'
  },
  //
  socialDesirability: {
    mdi: 'drama-masks'
  },
  independence: {
    mdi: 'lifebuoy'
  },
  workPace: {
    mdi: 'timelapse'
  },
  //
  check: {
    mdi: 'check'
  },
  //
  // TGC
  //
  morality: {
    mdi: 'book-lock'
  },
  openness: {
    mdi: 'shape'
  },
  assurance: {
    mdi: 'hand-heart'
  },
  authority: {
    mdi: 'medal'
  },
  diplomacy: {
    mdi: 'tie'
  },
  delegation: {
    mdi: 'swap-horizontal-bold'
  },
  leadership: {
    mdi: 'compass'
  },
  motivation: {
    mdi: 'head-plus'
  },
  proactivity: {
    mdi: 'engine'
  },
  undermining: {
    mdi: 'emoticon-angry'
  },
  pleasantness: {
    mdi: 'thumb-up'
  },
  attitudeChange: {
    mdi: 'directions-fork'
  },
  prioritization: {
    mdi: 'flag'
  },
  workEfficiency: {
    mdi: 'progress-check'
  },
  teamOrientation: {
    mdi: 'account-group'
  },
  processOrientation: {
    mdi: 'table-cog'
  },
  managerialIntegrity: {
    mdi: 'set-left-center'
  },
  managerialIndependence: {
    mdi: 'lifebuoy'
  },
  performanceOrientation: {
    mdi: 'bullseye-arrow'
  },
  responsibilityAcceptance: {
    mdi: 'hand'
  },
  managerialCustomerOrientation: {
    mdi: 'cart'
  },
  managerialEffectiveCommunication: {
    mdi: 'comment-check'
  },
  //
  // Culture Fit
  //
  cfStability: {
    mdi: 'arrow-expand-down'
  },
  cfStability_opposite: {
    mdi: 'arrow-expand-up'
  },
  cfPerformance: {
    mdi: 'head-check'
  },
  cfPerformance_opposite: {
    mdi: 'head-heart'
  },
  cfIndividuality: {
    mdi: 'gamepad-circle-right'
  },
  cfIndividuality_opposite: {
    mdi: 'gamepad-circle'
  },
  cfIndependence: {
    mdi: 'human'
  },
  cfIndependence_opposite: {
    mdi: 'sitemap'
  },
  cfOpenMindedness: {
    mdi: 'home-lock-open'
  },
  cfOpenMindedness_opposite: {
    mdi: 'home-lock'
  },
  cfChaos: {
    mdi: 'sword-cross'
  },
  cfChaos_opposite: {
    mdi: 'shield'
  },
  cfStatus: {
    mdi: 'chevron-triple-up'
  },
  cfStatus_opposite: {
    mdi: 'brain'
  }
};

const Metric = ({ metric, style, opposite, ...props }) => {
  const { color, ...rest } = style || {};
  const symbol = (opposite && mapping[`${metric.key}_opposite`]) || mapping[metric.key];
  return <Competency symbol={symbol} color={color} style={rest} fallback={metric.shortLabel} {...props} />;
};

export default Metric;
