import React from 'react';
import { connect } from 'react-redux';

import LockedPlaceholder from '../../components/placeholder/LockedPlaceholder';

import { selectAccountPermissions } from '../../selectors/auth';
import * as upgradeAccountActions from '../../actions/entities/accountActions';
import { EUpgradeRequestSource } from '../../constants/enum';

const DelegatingModeBasedContainer = props => {
  const { children, route, auth, intl } = props;
  const { invokeUpgradeAccountModal } = props;
  const modes = route.modes ? route.modes : route.mode ? [route.mode] : [];
  for (let i = 0; i < modes.length; i += 1) {
    const m = modes[i];
    if (auth.modes[m.key]) {
      return children;
    }
  }
  const mode = modes[0] || EUpgradeRequestSource.MODE_RECRUITMENT;
  const messages = intl.messages.components.navigation.lockedModeMessages[mode.key] || {};
  const source = EUpgradeRequestSource[`MODE_${mode.key}`];
  return (
    <div style={{ margin: '32px auto', maxWidth: '40em' }}>
      <LockedPlaceholder upgradeAccount={invokeUpgradeAccountModal} source={source} {...messages} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { intl } = state;
  return {
    auth: selectAccountPermissions(state, ownProps),
    intl
  };
};

const actions = {
  invokeUpgradeAccountModal: upgradeAccountActions.invokeUpgradeAccountModal
};

export default connect(mapStateToProps, actions)(DelegatingModeBasedContainer);
