import React from 'react';
import cn from 'classnames';

const FeatureIndicator = ({ name, className, description, style }) => (
  <div
    className={cn({
      'mui-feature-indicator': true,
      'bg-primary-light': true,
      'text-primary': true,
      [className]: className
    })}
    style={style}
  >
    <div className="mui-feature-indicator-name">
      <span>{name}</span>
    </div>
    <div className="mui-feature-indicator-description">
      <span>{description}</span>
    </div>
  </div>
);

export default FeatureIndicator;
