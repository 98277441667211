import { API, DEMO_SIMULATION_URL_GET } from '../../constants/constants';

export const getDemoUrl = (gender = 'FEMALE') => ({
  type: API,
  payload: {
    asyncType: DEMO_SIMULATION_URL_GET
  },
  meta: {
    url: `/api/v1/operators/games/demo`,
    method: 'POST',
    body: {
      gender
    }
  }
});
