import React from 'react';
import { connect } from 'react-redux';

import ProjectMetricsConfig from './ProjectMetricsConfig';

import allActions from '../../../actions';
import * as metricsConfigActions from '../../../actions/options/metricsConfigActions';
import * as projectOverviewActions from '../../../actions/options/projectOverviewActions';

import { EProjectOverviewPart } from '../../../constants/enum';

class ProjectContext extends React.Component {
  handleSaveMetricsConfig = (config, scoreMapping) => {
    const { saveMetricsConfig, loadData, refreshData } = this.props;
    const { project } = this.props;
    return saveMetricsConfig(project, config, scoreMapping)
      .then(() => refreshData())
      .then(() => loadData());
  };

  isPartActive = part => {
    const { projectOverview } = this.props;
    // by default it should be true
    return !projectOverview.parts || projectOverview.parts[part] !== false;
  };

  handleTogglePart = part => {
    const { project, toggleProjectOverviewPart } = this.props;
    toggleProjectOverviewPart(project.id, part);
  };
  handleToggleWeights = () => this.handleTogglePart(EProjectOverviewPart.WEIGHTS);

  render() {
    const { project, projectCrudAllowed } = this.props;
    const weightsShown = this.isPartActive(EProjectOverviewPart.WEIGHTS);
    return (
      <div>
        <ProjectMetricsConfig
          project={project}
          projectCrudAllowed={projectCrudAllowed}
          expanded={weightsShown}
          onExpandedToggle={this.handleToggleWeights}
          onSave={this.handleSaveMetricsConfig}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    options: { projectOverview }
  } = state;
  const { project } = props;
  return {
    projectOverview: (projectOverview && project && projectOverview[project.id]) || {}
  };
};

const actions = {
  ...allActions,
  ...metricsConfigActions,
  ...projectOverviewActions
};

export default connect(mapStateToProps, actions)(ProjectContext);
