import { createSelector } from 'reselect';
import _reduce from 'lodash/reduce';

const baseSelectActivityTemplates = state => state.entities.activityTemplates.templates;
const baseSelectIntl = state => state.intl;

export const selectActivityTemplates = createSelector(
  [baseSelectActivityTemplates, baseSelectIntl],
  (templates, { locale }) => {
    return _reduce(
      templates,
      (res, t) => {
        const { key, variant, properties } = t;
        const item = {
          key,
          variant,
          ...(properties[locale] || properties.en)
        };
        res.list.push(item);
        res.map[variant ? `${key}/${variant}` : key] = item;
        return res;
      },
      { list: [], map: {} }
    );
  }
);
