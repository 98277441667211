import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../spinner/Spinner';

const HomeSectionHeader = props => {
  const { title, menu, icon, loading } = props;
  return (
    <div className="ai-center container-flex-row mui-padded-x2 mui-padded-bottom-zero">
      {icon && (
        <div className="mui-margin-horizontal text-highlight">
          {loading ? (
            <Spinner show relative size={24} stroke={3} />
          ) : icon.type === 'mdi' ? (
            <span className={`mdi mdi-${icon.value} mdi-24px`} style={{ lineHeight: '24px' }} />
          ) : (
            <i className="material-icons text-sz-xl">{icon.value || icon}</i>
          )}
        </div>
      )}
      <h4 style={{ margin: 0 }}>{title}</h4>
      <div className="flex1 mui-padded-horizontal">
        <div style={{ height: '1px', width: '100%' }} className="bg-primary-light" />
      </div>
      {menu}
    </div>
  );
};

HomeSectionHeader.propTypes = {
  title: PropTypes.any.isRequired,
  menu: PropTypes.any,
  icon: PropTypes.any,
  loading: PropTypes.bool
};

export default HomeSectionHeader;
