import * as C from '../../constants/constants';

export const switchModal = (properties) => {
    return dispatch => {
        dispatch({
            type: C.SWITCH_MODAL,
            payload: properties
        });

        return new Promise(resolve => {
            resolve(properties)
        });
    }
}
