import React from 'react';
import PropTypes from 'prop-types';

import Badge from '../badge/Badge';

import { EProjectType } from '../../constants/enum';

import withIntl from '../withIntl';

const createCompactLabel = t => {
  const parts = t.split(/\s+/);
  let res = '';
  for (let i = 0; i < parts.length; i += 1) {
    const p = parts[i].trim();
    if (p) {
      res += p[0];
    }
  }
  return res;
};

const ProjectTypeBadge = props => {
  const {
    intl,
    compact,
    project: { view, type }
  } = props;
  const ptMessages = intl.messages.components.pages.private.projectEdit.projectTypes;
  const projectTypeMessages = ptMessages[type] || {
    tag: type
  };
  const fullText = `${projectTypeMessages.tag}${
    view && type !== EProjectType.VIEW.key ? ` / ${ptMessages[EProjectType.VIEW.key].tag}` : ''
  }`;
  const label = compact ? createCompactLabel(fullText) : fullText;
  return <Badge className={`project-type__${type}`} title={fullText} text={<span>{label}</span>} />;
};

ProjectTypeBadge.propTypes = {
  project: PropTypes.object.isRequired
};

export default withIntl(ProjectTypeBadge);
