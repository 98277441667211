import { handleActions } from 'redux-actions';

import { loadGridOptions, saveGridsOptions } from '../../utils/utils';
import * as c from '../../constants/constants';
import { EApplicantListSort } from '../../constants/enum';

export const defaultOptions = {
  sort: EApplicantListSort.FINISHED_AT,
  order: 'DESC',
  pageNumber: 0,
  pageSize: 20,
  totalElements: 0,
  selectedApplicants: {}
};

export const defaultState = loadGridOptions() || { applicantsGrid: {} };

export default handleActions(
  {
    [c.GRID_SET_OPTIONS]: (state, action) => {
      const { gridType, gridKey, values, key, value } = action.payload;
      const gridOfType = state[gridType] || {};
      const gridOfKey = gridOfType[gridKey] || {};
      const newOptions = values ? { ...gridOfKey.options, ...values } : { ...gridOfKey.options, [key]: value };
      const newState = {
        [gridType]: {
          ...gridOfType,
          [gridKey]: {
            ...gridOfKey,
            options: newOptions
          }
        }
      };
      saveGridsOptions(newState);
      return newState;
    }
  },
  defaultState
);
