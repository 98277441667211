import { handleActions } from 'redux-actions';
import L from 'lodash';

import * as C from '../../constants/constants';

const defaultState = {
  active: [],
  hidden: []
};

const filter = (l, p) => L.filter(l, i => i.id !== p.id);

export default handleActions(
  {
    [C.NOTIFICATIONS_ADD]: (state, { payload }) => ({
      ...state,
      hidden: filter(state.hidden, payload),
      active: [...filter(state.active, payload), payload]
    }),
    [C.NOTIFICATIONS_DISMISS]: (state, { payload }) => ({ ...state, active: filter(state.active, payload) }),
    [C.NOTIFICATIONS_HIDE]: (state, { payload }) => ({
      ...state,
      active: filter(state.active, payload),
      hidden: [...state.hidden, payload]
    }),
    [C.NOTIFICATIONS_SHOW_HIDDEN]: state => ({ ...state, active: [...state.active, ...state.hidden], hidden: [] }),
    [C.NOTIFICATIONS_HIDE_ACTIVE]: state => ({ ...state, active: [], hidden: [...state.hidden, ...state.active] }),
    [C.LOGOUT]: state => defaultState
  },
  defaultState
);
