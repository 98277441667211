import { handleActions } from 'redux-actions';

import * as c from '../../constants/constants';

export const defaultState = {
  chartActive: 'informationProcessing',
  toggleActive: false,
  mode: 'CHART'
};

export default handleActions(
  {
    [c.CHART_SET_ACTIVE]: (state, { payload }) => {
      return {
        ...state,
        chartActive: payload
      };
    },
    [c.CHART_TOGGLE_EXPAND]: state => {
      return {
        ...state,
        toggleActive: !state.toggleActive
      };
    },
    [c.CHART_SET_MODE]: (state, { payload }) => ({ ...state, mode: payload }),
    [c.CHART_SET_PROPERTY]: (state, { payload: { property, value } }) => ({ ...state, [property]: value })
  },
  defaultState
);
