import React, { useMemo } from 'react';

import EchoGrid from '../analytics/EchoGrid';

const EchoAnalytics = ({ participants, ...props }) => {
  const finished = useMemo(() => {
    const res = [];
    for (let i = 0; i < participants.length; i += 1) {
      const p = participants[i];
      if (p.activity?.finishedAt) {
        res.push(p);
      }
    }
    return res;
  }, [participants]);
  return (
    <div className="echo-analytics">
      <EchoGrid {...props} participants={finished} />
    </div>
  );
};

export default EchoAnalytics;
