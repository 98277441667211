import { handleActions } from 'redux-actions';
import _find from 'lodash/find';

import {
  COMPANY_CURRENT_SET,
  COMPANIES_REFRESH,
  COMPANIES_GET,
  LOGOUT,
  COMPANY_TALENT_MARKET_PLAN_CURRENT_GET,
  COMPANY_TALENT_MARKET_PLAN_CURRENT_SET
} from '../../constants/constants';

import localStorageDecorator from '../../store/localStorageDecorator';
import { getObject } from '../../persistence/storage';

const PERSISTENCE_KEY = '/companies/current';

export const defaultState = getObject(PERSISTENCE_KEY) || {
  item: null,
  talentMarketPlan: null
};

export default handleActions(
  localStorageDecorator(
    {
      [COMPANY_CURRENT_SET]: (state, { payload }) => ({ ...state, item: payload }),
      [COMPANIES_REFRESH]: () => defaultState,
      [LOGOUT]: () => defaultState,
      [COMPANIES_GET]: (state, { payload }) => {
        if (payload) {
          if (payload.length === 1) {
            return { ...state, item: payload[0] };
          }
          // update current
          if (state.item) {
            return { ...state, item: _find(payload, c => c.id === state.item.id) };
          }
        }
        return state;
      },
      [COMPANY_TALENT_MARKET_PLAN_CURRENT_GET.PENDING]: state => ({ ...state, talentMarketPlanLoading: true }),
      [COMPANY_TALENT_MARKET_PLAN_CURRENT_GET.ERROR]: state => ({
        ...state,
        talentMarketPlanLoading: false,
        talentMarketPlanError: true
      }),
      [COMPANY_TALENT_MARKET_PLAN_CURRENT_GET.SUCCESS]: (state, { payload }) => ({
        ...state,
        talentMarketPlan: payload?.payload,
        talentMarketPlanLoading: false,
        talentMarketPlanError: false
      }),
      // if we didn't provide 'lang' param, we wouldn't get a response
      [COMPANY_TALENT_MARKET_PLAN_CURRENT_SET.SUCCESS]: (state, { payload, meta }) =>
        meta.lang
          ? {
              ...state,
              talentMarketPlan: payload?.payload,
              talentMarketPlanLoading: false,
              talentMarketPlanError: false
            }
          : state
    },
    PERSISTENCE_KEY
  ),
  defaultState
);
