import { createAction } from 'redux-actions';

import { getProjects } from '../../api/entities/projectApi';
import { createApiCallAction } from '../apiCallAction';
import * as C from '../../constants/constants';

export const toggleAdvancedGeneralSettings = createAction(
  C.PROJECT_EDIT_ADVANCED_GENERAL_SETTINGS_TOGGLE,
  payload => payload
);

const loadAvailableViewProjectsAction = createApiCallAction(C.PROJECT_EDIT_LOAD_VIEWS, getProjects);
export const loadAvailableViewProjects = () => loadAvailableViewProjectsAction('VIEWS');

const loadAvailableChatProjectsAction = createApiCallAction(C.PROJECT_EDIT_LOAD_CHATS, getProjects);
export const loadAvailableChatProjects = () => loadAvailableChatProjectsAction('CHATS');
