import React from 'react';
import { connect } from 'react-redux';

import UserTags from '../tag/UserTags';

import { getGroupUserCompanyTags } from '../../actions/entities/tagActions';

const GroupUserTags = props => {
  const { userId, tags, group, getGroupUserCompanyTags } = props;
  return (
    <UserTags
      companyId={group?.company?.id}
      userId={userId}
      tags={tags}
      onLoad={() => getGroupUserCompanyTags(group)}
      preventInitialLoad
    />
  );
};

export default connect(null, { getGroupUserCompanyTags })(GroupUserTags);
