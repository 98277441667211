import React from 'react';
import PropTypes from 'prop-types';

const ApplicantTooltip = ({ payload, intl }) => {
  const data = payload.length && payload[0].payload;
  if (!data) {
    //noinspection JSConstructorReturnsPrimitive
    return null;
  }
  return (
    <div>
      <div className="chart__tooltip">
        <div>
          <strong>{data.name}</strong>
        </div>
        <div>
          <span>{intl.messages.filter.score}</span> {data.formattedScore}
        </div>
      </div>
    </div>
  );
};

ApplicantTooltip.propTypes = {
  payload: PropTypes.array,
  intl: PropTypes.object
};

export default ApplicantTooltip;
