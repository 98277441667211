import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import map from 'lodash/map';

import LockedPlaceholder from '../placeholder/LockedPlaceholder';
import MetricIcon from '../icons/Metric';
import ExpanderLabel from '../basic/ExpanderLabel';
import MetricRecommendations from '../applicant/MetricRecommendations';
import MetricChart from './MetricChart';
import MetricValueLabel from './MetricValueLabel';

import { EUpgradeRequestSource } from '../../constants/enum';

const getFactorResponsiveClasses = (length, responsive) => {
  if (!length) {
    return '';
  }
  let res = 'col-xs-12';
  if (length > 1) {
    res += ' col-sm-6';
  }
  if (responsive) {
    return res + ' col-md-12';
  }
  if (length > 2) {
    res += ' col-md-4';
  }
  if (length > 3) {
    res += ' col-lg-3';
  }
  return res;
};

const seq = (from, to) => {
  const r = [];
  for (let i = from; i < to; i += 1) {
    r.push(i);
  }
  return r;
};

const renderMetricHeader = m => (
  <div>
    <MetricIcon metric={m} size={40} style={{ display: 'inline-block', verticalAlign: 'middle', color: m.color }} />
    <strong style={{ verticalAlign: 'middle', fontSize: '0.9375em', marginLeft: 12 }}>
      {m.label}
      <span style={{ color: m.color, marginLeft: 12 }}>
        <MetricValueLabel metric={m} />
      </span>
    </strong>
    <div style={{ borderRadius: 2, overflow: 'hidden', margin: '1em 0' }}>
      <MetricChart metric={m} horizontal style={{ height: 32 }} />
    </div>
  </div>
);

const renderFactorsOperationalization = factors =>
  factors &&
  factors.length > 0 && (
    <div className="metrics-container">
      {map(factors, f => (
        <div key={f.key} className="metric-container">
          <div className="metric-container-content">
            <MetricIcon metric={f} size={24} />
            <span className="metric-label">{f.label}</span>
            <p>{f.operationalization}</p>
          </div>
        </div>
      ))}
    </div>
  );

const renderInterpretationBullets = bullets => {
  if (!bullets || bullets.length === 0) {
    return null;
  }
  return (
    <ul className={'interpretations'}>
      {map(bullets, (bullet, idx) => (
        <li key={idx}>{bullet}</li>
      ))}
    </ul>
  );
};

const renderFactorsChart = (factors, messages, factorResponsiveClasses) =>
  factors &&
  factors.length > 0 && (
    <div className="metrics-container">
      <div className="row" style={{ margin: '0 -0.5em' }}>
        {map(factors, f => {
          const interpretation = f.interpretation || {};
          const { text, bullets, recommendations } = interpretation;
          return (
            <div key={f.key} className={factorResponsiveClasses}>
              <div className="metric-container">
                <div className="metric-container-content">
                  <MetricIcon metric={f} size={24} />
                  <span className="metric-label">
                    {f.label}
                    <span style={{ color: f.color, marginLeft: 12 }}>
                      <MetricValueLabel metric={f} />
                    </span>
                  </span>
                </div>
                <MetricChart metric={f} horizontal />
                {f.description && <p style={{ margin: '16px' }}>{f.description}</p>}
                {text && <p style={{ margin: '16px' }} dangerouslySetInnerHTML={{ __html: text }} />}
                {renderInterpretationBullets(bullets)}
                <MetricRecommendations {...recommendations} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

const Competency = ({
  competency,
  factors,
  headerShown,
  operationalizationShown,
  toggleOperationalization,
  messages,
  descriptionEnabled,
  invokeUpgradeAccountModal,
  responsive
}) => {
  const { interpretation } = competency;
  const factorResponsiveClasses = getFactorResponsiveClasses(factors ? factors.length : 0, responsive);
  const interpretationText = interpretation && interpretation.text;
  const interpretationBullets = interpretation && interpretation.bullets;
  return (
    <div className={responsive ? 'col-xs-12 col-md-6' : ''}>
      <div className={'legend clearfix'} style={{ borderTop: headerShown ? 'none' : `2px solid ${competency.color}` }}>
        {headerShown ? (
          renderMetricHeader(competency)
        ) : (
          <h3 className="mui-padded-horizontal" style={{ margin: '8px 0 12px', fontSize: '1.25em' }}>
            {competency.label}
            <span style={{ color: competency.color, marginLeft: 12, fontSize: '80%' }}>
              <MetricValueLabel metric={competency} />
            </span>
          </h3>
        )}
        {!descriptionEnabled && (
          <div className="chart-locked-placeholder-container">
            <LockedPlaceholder
              message={messages.lockedSkillsDescriptionMessage}
              upgradeAccount={invokeUpgradeAccountModal}
              source={EUpgradeRequestSource.COMPETENCY_DESCRIPTION}
            />
          </div>
        )}
        {descriptionEnabled && (
          <div className="mui-padded-horizontal">
            {competency.description && <p>{competency.description}</p>}
            {interpretationText && <p dangerouslySetInnerHTML={{ __html: interpretationText }} />}
            {renderInterpretationBullets(interpretationBullets)}
            <MetricRecommendations {...(interpretation && interpretation.recommendations)} />
            <div style={{ margin: '0 -8px' }}>{renderFactorsChart(factors, messages, factorResponsiveClasses)}</div>
            {competency.operationalization && (
              <h4>
                <ExpanderLabel
                  label={messages.operationalization}
                  expanded={operationalizationShown}
                  toggleExpand={toggleOperationalization}
                />
              </h4>
            )}
            {operationalizationShown && competency.operationalization && (
              <div>
                <p>{competency.operationalization}</p>
                {renderFactorsOperationalization(factors)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const calcWidthRatios = (coreLength, otherLength) => {
  return {
    core: 3 / (3 * coreLength + 2 * otherLength),
    other: 2 / (3 * coreLength + 2 * otherLength)
  };
};

const renderSectionHeader = (
  widthRatio,
  core,
  { messages, setChartProperty, otherCompetenciesCollapsed, collapsible }
) => {
  const collapsed = !core && collapsible && otherCompetenciesCollapsed;
  const style = {
    display: 'inline-block',
    padding: '0 2px'
  };
  if (collapsed) {
    style.position = 'absolute';
    style.right = 0;
    style.top = 0;
  } else {
    style.width = `${100 * widthRatio}%`;
  }
  return (
    <div style={style}>
      <div className="container-flex-row ai-center fw-yes" style={{ padding: '0 16px' }}>
        <div className="mui-label" style={{ margin: '8px 0', fontSize: '12px' }}>
          {core ? messages.coreCompetenciesLabel : messages.otherCompetenciesLabel}
        </div>
        {!core && <span style={{ margin: '0 8px' }}>&middot;</span>}
        {!core && (
          <a
            role="button"
            className="link-button"
            onClick={() => setChartProperty('otherCompetenciesCollapsed', !otherCompetenciesCollapsed)}
            style={{ marginBottom: '2px' }}
          >
            <span>{otherCompetenciesCollapsed ? messages.showLabel : messages.hideLabel}</span>
          </a>
        )}
      </div>
      {!collapsed && <div className={`bg-primary${core ? '' : '-light'}`} style={{ height: '4px' }} />}
    </div>
  );
};

const renderCompetencyListItem = (competency, competencyFactorsMap, commonProps) => {
  if (!competency) {
    return null;
  }
  return <Competency competency={competency} factors={competencyFactorsMap[competency.key]} {...commonProps} />;
};

const Chart = props => {
  const { chart, intl, competencies, otherCompetencies, competencyFactors, descriptionEnabled } = props;
  const { setActiveChart, setToggleExpanded, setChartProperty, invokeUpgradeAccountModal } = props;
  const { otherCompetenciesCollapsed } = chart;
  const data = otherCompetenciesCollapsed ? competencies : [...competencies, ...otherCompetencies];
  const messages = intl.messages.components.pages.private.project.applicantDetail;
  const activeCompetency = find(data, s => s.key === chart.chartActive) || (data && data.length > 0 && data[0]);
  const { mode } = chart;
  if (mode === 'LIST') {
    const commonProps = {
      messages,
      headerShown: true,
      responsive: true,
      operationalizationShown: chart.toggleActive,
      toggleOperationalization: setToggleExpanded,
      descriptionEnabled,
      invokeUpgradeAccountModal
    };
    const showHeaders = competencies.length > 0 && otherCompetencies.length > 0;
    return (
      <div className="legends">
        {showHeaders && renderSectionHeader(1, true, { messages })}
        {map(seq(0, competencies.length / 2), rowIdx => (
          <div key={rowIdx} className="row" style={{ marginTop: '8px' }}>
            {renderCompetencyListItem(competencies[rowIdx * 2], competencyFactors, commonProps)}
            {renderCompetencyListItem(competencies[rowIdx * 2 + 1], competencyFactors, commonProps)}
          </div>
        ))}
        {showHeaders && renderSectionHeader(1, false, { messages, setChartProperty, otherCompetenciesCollapsed })}
        {!otherCompetenciesCollapsed &&
          map(seq(0, otherCompetencies.length / 2), rowIdx => (
            <div key={rowIdx} className="row" style={{ marginTop: '8px' }}>
              {renderCompetencyListItem(otherCompetencies[rowIdx * 2], competencyFactors, commonProps)}
              {renderCompetencyListItem(otherCompetencies[rowIdx * 2 + 1], competencyFactors, commonProps)}
            </div>
          ))}
      </div>
    );
  }
  const widthRatios = calcWidthRatios(competencies.length, otherCompetenciesCollapsed ? 0 : otherCompetencies.length);
  return (
    <div>
      <div style={{ overflow: 'auto' }}>
        <div style={{ minWidth: 68 * (competencies.length + otherCompetencies.length) }}>
          {competencies.length > 0 && otherCompetencies.length > 0 && (
            <div className="relative-container">
              {renderSectionHeader(widthRatios.core * competencies.length, true, { messages })}
              {renderSectionHeader(widthRatios.other * otherCompetencies.length, false, {
                messages,
                setChartProperty,
                otherCompetenciesCollapsed,
                collapsible: true
              })}
            </div>
          )}
          <div style={{ padding: '42px 16px 0' }}>
            {map(data, (metric, idx) => {
              const active = metric === activeCompetency;
              const labelStyle = {
                backgroundColor: active ? metric.color : 'transparent',
                color: active ? 'white' : metric.color
              };

              const core = idx < competencies.length;
              return (
                <MetricChart
                  metric={metric}
                  style={{ width: `${100 * (core ? widthRatios.core : widthRatios.other)}%`, opacity: core ? 1 : 0.7 }}
                  key={metric.key}
                >
                  <div className={`icon-wrapper${active ? ' active' : ''}`} onClick={() => setActiveChart(metric.key)}>
                    <MetricIcon
                      metric={metric}
                      size={40}
                      style={{ margin: '15px 0', display: 'inline-block', color: metric.color }}
                    />
                    <div style={{ ...labelStyle, height: '4px', width: '100%' }} />
                  </div>
                </MetricChart>
              );
            })}
          </div>
        </div>
      </div>
      {activeCompetency && (
        <Competency
          competency={activeCompetency}
          factors={competencyFactors[activeCompetency.key]}
          messages={messages}
          operationalizationShown={chart.toggleActive}
          toggleOperationalization={setToggleExpanded}
          descriptionEnabled={descriptionEnabled}
          invokeUpgradeAccountModal={invokeUpgradeAccountModal}
        />
      )}
    </div>
  );
};

Chart.propTypes = {
  isSten: PropTypes.bool,
  intl: PropTypes.object,
  data: PropTypes.array,
  chart: PropTypes.object,
  descriptionEnabled: PropTypes.bool,
  invokeUpgradeAccountModal: PropTypes.func,
  setActiveChart: PropTypes.func,
  setToggleExpanded: PropTypes.func
};

export default Chart;
