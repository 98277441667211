import React from 'react';
import { connect } from 'react-redux';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';

import TeamSubGroupConfig from './TeamSubGroupConfig';
import Button from '../mui/Button';

import { EGroupMembership, EGroupType } from '../../constants/enum';
import { getActivityMapKey, getDefaultActivityConfiguration } from './TeamUtils';

import { selectActivityTemplates } from './teamActivityTemplatesSelector';
import ProjectForm from '../forms/ProjectForm';
import Overlay from '../basic/Overlay';

const TeamConfigPart = props => {
  const { config, type, templates, groupType, messages, team } = props;
  const {
    onPrimaryLanguageChange,
    onOtherLanguageToggle,
    onActivityToggle,
    onProjectsNameChange,
    onProjectEdit
  } = props;
  const id = `teamConfigPart${type.key}`;
  const titleMessages = messages.titles[type.key] || {};
  const title = titleMessages.variants
    ? titleMessages.variants[groupType.key] || titleMessages.title
    : titleMessages.title;
  return (
    <div id={id} className="team__subgroup-panel configuring">
      <div className={`team__subgroup-header mui-padded`}>
        <div className="team__subgroup-header-deco" />
        <h4>{title}</h4>
      </div>
      <div className="mui-padded-horizontal">
        <TeamSubGroupConfig
          team={team}
          type={type}
          config={config}
          templates={templates}
          onPrimaryLanguageChange={val => onPrimaryLanguageChange(type, val)}
          onOtherLanguageToggle={(l, enable) => onOtherLanguageToggle(type, l, enable)}
          onActivityToggle={(a, enable) => onActivityToggle(type, a, enable)}
          onProjectsNameChange={e => onProjectsNameChange(type, e.target.value)}
          onProjectEdit={onProjectEdit}
        />
      </div>
    </div>
  );
};

const toFinalConfig = (config, initialConfig) => {
  const { activities, languages, primaryLanguage, projectsName } = config;
  const projectsNameTrimmed = projectsName && projectsName.trim();

  const pl = primaryLanguage && primaryLanguage[0];
  const languageList = [];
  if (pl) {
    languageList.push(pl);
  }
  for (let lang in languages) {
    if (languages.hasOwnProperty(lang)) {
      if (pl && pl !== lang) {
        languageList.push(lang);
      }
    }
  }

  const activityList = [];
  for (let aKey in activities) {
    if (activities.hasOwnProperty(aKey)) {
      const a = activities[aKey];
      const activityConfig = {
        key: a.key,
        variant: a.variant,
        languages: languageList
      };
      if (projectsNameTrimmed) {
        activityConfig.projectConfig = { name: projectsNameTrimmed };
      }
      activityList.push(activityConfig);
    }
  }

  return {
    ...initialConfig,
    activities: activityList
  };
};

const toActivityMap = list =>
  _reduce(
    list,
    (res, a) => {
      res[getActivityMapKey(a)] = a;
      return res;
    },
    {}
  );

const getInitialConfig = (config, type, templates, locale) => {
  const defaultLanguages = locale === 'en' ? [locale] : [locale, 'en'];
  const configCandidate = config || getDefaultActivityConfiguration(type, defaultLanguages);

  const tMap = toActivityMap(templates);

  const filteredActivities = _filter(configCandidate.activities || [], a => !!tMap[getActivityMapKey(a)]);
  const activities = toActivityMap(filteredActivities);

  const anyActivity = filteredActivities[0];
  const languagesList = (anyActivity && anyActivity.languages) || defaultLanguages;
  const languages = _reduce(
    languagesList,
    (res, l) => {
      res[l] = true;
      return res;
    },
    {}
  );
  const projectsName = anyActivity?.projectConfig?.name;

  return { activities, languages, primaryLanguage: [languagesList[0]], initialActivities: activities, projectsName };
};

const EMPTY_CONFIG = {
  activities: {},
  languages: {},
  primaryLanguage: ['cs'],
  initialActivities: {}
};

class TeamConfig extends React.Component {
  state = {
    managerConfig: EMPTY_CONFIG,
    membersConfig: EMPTY_CONFIG
  };

  componentDidMount() {
    this.initConfigs();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.team !== this.props.team ||
      prevProps.activityConfigurations !== this.props.activityConfigurations ||
      prevProps.templates !== this.props.templates
    ) {
      this.initConfigs();
    }
  }

  initConfigs = () => {
    const {
      activityConfigurations,
      templates,
      intl: { locale }
    } = this.props;
    const managerConfig = getInitialConfig(
      (activityConfigurations || {})[EGroupMembership.MANAGER.key],
      EGroupMembership.MANAGER,
      templates,
      locale
    );
    const membersConfig = getInitialConfig(
      (activityConfigurations || {})[EGroupMembership.MEMBER.key],
      EGroupMembership.MEMBER,
      templates,
      locale
    );
    this.setState({ managerConfig, membersConfig });
  };

  alterConfig = (type, fn) => {
    if (type === EGroupMembership.MEMBER) {
      this.setState({ membersConfig: fn(this.state.membersConfig) });
    } else if (type === EGroupMembership.MANAGER) {
      this.setState({ managerConfig: fn(this.state.managerConfig) });
    }
  };

  handlePrimaryLanguageChange = (type, value) => this.alterConfig(type, cfg => ({ ...cfg, primaryLanguage: value }));
  handleOtherLanguageToggle = (type, language, enable) =>
    this.alterConfig(type, cfg => {
      const {
        languages: { [language.value]: enabled, ...languages }
      } = cfg;
      if (enable) {
        languages[language.value] = true;
      }
      return { ...cfg, languages: languages };
    });
  handleActivityToggle = (type, activity, enable) =>
    this.alterConfig(type, cfg => {
      const aKey = getActivityMapKey(activity);
      const {
        activities: { [aKey]: enabled, ...activities },
        initialActivities: { [aKey]: initial }
      } = cfg;
      if (enable) {
        activities[aKey] = initial || activity;
      }
      return { ...cfg, activities };
    });
  handleProjectsNameChange = (type, value) => this.alterConfig(type, cfg => ({ ...cfg, projectsName: value }));

  handleSave = () => {
    const { type, activityConfigurations, onChange } = this.props;
    const { managerConfig, membersConfig } = this.state;
    const configs = {
      [EGroupMembership.MEMBER.key]: toFinalConfig(
        membersConfig,
        (activityConfigurations || {})[EGroupMembership.MEMBER.key]
      )
    };
    if (type === EGroupType.TEAM) {
      configs[EGroupMembership.MANAGER.key] = toFinalConfig(
        managerConfig,
        (activityConfigurations || {})[EGroupMembership.MANAGER.key]
      );
    }
    onChange(configs);
  };

  handleProjectEditStart = p => this.setState({ editingProject: p });
  handleProjectEditStop = () => this.setState({ editingProject: null });

  render() {
    const { intl, type, team, templates } = this.props;
    const { managerConfig, membersConfig, editingProject } = this.state;
    const messages = intl.messages.components.teams.subGroup;
    const commonProps = {
      team,
      templates,
      messages,
      groupType: type,
      onPrimaryLanguageChange: this.handlePrimaryLanguageChange,
      onOtherLanguageToggle: this.handleOtherLanguageToggle,
      onActivityToggle: this.handleActivityToggle,
      onProjectsNameChange: this.handleProjectsNameChange,
      onProjectEdit: this.handleProjectEditStart
    };
    return (
      <div className="mui-card">
        <div className="mui-padded-vertical">
          {type === EGroupType.TEAM && (
            <div className={`team__subgroup ${EGroupMembership.MANAGER.key}`}>
              <TeamConfigPart {...commonProps} type={EGroupMembership.MANAGER} config={managerConfig} />
            </div>
          )}
          <div className={`team__subgroup ${EGroupMembership.MEMBER.key}`}>
            <TeamConfigPart {...commonProps} type={EGroupMembership.MEMBER} config={membersConfig} />
          </div>
        </div>
        <div className="mui-padded-half container-flex-row fw-yes border-top border-color-primary__50 bg-primary-light jc-flex-end">
          {/*{!isFirst && (*/}
          {/*  <div className="mui-padded-half">*/}
          {/*    <Button label={messages.cancelLabel} onClick={this.handleCancel} />*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className="mui-padded-half">
            <Button
              label={messages.saveLabel}
              icon={<i className="material-icons text-white">save</i>}
              onClick={this.handleSave}
              primary
              raised
            />
          </div>
        </div>
        {editingProject && (
          <Overlay onRequestClose={this.handleProjectEditStop} maxWidth={768} noBlur>
            <div className="mui-card">
              <ProjectForm
                companyId={team.company.id}
                projectId={editingProject.id}
                onClose={this.handleProjectEditStop}
              />
            </div>
          </Overlay>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { intl } = state;
  return {
    intl,
    templates: selectActivityTemplates(state, ownProps).list
  };
};

export default connect(mapStateToProps)(TeamConfig);
