import React from 'react';
import reduce from 'lodash/reduce';
import map from 'lodash/map';

import Chip from '../../mui/IconChip';

import { formatMessage } from '../../../utils/utils';

const renderParticipants = arr => (
  <div style={{ margin: '0 -4px', padding: '0 0 4px' }}>
    {map(arr, ({ email }) => (
      <Chip style={{ margin: '4px' }} key={email}>
        {email}
      </Chip>
    ))}
  </div>
);

const InvitationSentContent = props => {
  const { messages, results } = props;
  const { inviteMore } = props;
  const res = reduce(
    results || [],
    ({ sent, notSent }, r) => {
      if (r.resultType === 'PROJECT_EXISTS') {
        return {
          sent,
          notSent: [...notSent, r]
        };
      } else {
        return {
          sent: [...sent, r],
          notSent
        };
      }
    },
    { sent: [], notSent: [] }
  );
  return (
    <div>
      {res.sent.length > 0 ? (
        <div>
          <div style={{ marginBottom: '8px' }}>
            <small>{messages.invitationSent.invitedParticipantsLabel}</small>
            {renderParticipants(res.sent)}
          </div>
          {res.notSent.length > 0 && (
            <div style={{ marginBottom: '8px' }}>
              <small>{messages.invitationSent.alreadyExistingParticipantsLabel}</small>
              {renderParticipants(res.notSent)}
            </div>
          )}
        </div>
      ) : (
        <p>{messages.invitationSent.noParticipantsInvitedText}</p>
      )}
      <p style={{ marginTop: '16px' }}>
        {formatMessage(messages.invitationSent.inviteMoreText, (idx, val) => (
          <a key={idx} role="button" onClick={inviteMore}>
            {val}
          </a>
        ))}
      </p>
    </div>
  );
};

export default InvitationSentContent;
