import { handleActions } from 'redux-actions';
import L from 'lodash';

import * as c from '../../constants/constants';

export const defaultState = [];

export default handleActions({
    [c.SNACKBAR_SHOW]: (state, { payload }) => ([...L.filter(state, s => s.id !== payload.id), payload]),
    [c.SNACKBAR_HIDE]: (state, { payload: { id } }) => L.filter(state, s => s.id !== id)
}, defaultState);
