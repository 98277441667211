import React from 'react';

const MultiColorDonut = props => {
  const { size, colors, x, y, withinSvg } = props;
  const options = props.options || {};
  const borderWidth = options.borderWidth || 0;
  const borderPadding = options.borderPadding || 0;
  const padding = options.padding || 0;
  //
  const r = (size - borderWidth) / 2 - borderPadding;
  const circleLength = Math.PI * (r * 2);
  const circles = [];
  if (borderPadding) {
    circles.push(
      <circle
        key={`colorBase`}
        cx={size / 2}
        cy={size / 2}
        r={r}
        style={{
          stroke: 'rgba(255,255,255,0.8)',
          strokeWidth: borderWidth ? borderWidth + 2 * borderPadding : null,
          fill: 'none'
        }}
      />
    );
  }
  const fragmentLength = circleLength / colors.length;
  const defs = [];
  for (let i = 0; i < colors.length; i += 1) {
    const offset = fragmentLength * i;
    const c = colors[i];
    const gradientId = `gradientFill${Math.random().toString(36).substring(2)}`;
    circles.push(
      <circle
        key={`color-${i}`}
        cx={size / 2}
        cy={size / 2}
        r={r}
        style={{
          stroke: `url(#${gradientId})`,
          strokeWidth: borderWidth ? borderWidth : null,
          fill: 'none',
          strokeDasharray: `${fragmentLength - 2 * padding} ${circleLength - fragmentLength + 2 * padding}`,
          strokeDashoffset: `${-(offset + padding)}`
        }}
      />
    );
    defs.push(
      <linearGradient key={gradientId} id={gradientId} x1={0} x2={0} y1={0} y2={4}>
        <stop offset="0%" stopColor={c} />
        <stop offset="100%" stopColor={'rgba(255, 255, 255, 0)'} />
      </linearGradient>
    )
  }
  if (withinSvg) {
    return <g x={x} y={y} width={size} height={size}><defs>{defs}</defs>{circles}</g>
  }
  return (
    <svg width={size} height={size} x={x} y={y}>
      <defs>{defs}</defs>
      {circles}
    </svg>
  );
};

export default MultiColorDonut;
