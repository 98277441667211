export const ETab = {
  OVERVIEW: {
    key: 'OVERVIEW',
    icon: 'offline_bolt'
  },
  INSIGHTS: {
    key: 'INSIGHTS',
    icon: 'insights'
  },
  LIST: {
    key: 'LIST',
    icon: 'view_list'
  },
  ANALYTICS: {
    key: 'ANALYTICS',
    icon: 'insert_chart'
  },
  WELL_BEING: {
    key: 'WELL_BEING',
    icon: 'self_improvement'
  }
};
