import React, { useRef, useState } from 'react';
import _map from 'lodash/map';
import _find from 'lodash/find';

import MenuItem from 'material-ui/MenuItem';
import Menu from 'material-ui/Menu';

import Button from '../mui/Button';
import Popover from '../mui/Popover';

import { ESortOrder } from '../../constants/enum';

import withIntl from '../withIntl';

const SortSelect = props => {
  const { dataSource, property, order, onChange, intl: { messages } } = props;
  const [menuShown, showMenu] = useState(false);
  const anchorElement = useRef(null);
  const orderIconValue = order === ESortOrder.ASC ? 'arrow_upward' : 'arrow_downward';
  const reversedOrder = order === ESortOrder.ASC ? ESortOrder.DESC : ESortOrder.ASC;
  const selectedItem = _find(dataSource, ds => ds.value === property);
  return (
    <div ref={anchorElement}>
      <Button
        mini
        onClick={() => showMenu(!menuShown)}
        label={selectedItem ? selectedItem.label : <em className="text-muted">{messages.chooseLabel}</em>}
        style={{ minWidth: '32px' }}
        labelStyle={{ padding: '12px' }}
      />
      <Button
        mini
        icon={<i className="material-icons text-sz-md">{orderIconValue}</i>}
        onClick={() => onChange(property, reversedOrder)}
        style={{ minWidth: '32px' }}
        disabled={!selectedItem}
      />
      <Popover anchorEl={anchorElement?.current} open={menuShown} onRequestClose={() => showMenu(false)}>
        <Menu desktop onItemClick={() => showMenu(false)}>
          {_map(dataSource, ds => (
            <MenuItem
              key={ds.value}
              primaryText={
                <div className="container-flex-row ai-center">
                  <div style={{ width: '32px' }}>
                    {ds.value === property ? <i className="material-icons text-sz-lg">{orderIconValue}</i> : undefined}
                  </div>
                  <div className="mui-padded-vertical">
                    <div style={{ lineHeight: '1.4em' }}>{ds.label}</div>
                    {ds.description && (
                      <div style={{ lineHeight: '1.4em' }} className="small">
                        {ds.description}
                      </div>
                    )}
                  </div>
                </div>
              }
              onClick={() => onChange(ds.value, ds.value === property ? reversedOrder : ESortOrder.DESC)}
            />
          ))}
        </Menu>
      </Popover>
    </div>
  );
};

const intlMessages = {
  cs: {
    chooseLabel: 'Vyberte'
  },
  sk: {
    chooseLabel: 'Vyberte'
  },
  en: {
    chooseLabel: 'Choose'
  }
};

export default withIntl(SortSelect, intlMessages);
