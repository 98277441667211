import React from 'react';
import PropTypes from 'prop-types';

import SimpleModal from './SimpleModal';

const EmailSentModal = props => {
  const { intl, opened, onModalClose } = props;
  const messages = intl.messages.components.modals.emailSent;

  return (
    <SimpleModal open={opened} message={messages.text} cancelLabel={messages.redirect} switchModal={onModalClose} />
  );
};

EmailSentModal.propTypes = {
  modal: PropTypes.object,
  intl: PropTypes.object,
  opened: PropTypes.bool,
  passwordReset: PropTypes.func,
  updateLocale: PropTypes.func,
  onModalClose: PropTypes.func
};

export default EmailSentModal;
