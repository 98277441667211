import { createSelector } from 'reselect';
import reduce from 'lodash/reduce';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';

import { basicSelectCurrentCompany } from './base';
import { getOpportunityTypeProps } from '../utils/opportunity';
import { ESortOrder, EOpportunityParticipantStatus, OPPORTUNITY_PARTICIPANT_STATUSES } from '../constants/enum';

const basicSelectOpportunities = state => state.entities.opportunities;
const basicSelectOpportunity = state => state.entities.opportunity;
const basicSelectIntl = state => state.intl;

const getDisplayStatus = (post, when) => {
  const from = post.displayedFrom;
  const to = post.displayedTo;
  if (from && from > when) {
    return {
      status: 1,
      timestamp: from
    };
  }
  if (to && to < when) {
    return {
      status: 2,
      timestamp: to
    };
  }
  return {
    status: 0,
    timestamp: from || post.createdAt || when
  };
};

const getPost = opp => (opp && opp.posts && opp.posts[0]) || {};

const createDisplayComparator = () => {
  const now = Date.now();
  return (a, b) => {
    const dsa = getDisplayStatus(getPost(a), now);
    const dsb = getDisplayStatus(getPost(b), now);

    const numA = dsa.status !== dsb.status ? dsa.status : -dsa.timestamp;
    const numB = dsa.status !== dsb.status ? dsb.status : -dsb.timestamp;
    return numA < numB ? -1 : numA > numB ? 1 : 0;
  };
};

const createFullTextMatcher = fullText => {
  if (!fullText) {
    return () => true;
  }
  const flt = fullText.toLowerCase();
  return {
    matches: value => value && value.toLowerCase().indexOf(flt) > -1
  };
};

export const selectFilteredOpportunities = createSelector(
  [basicSelectCurrentCompany, basicSelectOpportunities, basicSelectIntl],
  (currentCompany, opportunities, intl) => {
    const { filter } = opportunities;
    const allOpportunities = currentCompany
      ? _filter(opportunities.items, opp => opp.company && currentCompany.id === opp.company.id)
      : opportunities.items;
    const fullTextMatcher = filter && filter.fullText ? createFullTextMatcher(filter.fullText) : null;
    const items = fullTextMatcher
      ? _filter(allOpportunities, i => {
          const p = (i.posts && i.posts[0]) || {};
          return (
            fullTextMatcher.matches(p.title) ||
            fullTextMatcher.matches(p.description) ||
            (i.company && fullTextMatcher.matches(i.company.name)) ||
            fullTextMatcher.matches((intl.messages.constants.enums.EOpportunityType[i.type] || {}).label)
          );
        })
      : allOpportunities;
    const unpublishedOpportunities = reduce(
      allOpportunities,
      (res, opp) => {
        if (!getPost(opp).publishedAt) {
          res.count += 1;
          if (!res.first) {
            res.first = opp;
          }
        }
        return res;
      },
      { count: 0, first: null }
    );
    return {
      ...opportunities,
      totalCount: allOpportunities.length,
      publishedCount: allOpportunities.length - unpublishedOpportunities.count,
      firstUnpublishedOpportunity: unpublishedOpportunities.first,
      items
    };
  }
);

export const selectOpportunitiesSplitByRegistrable = createSelector([selectFilteredOpportunities], opportunities => {
  const items = _map(opportunities.items, item => ({ ...item, typeProps: getOpportunityTypeProps(item.type) })).sort(
    createDisplayComparator()
  );
  return {
    ...opportunities,
    items,
    split: reduce(
      items,
      (res, item) => {
        if (item.typeProps.registrable) {
          res.registrable.push(item);
        } else {
          res.rest.push(item);
        }
        return res;
      },
      { registrable: [], rest: [] }
    )
  };
});

const determineStatus = p => {
  for (let prop in EOpportunityParticipantStatus) {
    if (EOpportunityParticipantStatus.hasOwnProperty(prop)) {
      const e = EOpportunityParticipantStatus[prop];
      if (e.matches(p)) {
        return e;
      }
    }
  }
  return EOpportunityParticipantStatus.UNPROCESSED;
};

const selectOpportunityParticipants = createSelector([basicSelectOpportunity], o => {
  const { showRejected } = o;
  const p = (o.item && o.item.participants) || [];
  const itemsByStatus = _groupBy(p, p => determineStatus(p).key);
  const items = [];
  const counts = {};
  let totalCount = 0;
  for (let i = 0; i < OPPORTUNITY_PARTICIPANT_STATUSES.length; i += 1) {
    const s = OPPORTUNITY_PARTICIPANT_STATUSES[i];
    const statusItems = itemsByStatus[s.key] || [];
    const count = statusItems.length;
    totalCount += count;
    counts[s.key] = count;
    if (count > 0 && (showRejected || s !== EOpportunityParticipantStatus.REJECTED)) {
      items.push({ statusKey: s.key, values: statusItems });
    }
  }
  return {
    items,
    counts,
    totalCount
  };
});

const selectOpportunityParticipantsSort = createSelector([basicSelectOpportunity], o => o.sort);

const createSortFunction = (properties, coefficient) => (a, b) => {
  for (let i = 0; i < properties.length; i += 1) {
    const prop = properties[i];
    const aVal = _get(a, prop);
    const bVal = _get(b, prop);
    const same = (aVal == null && bVal == null) || aVal === bVal;
    if (!same) {
      if (aVal == null) {
        return 1;
      }
      if (bVal == null) {
        return -1;
      }
      if (aVal < bVal) {
        return coefficient * -1;
      }
      return coefficient;
    }
  }
  return 0;
};

export const selectSortedOpportunityParticipants = createSelector(
  [selectOpportunityParticipants, selectOpportunityParticipantsSort],
  (p, s) => {
    const { properties, order } = s;
    if (!properties || !order) {
      return p;
    }
    const sortProperties = [...properties, 'id'];
    const sortOrderCoefficient = order === ESortOrder.ASC ? 1 : -1;
    const sortFunction = createSortFunction(sortProperties, sortOrderCoefficient);
    const items = _map(p.items, i => ({ ...i, values: i.values.sort(sortFunction) }));
    return {
      ...p,
      items
    };
  }
);
