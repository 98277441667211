import React, { useCallback, useState } from 'react';
import _map from 'lodash/map';

import Text from '../superfield/SuperText';
import Button from '../mui/Button';

import { isEmail } from '../forms/Validator';
import * as Api from '../../api/entities/groupApi';

import withIntl from '../withIntl';

const TeamShareDialog = ({ team, onClose, intl: { messages } }) => {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = useCallback(e => setEmail(e.target.value), [setEmail]);
  const handleConfirm = () => {
    setLoading(true);
    Api.shareGroup(team.id, { items: [{ email }] })
      .then(res => setResult(res.payload))
      .finally(() => setLoading(false));
  };
  const handleAgain = useCallback(() => {
    setResult(null);
    setEmail('');
  }, [setResult, setEmail]);

  const isEmailValid = isEmail(email);

  return (
    <div>
      <div className="mui-padded-x2">
        {result ? (
          <div>
            {_map(result?.items || [], (item, idx) => (
              <div key={idx}>
                <h6>{messages.emailLabel}</h6>
                <pre>{item.email}</pre>
                <h6>{messages.passwordLabel}</h6>
                <pre>{item.password ? item.password : <em>{messages.noPasswordLabel}</em>}</pre>
              </div>
            ))}
            <a role="button" className="link-button" onClick={handleAgain}>
              {messages.againLabel}
            </a>
          </div>
        ) : (
          <Text
            label={messages.emailLabel}
            value={email}
            onChange={handleEmailChange}
            helperText={messages.emailHelperText}
          />
        )}
      </div>
      <div className="mui-padded-half container-flex-row fw-yes jc-flex-end">
        <div className="mui-padded-half">
          <Button label={messages.cancelLabel} onClick={onClose} disabled={loading} />
        </div>
        <div className="mui-padded-half">
          <Button
            label={messages.confirmLabel}
            onClick={handleConfirm}
            disabled={!isEmailValid || loading || !!result}
            primary
          />
        </div>
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    emailLabel: 'E-mail',
    emailHelperText: 'Zadejte e-mail uživatele, kterému chcete skupinu sdílet',
    passwordLabel: 'Vygenerované heslo',
    noPasswordLabel: 'Žádné - uživatel již existuje',
    cancelLabel: 'Zavřít',
    confirmLabel: 'Sdílet',
    againLabel: 'Přidat dalšího uživatele'
  }
};

export default withIntl(TeamShareDialog, intlMessages);
