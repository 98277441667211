import { authRequest } from '../Request.js';
import { API_URL } from '../../config';

export function getEapiKeys(companyId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/admin/companies/${companyId}/eapi_keys`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function createEapiKey(companyId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .post(`${API_URL}/api/v1/admin/companies/${companyId}/eapi_keys`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}

export function deleteEapiKey(companyId, keyId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .delete(`${API_URL}/api/v1/admin/companies/${companyId}/eapi_keys/${keyId}`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
