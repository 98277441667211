import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import { EProjectState } from '../../constants/enum';

const formatDate = (d, locale) =>
  d
    ? moment(d)
        .locale(locale)
        .format('L')
    : null;

const getValidityText = (validFrom, validTo, messages) => {
  if (validFrom) {
    return validTo ? `${validFrom} - ${validTo}` : `${messages.from} ${validFrom}`;
  }
  return validTo ? `${messages.to} ${validTo}` : messages.noRestriction;
};

const ProjectCardStatus = props => {
  const { project: { validity, activity: { latestProfileAddedAt } }, intl, style, compact } = props;
  const messages = intl.messages.components.cards.project;
  const validityText = getValidityText(
    formatDate(validity.from, intl.locale),
    formatDate(validity.to, intl.locale),
    messages
  );
  const projectState = EProjectState[validity.state];
  const stateLabel = intl.messages.project[projectState.key.toLowerCase()].card;
  return (
    <div className={cn({ 'project-card-status': true, compact })} style={style}>
      <div style={{ color: projectState.color }}>
        <div className="project-card-status-content">
          <span>{stateLabel}</span> <small>({validityText})</small>
        </div>
      </div>
      {latestProfileAddedAt && (
          <small>
            {messages.latestProfileLabel}:{' '}
            {moment(latestProfileAddedAt)
              .locale(intl.locale)
              .calendar()}
          </small>
        )}
    </div>
  );
};

ProjectCardStatus.propTypes = {
  project: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  style: PropTypes.object
};

export default ProjectCardStatus;
