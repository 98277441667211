import React from 'react';
import cn from 'classnames';

const MIN_FONT_SIZE = 6;

const getStyle = (custom, size = 24, color, style = {}) => {
  if (custom) {
    return {
      width: size,
      height: size,
      ...style
    };
  }
  return {
    fontSize: size,
    color,
    ...style
  };
};

const getCustomIconStyle = (size = 24, color) => {
  return {
    fontSize: size - 4,
    lineHeight: `${size - 4}px`,
    padding: 2,
    width: size,
    height: size,
    color: 'white',
    backgroundColor: color
  };
};

const getFallbackStyle = (size = 24, color) => {
  const effectiveSize = size;
  const fontSize = Math.max(MIN_FONT_SIZE, Math.round(effectiveSize / 3));
  const paddingBottom = Math.floor((effectiveSize - fontSize) / 2);
  return {
    ...getCustomIconStyle(size, color),
    fontSize,
    lineHeight: `${fontSize}px`,
    paddingBottom,
    paddingTop: Math.round(effectiveSize - fontSize - paddingBottom)
  };
};

export default ({ symbol, fallback, size, color, className, style, ...props }) => (
  <i
    className={cn({
      'competency-icon': symbol,
      'native': typeof symbol === 'string',
      'competency-icon-fallback': !symbol,
      [className]: className
    })}
    style={getStyle(!symbol || symbol.mdi, size, color, style)}
    {...props}
  >
    {symbol ? (
      symbol.mdi ? (
        <span className={`mdi mdi-${symbol.mdi}`} style={getCustomIconStyle(size, color)} />
      ) : (
        symbol
      )
    ) : (
      <i className="competency-icon-fallback-content" style={getFallbackStyle(size, color)}>
        {fallback}
      </i>
    )}
  </i>
);
