import React from 'react';
import PropTypes from 'prop-types';

import Popover from '../mui/Popover';
import TagAssignmentPanel from './TagAssignmentPanel';

class TagAssignmentPopover extends React.Component {
  state = {
    contentWidth: 0,
    contentHeight: 0
  };

  componentDidMount() {
    this.scheduleUpdate();
  }
  componentWillUnmount() {
    this.unscheduleUpdate();
  }

  scheduleUpdate = () => {
    this.updateInterval = setInterval(() => {
      if (this.popoverContent) {
        const w = this.popoverContent.clientWidth;
        const h = this.popoverContent.clientHeight;
        const { contentWidth, contentHeight } = this.state;
        if (w !== contentWidth || h !== contentHeight) {
          this.setState({ contentWidth: w, contentHeight: h });
        }
      }
    }, 100);
  };

  unscheduleUpdate = () => {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  };

  setPopoverContent = el => {
    this.popoverContent = el;
  };

  render() {
    const {
      anchorEl,
      anchorOrigin,
      targetOrigin,
      onClose,
      onTagSaved,
      activeTags,
      companyId,
      onTagAssigned
    } = this.props;
    return (
      <Popover
        open
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        targetOrigin={targetOrigin}
        onRequestClose={onClose}
      >
        <div ref={this.setPopoverContent}>
          <TagAssignmentPanel
            activeTags={activeTags}
            companyId={companyId}
            onTagAssigned={onTagAssigned}
            onTagSaved={onTagSaved}
            onClose={onClose}
          />
        </div>
      </Popover>
    );
  }
}

TagAssignmentPopover.propTypes = {
  anchorEl: PropTypes.any,
  companyId: PropTypes.string,
  activeTags: PropTypes.array,
  onTagAssigned: PropTypes.func,
  onTagSaved: PropTypes.func,
  onClose: PropTypes.func
};

export default TagAssignmentPopover;
