import * as Api from '../../api/entities/eapiKeyApi';
import { createApiCallAction } from '../apiCallAction';

import { EAPI_KEY_CREATE, EAPI_KEY_DELETE, EAPI_KEY_HIDE, EAPI_KEYS_GET } from '../../constants/constants';

const getEapiKeysAction = createApiCallAction(EAPI_KEYS_GET, Api.getEapiKeys);
export const getEapiKeys = companyId => getEapiKeysAction(companyId);

const createEapiKeyAction = createApiCallAction(EAPI_KEY_CREATE, Api.createEapiKey);
export const createEapiKey = companyId => createEapiKeyAction(companyId);

const deleteEapiKeyAction = createApiCallAction(EAPI_KEY_DELETE, Api.deleteEapiKey, {
  transformArgsToMeta: args => ({ id: args[1] })
});
export const deleteEapiKey = (companyId, keyId) => deleteEapiKeyAction(companyId, keyId);

export const hideEapiKey = () => ({ type: EAPI_KEY_HIDE });
