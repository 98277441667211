import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _reduce from 'lodash/reduce';

import CultureFitCommonPart, { validate as cultureFitValidate } from '../../../forms/parts/CultureFitCommonPart';

import { CULTURE_FIT_DIMENSIONS } from '../../../../constants/enum';
import { cultureFitToFormValue } from '../../../forms/FormUtils';
import { selectCompanyProfileToEdit } from '../../../../selectors/companyProfileBuilder';

export const ID = 'companyProfileCulturePartForm';

const messages = {};

const CulturePart = props => {
  const { intl, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <CultureFitCommonPart />
    </form>
  );
};

const validate = (values, props) => {
  return cultureFitValidate(values, props);
};

const mapStateToProps = (state, ownProps) => {
  const { intl } = state;
  const profile = selectCompanyProfileToEdit(state, ownProps);
  const initialValues = _reduce(
    CULTURE_FIT_DIMENSIONS,
    (res, d) => {
      res[d.formName] = cultureFitToFormValue(profile, d.key);
      return res;
    },
    {}
  );
  return {
    intl,
    initialValues
  };
};

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

CulturePart.ID = ID;

export default connect(mapStateToProps)(reduxForm(formConfig)(CulturePart));
