import React from 'react';
import PropTypes from 'prop-types';

import DualMetricsInterpretation from '../analytics/DualMetricsInterpretation';
import DualMetricsMassChart from '../analytics/DualMetricsMassChart';

import { getNumericDualMetrics, computeCrossComparisonFit } from '../../utils/analytics';

import { BRAND } from '../../constants/constants';

const CultureFitAnalytics = props => {
  const { metricDefinitions, participants, manager } = props;
  const sourceParticipants = manager ? [manager] : [];
  const dualMetrics = getNumericDualMetrics(metricDefinitions);
  const activeIndex = manager ? 0 : null;
  const data = computeCrossComparisonFit(dualMetrics, sourceParticipants, participants, activeIndex);
  const colors = manager && { main: 'rgba(0,0,0,0.24)', overlay: BRAND.theme.palette.accent1Color };
  return (
    <div>
      <div className="mui-padded-x2">
        <div className="container-chart-card">
          <DualMetricsMassChart metrics={data.metrics} />
        </div>
      </div>
      <div className="bg-primary-light">
        <DualMetricsInterpretation metrics={data.metrics} activeParticipant={manager} colors={colors} />
      </div>
    </div>
  );
};

CultureFitAnalytics.propTypes = {
  metricDefinitions: PropTypes.object.isRequired,
  participants: PropTypes.array,
  manager: PropTypes.object
};

export default CultureFitAnalytics;
