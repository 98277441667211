import React from 'react';

import Badge from './Badge';

import withIntl from '../withIntl';

const BetaBadge = props => {
  const { intl: { messages } } = props;
  return (
    <Badge
      color="rgb(176, 0, 32)"
      text={messages.label}
      style={{ textTransform: 'uppercase', padding: '3px 4px 2px', fontSize: '11px', lineHeight: '11px' }}
    />
  );
};

const intlMessages = {
  cs: {
    label: 'Beta'
  },
  sk: {
    label: 'Beta'
  },
  en: {
    label: 'Beta'
  }
};

export default withIntl(BetaBadge, intlMessages);
