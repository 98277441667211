import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import values from 'lodash/values';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import IconButton from 'material-ui/IconButton';

import SearchField from '../../../components/mui/DebouncedSearchField';
import RangeFilter from '../../../components/filter/RangeFilter';
import CategoryFilter from '../../../components/filter/CategoryFilter';
import DateRangeFilter from '../../../components/filter/DateRangeFilter';

import * as participantFiltersActions from '../../../actions/options/participantFiltersActions';
import { setGridOptions } from '../../../actions/options/gridActions';
import { selectParticipantsFilterValues } from '../../../selectors/applicantList';

class ParticipantFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedFilterParts: {}
    };
  }

  handleToggleFilterPartExpanded = filter => {
    this.setState(({ expandedFilterParts }) => ({
      expandedFilterParts: { ...expandedFilterParts, [filter.key]: !expandedFilterParts[filter.key] }
    }));
  };

  handleFilterChange = () => {
    const { project, setGridOptions } = this.props;
    setGridOptions('applicantsGrid', project.slug, { pageNumber: 0 });
  };

  handleCloseFilter = () => {
    const { project, closeParticipantFilter } = this.props;
    closeParticipantFilter(project.id);
  };

  handleChangeFullText = val => {
    const { project, setParticipantFilterFullText } = this.props;
    setParticipantFilterFullText(project.id, val);
    this.handleFilterChange();
  };

  setParticipantFilterFromValuesDebounced = debounce(this.props.setParticipantFilterFromValues, 300);

  extractFilterFromValues = filter => {
    const { fromValues } = this.props.filter;
    const { [filter.key]: obj } = fromValues || {};
    return obj || {};
  };

  handleToggleFromValues = (filter, value) => {
    const { project, setParticipantFilterFromValues } = this.props;
    const { items, ...rest } = this.extractFilterFromValues(filter);
    const { [value]: oldVal, ...newItems } = items || {};
    if (!oldVal) {
      newItems[value] = true;
    } else {
      // encountered weird 'rest' operator behaviour on numerical keys
      delete newItems[value];
    }
    setParticipantFilterFromValues(project.id, filter.key, { ...rest, items: isEmpty(newItems) ? null : newItems });
    this.handleFilterChange();
  };

  handleSetMatchAllValues = (filter, matchAll) => {
    const { project, setParticipantFilterFromValues } = this.props;
    const values = this.extractFilterFromValues(filter);
    setParticipantFilterFromValues(project.id, filter.key, { ...values, matchAll });
    this.handleFilterChange();
  };

  handleSetFromValuesRange = (filter, { start, end }) => {
    const { project } = this.props;
    const obj = this.extractFilterFromValues(filter);
    this.setParticipantFilterFromValuesDebounced(project.id, filter.key, { ...obj, start, end });
    this.handleFilterChange();
  };

  handleSetFromValuesFilterEnabled = (filter, enabled) => {
    const { project, setParticipantFilterFromValues } = this.props;
    const obj = this.extractFilterFromValues(filter);
    setParticipantFilterFromValues(project.id, filter.key, { ...obj, enabled });
    this.handleFilterChange();
  };

  render() {
    const { filter, project, intl, participantsFilterValues } = this.props;
    if (!project || !filter.open) {
      return null;
    }
    const { fullText, fromValues } = filter;
    const eMessages = intl.messages.constants.enums;
    const messages = intl.messages.components.pages.private.project.participantFilter;
    const numberFormatter = new Intl.NumberFormat(intl.locale).format;
    return (
      <div className="project-overview-filter bg-primary-light mui-appear mui-backdrop-blur">
        <IconButton
          style={{ position: 'absolute', top: 16, right: 8 }}
          onClick={this.handleCloseFilter}
          iconStyle={{ color: 'rgba(33, 33, 33, 0.54)' }}
        >
          <i className="material-icons">close</i>
        </IconButton>
        <div className="row">
          <div className="col-sm-12">
            <div className="full-text-container">
              <SearchField
                onValueChange={this.handleChangeFullText}
                value={fullText || ''}
                debounce={300}
                placeholder={messages.fullTextPlaceholder}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12" style={{ padding: '0 4px 4px' }}>
            {map(values(participantsFilterValues), fv => {
              const activeValues = (fromValues && fromValues[fv.key]) || {};
              const expanded = !!this.state.expandedFilterParts[fv.key];
              const isRange = fv.type === 'RANGE';
              const filterMessages = messages.fromValuesFilters[fv.key] || { label: fv.key };
              const label = fv.label || filterMessages.label;
              const description = filterMessages.description;
              const formatter = fv.formatter || numberFormatter;
              const commonProps = {
                expanded,
                filter: fv,
                activeValues,
                label,
                description,
                filterMessages,
                onToggleExpanded: this.handleToggleFilterPartExpanded
              };
              if (fv.type === 'DATE') {
                return (
                  <DateRangeFilter
                    key={fv.key}
                    {...commonProps}
                    onRangeChange={this.handleSetFromValuesRange}
                    onRangeSetEnabled={this.handleSetFromValuesFilterEnabled}
                  />
                );
              }
              if (isRange) {
                return (
                  <RangeFilter
                    key={fv.key}
                    {...commonProps}
                    formatter={formatter}
                    onToggleExpanded={this.handleToggleFilterPartExpanded}
                    onRangeChange={this.handleSetFromValuesRange}
                    onRangeSetEnabled={this.handleSetFromValuesFilterEnabled}
                  />
                );
              }
              return (
                <CategoryFilter
                  key={fv.key}
                  {...commonProps}
                  matchAllLabel={messages.matchAllLabel}
                  enumMessages={eMessages}
                  onToggleValue={this.handleToggleFromValues}
                  onSetMatchAll={this.handleSetMatchAllValues}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { options: { participantFilters }, intl } = state;
  const { project } = ownProps;

  return {
    filter: (project && participantFilters[project.id]) || {},
    intl,
    participantsFilterValues: selectParticipantsFilterValues(state, ownProps)
  };
};

const actionMap = { ...participantFiltersActions, setGridOptions };

export default withRouter(connect(mapStateToProps, actionMap)(ParticipantFilter));
