import React from 'react';
import _map from 'lodash/map';

import ActivityToggle from '../../../activity/ActivityToggle';

const ActivitySelectFormField = props => {
  const {
    input: { onChange, value },
    meta,
    label,
    helperText,
    indicateRequired,
    activities
  } = props;
  const { error, touched } = meta || {};
  const err = touched && error;
  return (
    <div style={{ margin: '16px 0 8px' }}>
      <div className={`mui-fields-label${err ? ' text-error' : ''}`}>
        {label}
        {indicateRequired ? ' *' : ''}
      </div>
      <div
        className="row container-flex-row fw-yes bg-primary-light mui-padded-half border-radius"
        style={{ margin: 0 }}
      >
        {_map(activities, a => (
          <div key={a.id} className="col-md-4 col-sm-6 container-flex-row" style={{ padding: 4 }}>
            <ActivityToggle active={value === a.id} activity={a} onToggle={({ id }) => onChange(id)} type="radio" />
          </div>
        ))}
      </div>
      {err && <div className="mui-fields-error-text">{err || helperText}</div>}
    </div>
  );
};

export default ActivitySelectFormField;
