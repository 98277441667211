import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'material-ui/Checkbox';

import { EApplicantListSort } from '../../constants/enum';
import { STYLES } from './ApplicantListGrid';
import {
  GridLabel,
  GridRow,
  GridCell,
  SortableGridLabel
} from '../grid';

const ApplicantListGridHeaderRow = props => {
  const { intl, sortProps, isAnyApplicantSelected } = props;
  const { handleToggleAllApplicantSelection } = props;
  const messages = intl.messages.components.pages.private.project.applicantList;
  return (
    <GridRow>
      <GridCell xs={3} sm={2} md={1} style={STYLES.checkboxCell}>
        <Checkbox
          style={STYLES.checkbox}
          checked={isAnyApplicantSelected}
          iconStyle={isAnyApplicantSelected ? {} : { fill: '#757575' }}
          onCheck={handleToggleAllApplicantSelection}
        />
      </GridCell>
      <GridCell xs={6} sm={3} lg={2} style={STYLES.alignRight}>
        <SortableGridLabel sort={EApplicantListSort.POSITION} {...sortProps}>
          {messages.cols.position}
        </SortableGridLabel>
      </GridCell>
      <GridCell xs={9} sm={12} md={5} lg={7}>
        <SortableGridLabel sort={EApplicantListSort.NAME} {...sortProps}>
          {messages.cols.name}
        </SortableGridLabel>
      </GridCell>
      <GridCell xs={6} sm={3} lg={2} style={STYLES.alignRight}>
        <SortableGridLabel sort={EApplicantListSort.SCORE} {...sortProps}>
          {messages.cols.score}
        </SortableGridLabel>
      </GridCell>
      <GridCell md={6} smHide xsHide>
        <GridLabel>{messages.cols.competencies}</GridLabel>
      </GridCell>
      <GridCell md={3} sm={4} xsHide style={STYLES.alignRight}>
        <SortableGridLabel sort={EApplicantListSort.FINISHED_AT} {...sortProps}>
          {messages.cols.finishedAt}
        </SortableGridLabel>
      </GridCell>
      <GridCell md={3} smHide xsHide>
        <GridLabel>{messages.cols.applicationStatus}</GridLabel>
      </GridCell>
    </GridRow>
  );
};

ApplicantListGridHeaderRow.propTypes = {
  intl: PropTypes.object.isRequired,
  sortProps: PropTypes.object.isRequired,
  isAnyApplicantSelected: PropTypes.bool.isRequired,
  //
  handleToggleAllApplicantSelection: PropTypes.func.isRequired
};

export default ApplicantListGridHeaderRow;
