import { handleActions } from 'redux-actions';
import * as c from '../../constants/constants';

export const defaultState = {};

export default handleActions(
  {
    [c.APPLICANT_REFRESH]: () => {
      return defaultState;
    },
    [c.RESULT_GET.SUCCESS]: (state, { payload }) => payload,
    [c.RESULT_GET.ERROR]: state => ({ ...state, error: true }),
    [c.APPLICANT_EVALUATION_DESCRIPTION_GET]: (state, { payload }) => {
      return {
        ...state,
        evaluationDescription: payload
      };
    },
    [c.APPLICANT_PROJECT_ACK]: (state, { payload: { userId, projectId } }) => {
      if (state && state.participant && state.participant.id === userId && state.projectId === projectId) {
        return {
          ...state,
          ackSent: Date.now()
        };
      }
      return state;
    },
    [c.APPLICANT_PROJECT_ACK_SUCCESS]: (state, { payload: { userId, projectId, ...ack } }) => {
      if (state && state.participant && state.participant.id === userId && state.projectId === projectId) {
        return {
          ...state,
          ackSent: null,
          ack
        };
      }
      return state;
    }
  },
  defaultState
);
