import React from 'react';
import PropTypes from 'prop-types';

import SimpleModal from './SimpleModal';

const ChangedFormLeave = props => {
  const { id, intl, callback } = props;
  const { switchModal } = props;

  const messages = intl.messages.components.modals.changeFormLeave;

  return (
    <SimpleModal
      open
      id={id}
      callback={callback}
      switchModal={switchModal}
      cancelLabel={messages.cancel}
      confirmLabel={messages.confirm}
      message={messages.message}
    />
  );
};

ChangedFormLeave.propTypes = {
  intl: PropTypes.object,
  switchModal: PropTypes.func
};

export default ChangedFormLeave;
