import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import SimpleModal from './SimpleModal';
import PhoneForm from '../forms/Phone';
import PriceList from '../company/PriceList';

import * as c from '../../constants/constants';
import { formatMessage } from '../../utils/utils';

const getSuccessProps = (messages, props) => {
  return {
    switchModal: props.switchModal,
    cancelLabel: messages.confirmationMessage.button,
    message: <p>{messages.confirmationMessage.success}</p>
  };
};

const getErrorProps = (messages, props) => {
  const supportEmail = c.BRAND.email('support');
  return {
    switchModal: props.switchModal,
    cancelLabel: messages.confirmationMessage.button,
    message: (
      <p>
        {formatMessage(messages.confirmationMessage.error, [<a href={`mailto:${supportEmail}`}>{supportEmail}</a>])}
      </p>
    )
  };
};

const getProps = (messages, props) => {
  const { id, data } = props;
  const { switchModal, upgradeAccount } = props;
  const source = data && data.source && data.source.key;
  return {
    switchModal: () => false,
    bodyStyle: { padding: 0 },
    title: messages.title,
    message: (
      <PhoneForm
        messages={{
          ...messages,
          message: messages.htmlMessage
            ? [<p key="htmlMessage" dangerouslySetInnerHTML={{ __html: messages.htmlMessage }} />]
            : map(messages.message, m => formatMessage(m, [c.BRAND.label]))
        }}
        onCancel={() => switchModal({ id, open: false })}
        submitPhone={({ phone }) => upgradeAccount({ phone, source })}
      />
    )
  };
};

const getCreditTopupProps = (messages, props, priceListMessages) => {
  const { id, data: { company, source } } = props;
  const { priceList } = company;
  const { switchModal, upgradeAccount } = props;
  return {
    switchModal: () => false,
    bodyStyle: { padding: 0 },
    contentStyle: { maxWidth: priceList ? '640px' : '500px' },
    title: messages.title,
    message: (
      <div>
        {priceList && (
          <div style={{ padding: '16px 24px 24px 24px', borderBottom: '1px solid #e0e0e0' }}>
            <p>{formatMessage(messages.messages.priceListIntro, [c.BRAND.label])}</p>
            <PriceList
              messages={priceListMessages}
              priceList={priceList}
              selectPackage={(pkgId, pkgData) =>
                upgradeAccount({
                  companyId: company.id,
                  credits: pkgData.creditAmount,
                  pricePackage: pkgId,
                  source: source && source.key
                })}
            />
          </div>
        )}
        <PhoneForm
          messages={{
            ...messages,
            message: priceList
              ? [messages.messages.customIntro]
              : map(messages.messages.noPriceList, m => formatMessage(m, [c.BRAND.label]))
          }}
          onCancel={() => switchModal({ id, open: false })}
          submitPhone={({ phone }) =>
            upgradeAccount({ phone, companyId: company.id, credits: 0, source: source && source.key })}
        />
      </div>
    )
  };
};

const AccountUpgradeModal = props => {
  const { id, status, intl, data } = props;
  const messages = {
    ...intl.messages.components.modals.accountUpgrade,
    ...(data && data.creditTopup ? intl.messages.components.modals.creditTopup : {})
  };
  let modalProps;
  // TODO this is awful, improve
  if (status === c.STATUS_SUCCESS) {
    modalProps = getSuccessProps(messages, props);
  } else if (status === c.STATUS_ERROR) {
    modalProps = getErrorProps(messages, props);
  } else if (data && data.creditTopup) {
    modalProps = getCreditTopupProps(messages, props, intl.messages.components.priceList);
  } else {
    modalProps = getProps(messages, props);
  }
  return <SimpleModal id={id} open {...modalProps} autoScrollBodyContent />;
};

AccountUpgradeModal.propTypes = {
  intl: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.string,
  //
  switchModal: PropTypes.func.isRequired,
  upgradeAccount: PropTypes.func.isRequired
};

export default AccountUpgradeModal;
