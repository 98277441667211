import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import map from 'lodash/map';
import includes from 'lodash/includes';

import Tooltip from '../../tooltip/Tooltip';

import { reverseArray, sortDisplayedMetrics } from '../../../utils/utils';

const reverseString = s => {
  if (!s) {
    return s;
  }
  let ns = '';
  for (let i = 0; i < s.length; i += 1) {
    ns += s[s.length - i - 1];
  }
  return ns;
};

const Cell = props => {
  const { allowedRanks, rank, color } = props;
  const style = {};
  if (includes(allowedRanks, rank)) {
    style.background = `linear-gradient(45deg, transparent -200%, ${color})`;
    // style.backgroundColor = color;
  }
  return <div className={`metrics-cell-chart-cell`} style={style} />;
};

const renderReducedChart = (metric, options) => {
  const { key, label, oppositeLabel, rank, color, reversed, valueType } = metric;
  const { dual } = options;
  const count = dual || valueType === 'FIAT' || valueType === 'GRADE' ? 4 : 3;
  const metricLabel =
    reversed && oppositeLabel ? `${oppositeLabel} x ${label}` : `${label}${oppositeLabel ? ` x ${oppositeLabel}` : ''}`;
  const shortLabel = reversed && dual ? reverseString(metric.shortLabel) : metric.shortLabel;
  const cells = dual
    ? [
        <Cell key="0" rank={rank} allowedRanks={['HIGH']} color={color} />,
        <Cell key="1" rank={rank} allowedRanks={['HIGH', 'MID_HIGH']} color={color} />,
        <Cell key="2" rank={rank} allowedRanks={['LOW', 'MID_LOW']} color={color} />,
        <Cell key="3" rank={rank} allowedRanks={['LOW']} color={color} />
      ]
    : valueType === 'GRADE'
      ? [
        <Cell key="0" rank={rank} allowedRanks={['LOW']} color={color} />,
        <Cell key="1" rank={rank} allowedRanks={['LOW', 'MID_LOW']} color={color} />,
        <Cell key="2" rank={rank} allowedRanks={['LOW', 'MID_LOW', 'MID', 'MID_HIGH']} color={color} />,
        <Cell key="3" rank={'any'} allowedRanks={['any']} color={color} />
      ]
    : count === 4
    ? [
        <Cell key="0" rank={rank} allowedRanks={['HIGH']} color={color} />,
        <Cell key="1" rank={rank} allowedRanks={['HIGH', 'MID_HIGH']} color={color} />,
        <Cell key="2" rank={rank} allowedRanks={['HIGH', 'MID_HIGH', 'MID', 'MID_LOW']} color={color} />,
        <Cell key="3" rank={'any'} allowedRanks={['any']} color={color} />
      ]
    : [
        <Cell key="0" rank={rank} allowedRanks={['HIGH', 'MID_HIGH']} color={color} />,
        <Cell key="1" rank={rank} allowedRanks={['HIGH', 'MID_HIGH', 'MID']} color={color} />,
        <Cell key="3" rank={'any'} allowedRanks={['any']} color={color} />
      ];
  return (
    <div key={key} className={`metrics-cell-chart-bar cells-${count} tooltip-element`}>
      {reversed ? reverseArray(cells) : cells}
      <Tooltip position="right">
        <span>
          {metricLabel}
          {options.valueRenderer && ': '}
          {options.valueRenderer && <strong>{options.valueRenderer(metric)}</strong>}
        </span>
      </Tooltip>
      <span className="metrics-cell-short-label">{shortLabel}</span>
    </div>
  );
};

const MetricsCellContent = ({ metrics, metricDefinitions, children, intl }) => {
  const metricsData = sortDisplayedMetrics(metrics, metricDefinitions);
  const perfIndicatorsData = sortDisplayedMetrics(metrics, metricDefinitions, 'CATEGORY');
  const dualMetricsData = sortDisplayedMetrics(metrics, metricDefinitions, 'NUMERIC_DUAL');
  const rankMessages = intl.messages.constants.enums.ERank;
  const arr = [];
  if (metricsData.length > 0) {
    arr.push({
      data: metricsData,
      options: {
        valueRenderer: f => {
          if (f.formattedValue) {
            return f.formattedValue;
          }
          const rankMessage = rankMessages[f.rank];
          if (rankMessage) {
            return rankMessage.label;
          }
          return '?';
        }
      }
    });
  }
  if (perfIndicatorsData.length > 0) {
    arr.push({
      data: perfIndicatorsData,
      options: {}
    });
  }
  if (dualMetricsData.length > 0) {
    arr.push({
      data: dualMetricsData,
      options: {
        dual: true,
        valueRenderer: f => (f.value < 0.5 ? f.oppositeLabel : f.label)
      }
    });
  }
  return (
    <div className="metrics-cell-content">
      {map(arr, ({ data, options }, idx) => (
        <div key={idx} className="metrics-cell-chart">
          {map(data, f => renderReducedChart(f, options))}
        </div>
      ))}
      {children}
    </div>
  );
};

MetricsCellContent.propTypes = {
  metrics: PropTypes.object.isRequired,
  metricDefinitions: PropTypes.object.isRequired,
  children: PropTypes.any
};

export default connect(({ intl }) => ({ intl }))(MetricsCellContent);
