import * as c from '../../constants/constants';

export function createSimulationDefinition(modelId) {
  return {
    type: c.API,
    payload: {
      asyncType: c.SIMULATION_DEFINITION_CREATE
    },
    meta: {
      url: `/api/v2/designer/simulations/models/${modelId}/definitions`,
      method: 'POST'
    }
  };
}
