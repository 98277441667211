import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import RaisedButton from 'material-ui/RaisedButton';
import TextField from './../superfield/form/SuperText';

import { BasicForm, BasicTitle } from '../basic';

const ResetPassword = props => {
  const { handleSubmit, intl, passwordReset } = props;
  const messages = intl.messages.components.pages.public.resetPassword;

  return (
    <BasicForm onSubmit={handleSubmit(passwordReset)}>
      <BasicTitle title={messages.text} />
      <div className={'row'}>
        <div className={'col-xs-12'}>
          <Field name={'password'} component={TextField} label={messages.newPwd} type="password" autoFocus />
        </div>
      </div>

      <div className={'row'}>
        <div className={'col-xs-12'}>
          <Field
            name={'passwordToMatch'}
            component={TextField}
            label={messages.repeatNewPwd}
            type={'password'}
          />
        </div>
      </div>

      <div className={'row'} style={{ marginTop: '25px' }}>
        <div className={'col-xs-12'}>
          <RaisedButton
            type={'submit'}
            label={messages.submit}
            primary
            style={{ float: 'right' }}
          />
        </div>
      </div>
    </BasicForm>
  );
};

ResetPassword.propTypes = {
  modal: PropTypes.object,
  intl: PropTypes.object,
  resetPassword: PropTypes.func,
  updateLocale: PropTypes.func,
  handleSubmit: PropTypes.func,
  passwordReset: PropTypes.func
};

export default reduxForm({
  form: 'resetPassword'
})(ResetPassword);
