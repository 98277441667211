import React, { useCallback, useMemo } from 'react';
import _map from 'lodash/map';

import Checkbox from 'material-ui/Checkbox';

import SuperSelect from '../superfield/SuperSelect';
import ActivityLabel from '../activity/ActivityLabel';
import ButtonToggle from '../mui/ButtonToggle';
import Chip from '../mui/Chip';
import MetricInsightPropertyMessage from './MetricInsightPropertyMessage';

import { EMetricFocus, EGroupFocus } from './TeamInsightsUtils';
import { mapObjectAsArray } from '../../utils/utils';

import withIntl from '../withIntl';
import { EActivityType, EGroupType } from '../../constants/enum';

const SingleValueSelect = props => {
  const { onChange, value, ...rest } = props;

  const values = useMemo(() => [value], [value]);
  const handleChange = useCallback(values => onChange(values[0]), [onChange]);

  return <SuperSelect {...rest} values={values} onChange={handleChange} />;
};

const findNeighbourMembers = (dataSource, activeId) => {
  let prev = null;
  let next = null;
  if (activeId) {
    for (let i = 0; i < dataSource.length; i += 1) {
      const m = dataSource[i];
      if (m.value === activeId) {
        prev = i > 0 ? dataSource[i - 1] : null;
        next = i < dataSource.length - 1 ? dataSource[i + 1] : null;
      }
    }
  }
  return { prev, next };
};

const renderNeighbourMemberNavigation = (neighbourMembers, onActiveMemberChange) => {
  const { prev, next } = neighbourMembers;
  return (
    <div className="container-flex-row fw-yes jc-space-between" style={{ margin: '0 -4px' }}>
      <div>
        {prev && (
          <a role="button" className="link-button" onClick={() => onActiveMemberChange(prev.value)}>
            <i className="material-icons text-muted" style={{ fontSize: '12px', margin: '0 4px 0 0' }}>
              keyboard_arrow_left
            </i>
            <span>{prev.label}</span>
          </a>
        )}
      </div>
      <div>
        {next && (
          <a role="button" className="link-button" onClick={() => onActiveMemberChange(next.value)}>
            <span>{next.label}</span>
            <i className="material-icons text-muted" style={{ fontSize: '12px', margin: '0 0 0 4px' }}>
              keyboard_arrow_right
            </i>
          </a>
        )}
      </div>
    </div>
  );
};

const EnumTogglePanel = props => {
  const { items, onToggle, active, messages } = props;
  return (
    <div className="container-flex-row bg-primary-light fw-yes border-radius" style={{ padding: '2px' }}>
      {mapObjectAsArray(items, item => (
        <div className="flex1" key={item.key} style={{ padding: '2px' }}>
          <ButtonToggle onToggle={() => onToggle(item.key)} active={item.key === active}>
            <div className="text-center mui-padded flex1 text-sz-reg-sm text-uppercase">
              <b>{messages[item.key]}</b>
            </div>
          </ButtonToggle>
        </div>
      ))}
    </div>
  );
};

const GROUP_FOCUS_VARIANT = {
  GROUP: { FULL: EGroupFocus.FULL, MEMBER: EGroupFocus.MEMBER },
  TEAM: EGroupFocus
};

const TeamInsightsControlsPanel = props => {
  const {
    team,
    activeMemberId,
    membersDataSource,
    focusKey,
    focusedMetrics,
    groupFocusKey,
    activityMetricProperties,
    activities,
    activityChartsShown,
    activityParticipantsListShown,
    //
    intl: { messages },
    //
    onActiveMemberChange,
    onFocusKeyChange,
    onMetricFocusToggle,
    onGroupFocusKeyChange,
    onActivityMetricPropertyToggle,
    onActivityChartsShownToggle,
    onActivityParticipantsListShownToggle
  } = props;
  const neighbourMembers = findNeighbourMembers(membersDataSource.list, activeMemberId);
  return (
    <div>
      <div className="mui-fields-container">
        <div className="mui-fields-label">{messages.focus.title}</div>
        <EnumTogglePanel
          items={team.type === EGroupType.TEAM.key ? GROUP_FOCUS_VARIANT.TEAM : GROUP_FOCUS_VARIANT.GROUP}
          onToggle={onGroupFocusKeyChange}
          active={groupFocusKey}
          messages={messages.focus.values}
        />
      </div>
      {groupFocusKey === EGroupFocus.MEMBER.key && (
        <div className="mui-fields-container">
          <SingleValueSelect
            label={messages.memberLabel}
            dataSource={membersDataSource.list}
            value={activeMemberId}
            onChange={onActiveMemberChange}
            helperText={renderNeighbourMemberNavigation(neighbourMembers, onActiveMemberChange)}
          />
        </div>
      )}
      <div className="border-color-primary__50 border-top mui-fields-container mui-padded-top-x2">
        <div className="mui-fields-label">{messages.factors.title}</div>
        <EnumTogglePanel
          items={EMetricFocus}
          onToggle={onFocusKeyChange}
          active={focusKey}
          messages={messages.factors.values}
        />
        {focusKey === EMetricFocus.SELECTED.key && (
          <div className="mui-margin-vertical">
            {_map(activities, a => (
              <div key={a.mapKey}>
                <div className="mui-label" style={{ margin: '16px 4px 8px' }}>
                  <ActivityLabel activity={a} />
                </div>
                {_map(a.metrics.all, m => (
                  <div key={m.key}>
                    <div className="mui-chip-container mui-margin-half">
                      <Chip variant="mini" onClick={() => onMetricFocusToggle(m)} active={!!focusedMetrics[m.key]}>
                        {m.reversed ? m.oppositeLabel : m.label}
                        {m.oppositeLabel && <small> x </small>}
                        {m.reversed ? m.label : m.oppositeLabel}
                      </Chip>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mui-padded-vertical">
        {_map(activities, a => {
          const amProps = activityMetricProperties[a.mapKey] || {};
          const chips = mapObjectAsArray(a.insightProps, (ip, key) => (
            <div className="mui-chip-container mui-margin-half" key={key}>
              <Chip variant="compact" onClick={() => onActivityMetricPropertyToggle(a, key)} active={!!amProps[key]}>
                <MetricInsightPropertyMessage property={key} type="label" />
              </Chip>
            </div>
          ));
          return (
            <div key={a.key} className="mui-padded-vertical-x2 border-top border-color-primary__50 mui-margin-top">
              <div className="mui-label">
                <ActivityLabel activity={a} />
              </div>
              <div className="container-flex-row fw-yes">
                {chips.length > 0 ? (
                  <div style={{ margin: '0 -4px' }}>{chips}</div>
                ) : (
                  <em className="text-muted">{messages.insightPropsEmptyMessage}</em>
                )}
              </div>
              {chips.length > 0 && (
                <>
                  {EActivityType.CULTURE_FIT.isLike(a) && (
                    <div className='mui-margin-top'>
                      <Checkbox
                        label={messages.showParticipantsLabel}
                        onCheck={() => onActivityParticipantsListShownToggle(a)}
                        checked={activityParticipantsListShown && activityParticipantsListShown[a.mapKey]}
                        iconStyle={{ marginRight: '8px' }}
                        labelStyle={{ margin: 0, fontSize: '13px' }}
                      />
                    </div>
                  )}
                  <div className="mui-margin-top">
                    <Checkbox
                      label={messages.showChartLabel}
                      onCheck={() => onActivityChartsShownToggle(a)}
                      checked={activityChartsShown && activityChartsShown[a.mapKey]}
                      iconStyle={{ marginRight: '8px' }}
                      labelStyle={{ margin: 0, fontSize: '13px' }}
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    factors: {
      title: 'Faktory',
      values: {
        SIGNIFICANT: 'Výrazné',
        ALL: 'Všechny',
        SELECTED: 'Vybrané'
      }
    },
    focus: {
      title: 'Focus',
      values: {
        FULL: 'Skupina',
        MANAGER: 'Manažer',
        MEMBER: 'Jednotlivec'
      }
    },
    showChartLabel: 'Zobrazit graf',
    showParticipantsLabel: 'Zobrazit účastníky',
    memberLabel: 'Jednotlivec',
    insightPropsEmptyMessage: 'Žádné dostupné údaje'
  },
  sk: {
    factors: {
      title: 'Faktory',
      values: {
        SIGNIFICANT: 'Výrazné',
        ALL: 'Všetky',
        SELECTED: 'Vybrané'
      }
    },
    focus: {
      title: 'Focus',
      values: {
        FULL: 'Skupina',
        MANAGER: 'Manažér',
        MEMBER: 'Jednotlivec'
      }
    },
    showChartLabel: 'Zobraziť graf',
    showParticipantsLabel: 'Zobraziť účastníkov',
    memberLabel: 'Jednotlivec',
    insightPropsEmptyMessage: 'Žiadne dostupné dáta'
  },
  en: {
    factors: {
      title: 'Factors',
      values: {
        SIGNIFICANT: 'Significant',
        ALL: 'All',
        SELECTED: 'Selected'
      }
    },
    focus: {
      title: 'Focus',
      values: {
        FULL: 'Group',
        MANAGER: 'Manager',
        MEMBER: 'Individual'
      }
    },
    showChartLabel: 'Show chart',
    showParticipantsLabel: 'Show participants',
    memberLabel: 'Individual',
    insightPropsEmptyMessage: 'No data available'
  }
};

export default withIntl(TeamInsightsControlsPanel, intlMessages);
