import { handleActions } from 'redux-actions';
import _filter from 'lodash/filter';

import { EAPI_KEY_CREATE, EAPI_KEY_DELETE, EAPI_KEY_HIDE, EAPI_KEYS_GET } from '../../constants/constants';

const initialState = {
  items: [],
  current: null
};

export default handleActions(
  {
    [EAPI_KEYS_GET.PENDING]: state => ({ ...state, loading: true }),
    [EAPI_KEYS_GET.ERROR]: state => ({ ...state, loading: false }),
    [EAPI_KEYS_GET.SUCCESS]: (state, { payload: { items } }) => ({ ...state, items, loading: false }),
    //
    [EAPI_KEY_CREATE.PENDING]: state => ({ ...state, creating: true }),
    [EAPI_KEY_CREATE.ERROR]: state => ({ ...state, creating: false }),
    [EAPI_KEY_CREATE.SUCCESS]: (state, { payload: { payload } }) => ({
      ...state,
      items: [
        ...state.items,
        {
          ...payload,
          secret: null
        }
      ],
      current: payload,
      creating: false
    }),
    //
    [EAPI_KEY_DELETE.PENDING]: state => ({ ...state, loading: true }),
    [EAPI_KEY_DELETE.ERROR]: state => ({ ...state, loading: false }),
    [EAPI_KEY_DELETE.SUCCESS]: (state, { meta }) => ({
      ...state,
      items: _filter(state.items, i => i.id !== meta.id),
      loading: false
    }),
    //
    [EAPI_KEY_HIDE]: state => ({ ...state, current: null })
  },
  initialState
);
