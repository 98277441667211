import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _find from 'lodash/find';

import UserTag from './UserTag';
import TagAssignmentPopover from './TagAssignmentPopover';
import Spinner from '../spinner/Spinner';
import Tooltip from '../tooltip/Tooltip';
import Collapse from '../basic/Collapse';

import * as Api from '../../api/entities/tagApi';

import withIntl from '../withIntl';

class UserTags extends React.Component {
  state = { tags: [], assigning: false, hovered: false, loading: false };

  componentDidMount() {
    if (!this.props.preventInitialLoad) {
      this.load();
    }
    if (this.props.tags) {
      this.setState({ tags: this.props.tags });
    }
  }

  componentDidUpdate(prevProps) {
    const { userId, companyId, tags } = this.props;
    if (userId !== prevProps.userId || companyId !== prevProps.companyId) {
      if (!this.props.preventInitialLoad) {
        this.load();
      }
    }
    if (tags !== prevProps.tags) {
      this.setState({ tags: tags || [] });
    }
  }

  load = () => {
    const { userId, companyId, onLoad } = this.props;
    if (onLoad) {
      onLoad(companyId, userId);
    } else {
      if (!userId || !companyId) {
        return;
      }
      this.setState({ loading: true });
      Api.getUserCompanyTags(companyId, userId)
        .then(res => this.setState({ tags: res.items }))
        .finally(() => this.setState({ loading: false }));
    }
  };
  handleTagAssigned = (tag, enabled) => {
    const { companyId } = this.props;
    if (!enabled) {
      // find out the tag ID
      const uct = _find(this.state.tags, t => t.tagId === tag.id);
      if (uct) {
        Api.deleteUserCompanyTag(companyId, uct.id).then(this.load);
      }
    } else {
      const { userId } = this.props;
      Api.assignUserCompanyTags(companyId, { userIds: [userId], tagIds: [tag.id] }).then(this.load);
    }
  };
  handleRemove = tag => {
    const { companyId } = this.props;
    Api.deleteUserCompanyTag(companyId, tag.id).then(this.load);
  };

  handleAssignmentOpen = () => this.setState({ assigning: true });
  handleAssignmentClose = () => this.setState({ assigning: false });

  handleTagSaved = t => {
    if (!t.id) {
      // new tag added - autoclose
      this.handleAssignmentClose();
    }
    this.load();
  };

  handleMouseEnter = () => this.setState(() => ({ hovered: true }));
  handleMouseLeave = () => this.setState(() => ({ hovered: false }));

  render() {
    const { tags, loading, assigning, hovered } = this.state;
    const {
      intl: { messages }
    } = this.props;
    return (
      <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <div className="container-flex-row ai-center fw-yes mui-padded-half">
          <div className="mui-padded-half tooltip-element">
            <span className="mdi mdi-tag-multiple-outline mdi-16px text-primary-sub" />
            <Tooltip position="bottom">{messages.tagTooltip}</Tooltip>
          </div>
          {_map(tags, t => (
            <div key={t.id} className="mui-padded-half">
              <UserTag {...t} onRemove={() => this.handleRemove(t, false)} />
            </div>
          ))}
          {tags.length === 0 && (
            <div className="mui-padded-horizontal">
              <em className="text-muted" style={{ fontSize: '12px' }}>
                {messages.noTagsMessage}
              </em>
            </div>
          )}
          <Collapse isOpened={assigning || hovered}>
            <div className={'mui-padded-half'} ref={el => (this.anchorEl = el)}>
              <a role="button" className="link-button" onClick={this.handleAssignmentOpen}>
                <i className="material-icons">add</i>
                <span>{messages.addLabel}</span>
              </a>
            </div>
          </Collapse>
          <Spinner size={16} stroke={2} show={loading} relative />
        </div>
        {assigning && (
          <TagAssignmentPopover
            companyId={this.props.companyId}
            onClose={this.handleAssignmentClose}
            onTagAssigned={this.handleTagAssigned}
            onTagSaved={this.handleTagSaved}
            anchorEl={this.anchorEl}
            activeTags={tags}
          />
        )}
      </div>
    );
  }
}

UserTags.propTypes = {
  userId: PropTypes.string,
  companyId: PropTypes.string,
  tags: PropTypes.array,
  onLoad: PropTypes.func,
  preventInitialLoad: PropTypes.bool
};

const intlMessages = {
  cs: {
    addLabel: 'Přidat tag',
    tagTooltip: 'Tagy',
    noTagsMessage: 'Bez tagů'
  },
  sk: {
    addLabel: 'Pridať tag',
    tagTooltip: 'Tagy',
    noTagsMessage: 'Bez tagov'
  },
  en: {
    addLabel: 'Add a tag',
    tagTooltip: 'Tags',
    noTagsMessage: 'No tags'
  }
};

export default withIntl(UserTags, intlMessages);
