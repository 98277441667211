import React from 'react';
import _map from 'lodash/map';
import numeral from 'numeral';

import Popover from '../../mui/Popover';

class DelayedContent extends React.Component {
  state = {
    shown: false
  };

  componentDidMount() {
    this.timeout = setTimeout(() => this.setState({ shown: true }), this.props.delay);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { children } = this.props;
    const { shown } = this.state;
    return shown ? children : null;
  }
}

const TooltipContent = props => {
  const { active, payload, metrics, scoreFormatter } = props;
  const data = payload && payload.length && payload[0].payload;
  if (!data || !active) {
    return null;
  }
  const metric = metrics[data.metricIndex].metric;

  const container = React.useRef(null);
  return (
    <div ref={container} className="mui-padded">
      <Popover
        open
        anchorEl={container?.current}
        useLayerForClickAway={false}
        style={{ boxShadow: 'none', background: 'transparent' }}
      >
        <div className="chart__tooltip">
          <div>
            <span className="metrics-bubble-chart__tooltip-label">{metric.label}</span>
            <span className="metrics-bubble-chart__tooltip-score-circle">{scoreFormatter(data.score, data.stat)}</span>
          </div>
          {!data.stat && (
            <div>
              <span className="metrics-bubble-chart__tooltip-count">{data.count}x</span>
              <span className="metrics-bubble-chart__tooltip-pct">({numeral(data.value).format('0.0 %')})</span>
            </div>
          )}
          {data.names && (
            <DelayedContent delay={1000}>
              <ul style={{ paddingLeft: '20px', paddingTop: '8px' }}>
                {_map(data.names, (val, idx) => (
                  <li key={idx}>{val}</li>
                ))}
              </ul>
            </DelayedContent>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default TooltipContent;
