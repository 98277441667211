import React from 'react';
import moment from 'moment';

import { EUserOpportunityPostResponse } from '../../../constants/enum';

const determineMessage = (messages, response, secondaryResponse) => {
  const { status } = response;
  const secondaryStatus = secondaryResponse && secondaryResponse.status;
  if (!secondaryStatus) {
    return messages[status];
  }
  return messages[`${status}_${secondaryStatus}`] || messages[status];
};

const OpportunityResponseStatus = props => {
  const { messages, response, secondaryResponse, intl: { locale }, rejectReasonMessages } = props;
  const message = determineMessage(messages, response, secondaryResponse);
  return (
    <div>
      <div>
        <span>{message.label}</span>
        {rejectReasonMessages &&
          response.status === EUserOpportunityPostResponse.REJECTED.key &&
          response.rejectReason && (
            <span className="text-normal-sub" style={{ marginLeft: '4px' }}>
              - {rejectReasonMessages[response.rejectReason.type].label}
            </span>
          )}
      </div>
      <div>
        <small>
          {moment(response.timestamp)
            .locale(locale)
            .calendar()}
        </small>
      </div>
    </div>
  );
};

export default OpportunityResponseStatus;
