import React from 'react';
import PropTypes from 'prop-types';

import ResetPasswordEmailForm from './../../forms/ResetPasswordEmail';
import EmailSentModal from './../../modals/EmailSent';
import PublicBase from './PublicBase';
import LoginRedirectFooter from './LoginRedirectFooter';

const ResetPasswordEmail = props => {
  const { onSubmit, intl, updateLocale, onModalClose, opened } = props;

  return (
    <PublicBase {...{ intl, updateLocale }} footer={<LoginRedirectFooter intl={intl} />}>
      <ResetPasswordEmailForm {...{ intl, onSubmit }} />
      <EmailSentModal {...{ intl, opened, onModalClose }} />
    </PublicBase>
  );
};

ResetPasswordEmail.propTypes = {
  intl: PropTypes.object,
  opened: PropTypes.bool,
  updateLocale: PropTypes.func,
  onSubmit: PropTypes.func,
  onModalClose: PropTypes.func
};

export default ResetPasswordEmail;
