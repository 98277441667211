import React from 'react';

import Avatar from 'material-ui/Avatar';

const hashCode = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash;
  }
  return hash;
};

const getInitials = ({ firstName, lastName, email }) => {
  if (firstName && lastName) {
    return firstName[0] + lastName[0];
  }
  if (email) {
    const res = /^(\w)\w*([^\w]+(\w?))?/.exec(email);
    if (res) {
      return (res[1] || '') + (res[3] || '') || 'XX';
    }
  }
  return 'XX';
};

const InitialsAvatar = props => {
  const { firstName, lastName, identifier, style, ...rest } = props;
  const initials = getInitials(props);
  const hue = (hashCode(identifier || initials) - 'A'.charCodeAt(0)) % 360;
  return (
    <Avatar
      style={{
        backgroundColor: `hsla(${hue},85%,89%,0.47)`,
        color: `hsla(${hue},85%,35%,0.87)`,
        transition: '0.2s',
        ...style
      }}
      {...rest}
    >
      {initials}
    </Avatar>
  );
};

export default InitialsAvatar;
