import { handleActions } from 'redux-actions';
import { OPPORTUNITIES_GET, OPPORTUNITIES_FILTER_FULL_TEXT_SET } from '../../constants/constants';

const initialState = {
  items: [],
  loading: false,
  filter: {
    fullText: ''
  }
};

export default handleActions({
  [OPPORTUNITIES_GET.SUCCESS]: (state, { payload }) => ({
    ...state,
    items: payload.items,
    error: false,
    loading: false
  }),
  [OPPORTUNITIES_GET.PENDING]: (state) => ({ ...state, loading: true }),
  [OPPORTUNITIES_GET.ERROR]: (state) => ({ ...state, loading: false, error: true }),
  [OPPORTUNITIES_FILTER_FULL_TEXT_SET]: (state, { payload }) => ({ ...state, filter: { ...state.filter, fullText: payload } })
}, initialState);
