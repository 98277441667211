import { authRequest } from '../Request';
import { API_URL } from '../../config';

export function getCompanyPools(companyId) {
  return new Promise((resolve, reject) => {
    authRequest(false)
      .get(`${API_URL}/api/v1/companies/${companyId}/pools`)
      .end((err, res) => (err ? reject(err.response) : resolve(res.body)));
  });
}
