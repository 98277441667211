import React from 'react';
import { Link } from 'react-router';
import _map from 'lodash/map';

import MembersCountMessage from './MembersCountMessage';
import InfoTooltip from '../basic/InfoTooltip';

import withIntl from '../withIntl';

const GroupSourcesPanel = props => {
  const { sources, intl: { messages } } = props;
  return (
    <div className="container-flex-row fw-yes ai-center">
      <div className="mui-padded-half">
        <InfoTooltip content={messages.tooltipMessage} dark compact>
          <i className="material-icons text-muted text-sz-lg">link</i>
        </InfoTooltip>
      </div>
      <div className="mui-padded-half container-flex-row fw-yes">
        {_map(sources, (s, idx) => (
          <div key={s.id} className="text-sz-sm">
            {idx > 0 && <span className="mui-margin-horizontal">&middot;</span>}
            <Link to={`/groups/${s.id}`}>
              {s.name}{' '}
              <small>
                (<MembersCountMessage type={s.type} count={s.metadata?.memberCount || 0} />)
              </small>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

const intlMessages = {
  cs: {
    tooltipMessage: 'Připojené otevřené pozice'
  },
  sk: {
    tooltipMessage: 'Pripojené otvorené pozície'
  },
  en: {
    tooltipMessage: 'Linked open positions'
  }
};

export default withIntl(GroupSourcesPanel, intlMessages);
