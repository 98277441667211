import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Checkbox from 'material-ui/Checkbox';

import { GridRow, GridCell, GridLabel, SortableGridLabel } from '../grid';
import MetricsCellContent from './cells/MetricsCellContent';
import FinishedCellContent from './cells/FinishedCellContent';
import ParticipantNameCellContent from './cells/ParticipantNameCellContent';
import Separator from './cells/Separator';

import LockedListGridRow from './LockedListGridRow';
import UnfinishedListGridRow from './UnfinishedListGridRow';

import { STYLES } from './ApplicantListGrid';
import { EApplicantListSort } from '../../constants/enum';
import { formatMessage } from '../../utils/utils';

const stopPropagation = e => e.stopPropagation();

// CB: xs=6 sm=4 md=2
const RESPONSIVE_PROPS = {
  check: {
    xs: 4,
    sm: 2,
    md: 2
  },
  name: {
    xs: 20,
    sm: 10
  },
  metrics: {
    xs: 14,
    sm: 7,
    md: 8
  },
  finished: {
    xs: 10,
    sm: 5,
    md: 4
  }
};

export const RESPONSIVE_HEADER_PROPS = {
  ...RESPONSIVE_PROPS,
  metrics: {
    ...RESPONSIVE_PROPS.metrics,
    xsHide: true
  },
  finished: {
    ...RESPONSIVE_PROPS.finished,
    xsHide: true
  }
};

const QuestionnaireListGridRow = props => {
  const { row, metricDefinitions, project, intl, selected } = props;
  // action
  const {
    onItemClicked,
    handleNotifyUnfinishedProfile,
    handleToggleApplicantSelection,
    invokeUpgradeAccountModal
  } = props;
  const { result, ack, status, simulation, loadingCounter, participant } = row;
  const displayed = !simulation.locked || (result && !isEmpty(result.metrics));
  if (!displayed) {
    return <LockedListGridRow intl={intl} invokeUpgradeAccountModal={invokeUpgradeAccountModal} />;
  }
  if (!result) {
    return (
      <UnfinishedListGridRow
        row={row}
        intl={intl}
        project={project}
        handleNotifyUnfinishedProfile={handleNotifyUnfinishedProfile}
        handleToggleApplicantSelection={handleToggleApplicantSelection}
        selected={selected}
        questionnaire
      />
    );
  }
  const onSort = (e, ...args) => {
    e.stopPropagation();
    props.onSort && props.onSort(e, ...args);
  };
  const isNew = ack === null || ack.status !== status;
  const messages = intl.messages.components.pages.private.project.applicantList;
  const outside = row.project && row.project.id !== project.id;
  return (
    <GridRow onClick={() => onItemClicked(row)}>
      {row.crossCompared && (
        <GridCell xs={24} style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
          <div
            style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: '12px',
              verticalAlign: 'top',
              background: row.crossComparedColor
            }}
          />
          {outside && (
            <div className="mui-label" style={{ margin: 0, verticalAlign: 'top', display: 'inline-block' }}>
              {formatMessage(messages.crossComparedFromProjectMessage, [<b>{row.project.name}</b>])}
            </div>
          )}
        </GridCell>
      )}
      {loadingCounter > 0 && <div className="applicant-loader" />}
      <GridCell {...RESPONSIVE_PROPS.check} style={STYLES.checkboxCell} onClick={stopPropagation}>
        <Checkbox
          checked={selected}
          iconStyle={selected ? {} : { fill: '#757575' }}
          onCheck={e => handleToggleApplicantSelection(row, e)}
        />
      </GridCell>
      <GridCell {...RESPONSIVE_PROPS.name} className="applicant-name">
        <ParticipantNameCellContent isNew={isNew} participant={participant} messages={messages} />
      </GridCell>
      <Separator smHide />
      <GridCell {...RESPONSIVE_PROPS.metrics} className="applicant-metrics">
        <GridLabel smHide mdHide lgHide>
          {messages.cols.metrics}
        </GridLabel>
        <MetricsCellContent metrics={result.metrics} metricDefinitions={metricDefinitions} />
      </GridCell>
      <GridCell {...RESPONSIVE_PROPS.finished} style={STYLES.alignRight}>
        <SortableGridLabel
          smHide
          mdHide
          lgHide
          sort={EApplicantListSort.FINISHED_AT}
          currentSort={props.currentSort}
          currentOrder={props.currentOrder}
          onSort={onSort}
        >
          {messages.cols.finishedAt}
        </SortableGridLabel>
        <FinishedCellContent finishedTimestamp={simulation.finishedAt} locale={intl.locale} metrics={result.metrics} />
      </GridCell>
    </GridRow>
  );
};

QuestionnaireListGridRow.propTypes = {
  row: PropTypes.object.isRequired,
  metricDefinitions: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  project: PropTypes.object,
  currentSort: PropTypes.string,
  currentOrder: PropTypes.string,
  selected: PropTypes.bool,
  //
  onItemClicked: PropTypes.func.isRequired,
  onSort: PropTypes.func,
  invokeUpgradeAccountModal: PropTypes.func.isRequired,
  handleNotifyUnfinishedProfile: PropTypes.func.isRequired,
  handleToggleApplicantSelection: PropTypes.func.isRequired
};

export default QuestionnaireListGridRow;
