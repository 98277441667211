import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { SubmissionError } from 'redux-form';

import allActions from './../../actions';

import ResetPasswordPage from './../../components/pages/public/ResetPassword';

class ResetPassword extends Component {
  passwordReset = ({ password, passwordToMatch }) => {
    const { resetPassword, showSnackbar, router, location, intl } = this.props;
    const messages = intl.messages.components.pages.public.resetPassword;

    if (password !== passwordToMatch)
      return new Promise(() => {
        throw new SubmissionError({
          passwordToMatch: messages.errorEqual
        });
      });
    else if (password.length < 8)
      return new Promise(() => {
        throw new SubmissionError({
          passwordToMatch: messages.errorWidth
        });
      });

    return resetPassword(password, location.query.email, location.query.token).then(res => {
      if (res.errorMessage) {
        showSnackbar({ id: 'ERROR', message: intl.messages.components.snackbar.error });
      } else {
        router.push({ pathname: '/login' });
      }
    });
  };

  render() {
    const { intl, updateLocale } = this.props;
    const pageProps = {
      intl,
      updateLocale,
      passwordReset: this.passwordReset
    };

    return <ResetPasswordPage {...pageProps} />;
  }
}

ResetPassword.propTypes = {
  intl: PropTypes.object,
  router: PropTypes.object,
  location: PropTypes.object,
  updateLocale: PropTypes.func,
  passwordResetModal: PropTypes.func,
  resetPassword: PropTypes.func,
  showSnackbar: PropTypes.func
};

const mapStateToProps = state => {
  const { intl } = state;
  return {
    intl
  };
};

const mapDispatchToProps = dispatch => ({ ...bindActionCreators(allActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));
