import {
  PARTICIPANT_FILTER_OPEN,
  PARTICIPANT_FILTER_CLOSE,
  PARTICIPANT_FILTER_SET_FULL_TEXT,
  PARTICIPANT_FILTER_SET_FROM_VALUES
} from '../../constants/constants';

export const openParticipantFilter = projectId => ({ type: PARTICIPANT_FILTER_OPEN, payload: { projectId } });

export const closeParticipantFilter = projectId => ({ type: PARTICIPANT_FILTER_CLOSE, payload: { projectId } });

export const setParticipantFilterFullText = (projectId, fullText) => ({
  type: PARTICIPANT_FILTER_SET_FULL_TEXT,
  payload: { projectId, fullText }
});

export const setParticipantFilterFromValues = (projectId, filterKey, values) => ({
  type: PARTICIPANT_FILTER_SET_FROM_VALUES,
  payload: { projectId, filterKey, values }
});
