import { handleActions } from 'redux-actions';

import { SCORE_MAPPING_SET, SCORE_TOTAL_ENABLED_SET, CORE_METRICS_SET } from '../../constants/constants';

const defaultState = {};

export default handleActions(
  {
    [SCORE_MAPPING_SET]: (state, { payload: { projectId, scoreMapping } }) => ({
      ...state,
      [projectId]: {
        ...state[projectId],
        scoreMapping
      }
    }),
    [SCORE_TOTAL_ENABLED_SET]: (state, { payload: { projectId, enabled } }) => ({
      ...state,
      [projectId]: {
        ...state[projectId],
        totalScoreEnabled: enabled
      }
    }),
    [CORE_METRICS_SET]: (state, { payload: { projectId, coreMetrics } }) => ({
      ...state,
      [projectId]: {
        ...state[projectId],
        coreMetrics
      }
    })
  },
  defaultState
);
